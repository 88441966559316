import React, { useEffect, useState, useRef, useCallback, useMemo, StrictMode } from 'react'
import SignatureCanvas from 'react-signature-canvas'

import Images from '../assets/Images'
import Icons from '../assets/Icons'

const SignatureCanvasPopup = ({ props }) => {

    const { message, image, heading, close, callback } = props

    const signatureRef = useRef(null);
    const [isDrawing, setIsDrawing] = useState(false);
    const [CanvasWidth, setCanvasWidth] = useState(500);
    const CanvasHeight = 350

    const clearCanvas = () => {
        signatureRef.current.clear();
        setIsDrawing(false);
    };
    const saveSignature = () => {

        if (!isDrawing) return

        const dataURL = signatureRef.current.toDataURL("image/png");
        callback(dataURL)
    };
    const handleEndDrawing = () => {
        setIsDrawing(true);
    };

    const HandleCancel = async (e) => {
        close(false)
    }

    const Popup_Header = () => {
        return (
            <div className="side-popup-header">
                <div className="header-item-select">
                    <div className="header-item-select-content">
                        <div className="label">{heading}</div>
                    </div>
                </div>
                <div
                    className="header-item-close"
                    onClick={(e) => HandleCancel(e)}
                    dangerouslySetInnerHTML={{ __html: Icons.general.close }}
                ></div>
            </div>
        );
    };
    const Popup_Footer = () => {

        return (
            <div className="sidebar-popup-footer">
                <div className="footer-item action-items">
                    <div className="action-preview">
                    </div>
                    <div className='action-btns'>
                        <div
                            className={`action-btn`}
                            onClick={(e) => HandleCancel(e)}>
                            Close
                        </div>
                        <div
                            className={`action-btn action-active ${isDrawing ? '' : 'action-disabled'}`}
                            onClick={(e) => saveSignature(e)}>
                            Save
                        </div>

                    </div>
                </div>
            </div>
        );
    };
    useEffect(() => {
        const handleResize = () => {
            if (signatureRef.current) {
                setCanvasWidth(signatureRef.current.offsetWidth || CanvasWidth);
            }
        };

        handleResize();
        window.addEventListener("resize", handleResize);

        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    return (
        <div className="popup-container-main popup-container-center">
            <div className="popup-block-ui"></div>
            <div className="side-popup-container signature-main-container" style={{ maxWidth: '500px', height: '600px !important' }}>
                <Popup_Header />
                <div className="sidebar-popup-content">
                    <div className="content-section">
                        <div className="popup-signature-main">
                            <div className="signature-header">
                                <div className="header-left">
                                    <div className="header-title">Leave your signature to submit*</div>
                                </div>
                                <div className="header-right">
                                    <div
                                        className={`header-reset-btn`}
                                        onClick={(e) => clearCanvas(e)}>
                                        <div className="icon"
                                            dangerouslySetInnerHTML={{ __html: Icons.general.eraser }}
                                        ></div>
                                        <div className="label">Reset Signature</div>
                                    </div>
                                </div>
                            </div>
                            <SignatureCanvas
                                ref={signatureRef}
                                penColor="black"
                                onEnd={handleEndDrawing}
                                canvasProps={{
                                    width: CanvasWidth,
                                    height: CanvasHeight,
                                    className: "signature-canvas"
                                }}
                            />
                        </div>
                    </div>

                </div>
                <Popup_Footer />
            </div>
        </div>
    )
}

export default SignatureCanvasPopup;