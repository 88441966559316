

import React, { useEffect, useState, useRef } from 'react'
import { Route, Routes, BrowserRouter, Link, useLocation, Navigate, Outlet, useNavigate, useParams } from 'react-router-dom';
import store from '../../redux/store'
import StoreHandler from '../../redux/StoreHandler'
import { useSelector, useDispatch } from 'react-redux'
import UserAction from '../../redux/action/userAction'

import ReverseProxy from '../../config/reverseProxy'
import EstimateHandler from '../../Handlers/Estimate/Estimate';
import WorkRatesHandler from '../../Handlers/WorkRates/WorkRates';
import ScheduleRatesHandler from '../../Handlers/ScheduleRates/ScheduleRates';

import Icons from '../../assets/Icons'
import Images from '../../assets/Images'
import { CostInput, TextInput, SelectInput, QuantityInput, RadioInput } from '../../components/Inputs'
import Utils from '../../utils'
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

import AddEstimateCatalogPopup from './/AddEstimateCatalogPopup'

import { useAuth, useMenuAuth } from '../../hooks/useAuth'


const AddGroup = ({ props }) => {

    const { existItemData, updateCallback } = props


    const { projectId, itemType, itemId, actionType } = useParams()

    const estimateHandler = new EstimateHandler()

    const [project_id, setProject_id] = useState('')
    const [estimate_id, setEstimate_id] = useState('')
    const [name, setName] = useState('')
    const [group_id, setGroup_id] = useState('')
    const [group, setGroup] = useState('')
    const [description, setDescription] = useState('')

    const [ProjectPhases, setProjectPhases] = useState([])
    const [ProjectPhases_Options, setProjectPhases_Options] = useState([])

    const [Phase_id, setPhase_id] = useState('')
    const [Phase_name, setPhase_name] = useState('')

    const [isLoading, setIsLoading] = useState(false)
    const [warningAlert, setWarningAlert] = useState(false)
    const [warningAlertType, setWarningAlertType] = useState('warning')
    const [apiFailedMessage, setApiFailedMessage] = useState("Error in Server")


    const [currency_type, setCurrency_type] = useState('₹')

    const navigate = useNavigate()
    const store = useSelector((store) => store)
    const dispatch = useDispatch()
    const { updateState } = new UserAction


    const storeHandler = new StoreHandler({
        dataStack: [`project-${projectId}`, `project_phases-${projectId}`],
        store: store,
        dispatch: dispatch,
        updateState: updateState,
    })

    const LoadStoreData = async () => {
        setIsLoading(true)
        let resolved = await storeHandler.init()
        setIsLoading(false)
    }

    const isExistItemData = existItemData && existItemData.id != undefined
    const [additionalNotes, setAdditionalNotes] = useState(false);


    const AddBtnOptions = [
        {
            icon: Icons.general.group,
            label: 'Group',
            type: 'group',
            id: "group"
        },
        {
            icon: Icons.general.work,
            label: 'Work Template',
            type: 'work_template',
            id: 'work_template'
        },
        {
            icon: Icons.general.labour,
            label: 'Labour',
            type: 'item',
            id: "labour"
        },
        {
            icon: Icons.general.material,
            label: 'Materials',
            type: 'item',
            id: "material"
        },
        {
            icon: Icons.general.machines,
            label: 'Machines',
            type: 'item',
            id: "machine"
        },
        {
            icon: Icons.general.subcontractor,
            label: 'Subcontractor',
            type: 'item',
            id: "subcontractor"
        },
        {
            icon: Icons.general.fee,
            label: 'Fee',
            type: 'item',
            id: "fee"
        },
        {
            icon: Icons.general.allowance,
            label: 'Allowances',
            type: 'item',
            id: "allowance"
        },
        {
            icon: Icons.general.work,
            label: 'Work',
            type: 'work',
            id: 'work'
        },
    ]

    const [AddBtnOption, setAddBtnOption] = useState(AddBtnOptions.filter(opt => opt.id == itemType)[0] || undefined)

    const [groupOptions, setGroupOptions] = useState([])

    const getEstimateGroups = async () => {

        let filter = {
            type: 1,
            projectId: projectId
        }

        let response = await estimateHandler.getEstimateItemsHandler(filter)

        if (response.success) {

            let { data } = response

            if (!Array.isArray(data)) return

            let getSubItemsIds = (items, parentid) => {

                let subGroupIds = []

                let getItems = (items, parentid) => {

                    if (!Array.isArray(items)) return

                    let _items = items.filter(itm => itm.parent_id == parentid)

                    subGroupIds.push(..._items)

                    _items.forEach(itm => getItems(items, itm.id))
                }

                getItems(items, parentid)

                subGroupIds = subGroupIds.map(g => g.id)

                subGroupIds.push(parentid)

                return subGroupIds
            }

            if (isExistItemData) {

                data = data.filter(d => !getSubItemsIds(data, existItemData.id).includes(d.id))
            }

            data = data.filter(d => d.type == '1').map(d => {

                return {
                    value: d.id,
                    label: d.name,
                }

            })



            setGroupOptions(data)
        }
    }

    const HandlePopupSubmit = async (e) => {
        e.preventDefault()

        let updated_data = {
            projectId: projectId,
            estimate_id: estimate_id,
            name,
            parent_id: group_id || undefined,
            parent_name: group || undefined,

            phase_id: String(Phase_id || ''),
            phase_name: Phase_name,

            type: 1,
            expanded: false,
            description,

        }
        setIsLoading(true)

        let response = { success: false }

        if (actionType == 'edit' && isExistItemData && existItemData.id) {
            updated_data['id'] = existItemData.id
            response = await estimateHandler.updateEstimateGroupHandler(updated_data)
        }
        else if (actionType == 'add') response = await estimateHandler.createEstimateGroupHandler(updated_data)

        setIsLoading(false)

        if (response.success) {

            if (typeof updateCallback == 'function') updateCallback()
            navigate(`/builder/projects/${projectId}/estimate`)
        }
        else {
            setWarningAlert(true)
            setWarningAlertType('error')
            setApiFailedMessage(`Error in  Group`)
        }


    }

    const Popup_Header = ({ props }) => {

        const { icon, label } = props
        return (
            <div className="side-popup-header">
                <div className="header-item-select">
                    <div className="header-item-select-content">
                        <span className="icon" dangerouslySetInnerHTML={{ __html: icon }}></span>
                        <div className="label">{actionType == 'edit' ? 'Update' : 'Add'} {label || 'Group'}</div>

                    </div>
                </div>
                <div
                    className="header-item-close"
                    onClick={(e) => {
                        navigate(`/builder/projects/${projectId}/estimate`)
                    }}
                    dangerouslySetInnerHTML={{ __html: Icons.general.close }}
                ></div>
            </div>
        );
    };
    const Popup_Footer = ({ props }) => {

        const { icon, label } = props

        return (
            <div className="sidebar-popup-footer">
                <div className="footer-item action-items">
                    <div className="action-preview">
                    </div>
                    <button
                        className="action-btn"
                        type='submit'
                    >
                        <div className="icon" dangerouslySetInnerHTML={{ __html: actionType == 'edit' ? Icons.general.save : Icons.general.add_btn }}></div>
                        <div className="label">{actionType == 'edit' ? 'Save' : 'Add Group'}</div>
                    </button>
                </div>
            </div>
        );
    };

    const getEstimateItemTypeEenem = (type) => {

        if (type == '1') return 'group'
        else if (type == '2') return 'work_template'
        else if (type == '3') return 'item'
        else if (type == '4') return 'work'
        else return ''

    }
    const getEstimateItemCategoryEenem = (type) => {

        if (type == '1') return 'material'
        else if (type == '2') return 'labour'
        else if (type == '3') return 'machine'
        else if (type == '4') return 'subcontractor'
        else if (type == '5') return 'fee'
        else if (type == '6') return 'allowance'
        else return ''

    }
    const getEstimateItemStatusEenem = (type) => {

        if (type == '0') return 'incomplete'
        else if (type == '1') return 'completed'
        else if (type == '2') return 'hold'
        else if (type == '3') return 'stopped'
        else return ''
    }

    const getFormatedEstimateItem = (item) => {

        return {
            sno: item.sno,
            expanded: true,
            selected: false,
            disable: false,

            parent_id: item.parent_id,
            parent_name: item.parent_name,
            id: item.id,
            name: item.name,
            description: item.description,

            costcode_id: item.costcode_id || "",
            costcode_name: item.costcode_name || "",


            co_efficient: parseFloat(item.co_efficient || 0) || 0,
            per_co_efficient_cost: parseFloat(item.per_co_efficient_cost || 0) || 0,

            type: getEstimateItemTypeEenem(item.type),
            item_type: item.type == '1' ? 'group' : getEstimateItemCategoryEenem(item.item_type),
            cost_type: getEstimateItemCategoryEenem(item.item_type) || "",
            _status: item.status,
            status: getEstimateItemStatusEenem(item.status),

            quantity: parseFloat(item.quantity || 0) || 0,
            unitrate: parseFloat(item.unitrate || 0) || 0,
            builder_price: parseFloat(item.builder_price || 0) || 0,
            price: parseFloat(item.price || 0) || 0,


            unit_id: item.unit_id || "",
            unit_name: item.unit_name || "",

            markup_id: item.markup_id || "",
            markup_name: item.markup_name || "",
            markup_value: parseFloat(item.markup_value || 0) || 0,
            markup_value_type: item.markup_value_type || "",
            markup_amount: parseFloat(item.markup_amount || 0) || 0,

            tax_id: item.tax_id || "",
            tax_name: item.tax_name || "",
            tax_value_type: item.tax_value_type || "",
            tax_value: parseFloat(item.tax_value || 0) || 0,
            tax_amount: parseFloat(item.tax_amount || 0) || 0,

            sub_items: []
        }

    }

    const LoadEstimateItem = async (idx) => {

        if (idx == undefined) return

        let filter = {
            projectId: projectId,
            idx: idx
        }

        setIsLoading(true)

        let response = await estimateHandler.getEstimateItemsHandler(filter)
        setIsLoading(false)

        if (response.success) {


            let { data } = response

            if (!Array.isArray(data) || !data.length) return

            let item = getFormatedEstimateItem(data[0])

            setName(item.name || '')
            setAdditionalNotes(item.description ? true : false)
            setDescription(item.description || '')
            setGroup_id(item.parent_id || '')
            setGroup(item.parent_name || '')
            setPhase_id(item.phase_id || '')
            setPhase_name(item.phase_name || '')

        }
        else {
            setWarningAlert(true)
            setWarningAlertType('error')
            setApiFailedMessage(`Error in Group`)
        }
    }

    const HandlePhaseInputChange = (value, label) => {

        let phase = ProjectPhases.filter(phase => phase.id == value)[0]

        if (!phase) return

        setPhase_id(phase.id)
        setPhase_name(phase.name)
    }


    useEffect(() => {

        LoadStoreData()
        getEstimateGroups()

        if (actionType == 'edit' && isExistItemData) {

            setName(existItemData.name || '')
            setAdditionalNotes(existItemData.description ? true : false)
            setDescription(existItemData.description || '')
            setGroup_id(existItemData.parent_id || '')
            setGroup(existItemData.parent_name || '')
            setPhase_id(existItemData.phase_id || '')
            setPhase_name(existItemData.phase_name || '')
        }

        if (actionType == 'edit' && !isExistItemData) LoadEstimateItem(itemId)

    }, [])

    useEffect(() => {

        if (store && store.user) {

            let { selected_project, selected_project_phases } = store.user

            if (selected_project) {
                let { id, estimate_id } = selected_project

                if (estimate_id) setEstimate_id(estimate_id)
                if (id) setProject_id(id)
            }
            if (selected_project_phases) {

                setProjectPhases(selected_project_phases)

                let project_phases = selected_project_phases.map(phase => {
                    return {
                        value: phase.id,
                        label: `${phase.name} | ${phase.client_name}`,
                        isDefault: false
                    }
                })

                setProjectPhases_Options(project_phases || [])

            }

        }


    }, [store])

    return (
        <div className="popup-container-main popup-container-center">
            <div className="popup-block-ui"></div>
            <form className="side-popup-container" onSubmit={HandlePopupSubmit}>

                <Popup_Header props={AddBtnOption} />

                <div className="sidebar-popup-content">
                    <div className="content-section">


                        <div className="content-item">
                            <TextInput
                                props={{
                                    id: "estimation-group-name",
                                    value: name,
                                    placeholder: '',
                                    setValue: (value) => setName(value),
                                    isIcon: false,
                                    isLabel: true,
                                    isRequired: true,
                                    label: "Name",
                                }}
                            />
                        </div>
                        <div className="content-item">
                            <SelectInput
                                props={{
                                    id: "estimation-group-phase",
                                    value: Phase_id,
                                    placeholder: '',
                                    options: [
                                        {
                                            value: "",
                                            label: "Select phase"
                                        }, ...ProjectPhases_Options || []
                                    ] || [],
                                    setValue: (value, label) => HandlePhaseInputChange(value, label),
                                    isIcon: false,
                                    isLabel: true,
                                    isRequired: false,
                                    label: "Project Phase",
                                }}
                            />
                        </div>
                        <div className="content-item">
                            <SelectInput
                                props={{
                                    id: "estimation-group-group",
                                    value: group_id,
                                    placeholder: '',
                                    options: [
                                        {
                                            value: "",
                                            label: "Select group"
                                        }, ...groupOptions || []
                                    ] || [],
                                    setValue: (value, label) => {
                                        setGroup_id(value)
                                        setGroup(label)
                                    },
                                    isIcon: false,
                                    isLabel: true,
                                    isRequired: false,
                                    label: "Parent Group",
                                }}
                            />
                        </div>
                        <div className="content-section">
                            <div className="content-item">
                                <div id="estimation-group-description" className={`additional-item ${additionalNotes ? 'additional-item-active' : ''}`}>
                                    <div className="head" onClick={(e) => setAdditionalNotes(!additionalNotes)}>
                                        <div className="icon" dangerouslySetInnerHTML={{ __html: Icons.general.description }}></div>
                                        <div className="label">Description</div>
                                    </div>
                                    <div className="item-expanded">
                                        <ReactQuill
                                            theme="snow"
                                            value={description}
                                            onChange={(value) => setDescription(value)}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>

                <Popup_Footer props={AddBtnOption} />
            </form>
        </div>
    )
}
const AddItem = ({ props }) => {

    const { existItemData, updateCallback } = props

    const { projectId, itemType, itemId, actionType } = useParams()

    const [isLoading, setIsLoading] = useState(false)
    const [warningAlert, setWarningAlert] = useState(false)
    const [warningAlertType, setWarningAlertType] = useState('warning')
    const [apiFailedMessage, setApiFailedMessage] = useState("Error in Server")

    const estimateHandler = new EstimateHandler()
    const scheduleRatesHandler = new ScheduleRatesHandler()

    const [currency_type, setCurrency_type] = useState('₹')

    const navigate = useNavigate()
    const store = useSelector((store) => store)
    const dispatch = useDispatch()
    const { updateState } = new UserAction

    const storeHandler = new StoreHandler({
        dataStack: ['costcodes', 'company', 'field_settings', 'finance_settings', `project-${projectId}`, `project_phases-${projectId}`],
        store: store,
        dispatch: dispatch,
        updateState: updateState,
    })

    const LoadStoreData = async () => {
        setIsLoading(true)
        let resolved = await storeHandler.init()
        setIsLoading(false)
    }
    const getEstimateGroups = async () => {

        let filter = {
            type: 1,
            projectId: projectId
        }

        let response = await estimateHandler.getEstimateItemsHandler(filter)

        if (response.success) {

            let { data } = response

            if (!Array.isArray(data)) return

            data = data.filter(d => d.type == '1').map(d => {

                return {
                    value: d.id,
                    label: d.name,
                }

            })

            setGroupOptions(data)
        }
    }

    const AddBtnOptions = [
        {
            icon: Icons.general.group,
            label: 'Group',
            type: 'group',
            id: "group"
        },
        {
            icon: Icons.general.work,
            label: 'Work Template',
            type: 'work_template',
            id: 'work_template'
        },
        {
            icon: Icons.general.labour,
            label: 'Labour',
            type: 'item',
            id: "labour"
        },
        {
            icon: Icons.general.material,
            label: 'Materials',
            type: 'item',
            id: "material"
        },
        {
            icon: Icons.general.machines,
            label: 'Machines',
            type: 'item',
            id: "machine"
        },
        {
            icon: Icons.general.subcontractor,
            label: 'Subcontractor',
            type: 'item',
            id: "subcontractor"
        },
        {
            icon: Icons.general.fee,
            label: 'Fee',
            type: 'item',
            id: "fee"
        },
        {
            icon: Icons.general.allowance,
            label: 'Allowances',
            type: 'item',
            id: "allowance"
        },
        {
            icon: Icons.general.work,
            label: 'Work',
            type: 'work',
            id: 'work'
        },
    ]

    const [AddBtnOption, setAddBtnOption] = useState(AddBtnOptions.filter(opt => opt.id == itemType)[0] || undefined)

    const isExistItemData = existItemData && existItemData.id != undefined

    const [enableAllowance, setEnableAllowance] = useState(false)
    const [allowanceFor, setAllowanceFor] = useState({
        manpower: false,
        labour: false,
        machine: false,
        material: false,
        subcontractor: false,
        fee: false,
    })

    const [SOR_items_Orginal, setSOR_items_Orginal] = useState([])

    const [SOR_item_id, setSOR_item_id] = useState('')
    const [SOR_item, setSOR_item] = useState('')

    const [SOR_items_Options, setSOR_items_Options] = useState([])


    const [project_id, setProject_id] = useState('')
    const [estimate_id, setEstimate_id] = useState('')

    const [name, setName] = useState('')

    const [group_id, setGroup_id] = useState('')
    const [group, setGroup] = useState('')

    const [id, setId] = useState(isExistItemData ? existItemData.id : '')
    const [quantity, setQuantity] = useState('')
    const [unit_id, setUnit_id] = useState('')
    const [unit_name, setUnit_name] = useState('')
    const [status, setStatus] = useState('')
    const [unitrate, setUnitrate] = useState('')
    const [description, setDescription] = useState('')
    const [additionalNotes, setAdditionalNotes] = useState(false);

    const [tax_id, setTax_id] = useState('')
    const [tax_name, setTax_name] = useState('')
    const [tax_value, setTax_value] = useState(0)

    const [markup_id, setMarkup_id] = useState('')
    const [markup_name, setMarkup_name] = useState('')
    const [markup_value, setMarkup_value] = useState(0)
    const [markup_type, setMarkup_type] = useState('%')

    const [parent_type, setParent_type] = useState('')
    const [parent_quantity, setParent_quantity] = useState(1)


    const [ProjectPhases, setProjectPhases] = useState([])
    const [ProjectPhases_Options, setProjectPhases_Options] = useState([])

    const [Phase_id, setPhase_id] = useState('')
    const [Phase_name, setPhase_name] = useState('')

    const [statusOptions, setStatusOptions] = useState([
        {
            value: "incomplete",
            label: "Incomplete"
        },
        {
            value: "completed",
            label: "Completed"
        },
        {
            value: "hold",
            label: "Hold"
        },
        {
            value: "stopped",
            label: "Stopped"
        },
    ])
    const [costcodeOptions, setCostcodeOptions] = useState([])
    const [unitsOptions, setUnitsOptions] = useState([])
    const [taxesOptions, setTaxesOptions] = useState([])
    const [markupsOptions, setMarkupsOptions] = useState([])
    const [groupOptions, setGroupOptions] = useState([])

    const unti_tax = (parseFloat((unitrate * parent_quantity * quantity) || 0) * parseFloat(tax_value)) / 100
    const unti_markup = markup_type == '%' ? (parseFloat((unitrate * parent_quantity * quantity) || 0) * parseFloat(markup_value)) / 100 : parseFloat(markup_value)

    const price = (unitrate * parent_quantity * quantity) + unti_tax + unti_markup

    const HandleScheduleInputChange = (id, label) => {

        if (!id) return

        setSOR_item_id(id)
        setSOR_item(label)

        let get_sor_item = SOR_items_Orginal.filter(s => s.id == id)[0]

        if (!get_sor_item) return

        setName(get_sor_item.name || "")
        setDescription(get_sor_item.description || "")

        setAdditionalNotes(get_sor_item.description ? true : false)

        setUnit_id(get_sor_item.unit_id || "")
        setUnit_name(get_sor_item.unit_name || "")

        setMarkup_id(get_sor_item.markup_id || "")
        setMarkup_name(get_sor_item.markup_name || "")
        setMarkup_value(parseFloat(get_sor_item.markup_value || 0))
        setMarkup_type(get_sor_item.markup_value_type || "")

        setTax_id(get_sor_item.tax_id || "")
        setTax_name(get_sor_item.tax_name || "")
        setTax_value(parseFloat(get_sor_item.tax_value || 0))

        setUnitrate(parseFloat(get_sor_item.unitrate || 0) || "")
        setQuantity(parseFloat(get_sor_item.quantity || 1))

    }

    const LoadScheduleRatesItems = async () => {

        setIsLoading(true)

        let response = await scheduleRatesHandler.getItemsHandler()
        setIsLoading(false)

        if (response.success) {

            let { data } = response

            if (!Array.isArray(data)) return

            let sor_item_types = {
                material: "1",
                labour: "2",
                machine: "3",
                subcontractor: "4",
                fee: "5",
                allowance: "6",
            }

            let sor_options = data.filter(d => d.type == '3' && sor_item_types[itemType] == d.item_type).map(d => {
                return {
                    value: d.id,
                    label: d.name,
                    type: d.item_type,
                    type: d.item_type,
                    isDefault: false
                }
            })

            setSOR_items_Orginal(data)
            setSOR_items_Options(sor_options)


        }
    }



    const getEstimateItemTypeEenemReverse = (type) => {

        if (type == 'material') return 1
        else if (type == 'labour') return 2
        else if (type == 'machine') return 3
        else if (type == 'subcontractor') return 4
        else if (type == 'fee') return 5
        else if (type == 'allowance') return 6
        else return 0

    }


    const getEstimateItemStatusEenemReverse = (type) => {

        if (type == 'incomplete') return 0
        else if (type == 'completed') return 1
        else if (type == 'hold') return 2
        else if (type == 'stopped') return 3
        else return -1

    }
    const getEstimateItemStatusEenem = (type) => {

        if (type == '0') return 'incomplete'
        else if (type == '1') return 'completed'
        else if (type == '2') return 'hold'
        else if (type == '3') return 'stopped'
        else return ''
    }

    const HandlePopupSubmit = async (e) => {
        e.preventDefault()

        let updated_data = {

            name: name,
            estimate_id: estimate_id,
            projectId: projectId,
            status: getEstimateItemStatusEenemReverse(status),

            unit_id: unit_id,
            unit_name: unit_name,
            unitrate: String(unitrate),
            quantity: String(quantity),

            sor_id: String(SOR_item_id) || "",
            sor_name: String(SOR_item) || "",

            parent_type: String(parent_type) || "",
            parent_quantity: String(parent_quantity) || "",

            parent_id: group_id || undefined,
            parent_name: group || undefined,

            phase_id: String(Phase_id || ''),
            phase_name: Phase_name,

            tax_id: tax_id,
            tax_name: tax_name,
            tax_value: String(tax_value),
            tax_amount: String(unti_tax),


            markup_id: markup_id,
            markup_name: markup_name,
            markup_value: String(markup_value),
            markup_value_type: markup_type,
            markup_amount: String(unti_markup),

            expanded: false,
            builder_price: String(unitrate * quantity),
            price: String(price),
            type: 3,
            item_type: getEstimateItemTypeEenemReverse(AddBtnOption.id),
            description: description,
        }

        setIsLoading(true)

        let response = { success: false }

        if (actionType == 'edit' && id) {
            updated_data['id'] = id
            response = await estimateHandler.updateEstimateItemHandler(updated_data)
        }
        else if (actionType == 'add') response = await estimateHandler.createEstimateItemHandler(updated_data)

        if (response.success) {
            setIsLoading(false)
            if (typeof updateCallback == 'function') updateCallback()
            navigate(`/builder/projects/${projectId}/estimate`)
        }
        else {
            setIsLoading(false)
            setWarningAlert(true)
            setWarningAlertType('error')
            setApiFailedMessage(`Error in ${AddBtnOption.label} Item`)
        }


    }


    const Popup_Header = ({ props }) => {

        const { icon, label } = props
        return (
            <div className="side-popup-header">
                <div className="header-item-select">
                    <div className="header-item-select-content">
                        <span className="icon" dangerouslySetInnerHTML={{ __html: icon }}></span>
                        <div className="label">{actionType == 'edit' ? 'Update' : 'Add'} {label || "Item"}</div>

                    </div>
                </div>
                <div
                    className="header-item-close"
                    onClick={(e) => {
                        navigate(`/builder/projects/${projectId}/estimate`)
                    }}
                    dangerouslySetInnerHTML={{ __html: Icons.general.close }}
                ></div>
            </div>
        );
    };
    const Popup_Footer = ({ props }) => {

        const { icon, label } = props

        return (
            <div className="sidebar-popup-footer">
                <div className="footer-item action-items">
                    <div className="action-preview">
                        <div className="label">{label} Price</div>
                        <div className="value">{currency_type}{price || '0.00'}</div>
                    </div>
                    <button
                        className="action-btn"
                        type='submit'
                    >
                        <div className="icon" dangerouslySetInnerHTML={{ __html: actionType == 'edit' ? Icons.general.save : Icons.general.add_btn }}></div>
                        <div className="label">{actionType == 'edit' ? 'Save' : 'Add Item'}</div>
                    </button>
                </div>
            </div>
        );
    };


    const getEstimateItemTypeEenem = (type) => {

        if (type == '1') return 'group'
        else if (type == '2') return 'work_template'
        else if (type == '3') return 'item'
        else if (type == '4') return 'work'
        else return ''

    }
    const getEstimateItemCategoryEenem = (type) => {

        if (type == '1') return 'material'
        else if (type == '2') return 'labour'
        else if (type == '3') return 'machine'
        else if (type == '4') return 'subcontractor'
        else if (type == '5') return 'fee'
        else if (type == '6') return 'allowance'
        else return ''

    }


    const getFormatedEstimateItem = (item) => {

        return {
            sno: item.sno,
            expanded: true,
            selected: false,
            disable: false,

            sor_id: item.sor_id,
            sor_name: item.sor_name,

            parent_id: item.parent_id,
            parent_name: item.parent_name,
            id: item.id,
            name: item.name,
            description: item.description,


            co_efficient: parseFloat(item.co_efficient || 0) || 0,
            per_co_efficient_cost: parseFloat(item.per_co_efficient_cost || 0) || 0,

            type: getEstimateItemTypeEenem(item.type),
            item_type: item.type != '3' ? getEstimateItemTypeEenem(item.type) : getEstimateItemCategoryEenem(item.item_type),
            cost_type: item.type != '1' && item.type != '3' ? getEstimateItemTypeEenem(item.type) : getEstimateItemCategoryEenem(item.item_type),

            parent_type: item.parent_type,
            parent_quantity: parseFloat(item.parent_quantity || 0),

            _status: item.status,
            status: getEstimateItemStatusEenem(item.status),

            quantity: parseFloat(item.quantity || 0) || 0,
            unitrate: parseFloat(item.unitrate || 0) || 0,
            builder_price: parseFloat(item.builder_price || 0) || 0,
            price: parseFloat(item.price || 0) || 0,


            unit_id: item.unit_id || "",
            unit_name: item.unit_name || "",

            markup_id: item.markup_id || "",
            markup_name: item.markup_name || "",
            markup_value: parseFloat(item.markup_value || 0) || 0,
            markup_value_type: item.markup_value_type || "",
            markup_amount: parseFloat(item.markup_amount || 0) || 0,

            tax_id: item.tax_id || "",
            tax_name: item.tax_name || "",
            tax_value_type: item.tax_value_type || "",
            tax_value: parseFloat(item.tax_value || 0) || 0,
            tax_amount: parseFloat(item.tax_amount || 0) || 0,

            sub_items: []
        }

    }

    const LoadEstimateItem = async (idx) => {

        if (idx == undefined) return

        let filter = {
            projectId: projectId,
            idx: idx
        }

        setIsLoading(true)

        let response = await estimateHandler.getEstimateItemsHandler(filter)
        setIsLoading(false)

        if (response.success) {


            let { data } = response


            if (!Array.isArray(data) || !data.length) return


            let items = data.map(d => {

                let item = getFormatedEstimateItem(d)

                let tax_amount = item.tax_amount
                let markup_amount = item.markup_amount
                let builder_price = item.builder_price
                let price = item.price

                item._tax_amount = tax_amount || ""
                item._tax_percent = ((tax_amount / builder_price) * 100 || 0).toFixed(2)
                item._markup_amount = markup_amount || ""
                item._markup_percent = ((markup_amount / builder_price) * 100 || 0).toFixed(2)
                item._builder_price = builder_price || ""
                item._price = price || ""
                item._unitrate = item.unitrate || ""
                item._quantity = item.quantity || ""

                item.tax_amount = tax_amount ? `${currency_type}${tax_amount}` : ""
                item.markup_amount = markup_amount ? `${currency_type}${markup_amount}` : ""
                item.builder_price = builder_price ? `${currency_type}${builder_price}` : ""
                item.price = price ? `${currency_type}${price}` : ""
                item.unitrate = item.unitrate ? `${currency_type}${item.unitrate}` : ""
                item.quantity = item.quantity ? `${item.quantity}${item.unit_name || ""}` : ""

                return item

            })

            let item = items[0]

            setId(item.id)

            setParent_type(item.parent_type || '0')
            setParent_quantity(item.parent_quantity)

            setName(item.name || '')

            setGroup_id(item.parent_id || '')
            setGroup(item.parent_name || '')

            setUnit_id(item.unit_id || '')
            setUnit_name(item.unit_name || '')

            setStatus(item.status)
            setQuantity(parseFloat(item._quantity || 0) || '')
            setUnitrate(parseFloat(item._unitrate || 0) || '')
            setDescription(item.description || '')

            setAdditionalNotes(item.description ? true : false)

            setTax_id(item.tax_id || '')
            setTax_name(item.tax_name || '')
            setTax_value(parseFloat(item.tax_value || 0) || 0)

            setMarkup_id(item.markup_id || '')
            setMarkup_name(item.markup_name || '')
            setMarkup_value(parseFloat(item.markup_value || 0) || 0)
            setMarkup_type(item.markup_value_type)

        }
        else {
            setWarningAlert(true)
            setWarningAlertType('error')
            setApiFailedMessage(`Error in ${AddBtnOption.label} Item`)
        }
    }


    useEffect(() => {

        LoadStoreData()
        getEstimateGroups()
        LoadScheduleRatesItems()

        if (actionType == 'edit' && isExistItemData) {

            if (existItemData.parent_type) {
                console.log(existItemData.parent_type, 'parent_type');
                setParent_type(existItemData.parent_type || '')
                setParent_quantity(existItemData.parent_quantity || 0)
            }

            setSOR_item_id(existItemData.sor_id)
            setSOR_item(existItemData.sor_name)

            setName(existItemData.name || '')



            setGroup_id(existItemData.parent_id || '')
            setGroup(existItemData.parent_name || '')

            setPhase_id(existItemData.phase_id || '')
            setPhase_name(existItemData.phase_name || '')

            setUnit_id(existItemData.unit_id || '')
            setUnit_name(existItemData.unit_name || '')

            setStatus(existItemData.status)
            setQuantity(parseFloat(existItemData._quantity || 0) || '')
            setUnitrate(parseFloat(existItemData._unitrate || 0) || '')
            setDescription(existItemData.description || '')

            setAdditionalNotes(existItemData.description ? true : false)

            setTax_id(existItemData.tax_id || '')
            setTax_name(existItemData.tax_name || '')
            setTax_value(parseFloat(existItemData.tax_value || 0) || 0)

            setMarkup_id(existItemData.markup_id || '')
            setMarkup_name(existItemData.markup_name || '')
            setMarkup_value(parseFloat(existItemData.markup_value || 0) || 0)
            setMarkup_type(existItemData.markup_value_type)


        }

        if (actionType == 'edit' && !isExistItemData) LoadEstimateItem(itemId)

    }, [])
    const HandlePhaseInputChange = (value, label) => {

        let phase = ProjectPhases.filter(phase => phase.id == value)[0]

        if (!phase) return

        setPhase_id(phase.id)
        setPhase_name(phase.name)
    }


    useEffect(() => {

        if (store && store.user) {

            let { company, costcodes, selected_project, clients, settings, selected_project_phases } = store.user

            if (selected_project) {
                let { id, estimate_id } = selected_project

                if (estimate_id) setEstimate_id(estimate_id)
                if (id) setProject_id(id)
            }

            if (company) {

                let { currency_setting, timezone, temperature_setting } = company

                if (currency_setting) setCurrency_type(currency_setting)
            }
            if (Array.isArray(costcodes)) {

                costcodes = costcodes.filter(opt => opt.type == '1').map((opt) => {
                    return {
                        value: opt.id,
                        label: opt.name,
                    }
                })

                setCostcodeOptions(costcodes)
            }


            if (settings && Array.isArray(settings.field_settings) && settings.field_settings.length) {

                let { units } = settings.field_settings[0]

                units = units.filter(opt => opt.isActive).map((opt) => {
                    return {
                        value: opt.id,
                        label: opt.name,
                        isDefault: opt.isDefault || false
                    }
                })

                setUnitsOptions(units || [])

            }

            if (settings && Array.isArray(settings.finance_settings) && settings.finance_settings.length) {

                let { taxes, markups, overheads, insurance } = settings.finance_settings[0]

                taxes = taxes.filter(opt => opt.isActive).map((opt) => {
                    return {
                        id: opt.id,
                        value: parseFloat(opt.value || 0),
                        label: `${opt.name} (${opt.value_type != '%' ? opt.value_type : ''}${opt.value}${opt.value_type})`,
                        type: opt.type,
                        isDefault: opt.isDefault || false
                    }
                })

                setTaxesOptions(taxes || [])


                markups = markups.filter(opt => opt.isActive).map((opt) => {
                    return {
                        id: opt.id,
                        value: parseFloat(opt.value || 0),
                        label: `${opt.name} (${opt.value_type != '%' ? opt.value_type : ''}${opt.value}${opt.value_type})`,
                        type: opt.value_type,
                        isDefault: opt.isDefault || false
                    }
                })

                setMarkupsOptions(markups || [])
            }

            if (selected_project_phases) {

                setProjectPhases(selected_project_phases)

                let project_phases = selected_project_phases.map(phase => {
                    return {
                        value: phase.id,
                        label: `${phase.name} | ${phase.client_name}`,
                        isDefault: false
                    }
                })

                setProjectPhases_Options(project_phases || [])

            }

        }

    }, [store])

    return (

        <div className="popup-container-main propup-container-center">
            <div className="popup-block-ui"></div>
            <form className="side-popup-container animateXRight animateXRightactive" onSubmit={HandlePopupSubmit}>
                <Popup_Header props={AddBtnOption} />

                <div className="sidebar-popup-content">
                    <div className="content-section">

                        <div className="content-item">
                            <SelectInput
                                props={{
                                    id: "estimate-work-scheduleitem",
                                    value: SOR_item_id,
                                    placeholder: '',
                                    options: SOR_items_Options || [],
                                    setValue: (value, label) => HandleScheduleInputChange(value, label),
                                    isIcon: false,
                                    isLabel: true,
                                    isRequired: true,
                                    label: "Schedule Item",
                                }}
                            />
                        </div>

                        <div className="content-item">
                            <TextInput
                                props={{
                                    id: "estimate-work-name",
                                    value: name,
                                    placeholder: '',
                                    setValue: (value) => {
                                        setName(value)
                                    },
                                    isIcon: false,
                                    isLabel: true,
                                    isRequired: true,
                                    label: "Name",
                                }}
                            />
                        </div>
                        <div className="content-item">
                            <SelectInput
                                props={{
                                    id: "estimate-work-phase",
                                    value: Phase_id,
                                    placeholder: '',
                                    options: [
                                        {
                                            value: "",
                                            label: "Select phase"
                                        }, ...ProjectPhases_Options || []
                                    ] || [],
                                    setValue: (value, label) => HandlePhaseInputChange(value, label),
                                    isIcon: false,
                                    isLabel: true,
                                    isRequired: false,
                                    label: "Project Phase",
                                }}
                            />
                        </div>

                        {parent_type != '4' ?
                            <div className="content-item">
                                <SelectInput
                                    props={{
                                        id: "estimate-work-group",
                                        value: group_id,
                                        placeholder: '',
                                        options: [
                                            {
                                                value: "",
                                                label: "Select Group"
                                            }, ...groupOptions || []
                                        ] || [],
                                        setValue: (value, label) => {
                                            setGroup_id(value)
                                            setGroup(label)
                                        },
                                        isIcon: false,
                                        isLabel: true,
                                        isRequired: false,
                                        label: "Group",
                                    }}
                                />
                            </div>
                            : ''}


                        <div className="content-item">

                            {parent_type != '4' ?
                                <TextInput
                                    props={{
                                        id: "estimate-work-quantity",
                                        value: quantity,
                                        type: 'number',
                                        placeholder: '',
                                        setValue: (value) => {
                                            setQuantity(value)
                                        },
                                        isIcon: false,
                                        isLabel: true,
                                        isRequired: true,
                                        label: "Quantity",
                                    }}
                                />
                                :

                                <QuantityInput
                                    props={{
                                        parentQty: String(parent_quantity),
                                        actionType: "multiple",
                                        value: String(quantity),
                                        placeholder: '',
                                        setValue: (value) => setQuantity(parseFloat(value)),
                                        isIcon: false,
                                        isLabel: true,
                                        isRequired: true,
                                        label: "Quantity (Main Qty x Work Quantity)",
                                        id: "estimate-work-quantity"
                                    }}
                                />
                            }

                        </div>
                        <div className="content-item">

                            <div className="content-sub-item">
                                <SelectInput
                                    props={{
                                        id: "estimate-work-unit",
                                        value: unit_id,
                                        placeholder: '',
                                        readOnly: false,
                                        options: unitsOptions || [],
                                        setValue: (value, label) => {
                                            setUnit_id(value)
                                            setUnit_name(label)
                                        },
                                        isIcon: false,
                                        isLabel: true,
                                        isRequired: true,
                                        label: "Unit type",
                                    }}
                                />
                            </div>

                            <div className="content-sub-item">
                                <CostInput
                                    props={{
                                        id: "estimate-work-unitrate",
                                        value: unitrate,
                                        isCostMethod: false,
                                        currency_type: currency_type,
                                        readOnly: false,
                                        placeholder: '',
                                        setValue: (value) => {
                                            setUnitrate(value)
                                        },
                                        isIcon: true,
                                        icon: currency_type,
                                        isLabel: true,
                                        isRequired: true,
                                        label: "Unit rate",
                                    }}
                                />
                            </div>

                        </div>
                        <div className="content-item">
                            <CostInput
                                props={{
                                    id: "estimate-work-price",
                                    value: price,
                                    currency_type: currency_type,
                                    isCostMethod: false,
                                    placeholder: '',
                                    readOnly: true,
                                    setValue: (value) => {
                                        // setPrice(value)
                                    },
                                    isIcon: true,
                                    icon: currency_type,
                                    isLabel: true,
                                    isRequired: false,
                                    label: "Amount",
                                }}
                            />
                        </div>
                    </div>
                    <div className="content-section">
                        <div className="content-section-header">
                            <div className="label">Pricing</div>
                        </div>

                        <div className="content-section-header">
                            <div className="label">Tax</div>
                            <div className="input">
                                <select
                                    name=""
                                    id=""
                                    onInput={(e) => {

                                        let tax = taxesOptions?.filter(t => t.id == e.target.value)[0]

                                        if (tax) {

                                            setTax_id(tax.id)
                                            setTax_value(parseFloat(tax.value || 0))
                                            setTax_name(tax.label)
                                        }
                                    }}
                                    value={tax_id}
                                >
                                    {taxesOptions?.map((tax, i) => (
                                        <option value={`${tax.id}`}>{tax.label}</option>
                                    ))}
                                </select>
                            </div>
                        </div>
                        <div className="content-item">
                            <div className="content-sub-item">
                                <CostInput
                                    props={{
                                        value: tax_value,
                                        isCostMethod: false,
                                        currency_type: currency_type,
                                        placeholder: 'Unit cost',
                                        setValue: (value) => {
                                            setTax_value(parseFloat(value) || 0)
                                        },
                                        isIcon: true,
                                        icon: '%',
                                        isLabel: true,
                                        isRequired: true,
                                        label: "Tax",
                                        id: "estimate-item-tax"
                                    }}
                                />
                            </div>
                            <div className="content-sub-item content-sub-label-item">
                                <div className="content-sub-label-content">
                                    <div className="label">Unit tax</div>
                                    <div className="value">{currency_type}{unti_tax}</div>
                                </div>
                            </div>

                        </div>

                        <div className="content-section-header">
                            <div className="label">Markup</div>
                            <div className="input">
                                <select
                                    name=""
                                    id=""
                                    onInput={(e) => {

                                        let item = markupsOptions.filter(itm => itm.id == e.target.value)[0]
                                        if (item) {
                                            setMarkup_value(parseFloat(item.value || 0))
                                            setMarkup_id(item.id)
                                            setMarkup_name(item.label)
                                            setMarkup_type(item.type)
                                        }
                                    }}
                                    value={markup_id}
                                >
                                    {markupsOptions.map((itm, i) => (
                                        <option value={`${itm.id}`}>{itm.label}</option>
                                    ))}
                                </select>
                            </div>
                        </div>
                        <div className="content-item">
                            <div className="content-sub-item">
                                <CostInput
                                    props={{
                                        value: markup_value,
                                        costMethodValue: markup_type,
                                        isCostMethod: true,
                                        currency_type: currency_type,
                                        placeholder: 'Unit cost',
                                        setValue: (value, type) => {
                                            setMarkup_value(parseFloat(value) || 0)
                                            if (type) setMarkup_type(type)
                                        },
                                        isIcon: true,
                                        icon: '%',
                                        isLabel: true,
                                        isRequired: true,
                                        label: "Markup",
                                        id: "estimate-item-markup"
                                    }}
                                />
                            </div>
                            <div className="content-sub-item content-sub-label-item">
                                <div className="content-sub-label-content">
                                    <div className="label">Unit markup</div>
                                    <div className="value">{currency_type}{unti_markup}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="content-section">
                        <div className="content-item">
                            <div id="estimate-description" className={`additional-item ${additionalNotes ? 'additional-item-active' : ''}`}>
                                <div className="head" onClick={(e) => setAdditionalNotes(!additionalNotes)}>
                                    <div className="icon" dangerouslySetInnerHTML={{ __html: Icons.general.description }}></div>
                                    <div className="label">Description</div>
                                </div>
                                <div className="item-expanded">
                                    <ReactQuill
                                        theme="snow"
                                        value={description}
                                        onChange={(value) => setDescription(value)}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <Popup_Footer props={AddBtnOption} />
            </form>
        </div>
    )

}
const AddWorkSideItem = ({ props }) => {

    let { type, header_option, close, MainQty, existItemData, updateCallback } = props

    MainQty = parseFloat(MainQty || 0) || 0

    const { projectId, itemType, itemId, actionType } = useParams()

    const [isLoading, setIsLoading] = useState(false)
    const [warningAlert, setWarningAlert] = useState(false)
    const [warningAlertType, setWarningAlertType] = useState('warning')
    const [apiFailedMessage, setApiFailedMessage] = useState("Error in Server")

    const scheduleRatesHandler = new ScheduleRatesHandler()


    const [currency_type, setCurrency_type] = useState('₹')

    const navigate = useNavigate()
    const store = useSelector((store) => store)
    const dispatch = useDispatch()
    const { updateState } = new UserAction

    const storeHandler = new StoreHandler({
        dataStack: ['costcodes', 'company', 'field_settings', 'finance_settings', `project-${projectId}`],
        store: store,
        dispatch: dispatch,
        updateState: updateState,
    })

    const LoadStoreData = async () => {
        setIsLoading(true)
        let resolved = await storeHandler.init()
        setIsLoading(false)
    }

    const AddBtnOptions = [
        {
            icon: Icons.general.group,
            label: 'Group',
            type: 'group',
            id: "group"
        },
        {
            icon: Icons.general.work,
            label: 'Work Template',
            type: 'work_template',
            id: 'work_template'
        },
        {
            icon: Icons.general.labour,
            label: 'Labour',
            type: 'item',
            id: "labour"
        },
        {
            icon: Icons.general.material,
            label: 'Materials',
            type: 'item',
            id: "material"
        },
        {
            icon: Icons.general.machines,
            label: 'Machines',
            type: 'item',
            id: "machine"
        },
        {
            icon: Icons.general.subcontractor,
            label: 'Subcontractor',
            type: 'item',
            id: "subcontractor"
        },
        {
            icon: Icons.general.fee,
            label: 'Fee',
            type: 'item',
            id: "fee"
        },
        {
            icon: Icons.general.allowance,
            label: 'Allowances',
            type: 'item',
            id: "allowance"
        },
        {
            icon: Icons.general.work,
            label: 'Work',
            type: 'work',
            id: 'work'
        },
    ]

    const [AddBtnOption, setAddBtnOption] = useState(AddBtnOptions.filter(opt => opt.id == header_option)[0] || undefined)

    const isExistItemData = existItemData && existItemData.id != undefined

    const [enableAllowance, setEnableAllowance] = useState(false)
    const [allowanceFor, setAllowanceFor] = useState({
        manpower: false,
        labour: false,
        machine: false,
        material: false,
        subcontractor: false,
        fee: false,
    })


    const [SOR_items_Orginal, setSOR_items_Orginal] = useState([])

    const [SOR_item_id, setSOR_item_id] = useState('')
    const [SOR_item, setSOR_item] = useState('')

    const [SOR_items_Options, setSOR_items_Options] = useState([])

    const [project_id, setProject_id] = useState('')
    const [estimate_id, setEstimate_id] = useState('')

    const [name, setName] = useState('')

    const [group_id, setGroup_id] = useState('')
    const [group, setGroup] = useState('')


    const [id, setId] = useState(isExistItemData ? existItemData.id : '')
    const [quantity, setQuantity] = useState('')
    const [unit_id, setUnit_id] = useState('')
    const [unit_name, setUnit_name] = useState('')
    const [status, setStatus] = useState('')
    const [unitrate, setUnitrate] = useState('')
    const [description, setDescription] = useState('')
    const [additionalNotes, setAdditionalNotes] = useState(false);

    const [tax_id, setTax_id] = useState('')
    const [tax_name, setTax_name] = useState('')
    const [tax_value, setTax_value] = useState(0)

    const [markup_id, setMarkup_id] = useState('')
    const [markup_name, setMarkup_name] = useState('')
    const [markup_value, setMarkup_value] = useState(0)
    const [markup_type, setMarkup_type] = useState('%')


    const [statusOptions, setStatusOptions] = useState([
        {
            value: "incomplete",
            label: "Incomplete"
        },
        {
            value: "completed",
            label: "Completed"
        },
        {
            value: "hold",
            label: "Hold"
        },
        {
            value: "stopped",
            label: "Stopped"
        },
    ])
    const [costcodeOptions, setCostcodeOptions] = useState([])
    const [unitsOptions, setUnitsOptions] = useState([])
    const [taxesOptions, setTaxesOptions] = useState([])
    const [markupsOptions, setMarkupsOptions] = useState([])
    const [groupOptions, setGroupOptions] = useState([])

    const [co_efficient, setCo_efficient] = useState(0)
    const [per_co_efficient_cost, setPer_co_efficient_cost] = useState(0)

    const unti_tax = (parseFloat((unitrate * quantity) || 0) * parseFloat(tax_value)) / 100
    const unti_markup = markup_type == '%' ? (parseFloat((unitrate * quantity) || 0) * parseFloat(markup_value)) / 100 : parseFloat(markup_value)

    const price = (unitrate * quantity) + unti_tax + unti_markup

    const HandleScheduleInputChange = (id, label) => {

        if (!id) return

        setSOR_item_id(id)
        setSOR_item(label)

        let get_sor_item = SOR_items_Orginal.filter(s => s.id == id)[0]

        if (!get_sor_item) return

        setName(get_sor_item.name || "")
        setDescription(get_sor_item.description || "")

        setAdditionalNotes(get_sor_item.description ? true : false)

        setUnit_id(get_sor_item.unit_id || "")
        setUnit_name(get_sor_item.unit_name || "")

        setMarkup_id(get_sor_item.markup_id || "")
        setMarkup_name(get_sor_item.markup_name || "")
        setMarkup_value(parseFloat(get_sor_item.markup_value || 0))
        setMarkup_type(get_sor_item.markup_value_type || "")

        setTax_id(get_sor_item.tax_id || "")
        setTax_name(get_sor_item.tax_name || "")
        setTax_value(parseFloat(get_sor_item.tax_value || 0))

        setUnitrate(parseFloat(get_sor_item.unitrate || 0) || "")
        setQuantity(parseFloat(get_sor_item.quantity || 1))

    }

    const LoadScheduleRatesItems = async () => {

        setIsLoading(true)

        let response = await scheduleRatesHandler.getItemsHandler()
        setIsLoading(false)

        if (response.success) {

            let { data } = response

            if (!Array.isArray(data)) return

            let sor_item_types = {
                material: "1",
                labour: "2",
                machine: "3",
                subcontractor: "4",
                fee: "5",
                allowance: "6",
            }

            let sor_options = data.filter(d => d.type == '3' && sor_item_types[header_option] == d.item_type).map(d => {
                return {
                    value: d.id,
                    label: d.name,
                    type: d.item_type,
                    type: d.item_type,
                    isDefault: false
                }
            })

            setSOR_items_Orginal(data)
            setSOR_items_Options(sor_options)


        }
    }

    useEffect(() => {

        if (store && store.user) {

            let { company, selected_project, clients, settings } = store.user

            if (selected_project) {
                let { id, estimate_id } = selected_project

                if (estimate_id) setEstimate_id(estimate_id)
                if (id) setProject_id(id)
            }

            if (company) {

                let { currency_setting, timezone, temperature_setting } = company

                if (currency_setting) setCurrency_type(currency_setting)
            }



            if (settings && Array.isArray(settings.field_settings) && settings.field_settings.length) {

                let { units } = settings.field_settings[0]

                units = units.filter(opt => opt.isActive).map((opt) => {
                    return {
                        value: opt.id,
                        label: opt.name,
                        isDefault: opt.isDefault || false
                    }
                })

                setUnitsOptions(units || [])

            }

            if (settings && Array.isArray(settings.finance_settings) && settings.finance_settings.length) {

                let { taxes, markups, overheads, insurance } = settings.finance_settings[0]

                taxes = taxes.filter(opt => opt.isActive).map((opt) => {
                    return {
                        id: opt.id,
                        value: parseFloat(opt.value || 0),
                        label: opt.name,
                        type: opt.type,
                        isDefault: opt.isDefault || false
                    }
                })

                setTaxesOptions(taxes || [])


                markups = markups.filter(opt => opt.isActive).map((opt) => {
                    return {
                        id: opt.id,
                        value: parseFloat(opt.value || 0),
                        label: opt.name,
                        type: opt.value_type,
                        isDefault: opt.isDefault || false
                    }
                })

                setMarkupsOptions(markups || [])
            }


        }

    }, [store])

    const getEstimateItemTypeEenemReverse = (type) => {

        if (type == 'material') return 1
        else if (type == 'labour') return 2
        else if (type == 'machine') return 3
        else if (type == 'subcontractor') return 4
        else if (type == 'fee') return 5
        else if (type == 'allowance') return 6
        else return 0

    }


    const getEstimateItemStatusEenemReverse = (type) => {

        if (type == 'incomplete') return 0
        else if (type == 'completed') return 1
        else if (type == 'hold') return 2
        else if (type == 'stopped') return 3
        else return -1

    }

    const HandlePopupSubmit = async (e) => {
        e.preventDefault()


        let updated_data = {
            id: isExistItemData ? existItemData.id : Utils.getUniqueId(),
            name: name,
            estimate_id: estimate_id,
            projectId: projectId,
            status: getEstimateItemStatusEenemReverse(status),

            unit_id, unit_id,
            unit_name, unit_name,
            unitrate: String(unitrate),
            quantity: String(quantity),

            parent_id: group_id || undefined,
            parent_name: group || undefined,

            sor_id: SOR_item_id || undefined,
            sor_name: SOR_item || undefined,

            co_efficient: String(co_efficient),
            per_co_efficient_cost: String(per_co_efficient_cost),

            tax_id: tax_id,
            tax_name: tax_name,
            tax_value: String(tax_value),
            tax_amount: String(unti_tax),


            markup_id: markup_id,
            markup_name: markup_name,
            markup_value: String(markup_value),
            markup_value_type: markup_type,
            markup_amount: String(unti_markup),

            expanded: false,
            builder_price: String(unitrate * quantity * MainQty),
            price: String(price),
            type: 3,
            item_type: getEstimateItemTypeEenemReverse(AddBtnOption.id),
            type_option: AddBtnOption,
            description: description,
        }


        if (typeof updateCallback == 'function' && updated_data.id) updateCallback(updated_data, type)

        close()


    }


    const Popup_Header = ({ props }) => {

        const { icon, label } = props
        return (
            <div className="side-popup-header">
                <div className="header-item-select">
                    <div className="header-item-select-content">
                        <span className="icon" dangerouslySetInnerHTML={{ __html: icon }}></span>
                        <div className="label">{type == 'edit' ? 'Update' : 'Add'} {label || "Item"}</div>

                    </div>
                </div>
                <div
                    className="header-item-close"
                    onClick={(e) => close()}
                    dangerouslySetInnerHTML={{ __html: Icons.general.close }}
                ></div>
            </div>
        );
    };
    const Popup_Footer = ({ props }) => {

        const { icon, label } = props

        return (
            <div className="sidebar-popup-footer">
                <div className="footer-item action-items">
                    <div className="action-preview">
                        <div className="label">{label} Price</div>
                        <div className="value">{currency_type}{price || '0.00'}</div>
                    </div>
                    <button
                        className="action-btn"
                        type='submit'
                    >
                        <div className="icon" dangerouslySetInnerHTML={{ __html: type == 'edit' ? Icons.general.save : Icons.general.add_btn }}></div>
                        <div className="label">{type == 'edit' ? 'Save' : 'Add Item'}</div>
                    </button>
                </div>
            </div>
        );
    };



    useEffect(() => {

        LoadStoreData()
        LoadScheduleRatesItems()

        if (type == 'edit' && isExistItemData) {

            setId(existItemData.id)

            setSOR_item_id(existItemData.sor_id || '')
            setSOR_item(existItemData.sor_name || '')

            setName(existItemData.name || '')

            setGroup_id(existItemData.parent_id || '')
            setGroup(existItemData.parent_name || '')

            setUnit_id(existItemData.unit_id || '')
            setUnit_name(existItemData.unit_name || '')

            setStatus(existItemData.status)
            setQuantity(parseFloat(existItemData.quantity || 0) || '')
            setUnitrate(parseFloat(existItemData.unitrate || 0) || '')
            setDescription(existItemData.description || '')

            setAdditionalNotes(existItemData.description ? true : false)

            setTax_id(existItemData.tax_id || '')
            setTax_name(existItemData.tax_name || '')
            setTax_value(parseFloat(existItemData.tax_value || 0) || 0)

            setMarkup_id(existItemData.markup_id || '')
            setMarkup_name(existItemData.markup_name || '')
            setMarkup_value(parseFloat(existItemData.markup_value || 0) || 0)
            setMarkup_type(existItemData.markup_value_type)

            setCo_efficient(parseFloat(existItemData.co_efficient || 0) || 0)
            setPer_co_efficient_cost(parseFloat(existItemData.per_co_efficient_cost || 0) || 0)

        }


    }, [])

    return (

        <div className="popup-container-main propup-container-center">
            <div className="popup-block-ui"></div>
            <form className="side-popup-container animateXRight animateXRightactive" onSubmit={HandlePopupSubmit}>
                <Popup_Header props={AddBtnOption} />

                <div className="sidebar-popup-content">
                    <div className="content-section">

                        <div className="content-item">
                            <SelectInput
                                props={{
                                    id: "estimate-work-scheduleitem",
                                    value: SOR_item_id,
                                    placeholder: '',
                                    options: SOR_items_Options || [],
                                    setValue: (value, label) => { },
                                    isIcon: false,
                                    isLabel: true,
                                    readOnly: true,
                                    label: "Schedule Item",
                                }}
                            />
                        </div>

                        <div className="content-item">
                            <TextInput
                                props={{
                                    id: "estimate-work-name",
                                    value: name,
                                    placeholder: '',
                                    setValue: (value) => {
                                        setName(value)
                                    },
                                    isIcon: false,
                                    isLabel: true,
                                    isRequired: true,
                                    label: "Name",
                                }}
                            />
                        </div>
                        <div className="content-item">
                            <TextInput
                                props={{
                                    id: "costcatalog-work-quantity",
                                    value: quantity,
                                    placeholder: '',
                                    setValue: (value) => { },
                                    isIcon: false,
                                    type: 'number',
                                    isLabel: true,
                                    readOnly: true,
                                    label: "Quantity",
                                }}
                            />
                        </div>
                        <div className="content-item">
                            <TextInput
                                props={{
                                    id: "estimate-work-co_efficient",
                                    value: co_efficient,
                                    placeholder: '',
                                    setValue: (value) => { },
                                    isIcon: false,
                                    type: 'number',
                                    isLabel: true,
                                    readOnly: true,
                                    label: "Co-efficient",
                                }}
                            />
                        </div>
                        <div className="content-item">

                            <div className="content-sub-item">
                                <SelectInput
                                    props={{
                                        id: "estimate-work-unit",
                                        value: unit_id,
                                        placeholder: '',
                                        readOnly: false,
                                        options: unitsOptions || [],
                                        setValue: (value, label) => { },
                                        readOnly: true,
                                        isIcon: false,
                                        isLabel: true,
                                        label: "Unit type",
                                    }}
                                />
                            </div>

                            <div className="content-sub-item">
                                <CostInput
                                    props={{
                                        id: "estimate-work-unitrate",
                                        value: unitrate,
                                        isCostMethod: false,
                                        currency_type: currency_type,
                                        readOnly: false,
                                        placeholder: '',
                                        setValue: (value) => {
                                            setUnitrate(value)
                                        },
                                        isIcon: true,
                                        icon: currency_type,
                                        isLabel: true,
                                        isRequired: true,
                                        label: "Unit rate",
                                    }}
                                />
                            </div>

                        </div>
                        <div className="content-item">
                            <CostInput
                                props={{
                                    id: "costcatalog-work-price",
                                    value: per_co_efficient_cost,
                                    currency_type: currency_type,
                                    isCostMethod: false,
                                    placeholder: '',
                                    readOnly: true,
                                    setValue: (value) => {
                                        // setPrice(value)
                                    },
                                    isIcon: true,
                                    icon: currency_type,
                                    isLabel: true,
                                    readOnly: true,
                                    isRequired: false,
                                    label: "Per co-efficient cost",
                                }}
                            />
                        </div>
                    </div>
                    <div className="content-section">
                        <div className="content-section-header">
                            <div className="label">Pricing</div>
                        </div>

                        <div className="content-section-header">
                            <div className="label">Tax</div>
                            <div className="input">
                                <select
                                    name=""
                                    id=""
                                    onInput={(e) => {

                                        let tax = taxesOptions?.filter(t => t.id == e.target.value)[0]

                                        if (tax) {

                                            setTax_id(tax.id)
                                            setTax_value(parseFloat(tax.value || 0))
                                            setTax_name(tax.label)
                                        }
                                    }}
                                    value={tax_id}
                                >
                                    {taxesOptions?.map((tax, i) => (
                                        <option value={`${tax.id}`}>{tax.label}</option>
                                    ))}
                                </select>
                            </div>
                        </div>
                        <div className="content-item">
                            <div className="content-sub-item">
                                <CostInput
                                    props={{
                                        value: tax_value,
                                        isCostMethod: false,
                                        currency_type: currency_type,
                                        placeholder: 'Unit cost',
                                        setValue: (value) => {
                                            setTax_value(parseFloat(value) || 0)
                                        },
                                        isIcon: true,
                                        icon: '%',
                                        isLabel: true,
                                        isRequired: true,
                                        label: "Tax",
                                        id: "estimate-item-tax"
                                    }}
                                />
                            </div>
                            <div className="content-sub-item content-sub-label-item">
                                <div className="content-sub-label-content">
                                    <div className="label">Unit tax</div>
                                    <div className="value">{currency_type}{unti_tax}</div>
                                </div>
                            </div>

                        </div>

                        <div className="content-section-header">
                            <div className="label">Markup</div>
                            <div className="input">
                                <select
                                    name=""
                                    id=""
                                    onInput={(e) => {

                                        let item = markupsOptions.filter(itm => itm.id == e.target.value)[0]
                                        if (item) {
                                            setMarkup_value(parseFloat(item.value || 0))
                                            setMarkup_id(item.id)
                                            setMarkup_name(item.label)
                                            setMarkup_type(item.type)
                                        }
                                    }}
                                    value={markup_id}
                                >
                                    {markupsOptions.map((itm, i) => (
                                        <option value={`${itm.id}`}>{itm.label}</option>
                                    ))}
                                </select>
                            </div>
                        </div>
                        <div className="content-item">
                            <div className="content-sub-item">
                                <CostInput
                                    props={{
                                        value: markup_value,
                                        costMethodValue: markup_type,
                                        isCostMethod: true,
                                        currency_type: currency_type,
                                        placeholder: 'Unit cost',
                                        setValue: (value, type) => {
                                            setMarkup_value(parseFloat(value) || 0)
                                            if (type) setMarkup_type(type)
                                        },
                                        isIcon: true,
                                        icon: '%',
                                        isLabel: true,
                                        isRequired: true,
                                        label: "Markup",
                                        id: "estimate-item-markup"
                                    }}
                                />
                            </div>
                            <div className="content-sub-item content-sub-label-item">
                                <div className="content-sub-label-content">
                                    <div className="label">Unit markup</div>
                                    <div className="value">{currency_type}{unti_markup}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="content-section">
                        <div className="content-item">
                            <div id="estimate-description" className={`additional-item ${additionalNotes ? 'additional-item-active' : ''}`}>
                                <div className="head" onClick={(e) => setAdditionalNotes(!additionalNotes)}>
                                    <div className="icon" dangerouslySetInnerHTML={{ __html: Icons.general.description }}></div>
                                    <div className="label">Description</div>
                                </div>
                                <div className="item-expanded">
                                    <ReactQuill
                                        theme="snow"
                                        value={description}
                                        onChange={(value) => setDescription(value)}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <Popup_Footer props={AddBtnOption} />
            </form>
        </div>
    )

}

const AddWork = ({ props }) => {

    const { existItemData, updateCallback } = props

    const { projectId, itemType, itemId, actionType } = useParams()

    const [isLoading, setIsLoading] = useState(false)
    const [warningAlert, setWarningAlert] = useState(false)
    const [warningAlertType, setWarningAlertType] = useState('warning')
    const [apiFailedMessage, setApiFailedMessage] = useState("Error in Server")

    const estimateHandler = new EstimateHandler()
    const workRatesHandler = new WorkRatesHandler()

    const [currency_type, setCurrency_type] = useState('₹')

    const navigate = useNavigate()
    const store = useSelector((store) => store)
    const dispatch = useDispatch()
    const { updateState } = new UserAction

    const storeHandler = new StoreHandler({
        dataStack: ['costcodes', 'company', 'field_settings', 'finance_settings', `project-${projectId}`, `project_phases-${projectId}`],
        store: store,
        dispatch: dispatch,
        updateState: updateState,
    })

    const LoadStoreData = async () => {
        setIsLoading(true)
        let resolved = await storeHandler.init()
        setIsLoading(false)
    }

    const AddBtnOptions = [
        {
            icon: Icons.general.group,
            label: 'Group',
            type: 'group',
            id: "group"
        },
        {
            icon: Icons.general.work,
            label: 'Work Template',
            type: 'work_template',
            id: 'work_template'
        },
        {
            icon: Icons.general.labour,
            label: 'Labour',
            type: 'item',
            id: "labour"
        },
        {
            icon: Icons.general.material,
            label: 'Materials',
            type: 'item',
            id: "material"
        },
        {
            icon: Icons.general.machines,
            label: 'Machines',
            type: 'item',
            id: "machine"
        },
        {
            icon: Icons.general.subcontractor,
            label: 'Subcontractor',
            type: 'item',
            id: "subcontractor"
        },
        {
            icon: Icons.general.fee,
            label: 'Fee',
            type: 'item',
            id: "fee"
        },
        {
            icon: Icons.general.allowance,
            label: 'Allowances',
            type: 'item',
            id: "allowance"
        },
        {
            icon: Icons.general.work,
            label: 'Work',
            type: 'work',
            id: 'work'
        },
    ]

    const [workRate_Items_Orginal, setWorkRate_Items_Orginal] = useState([])
    const [workRate_Items_Options, setWorkRate_Items_Options] = useState([])

    const [workRate_id, setWorkRate_id] = useState('')
    const [workRate_name, setWorkRate_name] = useState('')



    const getWorkRateItemTypeEenem = (type) => {
        if (type == "1") return "group";
        else if (type == "2") return 'work_template';
        else if (type == "3") return "item";
        else if (type == "4") return 'work';
        else return "";
    };
    const getWorkRateItemCategoryEenem = (type) => {
        if (type == "1") return "material";
        else if (type == "2") return "labour";
        else if (type == "3") return "machine";
        else if (type == "4") return "subcontractor";
        else if (type == "5") return "fee";
        else if (type == "6") return "allowance";
        else return "";
    };
    const getWorkRateItemStatusEenem = (type) => {
        if (type == "0") return "incomplete";
        else if (type == "1") return "completed";
        else if (type == "2") return "hold";
        else if (type == "3") return "stopped";
        else return "";
    };

    const getSubItem = (items, parentid) => {
        let subItems = [];

        let getItems = (items, parentid) => {
            if (!Array.isArray(items)) return;

            let _items = items.filter((itm) => itm.parent_id == parentid);

            subItems.push(..._items);

            _items.forEach((itm) => getItems(items, itm.id));
        };

        getItems(items, parentid);

        return subItems;
    };

    const getFormatedWorkRateItem = (item) => {
        return {
            sno: item.sno,
            expanded: true,
            selected: false,
            disable: false,

            parent_id: item.parent_id,
            parent_name: item.parent_name,
            id: item.id,
            name: item.name,
            description: item.description,

            sor_id: item.sor_id || "",
            sor_name: item.sor_name || "",

            co_efficient: parseFloat(item.co_efficient || 0) || 0,
            per_co_efficient_cost: parseFloat(item.per_co_efficient_cost || 0) || 0,

            type: getWorkRateItemTypeEenem(item.type),
            item_type:
                item.type != "3"
                    ? getWorkRateItemTypeEenem(item.type)
                    : getWorkRateItemCategoryEenem(item.item_type),
            cost_type:
                item.type != "1" && item.type != "3"
                    ? getWorkRateItemTypeEenem(item.type)
                    : getWorkRateItemCategoryEenem(item.item_type),

            parent_type: item.parent_type,
            parent_quantity: parseFloat(item.parent_quantity || 0),

            _status: item.status,
            status: getWorkRateItemStatusEenem(item.status),

            quantity: parseFloat(item.quantity || 0) || 0,
            unitrate: parseFloat(item.unitrate || 0) || 0,
            builder_price: parseFloat(item.builder_price || 0) || 0,
            price: parseFloat(item.price || 0) || 0,

            unit_id: item.unit_id || "",
            unit_name: item.unit_name || "",

            markup_id: item.markup_id || "",
            markup_name: item.markup_name || "",
            markup_value: parseFloat(item.markup_value || 0) || 0,
            markup_value_type: item.markup_value_type || "",
            markup_amount: parseFloat(item.markup_amount || 0) || 0,

            tax_id: item.tax_id || "",
            tax_name: item.tax_name || "",
            tax_value_type: item.tax_value_type || "",
            tax_value: parseFloat(item.tax_value || 0) || 0,
            tax_amount: parseFloat(item.tax_amount || 0) || 0,

            sub_items: [],
        };
    };

    const getWorkRateItems = async () => {

        let response = await workRatesHandler.getWorkRatesItemsHandler()

        if (response.success) {

            let { data } = response

            if (!Array.isArray(data)) return

            let items = data.map((d) => {
                let item = getFormatedWorkRateItem(d);
                let subItems = getSubItem(data, d.id).map((itm) =>
                    getFormatedWorkRateItem(itm)
                );

                let tax_amount = item.tax_amount;
                let markup_amount = item.markup_amount;
                let builder_price = item.builder_price;
                let price = item.price;

                let subItems_Total = subItems.reduce(
                    (prev, val, ind) => {
                        return {
                            tax_amount: prev.tax_amount + val.tax_amount,
                            markup_amount: prev.markup_amount + val.markup_amount,
                            builder_price: prev.builder_price + val.builder_price,
                            price: prev.price + val.price,
                        };
                    },
                    { tax_amount: 0, markup_amount: 0, builder_price: 0, price: 0 }
                );

                tax_amount = parseFloat(
                    tax_amount + (subItems_Total.tax_amount || 0)
                ).toFixed(2);
                markup_amount = parseFloat(
                    markup_amount + (subItems_Total.markup_amount || 0)
                ).toFixed(2);
                builder_price = parseFloat(
                    builder_price + (subItems_Total.builder_price || 0)
                ).toFixed(2);
                price = parseFloat(price + (subItems_Total.price || 0)).toFixed(2);

                if (item.parent_id) {
                    let parent_item = data.filter((d) => d.id == item.parent_id)[0];
                    if (parent_item) {
                        item.parent_type = parent_item.type;
                        if (parent_item.type == "4")
                            item.parent_quantity = parent_item.quantity;
                    }
                }

                item._tax_amount = tax_amount || "";
                item._tax_percent = ((tax_amount / builder_price) * 100 || 0).toFixed(
                    2
                );
                item._markup_amount = markup_amount || "";
                item._markup_percent = (
                    (markup_amount / builder_price) * 100 || 0
                ).toFixed(2);
                item._builder_price = builder_price || "";
                item._price = price || "";
                item._unitrate = item.unitrate || "";
                item._quantity = item.quantity || "";

                item.tax_amount = tax_amount ? `${currency_type}${tax_amount}` : "";
                item.markup_amount = markup_amount
                    ? `${currency_type}${markup_amount}`
                    : "";
                item.builder_price = builder_price
                    ? `${currency_type}${builder_price}`
                    : "";
                item.price = price ? `${currency_type}${price}` : "";
                item.unitrate = item.unitrate
                    ? `${currency_type}${item.unitrate}`
                    : "";
                item.quantity = item.quantity
                    ? `${item.quantity}${item.unit_name || ""}`
                    : "";

                if (item.type == 'work') {
                    let cost_lines =
                        getSubItem(data, d.id).map((itm) =>
                            getFormatedWorkRateItem(itm)
                        ) || [];

                    cost_lines = cost_lines.map((item) => {
                        let type_option = AddBtnOptions.filter((opt) => {
                            if (opt.type != "item" && item.type == opt.type) return true;
                            else if (opt.type == "item" && item.item_type == opt.id)
                                return true;
                            else return false;
                        });

                        type_option = type_option.length ? type_option[0] : undefined;
                        item.type_option = type_option;

                        return item;
                    });

                    item.cost_lines = cost_lines;
                }
                return item;
            }).filter((item) => item.type == 'work')

            let workRate_options = items.map(itm => {
                return {
                    value: itm.id,
                    label: itm.name
                }
            })

            setWorkRate_Items_Orginal(items);
            setWorkRate_Items_Options(workRate_options);
        }
    }

    const HandleWorkRateSelect = (id, label) => {
        setWorkRate_id(id)
        setWorkRate_name(label)
        setCostcode_id(id)
        setCostcode_name(label)

        let get_work_item = workRate_Items_Orginal.filter(s => s.id == id)[0]

        if (!get_work_item) return

        setName(get_work_item.name || '')
        setAdditionalNotes(get_work_item.description ? true : false)
        setDescription(get_work_item.description || '')
        setGroup_id(get_work_item.parent_id || '')
        setGroup(get_work_item.parent_name || '')
        setQuantity(get_work_item._quantity || '')
        setUnit_id(get_work_item.unit_id || '')
        setUnit_name(get_work_item.unit_name || '')

        let cost_lines = get_work_item.cost_lines.map(item => {
            item.id = Utils.getUniqueId()
            return item
        })

        setCost_Lines(cost_lines || [])

        calculateTotalSummary()

    }

    const getEstimateGroups = async () => {

        let filter = {
            type: 1,
            projectId: projectId
        }

        let response = await estimateHandler.getEstimateItemsHandler(filter)

        if (response.success) {

            let { data } = response

            if (!Array.isArray(data)) return

            let getSubItemsIds = (items, parentid) => {

                let subGroupIds = []

                let getItems = (items, parentid) => {

                    if (!Array.isArray(items)) return

                    let _items = items.filter(itm => itm.parent_id == parentid)

                    subGroupIds.push(..._items)

                    _items.forEach(itm => getItems(items, itm.id))
                }

                getItems(items, parentid)

                subGroupIds = subGroupIds.map(g => g.id)

                subGroupIds.push(parentid)

                return subGroupIds
            }

            if (isExistItemData) {

                data = data.filter(d => !getSubItemsIds(data, existItemData.id).includes(d.id))
            }

            data = data.filter(d => d.type == '1').map(d => {

                return {
                    value: d.id,
                    label: d.name,
                }

            })



            setGroupOptions(data)
        }
    }

    const [project_id, setProject_id] = useState('')
    const [estimate_id, setEstimate_id] = useState('')

    const [group_id, setGroup_id] = useState('')
    const [group, setGroup] = useState('')

    const [costcode_id, setCostcode_id] = useState('')
    const [costcode_name, setCostcode_name] = useState('')

    const [id, setId] = useState('')

    const [builderPrice, setBuilderPrice] = useState(0)
    const [price, setPrice] = useState(0)
    const [totalTaxes, setTotalTaxes] = useState(0)
    const [totalMarkups, setTotalMarkups] = useState(0)
    const [name, setName] = useState('')
    const [unit_id, setUnit_id] = useState('')
    const [unit_name, setUnit_name] = useState('')
    const [quantity, setQuantity] = useState('')
    const [description, setDescription] = useState('')

    const [sideItemShowPopup, setSideItemShowPopup] = useState(false)
    const [sideItemType, setSideItemType] = useState('add')
    const [sideItemHeader, setSideItemHeader] = useState({})
    const [sideItemExistDetials, setSideItemExistDetials] = useState({})
    const [Cost_Lines, setCost_Lines] = useState([]);
    const [additionalNotes, setAdditionalNotes] = useState(false);

    const isExistItemData = existItemData && existItemData.id != undefined

    const [statusOptions, setStatusOptions] = useState([
        {
            value: "incomplete",
            label: "Incomplete"
        },
        {
            value: "completed",
            label: "Completed"
        },
        {
            value: "hold",
            label: "Hold"
        },
        {
            value: "stopped",
            label: "Stopped"
        },
    ])
    const [costcodeOptions, setCostcodeOptions] = useState([])
    const [unitsOptions, setUnitsOptions] = useState([])
    const [taxesOptions, setTaxesOptions] = useState([])
    const [markupsOptions, setMarkupsOptions] = useState([])
    const [groupOptions, setGroupOptions] = useState([])

    const [ProjectPhases, setProjectPhases] = useState([])
    const [ProjectPhases_Options, setProjectPhases_Options] = useState([])

    const [Phase_id, setPhase_id] = useState('')
    const [Phase_name, setPhase_name] = useState('')

    const getEstimateItemTypeEenem = (type) => {

        if (type == '1') return 'group'
        else if (type == '2') return 'work_template'
        else if (type == '3') return 'item'
        else if (type == '4') return 'work'
        else return ''

    }
    const getEstimateItemCategoryEenem = (type) => {

        if (type == '1') return 'material'
        else if (type == '2') return 'labour'
        else if (type == '3') return 'machine'
        else if (type == '4') return 'subcontractor'
        else if (type == '5') return 'fee'
        else if (type == '6') return 'allowance'
        else return ''

    }
    const getEstimateItemStatusEenem = (type) => {

        if (type == '0') return 'incomplete'
        else if (type == '1') return 'completed'
        else if (type == '2') return 'hold'
        else if (type == '3') return 'stopped'
        else return ''
    }

    const getFormatedEstimateItem = (item) => {

        return {
            sno: item.sno,
            expanded: true,
            selected: false,
            disable: false,

            parent_id: item.parent_id,
            parent_name: item.parent_name,

            sor_id: item.sor_id,
            sor_name: item.sor_name,


            co_efficient: parseFloat(item.co_efficient || 0) || 0,
            per_co_efficient_cost: parseFloat(item.per_co_efficient_cost || 0) || 0,

            work_id: item.work_id,
            work_name: item.work_name,

            id: item.id,
            name: item.name,
            description: item.description,

            costcode_id: item.costcode_id || "",
            costcode_name: item.costcode_name || "",

            type: getEstimateItemTypeEenem(item.type),
            item_type: item.type != '3' ? getEstimateItemTypeEenem(item.type) : getEstimateItemCategoryEenem(item.item_type),
            cost_type: item.type != '1' && item.type != '3' ? getEstimateItemTypeEenem(item.type) : getEstimateItemCategoryEenem(item.item_type),
            _status: item.status,
            status: getEstimateItemStatusEenem(item.status),

            parent_type: item.parent_type,
            parent_quantity: parseFloat(item.parent_quantity || 0),

            quantity: parseFloat(item.co_efficient || 0) * parseFloat(quantity || 0) || 0,
            unitrate: parseFloat(item.unitrate || 0) || 0,
            builder_price: parseFloat(item.builder_price || 0) || 0,
            price: parseFloat(item.price || 0) || 0,


            unit_id: item.unit_id || "",
            unit_name: item.unit_name || "",

            markup_id: item.markup_id || "",
            markup_name: item.markup_name || "",
            markup_value: parseFloat(item.markup_value || 0) || 0,
            markup_value_type: item.markup_value_type || "",
            markup_amount: parseFloat(item.markup_amount || 0) || 0,

            tax_id: item.tax_id || "",
            tax_name: item.tax_name || "",
            tax_value_type: item.tax_value_type || "",
            tax_value: parseFloat(item.tax_value || 0) || 0,
            tax_amount: parseFloat(item.tax_amount || 0) || 0,

            sub_items: []
        }

    }

    const LoadEstimateItem = async (idx) => {

        if (idx == undefined) return

        let filter = {
            projectId: projectId,
            idx: idx
        }

        setIsLoading(true)

        let response = await estimateHandler.getEstimateItemsHandler(filter)
        setIsLoading(false)

        if (response.success) {


            let { data } = response


            if (!Array.isArray(data) || !data.length) return


            let items = data.map(d => {

                let item = getFormatedEstimateItem(d)

                let tax_amount = item.tax_amount
                let markup_amount = item.markup_amount
                let builder_price = item.builder_price
                let price = item.price

                item._tax_amount = tax_amount || ""
                item._tax_percent = ((tax_amount / builder_price) * 100 || 0).toFixed(2)
                item._markup_amount = markup_amount || ""
                item._markup_percent = ((markup_amount / builder_price) * 100 || 0).toFixed(2)
                item._builder_price = builder_price || ""
                item._price = price || ""
                item._unitrate = item.unitrate || ""
                item._quantity = item.quantity || ""

                item.tax_amount = tax_amount ? `${currency_type}${tax_amount}` : ""
                item.markup_amount = markup_amount ? `${currency_type}${markup_amount}` : ""
                item.builder_price = builder_price ? `${currency_type}${builder_price}` : ""
                item.price = price ? `${currency_type}${price}` : ""
                item.unitrate = item.unitrate ? `${currency_type}${item.unitrate}` : ""
                item.quantity = item.quantity ? `${item.quantity}${item.unit_name || ""}` : ""

                return item

            })

            let item = items[0]

            setId(item.id)

            setName(item.name || '')

            setGroup_id(item.parent_id || '')
            setGroup(item.parent_name || '')

            setCostcode_id(item.costcode_id || '')
            setCostcode_name(item.costcode_name || '')

            setUnit_id(item.unit_id || '')
            setUnit_name(item.unit_name || '')

            setQuantity(parseFloat(item._quantity || 0) || '')
            setDescription(item.description || '')

            setAdditionalNotes(item.description ? true : false)


            let cost_line_filter = {
                projectId: projectId,
                parent_id: item.id
            }

            let cost_lines_response = await estimateHandler.getEstimateItemsHandler(cost_line_filter)


            if (cost_lines_response.success && Array.isArray(cost_lines_response.data) && cost_lines_response.data) {

                let cost_lines = cost_lines_response.data.map(item => {

                    item = getFormatedEstimateItem(item)

                    let type_option = AddBtnOptions.filter(opt => {

                        if (opt.type != 'item' && item.type == opt.type) return true
                        else if (opt.type == 'item' && item.item_type == opt.id) return true
                        else return false
                    })

                    type_option = type_option.length ? type_option[0] : undefined
                    item.type_option = type_option

                    return item

                })

                setCost_Lines(cost_lines)
            }

        }
        else {
            setWarningAlert(true)
            setWarningAlertType('error')
            setApiFailedMessage(`Error in fetching estimate item`)
        }
    }



    const getEstimateItemTypeEenemReverse = (type) => {

        if (type == 'material') return 1
        else if (type == 'labour') return 2
        else if (type == 'machine') return 3
        else if (type == 'subcontractor') return 4
        else if (type == 'fee') return 5
        else if (type == 'allowance') return 6
        else return 0

    }
    const getEstimateItemStatusEenemReverse = (type) => {

        if (type == 'incomplete') return 0
        else if (type == 'completed') return 1
        else if (type == 'hold') return 2
        else if (type == 'stopped') return 3
        else return -1

    }
    const FormatCost_Lines = (Cost_Lines) => {

        if (!Array.isArray(Cost_Lines)) return []

        return Cost_Lines.map(item => {
            return {

                id: item.id,
                name: item.name,
                estimate_id: estimate_id,
                projectId: projectId,
                status: getEstimateItemStatusEenemReverse(item.status),

                unit_id: item.unit_id,
                unit_name: item.unit_name,
                unitrate: String(item.unitrate),
                quantity: String(parseFloat(item.co_efficient || 0) * parseFloat(quantity || 0)),

                parent_id: item.group_id || undefined,
                parent_name: item.group || undefined,

                co_efficient: String(item.co_efficient),
                per_co_efficient_cost: String(item.per_co_efficient_cost),

                sor_id: item.sor_id || undefined,
                sor_name: item.sor_name || undefined,

                tax_id: item.tax_id,
                tax_name: item.tax_name,
                tax_value: String(item.tax_value),
                tax_amount: String(item.tax_amount),


                markup_id: item.markup_id,
                markup_name: item.markup_name,
                markup_value: String(item.markup_value),
                markup_value_type: item.markup_value_type,
                markup_amount: String(item.markup_amount),

                builder_price: String(item.builder_price),
                price: String(item.price),
                type: 3,
                item_type: getEstimateItemTypeEenemReverse(item.type_option.id),
                description: item.description,
            }
        })
    }

    const HandlePopupSubmit = async (e) => {
        e.preventDefault()

        let updated_data = {
            projectId: projectId,
            estimate_id: estimate_id,
            name,
            parent_id: group_id || undefined,
            parent_name: group || undefined,

            phase_id: String(Phase_id || ""),
            phase_name: Phase_name || "",

            costcode_id: costcode_id,
            costcode_name: costcode_name,

            work_id: workRate_id || "",
            work_name: workRate_name || "",

            quantity: String(quantity),
            unit_id: unit_id,
            unit_name: unit_name,
            type: 4,
            expanded: false,
            description,

            cost_lines: FormatCost_Lines(Cost_Lines)

        }

        setIsLoading(true)

        let response = { success: false }

        if (actionType == 'edit' && isExistItemData && existItemData.id) {
            updated_data['id'] = existItemData.id
            response = await estimateHandler.updateEstimateGroupHandler(updated_data)
        }
        else if (actionType == 'add') response = await estimateHandler.createEstimateGroupHandler(updated_data)

        setIsLoading(false)

        if (response.success) {

            if (typeof updateCallback == 'function') updateCallback()
            navigate(`/builder/projects/${projectId}/estimate`)
        }
        else {
            setWarningAlert(true)
            setWarningAlertType('error')
            setApiFailedMessage(`Error in estimate item`)
        }


    }


    const Popup_Header = ({ props }) => {

        return (
            <div className="side-popup-header">
                <div className="header-item-select">
                    <div className="header-item-select-content">
                        <span className="icon" dangerouslySetInnerHTML={{ __html: Icons.general.work }}></span>
                        <div className="label">{actionType == 'edit' ? 'Update' : 'Add'} Work</div>
                    </div>
                </div>
                <div
                    className="header-item-close"
                    onClick={(e) => {
                        navigate(`/builder/projects/${projectId}/estimate`)
                    }}
                    dangerouslySetInnerHTML={{ __html: Icons.general.close }}
                ></div>
            </div>
        );
    };
    const Popup_Footer = ({ props }) => {

        return (
            <div className="sidebar-popup-footer">
                <div className="footer-item action-items">
                    <div className="footer-item-left">

                        <div className="action-preview">
                            <div className="label">Prime cost</div>
                            <div className="value">{currency_type}{parseFloat(builderPrice || 0).toFixed(2)}</div>
                        </div>
                        <div className="action-preview">
                            <div className="label">Taxes | Markups</div>
                            <div className="value">{currency_type}{parseFloat(totalTaxes || 0).toFixed(2)} | {currency_type}{parseFloat(totalMarkups).toFixed(2) || '0.00'}</div>
                        </div>

                        <div className="action-preview">
                            <div className="label">Final price</div>
                            <div className="value">{currency_type}{parseFloat(price).toFixed(2) || '0.00'}</div>
                        </div>
                    </div>
                    <button
                        className="action-btn"
                        type='submit'
                    >

                        <div className="label">Save</div>
                    </button>
                </div>
            </div>
        );
    };

    const HandleAddWorkItem = (item) => {

        setSideItemHeader(item)

        setSideItemType('add')
        setSideItemShowPopup(true)

    }
    const HandleRemoveWorkItem = (index) => {
        let _Cost_Lines = [...Cost_Lines]

        if (_Cost_Lines.length) _Cost_Lines.splice(index, 1)

        setCost_Lines(_Cost_Lines)
    }
    const HandleOpenItem = (item, index) => {

        setSideItemHeader(item.type_option)
        setSideItemType('edit')
        setSideItemExistDetials(item)

        setSideItemShowPopup(true)
    }
    const HandleCreateWorkItem = (data, type = 'add') => {

        if (type == 'add') setCost_Lines([...Cost_Lines, data])
        else if (type == 'edit') {

            let _items = [...Cost_Lines]

            let exist_item_index = _items.findIndex(itm => itm.id == data.id)

            if (exist_item_index != -1) {
                _items[exist_item_index] = data
                setCost_Lines(_items)
            }
        }
        setSideItemShowPopup(false)
    }
    const HandleQuantityChange = (value) => {

        setQuantity(value)

        let _items = [...Cost_Lines]

        if (Array.isArray(_items) && _items.length) {

            let __items = _items.map((itm, i) => {

                itm.tax_value = parseFloat(itm.tax_value || 0) || 0
                itm.unitrate = parseFloat(itm.unitrate || 0) || 0
                itm.markup_value = parseFloat(itm.markup_value || 0) || 0
                itm.tax_amount = parseFloat(itm.tax_amount || 0) || 0
                itm.markup_amount = parseFloat(itm.markup_amount || 0) || 0

                let _quantity = value > 0 ? (parseFloat(itm.co_efficient || 0) * parseFloat(value || 0)) : 0

                itm.quantity = _quantity

                itm.builder_price = _quantity * itm.unitrate
                itm.tax_amount = itm.tax_value ? _quantity * itm.unitrate / itm.tax_value / 100 : 0
                itm.markup_amount = itm.markup_value ? itm.markupType == '%' ? ((itm.unitrate * _quantity) * itm.markup_value) / 100 : itm.markup_value : 0
                itm.price = (itm.unitrate * _quantity) + itm.tax_amount + itm.markup_amount

                return itm
            })

            setCost_Lines(__items)
        }

    }
    const HandleCloseSidePopup = () => {

        setSideItemShowPopup(false)
        setSideItemHeader({})
        setSideItemType('add')
        setSideItemExistDetials({})
    }

    const calculateTotalSummary = () => {

        let _items = [...Cost_Lines]


        let _total = _items.reduce((prev, val, i) => {
            return {
                builder_price: prev.price + parseFloat(val.builder_price),
                price: prev.price + parseFloat(val.price),
                markups: prev.markups + parseFloat(val.markup_amount),
                taxes: prev.taxes + parseFloat(val.tax_amount),
            }

        }, {
            price: 0,
            builder_price: 0,
            markups: 0,
            taxes: 0
        })

        setPrice(_total.price)
        setBuilderPrice(_total.builder_price)
        setTotalTaxes(_total.taxes)
        setTotalMarkups(_total.markups)
    }
    useEffect(() => {

        calculateTotalSummary()
    }, [Cost_Lines])

    const HandlePhaseInputChange = (value, label) => {

        let phase = ProjectPhases.filter(phase => phase.id == value)[0]

        if (!phase) return

        setPhase_id(phase.id)
        setPhase_name(phase.name)
    }

    useEffect(() => {

        if (actionType == 'edit' && isExistItemData) {

            setWorkRate_id(existItemData.work_id || '')
            setWorkRate_name(existItemData.work_name || '')

            setCostcode_id(existItemData.costcode_id || '')
            setCostcode_name(existItemData.costcode_name || '')

            setName(existItemData.name || '')
            setAdditionalNotes(existItemData.description ? true : false)
            setDescription(existItemData.description || '')

            setGroup_id(existItemData.parent_id || '')
            setGroup(existItemData.parent_name || '')

            setPhase_id(existItemData.phase_id || '')
            setPhase_name(existItemData.phase_name || '')

            setQuantity(existItemData._quantity || '')
            setUnit_id(existItemData.unit_id || '')
            setUnit_name(existItemData.unit_name || '')
            setCost_Lines(existItemData.cost_lines || [])

            calculateTotalSummary()
        }

        if (actionType == 'edit' && !isExistItemData) LoadEstimateItem(itemId)

        LoadStoreData()
        getEstimateGroups()
        getWorkRateItems()

    }, [])



    useEffect(() => {

        if (store && store.user) {

            let { company, costcodes, selected_project, selected_project_phases, settings } = store.user

            if (selected_project) {
                let { id, estimate_id } = selected_project

                if (estimate_id) setEstimate_id(estimate_id)
                if (id) setProject_id(id)
            }

            if (company) {

                let { currency_setting, timezone, temperature_setting } = company

                if (currency_setting) setCurrency_type(currency_setting)
            }
            if (Array.isArray(costcodes)) {

                costcodes = costcodes.filter(opt => opt.type == '1').map((opt) => {
                    return {
                        value: opt.id,
                        label: opt.name,
                    }
                })

                setCostcodeOptions(costcodes)
            }


            if (settings && Array.isArray(settings.field_settings) && settings.field_settings.length) {

                let { units } = settings.field_settings[0]

                units = units.filter(opt => opt.isActive).map((opt) => {
                    return {
                        value: opt.id,
                        label: opt.name,
                        isDefault: opt.isDefault || false
                    }
                })

                setUnitsOptions(units || [])

            }

            if (settings && Array.isArray(settings.finance_settings) && settings.finance_settings.length) {

                let { taxes, markups, overheads, insurance } = settings.finance_settings[0]

                taxes = taxes.filter(opt => opt.isActive).map((opt) => {
                    return {
                        id: opt.id,
                        value: parseFloat(opt.value || 0),
                        label: opt.name,
                        type: opt.type,
                        isDefault: opt.isDefault || false
                    }
                })

                setTaxesOptions(taxes || [])


                markups = markups.filter(opt => opt.isActive).map((opt) => {
                    return {
                        id: opt.id,
                        value: parseFloat(opt.value || 0),
                        label: opt.name,
                        type: opt.value_type,
                        isDefault: opt.isDefault || false
                    }
                })

                setMarkupsOptions(markups || [])
            }
            if (selected_project_phases) {

                setProjectPhases(selected_project_phases)

                let project_phases = selected_project_phases.map(phase => {
                    return {
                        value: phase.id,
                        label: `${phase.name} | ${phase.client_name}`,
                        isDefault: false
                    }
                })

                setProjectPhases_Options(project_phases || [])

            }


        }

    }, [store])

    return (
        <>

            <div className="popup-container-main popup-container-scroll-center">
                <div className="popup-block-ui"></div>
                <form className="side-popup-container" onSubmit={(e) => {
                    HandlePopupSubmit(e)
                }}>
                    <Popup_Header />

                    <div className="sidebar-popup-content">

                        <div className="content-section-title">GENERAL INFO</div>
                        <div className="content-section content-top-main">

                            <div className="content-top-items">

                                <div className="content-item">
                                    <SelectInput
                                        props={{
                                            id: "estimate-work-template",
                                            value: workRate_id,
                                            placeholder: '',
                                            options: [
                                                {
                                                    value: "",
                                                    label: "Select Work"
                                                }, ...workRate_Items_Options || []
                                            ] || [],
                                            setValue: (value, label) => HandleWorkRateSelect(value, label),
                                            isIcon: false,
                                            isLabel: true,
                                            isRequired: true,
                                            label: "Work rate Items",

                                        }}
                                    />

                                </div>

                                <div className="content-item">
                                    <TextInput
                                        props={{
                                            value: name,
                                            placeholder: '',
                                            setValue: (value) => setName(value),
                                            isIcon: false,
                                            isLabel: true,
                                            isRequired: true,
                                            label: "Name",
                                            id: "estimate-work-name"
                                        }}
                                    />
                                </div>
                                <div className="content-item">
                                    <SelectInput
                                        props={{
                                            id: "estimate-work-phase",
                                            value: Phase_id,
                                            placeholder: '',
                                            options: [
                                                {
                                                    value: "",
                                                    label: "Select Group"
                                                }, ...ProjectPhases_Options || []
                                            ] || [],
                                            setValue: (value, label) => HandlePhaseInputChange(value, label),
                                            isIcon: false,
                                            isLabel: true,
                                            isRequired: true,
                                            label: "Project Phase",

                                        }}
                                    />
                                </div>


                            </div>
                            <div className="content-top-items">

                                <div className="content-item">
                                    <SelectInput
                                        props={{
                                            id: "estimate-work-group",
                                            value: group_id,
                                            placeholder: '',
                                            options: [
                                                {
                                                    value: "",
                                                    label: "Select Group"
                                                }, ...groupOptions || []
                                            ] || [],
                                            setValue: (value, label) => {
                                                setGroup_id(value)
                                                setGroup(label)
                                            },
                                            isIcon: false,
                                            isLabel: true,
                                            isRequired: true,
                                            label: "Group",

                                        }}
                                    />
                                </div>

                                <div className="content-item">
                                    <TextInput
                                        props={{
                                            value: quantity,
                                            placeholder: '',
                                            setValue: (value) => HandleQuantityChange(value),
                                            isIcon: false,
                                            isLabel: true,
                                            type: 'number',
                                            isRequired: true,
                                            label: "Quantity",
                                            id: "estimate-work-quantity"
                                        }}
                                    />
                                </div>
                                <div className="content-item">
                                    <SelectInput
                                        props={{
                                            id: "estimate-work-unit",
                                            value: unit_id,
                                            placeholder: '',
                                            options: unitsOptions || [],
                                            setValue: (value, label) => {
                                                setUnit_id(value)
                                                setUnit_name(label)
                                            },
                                            isIcon: false,
                                            isLabel: true,
                                            isRequired: true,
                                            label: "Unit type",

                                        }}
                                    />

                                </div>
                            </div>

                            <div className="content-item">
                                <div className={`additional-item ${additionalNotes ? 'additional-item-active' : ''}`}>
                                    <div className="head" onClick={(e) => setAdditionalNotes(!additionalNotes)}>
                                        <div className="icon" dangerouslySetInnerHTML={{ __html: Icons.general.description }}></div>
                                        <div className="label">Description</div>
                                    </div>
                                    <div className="item-expanded">
                                        <ReactQuill
                                            theme="snow"
                                            value={description}
                                            onChange={(value) => setDescription(value)}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="content-section-title">Items Includes</div>
                        <div className="content-section content-section-items">
                            {Cost_Lines.map((item, index) => (

                                <div className="content-section-item" key={index} >
                                    <div className="left" onClick={(e) => HandleOpenItem(item, index)}>
                                        <div
                                            className="icon"
                                            dangerouslySetInnerHTML={{ __html: item.type_option.icon }}
                                        ></div>
                                        <div className="detials">
                                            <div className="title">{item.name}</div>
                                            <div className="desc">
                                                <div className="label">Quantity</div>
                                                <div className="value">{parseFloat(item.co_efficient || 0) * parseFloat(quantity || 0)} {item.unit_name}</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="right">
                                        <div className="right-items" onClick={(e) => HandleOpenItem(item, index)}>
                                            <div className="right-item">
                                                <div className="label">Co-efficeient</div>
                                                <div className="value">{parseFloat(item.co_efficient || 0).toFixed(2)}</div>
                                            </div>
                                            <div className="right-item">
                                                <div className="label">Pre Rate</div>
                                                <div className="value">{currency_type}{parseFloat(item.per_co_efficient_cost || 0).toFixed(2)}</div>
                                            </div>
                                            <div className="right-item">
                                                <div className="label">Prime cost</div>
                                                <div className="value">{currency_type}{parseFloat(item.builder_price || 0).toFixed(2)}</div>
                                            </div>
                                            <div className="right-item">
                                                <div className="label">Markup</div>
                                                <div className="value">{currency_type}{parseFloat(item.markup_amount || 0).toFixed(2)}</div>
                                            </div>
                                            <div className="right-item">
                                                <div className="label">Tax</div>
                                                <div className="value">{currency_type}{parseFloat(item.tax_amount || 0).toFixed(2)}</div>
                                            </div>
                                            <div className="right-item">
                                                <div className="label">Final Amount</div>
                                                <div className="value">{currency_type}{parseFloat(item.price || 0).toFixed(2)}</div>
                                            </div>
                                        </div>
                                        <div className="right-action">
                                            <div className="right-action-item">
                                                <div
                                                    className="icon"
                                                    dangerouslySetInnerHTML={{ __html: Icons.general.delete_btn }}
                                                    onClick={(e) => HandleRemoveWorkItem(index)}
                                                ></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                            {!Cost_Lines.length ?
                                <div className="content-section-noresult">
                                    <div
                                        className="icon"
                                        dangerouslySetInnerHTML={{ __html: Icons.general.noresult }}
                                    ></div>
                                    <div className="label">No Items added yet</div>
                                </div>
                                : ''}
                        </div>

                    </div>
                    {/* <div className="content-addbtn-items">

                        {AddBtnOptions.filter(itm => itm.type == 'item')?.map((item, i) => (

                            <div
                                className="content-addbtn-item"
                                onClick={(e) => HandleAddWorkItem(item)}
                                key={`add-work-items-${item.id}`}
                            >
                                <div className="content">
                                    <div
                                        className="icon"
                                        dangerouslySetInnerHTML={{ __html: item.icon }}
                                    ></div>
                                    <div className="label">Add {item.label}</div>
                                </div>
                            </div>

                        ))}
                    </div> */}
                    <Popup_Footer />
                </form>
            </div>
            {sideItemShowPopup ? <AddWorkSideItem props={{
                type: sideItemType,
                header_option: sideItemHeader.id,
                updateCallback: HandleCreateWorkItem,
                close: HandleCloseSidePopup,
                existItemData: sideItemExistDetials,
                MainQty: quantity || 0
            }} /> : null}
        </>
    )

}


const AddEstimateItemPopup = ({ props }) => {

    const { existItemData, updateCallback } = props

    const { projectId, itemType, itemId, actionType } = useParams()

    const [isLoading, setIsLoading] = useState(false)
    const [warningAlert, setWarningAlert] = useState(false)
    const [warningAlertType, setWarningAlertType] = useState('warning')
    const [apiFailedMessage, setApiFailedMessage] = useState("Error in Server")

    const [currency_type, setCurrency_type] = useState('₹')

    const navigate = useNavigate()
    const store = useSelector((store) => store)
    const dispatch = useDispatch()
    const { updateState } = new UserAction


    const { isAdmin, menu_permission } = useMenuAuth({ menu: 'pre_construction--project_estimate', feature_key: 'estimate' })
    const location = useLocation()

    const [selected_view_settings_value, setSelected_view_settings_value] = useState('')
    const [has_view_access, setHas_view_access] = useState(false)
    const [has_add_access, setHas_add_access] = useState(false)
    const [has_edit_access, setHas_edit_access] = useState(false)
    const [has_delete_access, setHas_delete_access] = useState(false)

    useEffect(() => {

        if (isAdmin) {

            setHas_view_access(true)
            setHas_add_access(true)
            setHas_edit_access(true)
            setHas_delete_access(true)
        }

        else if (menu_permission) {

            if (
                !menu_permission._view
                &&
                (!menu_permission.selected_view_settings_value
                    || menu_permission.selected_view_settings_value == 'no_access')
            ) {
                navigate(`${ReverseProxy['proxyUrl']}/quick-menu`, { state: { from: location }, replace: true })
            }

            if (actionType == 'add' && menu_permission._create == '0') navigate(`${ReverseProxy['proxyUrl']}/builder/projects`, { state: { from: location }, replace: true })
            if (actionType == 'edit' && menu_permission._edit == '0') navigate(`${ReverseProxy['proxyUrl']}/builder/projects`, { state: { from: location }, replace: true })

            setHas_view_access(menu_permission._view == '1')
            setHas_add_access(menu_permission._create == '1')
            setHas_edit_access(menu_permission._edit == '1')
            setHas_delete_access(menu_permission._delete == '1')

            setSelected_view_settings_value(menu_permission.selected_view_settings_value)

        }

    }, [store])

    return (

        <>
            {itemType == 'group'
                ?
                actionType == 'add' ? <AddGroup props={{ updateCallback }} />
                    : actionType == 'edit' ? <AddGroup props={{ existItemData, updateCallback }} /> : ''
                :
                itemType == 'work'
                    ?
                    actionType == 'add' ? <AddWork props={{ updateCallback }} />
                        : actionType == 'edit' ? <AddWork props={{ existItemData, updateCallback }} /> : ''
                    :
                    itemType == 'work_template'
                        ?
                        actionType == 'add' ? <AddEstimateCatalogPopup props={{ updateCallback }} />
                            : actionType == 'edit' ? <AddEstimateCatalogPopup props={{ existItemData, updateCallback }} /> : ''
                        :
                        actionType == 'add' ? <AddItem props={{ updateCallback }} />
                            : actionType == 'edit' ? <AddItem props={{ existItemData, updateCallback }} /> : ''

            }
        </>

    )

}

export default AddEstimateItemPopup;