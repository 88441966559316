

import React, { useEffect, useState, useRef, useCallback, useMemo, StrictMode } from 'react'
import { Route, Routes, BrowserRouter, Link, useLocation, Navigate, Outlet, useNavigate, useParams } from 'react-router-dom';
import store from '../../../redux/store'
import StoreHandler from '../../../redux/StoreHandler'
import { useSelector, useDispatch } from 'react-redux'
import UserAction from '../../../redux/action/userAction'

import ReverseProxy from '../../../config/reverseProxy'
import MaterialIssueHandler from '../../../Handlers/MaterialIssue/MaterialIssue';
import TaskManagementHandler from '../../../Handlers/TaskManagement/TaskManagement';
import StockItemsHandler from '../../../Handlers/StockItems/StockItems';
import CommentsHandler from '../../../Handlers/Comments/Comments';
import Icons from '../../../assets/Icons'
import Images from '../../../assets/Images'
import { CostInput, TextInput, SelectInput, TextArea, DateTimeInput, RadioInput } from '../../../components/Inputs'
import Utils from '../../../utils'
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

import AlertPopup from '../../../components/AlertPopup'
import SystemToastPopup from '../../../components/SystemToastPopup'
import Loading from '../../../components/Loading'
import BuilderAttachments from '../../../Handlers/BuilderAttachments/BuilderAttachments';

import ImagePreviewPopup from '../../../components/ImagePreviewPopup'

import { useAuth, useMenuAuth } from '../../../hooks/useAuth'

const CreateMaterialIssue = ({ props }) => {

    const { projectId, issue_no, issue_to } = useParams();

    const { menuType = 'create' } = props

    const [project_id, setProject_id] = useState('')
    const [project_name, setProject_name] = useState('')

    const [isLoading, setIsLoading] = useState(false)
    const [warningAlert, setWarningAlert] = useState(false)
    const [warningAlertType, setWarningAlertType] = useState('warning')
    const [apiFailedMessage, setApiFailedMessage] = useState('Error in Material Issue')

    const navigate = useNavigate()
    const store = useSelector((store) => store)
    const dispatch = useDispatch()
    const { updateState } = new UserAction

    const formSubmitBtnRef = useRef()

    const storeHandler = new StoreHandler({
        dataStack: ['company', 'sor_materials', 'sites', 'team_users', 'field_settings', `project-${projectId}`],
        store: store,
        dispatch: dispatch,
        updateState: updateState,
    })

    const LoadStoreData = async () => {
        setIsLoading(true)
        let resolved = await storeHandler.init()
        setIsLoading(false)
    }

    const { isAdmin, menu_permission } = useMenuAuth({ menu: 'inventory--material_issues', feature_key: 'inventory-material-issue' })
    const location = useLocation()

    const [selected_view_settings_value, setSelected_view_settings_value] = useState('')
    const [has_view_access, setHas_view_access] = useState(false)
    const [has_add_access, setHas_add_access] = useState(false)
    const [has_edit_access, setHas_edit_access] = useState(false)
    const [has_delete_access, setHas_delete_access] = useState(false)

    useEffect(() => {

        if (isAdmin) {

            setHas_view_access(true)
            setHas_add_access(true)
            setHas_edit_access(true)
            setHas_delete_access(true)
        }

        else if (menu_permission) {

            if (
                !menu_permission._view
                &&
                (!menu_permission.selected_view_settings_value
                    || menu_permission.selected_view_settings_value == 'no_access')
            ) {
                navigate(`${ReverseProxy['proxyUrl']}/quick-menu`, { state: { from: location }, replace: true })
            }

            if (menuType == 'create' && menu_permission._create == '0') navigate(`${ReverseProxy['proxyUrl']}/builder/projects`, { state: { from: location }, replace: true })
            if (menuType == 'edit' && menu_permission._edit == '0') navigate(`${ReverseProxy['proxyUrl']}/builder/projects`, { state: { from: location }, replace: true })

            setHas_view_access(menu_permission._view == '1')
            setHas_add_access(menu_permission._create == '1')
            setHas_edit_access(menu_permission._edit == '1')
            setHas_delete_access(menu_permission._delete == '1')

            setSelected_view_settings_value(menu_permission.selected_view_settings_value)

        }

    }, [store])

    const stockItemsHandler = new StockItemsHandler()
    const materialIssueHandler = new MaterialIssueHandler()
    const maskManagementHandler = new TaskManagementHandler()
    const commentsHandler = new CommentsHandler()
    const builderAttachments = new BuilderAttachments()

    const [tasksOptions, setTasksOptions] = useState([])
    const [tasksOptions_Orginal, setTasksOptions_Orginal] = useState([])

    const [materialsOptions, setMaterialsOptions] = useState([])
    const [materialsOptions_Orginal, setMaterialsOptions_Orginal] = useState([])
    const [unitsOptions, setUnitsOptions] = useState([])
    const [currency_type, setCurrency_type] = useState('₹')

    const [id, setId] = useState(Utils.getUniqueId())
    const [name, setName] = useState('')
    const [issue_No, setIssue_No] = useState('')
    const [status, setStatus] = useState('')

    const [issueDate, setIssueDate] = useState(Utils._getLocalDate())

    const [remarks, setRemarks] = useState('')
    const [comments, setComments] = useState('')

    const [issue_reference, setIssueReference] = useState('')

    const [checkedby_id, setCheckedBy_id] = useState('')
    const [checkedby_name, setCheckedBy_name] = useState('')

    const [otherSite_id, setOtherSite_id] = useState('')
    const [otherSite_name, setOtherSite_name] = useState('')

    const [otherSitesOptions, setOtherSitesOptions] = useState([])
    const [teamMatesOptions, setTeamMatesOptions] = useState([])

    const [statusOptions, setStatusOptions] = useState([
        {
            value: 0,
            label: "Pending",
            isDefault: false
        },
        {
            value: 1,
            label: "Approved",
            isDefault: false
        },
        {
            value: 2,
            label: "Rejected",
            isDefault: false
        },
    ])

    const [paymentSplitLimitExists, setPaymentSplitLimitExists] = useState(false)
    const [materialItems, setMaterialItems] = useState([
        {
            id: Utils.getUniqueId(),
            material_id: "",
            material_name: "",
            value_type: `$`,
            unit_id: '',
            unit_name: '',
            quantity: 0,
            max_quantity: 0,
            description: '',
        }
    ])

    const [stockItems, setStockItems] = useState([])

    const HandleMaterialOptionsSelected = () => {

        let material_items = [...materialItems].map(c => c.material_id)

        let _materialsOptions = [...materialsOptions]

        _materialsOptions = _materialsOptions.map(option => {

            if (material_items.includes(option.value)) option.disabled = true
            else option.disabled = false

            return option
        })

        setMaterialsOptions(_materialsOptions)

    }


    const HandleTasksOptionsSelected = () => {

        let material_items = [...materialItems].map(c => c.task_id)

        let _tasksOptions = [...tasksOptions]

        _tasksOptions = _tasksOptions.map(option => {

            if (material_items.includes(option.value)) option.disabled = true
            else option.disabled = false
            return option
        })

        setTasksOptions(_tasksOptions)

    }

    const HandlePaymentsInputChange = (value, index, key, label) => {

        let materialItems_ = [...materialItems]

        if (key == 'quantity') {

            materialItems_[index][key] = value
            materialItems_[index]['amount'] = parseFloat(value || 0) * parseFloat(materialItems_[index]['unitrate'] || 0)
            materialItems_[index]['value_type'] = currency_type
        }
        else if (key == 'material') {

            materialItems_[index]['material_id'] = value
            materialItems_[index]['material_name'] = label

            HandleMaterialOptionsSelected()


            let stockItem = [...stockItems].filter(s => s.id == value)[0]

            if (stockItem) {
                materialItems_[index]['max_quantity'] = parseFloat(stockItem.balace_quantity || 0)
                materialItems_[index]['opening_quantity'] = parseFloat(stockItem.balace_quantity || 0)
            }

            let original_item = materialsOptions_Orginal.filter(m => m.id == value)[0]

            if (original_item) {

                materialItems_[index]['unit_id'] = original_item.unit_id
                materialItems_[index]['unit_name'] = original_item.unit_name

                materialItems_[index]['unitrate'] = parseFloat(original_item.price || 0)
                materialItems_[index]['amount'] = parseFloat(materialItems_[index]['quantity'] || 0) * parseFloat(original_item.price || 0)

            }

        }
        else if (key == 'task') {

            materialItems_[index]['task_id'] = value
            materialItems_[index]['task_name'] = label

            HandleTasksOptionsSelected()
        }
        else if (key == 'unit') {

            materialItems_[index]['unit_id'] = value
            materialItems_[index]['unit_name'] = label
        }

        else materialItems_[index][key] = value

        setMaterialItems(materialItems_)
    }
    const HandlePaymentsRemove = (index) => {
        let materialItems_ = [...materialItems]
        if (index == 0) {

            materialItems_[index] = {
                id: Utils.getUniqueId(),
                name: "",
                value: 0,
                amount: 0
            }
            setMaterialItems(materialItems_)
            return
        }
        materialItems_.splice(index, 1)
        setMaterialItems(materialItems_)

        HandleMaterialOptionsSelected()
        HandleTasksOptionsSelected()
    }
    const HandlePaymentsAddNew = () => {

        let materialItems_ = [...materialItems]
        materialItems_.push({
            id: Utils.getUniqueId(),
            material_id: "",
            material_name: "",
            value_type: `$`,
            unit_id: '',
            unit_name: '',
            quantity: 0,
            max_quantity: 0,
            description: '',

        })
        setMaterialItems(materialItems_)
    }

    useEffect(() => {

        if (Array.isArray(materialItems)) {

            const PaymentTotal = materialItems.reduce((prev, val, i) => prev + parseFloat(val.value), 0)
            if (PaymentTotal > 100 && !paymentSplitLimitExists) setPaymentSplitLimitExists(true)
            else if (PaymentTotal <= 100 && paymentSplitLimitExists) setPaymentSplitLimitExists(false)


            HandleMaterialOptionsSelected()
            HandleTasksOptionsSelected()
        }

    }, [materialItems])

    useEffect(() => {

        let _materialItems = [...materialItems].map(item => {

            let stockItem = [...stockItems].filter(s => s.id == item.material_id)[0]

            if (stockItem) {
                item['max_quantity'] = parseFloat(item.quantity || 0) + parseFloat(stockItem.balace_quantity || 0)
            }

            return item
        })

        setMaterialItems(_materialItems)


    }, [stockItems])

    const HandleCancel = (e) => {

        navigate(`/builder/projects/${projectId}/inventory/material-issued`)
    }

    const HandleSubmit = async (e) => {
        e.preventDefault()

        if (paymentSplitLimitExists) return

        let update_detials = {
            id: id,
            name: name,
            issue_no: issue_No,
            projectId: projectId,
            project_id: project_id,
            project_name: project_name,

            other_site_id: String(otherSite_id || ""),
            other_site_name: String(otherSite_name || ""),

            checkedby_id: String(checkedby_id),
            checkedby_name: String(checkedby_name),

            issue_to: issue_to == 'other_site' ? '1' : '0',

            issue_reference: String(issue_reference),

            issue_date: issueDate,
            status: String(status),


            remarks: remarks || "",
            comments: comments || "",

            cost_items: materialItems || [],
            attachments: AttachmentsFiles || [],

        }


        let response = { success: false }

        if (menuType == 'edit') {

            update_detials["id"] = id
            response = await materialIssueHandler.updateMaterialIssueHandler(update_detials)

        } else {
            response = await materialIssueHandler.createMaterialIssueHandler(update_detials)


            let update_data = {
                project_id: projectId,
                parent_id: issue_No,
                parent_name: name || `M-Issue ${issue_No}`,
                parent_type: String(0),
                comments: CommentsItems
            }

            let comments_response = await commentsHandler.createMultiCommentHandler(update_data)

            if (!comments_response.success) {
                setWarningAlert(true)
                setWarningAlertType('error')
                setApiFailedMessage(`Error in comments, Please try again!`)
            }
        }

        if (response.success) {
            setIsLoading(false)
            navigate(`/builder/projects/${projectId}/inventory/material-issued`)
        }
        else {
            setIsLoading(false)
            setWarningAlert(true)
            setWarningAlertType('error')
            setApiFailedMessage(`Error in Material Issue, Please try again!`)
        }
    }

    const getNextProjectID = async () => {

        let response = await materialIssueHandler.getNextIssueIDHandler()

        if (response && response.success) {

            let { issue_no } = response.data

            if (issue_no) setIssue_No(issue_no)

        } else {
            setWarningAlert(true)
            setWarningAlertType('error')
            setApiFailedMessage(`Error in Material Issue, Please try again!`)
        }
    }

    const getStockItems = async () => {

        let filter = {
            projectId: projectId
        }

        let response = await stockItemsHandler.getStockItemsHandler(filter)

        if (response && response.success) {

            setStockItems(response.data)




        } else {
            setWarningAlert(true)
            setWarningAlertType('error')
            setApiFailedMessage(`Error in Stock Items, Please try again!`)
        }
    }


    const LoadClientInvoice = async () => {

        let filter = {
            issue_no: issue_no,
            projectId: projectId,
        }

        setIsLoading(true)

        let response = await materialIssueHandler.getMaterialIssuesHandler(filter)
        setIsLoading(false)

        if (response.success) {
            let { data } = response

            if (!Array.isArray(data) || !data.length) {
                return navigate(`/builder/projects/${projectId}/inventory/material-issued`)
            }


            let {
                id: ID,
                name,
                issue_no,
                other_site_id,
                other_site_name,
                checkedby_id,
                checkedby_name,
                issue_reference,
                projectId: projectID,
                issue_date,
                status,

                remarks,
                comments,

                cost_items,
                attachments,
            } = data[0]

            setId(ID)
            setIssue_No(issue_no)
            setName(name)
            setStatus(status)
            setRemarks(remarks)

            setOtherSite_id(other_site_id)
            setOtherSite_name(other_site_name)

            setCheckedBy_id(checkedby_id)
            setCheckedBy_name(checkedby_name)

            setIssueReference(issue_reference)

            setComments(comments)

            setIssueDate(issue_date)

            setMaterialItems(cost_items || [])
            setAttachmentsFiles(attachments || [])

        }
    }

    const LoadTasks = async () => {

        let filter = {
            type: '2',
            projectId: projectId
        }

        let response = await maskManagementHandler.getTasksHandler(filter)

        if (response && response.success && Array.isArray(response.data)) {

            let tasks = response.data

            tasks = tasks.filter(task => parseFloat(task.total_work_progress || 0) > parseFloat(task.work_done_progress || 0))

            let task_options = tasks.map(t => {
                return {
                    value: t.id,
                    label: t.name
                }
            })

            setTasksOptions(task_options)
            setTasksOptions_Orginal(tasks)

        } else {
            setWarningAlert(true)
            setWarningAlertType('error')
            setApiFailedMessage(`Error in Tasks, Please try again!`)
        }
    }

    useEffect(() => {

        if (menuType == 'create') {
            getNextProjectID()
        }
        else {
            LoadClientInvoice()
            LoadComments({ project_id: projectId, parent_id: issue_no })
        }


        LoadStoreData()
        getStockItems()
        LoadTasks()
    }, [])


    useEffect(() => {

        if (store && store.user) {

            let { company, team_users, selected_project, sites, sor_materials, settings } = store.user

            if (selected_project) {
                let { id, name, estimate_id } = selected_project

                if (id) setProject_id(id)
                if (name) setProject_name(name)
            }

            if (company) {

                let { currency_setting, timezone, temperature_setting } = company

                if (currency_setting) setCurrency_type(currency_setting)
            }
            if (Array.isArray(sor_materials)) {

                let _sor_materials = sor_materials.map((opt) => {
                    return {
                        value: opt.id,
                        label: opt.name,
                    }
                })

                setMaterialsOptions(_sor_materials)
                setMaterialsOptions_Orginal(sor_materials)
            }
            if (Array.isArray(team_users)) {

                team_users = team_users.filter(opt => opt.status == '1').map((opt) => {
                    return {
                        value: opt.id,
                        label: opt.name,
                    }
                })

                setTeamMatesOptions(team_users)
            }
            if (Array.isArray(sites)) {

                sites = sites.filter(s => s.projectID != projectId).map((opt) => {
                    return {
                        value: opt.id,
                        label: opt.name,
                    }
                })

                setOtherSitesOptions(sites)
            }


            if (settings && Array.isArray(settings.field_settings) && settings.field_settings.length) {

                let { project_statuses, project_types, units } = settings.field_settings[0]

                units = units.filter(opt => opt.isActive).map((opt) => {
                    return {
                        value: opt.id,
                        label: opt.name,
                        isDefault: opt.isDefault || false
                    }
                })

                setUnitsOptions(units || [])

            }


        }

    }, [store])


    const [ShowSelectedPreviewImage, setShowSelectedPreviewImage] = useState(false)
    const [SelectedPreviewImage, setSelectedPreviewImage] = useState(undefined)
    const AttachmentsInputRef = useRef()
    const [AttachmentsFiles, setAttachmentsFiles] = useState([])

    const HandleTriggerAddAttachment = (e) => {
        if (AttachmentsInputRef.current) AttachmentsInputRef.current.click()
    }
    const HandleAddAttachment = async (e) => {

        let file = e.target.files[0]

        if (!file) return

        const formData = new FormData();

        formData.append('projectId', projectId);
        formData.append('attachment', file);
        formData.append('linked_type', '7');
        formData.append('linked_item_id', id);

        setIsLoading(true)

        let upload_response = await builderAttachments.createAttachmentHandler(formData)

        setIsLoading(false)

        if (upload_response.success) {

            let { public_id, url } = upload_response.data

            if (!public_id || !url) return

            let _AttachmentsFiles = [...AttachmentsFiles]

            _AttachmentsFiles.push({
                public_id: public_id,
                original_filename: file.name,
                bytes: file.size,
                format: file.type,
                file_url: url,
                linked_type: '7',
                linked_item_id: id,
            })

            setAttachmentsFiles(_AttachmentsFiles)

        } else {
            setWarningAlert(true)
            setWarningAlertType('error')
            setApiFailedMessage(`Error in Attachment, Please try again!`)
        }
    }
    const HandleDeleteAttachment = async (idx) => {

        let _AttachmentsFiles = [...AttachmentsFiles]

        setIsLoading(true)

        let upload_response = await builderAttachments.deleteAttachmentHandler({
            public_id: _AttachmentsFiles[idx].public_id,
            projectId: projectId,
        })

        setIsLoading(false)

        if (upload_response.success) {
            _AttachmentsFiles.splice(idx, 1)
            setAttachmentsFiles(_AttachmentsFiles)
        }
        else {
            setWarningAlert(true)
            setWarningAlertType('error')
            setApiFailedMessage(`Error in Attachment, Please try again!`)
        }
    }



    const HandleSelectPreviewImage = (image) => {
        setShowSelectedPreviewImage(true)
        setSelectedPreviewImage({
            name: image.original_filename,
            file_url: image.file_url,
        })
    }
    const HandleHideSelectPreviewImage = (confirmation) => {
        setShowSelectedPreviewImage(confirmation)
        setSelectedPreviewImage(undefined)
    }



    const [CommentsItems, setCommentsItems] = useState([])

    const [CommentsInput, setCommentsInput] = useState("")

    const LoadComments = async ({ project_id, parent_id }) => {

        console.log(project_id, parent_id, 'parent_id');
        if (!project_id || !parent_id) return

        let filter = {
            project_id: project_id,
            parent_id: parent_id
        }

        setIsLoading(true)

        let response = await commentsHandler.getCommentsHandler(filter)
        setIsLoading(false)

        if (response.success) {
            let { data } = response

            if (!Array.isArray(data)) return

            console.log(data, 'data');

            setCommentsItems(data)
        }
    }

    const HandleAddComment = async () => {
        let _CommentsItems = [...CommentsItems]

        let userdetials = JSON.parse(localStorage.getItem('userdetials'))

        _CommentsItems.push({
            id: Utils.getUniqueId(),
            created_at: new Date().getTime(),
            created_by: userdetials.id,
            created_by_name: userdetials.name,
            message: CommentsInput,
            parent_id: issue_No,
            parent_name: name || `M-Issue ${issue_No}`,
            parent_type: 0,
            project_id: projectId,
            company_id: userdetials.company_id,
        })

        setCommentsItems(_CommentsItems)
        setCommentsInput("")

        if (menuType == 'create') return

        let update_data = {
            project_id: projectId,
            parent_id: issue_No,
            parent_name: name || `M-Issue ${issue_No}`,
            parent_type: String(0),
            message: CommentsInput
        }

        let response = await commentsHandler.createCommentHandler(update_data)

        if (!response.success) {
            setWarningAlert(true)
            setWarningAlertType('error')
            setApiFailedMessage(`Error in comments, Please try again!`)
        }
    }

    const HandleDeleteComment = async (id, idx) => {
        let _CommentsItems = [...CommentsItems]

        _CommentsItems.splice(idx, 1)

        setCommentsItems(_CommentsItems)

        if (menuType == 'create') return

        let update_data = {
            project_id: projectId,
            ids: [id]
        }

        let response = await commentsHandler.deleteCommentHandler(update_data)

        if (!response.success) {
            setWarningAlert(true)
            setWarningAlertType('error')
            setApiFailedMessage(`Error in comments, Please try again!`)


        }
    }


    return (
        <>


            {isLoading ?
                <Loading
                    props={{
                        isMainLogo: false,
                        isLabel: true
                    }} />
                : null}
            {warningAlert ?

                <SystemToastPopup
                    props={{
                        type: warningAlertType,
                        message: apiFailedMessage || "Error in Material Issue",
                        callback: (confirmation) => setWarningAlert(false)
                    }} />

                : null}

            {ShowSelectedPreviewImage ?

                <ImagePreviewPopup
                    props={{
                        heading: `Preview - ${SelectedPreviewImage.name}`,
                        image: SelectedPreviewImage.file_url,
                        message: "Invalid Inputs, Please try again with vaild Inputs!",
                        callback: (confirmation) => HandleHideSelectPreviewImage(confirmation)
                    }} />

                : null}




            <div className="project-create_client_invoice-main" id='project-create_client_invoice-main'>
                <div className="create_client_invoice-panel-content">

                    <div className="panel-content-header">
                        <div className="left">
                            <div className="icon"
                                dangerouslySetInnerHTML={{ __html: Icons.general.back_arrow }}
                                onClick={(e) => HandleCancel(e)}
                            ></div>
                            <div className="title">{menuType == 'create' ? 'Issue Material' : 'Update Issued Material'} </div>
                        </div>

                    </div>
                    <form className="panel-content-sections" onSubmit={HandleSubmit}>
                        <div className="create-client_invoicecontent-main">

                            <div className="create-client_invoicesections">

                                <div className="create-client_invoicesection">
                                    <div className="section-input-items">

                                        <div className="section-input-item">
                                            <TextInput
                                                props={{
                                                    id: "create-invoice-name",
                                                    value: name,
                                                    placeholder: '',
                                                    setValue: (value) => setName(value),
                                                    isIcon: false,
                                                    isLabel: true,
                                                    isRequired: true,
                                                    label: "Name",
                                                }}
                                            />
                                        </div>
                                        <div className="section-input-item">
                                            <TextInput
                                                props={{
                                                    id: "create-invoice-issue_no",
                                                    value: issue_No,
                                                    placeholder: '',
                                                    setValue: (value) => { },
                                                    isIcon: false,
                                                    isLabel: true,
                                                    isRequired: true,
                                                    readOnly: true,
                                                    label: "Issue No",
                                                }}
                                            />
                                        </div>

                                        <div className="section-input-item">
                                            <SelectInput
                                                props={{
                                                    id: "create-invoice-checkedby",
                                                    value: checkedby_id,
                                                    placeholder: '',
                                                    readOnly: false,
                                                    options: teamMatesOptions || [],
                                                    setValue: (value, label) => {
                                                        setCheckedBy_id(value)
                                                        setCheckedBy_name(label)
                                                    },
                                                    isStatus: false,
                                                    isIcon: false,
                                                    isLabel: true,
                                                    isRequired: true,
                                                    label: "Checked by",
                                                }}
                                            />
                                        </div>

                                    </div>
                                    <div className="section-input-items">

                                        {issue_to == 'other_site' ?
                                            <div className="section-input-item">
                                                <SelectInput
                                                    props={{
                                                        id: "create-invoice-site",
                                                        value: otherSite_id,
                                                        placeholder: '',
                                                        readOnly: false,
                                                        options: otherSitesOptions || [],
                                                        setValue: (value, label) => {
                                                            setOtherSite_id(value)
                                                            setOtherSite_name(label)
                                                        },
                                                        isStatus: false,
                                                        isIcon: false,
                                                        isLabel: true,
                                                        isRequired: true,
                                                        label: "Other Site",
                                                    }}
                                                />
                                            </div>
                                            : ''}

                                        <div className="section-input-item">
                                            <TextInput
                                                props={{
                                                    id: "create-invoice-issue_reference",
                                                    value: issue_reference,
                                                    placeholder: '',
                                                    setValue: (value) => {
                                                        setIssueReference(value)
                                                    },
                                                    isIcon: false,
                                                    isLabel: true,
                                                    isRequired: false,
                                                    label: "Reference",
                                                }}
                                            />
                                        </div>
                                        <div className="section-input-item">
                                            <SelectInput
                                                props={{
                                                    id: "create-invoice-status",
                                                    value: status,
                                                    placeholder: '',
                                                    readOnly: false,
                                                    options: statusOptions || [],
                                                    setValue: (value, label) => {
                                                        setStatus(value)
                                                    },
                                                    isStatus: false,
                                                    isIcon: false,
                                                    isLabel: true,
                                                    isRequired: false,
                                                    label: "Status",
                                                }}
                                            />
                                        </div>

                                    </div>
                                </div>

                                <div className="create-client_invoicesection" id={`create-client_invoiceremarks`}>

                                    <div className={`createproposal-description-main`}>
                                        <div className="head">
                                            <div className="title">
                                                <div className="icon" dangerouslySetInnerHTML={{ __html: Icons.general.description }}></div>
                                                <div className="label">Remarks</div>
                                            </div>
                                        </div>
                                        <div className="item-expanded">
                                            <ReactQuill
                                                theme="snow"
                                                value={remarks || ""}
                                                onChange={(value) => setRemarks(value)}
                                            />
                                        </div>
                                    </div>

                                </div>

                                <div className="create-client_invoicesection" id={`create-client_invoicepayment_schedule`}>
                                    <div className={`createproposal-description-main`}>
                                        <div className="head">
                                            <div className="title">
                                                <div className="label">Add Materials</div>
                                                <div className="icon" dangerouslySetInnerHTML={{ __html: Icons.general.info }}></div>
                                            </div>
                                        </div>

                                        <div className={`invoice-payment-splits-main ${issue_to != 'other_site' ? 'materialIssue-splits-main' : 'indent-material-splits-main'}`}>
                                            <div className="invoice-payment-splits-header">
                                                {issue_to != 'other_site' ? <div className="header-item-task">Task</div> : ""}
                                                <div className="header-item-name">Material</div>
                                                <div className="header-item-quantity">Quantity</div>
                                                <div className="header-item-action">Action</div>
                                            </div>

                                            <div className="invoice-payment-splits-content">
                                                {materialItems.map((item, index) => (
                                                    <div className="invoice-payment-content-item">
                                                        <div className="invoice-payment-content-items">
                                                            {issue_to != 'other_site' ?
                                                                <div className="invoice-payment-content-task">

                                                                    <SelectInput
                                                                        props={{
                                                                            id: `invoice-payment-task-${index}`,
                                                                            value: item.task_id,
                                                                            placeholder: 'Select task',
                                                                            options: tasksOptions || [],
                                                                            setValue: (value, label) => HandlePaymentsInputChange(value, index, 'task', label),
                                                                            isIcon: false,
                                                                            isLabel: false,
                                                                            isRequired: true,
                                                                            label: "Task",
                                                                        }}
                                                                    />
                                                                </div>
                                                                : ""}
                                                            <div className="invoice-payment-content-name">

                                                                <SelectInput
                                                                    props={{
                                                                        id: `invoice-payment-material-${index}`,
                                                                        value: item.material_id,
                                                                        placeholder: 'Select material',
                                                                        options: materialsOptions || [],
                                                                        setValue: (value, label) => HandlePaymentsInputChange(value, index, 'material', label),
                                                                        isIcon: false,
                                                                        isLabel: false,
                                                                        isRequired: true,
                                                                        label: "Material",
                                                                    }}
                                                                />
                                                            </div>
                                                            <div className="invoice-payment-content-quantity">
                                                                <TextInput
                                                                    props={{
                                                                        id: `invoice-payment-quantity-${index}`,
                                                                        value: item.quantity,
                                                                        placeholder: 'Quantity',
                                                                        setValue: (value) => HandlePaymentsInputChange(value, index, 'quantity'),
                                                                        isIcon: false,
                                                                        isLabel: false,
                                                                        isRequired: true,
                                                                        setMaxLimit: item.max_quantity || 0,
                                                                        maxLimitLabel: true,
                                                                        type: 'number',
                                                                        label: "Quantity",
                                                                    }}
                                                                />
                                                                {item.unit_name}
                                                            </div>

                                                            <div className="invoice-payment-content-action">
                                                                <div
                                                                    className="action-remove"
                                                                    onClick={(e) => HandlePaymentsRemove(index)}
                                                                >
                                                                    <div className="icon" dangerouslySetInnerHTML={{ __html: Icons.general.delete_btn }}></div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="invoice-payment-content-items">
                                                            <div className="invoice-payment-content-description">
                                                                <TextArea
                                                                    props={{
                                                                        id: `invoice-payment-description-${index}`,
                                                                        value: item.description,
                                                                        placeholder: 'Description',
                                                                        setValue: (value) => HandlePaymentsInputChange(value, index, 'description'),
                                                                        isIcon: false,
                                                                        isLabel: false,
                                                                        isRequired: false,
                                                                        label: "Description",
                                                                    }}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                            <div className={`invoice-payment-content-warning ${paymentSplitLimitExists ? 'warning-active' : ''}`}>
                                                <div
                                                    className="icon"
                                                    dangerouslySetInnerHTML={{ __html: Icons.general.warning }}
                                                ></div>
                                                <div className="label">The sum of payment cannot exceed 100%.</div>
                                            </div>
                                            <div
                                                className="invoice-payment-splits-action-buttons"
                                            >
                                                <div
                                                    className="add-button"
                                                    onClick={(e) => HandlePaymentsAddNew()}
                                                >
                                                    <div
                                                        className="icon"
                                                        dangerouslySetInnerHTML={{ __html: Icons.general.add_btn }}
                                                    ></div>
                                                    <div className="label">Add new item</div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                                <div className="create-client_invoicesection" id={`create-proposal-attachments`}>
                                    <div className={`createproposal-description-main`}>
                                        <div className="head">
                                            <div className="title">
                                                <div className="icon" dangerouslySetInnerHTML={{ __html: Icons.general.attachments }}></div>
                                                <div className="label">Attachments</div>
                                            </div>
                                        </div>
                                        <div className="attachments-section-main">
                                            <div className="attachments-section-files">
                                                <div className="attachment-input" onClick={HandleTriggerAddAttachment}>
                                                    <input type="file" onChange={HandleAddAttachment} ref={AttachmentsInputRef} accept='.png,.jpg,.jpeg' />
                                                    <div className="attachment-add-icon"
                                                        dangerouslySetInnerHTML={{ __html: Icons.general.add_attachments_plus }}
                                                    ></div>
                                                    <div className="attachment-label">Upload</div>
                                                    <div className="attachment-limit">Max limit: 5MB</div>
                                                </div>
                                                {AttachmentsFiles?.map((file, idx) => (
                                                    <div
                                                        className="attachment-file"
                                                        key={file.public_id}
                                                        id={`file-${file.public_id}`}
                                                    >
                                                        <div
                                                            className="attachment-delete-btn"
                                                            dangerouslySetInnerHTML={{ __html: Icons.general.close }}
                                                            onClick={() => HandleDeleteAttachment(idx)}
                                                        ></div>
                                                        <div className="attachment-file-preview" onClick={() => HandleSelectPreviewImage(file)}>
                                                            <img src={file.file_url} alt="attachment-file" />
                                                        </div>
                                                        <div className="attachment-file-footer">
                                                            <div className="file-name">{file.original_filename}</div>
                                                            <div
                                                                className="file-download"
                                                                dangerouslySetInnerHTML={{ __html: Icons.general.download }}
                                                            ></div>
                                                        </div>

                                                    </div>
                                                ))}


                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="create-proposal-section">

                                    <div className={`createproposal-description-main`}>
                                        <div className="head">
                                            <div className="label">Comments</div>
                                        </div>
                                        <div className="comments-section-main">
                                            <div className="comments-section-items">

                                                {CommentsItems.map((item, idx) => (

                                                    <div
                                                        className="comments-item-main"
                                                        key={`comments-item-${item.id}`}
                                                        id={`comments-item-${item.id}`}
                                                    >
                                                        <div className="comments-item-usericon">
                                                            {item.created_by_name.charAt(0)}
                                                        </div>
                                                        <div className="comments-item-detials">
                                                            <div className="detials-head">
                                                                <div className="left">
                                                                    <div className="name">{item.created_by_name}</div>
                                                                    <div className="time">{Utils.getLocalFullDateLabel(item.created_at)}</div>
                                                                </div>
                                                                <div className="right">
                                                                    <div
                                                                        className="delete-icon"
                                                                        onClick={() => HandleDeleteComment(item.id, idx)}
                                                                        dangerouslySetInnerHTML={{ __html: Icons.general.delete_btn }}></div>
                                                                </div>
                                                            </div>
                                                            <div className="detials-message">{item.message}</div>
                                                        </div>
                                                    </div>


                                                ))}


                                                <div className="comments-input-main">
                                                    <div className="comments-input-usericon">
                                                        RT
                                                    </div>
                                                    <div className="comments-input comments-input-active">
                                                        <div className="comments-input-textarea">
                                                            <textarea
                                                                placeholder='Add a comment...'
                                                                value={CommentsInput}
                                                                onChange={(e) => setCommentsInput(e.target.value)}
                                                            ></textarea>
                                                        </div>
                                                        <div className={`comments-input-controls ${CommentsInput.length ? `comments-input-controls-active` : ''}`}>
                                                            <div
                                                                className={`comments-input-control-sendmsg`}
                                                                onClick={() => HandleAddComment()}
                                                            >
                                                                <div className="icon" dangerouslySetInnerHTML={{ __html: Icons.general.send_message }}></div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                            <div className="create-client_invoicefooter">
                                <div className="footer-item action-items">

                                    <div className="action-cancel" onClick={(e) => HandleCancel(e)}>
                                        Cancel
                                    </div>
                                    <button
                                        className={`action-btn`}
                                        type='submit'
                                        ref={formSubmitBtnRef}
                                    >

                                        <div className="label">Save</div>
                                    </button>
                                </div>
                            </div>

                        </div>
                    </form>
                </div>

            </div>
        </>
    )

}

export default CreateMaterialIssue;