import React, { useEffect, useState, useRef } from "react";
import {
    Route, Routes, BrowserRouter, Link, useNavigate, useParams, useLocation
} from "react-router-dom";
import store from "../../redux/store";
import StoreHandler from "../../redux/StoreHandler";
import { useSelector, useDispatch } from "react-redux";
import UserAction from "../../redux/action/userAction";

import ReverseProxy from "../../config/reverseProxy";
import WorkTemplatesHandler from "../../Handlers/WorkTemplates/WorkTemplates";
import Icons from "../../assets/Icons";
import Images from "../../assets/Images";
import {
    CostInput, TextInput, SelectInput, SearcBarInput, RadioInput,
} from "../../components/Inputs";
import Utils from "../../utils";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

import AlertPopup from "../../components/AlertPopup";
import Loading from "../../components/Loading";
import NoDataFound from "../../components/NoDataFound";
import SystemToastPopup from '../../components/SystemToastPopup'

import AddCostCatalogItemPopup from "./AddCostCatalogItemPopup";

import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";


import { useAuth, useMenuAuth } from '../../hooks/useAuth'

const WorkTemplate = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [warningAlert, setWarningAlert] = useState(false);
    const [warningAlertType, setWarningAlertType] = useState('warning')
    const [apiFailedMessage, setApiFailedMessage] = useState("Error in Server")

    const [deleteItemId, setDeleteItem] = useState(null);
    const [deleteItemType, setDeleteItemType] = useState(null);
    const [deleteItemAlert, setDeleteItemAlert] = useState(false);

    const workTemplatesHandler = new WorkTemplatesHandler();

    const [currency_type, setCurrency_type] = useState("");

    const navigate = useNavigate();
    const store = useSelector((store) => store);
    const dispatch = useDispatch();
    const { updateState } = new UserAction();

    const storeHandler = new StoreHandler({
        dataStack: ["company"],
        store: store,
        dispatch: dispatch,
        updateState: updateState,
    });

    const LoadStoreData = async () => {
        setIsLoading(true);
        let resolved = await storeHandler.init();
        setIsLoading(false);
    };



    const { isAdmin, menu_permission } = useMenuAuth({ menu: 'master_features--work_templates', feature_key: 'work-templates' })
    const location = useLocation()

    const [selected_view_settings_value, setSelected_view_settings_value] = useState('')
    const [has_view_access, setHas_view_access] = useState(false)
    const [has_add_access, setHas_add_access] = useState(false)
    const [has_edit_access, setHas_edit_access] = useState(false)
    const [has_delete_access, setHas_delete_access] = useState(false)

    useEffect(() => {

        if (isAdmin) {

            setHas_view_access(true)
            setHas_add_access(true)
            setHas_edit_access(true)
            setHas_delete_access(true)
        }

        else if (menu_permission) {

            if (
                !menu_permission._view
                &&
                (!menu_permission.selected_view_settings_value
                    || menu_permission.selected_view_settings_value == 'no_access')
            ) {
                navigate(`${ReverseProxy['proxyUrl']}/quick-menu`, { state: { from: location }, replace: true })
            }

            if (menu_permission._view == '0') navigate(`${ReverseProxy['proxyUrl']}/builder/projects`, { state: { from: location }, replace: true })

            setHas_view_access(menu_permission._view == '1')
            setHas_add_access(menu_permission._create == '1')
            setHas_edit_access(menu_permission._edit == '1')
            setHas_delete_access(menu_permission._delete == '1')

            setSelected_view_settings_value(menu_permission.selected_view_settings_value)

        }

    }, [store])

    const [tabelHeader, setTabelHeader] = useState([
        {
            id: "select",
            label: "Select",
            minWidth: 30,
            disable: false,
        },

        {
            id: "name",
            label: "Name",
            minWidth: 400,
            disable: false,
        },
        {
            id: "actions",
            label: "Action",
            disable: false,
        },
    ]);
    const [workRateItemsOrginal, setWorkRateItemsOrginal] = useState([]);
    const [workRateItems, setWorkRateItems] = useState([]);
    const [selectedAction, setSelectedAction] = useState("");

    const [tableSelectAll, setTableSelectAll] = useState(false);

    const [existItemData, setExistItemData] = useState({});

    const [ActionDropdown, setActionDropdown] = useState([
        {
            id: "edit",
            label: "Edit",
            icon: Icons.general.edit,
            click: (row) => HandleOpenItem(row),
        },
        {
            id: "delete",
            label: "Delete",
            icon: Icons.general.delete,
            click: (row) => HandleDeleteItem(row),
        },
    ]);

    const HandleOpenItem = (item) => {
        if (!has_edit_access) return
        setExistItemData(item);
        navigate(`/builder/work-templates/${item.sno}/edit`);
    };

    const HanelCellAction = (id, row) => {
        if (id == "actions") {
            setSelectedAction(row.id);
            HandleActionDisable(row.id);
        }
    };
    const HandleActionDisable = (id) => {
        let input = document.getElementById(`estimate-tabel-action-btn-${id}`);
        let dropdown_items = document.getElementById(
            `estimate-tabel-action-dropdown-${id}`
        );

        const Event = (e) => {
            let path = e.path || (e.composedPath && e.composedPath());

            if (
                Array.isArray(path) &&
                !path.includes(dropdown_items) &&
                !path.includes(input)
            ) {
                setSelectedAction("");
                window.removeEventListener("click", Event);
            }
        };

        window.addEventListener("click", Event);
    };

    const HandleSelectAllEvent = (value) => {
        setTableSelectAll(value);

        let _workRateItems = [...workRateItems];

        _workRateItems = _workRateItems.map((item) => {
            item.selected = value;
            return item;
        });

        setWorkRateItems(_workRateItems);
    };

    const HandleSelectEvent = (group_id, parent_id, value) => {
        let _workRateItems = [...workRateItems];

        _workRateItems = _workRateItems.map((item, idx) => {
            if (item.id == group_id && item.parent_id == parent_id)
                item.selected = value;
            return item;
        });

        setWorkRateItems(_workRateItems);
    };



    const LoadWorkRateItems = async () => {
        setIsLoading(true);

        let response = await workTemplatesHandler.getWorkTemplatesHandler();
        setIsLoading(false);

        if (response.success) {
            let { data } = response;

            if (!Array.isArray(data)) return;


            setWorkRateItemsOrginal(data);
            setWorkRateItems(data);
        } else {
            setWarningAlert(true);
            setWarningAlertType('error')
            setApiFailedMessage(`Error in loading Work Templates`);
        }
    };

    useEffect(() => {
        LoadStoreData();
        LoadWorkRateItems()
    }, []);

    useEffect(() => {
        if (store && store.user) {
            let { company, settings } = store.user;

            if (company) {
                let { currency_setting, timezone, temperature_setting } = company;

                if (currency_setting) setCurrency_type(currency_setting);
            }
        }
    }, [store]);

    const HandleUpdateCallback = () => {
        LoadWorkRateItems();
    };


    const CreateWorkRateTableItem = (row, idx) => {
        let { id, disable, type, parent_id, expanded, _status } = row;

        return (
            <>
                {!disable ? (
                    <>
                        <TableRow
                            hover
                            role="checkbox"
                            tabIndex={-1}
                            key={id}
                            className={`estimate-table-row ${type == "group"
                                ? !parent_id
                                    ? "estimate-table-row-group"
                                    : "estimate-table-row-subgroup"
                                : ""
                                }`}
                        >
                            {tabelHeader.map((column) => {
                                const value = row[column.id];
                                return column.disable ? (
                                    ""
                                ) : column.id == "select" ? (
                                    <TableCell
                                        key={column.id}
                                        align={column.align}
                                        className="estimate-table-row-item"
                                        style={{ paddingLeft: "0" }}
                                    >
                                        <RadioInput
                                            props={{
                                                value: row.selected || false,
                                                isIcon: false,
                                                icon: "",
                                                inputType: "checkbox",
                                                name: "table-default-radio",
                                                setValue: (value) =>
                                                    HandleSelectEvent(id, parent_id, value),
                                            }}
                                        />
                                    </TableCell>
                                ) : (
                                    <TableCell
                                        key={column.id}
                                        align={column.align}
                                        style={{ paddingLeft: "0" }}
                                        onClick={(e) => {

                                            if (column.id != "actions")
                                                HanelCellAction(column.id, row);
                                        }}
                                        className="estimate-table-row-item"
                                    >
                                        {column.id == "name" ? (
                                            <div
                                                className="tabel-id"
                                                onClick={(e) => HandleOpenItem(row)}
                                            >
                                                {column.format ? column.format(value) : value}
                                            </div>
                                        )
                                            : column.id == "status" ? (
                                                <div className="tabel-status-main">
                                                    <span
                                                        className={`tabel-status-span status-span-${value}`}
                                                    ></span>
                                                    <div
                                                        style={{ display: "none" }}
                                                        className="tabel-status"
                                                    >
                                                        {column.format ? column.format(value) : value}
                                                    </div>
                                                </div>
                                            ) : column.id == "actions" && has_edit_access ? (
                                                <div className="tabel-action-main">
                                                    <div
                                                        className="action-icon estimate-tabel-action-btn"
                                                        id={`estimate-tabel-action-btn-${row.id}`}
                                                        dangerouslySetInnerHTML={{
                                                            __html: Icons.general.dotted_action,
                                                        }}
                                                        onClick={(e) => HanelCellAction(column.id, row)}
                                                    ></div>
                                                    <div
                                                        className={`action-dropdown estimate-tabel-action-dropdown ${selectedAction == row.id
                                                            ? "action-dropdown-active"
                                                            : ""
                                                            }`}
                                                        id={`estimate-tabel-action-dropdown${row.id}`}
                                                    >
                                                        {ActionDropdown.map(option => (


                                                            !has_edit_access ? '' :
                                                                option.id == 'delete' && !has_delete_access ? '' :

                                                                    <div
                                                                        className="action-dropdown-item"
                                                                        key={`proposals-action${option.id}`}
                                                                        onClick={(e) => {
                                                                            setSelectedAction('')
                                                                            if (option.id == 'edit') HandleOpenItem(row)
                                                                            else if (option.id == 'delete') HandleDeleteItem(row)

                                                                        }}
                                                                    >
                                                                        <div
                                                                            className="icon"
                                                                            dangerouslySetInnerHTML={{ __html: option.icon }}
                                                                        ></div>
                                                                        <div className="label">{option.label}</div>
                                                                    </div>
                                                        ))}
                                                    </div>
                                                </div>
                                            ) : column.format ? (
                                                column.format(value)
                                            ) : (
                                                value
                                            )}
                                    </TableCell>
                                );
                            })}
                        </TableRow>
                    </>
                ) : (
                    ""
                )}
            </>
        );
    };

    const HandleDeleteItem = async (item) => {

        if (!has_edit_access || !has_delete_access) return
        setDeleteItem(item.id);
        setDeleteItemType(item.type);
        setDeleteItemAlert(true);
    };



    const HandleDeleteConfirmItem = async (id, type, confirmation) => {
        console.log(confirmation, "confirmation");
        if (!confirmation || !id) {
            setDeleteItem(null);
            setDeleteItemType(null);
            setDeleteItemAlert(false);

            return;
        }
        let filter = {
            id
        };

        setIsLoading(true);

        let response = await workTemplatesHandler.deleteWorkTemplateHandler(
            filter
        );
        setIsLoading(false);

        if (response.success) {
            LoadWorkRateItems();
        } else {
            setWarningAlert(true);
            setWarningAlertType('error')
            setApiFailedMessage(`Error in deleting Work Templates`);
        }
        setDeleteItem(null);
        setDeleteItemType(null);
        setDeleteItemAlert(false);
    };

    const HandleAddBtn = (e) => {
        if (!has_add_access) return
        navigate(`/builder/work-templates/add`);
    };
    const getDeleteMessage = (type) => {
        if (type == "group")
            return `Are you sure you want to delete the Group? Items included in the group will be deleted accordingly.`;
        else if (type == "work") return `Are you sure you want to delete the Work?`;
        else if (type == "item") return `Are you sure you want to delete the Item?`;
        else return "Are you sure you want to delete?";
    };

    const [Filters, setFilters] = useState({
        left: [
            {
                id: "estimate-multiselect",
                type: "icon",
                isSelectToggle: true,
                value: false,
                placeholder: "",
                callback: (val) => HandleFiltersEvent(val, "estimate-multiselect"),
                isIcon: true,
                icon: Icons.general.multi_select,
                isLabel: false,
                label: "",
                isIconLeft: false,
            },
            {
                id: "estimate-search",
                value: "",
                isSearchBar: true,
                type: "input",
                isDropDownContainer: false,
                placeholder: "Search by name",
                dropDownOptions: [],
                callback: (val) => { },

                isIcon: true,
                icon: Icons.general.search,
                isLabel: false,
                label: "",
                isIconLeft: false,
            },
        ],
        right: [
        ],
    });
    const HandleFiltersEvent = async (value, filter_id) => {
        if (!filter_id) return;

        if (filter_id == "estimate-multiselect") {
            let _tabelHeader = [...tabelHeader];

            _tabelHeader[0].disable = !_tabelHeader[0].disable;

            setTabelHeader(_tabelHeader);
        } else if (filter_id == "estimate-search") {
            let filters = { ...Filters };

            filters.left[1].value = value;

            let _workRateItems = [...workRateItems];

            let search_items = (items, value, column = "name") => {
                let search_item = (items, value, column = "name") => {
                    items = items.map((item) => {
                        let is_item_match = String(item[column])
                            .toLocaleLowerCase()
                            .includes(String(value).toLocaleLowerCase());

                        if (Array.isArray(item.sub_items))
                            item.sub_items = search_item(item.sub_items, value, column);

                        let is_items_match =
                            item.sub_items.filter((s) => !s.disable).length > 0;

                        if (is_items_match) item.disable = false;
                        else if (is_item_match) item.disable = false;
                        else item.disable = true;

                        return item;
                    });

                    return items;
                };

                items = search_item(items, value, column);
                return items;
            };

            _workRateItems = search_items(_workRateItems, value, "name");

            setWorkRateItems(_workRateItems);

            setFilters(filters);
        }
    };

    const isLeftFilter = Array.isArray(Filters.left) && Filters.left.length > 0;
    const isRightFilter =
        Array.isArray(Filters.right) && Filters.right.length > 0;

    const Icon_Filter = ({ props }) => {
        const {
            id,
            isSearchBar,
            type,
            value,
            isSelectToggle,
            isDropDownContainer,
            dropDownOptions,
            placeholder,
            callback,
            isIcon,
            icon,
            isLabel,
            label,
            isIconLeft,
        } = props;

        const [iconStatus, setIconStatus] = useState(
            value != undefined ? value : false
        );

        const HandleFilterIconInput = (e) => {
            HandleFiltersEvent(!iconStatus, id);
            setIconStatus(!iconStatus);
        };

        return (
            <>
                {isIcon && type == "icon" ? (
                    <div
                        className="filter-item group-expand-filter"
                        onClick={(e) => HandleFilterIconInput(e)}
                        key={`filter-item-${id}`}
                    >
                        <div
                            className="icon"
                            dangerouslySetInnerHTML={{
                                __html: id.includes("expand")
                                    ? iconStatus
                                        ? Icons.general.collapse
                                        : Icons.general.expand
                                    : icon,
                            }}
                        ></div>
                        {isLabel ? (
                            <div className="label">
                                {id.includes("expand")
                                    ? iconStatus
                                        ? "Collapse all"
                                        : "Expand all"
                                    : label}
                            </div>
                        ) : (
                            ""
                        )}
                    </div>
                ) : null}
            </>
        );
    };
    const Button_Filter = ({ props }) => {
        const {
            id,
            isSearchBar,
            type,
            isDropDownContainer,
            dropDownOptions,
            callback,
            isIcon,
            icon,
            isLabel,
            label,
            isIconLeft,
        } = props;
        const [selectFilterOpts, setSelectFilterOpts] = useState([]);

        const [dropdownStatus, setDropDownStatus] = useState(false);

        const HandleClick = (e) => {
            setDropDownStatus(!dropdownStatus);
        };

        let HandleSubItemChange = (value, opt, i) => {
            let _filters = [...selectFilterOpts];

            if (_filters[i].id == "opt-select-all") {
                _filters = _filters.map((f) => {
                    f.value = value;

                    return f;
                });
            } else _filters[i].value = value;

            setSelectFilterOpts(_filters);

            HandleFiltersEvent(opt, id);
        };

        useEffect(() => {
            let items_parent = document.querySelector(`#${id}`);

            window.addEventListener("click", (e) => {
                let path = e.path || (e.composedPath && e.composedPath());

                if (Array.isArray(path) && !path.includes(items_parent))
                    setDropDownStatus(false);
            });
            setSelectFilterOpts(dropDownOptions);
        }, []);

        return (
            <div
                className="filter-item dropdown-select-filter"
                id={id}
                key={`filter-item-${id}`}
            >
                <div className="button" onClick={(e) => HandleClick(e)}>
                    {isIcon && isIconLeft ? (
                        <span
                            className={`icon ${dropdownStatus ? "icon-active" : ""}`}
                            dangerouslySetInnerHTML={{ __html: icon }}
                        ></span>
                    ) : null}

                    {isLabel ? <span class="label">{label}</span> : null}

                    {isIcon && !isIconLeft ? (
                        <span
                            className={`icon ${dropdownStatus ? "icon-active" : ""}`}
                            dangerouslySetInnerHTML={{ __html: icon }}
                        ></span>
                    ) : null}
                </div>

                {isDropDownContainer ? (
                    <div
                        className={`dropdown-select-items ${dropdownStatus ? "dropdown-select-items-active" : ""
                            }`}
                    >
                        {selectFilterOpts.map((opt, i) =>
                            opt.type == "label" ? (
                                <div className="dropdown-select-title">{opt.label}</div>
                            ) : (
                                <div className="dropdown-select-itm">
                                    <RadioInput
                                        props={{
                                            id: `filters-item-select-${opt.id}`,
                                            value: opt.value,
                                            isIcon: false,
                                            icon: "",
                                            inputType: "checkbox",
                                            name: "table-default-radio",
                                            setValue: (value) => HandleSubItemChange(value, opt, i),
                                        }}
                                    />
                                    <label
                                        className="label"
                                        htmlFor={`filters-item-select-${opt.id}`}
                                    >
                                        {opt.label}
                                    </label>
                                </div>
                            )
                        )}
                    </div>
                ) : null}
            </div>
        );
    };

    return (
        <>
            {isLoading ? (
                <Loading
                    props={{
                        isMainLogo: false,
                        isLabel: true,
                    }}
                />
            ) : null}

            {warningAlert ? (
                <SystemToastPopup
                    props={{
                        type: warningAlertType,
                        message: apiFailedMessage || "Error in Schedule Rates items",
                        callback: (confirmation) => setWarningAlert(false)
                    }} />
            ) : null}

            {deleteItemAlert ? (
                <AlertPopup
                    props={{
                        type: "delete",
                        actionBtnOption: {
                            icon: Icons.general.btn_delete,
                            label: "Yes, Delete",
                        },
                        heading: "Delete item",
                        message: getDeleteMessage(deleteItemType),
                        callback: (confirmation) =>
                            HandleDeleteConfirmItem(
                                deleteItemId,
                                deleteItemType,
                                confirmation
                            ),
                    }}
                />
            ) : null}

            <Routes>
                <Route
                    path="/:itemId/:actionType/*"
                    element={
                        <AddCostCatalogItemPopup
                            props={{
                                existItemData: existItemData || undefined,
                                updateCallback: HandleUpdateCallback,
                            }}
                        />
                    }
                ></Route>
                <Route
                    path="/:actionType/*"
                    element={
                        <AddCostCatalogItemPopup
                            props={{
                                existItemData: undefined,
                                updateCallback: HandleUpdateCallback,
                            }}
                        />
                    }
                ></Route>
            </Routes>

            <div className="project-project_estimate-main">
                <div className="project_estimate-panel-content">
                    <div className="panel-content-header">
                        <div className="title">Work Templates</div>
                        <div className="buttons">
                            {has_add_access ?
                                <div
                                    className="button add-button"
                                    onClick={(e) => HandleAddBtn(e)}
                                    id="pd_estimate-addbtn"
                                >
                                    <div
                                        className="icon"
                                        dangerouslySetInnerHTML={{ __html: Icons.general.add_btn }}
                                    ></div>
                                    <div className="label">Add New</div>
                                </div>
                                : ''}
                        </div>
                    </div>
                    <div className="filter-section-main">
                        {isLeftFilter ? (
                            <div className="left-filter-main">
                                {Filters.left.map((item, i) =>
                                    item.type == "icon" ? (
                                        <Icon_Filter props={item} />
                                    ) : item.type == "button" ? (
                                        <Button_Filter props={item} />
                                    ) : item.isSearchBar && item.type == "input" ? (
                                        <SearcBarInput
                                            props={{
                                                id: item.id,
                                                value: item.value,
                                                placeholder: item.placeholder || "Search by name",
                                                setValue: (val) => {
                                                    HandleFiltersEvent(val, item.id);
                                                },
                                                isIcon: item.isIcon,
                                                isLeft: item.isIconLeft,
                                                icon: item.icon || Icons.general.search,
                                            }}
                                        />
                                    ) : (
                                        ""
                                    )
                                )}
                            </div>
                        ) : (
                            ""
                        )}
                        {isRightFilter ? (
                            <div className="right-filter-main">
                                {Filters.right.map((item, i) =>
                                    item.type == "icon" ? (
                                        <Icon_Filter props={item} />
                                    ) : item.type == "button" ? (
                                        <Button_Filter props={item} />
                                    ) : item.isSearchBar && item.type == "input" ? (
                                        <SearcBarInput
                                            props={{
                                                id: item.id,
                                                value: item.value,
                                                placeholder: item.placeholder || "Search by name",
                                                setValue: (val) => {
                                                    HandleFiltersEvent(val, item.id);
                                                },
                                                isIcon: item.isIcon,
                                                isLeft: item.isIconLeft,
                                                icon: item.icon || Icons.general.search,
                                            }}
                                        />
                                    ) : (
                                        ""
                                    )
                                )}
                            </div>
                        ) : (
                            ""
                        )}
                    </div>
                    <div className="panel-content-sections">
                        {workRateItems.length ?
                            <Paper
                                style={{
                                    width: "100%",
                                    height: "100%",
                                    boxShadow: "none",
                                    overflow: "hidden",
                                }}
                            >
                                <TableContainer
                                    style={{ height: "100%", boxShadow: "none !important" }}
                                >
                                    <Table stickyHeader>
                                        <TableHead className="tabel-header estimate-tabel-header">
                                            <TableRow>
                                                {tabelHeader
                                                    .filter((th) => !th.disable)
                                                    .map((column) => (
                                                        <TableCell
                                                            key={column.id}
                                                            align={column.align}
                                                            style={{
                                                                minWidth: column.minWidth,
                                                                paddingLeft: "0",
                                                            }}
                                                            className="tabel-header-items"
                                                        >
                                                            {column.id == "select" ? (
                                                                <RadioInput
                                                                    props={{
                                                                        value: tableSelectAll || false,
                                                                        isIcon: false,
                                                                        icon: "",
                                                                        inputType: "checkbox",
                                                                        name: "table-default-radio",
                                                                        setValue: (value) =>
                                                                            HandleSelectAllEvent(value),
                                                                    }}
                                                                />
                                                            ) : (
                                                                column.label
                                                            )}
                                                        </TableCell>
                                                    ))}
                                            </TableRow>
                                        </TableHead>
                                        <TableBody className='tabel-body'>
                                            {workRateItems.map((row, i) =>
                                                CreateWorkRateTableItem(row, i)
                                            )}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Paper>
                            : <NoDataFound label={'No Templates Found'} />}
                    </div>
                </div>
            </div>
        </>
    );
};

export default WorkTemplate;
