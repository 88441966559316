

import React, { useEffect, useState, useRef } from 'react'
import { Route, Routes, BrowserRouter, Link, useLocation, Navigate, Outlet, useNavigate, useParams } from 'react-router-dom';
import store from '../../redux/store'
import StoreHandler from '../../redux/StoreHandler'
import { useSelector, useDispatch } from 'react-redux'
import UserAction from '../../redux/action/userAction'

import ReverseProxy from '../../config/reverseProxy'
import ProjectsHandler from '../../Handlers/Projects/Projects';
import Icons from '../../assets/Icons'
import Images from '../../assets/Images'
import { CostInput, TextInput, ColorInput, SelectInput, DateTimeInput, RadioInput } from '../../components/Inputs'
import Utils from '../../utils'
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

import AlertPopup from '../../components/AlertPopup'
import SystemToastPopup from '../../components/SystemToastPopup'
import Loading from '../../components/Loading'

const AddPhaseGroupPopup = ({ props }) => {


    const {
        type,
        phases_groups,
        close_callback,
        submit_callback,
    } = props

    const { projectId } = useParams();

    const [isLoading, setIsLoading] = useState(false)
    const [warningAlert, setWarningAlert] = useState(false)
    const [warningAlertType, setWarningAlertType] = useState('warning')
    const [apiFailedMessage, setApiFailedMessage] = useState('Error in Adding Project User')

    const navigate = useNavigate()
    const store = useSelector((store) => store)
    const dispatch = useDispatch()
    const { updateState } = new UserAction

    const storeHandler = new StoreHandler({
        dataStack: [''],
        store: store,
        dispatch: dispatch,
        updateState: updateState,
    })

    const LoadStoreData = async () => {
        // setIsLoading(true)
        let resolved = await storeHandler.init()
        // setIsLoading(false)
    }

    const [PhasesGroups, setPhasesGroups] = useState([...phases_groups])

    const HandleCancelCallback = () => {
        close_callback()
    }


    const HandleDeleteGroup = (index) => {
        let _PhasesGroups = [...PhasesGroups]
        _PhasesGroups.splice(index, 1)
        setPhasesGroups(_PhasesGroups)

    }
    const HandleAddMoreGroups = () => {
        let _PhasesGroups = [...PhasesGroups]
        _PhasesGroups.push({
            id: Utils.getUniqueId(),
            name: '',
            count: 0,
        })
        setPhasesGroups(_PhasesGroups)
    }
    const HandleInputChange = (index, key, value) => {
        let _PhasesGroups = [...PhasesGroups]

        _PhasesGroups[index][key] = value

        setPhasesGroups(_PhasesGroups)
    }

    const HandleSubmit = (e) => {
        e.preventDefault()

        let _PhasesGroups = PhasesGroups.filter(user => user.id && user.name)

        submit_callback(_PhasesGroups)
    }



    useEffect(() => {
        if (!PhasesGroups.length) HandleAddMoreGroups()
    }, [])

    return (
        <>
            {isLoading ?
                <Loading
                    props={{
                        isMainLogo: false,
                        isLabel: true
                    }} />
                : null}
            {warningAlert ?

                <SystemToastPopup
                    props={{
                        type: warningAlertType,
                        message: apiFailedMessage || "Error in Create Projects",
                        callback: (confirmation) => setWarningAlert(false)
                    }} />

                : null}

            <div className="popup-container-main popup-container-center">
                <div className="popup-block-ui"></div>
                <form
                    className="side-popup-container side-add_project_users-popup-container"
                    onSubmit={(e) => HandleSubmit(e)}
                >
                    <div className="side-popup-header">
                        <div className="header-item-select">
                            <div className="header-item-select-content">
                                <div className="label">Phases Groups</div>

                            </div>
                        </div>
                        <div
                            className="header-item-close"
                            onClick={(e) => HandleCancelCallback()}
                            dangerouslySetInnerHTML={{ __html: Icons.general.close }}
                        ></div>
                    </div>
                    <div className="sidebar-popup-content">
                        <div className="add-project_user-popup-content">
                            <div className="add-project_user-content-items">
                                {PhasesGroups.map((user, index) => (
                                    <div
                                        className="add-project_user-content-item project-phases-add_group-item"
                                        key={`project-user-${index}`}
                                        style={{
                                            justifyContent: 'flex-start'
                                        }}
                                    >
                                        <div className="add-project_user-content-item-index">{index + 1}.</div>
                                        <div
                                            className="add-project_user-content-item-input"
                                            style={{
                                                width: '80%'
                                            }}
                                        >
                                            <TextInput
                                                props={{
                                                    id: "add-project_user-user-contact_person_name",
                                                    value: user.name,
                                                    placeholder: 'Enter group name',
                                                    setValue: (value, label) => HandleInputChange(index, 'name', value),
                                                    isIcon: false,
                                                    isLabel: false,
                                                    isRequired: true,
                                                    label: "",
                                                }}
                                            />


                                        </div>

                                        {PhasesGroups.length <= 1 ? '' :
                                            <div
                                                className="add-project_user-content-item-delete"
                                                onClick={(e) => HandleDeleteGroup(index)}
                                                dangerouslySetInnerHTML={{ __html: Icons.general.delete_btn }}
                                            ></div>
                                        }
                                    </div>

                                ))}

                            </div>
                            <div className="add-project_user-content-actions">
                                <div
                                    className="project-team-add_user-btn"
                                    onClick={(e) => HandleAddMoreGroups()}
                                >
                                    <div className="icon"
                                        dangerouslySetInnerHTML={{ __html: Icons.general.add_btn }}
                                    ></div>
                                    <div className="label">Add more groups</div>
                                </div>
                            </div>
                        </div>

                    </div>
                    <div className="sidebar-popup-footer">
                        <div className="footer-item action-items">
                            <div className="action-preview">
                            </div>
                            <button
                                className="action-btn"
                                type='submit'
                            >
                                <div className="icon" dangerouslySetInnerHTML={{ __html: Icons.general.add_btn }}></div>
                                <div className="label">Create</div>
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </>
    )
}

export default AddPhaseGroupPopup;