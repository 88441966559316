
import proxyConfig from '../../config/reverseProxy'
import HeaderConfig from '../../config/header'

class StockItems {

    constructor() {

    }



    async getStockItemsHandler(params) {

        try {

            let response = await fetch(proxyConfig['serverBaseUrl'] + '/builder/stock-items/get-stock-items', {
                method: 'POST',
                headers: HeaderConfig.getHeader({ method: 'POST', isToken: true }),
                body: JSON.stringify(params)
            })

            response = await response.json()

            if (response) return response
        } catch {
            return { success: false }
        }


    }

    async getActual_vs_EstimatedHandler(params) {

        try {

            let response = await fetch(proxyConfig['serverBaseUrl'] + '/builder/stock-items/get-actual_vs_estimated-items', {
                method: 'POST',
                headers: HeaderConfig.getHeader({ method: 'POST', isToken: true }),
                body: JSON.stringify(params)
            })

            response = await response.json()

            if (response) return response
        } catch {
            return { success: false }
        }


    }


}

export default StockItems;