import React, { useEffect, useState, useRef } from 'react'
import { Route, Routes, BrowserRouter, Link, useLocation, Navigate, Outlet, useNavigate, useParams } from 'react-router-dom';

import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';

import Icons from '../assets/Icons'
import Images from '../assets/Images'

const Preloader = ({ props }) => {

    const { delay, setPreloaderStatus } = props

    const [progress_value, setProgress_value] = useState(0)

    useEffect(() => {

        setTimeout(() => {
            setProgress_value(25)
            setTimeout(() => {
                setProgress_value(60)
                setTimeout(() => {
                    setProgress_value(100)
                    setTimeout(() => {
                        setPreloaderStatus(false)
                    }, delay / 2)
                }, delay)
            }, delay)
        }, delay)


    }, [])
    return (
        <div className="project-preloader-main">
            <div className="preloader-content">
                <div className="logo">
                    <img src={Images.logo} />
                </div>
                <div className="progress-bar">
                    <span className='progress-bar-value' style={{ width: `${progress_value}%` }}></span>
                </div>
            </div>
        </div>
    )
}

export default Preloader;