

import React, { useEffect, useState, useRef, useCallback, useMemo, StrictMode } from 'react'
import { Route, Routes, BrowserRouter, Link, useLocation, Navigate, Outlet, useNavigate, useParams } from 'react-router-dom';
import store from '../../redux/store'
import StoreHandler from '../../redux/StoreHandler'
import { useSelector, useDispatch } from 'react-redux'
import UserAction from '../../redux/action/userAction'

import ReverseProxy from '../../config/reverseProxy'
import ChangeOrdersHandler from '../../Handlers/ChangeOrders/ChangeOrders';
import CommentsHandler from '../../Handlers/Comments/Comments';

import Icons from '../../assets/Icons'
import Images from '../../assets/Images'
import { CostInput, TextInput, SelectInput, DateTimeInput, RadioInput, MultiSelectInput } from '../../components/Inputs'
import Utils from '../../utils'
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

import AlertPopup from '../../components/AlertPopup'
import SystemToastPopup from '../../components/SystemToastPopup'
import Loading from '../../components/Loading'

import PreviewChangeOrders from './previewChangeOrders'
import BuilderAttachments from '../../Handlers/BuilderAttachments/BuilderAttachments';

import ImagePreviewPopup from '../../components/ImagePreviewPopup'


import { useAuth, useMenuAuth } from '../../hooks/useAuth'
const AddPaymentHistory = ({ props }) => {

    const { type, existItemData, max_amount, updateCallback, close } = props


    const { projectId } = useParams()

    const [date, setDate] = useState(Utils._getLocalDate())
    const [amount, setAmount] = useState('')
    const [payment_method, setPayment_method] = useState('')
    const [notes, setNotes] = useState('')

    const navigate = useNavigate()
    const store = useSelector((store) => store)
    const dispatch = useDispatch()
    const { updateState } = new UserAction


    const isExistItemData = existItemData && existItemData.id != undefined
    const [paymentMenthodOptions, setPaymentMenthodOptions] = useState([
        {
            value: 'Bank transfer',
            label: 'Bank transfer'
        },
        {
            value: 'Direct Deposit',
            label: 'Direct Deposit'
        },
        {
            value: 'Credit card',
            label: 'Credit card'
        },
        {
            value: 'Cash',
            label: 'Cash'
        },
        {
            value: 'Check',
            label: 'Check'
        },
        {
            value: 'UPI',
            label: 'UPI'
        },
        {
            value: 'Other',
            label: 'Other'
        },
    ])


    const HandlePopupSubmit = async (e) => {
        e.preventDefault()

        let updated_data = {
            id: '',
            date,
            amount,
            payment_method,
            notes,

        }

        if (type == 'edit' && isExistItemData && existItemData.id) {
            updated_data.id = existItemData.id
        }
        else { updated_data.id = Utils.getUniqueId() }

        console.log(updated_data, 'updated_data');

        updateCallback(updated_data)


    }


    return (
        <div className="popup-container-main popup-container-center">
            <div className="popup-block-ui"></div>
            <form className="side-popup-container create-payment_history-main" onSubmit={HandlePopupSubmit}>

                <div className="side-popup-header">
                    <div className="header-item-select">
                        <div className="header-item-select-content">
                            <div className="label">Record Payments</div>

                        </div>
                    </div>
                    <div
                        className="header-item-close"
                        onClick={(e) => close()}
                        dangerouslySetInnerHTML={{ __html: Icons.general.close }}
                    ></div>
                </div>

                <div className="sidebar-popup-content">
                    <div className="content-section">
                        <div className="content-section-items">


                            <div className="content-item">
                                <DateTimeInput
                                    props={{
                                        id: "create-paymenthistory-date",
                                        value: date,
                                        placeholder: '',
                                        setValue: (value) => {
                                            setDate(value)
                                        },
                                        isIcon: false,
                                        isLabel: true,
                                        icon: Icons.general.schedule,
                                        isRequired: true,
                                        label: "Date",
                                    }}
                                />
                            </div>
                            <div className="content-item">
                                <TextInput
                                    props={{
                                        id: "create-paymenthistory-amount",
                                        value: amount,
                                        placeholder: '',
                                        setValue: (value) => setAmount(value),
                                        isIcon: false,
                                        isLabel: true,
                                        isRequired: true,
                                        type: 'number',
                                        setMaxLimit: max_amount || 0,
                                        maxLimitLabel: true,
                                        label: "Amount",
                                    }}
                                />
                            </div>
                            <div className="content-item">
                                <SelectInput
                                    props={{
                                        id: "estimation-group-group",
                                        value: payment_method,
                                        placeholder: '',
                                        options: paymentMenthodOptions || [],
                                        setValue: (value, label) => {
                                            setPayment_method(value)
                                        },
                                        isIcon: false,
                                        isLabel: true,
                                        isRequired: true,
                                        label: "Payment method",
                                    }}
                                />

                            </div>
                        </div>
                        <div className="content-section">
                            <div className="content-item">
                                <div id="group-description" className={`additional-item additional-item-active`}>
                                    <div className="head">
                                        <div className="icon" dangerouslySetInnerHTML={{ __html: Icons.general.description }}></div>
                                        <div className="label">Notes</div>
                                    </div>
                                    <div className="item-expanded">
                                        <textarea onChange={(e) => setNotes(e.target.value)}>{notes}</textarea>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>

                <div className="sidebar-popup-footer">
                    <div className="footer-item action-items">
                        <div className="action-preview">
                        </div>
                        <button
                            className="action-btn"
                            type='submit'
                        >

                            <div className="label">Record payment</div>
                        </button>
                    </div>
                </div>
            </form>
        </div>
    )
}

const ViewChangeOrders = ({ props }) => {

    const { projectId, changeOrderId } = useParams();

    const { menuType = 'create' } = props

    const [project_id, setProject_id] = useState('')
    const [project_name, setProject_name] = useState('')

    const [isLoading, setIsLoading] = useState(false)
    const [warningAlert, setWarningAlert] = useState(false)
    const [warningAlertType, setWarningAlertType] = useState('warning')
    const [apiFailedMessage, setApiFailedMessage] = useState('Error in Change Orders')

    const navigate = useNavigate()
    const store = useSelector((store) => store)
    const dispatch = useDispatch()
    const { updateState } = new UserAction

    const formSubmitBtnRef = useRef()

    const storeHandler = new StoreHandler({
        dataStack: ['team_users'],
        store: store,
        dispatch: dispatch,
        updateState: updateState,
    })

    const LoadStoreData = async () => {
        setIsLoading(true)
        let resolved = await storeHandler.init()
        setIsLoading(false)
    }
    const { isAdmin, menu_permission } = useMenuAuth({ menu: 'finance--changes_orders', feature_key: 'change_order' })
    const location = useLocation()

    const [selected_view_settings_value, setSelected_view_settings_value] = useState('')
    const [has_view_access, setHas_view_access] = useState(false)
    const [has_add_access, setHas_add_access] = useState(false)
    const [has_edit_access, setHas_edit_access] = useState(false)
    const [has_delete_access, setHas_delete_access] = useState(false)

    useEffect(() => {

        if (isAdmin) {

            setHas_view_access(true)
            setHas_add_access(true)
            setHas_edit_access(true)
            setHas_delete_access(true)
        }

        else if (menu_permission) {

            if (
                !menu_permission._view
                &&
                (!menu_permission.selected_view_settings_value
                    || menu_permission.selected_view_settings_value == 'no_access')
            ) {
                navigate(`${ReverseProxy['proxyUrl']}/quick-menu`, { state: { from: location }, replace: true })
            }
            setHas_view_access(menu_permission._view == '1')
            setHas_add_access(menu_permission._create == '1')
            setHas_edit_access(menu_permission._edit == '1')
            setHas_delete_access(menu_permission._delete == '1')

            setSelected_view_settings_value(menu_permission.selected_view_settings_value)

        }

    }, [store])

    const changeOrdersHandler = new ChangeOrdersHandler()
    const commentsHandler = new CommentsHandler()
    const builderAttachments = new BuilderAttachments()

    const [costcodeOptions, setCostcodeOptions] = useState([])
    const [unitsOptions, setUnitsOptions] = useState([])
    const [currency_type, setCurrency_type] = useState('₹')

    const [id, setId] = useState(Utils.getUniqueId())
    const [Title, setTitle] = useState('')
    const [ChangeOrderID, setChangeOrderID] = useState('')
    const [status, setStatus] = useState('')

    const [CODate, setCODate] = useState(Utils._getLocalDate())
    const [DueDate, setDueDate] = useState('')

    const [ChangeOrderTotal, setChangeOrderTotal] = useState(0)
    const [ChangeOrderPaid, setChangeOrderPaid] = useState(0)
    const [ChangeOrderDue, setChangeOrderDue] = useState(0)

    const [Description, setDescription] = useState('')
    const [comments, setComments] = useState('')

    const [Phase_id, setPhase_id] = useState('')
    const [Phase_name, setPhase_name] = useState('')
    const [Client_id, setClient_id] = useState('')
    const [Client_name, setClient_name] = useState('')

    const getStatusLabel = (status) => {

        let statusLabel = StatusOptions.filter(opt => opt.value == status)
        if (statusLabel.length) return statusLabel[0].label
        else return ''
    }

    const [StatusOptions, setStatusOptions] = useState([
        {
            value: 0,
            label: "Pending",
            isDefault: false
        },
        {
            value: 1,
            label: "Approved",
            isDefault: false
        },
        {
            value: 2,
            label: "Rejected",
            isDefault: false
        },
    ])

    const [paymentHistory, setPaymentHistory] = useState([])
    const [paymentHistoryStatusOptions, setPaymentHistoryStatusOptions] = useState([
        {
            value: 'draft',
            label: "Draft",
        },
        {
            value: 'send',
            label: "Send",
        },
        {
            value: 'paid',
            label: "Paid",
        },
        {
            value: 'void',
            label: "Void",
        },
    ])
    const [showAddpaymentHistory, setShowAddpaymentHistory] = useState(false)
    const [paymentHistoryAlert, setPaymentHistoryAlert] = useState(false)
    const [paymentHistoryMessage, setPaymentHistoryMessage] = useState('By recording payment method the status of the item will be changed to approved')


    const [termsConditionOptions, setTermsConditionOptions] = useState([])
    const [termsConditionId, setTermsConditionId] = useState("")
    const [termsConditionValue, setTermsConditionValue] = useState("")

    const [AssigneeOptions_Orginal, setAssigneeOptions_Orginal] = useState([])
    const [AssigneeOptions, setAssigneeOptions] = useState([])
    const [SelectedAssignees_Res, setSelectedAssignees_Res] = useState([])
    const [SelectedAssignees, setSelectedAssignees] = useState([])

    const HandleTermsConditions = (value, label) => {

        setTermsConditionId(value)

        let selected_TC = termsConditionOptions.filter(tc => tc.value == value)[0]
        if (selected_TC) setTermsConditionValue(selected_TC.body)
    }

    const [RecordLoaded, setRecordLoaded] = useState(false)


    const [showCreatePreview, setShowCreatePreview] = useState(false)

    const PandlepreviewChangeOrder = (e) => {

        if (menuType == 'create') setShowCreatePreview(true)
        else navigate(`/builder/projects/${projectId}/change_orders/${changeOrderId}/view/preview`)

    }
    const PandleEditChangeOrder = (e) => {
        if (!has_edit_access) return
        if (menuType == 'create') setShowCreatePreview(true)
        else navigate(`/builder/projects/${projectId}/change_orders/${changeOrderId}/edit`)

    }
    const HandleCancel = (e) => {

        navigate(`/builder/projects/${projectId}/change_orders`)
    }

    const HandleSubmit = async (e) => {
        e.preventDefault()

        let update_detials = {
            id: id,
            name: Title,
            changeOrderId: ChangeOrderID,
            projectId: projectId,

            date: CODate,
            due_date: DueDate,
            status: String(status),

            change_order_total: String(ChangeOrderTotal || 0),
            change_order_paid: String(ChangeOrderPaid || 0),
            change_order_due: String(ChangeOrderDue || 0),

            description: Description || "",

            attachments: AttachmentsFiles || [],
            payment_history: paymentHistory || [],


            terms_condition_id: termsConditionId || "",
            terms_condition: termsConditionValue || "",

        }


        let response = { success: false }

        if (menuType == 'edit') {

            update_detials["id"] = id
            response = await changeOrdersHandler.updateChangeOrderHandler(update_detials)

        } else {
            response = await changeOrdersHandler.createChangeOrderHandler(update_detials)

            let update_data = {
                project_id: projectId,
                parent_id: ChangeOrderID,
                parent_name: Title || `Change Order ${ChangeOrderID}`,
                parent_type: String(0),
                comments: CommentsItems
            }

            let comments_response = await commentsHandler.createMultiCommentHandler(update_data)

            if (!comments_response.success) {
                setWarningAlert(true)
                setWarningAlertType('error')
                setApiFailedMessage(`Error in Comments, Please try again!`)
            }
        }

        if (response.success) {
            setIsLoading(false)
            navigate(`/builder/projects/${projectId}/change_orders`)
        }
        else {
            setIsLoading(false)
            setWarningAlert(true)
            setWarningAlertType('error')
            setApiFailedMessage(`Error in Change Orders, Please try again!`)
        }
    }

    const getNextProjectID = async () => {

        let response = await changeOrdersHandler.getNextChangeOrdersIDHandler()

        if (response && response.success) {

            let { changeOrderId } = response.data

            if (changeOrderId) setChangeOrderID(changeOrderId)

        } else {
            setWarningAlert(true)
            setWarningAlertType('error')
            setApiFailedMessage(`Error in Change Orders, Please try again!`)
        }
    }


    const LoadChangeOrder = async () => {

        let filter = {
            changeOrderId: changeOrderId,
            projectId: projectId,
        }

        setIsLoading(true)

        let response = await changeOrdersHandler.getChangeOrderssHandler(filter)
        setIsLoading(false)

        if (response.success) {
            let { data } = response

            if (!Array.isArray(data) || !data.length) {
                return navigate(`/builder/projects/${projectId}/change_orders`)
            }


            let {
                id: ID,
                name,
                changeOrderId,
                projectId: projectID,
                phase_id,
                phase_name,
                client_id,
                client_name,
                date,
                due_date,
                status,
                change_order_total,
                change_order_paid,
                change_order_due,
                payment_history,
                description,
                terms_condition_id,
                terms_condition,
                attachments,
                assignees,
            } = data[0]

            setId(ID)
            setChangeOrderID(changeOrderId)
            setTitle(name)
            setStatus(status)
            setDescription(description)

            setPhase_id(phase_id)
            setPhase_name(phase_name)
            setClient_id(client_id)
            setClient_name(client_name)

            setTermsConditionId(terms_condition_id)
            setTermsConditionValue(terms_condition)

            setCODate(date)
            setDueDate(due_date)

            setChangeOrderTotal(change_order_total)
            setChangeOrderPaid(change_order_paid)
            setChangeOrderDue(change_order_due)
            setPaymentHistory(payment_history || [])
            setAttachmentsFiles(attachments || [])
            setSelectedAssignees_Res(assignees || [])

            setRecordLoaded(true)

        }
    }

    useEffect(() => {

        if (menuType == 'create') {
            getNextProjectID()
        }
        else {
            LoadChangeOrder()

            LoadComments({ project_id: projectId, parent_id: changeOrderId })
        }

        LoadStoreData()
    }, [])

    useEffect(() => {

        if (AssigneeOptions.length && Array.isArray(SelectedAssignees_Res) && SelectedAssignees_Res.length) {

            let select_assignee = SelectedAssignees_Res.map(p => {
                let assignee = AssigneeOptions.filter(ph => ph.value == p)[0]

                if (!assignee) return p
                return assignee
            })

            setSelectedAssignees(select_assignee)

        }

    }, [AssigneeOptions, SelectedAssignees_Res])


    useEffect(() => {

        let paid_amount = paymentHistory.reduce((prev, val, i) => {

            return prev + parseFloat(val.amount || 0)
        }, 0)
        setChangeOrderPaid(paid_amount)

        let due_amount = ChangeOrderTotal - paid_amount
        due_amount = due_amount < 0 ? 0 : due_amount
        setChangeOrderDue(due_amount)


    }, [ChangeOrderTotal, paymentHistory])

    useEffect(() => {

        if (store && store.user) {

            let { team_users } = store.user

            if (Array.isArray(team_users)) {

                let _team_users = team_users.filter(opt => opt.status == '1').map((opt) => {
                    return {
                        value: opt.id,
                        label: opt.name,
                    }
                })

                setAssigneeOptions(_team_users)
                setAssigneeOptions_Orginal(team_users)
            }
        }

    }, [store])




    const [ShowSelectedPreviewImage, setShowSelectedPreviewImage] = useState(false)
    const [SelectedPreviewImage, setSelectedPreviewImage] = useState(undefined)
    const AttachmentsInputRef = useRef()
    const [AttachmentsFiles, setAttachmentsFiles] = useState([])

    const HandleTriggerAddAttachment = (e) => {
        if (AttachmentsInputRef.current) AttachmentsInputRef.current.click()
    }
    const HandleAddAttachment = async (e) => {

        let file = e.target.files[0]

        if (!file) return

        const formData = new FormData();

        formData.append('projectId', projectId);
        formData.append('attachment', file);
        formData.append('linked_type', '8');
        formData.append('linked_item_id', id);

        setIsLoading(true)

        let upload_response = await builderAttachments.createAttachmentHandler(formData)

        setIsLoading(false)

        if (upload_response.success) {

            let { public_id, url } = upload_response.data

            if (!public_id || !url) return

            let _AttachmentsFiles = [...AttachmentsFiles]

            _AttachmentsFiles.push({
                public_id: public_id,
                original_filename: file.name,
                bytes: file.size,
                format: file.type,
                file_url: url,
                linked_type: '8',
                linked_item_id: id,
            })

            setAttachmentsFiles(_AttachmentsFiles)

        } else {
            setWarningAlert(true)
            setWarningAlertType('error')
            setApiFailedMessage(`Error in Attachments, Please try again!`)
        }
    }
    const HandleDeleteAttachment = async (idx) => {

        let _AttachmentsFiles = [...AttachmentsFiles]

        setIsLoading(true)

        let upload_response = await builderAttachments.deleteAttachmentHandler({
            public_id: _AttachmentsFiles[idx].public_id,
            projectId: projectId,
        })

        setIsLoading(false)

        if (upload_response.success) {
            _AttachmentsFiles.splice(idx, 1)
            setAttachmentsFiles(_AttachmentsFiles)
        }
        else {
            setWarningAlert(true)
            setWarningAlertType('error')
            setApiFailedMessage(`Error in Attachments, Please try again!`)
        }
    }



    const HandleSelectPreviewImage = (image) => {
        setShowSelectedPreviewImage(true)
        setSelectedPreviewImage({
            name: image.original_filename,
            file_url: image.file_url,
        })
    }
    const HandleHideSelectPreviewImage = (confirmation) => {
        setShowSelectedPreviewImage(confirmation)
        setSelectedPreviewImage(undefined)
    }


    const [CommentsItems, setCommentsItems] = useState([])

    const [CommentsInput, setCommentsInput] = useState("")

    const LoadComments = async ({ project_id, parent_id }) => {

        console.log(project_id, parent_id, 'parent_id');
        if (!project_id || !parent_id) return

        let filter = {
            project_id: project_id,
            parent_id: parent_id
        }

        setIsLoading(true)

        let response = await commentsHandler.getCommentsHandler(filter)
        setIsLoading(false)

        if (response.success) {
            let { data } = response

            if (!Array.isArray(data)) return

            console.log(data, 'data');

            setCommentsItems(data)
        }
    }

    const HandleAddComment = async () => {
        let _CommentsItems = [...CommentsItems]

        let userdetials = JSON.parse(localStorage.getItem('userdetials'))

        _CommentsItems.push({
            id: Utils.getUniqueId(),
            created_at: new Date().getTime(),
            created_by: userdetials.id,
            created_by_name: userdetials.name,
            message: CommentsInput,
            parent_id: ChangeOrderID,
            parent_name: Title || `Change Order ${ChangeOrderID}`,
            parent_type: 0,
            project_id: projectId,
            company_id: userdetials.company_id,
        })

        setCommentsItems(_CommentsItems)
        setCommentsInput("")

        if (menuType == 'create') return

        let update_data = {
            project_id: projectId,
            parent_id: ChangeOrderID,
            parent_name: Title || `Change Order ${ChangeOrderID}`,
            parent_type: String(0),
            message: CommentsInput
        }

        let response = await commentsHandler.createCommentHandler(update_data)

        if (!response.success) {
            setWarningAlert(true)
            setWarningAlertType('error')
            setApiFailedMessage(`Error in Attachments, Please try again!`)
        }
    }

    const HandleDeleteComment = async (id, idx) => {
        let _CommentsItems = [...CommentsItems]

        _CommentsItems.splice(idx, 1)

        setCommentsItems(_CommentsItems)

        if (menuType == 'create') return

        let update_data = {
            project_id: projectId,
            ids: [id]
        }

        let response = await commentsHandler.deleteCommentHandler(update_data)

        if (!response.success) {
            setWarningAlert(true)
            setWarningAlertType('error')
            setApiFailedMessage(`Error in Comments, Please try again!`)
        }
    }

    const HandleAddPaymentHistory = (confirmation = false) => {

        if (confirmation) setShowAddpaymentHistory(true)

    }
    const HandleUpdatePaymentHistory = (payment_history, type = 'create') => {

        let _paymentHistory = [...paymentHistory]
        if (type == 'create') _paymentHistory.push(payment_history)
        else {
            let idx = _paymentHistory.findIndex(p => p.id == payment_history.id)
            if (idx != -1) _paymentHistory[idx] = payment_history
        }

        setPaymentHistory(_paymentHistory)

    }

    return (
        <>


            {isLoading ?
                <Loading
                    props={{
                        isMainLogo: false,
                        isLabel: true
                    }} />
                : null}
            {warningAlert ?

                <SystemToastPopup
                    props={{
                        type: warningAlertType,
                        message: apiFailedMessage || "Error in Bills",
                        callback: (confirmation) => setWarningAlert(false)
                    }} />

                : null}


            {ShowSelectedPreviewImage ?

                <ImagePreviewPopup
                    props={{
                        heading: `Preview - ${SelectedPreviewImage.name}`,
                        image: SelectedPreviewImage.file_url,
                        message: "Invalid Inputs, Please try again with vaild Inputs!",
                        callback: (confirmation) => HandleHideSelectPreviewImage(confirmation)
                    }} />

                : null}

            {paymentHistoryAlert ?

                <AlertPopup
                    props={{
                        type: "success",
                        actionBtnOption: { icon: Icons.general.warning, label: "Ok" },
                        heading: "Create Payment Record",
                        message: paymentHistoryMessage || "By recording payment method the status of the item will be changed to approved",
                        callback: (confirmation) => {
                            setPaymentHistoryAlert(false)
                            HandleAddPaymentHistory(confirmation)
                        }
                    }} />

                : null}
            {showAddpaymentHistory ?

                <AddPaymentHistory
                    props={{
                        type: "create",
                        existItemData: undefined,
                        max_amount: ChangeOrderDue || 0,
                        close: () => { setShowAddpaymentHistory(false) },
                        updateCallback: (data) => {
                            setShowAddpaymentHistory(false)
                            HandleUpdatePaymentHistory(data, 'create')
                        }
                    }} />

                : null}


            {showCreatePreview ?
                <PreviewChangeOrders
                    props={{
                        menuType: 'edit',
                        RecordLoaded: true,
                        detials: {
                            id: id,
                            name: Title,
                            changeOrderId: ChangeOrderID,
                            projectId: projectId,

                            phase_id: Phase_id,
                            phase_name: Phase_name,
                            client_id: Client_id,
                            client_name: Client_name,

                            date: CODate,
                            due_date: DueDate,
                            status,
                            total: ChangeOrderTotal,

                            description: Description || "",

                            terms_condition_id: termsConditionId || "",
                            terms_condition: termsConditionValue || "",

                        },
                        saveCallback: () => {
                            formSubmitBtnRef.current?.click()
                            setShowCreatePreview(false)
                        },
                        close: () => { setShowCreatePreview(false) }
                    }}
                />
                : null}

            <Routes>
                <Route path='/preview'
                    element={
                        <PreviewChangeOrders
                            props={{
                                menuType: 'edit',
                                RecordLoaded: RecordLoaded,
                                detials: {

                                    id: id,
                                    name: Title,
                                    changeOrderId: ChangeOrderID,
                                    projectId: projectId,

                                    phase_id: Phase_id,
                                    phase_name: Phase_name,
                                    client_id: Client_id,
                                    client_name: Client_name,

                                    date: CODate,
                                    due_date: DueDate,
                                    status,
                                    total: ChangeOrderTotal,


                                    description: Description || "",

                                    terms_condition_id: termsConditionId || "",
                                    terms_condition: termsConditionValue || "",

                                },
                                saveCallback: (e) => {
                                    navigate(`/builder/projects/${projectId}/change_orders/${changeOrderId}/view`)
                                },
                                close: () => {

                                    navigate(`/builder/projects/${projectId}/change_orders/${changeOrderId}/view`)

                                }
                            }}
                        />}>
                </Route>

            </Routes>

            <div className="project-create_client_invoice-main" id='project-create_client_invoice-main'>
                <div className="create_client_invoice-panel-content">

                    <div className="panel-content-header">
                        <div className="left">
                            <div className="icon"
                                dangerouslySetInnerHTML={{ __html: Icons.general.back_arrow }}
                                onClick={(e) => HandleCancel(e)}
                            ></div>
                            <div className="title">Change order Detials</div>
                        </div>
                        <div className="right">
                            <div className="total-items">
                                <div className={`total-item ${!ChangeOrderTotal ? 'total-item-diable' : ''}`}>
                                    <div className="label">Total</div>
                                    <div className="value">{currency_type}{parseFloat(ChangeOrderTotal || 0).toFixed(2)}</div>
                                </div>
                                <div className={`total-item ${!ChangeOrderPaid ? 'total-item-diable' : ''}`}>
                                    <div className="label">Paid</div>
                                    <div className="value">{currency_type}{parseFloat(ChangeOrderPaid || 0).toFixed(2)}</div>
                                </div>
                                <div className={`total-item ${!ChangeOrderDue ? 'total-item-diable' : ''}`}>
                                    <div className="label">Due</div>
                                    <div className="value">{currency_type}{parseFloat(ChangeOrderDue || 0).toFixed(2)}</div>
                                </div>
                            </div>
                            <div className="preview-button" onClick={() => PandlepreviewChangeOrder()}>
                                <div
                                    className="icon"
                                    dangerouslySetInnerHTML={{ __html: Icons.general.eye_open }}
                                ></div>
                                <div className="label">Preview</div>
                            </div>
                            {has_edit_access ?
                                <div className="edit-button" onClick={() => PandleEditChangeOrder()}>
                                    <div
                                        className="icon"
                                        dangerouslySetInnerHTML={{ __html: Icons.general.edit_btn }}
                                    ></div>
                                    <div className="label">Edit</div>
                                </div>
                                : ''}
                        </div>
                    </div>
                    <form className="panel-content-sections" onSubmit={HandleSubmit}>
                        <div className="create-client_invoicecontent-main">

                            <div className="create-client_invoicesections" style={{ height: '100%' }}>

                                <div className="create-client_invoicesection">
                                    <div className="section-input-items">
                                        <div
                                            className="section-input-item"
                                            style={{
                                                height: 'max-content',
                                                display: 'flex',
                                                flexDirection: 'column',
                                                gap: '8px'
                                            }}
                                        >
                                            <div
                                                className="view-input-label"
                                                style={{
                                                    fontSize: '18px',
                                                    fontWeight: '600'
                                                }}
                                            >
                                                Title
                                            </div>
                                            <div className="view-input-value">{Title}</div>

                                        </div>
                                        <div
                                            className="section-input-item"
                                            style={{
                                                height: 'max-content',
                                                display: 'flex',
                                                flexDirection: 'column',
                                                gap: '8px'
                                            }}
                                        >
                                            <div
                                                className="view-input-label"
                                                style={{
                                                    fontSize: '18px',
                                                    fontWeight: '600'
                                                }}
                                            >
                                                Change Order ID
                                            </div>
                                            <div className="view-input-value">{ChangeOrderID}</div>

                                        </div>
                                        <div
                                            className="section-input-item"
                                            style={{
                                                height: 'max-content',
                                                display: 'flex',
                                                flexDirection: 'column',
                                                gap: '8px'
                                            }}
                                        >
                                            <div
                                                className="view-input-label"
                                                style={{
                                                    fontSize: '18px',
                                                    fontWeight: '600'
                                                }}
                                            >
                                                Project Phase
                                            </div>
                                            <div className="view-input-value">{Phase_name}</div>

                                        </div>
                                        <div
                                            className="section-input-item"
                                            style={{
                                                height: 'max-content',
                                                display: 'flex',
                                                flexDirection: 'column',
                                                gap: '8px'
                                            }}
                                        >
                                            <div
                                                className="view-input-label"
                                                style={{
                                                    fontSize: '18px',
                                                    fontWeight: '600'
                                                }}
                                            >
                                                Phase Client
                                            </div>
                                            <div className="view-input-value">{Client_name}</div>

                                        </div>




                                    </div>
                                    <div className="section-input-items">
                                        {SelectedAssignees.length ?
                                            <div className="section-input-item" style={{ height: 'max-content' }}>
                                                <MultiSelectInput
                                                    props={{
                                                        id: 'assignee-multi-select-input',
                                                        placeholder: 'Select Assignees',
                                                        isRequired: true,
                                                        readOnly: true,
                                                        value: SelectedAssignees,
                                                        options: AssigneeOptions,
                                                        isIcon: false,
                                                        avoidDuplicate: true,
                                                        icon: '',
                                                        isLabel: true,
                                                        label: 'Assignees',
                                                        setValue: (value) => { }
                                                    }}
                                                />
                                            </div>
                                            : ''}
                                        <div
                                            className="section-input-item"
                                            style={{
                                                height: 'max-content',
                                                display: 'flex',
                                                flexDirection: 'column',
                                                gap: '8px'
                                            }}
                                        >
                                            <div
                                                className="view-input-label"
                                                style={{
                                                    fontSize: '18px',
                                                    fontWeight: '600'
                                                }}
                                            >
                                                Amount
                                            </div>
                                            <div className="view-input-value">{currency_type}{ChangeOrderTotal}</div>

                                        </div>
                                        <div
                                            className="section-input-item"
                                            style={{
                                                height: 'max-content',
                                                display: 'flex',
                                                flexDirection: 'column',
                                                gap: '8px'
                                            }}
                                        >
                                            <div
                                                className="view-input-label"
                                                style={{
                                                    fontSize: '18px',
                                                    fontWeight: '600'
                                                }}
                                            >
                                                DueDate
                                            </div>
                                            <div className="view-input-value">{DueDate}</div>

                                        </div>
                                        <div
                                            className="section-input-item"
                                            style={{
                                                height: 'max-content',
                                                display: 'flex',
                                                flexDirection: 'column',
                                                gap: '8px'
                                            }}
                                        >
                                            <div
                                                className="view-input-label"
                                                style={{
                                                    fontSize: '18px',
                                                    fontWeight: '600'
                                                }}
                                            >
                                                Status
                                            </div>
                                            <div className="view-input-value">{getStatusLabel(status)}</div>

                                        </div>

                                    </div>
                                </div>

                                <div className="create-client_invoicesection" id={`create-changeOrder-introduction_text`}>

                                    <div className={`createproposal-description-main`}>
                                        <div className="head">
                                            <div className="title">
                                                <div className="icon" dangerouslySetInnerHTML={{ __html: Icons.general.description }}></div>
                                                <div className="label">Description</div>
                                            </div>
                                        </div>
                                        <div
                                            className="item-expanded"
                                            dangerouslySetInnerHTML={{ __html: Description || "N/A" }}
                                        >
                                        </div>
                                    </div>

                                </div>

                                <div className="create-proposal-section" id={`create-proposal-summary`}>
                                    <div className="createproject-section proposal-left-section">
                                        <div className="proposal-summary-totals proposal-summary-title">
                                            <div className="title">Summary</div>
                                        </div>
                                        <div className="proposal-summary-totals proposal-summary-items">

                                            <div className="proposal-summary-item">
                                                <div className="proposal-summary-item-content">

                                                    <div className="item" style={{ fontSize: '24px' }}>
                                                        <div className="title">Total</div>
                                                        <div className="quantity"></div>
                                                        <div className="amount">
                                                            {currency_type || '₹'} {parseFloat(ChangeOrderTotal || 0).toFixed(2)}
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>


                                <div className="create-client_invoicesection" id={`create-client_invoice_payment_history`}>
                                    <div className={`createproposal-description-main`}>
                                        <div className="head">
                                            <div className="title">
                                                <div className="label">Client Payments ({paymentHistory.length})</div>
                                                <div className="icon" dangerouslySetInnerHTML={{ __html: Icons.general.info }}></div>
                                            </div>
                                        </div>
                                        <div className="head">
                                            <div className="title">
                                                <div className="label"></div>
                                            </div>
                                            {ChangeOrderDue > 0 ?
                                                <div className={`add-paymenthistory-btn ${!paymentHistory.length ? 'add-paymenthistory-disable' : ''}`} onClick={() => HandleAddPaymentHistory(true)}>
                                                    <div className="icon" dangerouslySetInnerHTML={{ __html: Icons.general.add_btn }}></div>
                                                    <div className="label">Record Payments</div>
                                                </div>
                                                : ''}

                                        </div>
                                        {!paymentHistory.length ?
                                            <div className="no-paymenthistory-main">

                                                <div className="no-paymenthistory-vector">
                                                    <div className="icon" dangerouslySetInnerHTML={{ __html: Icons.general.no_records }}></div>
                                                    <div className="label">No payments added yet</div>
                                                </div>
                                                {ChangeOrderDue > 0 ?
                                                    <div className="add-paymenthistory-btn" onClick={() => setPaymentHistoryAlert(true)}>
                                                        <div className="icon" dangerouslySetInnerHTML={{ __html: Icons.general.add_btn }}></div>
                                                        <div className="label">Record Payments</div>
                                                    </div>
                                                    : ''}

                                            </div>
                                            : ''}
                                        {paymentHistory.length ?

                                            <div className="paymenthistory-table-main">
                                                <div className="paymenthistory-table-header">
                                                    <div className="header-item table-item-sno">S:No</div>
                                                    <div className="header-item table-item-date">Date</div>
                                                    <div className="header-item table-item-amount">Amount</div>
                                                    <div className="header-item table-item-payment_method">Payment Method</div>
                                                    <div className="header-item table-item-notes">Notes</div>
                                                    <div className="header-item table-item-status">Status</div>
                                                </div>
                                                <div className="paymenthistory-table-content">

                                                    {paymentHistory?.map((item, i) => (
                                                        <div
                                                            className="content-row"
                                                            key={`paymenthistory-table-item-${item.id}`}
                                                        >
                                                            <div className="content-row-item table-item-sno">{i + 1}</div>
                                                            <div className="content-row-item table-item-date">{item.date}</div>
                                                            <div className="content-row-item table-item-amount">{currency_type} {item.amount}</div>
                                                            <div className="content-row-item table-item-payment_method">{item.payment_method}</div>
                                                            <div className="content-row-item table-item-notes">
                                                                {item.notes}

                                                            </div>
                                                            <div className="content-row-item table-item-status">
                                                                {item.status}
                                                            </div>


                                                        </div>
                                                    ))}

                                                </div>

                                            </div>

                                            : ''}


                                    </div>
                                </div>

                                <div className="create-client_invoicesection" id={`create-proposal-attachments`}>
                                    <div className={`createproposal-description-main`}>
                                        <div className="head">
                                            <div className="title">
                                                <div className="icon" dangerouslySetInnerHTML={{ __html: Icons.general.attachments }}></div>
                                                <div className="label">Attachments</div>
                                            </div>
                                        </div>
                                        <div className="attachments-section-main">
                                            <div className="attachments-section-files">

                                                {AttachmentsFiles?.map((file, idx) => (
                                                    <div
                                                        className="attachment-file"
                                                        key={file.public_id}
                                                        id={`file-${file.public_id}`}
                                                    >

                                                        <div className="attachment-file-preview" onClick={() => HandleSelectPreviewImage(file)}>
                                                            <img src={file.file_url} alt="attachment-file" />
                                                        </div>
                                                        <div className="attachment-file-footer">
                                                            <div className="file-name">{file.original_filename}</div>
                                                            <div
                                                                className="file-download"
                                                                dangerouslySetInnerHTML={{ __html: Icons.general.download }}
                                                            ></div>
                                                        </div>

                                                    </div>
                                                ))}


                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="create-client_invoicesection" id={`create-changeOrder-terms_conditions`}>
                                    <div className={`createproposal-description-main`}>
                                        <div className="head">
                                            <div className="title">
                                                <div className="label">Terms and conditions</div>
                                                <div className="icon" dangerouslySetInnerHTML={{ __html: Icons.general.info }}></div>
                                            </div>
                                        </div>

                                        <div className="terms-condition-result" dangerouslySetInnerHTML={{ __html: termsConditionValue }}></div>
                                    </div>
                                </div>

                                <div className="create-proposal-section">

                                    <div className={`createproposal-description-main`}>
                                        <div className="head">
                                            <div className="label">Comments</div>
                                        </div>
                                        <div className="comments-section-main">
                                            <div className="comments-section-items">

                                                {CommentsItems.map((item, idx) => (

                                                    <div
                                                        className="comments-item-main"
                                                        key={`comments-item-${item.id}`}
                                                        id={`comments-item-${item.id}`}
                                                    >
                                                        <div className="comments-item-usericon">
                                                            {item.created_by_name.charAt(0)}
                                                        </div>
                                                        <div className="comments-item-detials">
                                                            <div className="detials-head">
                                                                <div className="left">
                                                                    <div className="name">{item.created_by_name}</div>
                                                                    <div className="time">{Utils.getLocalFullDateLabel(item.created_at)}</div>
                                                                </div>
                                                                <div className="right">
                                                                    <div
                                                                        className="delete-icon"
                                                                        onClick={() => HandleDeleteComment(item.id, idx)}
                                                                        dangerouslySetInnerHTML={{ __html: Icons.general.delete_btn }}></div>
                                                                </div>
                                                            </div>
                                                            <div className="detials-message">{item.message}</div>
                                                        </div>
                                                    </div>


                                                ))}


                                                <div className="comments-input-main">
                                                    <div className="comments-input-usericon">
                                                        RT
                                                    </div>
                                                    <div className="comments-input comments-input-active">
                                                        <div className="comments-input-textarea">
                                                            <textarea
                                                                placeholder='Add a comment...'
                                                                value={CommentsInput}
                                                                onChange={(e) => setCommentsInput(e.target.value)}
                                                            ></textarea>
                                                        </div>
                                                        <div className={`comments-input-controls ${CommentsInput.length ? `comments-input-controls-active` : ''}`}>
                                                            <div
                                                                className={`comments-input-control-sendmsg`}
                                                                onClick={() => HandleAddComment()}
                                                            >
                                                                <div className="icon" dangerouslySetInnerHTML={{ __html: Icons.general.send_message }}></div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>


                        </div>
                    </form>
                </div>

            </div>
        </>
    )

}

export default ViewChangeOrders;