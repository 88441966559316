

import React, { useEffect, useState, useRef } from 'react'
import { Route, Routes, BrowserRouter, Link, useLocation, Navigate, Outlet, useNavigate, useParams } from 'react-router-dom';
import store from '../../redux/store'
import StoreHandler from '../../redux/StoreHandler'
import { useSelector, useDispatch } from 'react-redux'
import UserAction from '../../redux/action/userAction'

import ReverseProxy from '../../config/reverseProxy'
import ProjectsHandler from '../../Handlers/Projects/Projects';
import Icons from '../../assets/Icons'
import Images from '../../assets/Images'
import { CostInput, TextInput, ColorInput, SelectInput, DateTimeInput, RadioInput, RadioOptionsInput } from '../../components/Inputs'
import Utils from '../../utils'
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

import AlertPopup from '../../components/AlertPopup'
import SystemToastPopup from '../../components/SystemToastPopup'
import Loading from '../../components/Loading'

import { useAuth, useMenuAuth } from '../../hooks/useAuth'

import AddProjectClientPopup from './AddProjectClientPopup'
import AddProjectUserPopup from './AddProjectUserPopup'


const CreateProject = ({ props }) => {

    const { projectId } = useParams();

    const { menuType = 'create', isEditView = false } = props

    const [isLoading, setIsLoading] = useState(false)
    const [warningAlert, setWarningAlert] = useState(false)
    const [warningAlertType, setWarningAlertType] = useState('warning')
    const [apiFailedMessage, setApiFailedMessage] = useState('Error in Create Projects')

    const navigate = useNavigate()
    const store = useSelector((store) => store)
    const dispatch = useDispatch()
    const { updateState } = new UserAction

    const storeHandler = new StoreHandler({
        dataStack: ['company', 'field_settings', 'clients'],
        store: store,
        dispatch: dispatch,
        updateState: updateState,
    })

    const LoadStoreData = async () => {
        setIsLoading(true)
        let resolved = await storeHandler.init()
        setIsLoading(false)
    }

    const { isAdmin, menu_permission } = useMenuAuth({ menu: 'project_management--projects', feature_key: 'projects' })
    const location = useLocation()

    const [selected_view_settings_value, setSelected_view_settings_value] = useState('')
    const [has_view_access, setHas_view_access] = useState(false)
    const [has_add_access, setHas_add_access] = useState(false)
    const [has_edit_access, setHas_edit_access] = useState(false)
    const [has_delete_access, setHas_delete_access] = useState(false)

    useEffect(() => {

        if (isAdmin) {

            setHas_view_access(true)
            setHas_add_access(true)
            setHas_edit_access(true)
            setHas_delete_access(true)
        }

        else if (menu_permission) {

            if (
                !menu_permission._view
                &&
                (!menu_permission.selected_view_settings_value
                    || menu_permission.selected_view_settings_value == 'no_access')
            ) {
                navigate(`${ReverseProxy['proxyUrl']}/quick-menu`, { state: { from: location }, replace: true })
            }

            if (!menu_permission._create && menuType == 'create') navigate(`${ReverseProxy['proxyUrl']}/builder/projects`, { state: { from: location }, replace: true })
            if (!menu_permission._edit && menuType == 'edit') navigate(`${ReverseProxy['proxyUrl']}/builder/projects`, { state: { from: location }, replace: true })

            setHas_view_access(menu_permission._view)
            setHas_add_access(menu_permission._create)
            setHas_edit_access(menu_permission._edit)
            setHas_delete_access(menu_permission._delete)

            setSelected_view_settings_value(menu_permission.selected_view_settings_value)

        }

    }, [store])


    const projectsHandler = new ProjectsHandler()

    const [id, setId] = useState('')
    const [name, setName] = useState('')
    const [projectID, setProjectID] = useState('')
    const [projectTypeID, setProjectTypeID] = useState('')
    const [projectTypeName, setProjectTypeName] = useState('')
    const [statusID, setStatusID] = useState('')
    const [statusName, setStatusName] = useState('')
    const [statusColor, setStatusColor] = useState('')
    const [City, setCity] = useState('')
    const [State, setState] = useState('')
    const [address, setAddress] = useState('')
    const [description, setDescription] = useState('')
    const [billing_address, setBilling_address] = useState('')
    const [delivery_address, setDelivery_address] = useState('')
    const [work_address, setWork_address] = useState('')
    const [area, setArea] = useState(0)
    const [Floors, setFloors] = useState(0)
    const [unitID, setUnitID] = useState('')
    const [unitName, setUnitName] = useState('')
    const [price, setPrice] = useState(0)
    const [colorcode, setColorCode] = useState(Utils.getRandomColor())

    const [AssignedProjectUsers, setAssignedProjectUsers] = useState([])

    const [clientOptions, setClientOptions] = useState([])
    const [projectTypeOptions, setProjectTypeOptions] = useState([])
    const [projectStatusOptions, setProjectStatusOptions] = useState([])
    const [currency_type, setCurrency_type] = useState('₹')

    // Import Dates
    const [createdate, setCreatedate] = useState('')
    const [startdate, setStartdate] = useState('')
    const [enddate, setEnddate] = useState('')
    const [RecceDueDate, setRecceDueDate] = useState('')

    const [ProjectBanner, setProjectBanner] = useState('')
    const [ProjectBanner_URL, setProjectBanner_URL] = useState('')

    // clients
    const [AssignedProjectClients, setAssignedProjectClients] = useState([])


    const [isAddProjectClientPopup, setIsAddProjectClientPopup] = useState(false)

    const HandleOpenAddProjectClientPopup = () => {
        setIsAddProjectClientPopup(true)
    }
    const HandleCloseAddProjectClientPopup = () => {
        setIsAddProjectClientPopup(false)
    }
    const HandleDeleteProjectClient = (id) => {
        let client = [...AssignedProjectClients]
        client = client.filter((user) => user.id != id)
        setAssignedProjectClients(client)
    }
    const HandleSubmitAddProjectClientPopup = (clients) => {

        HandleCloseAddProjectClientPopup()

        let _AssignedProjectClients = [...AssignedProjectClients]
        clients.forEach((user) => {
            let isExist = _AssignedProjectClients.find((u) => u.client_id == user.client_id)
            if (!isExist) _AssignedProjectClients.push(user)
        })

        setAssignedProjectClients(_AssignedProjectClients)
    }


    const [isAddProjectUserPopup, setIsAddProjectUserPopup] = useState(false)

    const HandleOpenAddProjectUserPopup = () => {
        setIsAddProjectUserPopup(true)
    }
    const HandleCloseAddProjectUserPopup = () => {
        setIsAddProjectUserPopup(false)
    }
    const HandleDeleteProjectUser = (id) => {
        let users = [...AssignedProjectUsers]
        users = users.filter((user) => user.id != id)
        setAssignedProjectUsers(users)
    }
    const HandleSubmitAddProjectUserPopup = (users) => {

        HandleCloseAddProjectUserPopup()

        let _AssignedProjectUsers = [...AssignedProjectUsers]
        users.forEach((user) => {
            let isExist = _AssignedProjectUsers.find((u) => u.id == user.id)
            if (!isExist) _AssignedProjectUsers.push(user)
        })

        setAssignedProjectUsers(_AssignedProjectUsers)
    }

    const isFile = (variable) => variable !== null && typeof variable === 'object' && variable.constructor === File;

    const HandleBannerImageChange = (event) => {
        const file = event.target.files[0];

        if (!file) return

        setProjectBanner(file);

        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
            setProjectBanner_URL(reader.result)
        };


    };
    const HandleBannerImageChangeTrigger = (event) => {

        let input = document.getElementById('banner-project-image')
        input.click()
    };

    const HandleCancel = (e) => {

        navigate('/builder/projects')
    }

    const HandleStatusInput = (value, label) => {

        let status_option = projectStatusOptions.find((opt) => opt.value == value)

        if (status_option) {
            setStatusID(value)
            setStatusName(label)
            setStatusColor(status_option.color)
        }
    }

    const HandleSubmit = async (e) => {
        e.preventDefault()

        let update_detials = {
            name: name,
            description: description,
            projectId: projectID,
            type_id: projectTypeID,
            type_name: projectTypeName,
            status_id: statusID,
            status_name: statusName,
            status_color: statusColor,
            address: address,
            city: City,
            state: State,
            createdate: createdate,
            startdate: startdate,

            billing_address: billing_address,
            delivery_address: delivery_address,
            work_address: work_address,
            enddate: enddate,
            team_users: JSON.stringify(AssignedProjectUsers || []),
            clients: JSON.stringify(AssignedProjectClients || []),
            area: String(area),
            floors: String(Floors),
            unit_id: unitID,
            unit_name: unitName,
            price: String(price),
            colorcode: String(colorcode),
            banner_image: ProjectBanner
        }

        const formData = new FormData();

        for (let key in update_detials) formData.append(key, update_detials[key]);


        let response = { success: false }

        if (menuType == 'edit') {

            formData.append('id', id)
            response = await projectsHandler.updateProjectHandler(formData)
        }

        else response = await projectsHandler.createProjectHandler(formData)

        if (response.success) {
            setIsLoading(false)
            navigate(`/builder/projects`)
        }
        else {
            setIsLoading(false)
            setWarningAlert(true)
            setWarningAlertType('error')
            setApiFailedMessage(`Error in Projects, Please try again!`)
        }
    }

    const getNextProjectID = async () => {

        let response = await projectsHandler.getNextProjectIDHandler()

        if (response && response.success) {

            let { projectId } = response.data

            if (projectId) setProjectID(projectId)

        } else {
            setWarningAlert(true)
            setWarningAlertType('error')
            setApiFailedMessage(`Error in Projects, Please try again!`)
        }
    }

    const LoadProject = async () => {

        let filters = {
            projectID: projectId
        }

        let response = await projectsHandler.getProjectsHandler(filters)

        if (response && response.success && Array.isArray(response.data) && response.data.length) {

            let {
                id,
                name,
                projectID,
                banner_image,
                type_id,
                type_name,
                status_id,
                status_name,
                status_color,
                city, state,
                address,
                description,

                createdate,
                startdate,
                enddate,
                price,
                colorcode,
                area,
                floors,
                unit_id,
                unit_name,

                billing_address,
                delivery_address,
                work_address,
                team_users,
                clients

            } = response.data[0]

            createdate = createdate ? Utils.getLocalFullDateBYFormat(createdate, '-', 'YYYY/MM/DD') : ''
            startdate = startdate ? Utils.getLocalFullDateBYFormat(startdate, '-', 'YYYY/MM/DD') : ''
            enddate = enddate ? Utils.getLocalFullDateBYFormat(enddate, '-', 'YYYY/MM/DD') : ''

            setId(id)
            setName(name)
            setProjectID(projectID)
            setProjectTypeID(type_id)
            setProjectTypeName(type_name)
            setStatusID(status_id)
            setStatusName(status_name)
            setStatusColor(status_color)

            setCity(city)
            setState(state)
            setAddress(address)
            setCreatedate(createdate)
            setStartdate(startdate)
            setEnddate(enddate)
            setDescription(description || "")
            setArea(area || 0)
            setFloors(floors || 0)
            setUnitID(unit_id)
            setUnitName(unit_name)
            setPrice(price || 0)
            setColorCode(colorcode || Utils.getRandomColor())


            setBilling_address(billing_address || "")
            setDelivery_address(delivery_address || "")
            setWork_address(work_address || "")

            setAssignedProjectUsers(team_users || [])
            setAssignedProjectClients(clients || [])

            if (banner_image) {
                setProjectBanner(Utils.dataURLtoFile(`data:image/png;base64,${banner_image}`, name))
                setProjectBanner_URL(`data:image/png;base64,${banner_image}`)
            } else {

                setProjectBanner_URL(Images.DEFAULT_PROJECT_BANNER)
            }

        } else {
            setWarningAlert(true)
            setWarningAlertType('error')
            setApiFailedMessage(`Error in Projects, Please try again!`)
        }
    }

    useEffect(() => {

        let loadImg = () => {

            if (!isFile(ProjectBanner)) return

            let input = document.getElementById('banner-project-image')
            const fileList = new DataTransfer();
            fileList.items.add(ProjectBanner);

            input.files = fileList.files;
        }

        loadImg()

    }, [ProjectBanner])

    useEffect(() => {

        if (menuType == 'create') {
            getNextProjectID()
            setProjectBanner_URL(Images.DEFAULT_PROJECT_BANNER)
        }
        else LoadProject()

        LoadStoreData()
    }, [])

    useEffect(() => {

        if (store && store.user) {

            let { company, clients, settings } = store.user

            if (company) {

                let { currency_setting, timezone, temperature_setting } = company

                if (currency_setting) setCurrency_type(currency_setting)
            }


            if (Array.isArray(clients) && clients.length) {
                clients = clients.map((client) => {
                    return {
                        value: client.id,
                        label: client.name,
                        phone: client.phone,
                    }
                })

                setClientOptions(clients)
            }
            if (settings && Array.isArray(settings.field_settings) && settings.field_settings.length) {

                let { project_statuses, project_types, units } = settings.field_settings[0]

                project_statuses = project_statuses.filter(opt => opt.isActive).map((opt) => {
                    return {
                        value: opt.id,
                        label: opt.name,
                        color: opt.color,
                        isDefault: opt.isDefault || false
                    }
                })
                project_types = project_types.filter(opt => opt.isActive).map((opt) => {
                    return {
                        value: opt.id,
                        label: opt.name,
                        isDefault: opt.isDefault || false
                    }
                })

                setProjectTypeOptions(project_types || [])
                setProjectStatusOptions(project_statuses || [])
            }

        }

    }, [store])


    return (
        <>


            {isLoading ?
                <Loading
                    props={{
                        isMainLogo: false,
                        isLabel: true
                    }} />
                : null}
            {warningAlert ?

                <SystemToastPopup
                    props={{
                        type: warningAlertType,
                        message: apiFailedMessage || "Error in Create Projects",
                        callback: (confirmation) => setWarningAlert(false)
                    }} />

                : null}

            {isAddProjectUserPopup ?
                <AddProjectUserPopup
                    props={{
                        project_users: AssignedProjectUsers,
                        close_callback: HandleCloseAddProjectUserPopup,
                        submit_callback: HandleSubmitAddProjectUserPopup
                    }}
                />
                : ''
            }

            {isAddProjectClientPopup ?
                <AddProjectClientPopup
                    props={{
                        project_clients: AssignedProjectClients,
                        close_callback: HandleCloseAddProjectClientPopup,
                        submit_callback: HandleSubmitAddProjectClientPopup
                    }}
                />
                : ''}



            <div className="project-projects-main">
                <div className="projects-panel-content">

                    <div className="panel-content-header">
                        <div className="left">
                            <div className="icon"
                                dangerouslySetInnerHTML={{ __html: Icons.general.back_arrow }}
                                onClick={(e) => HandleCancel(e)}
                            ></div>
                            <div className="title">{menuType == 'create' ? 'Create New' : 'Edit'} Project</div>
                        </div>
                    </div>
                    <form className="panel-content-sections" onSubmit={HandleSubmit}>
                        <div className="create-project-content-main">



                            <div className="create-project-sections">
                                {/* Banner Details */}
                                <div className="project-banner-main">
                                    <div className="project-banner-image">
                                        <img src={ProjectBanner_URL || ""} />
                                    </div>

                                    <div className="project-banner-btn"
                                        onClick={(e) => HandleBannerImageChangeTrigger(e)}
                                    >
                                        <input
                                            className='upload-input'
                                            accept="image/jpg, image/jpeg, image/png"
                                            onChange={(e) => HandleBannerImageChange(e)}
                                            type="file"
                                            id='banner-project-image'
                                        />
                                        <div
                                            className='icon'
                                            dangerouslySetInnerHTML={{ __html: Icons.general.edit_btn }}
                                        ></div>
                                        <div className="label">Change banner</div>
                                    </div>
                                </div>
                                {/* Basic Details */}
                                <div className="create-project-section">
                                    <div className="section-header">
                                        <div className="title">Basic Details</div>
                                    </div>
                                    <div className="section-input-items">
                                        <div className="section-input-item">
                                            <TextInput
                                                props={{
                                                    id: "create-project-projectID",
                                                    value: projectID,
                                                    placeholder: '',
                                                    setValue: (value) => setProjectID(value),
                                                    isIcon: false,
                                                    isLabel: true,
                                                    isRequired: true,
                                                    readOnly: true,
                                                    label: "Project ID",
                                                }}
                                            />
                                        </div>
                                        <div className="section-input-item">
                                            <TextInput
                                                props={{
                                                    id: "create-project-name",
                                                    value: name,
                                                    placeholder: '',
                                                    setValue: (value) => setName(value),
                                                    isIcon: false,
                                                    isLabel: true,
                                                    isRequired: true,
                                                    label: "Project Name",
                                                }}
                                            />
                                        </div>

                                        <div className="section-input-item">
                                            <ColorInput
                                                props={{
                                                    id: "create-project-colorcode",
                                                    value: colorcode,
                                                    placeholder: '',
                                                    setValue: (value) => setColorCode(value),
                                                    isIcon: false,
                                                    isLabel: true,
                                                    isRequired: false,
                                                    label: "Color Code",
                                                }}
                                            />
                                        </div>


                                        <div className="section-input-item">
                                            <SelectInput
                                                props={{
                                                    id: "create-project-status",
                                                    value: statusID,
                                                    placeholder: '',
                                                    readOnly: false,
                                                    options: projectStatusOptions || [],
                                                    setValue: (value, label) => HandleStatusInput(value, label),
                                                    isStatus: false,
                                                    isIcon: false,
                                                    isLabel: true,
                                                    isRequired: true,
                                                    label: "Status",
                                                }}
                                            />
                                        </div>

                                        <div className="section-input-item">
                                            <TextInput
                                                props={{
                                                    id: "create-project-state",
                                                    value: State,
                                                    placeholder: '',
                                                    setValue: (value) => setState(value),
                                                    isIcon: false,
                                                    isLabel: true,
                                                    isRequired: false,
                                                    label: "State",
                                                }}
                                            />
                                        </div>
                                        <div className="section-input-item">
                                            <TextInput
                                                props={{
                                                    id: "create-project-city",
                                                    value: City,
                                                    placeholder: '',
                                                    setValue: (value) => setCity(value),
                                                    isIcon: false,
                                                    isLabel: true,
                                                    isRequired: false,
                                                    label: "City",
                                                }}
                                            />
                                        </div>

                                        <div className="section-full-itm">
                                            <div className="head">
                                                <div className="label">Project Address</div>
                                            </div>

                                            <textarea
                                                id="create-project-billing_address"
                                                value={address}
                                                onChange={(e) => setAddress(e.target.value)}
                                            ></textarea>

                                        </div>
                                        <div className="section-full-itm">
                                            <div className="head">
                                                <div className="label">Project Description</div>
                                            </div>

                                            <textarea
                                                id="create-project-description"
                                                value={description}
                                                onChange={(e) => setDescription(e.target.value)}
                                            ></textarea>

                                        </div>


                                    </div>
                                </div>
                                {/* Project Scope */}
                                <div className="create-project-section">
                                    <div className="section-header">
                                        <div className="title">Project Scope</div>
                                    </div>
                                    <div className="section-input-items">

                                        <div className="section-input-item">
                                            <SelectInput
                                                props={{
                                                    id: "create-project-projectType",
                                                    value: projectTypeID,
                                                    placeholder: '',
                                                    readOnly: false,
                                                    options: projectTypeOptions || [],
                                                    setValue: (value, label) => {
                                                        setProjectTypeID(value)
                                                        setProjectTypeName(label)
                                                    },
                                                    isStatus: false,
                                                    isIcon: false,
                                                    isLabel: true,
                                                    isRequired: true,
                                                    label: "Project Type",
                                                }}
                                            />
                                        </div>
                                        <div className="section-input-item section-input-item-mini" >
                                            <TextInput
                                                props={{
                                                    id: "create-project-floors",
                                                    value: Floors,
                                                    placeholder: '',
                                                    setValue: (value) => setFloors(value),
                                                    isIcon: false,
                                                    isLabel: true,
                                                    isRequired: false,
                                                    label: "Total Project Floor (G+)",
                                                }}
                                            />
                                        </div>
                                        <div className="section-input-item section-input-item-mini" >
                                            <TextInput
                                                props={{
                                                    id: "create-project-area",
                                                    value: area,
                                                    placeholder: '',
                                                    setValue: (value) => setArea(value),
                                                    isIcon: false,
                                                    isLabel: true,
                                                    isRequired: false,
                                                    label: "Total Project Ares (in SQFT)",
                                                }}
                                            />
                                        </div>
                                        <div className="section-input-item">
                                            <CostInput
                                                props={{
                                                    id: "create-project-price",
                                                    value: price,
                                                    isCostMethod: false,
                                                    placeholder: '',
                                                    currency_type: currency_type,
                                                    setValue: (value) => {
                                                        setPrice(value)
                                                    },
                                                    isIcon: true,
                                                    isIconLeft: true,
                                                    icon: currency_type,
                                                    isLabel: true,
                                                    isRequired: false,
                                                    label: "Total Project Estimate",
                                                }}
                                            />
                                        </div>


                                    </div>
                                </div>
                                {/* Client Details */}
                                <div className="create-project-section">
                                    <div className="section-header">
                                        <div className="title">Clients & Details</div>
                                        <div className="action-items">
                                            <div
                                                className="multi_client-add_client-btn"
                                                onClick={(e) => HandleOpenAddProjectClientPopup()}
                                            >
                                                <div className="icon"
                                                    dangerouslySetInnerHTML={{ __html: Icons.general.add_btn }}
                                                ></div>
                                                <div className="label">Add Client</div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="project-multi_client-main">

                                        <div className="multi_client-section">
                                            <div className="multi_client-items">
                                                {AssignedProjectClients.map((user, index) => (
                                                    <div
                                                        className="multi_client-item"
                                                        key={`multi_client-item-${index}`}
                                                    >
                                                        <div
                                                            className="multi_client-item-icon"
                                                            style={{ background: user.client_color_code }}
                                                        >
                                                            {Utils.getNameInitials(user.client_name)}
                                                        </div>
                                                        <div className="multi_client-item-details">
                                                            <div className="multi_client-item-name">{user.client_name}</div>
                                                            <div className="multi_client-item-desc">Contact: {user.contact_person_name} | {user.contact_person_phone}</div>
                                                        </div>
                                                        <div className="multi_client-item-delete_btn"
                                                            dangerouslySetInnerHTML={{ __html: Icons.general.delete_btn }}
                                                            onClick={(e) => HandleDeleteProjectClient(user.id)}
                                                        ></div>
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    </div>

                                </div>
                                {/* Important Dates */}
                                <div className="create-project-section">
                                    <div className="section-header">
                                        <div className="title">Important Dates</div>
                                    </div>
                                    <div className="section-input-items">

                                        <div className="section-input-item">
                                            <DateTimeInput
                                                props={{
                                                    id: "create-project-createdate",
                                                    value: createdate,
                                                    placeholder: '',
                                                    setValue: (value) => {
                                                        setCreatedate(value)
                                                    },
                                                    isIcon: false,
                                                    isLabel: true,
                                                    icon: Icons.general.schedule,
                                                    isRequired: false,
                                                    label: "Project Created Date",
                                                }}
                                            />
                                        </div>
                                        <div className="section-input-item" style={{ display: 'none' }}>
                                            <DateTimeInput
                                                props={{
                                                    id: "create-project-recee_due_date",
                                                    value: RecceDueDate,
                                                    placeholder: '',
                                                    setValue: (value) => {
                                                        setRecceDueDate(value)
                                                    },
                                                    isIcon: false,
                                                    isLabel: true,
                                                    icon: Icons.general.schedule,
                                                    isRequired: false,
                                                    label: "Recce Due Date",
                                                }}
                                            />
                                        </div>
                                        <div className="section-input-item">
                                            <DateTimeInput
                                                props={{
                                                    id: "create-project-startdate",
                                                    value: startdate,
                                                    placeholder: '',
                                                    setValue: (value) => {
                                                        setStartdate(value)
                                                    },
                                                    isIcon: false,
                                                    isLabel: true,
                                                    icon: Icons.general.schedule,
                                                    isRequired: false,
                                                    label: "Expected Start Date",
                                                }}
                                            />
                                        </div>
                                        <div className="section-input-item">
                                            <DateTimeInput
                                                props={{
                                                    id: "create-project-enddate",
                                                    value: enddate,
                                                    placeholder: '',
                                                    setValue: (value) => {
                                                        setEnddate(value)
                                                    },
                                                    isIcon: false,
                                                    isLabel: true,
                                                    icon: Icons.general.schedule,
                                                    isRequired: false,
                                                    label: "Expected Due Date",
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>
                                {/* Project Users */}
                                <div className="create-project-section">
                                    <div className="section-header">
                                        <div className="title">Project Team</div>
                                        <div className="action-items">
                                            <div
                                                className="project-team-add_user-btn"
                                                onClick={(e) => HandleOpenAddProjectUserPopup()}
                                            >
                                                <div className="icon"
                                                    dangerouslySetInnerHTML={{ __html: Icons.general.add_btn }}
                                                ></div>
                                                <div className="label">Add Users</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="project-team-section">
                                        <div className="project-team-items">

                                            {AssignedProjectUsers.map((user, index) => (
                                                <div
                                                    className="project-team-item"
                                                    key={`project-team-item-${index}`}
                                                >
                                                    <div
                                                        className="team-item-icon"
                                                        style={{ background: user.color_code }}
                                                    >
                                                        {Utils.getNameInitials(user.name)}
                                                    </div>
                                                    <div className="team-item-details">
                                                        <div className="team-item-name">{user.name}</div>
                                                        <div className="team-item-desc">{user.role_name} | {user.phone}</div>
                                                    </div>
                                                    <div className="team-item-delete_btn"
                                                        dangerouslySetInnerHTML={{ __html: Icons.general.delete_btn }}
                                                        onClick={(e) => HandleDeleteProjectUser(user.id)}
                                                    ></div>

                                                </div>

                                            ))}
                                        </div>
                                    </div>

                                </div>
                            </div>

                            <div className="create-project-footer">
                                <div className="footer-item action-items">

                                    <div className="action-cancel" onClick={(e) => HandleCancel(e)}>
                                        Cancel
                                    </div>
                                    <button
                                        className={`action-btn`}
                                        type='submit'
                                    >

                                        <div className="label">Save</div>
                                    </button>
                                </div>
                            </div>

                        </div>
                    </form>
                </div>
            </div>
        </>
    )

}

export default CreateProject;