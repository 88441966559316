

import React, { useEffect, useState, useRef, useCallback, useMemo, StrictMode } from 'react'
import { Route, Routes, BrowserRouter, Link, useLocation, Navigate, Outlet, useNavigate, useParams } from 'react-router-dom';
import store from '../../redux/store'
import StoreHandler from '../../redux/StoreHandler'
import { useSelector, useDispatch } from 'react-redux'
import UserAction from '../../redux/action/userAction'

import ReverseProxy from '../../config/reverseProxy'
import PurchaseOrderHandler from '../../Handlers/PurchaseOrder/PurchaseOrder';
import Icons from '../../assets/Icons'
import Images from '../../assets/Images'
import { CostInput, TextInput, SelectInput, DateTimeInput, RadioInput, TextArea, MultiSelectInput } from '../../components/Inputs'
import Utils from '../../utils'
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

import Loading from '../../components/Loading'
import SystemToastPopup from '../../components/SystemToastPopup'



const PreviewOptimizedPOCreation = ({ props }) => {

    const { projectId } = useParams();

    const { close, po_data, saveCallback } = props

    const purchaseOrderHandler = new PurchaseOrderHandler()

    const [isLoading, setIsLoading] = useState(false)
    const [warningAlert, setWarningAlert] = useState(false)
    const [warningAlertType, setWarningAlertType] = useState('warning')
    const [apiFailedMessage, setApiFailedMessage] = useState("Error in Server")

    const navigate = useNavigate()
    const store = useSelector((store) => store)
    const dispatch = useDispatch()
    const { updateState } = new UserAction

    const storeHandler = new StoreHandler({
        dataStack: ['terms_conditions', 'team_users'],
        store: store,
        dispatch: dispatch,
        updateState: updateState,
    })

    const LoadStoreData = async () => {
        setIsLoading(true)
        let resolved = await storeHandler.init()
        setIsLoading(false)
    }

    const [PurchaseOrders, setPurchaseOrders] = useState([])

    const [SelectedPurchaseOrder, setSelectedPurchaseOrder] = useState(0)

    const [termsConditionOptions, setTermsConditionOptions] = useState([])

    const [AssigneeOptions_Orginal, setAssigneeOptions_Orginal] = useState([])
    const [AssigneeOptions, setAssigneeOptions] = useState([])

    const HandleSelectPurchaseOrder = (idx) => {
        setSelectedPurchaseOrder(idx)
    }

    const HandlePOSelect = (value, idx) => {
        let purchaseOrders = PurchaseOrders
        purchaseOrders[idx].selected = value
        setPurchaseOrders([...purchaseOrders])
    }

    const HandlePurchaseOrders = () => {
        saveCallback(PurchaseOrders)
    }
    const getNextPurchaseOrderID = async () => {

        let response = await purchaseOrderHandler.getNextPurchaseOrderIDHandler()

        if (response && response.success) {

            let { purchaseId } = response.data

            if (purchaseId) return purchaseId
            else return null

        } else return null
    }

    const RenderPosData = async (po_data) => {
        let {
            name,
            description,
            delivery_deadline_date,
            approval_deadline_date,
            terms_condition_id,
            terms_condition,
            submissions,
        } = po_data

        submissions = submissions || []

        const NextPOID = await getNextPurchaseOrderID()

        let getNextID = (id) => {

            let po_id = String(id).split('-')[1]
            let po_id_int = parseInt(po_id)
            let next_po_id = po_id_int + 1

            if (String(next_po_id).length == 1) next_po_id = `00${next_po_id}`
            else if (String(next_po_id).length == 2) next_po_id = `0${next_po_id}`

            return `PO-${next_po_id}`

        }

        let last_po_id = NextPOID

        let purchaseOrders = []
        submissions.forEach((item, index) => {
            purchaseOrders.push({
                id: Utils.getUniqueId(),
                name: name,
                selected: false,
                projectId: projectId,
                purchaseID: last_po_id,
                vendorID: item.vendor_id || '',
                vendor_name: item.vendor_name || '',
                vendor_email: item.vendor_email || '',
                approval_deadline_date: approval_deadline_date || '',
                delivery_deadline_date: delivery_deadline_date || '',
                status: 0,
                assignees: [],
                introduction_text: description || '',
                terms_condition_id: terms_condition_id || '',
                terms_condition: terms_condition || '',
                cost_items: item.cost_items.map((item, index) => {

                    return {
                        id: Utils.getUniqueId(),
                        material_id: item.material_id || '',
                        material_name: item.material_name || '',
                        unitrate: parseFloat(item.unitrate || 0),
                        tax: 0,
                        markup: 0,
                        tax_amount: 0,
                        markup_amount: 0,
                        final_unitrate: parseFloat(item.unitrate || 0),
                        amount: parseFloat(item.amount || 0),
                        final_amount: parseFloat(item.amount || 0),
                        pre_tax_amount: parseFloat(item.amount || 0),
                        value_type: `$`,
                        unit_id: item.unit_id || '',
                        unit_name: item.unit_name || '',
                        quantity: parseFloat(item.quantity || 0),
                        description: item.description || '',
                        notes: ''
                    }
                }),
            })

            last_po_id = getNextID(last_po_id)

        })

        setPurchaseOrders(purchaseOrders)

    }

    useEffect(() => {

        RenderPosData(po_data)
        LoadStoreData()
    }, [])

    useEffect(() => {

        if (store && store.user) {

            let { team_users, settings } = store.user


            if (Array.isArray(team_users)) {

                let _team_users = team_users.filter(opt => opt.status == '1').map((opt) => {
                    return {
                        value: opt.id,
                        label: opt.name,
                    }
                })

                setAssigneeOptions(_team_users)
                setAssigneeOptions_Orginal(team_users)
            }
            if (settings && Array.isArray(settings.terms_conditions) && settings.terms_conditions.length) {



                let terms_conditions = settings.terms_conditions.map(tc => {
                    return {
                        id: tc.id,
                        value: tc.id,
                        label: tc.name,
                        body: tc.body,
                        isDefault: false,
                        document: tc.document || []
                    }
                })

                setTermsConditionOptions([
                    {
                        id: "none",
                        value: "none",
                        label: "None",
                        body: "",
                        isDefault: false,
                        document: []
                    },
                    ...terms_conditions
                ])
            }



        }

    }, [store])

    const HandleCancel = async (e) => {

        close()
    }


    const RFQPOCreateComponent = (purchase_order) => {

        const {
            name,
            purchaseID,
            vendor_name,
            status,
            introduction_text,
            approval_deadline_date,
            delivery_deadline_date,
            terms_condition_id,
            terms_condition,
            cost_items,
            assignees = [],
        } = purchase_order

        console.log(AssigneeOptions, 'AssigneeOptions');


        const HandleInputChanges = (value, key) => {

            let purchaseOrders = [...PurchaseOrders]
            let idx = purchaseOrders.findIndex((item) => item.id == purchase_order.id)

            if (idx != -1) {
                purchaseOrders[idx][key] = value
                setPurchaseOrders(purchaseOrders)
            }
        }

        const HandleSelectAssignee = (users) => {

            users = users.map(p => {
                let users = AssigneeOptions_Orginal.filter(ph => ph.id == p.id)[0]
                if (!users) return p

                return users
            })

            let purchaseOrders = [...PurchaseOrders]
            let idx = purchaseOrders.findIndex((item) => item.id == purchase_order.id)

            if (idx != -1) {
                purchaseOrders[idx].assignees = users
                setPurchaseOrders(purchaseOrders)
            }
        }

        const HandleMaterialsInputChange = (value, index, key) => {

            let purchaseOrders = [...PurchaseOrders]
            let idx = purchaseOrders.findIndex((item) => item.id == purchase_order.id)

            if (idx != -1) {
                purchaseOrders[idx].cost_items[index][key] = value
                setPurchaseOrders(purchaseOrders)
            }
        }
        const HandleTermsConditions = (value, label) => {
            let purchaseOrders = [...PurchaseOrders]
            let idx = purchaseOrders.findIndex((item) => item.id == purchase_order.id)

            if (idx != -1) {
                purchaseOrders[idx].terms_condition_id = value
                purchaseOrders[idx].terms_condition = label
                setPurchaseOrders(purchaseOrders)
            }
        }

        const materialsOptions = cost_items.map((item, index) => {
            return {
                value: item.material_id,
                label: item.material_name,
                isDefault: false
            }
        })

        return (
            <div className="project-create_client_invoice-main" id='project-create_client_invoice-main' style={{ maxWidth: '100%' }}>
                <div className="create_client_invoice-panel-content">

                    <div className="panel-content-sections">
                        <div className="create-client_invoicecontent-main">

                            <div className="create-client_invoicesections">

                                <div className="create-client_invoicesection">
                                    <div className="section-input-items">
                                        <div className="section-input-item" style={{ width: '33%' }}>
                                            <TextInput
                                                props={{
                                                    id: "create-invoice-name",
                                                    value: name,
                                                    placeholder: '',
                                                    setValue: (value) => HandleInputChanges(value, 'name'),
                                                    isIcon: false,
                                                    isLabel: true,
                                                    isRequired: true,
                                                    label: "Name",
                                                }}
                                            />
                                        </div>
                                        <div className="section-input-item" style={{ width: '33%' }}>
                                            <TextInput
                                                props={{
                                                    id: "create-invoice-purchaseID",
                                                    value: purchaseID,
                                                    placeholder: '',
                                                    setValue: (value) => { },
                                                    isIcon: false,
                                                    isLabel: true,
                                                    isRequired: true,
                                                    readOnly: true,
                                                    label: "PO ID",
                                                }}
                                            />
                                        </div>
                                        <div className="section-input-item" style={{ width: '33%' }}>
                                            <TextInput
                                                props={{
                                                    id: "create-invoice-vendor_name",
                                                    value: vendor_name,
                                                    placeholder: '',
                                                    setValue: (value) => { },
                                                    isIcon: false,
                                                    isLabel: true,
                                                    isRequired: true,
                                                    readOnly: true,
                                                    label: "Vendor",
                                                }}
                                            />
                                        </div>


                                    </div>
                                    <div className="section-input-items">
                                        <div className="section-input-item" style={{ width: '33%', height: 'max-content' }}>
                                            <MultiSelectInput
                                                props={{
                                                    id: 'assignee-multi-select-input',
                                                    placeholder: 'Select Assignees',
                                                    isRequired: true,
                                                    value: assignees,
                                                    options: AssigneeOptions,
                                                    isIcon: false,
                                                    avoidDuplicate: true,
                                                    icon: '',
                                                    isLabel: true,
                                                    label: 'Assignees',
                                                    setValue: (value) => HandleSelectAssignee(value),
                                                }}
                                            />
                                        </div>
                                        <div className="section-input-item" style={{ width: '33%' }}>
                                            <DateTimeInput
                                                props={{
                                                    id: "create-invoice-approvaldate",
                                                    value: approval_deadline_date,
                                                    placeholder: '',
                                                    setValue: (value) => HandleInputChanges(value, 'approval_deadline_date'),
                                                    isIcon: false,
                                                    isLabel: true,
                                                    icon: Icons.general.schedule,
                                                    isRequired: true,
                                                    label: "Approval Deadline",
                                                }}
                                            />
                                        </div>
                                        <div className="section-input-item" style={{ width: '33%' }}>
                                            <DateTimeInput
                                                props={{
                                                    id: "create-invoice-deliverydate",
                                                    value: delivery_deadline_date,
                                                    placeholder: '',
                                                    setValue: (value) => HandleInputChanges(value, 'delivery_deadline_date'),
                                                    isIcon: false,
                                                    isLabel: true,
                                                    icon: Icons.general.schedule,
                                                    isRequired: true,
                                                    label: "Expected Delivery Date",
                                                }}
                                            />
                                        </div>

                                    </div>
                                </div>

                                <div className="create-client_invoicesection" id={`create-client_invoiceintroduction_text`}>

                                    <div className={`createproposal-description-main`}>
                                        <div className="head">
                                            <div className="title">
                                                <div className="icon" dangerouslySetInnerHTML={{ __html: Icons.general.description }}></div>
                                                <div className="label">Description</div>
                                            </div>
                                        </div>
                                        <div className="item-expanded">
                                            <ReactQuill
                                                theme="snow"
                                                value={introduction_text || ""}
                                                onChange={(value) => HandleInputChanges(value, 'introduction_text')}
                                            />
                                        </div>
                                    </div>

                                </div>

                                <div className="create-client_invoicesection" id={`create-client_invoicepayment_schedule`}>
                                    <div className={`createproposal-description-main`}>
                                        <div className="head">
                                            <div className="title">
                                                <div className="label">Materials</div>
                                                <div className="icon" dangerouslySetInnerHTML={{ __html: Icons.general.info }}></div>
                                            </div>
                                        </div>

                                        <div className="purchase_order-items-splits-main">
                                            <div className="purchase_order-splits-header">
                                                <div className="header-item-name">Item Details</div>
                                                <div className="header-item-quantity">Quantity</div>
                                                <div className="header-item-action" style={{ display: 'none' }}>Action</div>
                                            </div>
                                            <div className="purchase_order-splits-content">
                                                {cost_items.map((item, index) => (
                                                    <div className="purchase_order-content-item">
                                                        <div className="purchase_order-content-items">
                                                            <div className="purchase_order-content-name">

                                                                <SelectInput
                                                                    props={{
                                                                        id: `purchase_order-material-${index}`,
                                                                        value: item.material_id,
                                                                        placeholder: 'Select material',
                                                                        options: materialsOptions || [],
                                                                        setValue: (value, label) => { },
                                                                        isIcon: false,
                                                                        isLabel: false,
                                                                        isRequired: false,
                                                                        readOnly: true,
                                                                        disableDefaultIcon: true,
                                                                        label: "Material",
                                                                    }}
                                                                />
                                                            </div>
                                                            <div className="purchase_order-content-quantity">
                                                                <TextInput
                                                                    props={{
                                                                        id: `purchase_order-quantity-${index}`,
                                                                        value: item.quantity,
                                                                        placeholder: 'Quantity',
                                                                        setValue: (value) => { },
                                                                        isIcon: false,
                                                                        isLabel: false,
                                                                        isRequired: true,
                                                                        readOnly: true,
                                                                        type: 'number',
                                                                        label: "Quantity",
                                                                    }}
                                                                />
                                                                {item.unit_name}
                                                            </div>
                                                            <div className="purchase_order-content-action">
                                                            </div>
                                                        </div>
                                                        <div className="purchase_order-content-items">
                                                            <div className="purchase_order-content-description">
                                                                <TextArea
                                                                    props={{
                                                                        id: `purchase_order-description-${index}`,
                                                                        value: item.description,
                                                                        placeholder: 'Description',
                                                                        setValue: (value) => HandleMaterialsInputChange(value, index, 'description'),
                                                                        isIcon: false,
                                                                        isLabel: false,
                                                                        isRequired: false,
                                                                        label: "Description",
                                                                    }}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                        </div>

                                    </div>
                                </div>
                                <div className="create-client_invoicesection" id={`create-purchase_order_terms_conditions`}>
                                    <div className={`createproposal-description-main`}>
                                        <div className="head">
                                            <div className="title">
                                                <div className="label">Terms and conditions</div>
                                                <div className="icon" dangerouslySetInnerHTML={{ __html: Icons.general.info }}></div>
                                            </div>
                                        </div>
                                        <div className="select-input">
                                            <div className="section-input-items">
                                                <div className="section-input-item">
                                                    <SelectInput
                                                        props={{
                                                            id: "create-client_invoiceterms_conditions-input",
                                                            value: terms_condition_id,
                                                            placeholder: '',
                                                            readOnly: false,
                                                            options: termsConditionOptions || [],
                                                            setValue: (value, label) => HandleTermsConditions(value, label),
                                                            isStatus: false,
                                                            isIcon: false,
                                                            isLabel: false,
                                                            isRequired: false,
                                                            label: "",
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="terms-condition-result" dangerouslySetInnerHTML={{ __html: terms_condition }}></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }


    return (
        <>

            {isLoading ?
                <Loading
                    props={{
                        isMainLogo: false,
                        isLabel: true
                    }} />
                : null}
            {warningAlert ?

                <SystemToastPopup
                    props={{
                        type: warningAlertType,
                        message: apiFailedMessage || "Error in Purchase Order, Please try again!",
                        callback: (confirmation) => setWarningAlert(false)
                    }} />

                : null}
            <div className="popup-container-main popup-container-center">
                <div className="popup-block-ui"></div>
                <div className="side-popup-container preview-popup-container">

                    <div className="side-popup-header">
                        <div></div>
                        <div className="header-item-select">
                            <div className="header-item-select-content">
                                <div className="label">Optimized Purchase Orders Creation</div>
                            </div>
                        </div>
                        <div
                            className="header-item-close"
                            onClick={(e) => HandleCancel()}
                            dangerouslySetInnerHTML={{ __html: Icons.general.close }}
                        ></div>
                    </div>

                    <div className="sidebar-popup-content">
                        <div className="rfq-pos_creation-main">
                            <div className="pos_creation-sidebar">
                                <div className="pos_creation-sidebar-items">
                                    {PurchaseOrders.map((item, index) => (
                                        <div
                                            key={`pos_creation-sidebar-item-${index}`}
                                            className={`pos_creation-sidebar-item ${SelectedPurchaseOrder == index ? 'pos_creation-sidebar-item-active' : ''}`}
                                            onClick={(e) => HandleSelectPurchaseOrder(index)}
                                        >
                                            <div className="select-input">
                                                <RadioInput props={{
                                                    value: item.selected || false,
                                                    isIcon: false,
                                                    icon: "",
                                                    inputType: "toggle",
                                                    name: "pos_creation-sidebar-item",
                                                    setValue: (value) => HandlePOSelect(value, index),
                                                }} />
                                            </div>
                                            <div className="label">{item.purchaseID} | {item.vendorID}</div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                            <div className="pos_creation-content-container">
                                {PurchaseOrders[SelectedPurchaseOrder] ? RFQPOCreateComponent(PurchaseOrders[SelectedPurchaseOrder]) : null}
                            </div>
                        </div>
                    </div>
                    <div className="sidebar-popup-footer">
                        <div className="footer-item action-items">
                            <div className="action-preview">
                            </div>
                            <div className='action-btns'>
                                <div
                                    className={`action-btn button-download ${PurchaseOrders.filter(po => po.selected).length ? '' : 'disabled'}`}
                                    onClick={(e) => HandlePurchaseOrders(e)}
                                >

                                    <div className="label">Create Purchase Orders</div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )

}

export default PreviewOptimizedPOCreation;