
import React, { useEffect, useState } from 'react'
import { Route, Routes, BrowserRouter, Link, useLocation, Navigate, Outlet, useNavigate, useParams } from 'react-router-dom';
import Images from '../assets/Images'
import Icons from '../assets/Icons'
import ReverseProxy from '../config/reverseProxy'
import SystemToastPopup from './SystemToastPopup'


const SubscriptionExpiredPopup = ({ props }) => {

    const { callback } = props
    const navigate = useNavigate
    const [ShowContactUsResult, setShowContactUsResult] = useState(false)




    const HandleContactUs = async (e) => {

        setShowContactUsResult(true)

    }
    const HandleSubscribe = async (e) => {

        callback(true)

    }


    return (
        <div className="popup-container-main popup-container-center">
            <div className="popup-block-ui"></div>

            {ShowContactUsResult ?

                <SystemToastPopup
                    props={{
                        type: 'success',
                        message: "We will contact you shortly",
                        callback: (confirmation) => setShowContactUsResult(false)
                    }} />

                : null}

            <div className="subscription-exiry-popup-main">
                <div className="subscription-exiry-content">
                    <div className="heading">Your subscription expired, Renew Now!</div>
                    <div className="message">
                        Don’t worry all of your data is safe with us and you can continue using
                        features like project planning, task scheduling and more.
                        <br />
                        <br />
                        If you want to continue using our web application, please
                        click on the button below and one of our team members
                        will contact you with steps on how to proceed.
                    </div>
                    <div className="action-buttons">
                        <div
                            className="button subscribe-button"
                            onClick={(e) => HandleSubscribe(e)}
                        >SUBSCRIBE NOW</div>
                        <div
                            className="button subscribe-contactus"
                            onClick={(e) => HandleContactUs(e)}
                        >CONTACT US</div>
                    </div>
                </div>

            </div>
        </div>
    )

}

export default SubscriptionExpiredPopup;