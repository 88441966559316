import React, { useState, useEffect, useRef } from 'react'
import Icons from '../assets/Icons'
import Utils from '../utils'

const CostInput = ({ props }) => {

    const { id, readOnly = false, placeholder, currency_type, costMethodValue, isCostMethod, value = "", setValue, isIconLeft, isIcon, icon, isLabel, label, isRequired } = props

    const [cost, setCost] = useState(0)
    const [costMethod, setCostMethod] = useState('')
    const [focus, setFocus] = useState(false)

    const HandleNumberOnly = (value) => {
        const numberRegex = /^-?\d+(\.\d+)?$/;
        const isNumber = numberRegex.test(value)
        if (isNumber) setCost(cost)

        return isNumber
    }
    const HandleCostMethod = (_value) => {

        if (_value == costMethodValue) return

        setCostMethod(_value)
        setValue(cost, _value)
    }
    const HandleInput = (_value, init = false) => {

        if (_value && !HandleNumberOnly(_value)) return

        if (_value == value) return

        setCost(_value)
        setValue(_value, costMethod)
    }

    useEffect(() => {

        setCost(value)
        setCostMethod(costMethodValue)

    }, [value, costMethodValue])

    return (
        <div className={`costinput-main ${readOnly ? `input-main-readOnly` : ''}`} >
            {isIconLeft && !isCostMethod && isIcon ?
                <span className='costtype-icon'>{icon}</span>
                : null}
            <input
                className={`input  ${readOnly ? `input-readOnly` : ''}`}
                value={cost}
                type="text"
                id={id}
                aria-invalid={false}
                readOnly={readOnly}
                required={isRequired}
                placeholder={placeholder || ""}
                autoComplete='off'
                onChange={(e) => HandleInput(e.target.value)}
                onFocus={(e) => setFocus(true)}
                onBlur={(e) => setFocus(false)}

            />
            {isCostMethod && !readOnly ?
                <select
                    className="costmethod-select"
                    value={costMethod}
                    onChange={(e) => HandleCostMethod(e.target.value)}
                    autoComplete='off'
                >
                    <option value="%">%</option>
                    <option value={`${currency_type}`}>{currency_type}</option>
                </select>
                : null}
            {!isIconLeft && !isCostMethod && isIcon ?
                <span className='costtype-icon'>{icon}</span>
                : null}
            {isLabel && label ? <label htmlFor={id} className={`textinput-label ${focus || String(cost).length > 0 ? 'textinput-label-active' : ''}`} data-required={`${isRequired ? '*' : ''}`} >{label}</label> : null}

        </div>
    )
}
const DateTimeInput = ({ props }) => {

    const { id, readOnly = false, value = "", minValue = null, maxValue = null, setValue, isIcon, icon, isLabel, label, isRequired } = props

    const [cost, setDate] = useState(0)
    const [focus, setFocus] = useState(false)

    const HandleInput = (_value, init = false) => {

        if (_value == value) return

        setDate(_value)
        setValue(_value)
    }

    useEffect(() => {

        setDate(value)

    }, [value])

    return (
        <div className={`dateinput-main ${readOnly ? `input-main-readOnly` : ''}`} >

            <input
                autoComplete='off'
                min={minValue || '0000-01-01T00:00'}
                max={maxValue || '9999-12-31T23:59'}
                className={`input  ${readOnly ? `input-readOnly` : ''}`}
                value={cost}
                type="date"
                id={id}
                required={isRequired}
                aria-invalid={true}
                readOnly={readOnly}
                onChange={(e) => HandleInput(e.target.value)}
                onFocus={(e) => setFocus(true)}
                onBlur={(e) => setFocus(false)}
            />


            {isLabel && label ? <label htmlFor={id} className={`textinput-label ${focus || String(cost).length > 0 ? 'textinput-label-active' : ''}`} data-required={`${isRequired ? '*' : ''}`} >{label}</label> : null}

        </div>
    )
}
const TimeInput = ({ props }) => {

    const { id, readOnly = false, value = "", setValue, isIcon, icon, isLabel, label, isRequired } = props

    const [cost, setTime] = useState(0)
    const [focus, setFocus] = useState(false)

    const HandleInput = (_value, init = false) => {

        if (_value == value) return

        setTime(_value)
        setValue(_value)
    }

    useEffect(() => {

        setTime(value)

    }, [value])

    return (
        <div className={`dateinput-main ${readOnly ? `input-main-readOnly` : ''}`} >

            <input
                autoComplete='off'
                max='9999-12-31T23:59'
                className={`input  ${readOnly ? `input-readOnly` : ''}`}
                value={cost}
                type="time"
                id={id}
                required={isRequired}
                aria-invalid={true}
                readOnly={readOnly}
                onChange={(e) => HandleInput(e.target.value)}
                onFocus={(e) => setFocus(true)}
                onBlur={(e) => setFocus(false)}
            />
            {isLabel && label ? <label htmlFor={id} className={`textinput-label ${focus || String(cost).length > 0 ? 'textinput-label-active' : ''}`} data-required={`${isRequired ? '*' : ''}`} >{label}</label> : null}
        </div>
    )
}
const TextInput = ({ props }) => {

    let { id, type, isRequired, value = "", setMinLimit, setMaxLimit, maxLimitLabel, readOnly = false, placeholder = "", setValue, isIcon, icon, isLeft = true, isLabel, label } = props

    type = type || 'text'

    const [text, setText] = useState(value || '')
    const [focus, setFocus] = useState(false)

    const HandleInput = (value, init = false) => {

        if (type == 'number') {


            value = Utils.numberOnly(value)
        }


        if (setMaxLimit != undefined && setMaxLimit < parseFloat(value || 0)) {
            setText(setMaxLimit)
            if (!init) setValue(setMaxLimit)
        }
        else {
            setText(value)
            if (!init) setValue(value)
        }


    }

    useEffect(() => {

        setText(value)

    }, [value])

    return (
        <div className={`textinput-main ${readOnly ? `input-main-readOnly` : ''}`} >
            {maxLimitLabel && setMaxLimit != undefined ? <div className="max-limit-label">/ {setMaxLimit}</div> : ''}
            {isIcon && isLeft ? <span className='textinput-icon' dangerouslySetInnerHTML={{ __html: icon }}></span> : null}
            <input
                autoComplete='off'
                className={`input  ${readOnly ? `input-readOnly` : ''}  ${!isIcon ? 'input-full' : ''}`}
                id={id}
                value={text}
                readOnly={readOnly}
                placeholder={placeholder}
                type='text'
                required={isRequired}
                autoCorrect="off"
                aria-invalid={false}
                onChange={(e) => {
                    HandleInput(e.target.value)
                }}
                onFocus={(e) => setFocus(true)}
                onBlur={(e) => setFocus(false)}

            />
            {isIcon && !isLeft ? <span className='textinput-icon' dangerouslySetInnerHTML={{ __html: icon }}></span> : null}
            {isLabel && label ? <label htmlFor={id} className={`textinput-label ${focus || String(text).length > 0 ? 'textinput-label-active' : ''}`} data-required={`${isRequired ? '*' : ''}`} >{label}</label> : null}
        </div>
    )
}
const TextArea = ({ props }) => {


    let { id, isRequired, value = "", readOnly = false, placeholder = "", setValue, isIcon, isLabel, label } = props

    const [text, setText] = useState(value || '')
    const [focus, setFocus] = useState(false)

    const HandleInput = (value, init = false) => {


        setText(value)
        if (!init) setValue(value)


    }

    useEffect(() => {

        setText(value)

    }, [value])

    return (
        <div className={`textinput-main input-textarea ${readOnly ? `input-main-readOnly` : ''}`} >
            <textarea
                autoComplete='off'
                className={`input input-full ${readOnly ? `input-readOnly` : ''}`}
                id={id}
                readOnly={readOnly}
                placeholder={placeholder}
                required={isRequired}
                aria-invalid={false}
                autoCorrect="off"
                onChange={(e) => {
                    HandleInput(e.target.value)
                }}
                onFocus={(e) => setFocus(true)}
                onBlur={(e) => setFocus(false)}
                value={text || ""}

            ></textarea>
            {isLabel && label ? <label htmlFor={id} className={`textinput-label ${focus || String(text).length > 0 ? 'textinput-label-active' : ''}`} data-required={`${isRequired ? '*' : ''}`} >{label}</label> : null}
        </div>
    )
}
const ColorInput = ({ props }) => {

    let { id, isRequired, value = "", readOnly = false, placeholder = "", setValue, isLabel, label } = props

    const [color, setColor] = useState(value || '')
    const [focus, setFocus] = useState(false)

    const ColorInputRef = useRef()

    const HandleInput = (value, init = false) => {

        setColor(value)
        if (!init) setValue(value)

    }

    const HandleInputClick = () => {

        if (ColorInputRef && ColorInputRef.current) {
            ColorInputRef.current.querySelector('input').click()
        }

    }

    useEffect(() => {

        setColor(value)

    }, [value])

    return (
        <div
            ref={ColorInputRef}
            className={`colorinput-main ${readOnly ? `input-main-readOnly` : ''}`}
            onClick={(e) => HandleInputClick()}
        >

            <div className="colorinput-input" style={{ background: color }}></div>
            <div className="colorinput-value">{color}</div>
            <input
                autoComplete='off'
                className={`input  ${readOnly ? `input-readOnly` : ''}`}
                id={id}
                value={color}
                readOnly={readOnly}
                placeholder={placeholder}
                type='color'
                required={isRequired}
                aria-invalid={false}
                onChange={(e) => {
                    HandleInput(e.target.value)
                }}
                onFocus={(e) => setFocus(true)}
                onBlur={(e) => setFocus(false)}

            />
            {isLabel && label ? <label htmlFor={id} className={`colorinput-label colorinput-label-active`} data-required={`${isRequired ? '*' : ''}`} >{label}</label> : null}
        </div>
    )
}
const QuantityInput = ({ props }) => {

    const { id, parentQty, actionType, isRequired, value = "", readOnly = false, placeholder = "", setValue, isIcon, icon, isLeft = true, isLabel, label } = props

    const _actionType = actionType == "multiple" ? "X" : actionType == "add" ? "+" : actionType == "subract" ? "-" : "."

    const [text, setText] = useState(value || '')
    const [focus, setFocus] = useState(false)
    const [resultValue, setResultValue] = useState(0)

    const HandleInput = (value, init = false) => {

        if (parentQty) {

            let qty = 0

            if (actionType == "multiple") qty = parseFloat(parentQty || 1) * parseFloat(value)
            else if (actionType == "add") qty = parseFloat(parentQty || 0) + parseFloat(value)
            else if (actionType == "subract") qty = parseFloat(parentQty || 0) - parseFloat(value)

            setResultValue(qty || 0)

        }

        setText(value || 0)

        if (!init) setValue(value || 0)

    }

    useEffect(() => {

        setText(value || 0)

        if (parentQty) {

            let qty = 0

            if (actionType == "multiple") qty = parseFloat(parentQty || 1) * parseFloat(value)
            else if (actionType == "add") qty = parseFloat(parentQty || 0) + parseFloat(value)
            else if (actionType == "subract") qty = parseFloat(parentQty || 0) - parseFloat(value)

            setResultValue(qty || 0)

        }

    }, [value])

    return (
        <div className={`textinput-main ${readOnly ? `input-main-readOnly` : ''}`} >
            {parentQty ?
                <div className="input-parent-value">
                    <div className="value">{parentQty}</div>
                    <div className="action">{_actionType}</div>
                </div>
                : null}
            {isIcon && isLeft ? <span className='textinput-icon'>{icon}</span> : null}
            <input
                autoComplete='off'
                className={`input  ${readOnly ? `input-readOnly` : ''}  ${!isIcon ? 'input-full' : ''}`}
                id={id}
                value={text}
                readOnly={readOnly}
                placeholder={placeholder}
                type="text"
                aria-invalid={false}
                onChange={(e) => {
                    HandleInput(e.target.value)
                }}
                onFocus={(e) => setFocus(true)}
                onBlur={(e) => setFocus(false)}

            />
            {parentQty ?
                <div className="input-value-result">
                    <div className="value">{parseFloat(resultValue).toFixed(0) || "N/A"}</div>
                </div>
                : null}
            {isIcon && !isLeft ? <span className='textinput-icon'>{icon}</span> : null}
            {isLabel && label ? <label htmlFor={id} className={`textinput-label ${focus || text.length > 0 ? 'textinput-label-active' : ''}`} data-required={`${isRequired ? '*' : ''}`} >{label}</label> : null}
        </div>
    )
}
const SearcBarInput = ({ props }) => {

    const { id, value = "", placeholder = "", setValue, isIcon, icon, isLeft = true } = props

    const [text, setText] = useState('')
    const [searchDebounce, setSearchDebounce] = useState(null)
    const DebounceDelay = 200

    const HandleInput = (value, init = false) => {

        setText(value)

        if (searchDebounce) clearTimeout(searchDebounce)

        setSearchDebounce(setTimeout(() => {

            if (!init) setValue(value)

        }, DebounceDelay))
    }

    useEffect(() => {
        setText(value)
    }, [])

    return (
        <div className="textinput-main">
            {isIcon && isLeft ? <span
                className='textinput-icon'
                dangerouslySetInnerHTML={{ __html: icon }}
            ></span> : null}
            <input
                autoComplete='off'
                className={`input ${!isIcon ? 'input-full' : ''}`}
                value={text}
                placeholder={placeholder}
                type="text"
                id={id}
                aria-invalid={false}
                onChange={(e) => HandleInput(e.target.value)}
            />
            {isIcon && !isLeft ? <span
                className='textinput-icon'
                dangerouslySetInnerHTML={{ __html: icon }}
            ></span> : null}
        </div>
    )
}
const SelectInput = ({ props }) => {

    const { id, placeholder, disableDefaultIcon = false, readOnly = false, isStatus = false, alsoTypeValue, isRequired, value = "", options, setValue, isIcon, icon, isLabel, label } = props

    const [searchInput, setSearchInput] = useState('')
    const [focus, setFocus] = useState(false)
    const [dropdownsStatus, setDropdownsStatus] = useState(false)
    const [dropdowns, setDropdowns] = useState([])

    const HandleDropdownStatus = () => {

        let input = document.getElementById(id)
        let dropdown_items = document.getElementById(`${id}-textinput-dropdown-results`)

        window.addEventListener('click', (e) => {

            let path = e.path || (e.composedPath && e.composedPath());

            if (Array.isArray(path) && !path.includes(dropdown_items) && !path.includes(input)) {
                setDropdownsStatus(false)
            }
        })

    }
    const HandleDropdownSelect = (opt) => {

        setSearchInput(opt.label)
        setValue(opt.value, opt.label)
    }
    const searchItemInArray = (input, array) => {
        const matches = [];

        for (let i = 0; i < array.length; i++) {

            if (array[i].label.toLowerCase().includes(input.toLowerCase())) {
                matches.push(array[i]);
            }
        }

        return matches;
    }

    const HandleInput = (value) => {

        setSearchInput(value)

        let _items = options

        _items = searchItemInArray(value, _items)

        setDropdowns(_items)


        if (alsoTypeValue) setValue(value)
    }

    const HandleAddDropDown = (id) => {
    }

    useEffect(() => {

        let option = options.filter(opt => opt.value == value)[0]

        setSearchInput(option ? option.label : '')
        setDropdowns(options)

        if (!readOnly) {
            HandleDropdownStatus()
        }


    }, [value, options])

    return (
        <div className={`textinput-main ${readOnly ? `input-main-readOnly` : ''}`} >
            {isIcon ? <span
                className='textinput-icon'
                dangerouslySetInnerHTML={{ __html: icon }}
            ></span> : null}
            <input
                autoComplete='off'
                className={`input ${readOnly ? `input-readOnly` : ''} ${!isIcon ? 'input-full' : ''}`}
                value={searchInput}
                placeholder={placeholder || ""}
                type="text"
                id={id}
                autoCorrect="off"
                aria-invalid={false}
                required={isRequired}
                onInput={(e) => HandleInput(e.target.value)}
                onFocus={(e) => {
                    setFocus(true)
                    setDropdownsStatus(true)
                }}
                onBlur={(e) => setFocus(false)}
                readOnly={readOnly}
            />
            {!disableDefaultIcon ?
                <span

                    className={`dropdown-icon ${dropdownsStatus && !readOnly ? 'dropdown-icon-active' : ''}`}
                    dangerouslySetInnerHTML={{ __html: Icons.general.dropdown_arrow }}
                ></span>
                : ''}

            {dropdownsStatus && !readOnly ?
                <div className="textinput-dropdown-main" id={`${id}-textinput-dropdown-results`}>
                    <div className="textinput-dropdown-results">
                        {dropdowns.filter(d => !d.disabled).length ?
                            dropdowns.filter(d => !d.disabled).map((opt, i) => (

                                isStatus ?
                                    <span onClick={(e) => HandleDropdownSelect(opt)} className="textinput-dropdown-item" id={opt.value} key={i}>
                                        <div className={`input-status input-status-${opt.value}`}></div>
                                        <div className="label"> {opt.label}</div>
                                    </span>
                                    :
                                    <span onClick={(e) => HandleDropdownSelect(opt)} className="textinput-dropdown-item" id={opt.value} key={i}>
                                        {opt.label}
                                    </span>

                            ))
                            :
                            <div className="textinput-dropdown-noresult">
                                <div
                                    className="icon"
                                    dangerouslySetInnerHTML={{ __html: Icons.general.noresult }}
                                ></div>
                                <div className="label">No options</div>
                            </div>
                        }

                    </div>
                    <div className="textinput-dropdown-actions" style={{ display: 'none' }}>
                        <div
                            className="dropdown-additem"
                            onClick={(e) => HandleAddDropDown(id)}
                        >
                            <div
                                className="icon"
                                dangerouslySetInnerHTML={{ __html: Icons.general.add_round_btn }}
                            ></div>
                            <div className="label">Add new</div>
                        </div>
                    </div>
                </div>
                : null}
            {isLabel && label ? <label htmlFor={id} className={`textinput-label ${focus || String(searchInput || "").length > 0 ? 'textinput-label-active' : ''}`} data-required={`${isRequired ? '*' : ''}`}>{label}</label> : null}
        </div>
    )
}
const RadioInput = ({ props }) => {

    const { id = "", inputType, className = [], value = "", setValue, name = "" } = props

    const Elem_Id = `radio-id-${Utils.getUniqueId()}`

    const [checked, setChecked] = useState(value)

    const HandleInput = (value, init = false) => {

        setChecked(value)
        if (!init) setValue(value)
    }

    useEffect(() => {
        setChecked(value)

        let input_elem = document.querySelector(`.${Elem_Id}`)
        if (input_elem) input_elem.checked = value

    }, [value])


    return (
        <div className="checkboxinput-main">
            {inputType == 'toggle' ?
                <label class="checkboxinput-switch">
                    <input
                        autoComplete='off'
                        type="checkbox"
                        className={`${Elem_Id}`}
                        name={name}
                        defaultChecked={checked}
                        // checked={checked}
                        onInput={(e) => HandleInput(e.target.checked)}
                    />
                    <span class="checkboxinput-slider"></span>
                </label>
                :
                <input
                    autoComplete='off'
                    id={id}
                    className={`input ${className.join(' ')} ${Elem_Id}`}
                    name={name}
                    type={inputType || `radio`}
                    defaultChecked={checked}
                    // checked={checked}
                    onInput={(e) => HandleInput(e.target.checked)}
                />
            }
        </div>
    )
}
const RadioOptionsInput = ({ props }) => {


    const { id = "", inputType, readOnly, isIconLeft, isIcon, icon, isLabel, label, classNames = [], options, value = "", setValue, name = "" } = props

    const [inputValue, setInputValue] = useState(value)

    const HandleInput = (value, init = false) => {

        setInputValue(value)
        if (!init) setValue(value)
    }



    useEffect(() => {

        let selected_option = options.filter(opt => opt.value == value)[0]

        if (!selected_option) return

        let input_elem = document.querySelector(`#${selected_option.id}`)
        if (input_elem) input_elem.checked = true

    }, [value])

    return (
        <div className={`input-option-main ${classNames.join(' ')}`} id={id}>
            <div className="label-main">
                {isIcon && isIconLeft ? <span
                    className='icon'
                    dangerouslySetInnerHTML={{ __html: icon }}
                ></span> : null}
                {isLabel ? <div className="label">{label}</div> : ''}
                {isIcon && !isIconLeft ? <span
                    className='icon'
                    dangerouslySetInnerHTML={{ __html: icon }}
                ></span> : null}
            </div>

            <div className="options">
                {
                    options.map((option, i) => (
                        <div className="option" key={option.id}>
                            <input
                                type={inputType || `radio`}
                                id={option.id}
                                name={name}
                                readOnly={readOnly || false}
                                value={inputValue == option.value}
                                defaultChecked={inputValue == option.value}
                                onInput={(e) => HandleInput(option.value)}
                            />
                            <label htmlFor={option.id}>{option.label}</label>
                        </div>
                    ))
                }

            </div>
        </div>
    )
}

const MultiSelectInput = ({ props }) => {

    const { id, placeholder, readOnly = false, avoidDuplicate = true, isRequired, value, setValue, options, isIcon, icon, isLabel, label } = props

    const [MultiSelectInputOptions_Orginal, setMultiSelectInputOptions_Orginal] = useState([])
    const [MultiSelectInputOptions, setMultiSelectInputOptions] = useState([])
    const [MultiSelectInputSelectedItems, setMultiSelectInputSelectedItems] = useState([])
    const [MultiSelectInputSearchValue, setMultiSelectInputSearchValue] = useState([])
    const [MultiSelectInputOpenDropdown, setMultiSelectInputOpenDropdown] = useState(false)

    const HandleMultiSelectDropdownStatus = () => {

        let input = document.getElementById(id)
        let dropdown_items = document.getElementById(`${id}-multi-select-input-dropdown`)


        window.addEventListener('click', (e) => {

            let path = e.path || (e.composedPath && e.composedPath());

            if (Array.isArray(path) && !path.includes(dropdown_items) && !path.includes(input)) {
                setMultiSelectInputOpenDropdown(false)
            }
        })
    }

    const HandleMultiSelectSearch = (value) => {

        let _MultiSelectInputOptions = [...MultiSelectInputOptions_Orginal]

        if (value) {

            let select_items = MultiSelectInputSelectedItems.map(item => item.value)
            _MultiSelectInputOptions = _MultiSelectInputOptions.filter(opt => !select_items.includes(opt.value))
            _MultiSelectInputOptions = _MultiSelectInputOptions.filter(opt => opt.label.toLowerCase().includes(value.toLowerCase()))

        }

        setMultiSelectInputOptions(_MultiSelectInputOptions)
        setMultiSelectInputSearchValue(value)
        setMultiSelectInputOpenDropdown(true)

        HandleMultiSelectDropdownStatus()

        setValue(MultiSelectInputSelectedItems)
    }

    const HandleMultoSelectItemRemove = (id) => {

        let _MultiSelectInputSelectedItems = [...MultiSelectInputSelectedItems]

        _MultiSelectInputSelectedItems = _MultiSelectInputSelectedItems.filter(opt => opt.value != id)

        setMultiSelectInputSelectedItems(_MultiSelectInputSelectedItems)

        setValue(_MultiSelectInputSelectedItems)

        if (avoidDuplicate) {

            let _MultiSelectInputOptions_Orginal = [...MultiSelectInputOptions_Orginal]
            let _MultiSelectInputOptions = [...MultiSelectInputOptions]

            let removed_option = options.filter(opt => opt.value == id)[0]

            _MultiSelectInputOptions.push(removed_option)
            _MultiSelectInputOptions_Orginal.push(removed_option)

            setMultiSelectInputOptions_Orginal(_MultiSelectInputOptions_Orginal)
            setMultiSelectInputOptions(_MultiSelectInputOptions)
        }
    }

    const HandleMultiSelectDropdownItemClick = (item) => {

        let _MultiSelectInputSelectedItems = [...MultiSelectInputSelectedItems]

        _MultiSelectInputSelectedItems.push(item)

        if (avoidDuplicate) {

            let selected_items = _MultiSelectInputSelectedItems.map(item => item.value)

            let _MultiSelectInputOptions_Orginal = [...MultiSelectInputOptions_Orginal]
            let _MultiSelectInputOptions = [...MultiSelectInputOptions]

            _MultiSelectInputOptions = _MultiSelectInputOptions.filter(opt => !selected_items.includes(opt.value))
            _MultiSelectInputOptions_Orginal = _MultiSelectInputOptions_Orginal.filter(opt => !selected_items.includes(opt.value))

            setMultiSelectInputOptions_Orginal(_MultiSelectInputOptions_Orginal)
            setMultiSelectInputOptions(_MultiSelectInputOptions)
        }

        setMultiSelectInputSelectedItems(_MultiSelectInputSelectedItems)
        setMultiSelectInputOpenDropdown(false)
        setMultiSelectInputSearchValue('')

        setValue(_MultiSelectInputSelectedItems)
    }

    useEffect(() => {

        if (!options.length) return

        let _options = options.map((opt, i) => {
            return {
                label: opt.label,
                value: opt.value,
            }
        })

        if (avoidDuplicate && value.length) {

            let _MultiSelectInputOptions = []

            let selected_items = value.map(item => item.value)

            let removed_option = _options.filter(opt => !selected_items.includes(opt.value))

            _MultiSelectInputOptions.push(...removed_option)

            setMultiSelectInputOptions_Orginal(_MultiSelectInputOptions)
            setMultiSelectInputOptions(_MultiSelectInputOptions)
        }
        else {
            setMultiSelectInputOptions_Orginal(_options)
            setMultiSelectInputOptions(_options)
        }

        if (!readOnly) HandleMultiSelectDropdownStatus()

    }, [value, options])

    useEffect(() => {

        if (value && value.length) setMultiSelectInputSelectedItems(value)
        else setMultiSelectInputSelectedItems([])

    }, [value])


    return (
        <div className="multi-select-input-main">
            {isLabel && label ? <label htmlFor={id} className={`multi-select-input-label`} data-required={`${isRequired ? '*' : ''}`}>{label}</label> : null}

            {MultiSelectInputSelectedItems.length ?
                <div className="multi-select-input-selected-items">
                    {MultiSelectInputSelectedItems?.map(item => (
                        <div className="multi-select-input-selected-item"
                            key={`selected-item-${item.value}`}
                        >
                            <div className="selected-item-label">{item.label}</div>
                            {readOnly ? '' :
                                <div className="selected-item-delete"
                                    dangerouslySetInnerHTML={{ __html: Icons.general.close }}
                                    onClick={() => HandleMultoSelectItemRemove(item.value)}
                                ></div>
                            }
                        </div>
                    ))}

                </div>
                : ''}
            <div className="multi-select-input-item" style={{ display: readOnly ? 'none' : 'flex' }}>
                <input
                    type="text"
                    placeholder={placeholder || ''}
                    id={id}
                    value={MultiSelectInputSearchValue}
                    onChange={(e) => HandleMultiSelectSearch(e.target.value)}
                    onFocus={() => setMultiSelectInputOpenDropdown(true)}
                />
            </div>
            <div
                className={`multi-select-input-dropdown ${MultiSelectInputOpenDropdown ? 'multi-select-input-dropdown-active' : ''}`}
                id={`${id}-multi-select-input-dropdown`}
            >
                <div className="multi-select-input-dropdown-items">
                    {!MultiSelectInputOptions.length ?
                        <div className="multi-select-input-dropdown-item">
                            <div className="dropdown-item-label">No options</div>
                        </div>
                        :
                        MultiSelectInputOptions?.map(item => (
                            <div className="multi-select-input-dropdown-item"
                                key={`multi-select-input-dropdown-item-${item.value}`}
                                onClick={() => HandleMultiSelectDropdownItemClick(item)}
                            >
                                <div className="dropdown-item-label">{item.label}</div>
                            </div>
                        ))
                    }

                </div>
            </div>
        </div>
    )
}

export { MultiSelectInput, CostInput, TimeInput, DateTimeInput, ColorInput, SearcBarInput, QuantityInput, TextArea, TextInput, SelectInput, RadioInput, RadioOptionsInput }