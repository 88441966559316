
import proxyConfig from '../../config/reverseProxy'
import HeaderConfig from '../../config/header'

class ClientInvoice {

    constructor() {

    }


    async getNextClientInvoiceIDHandler(params) {

        try {

            let response = await fetch(proxyConfig['serverBaseUrl'] + '/builder/client-invoice/get-next-clientInvoiceId', {
                method: 'POST',
                headers: HeaderConfig.getHeader({ method: 'POST', isToken: true }),
                body: JSON.stringify(params)
            })

            response = await response.json()

            if (response) return response
        } catch {
            return { success: false }
        }


    }
    async getBasicClientInvoicesHandler(params) {

        try {

            let response = await fetch(proxyConfig['serverBaseUrl'] + '/builder/client-invoice/get-basic-client-invoices', {
                method: 'POST',
                headers: HeaderConfig.getHeader({ method: 'POST', isToken: true }),
                body: JSON.stringify(params)
            })

            response = await response.json()

            if (response) return response
        } catch {
            return { success: false }
        }


    }
    async getClientInvoicesHandler(params) {

        try {

            let response = await fetch(proxyConfig['serverBaseUrl'] + '/builder/client-invoice/get-client-invoices', {
                method: 'POST',
                headers: HeaderConfig.getHeader({ method: 'POST', isToken: true }),
                body: JSON.stringify(params)
            })

            response = await response.json()

            if (response) return response
        } catch {
            return { success: false }
        }


    }
    async createClientInvoiceHandler(params) {

        try {

            let response = await fetch(proxyConfig['serverBaseUrl'] + '/builder/client-invoice/create-client-invoices', {
                method: 'POST',
                headers: HeaderConfig.getHeader({ method: 'POST', isToken: true }),
                body: JSON.stringify(params)
            })

            response = await response.json()

            if (response) return response
        } catch {
            return { success: false }
        }


    }
    async updateClientInvoiceHandler(params) {

        try {

            let response = await fetch(proxyConfig['serverBaseUrl'] + '/builder/client-invoice/update-client-invoices', {
                method: 'POST',
                headers: HeaderConfig.getHeader({ method: 'POST', isToken: true }),
                body: JSON.stringify(params)
            })

            response = await response.json()

            if (response) return response
        } catch {
            return { success: false }
        }


    }
    async deleteClientInvoiceHandler(params) {

        try {

            let response = await fetch(proxyConfig['serverBaseUrl'] + '/builder/client-invoice/delete-client-invoices', {
                method: 'POST',
                headers: HeaderConfig.getHeader({ method: 'POST', isToken: true }),
                body: JSON.stringify(params)
            })

            response = await response.json()

            if (response) return response
        } catch {
            return { success: false }
        }


    }

}

export default ClientInvoice;