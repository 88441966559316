

import React, { useEffect, useState, useRef, useCallback, useMemo, StrictMode } from 'react'
import { Route, Routes, BrowserRouter, Link, useLocation, Navigate, Outlet, useNavigate, useParams } from 'react-router-dom';
import store from '../../redux/store'
import StoreHandler from '../../redux/StoreHandler'
import { useSelector, useDispatch } from 'react-redux'
import UserAction from '../../redux/action/userAction'

import ReverseProxy from '../../config/reverseProxy'

import ClientInvoiceHandler from '../../Handlers/ClientInvoice/ClientInvoice';
import Icons from '../../assets/Icons'
import Images from '../../assets/Images'
import { CostInput, TextInput, SelectInput, DateTimeInput, RadioInput } from '../../components/Inputs'
import Utils from '../../utils'
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

import { PDFExport, savePDF } from '@progress/kendo-react-pdf';



const PreviewClientInvoice = ({ props }) => {

    const { projectId, invoiceId } = useParams();

    const { menuType = 'create', close, invoiceLoaded, invoicedetials, saveCallback } = props

    const [project_id, setProject_id] = useState('')
    const [project_name, setProject_name] = useState('')
    const [estimate_id, setEstimate_id] = useState('')

    const [isLoading, setIsLoading] = useState(false)
    const [warningAlert, setWarningAlert] = useState(false)
    const [warningAlertType, setWarningAlertType] = useState('warning')
    const [apiFailedMessage, setApiFailedMessage] = useState("Error in Server")

    const navigate = useNavigate()
    const store = useSelector((store) => store)
    const dispatch = useDispatch()
    const { updateState } = new UserAction

    const storeHandler = new StoreHandler({
        dataStack: ['company', 'terms_conditions', 'field_settings', 'finance_settings', `project-${projectId}`],
        store: store,
        dispatch: dispatch,
        updateState: updateState,
    })

    const LoadStoreData = async () => {
        setIsLoading(true)
        let resolved = await storeHandler.init()
        setIsLoading(false)
    }
    const pdfRef = useRef()
    const clientInvoiceHandler = new ClientInvoiceHandler()

    const [unitsOptions, setUnitsOptions] = useState([])
    const [currency_type, setCurrency_type] = useState('₹')

    const CompanyProfile = store.user.company || undefined
    const isCompany = Object.keys(CompanyProfile).length > 0

    const [ShowWatermark, setShowWatermark] = useState(false)

    const [companyImg, setCompanyImg] = useState('')
    const [companyName, setCompanyName] = useState('N/A')
    const [projectName, setProjectName] = useState('N/A')
    const [projectArea, setProjectArea] = useState('N/A')
    const [projectID, setProjectID] = useState('N/A')
    const [projectAddress, setProjectAddress] = useState('N/A')
    const [dateFormat, setDateFormat] = useState('DD/MM/YYYY')

    const [id, setId] = useState('')
    const [name, setName] = useState('')
    const [invoiceID, setInvoiceID] = useState('')
    const [status, setStatus] = useState('')
    const [introduction_text, setIntroduction_text] = useState('')
    const [comments, setComments] = useState('')

    const [Phase_id, setPhase_id] = useState('')
    const [Phase_name, setPhase_name] = useState('')
    const [Client_id, setClient_id] = useState('')
    const [Client_name, setClient_name] = useState('')

    const [invoiceDate, setInvoiceDate] = useState('')
    const [dueDate, setDueDate] = useState('')

    const [invoiceSubTotal, setInvoiceSubTotal] = useState(0)
    const [invoicePretaxSubTotal, setInvoicePretaxSubTotal] = useState(0)
    const [invoiceTotal, setInvoiceTotal] = useState(0)
    const [invoiceTotalTax, setInvoiceTotalTax] = useState(0)
    const [invoiceTotalMarkup, setInvoiceTotalMarkup] = useState(0)
    const [invoicePaid, setInvoicePaid] = useState(0)
    const [invoiceDue, setInvoiceDue] = useState(0)

    const [termsConditionValue, setTermsConditionValue] = useState("")

    const [StatusOptions, setStatusOptions] = useState([
        {
            value: 0,
            label: "Pending",
            isDefault: false
        },
        {
            value: 1,
            label: "Approved",
            isDefault: false
        },
        {
            value: 2,
            label: "Rejected",
            isDefault: false
        },
    ])
    const [InvoiceItems, setInvoiceItems] = useState([
        {
            id: Utils.getUniqueId(),
            name: '',

            unitrate: 0,
            tax: 0,
            markup: 0,
            tax_amount: 0,
            markup_amount: 0,
            final_unitrate: 0,
            amount: 0,
            final_amount: 0,
            pre_tax_amount: 0,
            value_type: `$`,
            unit_id: '',
            unit_name: '',
            quantity: 0,
            description: '',
            notes: ''
        }
    ])

    const [paymentHistory, setPaymentHistory] = useState([
    ])

    const HandleDownloadBtn = () => {

        pdfRef.current.save();
        // savePDF(pdfRef.current, { paperSize: "A4" });
    }
    const HandleSaveBtn = () => {

        saveCallback()
    }

    const getNextClientInvoiceID = async () => {

        let response = await clientInvoiceHandler.getNextClientInvoiceIDHandler()

        if (response && response.success) {

            let { invoiceId } = response.data

            if (invoiceId) setInvoiceID(invoiceId)

        } else {
            setWarningAlert(true)
            setWarningAlertType('error')
            setApiFailedMessage(`Error in next invoice id`)
        }
    }


    const LoadProposal = async () => {


        let filter = {
            invoiceId: invoiceId,
            projectId: projectId,
        }

        let _invoicedetials = undefined

        if (invoiceLoaded && invoicedetials && Object.keys(invoicedetials).length) {

            _invoicedetials = invoicedetials

        } else if (invoiceId) {

            setIsLoading(true)

            let response = await clientInvoiceHandler.getClientInvoicesHandler(filter)
            setIsLoading(false)

            if (response.success) {
                let { data } = response

                if (!Array.isArray(data) || !data.length) navigate(`/builder/projects/${projectId}/client_invoice`)

                console.log(data, 'data');

                _invoicedetials = data[0]
            }

        }

        if (_invoicedetials && Object.keys(_invoicedetials).length) {

            console.log(_invoicedetials, _invoicedetials);


            let {
                id,
                name,
                invoiceId,
                phase_id,
                phase_name,
                client_id,
                client_name,
                projectId,
                invoice_date,
                due_date,
                status,
                invoice_total,
                invoice_paid,
                invoice_due,
                introduction_text,
                comments,
                terms_condition_id,
                terms_condition,
                cost_items,
                payment_history
            } = _invoicedetials


            setId(id)
            setInvoiceID(invoiceId)
            setName(name)
            setPhase_id(phase_id)
            setPhase_name(phase_name)
            setClient_id(client_id)
            setClient_name(client_name)

            if (status != undefined) {

                let _status = StatusOptions.filter(opt => opt.value == status)[0]
                if (_status) setStatus(_status.label)
            }

            setIntroduction_text(introduction_text)

            setTermsConditionValue(terms_condition)
            setComments(comments)

            setInvoiceDate(invoice_date)
            setDueDate(due_date)

            setInvoiceTotal(invoice_total)
            setInvoicePaid(invoice_paid)
            setInvoiceDue(invoice_due)

            setInvoiceItems(cost_items || [])
            setPaymentHistory(payment_history || [])

        }

    }

    useEffect(() => {

        if (menuType == 'create') {
            getNextClientInvoiceID()
        }
        else {
            LoadProposal()
        }

        LoadStoreData()

    }, [])

    useEffect(() => {

        if (store && store.user) {

            let { company, settings, selected_project } = store.user
            if (selected_project) {
                let { name, status, area, unit_id, unit_name, type_id, type_name, address, client_id, client_name } = selected_project



                setProjectName(name || 'N/A')
                setProjectArea(`${area}${unit_name || ""}` || 'N/A')
                setProjectID(projectId)
                setProjectAddress(address || "N/A")

            }

            if (company) {
                let { name, logo_img, currency_setting, date_format_setting, timezone, temperature_setting } = company

                const profileImgURL = logo_img ? `data:image/png;base64,${logo_img}` : ''

                setCompanyImg(profileImgURL)
                setCompanyName(name || 'N/A')
                setDateFormat(date_format_setting || 'DD/MM/YYYY')
                if (currency_setting) setCurrency_type(currency_setting)
            }


            if (settings && Array.isArray(settings.field_settings) && settings.field_settings.length) {

                let { project_statuses, project_types, units } = settings.field_settings[0]

                units = units.filter(opt => opt.isActive).map((opt) => {
                    return {
                        value: opt.id,
                        label: opt.name,
                        isDefault: opt.isDefault || false
                    }
                })

                setUnitsOptions(units || [])

            }

        }

    }, [store])


    useEffect(() => {

        let total_amounts = InvoiceItems.reduce((prev, val, i) => {

            return {
                pre_tax_amount: parseFloat(prev.pre_tax_amount || 0) + parseFloat(val.pre_tax_amount || 0),
                tax_amount: parseFloat(prev.tax_amount || 0) + parseFloat(val.tax_amount || 0),
                markup_amount: parseFloat(prev.markup_amount || 0) + parseFloat(val.markup_amount || 0),
                amount: parseFloat(prev.amount || 0) + parseFloat(val.amount || 0),
                final_amount: parseFloat(prev.final_amount || 0) + parseFloat(val.final_amount || 0),
            }

        }, {
            pre_tax_amount: 0,
            tax_amount: 0,
            markup_amount: 0,
            amount: 0,
            final_amount: 0

        })
        setInvoiceSubTotal(total_amounts.amount)
        setInvoicePretaxSubTotal(total_amounts.pre_tax_amount)
        setInvoiceTotal(total_amounts.final_amount)
        setInvoiceTotalTax(total_amounts.tax_amount)
        setInvoiceTotalMarkup(total_amounts.markup_amount)



        let paid_amount = paymentHistory.reduce((prev, val, i) => {

            return prev + parseFloat(val.amount || 0)
        }, 0)
        setInvoicePaid(paid_amount)

        let due_amount = total_amounts.final_amount - paid_amount
        due_amount = due_amount < 0 ? 0 : due_amount
        setInvoiceDue(due_amount)


    }, [InvoiceItems, paymentHistory])


    const HandleCancel = async (e) => {

        close()
    }


    return (
        <>
            <div className="popup-container-main popup-container-center">
                <div className="popup-block-ui"></div>
                <div className="side-popup-container preview-popup-container">

                    <div className="side-popup-header">
                        <div></div>
                        <div className="header-item-select">
                            <div className="header-item-select-content">
                                <div className="label">Web Preview</div>
                                <div className="description">This is what client will see when you send the invoice</div>
                            </div>
                        </div>
                        <div
                            className="header-item-close"
                            onClick={(e) => HandleCancel()}
                            dangerouslySetInnerHTML={{ __html: Icons.general.close }}
                        ></div>
                    </div>

                    <div className="sidebar-popup-content">
                        <div className="project-create_client_invoice-main" id='project-create_client_invoice-main'>

                            <div className="create_client_invoice-panel-content">
                                <PDFExport
                                    ref={pdfRef}
                                    author='CivilATOR'
                                    fileName={`${String(projectName).split(' ').join('_')}_Invoice_${Utils.getLocalFullDateBYFormat(null, '_', dateFormat)}`}
                                    margin={5}
                                    paperSize="A4"
                                    scale={0.6}
                                >
                                    <div className="panel-content-sections">
                                        <div className="create-proposal-content-main">

                                            <div className="create-proposal-sections">

                                                <div className="preview-header-section">
                                                    <div className="header-section-top">
                                                        <div className="company-detials">
                                                            <div className="logo">
                                                                <img src={companyImg || Images.User} />
                                                            </div>
                                                            <div className="title">{companyName}</div>
                                                        </div>
                                                        <div className="detials">
                                                            <div className="detials-item" >
                                                                <div className="label">Status</div>
                                                                <div className="value">{status}</div>
                                                            </div>
                                                            <div className="detials-item">
                                                                <div className="label">Project ID</div>
                                                                <div className="value">{projectId}</div>
                                                            </div>
                                                            <div className="detials-item">
                                                                <div className="label">Invoice ID</div>
                                                                <div className="value">{invoiceId}</div>
                                                            </div>
                                                            <div className="detials-item">
                                                                <div className="label">Total Invoice Amount</div>
                                                                <div className="value value-big">
                                                                    {currency_type == '₹' ?
                                                                        <span
                                                                            className='proposal-currency-mid'
                                                                            dangerouslySetInnerHTML={{ __html: Icons.general.inr }}
                                                                        ></span>
                                                                        : currency_type}

                                                                    {invoiceTotal}</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="header-section-detials">
                                                        <div className="title">Invoice Information</div>
                                                        <div className="detials">
                                                            <div className="detials-item">
                                                                <div className="label">Invoice Name</div>
                                                                <div className="value">{name}</div>
                                                            </div>
                                                            <div className="detials-item">
                                                                <div className="label">Invoice Date</div>
                                                                <div className="value">{invoiceDate}</div>
                                                            </div>

                                                            <div className="detials-item">
                                                                <div className="label">Due Date</div>
                                                                <div className="value">{dueDate}</div>
                                                            </div>
                                                            <div className="detials-item">
                                                                <div className="label">Project Phase</div>
                                                                <div className="value">{Phase_name}</div>
                                                            </div>

                                                            <div className="detials-item">
                                                                <div className="label">Phase Client</div>
                                                                <div className="value">{Client_name}</div>
                                                            </div>
                                                            <div className="detials-item">
                                                                <div className="label">Project Name</div>
                                                                <div className="value">{projectName}</div>
                                                            </div>
                                                            <div className="detials-item">
                                                                <div className="label">Project Address</div>
                                                                <div className="value">{projectAddress}</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                {introduction_text ?
                                                    <div
                                                        className="create-proposal-section"
                                                        id={`create-proposal-introduction_text`}
                                                        dangerouslySetInnerHTML={{ __html: introduction_text }}
                                                    >
                                                    </div>
                                                    : ""}

                                                {InvoiceItems.length ?
                                                    <div
                                                        className="create-proposal-section"
                                                        id={`create-client_invoice_payment_history`}
                                                    >
                                                        <div className={`createproposal-description-main`}>

                                                            <div className="head">
                                                                <div className="title">
                                                                    <div className="label">Payment Breakdown</div>
                                                                </div>
                                                            </div>


                                                            <div className="preview-costItems-table-main">
                                                                <div className="preview-costItems-table-header">
                                                                    <div className="header-item table-item-description">ITEM DETAILS</div>
                                                                    <div className="header-item table-item-amount">AMOUNT</div>
                                                                </div>
                                                                <div className="preview-costItems-table-content">

                                                                    {InvoiceItems?.map((item, i) => (
                                                                        <div
                                                                            className="content-row"
                                                                            key={`preview-costItems-table-item-${item.id}`}
                                                                        >
                                                                            <div className="content-row-item table-item-description">
                                                                                {item.name} <br />{item.description ? `${item.description}` : ''}
                                                                            </div>

                                                                            <div className="content-row-item table-item-amount">{currency_type == '₹' ?
                                                                                <span
                                                                                    className='proposal-currency-small'
                                                                                    dangerouslySetInnerHTML={{ __html: Icons.general.inr }}
                                                                                ></span>
                                                                                : currency_type}{item.pre_tax_amount}</div>
                                                                        </div>
                                                                    ))}

                                                                </div>

                                                            </div>



                                                        </div>
                                                    </div>
                                                    : ''}


                                                <div className="create-proposal-section" id={`create-proposal-summary`}>
                                                    <div className="createproject-section proposal-left-section">
                                                        <div className="proposal-summary-totals proposal-summary-title">
                                                            <div className="title">Summary</div>
                                                        </div>
                                                        <div className="proposal-summary-totals proposal-summary-items">

                                                            <div className="proposal-summary-item">
                                                                <div className="proposal-summary-item-content">

                                                                    <div className="item" style={{ fontSize: '20px' }} >
                                                                        <div className="title">Total Invoice Amount</div>
                                                                        <div className="quantity"></div>
                                                                        <div className="amount">
                                                                            {currency_type == '₹' ?
                                                                                <span
                                                                                    className='proposal-currency-small'
                                                                                    dangerouslySetInnerHTML={{ __html: Icons.general.inr }}
                                                                                ></span>
                                                                                : currency_type} {parseFloat(invoiceTotal || 0).toFixed(2)}
                                                                        </div>
                                                                    </div>


                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                {termsConditionValue ?
                                                    <div
                                                        className="create-proposal-section"
                                                        style={{ padding: '2rem' }}
                                                        id={`create-proposal-terms_conditions`}>

                                                        <div className={`createproposal-description-main`}>
                                                            <div className="head">
                                                                <div className="label">Terms and conditions</div>
                                                            </div>
                                                            <div className="terms-condition-result" dangerouslySetInnerHTML={{ __html: termsConditionValue }}></div>
                                                        </div>

                                                    </div>
                                                    : ''}

                                                {/* {paymentHistory.length ?
                                                    <div
                                                        className="create-client_invoicesection"
                                                        id={`create-client_invoice_payment_history`}
                                                        style={{ padding: '2rem' }}
                                                    >
                                                        <div className={`createproposal-description-main`}>

                                                            <div className="head">
                                                                <div className="title">
                                                                    <div className="label">Payments History ({paymentHistory.length})</div>
                                                                </div>
                                                            </div>


                                                            <div className="paymenthistory-table-main">
                                                                <div className="paymenthistory-table-header">
                                                                    <div className="header-item table-item-sno">S:No</div>
                                                                    <div className="header-item table-item-date">Date</div>
                                                                    <div className="header-item table-item-amount">Amount</div>
                                                                    <div className="header-item table-item-payment_method">Payment Method</div>
                                                                    <div className="header-item table-item-notes">Notes</div>
                                                                    <div className="header-item table-item-status">Status</div>
                                                                </div>
                                                                <div className="paymenthistory-table-content">

                                                                    {paymentHistory?.map((item, i) => (
                                                                        <div
                                                                            className="content-row"
                                                                            key={`paymenthistory-table-item-${item.id}`}
                                                                        >
                                                                            <div className="content-row-item table-item-sno">{i + 1}</div>
                                                                            <div className="content-row-item table-item-date">{item.date}</div>
                                                                            <div className="content-row-item table-item-amount">{currency_type == '₹' ?
                                                                                <span
                                                                                    className='proposal-currency-small'
                                                                                    dangerouslySetInnerHTML={{ __html: Icons.general.inr }}
                                                                                ></span>
                                                                                : currency_type} {item.amount}</div>
                                                                            <div className="content-row-item table-item-payment_method">{item.payment_method}</div>
                                                                            <div className="content-row-item table-item-notes">{item.notes}</div>
                                                                            <div className="content-row-item table-item-status">{item.status}</div>
                                                                        </div>
                                                                    ))}

                                                                </div>

                                                            </div>



                                                        </div>
                                                    </div>
                                                    : ''} */}

                                                {comments ?
                                                    <div className="create-proposal-section" id={`create-proposal-comments`}>

                                                        <div className={`createproposal-description-main`}>
                                                            <div className="head">
                                                                <div className="label">Comments</div>
                                                            </div>
                                                            <div className="terms-condition-result" dangerouslySetInnerHTML={{ __html: comments }}></div>
                                                        </div>

                                                    </div>
                                                    : ''}
                                            </div>
                                        </div>
                                        {ShowWatermark ?
                                            <div className="proposal-product-watermark">
                                                <div className="watermark">
                                                    <div className="label">Powered by</div>
                                                    <img src={Images.logo} className='img' />
                                                </div>
                                            </div>
                                            : ''}
                                    </div>
                                </PDFExport>
                            </div>
                        </div>
                    </div>
                    <div className="sidebar-popup-footer">
                        <div className="footer-item action-items">
                            <div className="action-preview">
                            </div>
                            <div className='action-btns'>
                                <div
                                    className="action-btn button-download"
                                    onClick={(e) => HandleDownloadBtn(e)}
                                >
                                    <div
                                        className="icon"
                                        dangerouslySetInnerHTML={{ __html: Icons.general.download }}
                                    ></div>
                                    <div className="label">Download</div>
                                </div>
                                <div
                                    className="action-btn button-download"
                                    onClick={(e) => HandleSaveBtn(e)}
                                >
                                    <div
                                        className="icon"
                                        dangerouslySetInnerHTML={{ __html: Icons.general.save }}
                                    ></div>
                                    <div className="label">Save</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )

}

export default PreviewClientInvoice;