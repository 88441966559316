

import React, { useEffect, useState, useRef, useCallback, useMemo, StrictMode } from 'react'
import { Route, Routes, BrowserRouter, Link, useLocation, Navigate, Outlet, useNavigate, useParams } from 'react-router-dom';
import store from '../../redux/store'
import StoreHandler from '../../redux/StoreHandler'
import { useSelector, useDispatch } from 'react-redux'
import UserAction from '../../redux/action/userAction'

import ReverseProxy from '../../config/reverseProxy'
import SettingsHandler from '../../Handlers/Settings/Settings';
import EstimateHandler from '../../Handlers/Estimate/Estimate';
import PurchaseOrderHandler from '../../Handlers/PurchaseOrder/PurchaseOrder';
import CommentsHandler from '../../Handlers/Comments/Comments';

import Icons from '../../assets/Icons'
import Images from '../../assets/Images'
import { CostInput, TextInput, SelectInput, DateTimeInput, TextArea, RadioInput, MultiSelectInput } from '../../components/Inputs'
import Utils from '../../utils'
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

import AlertPopup from '../../components/AlertPopup'
import SystemToastPopup from '../../components/SystemToastPopup'
import Loading from '../../components/Loading'

import PreviewPurchaseOrder from './previewPurchaseOrder'
import BuilderAttachments from '../../Handlers/BuilderAttachments/BuilderAttachments';

import ImagePreviewPopup from '../../components/ImagePreviewPopup'

import { useAuth, useMenuAuth } from '../../hooks/useAuth'

const CreatePurchaseOrder = ({ props }) => {

    const { projectId, purchaseId } = useParams();

    const { menuType = 'create' } = props

    const [project_id, setProject_id] = useState('')
    const [project_name, setProject_name] = useState('')

    const [isLoading, setIsLoading] = useState(false)
    const [warningAlert, setWarningAlert] = useState(false)
    const [warningAlertType, setWarningAlertType] = useState('warning')
    const [apiFailedMessage, setApiFailedMessage] = useState('Error in Purchase Order')

    const navigate = useNavigate()
    const store = useSelector((store) => store)
    const dispatch = useDispatch()
    const { updateState } = new UserAction

    const location = useLocation()
    const queryParams = new URLSearchParams(location.search);
    const po_data = JSON.parse(queryParams.get('po_data') || '{}')

    const formSubmitBtnRef = useRef()

    const storeHandler = new StoreHandler({
        dataStack: ['sor_materials', 'terms_conditions', 'team_users', 'vendors', 'field_settings', `project-${projectId}`],
        store: store,
        dispatch: dispatch,
        updateState: updateState,
    })

    const LoadStoreData = async () => {
        setIsLoading(true)
        let resolved = await storeHandler.init()
        setIsLoading(false)
    }

    const { isAdmin, menu_permission } = useMenuAuth({ menu: 'purchase--purchase_orders', feature_key: 'purchase-orders' })


    const [selected_view_settings_value, setSelected_view_settings_value] = useState('')
    const [has_view_access, setHas_view_access] = useState(false)
    const [has_add_access, setHas_add_access] = useState(false)
    const [has_edit_access, setHas_edit_access] = useState(false)
    const [has_delete_access, setHas_delete_access] = useState(false)

    useEffect(() => {

        if (isAdmin) {

            setHas_view_access(true)
            setHas_add_access(true)
            setHas_edit_access(true)
            setHas_delete_access(true)
        }

        else if (menu_permission) {

            if (
                !menu_permission._view
                &&
                (!menu_permission.selected_view_settings_value
                    || menu_permission.selected_view_settings_value == 'no_access')
            ) {
                navigate(`${ReverseProxy['proxyUrl']}/quick-menu`, { state: { from: location }, replace: true })
            }

            if (menuType == 'create' && menu_permission._create == '0') navigate(`${ReverseProxy['proxyUrl']}/builder/projects`, { state: { from: location }, replace: true })
            if (menuType == 'edit' && menu_permission._edit == '0') navigate(`${ReverseProxy['proxyUrl']}/builder/projects`, { state: { from: location }, replace: true })

            setHas_view_access(menu_permission._view == '1')
            setHas_add_access(menu_permission._create == '1')
            setHas_edit_access(menu_permission._edit == '1')
            setHas_delete_access(menu_permission._delete == '1')

            setSelected_view_settings_value(menu_permission.selected_view_settings_value)

        }

    }, [store])

    const purchaseOrderHandler = new PurchaseOrderHandler()
    const commentsHandler = new CommentsHandler()
    const builderAttachments = new BuilderAttachments()

    const [materialsOptions, setMaterialsOptions] = useState([])
    const [materialsOptions_Orginal, setMaterialsOptions_Orginal] = useState([])

    const [unitsOptions, setUnitsOptions] = useState([])
    const [currency_type, setCurrency_type] = useState('₹')

    const [id, setId] = useState(Utils.getUniqueId())
    const [name, setName] = useState('')
    const [purchaseID, setPurchaseID] = useState('')
    const [status, setStatus] = useState('')

    const [vendor_id, setVendorId] = useState('')
    const [vendor_name, setVendorName] = useState('')

    const [purchaseOrderDate, setPurchaseOrderDate] = useState(Utils._getLocalDate())

    const [approvalDeadlineDate, setApprovalDeadlineDate] = useState('')
    const [deliveryDeadlineDate, setDeliveryDeadlineDate] = useState('')

    const [AssigneeOptions_Orginal, setAssigneeOptions_Orginal] = useState([])
    const [AssigneeOptions, setAssigneeOptions] = useState([])
    const [SelectedAssignees_Res, setSelectedAssignees_Res] = useState([])
    const [SelectedAssignees, setSelectedAssignees] = useState([])

    const HandleSelectAssignee = (users) => {

        users = users.map(p => {
            let users = AssigneeOptions_Orginal.filter(ph => ph.id == p.id)[0]
            if (!users) return p

            return users
        })

        setSelectedAssignees(users)
    }

    const [GRNTotalAmount, setGRNTotalAmount] = useState(0)
    const [GRNPaidAmount, setGRNPaidAmount] = useState(0)
    const [GRNDueAmount, setGRNDueAmount] = useState(0)

    const [introduction_text, setIntroduction_text] = useState('')
    const [comments, setComments] = useState('')

    const [vendorOptions, setVendorOptions] = useState([])

    const [statusOptions, setStatusOptions] = useState([
        {
            value: 0,
            label: "Pending",
            isDefault: false
        },
        {
            value: 1,
            label: "Approved",
            isDefault: false
        },
        {
            value: 2,
            label: "Rejected",
            isDefault: false
        },
    ])

    const [termsConditionOptions, setTermsConditionOptions] = useState([])
    const [termsConditionId, setTermsConditionId] = useState("")
    const [termsConditionValue, setTermsConditionValue] = useState("")

    const HandleTermsConditions = (value, label) => {

        setTermsConditionId(value)

        let selected_TC = termsConditionOptions.filter(tc => tc.value == value)[0]
        if (selected_TC) setTermsConditionValue(selected_TC.body)
    }

    const [purchaseOrderLoaded, setPurchaseOrderLoaded] = useState(false)

    const [materialItems, setMaterialItems] = useState([
        {
            id: Utils.getUniqueId(),
            material_id: "",
            material_name: "",
            unitrate: 0,
            tax: 0,
            markup: 0,
            tax_amount: 0,
            markup_amount: 0,
            final_unitrate: 0,
            amount: 0,
            final_amount: 0,
            pre_tax_amount: 0,
            value_type: `$`,
            unit_id: '',
            unit_name: '',
            quantity: 0,
            description: '',
            notes: ''
        }
    ])

    const HandleMaterialOptionsSelected = () => {

        let material_items = [...materialItems].map(c => c.material_id)

        let _materialsOptions = [...materialsOptions]

        _materialsOptions = _materialsOptions.map(option => {

            if (material_items.includes(option.value)) option.disabled = true
            else option.disabled = false
            return option
        })

        setMaterialsOptions(_materialsOptions)

    }

    const HandlePaymentsInputChange = (value, index, key, label) => {

        let materialItems_ = [...materialItems]

        if (key == 'material') {

            materialItems_[index]['material_id'] = value
            materialItems_[index]['material_name'] = label

            HandleMaterialOptionsSelected()

            let original_item = materialsOptions_Orginal.filter(m => m.id == value)[0]

            if (original_item) {

                materialItems_[index]['unit_id'] = original_item.unit_id
                materialItems_[index]['unit_name'] = original_item.unit_name
                materialItems_[index]['unitrate'] = parseFloat(original_item.price || 0)
            }
        }

        else materialItems_[index][key] = value

        let unit_quandity_amount = parseFloat(materialItems_[index]['unitrate'] || 0) * parseFloat(materialItems_[index]['quantity'] || 0)
        let final_unitrate = parseFloat(materialItems_[index]['unitrate'] || 0) * parseFloat(materialItems_[index]['markup'] || 0) / 100

        materialItems_[index]['amount'] = parseFloat(materialItems_[index]['unitrate'] || 0) * parseFloat(materialItems_[index]['quantity'] || 0)

        materialItems_[index]['final_unitrate'] = parseFloat(materialItems_[index]['unitrate'] || 0) + final_unitrate

        materialItems_[index]['markup_amount'] = unit_quandity_amount * parseFloat(materialItems_[index]['markup'] || 0) / 100
        materialItems_[index]['tax_amount'] = (unit_quandity_amount + materialItems_[index]['markup_amount']) * parseFloat(materialItems_[index]['tax'] || 0) / 100

        materialItems_[index]['final_amount'] = parseFloat(materialItems_[index]['tax_amount'] || 0) + parseFloat(materialItems_[index]['amount'] || 0) + parseFloat(materialItems_[index]['markup_amount'] || 0)
        materialItems_[index]['pre_tax_amount'] = parseFloat(materialItems_[index]['amount'] || 0) + parseFloat(materialItems_[index]['markup_amount'] || 0)


        setMaterialItems(materialItems_)
    }
    const HandlePaymentsRemove = (index) => {
        let materialItems_ = [...materialItems]
        if (index == 0) {

            materialItems_[index] = {
                id: Utils.getUniqueId(),
                name: "",
                value: 0,
                amount: 0
            }
            setMaterialItems(materialItems_)
            return
        }
        materialItems_.splice(index, 1)
        setMaterialItems(materialItems_)
    }
    const HandlePaymentsAddNew = () => {

        let materialItems_ = [...materialItems]
        materialItems_.push({
            id: Utils.getUniqueId(),
            material_id: "",
            material_name: "",
            unitrate: 0,
            tax: 0,
            markup: 0,
            tax_amount: 0,
            markup_amount: 0,
            final_unitrate: 0,
            amount: 0,
            final_amount: 0,
            pre_tax_amount: 0,
            value_type: `$`,
            unit_id: '',
            unit_name: '',
            quantity: 0,
            description: '',
            notes: ''
        })
        setMaterialItems(materialItems_)
    }

    const [showCreatePreview, setShowCreatePreview] = useState(false)

    const HandlepreviewClientInvoice = (e) => {

        if (menuType == 'create') setShowCreatePreview(true)
        else navigate(`/builder/projects/${projectId}/purchase_order/${purchaseId}/edit/preview`)

    }
    const HandleCancel = (e) => {

        navigate(`/builder/projects/${projectId}/purchase_order`)
    }

    const HandleSubmit = async (e) => {
        e.preventDefault()

        let assignees = SelectedAssignees?.map(p => p.value)

        let update_detials = {
            id: id,
            name: name,
            purchaseId: purchaseID,
            projectId: projectId,

            vendor_id: String(vendor_id),
            vendor_name: String(vendor_name),

            purchase_date: purchaseOrderDate,
            approval_deadline_date: approvalDeadlineDate,
            delivery_deadline_date: deliveryDeadlineDate,
            status: String(status),

            introduction_text: introduction_text || "",
            comments: comments || "",

            terms_condition_id: termsConditionId || "",
            terms_condition: termsConditionValue || "",

            cost_items: materialItems || [],
            attachments: AttachmentsFiles || [],
            assignees: assignees || [],

            delivery_history: [] // DeliverieHistories

        }


        let response = { success: false }

        if (menuType == 'edit') {

            update_detials["id"] = id
            response = await purchaseOrderHandler.updatePurchaseOrderHandler(update_detials)

        } else {
            response = await purchaseOrderHandler.createPurchaseOrderHandler(update_detials)


            let update_data = {
                project_id: projectId,
                parent_id: purchaseID,
                parent_name: name || `PO ${purchaseID}`,
                parent_type: String(0),
                comments: CommentsItems
            }

            let comments_response = await commentsHandler.createMultiCommentHandler(update_data)

            if (!comments_response.success) {
                setWarningAlert(true)
                setWarningAlertType('error')
                setApiFailedMessage(`Error in Comments, Please try again!`)

            }
        }

        if (response.success) {
            setIsLoading(false)
            navigate(`/builder/projects/${projectId}/purchase_order`)
        }
        else {
            setIsLoading(false)
            setWarningAlert(true)
            setWarningAlertType('error')
            setApiFailedMessage(`Error in Purchase Order, Please try again!`)
        }
    }

    const getNextPurchaseOrderID = async () => {

        let response = await purchaseOrderHandler.getNextPurchaseOrderIDHandler()

        if (response && response.success) {

            let { purchaseId } = response.data

            if (purchaseId) setPurchaseID(purchaseId)

        } else {
            setWarningAlert(true)
            setWarningAlertType('error')
            setApiFailedMessage(`Error in Purchase Order, Please try again!`)
        }
    }


    const LoadClientInvoice = async () => {

        let filter = {
            purchaseId: purchaseId,
            projectId: projectId,
        }

        setIsLoading(true)

        let response = await purchaseOrderHandler.getPurchaseOrdersHandler(filter)
        setIsLoading(false)

        if (response.success) {
            let { data } = response

            if (!Array.isArray(data) || !data.length) {
                return navigate(`/builder/projects/${projectId}/purchase_order`)
            }


            let {
                id: ID,
                name,
                purchaseID,
                projectId: projectID,
                purchase_date,
                approval_deadline_date,
                delivery_deadline_date,
                vendor_id,
                vendor_name,
                status,

                grn_total_amount,
                grn_paid_amount,
                grn_due_amount,

                introduction_text,
                comments,
                terms_condition_id,
                terms_condition,
                cost_items,
                attachments,
                assignees,
                delivery_history
            } = data[0]

            setId(ID)
            setPurchaseID(purchaseID)
            setName(name)
            setStatus(status)
            setIntroduction_text(introduction_text)

            setTermsConditionId(terms_condition_id)
            setTermsConditionValue(terms_condition)
            setComments(comments)

            setPurchaseOrderDate(purchase_date)
            setApprovalDeadlineDate(approval_deadline_date)
            setDeliveryDeadlineDate(delivery_deadline_date)

            setVendorId(vendor_id)
            setVendorName(vendor_name)

            setGRNTotalAmount(grn_total_amount || 0)
            setGRNPaidAmount(grn_paid_amount || 0)
            setGRNDueAmount(grn_due_amount || 0)

            setMaterialItems(cost_items || [])
            setAttachmentsFiles(attachments || [])
            setSelectedAssignees_Res(assignees || [])
            setPurchaseOrderLoaded(true)

        }
    }

    useEffect(() => {

        if (menuType == 'create') {
            getNextPurchaseOrderID()
        }
        else {
            LoadClientInvoice()

            LoadComments({ project_id: projectId, parent_id: purchaseId })
        }


        if (po_data && po_data.id && po_data.vendor_id) {

            let {
                id,
                name,
                ven_id,
                delivery_deadline_date,
                description,
                terms_condition_id,
                terms_condition,
                attachments,
                vendor_id,
                vendor_name,
                vendor_email,
                cost_items
            } = po_data

            let _materialItems = cost_items.map((item, index) => {

                return {
                    id: Utils.getUniqueId(),
                    material_id: item.material_id,
                    material_name: item.material_name,
                    unitrate: parseFloat(item.unitrate || 0),
                    tax: 0,
                    markup: 0,
                    tax_amount: 0,
                    markup_amount: 0,
                    final_unitrate: parseFloat(item.unitrate || 0),
                    amount: parseFloat(item.amount || 0),
                    final_amount: parseFloat(item.amount || 0),
                    pre_tax_amount: 0,
                    value_type: `$`,
                    unit_id: item.unit_id || '',
                    unit_name: item.unit_name || '',
                    quantity: parseFloat(item.quantity || 0),
                    description: item.description || '',
                    notes: ''
                }
            })

            setName(name || "")
            setVendorId(vendor_id || "")
            setVendorName(vendor_name || "")
            setMaterialItems(_materialItems || [])

            setDeliveryDeadlineDate(delivery_deadline_date || "")
            setApprovalDeadlineDate(delivery_deadline_date || "")
            setTermsConditionId(terms_condition_id || "")
            setTermsConditionValue(terms_condition || "")

            setIntroduction_text(description || "")

        }

        LoadStoreData()
    }, [])

    useEffect(() => {

        if (AssigneeOptions.length && Array.isArray(SelectedAssignees_Res) && SelectedAssignees_Res.length) {

            let select_assignee = SelectedAssignees_Res.map(p => {
                let assignee = AssigneeOptions.filter(ph => ph.value == p)[0]

                if (!assignee) return p
                return assignee
            })

            setSelectedAssignees(select_assignee)

        }

    }, [AssigneeOptions, SelectedAssignees_Res])



    useEffect(() => {

        if (store && store.user) {

            let { team_users, sor_materials, vendors, settings } = store.user


            if (Array.isArray(team_users)) {

                let _team_users = team_users.filter(opt => opt.status == '1').map((opt) => {
                    return {
                        value: opt.id,
                        label: opt.name,
                    }
                })

                setAssigneeOptions(_team_users)
                setAssigneeOptions_Orginal(team_users)
            }
            if (Array.isArray(sor_materials)) {

                let _sor_materials = sor_materials.map((opt) => {
                    return {
                        value: opt.id,
                        label: opt.name,
                    }
                })
                setMaterialsOptions(_sor_materials)
                setMaterialsOptions_Orginal(sor_materials)
            }
            if (settings && Array.isArray(settings.terms_conditions) && settings.terms_conditions.length) {



                let terms_conditions = settings.terms_conditions.map(tc => {
                    return {
                        id: tc.id,
                        value: tc.id,
                        label: tc.name,
                        body: tc.body,
                        isDefault: false,
                        document: tc.document || []
                    }
                })

                setTermsConditionOptions([
                    {
                        id: "none",
                        value: "none",
                        label: "None",
                        body: "",
                        isDefault: false,
                        document: []
                    },
                    ...terms_conditions
                ])
            }

            if (settings && Array.isArray(settings.field_settings) && settings.field_settings.length) {

                let { project_statuses, project_types, units } = settings.field_settings[0]

                units = units.filter(opt => opt.isActive).map((opt) => {
                    return {
                        value: opt.id,
                        label: opt.name,
                        isDefault: opt.isDefault || false
                    }
                })

                setUnitsOptions(units || [])

            }
            if (vendors && Array.isArray(vendors) && vendors.length) {

                let vendor_options = vendors.filter(opt => opt.status == '1').map((opt) => {
                    return {
                        value: opt.vendorID,
                        label: opt.name,
                        gstin_no: opt.gstin_no,
                        tin_no: opt.tin_no,
                        isDefault: false
                    }
                })

                setVendorOptions(vendor_options || [])

            }

        }

    }, [store])


    useEffect(() => {


    }, [po_data])

    const [ShowSelectedPreviewImage, setShowSelectedPreviewImage] = useState(false)
    const [SelectedPreviewImage, setSelectedPreviewImage] = useState(undefined)
    const AttachmentsInputRef = useRef()
    const [AttachmentsFiles, setAttachmentsFiles] = useState([])

    const HandleTriggerAddAttachment = (e) => {
        if (AttachmentsInputRef.current) AttachmentsInputRef.current.click()
    }
    const HandleAddAttachment = async (e) => {

        let file = e.target.files[0]

        if (!file) return

        const formData = new FormData();

        formData.append('projectId', projectId);
        formData.append('attachment', file);
        formData.append('linked_type', '3');
        formData.append('linked_item_id', id);

        setIsLoading(true)

        let upload_response = await builderAttachments.createAttachmentHandler(formData)

        setIsLoading(false)

        if (upload_response.success) {

            let { public_id, url } = upload_response.data

            if (!public_id || !url) return

            let _AttachmentsFiles = [...AttachmentsFiles]

            _AttachmentsFiles.push({
                public_id: public_id,
                original_filename: file.name,
                bytes: file.size,
                format: file.type,
                file_url: url,
                linked_type: '3',
                linked_item_id: id,
            })

            setAttachmentsFiles(_AttachmentsFiles)

        } else {
            setWarningAlert(true)
            setWarningAlertType('error')
            setApiFailedMessage(`Error in Attachment, Please try again!`)
        }
    }
    const HandleDeleteAttachment = async (idx) => {

        let _AttachmentsFiles = [...AttachmentsFiles]

        setIsLoading(true)

        let upload_response = await builderAttachments.deleteAttachmentHandler({
            public_id: _AttachmentsFiles[idx].public_id,
            projectId: projectId,
        })

        setIsLoading(false)

        if (upload_response.success) {
            _AttachmentsFiles.splice(idx, 1)
            setAttachmentsFiles(_AttachmentsFiles)
        }
        else {
            setWarningAlert(true)
            setWarningAlertType('error')
            setApiFailedMessage(`Error in Attachment, Please try again!`)
        }
    }



    const HandleSelectPreviewImage = (image) => {
        setShowSelectedPreviewImage(true)
        setSelectedPreviewImage({
            name: image.original_filename,
            file_url: image.file_url,
        })
    }
    const HandleHideSelectPreviewImage = (confirmation) => {
        setShowSelectedPreviewImage(confirmation)
        setSelectedPreviewImage(undefined)
    }
    const [CommentsItems, setCommentsItems] = useState([])

    const [CommentsInput, setCommentsInput] = useState("")

    const LoadComments = async ({ project_id, parent_id }) => {

        console.log(project_id, parent_id, 'parent_id');
        if (!project_id || !parent_id) return

        let filter = {
            project_id: project_id,
            parent_id: parent_id
        }

        setIsLoading(true)

        let response = await commentsHandler.getCommentsHandler(filter)
        setIsLoading(false)

        if (response.success) {
            let { data } = response

            if (!Array.isArray(data)) return

            console.log(data, 'data');

            setCommentsItems(data)
        }
    }

    const HandleAddComment = async () => {
        let _CommentsItems = [...CommentsItems]

        let userdetials = JSON.parse(localStorage.getItem('userdetials'))

        _CommentsItems.push({
            id: Utils.getUniqueId(),
            created_at: new Date().getTime(),
            created_by: userdetials.id,
            created_by_name: userdetials.name,
            message: CommentsInput,
            parent_id: purchaseID,
            parent_name: name || `PO ${purchaseID}`,
            parent_type: 0,
            project_id: projectId,
            company_id: userdetials.company_id,
        })

        setCommentsItems(_CommentsItems)
        setCommentsInput("")

        if (menuType == 'create') return

        let update_data = {
            project_id: projectId,
            parent_id: purchaseID,
            parent_name: name || `PO ${purchaseID}`,
            parent_type: String(0),
            message: CommentsInput
        }

        let response = await commentsHandler.createCommentHandler(update_data)

        if (!response.success) {
            setWarningAlert(true)
            setWarningAlertType('error')
            setApiFailedMessage(`Error in Comments, Please try again!`)
        }
    }

    const HandleDeleteComment = async (id, idx) => {
        let _CommentsItems = [...CommentsItems]

        _CommentsItems.splice(idx, 1)

        setCommentsItems(_CommentsItems)

        if (menuType == 'create') return

        let update_data = {
            project_id: projectId,
            ids: [id]
        }

        let response = await commentsHandler.deleteCommentHandler(update_data)

        if (!response.success) {
            setWarningAlert(true)
            setWarningAlertType('error')
            setApiFailedMessage(`Error in Comments, Please try again!`)
        }
    }

    return (
        <>


            {isLoading ?
                <Loading
                    props={{
                        isMainLogo: false,
                        isLabel: true
                    }} />
                : null}
            {warningAlert ?

                <SystemToastPopup
                    props={{
                        type: warningAlertType,
                        message: apiFailedMessage || "Error in Purchase Order, Please try again!",
                        callback: (confirmation) => setWarningAlert(false)
                    }} />

                : null}

            {ShowSelectedPreviewImage ?

                <ImagePreviewPopup
                    props={{
                        heading: `Preview - ${SelectedPreviewImage.name}`,
                        image: SelectedPreviewImage.file_url,
                        message: "Invalid Inputs, Please try again with vaild Inputs!",
                        callback: (confirmation) => HandleHideSelectPreviewImage(confirmation)
                    }} />

                : null}

            {showCreatePreview ?
                <PreviewPurchaseOrder
                    props={{
                        menuType: 'edit',
                        purchaseOrderLoaded: true,
                        purchaseOrderDetials: {
                            id: id,
                            name: name,
                            purchaseID: purchaseID,
                            projectId: projectId,

                            purchase_date: purchaseOrderDate,
                            approval_deadline_date: approvalDeadlineDate,
                            delivery_deadline_date: deliveryDeadlineDate,

                            vendor_id: vendor_id,
                            vendor_name: vendor_name,

                            status,
                            grn_total: GRNTotalAmount,
                            grn_paid: GRNPaidAmount,
                            grn_due: GRNDueAmount,

                            introduction_text: introduction_text || "",
                            comments: comments || "",

                            terms_condition_id: termsConditionId || "",
                            terms_condition: termsConditionValue || "",

                            cost_items: materialItems || [],
                        },
                        saveCallback: () => {
                            formSubmitBtnRef.current?.click()
                            setShowCreatePreview(false)
                        },
                        close: () => { setShowCreatePreview(false) }
                    }}
                />
                : null}

            <Routes>
                <Route path='/preview'
                    element={
                        <PreviewPurchaseOrder
                            props={{
                                menuType: 'edit',
                                purchaseOrderLoaded: purchaseOrderLoaded,
                                purchaseOrderDetials: {
                                    id: id,
                                    name: name,
                                    purchaseID: purchaseID,
                                    projectId: projectId,

                                    purchase_date: purchaseOrderDate,
                                    approval_deadline_date: approvalDeadlineDate,
                                    delivery_deadline_date: deliveryDeadlineDate,

                                    vendor_id: vendor_id,
                                    vendor_name: vendor_name,

                                    status,
                                    grn_total: GRNTotalAmount,
                                    grn_paid: GRNPaidAmount,
                                    grn_due: GRNDueAmount,

                                    introduction_text: introduction_text || "",
                                    comments: comments || "",

                                    terms_condition_id: termsConditionId || "",
                                    terms_condition: termsConditionValue || "",

                                    cost_items: materialItems || [],
                                },
                                saveCallback: (e) => {
                                    formSubmitBtnRef.current?.click()
                                    if (menuType == 'create') navigate(`/builder/projects/${projectId}/purchase_order/create`)
                                    else navigate(`/builder/projects/${projectId}/purchase_order/${purchaseId}/edit`)
                                },
                                close: () => {

                                    if (menuType == 'create') navigate(`/builder/projects/${projectId}/purchase_order/create`)
                                    else navigate(`/builder/projects/${projectId}/purchase_order/${purchaseId}/edit`)

                                }
                            }}
                        />}>
                </Route>

            </Routes>

            <div className="project-create_client_invoice-main" id='project-create_client_invoice-main'>
                <div className="create_client_invoice-panel-content">

                    <div className="panel-content-header">
                        <div className="left">
                            <div className="icon"
                                dangerouslySetInnerHTML={{ __html: Icons.general.back_arrow }}
                                onClick={(e) => HandleCancel(e)}
                            ></div>
                            <div className="title">{menuType == 'create' ? 'Create' : 'Edit'}  Purchase Order</div>
                        </div>
                        <div className="right">
                            {menuType == 'edit' ?
                                <div className="total-items">
                                    <div className={`total-item ${!GRNTotalAmount ? 'total-item-diable' : ''}`}>
                                        <div className="label">GRN Total</div>
                                        <div className="value">{currency_type}{parseFloat(GRNTotalAmount).toFixed(2)}</div>
                                    </div>
                                    <div className={`total-item ${!GRNPaidAmount ? 'total-item-diable' : ''}`}>
                                        <div className="label">GRN Paid</div>
                                        <div className="value">{currency_type}{parseFloat(GRNPaidAmount).toFixed(2)}</div>
                                    </div>
                                    <div className={`total-item ${!GRNDueAmount ? 'total-item-diable' : ''}`}>
                                        <div className="label">GRN Due</div>
                                        <div className="value">{currency_type}{parseFloat(GRNDueAmount).toFixed(2)}</div>
                                    </div>
                                </div>
                                : ''}
                            <div className="preview-button" onClick={() => HandlepreviewClientInvoice()}>
                                <div
                                    className="icon"
                                    dangerouslySetInnerHTML={{ __html: Icons.general.eye_open }}
                                ></div>
                                <div className="label">Preview</div>
                            </div>
                        </div>
                    </div>
                    <form className="panel-content-sections" onSubmit={HandleSubmit}>
                        <div className="create-client_invoicecontent-main">

                            <div className="create-client_invoicesections">

                                <div className="create-client_invoicesection">
                                    <div className="section-input-items">
                                        <div className="section-input-item">
                                            <TextInput
                                                props={{
                                                    id: "create-invoice-name",
                                                    value: name,
                                                    placeholder: '',
                                                    setValue: (value) => setName(value),
                                                    isIcon: false,
                                                    isLabel: true,
                                                    isRequired: true,
                                                    label: "Name",
                                                }}
                                            />
                                        </div>
                                        <div className="section-input-item">
                                            <TextInput
                                                props={{
                                                    id: "create-invoice-purchaseID",
                                                    value: purchaseID,
                                                    placeholder: '',
                                                    setValue: (value) => { },
                                                    isIcon: false,
                                                    isLabel: true,
                                                    isRequired: true,
                                                    readOnly: true,
                                                    label: "PO ID",
                                                }}
                                            />
                                        </div>
                                        <div className="section-input-item">
                                            <SelectInput
                                                props={{
                                                    id: "create-invoice-vendor",
                                                    value: vendor_id,
                                                    placeholder: '',
                                                    readOnly: false,
                                                    options: vendorOptions || [],
                                                    setValue: (value, label) => {
                                                        setVendorId(value)
                                                        setVendorName(label)
                                                    },
                                                    isStatus: false,
                                                    isIcon: false,
                                                    isLabel: true,
                                                    isRequired: true,
                                                    label: "Vendor",
                                                }}
                                            />
                                        </div>
                                        <div className="section-input-item">
                                            <SelectInput
                                                props={{
                                                    id: "create-invoice-status",
                                                    value: status,
                                                    placeholder: '',
                                                    readOnly: false,
                                                    options: statusOptions || [],
                                                    setValue: (value, label) => {
                                                        setStatus(value)
                                                    },
                                                    isStatus: false,
                                                    isIcon: false,
                                                    isLabel: true,
                                                    isRequired: true,
                                                    label: "Status",
                                                }}
                                            />
                                        </div>
                                    </div>
                                    <div className="section-input-items">
                                        <div className="section-input-item" style={{ height: 'max-content' }}>
                                            <MultiSelectInput
                                                props={{
                                                    id: 'assignee-multi-select-input',
                                                    placeholder: 'Select Assignees',
                                                    isRequired: true,
                                                    value: SelectedAssignees,
                                                    options: AssigneeOptions,
                                                    isIcon: false,
                                                    avoidDuplicate: true,
                                                    icon: '',
                                                    isLabel: true,
                                                    label: 'Assignees',
                                                    setValue: (value) => HandleSelectAssignee(value),
                                                }}
                                            />
                                        </div>
                                        <div className="section-input-item">
                                            <DateTimeInput
                                                props={{
                                                    id: "create-invoice-approvaldate",
                                                    value: approvalDeadlineDate,
                                                    placeholder: '',
                                                    setValue: (value) => {
                                                        setApprovalDeadlineDate(value)
                                                    },
                                                    isIcon: false,
                                                    isLabel: true,
                                                    icon: Icons.general.schedule,
                                                    isRequired: true,
                                                    label: "Approval Deadline",
                                                }}
                                            />
                                        </div>
                                        <div className="section-input-item">
                                            <DateTimeInput
                                                props={{
                                                    id: "create-invoice-deliverydate",
                                                    value: deliveryDeadlineDate,
                                                    placeholder: '',
                                                    setValue: (value) => {
                                                        setDeliveryDeadlineDate(value)
                                                    },
                                                    isIcon: false,
                                                    isLabel: true,
                                                    icon: Icons.general.schedule,
                                                    isRequired: true,
                                                    label: "Expected Delivery Date",
                                                }}
                                            />
                                        </div>

                                    </div>
                                </div>

                                <div className="create-client_invoicesection" id={`create-client_invoiceintroduction_text`}>

                                    <div className={`createproposal-description-main`}>
                                        <div className="head">
                                            <div className="title">
                                                <div className="icon" dangerouslySetInnerHTML={{ __html: Icons.general.description }}></div>
                                                <div className="label">Description</div>
                                            </div>
                                        </div>
                                        <div className="item-expanded">
                                            <ReactQuill
                                                theme="snow"
                                                value={introduction_text || ""}
                                                onChange={(value) => setIntroduction_text(value)}
                                            />
                                        </div>
                                    </div>

                                </div>

                                <div className="create-client_invoicesection" id={`create-client_invoicepayment_schedule`}>
                                    <div className={`createproposal-description-main`}>
                                        <div className="head">
                                            <div className="title">
                                                <div className="label">Materials</div>
                                                <div className="icon" dangerouslySetInnerHTML={{ __html: Icons.general.info }}></div>
                                            </div>
                                        </div>

                                        <div className="purchase_order-items-splits-main">
                                            <div className="purchase_order-splits-header">
                                                <div className="header-item-name">Item Details</div>
                                                <div className="header-item-quantity">Quantity</div>
                                                <div className="header-item-action">Action</div>
                                            </div>
                                            <div className="purchase_order-splits-content">
                                                {materialItems.map((item, index) => (
                                                    <div className="purchase_order-content-item">
                                                        <div className="purchase_order-content-items">
                                                            <div className="purchase_order-content-name">

                                                                <SelectInput
                                                                    props={{
                                                                        id: `purchase_order-material-${index}`,
                                                                        value: item.material_id,
                                                                        placeholder: 'Select material',
                                                                        options: materialsOptions || [],
                                                                        setValue: (value, label) => HandlePaymentsInputChange(value, index, 'material', label),
                                                                        isIcon: false,
                                                                        isLabel: false,
                                                                        isRequired: false,
                                                                        label: "Material",
                                                                    }}
                                                                />
                                                            </div>
                                                            <div className="purchase_order-content-quantity">
                                                                <TextInput
                                                                    props={{
                                                                        id: `purchase_order-quantity-${index}`,
                                                                        value: item.quantity,
                                                                        placeholder: 'Quantity',
                                                                        setValue: (value) => HandlePaymentsInputChange(value, index, 'quantity'),
                                                                        isIcon: false,
                                                                        isLabel: false,
                                                                        isRequired: true,
                                                                        type: 'number',
                                                                        label: "Quantity",
                                                                    }}
                                                                />
                                                                {item.unit_name}
                                                            </div>
                                                            <div className="purchase_order-content-action">
                                                                <div
                                                                    className="action-remove"
                                                                    onClick={(e) => HandlePaymentsRemove(index)}
                                                                >
                                                                    <div className="icon" dangerouslySetInnerHTML={{ __html: Icons.general.delete_btn }}></div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="purchase_order-content-items">
                                                            <div className="purchase_order-content-description">
                                                                <TextArea
                                                                    props={{
                                                                        id: `purchase_order-description-${index}`,
                                                                        value: item.description,
                                                                        placeholder: 'Description',
                                                                        setValue: (value) => HandlePaymentsInputChange(value, index, 'description'),
                                                                        isIcon: false,
                                                                        isLabel: false,
                                                                        isRequired: false,
                                                                        label: "Description",
                                                                    }}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                            <div
                                                className="purchase_order-splits-action-buttons"
                                            >
                                                <div
                                                    className="add-button"
                                                    onClick={(e) => HandlePaymentsAddNew()}
                                                >
                                                    <div
                                                        className="icon"
                                                        dangerouslySetInnerHTML={{ __html: Icons.general.add_btn }}
                                                    ></div>
                                                    <div className="label">Add new item</div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                                <div className="create-client_invoicesection" id={`create-proposal-attachments`}>
                                    <div className={`createproposal-description-main`}>
                                        <div className="head">
                                            <div className="title">
                                                <div className="icon" dangerouslySetInnerHTML={{ __html: Icons.general.attachments }}></div>
                                                <div className="label">Attachments</div>
                                            </div>
                                        </div>
                                        <div className="attachments-section-main">
                                            <div className="attachments-section-files">
                                                <div className="attachment-input" onClick={HandleTriggerAddAttachment}>
                                                    <input type="file" onChange={HandleAddAttachment} ref={AttachmentsInputRef} accept='.png,.jpg,.jpeg' />
                                                    <div className="attachment-add-icon"
                                                        dangerouslySetInnerHTML={{ __html: Icons.general.add_attachments_plus }}
                                                    ></div>
                                                    <div className="attachment-label">Upload</div>
                                                    <div className="attachment-limit">Max limit: 5MB</div>
                                                </div>
                                                {AttachmentsFiles?.map((file, idx) => (
                                                    <div
                                                        className="attachment-file"
                                                        key={file.public_id}
                                                        id={`file-${file.public_id}`}
                                                    >
                                                        <div
                                                            className="attachment-delete-btn"
                                                            dangerouslySetInnerHTML={{ __html: Icons.general.close }}
                                                            onClick={() => HandleDeleteAttachment(idx)}
                                                        ></div>
                                                        <div className="attachment-file-preview" onClick={() => HandleSelectPreviewImage(file)}>
                                                            <img src={file.file_url} alt="attachment-file" />
                                                        </div>
                                                        <div className="attachment-file-footer">
                                                            <div className="file-name">{file.original_filename}</div>
                                                            <div
                                                                className="file-download"
                                                                dangerouslySetInnerHTML={{ __html: Icons.general.download }}
                                                            ></div>
                                                        </div>

                                                    </div>
                                                ))}


                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="create-client_invoicesection" id={`create-purchase_order_terms_conditions`}>
                                    <div className={`createproposal-description-main`}>
                                        <div className="head">
                                            <div className="title">
                                                <div className="label">Terms and conditions</div>
                                                <div className="icon" dangerouslySetInnerHTML={{ __html: Icons.general.info }}></div>
                                            </div>
                                        </div>
                                        <div className="select-input">
                                            <div className="section-input-items">
                                                <div className="section-input-item">
                                                    <SelectInput
                                                        props={{
                                                            id: "create-client_invoiceterms_conditions-input",
                                                            value: termsConditionId,
                                                            placeholder: '',
                                                            readOnly: false,
                                                            options: termsConditionOptions || [],
                                                            setValue: (value, label) => HandleTermsConditions(value, label),
                                                            isStatus: false,
                                                            isIcon: false,
                                                            isLabel: false,
                                                            isRequired: false,
                                                            label: "",
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="terms-condition-result" dangerouslySetInnerHTML={{ __html: termsConditionValue }}></div>
                                    </div>
                                </div>
                                {menuType == 'edit' ?
                                    <div className="create-proposal-section">

                                        <div className={`createproposal-description-main`}>
                                            <div className="head">
                                                <div className="label">Comments</div>
                                            </div>
                                            <div className="comments-section-main">
                                                <div className="comments-section-items">

                                                    {CommentsItems.map((item, idx) => (

                                                        <div
                                                            className="comments-item-main"
                                                            key={`comments-item-${item.id}`}
                                                            id={`comments-item-${item.id}`}
                                                        >
                                                            <div className="comments-item-usericon">
                                                                {item.created_by_name.charAt(0)}
                                                            </div>
                                                            <div className="comments-item-detials">
                                                                <div className="detials-head">
                                                                    <div className="left">
                                                                        <div className="name">{item.created_by_name}</div>
                                                                        <div className="time">{Utils.getLocalFullDateLabel(item.created_at)}</div>
                                                                    </div>
                                                                    <div className="right">
                                                                        <div
                                                                            className="delete-icon"
                                                                            onClick={() => HandleDeleteComment(item.id, idx)}
                                                                            dangerouslySetInnerHTML={{ __html: Icons.general.delete_btn }}></div>
                                                                    </div>
                                                                </div>
                                                                <div className="detials-message">{item.message}</div>
                                                            </div>
                                                        </div>


                                                    ))}


                                                    <div className="comments-input-main">
                                                        <div className="comments-input-usericon">
                                                            RT
                                                        </div>
                                                        <div className="comments-input comments-input-active">
                                                            <div className="comments-input-textarea">
                                                                <textarea
                                                                    placeholder='Add a comment...'
                                                                    value={CommentsInput}
                                                                    onChange={(e) => setCommentsInput(e.target.value)}
                                                                ></textarea>
                                                            </div>
                                                            <div className={`comments-input-controls ${CommentsInput.length ? `comments-input-controls-active` : ''}`}>
                                                                <div
                                                                    className={`comments-input-control-sendmsg`}
                                                                    onClick={() => HandleAddComment()}
                                                                >
                                                                    <div className="icon" dangerouslySetInnerHTML={{ __html: Icons.general.send_message }}></div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                    : ''}
                            </div>
                            <div className="create-client_invoicefooter">
                                <div className="footer-item action-items">

                                    <div className="action-cancel" onClick={(e) => HandleCancel(e)}>
                                        Cancel
                                    </div>
                                    <button
                                        className={`action-btn`}
                                        type='submit'
                                        ref={formSubmitBtnRef}
                                    >

                                        <div className="label">Save</div>
                                    </button>
                                </div>
                            </div>

                        </div>
                    </form>
                </div>

            </div>
        </>
    )

}

export default CreatePurchaseOrder;