import React, { useEffect, useState, useRef } from 'react'
import { Route, Routes, BrowserRouter, Link, useLocation, Navigate, Outlet, useNavigate, useParams } from 'react-router-dom';
import Utils from '../utils'

import Images from '../assets/Images'
import Icons from '../assets/Icons'


const SideFilters = ({ props }) => {

    const { heading, filters, close, updateCallback } = props

    const [Filters, setFilters] = useState([])

    const HandleCancel = (e) => {
        close()
    }

    const HandleReset = (e) => {

        let _filters = [...Filters]

        _filters = _filters.map(filter => {

            if (filter.type == 'checkbox') {
                filter.value = false
            }
            else if (filter.type == 'multiselect') {

                filter.selectoptions = filter.selectoptions.map(option => {

                    option.value = false
                    return option
                })

            }

            return filter
        })



        setFilters(_filters)
    }

    const HandleFilterSubmit = (e) => {

        if (typeof updateCallback == 'function') updateCallback(Filters)
    }

    const HandleFilterEvent = (type, id, value, key, parent_id) => {

        if (!id || value == null) return

        if (type == 'checkbox') {

            let _filters = [...Filters]

            let filter_idx = _filters.findIndex(f => f.id == id)

            if (filter_idx == -1) return

            _filters[filter_idx].value = value

            setFilters(_filters)

        }
        else if (type == 'multiselect' && parent_id) {

            let _filters = [...Filters]

            let filter_idx = _filters.findIndex(f => f.id == parent_id)

            if (filter_idx == -1) return

            let filter_option_idx = _filters[filter_idx].selectoptions.findIndex(f => f.id == id)

            if (filter_option_idx == -1) return

            if (_filters[filter_idx].selectoptions[filter_option_idx].key == 'all') {

                _filters[filter_idx].selectoptions = _filters[filter_idx].selectoptions.map(o => {
                    o.value = value
                    return o
                })
            }
            else {
                _filters[filter_idx].selectoptions[filter_option_idx].value = value

                _filters[filter_idx].selectoptions.forEach((o, i) => {
                    if (o.key == 'all' && o.value) _filters[filter_idx].selectoptions[i].value = false
                })
            }

            setFilters(_filters)

        }


    }

    const Section_Header = () => {

        return (
            <div className="sidefilter-popup-header">
                <div className="header-item-select">
                    <div className="header-item-select-content">
                        <div className="label">{heading}</div>
                    </div>
                </div>
                <div
                    className="header-item-close"
                    onClick={(e) => HandleCancel(e)}
                    dangerouslySetInnerHTML={{ __html: Icons.general.close }}
                ></div>
            </div>
        );
    };
    const Section_Footer = () => {


        return (
            <div className="sidefilter-popup-footer">
                <div className="footer-items">

                    <div
                        className={`action-btn action-filter-btn`}
                        onClick={(e) => HandleFilterSubmit(e)}
                    >
                        <div className="label">FILTER</div>
                    </div>

                    <div className="action-btn action-reset-btn" onClick={(e) => HandleReset(e)}>
                        RESET
                    </div>
                </div>
            </div>
        );
    };

    useEffect(() => {

        if (Array.isArray(filters)) {

            let _filters = [...filters]
            setFilters(_filters)
        }
    }, [])

    return (
        <div className="sidefilter-popup-container-main">
            <div className="sidefilter-popup-block-ui"></div>
            <div className="sidefilter-popup-container animateXRight animateXRightactive">
                <Section_Header />
                <div className="sidefilter-popup-content">
                    <div className="sidefilter-content-items">

                        {Filters.map((filter, idx) => (
                            filter.type == 'checkbox' ?
                                <div className={`sidefilter-content-item ${filter.classNames.join(' ')}`} id={`sidefilter-item-${filter.id}`}>


                                    <label
                                        className="sidefilter-checkbox"
                                        htmlFor={`sidefilter-checkbox-${filter.id}`}
                                    >
                                        <input
                                            id={`sidefilter-checkbox-${filter.id}`}
                                            className="sidefilter-check"
                                            type="checkbox"
                                            value={filter.value}
                                            onChange={(e) => HandleFilterEvent(filter.type, filter.id, e.target.checked, filter.key)}
                                        />
                                        <span className="sidefilter-box"></span>
                                        {filter.isIconLeft && filter.isIcon && filter.icon ?
                                            <div
                                                className="icon"
                                                dangerouslySetInnerHTML={{ __html: filter.icon }}
                                            ></div>
                                            : ''}
                                        {filter.isLabel ?
                                            <div className="label">{filter.label || ""}</div>
                                            : ''}
                                        {!filter.isIconLeft && filter.isIcon && filter.icon ?
                                            <div
                                                className="icon"
                                                dangerouslySetInnerHTML={{ __html: filter.icon }}
                                            ></div>
                                            : ''}
                                    </label>

                                </div>
                                :
                                filter.type == 'multiselect' ?
                                    <div className={`sidefilter-content-item ${filter.classNames.join(' ')}`} id={`sidefilter-item-${filter.id}`}>

                                        <div className="sidefilter-item-header">
                                            {filter.isIconLeft && filter.isIcon && filter.icon ?
                                                <div
                                                    className="header-icon"
                                                    dangerouslySetInnerHTML={{ __html: filter.icon }}
                                                ></div>
                                                : ''}
                                            {filter.isLabel ?
                                                <div className="header-label">{filter.label || ""}</div>
                                                : ''}
                                            {!filter.isIconLeft && filter.isIcon && filter.icon ?
                                                <div
                                                    className="header-icon"
                                                    dangerouslySetInnerHTML={{ __html: filter.icon }}
                                                ></div>
                                                : ''}
                                        </div>

                                        <div className="sidefilter-item-options">
                                            {filter.selectoptions?.map((option, oidx) => (

                                                <div className="sidefilter-item-option" key={`sidefilter-item-option-${option.id}`}>
                                                    <label
                                                        className="sidefilter-checkbox"
                                                        htmlFor={`sidefilter-checkbox-${option.id}`}
                                                    >
                                                        <input
                                                            id={`sidefilter-checkbox-${option.id}`}
                                                            className="sidefilter-check"
                                                            type="checkbox"
                                                            value={option.value}
                                                            checked={option.value}
                                                            onChange={(e) => HandleFilterEvent(filter.type, option.id, e.target.checked, option.key, filter.id)}
                                                        />
                                                        <span className="sidefilter-box"></span>
                                                        {option.isIconLeft && option.isIcon && option.icon ?
                                                            <div
                                                                className="icon"
                                                                dangerouslySetInnerHTML={{ __html: option.icon }}
                                                            ></div>
                                                            : ''}
                                                        {option.isLabel ?
                                                            <div className="label">{option.label || ""}</div>
                                                            : ''}
                                                        {!option.isIconLeft && option.isIcon && option.icon ?
                                                            <div
                                                                className="icon"
                                                                dangerouslySetInnerHTML={{ __html: option.icon }}
                                                            ></div>
                                                            : ''}
                                                    </label>
                                                </div>

                                            ))}

                                        </div>



                                    </div>
                                    : ''
                        ))}


                    </div>
                </div>
                <Section_Footer />
            </div>
        </div>
    )
}

export default SideFilters;