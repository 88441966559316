import proxyConfig from '../../config/reverseProxy'
import HeaderConfig from '../../config/header'
import { io } from 'socket.io-client'

class SocketHandler {
    constructor() {
        this.socket_url = proxyConfig["socketUrl"];
        this.socket = null;
        this.userId = null;

        this.init = this.init.bind(this);
        this.initializeListeners = this.initializeListeners.bind(this);
        this.emitEvent = this.emitEvent.bind(this);
        this.onEvent = this.onEvent.bind(this);
        this.disconnect = this.disconnect.bind(this);
        this.setUserId = this.setUserId.bind(this);

    }

    setUserId(userId) {
        this.userId = userId;
    }

    init() {
        console.log("SocketProvider mounted");
        const url = new URL(this.socket_url)
        const baseUrl = `${url.protocol}//${url.host}`;
        const path = url.pathname

        this.socket = io(`${baseUrl}/web-app-clients`, {
            path: path !== "/" ? path + "/socket.io" : "/socket.io",
            query: {
                accessToken: localStorage.getItem('authToken')
            }
        });
        this.initializeListeners();
    }

    initializeListeners() {

        this.socket.on("connect", () => {
            console.log("Connected to the server with ID:", this.socket.id);
        });

        this.socket.on("disconnect", () => {
            console.log("Disconnected from the server with ID:", this.socket.id);
        });

        this.socket.on("error", (error) => {
            console.log("Error in websocket connection", error);
        });
    }

    emitEvent(event, data) {
        this.socket.emit(event, data);
    }

    onEvent(event, callback) {
        this.socket.on(event, (data) => callback(data));
    }

    disconnect() {
        if (this.socket.connected) {
            this.socket.disconnect();
        }
    }
}
export default SocketHandler;
