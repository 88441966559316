

import React, { useEffect, useState, useRef, useCallback, useMemo, StrictMode } from 'react'
import { Route, Routes, BrowserRouter, Link, useLocation, Navigate, Outlet, useNavigate, useParams } from 'react-router-dom';
import store from '../../redux/store'
import StoreHandler from '../../redux/StoreHandler'
import { useSelector, useDispatch } from 'react-redux'
import UserAction from '../../redux/action/userAction'

import ReverseProxy from '../../config/reverseProxy'
import SettingsHandler from '../../Handlers/Settings/Settings';
import EstimateHandler from '../../Handlers/Estimate/Estimate';
import PurchaseOrderHandler from '../../Handlers/PurchaseOrder/PurchaseOrder';
import CommentsHandler from '../../Handlers/Comments/Comments';

import Icons from '../../assets/Icons'
import Images from '../../assets/Images'
import { CostInput, TextInput, SelectInput, DateTimeInput, RadioInput, MultiSelectInput } from '../../components/Inputs'
import Utils from '../../utils'
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

import AlertPopup from '../../components/AlertPopup'
import SystemToastPopup from '../../components/SystemToastPopup'
import Loading from '../../components/Loading'
import ProjectsHanlder from '../../Handlers/Projects/Projects';
import PreviewPurchaseOrder from './previewPurchaseOrder'
import BuilderAttachments from '../../Handlers/BuilderAttachments/BuilderAttachments';

import ImagePreviewPopup from '../../components/ImagePreviewPopup'
import CommentBox from '../../components/CommentBox'
import { useAuth, useMenuAuth } from '../../hooks/useAuth'

const ViewPurchaseOrder = ({ props }) => {

    const { projectId, purchaseId } = useParams();

    const { menuType = 'create' } = props

    const [project_id, setProject_id] = useState('')
    const [project_name, setProject_name] = useState('')

    const projectsHandler = new ProjectsHanlder()
    const [isLoading, setIsLoading] = useState(false)
    const [warningAlert, setWarningAlert] = useState(false)
    const [warningAlertType, setWarningAlertType] = useState('warning')
    const [apiFailedMessage, setApiFailedMessage] = useState('Error in Purchase Order')

    const navigate = useNavigate()
    const store = useSelector((store) => store)
    const dispatch = useDispatch()
    const { updateState } = new UserAction

    const formSubmitBtnRef = useRef()

    const storeHandler = new StoreHandler({
        dataStack: ['team_users'],
        store: store,
        dispatch: dispatch,
        updateState: updateState,
    })

    const LoadStoreData = async () => {
        setIsLoading(true)
        let resolved = await storeHandler.init()
        setIsLoading(false)
    }

    const { isAdmin, menu_permission } = useMenuAuth({ menu: 'purchase--purchase_orders', feature_key: 'purchase-orders' })
    const location = useLocation()

    const [selected_view_settings_value, setSelected_view_settings_value] = useState('')
    const [has_view_access, setHas_view_access] = useState(false)
    const [has_add_access, setHas_add_access] = useState(false)
    const [has_edit_access, setHas_edit_access] = useState(false)
    const [has_delete_access, setHas_delete_access] = useState(false)

    useEffect(() => {

        if (isAdmin) {

            setHas_view_access(true)
            setHas_add_access(true)
            setHas_edit_access(true)
            setHas_delete_access(true)
        }

        else if (menu_permission) {

            if (
                !menu_permission._view
                &&
                (!menu_permission.selected_view_settings_value
                    || menu_permission.selected_view_settings_value == 'no_access')
            ) {
                navigate(`${ReverseProxy['proxyUrl']}/quick-menu`, { state: { from: location }, replace: true })
            }


            setHas_view_access(menu_permission._view == '1')
            setHas_add_access(menu_permission._create == '1')
            setHas_edit_access(menu_permission._edit == '1')
            setHas_delete_access(menu_permission._delete == '1')

            setSelected_view_settings_value(menu_permission.selected_view_settings_value)

        }

    }, [store])

    const purchaseOrderHandler = new PurchaseOrderHandler()
    const commentsHandler = new CommentsHandler()
    const builderAttachments = new BuilderAttachments()

    const [materialsOptions, setMaterialsOptions] = useState([])

    const [unitsOptions, setUnitsOptions] = useState([])
    const [currency_type, setCurrency_type] = useState('₹')

    const [id, setId] = useState(Utils.getUniqueId())
    const [name, setName] = useState('')
    const [purchaseID, setPurchaseID] = useState('')
    const [status, setStatus] = useState('')

    const [vendor_id, setVendorId] = useState('')
    const [vendor_name, setVendorName] = useState('')

    const [purchaseOrderDate, setPurchaseOrderDate] = useState(Utils._getLocalDate())

    const [approvalDeadlineDate, setApprovalDeadlineDate] = useState('')
    const [deliveryDeadlineDate, setDeliveryDeadlineDate] = useState('')

    const [AssigneeOptions_Orginal, setAssigneeOptions_Orginal] = useState([])
    const [AssigneeOptions, setAssigneeOptions] = useState([])
    const [SelectedAssignees_Res, setSelectedAssignees_Res] = useState([])
    const [SelectedAssignees, setSelectedAssignees] = useState([])




    const [GRNTotalAmount, setGRNTotalAmount] = useState(0)
    const [GRNPaidAmount, setGRNPaidAmount] = useState(0)
    const [GRNDueAmount, setGRNDueAmount] = useState(0)

    const [introduction_text, setIntroduction_text] = useState('')
    const [comments, setComments] = useState('')

    const [vendorOptions, setVendorOptions] = useState([])

    const [statusOptions, setStatusOptions] = useState([
        {
            value: 0,
            label: "Pending",
            isDefault: false
        },
        {
            value: 1,
            label: "Approved",
            isDefault: false
        },
        {
            value: 2,
            label: "Rejected",
            isDefault: false
        },
    ])

    const [termsConditionOptions, setTermsConditionOptions] = useState([])
    const [termsConditionId, setTermsConditionId] = useState("")
    const [termsConditionValue, setTermsConditionValue] = useState("")


    const [purchaseOrderLoaded, setPurchaseOrderLoaded] = useState(false)

    const [materialItems, setMaterialItems] = useState([
        {
            id: Utils.getUniqueId(),
            material_id: "",
            material_name: "",
            unitrate: 0,
            tax: 0,
            markup: 0,
            tax_amount: 0,
            markup_amount: 0,
            final_unitrate: 0,
            amount: 0,
            final_amount: 0,
            pre_tax_amount: 0,
            value_type: `$`,
            unit_id: '',
            unit_name: '',
            quantity: 0,
            description: '',
            notes: ''
        }
    ])

    const HandleMaterialOptionsSelected = () => {

        let material_items = [...materialItems].map(c => c.material_id)

        let _materialsOptions = [...materialsOptions]

        _materialsOptions = _materialsOptions.map(option => {

            if (material_items.includes(option.value)) option.disabled = true
            else option.disabled = false
            return option
        })

        setMaterialsOptions(_materialsOptions)

    }

    const [showCreatePreview, setShowCreatePreview] = useState(false)

    const HandlepreviewClientInvoice = (e) => {

        navigate(`/builder/projects/${projectId}/purchase_order/${purchaseId}/view/preview`)

    }
    const HandleEditClientInvoice = (e) => {
        if (!has_edit_access) return
        navigate(`/builder/projects/${projectId}/purchase_order/${purchaseId}/edit`)

    }
    const HandleCancel = (e) => {

        navigate(`/builder/projects/${projectId}/purchase_order`)
    }

    const LoadClientInvoice = async () => {

        let filter = {
            purchaseId: purchaseId,
            projectId: projectId,
        }

        setIsLoading(true)

        let response = await purchaseOrderHandler.getPurchaseOrdersHandler(filter)
        setIsLoading(false)

        if (response.success) {
            let { data } = response

            if (!Array.isArray(data) || !data.length) {
                return navigate(`/builder/projects/${projectId}/purchase_order`)
            }


            let {
                id: ID,
                name,
                purchaseID,
                projectId: projectID,
                purchase_date,
                approval_deadline_date,
                delivery_deadline_date,
                vendor_id,
                vendor_name,
                status,
                grn_total_amount,
                grn_paid_amount,
                grn_due_amount,
                introduction_text,
                comments,
                terms_condition_id,
                terms_condition,
                cost_items,
                attachments,
                assignees,
                delivery_history
            } = data[0]

            setId(ID)
            setPurchaseID(purchaseID)
            setName(name)
            setStatus(status)
            setIntroduction_text(introduction_text)

            setTermsConditionId(terms_condition_id)
            setTermsConditionValue(terms_condition)
            setComments(comments)

            setPurchaseOrderDate(purchase_date)
            setApprovalDeadlineDate(approval_deadline_date)
            setDeliveryDeadlineDate(delivery_deadline_date)

            setVendorId(vendor_id)
            setVendorName(vendor_name)

            setGRNTotalAmount(grn_total_amount || 0)
            setGRNPaidAmount(grn_paid_amount || 0)
            setGRNDueAmount(grn_due_amount || 0)

            setMaterialItems(cost_items || [])
            setAttachmentsFiles(attachments || [])
            setSelectedAssignees_Res(assignees || [])
            setPurchaseOrderLoaded(true)

        }
    }

    useEffect(() => {


        if (menuType == 'create') {
        }
        else {
            LoadClientInvoice()

            LoadComments({ project_id: projectId, parent_id: purchaseId })
        }

        LoadStoreData()
    }, [])


    useEffect(() => {

        if (store && store.user) {

            let { team_users } = store.user


            if (Array.isArray(team_users)) {

                let _team_users = team_users.filter(opt => opt.status == '1').map((opt) => {
                    return {
                        value: opt.id,
                        label: opt.name,
                    }
                })

                setAssigneeOptions(_team_users)
                setAssigneeOptions_Orginal(team_users)
            }


        }

    }, [store])

    useEffect(() => {

        if (AssigneeOptions.length && Array.isArray(SelectedAssignees_Res) && SelectedAssignees_Res.length) {

            let select_assignee = SelectedAssignees_Res.map(p => {
                let assignee = AssigneeOptions.filter(ph => ph.value == p)[0]

                if (!assignee) return p
                return assignee
            })

            setSelectedAssignees(select_assignee)

        }

    }, [AssigneeOptions, SelectedAssignees_Res])


    const [ShowSelectedPreviewImage, setShowSelectedPreviewImage] = useState(false)
    const [SelectedPreviewImage, setSelectedPreviewImage] = useState(undefined)
    const AttachmentsInputRef = useRef()
    const [AttachmentsFiles, setAttachmentsFiles] = useState([])

    const HandleSelectPreviewImage = (image) => {
        setShowSelectedPreviewImage(true)
        setSelectedPreviewImage({
            name: image.original_filename,
            file_url: image.file_url,
        })
    }
    const HandleHideSelectPreviewImage = (confirmation) => {
        setShowSelectedPreviewImage(confirmation)
        setSelectedPreviewImage(undefined)
    }
    const [CommentsItems, setCommentsItems] = useState([])

    const LoadComments = async ({ project_id, parent_id }) => {

        if (!project_id || !parent_id) return

        let filter = {
            project_id: project_id,
            parent_id: parent_id
        }

        setIsLoading(true)

        let response = await commentsHandler.getCommentsHandler(filter)
        setIsLoading(false)

        if (response.success) {
            let { data } = response

            if (!Array.isArray(data)) return

            console.log(data, 'data');

            setCommentsItems(data)
        }
    }

    const HandleAddComment = async (comment) => {
        let _CommentsItems = [...CommentsItems]

        _CommentsItems.push(comment)

        setCommentsItems(_CommentsItems)

        let update_data = {
            project_id: projectId,
            parent_id: purchaseID,
            parent_name: name || `PO ${purchaseID}`,
            parent_type: String(0),
            message: comment.message,
            tagged_users: comment.tagged_users || []
        }

        let response = await commentsHandler.createCommentHandler(update_data)

        if (!response.success) {
            setWarningAlert(true)
            setWarningAlertType('error')
            setApiFailedMessage(`Error in Comments, Please try again!`)
        }
    }

    const HandleDeleteComment = async (id, idx) => {
        let _CommentsItems = [...CommentsItems]

        _CommentsItems.splice(idx, 1)

        setCommentsItems(_CommentsItems)

        if (menuType == 'create') return

        let update_data = {
            project_id: projectId,
            ids: [id]
        }

        let response = await commentsHandler.deleteCommentHandler(update_data)

        if (!response.success) {
            setWarningAlert(true)
            setWarningAlertType('error')
            setApiFailedMessage(`Error in Comments, Please try again!`)
        }
    }

    const getStatusLabel = (status) => {

        let statusLabel = statusOptions.filter(opt => opt.value == status)
        if (statusLabel.length) return statusLabel[0].label
        else return ''
    }


    return (
        <>


            {isLoading ?
                <Loading
                    props={{
                        isMainLogo: false,
                        isLabel: true
                    }} />
                : null}
            {warningAlert ?

                <SystemToastPopup
                    props={{
                        type: warningAlertType,
                        message: apiFailedMessage || "Error in Purchase Order, Please try again!",
                        callback: (confirmation) => setWarningAlert(false)
                    }} />

                : null}

            {ShowSelectedPreviewImage ?

                <ImagePreviewPopup
                    props={{
                        heading: `Preview - ${SelectedPreviewImage.name}`,
                        image: SelectedPreviewImage.file_url,
                        message: "Invalid Inputs, Please try again with vaild Inputs!",
                        callback: (confirmation) => HandleHideSelectPreviewImage(confirmation)
                    }} />

                : null}

            {showCreatePreview ?
                <PreviewPurchaseOrder
                    props={{
                        menuType: 'edit',
                        purchaseOrderLoaded: true,
                        purchaseOrderDetials: {
                            id: id,
                            name: name,
                            purchaseID: purchaseID,
                            projectId: projectId,

                            purchase_date: purchaseOrderDate,
                            approval_deadline_date: approvalDeadlineDate,
                            delivery_deadline_date: deliveryDeadlineDate,

                            vendor_id: vendor_id,
                            vendor_name: vendor_name,

                            status,
                            grn_total: GRNTotalAmount,
                            grn_paid: GRNPaidAmount,
                            grn_due: GRNDueAmount,

                            introduction_text: introduction_text || "",
                            comments: comments || "",

                            terms_condition_id: termsConditionId || "",
                            terms_condition: termsConditionValue || "",

                            cost_items: materialItems || [],
                        },
                        saveCallback: () => {
                            formSubmitBtnRef.current?.click()
                            setShowCreatePreview(false)
                        },
                        close: () => { setShowCreatePreview(false) }
                    }}
                />
                : null}

            <Routes>
                <Route path='/preview'
                    element={
                        <PreviewPurchaseOrder
                            props={{
                                menuType: 'edit',
                                purchaseOrderLoaded: purchaseOrderLoaded,
                                purchaseOrderDetials: {
                                    id: id,
                                    name: name,
                                    purchaseID: purchaseID,
                                    projectId: projectId,

                                    purchase_date: purchaseOrderDate,
                                    approval_deadline_date: approvalDeadlineDate,
                                    delivery_deadline_date: deliveryDeadlineDate,

                                    vendor_id: vendor_id,
                                    vendor_name: vendor_name,

                                    status,
                                    grn_total: GRNTotalAmount,
                                    grn_paid: GRNPaidAmount,
                                    grn_due: GRNDueAmount,

                                    introduction_text: introduction_text || "",
                                    comments: comments || "",

                                    terms_condition_id: termsConditionId || "",
                                    terms_condition: termsConditionValue || "",

                                    cost_items: materialItems || [],
                                },
                                saveCallback: (e) => {
                                    navigate(`/builder/projects/${projectId}/purchase_order/${purchaseId}/view`)
                                },
                                close: () => {

                                    navigate(`/builder/projects/${projectId}/purchase_order/${purchaseId}/view`)

                                }
                            }}
                        />}>
                </Route>

            </Routes>

            <div className="project-create_client_invoice-main" id='project-create_client_invoice-main'>
                <div className="create_client_invoice-panel-content">

                    <div className="panel-content-header">
                        <div className="left">
                            <div className="icon"
                                dangerouslySetInnerHTML={{ __html: Icons.general.back_arrow }}
                                onClick={(e) => HandleCancel(e)}
                            ></div>
                            <div className="title">Purchase Order Detials</div>
                        </div>
                        <div className="right">
                            <div className="total-items">
                                <div className={`total-item ${!GRNTotalAmount ? 'total-item-diable' : ''}`}>
                                    <div className="label">GRN Total</div>
                                    <div className="value">{currency_type}{parseFloat(GRNTotalAmount).toFixed(2)}</div>
                                </div>
                                <div className={`total-item ${!GRNPaidAmount ? 'total-item-diable' : ''}`}>
                                    <div className="label">GRN Paid</div>
                                    <div className="value">{currency_type}{parseFloat(GRNPaidAmount).toFixed(2)}</div>
                                </div>
                                <div className={`total-item ${!GRNDueAmount ? 'total-item-diable' : ''}`}>
                                    <div className="label">GRN Due</div>
                                    <div className="value">{currency_type}{parseFloat(GRNDueAmount).toFixed(2)}</div>
                                </div>
                            </div>
                            <div className="preview-button" onClick={() => HandlepreviewClientInvoice()}>
                                <div
                                    className="icon"
                                    dangerouslySetInnerHTML={{ __html: Icons.general.eye_open }}
                                ></div>
                                <div className="label">Preview</div>
                            </div>
                            {has_edit_access ?
                                <div className="edit-button" onClick={() => HandleEditClientInvoice()}>
                                    <div
                                        className="icon"
                                        dangerouslySetInnerHTML={{ __html: Icons.general.edit_btn }}
                                    ></div>
                                    <div className="label">Edit</div>
                                </div>
                                : ''}
                        </div>
                    </div>
                    <form className="panel-content-sections">
                        <div className="create-client_invoicecontent-main">

                            <div className="create-client_invoicesections" style={{ height: '100%' }}>

                                <div className="create-client_invoicesection">
                                    <div className="section-input-items">
                                        <div
                                            className="section-input-item"
                                            style={{
                                                height: 'max-content',
                                                display: 'flex',
                                                flexDirection: 'column',
                                                gap: '8px'
                                            }}
                                        >
                                            <div
                                                className="view-input-label"
                                                style={{
                                                    fontSize: '18px',
                                                    fontWeight: '600'
                                                }}
                                            >
                                                Name
                                            </div>
                                            <div className="view-input-value">{name}</div>

                                        </div>
                                        <div
                                            className="section-input-item"
                                            style={{
                                                height: 'max-content',
                                                display: 'flex',
                                                flexDirection: 'column',
                                                gap: '8px'
                                            }}
                                        >
                                            <div
                                                className="view-input-label"
                                                style={{
                                                    fontSize: '18px',
                                                    fontWeight: '600'
                                                }}
                                            >
                                                PO ID
                                            </div>
                                            <div className="view-input-value">{purchaseID}</div>

                                        </div>
                                        <div
                                            className="section-input-item"
                                            style={{
                                                height: 'max-content',
                                                display: 'flex',
                                                flexDirection: 'column',
                                                gap: '8px'
                                            }}
                                        >
                                            <div
                                                className="view-input-label"
                                                style={{
                                                    fontSize: '18px',
                                                    fontWeight: '600'
                                                }}
                                            >
                                                Vendor Name
                                            </div>
                                            <div className="view-input-value">{vendor_name}</div>

                                        </div>

                                        <div
                                            className="section-input-item"
                                            style={{
                                                height: 'max-content',
                                                display: 'flex',
                                                flexDirection: 'column',
                                                gap: '8px'
                                            }}
                                        >
                                            <div
                                                className="view-input-label"
                                                style={{
                                                    fontSize: '18px',
                                                    fontWeight: '600'
                                                }}
                                            >
                                                Status
                                            </div>
                                            <div className="view-input-value">{getStatusLabel(status)}</div>

                                        </div>
                                    </div>
                                    <div className="section-input-items">
                                        {SelectedAssignees.length ?
                                            <div className="section-input-item" style={{ height: 'max-content' }}>
                                                <MultiSelectInput
                                                    props={{
                                                        id: 'assignee-multi-select-input',
                                                        placeholder: 'Select Assignees',
                                                        isRequired: true,
                                                        readOnly: true,
                                                        value: SelectedAssignees,
                                                        options: AssigneeOptions,
                                                        isIcon: false,
                                                        avoidDuplicate: true,
                                                        icon: '',
                                                        isLabel: true,
                                                        label: 'Assignees',
                                                        setValue: (value) => { }
                                                    }}
                                                />
                                            </div>
                                            : ''}
                                        <div
                                            className="section-input-item"
                                            style={{
                                                height: 'max-content',
                                                display: 'flex',
                                                flexDirection: 'column',
                                                gap: '8px'
                                            }}
                                        >
                                            <div
                                                className="view-input-label"
                                                style={{
                                                    fontSize: '18px',
                                                    fontWeight: '600'
                                                }}
                                            >
                                                Approval Deadline
                                            </div>
                                            <div className="view-input-value">{approvalDeadlineDate}</div>

                                        </div>
                                        <div
                                            className="section-input-item"
                                            style={{
                                                height: 'max-content',
                                                display: 'flex',
                                                flexDirection: 'column',
                                                gap: '8px'
                                            }}
                                        >
                                            <div
                                                className="view-input-label"
                                                style={{
                                                    fontSize: '18px',
                                                    fontWeight: '600'
                                                }}
                                            >
                                                Delivery Deadline
                                            </div>
                                            <div className="view-input-value">{deliveryDeadlineDate}</div>

                                        </div>

                                    </div>
                                </div>

                                <div className="create-client_invoicesection" id={`create-client_invoiceintroduction_text`}>

                                    <div className={`createproposal-description-main`}>
                                        <div className="head">
                                            <div className="title">
                                                <div className="icon" dangerouslySetInnerHTML={{ __html: Icons.general.description }}></div>
                                                <div className="label">Description</div>
                                            </div>
                                        </div>
                                        <div
                                            className="item-expanded"
                                            dangerouslySetInnerHTML={{ __html: introduction_text || " " }}
                                        >

                                        </div>
                                    </div>

                                </div>

                                <div className="create-client_invoicesection" id={`create-client_invoicepayment_schedule`}>
                                    <div className={`createproposal-description-main`}>
                                        <div className="head">
                                            <div className="title">
                                                <div className="label">Materials</div>
                                                <div className="icon" dangerouslySetInnerHTML={{ __html: Icons.general.info }}></div>
                                            </div>
                                        </div>

                                        <div className="purchase_order-items-splits-main">
                                            <div className="purchase_order-splits-header">
                                                <div className="header-item-name">ITEM DETAILS</div>
                                                <div className="header-item-quantity">QUANDITY</div>
                                            </div>
                                            <div className="purchase_order-splits-content">
                                                {materialItems.map((item, index) => (
                                                    <div className="purchase_order-content-item">
                                                        <div className="purchase_order-content-items"
                                                            style={{
                                                                height: '40px'
                                                            }}
                                                        >
                                                            <div
                                                                className="purchase_order-content-name"

                                                            >

                                                                {item.material_name}
                                                            </div>
                                                            <div
                                                                className="purchase_order-content-quantity"
                                                            >
                                                                {item.quantity}{item.unit_name}
                                                            </div>
                                                        </div>
                                                        <div className="purchase_order-content-items"
                                                            style={{
                                                                minHeight: '40px'
                                                            }}
                                                        >
                                                            <div className="purchase_order-content-description">
                                                                Description: {item.description}
                                                            </div>

                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                        </div>

                                    </div>
                                </div>
                                <div className="create-client_invoicesection" id={`create-proposal-attachments`}>
                                    <div className={`createproposal-description-main`}>
                                        <div className="head">
                                            <div className="title">
                                                <div className="icon" dangerouslySetInnerHTML={{ __html: Icons.general.attachments }}></div>
                                                <div className="label">Attachments</div>
                                            </div>
                                        </div>
                                        <div className="attachments-section-main">
                                            <div className="attachments-section-files">

                                                {AttachmentsFiles?.map((file, idx) => (
                                                    <div
                                                        className="attachment-file"
                                                        key={file.public_id}
                                                        id={`file-${file.public_id}`}
                                                    >
                                                        <div className="attachment-file-preview" onClick={() => HandleSelectPreviewImage(file)}>
                                                            <img src={file.file_url} alt="attachment-file" />
                                                        </div>
                                                        <div className="attachment-file-footer">
                                                            <div className="file-name">{file.original_filename}</div>
                                                            <div
                                                                className="file-download"
                                                                dangerouslySetInnerHTML={{ __html: Icons.general.download }}
                                                            ></div>
                                                        </div>

                                                    </div>
                                                ))}


                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="create-client_invoicesection" id={`create-purchase_order_terms_conditions`}>
                                    <div className={`createproposal-description-main`}>
                                        <div className="head">
                                            <div className="title">
                                                <div className="label">Terms and conditions</div>
                                                <div className="icon" dangerouslySetInnerHTML={{ __html: Icons.general.info }}></div>
                                            </div>
                                        </div>
                                        <div className="terms-condition-result" dangerouslySetInnerHTML={{ __html: termsConditionValue }}></div>
                                    </div>
                                </div>

                                <div className="create-proposal-section">

                                    <div className={`createproposal-description-main`}>
                                        <div className="head">
                                            <div className="label">Comments</div>
                                        </div>
                                        <div className="comments-section-main">
                                            <div className="comments-section-items">

                                                {CommentsItems.map((item, idx) => (

                                                    <div
                                                        className="comments-item-main"
                                                        key={`comments-item-${item.id}`}
                                                        id={`comments-item-${item.id}`}
                                                    >
                                                        <div className="comments-item-usericon">
                                                            {item.created_by_name.charAt(0)}
                                                        </div>
                                                        <div className="comments-item-detials">
                                                            <div className="detials-head">
                                                                <div className="left">
                                                                    <div className="name">{item.created_by_name}</div>
                                                                    <div className="time">{Utils.getLocalFullDateLabel(item.created_at)}</div>
                                                                </div>
                                                                <div className="right">
                                                                    <div
                                                                        className="delete-icon"
                                                                        onClick={() => HandleDeleteComment(item.id, idx)}
                                                                        dangerouslySetInnerHTML={{ __html: Icons.general.delete_btn }}></div>
                                                                </div>
                                                            </div>
                                                            <div className="detials-message">{item.message}</div>
                                                            {item.tagged_users && item.tagged_users.length ?
                                                                <div className="detials-tagged-users">
                                                                    {item.tagged_users.map((tag, idx) => (
                                                                        <span className='detials-tagged-user' key={`tagged-user-${tag.id}`}>{tag.name}</span>
                                                                    ))}
                                                                </div>
                                                                : ''}
                                                        </div>
                                                    </div>


                                                ))}



                                                {menuType == 'create' ?
                                                    <span>Comments will be available after create</span>
                                                    :
                                                    <CommentBox
                                                        props={{
                                                            project_id: projectId,
                                                            parent_id: purchaseID,
                                                            parent_name: name,
                                                            add_callback: HandleAddComment,
                                                        }}
                                                    />
                                                }

                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>

                        </div>
                    </form>
                </div >

            </div >
        </>
    )

}

export default ViewPurchaseOrder;