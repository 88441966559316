
import React, { useEffect, useState, useRef, useCallback, useMemo, StrictMode } from 'react'
import { Route, Routes, BrowserRouter, Link, useLocation, Navigate, Outlet, useNavigate, useParams } from 'react-router-dom';

import ProjectsHanlder from '../Handlers/Projects/Projects';

import store from '../redux/store'
import StoreHandler from '../redux/StoreHandler'
import { useSelector, useDispatch } from 'react-redux'
import UserAction from '../redux/action/userAction'


import Images from '../assets/Images'
import Icons from '../assets/Icons'
import Utils from '../utils'

const CommentBox = ({ props }) => {

    const { project_id, parent_id, parent_name, add_callback } = props

    const [CommentsInput, setCommentsInput] = useState("")
    const [UserShortName, setUserShortName] = useState("C")

    const [showTagUserList, setShowTagUserList] = useState(false)
    const [TeamUserList_Orginal, setTeamUserList_Orginal] = useState([])
    const [TeamUserList, setTeamUserList] = useState([...TeamUserList_Orginal])
    const [TaggedUserList, setTaggedUserList] = useState([])
    const CommentInputRef = useRef(null)

    const projectsHandler = new ProjectsHanlder()

    const loadTeamUsers = async () => {

        let filter = {
            projectID: project_id,
        }

        let response = await projectsHandler.getProjectTeamHandler(filter)

        if (response && response.success) {

            let team_users = response.data.team_users || []

            team_users = team_users.map((item) => {

                let name = String(item.name).split(" ")
                let short_name = name[0].charAt(0) + name[name.length - 1].charAt(0)

                return {
                    id: item.id,
                    name: item.name,
                    profile: short_name,
                    color_code: item.color_code
                }
            })

            setTeamUserList_Orginal([...team_users])
            setTeamUserList([...team_users])
        }

    }

    useEffect(() => {

        let userdetials = JSON.parse(localStorage.getItem('userdetials'))
        let name = String(userdetials.name).split(" ")
        let short_name = name[0].charAt(0) + name[name.length - 1].charAt(0)
        setUserShortName(short_name)

        loadTeamUsers()
    }, [])


    const HandleAddComment = () => {

        if (!CommentsInput) {
            CommentInputRef.current.focus()
            return
        }

        let userdetials = JSON.parse(localStorage.getItem('userdetials'))

        let comment = {
            id: Utils.getUniqueId(),
            created_at: new Date().getTime(),
            created_by: userdetials.id,
            created_by_name: userdetials.name,
            message: CommentsInput,
            parent_id: parent_id,
            parent_name: parent_name || `CMD ${parent_id}`,
            parent_type: 0,
            project_id: project_id,
            company_id: userdetials.company_id,
            tagged_users: TaggedUserList || []
        }

        add_callback(comment)

        setCommentsInput("")
        setTaggedUserList([])
        setTeamUserList([...TeamUserList_Orginal])
    }

    const HandleCommentsInput = (value) => {

        setCommentsInput(value)

    }

    const HandleAddTagUser = (user) => {

        setTaggedUserList([...TaggedUserList, user])

        let teamUserList = TeamUserList.filter((item) => item.id != user.id)
        setTeamUserList(teamUserList)

        setShowTagUserList(false)

        if (CommentInputRef.current) CommentInputRef.current.focus()
    }

    const HandleOpenTaggedUser = () => {

        setShowTagUserList(true)

        let dropdown_items = document.querySelector(`.comments-input-tag-main`)
        window.addEventListener('click', (e) => {

            let path = e.path || (e.composedPath && e.composedPath());
            if (showTagUserList && Array.isArray(path) && !path.includes(dropdown_items)) setShowTagUserList(false)
        })

        console.log(TeamUserList_Orginal, 'team_users');
    }
    const HandleRemoveTaggedUser = (user) => {

        let filtered = TaggedUserList.filter((item) => item.id != user.id)

        let teamUserList = [...TeamUserList, user]
        setTeamUserList(teamUserList)

        setTaggedUserList(filtered)

    }

    useEffect(() => {

        let add_user_btn = document.querySelector(`.comments-input-control-adduser`)
        let dropdown_items = document.querySelector(`.comments-input-tag-main`)
        window.addEventListener('click', (e) => {

            let path = e.path || (e.composedPath && e.composedPath());
            if (showTagUserList && Array.isArray(path) && !path.includes(dropdown_items) && !path.includes(add_user_btn)) setShowTagUserList(false)
        })

    }, [showTagUserList])


    return (
        <div className="comments-input-main">
            <div className="comments-input-usericon">{UserShortName}</div>
            <div className="comments-input comments-input-active">

                {showTagUserList ?
                    <div className="comments-input-tag-main">
                        <div className="comments-input-tag-users">
                            {TeamUserList.sort((a, b) => a.name.localeCompare(b.name, undefined, { sensitivity: "base" })).map((user, index) => (
                                <div
                                    className="comments-input-tag-users-item"
                                    key={`comment-tag-user-${user.id}`}
                                    onClick={() => HandleAddTagUser(user)}
                                >
                                    <div
                                        className="user-profile"
                                        style={{ backgroundColor: Utils.getRandomColor() }}
                                        dangerouslySetInnerHTML={{ __html: user.profile }}
                                    >
                                    </div>
                                    <div className="user-name">{user.name}</div>
                                </div>
                            ))}
                        </div>
                    </div>
                    : ''}

                {TaggedUserList.length ?
                    <div className="comments-input-tagged-users">
                        {TaggedUserList.map((user, index) => (
                            <div
                                className="tagged-users-item"
                                key={`comment-tagged-user-${user.id}`}
                            >
                                <div className="tagged-user-name">{user.name}</div>
                                <div className="tagged-user-close"
                                    dangerouslySetInnerHTML={{ __html: Icons.general.close }}
                                    onClick={() => HandleRemoveTaggedUser(user)}
                                ></div>
                            </div>

                        ))}
                    </div>
                    : ''}

                <div className="comments-input-textarea">
                    <textarea
                        ref={CommentInputRef}
                        placeholder='Add a comment...'
                        value={CommentsInput}
                        onChange={(e) => HandleCommentsInput(e.target.value)}
                    ></textarea>
                </div>
                <div className={`comments-input-controls ${CommentsInput.length ? `comments-input-controls-active` : ''}`}>
                    <div
                        className={`comments-input-control-adduser`}
                        onClick={() => HandleOpenTaggedUser()}
                    >
                        <div className="icon" dangerouslySetInnerHTML={{ __html: Icons.general.add_user }}></div>
                    </div>
                    <div
                        className={`comments-input-control-sendmsg`}
                        onClick={() => HandleAddComment()}
                    >
                        <div className="icon" dangerouslySetInnerHTML={{ __html: Icons.general.send_message }}></div>
                    </div>
                </div>
            </div>
        </div>
    )

}

export default CommentBox;