

import React, { useEffect, useState, useRef, useCallback, useMemo, StrictMode } from 'react'
import { Route, Routes, BrowserRouter, Link, useLocation, Navigate, Outlet, useNavigate, useParams } from 'react-router-dom';
import store from '../../../redux/store'
import StoreHandler from '../../../redux/StoreHandler'
import { useSelector, useDispatch } from 'react-redux'
import UserAction from '../../../redux/action/userAction'

import ReverseProxy from '../../../config/reverseProxy'
import IndentsHandler from '../../../Handlers/Indents/Indents';
import ProjectsHanlder from '../../../Handlers/Projects/Projects';
import CommentsHandler from '../../../Handlers/Comments/Comments';
import Icons from '../../../assets/Icons'
import Images from '../../../assets/Images'
import { CostInput, TextInput, SelectInput, DateTimeInput, RadioInput } from '../../../components/Inputs'
import Utils from '../../../utils'
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

import AlertPopup from '../../../components/AlertPopup'
import SystemToastPopup from '../../../components/SystemToastPopup'
import Loading from '../../../components/Loading'
import BuilderAttachments from '../../../Handlers/BuilderAttachments/BuilderAttachments';

import ImagePreviewPopup from '../../../components/ImagePreviewPopup'
import { useAuth, useMenuAuth } from '../../../hooks/useAuth'

const ViewIndents = ({ props }) => {

    const { projectId, indent_no } = useParams();

    const { menuType = 'create' } = props

    const [project_id, setProject_id] = useState('')
    const [project_name, setProject_name] = useState('')

    const [isLoading, setIsLoading] = useState(false)
    const [warningAlert, setWarningAlert] = useState(false)
    const [warningAlertType, setWarningAlertType] = useState('warning')
    const [apiFailedMessage, setApiFailedMessage] = useState('Error in Indents')

    const navigate = useNavigate()
    const store = useSelector((store) => store)
    const dispatch = useDispatch()
    const { updateState } = new UserAction

    const formSubmitBtnRef = useRef()

    const storeHandler = new StoreHandler({
        dataStack: ['company', 'sor_materials', 'field_settings', `project-${projectId}`],
        store: store,
        dispatch: dispatch,
        updateState: updateState,
    })

    const LoadStoreData = async () => {
        setIsLoading(true)
        let resolved = await storeHandler.init()
        setIsLoading(false)
    }

    const { isAdmin, menu_permission } = useMenuAuth({ menu: 'inventory--indents', feature_key: 'inventory-indents' })
    const location = useLocation()

    const [selected_view_settings_value, setSelected_view_settings_value] = useState('')
    const [has_view_access, setHas_view_access] = useState(false)
    const [has_add_access, setHas_add_access] = useState(false)
    const [has_edit_access, setHas_edit_access] = useState(false)
    const [has_delete_access, setHas_delete_access] = useState(false)

    useEffect(() => {

        if (isAdmin) {

            setHas_view_access(true)
            setHas_add_access(true)
            setHas_edit_access(true)
            setHas_delete_access(true)
        }

        else if (menu_permission) {

            if (
                !menu_permission._view
                &&
                (!menu_permission.selected_view_settings_value
                    || menu_permission.selected_view_settings_value == 'no_access')
            ) {
                navigate(`${ReverseProxy['proxyUrl']}/quick-menu`, { state: { from: location }, replace: true })
            }
            setHas_view_access(menu_permission._view == '1')
            setHas_add_access(menu_permission._create == '1')
            setHas_edit_access(menu_permission._edit == '1')
            setHas_delete_access(menu_permission._delete == '1')

            setSelected_view_settings_value(menu_permission.selected_view_settings_value)

        }

    }, [store])

    const indentsHandler = new IndentsHandler()
    const commentsHandler = new CommentsHandler()
    const builderAttachments = new BuilderAttachments()
    const projectsHandler = new ProjectsHanlder()

    const [materialsOptions, setMaterialsOptions] = useState([])
    const [materialsOptions_Orginal, setMaterialsOptions_Orginal] = useState([])

    const [unitsOptions, setUnitsOptions] = useState([])
    const [currency_type, setCurrency_type] = useState('₹')

    const [id, setId] = useState(Utils.getUniqueId())
    const [name, setName] = useState('')
    const [indent_No, setIndent_No] = useState('')
    const [status, setStatus] = useState('')

    const [indentDate, setIndentDate] = useState(Utils._getLocalDate())
    const [deliveryDate, setDeliveryDate] = useState('')


    const [remarks, setRemarks] = useState('')
    const [comments, setComments] = useState('')

    const [indent_reference, setIndentReference] = useState('')

    const [assigned_id, setAssigned_id] = useState('')
    const [assigned_name, setAssigned_name] = useState('')

    const [teamMatesOptions, setTeamMatesOptions] = useState([])

    const [statusOptions, setStatusOptions] = useState([
        {
            value: 0,
            label: "Pending",
            isDefault: false
        },
        {
            value: 1,
            label: "Approved",
            isDefault: false
        },
        {
            value: 2,
            label: "Rejected",
            isDefault: false
        },
    ])


    const [remaniderOptions, setRemaniderOptions] = useState([
        {
            value: 0,
            label: "Pending",
            isDefault: false
        },
        {
            value: 1,
            label: "Approved",
            isDefault: false
        },
        {
            value: 2,
            label: "Rejected",
            isDefault: false
        },
    ])

    const [paymentSplitLimitExists, setPaymentSplitLimitExists] = useState(false)
    const [materialItems, setMaterialItems] = useState([
        {
            id: Utils.getUniqueId(),
            material_id: "",
            material_name: "",
            value_type: `$`,
            unit_id: '',
            unit_name: '',
            quantity: 0,
            description: '',
        }
    ])


    const HandleMaterialOptionsSelected = () => {

        let material_items = [...materialItems].map(c => c.material_id)

        let _materialsOptions = [...materialsOptions]

        _materialsOptions = _materialsOptions.map(option => {

            if (material_items.includes(option.value)) option.disabled = true
            else option.disabled = false
            return option
        })

        setMaterialsOptions(_materialsOptions)

    }

    const HandlePaymentsInputChange = (value, index, key, label) => {

        let materialItems_ = [...materialItems]

        if (key == 'unitrate') {

            materialItems_[index][key] = value
            materialItems_[index]['amount'] = parseFloat(value || 0) * parseFloat(materialItems_[index]['quantity'] || 0)
            materialItems_[index]['value_type'] = currency_type
        }
        else if (key == 'quantity') {

            materialItems_[index][key] = value
            materialItems_[index]['amount'] = parseFloat(value || 0) * parseFloat(materialItems_[index]['unitrate'] || 0)
            materialItems_[index]['value_type'] = currency_type
        }
        else if (key == 'material') {

            materialItems_[index]['material_id'] = value
            materialItems_[index]['material_name'] = label

            HandleMaterialOptionsSelected()

            let original_item = materialsOptions_Orginal.filter(m => m.id == value)[0]

            if (original_item) {

                materialItems_[index]['unit_id'] = original_item.unit_id
                materialItems_[index]['unit_name'] = original_item.unit_name

                materialItems_[index]['unitrate'] = parseFloat(original_item.price || 0)
                materialItems_[index]['amount'] = parseFloat(materialItems_[index]['quantity'] || 0) * parseFloat(original_item.price || 0)

            }
        }

        else materialItems_[index][key] = value

        setMaterialItems(materialItems_)
    }
    const HandlePaymentsRemove = (index) => {
        let materialItems_ = [...materialItems]
        if (index == 0) {

            materialItems_[index] = {
                id: Utils.getUniqueId(),
                name: "",
                value: 0,
                amount: 0
            }
            setMaterialItems(materialItems_)
            return
        }
        materialItems_.splice(index, 1)
        setMaterialItems(materialItems_)
    }
    const HandlePaymentsAddNew = () => {

        let materialItems_ = [...materialItems]
        materialItems_.push({
            id: Utils.getUniqueId(),
            material_id: "",
            material_name: "",
            value_type: `$`,
            unit_id: '',
            unit_name: '',
            quantity: 0,
            description: '',
        })
        setMaterialItems(materialItems_)
    }

    useEffect(() => {

        if (Array.isArray(materialItems)) {

            const PaymentTotal = materialItems.reduce((prev, val, i) => prev + parseFloat(val.value), 0)
            if (PaymentTotal > 100 && !paymentSplitLimitExists) setPaymentSplitLimitExists(true)
            else if (PaymentTotal <= 100 && paymentSplitLimitExists) setPaymentSplitLimitExists(false)


            HandleMaterialOptionsSelected()
        }

    }, [materialItems])


    const HandleCancel = (e) => {

        navigate(`/builder/projects/${projectId}/inventory/indents`)
    }

    const HandleSubmit = async (e) => {
        e.preventDefault()

        if (paymentSplitLimitExists) return

        let update_detials = {
            id: id,
            name: name,
            indent_no: indent_No,
            projectId: projectId,

            assigned_id: String(assigned_id),
            assigned_name: String(assigned_name),

            indent_reference: String(indent_reference),

            indent_date: indentDate,
            delivery_date: deliveryDate,
            status: String(status),


            remarks: remarks || "",
            comments: comments || "",

            cost_items: materialItems || [],
            attachments: AttachmentsFiles || [],

        }


        let response = { success: false }

        if (menuType == 'edit') {

            update_detials["id"] = id
            response = await indentsHandler.updateIndentHandler(update_detials)

        } else {
            response = await indentsHandler.createIndentHandler(update_detials)

            let update_data = {
                project_id: projectId,
                parent_id: indent_No,
                parent_name: name || `Indent ${indent_No}`,
                parent_type: String(0),
                comments: CommentsItems
            }

            let comments_response = await commentsHandler.createMultiCommentHandler(update_data)

            if (!comments_response.success) {
                setWarningAlert(true)
                setWarningAlertType('error')
                setApiFailedMessage(`Error in Comments, Please try again!`)
            }

        }

        if (response.success) {
            setIsLoading(false)
            navigate(`/builder/projects/${projectId}/inventory/indents`)
        }
        else {
            setIsLoading(false)
            setWarningAlert(true)
            setWarningAlertType('error')
            setApiFailedMessage(`Error in Indents, Please try again!`)
        }
    }

    const getNextProjectID = async () => {

        let response = await indentsHandler.getNextIndentIDHandler()

        if (response && response.success) {

            let { indent_no } = response.data

            if (indent_no) setIndent_No(indent_no)

        } else {
            setWarningAlert(true)
            setWarningAlertType('error')
            setApiFailedMessage(`Error in Indents, Please try again!`)
        }
    }


    const LoadClientInvoice = async () => {

        let filter = {
            indent_no: indent_no,
            projectId: projectId,
        }

        setIsLoading(true)

        let response = await indentsHandler.getIndentsHandler(filter)
        setIsLoading(false)

        if (response.success) {
            let { data } = response

            if (!Array.isArray(data) || !data.length) {
                return navigate(`/builder/projects/${projectId}/inventory/indents`)
            }


            let {
                id: ID,
                name,
                indent_no,

                assigned_id,
                assigned_name,
                indent_reference,
                projectId: projectID,
                indent_date,
                delivery_date,
                status,

                remarks,
                comments,
                attachments,
                cost_items,
            } = data[0]

            setId(ID)
            setIndent_No(indent_no)
            setName(name)
            setStatus(status)
            setRemarks(remarks)

            setAssigned_id(assigned_id)
            setAssigned_name(assigned_name)

            setIndentReference(indent_reference)

            setComments(comments)

            setIndentDate(indent_date)
            setDeliveryDate(delivery_date)


            setMaterialItems(cost_items || [])
            setAttachmentsFiles(attachments || [])


        }
    }


    useEffect(() => {

        if (menuType == 'create') {
            getNextProjectID()
        }
        else {
            LoadClientInvoice()
            LoadComments({ project_id: projectId, parent_id: indent_no })
        }
        LoadProjectUsers()
        LoadStoreData()
    }, [])

    const LoadProjectUsers = async () => {

        let filter = {
            projectID: projectId,
        }

        let response = await projectsHandler.getProjectTeamHandler(filter)

        if (response.success) {

            let { team_users } = response.data

            if (!Array.isArray(team_users)) return

            let _team_users = team_users.map(user => {


                return {
                    value: user.id,
                    label: user.name,
                }
            })

            setTeamMatesOptions([
                {
                    value: '',
                    label: 'Select User'
                }, ..._team_users
            ])
        } else {
            setWarningAlert(true)
            setWarningAlertType('error')
            setApiFailedMessage(`Error in Indents, Please try again!`)
        }

    }
    const getStatusLabel = (status) => {

        let statusLabel = statusOptions.filter(opt => opt.value == status)
        if (statusLabel.length) return statusLabel[0].label
        else return ''
    }


    useEffect(() => {

        if (store && store.user) {

            let { company, sor_materials, settings } = store.user

            if (company) {

                let { currency_setting, timezone, temperature_setting } = company

                if (currency_setting) setCurrency_type(currency_setting)
            }
            if (Array.isArray(sor_materials)) {


                let _sor_materials = sor_materials.map((opt) => {
                    return {
                        value: opt.id,
                        label: opt.name,
                    }
                })

                setMaterialsOptions(_sor_materials)
                setMaterialsOptions_Orginal(sor_materials)
            }

            if (settings && Array.isArray(settings.field_settings) && settings.field_settings.length) {

                let { project_statuses, project_types, units } = settings.field_settings[0]

                units = units.filter(opt => opt.isActive).map((opt) => {
                    return {
                        value: opt.id,
                        label: opt.name,
                        isDefault: opt.isDefault || false
                    }
                })

                setUnitsOptions(units || [])

            }


        }

    }, [store])


    const [ShowSelectedPreviewImage, setShowSelectedPreviewImage] = useState(false)
    const [SelectedPreviewImage, setSelectedPreviewImage] = useState(undefined)
    const AttachmentsInputRef = useRef()
    const [AttachmentsFiles, setAttachmentsFiles] = useState([])

    const HandleTriggerAddAttachment = (e) => {
        if (AttachmentsInputRef.current) AttachmentsInputRef.current.click()
    }
    const HandleAddAttachment = async (e) => {

        let file = e.target.files[0]

        if (!file) return

        const formData = new FormData();

        formData.append('projectId', projectId);
        formData.append('attachment', file);
        formData.append('linked_type', '6');
        formData.append('linked_item_id', id);

        setIsLoading(true)

        let upload_response = await builderAttachments.createAttachmentHandler(formData)

        setIsLoading(false)

        if (upload_response.success) {

            let { public_id, url } = upload_response.data

            if (!public_id || !url) return

            let _AttachmentsFiles = [...AttachmentsFiles]

            _AttachmentsFiles.push({
                public_id: public_id,
                original_filename: file.name,
                bytes: file.size,
                format: file.type,
                file_url: url,
                linked_type: '6',
                linked_item_id: id,
            })

            setAttachmentsFiles(_AttachmentsFiles)

        } else {
            setWarningAlert(true)
            setWarningAlertType('error')
            setApiFailedMessage(`Error in Attachments, Please try again!`)
        }
    }
    const HandleDeleteAttachment = async (idx) => {

        let _AttachmentsFiles = [...AttachmentsFiles]

        setIsLoading(true)

        let upload_response = await builderAttachments.deleteAttachmentHandler({
            public_id: _AttachmentsFiles[idx].public_id,
            projectId: projectId,
        })

        setIsLoading(false)

        if (upload_response.success) {
            _AttachmentsFiles.splice(idx, 1)
            setAttachmentsFiles(_AttachmentsFiles)
        }
        else {
            setWarningAlert(true)
            setWarningAlertType('error')
            setApiFailedMessage(`Error in Attachments, Please try again!`)
        }
    }


    const HandleSelectPreviewImage = (image) => {
        setShowSelectedPreviewImage(true)
        setSelectedPreviewImage({
            name: image.original_filename,
            file_url: image.file_url,
        })
    }
    const HandleHideSelectPreviewImage = (confirmation) => {
        setShowSelectedPreviewImage(confirmation)
        setSelectedPreviewImage(undefined)
    }




    const [CommentsItems, setCommentsItems] = useState([])

    const [CommentsInput, setCommentsInput] = useState("")

    const LoadComments = async ({ project_id, parent_id }) => {

        console.log(project_id, parent_id, 'parent_id');
        if (!project_id || !parent_id) return

        let filter = {
            project_id: project_id,
            parent_id: parent_id
        }

        setIsLoading(true)

        let response = await commentsHandler.getCommentsHandler(filter)
        setIsLoading(false)

        if (response.success) {
            let { data } = response

            if (!Array.isArray(data)) return

            console.log(data, 'data');

            setCommentsItems(data)
        }
    }

    const HandleAddComment = async () => {
        let _CommentsItems = [...CommentsItems]

        let userdetials = JSON.parse(localStorage.getItem('userdetials'))

        _CommentsItems.push({
            id: Utils.getUniqueId(),
            created_at: new Date().getTime(),
            created_by: userdetials.id,
            created_by_name: userdetials.name,
            message: CommentsInput,
            parent_id: indent_No,
            parent_name: name || `Indent ${indent_No}`,
            parent_type: 0,
            project_id: projectId,
            company_id: userdetials.company_id,
        })

        setCommentsItems(_CommentsItems)
        setCommentsInput("")

        if (menuType == 'create') return

        let update_data = {
            project_id: projectId,
            parent_id: indent_No,
            parent_name: name || `Indent ${indent_No}`,
            parent_type: String(0),
            message: CommentsInput
        }

        let response = await commentsHandler.createCommentHandler(update_data)

        if (!response.success) {
            setWarningAlert(true)
            setWarningAlertType('error')
            setApiFailedMessage(`Error in Comments, Please try again!`)
        }
    }

    const HandleDeleteComment = async (id, idx) => {
        let _CommentsItems = [...CommentsItems]

        _CommentsItems.splice(idx, 1)

        setCommentsItems(_CommentsItems)

        if (menuType == 'create') return

        let update_data = {
            project_id: projectId,
            ids: [id]
        }

        let response = await commentsHandler.deleteCommentHandler(update_data)

        if (!response.success) {
            setWarningAlert(true)
            setWarningAlertType('error')
            setApiFailedMessage(`Error in Comments, Please try again!`)
        }
    }

    const PandleeditClientInvoice = (e) => {
        if (!has_edit_access) return
        navigate(`/builder/projects/${projectId}/inventory/indents/${indent_No}/edit`)

    }

    return (
        <>


            {isLoading ?
                <Loading
                    props={{
                        isMainLogo: false,
                        isLabel: true
                    }} />
                : null}
            {warningAlert ?

                <SystemToastPopup
                    props={{
                        type: warningAlertType,
                        message: apiFailedMessage || "Error in Indents",
                        callback: (confirmation) => setWarningAlert(false)
                    }} />

                : null}


            {ShowSelectedPreviewImage ?

                <ImagePreviewPopup
                    props={{
                        heading: `Preview - ${SelectedPreviewImage.name}`,
                        image: SelectedPreviewImage.file_url,
                        message: "Invalid Inputs, Please try again with vaild Inputs!",
                        callback: (confirmation) => HandleHideSelectPreviewImage(confirmation)
                    }} />

                : null}



            <div className="project-create_client_invoice-main" id='project-create_client_invoice-main'>
                <div className="create_client_invoice-panel-content">

                    <div className="panel-content-header">
                        <div className="left">
                            <div className="icon"
                                dangerouslySetInnerHTML={{ __html: Icons.general.back_arrow }}
                                onClick={(e) => HandleCancel(e)}
                            ></div>
                            <div className="title">Indent Detials</div>
                        </div>

                        <div className="right">
                            {has_edit_access ?
                                <div className="edit-button" onClick={() => PandleeditClientInvoice()}>
                                    <div
                                        className="icon"
                                        dangerouslySetInnerHTML={{ __html: Icons.general.edit_btn }}
                                    ></div>
                                    <div className="label">Edit</div>
                                </div>
                                : ''}
                        </div>

                    </div>
                    <form className="panel-content-sections">
                        <div className="create-client_invoicecontent-main">

                            <div className="create-client_invoicesections" style={{ height: '100%' }}>

                                <div className="create-client_invoicesection">
                                    <div className="section-input-items">

                                        <div
                                            className="section-input-item"
                                            style={{
                                                height: 'max-content',
                                                display: 'flex',
                                                flexDirection: 'column',
                                                gap: '8px'
                                            }}
                                        >
                                            <div
                                                className="view-input-label"
                                                style={{
                                                    fontSize: '18px',
                                                    fontWeight: '600'
                                                }}
                                            >
                                                Name
                                            </div>
                                            <div className="view-input-value">{name}</div>

                                        </div>
                                        <div
                                            className="section-input-item"
                                            style={{
                                                height: 'max-content',
                                                display: 'flex',
                                                flexDirection: 'column',
                                                gap: '8px'
                                            }}
                                        >
                                            <div
                                                className="view-input-label"
                                                style={{
                                                    fontSize: '18px',
                                                    fontWeight: '600'
                                                }}
                                            >
                                                Indent No
                                            </div>
                                            <div className="view-input-value">{indent_No}</div>

                                        </div>
                                        <div
                                            className="section-input-item"
                                            style={{
                                                height: 'max-content',
                                                display: 'flex',
                                                flexDirection: 'column',
                                                gap: '8px'
                                            }}
                                        >
                                            <div
                                                className="view-input-label"
                                                style={{
                                                    fontSize: '18px',
                                                    fontWeight: '600'
                                                }}
                                            >
                                                Assign request
                                            </div>
                                            <div className="view-input-value">{assigned_name}</div>

                                        </div>
                                        <div
                                            className="section-input-item"
                                            style={{
                                                height: 'max-content',
                                                display: 'flex',
                                                flexDirection: 'column',
                                                gap: '8px'
                                            }}
                                        >
                                            <div
                                                className="view-input-label"
                                                style={{
                                                    fontSize: '18px',
                                                    fontWeight: '600'
                                                }}
                                            >
                                                Reference
                                            </div>
                                            <div className="view-input-value">{indent_reference}</div>

                                        </div>



                                    </div>
                                    <div className="section-input-items">

                                        <div
                                            className="section-input-item"
                                            style={{
                                                height: 'max-content',
                                                display: 'flex',
                                                flexDirection: 'column',
                                                gap: '8px'
                                            }}
                                        >
                                            <div
                                                className="view-input-label"
                                                style={{
                                                    fontSize: '18px',
                                                    fontWeight: '600'
                                                }}
                                            >
                                                Delivery Date
                                            </div>
                                            <div className="view-input-value">{deliveryDate}</div>

                                        </div>
                                        <div
                                            className="section-input-item"
                                            style={{
                                                height: 'max-content',
                                                display: 'flex',
                                                flexDirection: 'column',
                                                gap: '8px'
                                            }}
                                        >
                                            <div
                                                className="view-input-label"
                                                style={{
                                                    fontSize: '18px',
                                                    fontWeight: '600'
                                                }}
                                            >
                                                Status
                                            </div>
                                            <div className="view-input-value">{getStatusLabel(status)}</div>

                                        </div>

                                    </div>

                                </div>

                                <div className="create-client_invoicesection" id={`create-client_invoiceremarks`}>

                                    <div className={`createproposal-description-main`}>
                                        <div className="head">
                                            <div className="title">
                                                <div className="icon" dangerouslySetInnerHTML={{ __html: Icons.general.description }}></div>
                                                <div className="label">Remarks</div>
                                            </div>
                                        </div>
                                        <div className="item-expanded"
                                            dangerouslySetInnerHTML={{ __html: remarks || "N/A" }}
                                        >

                                        </div>
                                    </div>

                                </div>

                                <div className="create-client_invoicesection" id={`create-client_invoicepayment_schedule`}>
                                    <div className={`createproposal-description-main`}>
                                        <div className="head">
                                            <div className="title">
                                                <div className="label">Add Materials</div>
                                                <div className="icon" dangerouslySetInnerHTML={{ __html: Icons.general.info }}></div>
                                            </div>
                                        </div>

                                        <div className="invoice-payment-splits-main indent-material-splits-main">
                                            <div className="invoice-payment-splits-header">
                                                <div className="header-item-name">Material</div>
                                                <div className="header-item-quantity">Quantity</div>
                                            </div>
                                            <div className="invoice-payment-splits-content">
                                                {materialItems.map((item, index) => (
                                                    <div className="invoice-payment-content-item">
                                                        <div className="invoice-payment-content-items" style={{ height: '40px' }}>
                                                            <div className="invoice-payment-content-name">
                                                                {item.material_name}
                                                            </div>
                                                            <div className="invoice-payment-content-quantity">
                                                                {item.quantity}  {item.unit_name}
                                                            </div>
                                                        </div>
                                                        <div className="invoice-payment-content-items" style={{ height: '40px' }}>
                                                            <div className="invoice-payment-content-description">
                                                                Description: {item.description}
                                                            </div>
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>

                                        </div>

                                    </div>
                                </div>

                                <div className="create-client_invoicesection" id={`create-proposal-attachments`}>
                                    <div className={`createproposal-description-main`}>
                                        <div className="head">
                                            <div className="title">
                                                <div className="icon" dangerouslySetInnerHTML={{ __html: Icons.general.attachments }}></div>
                                                <div className="label">Attachments</div>
                                            </div>
                                        </div>
                                        <div className="attachments-section-main">
                                            <div className="attachments-section-files">

                                                {AttachmentsFiles?.map((file, idx) => (
                                                    <div
                                                        className="attachment-file"
                                                        key={file.public_id}
                                                        id={`file-${file.public_id}`}
                                                    >

                                                        <div className="attachment-file-preview" onClick={() => HandleSelectPreviewImage(file)}>
                                                            <img src={file.file_url} alt="attachment-file" />
                                                        </div>
                                                        <div className="attachment-file-footer">
                                                            <div className="file-name">{file.original_filename}</div>
                                                            <div
                                                                className="file-download"
                                                                dangerouslySetInnerHTML={{ __html: Icons.general.download }}
                                                            ></div>
                                                        </div>

                                                    </div>
                                                ))}


                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="create-proposal-section">

                                    <div className={`createproposal-description-main`}>
                                        <div className="head">
                                            <div className="label">Comments</div>
                                        </div>
                                        <div className="comments-section-main">
                                            <div className="comments-section-items">

                                                {CommentsItems.map((item, idx) => (

                                                    <div
                                                        className="comments-item-main"
                                                        key={`comments-item-${item.id}`}
                                                        id={`comments-item-${item.id}`}
                                                    >
                                                        <div className="comments-item-usericon">
                                                            {item.created_by_name.charAt(0)}
                                                        </div>
                                                        <div className="comments-item-detials">
                                                            <div className="detials-head">
                                                                <div className="left">
                                                                    <div className="name">{item.created_by_name}</div>
                                                                    <div className="time">{Utils.getLocalFullDateLabel(item.created_at)}</div>
                                                                </div>
                                                                <div className="right">
                                                                    <div
                                                                        className="delete-icon"
                                                                        onClick={() => HandleDeleteComment(item.id, idx)}
                                                                        dangerouslySetInnerHTML={{ __html: Icons.general.delete_btn }}></div>
                                                                </div>
                                                            </div>
                                                            <div className="detials-message">{item.message}</div>
                                                        </div>
                                                    </div>


                                                ))}


                                                <div className="comments-input-main">
                                                    <div className="comments-input-usericon">
                                                        RT
                                                    </div>
                                                    <div className="comments-input comments-input-active">
                                                        <div className="comments-input-textarea">
                                                            <textarea
                                                                placeholder='Add a comment...'
                                                                value={CommentsInput}
                                                                onChange={(e) => setCommentsInput(e.target.value)}
                                                            ></textarea>
                                                        </div>
                                                        <div className={`comments-input-controls ${CommentsInput.length ? `comments-input-controls-active` : ''}`}>
                                                            <div
                                                                className={`comments-input-control-sendmsg`}
                                                                onClick={() => HandleAddComment()}
                                                            >
                                                                <div className="icon" dangerouslySetInnerHTML={{ __html: Icons.general.send_message }}></div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>


                        </div>
                    </form>
                </div>

            </div>
        </>
    )

}

export default ViewIndents;