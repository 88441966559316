

import React, { useEffect, useState, useRef, useCallback, useMemo, StrictMode } from 'react'
import { Route, Routes, BrowserRouter, Link, useLocation, Navigate, Outlet, useNavigate, useParams } from 'react-router-dom';
import store from '../../redux/store'
import StoreHandler from '../../redux/StoreHandler'
import { useSelector, useDispatch } from 'react-redux'
import UserAction from '../../redux/action/userAction'

import ReverseProxy from '../../config/reverseProxy'
import CheckListsHandler from '../../Handlers/CheckLists/CheckLists';


import Icons from '../../assets/Icons'
import Images from '../../assets/Images'
import { SearcBarInput, TextArea, TextInput, SelectInput, TimeInput, MultiSelectInput, DateTimeInput } from '../../components/Inputs'
import Utils from '../../utils'
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

import SystemToastPopup from '../../components/SystemToastPopup'
import AlertPopup from '../../components/AlertPopup'
import Loading from '../../components/Loading'
import NoDataFound from '../../components/NoDataFound'

import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableFooter from '@mui/material/TableFooter';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';

import { useAuth, useMenuAuth } from '../../hooks/useAuth'


const CheckListEdit = ({ props }) => {

    const { projectId, listId } = useParams();
    const location = useLocation()

    const queryParams = new URLSearchParams(location.search);
    const date = queryParams.get('date');
    const currentDate = Utils._getLocalDate()

    const [isLoading, setIsLoading] = useState(false)
    const [warningAlert, setWarningAlert] = useState(false)
    const [warningAlertType, setWarningAlertType] = useState('warning')
    const [apiFailedMessage, setApiFailedMessage] = useState('Error in Bills')

    const navigate = useNavigate()
    const store = useSelector((store) => store)
    const dispatch = useDispatch()
    const { updateState } = new UserAction

    const storeHandler = new StoreHandler({
        dataStack: ['team_users'],
        store: store,
        dispatch: dispatch,
        updateState: updateState,
    })

    const LoadStoreData = async () => {
        setIsLoading(true)
        let resolved = await storeHandler.init()
        setIsLoading(false)
    }

    const { isAdmin, menu_permission } = useMenuAuth({ menu: 'safety--checklists', feature_key: 'checklists' })


    const [selected_view_settings_value, setSelected_view_settings_value] = useState('')
    const [has_view_access, setHas_view_access] = useState(false)
    const [has_add_access, setHas_add_access] = useState(false)
    const [has_edit_access, setHas_edit_access] = useState(false)
    const [has_delete_access, setHas_delete_access] = useState(false)

    useEffect(() => {

        if (isAdmin) {

            setHas_view_access(true)
            setHas_add_access(true)
            setHas_edit_access(true)
            setHas_delete_access(true)
        }

        else if (menu_permission) {

            if (
                !menu_permission._view
                &&
                (!menu_permission.selected_view_settings_value
                    || menu_permission.selected_view_settings_value == 'no_access')
            ) {
                navigate(`${ReverseProxy['proxyUrl']}/quick-menu`, { state: { from: location }, replace: true })
            }


            if (menu_permission._create == '0' && menu_permission._edit == '0') navigate(`${ReverseProxy['proxyUrl']}/builder/projects`, { state: { from: location }, replace: true })

            setHas_view_access(menu_permission._view == '1')
            setHas_add_access(menu_permission._create == '1')
            setHas_edit_access(menu_permission._edit == '1')
            setHas_delete_access(menu_permission._delete == '1')

            setSelected_view_settings_value(menu_permission.selected_view_settings_value)

        }

    }, [store])

    const [Id, setId] = useState('')
    const [Template_Name, setTemplate_Name] = useState('')


    const checkListsHandler = new CheckListsHandler()

    const [CheckListQuestionTemplates, setCheckListQuestionTemplates] = useState([])
    const [CheckListQuestions, setCheckListQuestions] = useState([])

    const attachmentFileInput = useRef([])

    const HandleInputChange = (type, value, index) => {
        let checklists = [...CheckListQuestions]

        if (type == 'description') checklists[index].description = value
        else if (type == 'yes_no') checklists[index].response.value = value
        else if (type == 'text') checklists[index].response.value = value
        else if (type == 'checkbox') {
            let selected = checklists[index].response.selected
            let found = selected.indexOf(value)
            if (found == -1) selected.push(value)
            else selected.splice(found, 1)
        }
        else if (type == 'multi_choice') {
            let selected = checklists[index].response.selected
            let found = selected.indexOf(value)
            if (found == -1) selected.push(value)
            else selected.splice(found, 1)
        }

        setCheckListQuestions(checklists)
    }

    const RenderResponseType = (params) => {

        const { q, i } = params

        const RenderYesNO = () => {
            return (
                <div className="answer-yes_no-main">
                    <div
                        className={`answer-yes_no-item ${q.response.value == 'yes' ? 'answer-yes_no-yes_btn' : ''}`}
                        onClick={(e) => HandleInputChange('yes_no', 'yes', i)}
                    >{q.response.yes_no_setting.yes_placeholder || 'Yes'}</div>
                    <div
                        className={`answer-yes_no-item ${q.response.value == 'no' ? 'answer-yes_no-no_btn' : ''}`}
                        onClick={(e) => HandleInputChange('yes_no', 'no', i)}
                    >{q.response.yes_no_setting.no_placeholder || 'No'}</div>
                    <div
                        className={`answer-yes_no-item ${q.response.value == 'na' ? 'answer-yes_no-na_btn' : ''}`}
                        onClick={(e) => HandleInputChange('yes_no', 'na', i)}
                    >{q.response.yes_no_setting.na_placeholder || 'N/A'}</div>
                </div>
            )
        }

        const RenderText = () => {
            return (
                <div className="answer-text-main">
                    <textarea
                        className='answer-text-input'
                        placeholder={q.response.text_setting.placeholder}
                        onChange={(e) => HandleInputChange('text', e.target.value, i)}
                    >{q.response.value}</textarea>

                </div>
            )
        }

        const RenderCheckBox = () => {
            return (
                <div className="answer-checkbox-main">
                    <div className="answer-checkbox-items">
                        {q.response.checkbox_setting.options.map((option, op_idx) => (
                            <div
                                className="answer-checkbox-item"
                                key={`answer-checkbox-item-${option.id}`}
                            >
                                <div className="checkbox-input">
                                    <input
                                        className='checkbox-label-input'
                                        type='checkbox'
                                        checked={q.response.selected.indexOf(option.placeholder) != -1}
                                        onChange={(e) => HandleInputChange('checkbox', option.placeholder, i)}
                                    />
                                </div>
                                <div className="checkbox-input-label">{option.placeholder}</div>
                            </div>
                        ))}
                    </div>
                </div>
            )
        }
        const RenderMultiChoice = () => {
            return (
                <div className="answer-multi_choice-main">
                    <div className="answer-multi_choice-items">
                        {q.response.multi_choice_setting.options.map((option, op_idx) => (
                            <div
                                className="answer-multi_choice-item"
                                key={`answer-multi_choice-item-${option.id}`}
                            >

                                <div className="multi_choice-input">
                                    <input
                                        className='multi_choice-label-input'
                                        type='radio'
                                        name={`answer-multi_choice-${i}`}
                                        checked={q.response.selected.indexOf(option.placeholder) != -1}
                                        onChange={(e) => HandleInputChange('multi_choice', option.placeholder, i)}
                                    />

                                </div>
                                <div className="multi_choice-input-label">{option.placeholder}</div>

                            </div>
                        ))}
                    </div>
                </div>
            )
        }

        return (
            <>
                {
                    q.response.type == 'yes_no' ? RenderYesNO()
                        : q.response.type == 'text' ? RenderText()
                            : q.response.type == 'multi_choice' ? RenderMultiChoice()
                                : q.response.type == 'checkbox' ? RenderCheckBox()
                                    : ''}
            </>
        )

    }

    const HandleCancel = (e) => {

        navigate(`/builder/projects/${projectId}/checklists/${listId}/view?date=${currentDate}`)
    }

    const HandleValidation = () => {
        // check based on is_required in question

        let checklists = [...CheckListQuestions]

        let is_valid = true

        checklists.forEach((q, index) => {

            if (q.response.is_required) {

                if (q.response.type == 'yes_no') {
                    if (!q.response.value) is_valid = false
                }
                else if (q.response.type == 'text') {
                    if (!q.response.value) is_valid = false
                }
                else if (q.response.type == 'checkbox') {
                    if (!q.response.selected.length) is_valid = false
                }
                else if (q.response.type == 'multi_choice') {
                    if (!q.response.selected.length) is_valid = false
                }

            }
        })

        return is_valid
    }

    const HandleSave = async (e) => {
        e.preventDefault()

        if (!HandleValidation()) {
            setWarningAlert(true)
            setWarningAlertType('warning')
            setApiFailedMessage('Please fill all required fields')
            return
        }

        let payload_data = {
            project_id: projectId,
            template_id: String(listId),
            date: date,
            questions: CheckListQuestions.map(q => {
                return {
                    q_id: q.q_id || null,
                    question: q.question,
                    description: q.description,
                    response_type: q.response.type,
                    response_value: q.response.value,
                    response_selected: q.response.selected,
                    response_options: q.response.options,
                    response_attachments: q.attachments.map(a => a.file)
                }
            }),
        }

        let response = {
            success: false
        }

        setIsLoading(true)

        console.log(Id);

        // return
        if (Id) {
            payload_data.id = String(Id)
            response = await checkListsHandler.updateChecklistHandler(payload_data)
        } else response = await checkListsHandler.createChecklistHandler(payload_data)

        setIsLoading(false)

        if (response.success) {

            setWarningAlert(true)
            setWarningAlertType('success')
            setApiFailedMessage('Checklist response saved successfully')

            setTimeout(() => {
                HandleCancel()
            }, 800)
            return
        }
        else {
            setWarningAlert(true)
            setWarningAlertType('warning')
            setApiFailedMessage('Error in saving response')
            return
        }

    }

    const LoadChecklistTemplate = async () => {

        let filter = {
            id: listId,
            project_id: projectId,
        }

        setIsLoading(true)

        let response = await checkListsHandler.getChecklistTemplatesHandler(filter)
        setIsLoading(false)

        if (response.success) {
            let { data } = response

            if (!Array.isArray(data) || !data.length) {
                return HandleCancel()
            }

            let {

                questions

            } = data[0]

            let _questions = questions.map((q, index) => {

                return {
                    id: q.id,
                    q_id: q.id,
                    question: q.question.value,
                    description: '',
                    response: {
                        type: q.response.type,
                        value: '',
                        selected: [],
                        is_required: q.is_required,

                        yes_no_setting: {
                            yes_placeholder: q.response.yes_no_setting.yes_placeholder,
                            no_placeholder: q.response.yes_no_setting.no_placeholder,
                            na_placeholder: q.response.yes_no_setting.na_placeholder
                        },
                        text_setting: {
                            placeholder: q.response.text_setting.placeholder
                        },
                        checkbox_setting: {
                            options: q.response.checkbox_setting.options
                        },
                        multi_choice_setting: {
                            options: q.response.multi_choice_setting.options
                        }
                    },
                    attachments: []
                }
            })




            let checklist_exist = await getChecklistExist()

            console.log(checklist_exist, 'checklist_exist');


            if (checklist_exist) {
                let {
                    sno: id,
                    template_id,
                    template_name,
                    questions

                } = checklist_exist

                questions = questions || []

                console.log(id);

                setId(id)
                setTemplate_Name(template_name)

                _questions = _questions.map((q, index) => {

                    let found = questions.find(qq => qq.q_id == q.q_id)

                    if (found) {
                        q.q_id = found.q_id
                        q.question = found.question
                        q.description = found.description
                        q.response.value = found.response_value
                        q.response.selected = found.response_selected
                        q.attachments = found.response_attachments.map((a, a_idx) => {
                            return {
                                id: Utils.getUniqueId(),
                                url: a,
                                file: null
                            }
                        })
                    }

                    return q
                })
            }

            setCheckListQuestions(_questions)
        }
    }

    useEffect(() => {
        if (!date) navigate(`/builder/projects/${projectId}/checklists/${listId}/edit?date=${currentDate}`)

    }, [date]);


    const HandleOpenFile = (idx) => {

        attachmentFileInput.current[idx]?.click()
    }

    const HandleFileChange = (e, idx) => {

        let files = e.target.files

        if (!files.length) return

        let checklists = [...CheckListQuestions]

        for (let i = 0; i < files.length; i++) {

            let file = files[i]
            let reader = new FileReader()

            reader.onload = (e) => {

                let attachment = {
                    id: Utils.getUniqueId(),
                    url: e.target.result,
                    file: file
                }

                checklists[idx].attachments.push(attachment)
                setCheckListQuestions(checklists)

            }

            reader.readAsDataURL(file)
        }
    }
    const HandleDeleteAttachment = (idx, attachment_idx) => {

        let checklists = [...CheckListQuestions]

        checklists[idx].attachments.splice(attachment_idx, 1)
        setCheckListQuestions(checklists)
    }

    const HandleReset = () => {
        let checklists = [...CheckListQuestions]

        checklists.map((q, index) => {
            q.response.value = ''
            q.response.selected = []
            q.attachments = []
        })

        setCheckListQuestions(checklists)
    }

    const getChecklistExist = async () => {

        let filter = {
            project_id: projectId,
            template_id: listId,
            date: date
        }

        setIsLoading(true)

        let response = await checkListsHandler.getChecklistsHandler(filter)
        setIsLoading(false)

        if (response.success) {
            let { data } = response

            if (!Array.isArray(data) || !data.length) {
                return false
            }

            return data[0]

        }
        return false
    }

    useEffect(() => {
        getChecklistExist()
        LoadChecklistTemplate()
    }, [])

    useEffect(() => {

        if (store && store.user) {

        }

    }, [store])


    return (
        <>


            {isLoading ?
                <Loading
                    props={{
                        isMainLogo: false,
                        isLabel: true
                    }} />
                : null}
            {warningAlert ?

                <SystemToastPopup
                    props={{
                        type: warningAlertType,
                        message: apiFailedMessage || "Error in Bills",
                        callback: (confirmation) => setWarningAlert(false)
                    }} />

                : null}


            <form className="project-survey_updater-main" id='project-survey_updater-main' onSubmit={HandleSave}>
                <div className="survey_updater-header edit_form-survey_updater-header">
                    <div className="survey_updater-header-left">
                        <div
                            className="survey_updater-header-back_btn"
                            dangerouslySetInnerHTML={{ __html: Icons.general.dropdown_arrow }}
                            onClick={e => HandleCancel(e)}
                        ></div>
                        <div className="survey_updater-header-title">Checklist - {date}</div>


                    </div>
                    <div className="survey_updater-header-right">
                        <div
                            className="survey_updater-header-reset_btn"
                            onClick={HandleReset}
                        >Reset</div>
                        <button type='submit' className="survey_updater-header-save_btn">Save</button>
                    </div>
                </div>
                <div className="survey_updater-edit_form">
                    <div className="edit_form-items">
                        {CheckListQuestions.length ?
                            CheckListQuestions.map((item, index) => (
                                <div
                                    className="edit_form-item"
                                    key={`edit_form-item-${index}`}
                                >
                                    <div className="edit_form-item-head">
                                        <div className="edit_form-item-question">{index + 1}. {item.question} {item.is_required ? <div className="question-required">*</div> : ''}</div>

                                    </div>
                                    <div className="edit_form-item-response">
                                        {RenderResponseType({ q: item, i: index })}
                                    </div>
                                    <div className="edit_form-item-description">
                                        <TextArea
                                            props={{
                                                id: "edit_form-item-description",
                                                value: item.description,
                                                placeholder: 'Description',
                                                setValue: (value) => HandleInputChange('description', value, index),
                                                isIcon: false,
                                                isLabel: false,
                                                isRequired: false,
                                                label: "",
                                            }}
                                        />
                                    </div>
                                    <input
                                        className="edit_form-item-attachment-input"
                                        id={`edit_form-item-attachment-input-${index}`}
                                        type="file"
                                        multiple={false}
                                        onChange={(e) => HandleFileChange(e, index)}
                                        style={{ display: 'none' }}
                                        ref={(el) => (attachmentFileInput.current[index] = el)}
                                    />
                                    <div
                                        className="edit_form-item-add_file"
                                        onClick={(e) => HandleOpenFile(index)}
                                    >Add Attachment</div>
                                    <div className="edit_form-item-attachments">
                                        {item.attachments.map((attachment, Aindex) => (
                                            <div
                                                className="edit_form-item-attachment-item"
                                                key={`edit_form-item-attachment-item-${Aindex}`}
                                            >
                                                <div className="delete"
                                                    dangerouslySetInnerHTML={{ __html: Icons.general.close }}
                                                    onClick={(e) => HandleDeleteAttachment(index, Aindex)}
                                                ></div>
                                                <img src={attachment.url} />
                                            </div>
                                        ))}

                                    </div>
                                </div>
                            ))
                            : <NoDataFound label={'No Questions found'} />
                        }

                    </div>

                </div>

            </form>
        </>
    )

}

export default CheckListEdit;