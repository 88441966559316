

import React, { useEffect, useState, useRef } from 'react'
import { Route, Routes, BrowserRouter, Link, useLocation, Navigate, Outlet, useNavigate, useParams } from 'react-router-dom';
import store from '../../redux/store'
import { useSelector, useDispatch } from 'react-redux'
import UserAction from '../../redux/action/userAction'

import ReverseProxy from '../../config/reverseProxy'
import CompanyHandler from '../../Handlers/Company/Company';
import SettingsHandler from '../../Handlers/Settings/Settings';
import Icons from '../../assets/Icons'
import Images from '../../assets/Images'
import { CostInput, TextInput, SelectInput, RadioInput } from '../../components/Inputs'
import Utils from '../../utils'
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

import AlertPopup from '../../components/AlertPopup'
import Loading from '../../components/Loading'
import SystemToastPopup from '../../components/SystemToastPopup'

import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableFooter from '@mui/material/TableFooter';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';

import { useAuth, useMenuAuth } from '../../hooks/useAuth'

const Add_Template = ({ props }) => {

    const store = useSelector((store) => store)

    const currency_type = store.user && store.user.company ? store.user.company.currency_setting || "₹" : "₹"

    const { type, heading, close, callback, detials } = props

    const { templateId } = useParams()

    const settingsHandler = new SettingsHandler()

    const [isLoading, setIsLoading] = useState(false)
    const [warningAlert, setWarningAlert] = useState(false)
    const [warningAlertType, setWarningAlertType] = useState('warning')
    const [apiFailedMessage, setApiFailedMessage] = useState("Error in Server")

    const dispatch = useDispatch()
    const { updateState } = new UserAction

    const navigate = useNavigate()

    const { isAdmin, menu_permission } = useMenuAuth({ menu: 'settings--payment_schedule' })
    const location = useLocation()

    const [selected_view_settings_value, setSelected_view_settings_value] = useState('')
    const [has_view_access, setHas_view_access] = useState(false)
    const [has_add_access, setHas_add_access] = useState(false)
    const [has_edit_access, setHas_edit_access] = useState(false)
    const [has_delete_access, setHas_delete_access] = useState(false)

    useEffect(() => {

        if (isAdmin) {

            setHas_view_access(true)
            setHas_add_access(true)
            setHas_edit_access(true)
            setHas_delete_access(true)
        }

        else if (menu_permission) {

            if (
                !menu_permission._view
                &&
                (!menu_permission.selected_view_settings_value
                    || menu_permission.selected_view_settings_value == 'no_access')
            ) {
                navigate(`${ReverseProxy['proxyUrl']}/quick-menu`, { state: { from: location }, replace: true })
            }

            if (type == 'edit' && menu_permission._edit == '0') navigate(`${ReverseProxy['proxyUrl']}/builder/projects`, { state: { from: location }, replace: true })
            if (type == 'create' && menu_permission._create == '0') navigate(`${ReverseProxy['proxyUrl']}/builder/projects`, { state: { from: location }, replace: true })

            setHas_view_access(menu_permission._view == '1')
            setHas_add_access(menu_permission._create == '1')
            setHas_edit_access(menu_permission._edit == '1')
            setHas_delete_access(menu_permission._delete == '1')

            setSelected_view_settings_value(menu_permission.selected_view_settings_value)

        }

    }, [store])


    const [templateName, setTemplateName] = useState('')
    const [paymentSplits, setPaymentSplits] = useState([
        {
            id: Utils.getUniqueId(),
            name: "",
            milestone: 0
        }
    ])
    const [paymentSplitLimitExists, setPaymentSplitLimitExists] = useState(false)

    const HandleCancel = async (e) => {
        close(type)
    }
    const HandlePopupSubmit = async (e) => {

        e.preventDefault()

        if (paymentSplitLimitExists) return

        let updateta = {
            name: templateName,
            milestones: paymentSplits
        }

        let response = { success: false }

        if (type == 'edit') {

            updateta["id"] = templateId
            response = await settingsHandler.updatePaymentScheduleHandler(updateta)

        }

        else response = await settingsHandler.createPaymentScheduleHandler(updateta)

        if (response.success) {
            callback()
            setIsLoading(false)
        }
        else {
            setIsLoading(false)
            setWarningAlert(true)
            setWarningAlertType('error')
            setApiFailedMessage(`Error in Payment Schedule`)
        }

        close(type)
    }


    const HandlePaymentsInputChange = (value, index, key) => {

        let paymentSplits_ = [...paymentSplits]
        paymentSplits_[index][key] = value

        setPaymentSplits(paymentSplits_)
    }
    const HandlePaymentsRemove = (index) => {

        if (index == 0) return
        let paymentSplits_ = [...paymentSplits]
        paymentSplits_.splice(index, 1)
        setPaymentSplits(paymentSplits_)
    }
    const HandlePaymentsAddNew = () => {

        let paymentSplits_ = [...paymentSplits]
        paymentSplits_.push({
            id: Utils.getUniqueId(),
            name: '',
            value: '',
        })
        setPaymentSplits(paymentSplits_)
    }

    useEffect(() => {
        if (type == 'edit' && templateId) {

            const LoadTemplate = async (templateId) => {

                if (Object.keys(detials).length) {

                    let { id, name, milestones, created_at, document, updated_at, updated_by } = detials

                    console.log(milestones, 'milestones');

                    setTemplateName(name)
                    setPaymentSplits(milestones || [])

                }
                else {

                    let response = await settingsHandler.getPaymentSchedulesHandler({
                        id: templateId
                    })

                    if (response && response.success && Array.isArray(response.data) && response.data.length) {

                        let { id, name, milestones, created_at, document, updated_at, updated_by } = response.data[0]

                        setTemplateName(name)
                        setPaymentSplits(milestones || [])
                    }
                    else {
                        setWarningAlert(true)
                        setWarningAlertType('error')
                        setApiFailedMessage(`Error in Payment Schedule`)
                    }
                }
            }

            LoadTemplate(templateId)
        }

    }, [])

    useEffect(() => {

        if (Array.isArray(paymentSplits)) {

            const PaymentTotal = paymentSplits.reduce((prev, val, i) => prev + parseFloat(val.value), 0)

            if (PaymentTotal > 100 && !paymentSplitLimitExists) setPaymentSplitLimitExists(true)
            else if (PaymentTotal <= 100 && paymentSplitLimitExists) setPaymentSplitLimitExists(false)

        }

    }, [paymentSplits])
    const Popup_Header = () => {

        return (
            <div className="side-popup-header">
                <div className="header-item-select">
                    <div className="header-item-select-content">
                        <div className="label">{heading}</div>
                    </div>
                </div>
                <div
                    className="header-item-close"
                    onClick={(e) => HandleCancel(e)}
                    dangerouslySetInnerHTML={{ __html: Icons.general.close }}
                ></div>
            </div>
        );
    };

    const Popup_Footer = () => {

        return (
            <div className="sidebar-popup-footer">
                <div className="footer-item action-items">
                    <div className="action-preview">
                    </div>
                    <div className='action-btns'>
                        <div className="action-cancel" onClick={(e) => HandleCancel(e)}>
                            Cancel
                        </div>
                        <button
                            className={`action-btn action-${type} ${paymentSplitLimitExists ? 'action-btn-disable' : ''}`}
                            type='submit'
                        >

                            <div className="label">Save</div>
                        </button>
                    </div>
                </div>
            </div>
        );
    };

    return (
        <>

            {isLoading ?
                <Loading
                    props={{
                        isMainLogo: false,
                        isLabel: true
                    }} />
                : null}
            {warningAlert ?

                <SystemToastPopup
                    props={{
                        type: warningAlertType,
                        message: apiFailedMessage || "Error in Payment Schedule",
                        callback: (confirmation) => setWarningAlert(false)
                    }} />

                : null}


            <form
                className="popup-container-main popup-container-center payment_schedule-popup-container"
                onSubmit={HandlePopupSubmit}
            >
                <div className="popup-block-ui"></div>
                <div className="side-popup-container center-popup-container">
                    <Popup_Header />
                    <div className="terms_condition-popup-content">

                        <div className="content-item">
                            <TextInput
                                props={{
                                    id: "payment_schedule-templateName",
                                    value: templateName,
                                    placeholder: '',
                                    setValue: (value) => setTemplateName(value),
                                    isIcon: false,
                                    isLabel: true,
                                    isRequired: true,
                                    label: "Template name",
                                }}
                            />
                        </div>
                        <div className="payment-splits-main">
                            <div className="payment-splits-header">
                                <div className="header-item-name">Name</div>
                                <div className="header-item-milestone">Payment %</div>
                                <div className="header-item-action">Action</div>
                            </div>
                            <div className="payment-splits-content">
                                {paymentSplits.map((item, index) => (
                                    <div className="payment-content-items">
                                        <div className="payment-content-name">
                                            <TextInput
                                                props={{
                                                    id: "payment_schedule-paymentName",
                                                    value: item.name,
                                                    placeholder: '',
                                                    setValue: (value) => HandlePaymentsInputChange(value, index, 'name'),
                                                    isIcon: false,
                                                    isLabel: false,
                                                    isRequired: true,
                                                    label: "Name",
                                                }}
                                            />
                                        </div>
                                        <div className="payment-content-milestone">
                                            <CostInput props={{
                                                id: `payment_schedule-milestone`,
                                                isCostMethod: false,
                                                currency_type: currency_type,
                                                value: item.value,
                                                isIcon: true,
                                                isRequired: true,
                                                icon: "%",
                                                setValue: (value) => HandlePaymentsInputChange(value, index, 'value')
                                            }} />
                                        </div>

                                        <div className="payment-content-action">
                                            <div
                                                className="action-remove"
                                                onClick={(e) => HandlePaymentsRemove(index)}
                                            >
                                                <div className="icon" dangerouslySetInnerHTML={{ __html: Icons.general.close }}></div>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                            <div className={`payment-content-warning ${paymentSplitLimitExists ? 'warning-active' : ''}`}>
                                <div
                                    className="icon"
                                    dangerouslySetInnerHTML={{ __html: Icons.general.warning }}
                                ></div>
                                <div className="label">The sum of payment cannot exceed 100%.</div>
                            </div>
                            <div
                                className="payment-splits-action-buttons"
                            >
                                <div
                                    className="add-button"
                                    onClick={(e) => HandlePaymentsAddNew()}
                                >
                                    <div
                                        className="icon"
                                        dangerouslySetInnerHTML={{ __html: Icons.general.add_btn }}
                                    ></div>
                                    <div className="label">Add new payment milestone</div>
                                </div>
                            </div>
                        </div>



                    </div>
                    <Popup_Footer />
                </div>
            </form>
        </>
    )
}


const Payment_schedule = () => {

    const store = useSelector((store) => store)
    const dispatch = useDispatch()
    const { updateState } = new UserAction

    const navigate = useNavigate()

    const settingsHandler = new SettingsHandler()

    const [isLoading, setIsLoading] = useState(false)
    const [warningAlert, setWarningAlert] = useState(false)
    const [warningAlertType, setWarningAlertType] = useState('warning')
    const [apiFailedMessage, setApiFailedMessage] = useState("Error in Server")

    const [PaymentSchedule_original, setPaymentSchedule_original] = useState([])
    const [PaymentSchedule, setPaymentSchedule] = useState([])
    const [selected_paymentSchedule, setSelected_paymentSchedule] = useState({})
    const [selectedAction, setSelectedAction] = useState('')

    const { isAdmin, menu_permission } = useMenuAuth({ menu: 'settings--payment_schedule' })
    const location = useLocation()

    const [selected_view_settings_value, setSelected_view_settings_value] = useState('')
    const [has_view_access, setHas_view_access] = useState(false)
    const [has_add_access, setHas_add_access] = useState(false)
    const [has_edit_access, setHas_edit_access] = useState(false)
    const [has_delete_access, setHas_delete_access] = useState(false)

    useEffect(() => {

        if (isAdmin) {

            setHas_view_access(true)
            setHas_add_access(true)
            setHas_edit_access(true)
            setHas_delete_access(true)
        }

        else if (menu_permission) {

            if (
                !menu_permission._view
                &&
                (!menu_permission.selected_view_settings_value
                    || menu_permission.selected_view_settings_value == 'no_access')
            ) {
                navigate(`${ReverseProxy['proxyUrl']}/quick-menu`, { state: { from: location }, replace: true })
            }

            if (menu_permission._view == '0') navigate(`${ReverseProxy['proxyUrl']}/builder/projects`, { state: { from: location }, replace: true })

            setHas_view_access(menu_permission._view == '1')
            setHas_add_access(menu_permission._create == '1')
            setHas_edit_access(menu_permission._edit == '1')
            setHas_delete_access(menu_permission._delete == '1')

            setSelected_view_settings_value(menu_permission.selected_view_settings_value)

        }

    }, [store])

    const [ActionDropdown, setActionDropdown] = useState([
        {
            id: 'edit',
            label: 'Edit',
            icon: Icons.general.edit,

        },
        {
            id: 'delete',
            label: 'Delete',
            icon: Icons.general.delete,

        },
    ])


    const tabelHeader = [
        {
            id: 'name',
            label: 'Name',
            minWidth: 500
        },
        {
            id: 'created_at',
            label: 'Created',
            minWidth: 150,
            align: 'right',
            format: (value) => Utils.getLocalFullDateBYFormat(value),
        },
        {
            id: 'actions',
            label: 'Actions',
            minWidth: 50,
            align: 'right',
            format: (value) => value,
        },
    ];

    const getPaymentSchedules = async () => {

        let response = await settingsHandler.getPaymentSchedulesHandler()

        if (response && response.success) {

            setPaymentSchedule_original(response.data)
            setPaymentSchedule(createTabelData(tabelHeader, response.data))

            if (store && store.user.settings) {

                let settings = store.user.settings

                settings = { ...settings, payment_schedule: response.data }

                dispatch(updateState({
                    type: "SET_SETTINGS",
                    payload: { settings }
                }))

            }

        } else {
            setWarningAlert(true)
            setWarningAlertType('error')
            setApiFailedMessage(`Error in Payment Schedule`)
        }
    }


    const createTabelData = (header, items) => {

        if (!Object.keys(header).length || !Array.isArray(items)) return []

        items = items.map((itm, i) => {

            let item = { ...itm }

            header.forEach((head, i) => item[head.id] = itm[head.id])

            return item
        })

        return items

    }

    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const HandleAddTemplate = () => {
        if (!has_add_access) return
        navigate(`${window.location.pathname}/add-new`)
    }
    const HandleEditTemplate = (template) => {
        if (!has_edit_access) return
        setSelected_paymentSchedule(template)

        navigate(`${window.location.pathname}/edit/${template.id}`)
    }
    const HandleDeleteTemplate = async (template) => {
        if (!has_edit_access || !has_delete_access) return
        let response = await settingsHandler.deletePaymentScheduleHandler({
            id: template.id
        })

        if (response && response.success) {

            getPaymentSchedules()

        } else {
            setWarningAlert(true)
            setWarningAlertType('error')
            setApiFailedMessage(`Error in Payment Schedule`)
        }

    }
    const CloseAddTemplate = (type) => {

        let back_path = String(window.location.pathname).split('/')
        back_path.pop()
        if (type == 'edit') back_path.pop()

        back_path = back_path.join('/')

        navigate(`${back_path}`)
    }

    const HanelCellAction = (id, row) => {

        console.log(selectedAction, 'selectedAction');
        if (id == "name") HandleEditTemplate(row)
        if (id == "actions") {
            setSelectedAction(row.id)
            HandleActionDisable(row.id)
        }
    }

    const HandleActionDisable = (id) => {

        let input = document.getElementById(`payment_schedule-tabel-action-btn-${id}`)
        let dropdown_items = document.getElementById(`payment_schedule-tabel-action-dropdown-${id}`)

        const Event = (e) => {
            let path = e.path || (e.composedPath && e.composedPath());

            if (Array.isArray(path) && !path.includes(dropdown_items) && !path.includes(input)) {
                setSelectedAction('')
                window.removeEventListener('click', Event)
            }
        }

        window.addEventListener('click', Event)
    }

    useEffect(() => {
        getPaymentSchedules()
    }, [])


    return (
        <>
            <Routes >
                <Route exact path='/add-new' element={
                    <Add_Template props={{
                        type: "create",
                        heading: "Create new payment schedule template",
                        callback: getPaymentSchedules,
                        close: CloseAddTemplate
                    }} />}></Route>
                <Route exact path='/edit/:templateId' element={
                    <Add_Template props={{
                        type: "edit",
                        heading: "Edit payment schedule template",
                        callback: getPaymentSchedules,
                        close: CloseAddTemplate,
                        detials: selected_paymentSchedule
                    }} />}></Route>
            </Routes>

            <div className="settings-panel-content">
                <div className="panel-content-main">

                    <div className="panel-content-header">
                        <div className="title">Payment schedules</div>
                        <div className="buttons">
                            {has_add_access ?
                                <div
                                    className="button add-button"
                                    onClick={(e) => HandleAddTemplate()}
                                >
                                    <div className="icon" dangerouslySetInnerHTML={{ __html: Icons.general.add_btn }}></div>
                                    <div className="label">Create a Template</div>
                                </div>
                                : ''}
                        </div>
                    </div>
                    <div className="panel-content-sections panel-content-tabel-section">
                        <Paper style={{ width: '100%', height: '100%', boxShadow: 'none', overflow: 'hidden' }}>
                            <TableContainer style={{ height: '93%', boxShadow: 'none !important', }}>
                                <Table stickyHeader>
                                    <TableHead
                                        className='tabel-header'
                                    >
                                        <TableRow>
                                            {tabelHeader.map((column) => (
                                                <TableCell
                                                    key={column.id}
                                                    align={column.align}
                                                    style={{ minWidth: column.minWidth }}
                                                    className='tabel-header-items'
                                                >
                                                    {column.label}
                                                </TableCell>
                                            ))}
                                        </TableRow>
                                    </TableHead>
                                    <TableBody className='tabel-body'>
                                        {PaymentSchedule
                                            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                            .map((row) => {
                                                return (
                                                    <TableRow hover role="checkbox" tabIndex={-1} key={row.id}>
                                                        {tabelHeader.map((column) => {
                                                            const value = row[column.id];
                                                            return (
                                                                <TableCell
                                                                    key={column.id}
                                                                    align={column.align}
                                                                    onClick={(e) => {
                                                                        if (column.id != 'actions') HanelCellAction(column.id, row)
                                                                    }}
                                                                >
                                                                    {
                                                                        column.id == 'name' ?
                                                                            <div
                                                                                className="tabel-name"
                                                                            >
                                                                                {column.format ? column.format(value) : value}
                                                                            </div>

                                                                            : column.id == 'actions' ?

                                                                                <div
                                                                                    className="tabel-action-main"
                                                                                >
                                                                                    <div
                                                                                        className="action-icon terms_conditions-tabel-action-btn"
                                                                                        id={`payment_schedule-tabel-action-btn-${row.id}`}
                                                                                        dangerouslySetInnerHTML={{ __html: Icons.general.dotted_action }}
                                                                                        onClick={(e) => HanelCellAction(column.id, row)}
                                                                                    ></div>
                                                                                    <div
                                                                                        className={`action-dropdown terms_conditions-tabel-action-dropdown ${selectedAction == row.id ? 'action-dropdown-active' : ''}`}
                                                                                        id={`payment_schedule-tabel-action-dropdown${row.id}`}
                                                                                    >
                                                                                        {ActionDropdown.map(option => (


                                                                                            !has_edit_access ? '' :
                                                                                                option.id == 'delete' && !has_delete_access ? '' :

                                                                                                    <div
                                                                                                        className="action-dropdown-item"
                                                                                                        key={`proposals-action${option.id}`}
                                                                                                        onClick={(e) => {
                                                                                                            setSelectedAction('')
                                                                                                            if (option.id == 'edit') HandleEditTemplate(row)
                                                                                                            else if (option.id == 'delete') HandleDeleteTemplate(row)

                                                                                                        }}
                                                                                                    >
                                                                                                        <div
                                                                                                            className="icon"
                                                                                                            dangerouslySetInnerHTML={{ __html: option.icon }}
                                                                                                        ></div>
                                                                                                        <div className="label">{option.label}</div>
                                                                                                    </div>
                                                                                        ))}
                                                                                    </div>
                                                                                </div>
                                                                                : column.format ? column.format(value) : value
                                                                    }
                                                                </TableCell>
                                                            );
                                                        })}
                                                    </TableRow>
                                                );
                                            })}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            <TablePagination
                                style={{ height: '7%', overflow: 'hidden' }}
                                rowsPerPageOptions={[10, 25, 100]}
                                component="div"
                                count={PaymentSchedule.length || 0}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                            />
                        </Paper>
                    </div>
                </div>

            </div>
        </>
    )
}

export default Payment_schedule;
