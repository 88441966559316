

import React, { useEffect, useState, useRef, useCallback, useMemo, StrictMode } from 'react'
import { Route, Routes, BrowserRouter, Link, useLocation, Navigate, Outlet, useNavigate, useParams } from 'react-router-dom';
import store from '../../redux/store'
import StoreHandler from '../../redux/StoreHandler'
import { useSelector, useDispatch } from 'react-redux'
import UserAction from '../../redux/action/userAction'

import ReverseProxy from '../../config/reverseProxy'
import StockItemsHandler from '../../Handlers/StockItems/StockItems';
import Icons from '../../assets/Icons'
import Images from '../../assets/Images'
import { CostInput, TextInput, SelectInput, SearcBarInput, RadioInput } from '../../components/Inputs'
import Utils from '../../utils'
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

import AlertPopup from '../../components/AlertPopup'
import Loading from '../../components/Loading'
import NoDataFound from '../../components/NoDataFound';
import SystemToastPopup from '../../components/SystemToastPopup'

import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableFooter from '@mui/material/TableFooter';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';

import SideFilters from '../../components/SideFilters';

import SmarkTopCards from '../../components/SmarkTopCards'

import { useAuth, useMenuAuth } from '../../hooks/useAuth'
const BudgetReport = () => {

    const store = useSelector((store) => store)
    const dispatch = useDispatch()
    const { updateState } = new UserAction

    const { projectId } = useParams();

    const navigate = useNavigate()

    const stockItemsHandler = new StockItemsHandler()

    const storeHandler = new StoreHandler({
        dataStack: ['company'],
        store: store,
        dispatch: dispatch,
        updateState: updateState,
    })

    const LoadStoreData = async () => {
        setIsLoading(true)
        let resolved = await storeHandler.init()
        setIsLoading(false)
    }

    const { isAdmin, menu_permission } = useMenuAuth({ menu: 'finance--finance_budget', feature_key: 'budget' })
    const location = useLocation()

    const [selected_view_settings_value, setSelected_view_settings_value] = useState('')
    const [has_view_access, setHas_view_access] = useState(false)
    const [has_add_access, setHas_add_access] = useState(false)
    const [has_edit_access, setHas_edit_access] = useState(false)
    const [has_delete_access, setHas_delete_access] = useState(false)

    useEffect(() => {

        if (isAdmin) {

            setHas_view_access(true)
            setHas_add_access(true)
            setHas_edit_access(true)
            setHas_delete_access(true)
        }

        else if (menu_permission) {

            if (
                !menu_permission._view
                &&
                (!menu_permission.selected_view_settings_value
                    || menu_permission.selected_view_settings_value == 'no_access')
            ) {
                navigate(`${ReverseProxy['proxyUrl']}/quick-menu`, { state: { from: location }, replace: true })
            }

            if (menu_permission._view == '0') navigate(`${ReverseProxy['proxyUrl']}/builder/projects`, { state: { from: location }, replace: true })

            setHas_view_access(menu_permission._view == '1')
            setHas_add_access(menu_permission._create == '1')
            setHas_edit_access(menu_permission._edit == '1')
            setHas_delete_access(menu_permission._delete == '1')

            setSelected_view_settings_value(menu_permission.selected_view_settings_value)

        }

    }, [store])

    const [isLoading, setIsLoading] = useState(false)
    const [warningAlert, setWarningAlert] = useState(false)
    const [warningAlertType, setWarningAlertType] = useState('warning')
    const [apiFailedMessage, setApiFailedMessage] = useState("Error in Server")

    const [StockItems, setStockItems] = useState([])
    const [LabourItems, setLabourItems] = useState([])
    const [MaterialItems, setMaterialItems] = useState([])
    const [MachinesItems, setMachinesItems] = useState([])
    const [selected_termsCondition, setSelected_termsCondition] = useState({})
    const [selectedAction, setSelectedAction] = useState('')


    const [ActionDropdown, setActionDropdown] = useState([
        {
            id: 'edit',
            label: 'Issue',
            icon: Icons.general.edit,
            click: (row) => {
                setSelectedAction('')
                HandleIssueItem(row)
            }
        },

    ])
    const [currency_type, setCurrency_type] = useState('₹')

    const [LabourTabelHeader, setLabourTabelHeader] = useState([
        {
            id: 'select',
            label: 'Select',
            minWidth: 30,
            disable: false,
        },

        {
            id: 'name',
            label: 'Labour Name',
            minWidth: 100,

        },

        {
            id: 'unit_name',
            label: 'Unit',

        },
        {
            id: 'unitrate',
            label: 'Unitrate',
            format: (value) => `${currency_type}${parseFloat(value || 0).toFixed(2)}`

        },

        {
            id: 'estimated_quantity',
            label: 'Est. Qty',
        },
        {
            id: 'estimated_amount',
            label: 'Est. price',
            format: (value) => `${currency_type}${parseFloat(value || 0).toFixed(2)}`
        },
        {
            id: 'to_pay',
            label: 'Expense Amount',
            format: (value) => `${currency_type}${parseFloat(value || 0).toFixed(2)}`
        },
        {
            id: 'paid_amount',
            label: 'Expense Paid',
            format: (value) => `${currency_type}${parseFloat(value || 0).toFixed(2)}`
        },

        {
            id: 'total_attendance',
            label: 'Total Days',
        },

    ])
    const [MaterialTabelHeader, setMaterialTabelHeader] = useState([
        {
            id: 'select',
            label: 'Select',
            minWidth: 30,
            disable: false,
        },

        {
            id: 'name',
            label: 'Item',
            minWidth: 100,

        },
        {
            id: 'unit_name',
            label: 'Unit',

        },
        {
            id: 'unitrate',
            label: 'SOR Unitrate',
            format: (value) => `${currency_type}${parseFloat(value || 0).toFixed(2)}`

        },

        {
            id: 'estimated_quantity',
            label: 'Est. Qty',
        },
        {
            id: 'estimated_amount',
            label: 'Est. price',
            format: (value) => `${currency_type}${parseFloat(value || 0).toFixed(2)}`
        },
        {
            id: 'total_stock_issues_expenses',
            label: 'Expense Amount',
            format: (value) => `${currency_type}${parseFloat(value || 0).toFixed(2)}`
        },
        {
            id: 'total_stock_grn_expenses_paid',
            label: 'Expense Paid',
            format: (value) => `${currency_type}${parseFloat(value || 0).toFixed(2)}`
        },

        {
            id: 'total_stock_quantity',
            label: 'Total Stock Qty',
        },

        {
            id: 'issued_quantity',
            label: 'Issued Qty',
        },
        {
            id: 'used_quantity',
            label: 'Used Qty',
        },
        {
            id: 'balace_quantity',
            label: 'Balance Qty',
        },



    ])
    const [MachinesTabelHeader, setMachinesTabelHeader] = useState([
        {
            id: 'select',
            label: 'Select',
            minWidth: 30,
            disable: false,
        },

        {
            id: 'name',
            label: 'Machine Name',
            minWidth: 100,

        },
        {
            id: 'unit_name',
            label: 'Unit',

        },
        {
            id: 'unitrate',
            label: 'SOR Unitrate',
            format: (value) => `${currency_type}${parseFloat(value || 0).toFixed(2)}`

        },

        {
            id: 'estimated_quantity',
            label: 'Est. Qty',
        },
        {
            id: 'estimated_amount',
            label: 'Est. price',
            format: (value) => `${currency_type}${parseFloat(value || 0).toFixed(2)}`
        },
        {
            id: 'total_stock_issues_expenses',
            label: 'Expense Amount',
            format: (value) => `${currency_type}${parseFloat(value || 0).toFixed(2)}`
        },

        {
            id: 'total_stock_quantity',
            label: 'Total Stock Qty',
        },

        {
            id: 'issued_quantity',
            label: 'Issued Qty',
        },
        {
            id: 'used_quantity',
            label: 'Used Qty',
        },
        {
            id: 'balace_quantity',
            label: 'Balance Qty',
        },



    ])

    const [selectedResultSection, setSelectedResultSection] = useState('labour')

    const HandleResultSection = (section) => {
        setSelectedResultSection(section)
    }

    const getClientInvoices = async () => {

        let filter = {
            projectId: projectId
        }

        let response = await stockItemsHandler.getActual_vs_EstimatedHandler(filter)

        if (response && response.success) {

            let { materials, labours, machines } = response.data

            setLabourItems(labours || [])
            setMaterialItems(materials || [])
            setMachinesItems(machines || [])

            HandleSmartCardSetup(response.data)


        } else {
            setWarningAlert(true)
            setWarningAlertType('error')
            setApiFailedMessage(`Error in Budget Report`)
        }
    }

    const HandleIssueItem = (template) => {

        setSelected_termsCondition(template)

        // navigate(`${window.location.pathname}/${template.billID}/edit`)
    }

    const HanelCellAction = (id, row) => {

        if (id == "actions") {
            setSelectedAction(row.id)
            HandleActionDisable(row.id)
        }
    }

    const HandleActionDisable = (id) => {

        let input = document.getElementById(`proposals-tabel-action-btn-${id}`)
        let dropdown_items = document.getElementById(`proposals-tabel-action-dropdown-${id}`)

        const Event = (e) => {
            let path = e.path || (e.composedPath && e.composedPath());

            if (Array.isArray(path) && !path.includes(dropdown_items) && !path.includes(input)) {
                setSelectedAction('')
                window.removeEventListener('click', Event)
            }
        }

        window.addEventListener('click', Event)
    }

    useEffect(() => {
        LoadStoreData()
        getClientInvoices()
    }, [])

    useEffect(() => {

        if (store && store.user) {

            let { company } = store.user

            if (company) {

                let { currency_setting, timezone, temperature_setting } = company

                if (currency_setting) setCurrency_type(currency_setting)
            }

        }

    }, [store])

    const [ShowSideFilter, setShowSideFilter] = useState(false)
    const [SideFilterSettings, setSideFilterSettings] = useState([
        {
            id: 'selectshow-filter',
            type: 'multiselect',
            value: false,
            placeholder: '',

            selectoptions: [
                {
                    id: 'select-filteroption-selectshow-all',
                    type: 'checkbox',
                    key: `all`,
                    value: true,
                    placeholder: '',
                    classNames: [],
                    isIconLeft: false,
                    isIcon: false,
                    icon: '',
                    isLabel: true,
                    label: 'Show select box',
                },
            ],

            callback: (e) => { },
            classNames: [],
            isIconLeft: true,
            isIcon: true,
            icon: Icons.general.multi_select,
            isLabel: true,
            label: 'Show Selection',
        },

        {
            id: 'qtyfilterby-filter',
            type: 'multiselect',
            value: true,
            placeholder: '',

            selectoptions: [
                {
                    id: 'select-filteroption-qtyfilterby-all',
                    type: 'checkbox',
                    key: `all`,
                    value: true,
                    placeholder: '',
                    classNames: [],
                    isIconLeft: false,
                    isIcon: false,
                    icon: '',
                    isLabel: true,
                    label: 'All',
                },
                {
                    id: 'select-filteroption-qtyfilterby-over_run',
                    type: 'checkbox',
                    key: `over_run`,
                    category: 'balace_quantity',
                    value: true,
                    placeholder: '',
                    classNames: [],
                    isIconLeft: false,
                    isIcon: false,
                    icon: '',
                    isLabel: true,
                    label: 'Over-run',
                },

                {
                    id: 'select-filteroption-qtyfilterby-outoff_stock',
                    type: 'checkbox',
                    key: `outoff_stock`,
                    category: 'balace_quantity',
                    value: true,
                    placeholder: '',
                    classNames: [],
                    isIconLeft: false,
                    isIcon: false,
                    icon: '',
                    isLabel: true,
                    label: 'Out-off Stock',
                },

                {
                    id: 'select-filteroption-qtyfilterby-critical_qty',
                    type: 'checkbox',
                    key: `critical_qty`,
                    category: 'balace_quantity',
                    value: true,
                    placeholder: '',
                    classNames: [],
                    isIconLeft: false,
                    isIcon: false,
                    icon: '',
                    isLabel: true,
                    label: 'Critical (Qty<10)',
                },

            ],

            callback: (e) => { },
            classNames: [],
            isIconLeft: true,
            isIcon: true,
            icon: Icons.general.filter_by,
            isLabel: true,
            label: 'Filter by Quandity',
        },

        {
            id: 'showtable-filter',
            type: 'multiselect',
            value: true,
            placeholder: '',

            selectoptions: [
                {
                    id: 'select-filteroption-showtable-all',
                    type: 'checkbox',
                    key: `all`,
                    value: true,
                    placeholder: '',
                    classNames: [],
                    isIconLeft: false,
                    isIcon: false,
                    icon: '',
                    isLabel: true,
                    label: 'All',
                },
                {
                    id: 'select-filteroption-showtable-unit_name',
                    type: 'checkbox',
                    value: true,
                    key: `unit_name`,
                    placeholder: '',
                    classNames: [],
                    isIconLeft: false,
                    isIcon: false,
                    icon: '',
                    isLabel: true,
                    label: 'Unit',
                },
                {
                    id: 'select-filteroption-showtable-unitrate',
                    type: 'checkbox',
                    value: true,
                    key: `unitrate`,
                    placeholder: '',
                    classNames: [],
                    isIconLeft: false,
                    isIcon: false,
                    icon: '',
                    isLabel: true,
                    label: 'SOR Unitrate',
                },
                {
                    id: 'select-filteroption-showtable-estimated_quantity',
                    type: 'checkbox',
                    value: true,
                    key: `estimated_quantity`,
                    placeholder: '',
                    classNames: [],
                    isIconLeft: false,
                    isIcon: false,
                    icon: '',
                    isLabel: true,
                    label: 'Estimated Qty',
                },
                {
                    id: 'select-filteroption-showtable-estimated_amount',
                    type: 'checkbox',
                    value: true,
                    key: `estimated_amount`,
                    placeholder: '',
                    classNames: [],
                    isIconLeft: false,
                    isIcon: false,
                    icon: '',
                    isLabel: true,
                    label: 'Estimated Amount',
                },
                {
                    id: 'select-filteroption-showtable-total_stock_issues_expenses',
                    type: 'checkbox',
                    value: true,
                    key: `total_stock_issues_expenses`,
                    placeholder: '',
                    classNames: [],
                    isIconLeft: false,
                    isIcon: false,
                    icon: '',
                    isLabel: true,
                    label: 'Expense Amount',
                },
                {
                    id: 'select-filteroption-showtable-total_stock_quantity',
                    type: 'checkbox',
                    value: true,
                    key: `total_stock_quantity`,
                    placeholder: '',
                    classNames: [],
                    isIconLeft: false,
                    isIcon: false,
                    icon: '',
                    isLabel: true,
                    label: 'Total Stock Qty',
                },
                {
                    id: 'select-filteroption-showtable-issued_quantity',
                    type: 'checkbox',
                    value: true,
                    key: `issued_quantity`,
                    placeholder: '',
                    classNames: [],
                    isIconLeft: false,
                    isIcon: false,
                    icon: '',
                    isLabel: true,
                    label: 'Issued Qty',
                },
                {
                    id: 'select-filteroption-showtable-used_quantity',
                    type: 'checkbox',
                    value: true,
                    key: `used_quantity`,
                    placeholder: '',
                    classNames: [],
                    isIconLeft: false,
                    isIcon: false,
                    icon: '',
                    isLabel: true,
                    label: 'Used Qty',
                },
                {
                    id: 'select-filteroption-showtable-balace_quantity',
                    type: 'checkbox',
                    value: true,
                    key: `balace_quantity`,
                    placeholder: '',
                    classNames: [],
                    isIconLeft: false,
                    isIcon: false,
                    icon: '',
                    isLabel: true,
                    label: 'Balance Qty',
                },

            ],

            callback: (e) => { },
            classNames: [],
            isIconLeft: true,
            isIcon: true,
            icon: Icons.general.show_hide,
            isLabel: true,
            label: 'Show Tables',
        },

    ])

    const HandleSideFilterClose = () => {
        setShowSideFilter(false)
    }
    const HandleSideFilterUpdateCallback = (filters) => {

        let _StockItems = []

        if (selectedResultSection == 'labour') _StockItems = [...LabourItems]
        else if (selectedResultSection == 'material') _StockItems = [...MaterialItems]
        else if (selectedResultSection == 'machines') _StockItems = [...MachinesItems]

        filters.forEach((filter, fidx) => {

            if (filter.type == 'multiselect') {

                if (filter.id == 'selectshow-filter') {

                    filter.selectoptions.forEach((option, oidx) => {

                        if (option.key == 'all') {

                            let _tabelHeader = []

                            if (selectedResultSection == 'labour') _tabelHeader = [...LabourTabelHeader]
                            else if (selectedResultSection == 'material') _tabelHeader = [...MaterialTabelHeader]
                            else if (selectedResultSection == 'machines') _tabelHeader = [...MachinesTabelHeader]

                            _tabelHeader[0].disable = !option.value


                            if (selectedResultSection == 'labour') setLabourTabelHeader(_tabelHeader)
                            else if (selectedResultSection == 'material') setMaterialTabelHeader(_tabelHeader)
                            else if (selectedResultSection == 'machines') setMachinesTabelHeader(_tabelHeader)

                        }

                    })
                }

                else if (filter.id == 'qtyfilterby-filter') {

                    filter.selectoptions.forEach((option, oidx) => {

                        if (option.key == 'all') {
                            _StockItems = _StockItems.map(item => {

                                item.disable = !option.value

                                return item
                            })
                        }
                        else {
                            _StockItems = _StockItems.map(item => {

                                if (option.key == 'over_run') {

                                    if (
                                        (parseFloat(item.estimated_quantity) < parseFloat(item.issued_quantity))
                                        || (parseFloat(item.estimated_quantity) < parseFloat(item.used_quantity))
                                    ) item.disable = !option.value
                                }
                                else if (option.key == 'outoff_stock') {
                                    if (parseFloat(item.balace_quantity) <= 0) item.disable = !option.value
                                }
                                else if (option.key == 'critical_qty') {
                                    if (parseFloat(item.balace_quantity) <= 10) item.disable = !option.value
                                }



                                // if (item[option.category] == option.key) item.disable = !option.value

                                return item
                            })


                        }

                    })
                }

                else if (filter.id == 'showtable-filter') {

                    filter.selectoptions.forEach((option, oidx) => {

                        if (option.key == 'all') {

                            let _tabelHeader = []

                            if (selectedResultSection == 'labour') _tabelHeader = [...LabourTabelHeader]
                            else if (selectedResultSection == 'material') _tabelHeader = [...MaterialTabelHeader]
                            else if (selectedResultSection == 'machines') _tabelHeader = [...MachinesTabelHeader]

                            _tabelHeader = _tabelHeader.map((th, i) => {
                                if (i > 0) th.disable = false
                                return th
                            })

                            if (selectedResultSection == 'labour') setLabourTabelHeader(_tabelHeader)
                            else if (selectedResultSection == 'material') setMaterialTabelHeader(_tabelHeader)
                            else if (selectedResultSection == 'machines') setMachinesTabelHeader(_tabelHeader)
                        }
                        else {
                            let _tabelHeader = []

                            if (selectedResultSection == 'labour') _tabelHeader = [...LabourTabelHeader]
                            else if (selectedResultSection == 'material') _tabelHeader = [...MaterialTabelHeader]
                            else if (selectedResultSection == 'machines') _tabelHeader = [...MachinesTabelHeader]

                            let head_idx = _tabelHeader.findIndex(h => h.id == option.key)

                            if (head_idx != -1) {
                                _tabelHeader[head_idx].disable = !option.value

                                if (selectedResultSection == 'labour') setLabourTabelHeader(_tabelHeader)
                                else if (selectedResultSection == 'material') setMaterialTabelHeader(_tabelHeader)
                                else if (selectedResultSection == 'machines') setMachinesTabelHeader(_tabelHeader)

                            }
                        }

                    })
                }
            }

        })

        if (selectedResultSection == 'labour') setLabourItems(_StockItems)
        else if (selectedResultSection == 'material') setMaterialItems(_StockItems)
        else if (selectedResultSection == 'machines') setMachinesItems(_StockItems)

        setSideFilterSettings(filters)
        HandleSideFilterClose()
    }


    const [tableSelectAll, setTableSelectAll] = useState(false)
    const [Filters, setFilters] = useState({
        left: [

            {
                id: "estimate-search",
                value: '',
                isSearchBar: true,
                type: "input",
                isDropDownContainer: false,
                placeholder: "Search by name",
                dropDownOptions: [],
                callback: (val) => { },

                isIcon: true,
                icon: Icons.general.search,
                isLabel: false,
                label: "",
                isIconLeft: false
            },



        ],
        right: [

            {
                id: "estimate-morefilter",
                type: "icon",
                isSelectToggle: true,
                value: false,
                placeholder: "",
                callback: (val) => HandleFiltersEvent(val, 'estimate-morefilter'),
                isIcon: true,
                icon: Icons.general.filter_funnel,
                isLabel: true,
                label: "Filters",
                isIconLeft: true
            },
        ]
    })

    const HandleSelectAllEvent = (value) => {
        setTableSelectAll(value)

        let _StockItems = []

        if (selectedResultSection == 'labour') _StockItems = [...LabourItems]
        else if (selectedResultSection == 'material') _StockItems = [...MaterialItems]
        else if (selectedResultSection == 'machines') _StockItems = [...MachinesItems]



        const selectItem = (items, value) => {

            items = items.map(item => {

                item.selected = value
                return item
            })

            return items
        }

        _StockItems = selectItem(_StockItems, value)

        if (selectedResultSection == 'labour') setLabourItems(_StockItems)
        else if (selectedResultSection == 'material') setMaterialItems(_StockItems)
        else if (selectedResultSection == 'machines') setMachinesItems(_StockItems)
    }

    const HandleSelectEvent = (id, value) => {

        let _StockItems = []

        if (selectedResultSection == 'labour') _StockItems = [...LabourItems]
        else if (selectedResultSection == 'material') _StockItems = [...MaterialItems]
        else if (selectedResultSection == 'machines') _StockItems = [...MachinesItems]

        _StockItems = _StockItems.map(item => {

            if (item.id == id) item.selected = value
            return item
        })

        if (selectedResultSection == 'labour') setLabourItems(_StockItems)
        else if (selectedResultSection == 'material') setMaterialItems(_StockItems)
        else if (selectedResultSection == 'machines') setMachinesItems(_StockItems)
    }

    const HandleFiltersEvent = async (value, filter_id) => {

        if (!filter_id) return

        if (filter_id == 'estimate-search') {

            let filters = { ...Filters }

            filters.left[0].value = value

            let _StockItems = []

            if (selectedResultSection == 'labour') _StockItems = [...LabourItems]
            else if (selectedResultSection == 'material') _StockItems = [...MaterialItems]
            else if (selectedResultSection == 'machines') _StockItems = [...MachinesItems]

            let search_items = (items, value, column = 'name') => {

                let search_item = (items, value, column = 'name') => {

                    items = items.map(item => {

                        let is_item_match = String(item[column]).toLocaleLowerCase().includes(String(value).toLocaleLowerCase())

                        if (is_item_match) item.disable = false
                        else item.disable = true

                        return item

                    })

                    return items

                }

                items = search_item(items, value, column)
                return items
            }

            _StockItems = search_items(_StockItems, value, 'name')

            if (selectedResultSection == 'labour') setLabourItems(_StockItems)
            else if (selectedResultSection == 'material') setMaterialItems(_StockItems)
            else if (selectedResultSection == 'machines') setMachinesItems(_StockItems)

            setFilters(filters)

        }
        else if (filter_id == 'estimate-morefilter') {
            setShowSideFilter(true)
        }


    }

    const isLeftFilter = Array.isArray(Filters.left) && Filters.left.length > 0
    const isRightFilter = Array.isArray(Filters.right) && Filters.right.length > 0

    const Icon_Filter = ({ props }) => {
        const {
            id,
            isSearchBar,
            type,
            value,
            isSelectToggle,
            isDropDownContainer,
            dropDownOptions,
            placeholder,
            callback,
            isIcon,
            icon,
            isLabel,
            label,
            isIconLeft,
        } = props

        const [iconStatus, setIconStatus] = useState(value != undefined ? value : false)

        const HandleFilterIconInput = (e) => {


            HandleFiltersEvent(!iconStatus, id)
            setIconStatus(!iconStatus)
        }

        return (
            <>
                {isIcon && type == 'icon' ?
                    <div
                        className="filter-item group-expand-filter"
                        onClick={(e) => HandleFilterIconInput(e)}
                        key={`filter-item-${id}`}
                    >
                        <div
                            className="icon"
                            dangerouslySetInnerHTML={{ __html: id.includes('expand') ? iconStatus ? Icons.general.collapse : Icons.general.expand : icon }}
                        ></div>
                        {isLabel ? <div className="label">{id.includes('expand') ? iconStatus ? 'Collapse all' : 'Expand all' : label}</div> : ''}
                    </div>

                    : null}
            </>
        )
    }
    const Button_Filter = ({ props }) => {
        const {
            id,
            isSearchBar,
            type,
            isDropDownContainer,
            dropDownOptions,
            callback,
            isIcon,
            icon,
            isLabel,
            label,
            isIconLeft,
        } = props
        const [selectFilterOpts, setSelectFilterOpts] = useState([])

        const [dropdownStatus, setDropDownStatus] = useState(false)

        const HandleClick = (e) => {
            setDropDownStatus(!dropdownStatus)
        }

        let HandleSubItemChange = (value, opt, i) => {

            let _filters = [...selectFilterOpts]

            if (_filters[i].id == 'opt-select-all') {
                _filters = _filters.map(f => {
                    f.value = value

                    return f
                })
            } else _filters[i].value = value

            setSelectFilterOpts(_filters)

            HandleFiltersEvent(opt, id)
        }

        useEffect(() => {

            let items_parent = document.querySelector(`#${id}`)

            window.addEventListener('click', (e) => {

                let path = e.path || (e.composedPath && e.composedPath());

                if (Array.isArray(path) && !path.includes(items_parent))
                    setDropDownStatus(false)
            })
            setSelectFilterOpts(dropDownOptions)
        }, [])


        return (
            <div className='filter-item dropdown-select-filter' id={id} key={`filter-item-${id}`}>
                <div
                    className="button"
                    onClick={(e) => HandleClick(e)}
                >
                    {isIcon && isIconLeft ?
                        <span
                            className={`icon ${dropdownStatus ? 'icon-active' : ''}`}
                            dangerouslySetInnerHTML={{ __html: icon }}
                        ></span>
                        : null}

                    {isLabel ? <span class='label'>{label}</span> : null}

                    {isIcon && !isIconLeft ?
                        <span
                            className={`icon ${dropdownStatus ? 'icon-active' : ''}`}
                            dangerouslySetInnerHTML={{ __html: icon }}
                        ></span>
                        : null}
                </div>

                {isDropDownContainer ?

                    <div className={`dropdown-select-items ${dropdownStatus ? 'dropdown-select-items-active' : ''}`}>

                        {selectFilterOpts.map((opt, i) => (

                            opt.type == 'label' ?
                                <div className="dropdown-select-title">{opt.label}</div>
                                :
                                <div className="dropdown-select-itm">
                                    <RadioInput props={{
                                        id: `filters-item-select-${opt.id}`,
                                        value: opt.value,
                                        isIcon: false,
                                        icon: "",
                                        inputType: "checkbox",
                                        name: "table-default-radio",
                                        setValue: (value) => HandleSubItemChange(value, opt, i)
                                    }} />
                                    <label className='label' htmlFor={`filters-item-select-${opt.id}`}>{opt.label}</label>
                                </div>

                        ))}

                    </div>

                    : null}
            </div>

        )
    }

    const RenderItemsResult = (tabelHeader, resultItems) => {

        return (
            <>
                {resultItems.filter(p => !p.disable).length ?
                    <Paper style={{ width: '100%', height: '100%', boxShadow: 'none', overflow: 'hidden' }}>
                        <TableContainer style={{ height: '93%', boxShadow: 'none !important', }}>
                            <Table stickyHeader>
                                <TableHead
                                    className='tabel-header'
                                >
                                    <TableRow>
                                        {tabelHeader.filter(th => !th.disable).map((column) => (
                                            <TableCell
                                                key={column.id}
                                                align={column.align}
                                                style={{ minWidth: column.minWidth }}
                                                className='tabel-header-items'
                                            >
                                                {column.id == 'select' ?

                                                    <RadioInput props={{
                                                        value: tableSelectAll || false,
                                                        isIcon: false,
                                                        icon: "",
                                                        inputType: "checkbox",
                                                        name: "table-default-radio",
                                                        setValue: (value) => HandleSelectAllEvent(value)
                                                    }} />
                                                    :
                                                    column.label}
                                            </TableCell>
                                        ))}
                                    </TableRow>
                                </TableHead>
                                <TableBody
                                    className='tabel-body'
                                >
                                    {resultItems.filter(p => !p.disable)
                                        .map((row) => {
                                            return (
                                                <TableRow hover role="checkbox" tabIndex={-1} key={row.id}>
                                                    {tabelHeader.filter(th => !th.disable).map((column) => {
                                                        const value = row[column.id];
                                                        return (
                                                            column.id == 'select' ?


                                                                <TableCell
                                                                    key={column.id}
                                                                    align={column.align}
                                                                    className='estimate-table-row-item'
                                                                // 
                                                                >
                                                                    <RadioInput props={{
                                                                        value: row.selected || false,
                                                                        isIcon: false,
                                                                        icon: "",
                                                                        inputType: "checkbox",
                                                                        name: "table-default-radio",
                                                                        setValue: (value) => HandleSelectEvent(row.id, value)
                                                                    }} />

                                                                </TableCell>

                                                                :
                                                                <TableCell
                                                                    key={column.id}
                                                                    align={column.align}
                                                                    onClick={(e) => {
                                                                        if (column.id != 'actions') HanelCellAction(column.id, row)
                                                                    }}
                                                                >
                                                                    {
                                                                        column.id == 'billID' ?
                                                                            <div
                                                                                className="tabel-name"
                                                                            >
                                                                                {column.format ? column.format(value) : value}
                                                                            </div>

                                                                            : column.id == 'actions' ?

                                                                                <div
                                                                                    className="tabel-action-main"
                                                                                >
                                                                                    <div
                                                                                        className="action-icon proposals-tabel-action-btn"
                                                                                        id={`proposals-tabel-action-btn-${row.id}`}
                                                                                        dangerouslySetInnerHTML={{ __html: Icons.general.dotted_action }}
                                                                                        onClick={(e) => HanelCellAction(column.id, row)}
                                                                                    ></div>
                                                                                    <div
                                                                                        className={`action-dropdown proposals-tabel-action-dropdown ${selectedAction == row.id ? 'action-dropdown-active' : ''}`}
                                                                                        id={`proposals-tabel-action-dropdown${row.id}`}
                                                                                    >
                                                                                        {ActionDropdown.map(option => (
                                                                                            <div
                                                                                                className="action-dropdown-item"
                                                                                                key={`proposals-action${option.id}`}
                                                                                                onClick={(e) => option.click(row)}
                                                                                            >
                                                                                                <div
                                                                                                    className="icon"
                                                                                                    dangerouslySetInnerHTML={{ __html: option.icon }}
                                                                                                ></div>
                                                                                                <div className="label">{option.label}</div>
                                                                                            </div>
                                                                                        ))}
                                                                                    </div>
                                                                                </div>
                                                                                : column.format ? column.format(value) : value
                                                                    }
                                                                </TableCell>
                                                        );
                                                    })}
                                                </TableRow>
                                            );
                                        })}
                                </TableBody>
                            </Table>
                        </TableContainer>

                    </Paper>
                    : <NoDataFound label={'No Items Found'} />}
            </>
        )

    }


    const [SmarkCard_Items, setSmarkCard_Items] = useState([])

    const HandleSmartCardClick = (id) => { }

    const HandleSmartCardSetup = (data) => {

        let { materials, labours, machines } = data

        let items = {
            labour_estimated_amount: labours.reduce((prev, itm) => prev + parseFloat(itm.estimated_amount), 0),
            labour_expense_paid: labours.reduce((prev, itm) => prev + parseFloat(itm.paid_amount), 0),
            labour_expense_due: labours.reduce((prev, itm) => {
                let due_amount = (parseFloat(itm.to_pay || 0) - parseFloat(itm.paid_amount || 0))
                return prev + (due_amount > 0 ? due_amount : 0)
            }, 0),

            material_estimated_amount: materials.reduce((prev, itm) => prev + parseFloat(itm.estimated_amount), 0),
            material_expense_paid: materials.reduce((prev, itm) => prev + parseFloat(itm.total_stock_grn_expenses_paid), 0),
            material_expense_due: materials.reduce((prev, itm) => {
                let due_amount = (parseFloat(itm.total_stock_grn_expenses || 0) - parseFloat(itm.total_stock_grn_expenses_paid || 0))
                return prev + (due_amount > 0 ? due_amount : 0)
            }, 0),

            machine_estimated_amount: machines.reduce((prev, itm) => prev + parseFloat(itm.estimated_amount), 0),
            machine_expense_paid: machines.reduce((prev, itm) => prev + parseFloat(itm.total_stock_grn_expenses), 0),
            machine_expense_due: machines.reduce((prev, itm) => {
                let due_amount = (parseFloat(itm.total_stock_grn_expenses || 0) - parseFloat(itm.total_stock_grn_expenses_paid || 0))
                return prev + (due_amount > 0 ? due_amount : 0)
            }, 0),
        }

        console.log(items, 'items');

        let estimated_amount = items.labour_estimated_amount + items.material_estimated_amount + items.machine_estimated_amount
        let expense_paid = items.labour_expense_paid + items.material_expense_paid + items.machine_expense_paid
        let expense_due = items.labour_expense_due + items.material_expense_due + items.machine_expense_due

        let smark_cards = [

            {
                id: 'lm-smart-top-cards-estimated_amount',
                label: 'Estimated',
                value: parseFloat(estimated_amount || 0).toFixed(2) || "0",
                icon: Icons.general.invoice,
                unit_name: '₹',
                style: {
                    color: '#0065ff',
                    bg_linear_colors: {
                        start: '#e5efff',
                        end: '#e5f9ff'
                    },
                    border_color: '#c6d4dc',
                },
            },
            {
                id: 'lm-smart-top-cards-expense_paid',
                label: 'Paid Amount',
                value: parseFloat(expense_paid || 0).toFixed(2) || "0",
                icon: Icons.general.invoice,
                unit_name: '₹',
                style: {
                    color: '#33c87e',
                    bg_linear_colors: {
                        start: '#eaf9f2',
                        end: '#f2fef7'
                    },
                    border_color: '#ccd8d2',
                },
            },
            {
                id: 'lm-smart-top-cards-expense_due',
                label: 'Total Outstanding',
                value: parseFloat(expense_due || 0).toFixed(2) || "0",
                icon: Icons.general.invoice,
                unit_name: '₹',
                style: {
                    color: '#ff5b5b',
                    bg_linear_colors: {
                        start: '#ffeded',
                        end: '#fff9f9'
                    },
                    border_color: '#dcd1d1',
                },
            },

        ]

        console.log(smark_cards, 'smark_cards');

        setSmarkCard_Items(smark_cards)

    }


    return (
        <>
            {
                ShowSideFilter ?
                    <SideFilters
                        props={
                            {
                                heading: 'Filters',
                                filters: SideFilterSettings,
                                close: HandleSideFilterClose,
                                updateCallback: HandleSideFilterUpdateCallback
                            }
                        }
                    />
                    : ''
            }
            <div className="project-client_invoice-main">
                <div className="proposals-panel-content">

                    {SmarkCard_Items.length ?
                        <SmarkTopCards props={{
                            id: 'labourManagement-smart-top-cards',
                            cards: SmarkCard_Items || [],
                            callback: HandleSmartCardClick
                        }} />
                        : ''}

                    <div className="smart-panel-content-main">
                        <div className="panel-content-header">
                            <div className="_left">
                                <div className="title">Buget</div>
                                <div className="desc"> (Compare with estimation)</div>
                            </div>

                        </div>

                        <div className="report-result-sections">
                            <div className="report-result-sections-items">

                                <div
                                    className={`report-result-section ${selectedResultSection == 'labour' ? 'report-result-section-active' : ''}`}
                                    onClick={() => HandleResultSection('labour')}
                                >
                                    <div className="icon" dangerouslySetInnerHTML={{ __html: Icons.general.manpower }}></div>
                                    <div className="label">Labour BOQ</div>
                                </div>
                                <div
                                    className={`report-result-section ${selectedResultSection == 'material' ? 'report-result-section-active' : ''}`}
                                    onClick={() => HandleResultSection('material')}
                                >
                                    <div className="icon" dangerouslySetInnerHTML={{ __html: Icons.general.material }}></div>
                                    <div className="label">Material BOQ</div>
                                </div>
                                <div
                                    className={`report-result-section ${selectedResultSection == 'machines' ? 'report-result-section-active' : ''}`}
                                    onClick={() => HandleResultSection('machines')}
                                >
                                    <div className="icon" dangerouslySetInnerHTML={{ __html: Icons.general.machines }}></div>
                                    <div className="label">Machines BOQ</div>
                                </div>
                            </div>

                        </div>

                        <div className="filter-section-main">
                            {isLeftFilter ?

                                < div className="left-filter-main">
                                    {Filters.left.map((item, i) => (

                                        item.type == 'icon' ? <Icon_Filter props={item} />
                                            : item.type == 'button' ? <Button_Filter props={item} />
                                                : item.isSearchBar && item.type == 'input' ?
                                                    <div className="search-filter">
                                                        <SearcBarInput
                                                            props={{
                                                                id: item.id,
                                                                value: item.value,
                                                                placeholder: item.placeholder || "Search by name",
                                                                setValue: (val) => {
                                                                    HandleFiltersEvent(val, item.id)
                                                                },
                                                                isIcon: item.isIcon,
                                                                isLeft: item.isIconLeft,
                                                                icon: item.icon || Icons.general.search
                                                            }}
                                                        />
                                                    </div>
                                                    : ''
                                    ))}
                                </div>
                                : ''}
                            {isRightFilter ?

                                < div className="right-filter-main">
                                    {Filters.right.map((item, i) => (

                                        item.type == 'icon' ? <Icon_Filter props={item} />
                                            : item.type == 'button' ? <Button_Filter props={item} />
                                                : item.isSearchBar && item.type == 'input' ?
                                                    <div className="search-filter">
                                                        <SearcBarInput
                                                            props={{
                                                                id: item.id,
                                                                value: item.value,
                                                                placeholder: item.placeholder || "Search by name",
                                                                setValue: (val) => {
                                                                    HandleFiltersEvent(val, item.id)
                                                                },
                                                                isIcon: item.isIcon,
                                                                isLeft: item.isIconLeft,
                                                                icon: item.icon || Icons.general.search
                                                            }}
                                                        />
                                                    </div>
                                                    : ''
                                    ))}
                                </div>
                                : ''}
                        </div>
                        <div
                            className="panel-content-sections panel-content-tabel-section"
                        >
                            {
                                selectedResultSection == 'labour' ? RenderItemsResult(LabourTabelHeader, LabourItems)
                                    : selectedResultSection == 'material' ? RenderItemsResult(MaterialTabelHeader, MaterialItems)
                                        : selectedResultSection == 'machines' ? RenderItemsResult(MachinesTabelHeader, MachinesItems)
                                            : ''
                            }
                        </div>
                    </div>

                </div>

            </div>
        </>
    )
}

export default BudgetReport;