

import React, { useEffect, useState, useRef } from 'react'
import { Route, Routes, BrowserRouter, Link, useLocation, Navigate, Outlet, useNavigate, useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux'
import UserAction from '../../redux/action/userAction'
import StoreHandler from '../../redux/StoreHandler'

import ReverseProxy from '../../config/reverseProxy'
import SettingsHandler from '../../Handlers/Settings/Settings';
import Icons from '../../assets/Icons'
import Images from '../../assets/Images'
import { CostInput, TextInput, SelectInput, RadioInput } from '../../components/Inputs'
import Utils from '../../utils'


import AlertPopup from '../../components/AlertPopup'
import Loading from '../../components/Loading'
import SystemToastPopup from '../../components/SystemToastPopup'
import { useAuth, useMenuAuth } from '../../hooks/useAuth'

const NotificationSettings = () => {

    const settingsHandler = new SettingsHandler()

    const [isLoading, setIsLoading] = useState(false)
    const [warningAlert, setWarningAlert] = useState(false)
    const [warningAlertType, setWarningAlertType] = useState('warning')
    const [apiFailedMessage, setApiFailedMessage] = useState("Error in Server")

    const store = useSelector((store) => store)
    const dispatch = useDispatch()
    const { updateState } = new UserAction

    const storeHandler = new StoreHandler({
        dataStack: ['company'],
        store: store,
        dispatch: dispatch,
        updateState: updateState,
    })
    const LoadStoreData = async () => {
        setIsLoading(true)
        let resolved = await storeHandler.init()
        setIsLoading(false)
    }
    const navigate = useNavigate()
    const { isAdmin, menu_permission } = useMenuAuth({ menu: 'settings--notification_settings' })
    const location = useLocation()

    const [selected_view_settings_value, setSelected_view_settings_value] = useState('')
    const [has_view_access, setHas_view_access] = useState(false)
    const [has_add_access, setHas_add_access] = useState(false)
    const [has_edit_access, setHas_edit_access] = useState(false)
    const [has_delete_access, setHas_delete_access] = useState(false)

    useEffect(() => {

        if (isAdmin) {

            setHas_view_access(true)
            setHas_add_access(true)
            setHas_edit_access(true)
            setHas_delete_access(true)
        }

        else if (menu_permission) {

            if (
                !menu_permission._view
                &&
                (!menu_permission.selected_view_settings_value
                    || menu_permission.selected_view_settings_value == 'no_access')
            ) {
                navigate(`${ReverseProxy['proxyUrl']}/quick-menu`, { state: { from: location }, replace: true })
            }

            if (menu_permission._view == '0') navigate(`${ReverseProxy['proxyUrl']}/builder/projects`, { state: { from: location }, replace: true })

            setHas_view_access(menu_permission._view == '1')
            setHas_add_access(menu_permission._create == '1')
            setHas_edit_access(menu_permission._edit == '1')
            setHas_delete_access(menu_permission._delete == '1')

            setSelected_view_settings_value(menu_permission.selected_view_settings_value)

        }

    }, [store])



    const [_NotificationSettings_Detials_, set_NotificationSettings_Detials] = useState({

        off_notification: false,
        daily_logs_notification: false,
        client_chat_notification: false,
        client_comment_notification: false,
        all_notification: false,

        send_email_notification: false,
        send_phone_notification: false,

        daily_email_digests: false,
        weekly_email_digests: false,
        monthly_email_digests: false,

    })



    const [NotificationSettings_Detials, setNotificationSettings_Detials] = useState([
        {
            id: 'section-notification-settings',
            title: 'Notification Settings',
            items: [
                {

                    id: 'notification-conten-settings',
                    title: 'What should we notify you about?',
                    subitems: [

                        {
                            id: 'off_notification',
                            title: `Don't send me any notifications`,
                            desc: `You won’t receive any notifications via email or phone. You can always check the notification bell in the upper right to see everything that’s new for you.`,
                            name: 'notification-settings',
                            checked: false,
                        },
                        {
                            id: 'daily_logs_notification',
                            title: `Notify me about Daily Logs`,
                            desc: `You’ll receive a notification every time a Daily Log is created.`,
                            name: 'notification-settings',
                            checked: false,
                        },
                        {
                            id: 'client_chat_notification',
                            title: `Notify me every time a client sends a chat messaget`,
                            desc: `You’ll receive a push notification for every client message. To avoid flooding your email inbox, chat message notifications are only sent to your phone. `,
                            name: 'notification-settings',
                            checked: false,

                        },
                        {
                            id: 'client_comment_notification',
                            title: `Notify me every time a client leaves a comment`,
                            desc: `You’ll receive a notification whenever a client leaves a comment on an item.`,
                            name: 'notification-settings',
                            checked: false,

                        },
                        {
                            id: 'all_notification',
                            title: `Notify me about everything`,
                            desc: `You’ll receive a notification for all important activity, as well as every team and client chat message. To avoid flooding your email inbox, chat message notifications are only sent to your phone.`,
                            name: 'notification-settings',
                            checked: false,
                        },
                    ]
                },
                {

                    id: 'notification-receive-settings',
                    title: 'How would you like to receive notifications?',
                    subitems: [

                        {
                            id: 'send_email_notification',
                            title: `Send me email notifications`,
                            desc: `We’ll send you an email for each notification you receive.`,
                            name: 'notification-settings',
                            checked: false,
                        },
                        {
                            id: 'send_phone_notification',
                            title: `Send notifications to my phone`,
                            desc: `We’ll send push notifications to the BuildBook app on your phone.`,
                            name: 'notification-settings',
                            checked: false,
                        },

                    ]
                },

            ]
        },
        {
            id: 'section-email-digests',
            title: 'Email Digests',
            items: [
                {

                    id: 'email-digests-settings',
                    title: '',
                    subitems: [

                        {
                            id: 'daily_email_digests',
                            title: `Send me daily email digests`,
                            desc: `Each morning we'll send you an email summary of your project work scheduled for the day.`,
                            name: 'notification-settings',
                            checked: false,
                        },
                        {
                            id: 'weekly_email_digests',
                            title: `Send me weekly email digests`,
                            desc: `Each Monday we'll send you an email summary of your important project activities scheduled for the week.`,
                            name: 'notification-settings',
                            checked: false,
                        },
                        {
                            id: 'monthly_email_digests',
                            title: `Send me monthly email digests`,
                            desc: `Each month, if you are an account admin, we'll send you an email summary of upcoming COI expiration dates.`,
                            name: 'notification-settings',
                            checked: false,
                        },

                    ]
                },

            ]
        },
    ])

    const HandleUpdateNotificationSettings = async (settings) => {

        if (!has_edit_access) return

        let update_payload = {}

        settings.forEach((section, section_idx) => {
            section.items.forEach((item, item_idx) => {
                item.subitems.forEach((subitem, subitem_idx) => update_payload[subitem.id] = subitem.checked)
            })
        })

        console.log(update_payload, 'update_payload');


        // setIsLoading(true)
        let response = await settingsHandler.updateNotificationSettingsHandler(update_payload)
        // setIsLoading(false)

        if (response.success) {
        }
        else {
            setWarningAlert(true)
            setWarningAlertType('error')
            setApiFailedMessage('Error in Notification Settings')
        }
    }

    const HandleSettingsSelected = (section_idx, parent_idx, item_idx, item) => {

        if (!has_edit_access) return

        let _NotificationSettings_Detials = [...NotificationSettings_Detials]

        if (item.id == 'off_notification' || item.id == 'all_notification') {

            _NotificationSettings_Detials.map((section, section_idx) => {

                section.items.map((_item, item_idx) => {

                    _item.subitems.map((subitem, subitem_idx) => {

                        if (subitem.id == item.id) {
                            subitem.checked = !subitem.checked
                        } else {
                            subitem.checked = false
                        }

                    })

                })

            })
        } else {



            _NotificationSettings_Detials.map((section, section_idx) => {

                section.items.map((_item, item_idx) => {

                    _item.subitems.map((subitem, subitem_idx) => {

                        if (subitem.id == 'off_notification' || subitem.id == 'all_notification') {
                            subitem.checked = false
                        }

                    })

                })

            })

            _NotificationSettings_Detials[section_idx].items[parent_idx].subitems[item_idx].checked = !item.checked
        }

        HandleUpdateNotificationSettings(_NotificationSettings_Detials)

        setNotificationSettings_Detials(_NotificationSettings_Detials)
    }

    useEffect(() => {
        LoadStoreData()
    }, [])

    useEffect(() => {

        if (store.user.company) {

            let { notification_settings } = store.user.company

            if (notification_settings && Object.keys(notification_settings).length > 0) {

                let _NotificationSettings_Detials = [...NotificationSettings_Detials]

                _NotificationSettings_Detials.map((section, section_idx) => {

                    section.items.map((_item, item_idx) => {

                        _item.subitems.map((subitem, subitem_idx) => {

                            if (notification_settings[subitem.id]) {
                                subitem.checked = notification_settings[subitem.id]
                            }

                        })

                    })

                })

                setNotificationSettings_Detials(_NotificationSettings_Detials)
            }
        }

    }, [store])



    return (

        <>

            {isLoading ?
                <Loading
                    props={{
                        isMainLogo: false,
                        isLabel: true
                    }} />
                : null}
            {warningAlert ?

                <SystemToastPopup
                    props={{
                        type: warningAlertType,
                        message: apiFailedMessage || "Error in System Settings update",
                        callback: (confirmation) => setWarningAlert(false)
                    }} />

                : null}


            <div className="notification_settings-content-main">

                {
                    NotificationSettings_Detials.map((section, sectionIndex) => (

                        <div
                            className="notification_settings-section"
                            key={section.id}
                            id={section.id}
                        >
                            <div className="section-title">{section.title}</div>

                            <div className="section-content-items">
                                {
                                    section.items.map((item, itemIndex) => (

                                        <div
                                            className="section-content-item"
                                            key={item.id}
                                            id={item.id}
                                        >
                                            <div className="content-item-title">{item.title}</div>
                                            <div className="content-item-subitems">
                                                {item.subitems.map((subitem, subitemIndex) => (

                                                    <div
                                                        className="content-subitem"
                                                        key={subitem.id}
                                                        id={subitem.id}

                                                    >
                                                        <div
                                                            className="content-subitem-title"
                                                            onClick={() => HandleSettingsSelected(sectionIndex, itemIndex, subitemIndex, subitem)}
                                                        >
                                                            <div className="checkbox-input">

                                                                {subitem.checked ?
                                                                    <div
                                                                        className="checkbox-selected"
                                                                        dangerouslySetInnerHTML={{ __html: Icons.general.tick_mark }}
                                                                    ></div>
                                                                    : <div className="checkbox-circle"></div>
                                                                }
                                                            </div>
                                                            <div className="label">{subitem.title}</div>
                                                        </div>
                                                        <div className="content-subitem-desc">{subitem.desc}</div>
                                                    </div>

                                                ))}

                                            </div>
                                        </div>

                                    ))
                                }

                            </div>

                        </div>

                    ))
                }

            </div>
        </>
    )
}

export default NotificationSettings;
