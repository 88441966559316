

import React, { useEffect, useState, useRef } from 'react'
import { Route, Routes, BrowserRouter, Link, useLocation, Navigate, Outlet, useNavigate, useParams } from 'react-router-dom';
import store from '../../redux/store'
import { useSelector, useDispatch } from 'react-redux'
import UserAction from '../../redux/action/userAction'

import ReverseProxy from '../../config/reverseProxy'
import CompanyHandler from '../../Handlers/Company/Company';
import SettingsHandler from '../../Handlers/Settings/Settings';
import Icons from '../../assets/Icons'
import Images from '../../assets/Images'
import { CostInput, TextInput, SelectInput, RadioInput } from '../../components/Inputs'
import Utils from '../../utils'
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

import AlertPopup from '../../components/AlertPopup'
import Loading from '../../components/Loading'
import SystemToastPopup from '../../components/SystemToastPopup'

import { useAuth, useMenuAuth } from '../../hooks/useAuth'

const Finance = () => {

    const store = useSelector((store) => store)
    const currency_type = store.user && store.user.company ? store.user.company.currency_setting || "₹" : "₹"

    const dispatch = useDispatch()
    const { updateState } = new UserAction

    const navigate = useNavigate()

    const settingsHandler = new SettingsHandler()

    const [isLoading, setIsLoading] = useState(false)
    const [warningAlert, setWarningAlert] = useState(false)
    const [warningAlertType, setWarningAlertType] = useState('warning')
    const [apiFailedMessage, setApiFailedMessage] = useState("Error in Server")


    const { isAdmin, menu_permission } = useMenuAuth({ menu: 'settings--field_settings' })
    const location = useLocation()

    const [selected_view_settings_value, setSelected_view_settings_value] = useState('')
    const [has_view_access, setHas_view_access] = useState(false)
    const [has_add_access, setHas_add_access] = useState(false)
    const [has_edit_access, setHas_edit_access] = useState(false)
    const [has_delete_access, setHas_delete_access] = useState(false)

    useEffect(() => {

        if (isAdmin) {

            setHas_view_access(true)
            setHas_add_access(true)
            setHas_edit_access(true)
            setHas_delete_access(true)
        }

        else if (menu_permission) {

            if (
                !menu_permission._view
                &&
                (!menu_permission.selected_view_settings_value
                    || menu_permission.selected_view_settings_value == 'no_access')
            ) {
                navigate(`${ReverseProxy['proxyUrl']}/quick-menu`, { state: { from: location }, replace: true })
            }

            if (menu_permission._view == '0') navigate(`${ReverseProxy['proxyUrl']}/builder/projects`, { state: { from: location }, replace: true })

            setHas_view_access(menu_permission._view == '1')
            setHas_add_access(menu_permission._create == '1')
            setHas_edit_access(menu_permission._edit == '1')
            setHas_delete_access(menu_permission._delete == '1')

            setSelected_view_settings_value(menu_permission.selected_view_settings_value)

        }

    }, [store])

    const [activeMenu, setActiveMenu] = useState('')
    const [FinanceMenus, setFinanceMenus] = useState([
        {
            id: 'markups',
            label: "Markups",
        },
        {
            id: 'taxes',
            label: "Taxes",
        },
        {
            id: 'insurance',
            label: "Insurance",
        },
        {
            id: 'overheads',
            label: "Overheads",
        },
    ])

    const Finance_default_values = {

        markups: [
            {
                id: Utils.getUniqueId(),
                isActive: true,
                isDefault: true,
                name: "",
                value: 0,
                value_type: "%",
            }
        ],
        taxes: [
            {
                id: Utils.getUniqueId(),
                isActive: true,
                isDefault: true,
                name: "",
                type: 'expense',
                value: 0,
                value_type: "%",
            }
        ],
        insurance: [
            {
                id: Utils.getUniqueId(),
                isActive: true,
                isDefault: true,
                name: "",
                value: 0,
                value_type: "%",
            }
        ],
        overheads: [
            {
                id: Utils.getUniqueId(),
                isActive: true,
                isDefault: true,
                name: "",
                value: 0,
                value_type: "%",
            }
        ],

    }
    const [No_FinanceSetting, setNo_FinanceSetting] = useState(false)
    const [FinanceSetting_Id, setFinanceSetting_Id] = useState('')
    const [FinanceSetting_Options, setFinanceSetting_Options] = useState(Finance_default_values)

    const HandleToggleEvent = (menu) => {
        setActiveMenu(menu.id)
    }

    const HandleAddNew = () => {

        if (!has_add_access || !activeMenu) return

        let FinanceSetting_Options_ = { ...FinanceSetting_Options }

        FinanceSetting_Options_[activeMenu].push({
            id: Utils.getUniqueId(),
            name: '',
            isActive: true,
            isDefault: false,
        })
        setFinanceSetting_Options(FinanceSetting_Options_)
    }

    const HandleInputChange = (value, index, key) => {

        if (!has_add_access || !activeMenu) return

        let FinanceSetting_Options_ = { ...FinanceSetting_Options }
        FinanceSetting_Options_[activeMenu][index][key] = value

        setFinanceSetting_Options(FinanceSetting_Options_)
    }
    const HandleRemove = (index) => {

        if (!has_delete_access) return

        if (index == 0 || !activeMenu) return
        let FinanceSetting_Options_ = { ...FinanceSetting_Options }
        FinanceSetting_Options_[activeMenu].splice(index, 1)
        setFinanceSetting_Options(FinanceSetting_Options_)
    }
    const getFinanceHandler = async () => {

        let response = await settingsHandler.getFinanceHandler()

        if (response && response.success) {


            if (Array.isArray(response.data)) {

                if (!Array.isArray(response.data) || !response.data.length) {
                    setNo_FinanceSetting(true)
                    setFinanceSetting_Options(Finance_default_values)
                }
                else {

                    let field_data = response.data[0]

                    let finance_settings = {
                        markups: field_data.markups.length ? field_data.markups : Finance_default_values.markups,
                        taxes: field_data.taxes.length ? field_data.taxes : Finance_default_values.taxes,
                        insurance: field_data.insurance.length ? field_data.insurance : Finance_default_values.insurance,
                        overheads: field_data.overheads.length ? field_data.overheads : Finance_default_values.overheads,
                    }
                    setFinanceSetting_Id(field_data.id)
                    setFinanceSetting_Options(finance_settings)
                }

            }


        } else {
            setWarningAlert(true)
            setWarningAlertType('error')
            setApiFailedMessage(`Error in getting Finance Settings`)
        }
    }

    const HandleFieldSubmit = async (e) => {
        e.preventDefault()

        if (No_FinanceSetting) {

            let finance_settings = {
                markups: [],
                taxes: [],
                insurance: [],
                overheads: []
            }

            for (const key in FinanceSetting_Options) {
                finance_settings[key] = FinanceSetting_Options[key].filter(field => field.name != "")
            }

            setIsLoading(true)

            let response = await settingsHandler.createFinanceHandler(finance_settings)

            if (response.success) {
                getFinanceHandler()
                setIsLoading(false)
            }
            else {
                setIsLoading(false)
                setWarningAlert(true)
                setWarningAlertType('error')
                setApiFailedMessage(`Error in updating Finance Settings`)
            }

        } else {

            if (!has_add_access || !FinanceSetting_Id) return

            let finance_settings = {
                id: FinanceSetting_Id,
                markups: [],
                taxes: [],
                insurance: [],
                overheads: []
            }

            for (const key in FinanceSetting_Options) {
                finance_settings[key] = FinanceSetting_Options[key].filter(field => field.name != "")
            }

            setIsLoading(true)

            let response = await settingsHandler.updateFinanceHandler(finance_settings)

            if (response.success) {
                getFinanceHandler()
                setIsLoading(false)
            }
            else {
                setIsLoading(false)
                setWarningAlert(true)
                setWarningAlertType('error')
                setApiFailedMessage(`Error in updating Finance Settings`)
            }
        }
    }

    useEffect(() => {
        HandleToggleEvent(FinanceMenus[0])

        getFinanceHandler()
    }, [])




    return (
        <>

            {isLoading ?
                <Loading
                    props={{
                        isMainLogo: false,
                        isLabel: true
                    }} />
                : null}
            {warningAlert ?

                <SystemToastPopup
                    props={{
                        type: warningAlertType,
                        message: apiFailedMessage || "Error in Finance Settings",
                        callback: (confirmation) => setWarningAlert(false)
                    }} />

                : null}



            <form className="settings-panel-content" onSubmit={HandleFieldSubmit}>
                <div className="panel-content-main">

                    <div className="panel-content-header">
                        <div className="title">Finance</div>
                    </div>
                    <div className="panel-content-sections panel-content-tabel-section">
                        <div className="finance_settings-content-main">
                            <div className="toggle-items">
                                {FinanceMenus.map((menu, i) => (

                                    <div
                                        className={`toggle-item ${activeMenu == menu.id ? 'toggle-item-active' : ''}`}
                                        key={`finance_settings-toggle-${menu.id}`}
                                        onClick={(e) => HandleToggleEvent(menu)}
                                    >
                                        <div className="label">{menu.label}</div>
                                    </div>

                                ))}
                            </div>
                            <div className="finance_settings-result-main">

                                <div className="finance_settings-splits-main">
                                    <div className="finance_settings-splits-header">
                                        <div className="header-item-status">Active</div>
                                        <div className="header-item-name">Name</div>
                                        <div className="header-item-cost">Cost</div>
                                        <div className="header-item-default">Default</div>
                                        {has_delete_access ? <div className="header-item-action">Action</div> : ''}
                                    </div>
                                    <div className="finance_settings-splits-content">
                                        {FinanceSetting_Options[activeMenu]?.map((item, index) => (
                                            <div className="finance_settings-content-items">
                                                <div className="finance_settings-content-status">
                                                    {index != 0 ?
                                                        <RadioInput props={{
                                                            value: item.isActive,
                                                            isIcon: false,
                                                            icon: "",
                                                            inputType: "checkbox",
                                                            name: "table-default-radio",
                                                            setValue: (value) => HandleInputChange(value, index, 'isActive')
                                                        }} />
                                                        :
                                                        <span
                                                            className='checkboxinput-main'
                                                            dangerouslySetInnerHTML={{ __html: Icons.general.checkbox_disabled }}
                                                        >
                                                        </span>
                                                    }

                                                </div>
                                                <div className="finance_settings-content-name">
                                                    <TextInput
                                                        props={{
                                                            id: "finance_settings-name",
                                                            value: item.name,
                                                            placeholder: '',
                                                            setValue: (value) => HandleInputChange(value, index, 'name'),
                                                            isIcon: false,
                                                            isLabel: false,
                                                            isRequired: true,
                                                            label: "Name",
                                                        }}
                                                    />
                                                </div>
                                                <div className="finance_settings-content-cost">
                                                    <CostInput props={{
                                                        id: "finance_settings-cost",
                                                        isCostMethod: true,
                                                        currency_type: currency_type,
                                                        value: item.value,
                                                        isIcon: true,
                                                        isRequired: true,
                                                        icon: "%",
                                                        setValue: (value, value_type = undefined) => {
                                                            HandleInputChange(value, index, 'value')
                                                            if (value_type) HandleInputChange(value_type, index, 'value_type')
                                                        }
                                                    }} />
                                                </div>

                                                <div className="finance_settings-content-default">
                                                    <RadioInput props={{
                                                        id: "finance_settings-default",
                                                        value: item.isDefault,
                                                        isIcon: false,
                                                        icon: "",
                                                        name: "table-default-radio",
                                                        setValue: (value) => HandleInputChange(value, index, 'isDefault')
                                                    }} />
                                                </div>

                                                {has_delete_access ?
                                                    <div className="finance_settings-content-action">
                                                        <div
                                                            className="action-remove"
                                                            onClick={(e) => HandleRemove(index)}
                                                        >
                                                            <div className="icon" dangerouslySetInnerHTML={{ __html: Icons.general.close }}></div>
                                                        </div>
                                                    </div>
                                                    : ''}
                                            </div>
                                        ))}
                                    </div>
                                    <div
                                        className="finance_settings-splits-action-buttons"
                                    >
                                        {has_add_access ?
                                            <div
                                                className="add-button"
                                                onClick={(e) => HandleAddNew()}
                                            >
                                                <div
                                                    className="icon"
                                                    dangerouslySetInnerHTML={{ __html: Icons.general.add_btn }}
                                                ></div>
                                                <div className="label">Add new option</div>
                                            </div>
                                            : ''}
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                    <div className="panel-content-footer">
                        <div className="content-footer-items">
                            <div className="footer-button">
                                <div className="label">Cancel</div>
                            </div>
                            <button type='submit' className="footer-button footer-button-active">

                                <div className="label">Save Changes</div>
                            </button>
                        </div>
                    </div>
                </div>

            </form>
        </>
    )
}

export default Finance;
