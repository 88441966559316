

import React, { useEffect, useState, useRef } from 'react'
import { Route, Routes, BrowserRouter, Link, useLocation, Navigate, Outlet, useNavigate, useParams } from 'react-router-dom';
import store from '../../redux/store'
import StoreHandler from '../../redux/StoreHandler'
import { useSelector, useDispatch } from 'react-redux'
import UserAction from '../../redux/action/userAction'

import ReverseProxy from '../../config/reverseProxy'
import CostCatalogHandler from '../../Handlers/CostCatalog/CostCatalog';
import Icons from '../../assets/Icons'
import Images from '../../assets/Images'
import { CostInput, TextInput, SelectInput, SearcBarInput, RadioInput } from '../../components/Inputs'
import Utils from '../../utils'
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

import AlertPopup from '../../components/AlertPopup'
import SystemToastPopup from '../../components/SystemToastPopup'
import Loading from '../../components/Loading'

import AddCostCatalogItemPopup from './AddCostCatalogItemPopup'

import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

const CostCatalog = () => {

    const [isLoading, setIsLoading] = useState(false)
    const [warningAlert, setWarningAlert] = useState(false)
    const [warningAlertType, setWarningAlertType] = useState('warning')
    const [apiFailedMessage, setApiFailedMessage] = useState('Error in Cost Catalog, Please try again!')

    const [deleteItemId, setDeleteItem] = useState(null)
    const [deleteItemType, setDeleteItemType] = useState(null)
    const [deleteItemAlert, setDeleteItemAlert] = useState(false)

    const costCatalogHandler = new CostCatalogHandler()

    const [currency_type, setCurrency_type] = useState('₹')

    const navigate = useNavigate()
    const store = useSelector((store) => store)
    const dispatch = useDispatch()
    const { updateState } = new UserAction

    const storeHandler = new StoreHandler({
        dataStack: ['company', 'field_settings', 'finance_settings'],
        store: store,
        dispatch: dispatch,
        updateState: updateState,
    })

    const LoadStoreData = async () => {

        setIsLoading(true)
        let resolved = await storeHandler.init()
        setIsLoading(false)
    }


    const [tabelHeader, setTabelHeader] = useState([

        {
            id: 'select',
            label: 'Select',
            minWidth: 30,
            disable: false,
        },

        {
            id: 'name',
            label: 'Name',
            minWidth: 200,
            disable: false,
        },
        {
            id: 'cost_type',
            label: 'Cost Type',
            disable: false,

        },
        {
            id: 'quantity',
            label: 'Quantity',
            disable: false,
        },
        {
            id: 'unitrate',
            label: 'Unit rate',
            format: (value) => `${currency_type}${parseFloat(value || 0).toFixed(2)}`,
            disable: false,
        },
        {
            id: 'builder_price',
            label: 'Prime cost',
            disable: false,
        },
        {
            id: 'markup_amount',
            label: 'Markup',
            disable: false,
        },
        {
            id: 'tax_amount',
            label: 'Tax',
            disable: false,
        },
        {
            id: 'price',
            label: 'Final price',
            disable: false,
        },
        {
            id: 'actions',
            label: '',
            disable: false,
        },
    ])
    const [costcatalogItemsOrginal, setCostcatalogItemsOrginal] = useState([])
    const [costcatalogItems, setCostcatalogItems] = useState([])
    const [selectedAction, setSelectedAction] = useState('')

    const [tableSelectAll, setTableSelectAll] = useState(false)

    const [isAddBtnClicked, setIsAddBtnClicked] = useState(false)
    const [existItemData, setExistItemData] = useState({})


    const [ActionDropdown, setActionDropdown] = useState([
        {
            id: 'edit',
            label: 'Edit',
            icon: Icons.general.edit,
            click: (row) => HandleOpenItem(row)
        },
        {
            id: 'delete',
            label: 'Delete',
            icon: Icons.general.delete,
            click: (row) => HandleDeleteItem(row)
        },
    ])

    const AddBtnOptions = [
        {
            icon: Icons.general.group,
            label: 'Category',
            type: 'group',
            id: "group"
        },

        {
            icon: Icons.general.labour,
            label: 'Labour',
            type: 'item',
            id: "labour"
        },
        {
            icon: Icons.general.material,
            label: 'Materials',
            type: 'item',
            id: "material"
        },
        {
            icon: Icons.general.machines,
            label: 'Machines',
            type: 'item',
            id: "machine"
        },
        {
            icon: Icons.general.subcontractor,
            label: 'Subcontractor',
            type: 'item',
            id: "subcontractor"
        },
        {
            icon: Icons.general.fee,
            label: 'Fee',
            type: 'item',
            id: "fee"
        },
        {
            icon: Icons.general.allowance,
            label: 'Allowances',
            type: 'item',
            id: "allowance"
        },
        {
            icon: Icons.general.work,
            label: 'Work',
            type: 'work',
            id: 'work'
        },
    ]

    const HandleOpenItem = (item) => {

        setExistItemData(item)
        navigate(`/builder/cost-catalog/${item.type_option.id}/${item.sno}/edit`)
    }

    const HanelCellAction = (id, row) => {

        if (id == "actions") {
            setSelectedAction(row.id)
            HandleActionDisable(row.id)
        }
    }
    const HandleActionDisable = (id) => {

        let input = document.getElementById(`estimate-tabel-action-btn-${id}`)
        let dropdown_items = document.getElementById(`estimate-tabel-action-dropdown-${id}`)

        const Event = (e) => {
            let path = e.path || (e.composedPath && e.composedPath());

            if (Array.isArray(path) && !path.includes(dropdown_items) && !path.includes(input)) {
                setSelectedAction('')
                window.removeEventListener('click', Event)
            }
        }

        window.addEventListener('click', Event)
    }

    const HandleSelectAllEvent = (value) => {
        setTableSelectAll(value)

        let _costcatalogItems = [...costcatalogItems]

        const selectItem = (items, value) => {

            items = items.map(item => {

                item.selected = value
                if (Array.isArray(item.sub_items)) item.sub_items = selectItem(item.sub_items, value)
                return item
            })

            return items
        }

        _costcatalogItems = selectItem(_costcatalogItems, value)


        setCostcatalogItems(_costcatalogItems)
    }

    const HandleSelectEvent = (group_id, parent_id, value) => {

        let _costcatalogItems = [...costcatalogItems]

        const selectItem = (items, value) => {

            items = items.map(item => {

                item.selected = value
                if (item.type != 'group' && Array.isArray(item.sub_items)) item.sub_items = selectItem(item.sub_items, value)
                return item
            })

            return items
        }

        const findAndUpdateGroup = (group_id, parent_id, _costcatalogItems, value) => {

            _costcatalogItems = _costcatalogItems.map((item, idx) => {

                if (item.id == group_id && item.parent_id == parent_id) {
                    item.selected = value
                    if (item.type != 'item' && Array.isArray(item.sub_items)) {
                        item.sub_items = selectItem(item.sub_items, value)
                    }
                }
                else if (item.type != 'item' && Array.isArray(item.sub_items)) {
                    item.sub_items = findAndUpdateGroup(group_id, parent_id, item.sub_items, value)
                }

                return item
            })

            return _costcatalogItems
        }

        _costcatalogItems = findAndUpdateGroup(group_id, parent_id, _costcatalogItems, value)
        setCostcatalogItems(_costcatalogItems)
    }

    const getCostcatalogItemTypeEenem = (type) => {

        if (type == '1') return 'group'
        else if (type == '2') return 'work_template'
        else if (type == '3') return 'item'
        else if (type == '4') return 'work'
        else return ''

    }
    const getCostcatalogItemCategoryEenem = (type) => {

        if (type == '1') return 'material'
        else if (type == '2') return 'labour'
        else if (type == '3') return 'machine'
        else if (type == '4') return 'subcontractor'
        else if (type == '5') return 'fee'
        else if (type == '6') return 'allowance'
        else return ''

    }
    const getCostcatalogItemStatusEenem = (type) => {

        if (type == '0') return 'incomplete'
        else if (type == '1') return 'completed'
        else if (type == '2') return 'hold'
        else if (type == '3') return 'stopped'
        else return ''
    }

    const getSubItem = (items, parentid) => {

        let subItems = []

        let getItems = (items, parentid) => {

            if (!Array.isArray(items)) return

            let _items = items.filter(itm => itm.parent_id == parentid)

            subItems.push(..._items)

            _items.forEach(itm => getItems(items, itm.id))
        }

        getItems(items, parentid)

        return subItems
    }

    const getFormatedCostcatalogItem = (item) => {

        return {
            sno: item.sno,
            expanded: true,
            selected: false,
            disable: false,

            parent_id: item.parent_id,
            parent_name: item.parent_name,
            id: item.id,
            name: item.name,
            description: item.description,

            costcode_id: item.costcode_id || "",
            costcode_name: item.costcode_name || "",

            type: getCostcatalogItemTypeEenem(item.type),
            item_type: item.type != '3' ? getCostcatalogItemTypeEenem(item.type) : getCostcatalogItemCategoryEenem(item.item_type),
            cost_type: item.type != '1' && item.type != '3' ? getCostcatalogItemTypeEenem(item.type) : getCostcatalogItemCategoryEenem(item.item_type),

            parent_type: item.parent_type,
            parent_quantity: parseFloat(item.parent_quantity || 0),

            _status: item.status,
            status: getCostcatalogItemStatusEenem(item.status),

            quantity: parseFloat(item.quantity || 0) || 0,
            unitrate: parseFloat(item.unitrate || 0) || 0,
            builder_price: parseFloat(item.builder_price || 0) || 0,
            price: parseFloat(item.price || 0) || 0,


            unit_id: item.unit_id || "",
            unit_name: item.unit_name || "",

            markup_id: item.markup_id || "",
            markup_name: item.markup_name || "",
            markup_value: parseFloat(item.markup_value || 0) || 0,
            markup_value_type: item.markup_value_type || "",
            markup_amount: parseFloat(item.markup_amount || 0) || 0,

            tax_id: item.tax_id || "",
            tax_name: item.tax_name || "",
            tax_value_type: item.tax_value_type || "",
            tax_value: parseFloat(item.tax_value || 0) || 0,
            tax_amount: parseFloat(item.tax_amount || 0) || 0,

            sub_items: []
        }

    }

    const getOrderedTableItems = (items = []) => {
        const groupedItems = {};

        for (const item of items) {

            const parent_id = item.parent_id;

            if (parent_id != null) {

                if (!groupedItems[parent_id]) groupedItems[parent_id] = [];

                groupedItems[parent_id].push(item);
            }
        }

        for (const item of items) {
            const itemId = item.id;
            if (groupedItems[itemId]) {
                item.sub_items = groupedItems[itemId];
            }
        }

        return items.filter(d => d.parent_id == "")

    }

    const LoadCostcatalogItems = async () => {

        setIsLoading(true)

        let response = await costCatalogHandler.getCostCatalogItemsHandler()
        setIsLoading(false)

        if (response.success) {


            let { data } = response

            if (!Array.isArray(data)) return

            let items = data.map(d => {

                let item = getFormatedCostcatalogItem(d)
                let subItems = getSubItem(data, d.id).map(itm => getFormatedCostcatalogItem(itm))

                let tax_amount = item.tax_amount
                let markup_amount = item.markup_amount
                let builder_price = item.builder_price
                let price = item.price

                let subItems_Total = subItems.reduce((prev, val, ind) => {

                    return {
                        tax_amount: prev.tax_amount + val.tax_amount,
                        markup_amount: prev.markup_amount + val.markup_amount,
                        builder_price: prev.builder_price + val.builder_price,
                        price: prev.price + val.price,
                    }

                }, { tax_amount: 0, markup_amount: 0, builder_price: 0, price: 0 })

                tax_amount = parseFloat(tax_amount + (subItems_Total.tax_amount || 0)).toFixed(2)
                markup_amount = parseFloat(markup_amount + (subItems_Total.markup_amount || 0)).toFixed(2)
                builder_price = parseFloat(builder_price + (subItems_Total.builder_price || 0)).toFixed(2)
                price = parseFloat(price + (subItems_Total.price || 0)).toFixed(2)



                if (item.parent_id) {
                    let parent_item = data.filter(d => d.id == item.parent_id)[0]
                    if (parent_item) {
                        item.parent_type = parent_item.type
                        if (parent_item.type == '4') item.parent_quantity = parent_item.quantity
                    }
                }

                item._tax_amount = tax_amount || ""
                item._tax_percent = ((tax_amount / builder_price) * 100 || 0).toFixed(2)
                item._markup_amount = markup_amount || ""
                item._markup_percent = ((markup_amount / builder_price) * 100 || 0).toFixed(2)
                item._builder_price = builder_price || ""
                item._price = price || ""
                item._unitrate = item.unitrate || ""
                item._quantity = item.quantity || ""

                item.tax_amount = tax_amount ? `${currency_type}${tax_amount}` : ""
                item.markup_amount = markup_amount ? `${currency_type}${markup_amount}` : ""
                item.builder_price = builder_price ? `${currency_type}${builder_price}` : ""
                item.price = price ? `${currency_type}${price}` : ""
                item.unitrate = item.unitrate ? `${currency_type}${item.unitrate}` : ""
                item.quantity = item.quantity ? `${item.quantity}${item.unit_name || ""}` : ""

                if (item.type == 'work') {

                    let cost_lines = getSubItem(data, d.id).map(itm => getFormatedCostcatalogItem(itm)) || []

                    cost_lines = cost_lines.map(item => {

                        let type_option = AddBtnOptions.filter(opt => {

                            if (opt.type != 'item' && item.type == opt.type) return true
                            else if (opt.type == 'item' && item.item_type == opt.id) return true
                            else return false
                        })

                        type_option = type_option.length ? type_option[0] : undefined
                        item.type_option = type_option

                        return item

                    })

                    item.cost_lines = cost_lines
                }

                let type_option = AddBtnOptions.filter(opt => {

                    if (opt.type != 'item' && item.type == opt.type) return true
                    else if (opt.type == 'item' && item.item_type == opt.id) return true
                    else return false
                })

                type_option = type_option.length ? type_option[0] : undefined
                item.type_option = type_option

                return item

            })

            items = getOrderedTableItems(items)

            setCostcatalogItemsOrginal(data)
            setCostcatalogItems(items)
        }
        else {
            setWarningAlert(true)
            setWarningAlertType('error')
            setApiFailedMessage(`Error in Cost Catalog, Please try again!`)
        }
    }


    useEffect(() => {
        LoadStoreData()
        LoadCostcatalogItems()
    }, [])

    useEffect(() => {


        if (store && store.user) {

            let { company, settings } = store.user

            if (company) {

                let { currency_setting, timezone, temperature_setting } = company

                if (currency_setting) setCurrency_type(currency_setting)
            }

        }


    }, [store])


    const HandleUpdateCallback = () => {
        LoadCostcatalogItems()
    }


    const HandleExpandGroup = (group_id, parent_id) => {

        let _costcatalogItems = [...costcatalogItems]

        const findAndUpdateGroup = (group_id, parent_id, _costcatalogItems) => {

            _costcatalogItems = _costcatalogItems.map((item, idx) => {

                if (item.id == group_id && item.parent_id == parent_id) {
                    item.expanded = !item.expanded
                }
                else if (item.type != 'item' && Array.isArray(item.sub_items)) {
                    item.sub_items = findAndUpdateGroup(group_id, parent_id, item.sub_items)
                }

                return item
            })

            return _costcatalogItems
        }

        _costcatalogItems = findAndUpdateGroup(group_id, parent_id, _costcatalogItems)

        setCostcatalogItems(_costcatalogItems)
    }

    const CreateCostcatalogTableItem = (row, idx, sub_space_px, parent_expand = true) => {


        let { id, disable, type, parent_id, sub_items, expanded, _status } = row

        sub_items = sub_items || []

        return (
            <>
                {!disable ?
                    <>
                        <TableRow
                            hover
                            role="checkbox"
                            tabIndex={-1}
                            key={id}
                            style={{ display: parent_expand ? 'table-row' : 'none' }}
                            className={`estimate-table-row ${type == 'group' ? !parent_id ? 'estimate-table-row-group' : 'estimate-table-row-subgroup' : ''}`}
                        >
                            {tabelHeader.map((column) => {
                                const value = row[column.id];
                                return (

                                    column.disable ? '' :
                                        column.id == 'select' ?


                                            <TableCell
                                                key={column.id}
                                                align={column.align}
                                                className='estimate-table-row-item'
                                            >
                                                <RadioInput props={{
                                                    value: row.selected || false,
                                                    isIcon: false,
                                                    icon: "",
                                                    inputType: "checkbox",
                                                    name: "table-default-radio",
                                                    setValue: (value) => HandleSelectEvent(id, parent_id, value)
                                                }} />

                                            </TableCell>

                                            :
                                            <TableCell
                                                key={column.id}
                                                align={column.align}
                                                onClick={(e) => {
                                                    if (column.id != 'actions') HanelCellAction(column.id, row)
                                                }}
                                                className='estimate-table-row-item'
                                                style={column.id == 'name' ? { paddingLeft: `${sub_space_px}px` } : {}}
                                            >
                                                {
                                                    column.id == 'name' ?
                                                        <div
                                                            className="tabel-name-main"
                                                        >
                                                            {type != 'item' ?
                                                                <div
                                                                    className={`tabel-row-arrow ${expanded ? 'tabel-row-arrow-open' : ''} `}
                                                                    dangerouslySetInnerHTML={{ __html: Icons.general.group_arrow }}
                                                                    onClick={() => HandleExpandGroup(id, parent_id)}
                                                                ></div>
                                                                : ''}

                                                            <div
                                                                className="tabel-name"
                                                                onClick={(e) => HandleOpenItem(row)}
                                                            > {column.format ? column.format(value) : value}</div>

                                                        </div>

                                                        : column.id == 'status' ?

                                                            <div
                                                                className="tabel-status-main"
                                                            >

                                                                <span className={`tabel-status-span status-span-${value}`}></span>
                                                                <div style={{ display: 'none' }} className="tabel-status"> {column.format ? column.format(value) : value}</div>

                                                            </div>

                                                            : column.id == 'actions' ?

                                                                <div
                                                                    className="tabel-action-main"
                                                                >
                                                                    <div
                                                                        className="action-icon estimate-tabel-action-btn"
                                                                        id={`estimate-tabel-action-btn-${row.id}`}
                                                                        dangerouslySetInnerHTML={{ __html: Icons.general.dotted_action }}
                                                                        onClick={(e) => HanelCellAction(column.id, row)}
                                                                    ></div>
                                                                    <div
                                                                        className={`action-dropdown estimate-tabel-action-dropdown ${selectedAction == row.id ? 'action-dropdown-active' : ''}`}
                                                                        id={`estimate-tabel-action-dropdown${row.id}`}
                                                                    >
                                                                        {ActionDropdown.map(option => (
                                                                            <div
                                                                                className="action-dropdown-item"
                                                                                key={`clients-action${option.id}`}
                                                                                onClick={(e) => option.click(row)}
                                                                            >
                                                                                <div
                                                                                    className="icon"
                                                                                    dangerouslySetInnerHTML={{ __html: option.icon }}
                                                                                ></div>
                                                                                <div className="label">{option.label}</div>
                                                                            </div>
                                                                        ))}
                                                                    </div>
                                                                </div>
                                                                : column.format ? column.format(value) : value
                                                }
                                            </TableCell>
                                );
                            })}
                        </TableRow>
                        {sub_items.map((item, idxx) => (
                            CreateCostcatalogTableItem(item, idxx, sub_space_px + 20, parent_expand ? expanded : parent_expand)
                        ))}
                    </>
                    : ''}
            </>
        )
    }

    const HandleDeleteItem = async (item) => {

        setDeleteItem(item.id)
        setDeleteItemType(item.type)
        setDeleteItemAlert(true)
    }

    const getSubItemIds = (items, parentid) => {

        let subItems = []

        let getItems = (items, parentid) => {

            if (!Array.isArray(items)) return

            let _items = items.filter(itm => itm.parent_id == parentid)

            subItems.push(..._items)

            _items.forEach(itm => getItems(items, itm.id))
        }

        getItems(items, parentid)

        return subItems.map(itm => itm.id)
    }

    const HandleDeleteConfirmItem = async (id, type, confirmation) => {

        console.log(confirmation, 'confirmation');
        if (!confirmation || !id) {

            setDeleteItem(null)
            setDeleteItemType(null)
            setDeleteItemAlert(false)

            return
        }


        let ids = getSubItemIds([...costcatalogItemsOrginal], id)

        let filter = {
            ids: [id, ...ids],
        }

        setIsLoading(true)

        let response = await costCatalogHandler.deleteCostCatalogItemHandler(filter)
        setIsLoading(false)

        if (response.success) {
            LoadCostcatalogItems()
        }
        else {
            setWarningAlert(true)
            setWarningAlertType('error')
            setApiFailedMessage(`Error in Cost Catalog, Please try again!`)
        }
        setDeleteItem(null)
        setDeleteItemType(null)
        setDeleteItemAlert(false)
    }

    const HandleAddBtn = (e) => {

        setIsAddBtnClicked(true)

        let items_parent = document.querySelector('#pd_estimate-addbtn')

        window.addEventListener('click', (e) => {

            let path = e.path || (e.composedPath && e.composedPath());

            if (Array.isArray(path) && !path.includes(items_parent))
                setIsAddBtnClicked(false)
        })
    }
    const HandleAddBtnOption = (option) => {
        if (!option) return

        navigate(`/builder/cost-catalog/${option.id}/add`)
    }

    const getDeleteMessage = (type) => {

        if (type == "group") return `Are you sure you want to delete the Group? Items included in the group will be deleted accordingly.`
        else if (type == "work") return `Are you sure you want to delete the Work?`
        else if (type == "item") return `Are you sure you want to delete the Item?`
        else return "Are you sure you want to delete?"
    }


    const [Filters, setFilters] = useState({
        left: [
            {
                id: "estimate-multiselect",
                type: "icon",
                isSelectToggle: true,
                value: false,
                placeholder: "",
                callback: (val) => HandleFiltersEvent(val, 'estimate-multiselect'),
                isIcon: true,
                icon: Icons.general.multi_select,
                isLabel: false,
                label: "",
                isIconLeft: false
            },
            {
                id: "estimate-select",
                isSearchBar: false,
                type: "button",
                isDropDownContainer: true,
                placeholder: "",
                dropDownOptions: [
                    {
                        type: "option",
                        label: "Select all",
                        id: 'opt-select-all',
                        value: false,
                    },
                    {
                        type: "label",
                        label: "By Type",
                    },
                    {
                        type: "option",
                        label: "Material",
                        id: 'opt-type-material',
                        value: false,
                    },
                    {
                        type: "option",
                        label: "Labour",
                        id: 'opt-type-labour',
                        value: false,
                    },
                    {
                        type: "option",
                        label: "Machine",
                        id: 'opt-type-machine',
                        value: false,
                    },
                    {
                        type: "option",
                        label: "Subcontractor",
                        id: 'opt-type-subcontractor',
                        value: false,
                    },
                    {
                        type: "option",
                        label: "Fee",
                        id: 'opt-type-fee',
                        value: false,
                    },
                    {
                        type: "option",
                        label: "Allowances",
                        id: 'opt-type-allowances',
                        value: false,
                    },
                ],
                callback: (val) => HandleFiltersEvent(val, 'estimate-select'),
                isIcon: true,
                icon: Icons.general.dropdown_arrow,
                isLabel: true,
                label: "Select",
                isIconLeft: false
            },
            {
                id: "estimate-search",
                value: '',
                isSearchBar: true,
                type: "input",
                isDropDownContainer: false,
                placeholder: "Search by name",
                dropDownOptions: [],
                callback: (val) => { },

                isIcon: true,
                icon: Icons.general.search,
                isLabel: false,
                label: "",
                isIconLeft: false
            },
            {
                id: "estimate-expand",
                value: false,
                type: "icon",
                isSelectToggle: true,
                placeholder: "",
                callback: (val) => { },
                isIcon: true,
                icon: Icons.general.expand,
                isLabel: true,
                label: "Expand all",
                isIconLeft: true
            },
        ],
        right: [
            {
                id: "estimate-filter",
                isSearchBar: false,
                type: "button",
                isDropDownContainer: true,
                dropDownOptions: [
                    {
                        type: "option",
                        label: "Select all",
                        id: 'opt-select-all',
                        value: true,
                    },
                    {
                        type: "label",
                        label: "By Type",
                    },
                    {
                        type: "option",
                        label: "Material",
                        id: 'opt-type-material',
                        value: true,
                    },
                    {
                        type: "option",
                        label: "Labour",
                        id: 'opt-type-labour',
                        value: true,
                    },
                    {
                        type: "option",
                        label: "Machine",
                        id: 'opt-type-machine',
                        value: true,
                    },
                    {
                        type: "option",
                        label: "Subcontractor",
                        id: 'opt-type-subcontractor',
                        value: true,
                    },
                    {
                        type: "option",
                        label: "Fee",
                        id: 'opt-type-fee',
                        value: true,
                    },
                    {
                        type: "option",
                        label: "Allowances",
                        id: 'opt-type-allowances',
                        value: true,
                    },
                ],
                callback: (val) => { },
                isIcon: true,
                icon: Icons.general.filter_by,
                isLabel: false,
                label: "",
                isIconLeft: false
            },
            {
                id: "estimate-show_hide",
                isSearchBar: false,
                type: "button",
                isDropDownContainer: true,
                dropDownOptions: [
                    {
                        type: "option",
                        label: "Select all",
                        id: 'select-all',
                        value: true,
                    },
                    {
                        id: 'name',
                        label: 'Name',
                        value: true,
                    },
                    {
                        id: 'cost_type',
                        label: 'Cost Type',
                        value: true,

                    },
                    {
                        id: 'quantity',
                        label: 'Quantity',
                        value: true,
                    },
                    {
                        id: 'unitrate',
                        label: 'Unit rate',
                        value: true,
                    },
                    {
                        id: 'builder_price',
                        label: 'Prime cost',
                        value: true,
                    },
                    {
                        id: 'markup_amount',
                        label: 'Markup',
                        value: true,
                    },
                    {
                        id: 'tax_amount',
                        label: 'Tax',
                        value: true,
                    },
                    {
                        id: 'price',
                        label: 'Final price',
                        value: true,
                    },
                ],
                callback: (val) => { },
                isIcon: true,
                icon: Icons.general.show_hide,
                isLabel: false,
                label: "",
                isIconLeft: false
            },
        ]
    })
    const HandleFiltersEvent = async (value, filter_id) => {

        if (!filter_id) return

        if (filter_id == 'estimate-multiselect') {

            let _tabelHeader = [...tabelHeader]

            _tabelHeader[0].disable = !_tabelHeader[0].disable

            setTabelHeader(_tabelHeader)

        }
        else if (filter_id == 'estimate-expand') {

            let filters = { ...Filters }

            filters.left[3].value = value

            let _costcatalogItems = [...costcatalogItems]

            let expand_by_group = (items, value) => {

                const expandItem = (items, value) => {

                    items = items.map(item => {

                        if (item.type != 'item') item.expanded = value
                        if (Array.isArray(item.sub_items)) item.sub_items = expandItem(item.sub_items, value)

                        return item
                    })

                    return items
                }

                items = expandItem(items, value)

                return items
            }

            _costcatalogItems = expand_by_group(_costcatalogItems, value)
            setCostcatalogItems(_costcatalogItems)

            setFilters(filters)

        }
        else if (filter_id == 'estimate-select') {

            let filters = { ...Filters }

            let select_types_index = filters.left[1].dropDownOptions.findIndex(v => v.id == value.id)
            filters.left[1].dropDownOptions[select_types_index].value = value.value

            if (value.id == 'opt-select-all') HandleSelectAllEvent(value.value)
            else {

                let type = String(value.id).split('-')[1]
                let filter_by = String(value.id).split('-')[2]

                let _costcatalogItems = [...costcatalogItems]

                if (type == 'status') {

                    let select_by_group = (items, status, value) => {

                        const selectItem = (items, status, value) => {

                            items = items.map(item => {

                                if (item.status == status) {
                                    item.selected = value
                                }
                                if (Array.isArray(item.sub_items)) item.sub_items = selectItem(item.sub_items, status, value)

                                return item
                            })

                            return items
                        }

                        items = selectItem(items, status, value)

                        return items
                    }

                    _costcatalogItems = select_by_group(_costcatalogItems, filter_by, value.value)

                }
                if (type == 'type') {

                    let select_by_group = (items, type, value) => {

                        const selectItem = (items, type, value) => {

                            items = items.map(item => {

                                if (item.item_type == type) {
                                    item.selected = value
                                }
                                if (Array.isArray(item.sub_items)) item.sub_items = selectItem(item.sub_items, type, value)

                                return item
                            })

                            return items
                        }

                        items = selectItem(items, type, value)

                        return items
                    }

                    _costcatalogItems = select_by_group(_costcatalogItems, filter_by, value.value)

                }


                setCostcatalogItems(_costcatalogItems)
            }


            setFilters(filters)
        }
        else if (filter_id == 'estimate-search') {

            let filters = { ...Filters }

            filters.left[2].value = value

            let _costcatalogItems = [...costcatalogItems]

            let search_items = (items, value, column = 'name') => {

                let search_item = (items, value, column = 'name') => {

                    items = items.map(item => {

                        let is_item_match = String(item[column]).toLocaleLowerCase().includes(String(value).toLocaleLowerCase())

                        if (Array.isArray(item.sub_items)) item.sub_items = search_item(item.sub_items, value, column)

                        let is_items_match = item.sub_items.filter(s => !s.disable).length > 0

                        if (is_items_match) item.disable = false
                        else if (is_item_match) item.disable = false
                        else item.disable = true

                        return item

                    })

                    return items

                }

                items = search_item(items, value, column)
                return items
            }

            _costcatalogItems = search_items(_costcatalogItems, value, 'name')

            setCostcatalogItems(_costcatalogItems)

            setFilters(filters)

        }
        else if (filter_id == 'estimate-filter') {

            let filters = { ...Filters }

            filters.right[0].value = value

            let _costcatalogItems = [...costcatalogItems]

            if (value.id == 'opt-select-all') {

                _costcatalogItems = _costcatalogItems.map(e => {
                    e.disable = !value.value

                    return e
                })

            }
            else {

                let type = String(value.id).split('-')[1]
                let filter_by = String(value.id).split('-')[2]

                if (type == 'status') {

                    let filter_by_status = (items, status, value) => {

                        const filterItem = (items, status, value) => {

                            items = items.map(item => {

                                if (item.status == status) {
                                    item.disable = !value
                                }
                                if (Array.isArray(item.sub_items)) item.sub_items = filterItem(item.sub_items, status, value)

                                return item
                            })

                            return items
                        }

                        items = filterItem(items, status, value)

                        return items
                    }

                    _costcatalogItems = filter_by_status(_costcatalogItems, filter_by, value.value)


                }
                else if (type == 'type') {

                    let filter_by_type = (items, type, value) => {

                        const filterItem = (items, type, value) => {

                            items = items.map(item => {

                                if (item.type == 'item' && item.item_type == type) item.disable = !value
                                if (Array.isArray(item.sub_items)) item.sub_items = filterItem(item.sub_items, type, value)

                                return item
                            })

                            return items
                        }

                        items = filterItem(items, type, value)

                        return items
                    }

                    _costcatalogItems = filter_by_type(_costcatalogItems, filter_by, value.value)

                }


                setCostcatalogItems(_costcatalogItems)
            }

            setFilters(filters)

        }
        else if (filter_id == 'estimate-show_hide') {

            let _tabelHeader = [...tabelHeader]

            if (!value.id) return

            if (value.id == 'select-all') { }
            else {
                let head_idx = _tabelHeader.findIndex(h => h.id == value.id)
                _tabelHeader[head_idx].disable = !value.value

                setTabelHeader(_tabelHeader)
            }



        }
    }

    const isLeftFilter = Array.isArray(Filters.left) && Filters.left.length > 0
    const isRightFilter = Array.isArray(Filters.right) && Filters.right.length > 0

    const Icon_Filter = ({ props }) => {
        const {
            id,
            isSearchBar,
            type,
            value,
            isSelectToggle,
            isDropDownContainer,
            dropDownOptions,
            placeholder,
            callback,
            isIcon,
            icon,
            isLabel,
            label,
            isIconLeft,
        } = props

        const [iconStatus, setIconStatus] = useState(value != undefined ? value : false)

        const HandleFilterIconInput = (e) => {


            HandleFiltersEvent(!iconStatus, id)
            setIconStatus(!iconStatus)
        }

        return (
            <>
                {isIcon && type == 'icon' ?
                    <div
                        className="filter-item group-expand-filter"
                        onClick={(e) => HandleFilterIconInput(e)}
                        key={`filter-item-${id}`}
                    >
                        <div
                            className="icon"
                            dangerouslySetInnerHTML={{ __html: id.includes('expand') ? iconStatus ? Icons.general.collapse : Icons.general.expand : icon }}
                        ></div>
                        {isLabel ? <div className="label">{id.includes('expand') ? iconStatus ? 'Collapse all' : 'Expand all' : label}</div> : ''}
                    </div>

                    : null}
            </>
        )
    }
    const Button_Filter = ({ props }) => {
        const {
            id,
            isSearchBar,
            type,
            isDropDownContainer,
            dropDownOptions,
            callback,
            isIcon,
            icon,
            isLabel,
            label,
            isIconLeft,
        } = props
        const [selectFilterOpts, setSelectFilterOpts] = useState([])

        const [dropdownStatus, setDropDownStatus] = useState(false)

        const HandleClick = (e) => {
            setDropDownStatus(!dropdownStatus)
        }

        let HandleSubItemChange = (value, opt, i) => {

            let _filters = [...selectFilterOpts]

            if (_filters[i].id == 'opt-select-all') {
                _filters = _filters.map(f => {
                    f.value = value

                    return f
                })
            } else _filters[i].value = value

            setSelectFilterOpts(_filters)

            HandleFiltersEvent(opt, id)
        }

        useEffect(() => {

            let items_parent = document.querySelector(`#${id}`)

            window.addEventListener('click', (e) => {

                let path = e.path || (e.composedPath && e.composedPath());

                if (Array.isArray(path) && !path.includes(items_parent))
                    setDropDownStatus(false)
            })
            setSelectFilterOpts(dropDownOptions)
        }, [])


        return (
            <div className='filter-item dropdown-select-filter' id={id} key={`filter-item-${id}`}>
                <div
                    className="button"
                    onClick={(e) => HandleClick(e)}
                >
                    {isIcon && isIconLeft ?
                        <span
                            className={`icon ${dropdownStatus ? 'icon-active' : ''}`}
                            dangerouslySetInnerHTML={{ __html: icon }}
                        ></span>
                        : null}

                    {isLabel ? <span class='label'>{label}</span> : null}

                    {isIcon && !isIconLeft ?
                        <span
                            className={`icon ${dropdownStatus ? 'icon-active' : ''}`}
                            dangerouslySetInnerHTML={{ __html: icon }}
                        ></span>
                        : null}
                </div>

                {isDropDownContainer ?

                    <div className={`dropdown-select-items ${dropdownStatus ? 'dropdown-select-items-active' : ''}`}>

                        {selectFilterOpts.map((opt, i) => (

                            opt.type == 'label' ?
                                <div className="dropdown-select-title">{opt.label}</div>
                                :
                                <div className="dropdown-select-itm">
                                    <RadioInput props={{
                                        id: `filters-item-select-${opt.id}`,
                                        value: opt.value,
                                        isIcon: false,
                                        icon: "",
                                        inputType: "checkbox",
                                        name: "table-default-radio",
                                        setValue: (value) => HandleSubItemChange(value, opt, i)
                                    }} />
                                    <label className='label' htmlFor={`filters-item-select-${opt.id}`}>{opt.label}</label>
                                </div>

                        ))}

                    </div>

                    : null}
            </div>

        )
    }





    return (
        <>

            {isLoading ?

                <Loading
                    props={{
                        isMainLogo: false,
                        isLabel: true
                    }} />

                : null}

            {warningAlert ?

                <SystemToastPopup
                    props={{
                        type: warningAlertType,
                        message: apiFailedMessage || "Error in Cost Catalog, Please try again!",
                        callback: (confirmation) => setWarningAlert(false)
                    }} />

                : null}

            {deleteItemAlert ?

                <AlertPopup
                    props={{
                        type: "delete",
                        actionBtnOption: { label: "Yes, Delete" },
                        heading: "Delete item",
                        message: getDeleteMessage(deleteItemType),
                        callback: (confirmation) => HandleDeleteConfirmItem(deleteItemId, deleteItemType, confirmation)
                    }} />

                : null}

            <Routes>
                <Route path='/:itemType/:itemId/:actionType/*'
                    element={<AddCostCatalogItemPopup
                        props={{
                            existItemData: existItemData || undefined,
                            updateCallback: HandleUpdateCallback
                        }}
                    />}>
                </Route>
                <Route path='/:itemType/:actionType/*'
                    element={<AddCostCatalogItemPopup
                        props={{
                            existItemData: undefined,
                            updateCallback: HandleUpdateCallback
                        }}
                    />}
                ></Route>
            </Routes>

            <div className="project-project_estimate-main">
                <div className="project_estimate-panel-content">

                    <div className="panel-content-header">
                        <div className="title">Cost Catalog</div>
                        <div className="buttons">
                            <div
                                className="button add-button"
                                onClick={(e) => HandleAddBtn(e)}
                                id="pd_estimate-addbtn"
                            >
                                <div className="icon" dangerouslySetInnerHTML={{ __html: Icons.general.add_btn }}></div>
                                <div className="label">Add Item</div>
                            </div>
                            <div id="pd_estimate-content-btn-options" className={`pd_estimate-content-btn-options ${isAddBtnClicked ? 'pd_estimate-content-btn-options-active' : ''}`}>

                                {AddBtnOptions.map((option, i) => (
                                    <div
                                        key={`estimate-add-option-${option.id}`}
                                        className="btn-option-item"
                                        onClick={(e) => HandleAddBtnOption(option)}
                                    >
                                        <div
                                            className="icon"
                                            dangerouslySetInnerHTML={{ __html: option.icon }}
                                        ></div>
                                        <div className="label">{option.label}</div>
                                    </div>
                                ))}

                            </div>
                        </div>
                    </div>
                    <div className="filter-section-main">
                        {isLeftFilter ?

                            < div className="left-filter-main">
                                {Filters.left.map((item, i) => (

                                    item.type == 'icon' ? <Icon_Filter props={item} />
                                        : item.type == 'button' ? <Button_Filter props={item} />
                                            : item.isSearchBar && item.type == 'input' ?
                                                <SearcBarInput
                                                    props={{
                                                        id: item.id,
                                                        value: item.value,
                                                        placeholder: item.placeholder || "Search by name",
                                                        setValue: (val) => {
                                                            HandleFiltersEvent(val, item.id)
                                                        },
                                                        isIcon: item.isIcon,
                                                        isLeft: item.isIconLeft,
                                                        icon: item.icon || Icons.general.search
                                                    }}
                                                />
                                                : ''
                                ))}
                            </div>
                            : ''}
                        {isRightFilter ?

                            < div className="right-filter-main">
                                {Filters.right.map((item, i) => (

                                    item.type == 'icon' ? <Icon_Filter props={item} />
                                        : item.type == 'button' ? <Button_Filter props={item} />
                                            : item.isSearchBar && item.type == 'input' ?
                                                <SearcBarInput
                                                    props={{
                                                        id: item.id,
                                                        value: item.value,
                                                        placeholder: item.placeholder || "Search by name",
                                                        setValue: (val) => {
                                                            HandleFiltersEvent(val, item.id)
                                                        },
                                                        isIcon: item.isIcon,
                                                        isLeft: item.isIconLeft,
                                                        icon: item.icon || Icons.general.search
                                                    }}
                                                />
                                                : ''
                                ))}
                            </div>
                            : ''}
                    </div>
                    <div className="panel-content-sections">
                        <Paper style={{ width: '100%', height: '100%', boxShadow: 'none', overflow: 'hidden' }}>
                            <TableContainer style={{ height: '100%', boxShadow: 'none !important', }} >
                                <Table stickyHeader>
                                    <TableHead
                                        className='tabel-header estimate-tabel-header'
                                    >
                                        <TableRow>
                                            {tabelHeader.filter(th => !th.disable).map((column) => (

                                                <TableCell
                                                    key={column.id}
                                                    align={column.align}
                                                    style={{ minWidth: column.minWidth }}
                                                    className='tabel-header-items'
                                                >
                                                    {column.id == 'select' ?

                                                        <RadioInput props={{
                                                            value: tableSelectAll || false,
                                                            isIcon: false,
                                                            icon: "",
                                                            inputType: "checkbox",
                                                            name: "table-default-radio",
                                                            setValue: (value) => HandleSelectAllEvent(value)
                                                        }} />
                                                        :
                                                        column.label}
                                                </TableCell>
                                            ))}
                                        </TableRow>
                                    </TableHead>
                                    <TableBody className='tabel-body'>
                                        {costcatalogItems.map((row, i) => (
                                            CreateCostcatalogTableItem(row, i, 0, true)
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Paper>
                    </div>
                </div>
            </div>
        </>
    )

}

export default CostCatalog;