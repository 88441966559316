import React, { useEffect, useState, useRef } from 'react'
import { Route, Routes, BrowserRouter, Link, useLocation, Navigate, Outlet, useNavigate, useParams } from 'react-router-dom';

import ReverseProxy from '../../config/reverseProxy'
import Builder from '../../Handlers/Builder/Builder';
import Icons from '../../assets/Icons'
import Images from '../../assets/Images'

import AlertPopup from '../../components/AlertPopup'
import Loading from '../../components/Loading'
import SystemToastPopup from '../../components/SystemToastPopup'

const OnBoarding = () => {

    const navigate = useNavigate();
    const builderHandler = new Builder()

    const [isLoading, setIsLoading] = useState(false)
    const [warningAlert, setWarningAlert] = useState(false)
    const [warningAlertType, setWarningAlertType] = useState('warning')
    const [apiFailedMessage, setApiFailedMessage] = useState("Error in Server")

    const [OnBoarding_Sections, setOnBoarding_Sections] = useState([
        {
            id: 'onboarding-section-1',
            has_welcome: true,
            title: 'Welcome to Civilator, Ruban! 🎉',
            description: `Let's get your account set up to make sure you get the most out of Civilator!`,

            input_items: [
                {
                    id: 'location-input',
                    label: 'Where is your company located?',
                    type: 'text',
                    value: '',
                    select_options: [],
                    placeholder: '123, Main street, Chennai...'
                },
                {
                    id: 'country-input',
                    label: 'What is your country?',
                    type: 'select',
                    value: 'india',
                    select_options: [
                        {
                            lable: 'Select country',
                            value: ''
                        },
                        {
                            lable: 'India',
                            value: 'india'
                        },
                        {
                            lable: 'USA',
                            value: 'usa'
                        },
                        {
                            lable: 'UK',
                            value: 'uk'
                        },
                        {
                            lable: 'Australia',
                            value: 'australia'
                        },
                        {
                            lable: 'Canada',
                            value: 'canada'
                        },
                        {
                            lable: 'Others',
                            value: 'others'
                        },
                    ],
                    placeholder: '123, Main street, Chennai...'
                },
                {
                    id: 'builder_type-input',
                    label: 'What type of projects you do?',
                    type: 'select',
                    value: '',
                    select_options: [
                        {
                            lable: 'Select project type',
                            value: ''
                        },
                        {
                            lable: 'Residential',
                            value: 'residential'
                        },
                        {
                            lable: 'Commercial',
                            value: 'commercial'
                        },
                        {
                            lable: 'Interior Fit-outs',
                            value: 'interior'
                        },
                        {
                            lable: 'Govt. Projects',
                            value: 'govt'
                        },
                        {
                            lable: 'Infrastructure',
                            value: 'infrastructure'
                        },
                        {
                            lable: 'Others',
                            value: 'others'
                        },
                    ],
                    placeholder: ''
                },
            ]
        },
        {
            id: 'onboarding-section-2',
            has_welcome: true,
            title: 'Help us personalise your expirience!',
            description: ``,

            input_items: [
                {
                    id: 'team_size-input',
                    label: 'What is the size of your team?',
                    type: 'select',
                    value: '',
                    select_options: [
                        {
                            lable: 'Select team size',
                            value: ''
                        },
                        {
                            lable: '1-10',
                            value: '1-10'
                        },
                        {
                            lable: '11-50',
                            value: '11-50'
                        },
                        {
                            lable: '50-100',
                            value: '50-100'
                        },
                        {
                            lable: '100-200',
                            value: '100-200'
                        },
                        {
                            lable: '200+',
                            value: '200+'
                        },
                    ],
                    placeholder: ''
                },
                {
                    id: 'user_role-input',
                    label: 'How would you describe your role?',
                    type: 'select',
                    value: '',
                    select_options: [
                        {
                            lable: 'Select your role',
                            value: ''
                        },
                        {
                            lable: 'Site team',
                            value: 'site'
                        },
                        {
                            lable: 'Projects team',
                            value: 'projects'
                        },
                        {
                            lable: 'Planning team',
                            value: 'planning'
                        },
                        {
                            lable: 'Finance team',
                            value: 'finance'
                        },
                        {
                            lable: 'Founder/CXO',
                            value: 'founder'
                        },
                    ],
                    placeholder: ''
                },
                {
                    id: 'main_goal-input',
                    label: 'What is the main thing you want to manage?',
                    type: 'select',
                    value: '',
                    select_options: [
                        {
                            lable: 'Select your goal',
                            value: ''
                        },
                        {
                            lable: 'Project Site Reporting and Tracking',
                            value: 'site_reporting_tracking'
                        },
                        {
                            lable: 'Estimation, Budgeting & Planning',
                            value: 'estimation_budgeting_planning'
                        },
                        {
                            lable: 'Purchase & Financial',
                            value: 'purchas_finance'
                        },
                        {
                            lable: 'All of the above',
                            value: 'all'
                        },
                    ],
                    placeholder: ''
                },

            ]
        },

        {
            id: 'onboarding-section-3',
            has_welcome: true,
            title: 'Import Demo Project',
            description: `Explore all the features of Civilator with a demo project filled with data`,
            is_import_section: true,
            value: false,
            input_items: []
        },
    ])

    const [current_Screen, setCurrent_Screen] = useState(0)

    const HandleNextBtn = async (e, index) => {

        e.preventDefault()

        if (OnBoarding_Sections.length - 1 == index) HandleSubmitBtn()
        else if (OnBoarding_Sections.length - 1 >= index + 1) setCurrent_Screen(index + 1)

    }
    const HandleStepperClick = (index) => {

        if (OnBoarding_Sections.length - 1 >= index) setCurrent_Screen(index)
    }
    const HandleSubmitBtn = async () => {


        let onboarding_data = {
            onboarding_step: 1,
            location: OnBoarding_Sections[0].input_items[0].value,
            country: OnBoarding_Sections[0].input_items[1].value,
            builder_type: OnBoarding_Sections[0].input_items[2].value,

            team_size: OnBoarding_Sections[1].input_items[0].value,
            user_role: OnBoarding_Sections[1].input_items[1].value,
            main_goal: OnBoarding_Sections[1].input_items[2].value,

            import_demo_project: OnBoarding_Sections[2].value,
        }

        let response = await builderHandler.updateOnBoardingStatusHandler(onboarding_data)

        if (response.success) {
            window.location.href = `${ReverseProxy['proxyUrl']}/quick-menu`

        } else {

            setWarningAlert(true)
            setWarningAlertType('error')
            setApiFailedMessage('Error in updating On-boarding')

        }
    }

    const HandleInputChange = (sectionid, input_id, value) => {

        let sections = [...OnBoarding_Sections]

        let section_index = sections.findIndex((item) => item.id == sectionid)

        let input_index = sections[section_index].input_items.findIndex((item) => item.id == input_id)

        sections[section_index].input_items[input_index].value = value

        setOnBoarding_Sections(sections)
    }

    const HandleImportSection = (sectionid, value) => {

        let sections = [...OnBoarding_Sections]

        let section_index = sections.findIndex((item) => item.id == sectionid)

        sections[section_index].value = value

        setOnBoarding_Sections(sections)
    }



    return (
        <>
            {isLoading ?

                <Loading
                    props={{
                        isMainLogo: false,
                        isLabel: true
                    }} />

                : null}

            {warningAlert ?

                <SystemToastPopup
                    props={{
                        type: warningAlertType,
                        message: apiFailedMessage || "Error in OnBoarding",
                        callback: (confirmation) => setWarningAlert(false)
                    }} />

                : null}

            <div className="project-onboarding-main">
                <div className="onboarding-content-main">
                    <div className="onboarding-header">
                        <div className="logo">
                            <img src={Images.logo} alt="" />
                        </div>
                    </div>
                    <div className="onboarding-detials-main">

                        <div className="onboarding-stepper-main">
                            <div className="onboarding-stepper-bgline"></div>

                            {
                                OnBoarding_Sections?.map((section, index) => (

                                    <div
                                        className={`onboarding-item ${current_Screen >= index ? 'onboarding-item-completed' : ''} `}
                                        key={`onboarding-stepper-item-${section.id}`}

                                        onClick={(e) => HandleStepperClick(index)}
                                    >

                                        {current_Screen >= index ?
                                            <div
                                                className="item-icon"
                                                dangerouslySetInnerHTML={{ __html: Icons.general.tick_mark }}
                                            ></div>
                                            :
                                            <div className="item-circle"> </div>
                                        }
                                    </div>

                                ))
                            }

                        </div>
                        <div className="onboarding-sections-main">

                            {OnBoarding_Sections?.map((section, index) => (

                                <form
                                    className={`onboarding-section-item ${current_Screen == index ? 'onboarding-section-item-active' : ''}`}
                                    key={section.id}
                                    onSubmit={(e) => HandleNextBtn(e, index)}
                                >

                                    {section.has_welcome ?
                                        <div className="onboarding-welcome-detials">
                                            <div className="title">{section.title}</div>
                                            <div className="desc">{section.description}</div>
                                        </div>
                                        : ''}

                                    {!section.is_import_section ?
                                        <div className="onboarding-section-input-items">
                                            {section.input_items?.map((item, index) => (

                                                <div
                                                    className="onboarding-input-item"
                                                    key={`onboarding-input-item-${item.id}`}
                                                >
                                                    <div className="label">{item.label}</div>
                                                    <div className="input">

                                                        {item.type == 'select' ?
                                                            <select
                                                                placeholder={item.placeholder}
                                                                value={item.value}
                                                                onChange={(e) => HandleInputChange(section.id, item.id, e.target.value)}
                                                                required={true}
                                                            >
                                                                {item.select_options?.map((option, index) => (
                                                                    <option value={option.value}>{option.lable}</option>
                                                                ))}
                                                            </select>
                                                            :
                                                            <input
                                                                type={item.type || 'text'}
                                                                required={true}
                                                                placeholder={item.placeholder}
                                                                value={item.value}
                                                                onChange={(e) => HandleInputChange(section.id, item.id, e.target.value)}
                                                            />

                                                        }
                                                    </div>
                                                </div>

                                            ))}

                                        </div>
                                        :
                                        <div className="onboarding-import-section">




                                            <div
                                                className={`import-section-item ${section.value ? 'import-section-item-active' : ''}`}
                                                onClick={(e) => HandleImportSection(section.id, true)}
                                            >

                                                <div
                                                    className="selected-checkbox"
                                                    dangerouslySetInnerHTML={{ __html: Icons.general.tick_mark }}
                                                ></div>

                                                <div className="icon" dangerouslySetInnerHTML={{ __html: Icons.general.takeoff }}></div>
                                                <div className="label">Import demo project</div>
                                            </div>
                                            <div
                                                className={`import-section-item ${!section.value ? 'import-section-item-active' : ''}`}
                                                onClick={(e) => HandleImportSection(section.id, false)}
                                            >
                                                <div
                                                    className="selected-checkbox"
                                                    dangerouslySetInnerHTML={{ __html: Icons.general.tick_mark }}
                                                ></div>

                                                <div className="icon" dangerouslySetInnerHTML={{ __html: Icons.general.takeoff }}></div>
                                                <div className="label">Create new project</div>
                                            </div>
                                        </div>
                                    }

                                    <div className="onboarding-section-footer">
                                        <div className="footer-buttons">
                                            <button
                                                className="footer-next-buttom"
                                                type='submit'
                                            >Next</button>

                                            {index > 0 ?
                                                <div
                                                    className="footer-skip-buttom"
                                                    onClick={(e) => HandleStepperClick(index - 1)}
                                                >Go back</div>
                                                : ''}
                                        </div>
                                    </div>
                                </form>

                            ))}

                        </div>

                    </div>
                </div>
            </div>

        </>
    )
}

export default OnBoarding;