

import React, { useEffect, useState, useRef } from 'react'
import { Route, Routes, BrowserRouter, Link, useLocation, Navigate, Outlet, useNavigate, useParams } from 'react-router-dom';
import store from '../../redux/store'
import StoreHandler from '../../redux/StoreHandler'
import { useSelector, useDispatch } from 'react-redux'
import UserAction from '../../redux/action/userAction'

import ReverseProxy from '../../config/reverseProxy'
import IssuesManagementHandler from '../../Handlers/IssuesManagement/IssuesManagement';
import Icons from '../../assets/Icons'
import Images from '../../assets/Images'
import { CostInput, TextInput, SelectInput, SearcBarInput, RadioInput } from '../../components/Inputs'
import Utils from '../../utils'
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

import AlertPopup from '../../components/AlertPopup'
import SystemToastPopup from '../../components/SystemToastPopup'
import Loading from '../../components/Loading'
import NoDataFound from '../../components/NoDataFound';
import AddIssuePopup from './AddIssuePopup'


import { useAuth, useMenuAuth } from '../../hooks/useAuth'
const IssuesManagement = () => {

    const { projectId } = useParams()

    const [project_id, setProject_id] = useState('')
    const [project_name, setProject_name] = useState('')
    const [estimate_id, setEstimate_id] = useState('')

    const [isLoading, setIsLoading] = useState(false)
    const [warningAlert, setWarningAlert] = useState(false)
    const [warningAlertType, setWarningAlertType] = useState('warning')
    const [apiFailedMessage, setApiFailedMessage] = useState('Error in Issues')

    const [deleteItemId, setDeleteItem] = useState(null)
    const [deleteItemType, setDeleteItemType] = useState(null)
    const [deleteItemAlert, setDeleteItemAlert] = useState(false)

    const issuesManagementHandler = new IssuesManagementHandler()

    const [currency_type, setCurrency_type] = useState('₹')

    const navigate = useNavigate()
    const store = useSelector((store) => store)
    const dispatch = useDispatch()
    const { updateState } = new UserAction

    const storeHandler = new StoreHandler({
        dataStack: [`project-${projectId}`, `project_phases-${projectId}`],
        store: store,
        dispatch: dispatch,
        updateState: updateState,
    })

    const LoadStoreData = async () => {

        setIsLoading(true)
        let resolved = await storeHandler.init()
        setIsLoading(false)
    }

    const { isAdmin, menu_permission } = useMenuAuth({ menu: 'project_management--issues', feature_key: 'issue_management' })
    const location = useLocation()

    const [selected_view_settings_value, setSelected_view_settings_value] = useState('')
    const [has_view_access, setHas_view_access] = useState(false)
    const [has_add_access, setHas_add_access] = useState(false)
    const [has_edit_access, setHas_edit_access] = useState(false)
    const [has_delete_access, setHas_delete_access] = useState(false)

    useEffect(() => {

        if (isAdmin) {

            setHas_view_access(true)
            setHas_add_access(true)
            setHas_edit_access(true)
            setHas_delete_access(true)
        }

        else if (menu_permission) {

            if (
                !menu_permission._view
                &&
                (!menu_permission.selected_view_settings_value
                    || menu_permission.selected_view_settings_value == 'no_access')
            ) {
                navigate(`${ReverseProxy['proxyUrl']}/quick-menu`, { state: { from: location }, replace: true })
            }

            if (menu_permission._view == '0') navigate(`${ReverseProxy['proxyUrl']}/builder/projects`, { state: { from: location }, replace: true })

            setHas_view_access(menu_permission._view == '1')
            setHas_add_access(menu_permission._create == '1')
            setHas_edit_access(menu_permission._edit == '1')
            setHas_delete_access(menu_permission._delete == '1')

            setSelected_view_settings_value(menu_permission.selected_view_settings_value)

        }

    }, [store])


    const [tabelHeader, setTabelHeader] = useState([

        {
            id: 'select',
            label: 'Select',
            minWidth: 30,
            disable: false,
        },

        {
            id: 'name',
            label: 'Name',
            minWidth: 200,
            disable: false,
        },
        {
            id: 'worktype_name',
            label: 'Work Type',
            disable: false,

        },
        {
            id: 'priority_name',
            label: 'Priority',
            disable: false,

        },
        {
            id: 'status',
            label: 'Status',
            disable: false,
        },
        {
            id: 'startdate',
            label: 'Start',
            disable: false,
        },
        {
            id: 'enddate',
            label: 'End',
            disable: false,
        },
        {
            id: 'assignee_name',
            label: 'Assignee',
            disable: false,
        },

        {
            id: 'work_done_progress_percentage',
            label: 'Completion',
            disable: false,
            format: (value) => `${value}%`,
        },
        {
            id: 'total_task_price',
            label: 'Price',
            format: (value) => `${currency_type}${parseFloat(value || 0).toFixed(2)}`,
            disable: false,
        },
        {
            id: 'actions',
            label: '',
            disable: false,
        },
    ])
    const [TasksOrginal, setTasksOrginal] = useState([])
    const [FilteredTasks, setFilteredTasks] = useState([])

    const [totalIssues, setTotalIssues] = useState({
        all: 0,
        open: 0,
        closed: 0
    })

    const [addBtnOption, setAddBtnOption] = useState({})
    const [existItemData, setExistItemData] = useState({})

    const [SelectedPhaseSection, setSelectedPhaseSection] = useState('all')

    const [ProjectPhases, setProjectPhases] = useState([])
    const [ProjectPhases_Options, setProjectPhases_Options] = useState([])

    const HandlePhaseSection = (section) => {
        setSelectedPhaseSection(section)
    }

    const HandleOpenItem = (item) => {
        setExistItemData(item)

        navigate(`/builder/projects/${projectId}/issues-management/${item.sno}/view`)
    }

    const LoadItems = async () => {

        let filter = {
            projectId: projectId
        }

        setIsLoading(true)

        let response = await issuesManagementHandler.getIssuesHandler(filter)
        setIsLoading(false)

        if (response.success) {


            let { data } = response

            if (!Array.isArray(data)) return

            data = data.map(d => {
                d.phases = d.phases || []

                return d
            })

            setTasksOrginal(data)

            setFilteredTasks(data.filter(d => d.status == '0'))

            let total_issues = {
                all: data.length,
                open: data.filter(d => d.status == '0').length,
                closed: data.filter(d => d.status == '1').length
            }

            setTotalIssues(total_issues)

        }
        else {
            setWarningAlert(true)
            setWarningAlertType('error')
            setApiFailedMessage(`Error in Issues`)
        }
    }

    const HandleUpdateCallback = () => {
        LoadItems()
    }


    const HandleAddNewIssue = () => {
        if (!has_add_access) return
        navigate(`/builder/projects/${projectId}/issues-management/add`)
    }


    const [Filters, setFilters] = useState({
        left: [


            {
                id: "estimate-search",
                value: '',
                isSearchBar: true,
                type: "input",
                isDropDownContainer: false,
                placeholder: "Search by name",
                dropDownOptions: [],
                callback: (val) => { },

                isIcon: true,
                icon: Icons.general.search,
                isLabel: false,
                label: "",
                isIconLeft: false
            },

        ],
        right: [

            {
                id: "estimate-download",
                type: "icon",
                isSelectToggle: true,
                value: false,
                placeholder: "",
                callback: (val) => HandleFiltersEvent(val, 'estimate-download'),
                isIcon: true,
                icon: Icons.general.download,
                isLabel: false,
                label: "Download",
                isIconLeft: true
            },

        ]
    })
    const HandleFiltersEvent = async (value, filter_id) => {

        if (!filter_id) return

        if (filter_id == 'estimate-expand') {

            let filters = { ...Filters }

            filters.left[1].value = value

            let _tasks = [...FilteredTasks]

            let expand_by_group = (items, value) => {

                const expandItem = (items, value) => {

                    items = items.map(item => {

                        return item
                    })

                    return items
                }

                items = expandItem(items, value)

                return items
            }

            _tasks = expand_by_group(_tasks, value)
            setFilteredTasks(_tasks)

            setFilters(filters)

        }

        else if (filter_id == 'estimate-search') {

            let filters = { ...Filters }

            filters.left[0].value = value

            let _tasks = [...FilteredTasks]

            let search_items = (items, value, column = 'name') => {

                let search_item = (items, value, column = 'name') => {

                    items = items.map(item => {

                        let is_item_match = String(item[column]).toLocaleLowerCase().includes(String(value).toLocaleLowerCase())

                        if (is_item_match) item.disable = false
                        else item.disable = true

                        return item

                    })

                    return items

                }

                items = search_item(items, value, column)
                return items
            }

            _tasks = search_items(_tasks, value, 'reason')

            setFilteredTasks(_tasks)

            setFilters(filters)

        }

        else if (filter_id == 'estimate-download') {
            let _tasks = [...FilteredTasks]

            let ordered_items = []

            let flatter_subitems = (items) => {

                if (Array.isArray(items)) {

                    items.forEach(item => {

                        ordered_items.push(item)

                        if (Array.isArray(item.sub_items)) flatter_subitems(item.sub_items)

                    })
                }

            }
            flatter_subitems(_tasks)

            let table_header = [...tabelHeader]

            table_header.splice(0, 1)
            table_header.splice(table_header.length - 1, 1)


            let export_header = table_header.filter(th => !th.disable).map(th => {

                let id = ['quantity', 'unitrate', 'builder_price', 'markup_amount', 'tax_amount', 'price'].includes(th.id) ? `_${th.id}` : th.id

                return {
                    id: id,
                    label: th.label,
                }
            })

            export_header.splice(1, 0, ...[
                {
                    id: 'parent_name',
                    label: "Parent Name",
                },
            ])



            Utils.exportEstimateToExcel(export_header, ordered_items, `${project_name} Estimate`)
        }

    }

    const isLeftFilter = Array.isArray(Filters.left) && Filters.left.length > 0
    const isRightFilter = Array.isArray(Filters.right) && Filters.right.length > 0

    const Icon_Filter = ({ props }) => {
        const {
            id,
            isSearchBar,
            type,
            value,
            isSelectToggle,
            isDropDownContainer,
            dropDownOptions,
            placeholder,
            callback,
            isIcon,
            icon,
            isLabel,
            label,
            isIconLeft,
        } = props

        const [iconStatus, setIconStatus] = useState(value != undefined ? value : false)

        const HandleFilterIconInput = (e) => {


            HandleFiltersEvent(!iconStatus, id)
            setIconStatus(!iconStatus)
        }

        return (
            <>
                {isIcon && type == 'icon' ?
                    <div
                        className="filter-item group-expand-filter"
                        onClick={(e) => HandleFilterIconInput(e)}
                        key={`filter-item-${id}`}
                    >
                        <div
                            className="icon"
                            dangerouslySetInnerHTML={{ __html: id.includes('expand') ? iconStatus ? Icons.general.collapse : Icons.general.expand : icon }}
                        ></div>
                        {isLabel ? <div className="label">{id.includes('expand') ? iconStatus ? 'Collapse all' : 'Expand all' : label}</div> : ''}
                    </div>

                    : null}
            </>
        )
    }
    const Button_Filter = ({ props }) => {
        const {
            id,
            isSearchBar,
            type,
            isDropDownContainer,
            dropDownOptions,
            callback,
            isIcon,
            icon,
            isLabel,
            label,
            isIconLeft,
        } = props
        const [selectFilterOpts, setSelectFilterOpts] = useState([])

        const [dropdownStatus, setDropDownStatus] = useState(false)

        const HandleClick = (e) => {
            setDropDownStatus(!dropdownStatus)
        }

        let HandleSubItemChange = (value, opt, i) => {

            let _filters = [...selectFilterOpts]

            if (_filters[i].id == 'opt-select-all') {
                _filters = _filters.map(f => {
                    f.value = value

                    return f
                })
            } else _filters[i].value = value

            setSelectFilterOpts(_filters)

            HandleFiltersEvent(opt, id)
        }

        useEffect(() => {

            let items_parent = document.querySelector(`#${id}`)

            window.addEventListener('click', (e) => {

                let path = e.path || (e.composedPath && e.composedPath());

                if (Array.isArray(path) && !path.includes(items_parent))
                    setDropDownStatus(false)
            })
            setSelectFilterOpts(dropDownOptions)
        }, [])


        return (
            <div className='filter-item dropdown-select-filter' id={id} key={`filter-item-${id}`}>
                <div
                    className="button"
                    onClick={(e) => HandleClick(e)}
                >
                    {isIcon && isIconLeft ?
                        <span
                            className={`icon ${dropdownStatus ? 'icon-active' : ''}`}
                            dangerouslySetInnerHTML={{ __html: icon }}
                        ></span>
                        : null}

                    {isLabel ? <span class='label'>{label}</span> : null}

                    {isIcon && !isIconLeft ?
                        <span
                            className={`icon ${dropdownStatus ? 'icon-active' : ''}`}
                            dangerouslySetInnerHTML={{ __html: icon }}
                        ></span>
                        : null}
                </div>

                {isDropDownContainer ?

                    <div className={`dropdown-select-items ${dropdownStatus ? 'dropdown-select-items-active' : ''}`}>

                        {selectFilterOpts.map((opt, i) => (

                            opt.type == 'label' ?
                                <div className="dropdown-select-title">{opt.label}</div>
                                :
                                <div className="dropdown-select-itm">
                                    <RadioInput props={{
                                        id: `filters-item-select-${opt.id}`,
                                        value: opt.value,
                                        isIcon: false,
                                        icon: "",
                                        inputType: "checkbox",
                                        name: "table-default-radio",
                                        setValue: (value) => HandleSubItemChange(value, opt, i)
                                    }} />
                                    <label className='label' htmlFor={`filters-item-select-${opt.id}`}>{opt.label}</label>
                                </div>

                        ))}

                    </div>

                    : null}
            </div>

        )
    }


    const [selectedToggleSection, setSelectedToggleSection] = useState('open')

    const HandleToggleSection = (id) => {
        setSelectedToggleSection(id)

        let _FilteredTasks = [...TasksOrginal]

        if (id == 'all') setFilteredTasks(_FilteredTasks)
        else if (id == 'open') setFilteredTasks(_FilteredTasks.filter(t => t.status == '0'))
        else if (id == 'closed') setFilteredTasks(_FilteredTasks.filter(t => t.status == '1'))

    }

    useEffect(() => {
        LoadStoreData()
        LoadItems()

    }, [])

    useEffect(() => {


        if (store && store.user) {

            let { selected_project, selected_project_phases } = store.user

            if (selected_project) {

                let { id, name } = selected_project

                setProject_id(id)
                setProject_name(name)
            }
            if (selected_project_phases) {

                setProjectPhases(selected_project_phases)

                let project_phases = selected_project_phases.map(phase => {
                    return {
                        id: phase.id,
                        name: `${phase.name} | ${phase.client_name}`,
                        count: 0
                    }
                })

                setProjectPhases_Options([
                    {
                        id: 'all',
                        name: 'All',
                        count: 0
                    },
                    ...project_phases
                ])

            }



        }


    }, [store])

    return (
        <>

            {isLoading ?

                <Loading
                    props={{
                        isMainLogo: false,
                        isLabel: true
                    }} />

                : null}

            {warningAlert ?

                <SystemToastPopup
                    props={{
                        type: warningAlertType,
                        message: apiFailedMessage || "Error in Issues",
                        callback: (confirmation) => setWarningAlert(false)
                    }} />

                : null}



            <Routes>
                <Route path='/:issueItemId/:issueActionType/*'
                    element={<AddIssuePopup
                        props={{
                            existItemData: existItemData || undefined,
                            updateCallback: HandleUpdateCallback
                        }}
                    />}>
                </Route>
                <Route path='/:issueActionType/*'
                    element={<AddIssuePopup
                        props={{
                            existItemData: undefined,
                            updateCallback: HandleUpdateCallback
                        }}
                    />}
                ></Route>
            </Routes>

            <div className="project-project_estimate-main">
                <div className="project_estimate-panel-content">

                    <div className="panel-content-header">
                        <div className="title">Issues</div>

                        <div className="buttons">
                            {has_add_access ?
                                <div
                                    className="button add-button"
                                    onClick={(e) => HandleAddNewIssue()}
                                    id="pd_estimate-addbtn"
                                >
                                    <div className="icon" dangerouslySetInnerHTML={{ __html: Icons.general.add_btn }}></div>
                                    <div className="label">New Issue</div>
                                </div>
                                : ''}
                        </div>
                    </div>
                    <div className="filter-section-main">
                        {isLeftFilter ?

                            < div className="left-filter-main">
                                {Filters.left.map((item, i) => (

                                    item.type == 'icon' ? <Icon_Filter props={item} />
                                        : item.type == 'button' ? <Button_Filter props={item} />
                                            : item.isSearchBar && item.type == 'input' ?
                                                <div className="search-filter">
                                                    <SearcBarInput
                                                        props={{
                                                            id: item.id,
                                                            value: item.value,
                                                            placeholder: item.placeholder || "Search by name",
                                                            setValue: (val) => {
                                                                HandleFiltersEvent(val, item.id)
                                                            },
                                                            isIcon: item.isIcon,
                                                            isLeft: item.isIconLeft,
                                                            icon: item.icon || Icons.general.search
                                                        }}
                                                    />
                                                </div>
                                                : ''
                                ))}
                            </div>
                            : ''}
                        {isRightFilter ?

                            < div className="right-filter-main">
                                {Filters.right.map((item, i) => (

                                    item.type == 'icon' ? <Icon_Filter props={item} />
                                        : item.type == 'button' ? <Button_Filter props={item} />
                                            : item.isSearchBar && item.type == 'input' ?
                                                <div className="search-filter">
                                                    <SearcBarInput
                                                        props={{
                                                            id: item.id,
                                                            value: item.value,
                                                            placeholder: item.placeholder || "Search by name",
                                                            setValue: (val) => {
                                                                HandleFiltersEvent(val, item.id)
                                                            },
                                                            isIcon: item.isIcon,
                                                            isLeft: item.isIconLeft,
                                                            icon: item.icon || Icons.general.search
                                                        }}
                                                    />
                                                </div>
                                                : ''
                                ))}
                            </div>
                            : ''}
                    </div>
                    <div className="project-phases-filter-main">
                        <div className="phases-filter-header">
                            <div className="title">Project Phases</div>
                        </div>
                        <div className="phases-filter-items">
                            {ProjectPhases_Options.map((opt, i) => (
                                <div
                                    key={`project-phases-filter-${opt.id}`}
                                    className={`phases_filter-item ${SelectedPhaseSection == opt.id ? 'phases_filter-item-active' : ''}`}
                                    onClick={(e) => HandlePhaseSection(opt.id)}
                                >
                                    <div className="label">{opt.name}</div>
                                </div>
                            ))}

                        </div>
                    </div>
                    <div className="panel-content-sections">

                        <div className="panel-issues-main">
                            <div className="panel-issues-sections-main">
                                <div className="issues-toggle-header">
                                    <div className="toggle-header-left">
                                        <div
                                            className={`toggle-header-item ${selectedToggleSection == 'open' ? 'toggle-header-item-active' : ''}`}
                                            onClick={() => HandleToggleSection('open')}
                                        >
                                            <div className="label">Open({totalIssues.open || 0})</div>
                                        </div>
                                        <div
                                            className={`toggle-header-item ${selectedToggleSection == 'closed' ? 'toggle-header-item-active' : ''}`}
                                            onClick={() => HandleToggleSection('closed')}
                                        >
                                            <div className="label">Closed({totalIssues.closed || 0})</div>
                                        </div>
                                        <div
                                            className={`toggle-header-item ${selectedToggleSection == 'all' ? 'toggle-header-item-active' : ''}`}
                                            onClick={() => HandleToggleSection('all')}
                                        >
                                            <div className="label">All({totalIssues.all || 0})</div>
                                        </div>

                                    </div>
                                </div>

                                <div className="issue-section-items">
                                    {
                                        FilteredTasks.filter(e => (SelectedPhaseSection == 'all' ? '' : SelectedPhaseSection) ? e.phases.includes(SelectedPhaseSection) : true).length ?
                                            FilteredTasks.filter(e => (SelectedPhaseSection == 'all' ? '' : SelectedPhaseSection) ? e.phases.includes(SelectedPhaseSection) : true).filter(d => !d.disable).map((issue, idx) => (

                                                <div
                                                    className="issue-section-item" key={issue.id}
                                                    onClick={() => HandleOpenItem(issue)}
                                                >
                                                    <div className="issue-item-left">
                                                        <div className="title">{issue.reason}</div>
                                                        <div className="desc">
                                                            <div className="issue-message">Deadline: <div className="strong"> {issue.due_date || "N/A"}</div></div>
                                                            <div className="issue-message">Added on {Utils.getLocalFullDateLabel(issue.created_at)} by {issue.created_by_name}</div>
                                                        </div>
                                                    </div>
                                                    <div className="issue-item-right">
                                                        <div className={`issue-status ${issue.status == '1' ? 'issue-status-closed' : 'issue-status-open'}`}>{issue.status == '1' ? 'Closed' : 'Open'}</div>
                                                    </div>
                                                </div>
                                            ))
                                            : <NoDataFound label={'No Issues Found'} />
                                    }

                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </>
    )

}

export default IssuesManagement;