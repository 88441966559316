

import React, { useEffect, useState, useRef } from 'react'
import { Route, Routes, BrowserRouter, Link, useLocation, Navigate, Outlet, useNavigate, useParams } from 'react-router-dom';
import store from '../../redux/store'
import StoreHandler from '../../redux/StoreHandler'
import { useSelector, useDispatch } from 'react-redux'
import UserAction from '../../redux/action/userAction'

import ReverseProxy from '../../config/reverseProxy'
import ChatsHandler from '../../Handlers/Chats/Chats';
import Icons from '../../assets/Icons'
import Images from '../../assets/Images'
import { SearcBarInput, TextInput, SelectInput, RadioInput } from '../../components/Inputs'
import Utils from '../../utils'
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

import AlertPopup from '../../components/AlertPopup'
import SystemToastPopup from '../../components/SystemToastPopup'
import Loading from '../../components/Loading'
import NoDataFound from '../../components/NoDataFound'

import { useAuth, useMenuAuth } from '../../hooks/useAuth'

import ChatMessagingMain from './chatMessagingMain';

const ChatMain = () => {

    const store = useSelector((store) => store)
    const dispatch = useDispatch()
    const { updateState } = new UserAction

    const navigate = useNavigate()

    const { projectId } = useParams()

    const { isAdmin, menu_permission } = useMenuAuth({ menu: 'safety--checklists', feature_key: 'checklists' })

    const location = useLocation()

    const [selected_view_settings_value, setSelected_view_settings_value] = useState('')
    const [has_view_access, setHas_view_access] = useState(false)
    const [has_add_access, setHas_add_access] = useState(false)
    const [has_edit_access, setHas_edit_access] = useState(false)
    const [has_delete_access, setHas_delete_access] = useState(false)

    useEffect(() => {

        if (isAdmin) {

            setHas_view_access(true)
            setHas_add_access(true)
            setHas_edit_access(true)
            setHas_delete_access(true)
        }

        else if (menu_permission) {


            if (
                !menu_permission._view
                &&
                (!menu_permission.selected_view_settings_value
                    || menu_permission.selected_view_settings_value == 'no_access')
            ) {
                navigate(`${ReverseProxy['proxyUrl']}/quick-menu`, { state: { from: location }, replace: true })
            }

            setHas_view_access(menu_permission._view == '1')
            setHas_add_access(menu_permission._create == '1')
            setHas_edit_access(menu_permission._edit == '1')
            setHas_delete_access(menu_permission._delete == '1')

            setSelected_view_settings_value(menu_permission.selected_view_settings_value)

        }

    }, [store])


    const storeHandler = new StoreHandler({
        dataStack: [`project_client-${projectId}`],
        store: store,
        dispatch: dispatch,
        updateState: updateState,
    })

    const LoadStoreData = async () => {
        // setIsLoading(true)
        let resolved = await storeHandler.init()
        // setIsLoading(false)
    }

    const chatsHandler = new ChatsHandler()

    const [isLoading, setIsLoading] = useState(false)
    const [warningAlert, setWarningAlert] = useState(false)
    const [warningAlertType, setWarningAlertType] = useState('warning')
    const [apiFailedMessage, setApiFailedMessage] = useState('Error in Projects')

    const HandleBackBtn = (e) => {
        if (projectId) return navigate(`${ReverseProxy['proxyUrl']}/builder/projects/${projectId}/dashboard`)
        else return navigate(`${ReverseProxy['proxyUrl']}/builder/projects`)
    }

    const [ChatLists, setChatLists] = useState([])

    const [OpenChattingContainer, setOpenChattingContainer] = useState(false)
    const [SelectedChatList, setSelectedChatList] = useState(null)

    const HandleChatListClick = (item) => {
        setSelectedChatList(item)
        setOpenChattingContainer(true)
    }

    const HandleCloseMessaging = () => {
        setOpenChattingContainer(false)
    }

    const getChatlistItems = async () => {


        let filter = {}

        if (projectId) {
            filter.project_id = projectId
            filter.only_projects = true

        }

        setIsLoading(true)
        let response = await chatsHandler.getChatListsHandler(filter)

        setIsLoading(false)

        if (response && response.success) {

            let chatlists = response.data || []

            chatlists = chatlists.map((item) => {

                let profile = item.profile ? `data:image/png;base64,${item.profile}` : Images.User

                return {
                    id: item.id,
                    profile: profile,
                    type: item.type,
                    name: item.name,
                    desc: item.desc,
                    project_id: item.project_id,
                    // desc_icon: Icons.general.msg_send,
                    team_users: item.team_users || [],
                    time: "10 minutes ago",
                }
            })

            setChatLists(chatlists || [])

            if (projectId) {
                let selected_chatlist = chatlists.find((item) => item.project_id == projectId)
                if (selected_chatlist) {
                    setSelectedChatList(selected_chatlist)
                    setOpenChattingContainer(true)
                }
            }

        } else {
            setWarningAlert(true)
            setWarningAlertType('error')
            setApiFailedMessage(`Error in Phases, Please try again!`)
        }
    }

    useEffect(() => {
        getChatlistItems()
    }, [])



    return (
        <>


            {isLoading ?

                <Loading
                    props={{
                        isMainLogo: false,
                        isLabel: true
                    }} />

                : null}

            {warningAlert ?

                <SystemToastPopup
                    props={{
                        type: warningAlertType,
                        message: apiFailedMessage || "Error in Projects",
                        callback: (confirmation) => setWarningAlert(false)
                    }} />
                : null}

            <div className="project-chat_screen-main">

                <div
                    className={`chat_screen-list_container-main ${projectId ? 'project-chat_screen-container-disabler' : ''} ${OpenChattingContainer ? 'chat_screen-container-disabler' : ''}`}
                >
                    <div className="list_container-header">
                        <div className="list_container-header-left">
                            <div className="header-backbtn"
                                dangerouslySetInnerHTML={{ __html: Icons.general.dropdown_arrow }}
                                onClick={HandleBackBtn}
                            ></div>
                            <div className="header-title">Chats</div>
                        </div>
                    </div>
                    <div className="list_container-content">
                        <div className="list_container-content-items">
                            {ChatLists.map((item, index) => (
                                <div
                                    className={`list_container-content-item ${SelectedChatList ? SelectedChatList.id == item.id ? 'list_container-content-item-active' : '' : ''}`}
                                    key={`chat-item-${index}`}
                                    onClick={() => HandleChatListClick(item)}
                                >
                                    <div className="item-profile">
                                        <img src={item.profile || Images.User} />
                                    </div>
                                    <div className="item-details">
                                        <div className="item-left">

                                            <div className="item-content-title">{item.name}</div>
                                            <div className="item-content-message">
                                                {item.desc_icon ?
                                                    <div className="icon"
                                                        dangerouslySetInnerHTML={{ __html: item.desc_icon }}
                                                    ></div>
                                                    : ''}
                                                <div className="label">{item.desc}</div>
                                            </div>
                                        </div>
                                        <div className="item-right">
                                            <div className="item-time">{item.time}</div>
                                            {item.pinned ?
                                                <div className="item-pinned"
                                                    dangerouslySetInnerHTML={{ __html: Icons.general.pin }}
                                                ></div>
                                                : ''}
                                        </div>
                                    </div>
                                </div>
                            ))}

                        </div>
                    </div>
                </div>

                <div
                    className={`chat_screen-messaging_container-main ${projectId ? 'project-chat_screen-messaging_container' : ''} ${OpenChattingContainer ? '' : 'chat_screen-container-disabler'}`}>
                    {!OpenChattingContainer ? <div className="messaging_container-start_msg-main">
                        <div className="image">
                            <img src={Images.ChatGif} />
                        </div>
                        <div className="label">Start communicating with your team!</div>
                    </div>
                        : ''
                    }

                    {OpenChattingContainer && SelectedChatList ?
                        <ChatMessagingMain props={{
                            chatlist_details: SelectedChatList,
                            close: HandleCloseMessaging
                        }} />
                        : ''
                    }
                </div>

            </div>
        </>
    )
}

export default ChatMain;