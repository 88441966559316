

import React, { useEffect, useState, useRef, useCallback, useMemo, StrictMode } from 'react'
import { Route, Routes, BrowserRouter, Link, useLocation, Navigate, Outlet, useNavigate, useParams } from 'react-router-dom';
import store from '../../redux/store'
import StoreHandler from '../../redux/StoreHandler'
import { useSelector, useDispatch } from 'react-redux'
import UserAction from '../../redux/action/userAction'

import ReverseProxy from '../../config/reverseProxy'
import SettingsHandler from '../../Handlers/Settings/Settings';
import EstimateHandler from '../../Handlers/Estimate/Estimate';
import PurchaseRequestHandler from '../../Handlers/PurchaseRequest/PurchaseRequest';
import CommentsHandler from '../../Handlers/Comments/Comments';

import Icons from '../../assets/Icons'
import Images from '../../assets/Images'
import { CostInput, TextInput, SelectInput, DateTimeInput, RadioInput, MultiSelectInput } from '../../components/Inputs'
import Utils from '../../utils'
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

import AlertPopup from '../../components/AlertPopup'
import SystemToastPopup from '../../components/SystemToastPopup'
import Loading from '../../components/Loading'
import PreviewPurchaseRequest from './previewPurchaseRequest'

import ImagePreviewPopup from '../../components/ImagePreviewPopup'
import CommentBox from '../../components/CommentBox'
import { useAuth, useMenuAuth } from '../../hooks/useAuth'

import SharePurchaseRequestPopup from './sharePurchaseRequestPopup'

const RequestSubmission = ({ props }) => {

    const { projectId, requestId, submissionId } = useParams();

    const { menuType = 'create' } = props

    const [isLoading, setIsLoading] = useState(false)
    const [warningAlert, setWarningAlert] = useState(false)
    const [warningAlertType, setWarningAlertType] = useState('warning')
    const [apiFailedMessage, setApiFailedMessage] = useState('Error in Purchase Order')

    const navigate = useNavigate()
    const store = useSelector((store) => store)
    const dispatch = useDispatch()
    const { updateState } = new UserAction

    const formSubmitBtnRef = useRef()

    const storeHandler = new StoreHandler({
        dataStack: ['team_users', 'vendors'],
        store: store,
        dispatch: dispatch,
        updateState: updateState,
    })

    const LoadStoreData = async () => {
        setIsLoading(true)
        let resolved = await storeHandler.init()
        setIsLoading(false)
    }

    const { isAdmin, menu_permission } = useMenuAuth({ menu: 'purchase--purchase_orders', feature_key: 'purchase-orders' })
    const location = useLocation()

    const [selected_view_settings_value, setSelected_view_settings_value] = useState('')
    const [has_view_access, setHas_view_access] = useState(false)
    const [has_add_access, setHas_add_access] = useState(false)
    const [has_edit_access, setHas_edit_access] = useState(false)
    const [has_delete_access, setHas_delete_access] = useState(false)

    useEffect(() => {

        if (isAdmin) {

            setHas_view_access(true)
            setHas_add_access(true)
            setHas_edit_access(true)
            setHas_delete_access(true)
        }

        else if (menu_permission) {

            if (
                !menu_permission._view
                &&
                (!menu_permission.selected_view_settings_value
                    || menu_permission.selected_view_settings_value == 'no_access')
            ) {
                navigate(`${ReverseProxy['proxyUrl']}/quick-menu`, { state: { from: location }, replace: true })
            }


            setHas_view_access(menu_permission._view == '1')
            setHas_add_access(menu_permission._create == '1')
            setHas_edit_access(menu_permission._edit == '1')
            setHas_delete_access(menu_permission._delete == '1')

            setSelected_view_settings_value(menu_permission.selected_view_settings_value)

        }

    }, [store])

    const purchaseRequestHandler = new PurchaseRequestHandler()
    const commentsHandler = new CommentsHandler()

    const [materialsOptions, setMaterialsOptions] = useState([])

    const [currency_type, setCurrency_type] = useState('₹')

    const [id, setId] = useState(Utils.getUniqueId())
    const [name, setName] = useState('')
    const [requestID, setRequestID] = useState('')
    const [status, setStatus] = useState('')

    const [introduction_text, setIntroduction_text] = useState('')

    const [termsConditionOptions, setTermsConditionOptions] = useState([])
    const [termsConditionId, setTermsConditionId] = useState("")
    const [termsConditionValue, setTermsConditionValue] = useState("")

    const [AttachmentsFiles, setAttachmentsFiles] = useState([])

    const [OpeningDate, setOpeningDate] = useState(Utils._getLocalDate())
    const [DueDate, setDueDate] = useState('')
    const [DeliveryDeadlineDate, setDeliveryDeadlineDate] = useState('')

    const [FinalTotal, FinalsetTotal] = useState(0)
    const [Signature, setSignature] = useState(undefined)
    const [VendorName, setVendorName] = useState('')

    const [Submission, setSubmission] = useState(undefined)
    const [SubmissionDate, setSubmissionDate] = useState(Utils._getLocalDate())
    const [SubmissionStatus, setSubmissionStatus] = useState(false)


    const [materialItems, setMaterialItems] = useState([
        {
            id: Utils.getUniqueId(),
            material_id: "",
            material_name: "",
            unitrate: 0,
            max_unitrate: 0,
            unit_id: '',
            unit_name: '',
            quantity: 0,
            description: '',
            notes: ''
        }
    ])

    const HandleCancel = (e) => {

        navigate(`/builder/projects/${projectId}/purchase_request/${requestId}/responses`)
    }

    const LoadClientInvoice = async () => {

        let filter = {
            requestId: requestId,
            projectId: projectId,
        }

        setIsLoading(true)

        let response = await purchaseRequestHandler.getPurchaseRequestsHandler(filter)
        setIsLoading(false)

        if (response.success) {
            let { data } = response

            if (!Array.isArray(data) || !data.length) {
                return navigate(`/builder/projects/${projectId}/purchase_request`)
            }


            let {
                id: ID,
                name,
                requestID,
                projectId: projectID,
                opening_date,
                due_date,
                introduction_text,
                delivery_deadline_date,

                status,
                terms_condition_id,
                terms_condition,
                attachments,
                cost_items,
                submissions = [],
                assignees,
                vendors
            } = data[0]

            setId(ID)
            setRequestID(requestID)
            setName(name)
            setStatus(status)

            setIntroduction_text(introduction_text)

            setTermsConditionId(terms_condition_id)
            setTermsConditionValue(terms_condition)


            setOpeningDate(opening_date)
            setDueDate(due_date)
            setDeliveryDeadlineDate(delivery_deadline_date)


            if (submissions && submissions.length) {

                let submission = submissions.find(submission => submission.id == submissionId)

                if (submission) {

                    let { id, ven_id, vendor_id, vendor_name, vendor_email, submission_status, submission_date, cost_items, signature, created_at, updated_at } = submission

                    setSubmission(submission)
                    setVendorName(vendor_name || '')
                    setSubmissionStatus(submission_status)
                    setSubmissionDate(Utils.getLocalFullDateBYFormat(submission_date))
                    setSignature(signature)
                    setMaterialItems(cost_items || [])

                }
            }

        }
    }

    useEffect(() => {


        LoadClientInvoice()
        // LoadStoreData()
    }, [])

    const HandleCreatePO = () => {

        if (!Submission) {
            setWarningAlert(true)
            setWarningAlertType('warning')
            setApiFailedMessage('Error in Purchase Order, Please try again!')
            return
        }

        let po_data = {
            ...Submission,
            name: name,
            delivery_deadline_date: DeliveryDeadlineDate,
            description: introduction_text,
            terms_condition_id: termsConditionId,
            terms_condition: termsConditionValue,
            attachments: AttachmentsFiles,

        }

        navigate(`/builder/projects/${projectId}/purchase_order/create?po_data=${JSON.stringify(po_data)}`)
    }


    useEffect(() => {

        if (store && store.user) {

            let { team_users, vendors } = store.user





        }

    }, [store])


    return (
        <>


            {isLoading ?
                <Loading
                    props={{
                        isMainLogo: false,
                        isLabel: true
                    }} />
                : null}
            {warningAlert ?

                <SystemToastPopup
                    props={{
                        type: warningAlertType,
                        message: apiFailedMessage || "Error in Purchase Order, Please try again!",
                        callback: (confirmation) => setWarningAlert(false)
                    }} />

                : null}


            <div className="project-create_client_invoice-main" id='project-create_client_invoice-main'>
                <div className="create_client_invoice-panel-content">

                    <div className="panel-content-header">
                        <div className="left">
                            <div className="icon"
                                dangerouslySetInnerHTML={{ __html: Icons.general.back_arrow }}
                                onClick={(e) => HandleCancel(e)}
                            ></div>
                            <div className="title">RFQ Detials</div>
                        </div>
                        <div className="right">

                            <div className="preview-button" onClick={() => HandleCreatePO()}>
                                <div
                                    className="icon"
                                    dangerouslySetInnerHTML={{ __html: Icons.general.send_message }}
                                ></div>
                                <div className="label">Convert as PO</div>
                            </div>

                        </div>
                    </div>
                    <form className="panel-content-sections">
                        <div className="create-client_invoicecontent-main">

                            <div className="create-client_invoicesections" style={{ height: '100%' }}>

                                <div
                                    className="create-client_invoicesection create-proposal-section"
                                    id={`create-client_invoice_payment_history`}

                                >
                                    <div className={`createproposal-description-main`}>

                                        <div className="head">
                                            <div className="title">
                                                <div className="label">Materials</div>
                                            </div>
                                        </div>


                                        <div className="preview-costItems-table-main">
                                            <div className="preview-costItems-table-header">
                                                <div className="header-item table-item-description">Item details</div>
                                                <div className="header-item table-item-quandity">Quandity</div>
                                                <div className="header-item table-item-quandity">Max Unit price</div>
                                                <div className="header-item table-item-unit_price">Unit price</div>
                                                <div className="header-item table-item-amount">Amount</div>

                                            </div>
                                            <div className="preview-costItems-table-content">

                                                {materialItems?.map((item, i) => (
                                                    <div
                                                        className="content-row"
                                                        key={`preview-costItems-table-item-${item.id}`}
                                                    >
                                                        <div className="content-row-item table-item-description">
                                                            <div className="title">{i + 1}. {item.material_name} </div>
                                                            {item.description && <div className="desc">{item.description} </div>}
                                                        </div>
                                                        <div className="content-row-item table-item-quandity">{item.quantity} {item.unit_name}</div>
                                                        <div className="content-row-item table-item-quandity">{currency_type}{item.max_unitrate || 'N/A'}</div>
                                                        <div className="content-row-item table-item-unit_price">{currency_type}{item.unitrate}</div>
                                                        <div className="content-row-item table-item-amount">{currency_type}{item.amount || '00'}</div>

                                                    </div>
                                                ))}

                                            </div>

                                        </div>



                                    </div>
                                </div>
                                <div className="create-proposal-section" id={`create-proposal-summary`}>
                                    <div className="createproject-section proposal-left-section">
                                        <div className="proposal-summary-totals proposal-summary-title">
                                            <div className="title">Summary</div>
                                        </div>
                                        <div className="proposal-summary-totals proposal-summary-items">

                                            <div className="proposal-summary-item">
                                                <div className="proposal-summary-item-content">
                                                    <div className="item">
                                                        <div className="title">Total</div>
                                                        <div className="quantity"></div>
                                                        <div className="amount">
                                                            {currency_type}{parseFloat(FinalTotal || 0).toFixed(2)}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                {Signature &&
                                    <div className="section-signature-main">
                                        <div className="signature-title">Submitted</div>
                                        <div className="signature-desc">{VendorName}</div>
                                        <div className="signature-desc">{SubmissionDate}</div>
                                        <div className="signature-img">
                                            <img src={Signature} alt="" />
                                        </div>
                                    </div>
                                }
                            </div>

                        </div>
                    </form>
                </div >

            </div >
        </>
    )

}

export default RequestSubmission;