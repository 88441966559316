

import React, { useEffect, useState, useRef } from 'react'
import { Route, Routes, BrowserRouter, Link, useNavigate, useParams, useLocation } from 'react-router-dom'
import store from '../../redux/store'
import StoreHandler from '../../redux/StoreHandler'
import { useSelector, useDispatch } from 'react-redux'
import UserAction from '../../redux/action/userAction'

import ReverseProxy from '../../config/reverseProxy'
import WorkRatesHandler from '../../Handlers/WorkRates/WorkRates'
import WorkTemplatesHandler from '../../Handlers/WorkTemplates/WorkTemplates'
import ScheduleRatesHandler from '../../Handlers/ScheduleRates/ScheduleRates'
import Icons from '../../assets/Icons'
import Images from '../../assets/Images'
import { CostInput, TextInput, SelectInput, QuantityInput, RadioInput } from '../../components/Inputs'
import Utils from '../../utils'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'

import AlertPopup from '../../components/AlertPopup'
import Loading from '../../components/Loading'
import SystemToastPopup from '../../components/SystemToastPopup'



import { useAuth, useMenuAuth } from '../../hooks/useAuth'
const AddWork = ({ props }) => {

    const { existItemData, updateCallback } = props

    const { itemType, itemId, actionType } = useParams()

    const [isLoading, setIsLoading] = useState(false)
    const [warningAlert, setWarningAlert] = useState(false)
    const [warningAlertType, setWarningAlertType] = useState('warning')
    const [apiFailedMessage, setApiFailedMessage] = useState("Error in Server")

    const workRatesHandler = new WorkRatesHandler()
    const workTemplatesHandler = new WorkTemplatesHandler()

    const [currency_type, setCurrency_type] = useState('₹')

    const navigate = useNavigate()
    const store = useSelector((store) => store)
    const dispatch = useDispatch()
    const { updateState } = new UserAction

    const storeHandler = new StoreHandler({
        dataStack: ['costcodes', 'company', 'field_settings'],
        store: store,
        dispatch: dispatch,
        updateState: updateState,
    })

    const LoadStoreData = async () => {
        setIsLoading(true)
        let resolved = await storeHandler.init()
        setIsLoading(false)
    }

    const AddBtnOptions = [
        {
            icon: Icons.general.work,
            label: 'Work',
            type: 'item',
            id: "work"
        },

    ]


    const [id, setId] = useState('')


    const [name, setName] = useState('')
    const [description, setDescription] = useState('')


    const [additionalNotes, setAdditionalNotes] = useState(false)
    const [work_ids, setWork_ids] = useState([])

    const isExistItemData = existItemData && existItemData.id != undefined


    const [selectedWorks, setselectedWorks] = useState([])
    const [works_Orginal, setWorks_Orginal] = useState([])
    const [workOptions, setWorkOptions] = useState([])

    const HandleWorkSelected = (value) => {

        if (!value) return

        let work_option_idx = workOptions.findIndex(w => w.id == value)

        let _workOptions = [...workOptions]

        _workOptions.splice(work_option_idx, 1)

        let _selectedWorks = [...selectedWorks]

        let work = works_Orginal.filter(w => w.id == value)[0]
        let already_work_exist = _selectedWorks.filter(w => w.id == value)[0]

        if (work && !already_work_exist) {
            _selectedWorks.push(work)
            setselectedWorks(_selectedWorks)
        }

        setWorkOptions(_workOptions)
    }

    const getEstimateItemTypeEenem = (type) => {

        if (type == '1') return 'group'
        else if (type == '2') return 'work_template'
        else if (type == '3') return 'item'
        else if (type == '4') return 'work'
        else return ''

    }
    const getEstimateItemCategoryEenem = (type) => {

        if (type == '1') return 'material'
        else if (type == '2') return 'labour'
        else if (type == '3') return 'machine'
        else if (type == '4') return 'subcontractor'
        else if (type == '5') return 'fee'
        else if (type == '6') return 'allowance'
        else return ''

    }
    const getEstimateItemStatusEenem = (type) => {

        if (type == '0') return 'incomplete'
        else if (type == '1') return 'completed'
        else if (type == '2') return 'hold'
        else if (type == '3') return 'stopped'
        else return ''
    }
    const getSubItem = (items, parentid) => {
        let subItems = []

        let getItems = (items, parentid) => {
            if (!Array.isArray(items)) return

            let _items = items.filter((itm) => itm.parent_id == parentid)

            subItems.push(..._items)

            _items.forEach((itm) => getItems(items, itm.id))
        }

        getItems(items, parentid)

        return subItems
    }
    const getFormatedEstimateItem = (item) => {

        return {
            sno: item.sno,
            expanded: true,
            selected: false,
            disable: false,

            parent_id: item.parent_id,
            parent_name: item.parent_name,
            id: item.id,
            name: item.name,
            description: item.description,

            costcode_id: item.costcode_id || "",
            costcode_name: item.costcode_name || "",

            co_efficient: parseFloat(item.co_efficient || 0) || 0,
            per_co_efficient_cost: parseFloat(item.per_co_efficient_cost || 0) || 0,

            type: getEstimateItemTypeEenem(item.type),
            item_type: item.type != '3' ? getEstimateItemTypeEenem(item.type) : getEstimateItemCategoryEenem(item.item_type),
            cost_type: item.type != '1' && item.type != '3' ? getEstimateItemTypeEenem(item.type) : getEstimateItemCategoryEenem(item.item_type),
            _status: item.status,
            status: getEstimateItemStatusEenem(item.status),

            parent_type: parseInt(item.parent_type),
            parent_quantity: parseFloat(item.parent_quantity || 0),

            quantity: parseFloat(item.quantity || 0) || 0,
            unitrate: parseFloat(item.unitrate || 0) || 0,
            builder_price: parseFloat(item.builder_price || 0) || 0,
            price: parseFloat(item.price || 0) || 0,


            unit_id: item.unit_id || "",
            unit_name: item.unit_name || "",

            markup_id: item.markup_id || "",
            markup_name: item.markup_name || "",
            markup_value: parseFloat(item.markup_value || 0) || 0,
            markup_value_type: item.markup_value_type || "",
            markup_amount: parseFloat(item.markup_amount || 0) || 0,

            tax_id: item.tax_id || "",
            tax_name: item.tax_name || "",
            tax_value_type: item.tax_value_type || "",
            tax_value: parseFloat(item.tax_value || 0) || 0,
            tax_amount: parseFloat(item.tax_amount || 0) || 0,

            sub_items: []
        }

    }

    const LoadWorks = async () => {

        let response = await workRatesHandler.getWorkRatesItemsHandler()

        if (response.success) {


            let { data } = response

            if (!Array.isArray(data)) return

            console.log(data, 'data');

            let items = data.map((d) => {
                let item = getFormatedEstimateItem(d)
                let subItems = getSubItem(data, d.id).map((itm) => getFormatedEstimateItem(itm))

                let tax_amount = item.tax_amount
                let markup_amount = item.markup_amount
                let builder_price = item.builder_price
                let price = item.price

                let subItems_Total = subItems.reduce(
                    (prev, val, ind) => {
                        return {
                            tax_amount: prev.tax_amount + val.tax_amount,
                            markup_amount: prev.markup_amount + val.markup_amount,
                            builder_price: prev.builder_price + val.builder_price,
                            price: prev.price + val.price,
                        }
                    },
                    { tax_amount: 0, markup_amount: 0, builder_price: 0, price: 0 }
                )

                tax_amount = parseFloat(tax_amount + (subItems_Total.tax_amount || 0)).toFixed(2)
                markup_amount = parseFloat(markup_amount + (subItems_Total.markup_amount || 0)).toFixed(2)
                builder_price = parseFloat(builder_price + (subItems_Total.builder_price || 0)).toFixed(2)
                price = parseFloat(price + (subItems_Total.price || 0)).toFixed(2)

                if (item.parent_id) {
                    let parent_item = data.filter((d) => d.id == item.parent_id)[0]
                    if (parent_item) {
                        item.parent_type = parent_item.type
                        if (parent_item.type == "4")
                            item.parent_quantity = parent_item.quantity
                    }
                }

                item._tax_amount = tax_amount || ""
                item._tax_percent = ((tax_amount / builder_price) * 100 || 0).toFixed(
                    2
                )
                item._markup_amount = markup_amount || ""
                item._markup_percent = (
                    (markup_amount / builder_price) * 100 || 0
                ).toFixed(2)
                item._builder_price = builder_price || ""
                item._price = price || ""
                item._unitrate = item.unitrate || ""
                item._quantity = item.quantity || ""

                item.tax_amount = tax_amount ? `${currency_type}${tax_amount}` : ""
                item.markup_amount = markup_amount
                    ? `${currency_type}${markup_amount}`
                    : ""
                item.builder_price = builder_price
                    ? `${currency_type}${builder_price}`
                    : ""
                item.price = price ? `${currency_type}${price}` : ""
                item.unitrate = item.unitrate
                    ? `${currency_type}${item.unitrate}`
                    : ""
                item.quantity = item.quantity
                    ? `${item.quantity}${item.unit_name || ""}`
                    : ""
                return item
            }).filter(d => d.type == 'work')

            let works_options = items.map(d => {
                return {
                    value: d.id,
                    label: d.name,
                    isDefault: false
                }
            })

            setWorks_Orginal(items)
            setWorkOptions(works_options)


        }
        else {
            setWarningAlert(true)
            setWarningAlertType('error')
            setApiFailedMessage(`Error in loading works`)
        }
    }

    const LoadWorkItem = async (sno) => {

        if (sno == undefined) return

        let filter = {
            idx: sno
        }

        setIsLoading(true)

        let response = await workTemplatesHandler.getWorkTemplatesHandler(filter)
        setIsLoading(false)

        if (response.success) {


            let { data } = response


            if (!Array.isArray(data) || !data.length) return


            let item = data[0]

            setId(item.id)

            setName(item.name || '')

            setDescription(item.description || '')

            setAdditionalNotes(item.description ? true : false)

            setWork_ids(item.work_ids || [])

        }
        else {
            setWarningAlert(true)
            setWarningAlertType('error')
            setApiFailedMessage(`Error in loading work item`)
        }
    }

    useEffect(() => {

        let works = work_ids.map(id => {
            let work = works_Orginal.filter(w => w.id == id)[0]
            if (work) return work
        })

        setselectedWorks(works)


    }, [works_Orginal])


    useEffect(() => {

        if (actionType == 'edit' && isExistItemData) {

            setName(existItemData.name || '')
            setAdditionalNotes(existItemData.description ? true : false)
            setDescription(existItemData.description || '')

            setWork_ids(existItemData.work_ids || [])
        }
        if (actionType == 'edit' && !isExistItemData) LoadWorkItem(itemId)

        LoadWorks()
        LoadStoreData()

    }, [])

    useEffect(() => {

        if (store && store.user) {

            let { company } = store.user



            if (company) {

                let { currency_setting, timezone, temperature_setting } = company

                if (currency_setting) setCurrency_type(currency_setting)
            }

        }

    }, [store])




    const HandlePopupSubmit = async (e) => {
        e.preventDefault()

        let updated_data = {

            name,
            description,
            work_ids: selectedWorks.map(s => s.id)

        }

        setIsLoading(true)

        let response = { success: false }

        if (actionType == 'edit' && isExistItemData && existItemData.id) {
            updated_data['id'] = existItemData.id
            response = await workTemplatesHandler.updateWorkTemplateHandler(updated_data)
        }
        else if (actionType == 'add') response = await workTemplatesHandler.createWorkTemplateHandler(updated_data)

        setIsLoading(false)

        if (response.success) {

            if (typeof updateCallback == 'function') updateCallback()
            navigate(`/builder/work-templates`)
        }
        else {
            setWarningAlert(true)
            setWarningAlertType('error')
            setApiFailedMessage(`Error in work template`)
        }


    }


    const Popup_Header = ({ props }) => {

        return (
            <div className="side-popup-header">
                <div className="header-item-select">
                    <div className="header-item-select-content">
                        <span className="icon" dangerouslySetInnerHTML={{ __html: Icons.general.work }}></span>
                        <div className="label">{actionType == 'edit' ? 'Update' : 'Add'} Work Template</div>
                    </div>
                </div>
                <div
                    className="header-item-close"
                    onClick={(e) => {
                        navigate(`/builder/work-templates`)
                    }}
                    dangerouslySetInnerHTML={{ __html: Icons.general.close }}
                ></div>
            </div>
        )
    }
    const Popup_Footer = ({ props }) => {

        return (
            <div className="sidebar-popup-footer">
                <div className="footer-item action-items">
                    <div className="footer-item-left">

                        <div className="action-preview">
                            <div className="label">Total works</div>
                            <div className="value">{selectedWorks.length}</div>
                        </div>

                    </div>
                    <button
                        className="action-btn"
                        type='submit'
                    >

                        <div className="label">Save</div>
                    </button>
                </div>
            </div>
        )
    }
    const HandleRemoveWorkItem = (index) => {
        let _selectedWorks = [...selectedWorks]

        let work = selectedWorks[index]
        let work_options = [...workOptions]

        work_options.push({
            value: work.id,
            label: work.name
        })

        setWorkOptions(work_options)

        if (_selectedWorks.length) _selectedWorks.splice(index, 1)

        setselectedWorks(_selectedWorks)
    }


    return (
        <>

            <div className="popup-container-main popup-container-scroll-center">
                <div className="popup-block-ui"></div>
                <form className="side-popup-container" onSubmit={(e) => {
                    HandlePopupSubmit(e)
                }}>
                    <Popup_Header />

                    <div className="sidebar-popup-content">

                        <div className="content-section-title">GENERAL INFO</div>
                        <div className="content-section content-top-main">

                            <div className="content-top-items">

                                <div className="content-item" style={{ width: '350px' }}>
                                    <TextInput
                                        props={{
                                            value: name,
                                            placeholder: '',
                                            setValue: (value) => setName(value),
                                            isIcon: false,
                                            isLabel: true,
                                            isRequired: true,
                                            label: "Name",
                                            id: "costcatalog-work-name"
                                        }}
                                    />
                                </div>
                                <div className="content-item" style={{ width: '350px' }}>
                                    <SelectInput
                                        props={{
                                            id: "costcatalog-add-work",
                                            value: '',
                                            placeholder: '',
                                            options: [
                                                {
                                                    value: "",
                                                    label: "Select Work"
                                                }, ...workOptions
                                            ] || [],
                                            setValue: (value, label) => HandleWorkSelected(value),
                                            isIcon: false,
                                            isLabel: true,
                                            isRequired: true,
                                            label: "Add Work",

                                        }}
                                    />
                                </div>
                            </div>

                            <div className="content-item">
                                <div className={`additional-item ${additionalNotes ? 'additional-item-active' : ''}`}>
                                    <div className="head" onClick={(e) => setAdditionalNotes(!additionalNotes)}>
                                        <div className="icon" dangerouslySetInnerHTML={{ __html: Icons.general.description }}></div>
                                        <div className="label">Description</div>
                                    </div>
                                    <div className="item-expanded">
                                        <ReactQuill
                                            theme="snow"
                                            value={description}
                                            onChange={(value) => setDescription(value)}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="content-section-title">WORKS</div>
                        <div className="content-section content-section-items">
                            {selectedWorks.map((item, index) => (

                                <div className="content-section-item" key={index} >
                                    <div className="left">
                                        <div
                                            className="icon"
                                            dangerouslySetInnerHTML={{ __html: Icons.general.work }}
                                        ></div>
                                        <div className="detials">
                                            <div className="title">{item.name}</div>
                                            <div className="desc">
                                                <div className="label">Quantity</div>
                                                <div className="value">{item.quantity}</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="right">
                                        <div className="right-items">
                                            <div className="right-item">
                                                <div className="label">Markup</div>
                                                <div className="value">{item.markup_amount}</div>
                                            </div>
                                            <div className="right-item">
                                                <div className="label">Tax</div>
                                                <div className="value">{item.tax_amount}</div>
                                            </div>
                                            <div className="right-item">
                                                <div className="label">Final Amount</div>
                                                <div className="value">{item.price}</div>
                                            </div>
                                        </div>
                                        <div className="right-action">
                                            <div className="right-action-item">
                                                <div
                                                    className="icon"
                                                    dangerouslySetInnerHTML={{ __html: Icons.general.delete_btn }}
                                                    onClick={(e) => HandleRemoveWorkItem(index)}
                                                ></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                            {!selectedWorks.length ?
                                <div className="content-section-noresult">
                                    <div
                                        className="icon"
                                        dangerouslySetInnerHTML={{ __html: Icons.general.noresult }}
                                    ></div>
                                    <div className="label">No Works added yet</div>
                                </div>
                                : ''}
                        </div>

                    </div>
                    <Popup_Footer />
                </form>
            </div>
        </>
    )

}


const AddCostCatalogItemPopup = ({ props }) => {

    const { existItemData, updateCallback } = props

    const { itemId, actionType } = useParams()

    const navigate = useNavigate()
    const store = useSelector((store) => store)
    const dispatch = useDispatch()
    const { updateState } = new UserAction

    const { isAdmin, menu_permission } = useMenuAuth({ menu: 'master_features--work_templates' })
    const location = useLocation()

    const [selected_view_settings_value, setSelected_view_settings_value] = useState('')
    const [has_view_access, setHas_view_access] = useState(false)
    const [has_add_access, setHas_add_access] = useState(false)
    const [has_edit_access, setHas_edit_access] = useState(false)
    const [has_delete_access, setHas_delete_access] = useState(false)

    useEffect(() => {

        if (isAdmin) {

            setHas_view_access(true)
            setHas_add_access(true)
            setHas_edit_access(true)
            setHas_delete_access(true)
        }

        else if (menu_permission) {

            if (
                !menu_permission._view
                &&
                (!menu_permission.selected_view_settings_value
                    || menu_permission.selected_view_settings_value == 'no_access')
            ) {
                navigate(`${ReverseProxy['proxyUrl']}/quick-menu`, { state: { from: location }, replace: true })
            }

            if (actionType == 'create' && menu_permission._create == '0') navigate(`${ReverseProxy['proxyUrl']}/builder/projects`, { state: { from: location }, replace: true })
            if (actionType == 'edit' && menu_permission._edit == '0') navigate(`${ReverseProxy['proxyUrl']}/builder/projects`, { state: { from: location }, replace: true })

            setHas_view_access(menu_permission._view == '1')
            setHas_add_access(menu_permission._create == '1')
            setHas_edit_access(menu_permission._edit == '1')
            setHas_delete_access(menu_permission._delete == '1')

            setSelected_view_settings_value(menu_permission.selected_view_settings_value)

        }

    }, [store])

    return (

        <>
            {actionType == 'add' ? <AddWork props={{ updateCallback }} />
                : actionType == 'edit' ? <AddWork props={{ existItemData, updateCallback }} /> : ''}
        </>

    )

}

export default AddCostCatalogItemPopup