
import proxyConfig from '../../config/reverseProxy'
import HeaderConfig from '../../config/header'

class CostCatalog {

    constructor() {

    }


    async getCostCatalogItemsHandler(params) {

        try {

            let response = await fetch(proxyConfig['serverBaseUrl'] + '/builder/costcatalog/get-costcatalog-items', {
                method: 'POST',
                headers: HeaderConfig.getHeader({ method: 'POST', isToken: true }),
                body: JSON.stringify(params)
            })

            response = await response.json()

            if (response) return response
        } catch {
            return { success: false }
        }


    }
    async createCostCatalogGroupHandler(params) {

        try {

            let response = await fetch(proxyConfig['serverBaseUrl'] + '/builder/costcatalog/create-costcatalog-group', {
                method: 'POST',
                headers: HeaderConfig.getHeader({ method: 'POST', isToken: true }),
                body: JSON.stringify(params)
            })

            response = await response.json()

            if (response) return response
        } catch {
            return { success: false }
        }


    }
    async updateCostCatalogGroupHandler(params) {

        try {

            let response = await fetch(proxyConfig['serverBaseUrl'] + '/builder/costcatalog/update-costcatalog-group', {
                method: 'POST',
                headers: HeaderConfig.getHeader({ method: 'POST', isToken: true }),
                body: JSON.stringify(params)
            })

            response = await response.json()

            if (response) return response
        } catch {
            return { success: false }
        }


    }
    async createCostCatalogItemHandler(params) {

        try {

            let response = await fetch(proxyConfig['serverBaseUrl'] + '/builder/costcatalog/create-costcatalog-item', {
                method: 'POST',
                headers: HeaderConfig.getHeader({ method: 'POST', isToken: true }),
                body: JSON.stringify(params)
            })

            response = await response.json()

            if (response) return response
        } catch {
            return { success: false }
        }


    }
    async updateCostCatalogItemHandler(params) {

        try {

            let response = await fetch(proxyConfig['serverBaseUrl'] + '/builder/costcatalog/update-costcatalog-item', {
                method: 'POST',
                headers: HeaderConfig.getHeader({ method: 'POST', isToken: true }),
                body: JSON.stringify(params)
            })

            response = await response.json()

            if (response) return response
        } catch {
            return { success: false }
        }


    }
    async deleteCostCatalogItemHandler(params) {

        try {

            let response = await fetch(proxyConfig['serverBaseUrl'] + '/builder/costcatalog/delete-costcatalog-item', {
                method: 'POST',
                headers: HeaderConfig.getHeader({ method: 'POST', isToken: true }),
                body: JSON.stringify(params)
            })

            response = await response.json()

            if (response) return response
        } catch {
            return { success: false }
        }


    }


}

export default CostCatalog;