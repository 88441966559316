

import React, { useEffect, useState, useRef } from 'react'
import { Route, Routes, BrowserRouter, Link, useLocation, Navigate, Outlet, useNavigate, useParams } from 'react-router-dom';
import store from '../../redux/store'
import StoreHandler from '../../redux/StoreHandler'
import { useSelector, useDispatch } from 'react-redux'
import UserAction from '../../redux/action/userAction'

import ReverseProxy from '../../config/reverseProxy'
import ProjectsHandler from '../../Handlers/Projects/Projects';
import Icons from '../../assets/Icons'
import Images from '../../assets/Images'
import { CostInput, TextInput, ColorInput, SelectInput, DateTimeInput, RadioInput } from '../../components/Inputs'
import Utils from '../../utils'
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

import AlertPopup from '../../components/AlertPopup'
import SystemToastPopup from '../../components/SystemToastPopup'
import Loading from '../../components/Loading'

const AddProjectUserPopup = ({ props }) => {


    const {
        project_users,
        close_callback,
        submit_callback,
    } = props

    const { projectId } = useParams();

    const [isLoading, setIsLoading] = useState(false)
    const [warningAlert, setWarningAlert] = useState(false)
    const [warningAlertType, setWarningAlertType] = useState('warning')
    const [apiFailedMessage, setApiFailedMessage] = useState('Error in Adding Project User')

    const navigate = useNavigate()
    const store = useSelector((store) => store)
    const dispatch = useDispatch()
    const { updateState } = new UserAction

    const storeHandler = new StoreHandler({
        dataStack: ['team_users', 'roles'],
        store: store,
        dispatch: dispatch,
        updateState: updateState,
    })

    const LoadStoreData = async () => {
        // setIsLoading(true)
        let resolved = await storeHandler.init()
        // setIsLoading(false)
    }


    const [AllTeamUsers, setAllTeamUsers] = useState([])
    const [AllTeamUsers_Options, setAllTeamUsers_Options] = useState([])

    const [AllRoles, setAllRoles] = useState([])
    const [AllRoles_Options, setAllRoles_Options] = useState([])

    const [ProjectUsers, setProjectUsers] = useState([])

    const HandleCancelCallback = () => {
        close_callback()
    }

    const HandleDeleteUser = (id) => {
        let _ProjectUsers = [...ProjectUsers]
        _ProjectUsers = _ProjectUsers.filter(user => user.id != id)
        setProjectUsers(_ProjectUsers)
    }
    const HandleAddMoreUsers = () => {
        let _ProjectUsers = [...ProjectUsers]
        _ProjectUsers.push({
            id: '',
            name: '',
            color_code: '',
            email: '',
            phone: '',
            role_id: '',
            role_name: '',
        })
        setProjectUsers(_ProjectUsers)
    }
    const HandleUserInputChange = (index, key, value) => {
        let _ProjectUsers = [...ProjectUsers]

        if (key == 'user') {

            let selected_user = AllTeamUsers.find(user => user.id == value)

            if (selected_user) {
                _ProjectUsers[index]['id'] = selected_user.id
                _ProjectUsers[index]['color_code'] = selected_user.color_code
                _ProjectUsers[index]['name'] = selected_user.name
                _ProjectUsers[index]['email'] = selected_user.email
                _ProjectUsers[index]['phone'] = selected_user.phone
            }
        }

        if (key == 'role') {

            let selected_role = AllRoles.find(role => role.id == value)

            if (selected_role) {
                _ProjectUsers[index]['role_id'] = selected_role.id
                _ProjectUsers[index]['role_name'] = selected_role.name
            }
        }

        setProjectUsers(_ProjectUsers)
    }

    const HandleSubmit = (e) => {
        e.preventDefault()

        let _ProjectUsers = ProjectUsers.filter(user => user.id && user.role_id)

        submit_callback(_ProjectUsers)
    }

    useEffect(() => {

        if (store && store.user) {

            let { team_users, roles } = store.user


            if (Array.isArray(team_users)) {

                team_users = team_users.filter(user => project_users.find(pu => pu.id == user.id) ? false : true)

                setAllTeamUsers(team_users || [])

                let _team_users = team_users.map(user => {
                    return {
                        value: user.id,
                        label: user.name || '',
                    }
                })

                setAllTeamUsers_Options(_team_users || [])
            }

            if (Array.isArray(roles)) {

                setAllRoles(roles || [])

                let _roles = roles.map(role => {
                    return {
                        value: role.id,
                        label: role.name || '',
                    }
                })

                setAllRoles_Options(_roles || [])
            }
        }

    }, [store])

    useEffect(() => {
        LoadStoreData()
        HandleAddMoreUsers()
    }, [])

    return (
        <>
            {isLoading ?
                <Loading
                    props={{
                        isMainLogo: false,
                        isLabel: true
                    }} />
                : null}
            {warningAlert ?

                <SystemToastPopup
                    props={{
                        type: warningAlertType,
                        message: apiFailedMessage || "Error in Create Projects",
                        callback: (confirmation) => setWarningAlert(false)
                    }} />

                : null}

            <div className="popup-container-main popup-container-center">
                <div className="popup-block-ui"></div>
                <form
                    className="side-popup-container side-add_project_users-popup-container"
                    onSubmit={(e) => HandleSubmit(e)}
                >
                    <div className="side-popup-header">
                        <div className="header-item-select">
                            <div className="header-item-select-content">
                                <div className="label">Assign Project User</div>

                            </div>
                        </div>
                        <div
                            className="header-item-close"
                            onClick={(e) => HandleCancelCallback()}
                            dangerouslySetInnerHTML={{ __html: Icons.general.close }}
                        ></div>
                    </div>
                    <div className="sidebar-popup-content">
                        <div className="add-project_user-popup-content">
                            <div className="add-project_user-content-items">
                                {ProjectUsers.map((user, index) => (
                                    <div
                                        className="add-project_user-content-item"
                                        key={`project-user-${index}`}
                                    >
                                        <div className="add-project_user-content-item-input">
                                            <SelectInput
                                                props={{
                                                    id: `add-project_user-user-${index}`,
                                                    value: user.id,
                                                    placeholder: '',
                                                    readOnly: false,
                                                    options: AllTeamUsers_Options || [],
                                                    setValue: (value, label) => HandleUserInputChange(index, 'user', value),
                                                    isStatus: false,
                                                    isIcon: false,
                                                    isLabel: true,
                                                    isRequired: true,
                                                    label: "Select User",
                                                }}
                                            />
                                        </div>
                                        <div className="add-project_user-content-item-input">
                                            <SelectInput
                                                props={{
                                                    id: `add-project_user-role-${index}`,
                                                    value: user.role_id,
                                                    placeholder: '',
                                                    readOnly: false,
                                                    options: AllRoles_Options || [],
                                                    setValue: (value, label) => HandleUserInputChange(index, 'role', value),
                                                    isStatus: false,
                                                    isIcon: false,
                                                    isLabel: true,
                                                    isRequired: true,
                                                    label: "Select User Role",
                                                }}
                                            />
                                        </div>
                                        {ProjectUsers.length <= 1 ? '' :
                                            <div
                                                className="add-project_user-content-item-delete"
                                                onClick={(e) => HandleDeleteUser(user.id)}
                                                dangerouslySetInnerHTML={{ __html: Icons.general.delete_btn }}
                                            ></div>
                                        }
                                    </div>

                                ))}

                            </div>
                            <div className="add-project_user-content-actions">
                                <div
                                    className="project-team-add_user-btn"
                                    onClick={(e) => HandleAddMoreUsers()}
                                >
                                    <div className="icon"
                                        dangerouslySetInnerHTML={{ __html: Icons.general.add_btn }}
                                    ></div>
                                    <div className="label">Add more users</div>
                                </div>
                            </div>
                        </div>

                    </div>
                    <div className="sidebar-popup-footer">
                        <div className="footer-item action-items">
                            <div className="action-preview">
                            </div>
                            <button
                                className="action-btn"
                                type='submit'
                            >
                                <div className="icon" dangerouslySetInnerHTML={{ __html: Icons.general.add_btn }}></div>
                                <div className="label">Create</div>
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </>
    )
}

export default AddProjectUserPopup;