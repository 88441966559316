
import proxyConfig from '../../config/reverseProxy'
import HeaderConfig from '../../config/header'

class IssuesManagement {

    constructor() {

    }


    async getIssuesHandler(params) {

        try {

            let response = await fetch(proxyConfig['serverBaseUrl'] + '/builder/issue_management/get-issues', {
                method: 'POST',
                headers: HeaderConfig.getHeader({ method: 'POST', isToken: true }),
                body: JSON.stringify(params)
            })

            response = await response.json()

            if (response) return response
        } catch {
            return { success: false }
        }


    }
    async createIssueHandler(params) {

        try {

            let response = await fetch(proxyConfig['serverBaseUrl'] + '/builder/issue_management/create-issue', {
                method: 'POST',
                headers: HeaderConfig.getHeader({ method: 'POST', isToken: true }),
                body: JSON.stringify(params)
            })

            response = await response.json()

            if (response) return response
        } catch {
            return { success: false }
        }


    }
    async updateIssueHandler(params) {

        try {

            let response = await fetch(proxyConfig['serverBaseUrl'] + '/builder/issue_management/update-issue', {
                method: 'POST',
                headers: HeaderConfig.getHeader({ method: 'POST', isToken: true }),
                body: JSON.stringify(params)
            })

            response = await response.json()

            if (response) return response
        } catch {
            return { success: false }
        }


    }
    async closeIssueHandler(params) {

        try {

            let response = await fetch(proxyConfig['serverBaseUrl'] + '/builder/issue_management/close-issue', {
                method: 'POST',
                headers: HeaderConfig.getHeader({ method: 'POST', isToken: true }),
                body: JSON.stringify(params)
            })

            response = await response.json()

            if (response) return response
        } catch {
            return { success: false }
        }


    }
    async deleteIssueHandler(params) {

        try {

            let response = await fetch(proxyConfig['serverBaseUrl'] + '/builder/issue_management/delete-issue', {
                method: 'POST',
                headers: HeaderConfig.getHeader({ method: 'POST', isToken: true }),
                body: JSON.stringify(params)
            })

            response = await response.json()

            if (response) return response
        } catch {
            return { success: false }
        }


    }


}

export default IssuesManagement;