

import React, { useEffect, useState, useRef } from 'react'
import { Route, Routes, BrowserRouter, Link, useLocation, Navigate, Outlet, useNavigate, useParams } from 'react-router-dom';
import store from '../../redux/store'
import StoreHandler from '../../redux/StoreHandler'
import { useSelector, useDispatch } from 'react-redux'
import UserAction from '../../redux/action/userAction'

import ReverseProxy from '../../config/reverseProxy'
import EstimateHandler from '../../Handlers/Estimate/Estimate';
import Icons from '../../assets/Icons'
import Images from '../../assets/Images'
import { CostInput, TextInput, SelectInput, SearcBarInput, RadioInput } from '../../components/Inputs'
import Utils from '../../utils'
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

import AlertPopup from '../../components/AlertPopup'
import SystemToastPopup from '../../components/SystemToastPopup'
import Loading from '../../components/Loading'
import NoDataFound from '../../components/NoDataFound';

import AddEstimateItemPopup from './AddEstimateItemPopup'

import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

import SideFilters from '../../components/SideFilters';

import { useAuth, useMenuAuth } from '../../hooks/useAuth'
const Estimate = () => {

    const { projectId } = useParams()

    const [project_id, setProject_id] = useState('')
    const [project_name, setProject_name] = useState('')
    const [estimate_id, setEstimate_id] = useState('')

    const [isLoading, setIsLoading] = useState(false)
    const [warningAlert, setWarningAlert] = useState(false)
    const [warningAlertType, setWarningAlertType] = useState('warning')
    const [apiFailedMessage, setApiFailedMessage] = useState('Error in Estimate, Please try again!')


    const [deleteItemId, setDeleteItem] = useState(null)
    const [deleteItemType, setDeleteItemType] = useState(null)
    const [deleteItemAlert, setDeleteItemAlert] = useState(false)

    const estimateHandler = new EstimateHandler()

    const [currency_type, setCurrency_type] = useState('₹')

    const navigate = useNavigate()
    const store = useSelector((store) => store)
    const dispatch = useDispatch()
    const { updateState } = new UserAction

    const storeHandler = new StoreHandler({
        dataStack: ['company', 'field_settings', 'finance_settings', `project-${projectId}`, `project_phases-${projectId}`],
        store: store,
        dispatch: dispatch,
        updateState: updateState,
    })

    const LoadStoreData = async () => {

        setIsLoading(true)
        let resolved = await storeHandler.init()
        setIsLoading(false)
    }

    const { isAdmin, menu_permission } = useMenuAuth({ menu: 'pre_construction--project_estimate', feature_key: 'estimate' })
    const location = useLocation()

    const [selected_view_settings_value, setSelected_view_settings_value] = useState('')
    const [has_view_access, setHas_view_access] = useState(false)
    const [has_add_access, setHas_add_access] = useState(false)
    const [has_edit_access, setHas_edit_access] = useState(false)
    const [has_delete_access, setHas_delete_access] = useState(false)

    useEffect(() => {

        if (isAdmin) {

            setHas_view_access(true)
            setHas_add_access(true)
            setHas_edit_access(true)
            setHas_delete_access(true)
        }

        else if (menu_permission) {

            if (
                !menu_permission._view
                &&
                (!menu_permission.selected_view_settings_value
                    || menu_permission.selected_view_settings_value == 'no_access')
            ) {
                navigate(`${ReverseProxy['proxyUrl']}/quick-menu`, { state: { from: location }, replace: true })
            }

            if (menu_permission._view == '0') navigate(`${ReverseProxy['proxyUrl']}/builder/projects`, { state: { from: location }, replace: true })

            setHas_view_access(menu_permission._view == '1')
            setHas_add_access(menu_permission._create == '1')
            setHas_edit_access(menu_permission._edit == '1')
            setHas_delete_access(menu_permission._delete == '1')

            setSelected_view_settings_value(menu_permission.selected_view_settings_value)

        }

    }, [store])


    const [tabelHeader, setTabelHeader] = useState([

        {
            id: 'select',
            label: 'Select',
            minWidth: 30,
            disable: false,
        },

        {
            id: 'name',
            label: 'Name',
            minWidth: 200,
            disable: false,
        },
        {
            id: 'cost_type',
            label: 'Cost Type',
            disable: false,

        },
        {
            id: 'quantity',
            label: 'Quantity',
            disable: false,
        },
        {
            id: '_unitrate',
            label: 'Unit rate',
            format: (value) => `${currency_type}${parseFloat(value || 0).toFixed(2)}`,
            disable: false,
        },
        {
            id: 'builder_price',
            label: 'Prime cost',
            disable: false,
        },
        {
            id: 'markup_amount',
            label: 'Markup',
            disable: false,
        },
        {
            id: 'tax_amount',
            label: 'Tax',
            disable: false,
        },
        {
            id: 'price',
            label: 'Final price',
            disable: false,
        },
        {
            id: 'actions',
            label: 'Actions',
            minWidth: 50,
            align: 'right',
            disable: false,
        },
    ])
    const [EstimationResponse, setEstimationResponse] = useState({})

    const [estimateItemsOrginal, setEstimateItemsOrginal] = useState([])
    const [estimateItems, setEstimateItems] = useState([])
    const [selectedAction, setSelectedAction] = useState('')

    const [tableSelectAll, setTableSelectAll] = useState(false)

    const [isAddBtnClicked, setIsAddBtnClicked] = useState(false)
    const [addBtnOption, setAddBtnOption] = useState({})
    const [existItemData, setExistItemData] = useState({})


    const [SelectedPhaseSection, setSelectedPhaseSection] = useState('all')

    const [ProjectPhases, setProjectPhases] = useState([])
    const [ProjectPhases_Options, setProjectPhases_Options] = useState([])

    const HandlePhaseSection = (section) => {
        setSelectedPhaseSection(section)
    }



    const [overHeads_options, setOverHeads_options] = useState([])
    const [insurances_options, setInsurances_options] = useState([])

    const [overHeadsOptions, setOverHeadsOptions] = useState(overHeads_options)
    const [insurancesOptions, setInsurancesOptions] = useState(insurances_options)

    const [summaryHeaderOpen, setSummaryHeaderOpen] = useState(false)

    const [builderFinalCost, setBuilderFinalCost] = useState(0)
    const [allowanceFinalCost, setAllowanceFinalCost] = useState(0)
    const [markupsFinalCost, setMarkupsFinalCost] = useState(0)
    const [taxFinalCost, setTaxFinalCost] = useState(0)
    const [overHeadValue, setOverHeadValue] = useState(0)
    const [insuranceValue, setInsuranceValue] = useState(0)
    const [adjustmentValue, setAdjustmentValue] = useState(0)
    const [DiscountPerValue, setDiscountPerValue] = useState(0)
    const [DiscountAmountValue, setDiscountAmountValue] = useState(0)
    const [clientFinalCost, setClientFinalCost] = useState(0)


    const [summaryOverHeads, setSummaryOverHeads] = useState([])
    const [summaryInsurance, setSummaryInsurance] = useState([])

    const [UpdatePricingTrigger, setUpdatePricingTrigger] = useState(false)

    const AddBtnOptions = [
        {
            icon: Icons.general.group,
            label: 'Group',
            type: 'group',
            id: "group"
        },
        {
            icon: Icons.general.work,
            label: 'Work Template',
            type: 'work_template',
            id: 'work_template'
        },
        {
            icon: Icons.general.labour,
            label: 'Labour',
            type: 'item',
            id: "labour"
        },
        {
            icon: Icons.general.material,
            label: 'Materials',
            type: 'item',
            id: "material"
        },
        {
            icon: Icons.general.machines,
            label: 'Machines',
            type: 'item',
            id: "machine"
        },
        {
            icon: Icons.general.subcontractor,
            label: 'Subcontractor',
            type: 'item',
            id: "subcontractor"
        },
        {
            icon: Icons.general.fee,
            label: 'Fee',
            type: 'item',
            id: "fee"
        },
        {
            icon: Icons.general.allowance,
            label: 'Allowances',
            type: 'item',
            id: "allowance"
        },
        {
            icon: Icons.general.work,
            label: 'Work',
            type: 'work',
            id: 'work'
        },
    ]

    const HandleOpenItem = (item) => {

        if (item.parent_type == '4') {


            setWarningAlert(true)
            setWarningAlertType('warning')
            setApiFailedMessage(`Please open the work to edit the item!`)


            return
        }

        setExistItemData(item)
        setAddBtnOption(item.type_option)

        navigate(`/builder/projects/${projectId}/estimate/${item.type_option.id}/${item.sno}/edit`)
    }

    const HanelCellAction = (id, row) => {

        if (id == "actions") {
            setSelectedAction(row.id)
            HandleActionDisable(row.id)
        }
    }
    const HandleActionDisable = (id) => {

        let input = document.getElementById(`estimate-tabel-action-btn-${id}`)
        let dropdown_items = document.getElementById(`estimate-tabel-action-dropdown-${id}`)

        const Event = (e) => {
            let path = e.path || (e.composedPath && e.composedPath());

            if (Array.isArray(path) && !path.includes(dropdown_items) && !path.includes(input)) {
                setSelectedAction('')
                window.removeEventListener('click', Event)
            }
        }

        window.addEventListener('click', Event)
    }

    const HandleSelectAllEvent = (value) => {
        setTableSelectAll(value)

        let _estimateItems = [...estimateItems]

        const selectItem = (items, value) => {

            items = items.map(item => {

                item.selected = value
                if (Array.isArray(item.sub_items)) item.sub_items = selectItem(item.sub_items, value)
                return item
            })

            return items
        }

        _estimateItems = selectItem(_estimateItems, value)


        setEstimateItems(_estimateItems)
    }
    const HandleFilterAllEvent = (value) => {

        let _estimateItems = [...estimateItems]

        const selectItem = (items, value) => {

            items = items.map(item => {

                item.disable = value
                if (Array.isArray(item.sub_items)) item.sub_items = selectItem(item.sub_items, value)
                return item
            })

            return items
        }

        _estimateItems = selectItem(_estimateItems, value)


        setEstimateItems(_estimateItems)
    }

    const HandleSelectEvent = (group_id, parent_id, value) => {

        let _estimateItems = [...estimateItems]

        const selectItem = (items, value) => {

            items = items.map(item => {

                item.selected = value
                if (item.type != 'group' && Array.isArray(item.sub_items)) item.sub_items = selectItem(item.sub_items, value)
                return item
            })

            return items
        }

        const findAndUpdateGroup = (group_id, parent_id, _estimateItems, value) => {

            _estimateItems = _estimateItems.map((item, idx) => {

                if (item.id == group_id && item.parent_id == parent_id) {
                    item.selected = value
                    if (item.type != 'item' && Array.isArray(item.sub_items)) {
                        item.sub_items = selectItem(item.sub_items, value)
                    }
                }
                else if (item.type != 'item' && Array.isArray(item.sub_items)) {
                    item.sub_items = findAndUpdateGroup(group_id, parent_id, item.sub_items, value)
                }

                return item
            })

            return _estimateItems
        }

        _estimateItems = findAndUpdateGroup(group_id, parent_id, _estimateItems, value)
        setEstimateItems(_estimateItems)
    }

    const getEstimateItemTypeEenem = (type) => {

        if (type == '1') return 'group'
        else if (type == '2') return 'work_template'
        else if (type == '3') return 'item'
        else if (type == '4') return 'work'
        else return ''

    }
    const getEstimateItemCategoryEenem = (type) => {

        if (type == '1') return 'material'
        else if (type == '2') return 'labour'
        else if (type == '3') return 'machine'
        else if (type == '4') return 'subcontractor'
        else if (type == '5') return 'fee'
        else if (type == '6') return 'allowance'
        else return ''

    }
    const getEstimateItemStatusEenem = (type) => {

        if (type == '0') return 'incomplete'
        else if (type == '1') return 'completed'
        else if (type == '2') return 'hold'
        else if (type == '3') return 'stopped'
        else return ''
    }

    const getSubItem = (items, parentid) => {

        let subItems = []

        let getItems = (items, parentid) => {

            if (!Array.isArray(items)) return

            let _items = items.filter(itm => itm.parent_id == parentid)

            subItems.push(..._items)

            _items.forEach(itm => getItems(items, itm.id))
        }

        getItems(items, parentid)

        return subItems
    }

    const getFormatedEstimateItem = (item) => {

        return {
            sno: item.sno,
            created_at: new Date(item.created_at).getTime(),
            updated_at: item.updated_at ? new Date(item.updated_at).getTime() : new Date(item.created_at).getTime(),
            expanded: true,
            selected: false,
            disable: false,

            work_id: item.work_id,
            work_name: item.work_name,

            sor_id: item.sor_id,
            sor_name: item.sor_name,

            co_efficient: parseFloat(item.co_efficient || 0) || 0,
            per_co_efficient_cost: parseFloat(item.per_co_efficient_cost || 0) || 0,

            parent_id: item.parent_id,
            parent_name: item.parent_name,
            phase_id: item.phase_id,
            phase_name: item.phase_name,
            id: item.id,
            name: item.name,
            description: item.description,

            costcode_id: item.costcode_id || "",
            costcode_name: item.costcode_name || "",

            type: getEstimateItemTypeEenem(item.type),
            item_type: item.type != '3' ? getEstimateItemTypeEenem(item.type) : getEstimateItemCategoryEenem(item.item_type),
            cost_type: item.type != '1' && item.type != '3' ? getEstimateItemTypeEenem(item.type) : getEstimateItemCategoryEenem(item.item_type),

            parent_type: item.parent_type,
            parent_quantity: parseFloat(item.parent_quantity || 0),

            _status: item.status,
            status: getEstimateItemStatusEenem(item.status),

            quantity: parseFloat(item.quantity || 0) || 0,
            unitrate: parseFloat(item.unitrate || 0) || 0,
            builder_price: parseFloat(item.builder_price || 0) || 0,
            price: parseFloat(item.price || 0) || 0,


            unit_id: item.unit_id || "",
            unit_name: item.unit_name || "",

            markup_id: item.markup_id || "",
            markup_name: item.markup_name || "",
            markup_value: parseFloat(item.markup_value || 0) || 0,
            markup_value_type: item.markup_value_type || "",
            markup_amount: parseFloat(item.markup_amount || 0) || 0,

            tax_id: item.tax_id || "",
            tax_name: item.tax_name || "",
            tax_value_type: item.tax_value_type || "",
            tax_value: parseFloat(item.tax_value || 0) || 0,
            tax_amount: parseFloat(item.tax_amount || 0) || 0,

            sub_items: []
        }

    }

    const getOrderedTableItems = (items = []) => {
        const groupedItems = {};

        for (const item of items) {

            const parent_id = item.parent_id;

            if (parent_id != null) {

                if (!groupedItems[parent_id]) groupedItems[parent_id] = [];

                groupedItems[parent_id].push(item);
            }
        }

        for (const item of items) {
            const itemId = item.id;
            if (groupedItems[itemId]) {

                const orderedItems = groupedItems[itemId].sort((a, b) => {
                    if (a.type != 'item' && b.type == 'item') return -1;
                    if (a.type == 'item' && b.type != 'item') return 1;
                    return 0;
                })

                item.sub_items = orderedItems
            }
        }

        let orderedItems = items.sort((a, b) => {
            if (a.type != 'item' && b.type == 'item') return -1;
            if (a.type == 'item' && b.type != 'item') return 1;
            return 0;
        })

        orderedItems = orderedItems.sort((a, b) => a.created_at - b.created_at)

        return orderedItems.filter(d => d.parent_id == "")

    }

    const HandlePricingItems = (response, overHeads_options, insurances_options) => {

        let { id, adjustment_price, discount_percentage, discount_price, overhead_items, insurance_items } = response

        overhead_items = overhead_items || []
        insurance_items = insurance_items || []

        setEstimate_id(id)

        if (Array.isArray(overhead_items)) {

            console.log(overhead_items, 'overhead_items');

            setSummaryOverHeads(overhead_items)

            let _overHeads_options = overHeads_options.filter(itm => overhead_items.filter(item => item.id == itm.id).length == 0)

            console.log(_overHeads_options, '_overHeads_options');
            setOverHeadsOptions(_overHeads_options)
        }
        if (Array.isArray(insurance_items)) {

            setSummaryInsurance(insurance_items)

            let _insurances_options = insurances_options.filter(itm => insurance_items.filter(item => item.id == itm.id).length == 0)
            setInsurancesOptions(_insurances_options)

        }

        setAdjustmentValue(parseFloat(adjustment_price || 0))
        setDiscountPerValue(parseFloat(discount_percentage || 0))
    }

    const LoadEstimateItems = async () => {

        let filter = {
            projectId: projectId
        }

        setIsLoading(true)

        let response = await estimateHandler.getEstimateItemsHandler(filter)
        let estimation_response = await estimateHandler.getProjectEstimationHandler(filter)
        setIsLoading(false)

        if (response.success && estimation_response.success) {

            setEstimationResponse(estimation_response.data)

            setEstimate_id(estimation_response.id)
            // HandlePricingItems(estimation_response.data, overHeads_options, insurances_options)

            let { data } = response

            if (!Array.isArray(data)) return


            let total = []

            let items = data.map(d => {

                let item = getFormatedEstimateItem(d)
                let subItems = getSubItem(data, d.id).map(itm => getFormatedEstimateItem(itm))

                let tax_amount = item.tax_amount
                let markup_amount = item.markup_amount
                let builder_price = item.builder_price
                let price = item.price

                let subItems_Total = subItems.reduce((prev, val, ind) => {

                    return {
                        tax_amount: prev.tax_amount + val.tax_amount,
                        markup_amount: prev.markup_amount + val.markup_amount,
                        builder_price: prev.builder_price + val.builder_price,
                        price: prev.price + val.price,
                    }

                }, { tax_amount: 0, markup_amount: 0, builder_price: 0, price: 0 })

                tax_amount = parseFloat(tax_amount + (subItems_Total.tax_amount || 0)).toFixed(2)
                markup_amount = parseFloat(markup_amount + (subItems_Total.markup_amount || 0)).toFixed(2)
                builder_price = parseFloat(builder_price + (subItems_Total.builder_price || 0)).toFixed(2)
                price = parseFloat(price + (subItems_Total.price || 0)).toFixed(2)



                if (item.parent_id) {
                    let parent_item = data.filter(d => d.id == item.parent_id)[0]
                    if (parent_item) {
                        item.parent_type = parent_item.type
                        if (parent_item.type == '4') item.parent_quantity = parent_item.quantity
                    }
                }

                item._tax_amount = tax_amount || ""
                item._tax_percent = ((tax_amount / builder_price) * 100 || 0).toFixed(2)
                item._markup_amount = markup_amount || ""
                item._markup_percent = ((markup_amount / builder_price) * 100 || 0).toFixed(2)
                item._builder_price = builder_price || ""
                item._price = price || ""
                item._unitrate = item.unitrate || ""
                item._quantity = item.quantity || ""




                if (item.type == 'group' && !item.parent_id) {

                    total.push(parseFloat(builder_price || 0))
                    console.log(item.name, builder_price, total, 'builder_price');
                }

                item.tax_amount = tax_amount ? `${currency_type}${tax_amount}` : ""
                item.markup_amount = markup_amount ? `${currency_type}${markup_amount}` : ""
                item.builder_price = builder_price ? `${currency_type}${builder_price}` : ""
                item.price = price ? `${currency_type}${price}` : ""
                item.unitrate = item.unitrate ? `${currency_type}${item.unitrate}` : ""
                item.quantity = item.quantity ? `${item.quantity}${item.unit_name || ""}` : ""

                if (item.type == 'work') {

                    let cost_lines = getSubItem(data, d.id).map(itm => getFormatedEstimateItem(itm)) || []

                    cost_lines = cost_lines.map(item => {

                        let type_option = AddBtnOptions.filter(opt => {

                            if (opt.type != 'item' && item.type == opt.type) return true
                            else if (opt.type == 'item' && item.item_type == opt.id) return true
                            else return false
                        })

                        type_option = type_option.length ? type_option[0] : undefined
                        item.type_option = type_option

                        return item

                    })

                    item.cost_lines = cost_lines
                }

                let type_option = AddBtnOptions.filter(opt => {

                    if (opt.type != 'item' && item.type == opt.type) return true
                    else if (opt.type == 'item' && item.item_type == opt.id) return true
                    else return false
                })

                type_option = type_option.length ? type_option[0] : undefined
                item.type_option = type_option

                return item

            })

            items = getOrderedTableItems(items)

            setEstimateItemsOrginal(data)
            setEstimateItems(items)
        }
        else {
            setWarningAlert(true)
            setWarningAlertType('error')
            setApiFailedMessage(`Error in Estimate, Please try again!`)
        }
    }


    useEffect(() => {
        LoadStoreData()
        LoadEstimateItems()
    }, [])

    useEffect(() => {


        if (store && store.user) {

            let { company, selected_project, settings, selected_project_phases } = store.user

            if (selected_project) {

                let { id, name } = selected_project

                setProject_id(id)
                setProject_name(name)
            }

            if (company) {

                let { currency_setting, timezone, temperature_setting } = company

                if (currency_setting) setCurrency_type(currency_setting)
            }


            if (settings && Array.isArray(settings.finance_settings) && settings.finance_settings.length) {

                let { taxes, markups, overheads, insurance } = settings.finance_settings[0]

                overheads = overheads.filter(opt => opt.isActive).map((opt) => {
                    return {
                        id: opt.name,
                        value: parseFloat(opt.value || 0),
                        value_type: opt.value_type,
                        label: opt.name,
                        isDefault: opt.isDefault || false
                    }
                })

                setOverHeads_options(overheads || [])


                insurance = insurance.filter(opt => opt.isActive).map((opt) => {
                    return {
                        id: opt.name,
                        value: parseFloat(opt.value || 0),
                        value_type: opt.value_type,
                        label: opt.name,
                        isDefault: opt.isDefault || false
                    }
                })

                setInsurances_options(insurance || [])

                HandlePricingItems(EstimationResponse, overheads, insurance)

            }

            if (selected_project_phases) {

                setProjectPhases(selected_project_phases)

                let project_phases = selected_project_phases.map(phase => {
                    return {
                        id: phase.id,
                        name: `${phase.name} | ${phase.client_name}`,
                        count: 0
                    }
                })

                setProjectPhases_Options([
                    {
                        id: 'all',
                        name: 'All',
                        count: 0
                    },
                    ...project_phases
                ])

            }


        }


    }, [store, EstimationResponse])


    const HandleUpdateCallback = () => {
        LoadEstimateItems()
        if (!UpdatePricingTrigger) setUpdatePricingTrigger(true)
    }
    const HandleExpandGroup = (group_id, parent_id) => {

        let _estimateItems = [...estimateItems]

        const findAndUpdateGroup = (group_id, parent_id, _estimateItems) => {

            _estimateItems = _estimateItems.map((item, idx) => {

                if (item.id == group_id && item.parent_id == parent_id) {
                    item.expanded = !item.expanded
                }
                else if (item.type != 'item' && Array.isArray(item.sub_items)) {
                    item.sub_items = findAndUpdateGroup(group_id, parent_id, item.sub_items)
                }

                return item
            })

            return _estimateItems
        }

        _estimateItems = findAndUpdateGroup(group_id, parent_id, _estimateItems)

        setEstimateItems(_estimateItems)
    }

    const CreateEstimateTableItem = (row, idx, sub_space_px, parent_expand = true) => {

        let { id, disable, type, parent_id, sub_items, expanded, _status } = row

        sub_items = sub_items || []

        return (
            <>
                {!disable ?
                    <>
                        <TableRow
                            hover
                            role="checkbox"
                            tabIndex={-1}
                            key={id}
                            style={{ display: parent_expand ? 'table-row' : 'none' }}
                            className={`estimate-table-row ${type != 'item' ? !parent_id ? 'estimate-table-row-group' : 'estimate-table-row-subgroup' : ''}`}
                        >
                            {tabelHeader.map((column) => {
                                const value = row[column.id];
                                return (

                                    column.disable ? '' :
                                        column.id == 'select' ?


                                            <TableCell
                                                key={column.id}
                                                align={column.align}
                                                className='estimate-table-row-item'
                                            >
                                                <RadioInput props={{
                                                    value: row.selected || false,
                                                    isIcon: false,
                                                    icon: "",
                                                    inputType: "checkbox",
                                                    name: "table-default-radio",
                                                    setValue: (value) => HandleSelectEvent(id, parent_id, value)
                                                }} />

                                            </TableCell>

                                            :
                                            <TableCell
                                                key={column.id}
                                                align={column.align}
                                                onClick={(e) => {
                                                    if (column.id != 'actions') HanelCellAction(column.id, row)
                                                }}
                                                className='estimate-table-row-item'
                                                style={column.id == 'name' ? { paddingLeft: `${sub_space_px}px` } : {}}
                                            >
                                                {
                                                    column.id == 'name' ?
                                                        <div
                                                            className="tabel-name-main"
                                                        >
                                                            {type != 'item' ?
                                                                <div
                                                                    className={`tabel-row-arrow ${expanded ? 'tabel-row-arrow-open' : ''} `}
                                                                    dangerouslySetInnerHTML={{ __html: Icons.general.group_arrow }}
                                                                    onClick={() => HandleExpandGroup(id, parent_id)}
                                                                ></div>
                                                                : ''}

                                                            <div
                                                                className="tabel-name"
                                                                onClick={(e) => HandleOpenItem(row)}
                                                            > {column.format ? column.format(value) : value}</div>

                                                        </div>

                                                        : column.id == 'status' ?

                                                            <div
                                                                className="tabel-status-main"
                                                            >

                                                                <span className={`tabel-status-span status-span-${value}`}></span>
                                                                <div style={{ display: 'none' }} className="tabel-status"> {column.format ? column.format(value) : value}</div>

                                                            </div>

                                                            : column.id == 'actions' ?


                                                                <div
                                                                    className="tabel-action-main"
                                                                >
                                                                    {has_edit_access ?
                                                                        <div
                                                                            className="action-icon"
                                                                            dangerouslySetInnerHTML={{ __html: Icons.general.edit_btn }}
                                                                            onClick={(e) => HandleOpenItem(row)}
                                                                        ></div>
                                                                        : ''}
                                                                    {has_delete_access ?
                                                                        <div
                                                                            className="action-icon"
                                                                            dangerouslySetInnerHTML={{ __html: Icons.general.delete_btn }}
                                                                            onClick={(e) => HandleDeleteItem(row)}
                                                                        ></div>
                                                                        : ''}

                                                                </div>
                                                                : column.format ? column.format(value) : value
                                                }
                                            </TableCell>
                                );
                            })}
                        </TableRow>
                        {sub_items.map((item, idxx) => (
                            CreateEstimateTableItem(item, idxx, sub_space_px + 20, parent_expand ? expanded : parent_expand)
                        ))}
                    </>
                    : ''}
            </>
        )
    }

    const HandleDeleteItem = async (item) => {

        setDeleteItem(item.id)
        setDeleteItemType(item.type)
        setDeleteItemAlert(true)
    }

    const getSubItemIds = (items, parentid) => {

        let subItems = []

        let getItems = (items, parentid) => {

            if (!Array.isArray(items)) return

            let _items = items.filter(itm => itm.parent_id == parentid)

            subItems.push(..._items)

            _items.forEach(itm => getItems(items, itm.id))
        }

        getItems(items, parentid)

        return subItems.map(itm => itm.id)
    }

    const HandleDeleteConfirmItem = async (id, type, confirmation) => {

        if (!confirmation || !id) {

            setDeleteItem(null)
            setDeleteItemType(null)
            setDeleteItemAlert(false)

            return
        }


        let ids = getSubItemIds([...estimateItemsOrginal], id)

        let filter = {
            projectId,
            ids: [id, ...ids],
        }

        setIsLoading(true)

        let response = await estimateHandler.deleteEstimateItemHandler(filter)
        setIsLoading(false)

        if (response.success) {
            LoadEstimateItems()

            if (!UpdatePricingTrigger) setUpdatePricingTrigger(true)

        }
        else {
            setWarningAlert(true)
            setWarningAlertType('error')
            setApiFailedMessage(`Error in Estimate, Please try again!`)
        }
        setDeleteItem(null)
        setDeleteItemType(null)
        setDeleteItemAlert(false)
    }

    const HandleAddBtn = (e) => {

        if (!has_add_access) return

        setIsAddBtnClicked(true)

        let items_parent = document.querySelector('#pd_estimate-addbtn')

        window.addEventListener('click', (e) => {

            let path = e.path || (e.composedPath && e.composedPath());

            if (Array.isArray(path) && !path.includes(items_parent))
                setIsAddBtnClicked(false)
        })
    }
    const HandleAddBtnOption = (option) => {

        if (!has_add_access || !option) return
        setAddBtnOption(option)

        navigate(`/builder/projects/${projectId}/estimate/${option.id}/add`)
    }

    const getDeleteMessage = (type) => {

        if (type == "group") return `Are you sure you want to delete the Group? Items included in the group will be deleted accordingly.`
        else if (type == "work") return `Are you sure you want to delete the Work?`
        else if (type == "item") return `Are you sure you want to delete the Item?`
        else return "Are you sure you want to delete?"
    }


    const [ShowSideFilter, setShowSideFilter] = useState(false)
    const [SideFilterSettings, setSideFilterSettings] = useState([
        {
            id: 'selectshow-filter',
            type: 'multiselect',
            value: true,
            placeholder: '',

            selectoptions: [
                {
                    id: 'select-filteroption-selectshow-all',
                    type: 'checkbox',
                    key: `all`,
                    value: true,
                    placeholder: '',
                    classNames: [],
                    isIconLeft: false,
                    isIcon: false,
                    icon: '',
                    isLabel: true,
                    label: 'Show select box',
                },
            ],

            callback: (e) => { },
            classNames: [],
            isIconLeft: true,
            isIcon: true,
            icon: Icons.general.multi_select,
            isLabel: true,
            label: 'Show Selection',
        },
        {
            id: 'select-filter',
            type: 'multiselect',
            value: true,
            placeholder: '',

            selectoptions: [
                {
                    id: 'select-filteroption-select-all',
                    type: 'checkbox',
                    key: `all`,
                    category: '',
                    value: true,
                    placeholder: '',
                    classNames: [],
                    isIconLeft: false,
                    isIcon: false,
                    icon: '',
                    isLabel: true,
                    label: 'All',
                },
                {
                    id: 'select-filteroption-select-incomplete',
                    type: 'checkbox',
                    key: `incomplete`,
                    category: 'status',
                    value: true,
                    placeholder: '',
                    classNames: [],
                    isIconLeft: false,
                    isIcon: false,
                    icon: '',
                    isLabel: true,
                    label: 'InComplete',
                },
                {
                    id: 'select-filteroption-select-completed',
                    type: 'checkbox',
                    key: `completed`,
                    category: 'status',
                    value: true,
                    placeholder: '',
                    classNames: [],
                    isIconLeft: false,
                    isIcon: false,
                    icon: '',
                    isLabel: true,
                    label: 'Completed',
                },
                {
                    id: 'select-filteroption-select-hold',
                    type: 'checkbox',
                    key: `hold`,
                    category: 'status',
                    value: true,
                    placeholder: '',
                    classNames: [],
                    isIconLeft: false,
                    isIcon: false,
                    icon: '',
                    isLabel: true,
                    label: 'Hold',
                },
                {
                    id: 'select-filteroption-select-stopped',
                    type: 'checkbox',
                    key: `stopped`,
                    category: 'status',
                    value: true,
                    placeholder: '',
                    classNames: [],
                    isIconLeft: false,
                    isIcon: false,
                    icon: '',
                    isLabel: true,
                    label: 'Stopped',
                },
                {
                    id: 'select-filteroption-select-material',
                    type: 'checkbox',
                    key: `material`,
                    category: 'item_type',
                    value: true,
                    placeholder: '',
                    classNames: [],
                    isIconLeft: false,
                    isIcon: false,
                    icon: '',
                    isLabel: true,
                    label: 'Materials',
                },
                {
                    id: 'select-filteroption-select-labour',
                    type: 'checkbox',
                    key: `labour`,
                    category: 'item_type',
                    value: true,
                    placeholder: '',
                    classNames: [],
                    isIconLeft: false,
                    isIcon: false,
                    icon: '',
                    isLabel: true,
                    label: 'Labour',
                },
                {
                    id: 'select-filteroption-select-machine',
                    type: 'checkbox',
                    key: `machine`,
                    category: 'item_type',
                    value: true,
                    placeholder: '',
                    classNames: [],
                    isIconLeft: false,
                    isIcon: false,
                    icon: '',
                    isLabel: true,
                    label: 'Machine',
                },
                {
                    id: 'select-filteroption-select-subcontractor',
                    type: 'checkbox',
                    key: `subcontractor`,
                    category: 'item_type',
                    value: true,
                    placeholder: '',
                    classNames: [],
                    isIconLeft: false,
                    isIcon: false,
                    icon: '',
                    isLabel: true,
                    label: 'Sub Contractor',
                },
                {
                    id: 'select-filteroption-select-fee',
                    type: 'checkbox',
                    key: `fee`,
                    category: 'item_type',
                    value: true,
                    placeholder: '',
                    classNames: [],
                    isIconLeft: false,
                    isIcon: false,
                    icon: '',
                    isLabel: true,
                    label: 'Fee',
                },
                {
                    id: 'select-filteroption-select-allowances',
                    type: 'checkbox',
                    key: `allowances`,
                    category: 'item_type',
                    value: true,
                    placeholder: '',
                    classNames: [],
                    isIconLeft: false,
                    isIcon: false,
                    icon: '',
                    isLabel: true,
                    label: 'Allowances',
                },
            ],

            callback: (e) => { },
            classNames: [],
            isIconLeft: true,
            isIcon: true,
            icon: Icons.general.multi_select,
            isLabel: true,
            label: 'Select by',
        },
        {
            id: 'filterby-filter',
            type: 'multiselect',
            value: true,
            placeholder: '',

            selectoptions: [
                {
                    id: 'select-filteroption-filterby-all',
                    type: 'checkbox',
                    key: `all`,
                    value: true,
                    placeholder: '',
                    classNames: [],
                    isIconLeft: false,
                    isIcon: false,
                    icon: '',
                    isLabel: true,
                    label: 'All',
                },
                {
                    id: 'select-filteroption-filterby-incomplete',
                    type: 'checkbox',
                    key: `incomplete`,
                    category: 'status',
                    value: true,
                    placeholder: '',
                    classNames: [],
                    isIconLeft: false,
                    isIcon: false,
                    icon: '',
                    isLabel: true,
                    label: 'InComplete',
                },
                {
                    id: 'select-filteroption-filterby-completed',
                    type: 'checkbox',
                    key: `completed`,
                    category: 'status',
                    value: true,
                    placeholder: '',
                    classNames: [],
                    isIconLeft: false,
                    isIcon: false,
                    icon: '',
                    isLabel: true,
                    label: 'Completed',
                },
                {
                    id: 'select-filteroption-filterby-hold',
                    type: 'checkbox',
                    key: `hold`,
                    category: 'status',
                    value: true,
                    placeholder: '',
                    classNames: [],
                    isIconLeft: false,
                    isIcon: false,
                    icon: '',
                    isLabel: true,
                    label: 'Hold',
                },
                {
                    id: 'select-filteroption-filterby-stopped',
                    type: 'checkbox',
                    key: `stopped`,
                    category: 'status',
                    value: true,
                    placeholder: '',
                    classNames: [],
                    isIconLeft: false,
                    isIcon: false,
                    icon: '',
                    isLabel: true,
                    label: 'Stopped',
                },
                {
                    id: 'select-filteroption-filterby-material',
                    type: 'checkbox',
                    key: `material`,
                    category: 'item_type',
                    value: true,
                    placeholder: '',
                    classNames: [],
                    isIconLeft: false,
                    isIcon: false,
                    icon: '',
                    isLabel: true,
                    label: 'Materials',
                },
                {
                    id: 'select-filteroption-filterby-labour',
                    type: 'checkbox',
                    key: `labour`,
                    category: 'item_type',
                    value: true,
                    placeholder: '',
                    classNames: [],
                    isIconLeft: false,
                    isIcon: false,
                    icon: '',
                    isLabel: true,
                    label: 'Labour',
                },
                {
                    id: 'select-filteroption-filterby-machine',
                    type: 'checkbox',
                    key: `machine`,
                    category: 'item_type',
                    value: true,
                    placeholder: '',
                    classNames: [],
                    isIconLeft: false,
                    isIcon: false,
                    icon: '',
                    isLabel: true,
                    label: 'Machine',
                },
                {
                    id: 'select-filteroption-filterby-subcontractor',
                    type: 'checkbox',
                    key: `subcontractor`,
                    category: 'item_type',
                    value: true,
                    placeholder: '',
                    classNames: [],
                    isIconLeft: false,
                    isIcon: false,
                    icon: '',
                    isLabel: true,
                    label: 'Sub Contractor',
                },
                {
                    id: 'select-filteroption-filterby-fee',
                    type: 'checkbox',
                    key: `fee`,
                    category: 'item_type',
                    value: true,
                    placeholder: '',
                    classNames: [],
                    isIconLeft: false,
                    isIcon: false,
                    icon: '',
                    isLabel: true,
                    label: 'Fee',
                },
                {
                    id: 'select-filteroption-filterby-allowances',
                    type: 'checkbox',
                    key: `allowances`,
                    category: 'item_type',
                    value: true,
                    placeholder: '',
                    classNames: [],
                    isIconLeft: false,
                    isIcon: false,
                    icon: '',
                    isLabel: true,
                    label: 'Allowances',
                },
            ],

            callback: (e) => { },
            classNames: [],
            isIconLeft: true,
            isIcon: true,
            icon: Icons.general.filter_by,
            isLabel: true,
            label: 'Filter by',
        },
        {
            id: 'showtable-filter',
            type: 'multiselect',
            value: true,
            placeholder: '',

            selectoptions: [
                {
                    id: 'select-filteroption-showtable-all',
                    type: 'checkbox',
                    key: `all`,
                    value: true,
                    placeholder: '',
                    classNames: [],
                    isIconLeft: false,
                    isIcon: false,
                    icon: '',
                    isLabel: true,
                    label: 'All',
                },
                {
                    id: 'select-filteroption-showtable-name',
                    type: 'checkbox',
                    value: true,
                    key: `name`,
                    placeholder: '',
                    classNames: [],
                    isIconLeft: false,
                    isIcon: false,
                    icon: '',
                    isLabel: true,
                    label: 'Name',
                },
                {
                    id: 'select-filteroption-showtable-cost_type',
                    type: 'checkbox',
                    value: true,
                    key: `cost_type`,
                    placeholder: '',
                    classNames: [],
                    isIconLeft: false,
                    isIcon: false,
                    icon: '',
                    isLabel: true,
                    label: 'Cost Type',
                },
                {
                    id: 'select-filteroption-showtable-status',
                    type: 'checkbox',
                    value: true,
                    key: `status`,
                    placeholder: '',
                    classNames: [],
                    isIconLeft: false,
                    isIcon: false,
                    icon: '',
                    isLabel: true,
                    label: 'Status',
                },
                {
                    id: 'select-filteroption-showtable-quantity',
                    type: 'checkbox',
                    value: true,
                    key: `quantity`,
                    placeholder: '',
                    classNames: [],
                    isIconLeft: false,
                    isIcon: false,
                    icon: '',
                    isLabel: true,
                    label: 'Quantity',
                },
                {
                    id: 'select-filteroption-showtable-unitrate',
                    type: 'checkbox',
                    value: true,
                    key: `unitrate`,
                    placeholder: '',
                    classNames: [],
                    isIconLeft: false,
                    isIcon: false,
                    icon: '',
                    isLabel: true,
                    label: 'Unitrate',
                },
                {
                    id: 'select-filteroption-showtable-builder_price',
                    type: 'checkbox',
                    value: true,
                    key: `builder_price`,
                    placeholder: '',
                    classNames: [],
                    isIconLeft: false,
                    isIcon: false,
                    icon: '',
                    isLabel: true,
                    label: 'Prime cost',
                },
                {
                    id: 'select-filteroption-showtable-markup_amount',
                    type: 'checkbox',
                    value: true,
                    key: `markup_amount`,
                    placeholder: '',
                    classNames: [],
                    isIconLeft: false,
                    isIcon: false,
                    icon: '',
                    isLabel: true,
                    label: 'Markup',
                },
                {
                    id: 'select-filteroption-showtable-tax_amount',
                    type: 'checkbox',
                    value: true,
                    key: `tax_amount`,
                    placeholder: '',
                    classNames: [],
                    isIconLeft: false,
                    isIcon: false,
                    icon: '',
                    isLabel: true,
                    label: 'Tax',
                },
                {
                    id: 'select-filteroption-showtable-price',
                    type: 'checkbox',
                    value: true,
                    key: `price`,
                    placeholder: '',
                    classNames: [],
                    isIconLeft: false,
                    isIcon: false,
                    icon: '',
                    isLabel: true,
                    label: 'Final price',
                },
            ],

            callback: (e) => { },
            classNames: [],
            isIconLeft: true,
            isIcon: true,
            icon: Icons.general.show_hide,
            isLabel: true,
            label: 'Show Tables',
        },

    ])

    const HandleSideFilterClose = () => {
        setShowSideFilter(false)
    }
    const HandleSideFilterUpdateCallback = (filters) => {

        let _estimateItems = [...estimateItems]

        filters.forEach((filter, fidx) => {

            if (filter.type == 'multiselect') {

                if (filter.id == 'selectshow-filter') {

                    filter.selectoptions.forEach((option, oidx) => {

                        if (option.key == 'all') {

                            let _tabelHeader = [...tabelHeader]

                            _tabelHeader[0].disable = !option.value

                            setTabelHeader(_tabelHeader)

                        }

                    })
                }
                else if (filter.id == 'select-filter') {

                    filter.selectoptions.forEach((option, oidx) => {

                        if (option.key == 'all') HandleSelectAllEvent(option.value)
                        else {

                            let select_by = (items, key, validate_value, value) => {

                                const selectItem = (items, key, validate_value, value) => {

                                    items = items.map(item => {

                                        if (item[key] == validate_value) {
                                            item.selected = value
                                        }
                                        if (Array.isArray(item.sub_items)) item.sub_items = selectItem(item.sub_items, key, validate_value, value)

                                        return item
                                    })

                                    return items
                                }

                                items = selectItem(items, key, validate_value, value)

                                return items
                            }
                            _estimateItems = select_by(_estimateItems, option.category, option.key, option.value)

                        }

                    })
                }
                else if (filter.id == 'filterby-filter') {

                    filter.selectoptions.forEach((option, oidx) => {

                        if (option.key == 'all') HandleFilterAllEvent(!option.value)
                        else {
                            let filter_by = (items, key, validate_value, value) => {

                                const filterItem = (items, key, validate_value, value) => {

                                    items = items.map(item => {

                                        if (item[key] == validate_value) {
                                            if (key == 'item_type' && item.type == 'item') item.disable = !value
                                            else item.disable = !value

                                        }
                                        if (Array.isArray(item.sub_items)) item.sub_items = filterItem(item.sub_items, key, validate_value, value)

                                        return item
                                    })

                                    return items
                                }

                                items = filterItem(items, key, validate_value, value)

                                return items
                            }
                            _estimateItems = filter_by(_estimateItems, option.category, option.key, option.value)



                        }

                    })
                }
                else if (filter.id == 'showtable-filter') {

                    filter.selectoptions.forEach((option, oidx) => {

                        if (option.key == 'all') {

                            let _tabelHeader = [...tabelHeader]

                            _tabelHeader = _tabelHeader.map((th, i) => {
                                if (i > 0) th.disable = false
                                return th
                            })

                            setTabelHeader(_tabelHeader)
                        }
                        else {
                            let _tabelHeader = [...tabelHeader]

                            let head_idx = _tabelHeader.findIndex(h => h.id == option.key)

                            if (head_idx != -1) {
                                _tabelHeader[head_idx].disable = !option.value
                                setTabelHeader(_tabelHeader)
                            }


                        }

                    })
                }
            }

        })

        setEstimateItems(_estimateItems)

        setSideFilterSettings(filters)
        HandleSideFilterClose()
    }


    const [Filters, setFilters] = useState({
        left: [
            {
                id: "estimate-search",
                value: '',
                isSearchBar: true,
                type: "input",
                isDropDownContainer: false,
                placeholder: "Search by name",
                dropDownOptions: [],
                callback: (val) => { },

                isIcon: true,
                icon: Icons.general.search,
                isLabel: false,
                label: "",
                isIconLeft: false
            },
            {
                id: "estimate-expand",
                value: true,
                type: "icon",
                isSelectToggle: true,
                placeholder: "",
                callback: (val) => { },
                isIcon: true,
                icon: Icons.general.expand,
                isLabel: true,
                label: "Expand all",
                isIconLeft: true
            },
        ],
        right: [

            {
                id: "estimate-morefilter",
                type: "icon",
                isSelectToggle: true,
                value: true,
                placeholder: "",
                callback: (val) => HandleFiltersEvent(val, 'estimate-morefilter'),
                isIcon: true,
                icon: Icons.general.filter_funnel,
                isLabel: true,
                label: "Filters",
                isIconLeft: true
            },
            {
                id: "estimate-download",
                type: "icon",
                isSelectToggle: true,
                value: true,
                placeholder: "",
                callback: (val) => HandleFiltersEvent(val, 'estimate-download'),
                isIcon: true,
                icon: Icons.general.download,
                isLabel: false,
                label: "Download",
                isIconLeft: true
            },

        ]
    })
    const HandleFiltersEvent = async (value, filter_id) => {

        if (!filter_id) return

        else if (filter_id == 'estimate-expand') {

            let filters = { ...Filters }

            filters.left[1].value = value

            let _estimateItems = [...estimateItems]

            let expand_by_group = (items, value) => {

                const expandItem = (items, value) => {

                    items = items.map(item => {

                        if (item.type != 'item') item.expanded = value
                        if (Array.isArray(item.sub_items)) item.sub_items = expandItem(item.sub_items, value)

                        return item
                    })

                    return items
                }

                items = expandItem(items, value)

                return items
            }

            _estimateItems = expand_by_group(_estimateItems, value)
            setEstimateItems(_estimateItems)

            setFilters(filters)

        }

        else if (filter_id == 'estimate-search') {

            let filters = { ...Filters }

            filters.left[0].value = value

            let _estimateItems = [...estimateItems]

            let search_items = (items, value, column = 'name') => {

                let search_item = (items, value, column = 'name') => {

                    items = items.map(item => {

                        let is_item_match = String(item[column]).toLocaleLowerCase().includes(String(value).toLocaleLowerCase())

                        if (Array.isArray(item.sub_items)) item.sub_items = search_item(item.sub_items, value, column)

                        let is_items_match = item.sub_items.filter(s => !s.disable).length > 0

                        if (is_items_match) item.disable = false
                        else if (is_item_match) item.disable = false
                        else item.disable = true

                        return item

                    })

                    return items

                }

                items = search_item(items, value, column)
                return items
            }

            _estimateItems = search_items(_estimateItems, value, 'name')

            setEstimateItems(_estimateItems)

            setFilters(filters)

        }

        else if (filter_id == 'estimate-download') {
            let _estimateItems = [...estimateItems]

            let ordered_items = []

            let flatter_subitems = (items) => {

                if (Array.isArray(items)) {

                    items.forEach(item => {

                        ordered_items.push(item)

                        if (Array.isArray(item.sub_items)) flatter_subitems(item.sub_items)

                    })
                }

            }
            flatter_subitems(_estimateItems)

            let table_header = [...tabelHeader]

            table_header.splice(0, 1)
            table_header.splice(table_header.length - 1, 1)


            let export_header = table_header.filter(th => !th.disable).map(th => {

                let id = ['quantity', 'unitrate', 'builder_price', 'markup_amount', 'tax_amount', 'price'].includes(th.id) ? `_${th.id}` : th.id

                return {
                    id: id,
                    label: th.label,
                }
            })

            export_header.splice(1, 0, ...[
                {
                    id: 'parent_name',
                    label: "Parent Name",
                },
                {
                    id: 'costcode_name',
                    label: "Work Category",
                },
            ])

            let _quantity_idx = export_header.findIndex(th => th.id == '_quantity')

            if (_quantity_idx != -1) {
                export_header.splice(_quantity_idx + 1, 0, ...[
                    {
                        id: 'unit_name',
                        label: "Unit"
                    }
                ])
            }

            let _markup_amount_idx = export_header.findIndex(th => th.id == '_markup_amount')

            if (_markup_amount_idx != -1) {

                export_header.splice(_markup_amount_idx, 1)

                export_header.splice(_markup_amount_idx, 0, ...[
                    {
                        id: '_markup_percent',
                        label: "Markup %"
                    },
                    {
                        id: '_markup_amount',
                        label: `Markup ${currency_type}`
                    },
                ])
            }

            let _tax_amount_idx = export_header.findIndex(th => th.id == '_tax_amount')

            if (_tax_amount_idx != -1) {

                export_header.splice(_tax_amount_idx, 1)

                export_header.splice(_tax_amount_idx, 0, ...[
                    {
                        id: '_tax_percent',
                        label: "Tax %"
                    },
                    {
                        id: '_tax_amount',
                        label: `Tax ${currency_type}`
                    },
                ])
            }

            Utils.exportEstimateToExcel(export_header, ordered_items, `${project_name} Estimate`)
        }
        else if (filter_id == 'estimate-morefilter') {
            setShowSideFilter(true)
        }

    }

    const isLeftFilter = Array.isArray(Filters.left) && Filters.left.length > 0
    const isRightFilter = Array.isArray(Filters.right) && Filters.right.length > 0

    const Icon_Filter = ({ props }) => {
        const {
            id,
            isSearchBar,
            type,
            value,
            isSelectToggle,
            isDropDownContainer,
            dropDownOptions,
            placeholder,
            callback,
            isIcon,
            icon,
            isLabel,
            label,
            isIconLeft,
        } = props

        const [iconStatus, setIconStatus] = useState(value != undefined ? value : false)

        const HandleFilterIconInput = (e) => {


            HandleFiltersEvent(!iconStatus, id)
            setIconStatus(!iconStatus)
        }

        return (
            <>
                {isIcon && type == 'icon' ?
                    <div
                        className="filter-item group-expand-filter"
                        onClick={(e) => HandleFilterIconInput(e)}
                        key={`filter-item-${id}`}
                    >
                        <div
                            className="icon"
                            dangerouslySetInnerHTML={{ __html: id.includes('expand') ? iconStatus ? Icons.general.collapse : Icons.general.expand : icon }}
                        ></div>
                        {isLabel ? <div className="label">{id.includes('expand') ? iconStatus ? 'Collapse all' : 'Expand all' : label}</div> : ''}
                    </div>

                    : null}
            </>
        )
    }
    const Button_Filter = ({ props }) => {
        const {
            id,
            isSearchBar,
            type,
            isDropDownContainer,
            dropDownOptions,
            callback,
            isIcon,
            icon,
            isLabel,
            label,
            isIconLeft,
        } = props
        const [selectFilterOpts, setSelectFilterOpts] = useState([])

        const [dropdownStatus, setDropDownStatus] = useState(false)

        const HandleClick = (e) => {
            setDropDownStatus(!dropdownStatus)
        }

        let HandleSubItemChange = (value, opt, i) => {

            let _filters = [...selectFilterOpts]

            if (_filters[i].id == 'opt-select-all') {
                _filters = _filters.map(f => {
                    f.value = value

                    return f
                })
            } else _filters[i].value = value

            setSelectFilterOpts(_filters)

            HandleFiltersEvent(opt, id)
        }

        useEffect(() => {

            let items_parent = document.querySelector(`#${id}`)

            window.addEventListener('click', (e) => {

                let path = e.path || (e.composedPath && e.composedPath());

                if (Array.isArray(path) && !path.includes(items_parent))
                    setDropDownStatus(false)
            })
            setSelectFilterOpts(dropDownOptions)
        }, [])


        return (
            <div className='filter-item dropdown-select-filter' id={id} key={`filter-item-${id}`}>
                <div
                    className="button"
                    onClick={(e) => HandleClick(e)}
                >
                    {isIcon && isIconLeft ?
                        <span
                            className={`icon ${dropdownStatus ? 'icon-active' : ''}`}
                            dangerouslySetInnerHTML={{ __html: icon }}
                        ></span>
                        : null}

                    {isLabel ? <span class='label'>{label}</span> : null}

                    {isIcon && !isIconLeft ?
                        <span
                            className={`icon ${dropdownStatus ? 'icon-active' : ''}`}
                            dangerouslySetInnerHTML={{ __html: icon }}
                        ></span>
                        : null}
                </div>

                {isDropDownContainer ?

                    <div className={`dropdown-select-items ${dropdownStatus ? 'dropdown-select-items-active' : ''}`}>

                        {selectFilterOpts.map((opt, i) => (

                            opt.type == 'label' ?
                                <div className="dropdown-select-title">{opt.label}</div>
                                :
                                <div className="dropdown-select-itm">
                                    <RadioInput props={{
                                        id: `filters-item-select-${opt.id}`,
                                        value: opt.value,
                                        isIcon: false,
                                        icon: "",
                                        inputType: "checkbox",
                                        name: "table-default-radio",
                                        setValue: (value) => HandleSubItemChange(value, opt, i)
                                    }} />
                                    <label className='label' htmlFor={`filters-item-select-${opt.id}`}>{opt.label}</label>
                                </div>

                        ))}

                    </div>

                    : null}
            </div>

        )
    }

    const HandleSummaryHeaderClick = (e) => {

        setSummaryHeaderOpen(!summaryHeaderOpen)

        if (!summaryHeaderOpen) document.querySelector(':root').style.setProperty('--estimation-summary-height', '300px')
        else document.querySelector(':root').style.setProperty('--estimation-summary-height', '80px')
    }

    const HandleRemoveOverHead = (id, index) => {

        let items = [...summaryOverHeads]

        items.splice(index, 1)

        setSummaryOverHeads(items)


        let options_items = [...overHeadsOptions]

        let item = overHeads_options.filter(itm => itm.id == id)[0]

        if (item != undefined) options_items.push(item)

        setOverHeadsOptions(options_items)

        setTimeout(() => {
            UpdateOverHeadServer(items)
        }, 100)

    }
    const HandleRemoveInsurance = (id, index) => {

        let items = [...summaryInsurance]

        items.splice(index, 1)

        setSummaryInsurance(items)


        let options_items = [...insurancesOptions]

        let item = insurances_options.filter(itm => itm.id == id)[0]

        if (item != undefined) options_items.push(item)

        setInsurancesOptions(options_items)

        setTimeout(() => {
            UpdateInsuranceServer(items)
        }, 100)
    }

    const HandleOverHeadSelect = (e) => {

        let value = e.target.value

        let items = [...overHeadsOptions]

        let index = items.findIndex(itm => itm.id == value)

        if (index != undefined) items.splice(index, 1)

        let itm = overHeadsOptions[index]

        let summaryItems = [...summaryOverHeads]

        if (!summaryItems.filter(item => item.id == itm.id).length) {

            summaryItems.push({
                id: itm.id,
                label: itm.label,
                value: itm.value,
                value_type: itm.value_type
            })

            setSummaryOverHeads(summaryItems)
        }

        setOverHeadsOptions(items)

        setTimeout(() => {
            UpdateOverHeadServer(summaryItems)
        }, 100)
    }
    const HandleInsuranceSelect = (e) => {

        let value = e.target.value

        let items = [...insurancesOptions]

        let index = items.findIndex(itm => itm.id == value)

        if (index != undefined) items.splice(index, 1)

        let itm = insurancesOptions[index]

        let summaryItems = [...summaryInsurance]

        if (!summaryItems.filter(item => item.id == itm.id).length) {

            summaryItems.push({
                id: itm.id,
                label: itm.label,
                value: itm.value,
                value_type: itm.value_type
            })

            setSummaryInsurance(summaryItems)
        }

        setInsurancesOptions(items)

        setTimeout(() => {
            UpdateInsuranceServer(summaryItems)
        }, 100)
    }


    useEffect(() => {

        let total_project_price = 0
        let total_project_allowance = 0
        let total_project_markup = 0
        let total_project_tax = 0
        let total_project_overhead = 0
        let total_project_insurance = 0
        let total_project_client = 0

        if (Array.isArray(estimateItemsOrginal)) {
            estimateItemsOrginal.forEach((data, i) => {

                let { builder_price, item_type, tax_amount, markup_amount } = data

                if (item_type == "5" || item_type == "6") total_project_allowance += parseFloat(builder_price || 0)
                else {
                    total_project_price += parseFloat(builder_price || 0)
                    total_project_markup += parseFloat(markup_amount || 0)
                    total_project_tax += parseFloat(tax_amount || 0)
                }
            })
        }

        if (Array.isArray(summaryOverHeads)) {

            total_project_overhead = summaryOverHeads.reduce((prev, val, ind) => {

                let { value, value_type } = val

                let amount = 0

                if (value_type == '%') amount = (parseFloat(value) * total_project_price) / 100
                else amount = parseFloat(value)

                return prev + amount

            }, 0)
        }

        if (Array.isArray(summaryInsurance)) {

            total_project_insurance = summaryInsurance.reduce((prev, val, ind) => {
                let { value, value_type } = val

                let amount = 0

                if (value_type == '%') amount = (parseFloat(value) * total_project_price) / 100
                else amount = parseFloat(value)

                return prev + amount

            }, 0)
        }
        total_project_client = total_project_price + total_project_allowance + total_project_markup + total_project_tax + total_project_overhead + total_project_insurance

        if (DiscountPerValue) {

            let discount_amount = (total_project_client * DiscountPerValue) / 100
            total_project_client = total_project_client - discount_amount

            setDiscountAmountValue(discount_amount)
        }

        if (adjustmentValue) {
            total_project_client = parseFloat(total_project_client + parseFloat(adjustmentValue)).toFixed(2)
        }

        total_project_client = parseFloat(total_project_client).toFixed(2)

        setBuilderFinalCost(total_project_price)
        setAllowanceFinalCost(total_project_allowance)
        setMarkupsFinalCost(total_project_markup)
        setTaxFinalCost(total_project_tax)
        setOverHeadValue(total_project_overhead)
        setInsuranceValue(total_project_insurance)
        setClientFinalCost(total_project_client)


    }, [adjustmentValue, DiscountPerValue, estimateItemsOrginal, summaryOverHeads, summaryInsurance])


    useEffect(() => {

        if (UpdatePricingTrigger) {
            setUpdatePricingTrigger(false)
            UpdateEstimatePricing()
        }

    }, [
        UpdatePricingTrigger
    ])

    const UpdateOverHeadServer = async (items) => {

        let updated_data = {

            overheadItems: items,
            projectid: projectId,
            estimate_id: estimate_id,
        }

        setIsLoading(true)
        let response = await estimateHandler.updateEstimateOverheadHandler(updated_data)

        if (response.success) {
            setIsLoading(false)

            if (!UpdatePricingTrigger) setUpdatePricingTrigger(true)
        }
        else {
            setIsLoading(false)
            setWarningAlert(true)
            setWarningAlertType('error')
            setApiFailedMessage(`Error in updating Overhead, Please try again!`)
        }
    }
    const UpdateInsuranceServer = async (items) => {

        let updated_data = {

            insuranceItems: items,
            projectid: projectId,
            estimate_id: estimate_id,
        }
        setIsLoading(true)
        let response = await estimateHandler.updateEstimateInsuranceHandler(updated_data)

        if (response.success) {
            setIsLoading(false)
            if (!UpdatePricingTrigger) setUpdatePricingTrigger(true)
        }
        else {
            setIsLoading(false)
            setWarningAlertType('error')
            setApiFailedMessage(`Error in updating Insurance, Please try again!`)
        }
    }
    const UpdateEstimatePricing = async () => {

        let updated_data = {
            builderFinalCost: String(builderFinalCost || 0),
            allowanceFinalCost: String(allowanceFinalCost || 0),
            overHeadValue: String(overHeadValue || 0),
            insuranceValue: String(insuranceValue || 0),
            markupsFinalCost: String(markupsFinalCost || 0),
            taxFinalCost: String(taxFinalCost || 0),
            adjustmentValue: String(adjustmentValue || 0),
            discountPerValue: String(DiscountPerValue || 0),
            discountAmountValue: String(DiscountAmountValue || 0),
            clientFinalCost: String(clientFinalCost || 0),
            projectid: projectId,
            estimate_id: estimate_id,

        }
        // setIsLoading(true)
        let response = await estimateHandler.updateEstimatePricingHandler(updated_data)

        if (response.success) {
            // setIsLoading(false)
        }
        else {
            // setIsLoading(false)
            setWarningAlert(true)
            setWarningAlertType('error')
            setApiFailedMessage(`Error in updating Pricing, Please try again!`)
        }
    }

    const HandleAdjustmentChange = (e) => {

        let value = e.target.value
        value = Utils.numberOnly(value)
        setAdjustmentValue(value)
        if (!UpdatePricingTrigger) setUpdatePricingTrigger(true)
    }
    const HandleDiscountPerChange = (e) => {

        let value = e.target.value
        value = Utils.numberOnly(value)
        setDiscountPerValue(value)
        if (!UpdatePricingTrigger) setUpdatePricingTrigger(true)
    }


    return (
        <>

            {isLoading ?

                <Loading
                    props={{
                        isMainLogo: false,
                        isLabel: true
                    }} />

                : null}

            {warningAlert ?

                <SystemToastPopup
                    props={{
                        type: warningAlertType,
                        message: apiFailedMessage || "Error in Estimate",
                        callback: (confirmation) => setWarningAlert(false)
                    }} />

                : null}

            {deleteItemAlert ?

                <AlertPopup
                    props={{
                        type: "delete",
                        actionBtnOption: { label: "Yes, Delete" },
                        heading: "Delete item",
                        message: getDeleteMessage(deleteItemType),
                        callback: (confirmation) => HandleDeleteConfirmItem(deleteItemId, deleteItemType, confirmation)
                    }} />

                : null}

            <Routes>
                <Route path='/:itemType/:itemId/:actionType/*'
                    element={<AddEstimateItemPopup
                        props={{
                            existItemData: existItemData || undefined,
                            updateCallback: HandleUpdateCallback
                        }}
                    />}>
                </Route>
                <Route path='/:itemType/:actionType/*'
                    element={<AddEstimateItemPopup
                        props={{
                            existItemData: undefined,
                            updateCallback: HandleUpdateCallback
                        }}
                    />}
                ></Route>
            </Routes>

            {
                ShowSideFilter ?
                    <SideFilters
                        props={
                            {
                                heading: 'Estimate Filters',
                                filters: SideFilterSettings,
                                close: HandleSideFilterClose,
                                updateCallback: HandleSideFilterUpdateCallback
                            }
                        }
                    />
                    : ''
            }

            <div className="project-project_estimate-main">
                <div className="project_estimate-panel-content">

                    <div className="panel-content-header">
                        <div className="title">Estimate</div>
                        {has_add_access ?
                            <div className="buttons">

                                <div
                                    className="button add-button"
                                    onClick={(e) => HandleAddBtn(e)}
                                    id="pd_estimate-addbtn"
                                >
                                    <div className="icon" dangerouslySetInnerHTML={{ __html: Icons.general.add_btn }}></div>
                                    <div className="label">Add Item</div>
                                </div>
                                <div id="pd_estimate-content-btn-options" className={`pd_estimate-content-btn-options ${isAddBtnClicked ? 'pd_estimate-content-btn-options-active' : ''}`}>

                                    {AddBtnOptions.map((option, i) => (
                                        <div
                                            key={`estimate-add-option-${option.id}`}
                                            className="btn-option-item"
                                            onClick={(e) => HandleAddBtnOption(option)}
                                        >
                                            <div
                                                className="icon"
                                                dangerouslySetInnerHTML={{ __html: option.icon }}
                                            ></div>
                                            <div className="label">{option.label}</div>
                                        </div>
                                    ))}

                                </div>


                            </div>
                            : ''}
                    </div>
                    <div className="filter-section-main">
                        {isLeftFilter ?

                            < div className="left-filter-main">
                                {Filters.left.map((item, i) => (

                                    item.type == 'icon' ? <Icon_Filter props={item} />
                                        : item.type == 'button' ? <Button_Filter props={item} />
                                            : item.isSearchBar && item.type == 'input' ?
                                                <div className="search-filter">
                                                    <SearcBarInput
                                                        props={{
                                                            id: item.id,
                                                            value: item.value,
                                                            placeholder: item.placeholder || "Search by name",
                                                            setValue: (val) => {
                                                                HandleFiltersEvent(val, item.id)
                                                            },
                                                            isIcon: item.isIcon,
                                                            isLeft: item.isIconLeft,
                                                            icon: item.icon || Icons.general.search
                                                        }}
                                                    />
                                                </div>
                                                : ''
                                ))}
                            </div>
                            : ''}
                        {isRightFilter ?

                            < div className="right-filter-main">
                                {Filters.right.map((item, i) => (

                                    item.type == 'icon' ? <Icon_Filter props={item} />
                                        : item.type == 'button' ? <Button_Filter props={item} />
                                            : item.isSearchBar && item.type == 'input' ?
                                                <div className="search-filter">
                                                    <SearcBarInput
                                                        props={{
                                                            id: item.id,
                                                            value: item.value,
                                                            placeholder: item.placeholder || "Search by name",
                                                            setValue: (val) => {
                                                                HandleFiltersEvent(val, item.id)
                                                            },
                                                            isIcon: item.isIcon,
                                                            isLeft: item.isIconLeft,
                                                            icon: item.icon || Icons.general.search
                                                        }}
                                                    />
                                                </div>
                                                : ''
                                ))}
                            </div>
                            : ''}
                    </div>
                    <div className="project-phases-filter-main">
                        <div className="phases-filter-header">
                            <div className="title">Project Phases</div>
                        </div>
                        <div className="phases-filter-items">
                            {ProjectPhases_Options.map((opt, i) => (
                                <div
                                    key={`project-phases-filter-${opt.id}`}
                                    className={`phases_filter-item ${SelectedPhaseSection == opt.id ? 'phases_filter-item-active' : ''}`}
                                    onClick={(e) => HandlePhaseSection(opt.id)}
                                >
                                    <div className="label">{opt.name}</div>
                                </div>
                            ))}

                        </div>
                    </div>
                    <div className="panel-content-sections">
                        {estimateItems.filter(e => (SelectedPhaseSection == 'all' ? '' : SelectedPhaseSection) ? e.phase_id == SelectedPhaseSection : true).length ?
                            <Paper style={{ width: '100%', height: '100%', boxShadow: 'none', overflow: 'hidden' }}>
                                <TableContainer style={{ height: 'calc(100% - 80px)', boxShadow: 'none !important', }} >
                                    <Table stickyHeader>
                                        <TableHead
                                            className='tabel-header estimate-tabel-header'
                                        >
                                            <TableRow>
                                                {tabelHeader.filter(th => !th.disable).map((column) => (

                                                    <TableCell
                                                        key={column.id}
                                                        align={column.align}
                                                        style={{ minWidth: column.minWidth }}
                                                        className='tabel-header-items'
                                                    >
                                                        {column.id == 'select' ?

                                                            <RadioInput props={{
                                                                value: tableSelectAll || false,
                                                                isIcon: false,
                                                                icon: "",
                                                                inputType: "checkbox",
                                                                name: "table-default-radio",
                                                                setValue: (value) => HandleSelectAllEvent(value)
                                                            }} />
                                                            :
                                                            column.label}
                                                    </TableCell>
                                                ))}
                                            </TableRow>
                                        </TableHead>
                                        <TableBody className='tabel-body'>
                                            {estimateItems.filter(e => (SelectedPhaseSection == 'all' ? '' : SelectedPhaseSection) ? e.phase_id == SelectedPhaseSection : true).map((row, i) => (
                                                CreateEstimateTableItem(row, i, 0, true)
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Paper>
                            : <NoDataFound label={'No Items Added yet'} />}
                    </div>
                    <div className="panel-content-footer">

                        <div className={`pd_estimate-editview-summary ${summaryHeaderOpen ? 'pd_estimate-editview-summary-active' : ''}`}>
                            <div className="summary-left">
                                <div className="summary-header" style={{ height: `${summaryHeaderOpen ? '50px' : '80px'}` }}>
                                    <div className="left">
                                        <div className="left-item" onClick={(e) => HandleSummaryHeaderClick(e)}>
                                            <div
                                                className="icon"
                                                dangerouslySetInnerHTML={{ __html: Icons.general.dropdown_arrow }}
                                            ></div>
                                            <div className="label">Summary</div>
                                        </div>
                                    </div>
                                    <div className="right">
                                        <div className="right-item">
                                            <div className="label">Prime Fixed Cost</div>
                                            <div className="value ">{currency_type}{parseFloat(builderFinalCost || 0).toFixed(0)}</div>
                                        </div>
                                        <div className="right-item">
                                            <div className="label">Allowances</div>
                                            <div className="value ">{currency_type}{parseFloat(allowanceFinalCost || 0).toFixed(0)}</div>
                                        </div>
                                        <div className="right-item">
                                            <div className="label">Markups</div>
                                            <div className="value value-markups">{currency_type}{parseFloat(markupsFinalCost || 0).toFixed(0)}</div>
                                        </div>
                                        <div className="right-item">
                                            <div className="label">Final price</div>
                                            <div className="value value-price">{currency_type}{parseFloat(clientFinalCost || 0).toFixed(0)}</div>
                                        </div>
                                    </div>
                                </div>
                                <div className="summary-content" style={{ display: `${summaryHeaderOpen ? 'flex' : 'none'}` }}>
                                    <div className="content-item">
                                        <div className="head">
                                            <div
                                                className="icon"
                                                dangerouslySetInnerHTML={{ __html: Icons.general.percentage }}
                                            ></div>
                                            <div className="label">Overheads</div>
                                            <div className="select-input">
                                                <select name="" id="" onInput={(e) => HandleOverHeadSelect(e)}>
                                                    <option value="">Add an option</option>
                                                    {overHeadsOptions.map((item, i) => (
                                                        <option key={item.id} id={item.id} value={item.id}>{item.label}</option>
                                                    ))}
                                                </select>
                                            </div>
                                        </div>
                                        <div className="head-items">
                                            {summaryOverHeads.map((item, i) => (

                                                <div className="item" id={item.id} key={item.id}>
                                                    <div className="title">{item.label}</div>
                                                    <div className="quantity">{item.value_type == '%' ? `${item.value}%` : `${currency_type}${item.value}`}</div>
                                                    <div className="value">
                                                        {currency_type} {item.value_type == '%' ? ((builderFinalCost * parseFloat(item.value)) / 100).toFixed(2) : parseFloat(item.value).toFixed(2)}
                                                        <div
                                                            onClick={() => HandleRemoveOverHead(item.id, i)}
                                                            className="close"
                                                            dangerouslySetInnerHTML={{ __html: Icons.general.close }}
                                                        ></div>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                    <div className="content-item">
                                        <div className="head">
                                            <div
                                                className="icon"
                                                dangerouslySetInnerHTML={{ __html: Icons.general.protection }}
                                            ></div>
                                            <div className="label">Insurances</div>
                                            <div className="select-input">
                                                <select name="" id="" onInput={(e) => HandleInsuranceSelect(e)}>
                                                    <option value="">Add an option</option>

                                                    {insurancesOptions.map((item, i) => (
                                                        <option key={item.id} id={item.id} value={item.id}>{item.label}</option>
                                                    ))}

                                                </select>
                                            </div>
                                        </div>
                                        <div className="head-items">

                                            {summaryInsurance.map((item, i) => (

                                                <div className="item" id={item.id} key={item.id}>
                                                    <div className="title">{item.label}</div>
                                                    <div className="quantity">{item.value_type == '%' ? `${item.value}%` : `${currency_type}${item.value}`}</div>
                                                    <div className="value">
                                                        {currency_type} {item.value_type == '%' ? ((builderFinalCost * parseFloat(item.value)) / 100).toFixed(2) : parseFloat(item.value).toFixed(2)}
                                                        <div
                                                            onClick={() => HandleRemoveInsurance(item.id, i)}
                                                            className="close"
                                                            dangerouslySetInnerHTML={{ __html: Icons.general.close }}
                                                        ></div>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="summary-right">
                                <div className="title">Total</div>
                                <div className="total-items">
                                    <div className="total-item">
                                        <div className="label">Prime Fixed Cost</div>
                                        <div className="value">{currency_type}{parseFloat(builderFinalCost || 0).toFixed(2)}</div>
                                    </div>
                                    <div className="total-item">
                                        <div className="label">Allowances</div>
                                        <div className="value">{currency_type}{parseFloat(allowanceFinalCost || 0).toFixed(2)}</div>
                                    </div>
                                    <div className="total-item">
                                        <div className="label">Overheads</div>
                                        <div className="value">{currency_type}{parseFloat(overHeadValue || 0).toFixed(2)}</div>
                                    </div>
                                    <div className="total-item">
                                        <div className="label">Insurance</div>
                                        <div className="value">{currency_type}{parseFloat(insuranceValue || 0).toFixed(2)}</div>
                                    </div>
                                    <div className="total-item ">
                                        <div className="label">Markup</div>
                                        <div className="value value-markups">{currency_type}{parseFloat(markupsFinalCost || 0).toFixed(2)}</div>
                                    </div>
                                    <div className="total-item">
                                        <div className="label">Tax</div>
                                        <div className="value">{currency_type}{parseFloat(taxFinalCost || 0).toFixed(2)}</div>
                                    </div>

                                    <div className="total-item">
                                        <div className="label">Discount % </div>
                                        <input
                                            className='input'
                                            type='text'
                                            value={DiscountPerValue}
                                            onChange={HandleDiscountPerChange}

                                        />
                                    </div>
                                    <div className="total-item">
                                        <div className="label">Discount {currency_type} </div>
                                        <div className="value">{currency_type}{parseFloat(DiscountAmountValue || 0).toFixed(2)}</div>
                                    </div>
                                    <div className="total-item">
                                        <div className="label">Adjust {currency_type}</div>
                                        <input
                                            className='input'
                                            type='text'
                                            value={adjustmentValue}
                                            onChange={HandleAdjustmentChange}

                                        />
                                    </div>
                                </div>
                                <div className="final-total">
                                    <div className="label">Final price</div>
                                    <div className="value">{currency_type}{clientFinalCost}</div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </>
    )

}

export default Estimate;