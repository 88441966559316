import React, { useEffect, useState, useRef } from 'react'
import Images from '../assets/Images'
import Icons from '../assets/Icons'

import { RadioInput } from './Inputs'
const ShowToClient = ({ props }) => {
    const {
        value,
        callback
    } = props

    const [showToClient, setShowToClient] = useState(value)

    const HandleInputChange = (value) => {
        setShowToClient(value)
        callback(value)
    }

    useEffect(() => {
        console.log(value, 'value');

        setShowToClient(value)
    }, [value])

    return (
        <div className="option-view_client-toggle">
            <div className="label">
                Show to Client
            </div>
            <div className="input">
                <RadioInput props={{
                    value: showToClient,
                    isIcon: false,
                    icon: "",
                    inputType: "toggle",
                    name: "table-default-toggle",
                    setValue: (value) => HandleInputChange(value),
                }} />
            </div>
        </div>
    )
}

export default ShowToClient;