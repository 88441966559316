import React, { useEffect, useState, useRef, Fragment } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import UserAction from '../../redux/action/userAction'
import StoreHandler from '../../redux/StoreHandler'

// import User_Handler from '../../Handlers/Users/User'
import Loading from '../../components/Loading'
import AlertPopup from '../../components/AlertPopup'

import { Link, useNavigate } from 'react-router-dom'
import Image from '../../assets/Images'
import Icons from '../../assets/Icons'


const Feedback = () => {


    const [isLoading, setIsLoading] = useState(false)
    const [warningAlert, setWarningAlert] = useState(false)
    const [apiFailedMessage, setApiFailedMessage] = useState('Invalid Inputs, Please try again with vaild Inputs!')
    const navigate = useNavigate();

    // const user_Handler = new User_Handler()

    const [rating, setRating] = useState('0')
    const [category, setCategory] = useState('')
    const [message, setMessage] = useState('')

    const HandleFormSubmit = async (e) => {
        e.preventDefault()

        let userdetials = JSON.parse(localStorage.getItem("userdetials"))

        let payload = {
            rating: rating,
            category: category,
            message: message,
            company_id: userdetials.company_id,
            user_id: userdetials.id,
        }

        setIsLoading(true)

        let response = {
            success: true
        }

        // let response = await user_Handler.createCustomerFeedbackHandler(payload)

        setIsLoading(false)

        if (!response.success) {

            setIsLoading(false)
            setWarningAlert(true)
            setApiFailedMessage(`Something went wrong, Please try again!`)

            return
        }

        navigate('/projects')

    }

    return (
        <>
            {isLoading ?

                <Loading
                    props={{
                        isMainLogo: false,
                        isLabel: true
                    }} />

                : null}

            <div className="project-feedback-main">
                <form className="feedback-content" onSubmit={(e) => HandleFormSubmit(e)}>
                    <div className="feedback-header">
                        <div className="title">Customer Feedback</div>
                        <div className="desc">
                            Streamlined and user-friendly, our customer
                            feedback page is your direct line to share
                            your thoughts and experiences effortlessly.
                        </div>
                    </div>
                    <div className="feedback-inputs">
                        <div className="feedback-input-item">
                            <span className='label'>Feel free to rate us!</span>

                            <div class="feedback-input-rating">
                                <input onInput={(e) => setRating(e.target.value)} type="radio" id="star5" name="feedback-rating" value="5" />
                                <label class="star" for="star5" title="Awesome" aria-hidden="true"></label>
                                <input onInput={(e) => setRating(e.target.value)} type="radio" id="star4" name="feedback-rating" value="4" />
                                <label class="star" for="star4" title="Great" aria-hidden="true"></label>
                                <input onInput={(e) => setRating(e.target.value)} type="radio" id="star3" name="feedback-rating" value="3" />
                                <label class="star" for="star3" title="Very good" aria-hidden="true"></label>
                                <input onInput={(e) => setRating(e.target.value)} type="radio" id="star2" name="feedback-rating" value="2" />
                                <label class="star" for="star2" title="Good" aria-hidden="true"></label>
                                <input onInput={(e) => setRating(e.target.value)} type="radio" id="star1" name="feedback-rating" value="1" />
                                <label class="star" for="star1" title="Bad" aria-hidden="true"></label>
                            </div>

                        </div>
                        <div className="feedback-input-item">
                            <label className='label' htmlFor="feedback-input-category">Select feedback category</label>
                            <select onInput={(e) => setCategory(e.target.value)} className='feedback-input-category' required={true} name="" id="feedback-input-category">
                                <option value="general">General</option>
                                <option value="feature_request">Feature request</option>
                                <option value="suggestions">Suggestions</option>
                                <option value="bug">Bug</option>
                            </select>
                        </div>
                        <div className="feedback-input-item">

                            <label className='label' htmlFor="feedback-input-message">Do you have any suggestions to improve our product?</label>
                            <textarea onInput={(e) => setMessage(e.target.value)} className='feedback-input-message' required={true} id="feedback-input-message"></textarea>
                        </div>
                    </div>
                    <div className="feedback-buttons">
                        <button type='submit' className="feedback-button-item">Submit</button>
                    </div>
                </form>
            </div>
        </>
    )
}

export default Feedback;