

import React, { useEffect, useState, useRef, useCallback, useMemo, StrictMode } from 'react'
import { Route, Routes, BrowserRouter, Link, useLocation, Navigate, Outlet, useNavigate, useParams } from 'react-router-dom';
import store from '../../redux/store'
import StoreHandler from '../../redux/StoreHandler'
import { useSelector, useDispatch } from 'react-redux'
import UserAction from '../../redux/action/userAction'

import ReverseProxy from '../../config/reverseProxy'
import SettingsHandler from '../../Handlers/Settings/Settings';
import EstimateHandler from '../../Handlers/Estimate/Estimate';
import PurchaseRequestHandler from '../../Handlers/PurchaseRequest/PurchaseRequest';
import PurchaseOrderHandler from '../../Handlers/PurchaseOrder/PurchaseOrder';

import CommentsHandler from '../../Handlers/Comments/Comments';

import Icons from '../../assets/Icons'
import Images from '../../assets/Images'
import { CostInput, TextInput, SelectInput, DateTimeInput, RadioInput, MultiSelectInput } from '../../components/Inputs'
import Utils from '../../utils'
import 'react-quill/dist/quill.snow.css';

import SystemToastPopup from '../../components/SystemToastPopup'
import Loading from '../../components/Loading'

import { useAuth, useMenuAuth } from '../../hooks/useAuth'

import PreviewOptimizedPOCreation from './previewOptimizedPOCreation'

const PurchaseRequestResponses = ({ props }) => {

    const { projectId, requestId } = useParams();

    const { menuType = 'create' } = props

    const [isLoading, setIsLoading] = useState(false)
    const [warningAlert, setWarningAlert] = useState(false)
    const [warningAlertType, setWarningAlertType] = useState('warning')
    const [apiFailedMessage, setApiFailedMessage] = useState('Error in Purchase Order')

    const navigate = useNavigate()
    const store = useSelector((store) => store)
    const dispatch = useDispatch()
    const { updateState } = new UserAction

    const storeHandler = new StoreHandler({
        dataStack: ['team_users', 'vendors'],
        store: store,
        dispatch: dispatch,
        updateState: updateState,
    })

    const LoadStoreData = async () => {
        setIsLoading(true)
        let resolved = await storeHandler.init()
        setIsLoading(false)
    }

    const { isAdmin, menu_permission } = useMenuAuth({ menu: 'purchase--purchase_orders', feature_key: 'purchase-orders' })
    const location = useLocation()

    const [selected_view_settings_value, setSelected_view_settings_value] = useState('')
    const [has_view_access, setHas_view_access] = useState(false)
    const [has_add_access, setHas_add_access] = useState(false)
    const [has_edit_access, setHas_edit_access] = useState(false)
    const [has_delete_access, setHas_delete_access] = useState(false)

    useEffect(() => {

        if (isAdmin) {

            setHas_view_access(true)
            setHas_add_access(true)
            setHas_edit_access(true)
            setHas_delete_access(true)
        }

        else if (menu_permission) {

            if (
                !menu_permission._view
                &&
                (!menu_permission.selected_view_settings_value
                    || menu_permission.selected_view_settings_value == 'no_access')
            ) {
                navigate(`${ReverseProxy['proxyUrl']}/quick-menu`, { state: { from: location }, replace: true })
            }


            setHas_view_access(menu_permission._view == '1')
            setHas_add_access(menu_permission._create == '1')
            setHas_edit_access(menu_permission._edit == '1')
            setHas_delete_access(menu_permission._delete == '1')

            setSelected_view_settings_value(menu_permission.selected_view_settings_value)

        }

    }, [store])

    const purchaseRequestHandler = new PurchaseRequestHandler()
    const purchaseOrderHandler = new PurchaseOrderHandler()
    const commentsHandler = new CommentsHandler()

    const [currency_type, setCurrency_type] = useState('₹')

    const [id, setId] = useState(Utils.getUniqueId())
    const [name, setName] = useState('')
    const [requestID, setRequestID] = useState('')
    const [status, setStatus] = useState('')

    const [introduction_text, setIntroduction_text] = useState('')

    const [termsConditionOptions, setTermsConditionOptions] = useState([])
    const [termsConditionId, setTermsConditionId] = useState("")
    const [termsConditionValue, setTermsConditionValue] = useState("")

    const [AttachmentsFiles, setAttachmentsFiles] = useState([])

    const [OpeningDate, setOpeningDate] = useState(Utils._getLocalDate())
    const [DueDate, setDueDate] = useState('')
    const [DeliveryDeadlineDate, setDeliveryDeadlineDate] = useState('')


    const [CostItems, setCostItems] = useState([])
    const [Submissions, setSubmissions] = useState([])

    const [PendingTotal, setPendingTotal] = useState(0)
    const [SubmittedTotal, setSubmittedTotal] = useState(0)
    const [RejectedTotal, setRejectedTotal] = useState(0)

    const [showOptimizedPOCreation, setShowOptimizedPOCreation] = useState(false)

    const HandleSubmitManual = (e) => {

    }

    const HandleCancel = (e) => {

        navigate(`/builder/projects/${projectId}/purchase_request`)
    }

    const LoadPurchaseRequest = async () => {

        let filter = {
            requestId: requestId,
            projectId: projectId,
        }

        setIsLoading(true)

        let response = await purchaseRequestHandler.getPurchaseRequestsHandler(filter)
        setIsLoading(false)

        if (response.success) {
            let { data } = response

            if (!Array.isArray(data) || !data.length) {
                return navigate(`/builder/projects/${projectId}/purchase_request`)
            }


            let {
                id: ID,
                name,
                requestID,
                projectId: projectID,
                opening_date,
                due_date,
                introduction_text,
                delivery_deadline_date,

                status,
                terms_condition_id,
                terms_condition,
                attachments,
                cost_items,
                submissions = [],
                assignees,
                vendors
            } = data[0]

            setId(ID)
            setRequestID(requestID)
            setName(name)
            setStatus(status)

            setIntroduction_text(introduction_text)

            setTermsConditionId(terms_condition_id)
            setTermsConditionValue(terms_condition)


            setOpeningDate(opening_date)
            setDueDate(due_date)
            setDeliveryDeadlineDate(delivery_deadline_date)

            setCostItems(cost_items || [])
            setAttachmentsFiles(attachments || [])


            submissions = submissions.map((submission) => {
                submission.cost_items = submission.cost_items || []
                return submission
            })

            let submissions_total = submissions.reduce((acc, submission) => {
                if (submission.submission_status == '0') acc.pending += 1
                else if (submission.submission_status == '1') acc.submitted += 1
                else if (submission.submission_status == '2') acc.rejected += 1
                return acc
            }, {
                pending: 0,
                submitted: 0,
                rejected: 0
            })

            setPendingTotal(submissions_total.pending || 0)
            setSubmittedTotal(submissions_total.submitted || 0)
            setRejectedTotal(submissions_total.rejected || 0)


            setSubmissions(submissions || [])
        }
    }


    const createPurchaseOrder = (submission) => {

        let po_data = {
            ...submission,
            name: name,
            delivery_deadline_date: DeliveryDeadlineDate,
            description: introduction_text,
            terms_condition_id: termsConditionId,
            terms_condition: termsConditionValue,
            attachments: AttachmentsFiles,

        }

        navigate(`/builder/projects/${projectId}/purchase_order/create?po_data=${JSON.stringify(po_data)}`)
    }

    const OpenOptimisedPOCreation = () => {
        setShowOptimizedPOCreation(true)
    }
    const createOptimisedPurchaseOrder = async (purchase_orders) => {

        let selected_pos = purchase_orders.filter((po) => po.selected == true)

        selected_pos = selected_pos.map((po) => {

            let assignees = po.assignees?.map(p => p.value)

            return {
                id: po.id,
                projectId: po.projectId,
                purchaseId: po.purchaseID,
                status: String(po.status),
                name: po.name,
                purchase_date: Utils._getLocalDate(),
                approval_deadline_date: po.approval_deadline_date,
                delivery_deadline_date: po.delivery_deadline_date,
                vendor_id: po.vendorID,
                vendor_name: po.vendor_name,
                introduction_text: po.introduction_text,
                terms_condition_id: po.terms_condition_id,
                terms_condition: po.terms_condition,
                comments: '',
                cost_items: po.cost_items,
                attachments: [],
                assignees: assignees || [],
                delivery_history: [],
            }
        })

        if (!selected_pos.length) {
            setWarningAlert(true)
            setWarningAlertType('warning')
            setApiFailedMessage(`Please select atleast one Purchase Order!`)
            return
        }


        let update_detials = {
            projectId: projectId,
            purchase_orders: selected_pos
        }
        let response = await purchaseOrderHandler.createMultiPurchaseOrderHandler(update_detials)

        if (response.success) {
            setIsLoading(false)


            setWarningAlert(true)
            setWarningAlertType('success')
            setApiFailedMessage(`Purchase Orders created successfully!`)

            setTimeout(() => {
                setShowOptimizedPOCreation(false)
            }, 800)
        }
        else {
            setIsLoading(false)
            setWarningAlert(true)
            setWarningAlertType('error')
            setApiFailedMessage(`Error in Purchase Order, Please try again!`)
        }

    }

    const [SelectedTab, setSelectedTab] = useState('list')

    const HandleTabSelection = (tab) => {
        setSelectedTab(tab)
    }
    const RenderListTab = () => {
        const getStatus = (status) => {
            if (status == '0') return {
                lable: 'Pending',
                color: '#7109d1'
            }
            if (status == '1') return {
                lable: 'Submitted',
                color: '#2855ff'
            }
            if (status == '2') return {
                lable: 'Rejected',
                color: '#e54c40'
            }
        }

        const getTotal = (items) => {
            items = items || []

            let total = items.reduce((acc, item) => acc + parseFloat(item.amount || 0), 0)

            return total
        }

        const HandleListClick = (submission) => {
            navigate(`/builder/projects/${projectId}/purchase_request/${requestId}/responses/submission/${submission.id}`)
        }


        return (
            <div className="rfq-responses-list-items">
                {Submissions.map((submission, index) => (
                    <div
                        key={`rfq-responses-list-item-${index}`}
                        className="rfq-responses-list-item"
                        onClick={() => HandleListClick(submission)}
                    >
                        <div className="responses-list-item-left">
                            <div className="item-title">{submission.vendor_id} | {submission.vendor_name}</div>
                            <div className="item-desc">{submission.vendor_email}</div>
                        </div>
                        <div className="responses-list-item-right">
                            <div className="item-status item-status-pending"
                                style={{ backgroundColor: getStatus(submission.submission_status)?.color || '#7109d1' }}
                            >{getStatus(submission.submission_status)?.lable || 'Pending'}</div>
                            <div className="item-total">
                                <div className="label">Total</div>
                                <div className="value">{currency_type}{getTotal(submission.cost_items)}</div>
                            </div>
                            <div className="item-action"
                                dangerouslySetInnerHTML={{ __html: Icons.general.dotted_action }}
                            ></div>
                        </div>
                    </div>
                ))}
            </div>
        )
    }
    const RenderCompareTab = () => {

        const getTotal = (items) => {
            items = items || []

            let total = items.reduce((acc, item) => acc + parseFloat(item.amount || 0), 0)

            return total
        }

        const getSubmittedCostItems = (cost_item) => {

            let SubmittedCostItems = []

            Submissions.forEach((submission) => {

                let submitted_cost_item = submission.cost_items.find((item) => item.material_id == cost_item.material_id)

                if (submitted_cost_item) {
                    SubmittedCostItems.push({
                        id: submitted_cost_item.id,
                        material_id: submitted_cost_item.material_id,
                        max_unitrate: parseFloat(cost_item.max_unitrate || 0),
                        unitrate: submitted_cost_item.unitrate,
                        amount: parseFloat(submitted_cost_item.amount || 0)
                    })
                }
            })

            let getMinSubmissionItem = (m_id) => {
                let minimum_item = SubmittedCostItems.reduce((acc, item) => {
                    if (item.material_id == m_id) {
                        if (acc.unitrate > item.unitrate) {
                            return item
                        }
                    }
                    return acc
                }, SubmittedCostItems[0])

                return minimum_item
            }

            SubmittedCostItems = SubmittedCostItems.map((item) => {
                let min_item = getMinSubmissionItem(item.material_id)

                if (item.unitrate == min_item.unitrate) {
                    item.style = {
                        bg_color: '#07c07e1a',
                        color: '#07C07E'
                    }
                }

                if (item.unitrate > item.max_unitrate) {
                    item.style = {
                        bg_color: '#f29d411a',
                        color: '#F29D41'
                    }
                }

                return item
            })

            return SubmittedCostItems
        }

        return (
            <>

                <div className="rfq-responses-compare-main">
                    <div className="compare-cost_items-table">
                        <table>
                            <thead>
                                <tr>
                                    <th>Cost item</th>
                                    <th>Oty/Type</th>
                                    <th>Max unit price</th>
                                </tr>
                            </thead>
                            <tbody>
                                {CostItems.map((item, index) => (
                                    <tr
                                        key={`compare-cost_item-tr-${index}`}
                                    >
                                        <td>{item.material_name}</td>
                                        <td>{item.quantity || ''} {item.unit_name || ''}</td>
                                        <td>{currency_type}{item.max_unitrate || '0.00'}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                    {Submissions.length ?
                        <div className="compare-vendor_response-table">
                            <table>
                                <thead>
                                    <tr>
                                        {Submissions.map((submission, index) => (

                                            <th
                                                key={`compare-vendor_response-th-${index}`}
                                                colSpan={2}
                                            >
                                                <div className="th-vendor">
                                                    <div className="vendor-action-main">
                                                        <div className="vendor-action-btn"
                                                            onClick={() => createPurchaseOrder(submission)}
                                                        >Create PO</div>

                                                    </div>
                                                    <div className="vendor-name">{submission.vendor_name}</div>
                                                    <div className="vendor-budget">{currency_type}{getTotal(submission.cost_items)}</div>
                                                </div>
                                            </th>
                                        ))}
                                    </tr>
                                    <tr>
                                        {Submissions.map((submission, index) => (
                                            <>
                                                <th
                                                    key={`compare-vendor_response-th-${index}-unitrate`}
                                                >Unit price</th>
                                                <th
                                                    key={`compare-vendor_response-th-${index}-amount`}
                                                >Amount</th>
                                            </>
                                        ))}
                                    </tr>
                                </thead>
                                <tbody>
                                    {CostItems.map((item, index) => (
                                        <tr
                                            key={`compare-vendor_response-tbody-tr-${index}`}
                                        >
                                            {getSubmittedCostItems(item)?.map((s_item, index) => (
                                                <>
                                                    <td
                                                        key={`compare-vendor_response-tbody-td-${index}-unitrate`}
                                                        style={{
                                                            backgroundColor: s_item.style?.bg_color || '#fff',
                                                            color: s_item.style?.color || '#000'
                                                        }}
                                                    >{currency_type}{s_item.unitrate || '0'}</td>
                                                    <td
                                                        key={`compare-vendor_response-tbody-td-${index}-amount`}
                                                        style={{
                                                            backgroundColor: s_item.style?.bg_color || '#fff',
                                                            color: s_item.style?.color || '#000'
                                                        }}
                                                    >{currency_type}{s_item.amount || '0.00'}</td>
                                                </>
                                            ))}
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                        : ''}
                </div>

            </>
        )
    }
    const RenderTab = (tab) => {

        if (tab == 'list') return RenderListTab()
        if (tab == 'compare') return RenderCompareTab()
    }

    useEffect(() => {


        if (menuType == 'create') {
        }
        else {
            LoadPurchaseRequest()
        }

        // LoadStoreData()
    }, [])






    return (
        <>


            {isLoading ?
                <Loading
                    props={{
                        isMainLogo: false,
                        isLabel: true
                    }} />
                : null}
            {warningAlert ?

                <SystemToastPopup
                    props={{
                        type: warningAlertType,
                        message: apiFailedMessage || "Error in Purchase Order, Please try again!",
                        callback: (confirmation) => setWarningAlert(false)
                    }} />

                : null}

            {showOptimizedPOCreation ?
                <PreviewOptimizedPOCreation
                    props={{
                        po_data: {
                            name: name || '',
                            description: introduction_text || '',
                            delivery_deadline_date: DeliveryDeadlineDate || '',
                            approval_deadline_date: DeliveryDeadlineDate || '',
                            terms_condition_id: termsConditionId || '',
                            terms_condition: termsConditionValue || '',
                            attachments: AttachmentsFiles || [],
                            submissions: Submissions,
                        },
                        saveCallback: createOptimisedPurchaseOrder,
                        close: () => { setShowOptimizedPOCreation(false) }
                    }}
                />
                : ''}


            <div className="project-create_client_invoice-main" id='project-create_client_invoice-main'>
                <div className="create_client_invoice-panel-content">

                    <div className="panel-content-header">
                        <div className="left">
                            <div className="icon"
                                dangerouslySetInnerHTML={{ __html: Icons.general.back_arrow }}
                                onClick={(e) => HandleCancel(e)}
                            ></div>
                            <div className="title">RFQ responses</div>
                        </div>
                        <div className="right">
                            <div className="offers-button" onClick={() => HandleSubmitManual()}>
                                <div
                                    className="icon"
                                    dangerouslySetInnerHTML={{ __html: Icons.general.submissions }}
                                ></div>
                                <div className="label">Submit manually</div>
                            </div>
                        </div>
                    </div>
                    <form className="panel-content-sections">
                        <div className="create-client_invoicecontent-main">

                            <div className="create-client_invoicesections" style={{ height: '100%' }}>
                                <div className="rfq-responses-main">

                                    <div className="rfq-responses-header">
                                        <div className="rfq-responses-tabs">
                                            <div className="rfq-responses-tab-items">
                                                <div
                                                    className={`rfq-responses-tab-item ${SelectedTab == 'list' ? 'tab-item-active' : ''}`}
                                                    onClick={() => HandleTabSelection('list')}
                                                >List</div>
                                                <div
                                                    className={`rfq-responses-tab-item ${SelectedTab == 'compare' ? 'tab-item-active' : ''}`}
                                                    onClick={() => HandleTabSelection('compare')}

                                                >Compare</div>
                                            </div>
                                        </div>
                                        <div className="rfq-responses-header-right">
                                            <div className="rfq-responses-status">
                                                <div className="rfq-responses-status-items">
                                                    <div className="rfq-responses-status-item">Pending <div className="status-item-no">{PendingTotal || 0}</div></div>
                                                    <div className="rfq-responses-status-item">Submitted <div className="status-item-no">{SubmittedTotal || 0}</div></div>
                                                    <div className="rfq-responses-status-item">Rejected <div className="status-item-no">{RejectedTotal || 0}</div></div>
                                                </div>
                                            </div>
                                            <div className="rfq-responses-actions">
                                                <div className={`rfq-responses-action-item action-item-create_po`}
                                                    onClick={() => OpenOptimisedPOCreation()}
                                                >Create Optimised POs</div>
                                            </div>
                                        </div>

                                    </div>
                                    <div className="rfq-responses-content">
                                        {RenderTab(SelectedTab)}
                                    </div>
                                </div>
                            </div>

                        </div>
                    </form>
                </div >

            </div >
        </>
    )

}

export default PurchaseRequestResponses;