

import React, { useEffect, useState, useRef } from 'react'
import { Route, Routes, BrowserRouter, Link, useLocation, Navigate, Outlet, useNavigate, useParams } from 'react-router-dom';
import store from '../../redux/store'
import { useSelector, useDispatch } from 'react-redux'
import UserAction from '../../redux/action/userAction'

import ReverseProxy from '../../config/reverseProxy'
import RoleManagement from '../../Handlers/RoleManagement/RoleManagement';
import TeamManagementHandler from '../../Handlers/TeamManagement/TeamManagement';

import Icons from '../../assets/Icons'
import Images from '../../assets/Images'
import { CostInput, TextInput, SelectInput, RadioInput } from '../../components/Inputs'
import Utils from '../../utils'
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

import AlertPopup from '../../components/AlertPopup'
import Loading from '../../components/Loading'
import SystemToastPopup from '../../components/SystemToastPopup'

import { load } from '@cashfreepayments/cashfree-js';

import CashFree_PaymentgatewayHandler from '../../Handlers/PaymentGateways/CashFree_Paymentgateway'
import Razorpay_PaymentgatewayHandler from '../../Handlers/PaymentGateways/Razorpay_Paymentgateway'

import { useAuth, useMenuAuth } from '../../hooks/useAuth'




const SubscriptionPlans = () => {

    const store = useSelector((store) => store)
    const dispatch = useDispatch()
    const { updateState } = new UserAction

    const navigate = useNavigate()

    const cashFree_PaymentgatewayHandler = new CashFree_PaymentgatewayHandler()
    const razorpay_PaymentgatewayHandler = new Razorpay_PaymentgatewayHandler()

    const { isAdmin, roles_permissions, company_features } = useAuth()

    const [isLoading, setIsLoading] = useState(false)
    const [warningAlert, setWarningAlert] = useState(false)
    const [warningAlertType, setWarningAlertType] = useState('warning')
    const [apiFailedMessage, setApiFailedMessage] = useState("Error in Server")

    const [UsersDetials_original, setUsersDetials_original] = useState([])
    const [UsersDetials, setUsersDetials] = useState([])
    const [selected_termsCondition, setSelected_termsCondition] = useState({})
    const [selectedAction, setSelectedAction] = useState('')

    // const { isAdmin, menu_permission } = useMenuAuth({ menu: 'global_menus--employees', feature_key: 'teamManagement' })
    // const location = useLocation()

    const [selected_view_settings_value, setSelected_view_settings_value] = useState('')
    const [has_view_access, setHas_view_access] = useState(false)
    const [has_add_access, setHas_add_access] = useState(false)
    const [has_edit_access, setHas_edit_access] = useState(false)
    const [has_delete_access, setHas_delete_access] = useState(false)

    const [pricingType, setPricingType] = useState('yearly')

    const [mobileOpenedPlan, setMobileOpenedPlan] = useState('')

    const [Plans, setPlans] = useState([
        {
            id: "2cefa4f4-8859-4648-a72c-02db238a2921",
            title: "BOQ Estimation & Proposals",
            price: "1,999",
            monthly_price: "1,999",
            yearly_reduce_percentage: 34,
            yearly_price: "1,999",
            duration: pricingType,
            isGST: true,
            description: "Super fast BOQ Estimation & Customized Proposals Builder",
            included: [
                "Estimating",
                "Proposals",
                "BOQ Reports",
                "One-Time Schedule of rates",
                "One-Time Analysis of work rates",
                "Customized Proposal Builder",
                "Advanced Roles & Permissions",
                "Business Hours Support",
                "Unlimited Projects",
                "Includes 5 Users"
            ],
            button_label: "Get Started",
            link: "#"
        },
        {
            id: "cc6bbce2-fa60-4013-b72e-77c925c0b53d",
            title: "Pro",
            price: "9,999",
            monthly_price: "9,999",
            yearly_reduce_percentage: 34,
            yearly_price: "9,999",
            duration: pricingType,
            isGST: true,
            is_popular: true,
            description: "Track your entire project from one App",
            included: [
                "Smart Analytics",
                "BOQ Estimation",
                "Purchase Management",
                "Inventory Management",
                "Material Tracking",
                "Labour, Vendor Payables & Tracking",
                "Project Management",
                "Change Orders",
                "Client Payables",
                "Budgets",
                "Expense Tracking",
                "Reporting",
                "Support",
                "Includes 15 Users"
            ],
            button_label: "Get Started",
            link: "#"
        },
        {
            id: "advanced",
            title: "Advanced",
            price: "Custom",
            is_comingsoon: true,
            monthly_price: "Custom",
            yearly_price: "Custom",
            duration: pricingType,
            isGST: true,
            description: "Customized Project Management & Financials Tracking",
            included: [
                "All in Pro",
                "Auto-Scheduling",
                "Gantt, Dependency, Critical Path",
                "Custom reports",
                "Custom dashboards",
                "Custom requested features",
                "Business Hours Support",
                "Dedicated Key Account Manager",
                "Includes Custom Users"
            ],
            button_label: "Contact Us",
            link: "/contact"
        },
    ])

    const HandlePricingType = (type) => {

        setPricingType(type)

        let _Plans = Plans.map((plan, i) => {

            if (type == 'yearly') {
                plan.price = plan.monthly_price
                plan.duration = 'year'
            } else {
                plan.price = plan.monthly_price
                plan.duration = 'month'
            }
            return plan
        })

        setPlans(_Plans)


    }


    const HandleInitiatePayment = async (plan, payment) => {

        const { payment_order_id, payment_session_id, subscription_amount } = payment

        // Test - rzp_test_RTrQDGb1ib0o03, Live - rzp_live_ABzdtT4PdljU3z

        const options = {
            key: "rzp_live_ABzdtT4PdljU3z",
            amount: parseInt(subscription_amount) * 100,
            currency: 'INR',
            name: `Civilator - ${plan.title}`,
            description: plan.description,
            image: "https://civilator.in/static/media/logo1.14521ecb7795f31a7d8a.png",
            order_id: payment_order_id,
            handler: async (response) => {
                try {

                    const { razorpay_order_id, razorpay_payment_id, razorpay_signature } = response

                    const verify_response = await razorpay_PaymentgatewayHandler.verifyOrderHandler({
                        order_id: razorpay_order_id,
                        payment_id: razorpay_payment_id,
                        signature: razorpay_signature
                    })

                    if (verify_response.success) {

                        const { redirect } = verify_response

                        navigate(`${ReverseProxy['proxyUrl']}${redirect || '/builder/my-subscriptions'}`)
                    }

                } catch (error) {
                    console.log(error);
                }
            },
            theme: {
                color: "#ff7622",
            },
        };

        const razorpayCheckout = new window.Razorpay(options);
        razorpayCheckout.open();

    }

    const HandleSubscribePlan = async (plan) => {

        if (plan.is_comingsoon && plan.id == 'advanced') return navigate(`${ReverseProxy['proxyUrl']}/${plan.link}`)

        const get_session_id = await razorpay_PaymentgatewayHandler.createNewOrderHandler({
            plan_id: plan.id,
            plan_name: plan.title,
            duration: '1',
            duration_type: pricingType == 'yearly' ? '2' : '1'
        })


        if (get_session_id.success) {
            await HandleInitiatePayment(plan, get_session_id.data)

        }
    }

    useEffect(() => {
        // temp redirection
        // navigate(`${ReverseProxy['proxyUrl']}/quick-menu`)
    }, [])

    useEffect(() => {

        if (!isAdmin) {
            navigate(`${ReverseProxy['proxyUrl']}/quick-menu`)
            return () => { }
        }

    }, [store.user?.roles_permissions])

    return (
        <div className="project-pricing-main">
            <div className="pricing-content-main">
                <div className="pricing-content" >
                    <div className="heading">
                        <div className="title">Pricing</div>
                        <div className="desc">
                            Choose the plan that best works for you. Then, you can upgrade
                            your plan anytime, no questions asked.
                        </div>
                    </div>

                    <div className="pricing-plans">
                        <div className="plans-content">
                            {Plans.map((plan, i) => (
                                <div
                                    key={plan.id}
                                    id={plan.id}
                                    className={`plan-item ${plan.is_popular ? 'plan-popular' : ''}`}
                                >
                                    {plan.is_popular ? <div className="plan-popular-label">MOST POPULAR</div> : ''}
                                    <div className="plan-item-content">

                                        <div className="plan-header">
                                            <div className="plan-title">{plan.title}</div>
                                            <div className="plan-price">
                                                <span className='price'>
                                                    <span className='price-currency'>₹</span>
                                                    <div className="price-value">{plan.price}</div>
                                                </span>
                                                <span className='gst'>{plan.duration ? `/month. Billed ${pricingType}` : ""}{plan.isGST ? " + GST" : ""}</span>
                                            </div>
                                            <div className="plan-explain">{plan.description}</div>
                                        </div>
                                        <div
                                            className="plan-mobileview-showdetials-btn"
                                            onClick={(e) => setMobileOpenedPlan(mobileOpenedPlan == plan.id ? '' : plan.id)}
                                        >
                                            <div
                                                className="icon"
                                                dangerouslySetInnerHTML={{ __html: Icons.general.dropdown_arrow }}
                                            ></div>
                                        </div>
                                        <div className={`plan-detials ${mobileOpenedPlan == plan.id ? '' : 'plan-mobileview-disabled'}`}>
                                            <div className="plan-detials-title">What's included</div>
                                            <div className="plan-detials-items">
                                                {plan.included.map((item, i) => (
                                                    <div className="plain-detials-item">
                                                        <div
                                                            className="icon"
                                                            dangerouslySetInnerHTML={{ __html: Icons.general.tick }}
                                                        >
                                                        </div>
                                                        <div className="label">{item}</div>
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        className="plan-button"
                                        onClick={(e) => HandleSubscribePlan(plan)}

                                    >
                                        <div className="label">{plan.button_label}</div>

                                    </div>
                                </div>
                            ))}


                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SubscriptionPlans;