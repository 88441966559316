

import React, { useEffect, useState, useRef } from 'react'
import { Route, Routes, BrowserRouter, Link, useLocation, Navigate, Outlet, useNavigate, useParams } from 'react-router-dom';
import store from '../../redux/store'
import StoreHandler from '../../redux/StoreHandler'
import { useSelector, useDispatch } from 'react-redux'
import UserAction from '../../redux/action/userAction'

import ReverseProxy from '../../config/reverseProxy'
import WorkRatesHandler from '../../Handlers/WorkRates/WorkRates';
import ScheduleRatesHandler from '../../Handlers/ScheduleRates/ScheduleRates';
import Icons from '../../assets/Icons'
import Images from '../../assets/Images'
import { CostInput, TextInput, SelectInput, QuantityInput, RadioInput } from '../../components/Inputs'
import Utils from '../../utils'
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

import AlertPopup from '../../components/AlertPopup'
import Loading from '../../components/Loading'
import SystemToastPopup from '../../components/SystemToastPopup'


import { useAuth, useMenuAuth } from '../../hooks/useAuth'

const AddWorkSideItem = ({ props }) => {

    let { type, header_option, close, existItemData, updateCallback } = props

    const { itemType, itemId, actionType } = useParams()

    const [isLoading, setIsLoading] = useState(false)
    const [warningAlert, setWarningAlert] = useState(false)
    const [warningAlertType, setWarningAlertType] = useState('warning')
    const [apiFailedMessage, setApiFailedMessage] = useState("Error in Server")

    const [currency_type, setCurrency_type] = useState('₹')

    const scheduleRatesHandler = new ScheduleRatesHandler()

    const navigate = useNavigate()
    const store = useSelector((store) => store)
    const dispatch = useDispatch()
    const { updateState } = new UserAction

    const storeHandler = new StoreHandler({
        dataStack: ['company', 'field_settings', 'finance_settings'],
        store: store,
        dispatch: dispatch,
        updateState: updateState,
    })

    const LoadStoreData = async () => {
        setIsLoading(true)
        let resolved = await storeHandler.init()
        setIsLoading(false)
    }

    const AddBtnOptions = [
        {
            icon: Icons.general.group,
            label: 'Category',
            type: 'group',
            id: "group"
        },

        {
            icon: Icons.general.labour,
            label: 'Labour',
            type: 'item',
            id: "labour"
        },
        {
            icon: Icons.general.material,
            label: 'Materials',
            type: 'item',
            id: "material"
        },
        {
            icon: Icons.general.machines,
            label: 'Machines',
            type: 'item',
            id: "machine"
        },
        {
            icon: Icons.general.subcontractor,
            label: 'Subcontractor',
            type: 'item',
            id: "subcontractor"
        },
        {
            icon: Icons.general.fee,
            label: 'Fee',
            type: 'item',
            id: "fee"
        },
        {
            icon: Icons.general.allowance,
            label: 'Allowances',
            type: 'item',
            id: "allowance"
        },
    ]

    const [AddBtnOption, setAddBtnOption] = useState(AddBtnOptions.filter(opt => opt.id == header_option)[0] || undefined)

    const isExistItemData = existItemData && existItemData.id != undefined

    const [name, setName] = useState('')


    const [group_id, setGroup_id] = useState('')
    const [group, setGroup] = useState('')

    const [SOR_items_Orginal, setSOR_items_Orginal] = useState([])

    const [SOR_item_id, setSOR_item_id] = useState('')
    const [SOR_item, setSOR_item] = useState('')

    const [id, setId] = useState(isExistItemData ? existItemData.id : '')
    const [unitQuantity, setUnitQuantity] = useState('')
    const [workQuantity, setWorkQuantity] = useState('')
    const [unit_id, setUnit_id] = useState('')
    const [unit_name, setUnit_name] = useState('')
    const [unitrate, setUnitrate] = useState('')
    const [description, setDescription] = useState('')
    const [additionalNotes, setAdditionalNotes] = useState(false);

    const [tax_id, setTax_id] = useState('')
    const [tax_name, setTax_name] = useState('')
    const [tax_value, setTax_value] = useState(0)

    const [markup_id, setMarkup_id] = useState('')
    const [markup_name, setMarkup_name] = useState('')
    const [markup_value, setMarkup_value] = useState(0)
    const [markup_type, setMarkup_type] = useState('%')

    const [perUnit_id, setPerUnit_id] = useState('')
    const [perUnit_name, setPerUnit_name] = useState('')

    const [workUnit_id, setWorkUnit_id] = useState('')
    const [workUnit_name, setWorkUnit_name] = useState('')

    const [costcodeOptions, setCostcodeOptions] = useState([])
    const [SOR_items_Options, setSOR_items_Options] = useState([])
    const [unitsOptions, setUnitsOptions] = useState([])
    const [taxesOptions, setTaxesOptions] = useState([])
    const [markupsOptions, setMarkupsOptions] = useState([])


    const co_efficient = workQuantity > 0 && unitQuantity > 0 ? parseFloat(unitQuantity || 0) / parseFloat(workQuantity || 0) : 0
    const per_co_efficient_cost = co_efficient * unitrate || 0

    const unti_tax = (parseFloat((unitrate * co_efficient) || 0) * parseFloat(tax_value)) / 100
    const unti_markup = markup_type == '%' ? (parseFloat((unitrate * co_efficient) || 0) * parseFloat(markup_value)) / 100 : parseFloat(markup_value)

    const price = (unitrate * co_efficient) + unti_tax + unti_markup


    useEffect(() => {

        if (store && store.user) {

            let { company, clients, settings } = store.user


            if (company) {

                let { currency_setting, timezone, temperature_setting } = company

                if (currency_setting) setCurrency_type(currency_setting)
            }


            if (settings && Array.isArray(settings.field_settings) && settings.field_settings.length) {

                let { units } = settings.field_settings[0]

                units = units.filter(opt => opt.isActive).map((opt) => {
                    return {
                        value: opt.id,
                        label: opt.name,
                        isDefault: opt.isDefault || false
                    }
                })

                setUnitsOptions(units || [])

            }

            if (settings && Array.isArray(settings.finance_settings) && settings.finance_settings.length) {

                let { taxes, markups, overheads, insurance } = settings.finance_settings[0]

                taxes = taxes.filter(opt => opt.isActive).map((opt) => {
                    return {
                        id: opt.id,
                        value: parseFloat(opt.value || 0),
                        label: opt.name,
                        type: opt.type,
                        isDefault: opt.isDefault || false
                    }
                })

                setTaxesOptions(taxes || [])


                markups = markups.filter(opt => opt.isActive).map((opt) => {
                    return {
                        id: opt.id,
                        value: parseFloat(opt.value || 0),
                        label: opt.name,
                        type: opt.value_type,
                        isDefault: opt.isDefault || false
                    }
                })

                setMarkupsOptions(markups || [])
            }


        }

    }, [store])

    const getEstimateItemTypeEenemReverse = (type) => {

        if (type == 'material') return 1
        else if (type == 'labour') return 2
        else if (type == 'machine') return 3
        else if (type == 'subcontractor') return 4
        else if (type == 'fee') return 5
        else if (type == 'allowance') return 6
        else return 0

    }

    const HandlePopupSubmit = async (e) => {
        e.preventDefault()


        let updated_data = {
            id: isExistItemData ? existItemData.id : Utils.getUniqueId(),
            name: name,
            unit_id, unit_id,
            unit_name, unit_name,
            unitrate: String(unitrate),


            std_per_unit_id: perUnit_id,
            std_per_unit_name: perUnit_name,

            std_work_unit_id: workUnit_id,
            std_work_unit_name: workUnit_name,

            std_per_quandity: String(unitQuantity),
            std_work_quantity: String(workQuantity),

            parent_id: group_id || undefined,
            parent_name: group || undefined,
            parent_quantity: 0,

            co_efficient: co_efficient,
            per_co_efficient_cost: (co_efficient * unitrate) || 0,

            sor_id: SOR_item_id || "",
            sor_name: SOR_item || "",

            tax_id: tax_id,
            tax_name: tax_name,
            tax_value: String(tax_value),
            tax_amount: String(unti_tax),


            markup_id: markup_id,
            markup_name: markup_name,
            markup_value: String(markup_value),
            markup_value_type: markup_type,
            markup_amount: String(unti_markup),

            expanded: false,
            builder_price: String(unitrate * co_efficient),
            price: String(price),
            type: 3,
            item_type: getEstimateItemTypeEenemReverse(AddBtnOption.id),
            type_option: AddBtnOption,
            description: description,
        }


        if (typeof updateCallback == 'function' && updated_data.id) updateCallback(updated_data, type)

        close()


    }

    const Popup_Header = ({ props }) => {

        const { icon, label } = props
        return (
            <div className="side-popup-header">
                <div className="header-item-select">
                    <div className="header-item-select-content">
                        <span className="icon" dangerouslySetInnerHTML={{ __html: icon }}></span>
                        <div className="label">{type == 'edit' ? 'Update' : 'Add'} {label || "Item"}</div>

                    </div>
                </div>
                <div
                    className="header-item-close"
                    onClick={(e) => close()}
                    dangerouslySetInnerHTML={{ __html: Icons.general.close }}
                ></div>
            </div>
        );
    };
    const Popup_Footer = ({ props }) => {

        const { icon, label } = props

        return (
            <div className="sidebar-popup-footer">
                <div className="footer-item action-items">
                    <div className="action-preview">
                        <div className="label">{label} Price</div>
                        <div className="value">{currency_type}{price || '0.00'}</div>
                    </div>
                    <button
                        className="action-btn"
                        type='submit'
                    >
                        <div className="icon" dangerouslySetInnerHTML={{ __html: type == 'edit' ? Icons.general.save : Icons.general.add_btn }}></div>
                        <div className="label">{type == 'edit' ? 'Save' : 'Add Item'}</div>
                    </button>
                </div>
            </div>
        );
    };


    const HandleScheduleInputChange = (id, label) => {

        if (!id) return

        setSOR_item_id(id)
        setSOR_item(label)

        let get_sor_item = SOR_items_Orginal.filter(s => s.id == id)[0]

        if (!get_sor_item) return

        setName(get_sor_item.name || "")
        setDescription(get_sor_item.description || "")

        setUnit_id(get_sor_item.unit_id || "")
        setUnit_name(get_sor_item.unit_name || "")


        setMarkup_id(get_sor_item.markup_id || "")
        setMarkup_name(get_sor_item.markup_name || "")
        setMarkup_value(parseFloat(get_sor_item.markup_value || 0))
        setMarkup_type(get_sor_item.markup_value_type || "")

        setTax_id(get_sor_item.tax_id || "")
        setTax_name(get_sor_item.tax_name || "")
        setTax_value(parseFloat(get_sor_item.tax_value || 0))

        setUnitrate(parseFloat(get_sor_item.unitrate || 0) || "")
    }

    const LoadScheduleRatesItems = async () => {

        setIsLoading(true)

        let response = await scheduleRatesHandler.getItemsHandler()
        setIsLoading(false)

        if (response.success) {

            let { data } = response

            if (!Array.isArray(data)) return

            let sor_item_types = {
                material: "1",
                labour: "2",
                machine: "3",
                subcontractor: "4",
                fee: "5",
                allowance: "6",
            }

            let sor_options = data.filter(d => d.type == '3' && sor_item_types[header_option] == d.item_type).map(d => {
                return {
                    value: d.id,
                    label: d.name,
                    type: d.item_type,
                    type: d.item_type,
                    isDefault: false
                }
            })

            setSOR_items_Orginal(data)
            setSOR_items_Options(sor_options)


        }
    }

    useEffect(() => {




        LoadStoreData()
        LoadScheduleRatesItems()

        if (type == 'edit' && isExistItemData) {

            console.log(existItemData, 'existItemData');

            setId(existItemData.id)

            setName(existItemData.name || '')

            setGroup_id(existItemData.parent_id || '')
            setGroup(existItemData.parent_name || '')


            setSOR_item_id(existItemData.sor_id || '')
            setSOR_item(existItemData.sor_name || '')

            setUnit_id(existItemData.unit_id || '')
            setUnit_name(existItemData.unit_name || '')

            setUnitQuantity(parseFloat(existItemData.std_per_quandity || 0) || '')
            setWorkQuantity(parseFloat(existItemData.std_work_quantity || 0) || '')

            setPerUnit_id(existItemData.std_per_unit_id || '')
            setPerUnit_name(existItemData.std_per_unit_name || '')

            setWorkUnit_id(existItemData.std_work_unit_id || '')
            setWorkUnit_name(existItemData.std_work_unit_name || '')

            setUnitrate(parseFloat(existItemData.unitrate || 0) || '')
            setDescription(existItemData.description || '')

            setAdditionalNotes(existItemData.description ? true : false)

            setTax_id(existItemData.tax_id || '')
            setTax_name(existItemData.tax_name || '')
            setTax_value(parseFloat(existItemData.tax_value || 0) || 0)

            setMarkup_id(existItemData.markup_id || '')
            setMarkup_name(existItemData.markup_name || '')
            setMarkup_value(parseFloat(existItemData.markup_value || 0) || 0)
            setMarkup_type(existItemData.markup_value_type)

        }

    }, [])

    return (

        <div className="popup-container-main propup-container-center">
            <div className="popup-block-ui"></div>
            <form className="side-popup-container animateXRight animateXRightactive" onSubmit={HandlePopupSubmit}>
                <Popup_Header props={AddBtnOption} />

                <div className="sidebar-popup-content">
                    <div className="content-section">

                        <div className="content-item">
                            <SelectInput
                                props={{
                                    id: "costcatalog-work-scheduleitem",
                                    value: SOR_item_id,
                                    placeholder: '',
                                    readOnly: false,
                                    options: SOR_items_Options || [],
                                    setValue: (value, label) => HandleScheduleInputChange(value, label),
                                    isIcon: false,
                                    isLabel: true,
                                    isRequired: true,
                                    label: "Schedule Item",
                                }}
                            />
                        </div>


                        <div className="content-item">
                            <TextInput
                                props={{
                                    id: "costcatalog-work-name",
                                    value: name,
                                    placeholder: '',
                                    setValue: (value) => {
                                        setName(value)
                                    },
                                    isIcon: false,
                                    isLabel: true,
                                    isRequired: true,
                                    label: "Name",
                                }}
                            />
                        </div>

                        <div className="content-section-header">
                            <div className="label" style={{ padding: '0 1rem' }}>Standard data for Co-effi. Calculation</div>
                        </div>

                        <div className="content-item">

                            <div className="content-sub-item">
                                <TextInput
                                    props={{
                                        id: "costcatalog-work-unitQuantity",
                                        value: unitQuantity,
                                        placeholder: '',
                                        setValue: (value) => setUnitQuantity(value),
                                        isIcon: false,
                                        type: 'number',
                                        isLabel: true,
                                        isRequired: true,
                                        label: "STD: Unit Qty",
                                    }}
                                />
                            </div>

                            <div className="content-sub-item">
                                <SelectInput
                                    props={{
                                        id: "costcatalog-work-perunit",
                                        value: perUnit_id,
                                        placeholder: '',
                                        readOnly: false,
                                        options: unitsOptions || [],
                                        setValue: (value, label) => {
                                            setPerUnit_id(value)
                                            setPerUnit_name(label)
                                        },
                                        isIcon: false,
                                        isLabel: true,
                                        isRequired: true,
                                        label: "STD: Unit",
                                    }}
                                />
                            </div>

                        </div>
                        <div className="content-item">



                            <div className="content-sub-item">
                                <TextInput
                                    props={{
                                        id: "costcatalog-work-workQuantity",
                                        value: workQuantity,
                                        placeholder: '',
                                        setValue: (value) => setWorkQuantity(value),
                                        isIcon: false,
                                        type: 'number',
                                        isLabel: true,
                                        isRequired: true,
                                        label: "STD: Total Qty",
                                    }}
                                />
                            </div>

                            <div className="content-sub-item">
                                <SelectInput
                                    props={{
                                        id: "costcatalog-work-workunit",
                                        value: workUnit_id,
                                        placeholder: '',
                                        readOnly: false,
                                        options: unitsOptions || [],
                                        setValue: (value, label) => {
                                            setWorkUnit_id(value)
                                            setWorkUnit_name(label)
                                        },
                                        isIcon: false,
                                        isLabel: true,
                                        isRequired: true,
                                        label: "STD: Unit",
                                    }}
                                />
                            </div>
                        </div>


                        <div className="content-item">
                            <TextInput
                                props={{
                                    id: "costcatalog-work-co_efficient",
                                    value: co_efficient,
                                    placeholder: '',
                                    setValue: (value) => { },
                                    isIcon: false,
                                    type: 'number',
                                    isLabel: true,
                                    readOnly: true,
                                    isRequired: true,
                                    label: "Co-efficient",
                                }}
                            />
                        </div>
                        <div className="content-item">

                            <div className="content-sub-item">
                                <SelectInput
                                    props={{
                                        id: "costcatalog-work-unit",
                                        value: unit_id,
                                        placeholder: '',
                                        readOnly: false,
                                        options: unitsOptions || [],
                                        setValue: (value, label) => {
                                            setUnit_id(value)
                                            setUnit_name(label)
                                        },
                                        isIcon: false,
                                        isLabel: true,
                                        isRequired: true,
                                        label: "Unit type",
                                    }}
                                />
                            </div>

                            <div className="content-sub-item">
                                <CostInput
                                    props={{
                                        id: "costcatalog-work-unitrate",
                                        value: unitrate,
                                        isCostMethod: false,
                                        currency_type: currency_type,
                                        readOnly: false,
                                        placeholder: '',
                                        setValue: (value) => {
                                            setUnitrate(value)
                                        },
                                        isIcon: true,
                                        icon: currency_type,
                                        isLabel: true,
                                        isRequired: true,
                                        label: "Unit rate",
                                    }}
                                />
                            </div>

                        </div>

                        <div className="content-item">
                            <CostInput
                                props={{
                                    id: "costcatalog-work-price",
                                    value: per_co_efficient_cost,
                                    currency_type: currency_type,
                                    isCostMethod: false,
                                    placeholder: '',
                                    readOnly: true,
                                    setValue: (value) => {
                                        // setPrice(value)
                                    },
                                    isIcon: true,
                                    icon: currency_type,
                                    isLabel: true,
                                    isRequired: false,
                                    label: "Per co-efficient cost",
                                }}
                            />
                        </div>
                    </div>
                    <div className="content-section">
                        <div className="content-section-header">
                            <div className="label">Pricing</div>
                        </div>

                        <div className="content-section-header">
                            <div className="label">Tax</div>
                            <div className="input">
                                <select
                                    name=""
                                    id=""
                                    onInput={(e) => {

                                        let tax = taxesOptions?.filter(t => t.id == e.target.value)[0]

                                        if (tax) {

                                            setTax_id(tax.id)
                                            setTax_value(parseFloat(tax.value || 0))
                                            setTax_name(tax.label)
                                        }
                                    }}
                                    value={tax_id}
                                >
                                    {taxesOptions?.map((tax, i) => (
                                        <option value={`${tax.id}`}>{tax.label}</option>
                                    ))}
                                </select>
                            </div>
                        </div>
                        <div className="content-item">
                            <div className="content-sub-item">
                                <CostInput
                                    props={{
                                        value: tax_value,
                                        isCostMethod: false,
                                        currency_type: currency_type,
                                        placeholder: 'Unit cost',
                                        setValue: (value) => {
                                            setTax_value(parseFloat(value) || 0)
                                        },
                                        isIcon: true,
                                        icon: '%',
                                        isLabel: true,
                                        isRequired: true,
                                        label: "Tax",
                                        id: "costcatalog-item-tax"
                                    }}
                                />
                            </div>
                            <div className="content-sub-item content-sub-label-item">
                                <div className="content-sub-label-content">
                                    <div className="label">Unit tax</div>
                                    <div className="value">{currency_type}{unti_tax}</div>
                                </div>
                            </div>

                        </div>

                        <div className="content-section-header">
                            <div className="label">Markup</div>
                            <div className="input">
                                <select
                                    name=""
                                    id=""
                                    onInput={(e) => {

                                        let item = markupsOptions.filter(itm => itm.id == e.target.value)[0]
                                        if (item) {
                                            setMarkup_value(parseFloat(item.value || 0))
                                            setMarkup_id(item.id)
                                            setMarkup_name(item.label)
                                            setMarkup_type(item.type)
                                        }
                                    }}
                                    value={markup_id}
                                >
                                    {markupsOptions.map((itm, i) => (
                                        <option value={`${itm.id}`}>{itm.label}</option>
                                    ))}
                                </select>
                            </div>
                        </div>
                        <div className="content-item">
                            <div className="content-sub-item">
                                <CostInput
                                    props={{
                                        value: markup_value,
                                        costMethodValue: markup_type,
                                        isCostMethod: true,
                                        currency_type: currency_type,
                                        placeholder: 'Unit cost',
                                        setValue: (value, type) => {
                                            setMarkup_value(parseFloat(value) || 0)
                                            if (type) setMarkup_type(type)
                                        },
                                        isIcon: true,
                                        icon: '%',
                                        isLabel: true,
                                        isRequired: true,
                                        label: "Markup",
                                        id: "costcatalog-item-markup"
                                    }}
                                />
                            </div>
                            <div className="content-sub-item content-sub-label-item">
                                <div className="content-sub-label-content">
                                    <div className="label">Unit markup</div>
                                    <div className="value">{currency_type}{unti_markup}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="content-section">
                        <div className="content-item">
                            <div id="costcatalog-description" className={`additional-item ${additionalNotes ? 'additional-item-active' : ''}`}>
                                <div className="head" onClick={(e) => setAdditionalNotes(!additionalNotes)}>
                                    <div className="icon" dangerouslySetInnerHTML={{ __html: Icons.general.description }}></div>
                                    <div className="label">Description</div>
                                </div>
                                <div className="item-expanded">
                                    <ReactQuill
                                        theme="snow"
                                        value={description}
                                        onChange={(value) => setDescription(value)}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <Popup_Footer props={AddBtnOption} />
            </form>
        </div>
    )

}


const AddWork = ({ props }) => {

    const { existItemData, updateCallback } = props

    const { itemType, itemId, actionType } = useParams()

    const [isLoading, setIsLoading] = useState(false)
    const [warningAlert, setWarningAlert] = useState(false)
    const [warningAlertType, setWarningAlertType] = useState('warning')
    const [apiFailedMessage, setApiFailedMessage] = useState("Error in Server")

    const workRatesHandler = new WorkRatesHandler()

    const [currency_type, setCurrency_type] = useState('₹')

    const navigate = useNavigate()
    const store = useSelector((store) => store)
    const dispatch = useDispatch()
    const { updateState } = new UserAction

    const storeHandler = new StoreHandler({
        dataStack: ['costcodes', 'company', 'field_settings'],
        store: store,
        dispatch: dispatch,
        updateState: updateState,
    })

    const LoadStoreData = async () => {
        setIsLoading(true)
        let resolved = await storeHandler.init()
        setIsLoading(false)
    }

    const AddBtnOptions = [
        {
            icon: Icons.general.group,
            label: 'Category',
            type: 'group',
            id: "group"
        },

        {
            icon: Icons.general.labour,
            label: 'Labour',
            type: 'item',
            id: "labour"
        },
        {
            icon: Icons.general.material,
            label: 'Materials',
            type: 'item',
            id: "material"
        },
        {
            icon: Icons.general.machines,
            label: 'Machines',
            type: 'item',
            id: "machine"
        },
    ]


    const [costcode_id, setCostcode_id] = useState('')
    const [costcode_name, setCostcode_name] = useState('')

    const [id, setId] = useState('')

    const [builderPrice, setBuilderPrice] = useState(0)
    const [price, setPrice] = useState(0)
    const [totalTaxes, setTotalTaxes] = useState(0)
    const [totalMarkups, setTotalMarkups] = useState(0)
    const [name, setName] = useState('')
    const [unit_id, setUnit_id] = useState('')
    const [unit_name, setUnit_name] = useState('')
    const [description, setDescription] = useState('')

    const [sideItemShowPopup, setSideItemShowPopup] = useState(false)
    const [sideItemType, setSideItemType] = useState('add')
    const [sideItemHeader, setSideItemHeader] = useState({})
    const [sideItemExistDetials, setSideItemExistDetials] = useState({})
    const [Cost_Lines, setCost_Lines] = useState([]);
    const [additionalNotes, setAdditionalNotes] = useState(false);

    const isExistItemData = existItemData && existItemData.id != undefined


    const [costcodeOptions, setCostcodeOptions] = useState([])
    const [unitsOptions, setUnitsOptions] = useState([])

    const getEstimateItemTypeEenem = (type) => {

        if (type == '1') return 'group'
        else if (type == '2') return 'work_template'
        else if (type == '3') return 'item'
        else if (type == '4') return 'work'
        else return ''

    }
    const getEstimateItemCategoryEenem = (type) => {

        if (type == '1') return 'material'
        else if (type == '2') return 'labour'
        else if (type == '3') return 'machine'
        else if (type == '4') return 'subcontractor'
        else if (type == '5') return 'fee'
        else if (type == '6') return 'allowance'
        else return ''

    }
    const getEstimateItemStatusEenem = (type) => {

        if (type == '0') return 'incomplete'
        else if (type == '1') return 'completed'
        else if (type == '2') return 'hold'
        else if (type == '3') return 'stopped'
        else return ''
    }

    const getFormatedEstimateItem = (item) => {

        return {
            sno: item.sno,
            expanded: true,
            selected: false,
            disable: false,

            parent_id: item.parent_id,
            parent_name: item.parent_name,
            id: item.id,
            name: item.name,
            description: item.description,

            costcode_id: item.costcode_id || "",
            costcode_name: item.costcode_name || "",

            std_per_quandity: parseFloat(item.std_per_quandity || 0),
            std_work_quantity: parseFloat(item.std_work_quantity || 0),

            std_per_unit_id: item.std_per_unit_id,
            std_per_unit_name: item.std_per_unit_name,

            std_work_unit_id: item.std_work_unit_id,
            std_work_unit_name: item.std_work_unit_name,

            co_efficient: parseFloat(item.co_efficient || 0) || 0,
            per_co_efficient_cost: parseFloat(item.per_co_efficient_cost || 0) || 0,

            type: getEstimateItemTypeEenem(item.type),
            item_type: item.type != '3' ? getEstimateItemTypeEenem(item.type) : getEstimateItemCategoryEenem(item.item_type),
            cost_type: item.type != '1' && item.type != '3' ? getEstimateItemTypeEenem(item.type) : getEstimateItemCategoryEenem(item.item_type),
            _status: item.status,
            status: getEstimateItemStatusEenem(item.status),

            parent_type: parseInt(item.parent_type),
            parent_quantity: parseFloat(item.parent_quantity || 0),

            quantity: parseFloat(item.quantity || 0) || 0,
            unitrate: parseFloat(item.unitrate || 0) || 0,
            builder_price: parseFloat(item.builder_price || 0) || 0,
            price: parseFloat(item.price || 0) || 0,


            unit_id: item.unit_id || "",
            unit_name: item.unit_name || "",

            markup_id: item.markup_id || "",
            markup_name: item.markup_name || "",
            markup_value: parseFloat(item.markup_value || 0) || 0,
            markup_value_type: item.markup_value_type || "",
            markup_amount: parseFloat(item.markup_amount || 0) || 0,

            tax_id: item.tax_id || "",
            tax_name: item.tax_name || "",
            tax_value_type: item.tax_value_type || "",
            tax_value: parseFloat(item.tax_value || 0) || 0,
            tax_amount: parseFloat(item.tax_amount || 0) || 0,

            sub_items: []
        }

    }

    const LoadEstimateItem = async (sno) => {

        if (sno == undefined) return

        let filter = {
            idx: sno
        }

        setIsLoading(true)

        let response = await workRatesHandler.getWorkRatesItemsHandler(filter)
        setIsLoading(false)

        if (response.success) {


            let { data } = response


            if (!Array.isArray(data) || !data.length) return


            let items = data.map(d => {

                let item = getFormatedEstimateItem(d)

                let tax_amount = item.tax_amount
                let markup_amount = item.markup_amount
                let builder_price = item.builder_price
                let price = item.price

                item._tax_amount = tax_amount || ""
                item._tax_percent = ((tax_amount / builder_price) * 100 || 0).toFixed(2)
                item._markup_amount = markup_amount || ""
                item._markup_percent = ((markup_amount / builder_price) * 100 || 0).toFixed(2)
                item._builder_price = builder_price || ""
                item._price = price || ""
                item._unitrate = item.unitrate || ""
                item._quantity = item.quantity || ""

                item.tax_amount = tax_amount ? `${currency_type}${tax_amount}` : ""
                item.markup_amount = markup_amount ? `${currency_type}${markup_amount}` : ""
                item.builder_price = builder_price ? `${currency_type}${builder_price}` : ""
                item.price = price ? `${currency_type}${price}` : ""
                item.unitrate = item.unitrate ? `${currency_type}${item.unitrate}` : ""
                item.quantity = item.quantity ? `${item.quantity}${item.unit_name || ""}` : ""

                return item

            })

            let item = items[0]

            setId(item.id)

            setName(item.name || '')

            setCostcode_id(item.costcode_id || '')
            setCostcode_name(item.costcode_name || '')

            setUnit_id(item.unit_id || '')
            setUnit_name(item.unit_name || '')

            setDescription(item.description || '')

            setAdditionalNotes(item.description ? true : false)


            let cost_line_filter = {
                parent_id: item.id
            }

            let cost_lines_response = await workRatesHandler.getWorkRatesItemsHandler(cost_line_filter)


            if (cost_lines_response.success && Array.isArray(cost_lines_response.data) && cost_lines_response.data) {

                let cost_lines = cost_lines_response.data.map(item => {

                    item = getFormatedEstimateItem(item)

                    let type_option = AddBtnOptions.filter(opt => {

                        if (opt.type != 'item' && item.type == opt.type) return true
                        else if (opt.type == 'item' && item.item_type == opt.id) return true
                        else return false
                    })

                    type_option = type_option.length ? type_option[0] : undefined
                    item.type_option = type_option

                    return item

                })

                console.log(cost_lines, 'cost_lines');
                setCost_Lines(cost_lines)
            }

        }
        else {
            setWarningAlert(true)
            setWarningAlertType('error')
            setApiFailedMessage(`Error in Work Rates Item`)
        }
    }

    useEffect(() => {


        if (actionType == 'edit' && isExistItemData) {

            setName(existItemData.name || '')
            setAdditionalNotes(existItemData.description ? true : false)
            setDescription(existItemData.description || '')
            setCostcode_id(existItemData.costcode_id || '')
            setCostcode_name(existItemData.costcode_name || '')
            setUnit_id(existItemData.unit_id || '')
            setUnit_name(existItemData.unit_name || '')
            setCost_Lines(existItemData.cost_lines || [])
            calculateTotalSummary()
        }



        if (actionType == 'edit' && !isExistItemData) LoadEstimateItem(itemId)

        LoadStoreData()

    }, [])

    useEffect(() => {

        if (store && store.user) {

            let { company, costcodes, clients, settings } = store.user



            if (company) {

                let { currency_setting, timezone, temperature_setting } = company

                if (currency_setting) setCurrency_type(currency_setting)
            }
            if (Array.isArray(costcodes)) {

                costcodes = costcodes.filter(opt => opt.type == '1').map((opt) => {
                    return {
                        value: opt.id,
                        label: opt.name,
                    }
                })

                setCostcodeOptions(costcodes)
            }


            if (settings && Array.isArray(settings.field_settings) && settings.field_settings.length) {

                let { units } = settings.field_settings[0]

                units = units.filter(opt => opt.isActive).map((opt) => {
                    return {
                        value: opt.id,
                        label: opt.name,
                        isDefault: opt.isDefault || false
                    }
                })

                setUnitsOptions(units || [])

            }


        }

    }, [store])

    const getEstimateItemTypeEenemReverse = (type) => {

        if (type == 'material') return 1
        else if (type == 'labour') return 2
        else if (type == 'machine') return 3
        else if (type == 'subcontractor') return 4
        else if (type == 'fee') return 5
        else if (type == 'allowance') return 6
        else return 0

    }
    const getEstimateItemStatusEenemReverse = (type) => {

        if (type == 'incomplete') return 0
        else if (type == 'completed') return 1
        else if (type == 'hold') return 2
        else if (type == 'stopped') return 3
        else return -1

    }
    const FormatCost_Lines = (Cost_Lines) => {

        if (!Array.isArray(Cost_Lines)) return []

        return Cost_Lines.map(item => {
            return {

                id: item.id,
                name: item.name,
                status: getEstimateItemStatusEenemReverse(item.status),

                unit_id: item.unit_id,
                unit_name: item.unit_name,
                unitrate: String(item.unitrate),

                sor_id: item.sor_id,
                sor_name: item.sor_name,

                std_per_quandity: String(item.std_per_quandity),
                std_work_quantity: String(item.std_work_quantity),

                std_per_unit_id: item.std_per_unit_id,
                std_per_unit_name: item.std_per_unit_name,

                std_work_unit_id: item.std_work_unit_id,
                std_work_unit_name: item.std_work_unit_name,

                co_efficient: String(item.co_efficient),
                per_co_efficient_cost: String(item.per_co_efficient_cost),

                parent_id: item.group_id || undefined,
                parent_name: item.group || undefined,
                parent_quantity: item.parent_quantity || 0,

                tax_id: item.tax_id,
                tax_name: item.tax_name,
                tax_value: String(item.tax_value),
                tax_amount: String(item.tax_amount),


                markup_id: item.markup_id,
                markup_name: item.markup_name,
                markup_value: String(item.markup_value),
                markup_value_type: String(item.markup_value_type),
                markup_amount: String(item.markup_amount),

                builder_price: String(item.builder_price),
                price: String(item.price),
                type: 3,
                item_type: getEstimateItemTypeEenemReverse(item.type_option.id),
                description: item.description,
            }
        })
    }

    const HandlePopupSubmit = async (e) => {
        e.preventDefault()

        let updated_data = {

            name,
            costcode_id: costcode_id,
            costcode_name: costcode_name,
            unit_id: unit_id,
            unit_name: unit_name,
            type: 4,
            expanded: false,
            description,

            cost_lines: FormatCost_Lines(Cost_Lines)

        }

        setIsLoading(true)

        let response = { success: false }

        if (actionType == 'edit' && isExistItemData && existItemData.id) {
            updated_data['id'] = existItemData.id
            response = await workRatesHandler.updateWorkRatesGroupHandler(updated_data)
        }
        else if (actionType == 'add') response = await workRatesHandler.createWorkRatesGroupHandler(updated_data)

        setIsLoading(false)

        if (response.success) {

            if (typeof updateCallback == 'function') updateCallback()
            navigate(`/builder/work-rates`)
        }
        else {
            setWarningAlert(true)
            setWarningAlertType('error')
            setApiFailedMessage(`Error in  Work Rates Group`)
        }


    }


    const Popup_Header = ({ props }) => {

        return (
            <div className="side-popup-header">
                <div className="header-item-select">
                    <div className="header-item-select-content">
                        <span className="icon" dangerouslySetInnerHTML={{ __html: Icons.general.work }}></span>
                        <div className="label">{actionType == 'edit' ? 'Update' : 'Add'} Work</div>
                    </div>
                </div>
                <div
                    className="header-item-close"
                    onClick={(e) => {
                        navigate(`/builder/work-rates`)
                    }}
                    dangerouslySetInnerHTML={{ __html: Icons.general.close }}
                ></div>
            </div>
        );
    };
    const Popup_Footer = ({ props }) => {

        return (
            <div className="sidebar-popup-footer">
                <div className="footer-item action-items">
                    <div className="footer-item-left">

                        <div className="action-preview">
                            <div className="label">Prime cost</div>
                            <div className="value">{currency_type}{parseFloat(builderPrice || 0).toFixed(2)}</div>
                        </div>
                        <div className="action-preview">
                            <div className="label">Taxes | Markups</div>
                            <div className="value">{currency_type}{parseFloat(totalTaxes || 0).toFixed(2)} | {currency_type}{parseFloat(totalMarkups).toFixed(2) || '0.00'}</div>
                        </div>

                        <div className="action-preview">
                            <div className="label">Final price</div>
                            <div className="value">{currency_type}{parseFloat(price).toFixed(2) || '0.00'}</div>
                        </div>
                    </div>
                    <button
                        className="action-btn"
                        type='submit'
                    >

                        <div className="label">Save</div>
                    </button>
                </div>
            </div>
        );
    };

    const HandleAddWorkItem = (item) => {

        setSideItemHeader(item)

        setSideItemType('add')
        setSideItemShowPopup(true)

    }
    const HandleRemoveWorkItem = (index) => {
        let _Cost_Lines = [...Cost_Lines]

        if (_Cost_Lines.length) _Cost_Lines.splice(index, 1)

        setCost_Lines(_Cost_Lines)
    }
    const HandleOpenItem = (item, index) => {

        setSideItemHeader(item.type_option)
        setSideItemType('edit')
        setSideItemExistDetials(item)

        setSideItemShowPopup(true)
    }
    const HandleCreateWorkItem = (data, type = 'add') => {

        console.log(data, 'data');

        if (type == 'add') setCost_Lines([...Cost_Lines, data])
        else if (type == 'edit') {

            let _items = [...Cost_Lines]

            let exist_item_index = _items.findIndex(itm => itm.id == data.id)

            if (exist_item_index != -1) {
                _items[exist_item_index] = data
                setCost_Lines(_items)
            }
        }
        setSideItemShowPopup(false)
    }

    const HandleCloseSidePopup = () => {

        setSideItemShowPopup(false)
        setSideItemHeader({})
        setSideItemType('add')
        setSideItemExistDetials({})
    }

    const calculateTotalSummary = () => {

        let _items = [...Cost_Lines]


        let _total = _items.reduce((prev, val, i) => {
            return {
                builder_price: prev.price + parseFloat(val.builder_price),
                price: prev.price + parseFloat(val.price),
                markups: prev.markups + parseFloat(val.markup_amount),
                taxes: prev.taxes + parseFloat(val.tax_amount),
            }

        }, {
            price: 0,
            builder_price: 0,
            markups: 0,
            taxes: 0
        })

        setPrice(_total.price)
        setBuilderPrice(_total.builder_price)
        setTotalTaxes(_total.taxes)
        setTotalMarkups(_total.markups)
    }
    useEffect(() => {

        calculateTotalSummary()
    }, [Cost_Lines])

    return (
        <>

            <div className="popup-container-main popup-container-scroll-center">
                <div className="popup-block-ui"></div>
                <form className="side-popup-container" onSubmit={(e) => {
                    HandlePopupSubmit(e)
                }}>
                    <Popup_Header />

                    <div className="sidebar-popup-content">

                        <div className="content-section content-top-main">

                            <div className="content-top-items">

                                <div className="content-item">
                                    <TextInput
                                        props={{
                                            value: name,
                                            placeholder: '',
                                            setValue: (value) => setName(value),
                                            isIcon: false,
                                            isLabel: true,
                                            isRequired: true,
                                            label: "Name",
                                            id: "costcatalog-work-name"
                                        }}
                                    />
                                </div>
                                <div className="content-item">
                                    <SelectInput
                                        props={{
                                            id: "costcatalog-work-category",
                                            value: costcode_id,
                                            placeholder: '',
                                            options: costcodeOptions || [],
                                            setValue: (value, label) => {
                                                setCostcode_id(value)
                                                setCostcode_name(label)
                                            },
                                            isIcon: false,
                                            isLabel: true,
                                            isRequired: true,
                                            label: "Work Category",

                                        }}
                                    />
                                </div>
                                <div className="content-item">
                                    <SelectInput
                                        props={{
                                            id: "costcatalog-work-unit",
                                            value: unit_id,
                                            placeholder: '',
                                            options: unitsOptions || [],
                                            setValue: (value, label) => {
                                                setUnit_id(value)
                                                setUnit_name(label)
                                            },
                                            isIcon: false,
                                            isLabel: true,
                                            isRequired: true,
                                            label: "Unit type",

                                        }}
                                    />

                                </div>
                            </div>

                            <div className="content-item">
                                <div className={`additional-item ${additionalNotes ? 'additional-item-active' : ''}`}>
                                    <div className="head" onClick={(e) => setAdditionalNotes(!additionalNotes)}>
                                        <div className="icon" dangerouslySetInnerHTML={{ __html: Icons.general.description }}></div>
                                        <div className="label">Description</div>
                                    </div>
                                    <div className="item-expanded">
                                        <ReactQuill
                                            theme="snow"
                                            value={description}
                                            onChange={(value) => setDescription(value)}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="content-section-title">WORK ITEMS</div>
                        <div className="content-section content-section-items">
                            {Cost_Lines.map((item, index) => (

                                <div className="content-section-item" key={index} >
                                    <div className="left" onClick={(e) => HandleOpenItem(item, index)}>
                                        <div
                                            className="icon"
                                            dangerouslySetInnerHTML={{ __html: item.type_option.icon }}
                                        ></div>
                                        <div className="detials">
                                            <div className="title">{item.name}</div>
                                        </div>
                                    </div>
                                    <div className="right">
                                        <div className="right-items" onClick={(e) => HandleOpenItem(item, index)}>
                                            <div className="right-item">
                                                <div className="label">Standard data</div>
                                                <div className="value">
                                                    {parseFloat(item.std_per_quandity || 0).toFixed(2)}&nbsp;{item.std_per_unit_name || ""}
                                                    &nbsp;/&nbsp;{parseFloat(item.std_work_quantity || 0).toFixed(2)}&nbsp;{item.std_work_unit_name || ""}
                                                </div>
                                            </div>
                                            <div className="right-item">
                                                <div className="label">Co-efficeient</div>
                                                <div className="value">{parseFloat(item.co_efficient || 0).toFixed(2)}</div>
                                            </div>
                                            <div className="right-item">
                                                <div className="label">Per Rate</div>
                                                <div className="value">{currency_type}{parseFloat(item.per_co_efficient_cost || 0).toFixed(2)}</div>
                                            </div>
                                            <div className="right-item">
                                                <div className="label">Prime Cost</div>
                                                <div className="value">{currency_type}{parseFloat(item.builder_price || 0).toFixed(2)}</div>
                                            </div>
                                            <div className="right-item">
                                                <div className="label">Markup</div>
                                                <div className="value">{currency_type}{parseFloat(item.markup_amount || 0).toFixed(2)}</div>
                                            </div>
                                            <div className="right-item">
                                                <div className="label">Tax</div>
                                                <div className="value">{currency_type}{parseFloat(item.tax_amount || 0).toFixed(2)}</div>
                                            </div>
                                            <div className="right-item">
                                                <div className="label">Final Amount</div>
                                                <div className="value">{currency_type}{parseFloat(item.price || 0).toFixed(2)}</div>
                                            </div>
                                        </div>
                                        <div className="right-action">
                                            <div className="right-action-item">
                                                <div
                                                    className="icon"
                                                    dangerouslySetInnerHTML={{ __html: Icons.general.delete_btn }}
                                                    onClick={(e) => HandleRemoveWorkItem(index)}
                                                ></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                            {!Cost_Lines.length ?
                                <div className="content-section-noresult">
                                    <div
                                        className="icon"
                                        dangerouslySetInnerHTML={{ __html: Icons.general.noresult }}
                                    ></div>
                                    <div className="label">No Items added yet</div>
                                </div>
                                : ''}
                        </div>

                    </div>
                    <div className="content-addbtn-items">

                        {AddBtnOptions.filter(itm => itm.type == 'item')?.map((item, i) => (

                            <div
                                className="content-addbtn-item"
                                onClick={(e) => HandleAddWorkItem(item)}
                                key={`add-work-items-${item.id}`}
                            >
                                <div className="content">
                                    <div
                                        className="icon"
                                        dangerouslySetInnerHTML={{ __html: item.icon }}
                                    ></div>
                                    <div className="label">Add {item.label}</div>
                                </div>
                            </div>

                        ))}
                    </div>
                    <Popup_Footer />
                </form>
            </div>
            {sideItemShowPopup ? <AddWorkSideItem props={{
                type: sideItemType,
                header_option: sideItemHeader.id,
                updateCallback: HandleCreateWorkItem,
                close: HandleCloseSidePopup,
                existItemData: sideItemExistDetials,
            }} /> : null}
        </>
    )

}


const AddWorkRateItemPopup = ({ props }) => {

    const { existItemData, updateCallback } = props

    const { itemId, actionType } = useParams()

    const navigate = useNavigate()
    const store = useSelector((store) => store)
    const dispatch = useDispatch()
    const { updateState } = new UserAction

    const { isAdmin, menu_permission } = useMenuAuth({ menu: 'master_features--work_rates', feature_key: 'work-rates' })
    const location = useLocation()

    const [selected_view_settings_value, setSelected_view_settings_value] = useState('')
    const [has_view_access, setHas_view_access] = useState(false)
    const [has_add_access, setHas_add_access] = useState(false)
    const [has_edit_access, setHas_edit_access] = useState(false)
    const [has_delete_access, setHas_delete_access] = useState(false)

    useEffect(() => {

        if (isAdmin) {

            setHas_view_access(true)
            setHas_add_access(true)
            setHas_edit_access(true)
            setHas_delete_access(true)
        }

        else if (menu_permission) {

            if (
                !menu_permission._view
                &&
                (!menu_permission.selected_view_settings_value
                    || menu_permission.selected_view_settings_value == 'no_access')
            ) {
                navigate(`${ReverseProxy['proxyUrl']}/quick-menu`, { state: { from: location }, replace: true })
            }

            if (actionType == 'create' && menu_permission._create == '0') navigate(`${ReverseProxy['proxyUrl']}/builder/projects`, { state: { from: location }, replace: true })
            if (actionType == 'edit' && menu_permission._edit == '0') navigate(`${ReverseProxy['proxyUrl']}/builder/projects`, { state: { from: location }, replace: true })

            setHas_view_access(menu_permission._view == '1')
            setHas_add_access(menu_permission._create == '1')
            setHas_edit_access(menu_permission._edit == '1')
            setHas_delete_access(menu_permission._delete == '1')

            setSelected_view_settings_value(menu_permission.selected_view_settings_value)

        }

    }, [store])


    return (

        <>
            {actionType == 'add' ? <AddWork props={{ updateCallback }} />
                : actionType == 'edit' ? <AddWork props={{ existItemData, updateCallback }} /> : ''}
        </>

    )

}

export default AddWorkRateItemPopup;