
import proxyConfig from '../../config/reverseProxy'
import HeaderConfig from '../../config/header'

class DriveHandler {

    constructor() {

    }

    async getDrivesHandler(params) {

        try {

            let response = await fetch(proxyConfig['serverBaseUrl'] + '/builder/drive/get-drive-details', {
                method: 'POST',
                headers: HeaderConfig.getHeader({ method: 'POST', isToken: true }),
                body: JSON.stringify(params)
            })

            response = await response.json()

            if (response) return response
        } catch {
            return { success: false }
        }


    }
    async getDriveFolderPathHandler(params) {

        try {

            let response = await fetch(proxyConfig['serverBaseUrl'] + '/builder/drive/get-folder-path', {
                method: 'POST',
                headers: HeaderConfig.getHeader({ method: 'POST', isToken: true }),
                body: JSON.stringify(params)
            })

            response = await response.json()

            if (response) return response
        } catch {
            return { success: false }
        }


    }
    async createFolderHandler(params) {

        try {

            let response = await fetch(proxyConfig['serverBaseUrl'] + '/builder/drive/create-folder', {
                method: 'POST',
                headers: HeaderConfig.getHeader({ method: 'POST', isToken: true }),
                body: JSON.stringify(params)
            })

            response = await response.json()

            if (response) return response
        } catch {
            return { success: false }
        }


    }
    async uploadFileHandler(params) {

        try {

            let response = await fetch(proxyConfig['serverBaseUrl'] + '/builder/drive/upload-file', {
                method: 'POST',
                headers: HeaderConfig.getHeader({ method: 'POST', isdefault: false, isToken: true }),
                body: params
            })

            response = await response.json()

            if (response) return response
        } catch {
            return { success: false }
        }


    }
    async updateFileDetailsHandler(params) {

        try {

            let response = await fetch(proxyConfig['serverBaseUrl'] + '/builder/drive/update-file-details', {
                method: 'POST',
                headers: HeaderConfig.getHeader({ method: 'POST', isToken: true }),
                body: JSON.stringify(params)
            })

            response = await response.json()

            if (response) return response
        } catch {
            return { success: false }
        }


    }
    async deleteFolderHandler(params) {

        try {

            let response = await fetch(proxyConfig['serverBaseUrl'] + '/builder/drive/delete-folder', {
                method: 'POST',
                headers: HeaderConfig.getHeader({ method: 'POST', isToken: true }),
                body: JSON.stringify(params)
            })

            response = await response.json()

            if (response) return response
        } catch {
            return { success: false }
        }


    }
    async deleteFileHandler(params) {

        try {

            let response = await fetch(proxyConfig['serverBaseUrl'] + '/builder/drive/delete-file', {
                method: 'POST',
                headers: HeaderConfig.getHeader({ method: 'POST', isToken: true }),
                body: JSON.stringify(params)
            })

            response = await response.json()

            if (response) return response
        } catch {
            return { success: false }
        }


    }
    async updateVersionHandler(params) {

        try {

            let response = await fetch(proxyConfig['serverBaseUrl'] + '/builder/drive/update-version-primary', {
                method: 'POST',
                headers: HeaderConfig.getHeader({ method: 'POST', isToken: true }),
                body: JSON.stringify(params)
            })

            response = await response.json()

            if (response) return response
        } catch {
            return { success: false }
        }


    }
    async uploadVersionFileHandler(params) {

        try {

            let response = await fetch(proxyConfig['serverBaseUrl'] + '/builder/drive/upload-version-file', {
                method: 'POST',
                headers: HeaderConfig.getHeader({ method: 'POST', isdefault: false, isToken: true }),
                body: params
            })

            response = await response.json()

            if (response) return response
        } catch {
            return { success: false }
        }


    }
}

export default DriveHandler;