import React, { createContext, useEffect } from 'react';
import SocketHandler from './SocketHandler';

const socket = new SocketHandler();
socket.init();

export const SocketContext = createContext(null);

export const SocketProvider = ({ children }) => {
    return (
        <SocketContext.Provider value={socket}>
            {children}
        </SocketContext.Provider>
    );
};
