

import React, { useEffect, useState, useRef } from 'react'
import { Route, Routes, BrowserRouter, Link, useLocation, Navigate, Outlet, useNavigate, useParams } from 'react-router-dom';
import store from '../../redux/store'
import StoreHandler from '../../redux/StoreHandler'
import { useSelector, useDispatch } from 'react-redux'
import UserAction from '../../redux/action/userAction'

import ReverseProxy from '../../config/reverseProxy'
import EstimateHandler from '../../Handlers/Estimate/Estimate';
import Icons from '../../assets/Icons'
import Images from '../../assets/Images'
import { CostInput, TextInput, SelectInput, SearcBarInput, RadioInput } from '../../components/Inputs'
import Utils from '../../utils'
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

import AlertPopup from '../../components/AlertPopup'
import SystemToastPopup from '../../components/SystemToastPopup'
import Loading from '../../components/Loading'
import NoDataFound from '../../components/NoDataFound';

import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

import SideFilters from '../../components/SideFilters';
import SmarkTopCards from '../../components/SmarkTopCards'

import { useAuth, useMenuAuth } from '../../hooks/useAuth'

const EstimateBOQ = () => {

    const { projectId } = useParams()

    const [project_id, setProject_id] = useState('')
    const [project_name, setProject_name] = useState('')

    const [isLoading, setIsLoading] = useState(false)
    const [warningAlert, setWarningAlert] = useState(false)
    const [warningAlertType, setWarningAlertType] = useState('warning')
    const [apiFailedMessage, setApiFailedMessage] = useState("Error in Server")


    const estimateHandler = new EstimateHandler()

    const [currency_type, setCurrency_type] = useState('₹')

    const navigate = useNavigate()
    const store = useSelector((store) => store)
    const dispatch = useDispatch()
    const { updateState } = new UserAction

    const storeHandler = new StoreHandler({
        dataStack: ['company', `project-${projectId}`],
        store: store,
        dispatch: dispatch,
        updateState: updateState,
    })

    const LoadStoreData = async () => {

        let resolved = await storeHandler.init()
    }


    const { isAdmin, menu_permission } = useMenuAuth({ menu: 'pre_construction--boq_summary', feature_key: 'estimate' })
    const location = useLocation()

    const [selected_view_settings_value, setSelected_view_settings_value] = useState('')
    const [has_view_access, setHas_view_access] = useState(false)
    const [has_add_access, setHas_add_access] = useState(false)
    const [has_edit_access, setHas_edit_access] = useState(false)
    const [has_delete_access, setHas_delete_access] = useState(false)

    useEffect(() => {

        if (isAdmin) {

            setHas_view_access(true)
            setHas_add_access(true)
            setHas_edit_access(true)
            setHas_delete_access(true)
        }

        else if (menu_permission) {

            if (
                !menu_permission._view
                &&
                (!menu_permission.selected_view_settings_value
                    || menu_permission.selected_view_settings_value == 'no_access')
            ) {
                navigate(`${ReverseProxy['proxyUrl']}/quick-menu`, { state: { from: location }, replace: true })
            }

            if (menu_permission._view == '0') navigate(`${ReverseProxy['proxyUrl']}/builder/projects`, { state: { from: location }, replace: true })

            setHas_view_access(menu_permission._view == '1')
            setHas_add_access(menu_permission._create == '1')
            setHas_edit_access(menu_permission._edit == '1')
            setHas_delete_access(menu_permission._delete == '1')

            setSelected_view_settings_value(menu_permission.selected_view_settings_value)

        }

    }, [store])

    const [selectedResultSection, setSelectedResultSection] = useState('labour')

    const [laboursResult, setLaboursResult] = useState([])
    const [materialsResult, setMaterialsResult] = useState([])
    const [machinesResult, setMachinesResult] = useState([])


    const [tabelHeader, setTabelHeader] = useState([

        {
            id: 'name',
            label: 'Name',
            minWidth: 200,
            disable: false,
        },
        {
            id: 'cost_type',
            label: 'Cost Type',
            disable: false,

        },
        {
            id: 'status',
            label: 'Status',
            disable: false,
        },
        {
            id: 'quantity',
            label: 'Quantity',
            disable: false,
        },
        {
            id: 'unitrate',
            label: 'Unit rate',
            disable: false,
            format: (value) => {
                if (String(value).includes('₹')) return value
                else return value ? `${currency_type || "₹"}${value}` : ""
            }
        },
        {
            id: 'builder_price',
            label: 'Prime cost',
            disable: false,
            format: (value) => {
                if (String(value).includes('₹')) return value
                else return value ? `${currency_type || "₹"}${value}` : ""
            }
        },
        {
            id: 'markup_amount',
            label: 'Markup',
            disable: false,
            format: (value) => {
                if (String(value).includes('₹')) return value
                else return value ? `${currency_type || "₹"}${value}` : ""
            }
        },
        {
            id: 'tax_amount',
            label: 'Tax',
            disable: false,
            format: (value) => {
                if (String(value).includes('₹')) return value
                else return value ? `${currency_type || "₹"}${value}` : ""
            }
        },
        {
            id: 'price',
            label: 'Final price',
            disable: false,
            format: (value) => {
                if (String(value).includes('₹')) return value
                else return value ? `${currency_type || "₹"}${value}` : ""
            }
        },

    ])
    const [estimateItemsOrginal, setEstimateItemsOrginal] = useState([])
    const [estimateItems, setEstimateItems] = useState([])

    const AddBtnOptions = [
        {
            icon: Icons.general.group,
            label: 'Group',
            type: 'group',
            id: "group"
        },
        {
            icon: Icons.general.work,
            label: 'Work Template',
            type: 'work_template',
            id: 'work_template'
        },
        {
            icon: Icons.general.labour,
            label: 'Labour',
            type: 'item',
            id: "labour"
        },
        {
            icon: Icons.general.material,
            label: 'Materials',
            type: 'item',
            id: "material"
        },
        {
            icon: Icons.general.machines,
            label: 'Machines',
            type: 'item',
            id: "machine"
        },
        {
            icon: Icons.general.subcontractor,
            label: 'Subcontractor',
            type: 'item',
            id: "subcontractor"
        },
        {
            icon: Icons.general.fee,
            label: 'Fee',
            type: 'item',
            id: "fee"
        },
        {
            icon: Icons.general.allowance,
            label: 'Allowances',
            type: 'item',
            id: "allowance"
        },
        {
            icon: Icons.general.work,
            label: 'Work',
            type: 'work',
            id: 'work'
        },
    ]

    const getEstimateItemTypeEenem = (type) => {

        if (type == '1') return 'group'
        else if (type == '2') return 'work_template'
        else if (type == '3') return 'item'
        else if (type == '4') return 'work'
        else return ''

    }
    const getEstimateItemCategoryEenem = (type) => {

        if (type == '1') return 'material'
        else if (type == '2') return 'labour'
        else if (type == '3') return 'machine'
        else if (type == '4') return 'subcontractor'
        else if (type == '5') return 'fee'
        else if (type == '6') return 'allowance'
        else return ''

    }
    const getEstimateItemStatusEenem = (type) => {

        if (type == '0') return 'incomplete'
        else if (type == '1') return 'completed'
        else if (type == '2') return 'hold'
        else if (type == '3') return 'stopped'
        else return ''
    }

    const getSubItem = (items, parentid) => {

        let subItems = []

        let getItems = (items, parentid) => {

            if (!Array.isArray(items)) return

            let _items = items.filter(itm => itm.parent_id == parentid)

            subItems.push(..._items)

            _items.forEach(itm => getItems(items, itm.id))
        }

        getItems(items, parentid)

        return subItems
    }

    const getFormatedEstimateItem = (item) => {

        return {
            sno: item.sno,
            expanded: true,
            selected: false,
            disable: false,

            work_id: item.work_id,
            work_name: item.work_name,

            sor_id: item.sor_id,
            sor_name: item.sor_name,

            co_efficient: parseFloat(item.co_efficient || 0) || 0,
            per_co_efficient_cost: parseFloat(item.per_co_efficient_cost || 0) || 0,

            parent_id: item.parent_id,
            parent_name: item.parent_name,
            id: item.id,
            name: item.name,
            description: item.description,

            costcode_id: item.costcode_id || "",
            costcode_name: item.costcode_name || "",

            type: getEstimateItemTypeEenem(item.type),
            item_type: item.type != '3' ? getEstimateItemTypeEenem(item.type) : getEstimateItemCategoryEenem(item.item_type),
            cost_type: item.type != '1' && item.type != '3' ? getEstimateItemTypeEenem(item.type) : getEstimateItemCategoryEenem(item.item_type),

            parent_type: item.parent_type,
            parent_quantity: parseFloat(item.parent_quantity || 0),

            _status: item.status,
            status: getEstimateItemStatusEenem(item.status),

            quantity: parseFloat(item.quantity || 0) || 0,
            unitrate: parseFloat(item.unitrate || 0) || 0,
            builder_price: parseFloat(item.builder_price || 0) || 0,
            price: parseFloat(item.price || 0) || 0,


            unit_id: item.unit_id || "",
            unit_name: item.unit_name || "",

            markup_id: item.markup_id || "",
            markup_name: item.markup_name || "",
            markup_value: parseFloat(item.markup_value || 0) || 0,
            markup_value_type: item.markup_value_type || "",
            markup_amount: parseFloat(item.markup_amount || 0) || 0,

            tax_id: item.tax_id || "",
            tax_name: item.tax_name || "",
            tax_value_type: item.tax_value_type || "",
            tax_value: parseFloat(item.tax_value || 0) || 0,
            tax_amount: parseFloat(item.tax_amount || 0) || 0,

            sub_items: []
        }

    }

    const getOrderedTableItems = (items = []) => {
        const groupedItems = {};

        for (const item of items) {

            const parent_id = item.parent_id;

            if (parent_id != null) {

                if (!groupedItems[parent_id]) groupedItems[parent_id] = [];

                groupedItems[parent_id].push(item);
            }
        }

        for (const item of items) {
            const itemId = item.id;
            if (groupedItems[itemId]) {
                item.sub_items = groupedItems[itemId];
            }
        }

        return items.filter(d => d.parent_id == "")

    }

    const LoadEstimateItems = async () => {

        let filter = {
            projectId: projectId
        }

        setIsLoading(true)

        let response = await estimateHandler.getEstimateItemsHandler(filter)
        setIsLoading(false)

        if (response.success) {


            let { data } = response

            if (!Array.isArray(data)) return

            let items = data.map(d => {

                let item = getFormatedEstimateItem(d)
                let subItems = getSubItem(data, d.id).map(itm => getFormatedEstimateItem(itm))

                let tax_amount = item.tax_amount
                let markup_amount = item.markup_amount
                let builder_price = item.builder_price
                let price = item.price

                let subItems_Total = subItems.reduce((prev, val, ind) => {

                    return {
                        tax_amount: prev.tax_amount + val.tax_amount,
                        markup_amount: prev.markup_amount + val.markup_amount,
                        builder_price: prev.builder_price + val.builder_price,
                        price: prev.price + val.price,
                    }

                }, { tax_amount: 0, markup_amount: 0, builder_price: 0, price: 0 })

                tax_amount = parseFloat(tax_amount + (subItems_Total.tax_amount || 0)).toFixed(2)
                markup_amount = parseFloat(markup_amount + (subItems_Total.markup_amount || 0)).toFixed(2)
                builder_price = parseFloat(builder_price + (subItems_Total.builder_price || 0)).toFixed(2)
                price = parseFloat(price + (subItems_Total.price || 0)).toFixed(2)



                if (item.parent_id) {
                    let parent_item = data.filter(d => d.id == item.parent_id)[0]
                    if (parent_item) {
                        item.parent_type = parent_item.type
                        if (parent_item.type == '4') item.parent_quantity = parent_item.quantity
                    }
                }

                item._tax_amount = tax_amount || ""
                item._tax_percent = ((tax_amount / builder_price) * 100 || 0).toFixed(2)
                item._markup_amount = markup_amount || ""
                item._markup_percent = ((markup_amount / builder_price) * 100 || 0).toFixed(2)
                item._builder_price = builder_price || ""
                item._price = price || ""
                item._unitrate = item.unitrate || ""
                item._quantity = item.quantity || ""

                item.tax_amount = tax_amount ? `${currency_type}${tax_amount}` : ""
                item.markup_amount = markup_amount ? `${currency_type}${markup_amount}` : ""
                item.builder_price = builder_price ? `${currency_type}${builder_price}` : ""
                item.price = price ? `${currency_type}${price}` : ""
                item.unitrate = item.unitrate ? `${currency_type}${item.unitrate}` : ""
                item.quantity = item.quantity ? `${item.quantity}${item.unit_name || ""}` : ""

                let type_option = AddBtnOptions.filter(opt => {

                    if (opt.type != 'item' && item.type == opt.type) return true
                    else if (opt.type == 'item' && item.item_type == opt.id) return true
                    else return false
                })

                type_option = type_option.length ? type_option[0] : undefined
                item.type_option = type_option

                return item

            })



            items = getOrderedTableItems(items)

            setEstimateItemsOrginal(data)
            setEstimateItems(items)
            HandleBOQSummary(items)
            HandleSmartCardSetup(data)
        }
        else {
            setWarningAlert(true)
            setWarningAlertType('error')
            setApiFailedMessage(`Error in Estimate Items, Please try again!`)
        }
    }


    useEffect(() => {
        LoadStoreData()
        LoadEstimateItems()
    }, [])

    useEffect(() => {


        if (store && store.user) {

            let { company, selected_project, settings } = store.user

            if (selected_project) {

                let { id, name } = selected_project

                setProject_id(id)
                setProject_name(name)
            }

            if (company) {

                let { currency_setting, timezone, temperature_setting } = company

                if (currency_setting) setCurrency_type(currency_setting)
            }
        }


    }, [store])


    const HandleExpandGroup = (group_id, parent_id) => {

        let _estimateItems = [...estimateItems]

        const findAndUpdateGroup = (group_id, parent_id, _estimateItems) => {

            _estimateItems = _estimateItems.map((item, idx) => {

                if (item.id == group_id && item.parent_id == parent_id) {
                    item.expanded = !item.expanded
                }
                else if (item.type != 'item' && Array.isArray(item.sub_items)) {
                    item.sub_items = findAndUpdateGroup(group_id, parent_id, item.sub_items)
                }

                return item
            })

            return _estimateItems
        }

        _estimateItems = findAndUpdateGroup(group_id, parent_id, _estimateItems)

        setEstimateItems(_estimateItems)
    }

    const CreateEstimateTableItem = (row, idx, sub_space_px, parent_expand = true) => {


        let { id, disable, type, parent_id, sub_items, expanded, _status } = row

        sub_items = sub_items || []

        return (
            <>
                {!disable ?
                    <>
                        <TableRow
                            hover
                            role="checkbox"
                            tabIndex={-1}
                            key={id}
                            style={{ height: '45px', display: parent_expand ? 'table-row' : 'none' }}
                            className={`estimate-table-row ${type != 'item' ? !parent_id ? 'estimate-table-row-group' : 'estimate-table-row-subgroup' : ''}`}
                        >
                            {tabelHeader.map((column) => {
                                const value = row[column.id];
                                return (

                                    column.disable ? '' :
                                        <TableCell
                                            key={column.id}
                                            align={column.align}

                                            className='estimate-table-row-item'
                                            style={column.id == 'name' ? { paddingLeft: `${sub_space_px}px` } : {}}
                                        >
                                            {
                                                column.id == 'name' ?
                                                    <div
                                                        className="tabel-name-main"
                                                    >
                                                        {type != 'item' ?
                                                            <div
                                                                className={`tabel-row-arrow ${expanded ? 'tabel-row-arrow-open' : ''} `}
                                                                dangerouslySetInnerHTML={{ __html: Icons.general.group_arrow }}
                                                                onClick={() => HandleExpandGroup(id, parent_id)}
                                                            ></div>
                                                            : ''}

                                                        <div
                                                            className="tabel-name"
                                                        > {column.format ? column.format(value) : value}</div>

                                                    </div>

                                                    : column.id == 'status' ?

                                                        <div
                                                            className="tabel-status-main"
                                                        >
                                                            <span className={`tabel-status-span status-span-${value}`}></span>
                                                            <div style={{ display: 'none' }} className="tabel-status"> {column.format ? column.format(value) : value}</div>

                                                        </div>
                                                        : column.format ? column.format(value) : value
                                            }
                                        </TableCell>
                                );
                            })}
                        </TableRow>
                        {sub_items.map((item, idxx) => (
                            CreateEstimateTableItem(item, idxx, sub_space_px + 20, parent_expand ? expanded : parent_expand)
                        ))}
                    </>
                    : ''}
            </>
        )
    }

    const HandleDownloadEvent = () => {

        if (selectedResultSection == 'estimate') {
            let _estimateItems = [...estimateItems]

            let ordered_items = []

            let hide_diable_flattern_items = (items) => {

                if (Array.isArray(items)) {
                    items.forEach(item => {

                        if (!item.disable) {
                            ordered_items.push(item)

                            if (Array.isArray(item.sub_items)) hide_diable_flattern_items(item.sub_items)
                        }

                    })
                }

            }

            hide_diable_flattern_items(_estimateItems)

            let table_header = [...tabelHeader]

            let export_header = table_header.filter(th => !th.disable).map(th => {

                let id = ['quantity', 'unitrate', 'builder_price', 'markup_amount', 'tax_amount', 'price'].includes(th.id) ? `_${th.id}` : th.id

                return {
                    id: id,
                    label: th.label,
                }
            })

            export_header.splice(1, 0, ...[
                {
                    id: 'parent_name',
                    label: "Parent Name",
                },
            ])

            let _quantity_idx = export_header.findIndex(th => th.id == '_quantity')

            if (_quantity_idx != -1) {
                export_header.splice(_quantity_idx + 1, 0, ...[
                    {
                        id: 'unit_name',
                        label: "Unit"
                    }
                ])
            }

            let _markup_amount_idx = export_header.findIndex(th => th.id == '_markup_amount')

            if (_markup_amount_idx != -1) {

                export_header.splice(_markup_amount_idx, 1)

                export_header.splice(_markup_amount_idx, 0, ...[
                    {
                        id: '_markup_percent',
                        label: "Markup %"
                    },
                    {
                        id: '_markup_amount',
                        label: `Markup ${currency_type}`
                    },
                ])
            }

            let _tax_amount_idx = export_header.findIndex(th => th.id == '_tax_amount')

            if (_tax_amount_idx != -1) {

                export_header.splice(_tax_amount_idx, 1)

                export_header.splice(_tax_amount_idx, 0, ...[
                    {
                        id: '_tax_percent',
                        label: "Tax %"
                    },
                    {
                        id: '_tax_amount',
                        label: `Tax ${currency_type}`
                    },
                ])
            }

            Utils.exportEstimateToExcel(export_header, ordered_items, `${project_name} Estimate`)
        }
        else {


            let tabelHeader = [

                {
                    id: 'name',
                    label: 'Name',
                    minWidth: 500,
                    disable: false,
                },

                {
                    id: '_quantity',
                    label: 'Quantity',
                    disable: false,
                },
                {
                    id: 'unit_name',
                    label: 'Unit',
                    disable: false,
                },
                {
                    id: 'unitrate',
                    label: 'Unit rate',
                    disable: false,
                },
                {
                    id: '_markup_percent',
                    label: 'Markup %',
                    disable: false,
                },
                {
                    id: '_markup_amount',
                    label: `Markup ${currency_type}`,
                    disable: false,
                },
                {
                    id: '_tax_amount',
                    label: `Tax ${currency_type}`,
                    disable: false,
                },
                {
                    id: '_tax_percent',
                    label: `Tax %`,
                    disable: false,
                },
                {
                    id: 'price',
                    label: 'Price',
                    disable: false,
                },

            ]

            let ordered_items = []

            if (selectedResultSection == 'labour') ordered_items = [...laboursResult]
            else if (selectedResultSection == 'material') ordered_items = [...materialsResult]
            else if (selectedResultSection == 'machines') ordered_items = [...machinesResult]


            let export_header = tabelHeader.filter(th => !th.disable).map(th => {

                let id = ['quantity', 'unitrate', 'builder_price', 'markup_amount', 'tax_amount', 'price'].includes(th.id) ? `_${th.id}` : th.id

                return {
                    id: id,
                    label: th.label,
                }
            })


            Utils.exportEstimateToExcel(export_header, ordered_items, `BOQ-${String(selectedResultSection).toUpperCase()}-${project_name}`)

        }

    }

    const HandleFilterAllEvent = (value) => {

        let _estimateItems = [...estimateItems]

        const selectItem = (items, value) => {

            items = items.map(item => {

                item.disable = value
                if (Array.isArray(item.sub_items)) item.sub_items = selectItem(item.sub_items, value)
                return item
            })

            return items
        }

        _estimateItems = selectItem(_estimateItems, value)


        setEstimateItems(_estimateItems)
    }

    const [ShowSideFilter, setShowSideFilter] = useState(false)
    const [SideFilterSettings, setSideFilterSettings] = useState([
        {
            id: 'filterby-filter',
            type: 'multiselect',
            value: true,
            placeholder: '',

            selectoptions: [
                {
                    id: 'select-filteroption-filterby-all',
                    type: 'checkbox',
                    key: `all`,
                    value: true,
                    placeholder: '',
                    classNames: [],
                    isIconLeft: false,
                    isIcon: false,
                    icon: '',
                    isLabel: true,
                    label: 'All',
                },
                {
                    id: 'select-filteroption-filterby-incomplete',
                    type: 'checkbox',
                    key: `incomplete`,
                    category: 'status',
                    value: true,
                    placeholder: '',
                    classNames: [],
                    isIconLeft: false,
                    isIcon: false,
                    icon: '',
                    isLabel: true,
                    label: 'InComplete',
                },
                {
                    id: 'select-filteroption-filterby-completed',
                    type: 'checkbox',
                    key: `completed`,
                    category: 'status',
                    value: true,
                    placeholder: '',
                    classNames: [],
                    isIconLeft: false,
                    isIcon: false,
                    icon: '',
                    isLabel: true,
                    label: 'Completed',
                },
                {
                    id: 'select-filteroption-filterby-hold',
                    type: 'checkbox',
                    key: `hold`,
                    category: 'status',
                    value: true,
                    placeholder: '',
                    classNames: [],
                    isIconLeft: false,
                    isIcon: false,
                    icon: '',
                    isLabel: true,
                    label: 'Hold',
                },
                {
                    id: 'select-filteroption-filterby-stopped',
                    type: 'checkbox',
                    key: `stopped`,
                    category: 'status',
                    value: true,
                    placeholder: '',
                    classNames: [],
                    isIconLeft: false,
                    isIcon: false,
                    icon: '',
                    isLabel: true,
                    label: 'Stopped',
                },
                {
                    id: 'select-filteroption-filterby-material',
                    type: 'checkbox',
                    key: `material`,
                    category: 'item_type',
                    value: true,
                    placeholder: '',
                    classNames: [],
                    isIconLeft: false,
                    isIcon: false,
                    icon: '',
                    isLabel: true,
                    label: 'Materials',
                },
                {
                    id: 'select-filteroption-filterby-labour',
                    type: 'checkbox',
                    key: `labour`,
                    category: 'item_type',
                    value: true,
                    placeholder: '',
                    classNames: [],
                    isIconLeft: false,
                    isIcon: false,
                    icon: '',
                    isLabel: true,
                    label: 'Labour',
                },
                {
                    id: 'select-filteroption-filterby-machine',
                    type: 'checkbox',
                    key: `machine`,
                    category: 'item_type',
                    value: true,
                    placeholder: '',
                    classNames: [],
                    isIconLeft: false,
                    isIcon: false,
                    icon: '',
                    isLabel: true,
                    label: 'Machine',
                },
                {
                    id: 'select-filteroption-filterby-subcontractor',
                    type: 'checkbox',
                    key: `subcontractor`,
                    category: 'item_type',
                    value: true,
                    placeholder: '',
                    classNames: [],
                    isIconLeft: false,
                    isIcon: false,
                    icon: '',
                    isLabel: true,
                    label: 'Sub Contractor',
                },
                {
                    id: 'select-filteroption-filterby-fee',
                    type: 'checkbox',
                    key: `fee`,
                    category: 'item_type',
                    value: true,
                    placeholder: '',
                    classNames: [],
                    isIconLeft: false,
                    isIcon: false,
                    icon: '',
                    isLabel: true,
                    label: 'Fee',
                },
                {
                    id: 'select-filteroption-filterby-allowances',
                    type: 'checkbox',
                    key: `allowances`,
                    category: 'item_type',
                    value: true,
                    placeholder: '',
                    classNames: [],
                    isIconLeft: false,
                    isIcon: false,
                    icon: '',
                    isLabel: true,
                    label: 'Allowances',
                },
            ],

            callback: (e) => { },
            classNames: [],
            isIconLeft: true,
            isIcon: true,
            icon: Icons.general.filter_by,
            isLabel: true,
            label: 'Filter by',
        },
        {
            id: 'showtable-filter',
            type: 'multiselect',
            value: true,
            placeholder: '',

            selectoptions: [
                {
                    id: 'select-filteroption-showtable-all',
                    type: 'checkbox',
                    key: `all`,
                    value: true,
                    placeholder: '',
                    classNames: [],
                    isIconLeft: false,
                    isIcon: false,
                    icon: '',
                    isLabel: true,
                    label: 'All',
                },
                {
                    id: 'select-filteroption-showtable-name',
                    type: 'checkbox',
                    value: true,
                    key: `name`,
                    placeholder: '',
                    classNames: [],
                    isIconLeft: false,
                    isIcon: false,
                    icon: '',
                    isLabel: true,
                    label: 'Name',
                },
                {
                    id: 'select-filteroption-showtable-cost_type',
                    type: 'checkbox',
                    value: true,
                    key: `cost_type`,
                    placeholder: '',
                    classNames: [],
                    isIconLeft: false,
                    isIcon: false,
                    icon: '',
                    isLabel: true,
                    label: 'Cost Type',
                },
                {
                    id: 'select-filteroption-showtable-status',
                    type: 'checkbox',
                    value: true,
                    key: `status`,
                    placeholder: '',
                    classNames: [],
                    isIconLeft: false,
                    isIcon: false,
                    icon: '',
                    isLabel: true,
                    label: 'Status',
                },
                {
                    id: 'select-filteroption-showtable-quantity',
                    type: 'checkbox',
                    value: true,
                    key: `quantity`,
                    placeholder: '',
                    classNames: [],
                    isIconLeft: false,
                    isIcon: false,
                    icon: '',
                    isLabel: true,
                    label: 'Quantity',
                },
                {
                    id: 'select-filteroption-showtable-unitrate',
                    type: 'checkbox',
                    value: true,
                    key: `unitrate`,
                    placeholder: '',
                    classNames: [],
                    isIconLeft: false,
                    isIcon: false,
                    icon: '',
                    isLabel: true,
                    label: 'Unitrate',
                },
                {
                    id: 'select-filteroption-showtable-builder_price',
                    type: 'checkbox',
                    value: true,
                    key: `builder_price`,
                    placeholder: '',
                    classNames: [],
                    isIconLeft: false,
                    isIcon: false,
                    icon: '',
                    isLabel: true,
                    label: 'Prime cost',
                },
                {
                    id: 'select-filteroption-showtable-markup_amount',
                    type: 'checkbox',
                    value: true,
                    key: `markup_amount`,
                    placeholder: '',
                    classNames: [],
                    isIconLeft: false,
                    isIcon: false,
                    icon: '',
                    isLabel: true,
                    label: 'Markup',
                },
                {
                    id: 'select-filteroption-showtable-tax_amount',
                    type: 'checkbox',
                    value: true,
                    key: `tax_amount`,
                    placeholder: '',
                    classNames: [],
                    isIconLeft: false,
                    isIcon: false,
                    icon: '',
                    isLabel: true,
                    label: 'Tax',
                },
                {
                    id: 'select-filteroption-showtable-price',
                    type: 'checkbox',
                    value: true,
                    key: `price`,
                    placeholder: '',
                    classNames: [],
                    isIconLeft: false,
                    isIcon: false,
                    icon: '',
                    isLabel: true,
                    label: 'Final price',
                },
            ],

            callback: (e) => { },
            classNames: [],
            isIconLeft: true,
            isIcon: true,
            icon: Icons.general.show_hide,
            isLabel: true,
            label: 'Show Tables',
        },

    ])

    const HandleSideFilterClose = () => {
        setShowSideFilter(false)
    }
    const HandleSideFilterUpdateCallback = (filters) => {

        let _estimateItems = [...estimateItems]

        filters.forEach((filter, fidx) => {

            if (filter.type == 'multiselect') {

                if (filter.id == 'filterby-filter') {

                    filter.selectoptions.forEach((option, oidx) => {

                        if (option.key == 'all' && option.value) HandleFilterAllEvent(!option.value)
                        else {
                            let filter_by = (items, key, validate_value, value) => {

                                const filterItem = (items, key, validate_value, value) => {

                                    items = items.map(item => {

                                        if (key == 'status' && item[key] == validate_value) item.disable = !value
                                        else if (key == 'item_type' && item.type == 'item' && item[key] == validate_value) item.disable = !value

                                        if (Array.isArray(item.sub_items)) item.sub_items = filterItem(item.sub_items, key, validate_value, value)

                                        return item
                                    })

                                    return items
                                }

                                items = filterItem(items, key, validate_value, value)

                                return items
                            }
                            _estimateItems = filter_by(_estimateItems, option.category, option.key, option.value)


                        }

                    })
                }
                else if (filter.id == 'showtable-filter') {

                    filter.selectoptions.forEach((option, oidx) => {

                        if (option.key == 'all') {

                            let _tabelHeader = [...tabelHeader]

                            _tabelHeader = _tabelHeader.map((th, i) => {
                                if (i > 0) th.disable = false
                                return th
                            })

                            setTabelHeader(_tabelHeader)
                        }
                        else {
                            let _tabelHeader = [...tabelHeader]

                            let head_idx = _tabelHeader.findIndex(h => h.id == option.key)

                            if (head_idx != -1) {
                                _tabelHeader[head_idx].disable = !option.value
                                setTabelHeader(_tabelHeader)
                            }


                        }

                    })
                }
            }

        })

        console.log(_estimateItems, '_estimateItems');

        setEstimateItems(_estimateItems)

        setSideFilterSettings(filters)
        HandleSideFilterClose()
    }


    const [Filters, setFilters] = useState({
        left: [
            {
                id: "estimate-search",
                value: '',
                isSearchBar: true,
                type: "input",
                isDropDownContainer: false,
                placeholder: "Search by name",
                dropDownOptions: [],
                callback: (val) => { },
                isIcon: true,
                icon: Icons.general.search,
                isLabel: false,
                label: "",
                isIconLeft: false
            },

        ],
        right: [


            {
                id: "estimate-download",
                type: "icon",
                isSelectToggle: true,
                value: true,
                placeholder: "",
                callback: (val) => HandleFiltersEvent(val, 'estimate-download'),
                isIcon: true,
                icon: Icons.general.download,
                isLabel: true,
                label: "Download BOQ",
                isIconLeft: false
            },
        ]
    })

    const HandleFiltersEvent = async (value, filter_id) => {

        if (!filter_id) return

        if (filter_id == 'estimate-search') {

            let filters = { ...Filters }

            filters.left[0].value = value

            let _items = []

            if (selectedResultSection == 'labour') _items = [...laboursResult]
            else if (selectedResultSection == 'material') _items = [...materialsResult]
            else if (selectedResultSection == 'machines') _items = [...machinesResult]




            let search_items = (items, value, column = 'name') => {

                let search_item = (items, value, column = 'name') => {

                    items = items.map(item => {

                        let is_item_match = String(item[column]).toLocaleLowerCase().includes(String(value).toLocaleLowerCase())

                        if (is_item_match) item.disable = false
                        else item.disable = true

                        return item

                    })

                    return items

                }

                items = search_item(items, value, column)
                return items
            }

            _items = search_items(_items, value, 'name')

            console.log(_items, '_items');

            if (selectedResultSection == 'labour') setLaboursResult(_items)
            else if (selectedResultSection == 'material') setMaterialsResult(_items)
            else if (selectedResultSection == 'machines') setMachinesResult(_items)

            setFilters(filters)

        }

        else if (filter_id == 'estimate-download') HandleDownloadEvent()

    }

    const isLeftFilter = Array.isArray(Filters.left) && Filters.left.length > 0
    const isRightFilter = Array.isArray(Filters.right) && Filters.right.length > 0

    const Icon_Filter = ({ props }) => {
        const {
            id,
            isSearchBar,
            type,
            value,
            isSelectToggle,
            isDropDownContainer,
            dropDownOptions,
            placeholder,
            callback,
            isIcon,
            icon,
            isLabel,
            label,
            isIconLeft,
        } = props

        const [iconStatus, setIconStatus] = useState(value != undefined ? value : false)

        const HandleFilterIconInput = (e) => {


            HandleFiltersEvent(!iconStatus, id)
            setIconStatus(!iconStatus)
        }

        return (
            <>
                {isIcon && type == 'icon' ?
                    <div
                        className="filter-item group-expand-filter"
                        onClick={(e) => HandleFilterIconInput(e)}
                        key={`filter-item-${id}`}
                    >
                        <div
                            className="icon"
                            dangerouslySetInnerHTML={{ __html: id.includes('expand') ? iconStatus ? Icons.general.collapse : Icons.general.expand : icon }}
                        ></div>
                        {isLabel ? <div className="label">{id.includes('expand') ? iconStatus ? 'Collapse all' : 'Expand all' : label}</div> : ''}
                    </div>

                    : null}
            </>
        )
    }
    const Button_Filter = ({ props }) => {
        const {
            id,
            isSearchBar,
            type,
            isDropDownContainer,
            dropDownOptions,
            callback,
            isIcon,
            icon,
            isLabel,
            label,
            isIconLeft,
        } = props
        const [selectFilterOpts, setSelectFilterOpts] = useState([])

        const [dropdownStatus, setDropDownStatus] = useState(false)

        const HandleClick = (e) => {
            setDropDownStatus(!dropdownStatus)
        }

        let HandleSubItemChange = (value, opt, i) => {

            let _filters = [...selectFilterOpts]

            if (_filters[i].id == 'opt-select-all') {
                _filters = _filters.map(f => {
                    f.value = value

                    return f
                })
            } else _filters[i].value = value

            setSelectFilterOpts(_filters)

            HandleFiltersEvent(opt, id)
        }

        useEffect(() => {

            let items_parent = document.querySelector(`#${id}`)

            window.addEventListener('click', (e) => {

                let path = e.path || (e.composedPath && e.composedPath());

                if (Array.isArray(path) && !path.includes(items_parent))
                    setDropDownStatus(false)
            })
            setSelectFilterOpts(dropDownOptions)
        }, [])


        return (
            <div className='filter-item dropdown-select-filter' id={id} key={`filter-item-${id}`}>
                <div
                    className="button"
                    onClick={(e) => HandleClick(e)}
                >
                    {isIcon && isIconLeft ?
                        <span
                            className={`icon ${dropdownStatus ? 'icon-active' : ''}`}
                            dangerouslySetInnerHTML={{ __html: icon }}
                        ></span>
                        : null}

                    {isLabel ? <span class='label'>{label}</span> : null}

                    {isIcon && !isIconLeft ?
                        <span
                            className={`icon ${dropdownStatus ? 'icon-active' : ''}`}
                            dangerouslySetInnerHTML={{ __html: icon }}
                        ></span>
                        : null}
                </div>

                {isDropDownContainer ?

                    <div className={`dropdown-select-items ${dropdownStatus ? 'dropdown-select-items-active' : ''}`}>

                        {selectFilterOpts.map((opt, i) => (

                            opt.type == 'label' ?
                                <div className="dropdown-select-title">{opt.label}</div>
                                :
                                <div className="dropdown-select-itm">
                                    <RadioInput props={{
                                        id: `filters-item-select-${opt.id}`,
                                        value: opt.value,
                                        isIcon: false,
                                        icon: "",
                                        inputType: "checkbox",
                                        name: "table-default-radio",
                                        setValue: (value) => HandleSubItemChange(value, opt, i)
                                    }} />
                                    <label className='label' htmlFor={`filters-item-select-${opt.id}`}>{opt.label}</label>
                                </div>

                        ))}

                    </div>

                    : null}
            </div>

        )
    }



    const HandleResultSection = (section) => {
        setSelectedResultSection(section)
    }

    const HandleBOQSummary = (items) => {

        if (!Array.isArray(items)) return

        let _estimateItems = [...items]

        let ordered_items = []

        let flattern_items = (items) => {

            if (Array.isArray(items)) {
                items.forEach(item => {

                    if (!item.disable) {
                        ordered_items.push(item)

                        if (Array.isArray(item.sub_items)) flattern_items(item.sub_items)
                    }

                })
            }

        }
        flattern_items(_estimateItems)

        let labour_items = ordered_items.filter(itm => itm.type == 'item' && itm.item_type == 'labour')
        let material_items = ordered_items.filter(itm => itm.type == 'item' && itm.item_type == 'material')
        let machines_items = ordered_items.filter(itm => itm.type == 'item' && itm.item_type == 'machine')

        let getTotalItems = (items) => {

            let unq_labour_items = {}

            items.forEach((item, i) => {

                let name = String(item.name).trim().toLocaleLowerCase()
                let _unitrate = String(item._unitrate).trim().toLocaleLowerCase()
                let _id = `${name}-${_unitrate}`

                if (unq_labour_items[_id] && parseFloat(unq_labour_items[_id]['_unitrate'] || 0) == parseFloat(item['_unitrate'] || 0)) {


                    let _quantity = parseFloat(unq_labour_items[_id]['_quantity'] || 0) + parseFloat(item['_quantity'] || 0)

                    unq_labour_items[_id]['_quantity'] = _quantity
                    unq_labour_items[_id]['quantity'] = `${unq_labour_items[_id]['_quantity']}${unq_labour_items[_id]['unit_name']}`
                }
                else unq_labour_items[_id] = {

                    sno: item.sno,
                    expanded: item.expanded,
                    selected: item.selected,
                    disable: item.disable,
                    work_id: item.work_id,
                    work_name: item.work_name,
                    sor_id: item.sor_id,
                    sor_name: item.sor_name,
                    parent_id: item.parent_id,
                    parent_name: item.parent_name,
                    id: item.id,
                    name: item.name,
                    description: item.description,
                    costcode_id: item.costcode_id,
                    costcode_name: item.costcode_name,
                    type: item.type,
                    item_type: item.item_type,
                    cost_type: item.cost_type,
                    parent_type: item.parent_type,
                    parent_quantity: item.parent_quantity,
                    status: item.status,
                    quantity: item.quantity,
                    unitrate: item.unitrate,
                    builder_price: item.builder_price,
                    price: item.price,
                    unit_id: item.unit_id,
                    unit_name: item.unit_name,
                    markup_id: item.markup_id,
                    markup_name: item.markup_name,
                    markup_value: item.markup_value,
                    markup_value_type: item.markup_value_type,
                    markup_amount: item.markup_amount,
                    tax_id: item.tax_id,
                    tax_name: item.tax_name,
                    tax_value_type: item.tax_value_type,
                    tax_value: item.tax_value,
                    tax_amount: item.tax_amount,
                    sub_items: item.sub_items,

                    _status: item._status,

                    _tax_amount: item._tax_amount,
                    _tax_percent: item._tax_percent,
                    _markup_amount: item._markup_amount,
                    _markup_percent: item._markup_percent,
                    _builder_price: item._builder_price,
                    _price: item._price,
                    _unitrate: item._unitrate,
                    _quantity: item._quantity,
                    type_option: item.type_option,
                }

            })

            let _unq_labour_items = []

            for (const key in unq_labour_items) _unq_labour_items.push(unq_labour_items[key])

            return _unq_labour_items
        }

        let _labour_items = getTotalItems(labour_items)
        let _material_items = getTotalItems(material_items)
        let _machines_items = getTotalItems(machines_items)

        setLaboursResult(_labour_items)
        setMaterialsResult(_material_items)
        setMachinesResult(_machines_items)

    }

    const RenderEstimateResult = (resultItems) => {
        return (
            <>
                {resultItems.length ?
                    <Paper style={{ width: '100%', height: '100%', boxShadow: 'none', overflow: 'hidden' }}>
                        <TableContainer style={{ height: '100%', boxShadow: 'none !important', }} >
                            <Table stickyHeader>
                                <TableHead
                                    className='tabel-header estimate-tabel-header'
                                >
                                    <TableRow style={{ height: '40px' }}>
                                        {tabelHeader.filter(th => !th.disable).map((column) => (

                                            <TableCell
                                                key={column.id}
                                                align={column.align}
                                                style={{ minWidth: column.minWidth }}
                                                className='tabel-header-items'
                                            >
                                                {column.label}
                                            </TableCell>
                                        ))}
                                    </TableRow>
                                </TableHead>
                                <TableBody className='tabel-body'>
                                    {resultItems.map((row, i) => (
                                        CreateEstimateTableItem(row, i, 15, true)
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Paper>
                    : <NoDataFound label={'No Items Found'} />}
            </>
        )
    }
    const RenderItemsResult = (resultItems) => {

        let _tabelHeader = [

            {
                id: 'name',
                label: 'Name',
                minWidth: 500,
                disable: false,
            },

            {
                id: 'quantity',
                label: 'Quantity',
                disable: false,
            },
            {
                id: 'unitrate',
                label: 'Unit rate',
                disable: false,
                format: (value) => {
                    if (String(value).includes('₹')) return value
                    else return value ? `${currency_type || "₹"}${value}` : ""
                }
            },
            {
                id: 'markup_amount',
                label: 'Markup',
                disable: false,
                format: (value) => {
                    if (String(value).includes('₹')) return value
                    else return value ? `${currency_type || "₹"}${value}` : ""
                }
            },
            {
                id: 'tax_amount',
                label: 'Tax',
                disable: false,
                format: (value) => {
                    if (String(value).includes('₹')) return value
                    else return value ? `${currency_type || "₹"}${value}` : ""
                }
            },
            {
                id: 'price',
                label: 'Price',
                disable: false,
                format: (value) => {
                    if (String(value).includes('₹')) return value
                    else return value ? `${currency_type || "₹"}${value}` : ""
                }
            },

        ]

        return (
            <>
                {resultItems.length ?
                    <Paper style={{ width: '100%', height: '100%', boxShadow: 'none', overflow: 'hidden' }}>
                        <TableContainer style={{ height: '100%', boxShadow: 'none !important', }} >
                            <Table stickyHeader>
                                <TableHead
                                    className='tabel-header estimate-tabel-header'
                                >
                                    <TableRow style={{ height: '40px' }}>
                                        {_tabelHeader.filter(th => !th.disable).map((column) => (

                                            <TableCell
                                                key={column.id}
                                                align={column.align}
                                                style={{ minWidth: column.minWidth }}
                                                className='tabel-header-items'
                                            >
                                                {column.label}
                                            </TableCell>
                                        ))}
                                    </TableRow>
                                </TableHead>
                                <TableBody className='tabel-body'>
                                    {resultItems.filter(r => !r.disable).map((row, i) => (
                                        <TableRow
                                            hover
                                            role="checkbox"
                                            tabIndex={-1}
                                            key={row.id}
                                            className={`estimate-table-row`}
                                            style={{ height: '45px' }}
                                        >
                                            {_tabelHeader.map((column) => {
                                                const value = row[column.id];
                                                return (

                                                    column.disable ? '' :
                                                        <TableCell
                                                            key={column.id}
                                                            align={column.align}

                                                            className='estimate-table-row-item'
                                                        >
                                                            {column.format ? column.format(value) : value}
                                                        </TableCell>
                                                );
                                            })}
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Paper>
                    : <NoDataFound label={'No Items Found'} />}
            </>
        )
    }

    const [SmarkCard_Items, setSmarkCard_Items] = useState([])

    const HandleSmartCardClick = (id) => { }

    const HandleSmartCardSetup = (items) => {

        items = items || []

        let materials_cost = items.filter(itm => itm.type == "3" && itm.item_type == "1").reduce((prev, itm) => prev + parseFloat(itm.price || 0), 0)
        let labours_cost = items.filter(itm => itm.type == "3" && itm.item_type == "2").reduce((prev, itm) => prev + parseFloat(itm.price || 0), 0)
        let machines_cost = items.filter(itm => itm.type == "3" && itm.item_type == "3").reduce((prev, itm) => prev + parseFloat(itm.price || 0), 0)

        let smark_cards = [
            {
                id: 'lm-smart-top-cards-labours_cost',
                label: 'Labour Cost',
                value: labours_cost || "0",
                icon: Icons.general.users,
                unit_name: '₹',
                style: {
                    color: '#0065ff',
                    bg_linear_colors: {
                        start: '#e5efff',
                        end: '#e5f9ff'
                    },
                    border_color: '#c6d4dc',
                },
            },
            {
                id: 'lm-smart-top-cards-materials_cost',
                label: 'Material Cost',
                value: materials_cost || "0",
                icon: Icons.general.users,
                unit_name: '₹',
                style: {
                    color: '#33c87e',
                    bg_linear_colors: {
                        start: '#eaf9f2',
                        end: '#f2fef7'
                    },
                    border_color: '#ccd8d2',
                },
            },
            {
                id: 'lm-smart-top-cards-machines_cost',
                label: 'Machines Cost',
                value: machines_cost || "0",
                icon: Icons.general.users,
                unit_name: '₹',
                style: {
                    color: '#ff5b5b',
                    bg_linear_colors: {
                        start: '#ffeded',
                        end: '#fff9f9'
                    },
                    border_color: '#dcd1d1',
                },
            },
        ]

        setSmarkCard_Items(smark_cards)

    }


    return (
        <>

            {isLoading ?

                <Loading
                    props={{
                        isMainLogo: false,
                        isLabel: true
                    }} />

                : null}

            {warningAlert ?

                <SystemToastPopup
                    props={{
                        type: warningAlertType,
                        message: apiFailedMessage || "Error in Estimate BOQ",
                        callback: (confirmation) => setWarningAlert(false)
                    }} />

                : null}

            {
                ShowSideFilter ?
                    <SideFilters
                        props={
                            {
                                heading: 'Filters',
                                filters: SideFilterSettings,
                                close: HandleSideFilterClose,
                                updateCallback: HandleSideFilterUpdateCallback
                            }
                        }
                    />
                    : ''
            }

            <div className="project-project_estimate-main estimate-report-main">
                <div className="project_estimate-panel-content">

                    {SmarkCard_Items.length ?
                        <SmarkTopCards props={{
                            id: 'labourManagement-smart-top-cards',
                            cards: SmarkCard_Items || [],
                            callback: HandleSmartCardClick
                        }} />
                        : ''}
                    <div className="smart-panel-content-main">
                        <div className="report-result-sections">
                            <div className="report-result-sections-items">

                                <div
                                    style={{ display: 'none' }}
                                    className={`report-result-section ${selectedResultSection == 'estimate' ? 'report-result-section-active' : ''}`}
                                    onClick={() => HandleResultSection('estimate')}
                                >
                                    <div className="icon" dangerouslySetInnerHTML={{ __html: Icons.general.estimate }}></div>
                                    <div className="label">Estimate BOQ</div>
                                </div>
                                <div
                                    className={`report-result-section ${selectedResultSection == 'labour' ? 'report-result-section-active' : ''}`}
                                    onClick={() => HandleResultSection('labour')}
                                >
                                    <div className="icon" dangerouslySetInnerHTML={{ __html: Icons.general.manpower }}></div>
                                    <div className="label">Labour BOQ</div>
                                </div>
                                <div
                                    className={`report-result-section ${selectedResultSection == 'material' ? 'report-result-section-active' : ''}`}
                                    onClick={() => HandleResultSection('material')}
                                >
                                    <div className="icon" dangerouslySetInnerHTML={{ __html: Icons.general.material }}></div>
                                    <div className="label">Material BOQ</div>
                                </div>
                                <div
                                    className={`report-result-section ${selectedResultSection == 'machines' ? 'report-result-section-active' : ''}`}
                                    onClick={() => HandleResultSection('machines')}
                                >
                                    <div className="icon" dangerouslySetInnerHTML={{ __html: Icons.general.machines }}></div>
                                    <div className="label">Machines BOQ</div>
                                </div>
                            </div>

                        </div>
                        <div className="filter-section-main">
                            {isLeftFilter ?

                                < div className="left-filter-main">
                                    {Filters.left.map((item, i) => (

                                        item.id == 'estimate-download' ? '' :

                                            item.type == 'icon' ? <Icon_Filter props={item} />
                                                : item.type == 'button' ? <Button_Filter props={item} />
                                                    : item.isSearchBar && item.type == 'input' ?
                                                        <div className="search-filter">
                                                            <SearcBarInput
                                                                props={{
                                                                    id: item.id,
                                                                    value: item.value,
                                                                    placeholder: item.placeholder || "Search by name",
                                                                    setValue: (val) => {
                                                                        HandleFiltersEvent(val, item.id)
                                                                    },
                                                                    isIcon: item.isIcon,
                                                                    isLeft: item.isIconLeft,
                                                                    icon: item.icon || Icons.general.search
                                                                }}
                                                            />
                                                        </div>
                                                        : ''
                                    ))}
                                </div>
                                : ''}
                            {isRightFilter ?

                                < div className="right-filter-main">
                                    {Filters.right.map((item, i) => (

                                        item.id != 'estimate-download' && selectedResultSection != 'estimate' ? '' :

                                            item.type == 'icon' ? <Icon_Filter props={item} />
                                                : item.type == 'button' ? <Button_Filter props={item} />
                                                    : item.isSearchBar && item.type == 'input' ?
                                                        <div className="search-filter">
                                                            <SearcBarInput
                                                                props={{
                                                                    id: item.id,
                                                                    value: item.value,
                                                                    placeholder: item.placeholder || "Search by name",
                                                                    setValue: (val) => {
                                                                        HandleFiltersEvent(val, item.id)
                                                                    },
                                                                    isIcon: item.isIcon,
                                                                    isLeft: item.isIconLeft,
                                                                    icon: item.icon || Icons.general.search
                                                                }}
                                                            />
                                                        </div>
                                                        : ''
                                    ))}
                                </div>
                                : ''}
                        </div>

                        <div className="panel-content-sections">
                            {
                                selectedResultSection == 'estimate' ? RenderEstimateResult(estimateItems)
                                    : selectedResultSection == 'labour' ? RenderItemsResult(laboursResult)
                                        : selectedResultSection == 'material' ? RenderItemsResult(materialsResult)
                                            : selectedResultSection == 'machines' ? RenderItemsResult(machinesResult)
                                                : ''
                            }
                        </div>
                    </div>

                </div>
            </div>
        </>
    )

}

export default EstimateBOQ;