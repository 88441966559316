

import React, { useEffect, useState, useRef, useCallback, useMemo, StrictMode } from 'react'
import { Route, Routes, BrowserRouter, Link, useLocation, Navigate, Outlet, useNavigate, useParams } from 'react-router-dom';
import store from '../../redux/store'
import StoreHandler from '../../redux/StoreHandler'
import { useSelector, useDispatch } from 'react-redux'
import UserAction from '../../redux/action/userAction'

import ReverseProxy from '../../config/reverseProxy'
import SettingsHandler from '../../Handlers/Settings/Settings';
import EstimateHandler from '../../Handlers/Estimate/Estimate';
import ProposalHandler from '../../Handlers/Proposal/Proposal';
import Icons from '../../assets/Icons'
import Images from '../../assets/Images'
import { CostInput, TextInput, SelectInput, DateTimeInput, RadioInput } from '../../components/Inputs'
import Utils from '../../utils'
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

import { PDFExport, savePDF } from '@progress/kendo-react-pdf';

import AlertPopup from '../../components/AlertPopup'
import Loading from '../../components/Loading'
import SystemToastPopup from '../../components/SystemToastPopup'

import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { withStyles, makeStyles } from "@mui/material/styles";

const PreviewProposal = ({ props }) => {

    const { projectId, proposalId } = useParams();

    const { menuType = 'create', close, proposalLoaded, onSettingsChange, proposaldetials, saveCallback } = props

    const [project_id, setProject_id] = useState('')
    const [project_name, setProject_name] = useState('')
    const [estimate_id, setEstimate_id] = useState('')

    const [isLoading, setIsLoading] = useState(false)
    const [warningAlert, setWarningAlert] = useState(false)
    const [warningAlertType, setWarningAlertType] = useState('warning')
    const [apiFailedMessage, setApiFailedMessage] = useState("Error in Server")

    const navigate = useNavigate()
    const store = useSelector((store) => store)
    const dispatch = useDispatch()
    const { updateState } = new UserAction

    const storeHandler = new StoreHandler({
        dataStack: ['company', 'clients', 'terms_conditions', 'field_settings', 'finance_settings', `project-${projectId}`],
        store: store,
        dispatch: dispatch,
        updateState: updateState,
    })

    const LoadStoreData = async () => {
        setIsLoading(true)
        let resolved = await storeHandler.init()
        setIsLoading(false)
    }
    const pdfRef = useRef()
    const estimateHandler = new EstimateHandler()
    const proposalHandler = new ProposalHandler()
    const settingsHandler = new SettingsHandler()

    const [unitsOptions, setUnitsOptions] = useState([])
    const [currency_type, setCurrency_type] = useState('₹')

    const CompanyProfile = store.user.company || undefined
    const isCompany = Object.keys(CompanyProfile).length > 0

    const [ShowWatermark, setShowWatermark] = useState(false)

    const [companyImg, setCompanyImg] = useState('')
    const [companyName, setCompanyName] = useState('N/A')
    const [proposalName, setProposalName] = useState('N/A')
    const [projectName, setProjectName] = useState('N/A')
    const [projectArea, setProjectArea] = useState('N/A')
    const [projectStatus, setProjectStatus] = useState('N/A')
    const [projectID, setProjectID] = useState('N/A')
    const [clientName, setClientName] = useState('N/A')
    const [clientEmail, setClientEmail] = useState('N/A')
    const [clientNumber, setClientNumber] = useState('N/A')
    const [projectAddress, setProjectAddress] = useState('N/A')
    const [dateFormat, setDateFormat] = useState('DD/MM/YYYY')

    const [id, setId] = useState('')
    const [name, setName] = useState('')
    const [proposalID, setProposalID] = useState('')
    const [status, setStatus] = useState('')
    const [introduction_text, setIntroduction_text] = useState('')
    const [closing_text, setClosing_text] = useState('')
    const [comments, setComments] = useState('')
    const [EstimatePricing, setEstimatePricing] = useState({})

    const [Phase_id, setPhase_id] = useState('')
    const [Phase_name, setPhase_name] = useState('')
    const [Client_id, setClient_id] = useState('')
    const [Client_name, setClient_name] = useState('')

    const [proposalStatusOptions, setProposalStatusOptions] = useState([
        {
            value: 0,
            label: "Pending",
            isDefault: false
        },
        {
            value: 1,
            label: "Approved",
            isDefault: false
        },
        {
            value: 2,
            label: "Rejected",
            isDefault: false
        },
    ])

    const [termsConditionOptions, setTermsConditionOptions] = useState([])
    const [termsConditionId, setTermsConditionId] = useState("")
    const [termsConditionValue, setTermsConditionValue] = useState("")

    const [paymentScheduleId, setPaymentScheduleId] = useState("")
    const [paymentSplits, setPaymentSplits] = useState([])




    const [overHeads_options, setOverHeads_options] = useState([])
    const [insurances_options, setInsurances_options] = useState([])

    const [overHeadsOptions, setOverHeadsOptions] = useState(overHeads_options)
    const [insurancesOptions, setInsurancesOptions] = useState(insurances_options)

    const [builderFinalCost, setBuilderFinalCost] = useState(0)
    const [allowanceFinalCost, setAllowanceFinalCost] = useState(0)
    const [markupsFinalCost, setMarkupsFinalCost] = useState(0)
    const [taxFinalCost, setTaxFinalCost] = useState(0)
    const [overHeadValue, setOverHeadValue] = useState(0)
    const [insuranceValue, setInsuranceValue] = useState(0)
    const [adjustmentValue, setAdjustmentValue] = useState(0)
    const [clientFinalCost, setClientFinalCost] = useState(0)
    const [summaryFinalTotal, setSummaryFinalTotal] = useState(0)

    const [summaryOverHeads, setSummaryOverHeads] = useState([])
    const [summaryInsurance, setSummaryInsurance] = useState([])


    const [summaryItems, setSummaryItems] = useState([])
    const [summaryTotalItems, setSummaryTotalItems] = useState([])

    const [selectedLayoutPresets, setSelectedLayoutPresets] = useState('')
    const [layoutPresets, setLayoutPresets] = useState([
        {
            value: 0,
            label: "Pending",
            isDefault: false
        },
        {
            value: 1,
            label: "Approved",
            isDefault: false
        },
        {
            value: 2,
            label: "Rejected",
            isDefault: false
        },
    ])

    const HandleDownloadBtn = () => {

        pdfRef.current.save();
        // savePDF(pdfRef.current, { paperSize: "A4" });
    }
    const HandleSaveBtn = () => {

        saveCallback()
    }

    const getNextProjectID = async () => {

        let response = await proposalHandler.getNextProposalIDHandler()

        if (response && response.success) {

            let { proposalId } = response.data

            if (proposalId) setProposalID(proposalId)

        } else {
            setWarningAlert(true)
            setWarningAlertType('error')
            setApiFailedMessage(`Error in getting next proposal id`)
        }
    }


    const [tabelHeader, setTabelHeader] = useState([

        {
            id: 'select',
            label: 'Select',
            maxWidth: 30,
            disable: true,
        },

        {
            id: 'name',
            label: 'Name',
            width: 300,
            minWidth: 300,
            maxWidth: 300,
            disable: false,
        },
        {
            id: 'cost_type',
            label: 'Cost Type',
            width: 70,
            minWidth: 70,
            maxWidth: 70,
            disable: false,

        },

        {
            id: 'quantity',
            label: 'Quantity',
            width: 70,
            minWidth: 70,
            maxWidth: 70,
            disable: false,
        },
        {
            id: 'unitrate',
            label: 'Unit rate',
            width: 70,
            minWidth: 70,
            maxWidth: 70,
            disable: false,
        },
        {
            id: 'builder_price',
            label: 'Prime cost',
            width: 70,
            minWidth: 70,
            maxWidth: 70,
            disable: false,
        },
        {
            id: 'markup_amount',
            label: 'Markup',
            width: 70,
            minWidth: 70,
            maxWidth: 70,
            disable: false,
        },
        {
            id: 'tax_amount',
            label: 'Tax',
            width: 70,
            minWidth: 70,
            maxWidth: 70,
            disable: false,
        },
        {
            id: 'price',
            label: 'Total',
            width: 70,
            minWidth: 70,
            maxWidth: 70,
            disable: false,
        },
    ])
    const [tableSelectAll, setTableSelectAll] = useState(false)


    const [estimateItemsOrginal, setEstimateItemsOrginal] = useState([])
    const [estimateItems, setEstimateItems] = useState([])
    const AddBtnOptions = [
        {
            icon: Icons.general.group,
            label: 'Group',
            type: 'group',
            id: "group"
        },
        {
            icon: Icons.general.work,
            label: 'Work Template',
            type: 'work_template',
            id: 'work_template'
        },
        {
            icon: Icons.general.labour,
            label: 'Labour',
            type: 'item',
            id: "labour"
        },
        {
            icon: Icons.general.material,
            label: 'Materials',
            type: 'item',
            id: "material"
        },
        {
            icon: Icons.general.machines,
            label: 'Machines',
            type: 'item',
            id: "machine"
        },
        {
            icon: Icons.general.fee,
            label: 'Fee',
            type: 'item',
            id: "fee"
        },
        {
            icon: Icons.general.allowance,
            label: 'Allowances',
            type: 'item',
            id: "allowance"
        },
        {
            icon: Icons.general.work,
            label: 'Work',
            type: 'work',
            id: 'work'
        },
    ]

    const HandleSelectAllEvent = (value) => {
        setTableSelectAll(value)

        let _estimateItems = [...estimateItems]
        let _estimateItemsOrginal = [...estimateItemsOrginal]

        const selectItem = (items, value) => {

            items = items.map(item => {

                let orginal_item_index = _estimateItemsOrginal.findIndex(itm => itm.id == item.id)
                if (orginal_item_index != -1) {

                    _estimateItemsOrginal[orginal_item_index].selected = value
                }

                item.selected = value
                if (Array.isArray(item.sub_items)) item.sub_items = selectItem(item.sub_items, value)
                return item
            })

            return items
        }

        _estimateItems = selectItem(_estimateItems, value)


        setEstimateItems(_estimateItems)
        setEstimateItemsOrginal(_estimateItemsOrginal)
    }
    const HandleSelectEvent = (group_id, parent_id, value) => {

        let _estimateItems = [...estimateItems]
        let _estimateItemsOrginal = [...estimateItemsOrginal]

        const selectItem = (items, value) => {

            items = items.map(item => {

                item.selected = value
                if (item.type == 'group' && Array.isArray(item.sub_items)) item.sub_items = selectItem(item.sub_items, value)
                return item
            })

            return items
        }

        const findAndUpdateGroup = (group_id, parent_id, _estimateItems, value) => {

            _estimateItems = _estimateItems.map((item, idx) => {

                if (item.id == group_id && item.parent_id == parent_id) {
                    item.selected = value

                    let orginal_item_index = _estimateItemsOrginal.findIndex(itm => itm.id == item.id)

                    if (orginal_item_index != -1) _estimateItemsOrginal[orginal_item_index].selected = value

                    if (item.type == 'group' && Array.isArray(item.sub_items)) {
                        item.sub_items = selectItem(item.sub_items, value)
                    }
                }
                else if (item.type == 'group' && Array.isArray(item.sub_items)) {
                    item.sub_items = findAndUpdateGroup(group_id, parent_id, item.sub_items, value)
                }

                return item
            })

            return _estimateItems
        }

        _estimateItems = findAndUpdateGroup(group_id, parent_id, _estimateItems, value)
        setEstimateItems(_estimateItems)
        setEstimateItemsOrginal(_estimateItemsOrginal)
    }


    const HandlePricingItems = (data) => {

        if (!Object.keys(data).length) return

        setEstimatePricing(data || {})

        let { id, overhead_items, insurance_items } = data

        overhead_items = overhead_items || []
        insurance_items = insurance_items || []

        setEstimate_id(id)

        if (Array.isArray(overhead_items)) {

            setSummaryOverHeads(overhead_items)

            let _overHeads_options = overHeads_options.filter(itm => overhead_items.filter(item => item.id == itm.id).length == 0)
            setOverHeadsOptions(_overHeads_options)
        }
        if (Array.isArray(insurance_items)) {

            setSummaryInsurance(insurance_items)

            let _insurances_options = insurances_options.filter(itm => insurance_items.filter(item => item.id == itm.id).length == 0)
            setInsurancesOptions(_insurances_options)

        }
    }
    const getSubItem = (items, parentid) => {

        let subItems = []

        let getItems = (items, parentid) => {

            if (!Array.isArray(items)) return

            let _items = items.filter(itm => itm.parent_id == parentid)

            subItems.push(..._items)

            _items.forEach(itm => getItems(items, itm.id))
        }

        getItems(items, parentid)

        return subItems
    }

    const getEstimateItemTypeEenem = (type) => {

        if (type == '1') return 'group'
        else if (type == '2') return 'work_template'
        else if (type == '3') return 'item'
        else if (type == '4') return 'work'
        else return ''

    }
    const getEstimateItemCategoryEenem = (type) => {

        if (type == '1') return 'material'
        else if (type == '2') return 'labour'
        else if (type == '3') return 'machine'
        else if (type == '4') return 'subcontractor'
        else if (type == '5') return 'fee'
        else if (type == '6') return 'allowance'
        else return ''

    }
    const getEstimateItemStatusEenem = (type) => {

        if (type == '0') return 'incomplete'
        else if (type == '1') return 'completed'
        else if (type == '2') return 'hold'
        else if (type == '3') return 'stopped'
        else return ''
    }
    const getFormatedEstimateItem = (item) => {

        return {
            expanded: true,
            selected: item.selected || false,
            disable: false,

            parent_id: item.parent_id,
            parent_name: item.parent_name,
            id: item.id,
            name: item.name,
            description: item.description,

            costcode_id: item.costcode_id || "",
            costcode_name: item.costcode_name || "",


            co_efficient: parseFloat(item.co_efficient || 0) || 0,
            per_co_efficient_cost: parseFloat(item.per_co_efficient_cost || 0) || 0,

            type: getEstimateItemTypeEenem(item.type),
            item_type: item.type == '1' ? 'group' : getEstimateItemCategoryEenem(item.item_type),
            cost_type: getEstimateItemCategoryEenem(item.item_type) || "",
            _status: item.status,
            status: getEstimateItemStatusEenem(item.status),

            quantity: parseFloat(item.quantity || 0) || 0,
            unitrate: parseFloat(item.unitrate || 0) || 0,
            builder_price: parseFloat(item.builder_price || 0) || 0,
            price: parseFloat(item.price || 0) || 0,


            unit_id: item.unit_id || "",
            unit_name: item.unit_name || "",

            markup_id: item.markup_id || "",
            markup_name: item.markup_name || "",
            markup_value: parseFloat(item.markup_value || 0) || 0,
            markup_value_type: item.markup_value_type || "",
            markup_amount: parseFloat(item.markup_amount || 0) || 0,

            tax_id: item.tax_id || "",
            tax_name: item.tax_name || "",
            tax_value_type: item.tax_value_type || "",
            tax_value: parseFloat(item.tax_value || 0) || 0,
            tax_amount: parseFloat(item.tax_amount || 0) || 0,

            sub_items: []
        }

    }
    const getOrderedTableItems = (items = []) => {
        const groupedItems = {};

        for (const item of items) {

            const parent_id = item.parent_id;

            if (parent_id != null) {

                if (!groupedItems[parent_id]) groupedItems[parent_id] = [];

                groupedItems[parent_id].push(item);
            }
        }

        for (const item of items) {
            const itemId = item.id;
            if (groupedItems[itemId]) {

                // const orderedItems = groupedItems[itemId].sort((a, b) => {
                //     if (a.type != 'item' && b.type == 'item') return -1;
                //     if (a.type == 'item' && b.type != 'item') return 1;
                //     return 0;
                // })

                item.sub_items = groupedItems[itemId]
            }
        }

        const orderedItems = items.sort((a, b) => {
            if (a.type != 'item' && b.type == 'item') return -1;
            if (a.type == 'item' && b.type != 'item') return 1;
            return 0;
        })

        return orderedItems.filter(d => d.parent_id == "")

    }

    const setUpEstimateItems = (data) => {

        if (!Array.isArray(data)) return

        let items = data.map(d => {

            let item = getFormatedEstimateItem(d)
            let subItems = getSubItem(data, d.id).map(itm => getFormatedEstimateItem(itm))

            let tax_amount = item.tax_amount
            let markup_amount = item.markup_amount
            let builder_price = item.builder_price
            let price = item.price

            let subItems_Total = subItems.reduce((prev, val, ind) => {

                return {
                    tax_amount: prev.tax_amount + val.tax_amount,
                    markup_amount: prev.markup_amount + val.markup_amount,
                    builder_price: prev.builder_price + val.builder_price,
                    price: prev.price + val.price,
                }

            }, { tax_amount: 0, markup_amount: 0, builder_price: 0, price: 0 })

            tax_amount = parseFloat(tax_amount + (subItems_Total.tax_amount || 0)).toFixed(2)
            markup_amount = parseFloat(markup_amount + (subItems_Total.markup_amount || 0)).toFixed(2)
            builder_price = parseFloat(builder_price + (subItems_Total.builder_price || 0)).toFixed(2)
            price = parseFloat(price + (subItems_Total.price || 0)).toFixed(2)

            item._tax_amount = tax_amount || ""
            item._tax_percent = ((tax_amount / builder_price) * 100 || 0).toFixed(2)
            item._markup_amount = markup_amount || ""
            item._markup_percent = ((markup_amount / builder_price) * 100 || 0).toFixed(2)
            item._builder_price = builder_price || ""
            item._price = price || ""
            item._unitrate = item.unitrate || ""
            item._quantity = item.quantity || ""

            item.tax_amount = tax_amount ?
                `   <div class='proposal-amount'>
                        <span class='proposal-currency-small'>${Icons.general.inr}</span>
                        <span class='inner-value'>${tax_amount}</span>
                    </div>
                ` : ""
            item.markup_amount = markup_amount ?
                `   <div class='proposal-amount'>
                        <span class='proposal-currency-small'>${Icons.general.inr}</span>
                        <span class='inner-value'>${markup_amount}</span>
                    </div>
                ` : ""
            item.builder_price = builder_price ?
                `   <div class='proposal-amount'>
                        <span class='proposal-currency-small'>${Icons.general.inr}</span>
                        <span class='inner-value'>${builder_price}</span>
                    </div>
                ` : ""
            item.price = price ?
                `   <div class='proposal-amount'>
                        <span class='proposal-currency-small'>${Icons.general.inr}</span>
                        <span class='inner-value'>${price}</span>
                    </div>
                ` : ""
            item.unitrate = item.unitrate ?
                `   <div class='proposal-amount'>
                        <span class='proposal-currency-small'>${Icons.general.inr}</span>
                        <span class='inner-value'>${item.unitrate}</span>
                    </div>
                ` : ""

            item.quantity = item.quantity ? `${item.quantity}${item.unit_name || ""}` : ""


            let type_option = AddBtnOptions.filter(opt => {

                if (opt.type == 'group' && item.type == 'group') return true
                else if (opt.type != 'group' && item.item_type == opt.id) return true
                else return false
            })

            type_option = type_option.length ? type_option[0] : undefined
            item.type_option = type_option

            return item

        })

        items.forEach((item, i) => {

            if (!item.parent_id && !item.isSubTotal && item.type != 'item') {

                let _item = {
                    expanded: true,
                    selected: true,
                    disable: false,
                    isSubTotal: true,
                    parent_id: item.id,
                    parent_name: item.name,
                    id: item.id + 'subtotal',
                    name: `${item.name} Subtotal`,
                    description: "",

                    costcode_id: "",
                    costcode_name: "",

                    type: "subtotal",
                    item_type: "",
                    cost_type: "",
                    _status: item.status,
                    status: "",

                    quantity: "",
                    unitrate: "",
                    builder_price: item.builder_price,
                    price: item.price,


                    unit_id: "",
                    unit_name: "",

                    markup_id: item.markup_id || "",
                    markup_name: item.markup_name || "",
                    markup_value: item.markup_value,
                    markup_value_type: item.markup_value_type || "",
                    markup_amount: item.markup_amount,

                    tax_id: item.tax_id || "",
                    tax_name: item.tax_name || "",
                    tax_value_type: item.tax_value_type || "",
                    tax_value: item.tax_value,
                    tax_amount: item.tax_amount,

                    sub_items: []
                }
                items.push(_item)
            }

        })

        items = getOrderedTableItems(items)

        setEstimateItems(items)
        setEstimateItemsOrginal(data)
    }
    const LoadEstimateItems = async () => {

        let filter = {
            projectId: projectId
        }

        setIsLoading(true)

        let response = await estimateHandler.getEstimateItemsHandler(filter)
        let estimation_response = await estimateHandler.getProjectEstimationHandler(filter)
        setIsLoading(false)

        if (response.success && estimation_response.success) {

            HandlePricingItems(estimation_response.data)

            let { data } = response

            if (!Array.isArray(data)) return

            setUpEstimateItems(data)

        }
        else {
            setWarningAlert(true)
            setWarningAlertType('error')
            setApiFailedMessage(`Error in getting estimate items`)
        }
    }
    const CreateEstimateTableItem = (row, idx, group_number) => {


        let { id, disable, selected, type, parent_id, isSubTotal, sub_items, expanded, _status } = row

        sub_items = sub_items || []

        let description = row.description //Utils.getInnerTextFromHTML(row.description)

        let subTotalSetting = getLayoutItem(`estimate`).item.dropdownOptions[1].checklists.filter(c => c.id == 'group_subtotal')[0]
        let issubTotalEnabled = subTotalSetting ? subTotalSetting.value : true

        let groupNumberSetting = getLayoutItem(`estimate`).item.dropdownOptions[1].checklists.filter(c => c.id == 'group_number')[0]
        let isGroupNumberEnabled = groupNumberSetting ? groupNumberSetting.value : true

        let descriptionSettings = getLayoutItem(`estimate`).item.dropdownOptions[0].checklists.filter(c => c.id == 'description')[0]
        let isDescriptionEnabled = descriptionSettings ? descriptionSettings.value : true
        return (
            <>
                {!disable && selected ?
                    isSubTotal && !issubTotalEnabled ? '' :
                        <>
                            <TableRow
                                role="checkbox"
                                tabIndex={-1}
                                key={id}
                                className={`estimate-table-row ${isSubTotal ? 'estimate-table-row-subtotal' : ''} ${type != 'item' ? !parent_id ? 'estimate-table-row-group' : 'estimate-table-row-subgroup' : ''}`}
                            >
                                {
                                    tabelHeader.filter(th => !th.disable).filter(th => {
                                        let estimate_setting = getLayoutItem(`estimate`).item.dropdownOptions[0].checklists
                                        let isColumnEnabled = estimate_setting.filter(es => es.id == th.id)
                                        isColumnEnabled = isColumnEnabled.length ? isColumnEnabled[0].value : !th.disable
                                        return isColumnEnabled

                                    }).map((column) => {
                                        const value = row[column.id];
                                        return (

                                            column.disable ? '' :
                                                column.id == 'select' && !isSubTotal ?


                                                    <TableCell
                                                        key={column.id}
                                                        align={column.align}
                                                        className='estimate-table-row-item'

                                                    >
                                                        <RadioInput props={{
                                                            value: row.selected || false,
                                                            isIcon: false,
                                                            icon: "",
                                                            inputType: "toggle",
                                                            name: "table-default-toggle",
                                                            setValue: (value) => HandleSelectEvent(id, parent_id, value)
                                                        }} />

                                                    </TableCell>

                                                    :
                                                    <TableCell
                                                        key={column.id}
                                                        align={column.align}
                                                        className='estimate-table-row-item'
                                                        style={{
                                                            width: column.width,
                                                            maxWidth: column.maxWidth,
                                                            minWidth: column.minWidth
                                                        }}
                                                    >
                                                        {
                                                            row.isSubTotal ?
                                                                <div
                                                                    dangerouslySetInnerHTML={{ __html: value }}
                                                                ></div>
                                                                :
                                                                column.id == 'name' ?
                                                                    <div style={{
                                                                        height: '100%', display: 'flex', flexDirection: 'column', gap: '3px'
                                                                    }}
                                                                    >
                                                                        <span
                                                                            style={{
                                                                                width: '100%',
                                                                                height: '30px',
                                                                                display: 'flex',
                                                                                alignItems: 'center',
                                                                                fontSize: '18px',
                                                                            }}
                                                                        >
                                                                            {isGroupNumberEnabled ? `${group_number}.` : ''}&nbsp;{column.format ? column.format(value) : value}
                                                                        </span>
                                                                        {isDescriptionEnabled && description ?
                                                                            <span
                                                                                className='estimate-table-description'
                                                                                dangerouslySetInnerHTML={{ __html: description || "" }}>
                                                                            </span> : ''}
                                                                    </div>
                                                                    :
                                                                    <div
                                                                        dangerouslySetInnerHTML={{ __html: column.format ? column.format(value) : value }}
                                                                    ></div>


                                                        }
                                                    </TableCell>
                                        );
                                    })}
                            </TableRow>
                            {sub_items.map((item, idxx) => (
                                CreateEstimateTableItem(item, idxx, `${group_number}.${idx + 1}`)
                            ))}
                        </>
                    : ''}
            </>
        )
    }

    const LoadProposal = async () => {


        let filter = {
            proposalId: proposalId,
            projectId: projectId
        }

        let _proposaldata = undefined

        if (proposalLoaded && proposaldetials && Object.keys(proposaldetials).length) {

            _proposaldata = proposaldetials

        } else {

            setIsLoading(true)

            let response = await proposalHandler.getProposalsHandler(filter)
            setIsLoading(false)

            if (response.success) {
                let { data } = response

                _proposaldata = data[0]
            }

        }

        if (_proposaldata && Object.keys(_proposaldata).length) {

            console.log(_proposaldata, '_proposaldata');

            let {
                id,
                proposalID,
                name,
                status,
                phase_id,
                phase_name,
                client_id,
                client_name,
                introduction_text,
                closing_text,
                terms_condition_id,
                terms_condition,
                comments,
                estimate_items,
                settings_layout,
                estimate_pricing,
                payment_schedule_id,
                payment_schedule
            } = _proposaldata



            setId(id)
            setProposalID(proposalID)
            setProposalName(name)
            setPhase_id(phase_id)
            setPhase_name(phase_name)
            setClient_id(client_id)
            setClient_name(client_name)
            setStatus(status)
            setIntroduction_text(introduction_text)
            setClosing_text(closing_text)
            setTermsConditionId(terms_condition_id)
            setTermsConditionValue(terms_condition)
            setComments(comments)
            setLayoutsettingsItems(settings_layout)
            setEstimatePricing(estimate_pricing)
            setPaymentScheduleId(payment_schedule_id)
            setPaymentSplits(payment_schedule)

            HandlePricingItems(estimate_pricing || {})


            setUpEstimateItems(estimate_items)
        }

    }

    useEffect(() => {

        if (menuType == 'create') {
            getNextProjectID()
            LoadEstimateItems()
        }
        else {
            LoadProposal()
        }

        LoadStoreData()

    }, [])

    useEffect(() => {

        if (store && store.user) {

            let { company, settings, clients, selected_project } = store.user

            if (selected_project) {
                let { name, status, area, unit_id, unit_name, type_id, type_name, address, client_id, client_name } = selected_project



                setProjectName(name || 'N/A')
                setProjectArea(`${area}${unit_name || ""}` || 'N/A')
                setProjectID(projectId)
                setProjectAddress(address || "N/A")
                setClientName(client_name || "N/A")


                if (clients && Array.isArray(clients) && clients.length) {

                    let _client = clients.filter(opt => opt.id == client_id)[0]

                    if (_client) {
                        let { name, email, phone } = _client

                        setClientName(name || 'N/A')
                        setClientEmail(email || 'N/A')
                        setClientNumber(phone || 'N/A')
                    }
                }
            }

            if (company) {
                let { name, logo_img, currency_setting, date_format_setting, timezone, temperature_setting } = company

                const profileImgURL = logo_img ? `data:image/png;base64,${logo_img}` : ''

                setCompanyImg(profileImgURL)
                setCompanyName(name || 'N/A')
                setDateFormat(date_format_setting || 'DD/MM/YYYY')
                if (currency_setting) setCurrency_type(currency_setting)
            }

            if (settings && Array.isArray(settings.terms_conditions) && settings.terms_conditions.length) {



                let terms_conditions = settings.terms_conditions.map(tc => {
                    return {
                        id: tc.id,
                        value: tc.id,
                        label: tc.name,
                        body: tc.body,
                        isDefault: false,
                        document: tc.document || []
                    }
                })

                setTermsConditionOptions([
                    {
                        id: "none",
                        value: "none",
                        label: "None",
                        body: "",
                        isDefault: false,
                        document: []
                    },
                    ...terms_conditions
                ])
            }

            if (settings && Array.isArray(settings.field_settings) && settings.field_settings.length) {

                let { project_statuses, project_types, units } = settings.field_settings[0]

                units = units.filter(opt => opt.isActive).map((opt) => {
                    return {
                        value: opt.id,
                        label: opt.name,
                        isDefault: opt.isDefault || false
                    }
                })

                setUnitsOptions(units || [])

            }
            if (settings && Array.isArray(settings.finance_settings) && settings.finance_settings.length) {

                let { taxes, markups, overheads, insurance } = settings.finance_settings[0]

                overheads = overheads.filter(opt => opt.isActive).map((opt) => {
                    return {
                        id: opt.name,
                        value: parseFloat(opt.value || 0),
                        value_type: opt.value_type,
                        label: opt.name,
                        isDefault: opt.isDefault || false
                    }
                })


                setOverHeads_options(overheads || [])


                insurance = insurance.filter(opt => opt.isActive).map((opt) => {
                    return {
                        id: opt.name,
                        value: parseFloat(opt.value || 0),
                        value_type: opt.value_type,
                        label: opt.name,
                        isDefault: opt.isDefault || false
                    }
                })

                setInsurances_options(insurance || [])


                let _summaryOptions = [
                    {
                        id: "overhead-summary-option",
                        head: {
                            icon: Icons.general.percentage,
                            label: "OverHead",
                        },
                        items: overheads,
                        selected: true,
                        order: 1
                    },
                    {
                        id: "insurance-summary-option",
                        head: {
                            icon: Icons.general.protection,
                            label: "Insurance",
                        },
                        items: insurance,
                        selected: true,
                        order: 2
                    }
                ]


                setSummaryItems(_summaryOptions)

            }
        }

    }, [store])


    useEffect(() => {

        let total_project_price = 0
        let total_project_allowance = 0
        let total_project_markup = 0
        let total_project_tax = 0
        let total_project_overhead = 0
        let total_project_insurance = 0
        let total_project_client = 0


        if (Array.isArray(estimateItemsOrginal)) {


            estimateItemsOrginal.filter(d => d.selected).forEach((data, i) => {

                let { builder_price, item_type, tax_amount, markup_amount } = data

                if (item_type == "5" || item_type == "6") total_project_allowance += parseFloat(builder_price || 0)
                else {

                    total_project_price += parseFloat(builder_price || 0)
                    total_project_markup += parseFloat(markup_amount || 0)
                    total_project_tax += parseFloat(tax_amount || 0)
                }
            })
        }

        if (Array.isArray(summaryOverHeads)) {

            total_project_overhead = summaryOverHeads.reduce((prev, val, ind) => {

                let { value, value_type } = val

                let amount = 0

                if (value_type == '%') amount = (parseFloat(value) * total_project_price) / 100
                else amount = parseFloat(value)

                return prev + amount

            }, 0)
        }

        if (Array.isArray(summaryInsurance)) {

            total_project_insurance = summaryInsurance.reduce((prev, val, ind) => {
                let { value, value_type } = val

                let amount = 0

                if (value_type == '%') amount = (parseFloat(value) * total_project_price) / 100
                else amount = parseFloat(value)

                return prev + amount

            }, 0)
        }
        total_project_client = parseFloat(total_project_price + total_project_allowance + total_project_markup + total_project_tax + total_project_overhead + total_project_insurance).toFixed(2)

        setBuilderFinalCost(total_project_price)
        setAllowanceFinalCost(total_project_allowance)
        setMarkupsFinalCost(total_project_markup)
        setTaxFinalCost(total_project_tax)
        setOverHeadValue(total_project_overhead)
        setInsuranceValue(total_project_insurance)
        setClientFinalCost(total_project_client)



        let _summaryTotalOptions = [
            {
                id: 'builderFinalCost',
                label: "Prime Fixed Cost",
                value: total_project_price,
                value_type: currency_type,
                enabled: true,
                order: 1,
            },
            {
                id: 'allowanceFinalCost',
                label: "Allowances",
                value: total_project_allowance,
                value_type: currency_type,
                enabled: true,
                order: 2,
            },
            {
                id: 'overHeadValue',
                label: "Overheads",
                value: total_project_overhead,
                value_type: currency_type,
                enabled: true,
                order: 3,
            },
            {
                id: 'insuranceValue',
                label: "Insurances",
                value: total_project_insurance,
                value_type: currency_type,
                enabled: true,
                order: 4,
            },
            {
                id: 'markupsFinalCost',
                label: "Markup",
                value: total_project_markup,
                value_type: currency_type,
                enabled: true,
                order: 5,
            },
            {
                id: 'taxFinalCost',
                label: "Tax",
                value: total_project_tax,
                value_type: currency_type,
                enabled: true,
                order: 6,
            },
            {
                id: 'adustmentValue',
                label: "Adjust",
                value: adjustmentValue,
                value_type: currency_type,
                enabled: true,
                order: 7,
            },
        ]

        setSummaryTotalItems(_summaryTotalOptions)

        setBuilderFinalCost(parseFloat(total_project_price || 0))
        setSummaryFinalTotal(parseFloat(total_project_client || 0))



    }, [estimateItemsOrginal, summaryOverHeads, summaryInsurance])

    const [layoutsettingsOpen, setLayoutsettingsOpen] = useState(false)
    const [layoutsettingsItemSelected, setLayoutsettingsItemSelected] = useState(undefined)
    const [layoutsettingsItems, setLayoutsettingsItems] = useState([
        {
            id: 'introduction_text',
            isdragging: false,
            enabled: true,
            isDropdown: false,
            dropdownOptions: [],
            label: "Introduction Text",
            icon: Icons.general.description
        },
        {
            id: 'estimate',
            isdragging: false,
            enabled: true,
            isDropdown: true,
            dropdownOptions: [
                {
                    id: 'show_itemized',
                    title: 'Show itemized',
                    isTitle: true,
                    isToggle: true,
                    enabled: true,

                    checklists: [
                        {
                            id: 'description',
                            label: 'Description',
                            value: true,
                        },
                        {
                            id: 'cost_type',
                            label: 'Cost type',
                            value: false
                        },
                        {
                            id: 'quantity',
                            label: 'Quantity',
                            value: true
                        },
                        {
                            id: 'unitrate',
                            label: 'Unit rate',
                            value: true
                        },
                        {
                            id: 'builder_price',
                            label: 'Prime cost',
                            value: true
                        },
                        {
                            id: 'markup_amount',
                            label: 'Markup',
                            value: true
                        },
                        {
                            id: 'tax_amount',
                            label: 'Tax',
                            value: true
                        },
                        {
                            id: 'price',
                            label: 'Total',
                            value: true
                        },

                    ]
                },
                {
                    id: 'other',
                    title: 'Other',
                    isTitle: true,
                    isToggle: true,
                    enabled: true,

                    checklists: [
                        {
                            id: 'group_subtotal',
                            label: 'Show Subtotal',
                            value: true
                        },
                        {
                            id: 'group_number',
                            label: 'Group numbering',
                            value: true
                        },

                    ]
                },
            ],
            label: "Estimate",
            icon: Icons.general.estimate,
        },
        {
            id: 'allowances',
            isdragging: false,
            enabled: true,
            isDropdown: false,
            dropdownOptions: [],
            label: "Allowances",
            icon: Icons.general.allowances_icon
        },
        {
            id: 'overheads',
            isdragging: false,
            enabled: true,
            isDropdown: false,
            dropdownOptions: [],
            label: "Overheads",
            icon: Icons.general.percentage
        },
        {
            id: 'insurances',
            isdragging: false,
            enabled: true,
            isDropdown: false,
            dropdownOptions: [],
            label: "Insurances",
            icon: Icons.general.protection
        },
        {
            id: 'summary',
            isdragging: false,
            enabled: true,
            isDropdown: true,
            dropdownOptions: [
                {
                    id: 'summary',
                    title: '',
                    isTitle: false,
                    isToggle: false,
                    enabled: false,

                    checklists: [
                        {
                            id: 'builderFinalCost',
                            label: 'Prime Fixed Cost',
                            value: true
                        },
                        {
                            id: 'allowanceFinalCost',
                            label: 'Allowances',
                            value: true
                        },
                        {
                            id: 'overHeadValue',
                            label: 'Overheads',
                            value: true
                        },
                        {
                            id: 'insuranceValue',
                            label: 'Insurance',
                            value: true
                        },
                        {
                            id: 'markupsFinalCost',
                            label: 'Markup',
                            value: true
                        },
                        {
                            id: 'taxFinalCost',
                            label: 'Tax',
                            value: true
                        },
                        {
                            id: 'adustmentValue',
                            label: 'Adjust',
                            value: true
                        },
                        {
                            id: 'price',
                            label: 'Total',
                            value: true
                        },

                    ]
                },
            ],
            label: "Summary",
            icon: Icons.general.summary
        },
        {
            id: 'payment_schedule',
            isdragging: false,
            enabled: true,
            isDropdown: false,
            dropdownOptions: [],
            label: "Payment Schedule",
            icon: Icons.general.pay_schedule
        },
        {
            id: 'closing_text',
            isdragging: false,
            enabled: true,
            isDropdown: false,
            dropdownOptions: [],
            label: "Closing Text",
            icon: Icons.general.description
        },
        {
            id: 'terms_conditions',
            isdragging: false,
            enabled: true,
            isDropdown: false,
            dropdownOptions: [],
            label: "Terms & Conditions",
            icon: Icons.general.terms_conditions
        },
        {
            id: 'signature',
            disableToggle: true,
            isdragging: false,
            enabled: true,
            isDropdown: false,
            dropdownOptions: [],
            label: "Signature",
            icon: Icons.general.signature
        },

    ])

    const activeLayoutSettingsIds = layoutsettingsItems.filter(ls => ls.enabled).map(ls => ls.id)


    const HandleLayoutOpen = (value) => {

        setLayoutsettingsOpen(value)

        if (value) document.querySelector(':root').style.setProperty('--proposal-layoutsettings-width', '300px')
        else document.querySelector(':root').style.setProperty('--proposal-layoutsettings-width', '60px')

    }

    const HandleLayoutItemEnable = (value, item, i) => {
        let _layoutsettingsItems = [...layoutsettingsItems]
        _layoutsettingsItems[i].enabled = value
        setLayoutsettingsItems(_layoutsettingsItems)

        onSettingsChange(_layoutsettingsItems)
    }

    const HandleLayoutItemClick = (id) => {

        let parent_container = document.querySelector('#project-createproposal-main')

        if (parent_container && id) {

            let item = parent_container.querySelector(`#create-proposal-${id}`)

            if (item) item.scrollIntoView({ behavior: 'smooth' });
        }

    }

    const getLayoutItem = (id) => {

        let index = layoutsettingsItems.findIndex(item => item.id == id)
        let item = layoutsettingsItems[index]

        return { item, index }

    }
    const HandleLayoutItemOpen = (item, i) => {
        item.index = i
        setLayoutsettingsItemSelected(item)
    }
    const HandleLayoutItemClose = () => {
        setLayoutsettingsItemSelected(undefined)
    }



    const HandleLayoutItemChecklistsToggleAll = (value, parent_item, option_idx) => {
        let _layoutsettingsItems = [...layoutsettingsItems]

        _layoutsettingsItems[parent_item.index].dropdownOptions[option_idx].checklists = _layoutsettingsItems[parent_item.index].dropdownOptions[option_idx].checklists.map(c => {
            c.value = value

            return c
        })

        setLayoutsettingsItems(_layoutsettingsItems)
        onSettingsChange(_layoutsettingsItems)
    }
    const HandleLayoutItemChecklistsToggle = (value, parent_item, option_idx, checklist_idx) => {
        let _layoutsettingsItems = [...layoutsettingsItems]
        _layoutsettingsItems[parent_item.index].dropdownOptions[option_idx].checklists[checklist_idx].value = value
        setLayoutsettingsItems(_layoutsettingsItems)
        onSettingsChange(_layoutsettingsItems)
    }

    const HandleCancel = async (e) => {

        close()
    }


    return (
        <>
            <div className="popup-container-main popup-container-center">
                <div className="popup-block-ui"></div>
                <div className="side-popup-container preview-popup-container">

                    <div className="side-popup-header">
                        <div></div>
                        <div className="header-item-select">
                            <div className="header-item-select-content">
                                <div className="label">Web Preview</div>
                                <div className="description">This is what client will see when you send the proposal</div>
                            </div>
                        </div>
                        <div
                            className="settings-button"
                            onClick={(e) => HandleLayoutOpen(!layoutsettingsOpen)}
                            dangerouslySetInnerHTML={{ __html: Icons.general.settings }}
                        ></div>
                        <div
                            className="header-item-close"
                            onClick={(e) => HandleCancel()}
                            dangerouslySetInnerHTML={{ __html: Icons.general.close }}
                        ></div>
                    </div>

                    <div className="sidebar-popup-content">
                        <div className="project-createproposal-main" id='project-createproposal-main'>

                            <div className="createproposal-panel-content">
                                <PDFExport
                                    ref={pdfRef}
                                    author='CivilATOR'
                                    fileName={`${String(projectName).split(' ').join('_')}_Proposal_${Utils.getLocalFullDateBYFormat(null, '_', dateFormat)}`}
                                    margin={5}
                                    paperSize="A4"
                                    scale={0.6}
                                >
                                    <div className="panel-content-sections">
                                        <div className="create-proposal-content-main">

                                            <div className="create-proposal-sections">

                                                <div className="preview-header-section">
                                                    <div className="header-section-top">
                                                        <div className="company-detials">
                                                            <div className="logo">
                                                                <img src={companyImg || Images.User} />
                                                            </div>
                                                            <div className="title">{companyName}</div>
                                                        </div>
                                                        <div className="detials">
                                                            <div className="detials-item" style={{ display: 'none' }}>
                                                                <div className="label">Status</div>
                                                                <div className="value">{projectStatus}</div>
                                                            </div>
                                                            <div className="detials-item">
                                                                <div className="label">Project ID</div>
                                                                <div className="value">{projectId}</div>
                                                            </div>
                                                            <div className="detials-item">
                                                                <div className="label">Proposal ID</div>
                                                                <div className="value">{proposalId}</div>
                                                            </div>
                                                            <div className="detials-item">
                                                                <div className="label">Total</div>
                                                                <div className="value value-big">
                                                                    {currency_type == '₹' ?
                                                                        <span
                                                                            className='proposal-currency-mid'
                                                                            dangerouslySetInnerHTML={{ __html: Icons.general.inr }}
                                                                        ></span>
                                                                        : currency_type}

                                                                    {summaryFinalTotal}</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="header-section-detials">
                                                        <div className="title">Proposal Information</div>
                                                        <div className="detials">
                                                            <div className="detials-item">
                                                                <div className="label">Project Name</div>
                                                                <div className="value">{projectName}</div>
                                                            </div>
                                                            <div className="detials-item">
                                                                <div className="label">Builtup Area</div>
                                                                <div className="value">{projectArea}</div>
                                                            </div>
                                                            <div className="detials-item">
                                                                <div className="label">Proposal Name</div>
                                                                <div className="value">{proposalName}</div>
                                                            </div>
                                                            <div className="detials-item">
                                                                <div className="label">Project Name</div>
                                                                <div className="value">{projectName}</div>
                                                            </div>
                                                            <div className="detials-item">
                                                                <div className="label">Project Phase</div>
                                                                <div className="value">{Phase_name}</div>
                                                            </div>

                                                            <div className="detials-item">
                                                                <div className="label">Phase Client</div>
                                                                <div className="value">{Client_name}</div>
                                                            </div>

                                                            <div className="detials-item">
                                                                <div className="label">Project Address</div>
                                                                <div className="value">{projectAddress}</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>


                                                {activeLayoutSettingsIds.includes('introduction_text') && introduction_text ?
                                                    <div
                                                        className="create-proposal-section"
                                                        id={`create-proposal-introduction_text`}
                                                        dangerouslySetInnerHTML={{ __html: introduction_text }}
                                                    >
                                                    </div>
                                                    : ''}

                                                {activeLayoutSettingsIds.includes('estimate') ?
                                                    <div className="create-proposal-section" id={`create-proposal-estimate`}>
                                                        <div className="createproposal-estimate-main">
                                                            <div className="header">
                                                                <div className="left">
                                                                    <div className="title">BILL OF QUANTITIES</div>
                                                                </div>
                                                            </div>
                                                            <div className="estimate-table">

                                                                <Paper style={{ width: '100%', height: '100%', boxShadow: 'none', overflow: 'hidden' }}>
                                                                    <TableContainer style={{ height: '100%', boxShadow: 'none !important', }} >
                                                                        <Table stickyHeader>
                                                                            <TableHead
                                                                                className='tabel-header estimate-tabel-header'
                                                                            >
                                                                                <TableRow>
                                                                                    {
                                                                                        tabelHeader.filter(th => !th.disable).filter(th => {
                                                                                            let estimate_setting = getLayoutItem(`estimate`).item.dropdownOptions[0].checklists
                                                                                            let isColumnEnabled = estimate_setting.filter(es => es.id == th.id)
                                                                                            isColumnEnabled = isColumnEnabled.length ? isColumnEnabled[0].value : !th.disable
                                                                                            return isColumnEnabled

                                                                                        }).map((column) => (

                                                                                            <TableCell
                                                                                                key={column.id}
                                                                                                align={column.align}
                                                                                                style={{
                                                                                                    width: column.width,
                                                                                                    minWidth: column.minWidth,
                                                                                                    maxWidth: column.maxWidth,
                                                                                                    paddingRight: `${column.id == 'select' ? '0' : '10px'}`
                                                                                                }}
                                                                                                className='tabel-header-items'
                                                                                            >
                                                                                                {column.id == 'select' ?

                                                                                                    <RadioInput props={{
                                                                                                        value: tableSelectAll || false,
                                                                                                        isIcon: false,
                                                                                                        icon: "",
                                                                                                        inputType: "toggle",
                                                                                                        name: "table-default-toggle",
                                                                                                        setValue: (value) => HandleSelectAllEvent(value)
                                                                                                    }} />
                                                                                                    :
                                                                                                    column.label}
                                                                                            </TableCell>
                                                                                        ))}
                                                                                </TableRow>
                                                                            </TableHead>
                                                                            <TableBody className='tabel-body'>
                                                                                {estimateItems.map((row, i) => (
                                                                                    CreateEstimateTableItem(row, i, String(i + 1))
                                                                                ))}
                                                                            </TableBody>
                                                                        </Table>
                                                                    </TableContainer>
                                                                </Paper>

                                                            </div>
                                                        </div>
                                                    </div>
                                                    : ""}


                                                {activeLayoutSettingsIds.includes('overheads') || activeLayoutSettingsIds.includes('insurances') ?
                                                    <div className="create-proposal-section">
                                                        <div className="createproject-section">

                                                            <div className="proposal-summary-items">
                                                                {
                                                                    activeLayoutSettingsIds.includes('overheads')
                                                                        && summaryItems[0]
                                                                        && summaryItems[0].id == 'overhead-summary-option'
                                                                        && summaryOverHeads.length
                                                                        ?

                                                                        <div className="proposal-summary-item" id={`create-proposal-overheads`}>
                                                                            <div className="proposal-summary-item-head">
                                                                                <div
                                                                                    className="icon"
                                                                                    dangerouslySetInnerHTML={{ __html: summaryItems[0].head.icon }}
                                                                                ></div>
                                                                                <div className="label">{summaryItems[0].head.label}</div>
                                                                            </div>
                                                                            <div className="proposal-summary-item-content">
                                                                                {summaryOverHeads.map((item, i) => (

                                                                                    <div className="item" key={item.id} id={item.id}>
                                                                                        <div className="title">{item.label}</div>
                                                                                        <div className="quantity">
                                                                                            {
                                                                                                item.value_type == '%' ?
                                                                                                    <>
                                                                                                        {item.value}
                                                                                                        {item.value_type}

                                                                                                    </>
                                                                                                    : <>
                                                                                                        <span
                                                                                                            className='proposal-currency-small'
                                                                                                            dangerouslySetInnerHTML={{ __html: Icons.general.inr }}
                                                                                                        ></span>
                                                                                                        {item.value}
                                                                                                    </>
                                                                                            }
                                                                                        </div>
                                                                                        <div className="amount">
                                                                                            {currency_type == '₹' ?
                                                                                                <span
                                                                                                    className='proposal-currency-small'
                                                                                                    dangerouslySetInnerHTML={{ __html: Icons.general.inr }}
                                                                                                ></span>
                                                                                                : currency_type} {item.value_type == '%' ? ((builderFinalCost * parseFloat(item.value)) / 100).toFixed(2) : parseFloat(item.value).toFixed(2)}
                                                                                        </div>
                                                                                    </div>
                                                                                ))}
                                                                            </div>
                                                                        </div>
                                                                        : ''}
                                                                {
                                                                    activeLayoutSettingsIds.includes('insurances')
                                                                        && summaryItems[1]
                                                                        && summaryItems[1].id == 'insurance-summary-option'
                                                                        && summaryInsurance.length
                                                                        ?

                                                                        <div className="proposal-summary-item" id={`create-proposal-insurances`}>
                                                                            <div className="proposal-summary-item-head">
                                                                                <div
                                                                                    className="icon"
                                                                                    dangerouslySetInnerHTML={{ __html: summaryItems[1].head.icon }}
                                                                                ></div>
                                                                                <div className="label">{summaryItems[1].head.label}</div>
                                                                            </div>
                                                                            <div className="proposal-summary-item-content">
                                                                                {summaryInsurance.map((item, i) => (

                                                                                    <div className="item" key={item.id} id={item.id}>
                                                                                        <div className="title">{item.label}</div>
                                                                                        <div className="quantity">
                                                                                            {
                                                                                                item.value_type == '%' ?
                                                                                                    <>
                                                                                                        {item.value}
                                                                                                        {item.value_type}

                                                                                                    </>
                                                                                                    : <>
                                                                                                        <span
                                                                                                            className='proposal-currency-small'
                                                                                                            dangerouslySetInnerHTML={{ __html: Icons.general.inr }}
                                                                                                        ></span>
                                                                                                        {item.value}
                                                                                                    </>
                                                                                            }
                                                                                        </div>
                                                                                        <div className="amount">
                                                                                            {currency_type == '₹' ?
                                                                                                <span
                                                                                                    className='proposal-currency-small'
                                                                                                    dangerouslySetInnerHTML={{ __html: Icons.general.inr }}
                                                                                                ></span>
                                                                                                : currency_type} {item.value_type == '%' ? ((builderFinalCost * parseFloat(item.value)) / 100).toFixed(2) : parseFloat(item.value).toFixed(2)}
                                                                                        </div>
                                                                                    </div>
                                                                                ))}
                                                                            </div>
                                                                        </div>
                                                                        : ''}

                                                            </div>
                                                        </div>
                                                    </div>
                                                    : ""}

                                                {activeLayoutSettingsIds.includes('summary') ?
                                                    <div className="create-proposal-section" id={`create-proposal-summary`}>
                                                        <div className="createproject-section proposal-left-section">
                                                            <div className="proposal-summary-totals proposal-summary-title">
                                                                <div className="title">Summary</div>
                                                            </div>
                                                            <div className="proposal-summary-totals proposal-summary-items">

                                                                <div className="proposal-summary-item">
                                                                    <div className="proposal-summary-item-content">

                                                                        {summaryTotalItems.filter(s => s.enabled).filter(item => {
                                                                            let estimate_setting = getLayoutItem(`summary`).item.dropdownOptions[0].checklists
                                                                            let isColumnEnabled = estimate_setting.filter(es => es.id == item.id)
                                                                            isColumnEnabled = isColumnEnabled.length ? isColumnEnabled[0].value : item.enabled
                                                                            return isColumnEnabled
                                                                        }).map((item, i) => (
                                                                            <div className="item" key={item.id} id={item.id}>
                                                                                <div className="title">{item.label}</div>
                                                                                <div className="quantity"></div>
                                                                                <div className="amount">
                                                                                    {currency_type == '₹' ?
                                                                                        <span
                                                                                            className='proposal-currency-small'
                                                                                            dangerouslySetInnerHTML={{ __html: Icons.general.inr }}
                                                                                        ></span>
                                                                                        : currency_type} {parseFloat(item.value || 0).toFixed(2)}
                                                                                </div>
                                                                            </div>
                                                                        ))}
                                                                        {
                                                                            getLayoutItem(`summary`).item.dropdownOptions[0].checklists.some(ch => ch.id == 'price' && ch.value) ?
                                                                                <div className="item summary-final-total" id='summary-final-total'>
                                                                                    <div className="title">Final price</div>
                                                                                    <div className="quantity"></div>
                                                                                    <div className="amount">
                                                                                        {currency_type == '₹' ?
                                                                                            <span
                                                                                                className='proposal-currency-small'
                                                                                                dangerouslySetInnerHTML={{ __html: Icons.general.inr }}
                                                                                            ></span>
                                                                                            : currency_type} {parseFloat(summaryFinalTotal || 0).toFixed(2)}
                                                                                    </div>
                                                                                </div>
                                                                                : ''}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    : ""}
                                                {activeLayoutSettingsIds.includes('payment_schedule') && paymentSplits.length ?
                                                    <div className="create-proposal-section">
                                                        <div className="createproject-section">

                                                            <div className="proposal-summary-items">

                                                                <div className="proposal-summary-item" id={`create-proposal-overheads`}>
                                                                    <div className="proposal-summary-item-head">
                                                                        <div
                                                                            className="icon"
                                                                            dangerouslySetInnerHTML={{ __html: Icons.general.pay_schedule }}
                                                                        ></div>
                                                                        <div className="label">Payment Schedule</div>
                                                                    </div>
                                                                    <div className="proposal-summary-item-content">
                                                                        {paymentSplits.map((item, i) => (

                                                                            <div className="item" key={`paymeny-schedule-${item.id}`} id={`paymeny-schedule-${item.id}`}>
                                                                                <div className="title">{item.name}</div>
                                                                                <div className="quantity">
                                                                                    {item.value}
                                                                                    {
                                                                                        item.value_type == '₹' ? <span
                                                                                            className='proposal-currency-small'
                                                                                            dangerouslySetInnerHTML={{ __html: Icons.general.inr }}
                                                                                        ></span>
                                                                                            : item.value_type
                                                                                    }
                                                                                </div>
                                                                                <div className="amount">
                                                                                    {currency_type == '₹' ?
                                                                                        <span
                                                                                            className='proposal-currency-small'
                                                                                            dangerouslySetInnerHTML={{ __html: Icons.general.inr }}
                                                                                        ></span>
                                                                                        : currency_type} {parseFloat(item.amount).toFixed(2)}
                                                                                </div>
                                                                            </div>
                                                                        ))}
                                                                    </div>
                                                                </div>


                                                            </div>
                                                        </div>
                                                    </div>
                                                    : ""}
                                                {activeLayoutSettingsIds.includes('closing_text') && closing_text ?
                                                    <div
                                                        className="create-proposal-section"
                                                        id={`create-proposal-introduction_text`}
                                                        dangerouslySetInnerHTML={{ __html: closing_text }}
                                                    >
                                                    </div>
                                                    : ''}
                                                {activeLayoutSettingsIds.includes('terms_conditions') && termsConditionValue ?
                                                    <div className="create-proposal-section" id={`create-proposal-terms_conditions`}>

                                                        <div className={`createproposal-description-main`}>
                                                            <div className="head">
                                                                <div className="label">Terms and conditions</div>
                                                            </div>
                                                            <div className="terms-condition-result" dangerouslySetInnerHTML={{ __html: termsConditionValue }}></div>
                                                        </div>

                                                    </div>
                                                    : ""}
                                                {comments ?
                                                    <div className="create-proposal-section" id={`create-proposal-comments`}>

                                                        <div className={`createproposal-description-main`}>
                                                            <div className="head">
                                                                <div className="label">Comments</div>
                                                            </div>
                                                            <div className="terms-condition-result" dangerouslySetInnerHTML={{ __html: comments }}></div>
                                                        </div>

                                                    </div>
                                                    : ""}
                                            </div>
                                        </div>
                                        {ShowWatermark ?
                                            <div className="proposal-product-watermark">
                                                <div className="watermark">
                                                    <div className="label">Powered by</div>
                                                    <img src={Images.logo} className='img' />
                                                </div>
                                            </div>
                                            : ''}
                                    </div>
                                </PDFExport>
                            </div>

                            <div className={`createproposal-layoutsettings-main ${layoutsettingsOpen ? 'layoutsettings-open' : ''}`}>
                                <div className="createproposal-layoutsettings-main-content">
                                    <div className="open-toggle-button" onClick={() => HandleLayoutOpen(!layoutsettingsOpen)}>
                                        <div
                                            className="icon"
                                            dangerouslySetInnerHTML={{ __html: Icons.general.open_close_toggle }}
                                        ></div>
                                    </div>
                                    {!layoutsettingsItemSelected ?
                                        <div className="createproposal-layoutsettings-content">

                                            <div className="header">
                                                <div className="left">
                                                    <div className="title">
                                                        <div
                                                            className="icon"
                                                            dangerouslySetInnerHTML={{ __html: Icons.general.config }}
                                                        ></div>
                                                        <div className="label">Layout settings</div>
                                                    </div>
                                                </div>
                                                <div className="right">
                                                    <div className="save-button">
                                                        <div className="label">Save layout</div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="layoutsettings-preset-select">
                                                <SelectInput
                                                    props={{
                                                        id: "create-proposal-layoutpreset",
                                                        value: selectedLayoutPresets,
                                                        placeholder: '',
                                                        readOnly: false,
                                                        options: layoutPresets || [],
                                                        setValue: (value, label) => {
                                                            setSelectedLayoutPresets(value)
                                                        },
                                                        isStatus: false,
                                                        isIcon: false,
                                                        isLabel: false,
                                                        isRequired: false,
                                                        label: "",
                                                    }}
                                                />
                                            </div>
                                            <div className="layoutsettings-items-table">

                                                <div className="table-items">
                                                    {layoutsettingsItems?.map((item, i) => (

                                                        <div className="table-row" key={`layoutsettings-item-${item.id}`} data-draggable-id={item.id}>
                                                            <div
                                                                className="item-drag-icon"
                                                                dangerouslySetInnerHTML={{ __html: Icons.general.drag_updown }}
                                                            ></div>
                                                            <div
                                                                className="item-title"
                                                                style={{ width: '180px' }}
                                                                onClick={() => {
                                                                    HandleLayoutItemClick(item.id)
                                                                    if (item.isDropdown) HandleLayoutItemOpen(item, i)
                                                                }}
                                                            >
                                                                <div className="icon" dangerouslySetInnerHTML={{ __html: item.icon }}></div>
                                                                <div className="label">{item.label || ""}</div>
                                                            </div>
                                                            {!item.disableToggle ?
                                                                <div className="item-toggle">
                                                                    <RadioInput props={{
                                                                        value: item.enabled,
                                                                        isIcon: false,
                                                                        icon: "",
                                                                        inputType: "toggle",
                                                                        name: "layoutsettings-default-toggle",
                                                                        setValue: (value) => HandleLayoutItemEnable(value, item, i)
                                                                    }} />
                                                                </div>
                                                                : ""}
                                                            {item.isDropdown ?
                                                                <div className="item-dropdown" onClick={() => HandleLayoutItemOpen(item, i)}>
                                                                    <div
                                                                        className="icon"
                                                                        dangerouslySetInnerHTML={{ __html: Icons.general.dropdown_arrow }}
                                                                    ></div>
                                                                </div>
                                                                : ''}
                                                        </div>

                                                    ))}

                                                </div>
                                            </div>
                                        </div>
                                        :

                                        layoutsettingsItemSelected != undefined ?
                                            <div className="layoutsettings-selected-content">
                                                <div className="header">
                                                    <div className="left">
                                                        <div className="title">
                                                            <div
                                                                className="icon"
                                                                dangerouslySetInnerHTML={{ __html: Icons.general.back_arrow }}
                                                                onClick={() => HandleLayoutItemClose()}
                                                            ></div>
                                                        </div>
                                                        <div className="title">
                                                            <div
                                                                className="icon"
                                                                dangerouslySetInnerHTML={{ __html: layoutsettingsItemSelected.icon }}
                                                            ></div>
                                                            <div className="label">{layoutsettingsItemSelected.label}</div>
                                                        </div>
                                                    </div>
                                                    <div className="right">
                                                        <div className="item-toggle">
                                                            <RadioInput props={{
                                                                value: layoutsettingsItemSelected.enabled,
                                                                isIcon: false,
                                                                icon: "",
                                                                inputType: "toggle",
                                                                name: "layoutsettings-default-toggle",
                                                                setValue: (value) => HandleLayoutItemEnable(value, layoutsettingsItemSelected, layoutsettingsItemSelected.index)
                                                            }} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="message">
                                                    Hide and show items you want to see in proposal
                                                </div>
                                                <div className="content">
                                                    {layoutsettingsItemSelected?.dropdownOptions.map((option, i) => (
                                                        <div className="content-item" key={`layoutsettings-item-${option.id}`}>
                                                            <div className="content-item-header">


                                                                {option.isTitle ? option.title : ''}
                                                                {
                                                                    option.isToggle ?
                                                                        <div className="item-toggle">
                                                                            <RadioInput props={{
                                                                                value: option.enabled,
                                                                                isIcon: false,
                                                                                icon: "",
                                                                                inputType: "toggle",
                                                                                name: "layoutsettings-default-toggle",
                                                                                setValue: (value) => HandleLayoutItemChecklistsToggleAll(value, layoutsettingsItemSelected, i)
                                                                            }} />
                                                                        </div>
                                                                        : ''
                                                                }
                                                            </div>
                                                            <div className="content-item-checklists">
                                                                {option?.checklists.filter(ch => !ch.disable).map((checklist, cidx) => (
                                                                    <div className="checklist-item" key={`layoutsettings-checklist-${checklist.id}`}>
                                                                        <div className="input">
                                                                            <RadioInput props={{
                                                                                value: checklist.value,
                                                                                isIcon: false,
                                                                                icon: "",
                                                                                inputType: "checkbox",
                                                                                name: "layoutsettings-default-toggle",
                                                                                setValue: (value) => HandleLayoutItemChecklistsToggle(value, layoutsettingsItemSelected, i, cidx)
                                                                            }} />
                                                                        </div>
                                                                        <div className="label">{checklist.label}</div>
                                                                    </div>
                                                                ))}
                                                            </div>

                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                            : ""
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="sidebar-popup-footer">
                        <div className="footer-item action-items">
                            <div className="action-preview">
                            </div>
                            <div className='action-btns'>
                                <div
                                    className="action-btn button-download"
                                    onClick={(e) => HandleDownloadBtn(e)}
                                >
                                    <div
                                        className="icon"
                                        dangerouslySetInnerHTML={{ __html: Icons.general.download }}
                                    ></div>
                                    <div className="label">Download</div>
                                </div>
                                <div
                                    className="action-btn button-download"
                                    onClick={(e) => HandleSaveBtn(e)}
                                >
                                    <div
                                        className="icon"
                                        dangerouslySetInnerHTML={{ __html: Icons.general.save }}
                                    ></div>
                                    <div className="label">Save</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )

}

export default PreviewProposal;