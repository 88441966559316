

import React, { useEffect, useState, useRef } from 'react'
import { Route, Routes, BrowserRouter, Link, useLocation, Navigate, Outlet, useNavigate, useParams } from 'react-router-dom';
import store from '../../redux/store'
import StoreHandler from '../../redux/StoreHandler'
import { useSelector, useDispatch } from 'react-redux'
import UserAction from '../../redux/action/userAction'

import ReverseProxy from '../../config/reverseProxy'
import ProjectPhasesHandler from '../../Handlers/ProjectPhases/ProjectPhases';
import Icons from '../../assets/Icons'
import Images from '../../assets/Images'
import { SearcBarInput, TextInput, SelectInput, RadioInput } from '../../components/Inputs'
import Utils from '../../utils'
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

import AlertPopup from '../../components/AlertPopup'
import SystemToastPopup from '../../components/SystemToastPopup'
import Loading from '../../components/Loading'
import NoDataFound from '../../components/NoDataFound'

import { useAuth, useMenuAuth } from '../../hooks/useAuth'

import AddPhaseGroupPopup from './AddPhaseGroupPopup'
import CreateNewPhase from './CreateNewPhase';

const PhasesList = () => {

    const store = useSelector((store) => store)
    const dispatch = useDispatch()
    const { updateState } = new UserAction

    const navigate = useNavigate()

    const { projectId } = useParams()

    const { isAdmin, menu_permission } = useMenuAuth({ menu: 'project_management--project_phases', feature_key: 'project_phases' })

    const location = useLocation()

    const [selected_view_settings_value, setSelected_view_settings_value] = useState('')
    const [has_view_access, setHas_view_access] = useState(false)
    const [has_add_access, setHas_add_access] = useState(false)
    const [has_edit_access, setHas_edit_access] = useState(false)
    const [has_delete_access, setHas_delete_access] = useState(false)

    useEffect(() => {

        if (isAdmin) {

            setHas_view_access(true)
            setHas_add_access(true)
            setHas_edit_access(true)
            setHas_delete_access(true)
        }

        else if (menu_permission) {

            if (
                !menu_permission._view
                &&
                (!menu_permission.selected_view_settings_value
                    || menu_permission.selected_view_settings_value == 'no_access')
            ) {
                navigate(`${ReverseProxy['proxyUrl']}/quick-menu`, { state: { from: location }, replace: true })
            }

            setHas_view_access(menu_permission._view == '1')
            setHas_add_access(menu_permission._create == '1')
            setHas_edit_access(menu_permission._edit == '1')
            setHas_delete_access(menu_permission._delete == '1')

            setSelected_view_settings_value(menu_permission.selected_view_settings_value)

        }

    }, [store])


    const storeHandler = new StoreHandler({
        dataStack: [`project_client-${projectId}`],
        store: store,
        dispatch: dispatch,
        updateState: updateState,
    })

    const LoadStoreData = async () => {
        // setIsLoading(true)
        let resolved = await storeHandler.init()
        // setIsLoading(false)
    }

    const projectPhasesHandler = new ProjectPhasesHandler()

    const [isLoading, setIsLoading] = useState(false)
    const [warningAlert, setWarningAlert] = useState(false)
    const [warningAlertType, setWarningAlertType] = useState('warning')
    const [apiFailedMessage, setApiFailedMessage] = useState('Error in Projects')


    const [deleteItemId, setDeleteItem] = useState(null)
    const [deleteItemType, setDeleteItemType] = useState(null)
    const [deleteItemAlert, setDeleteItemAlert] = useState(false)

    const [ProjectPhasesDetials, setProjectPhasesDetials] = useState([])

    const [SeachInputValue, setSeachInputValue] = useState('')

    const [SelectedGroupResultSection, setSelectedGroupResultSection] = useState('all')

    const [IsAddPhasesGroupPopup, setIsAddPhasesGroupPopup] = useState(false)
    const [PhasesGroups, setPhasesGroups] = useState([])
    const [PhasesGroups_Options, setPhasesGroups_Options] = useState([
        {
            id: 'all',
            name: 'All',
            count: ProjectPhasesDetials.length
        }
    ])

    const [Selected_Phase, setSelected_Phase] = useState({})

    const [Status_Options, setStatus_Options] = useState([
        {
            value: '0',
            label: 'Action Pending',
            colorcode: '#ffb61a'
        },
        {
            value: '1',
            label: 'In Progress',
            colorcode: '#0062ff'
        },
        {
            value: '2',
            label: 'Completed',
            colorcode: '#03ad00'
        },
        {
            value: '3',
            label: 'On Hold',
            colorcode: '#fd7522'
        },
        {
            value: '4',
            label: 'Stopped',
            colorcode: '#ff4d4d'
        }
    ])

    const HandleOpenAddPhaseGroupPopup = (e) => {
        setIsAddPhasesGroupPopup(true)
    }
    const HandleCloseAddPhaseGroupPopup = () => {
        setIsAddPhasesGroupPopup(false)
    }
    const HandleSubmitAddPhaseGroupPopup = async (groups) => {

        HandleCloseAddPhaseGroupPopup()

        setPhasesGroups(groups)

        let payload_data = groups.map((group) => {

            return {
                id: group.id,
                name: group.name
            }
        })

        let payload = {
            project_id: projectId,
            groups: payload_data
        }

        setIsLoading(true)
        let response = await projectPhasesHandler.updateProjectPhaseGoupsHandler(payload)

        setIsLoading(false)

        if (response && response.success) {
            setWarningAlert(true)
            setWarningAlertType('success')
            setApiFailedMessage(`Phase group create successfully!`)
        }
        else {
            setWarningAlert(true)
            setWarningAlertType('error')
            setApiFailedMessage(`Error in creating Phase group, Please try again!`)
        }

    }


    const getProjectPhasesDetials = async () => {


        let filter = {
            project_id: projectId
        }

        let response = await projectPhasesHandler.getProjectPhasesHandler(filter)

        if (response && response.success) {

            let phases_details = response.data

            let phases = phases_details.filter(p => p.type == '1')
            let groups = phases_details.filter(p => p.type == '0')

            setProjectPhasesDetials(phases || [])
            setPhasesGroups(groups || [])

        } else {
            setWarningAlert(true)
            setWarningAlertType('error')
            setApiFailedMessage(`Error in Phases, Please try again!`)
        }
    }


    const HandleDeleteConfirmItem = async (id, type, confirmation) => {

        if (!confirmation || !id) {

            setDeleteItem(null)
            setDeleteItemType(null)
            setDeleteItemAlert(false)

            return
        }


        let response = await projectPhasesHandler.deleteProjectPhasesHandler({
            id: id,
            project_id: projectId
        })

        if (response && response.success) {

            getProjectPhasesDetials()

        } else {
            setWarningAlert(true)
            setWarningAlertType('error')
            setApiFailedMessage(`Error in deleteing Projects`)
        }

        setDeleteItem(null)
        setDeleteItemType(null)
        setDeleteItemAlert(false)
    }


    const getDeleteMessage = (type) => {

        return "Are you sure you want to delete?"
    }

    const HandleStatusResultSection = (section) => {
        setSelectedGroupResultSection(section)
    }

    const HandleAddPhase = (e) => {
        if (!has_add_access) return
        navigate(`${window.location.pathname}/add-phase`)
    }
    const HandleEditPhase = (phase) => {
        if (!has_edit_access) return
        setSelected_Phase(phase)

        navigate(`${window.location.pathname}/edit-phase/${phase.id}`)
    }

    const HandleDeletePhase = (phase) => {
        if (!has_delete_access) return

        setDeleteItem(phase.id)
        setDeleteItemType('phase')
        setDeleteItemAlert(true)
    }

    const CloseCreateRolePopup = (type) => {

        let back_path = String(window.location.pathname).split('/')
        back_path.pop()
        if (type == 'edit') back_path.pop()

        back_path = back_path.join('/')

        navigate(`${back_path}`)
    }
    const CreateRoleCallbackPopup = async (type) => {

        setWarningAlert(true)
        setWarningAlertType('success')
        setApiFailedMessage(`Phase ${type == 'edit' ? 'updated' : 'created'} successfully!`)

        CloseCreateRolePopup(type)
        getProjectPhasesDetials()
    }

    const getStatusValues = (value) => {
        let status = Status_Options.filter(s => s.value == value)

        return status.length ? status[0] : {
            value: '0',
            label: 'Action Pending',
            colorcode: '#ffb61a'
        }

    }

    const RenderPhasesItemsResult = (Resultitems, group, search_values) => {

        if (search_values) {
            Resultitems = Resultitems.filter(r => r.name.toLowerCase().includes(search_values.toLowerCase()))
        }

        Resultitems = Resultitems.filter(r => group ? r.parent_id == group : true)

        return (
            <>
                {Resultitems.length ?
                    <div className="phases-content-items">
                        {Resultitems.map((item, i) => (
                            <div className="phases-content-item"
                                key={`phases-content-item-${item.id}`}
                            >
                                <div className="phase-item-header">
                                    <div className="item-header-details">
                                        <div className="title">{item.name}</div>
                                        <div className="status" style={{ background: getStatusValues(item.status).colorcode }}>{getStatusValues(item.status).label}</div>
                                    </div>
                                    <div className="item-header-actions">
                                        {has_edit_access ?
                                            <div className="edit-button"
                                                dangerouslySetInnerHTML={{ __html: Icons.general.edit_btn }}
                                                onClick={(e) => HandleEditPhase(item)}
                                            >
                                            </div>
                                            : ''}
                                        {has_delete_access ?
                                            <div className="delete-button"
                                                dangerouslySetInnerHTML={{ __html: Icons.general.delete_btn }}
                                                onClick={(e) => HandleDeletePhase(item)}
                                            ></div>
                                            : ''}
                                    </div>
                                </div>
                                <div className="phase-item-details">
                                    <div className="details-item">
                                        <div className="label">Group</div>
                                        <div className="value">{item.parent_name}</div>
                                    </div>
                                    <div className="details-item">
                                        <div className="label">Client</div>
                                        <div className="value">{item.client_name}</div>
                                    </div>
                                    <div className="details-item">
                                        <div className="label">Start Date</div>
                                        <div className="value">{item.startdate ? Utils.getLocalFullDateBYFormat(item.startdate, '-') : 'NIL'}</div>
                                    </div>
                                    <div className="details-item">
                                        <div className="label">End Date</div>
                                        <div className="value">{item.enddate ? Utils.getLocalFullDateBYFormat(item.enddate, '-') : 'NIL'}</div>
                                    </div>
                                    <div className="details-item">
                                        <div className="label">Budget</div>
                                        <div className="value">₹{item.budget || '-/-'}</div>
                                    </div>
                                </div>
                            </div>
                        ))}

                    </div>
                    : <NoDataFound label={'No Phases Found'} />}
            </>
        )
    }

    useEffect(() => {
        // LoadStoreData()
        getProjectPhasesDetials()
    }, [])

    useEffect(() => {

        if (PhasesGroups.length) {

            let _PhasesGroups = PhasesGroups.map((opt) => {

                let count = ProjectPhasesDetials.filter(phase => phase.parent_id == opt.id).length

                return {
                    id: opt.id,
                    name: opt.name,
                    count: count
                }

            })

            setPhasesGroups_Options([
                {
                    id: 'all',
                    name: 'All',
                    count: ProjectPhasesDetials.length
                },
                ..._PhasesGroups
            ])
        }

    }, [ProjectPhasesDetials, PhasesGroups])


    return (
        <>


            {isLoading ?

                <Loading
                    props={{
                        isMainLogo: false,
                        isLabel: true
                    }} />

                : null}

            {warningAlert ?

                <SystemToastPopup
                    props={{
                        type: warningAlertType,
                        message: apiFailedMessage || "Error in Projects",
                        callback: (confirmation) => setWarningAlert(false)
                    }} />
                : null}

            {deleteItemAlert ?

                <AlertPopup
                    props={{
                        type: "delete",
                        actionBtnOption: { label: "Yes, Delete" },
                        heading: "Delete phase",
                        message: getDeleteMessage(deleteItemType),
                        callback: (confirmation) => HandleDeleteConfirmItem(deleteItemId, deleteItemType, confirmation)
                    }} />

                : null}

            {IsAddPhasesGroupPopup ?
                <AddPhaseGroupPopup
                    props={{
                        phases_groups: PhasesGroups,
                        close_callback: HandleCloseAddPhaseGroupPopup,
                        submit_callback: HandleSubmitAddPhaseGroupPopup
                    }}
                />
                : ''}

            <Routes >
                <Route exact path='/add-phase' element={
                    <CreateNewPhase props={{
                        type: "create",
                        heading: "Add New Phase",
                        callback: CreateRoleCallbackPopup,
                        close: CloseCreateRolePopup
                    }} />}></Route>
                <Route exact path='/edit-phase/:phaseId' element={
                    <CreateNewPhase props={{
                        type: "edit",
                        heading: "Edit Phase",
                        callback: CreateRoleCallbackPopup,
                        close: CloseCreateRolePopup,
                        detials: Selected_Phase
                    }} />}></Route>
            </Routes>

            <div className="project-phases-main">

                <div className="project-phases-header">
                    <div className="phases-header-left">
                        <div className="title">Project Phases</div>
                    </div>
                    <div className="phases-header-right">
                        <div className="action-search-filter">
                            <SearcBarInput
                                props={{
                                    id: 'search',
                                    value: SeachInputValue,
                                    placeholder: "Search",
                                    setValue: (val) => setSeachInputValue(val),
                                    isIcon: true,
                                    isLeft: true,
                                    icon: Icons.general.search
                                }}
                            />
                        </div>
                        <div className="actions-buttons">
                            {has_add_access ?
                                <div className="action-button add-button"
                                    onClick={HandleAddPhase}
                                >
                                    <div className="icon" dangerouslySetInnerHTML={{ __html: Icons.general.add_btn }}></div>
                                    <div className="label">Add Phase</div>
                                </div>
                                : ''}
                        </div>
                    </div>
                </div>
                <div className="project-phases-filters-main">
                    <div className="project-phases-filters">
                        <div className="status_filter-items">
                            {PhasesGroups_Options.map((opt, i) => (
                                <div
                                    key={`project-status-filter-${opt.id}`}
                                    className={`status_filter-item ${SelectedGroupResultSection == opt.id ? 'status_filter-item-active' : ''}`}
                                    onClick={(e) => HandleStatusResultSection(opt.id)}
                                >
                                    <div className="label">{opt.name}</div>
                                    <div className="count">{opt.count}</div>
                                </div>
                            ))}
                        </div>
                    </div>
                    {has_add_access ?
                        <div className="filters-action"
                            onClick={HandleOpenAddPhaseGroupPopup}
                        >
                            <div className="icon" dangerouslySetInnerHTML={{ __html: Icons.general.add_btn }}></div>
                            <div className="label">Add Group</div>

                        </div>
                        : ''}
                </div>
                <div className="project-phases-content">
                    {RenderPhasesItemsResult(ProjectPhasesDetials, SelectedGroupResultSection == 'all' ? '' : SelectedGroupResultSection, SeachInputValue)}

                </div>

            </div>
        </>
    )
}

export default PhasesList;