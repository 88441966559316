

import React, { useEffect, useState, useRef, useCallback, useMemo, StrictMode } from 'react'
import { Route, Routes, BrowserRouter, Link, useLocation, Navigate, Outlet, useNavigate, useParams } from 'react-router-dom';
import store from '../../redux/store'
import StoreHandler from '../../redux/StoreHandler'
import { useSelector, useDispatch } from 'react-redux'
import UserAction from '../../redux/action/userAction'

import ReverseProxy from '../../config/reverseProxy'
import SettingsHandler from '../../Handlers/Settings/Settings';
import EstimateHandler from '../../Handlers/Estimate/Estimate';
import PurchaseOrderHandler from '../../Handlers/PurchaseOrder/PurchaseOrder';
import StockItemsHandler from '../../Handlers/StockItems/StockItems';
import PO_GRN_Handler from '../../Handlers/PO_GRN/PO_GRN';
import CommentsHandler from '../../Handlers/Comments/Comments';
import Icons from '../../assets/Icons'
import Images from '../../assets/Images'
import { CostInput, TextInput, SelectInput, DateTimeInput, TextArea, RadioInput, MultiSelectInput } from '../../components/Inputs'
import Utils from '../../utils'
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

import AlertPopup from '../../components/AlertPopup'
import SystemToastPopup from '../../components/SystemToastPopup'
import Loading from '../../components/Loading'

import PreviewGRN from './previewGRN'
import BuilderAttachments from '../../Handlers/BuilderAttachments/BuilderAttachments';

import ImagePreviewPopup from '../../components/ImagePreviewPopup'

import { useAuth, useMenuAuth } from '../../hooks/useAuth'

const AddPaymentHistory = ({ props }) => {

    const { type, existItemData, max_amount, updateCallback, close } = props


    const { projectId } = useParams()

    const [date, setDate] = useState(Utils._getLocalDate())
    const [amount, setAmount] = useState('')
    const [payment_method, setPayment_method] = useState('')
    const [notes, setNotes] = useState('')

    const navigate = useNavigate()
    const store = useSelector((store) => store)
    const dispatch = useDispatch()
    const { updateState } = new UserAction


    const isExistItemData = existItemData && existItemData.id != undefined
    const [paymentMenthodOptions, setPaymentMenthodOptions] = useState([
        {
            value: 'Bank transfer',
            label: 'Bank transfer'
        },
        {
            value: 'Direct Deposit',
            label: 'Direct Deposit'
        },
        {
            value: 'Credit card',
            label: 'Credit card'
        },
        {
            value: 'Cash',
            label: 'Cash'
        },
        {
            value: 'Check',
            label: 'Check'
        },
        {
            value: 'UPI',
            label: 'UPI'
        },
        {
            value: 'Other',
            label: 'Other'
        },
    ])


    const HandlePopupSubmit = async (e) => {
        e.preventDefault()

        let updated_data = {
            id: '',
            date,
            amount,
            payment_method,
            notes,

        }

        if (type == 'edit' && isExistItemData && existItemData.id) {
            updated_data.id = existItemData.id
        }
        else { updated_data.id = Utils.getUniqueId() }

        console.log(updated_data, 'updated_data');

        updateCallback(updated_data)


    }


    return (
        <div className="popup-container-main popup-container-center">
            <div className="popup-block-ui"></div>
            <form className="side-popup-container create-payment_history-main" onSubmit={HandlePopupSubmit}>

                <div className="side-popup-header">
                    <div className="header-item-select">
                        <div className="header-item-select-content">
                            <div className="label">Record Payments</div>

                        </div>
                    </div>
                    <div
                        className="header-item-close"
                        onClick={(e) => close()}
                        dangerouslySetInnerHTML={{ __html: Icons.general.close }}
                    ></div>
                </div>

                <div className="sidebar-popup-content">
                    <div className="content-section">
                        <div className="content-section-items">


                            <div className="content-item">
                                <DateTimeInput
                                    props={{
                                        id: "create-paymenthistory-date",
                                        value: date,
                                        placeholder: '',
                                        setValue: (value) => {
                                            setDate(value)
                                        },
                                        isIcon: false,
                                        isLabel: true,
                                        icon: Icons.general.schedule,
                                        isRequired: true,
                                        label: "Date",
                                    }}
                                />
                            </div>
                            <div className="content-item">
                                <TextInput
                                    props={{
                                        id: "create-paymenthistory-amount",
                                        value: amount,
                                        placeholder: '',
                                        setValue: (value) => setAmount(value),
                                        isIcon: false,
                                        type: 'number',
                                        isLabel: true,
                                        setMaxLimit: max_amount || 0,
                                        maxLimitLabel: true,
                                        isRequired: true,
                                        label: "Amount",
                                    }}
                                />
                            </div>
                            <div className="content-item">
                                <SelectInput
                                    props={{
                                        id: "estimation-group-group",
                                        value: payment_method,
                                        placeholder: '',
                                        options: paymentMenthodOptions || [],
                                        setValue: (value, label) => {
                                            setPayment_method(value)
                                        },
                                        isIcon: false,
                                        isLabel: true,
                                        isRequired: true,
                                        label: "Payment method",
                                    }}
                                />

                            </div>
                        </div>
                        <div className="content-section">
                            <div className="content-item">
                                <div id="group-description" className={`additional-item additional-item-active`}>
                                    <div className="head">
                                        <div className="icon" dangerouslySetInnerHTML={{ __html: Icons.general.description }}></div>
                                        <div className="label">Notes</div>
                                    </div>
                                    <div className="item-expanded">
                                        <textarea onChange={(e) => setNotes(e.target.value)}>{notes}</textarea>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>

                <div className="sidebar-popup-footer">
                    <div className="footer-item action-items">
                        <div className="action-preview">
                        </div>
                        <button
                            className="action-btn"
                            type='submit'
                        >

                            <div className="label">Record payment</div>
                        </button>
                    </div>
                </div>
            </form>
        </div>
    )
}


const CreateGRN = ({ props }) => {

    const { projectId, grn_no } = useParams();

    const { menuType = 'create' } = props

    const [project_id, setProject_id] = useState('')
    const [project_name, setProject_name] = useState('')

    const [isLoading, setIsLoading] = useState(false)
    const [warningAlert, setWarningAlert] = useState(false)
    const [warningAlertType, setWarningAlertType] = useState('warning')
    const [apiFailedMessage, setApiFailedMessage] = useState('Error in GRN')

    const navigate = useNavigate()
    const store = useSelector((store) => store)
    const dispatch = useDispatch()
    const { updateState } = new UserAction

    const formSubmitBtnRef = useRef()

    const storeHandler = new StoreHandler({
        dataStack: ['company', 'sor_materials', 'team_users', 'vendors', 'terms_conditions', 'finance_settings', `project-${projectId}`],
        store: store,
        dispatch: dispatch,
        updateState: updateState,
    })

    const LoadStoreData = async () => {
        setIsLoading(true)
        let resolved = await storeHandler.init()
        setIsLoading(false)
    }

    const { isAdmin, menu_permission } = useMenuAuth({ menu: 'purchase--grns', feature_key: 'po_grn' })
    const location = useLocation()

    const [selected_view_settings_value, setSelected_view_settings_value] = useState('')
    const [has_view_access, setHas_view_access] = useState(false)
    const [has_add_access, setHas_add_access] = useState(false)
    const [has_edit_access, setHas_edit_access] = useState(false)
    const [has_delete_access, setHas_delete_access] = useState(false)

    useEffect(() => {

        if (isAdmin) {

            setHas_view_access(true)
            setHas_add_access(true)
            setHas_edit_access(true)
            setHas_delete_access(true)
        }

        else if (menu_permission) {

            if (
                !menu_permission._view
                &&
                (!menu_permission.selected_view_settings_value
                    || menu_permission.selected_view_settings_value == 'no_access')
            ) {
                navigate(`${ReverseProxy['proxyUrl']}/quick-menu`, { state: { from: location }, replace: true })
            }

            if (menuType == 'create' && menu_permission._create == '0') navigate(`${ReverseProxy['proxyUrl']}/builder/projects`, { state: { from: location }, replace: true })
            if (menuType == 'edit' && menu_permission._edit == '0') navigate(`${ReverseProxy['proxyUrl']}/builder/projects`, { state: { from: location }, replace: true })

            setHas_view_access(menu_permission._view == '1')
            setHas_add_access(menu_permission._create == '1')
            setHas_edit_access(menu_permission._edit == '1')
            setHas_delete_access(menu_permission._delete == '1')

            setSelected_view_settings_value(menu_permission.selected_view_settings_value)

        }

    }, [store])



    const stockItemsHandler = new StockItemsHandler()
    const purchaseOrderHandler = new PurchaseOrderHandler()
    const pO_GRN_Handler = new PO_GRN_Handler()
    const commentsHandler = new CommentsHandler()
    const builderAttachments = new BuilderAttachments()

    const [materialsOptions, setMaterialsOptions] = useState([])
    const [materialsOptions_Orginal, setMaterialsOptions_Orginal] = useState([])

    const [taxesOptions, setTaxesOptions] = useState([])
    const [markupsOptions, setMarkupsOptions] = useState([])
    const [currency_type, setCurrency_type] = useState('₹')

    const [id, setId] = useState(Utils.getUniqueId())
    const [GRN_No, setGRN_No] = useState('')
    const [status, setStatus] = useState('')

    const [GRNDate, setGRNDate] = useState(Utils._getLocalDate)


    const [GRNSubTotal, setGRNSubTotal] = useState(0)
    const [GRNPretaxSubTotal, setGRNPretaxSubTotal] = useState(0)
    const [GRNTotal, setGRNTotal] = useState(0)
    const [GRNTotalTax, setGRNTotalTax] = useState(0)
    const [GRNTotalMarkup, setGRNTotalMarkup] = useState(0)
    const [GRNPaid, setGRNPaid] = useState(0)
    const [GRNDue, setGRNDue] = useState(0)

    const [introduction_text, setIntroduction_text] = useState('')
    const [comments, setComments] = useState('')

    const [purchase_order_id, setPurchase_order_id] = useState('')
    const [purchase_order_name, setPurchase_order_name] = useState('')

    const [DueDate, setDueDate] = useState('')
    const [GRN_reference, setGRNReference] = useState('')

    const [vendor_id, setVendor_id] = useState('')
    const [vendor_name, setVendor_name] = useState('')

    const [purchaseOrdersData, setPurchaseOrdersData] = useState([])
    const [purchaseOrdersOptions, setPurchaseOrdersOptions] = useState([])

    const [vendorsOptions, setVendorsOptions] = useState([])

    const [statusOptions, setStatusOptions] = useState([
        {
            value: 0,
            label: "Pending",
            isDefault: false
        },
        {
            value: 1,
            label: "Approved",
            isDefault: false
        },
        {
            value: 2,
            label: "Rejected",
            isDefault: false
        },
    ])

    const [stockItems, setStockItems] = useState([])

    const [termsConditionOptions, setTermsConditionOptions] = useState([])
    const [termsConditionId, setTermsConditionId] = useState("")
    const [termsConditionValue, setTermsConditionValue] = useState("")

    const HandleTermsConditions = (value, label) => {

        setTermsConditionId(value)

        let selected_TC = termsConditionOptions.filter(tc => tc.value == value)[0]
        if (selected_TC) setTermsConditionValue(selected_TC.body)
    }

    const [GRNLoaded, setGRNLoaded] = useState(false)

    const [paymentSplitLimitExists, setPaymentSplitLimitExists] = useState(false)
    const [materialItems, setMaterialItems] = useState([
        {
            id: Utils.getUniqueId(),
            material_id: "",
            material_name: "",
            unitrate: 0,
            tax: 0,
            markup: 0,
            tax_amount: 0,
            markup_amount: 0,
            final_unitrate: 0,
            balance_quantity: 0,
            opening_quantity: 0,
            max_quantity: 0,
            amount: 0,
            final_amount: 0,
            pre_tax_amount: 0,
            value_type: `$`,
            unit_id: '',
            unit_name: '',
            quantity: 0,
            old_quantity: 0,
            description: '',
            notes: ''
        }
    ])

    const [paymentHistory, setPaymentHistory] = useState([])
    const [paymentHistoryStatusOptions, setPaymentHistoryStatusOptions] = useState([
        {
            value: 'draft',
            label: "Draft",
        },
        {
            value: 'send',
            label: "Send",
        },
        {
            value: 'paid',
            label: "Paid",
        },
        {
            value: 'void',
            label: "Void",
        },
    ])
    const [showAddpaymentHistory, setShowAddpaymentHistory] = useState(false)
    const [paymentHistoryAlert, setPaymentHistoryAlert] = useState(false)
    const [paymentHistoryMessage, setPaymentHistoryMessage] = useState('By recording payment method the status of the item will be changed to approved')

    const [AssigneeOptions_Orginal, setAssigneeOptions_Orginal] = useState([])
    const [AssigneeOptions, setAssigneeOptions] = useState([])
    const [SelectedAssignees_Res, setSelectedAssignees_Res] = useState([])
    const [SelectedAssignees, setSelectedAssignees] = useState([])

    const HandleSelectAssignee = (users) => {

        users = users.map(p => {
            let users = AssigneeOptions_Orginal.filter(ph => ph.id == p.id)[0]
            if (!users) return p

            return users
        })

        setSelectedAssignees(users)
    }

    const HandleAddPaymentHistory = (confirmation = false) => {

        if (confirmation) setShowAddpaymentHistory(true)

    }
    const HandleUpdatePaymentHistory = (payment_history, type = 'create') => {

        let _paymentHistory = [...paymentHistory]
        if (type == 'create') _paymentHistory.push(payment_history)
        else {
            let idx = _paymentHistory.findIndex(p => p.id == payment_history.id)
            if (idx != -1) _paymentHistory[idx] = payment_history
        }

        setPaymentHistory(_paymentHistory)

    }


    const HandlePaymentHistoryDelete = (item, idx) => {

        let _paymentHistory = [...paymentHistory]

        _paymentHistory.splice(idx, 1)
        setPaymentHistory(_paymentHistory)
    }

    const HandlePaymentHistoryItemValue = (value, idx, key) => {

        let _paymentHistory = [...paymentHistory]

        _paymentHistory[idx][key] = value

        setPaymentHistory(_paymentHistory)
    }

    const HandleMaterialOptionsSelected = () => {

        let material_items = [...materialItems].map(c => c.material_id)

        let _materialsOptions = [...materialsOptions]

        _materialsOptions = _materialsOptions.map(option => {

            if (material_items.includes(option.value)) option.disabled = true
            else option.disabled = false
            return option
        })

        setMaterialsOptions(_materialsOptions)

    }
    const getStockItems = async () => {

        let filter = {
            projectId: projectId
        }

        let response = await stockItemsHandler.getStockItemsHandler(filter)

        if (response && response.success) {

            setStockItems(response.data)

        } else {
            setWarningAlert(true)
            setWarningAlertType('error')
            setApiFailedMessage(`Error in getting stock items, Please try again!`)
        }
    }
    const HandlePaymentsInputChange = (value, index, key, label) => {

        let materialItems_ = [...materialItems]

        if (key == 'quantity') {

            materialItems_[index]['old_quantity'] = materialItems_[index]['quantity']
            materialItems_[index][key] = value
        }
        else if (key == 'material') {

            materialItems_[index]['material_id'] = value
            materialItems_[index]['material_name'] = label

            HandleMaterialOptionsSelected()

            let stockItem = [...stockItems].filter(s => s.id == value)[0]

            if (stockItem) {
                materialItems_[index]['opening_quantity'] = parseFloat(stockItem.balace_quantity || 0)
            }


            let original_item = materialsOptions_Orginal.filter(m => m.id == value)[0]

            if (original_item) {

                materialItems_[index]['unit_id'] = original_item.unit_id
                materialItems_[index]['unit_name'] = original_item.unit_name

                materialItems_[index]['unitrate'] = parseFloat(original_item.price || 0)
                materialItems_[index]['amount'] = parseFloat(materialItems_[index]['quantity'] || 0) * parseFloat(original_item.price || 0)

            }

        }
        else materialItems_[index][key] = value

        materialItems_[index]['balance_quantity'] = parseFloat(materialItems_[index]['max_quantity'] || 0) - parseFloat(materialItems_[index]['quantity'] || 0)

        let unit_quandity_amount = parseFloat(materialItems_[index]['unitrate'] || 0) * parseFloat(materialItems_[index]['quantity'] || 0)
        let final_unitrate = parseFloat(materialItems_[index]['unitrate'] || 0) * parseFloat(materialItems_[index]['markup'] || 0) / 100

        materialItems_[index]['amount'] = parseFloat(materialItems_[index]['unitrate'] || 0) * parseFloat(materialItems_[index]['quantity'] || 0)

        materialItems_[index]['final_unitrate'] = parseFloat(materialItems_[index]['unitrate'] || 0) + final_unitrate

        materialItems_[index]['markup_amount'] = unit_quandity_amount * parseFloat(materialItems_[index]['markup'] || 0) / 100
        materialItems_[index]['tax_amount'] = (unit_quandity_amount + materialItems_[index]['markup_amount']) * parseFloat(materialItems_[index]['tax'] || 0) / 100

        materialItems_[index]['final_amount'] = parseFloat(materialItems_[index]['tax_amount'] || 0) + parseFloat(materialItems_[index]['amount'] || 0) + parseFloat(materialItems_[index]['markup_amount'] || 0)
        materialItems_[index]['pre_tax_amount'] = parseFloat(materialItems_[index]['amount'] || 0) + parseFloat(materialItems_[index]['markup_amount'] || 0)

        setMaterialItems(materialItems_)
    }
    const HandlePaymentsRemove = (index) => {
        let materialItems_ = [...materialItems]
        if (index == 0) {

            materialItems_[index] = {
                id: Utils.getUniqueId(),
                name: "",
                value: 0,
                amount: 0
            }
            setMaterialItems(materialItems_)
            return
        }
        materialItems_.splice(index, 1)
        setMaterialItems(materialItems_)
    }
    const HandlePaymentsAddNew = () => {

        let materialItems_ = [...materialItems]
        materialItems_.push({
            id: Utils.getUniqueId(),
            material_id: "",
            material_name: "",
            unitrate: 0,
            tax: 0,
            markup: 0,
            tax_amount: 0,
            markup_amount: 0,
            final_unitrate: 0,
            balance_quantity: 0,
            opening_quantity: 0,
            max_quantity: 0,
            amount: 0,
            final_amount: 0,
            pre_tax_amount: 0,
            value_type: `$`,
            unit_id: '',
            unit_name: '',
            quantity: 0,
            old_quantity: 0,
            description: '',
            notes: ''
        })
        setMaterialItems(materialItems_)
    }

    const [showCreatePreview, setShowCreatePreview] = useState(false)

    const HandlepreviewClientInvoice = (e) => {

        if (menuType == 'create') setShowCreatePreview(true)
        else navigate(`/builder/projects/${projectId}/grn/${grn_no}/edit/preview`)

    }
    const HandleCancel = (e) => {

        navigate(`/builder/projects/${projectId}/grn`)
    }

    const HandleSubmit = async (e) => {
        e.preventDefault()

        if (paymentSplitLimitExists) return

        let assignees = SelectedAssignees?.map(p => p.value)


        let update_detials = {
            id: id,
            grn_no: GRN_No,
            projectId: projectId,

            purchase_order_id: String(purchase_order_id || ""),
            purchase_order_name: String(purchase_order_name || ""),

            vendor_id: String(vendor_id),
            vendor_name: String(vendor_name),

            due_date: String(DueDate),
            grn_reference: String(GRN_reference),

            grn_date: GRNDate,
            status: String(status),
            grn_total: String(GRNTotal || 0),
            grn_paid: String(GRNPaid || 0),
            grn_due: String(GRNDue || 0),

            introduction_text: introduction_text || "",
            comments: comments || "",

            terms_condition_id: termsConditionId || "",
            terms_condition: termsConditionValue || "",

            cost_items: materialItems || [],
            attachments: AttachmentsFiles || [],
            assignees: assignees || [],
            payment_history: paymentHistory || [],

        }


        let response = { success: false }

        if (menuType == 'edit') {

            update_detials["id"] = id
            response = await pO_GRN_Handler.updatePO_GRNHandler(update_detials)

        } else {
            response = await pO_GRN_Handler.createPO_GRNHandler(update_detials)

            let update_data = {
                project_id: projectId,
                parent_id: GRN_No,
                parent_name: `GRN ${GRN_No}`,
                parent_type: String(0),
                comments: CommentsItems
            }

            let comments_response = await commentsHandler.createMultiCommentHandler(update_data)

            if (!comments_response.success) {
                setWarningAlert(true)
                setWarningAlertType('error')
                setApiFailedMessage(`Error in Comments, Please try again!`)
            }
        }

        if (response.success) {
            setIsLoading(false)
            navigate(`/builder/projects/${projectId}/grn`)
        }
        else {
            setIsLoading(false)
            setWarningAlert(true)
            setWarningAlertType('error')
            setApiFailedMessage(`Error in GRN, Please try again!`)
        }
    }

    const getNextGRN_No = async () => {

        let response = await pO_GRN_Handler.getNextPO_GRN_NoHandler({
            projectId: projectId
        })

        if (response && response.success) {

            let { grn_no } = response.data

            if (grn_no) setGRN_No(grn_no)

        } else {
            setWarningAlert(true)
            setWarningAlertType('error')
            setApiFailedMessage(`Error in GRN, Please try again!`)
        }
    }


    const LoadGRNDetails = async () => {

        let filter = {
            grn_no: grn_no,
            projectId: projectId,
        }

        setIsLoading(true)

        let response = await pO_GRN_Handler.getPO_GRNsHandler(filter)
        setIsLoading(false)

        if (response.success) {
            let { data } = response

            if (!Array.isArray(data) || !data.length) {
                return navigate(`/builder/projects/${projectId}/grn`)
            }


            let {
                id: ID,
                grn_no,
                purchase_order_id,
                purchase_order_name,
                vendor_id,
                vendor_name,
                grn_reference,
                due_date,
                projectId: projectID,
                grn_date,
                status,
                grn_total,
                grn_paid,
                grn_due,
                introduction_text,
                comments,
                terms_condition_id,
                terms_condition,
                cost_items,
                payment_history,
                attachments,
                assignees
            } = data[0]

            setId(ID)
            setGRN_No(grn_no)
            setStatus(status)
            setIntroduction_text(introduction_text)

            setPurchase_order_id(purchase_order_id)
            setPurchase_order_name(purchase_order_name)

            setVendor_id(vendor_id)
            setVendor_name(vendor_name)

            setDueDate(due_date)
            setGRNReference(grn_reference)

            setTermsConditionId(terms_condition_id)
            setTermsConditionValue(terms_condition)
            setComments(comments)

            setGRNDate(grn_date)

            setGRNTotal(grn_total)
            setGRNPaid(grn_paid)
            setGRNDue(grn_due)

            setMaterialItems(cost_items || [])
            setPaymentHistory(payment_history || [])
            setAttachmentsFiles(attachments || [])
            setSelectedAssignees_Res(assignees || [])

            setGRNLoaded(true)

        }
    }
    useEffect(() => {

        if (AssigneeOptions.length && Array.isArray(SelectedAssignees_Res) && SelectedAssignees_Res.length) {

            let select_assignee = SelectedAssignees_Res.map(p => {
                let assignee = AssigneeOptions.filter(ph => ph.value == p)[0]

                if (!assignee) return p
                return assignee
            })

            setSelectedAssignees(select_assignee)

        }

    }, [AssigneeOptions, SelectedAssignees_Res])


    const getPurchaseOrders = async () => {

        let filter = {
            projectId: projectId,
            grn_no: grn_no
        }

        let response = await purchaseOrderHandler.getPendingPurchaseOrderHandler(filter)

        if (response && response.success) {

            if (!response.data || !Array.isArray(response.data) || !response.data.length) return

            let purchase_orders = response.data.map((opt) => {
                return {
                    value: opt.purchaseID,
                    label: opt.name,
                    isDefault: false
                }
            })

            setPurchaseOrdersData(response.data || [])
            setPurchaseOrdersOptions([
                {
                    value: '',
                    label: 'Select',
                    isDefault: false
                }, ...purchase_orders
            ] || [])


        } else {
            setWarningAlert(true)
            setWarningAlertType('error')
            setApiFailedMessage(`Error in getting purchase orders, Please try again!`)
        }
    }

    useEffect(() => {

        getStockItems()

        if (menuType == 'create') {
            getNextGRN_No()
        }
        else {
            LoadGRNDetails()
            LoadComments({ project_id: projectId, parent_id: grn_no })
        }

        getPurchaseOrders()
        LoadStoreData()
    }, [])

    useEffect(() => {

        let total_amounts = materialItems.reduce((prev, val, i) => {

            return {
                pre_tax_amount: parseFloat(prev.pre_tax_amount || 0) + parseFloat(val.pre_tax_amount || 0),
                tax_amount: parseFloat(prev.tax_amount || 0) + parseFloat(val.tax_amount || 0),
                markup_amount: parseFloat(prev.markup_amount || 0) + parseFloat(val.markup_amount || 0),
                amount: parseFloat(prev.amount || 0) + parseFloat(val.amount || 0),
                final_amount: parseFloat(prev.final_amount || 0) + parseFloat(val.final_amount || 0),
            }

        }, {
            pre_tax_amount: 0,
            tax_amount: 0,
            markup_amount: 0,
            amount: 0,
            final_amount: 0

        })
        setGRNSubTotal(total_amounts.amount)
        setGRNPretaxSubTotal(total_amounts.pre_tax_amount)
        setGRNTotal(total_amounts.final_amount)
        setGRNTotalTax(total_amounts.tax_amount)
        setGRNTotalMarkup(total_amounts.markup_amount)


        let paid_amount = paymentHistory.reduce((prev, val, i) => {

            return prev + parseFloat(val.amount || 0)
        }, 0)
        setGRNPaid(paid_amount)

        let due_amount = total_amounts.final_amount - paid_amount
        due_amount = due_amount < 0 ? 0 : due_amount
        setGRNDue(due_amount)


        HandleMaterialOptionsSelected()
    }, [materialItems, paymentHistory])


    useEffect(() => {

        let _materialItems = [...materialItems].map(item => {

            let stockItem = [...stockItems].filter(s => s.id == item.material_id)[0]

            if (stockItem) {
                item['opening_quantity'] = parseFloat(item.quantity || 0) + parseFloat(stockItem.balace_quantity || 0)
            }

            return item
        })

        setMaterialItems(_materialItems)


    }, [stockItems])

    useEffect(() => {

        if (store && store.user) {

            let { team_users, sor_materials, vendors, settings } = store.user


            if (Array.isArray(team_users)) {

                let _team_users = team_users.filter(opt => opt.status == '1').map((opt) => {
                    return {
                        value: opt.id,
                        label: opt.name,
                    }
                })

                setAssigneeOptions(_team_users)
                setAssigneeOptions_Orginal(team_users)
            }

            if (Array.isArray(sor_materials)) {

                let _sor_materials = sor_materials.map((opt) => {
                    return {
                        value: opt.id,
                        label: opt.name,
                    }
                })
                setMaterialsOptions(_sor_materials)
                setMaterialsOptions_Orginal(sor_materials)
            }
            if (settings && Array.isArray(settings.terms_conditions) && settings.terms_conditions.length) {



                let terms_conditions = settings.terms_conditions.map(tc => {
                    return {
                        id: tc.id,
                        value: tc.id,
                        label: tc.name,
                        body: tc.body,
                        isDefault: false,
                        document: tc.document || []
                    }
                })

                setTermsConditionOptions([
                    {
                        id: "none",
                        value: "none",
                        label: "None",
                        body: "",
                        isDefault: false,
                        document: []
                    },
                    ...terms_conditions
                ])
            }

            if (settings && Array.isArray(settings.finance_settings) && settings.finance_settings.length) {

                let { taxes, markups, overheads, insurance } = settings.finance_settings[0]

                taxes = taxes.filter(opt => opt.isActive).map((opt) => {
                    return {
                        id: opt.id,
                        value: parseFloat(opt.value || 0),
                        label: `${opt.name} (${opt.value_type != '%' ? opt.value_type : ''}${opt.value}${opt.value_type})`,
                        type: opt.value_type,
                        isDefault: opt.isDefault || false
                    }
                })

                taxes = [
                    {
                        id: "nil",
                        value: "0",
                        label: "NIL",
                        type: "%",
                        isDefault: false
                    }, ...taxes
                ]

                setTaxesOptions(taxes || [])


                markups = markups.filter(opt => opt.isActive).map((opt) => {
                    return {
                        id: opt.id,
                        value: parseFloat(opt.value || 0),
                        label: `${opt.name} (${opt.value_type != '%' ? opt.value_type : ''}${opt.value}${opt.value_type})`,
                        type: opt.value_type,
                        isDefault: opt.isDefault || false
                    }
                })

                markups = [
                    {
                        id: "nil",
                        value: "0",
                        label: "NIL",
                        type: "%",
                        isDefault: false
                    }, ...markups
                ]

                setMarkupsOptions(markups || [])
            }
            if (vendors && Array.isArray(vendors) && vendors.length) {

                let vendor_options = vendors.filter(opt => opt.status == '1').map((opt) => {
                    return {
                        value: opt.vendorID,
                        label: opt.name,
                        isDefault: false
                    }
                })

                setVendorsOptions(vendor_options || [])

            }

        }

    }, [store])

    const HandlePO_Selected = (id, label) => {

        setPurchase_order_id(id)
        if (id) setPurchase_order_name(label)
        else setPurchase_order_name("")

        let purchase_order = purchaseOrdersData.filter(p => p.purchaseID == id)[0]

        if (!purchase_order) return

        let { cost_items, vendor_id, vendor_name } = purchase_order

        cost_items = cost_items.map(c => {

            c.max_quantity = parseFloat(c.balance_po_quantity || 0)
            c.quantity = 0
            c.final_amount = 0
            c.pre_tax_amount = 0
            c.amount = 0

            return c
        })

        if (vendor_id && vendor_name) {
            setVendor_id(vendor_id)
            setVendor_name(vendor_name)
        }

        setMaterialItems(cost_items)

    }


    const [ShowSelectedPreviewImage, setShowSelectedPreviewImage] = useState(false)
    const [SelectedPreviewImage, setSelectedPreviewImage] = useState(undefined)
    const AttachmentsInputRef = useRef()
    const [AttachmentsFiles, setAttachmentsFiles] = useState([])

    const HandleTriggerAddAttachment = (e) => {
        if (AttachmentsInputRef.current) AttachmentsInputRef.current.click()
    }
    const HandleAddAttachment = async (e) => {

        let file = e.target.files[0]

        if (!file) return

        const formData = new FormData();

        formData.append('projectId', projectId);
        formData.append('attachment', file);
        formData.append('linked_type', '4');
        formData.append('linked_item_id', id);

        setIsLoading(true)

        let upload_response = await builderAttachments.createAttachmentHandler(formData)

        setIsLoading(false)

        if (upload_response.success) {

            let { public_id, url } = upload_response.data

            if (!public_id || !url) return

            let _AttachmentsFiles = [...AttachmentsFiles]

            _AttachmentsFiles.push({
                public_id: public_id,
                original_filename: file.name,
                bytes: file.size,
                format: file.type,
                file_url: url,
                linked_type: '4',
                linked_item_id: id,
            })

            setAttachmentsFiles(_AttachmentsFiles)

        } else {
            setWarningAlert(true)
            setWarningAlertType('error')
            setApiFailedMessage(`Error in attachment, Please try again!`)
        }
    }
    const HandleDeleteAttachment = async (idx) => {

        let _AttachmentsFiles = [...AttachmentsFiles]

        setIsLoading(true)

        let upload_response = await builderAttachments.deleteAttachmentHandler({
            public_id: _AttachmentsFiles[idx].public_id,
            projectId: projectId,
        })

        setIsLoading(false)

        if (upload_response.success) {
            _AttachmentsFiles.splice(idx, 1)
            setAttachmentsFiles(_AttachmentsFiles)
        }
        else {
            setWarningAlert(true)
            setWarningAlertType('error')
            setApiFailedMessage(`Error in attachment, Please try again!`)
        }
    }

    const HandleSelectPreviewImage = (image) => {
        setShowSelectedPreviewImage(true)
        setSelectedPreviewImage({
            name: image.original_filename,
            file_url: image.file_url,
        })
    }
    const HandleHideSelectPreviewImage = (confirmation) => {
        setShowSelectedPreviewImage(confirmation)
        setSelectedPreviewImage(undefined)
    }
    const [CommentsItems, setCommentsItems] = useState([])

    const [CommentsInput, setCommentsInput] = useState("")

    const LoadComments = async ({ project_id, parent_id }) => {

        console.log(project_id, parent_id, 'parent_id');
        if (!project_id || !parent_id) return

        let filter = {
            project_id: project_id,
            parent_id: parent_id
        }

        setIsLoading(true)

        let response = await commentsHandler.getCommentsHandler(filter)
        setIsLoading(false)

        if (response.success) {
            let { data } = response

            if (!Array.isArray(data)) return

            console.log(data, 'data');

            setCommentsItems(data)
        }
    }

    const HandleAddComment = async () => {
        let _CommentsItems = [...CommentsItems]

        let userdetials = JSON.parse(localStorage.getItem('userdetials'))

        _CommentsItems.push({
            id: Utils.getUniqueId(),
            created_at: new Date().getTime(),
            created_by: userdetials.id,
            created_by_name: userdetials.name,
            message: CommentsInput,
            parent_id: GRN_No,
            parent_name: `GRN ${GRN_No}`,
            parent_type: 0,
            project_id: projectId,
            company_id: userdetials.company_id,
        })

        setCommentsItems(_CommentsItems)
        setCommentsInput("")

        if (menuType == 'create') return

        let update_data = {
            project_id: projectId,
            parent_id: GRN_No,
            parent_name: `GRN ${GRN_No}`,
            parent_type: String(0),
            message: CommentsInput
        }

        let response = await commentsHandler.createCommentHandler(update_data)

        if (!response.success) {
            setWarningAlert(true)
            setWarningAlertType('error')
            setApiFailedMessage(`Error in attachment, Please try again!`)
        }
    }

    const HandleDeleteComment = async (id, idx) => {
        let _CommentsItems = [...CommentsItems]

        _CommentsItems.splice(idx, 1)

        setCommentsItems(_CommentsItems)

        if (menuType == 'create') return

        let update_data = {
            project_id: projectId,
            ids: [id]
        }

        let response = await commentsHandler.deleteCommentHandler(update_data)

        if (!response.success) {
            setWarningAlert(true)
            setWarningAlertType('error')
            setApiFailedMessage(`Error in Comments, Please try again!`)
        }
    }


    return (
        <>


            {isLoading ?
                <Loading
                    props={{
                        isMainLogo: false,
                        isLabel: true
                    }} />
                : null}
            {warningAlert ?

                <SystemToastPopup
                    props={{
                        type: warningAlertType,
                        message: apiFailedMessage || "Error in GRN",
                        callback: (confirmation) => setWarningAlert(false)
                    }} />


                : null}


            {ShowSelectedPreviewImage ?

                <ImagePreviewPopup
                    props={{
                        heading: `Preview - ${SelectedPreviewImage.name}`,
                        image: SelectedPreviewImage.file_url,
                        message: "Invalid Inputs, Please try again with vaild Inputs!",
                        callback: (confirmation) => HandleHideSelectPreviewImage(confirmation)
                    }} />

                : null}

            {paymentHistoryAlert ?

                <AlertPopup
                    props={{
                        type: "success",
                        actionBtnOption: { icon: Icons.general.warning, label: "Ok" },
                        heading: "Create Payment Record",
                        message: paymentHistoryMessage || "By recording payment method the status of the item will be changed to approved",
                        callback: (confirmation) => {
                            setPaymentHistoryAlert(false)
                            HandleAddPaymentHistory(confirmation)
                        }
                    }} />

                : null}
            {showAddpaymentHistory ?

                <AddPaymentHistory
                    props={{
                        type: "create",
                        existItemData: undefined,
                        max_amount: GRNDue || 0,
                        close: () => { setShowAddpaymentHistory(false) },
                        updateCallback: (data) => {
                            HandleUpdatePaymentHistory(data, 'create')
                            setShowAddpaymentHistory(false)

                        }
                    }} />

                : null}

            {showCreatePreview ?
                <PreviewGRN
                    props={{
                        menuType: 'edit',
                        GRNLoaded: true,
                        grndetials: {
                            id: id,
                            grn_no: GRN_No,
                            projectId: projectId,

                            purchase_order_id,
                            purchase_order_name,

                            vendor_id,
                            vendor_name,
                            due_date: DueDate,
                            grn_reference: GRN_reference,

                            grn_date: GRNDate,
                            status,
                            grn_total: GRNTotal,
                            grn_paid: GRNPaid,
                            grn_due: GRNDue,

                            introduction_text: introduction_text || "",
                            comments: comments || "",

                            terms_condition_id: termsConditionId || "",
                            terms_condition: termsConditionValue || "",

                            cost_items: materialItems || [],
                            payment_history: paymentHistory || [],
                        },
                        saveCallback: () => {
                            formSubmitBtnRef.current?.click()
                            setShowCreatePreview(false)
                        },
                        close: () => { setShowCreatePreview(false) }
                    }}
                />
                : null}

            <Routes>
                <Route path='/preview'
                    element={
                        <PreviewGRN
                            props={{
                                menuType: 'edit',
                                GRNLoaded: GRNLoaded,
                                grndetials: {

                                    id: id,
                                    grn_no: GRN_No,
                                    projectId: projectId,

                                    purchase_order_id,
                                    purchase_order_name,

                                    vendor_id,
                                    vendor_name,
                                    due_date: DueDate,
                                    grn_reference: GRN_reference,

                                    grn_date: GRNDate,
                                    status,
                                    grn_total: GRNTotal,
                                    grn_paid: GRNPaid,
                                    grn_due: GRNDue,

                                    introduction_text: introduction_text || "",
                                    comments: comments || "",

                                    terms_condition_id: termsConditionId || "",
                                    terms_condition: termsConditionValue || "",

                                    cost_items: materialItems || [],
                                    payment_history: paymentHistory || [],
                                },
                                saveCallback: (e) => {
                                    formSubmitBtnRef.current?.click()
                                    if (menuType == 'create') navigate(`/builder/projects/${projectId}/grn/create`)
                                    else navigate(`/builder/projects/${projectId}/grn/${grn_no}/edit`)
                                },
                                close: () => {

                                    if (menuType == 'create') navigate(`/builder/projects/${projectId}/grn/create`)
                                    else navigate(`/builder/projects/${projectId}/grn/${grn_no}/edit`)

                                }
                            }}
                        />}>
                </Route>

            </Routes>

            <div className="project-create_client_invoice-main" id='project-create_client_invoice-main'>
                <div className="create_client_invoice-panel-content">

                    <div className="panel-content-header">
                        <div className="left">
                            <div className="icon"
                                dangerouslySetInnerHTML={{ __html: Icons.general.back_arrow }}
                                onClick={(e) => HandleCancel(e)}
                            ></div>
                            <div className="title">{menuType == 'create' ? 'Create' : 'Edit'} GRN</div>
                        </div>
                        <div className="right">
                            <div className="total-items">
                                <div className={`total-item ${!GRNTotal ? 'total-item-diable' : ''}`}>
                                    <div className="label">Total</div>
                                    <div className="value">{currency_type}{parseFloat(GRNTotal).toFixed(2)}</div>
                                </div>
                                <div className={`total-item ${!GRNPaid ? 'total-item-diable' : ''}`}>
                                    <div className="label">Paid</div>
                                    <div className="value">{currency_type}{parseFloat(GRNPaid).toFixed(2)}</div>
                                </div>
                                <div className={`total-item ${!GRNDue ? 'total-item-diable' : ''}`}>
                                    <div className="label">Due</div>
                                    <div className="value">{currency_type}{parseFloat(GRNDue).toFixed(2)}</div>
                                </div>
                            </div>
                            <div className="preview-button" onClick={() => HandlepreviewClientInvoice()}>
                                <div
                                    className="icon"
                                    dangerouslySetInnerHTML={{ __html: Icons.general.eye_open }}
                                ></div>
                                <div className="label">Preview</div>
                            </div>
                        </div>
                    </div>
                    <form className="panel-content-sections" onSubmit={HandleSubmit}>
                        <div className="create-client_invoicecontent-main">

                            <div className="create-client_invoicesections">

                                <div className="create-client_invoicesection">
                                    <div className="section-input-items">
                                        <div className="section-input-item">
                                            <TextInput
                                                props={{
                                                    id: "create-invoice-GRN_No",
                                                    value: GRN_No,
                                                    placeholder: '',
                                                    setValue: (value) => { },
                                                    isIcon: false,
                                                    isLabel: true,
                                                    isRequired: true,
                                                    readOnly: true,
                                                    label: "GRN No",
                                                }}
                                            />
                                        </div>

                                        <div className="section-input-item">
                                            <SelectInput
                                                props={{
                                                    id: "create-invoice-po",
                                                    value: purchase_order_id,
                                                    placeholder: '',
                                                    readOnly: false,
                                                    options: purchaseOrdersOptions || [],
                                                    setValue: (value, label) => HandlePO_Selected(value, label),
                                                    isStatus: false,
                                                    isIcon: false,
                                                    isLabel: true,
                                                    isRequired: false,
                                                    label: "Pending PO",
                                                }}
                                            />
                                        </div>
                                        <div className="section-input-item">
                                            <SelectInput
                                                props={{
                                                    id: "create-invoice-vendor",
                                                    value: vendor_id,
                                                    placeholder: '',
                                                    readOnly: false,
                                                    options: vendorsOptions || [],
                                                    setValue: (value, label) => {
                                                        setVendor_id(value)
                                                        setVendor_name(label)
                                                    },
                                                    isStatus: false,
                                                    isIcon: false,
                                                    isLabel: true,
                                                    isRequired: true,
                                                    label: "Vendor",
                                                }}
                                            />
                                        </div>

                                        <div className="section-input-item">
                                            <TextInput
                                                props={{
                                                    id: "create-invoice-GRN_reference",
                                                    value: GRN_reference,
                                                    placeholder: '',
                                                    setValue: (value) => {
                                                        setGRNReference(value)
                                                    },
                                                    isIcon: false,
                                                    isLabel: true,
                                                    isRequired: false,
                                                    label: "Reference No",
                                                }}
                                            />
                                        </div>

                                    </div>
                                    <div className="section-input-items">
                                        <div className="section-input-item" style={{ height: 'max-content' }}>
                                            <MultiSelectInput
                                                props={{
                                                    id: 'assignee-multi-select-input',
                                                    placeholder: 'Select Assignees',
                                                    isRequired: true,
                                                    value: SelectedAssignees,
                                                    options: AssigneeOptions,
                                                    isIcon: false,
                                                    avoidDuplicate: true,
                                                    icon: '',
                                                    isLabel: true,
                                                    label: 'Assignees',
                                                    setValue: (value) => HandleSelectAssignee(value),
                                                }}
                                            />
                                        </div>
                                        <div className="section-input-item">
                                            <DateTimeInput
                                                props={{
                                                    id: "create-invoice-due_date",
                                                    value: DueDate,
                                                    placeholder: '',
                                                    setValue: (value) => {
                                                        setDueDate(value)
                                                    },
                                                    isIcon: false,
                                                    isLabel: true,
                                                    icon: Icons.general.schedule,
                                                    isRequired: true,
                                                    label: "Payment Due Date",
                                                }}
                                            />
                                        </div>

                                        <div className="section-input-item">
                                            <SelectInput
                                                props={{
                                                    id: "create-invoice-status",
                                                    value: status,
                                                    placeholder: '',
                                                    readOnly: false,
                                                    options: statusOptions || [],
                                                    setValue: (value, label) => {
                                                        setStatus(value)
                                                    },
                                                    isStatus: false,
                                                    isIcon: false,
                                                    isLabel: true,
                                                    isRequired: false,
                                                    label: "Status",
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>

                                <div className="create-client_invoicesection" id={`create-client_invoiceintroduction_text`}>

                                    <div className={`createproposal-description-main`}>
                                        <div className="head">
                                            <div className="title">
                                                <div className="icon" dangerouslySetInnerHTML={{ __html: Icons.general.description }}></div>
                                                <div className="label">Description</div>
                                            </div>
                                        </div>
                                        <div className="item-expanded">
                                            <ReactQuill
                                                theme="snow"
                                                value={introduction_text || ""}
                                                onChange={(value) => setIntroduction_text(value)}
                                            />
                                        </div>
                                    </div>

                                </div>

                                <div className="create-client_invoicesection" id={`create-client_invoicepayment_schedule`}>
                                    <div className={`createproposal-description-main`}>
                                        <div className="head">
                                            <div className="title">
                                                <div className="label">Materials</div>
                                                <div className="icon" dangerouslySetInnerHTML={{ __html: Icons.general.info }}></div>
                                            </div>
                                        </div>

                                        <div className="invoice-payment-splits-main">
                                            <div className="invoice-payment-splits-header">
                                                <div className="header-item-name">Item</div>
                                                <div className="header-item-quantity">Quantity</div>
                                                <div className="header-item-unitrate">Unitrate ({currency_type || '₹'})</div>
                                                <div className="header-item-tax">Tax (%)</div>
                                                <div className="header-item-markup">Markup (%)</div>
                                                <div className="header-item-amount">Amount</div>
                                                <div className="header-item-action">Action</div>
                                            </div>
                                            <div className="invoice-payment-splits-content">
                                                {materialItems.map((item, index) => (
                                                    <div className="invoice-payment-content-item">
                                                        <div className="invoice-payment-content-items">
                                                            <div className="invoice-payment-content-name">

                                                                <SelectInput
                                                                    props={{
                                                                        id: `invoice-payment-material-${index}`,
                                                                        value: item.material_id,
                                                                        placeholder: 'Select material',
                                                                        options: materialsOptions || [],
                                                                        setValue: (value, label) => HandlePaymentsInputChange(value, index, 'material', label),
                                                                        isIcon: false,
                                                                        isLabel: false,
                                                                        isRequired: false,
                                                                        label: "Material",
                                                                    }}
                                                                />
                                                            </div>
                                                            <div className="invoice-payment-content-quantity">
                                                                <TextInput
                                                                    props={{
                                                                        id: `invoice-payment-quantity-${index}`,
                                                                        value: item.quantity,
                                                                        placeholder: 'Quantity',
                                                                        setValue: (value) => HandlePaymentsInputChange(value, index, 'quantity'),
                                                                        isIcon: false,
                                                                        isLabel: false,
                                                                        isRequired: true,
                                                                        type: 'number',
                                                                        setMaxLimit: item.max_quantity || undefined,
                                                                        maxLimitLabel: item.max_quantity != undefined,
                                                                        label: "Quantity",
                                                                    }}
                                                                />
                                                                {item.unit_name}

                                                            </div>

                                                            <div className="invoice-payment-content-unitrate">

                                                                <TextInput
                                                                    props={{
                                                                        id: `invoice-payment-unitrate-${index}`,
                                                                        value: item.unitrate,
                                                                        placeholder: 'Unitrate',
                                                                        setValue: (value) => HandlePaymentsInputChange(value, index, 'unitrate'),
                                                                        isIcon: false,
                                                                        isLabel: false,
                                                                        isRequired: true,
                                                                        type: 'number',
                                                                        label: "Unitrate",
                                                                    }}
                                                                />
                                                            </div>
                                                            <div className="invoice-payment-content-tax">

                                                                <div className="section-input-item">
                                                                    <SelectInput
                                                                        props={{
                                                                            id: `invoice-payment-tax-${index}`,
                                                                            value: item.tax,
                                                                            placeholder: 'Select tax',
                                                                            readOnly: false,
                                                                            options: taxesOptions || [],
                                                                            setValue: (value) => HandlePaymentsInputChange(value, index, 'tax'),
                                                                            isStatus: false,
                                                                            isIcon: false,
                                                                            isLabel: false,
                                                                            isRequired: false,
                                                                            label: "Tax",
                                                                        }}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="invoice-payment-content-markup">
                                                                <SelectInput
                                                                    props={{
                                                                        id: `invoice-payment-markup-${index}`,
                                                                        value: item.markup,
                                                                        placeholder: 'Select markup',
                                                                        readOnly: false,
                                                                        options: markupsOptions || [],
                                                                        setValue: (value) => HandlePaymentsInputChange(value, index, 'markup'),
                                                                        isStatus: false,
                                                                        isIcon: false,
                                                                        isLabel: false,
                                                                        isRequired: false,
                                                                        label: "Markups",
                                                                    }}
                                                                />
                                                            </div>
                                                            <div className="invoice-payment-content-amount">{currency_type}{item.final_amount}</div>

                                                            <div className="invoice-payment-content-action">
                                                                <div
                                                                    className="action-remove"
                                                                    onClick={(e) => HandlePaymentsRemove(index)}
                                                                >
                                                                    <div className="icon" dangerouslySetInnerHTML={{ __html: Icons.general.delete_btn }}></div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="invoice-payment-content-items">
                                                            <div className="invoice-payment-content-description">
                                                                <TextArea
                                                                    props={{
                                                                        id: `invoice-payment-description-${index}`,
                                                                        value: item.description,
                                                                        placeholder: 'Description',
                                                                        setValue: (value) => HandlePaymentsInputChange(value, index, 'description'),
                                                                        isIcon: false,
                                                                        isLabel: false,
                                                                        isRequired: false,
                                                                        label: "Description",
                                                                    }}
                                                                />
                                                            </div>

                                                        </div>
                                                    </div>
                                                ))}
                                            </div>

                                            <div
                                                className="invoice-payment-splits-action-buttons"
                                            >
                                                <div
                                                    className="add-button"
                                                    onClick={(e) => HandlePaymentsAddNew()}
                                                >
                                                    <div
                                                        className="icon"
                                                        dangerouslySetInnerHTML={{ __html: Icons.general.add_btn }}
                                                    ></div>
                                                    <div className="label">Add new item</div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>

                                <div className="create-proposal-section" id={`create-proposal-summary`}>
                                    <div className="createproject-section proposal-left-section">
                                        <div className="proposal-summary-totals proposal-summary-title">
                                            <div className="title">Summary</div>
                                        </div>
                                        <div className="proposal-summary-totals proposal-summary-items">

                                            <div className="proposal-summary-item">
                                                <div className="proposal-summary-item-content">

                                                    <div className="item">
                                                        <div className="title">Sub Total</div>
                                                        <div className="quantity"></div>
                                                        <div className="amount">
                                                            {currency_type || '₹'} {parseFloat(GRNSubTotal || 0).toFixed(2)}
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                            <div className="proposal-summary-item">
                                                <div className="proposal-summary-item-content">

                                                    <div className="item">
                                                        <div className="title">Markup</div>
                                                        <div className="quantity"></div>
                                                        <div className="amount">
                                                            {currency_type || '₹'} {parseFloat(GRNTotalMarkup || 0).toFixed(2)}
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                            <div className="proposal-summary-item">
                                                <div className="proposal-summary-item-content">

                                                    <div className="item">
                                                        <div className="title">PreTax Total</div>
                                                        <div className="quantity"></div>
                                                        <div className="amount">
                                                            {currency_type || '₹'} {parseFloat(GRNPretaxSubTotal || 0).toFixed(2)}
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                            <div className="proposal-summary-item">
                                                <div className="proposal-summary-item-content">

                                                    <div className="item">
                                                        <div className="title">Tax</div>
                                                        <div className="quantity"></div>
                                                        <div className="amount">
                                                            {currency_type || '₹'} {parseFloat(GRNTotalTax || 0).toFixed(2)}
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>

                                            <div className="proposal-summary-item">
                                                <div className="proposal-summary-item-content">

                                                    <div className="item">
                                                        <div className="title">Total</div>
                                                        <div className="quantity"></div>
                                                        <div className="amount">
                                                            {currency_type || '₹'} {parseFloat(GRNTotal || 0).toFixed(2)}
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                            <div className="proposal-summary-item">
                                                <div className="proposal-summary-item-content">

                                                    <div className="item" style={{ fontSize: '24px' }}>
                                                        <div className="title">Paid</div>
                                                        <div className="quantity"></div>
                                                        <div className="amount">
                                                            {currency_type || '₹'} {parseFloat(GRNPaid || 0).toFixed(2)}
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                            <div className="proposal-summary-item">
                                                <div className="proposal-summary-item-content">

                                                    <div className="item" style={{ fontSize: '24px' }}>
                                                        <div className="title">Due</div>
                                                        <div className="quantity"></div>
                                                        <div className="amount">
                                                            {currency_type || '₹'} {parseFloat(GRNDue || 0).toFixed(2)}
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="create-client_invoicesection" id={`create-grn_payment_history`}>
                                    <div className={`createproposal-description-main`}>
                                        <div className="head">
                                            <div className="title">
                                                <div className="label">Payments History ({paymentHistory.length})</div>
                                                <div className="icon" dangerouslySetInnerHTML={{ __html: Icons.general.info }}></div>
                                            </div>
                                        </div>
                                        <div className="head">
                                            <div className="title">
                                                <div className="label"></div>
                                            </div>
                                            {GRNDue > 0 ?
                                                <div className={`add-paymenthistory-btn ${!paymentHistory.length ? 'add-paymenthistory-disable' : ''}`} onClick={() => HandleAddPaymentHistory(true)}>
                                                    <div className="icon" dangerouslySetInnerHTML={{ __html: Icons.general.add_btn }}></div>
                                                    <div className="label">Record Payments</div>
                                                </div>
                                                : ''}

                                        </div>
                                        {!paymentHistory.length ?
                                            <div className="no-paymenthistory-main">

                                                <div className="no-paymenthistory-vector">
                                                    <div className="icon" dangerouslySetInnerHTML={{ __html: Icons.general.no_records }}></div>
                                                    <div className="label">No payments added yet</div>
                                                </div>
                                                {GRNDue > 0 ?
                                                    <div className="add-paymenthistory-btn" onClick={() => setPaymentHistoryAlert(true)}>
                                                        <div className="icon" dangerouslySetInnerHTML={{ __html: Icons.general.add_btn }}></div>
                                                        <div className="label">Record Payments</div>
                                                    </div>
                                                    : ''}

                                            </div>
                                            : ''}
                                        {paymentHistory.length ?

                                            <div className="paymenthistory-table-main">
                                                <div className="paymenthistory-table-header">
                                                    <div className="header-item table-item-sno">S:No</div>
                                                    <div className="header-item table-item-date">Date</div>
                                                    <div className="header-item table-item-amount">Amount</div>
                                                    <div className="header-item table-item-payment_method">Payment Method</div>
                                                    <div className="header-item table-item-notes">Notes</div>
                                                    <div className="header-item table-item-status">Status</div>
                                                    <div className="header-item table-item-action" >Action</div>
                                                </div>
                                                <div className="paymenthistory-table-content">

                                                    {paymentHistory?.map((item, i) => (
                                                        <div
                                                            className="content-row"
                                                            key={`paymenthistory-table-item-${item.id}`}
                                                        >
                                                            <div className="content-row-item table-item-sno">{i + 1}</div>
                                                            <div className="content-row-item table-item-date">{item.date}</div>
                                                            <div className="content-row-item table-item-amount">{currency_type} {item.amount}</div>
                                                            <div className="content-row-item table-item-payment_method">{item.payment_method}</div>
                                                            <div className="content-row-item table-item-notes">
                                                                <TextInput
                                                                    props={{
                                                                        id: `paymenthistory-item-notes-${item.id}`,
                                                                        value: item.notes,
                                                                        placeholder: 'Internal notes',
                                                                        setValue: (value) => {
                                                                            HandlePaymentHistoryItemValue(value, i, 'notes')
                                                                        },
                                                                        isIcon: false,
                                                                        isLabel: false,
                                                                        isRequired: false,
                                                                        label: "",
                                                                    }}
                                                                />
                                                            </div>
                                                            <div className="content-row-item table-item-status">
                                                                <SelectInput
                                                                    props={{
                                                                        id: `paymenthistory-item-status-${item.id}`,
                                                                        value: item.status,
                                                                        placeholder: '',
                                                                        readOnly: false,
                                                                        options: paymentHistoryStatusOptions || [],
                                                                        setValue: (value, label) => {
                                                                            HandlePaymentHistoryItemValue(value, i, 'status')
                                                                        },
                                                                        isStatus: false,
                                                                        isIcon: false,
                                                                        isLabel: false,
                                                                        isRequired: false,
                                                                        label: "",
                                                                    }}
                                                                />
                                                            </div>
                                                            <div className="content-row-item table-item-action" >

                                                                <div

                                                                    className="icon"
                                                                    dangerouslySetInnerHTML={{ __html: Icons.general.delete_btn }}
                                                                    onClick={() => HandlePaymentHistoryDelete(item, i)}
                                                                ></div>
                                                            </div>

                                                        </div>
                                                    ))}

                                                </div>

                                            </div>

                                            : ''}


                                    </div>
                                </div>

                                <div className="create-client_invoicesection" id={`create-proposal-attachments`}>
                                    <div className={`createproposal-description-main`}>
                                        <div className="head">
                                            <div className="title">
                                                <div className="icon" dangerouslySetInnerHTML={{ __html: Icons.general.attachments }}></div>
                                                <div className="label">Attachments</div>
                                            </div>
                                        </div>
                                        <div className="attachments-section-main">
                                            <div className="attachments-section-files">
                                                <div className="attachment-input" onClick={HandleTriggerAddAttachment}>
                                                    <input type="file" onChange={HandleAddAttachment} ref={AttachmentsInputRef} accept='.png,.jpg,.jpeg' />
                                                    <div className="attachment-add-icon"
                                                        dangerouslySetInnerHTML={{ __html: Icons.general.add_attachments_plus }}
                                                    ></div>
                                                    <div className="attachment-label">Upload</div>
                                                    <div className="attachment-limit">Max limit: 5MB</div>
                                                </div>
                                                {AttachmentsFiles?.map((file, idx) => (
                                                    <div
                                                        className="attachment-file"
                                                        key={file.public_id}
                                                        id={`file-${file.public_id}`}
                                                    >
                                                        <div
                                                            className="attachment-delete-btn"
                                                            dangerouslySetInnerHTML={{ __html: Icons.general.close }}
                                                            onClick={() => HandleDeleteAttachment(idx)}
                                                        ></div>
                                                        <div className="attachment-file-preview" onClick={() => HandleSelectPreviewImage(file)}>
                                                            <img src={file.file_url} alt="attachment-file" />
                                                        </div>
                                                        <div className="attachment-file-footer">
                                                            <div className="file-name">{file.original_filename}</div>
                                                            <div
                                                                className="file-download"
                                                                dangerouslySetInnerHTML={{ __html: Icons.general.download }}
                                                            ></div>
                                                        </div>

                                                    </div>
                                                ))}


                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="create-client_invoicesection" id={`create-grn_terms_conditions`}>
                                    <div className={`createproposal-description-main`}>
                                        <div className="head">
                                            <div className="title">
                                                <div className="label">Terms and conditions</div>
                                                <div className="icon" dangerouslySetInnerHTML={{ __html: Icons.general.info }}></div>
                                            </div>
                                        </div>
                                        <div className="select-input">
                                            <div className="section-input-items">
                                                <div className="section-input-item">
                                                    <SelectInput
                                                        props={{
                                                            id: "create-client_invoiceterms_conditions-input",
                                                            value: termsConditionId,
                                                            placeholder: '',
                                                            readOnly: false,
                                                            options: termsConditionOptions || [],
                                                            setValue: (value, label) => HandleTermsConditions(value, label),
                                                            isStatus: false,
                                                            isIcon: false,
                                                            isLabel: false,
                                                            isRequired: false,
                                                            label: "",
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="terms-condition-result" dangerouslySetInnerHTML={{ __html: termsConditionValue }}></div>
                                    </div>
                                </div>

                                <div className="create-proposal-section">

                                    <div className={`createproposal-description-main`}>
                                        <div className="head">
                                            <div className="label">Comments</div>
                                        </div>
                                        <div className="comments-section-main">
                                            <div className="comments-section-items">

                                                {CommentsItems.map((item, idx) => (

                                                    <div
                                                        className="comments-item-main"
                                                        key={`comments-item-${item.id}`}
                                                        id={`comments-item-${item.id}`}
                                                    >
                                                        <div className="comments-item-usericon">
                                                            {item.created_by_name.charAt(0)}
                                                        </div>
                                                        <div className="comments-item-detials">
                                                            <div className="detials-head">
                                                                <div className="left">
                                                                    <div className="name">{item.created_by_name}</div>
                                                                    <div className="time">{Utils.getLocalFullDateLabel(item.created_at)}</div>
                                                                </div>
                                                                <div className="right">
                                                                    <div
                                                                        className="delete-icon"
                                                                        onClick={() => HandleDeleteComment(item.id, idx)}
                                                                        dangerouslySetInnerHTML={{ __html: Icons.general.delete_btn }}></div>
                                                                </div>
                                                            </div>
                                                            <div className="detials-message">{item.message}</div>
                                                        </div>
                                                    </div>


                                                ))}


                                                <div className="comments-input-main">
                                                    <div className="comments-input-usericon">
                                                        RT
                                                    </div>
                                                    <div className="comments-input comments-input-active">
                                                        <div className="comments-input-textarea">
                                                            <textarea
                                                                placeholder='Add a comment...'
                                                                value={CommentsInput}
                                                                onChange={(e) => setCommentsInput(e.target.value)}
                                                            ></textarea>
                                                        </div>
                                                        <div className={`comments-input-controls ${CommentsInput.length ? `comments-input-controls-active` : ''}`}>
                                                            <div
                                                                className={`comments-input-control-sendmsg`}
                                                                onClick={() => HandleAddComment()}
                                                            >
                                                                <div className="icon" dangerouslySetInnerHTML={{ __html: Icons.general.send_message }}></div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                            <div className="create-client_invoicefooter">
                                <div className="footer-item action-items">

                                    <div className="action-cancel" onClick={(e) => HandleCancel(e)}>
                                        Cancel
                                    </div>
                                    <button
                                        className={`action-btn`}
                                        type='submit'
                                        ref={formSubmitBtnRef}
                                    >

                                        <div className="label">Save</div>
                                    </button>
                                </div>
                            </div>

                        </div>
                    </form>
                </div>

            </div>
        </>
    )

}

export default CreateGRN;