

import React, { useEffect, useState, useRef, useCallback, useMemo, StrictMode } from 'react'
import { Route, Routes, BrowserRouter, Link, useLocation, Navigate, Outlet, useNavigate, useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux'
import UserAction from '../../redux/action/userAction'

import Icons from '../../assets/Icons'
import Images from '../../assets/Images'
import { CostInput, TextInput, SelectInput, DateTimeInput, TextArea, RadioInput, MultiSelectInput } from '../../components/Inputs'
import Utils from '../../utils'

import AlertPopup from '../../components/AlertPopup'
import SystemToastPopup from '../../components/SystemToastPopup'
import Loading from '../../components/Loading'

const SharePurchaseRequestPopup = ({ props }) => {

    const { requestId, selected_vendors, close } = props

    const { projectId } = useParams()

    const navigate = useNavigate()
    const store = useSelector((store) => store)
    const dispatch = useDispatch()
    const { updateState } = new UserAction

    const [isLoading, setIsLoading] = useState(false)
    const [warningAlert, setWarningAlert] = useState(false)
    const [warningAlertType, setWarningAlertType] = useState('warning')
    const [apiFailedMessage, setApiFailedMessage] = useState('Error in RFQ')

    const [RequestId, setRequestId] = useState(requestId)
    const [SelectedVendors, setSelectedVendors] = useState(selected_vendors)

    const HandlePopupSubmit = async (e) => {
        e.preventDefault()

        close()

    }

    const HandleCopyLink = (item) => {

        const link = `${window.location.origin}/send-rfq?rfq_id=${RequestId}&ven_id=${item.id}`
        navigator.clipboard.writeText(link)

        setWarningAlert(true)
        setWarningAlertType('success')
        setApiFailedMessage(`Link copied to clipboard for ${item.label}`)

    }


    return (
        <>
            {isLoading ?
                <Loading
                    props={{
                        isMainLogo: false,
                        isLabel: true
                    }} />
                : null}
            {warningAlert ?

                <SystemToastPopup
                    props={{
                        type: warningAlertType,
                        message: apiFailedMessage || "Error in RFQ, Please try again!",
                        callback: (confirmation) => setWarningAlert(false)
                    }} />

                : null}
            <div className="popup-container-main popup-container-center">
                <div className="popup-block-ui"></div>
                <form className="side-popup-container create-share_request-main" onSubmit={HandlePopupSubmit}>

                    <div className="side-popup-header">
                        <div className="header-item-select">
                            <div className="header-item-select-content">
                                <div className="label">Send RFQ </div>

                            </div>
                        </div>
                        <div
                            className="header-item-close"
                            onClick={(e) => close()}
                            dangerouslySetInnerHTML={{ __html: Icons.general.close }}
                        ></div>
                    </div>

                    <div className="sidebar-popup-content">
                        <div className="purchase_request_share-share-main">
                            <div className="purchase_request_share-items-splits-main">
                                <div className="purchase_request_share-splits-header">
                                    <div className="header-item-name">NAME</div>
                                    <div className="header-item-email">EMAIL</div>
                                    <div className="header-item-action"></div>
                                </div>
                                <div className="purchase_request_share-splits-content">
                                    {SelectedVendors.map((item, index) => (
                                        <div className="purchase_request_share-content-item">
                                            <div className="purchase_request_share-content-items">
                                                <div className="purchase_request_share-content-name">
                                                    <div className="title">{item.label || 'N/A'}</div>
                                                    {item.status && <div className="status">Already Send</div>}
                                                </div>
                                                <div className="purchase_request_share-content-email">
                                                    {item.email || 'N/A'}
                                                </div>
                                                <div className="purchase_request_share-content-action">
                                                    <div className="action-copy"
                                                        onClick={(e) => HandleCopyLink(item)}
                                                    >
                                                        <div className="label">Copy link</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>

                    </div>

                    <div className="sidebar-popup-footer">
                        <div className="footer-item action-items">
                            <div className="action-preview">
                            </div>
                            <button
                                className="action-btn"
                                type='submit'
                            >
                                <div className="icon" dangerouslySetInnerHTML={{ __html: Icons.general.send_message }}></div>
                                <div className="label">Send Invitation</div>
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </>
    )
}

export default SharePurchaseRequestPopup;