

import React, { useEffect, useState, useRef } from 'react'
import { Route, Routes, BrowserRouter, Link, useLocation, Navigate, Outlet, useNavigate, useParams } from 'react-router-dom';
import store from '../../redux/store'
import { useSelector, useDispatch } from 'react-redux'
import UserAction from '../../redux/action/userAction'

import ReverseProxy from '../../config/reverseProxy'
import CostCodeHandler from '../../Handlers/CostCode/CostCode';
import Icons from '../../assets/Icons'
import Images from '../../assets/Images'
import { CostInput, TextInput, SelectInput, RadioInput } from '../../components/Inputs'
import Utils from '../../utils'
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

import AlertPopup from '../../components/AlertPopup'
import SystemToastPopup from '../../components/SystemToastPopup'
import Loading from '../../components/Loading'
import NoDataFound from '../../components/NoDataFound'
import { useAuth, useMenuAuth } from '../../hooks/useAuth'

const Add_Template = ({ props }) => {

    const { type, heading, close, callback, parentOptions, detials } = props

    const { costCodeId } = useParams()

    const costCodeHandler = new CostCodeHandler()

    const store = useSelector((store) => store)
    const dispatch = useDispatch()
    const { updateState } = new UserAction

    const [isLoading, setIsLoading] = useState(false)
    const [warningAlert, setWarningAlert] = useState(false)
    const [warningAlertType, setWarningAlertType] = useState('warning')
    const [apiFailedMessage, setApiFailedMessage] = useState('Error in Work Category')

    const navigate = useNavigate()

    const { isAdmin, menu_permission } = useMenuAuth({ menu: 'master_features--work_categories', feature_key: 'costCode' })
    const location = useLocation()

    const [selected_view_settings_value, setSelected_view_settings_value] = useState('')
    const [has_view_access, setHas_view_access] = useState(false)
    const [has_add_access, setHas_add_access] = useState(false)
    const [has_edit_access, setHas_edit_access] = useState(false)
    const [has_delete_access, setHas_delete_access] = useState(false)

    useEffect(() => {

        if (isAdmin) {

            setHas_view_access(true)
            setHas_add_access(true)
            setHas_edit_access(true)
            setHas_delete_access(true)
        }

        else if (menu_permission) {

            if (
                !menu_permission._view
                &&
                (!menu_permission.selected_view_settings_value
                    || menu_permission.selected_view_settings_value == 'no_access')
            ) {
                navigate(`${ReverseProxy['proxyUrl']}/quick-menu`, { state: { from: location }, replace: true })
            }



            if (type == 'edit' && menu_permission._edit == '0') navigate(`${ReverseProxy['proxyUrl']}/builder/projects`, { state: { from: location }, replace: true })
            if (type == 'create' && menu_permission._create == '0') navigate(`${ReverseProxy['proxyUrl']}/builder/projects`, { state: { from: location }, replace: true })

            setHas_view_access(menu_permission._view == '1')
            setHas_add_access(menu_permission._create == '1')
            setHas_edit_access(menu_permission._edit == '1')
            setHas_delete_access(menu_permission._delete == '1')

            setSelected_view_settings_value(menu_permission.selected_view_settings_value)

        }

    }, [store])

    const [Name, setName] = useState('')
    const [Type, setType] = useState('')
    const [Code, seCode] = useState('')
    const [ParentId, setParentId] = useState('')

    const HandleCancel = async (e) => {
        close(type)
    }
    const HandlePopupSubmit = async (e) => {

        let updateta = {
            name: Name,
            type: Type == 'group' ? '0' : Type == 'item' ? '1' : -1,
            code: Code,
            parent_id: ParentId,

        }

        let response = { success: false }

        setIsLoading(true)
        if (type == 'edit') {

            updateta["id"] = costCodeId
            response = await costCodeHandler.updateCostCodeHandler(updateta)

        }

        else response = await costCodeHandler.createCostCodeHandler(updateta)

        setIsLoading(false)

        if (response.success) {
            callback()

        }
        else {
            setWarningAlert(true)
            setWarningAlertType('error')
            setApiFailedMessage(`Error in Work Category, Please try again!`)
        }

        close(type)
    }
    const Popup_Header = () => {

        return (
            <div className="side-popup-header">
                <div className="header-item-select">
                    <div className="header-item-select-content">
                        <div className="label">{heading}</div>
                    </div>
                </div>
                <div
                    className="header-item-close"
                    onClick={(e) => HandleCancel(e)}
                    dangerouslySetInnerHTML={{ __html: Icons.general.close }}
                ></div>
            </div>
        );
    };

    const Popup_Footer = () => {

        return (
            <div className="sidebar-popup-footer">
                <div className="footer-item action-items">
                    <div className="action-preview">
                    </div>
                    <div className='action-btns'>
                        <div className="action-cancel" onClick={(e) => HandleCancel(e)}>
                            Cancel
                        </div>
                        <div
                            className={`action-btn action-${type}`}
                            onClick={(e) => {
                                HandlePopupSubmit(e)
                            }}
                        >

                            <div className="label">Save</div>
                        </div>
                    </div>
                </div>
            </div>
        );
    };


    useEffect(() => {
        if (type == 'edit' && costCodeId) {

            const LoadCostCode = async (costCodeId) => {

                if (Object.keys(detials).length) {

                    let { id, name, type, code, parent_id, created_at, document, updated_at, updated_by } = detials

                    setName(name)
                    setType(type)
                    seCode(code)
                    setParentId(parent_id)

                }
                else {

                    let response = await costCodeHandler.getCostCodesHandler({
                        id: costCodeId
                    })

                    if (response && response.success && Array.isArray(response.data) && response.data.length) {

                        let { id, name, type, code, parent_id, created_at, document, updated_at, updated_by } = response.data[0]

                        setName(name)
                        setType(type)
                        seCode(code)
                        setParentId(parent_id)
                    }
                    else {
                        setWarningAlert(true)
                        setWarningAlertType('error')
                        setApiFailedMessage(`Error in Work Category, Please try again!`)
                    }
                }
            }

            LoadCostCode(costCodeId)
        }
    }, [])

    return (
        <>

            {isLoading ?
                <Loading
                    props={{
                        isMainLogo: false,
                        isLabel: true
                    }} />
                : null}
            {warningAlert ?

                <SystemToastPopup
                    props={{
                        type: warningAlertType,
                        message: apiFailedMessage || "Error in Work Category, Please try again!",
                        callback: (confirmation) => setWarningAlert(false)
                    }} />

                : null}


            <div className="popup-container-main popup-container-center">
                <div className="popup-block-ui"></div>
                <div className="side-popup-container center-popup-container">
                    <Popup_Header />
                    <div className="costCode-popup-content" style={{ minHeight: '250px' }} >
                        <div className="content-items">

                            <div className="content-item">
                                <SelectInput
                                    props={{
                                        id: "costCode-type",
                                        value: Type,
                                        placeholder: '',
                                        readOnly: false,
                                        options: [
                                            {
                                                value: 'group',
                                                label: 'Work Category',
                                            },
                                            {
                                                value: 'item',
                                                label: 'Work',
                                            }
                                        ],
                                        setValue: (value, label) => setType(value),
                                        isStatus: false,
                                        isIcon: false,
                                        isLabel: true,
                                        isRequired: true,
                                        label: "Type",
                                    }}
                                />
                            </div>
                            <div className="content-item">
                                <TextInput
                                    props={{
                                        id: "costCode-name",
                                        value: Name,
                                        placeholder: '',
                                        setValue: (value) => setName(value),
                                        isIcon: false,
                                        isLabel: true,
                                        isRequired: true,
                                        label: "Name",
                                    }}
                                />
                            </div>
                        </div>
                        <div className="content-items">
                            <div className="content-item">
                                <SelectInput
                                    props={{
                                        id: "costCode-parent",
                                        value: ParentId,
                                        placeholder: '',
                                        readOnly: false,
                                        options: [
                                            {
                                                value: '',
                                                label: 'None',
                                            }, ...parentOptions
                                        ],
                                        setValue: (value, label) => setParentId(value),
                                        isStatus: false,
                                        isIcon: false,
                                        isLabel: true,
                                        isRequired: true,
                                        label: "Parent category",
                                    }}
                                />
                            </div>
                            <div className="content-item">
                                <TextInput
                                    props={{
                                        id: "costCode-email",
                                        value: Code,
                                        placeholder: '',
                                        setValue: (value) => seCode(value),
                                        isIcon: false,
                                        isLabel: true,
                                        isRequired: true,
                                        label: "Code",
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                    <Popup_Footer />
                </div>
            </div>
        </>
    )
}

const CostCode = () => {

    const store = useSelector((store) => store)
    const dispatch = useDispatch()
    const { updateState } = new UserAction

    const navigate = useNavigate()

    const costCodeHandler = new CostCodeHandler()

    const [isLoading, setIsLoading] = useState(false)
    const [warningAlert, setWarningAlert] = useState(false)
    const [warningAlertType, setWarningAlertType] = useState('warning')
    const [apiFailedMessage, setApiFailedMessage] = useState("Error in Server")


    const { isAdmin, menu_permission } = useMenuAuth({ menu: 'master_features--work_categories', feature_key: 'costCode' })
    const location = useLocation()

    const [selected_view_settings_value, setSelected_view_settings_value] = useState('')
    const [has_view_access, setHas_view_access] = useState(false)
    const [has_add_access, setHas_add_access] = useState(false)
    const [has_edit_access, setHas_edit_access] = useState(false)
    const [has_delete_access, setHas_delete_access] = useState(false)

    useEffect(() => {

        if (isAdmin) {

            setHas_view_access(true)
            setHas_add_access(true)
            setHas_edit_access(true)
            setHas_delete_access(true)
        }

        else if (menu_permission) {

            if (
                !menu_permission._view
                &&
                (!menu_permission.selected_view_settings_value
                    || menu_permission.selected_view_settings_value == 'no_access')
            ) {
                navigate(`${ReverseProxy['proxyUrl']}/quick-menu`, { state: { from: location }, replace: true })
            }

            if (menu_permission._view == '0') navigate(`${ReverseProxy['proxyUrl']}/builder/projects`, { state: { from: location }, replace: true })

            setHas_view_access(menu_permission._view == '1')
            setHas_add_access(menu_permission._create == '1')
            setHas_edit_access(menu_permission._edit == '1')
            setHas_delete_access(menu_permission._delete == '1')

            setSelected_view_settings_value(menu_permission.selected_view_settings_value)

        }

    }, [store])

    const [selected_termsCondition, setSelected_termsCondition] = useState({})

    const [parentOptions, setParentOptions] = useState([])



    const [costcodeDetials, setCostcodeDetials] = useState([])

    const setupCostCodeItems = (data = []) => {

        const groupedItems = {};

        for (const item of data) {

            const parentId = item.parent_id;

            if (parentId != null) {

                if (!groupedItems[parentId]) groupedItems[parentId] = [];

                groupedItems[parentId].push(item);
            }
        }

        for (const item of data) {
            const itemId = item.id;
            if (groupedItems[itemId]) {

                let subitems = groupedItems[itemId].sort((a, b) => a.code - b.code)

                item.subcatgories = subitems
            }
        }

        // order groups based on code

        data = data.sort((a, b) => a.code - b.code)

        const result = data.filter(d => d.parent_id == '')

        return result;



    }

    const getCostCodeDetials = async () => {

        setIsLoading(true)

        let response = await costCodeHandler.getCostCodesHandler()

        setIsLoading(false)


        if (response && response.success) {

            const Group_items = (items) => {

                let group_items = items.map((item, idx) => {
                    return {
                        id: item.id,
                        type: item.type == '0' ? 'group' : item.type == '1' ? 'item' : '',
                        name: item.name,
                        code: item.code,
                        parent_id: item.parent_id,
                        selected: false,
                        expanded: false,
                        subcatgories: []
                    }


                })


                return setupCostCodeItems(group_items)
            }

            let _parentOptions = response.data.filter((item) => item.type == '0').map((item) => {
                return {
                    value: item.id,
                    label: item.name
                }
            })
            setParentOptions(_parentOptions)

            let costCodes = Group_items(response.data)

            setCostcodeDetials(costCodes)


            dispatch(updateState({
                type: "SET_costCode",
                payload: { costCode: response.data }
            }))

        } else {
            setWarningAlert(true)
            setWarningAlertType('error')
            setApiFailedMessage(`Error in Work Category, Please try again!`)
        }
    }

    const HandleAddTemplate = () => {
        if (!has_add_access) return
        navigate(`${window.location.pathname}/add-new`)
    }
    const HandleEditItem = (item) => {
        if (!has_edit_access) return
        setSelected_termsCondition(item)

        navigate(`${window.location.pathname}/edit/${item.id}`)
    }
    const HandleDeleteItem = async (item) => {

        if (!has_edit_access || !has_delete_access) return

        const flatternIds = (parent_id, _costcodeDetials) => {

            let ids = []

            _costcodeDetials.forEach((item, idx) => {

                if (item.parent_id == parent_id) {

                    ids.push(item.id)

                    if (item.type == 'group' && Array.isArray(item.subcatgories))
                        ids.push(...flatternIds(item.id, item.subcatgories))
                }

            })

            return ids
        }

        let ids = item.type == 'group' ? [item.id, ...flatternIds(item.id, item.subcatgories || [])] : [item.id]

        setIsLoading(true)

        let response = await costCodeHandler.deleteCostCodeHandler({
            ids: ids || []
        })

        setIsLoading(false)

        if (response && response.success) {

            getCostCodeDetials()

        } else {
            setWarningAlert(true)
            setWarningAlertType('error')
            setApiFailedMessage(`Error in Work Category, Please try again!`)
        }

    }
    const CloseAddTemplate = (type) => {

        let back_path = String(window.location.pathname).split('/')
        back_path.pop()
        if (type == 'edit') back_path.pop()

        back_path = back_path.join('/')

        navigate(`${back_path}`)
    }




    useEffect(() => {
        getCostCodeDetials()
    }, [])


    const HandleExpandGroup = (group_id, parent_id) => {

        let _costcodeDetials = [...costcodeDetials]

        const findAndUpdateGroup = (group_id, parent_id, _costcodeDetials) => {

            _costcodeDetials = _costcodeDetials.map((item, idx) => {

                if (item.id == group_id && item.parent_id == parent_id) {
                    item.expanded = !item.expanded
                }
                else if (item.type == 'group' && Array.isArray(item.subcatgories)) {
                    item.subcatgories = findAndUpdateGroup(group_id, parent_id, item.subcatgories)
                }

                return item
            })

            return _costcodeDetials
        }

        _costcodeDetials = findAndUpdateGroup(group_id, parent_id, _costcodeDetials)

        setCostcodeDetials(_costcodeDetials)
    }
    const HandleSelectItem = (group_id, parent_id, value) => {

        let _costcodeDetials = [...costcodeDetials]

        const UpdateAllSubcatgorieItems = (_costcodeDetials, value) => {

            _costcodeDetials = _costcodeDetials.map((item, idx) => {

                item.selected = value
                if (item.type == 'group' && Array.isArray(item.subcatgories))
                    item.subcatgories = UpdateAllSubcatgorieItems(item.subcatgories, value)

                return item
            })

            return _costcodeDetials
        }

        const findAndUpdateGroup = (group_id, parent_id, _costcodeDetials, value) => {

            _costcodeDetials = _costcodeDetials.map((item, idx) => {

                if (item.id == group_id && item.parent_id == parent_id) {
                    item.selected = value

                    if (Array.isArray(item.subcatgories))
                        item.subcatgories = UpdateAllSubcatgorieItems(item.subcatgories, value)
                }
                else if (item.type == 'group' && Array.isArray(item.subcatgories)) {
                    item.subcatgories = findAndUpdateGroup(group_id, parent_id, item.subcatgories, value)
                }

                return item
            })

            return _costcodeDetials
        }
        _costcodeDetials = findAndUpdateGroup(group_id, parent_id, _costcodeDetials, value)

        setCostcodeDetials(_costcodeDetials)

    }


    const GetTreeItemsFromData = ({ props }) => {

        let { id, parent_id, selected, expanded, type, name, code, subcatgories } = props

        subcatgories = Array.isArray(subcatgories) ? subcatgories : []

        let total_subcategoies = subcatgories.filter((subcatgory) => subcatgory.type == 'group').length || 0
        let total_costcodes = subcatgories.filter((subcatgory) => subcatgory.type == 'item').length || 0

        return (

            <div
                id={`cost-code-${type}-${id}`}
                data-parent_id={parent_id}
                className={`costcode-tree-${type}-item`}
            >
                <div className={`costcode-tree-${type}-main`}>

                    <div className={`${type}-left`}>
                        {type == 'group' ?
                            <div
                                className={`group-left-expand ${expanded ? 'group-left-expand-open' : ''} `}
                                dangerouslySetInnerHTML={{ __html: Icons.general.group_arrow }}
                                onClick={(e) => HandleExpandGroup(id, parent_id)}
                            ></div>
                            : ''}
                        <div className={`${type}-left-checkbox`}>
                            <RadioInput props={{
                                value: selected || false,
                                isIcon: false,
                                icon: "",
                                inputType: "checkbox",
                                name: "table-default-radio",
                                setValue: (value) => { HandleSelectItem(id, parent_id, value) }
                            }} />
                        </div>
                        <div className={`${type}-left-label`}>
                            <div
                                className="icon"
                                dangerouslySetInnerHTML={{
                                    __html: type == 'group' ? Icons.general.cost_code_group : Icons.general.cost_code_item
                                }}
                            ></div>
                            <div className="label">{code} {name}</div>
                        </div>

                        {type == 'group' ?
                            <div className="total-detials">({total_subcategoies} Subcategoies, {total_costcodes} category)</div>
                            : ''}
                    </div>
                    <div className={`${type}-right`}>
                        {has_edit_access ?
                            <div
                                className={`${type}-right-button button-edit`}
                                dangerouslySetInnerHTML={{ __html: Icons.general.edit_btn }}
                                onClick={(e) => HandleEditItem(props)}
                            ></div>
                            : ''}
                        {has_edit_access && has_delete_access ?
                            <div
                                className={`${type}-right-button button-delete`}
                                dangerouslySetInnerHTML={{ __html: Icons.general.delete_btn }}
                                onClick={(e) => HandleDeleteItem(props)}
                            ></div>
                            : ''}
                    </div>

                </div>
                {type == 'group' ?
                    <div className={`costcode-tree-group-items ${expanded ? 'group-items-active' : ''}`}>

                        {subcatgories?.map((item, idx) => (
                            <GetTreeItemsFromData props={item} />
                        ))}

                    </div>
                    : ''}

            </div >

        )

    }

    return (
        <>
            {isLoading ?
                <Loading
                    props={{
                        isMainLogo: false,
                        isLabel: true
                    }} />
                : null}
            {warningAlert ?

                <SystemToastPopup
                    props={{
                        type: warningAlertType,
                        message: apiFailedMessage || "Error in Work Category, Please try again!",
                        callback: (confirmation) => setWarningAlert(false)
                    }} />

                : null}

            <Routes >
                <Route exact path='/add-new' element={
                    <Add_Template props={{
                        type: "create",
                        heading: "Add Work Category",
                        callback: getCostCodeDetials,
                        close: CloseAddTemplate,
                        parentOptions: parentOptions
                    }} />}></Route>
                <Route exact path='/edit/:costCodeId' element={
                    <Add_Template props={{
                        type: "edit",
                        heading: "Edit Work Category",
                        callback: getCostCodeDetials,
                        close: CloseAddTemplate,
                        parentOptions: parentOptions,
                        detials: selected_termsCondition
                    }} />}></Route>
            </Routes>

            <div className="project-costCode-main">
                <div className="costCode-panel-content">

                    <div className="panel-content-header">
                        <div className="title">Work Categories</div>
                        <div className="buttons">
                            {has_add_access ?
                                <div
                                    className="button add-button"
                                    onClick={(e) => HandleAddTemplate()}
                                >
                                    <div className="icon" dangerouslySetInnerHTML={{ __html: Icons.general.add_btn }}></div>
                                    <div className="label">Add New</div>
                                </div>
                                : ''}
                        </div>
                    </div>
                    <div className="panel-content-sections panel-content-tabel-section">

                        <div className="costcode-treeview-main">

                            {
                                costcodeDetials.length ?
                                    costcodeDetials.map((item, idx) => (
                                        <GetTreeItemsFromData props={item} />
                                    ))
                                    : <NoDataFound label={'No Items Found'} />}
                        </div>

                    </div>
                </div>

            </div>
        </>
    )
}

export default CostCode;