
import Logo from './images/logo.png'
import Logo_light from './images/logo1.png'
import Logo_Small from './images/logo_small.png'
import Login_banner from './images/banner.png'
import Login_bg from './images/background_1.svg'
import User from './images/user.png'
import LandingTopBanner from './images/bg_test.png'
import Estimate_feature from './images/estimate_feature.mp4'
import Schedule_feature from './images/schedule_feature.mp4'
import Takeoff_feature from './images/illustration_takeoff_feature.webp'
import Proposal_feature from './images/illustration_proposal_feature.webp'
import Workdemo from './images/workdemo.webm'
import Workdemo_Png from './images/workdemo.png'
import AnyDevicesAccess from './images/all_devices.png'
import AppBanner from './images/app_banner.png'
import NoIssues from './images/noIssues.png'
import NoPhotos from './images/no_photos.png'

import ChatGif from './images/chat.gif'

import Request_demo_banner_1 from './images/request-demo-banner-1.jpg'
import Request_demo_banner_2 from './images/request-demo-banner-2.jpg'
import Request_demo_banner_3 from './images/request-demo-banner-3.jpg'
import Request_demo_banner_4 from './images/request-demo-banner-4.jpg'

import Company_Owner from './images/company-ownder.jpg'
import Project_Manager from './images/project-manager.jpg'
import Site_Engineer from './images/site-engineer.jpg'

import Benefits from './images/benefits.jpg'

import Customer1 from './images/customers/aarco_designers.png'
import Customer2 from './images/customers/mithrava.png'
import Customer3 from './images/customers/pc_designs.png'


import Task_management from './images/landingpage/task_management.png'
import Vendor_management from './images/landingpage/vendor_management.png'
import Material_management from './images/landingpage/material_management.png'

const Images = {
    logo: Logo,
    logo_light: Logo_light,
    logo_small: Logo_Small,
    login_banner: Login_banner,
    Login_bg: Login_bg,
    User: User,
    DEFAULT_PROJECT_BANNER: 'https://thumbor.forbes.com/thumbor/fit-in/900x510/https://www.forbes.com/advisor/wp-content/uploads/2022/10/condo-vs-apartment.jpeg.jpg',
    LandingTopBanner: LandingTopBanner,
    Estimate_feature: Estimate_feature,
    Schedule_feature: Schedule_feature,
    Takeoff_feature: Takeoff_feature,
    Proposal_feature: Proposal_feature,
    Workdemo: Workdemo,
    _Workdemo: Workdemo_Png,
    AnyDevicesAccess: AnyDevicesAccess,
    AppBanner: AppBanner,
    NoIssues: NoIssues,
    NoPhotos: NoPhotos,
    ChatGif: ChatGif,

    Company_Owner: Company_Owner,
    Project_Manager: Project_Manager,
    Site_Engineer: Site_Engineer,

    Benefits: Benefits,
    Task_management,
    Vendor_management,
    Material_management,
    request_demo_banners: {
        b1: Request_demo_banner_1,
        b2: Request_demo_banner_2,
        b3: Request_demo_banner_3,
        b4: Request_demo_banner_4
    },

    customers: {
        customer1: Customer1,
        customer2: Customer2,
        customer3: Customer3
    },
}

export default Images