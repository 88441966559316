

import React, { useEffect, useState, useRef, useCallback, useMemo, StrictMode } from 'react'
import { Route, Routes, BrowserRouter, Link, useLocation, Navigate, Outlet, useNavigate, useParams } from 'react-router-dom';
import store from '../../redux/store'
import StoreHandler from '../../redux/StoreHandler'
import { useSelector, useDispatch } from 'react-redux'
import UserAction from '../../redux/action/userAction'


import ReverseProxy from '../../config/reverseProxy'
import Image from '../../assets/Images'
import Icons from '../../assets/Icons'

import { useAuth, useMenuAuth } from '../../hooks/useAuth'

const Mainsidebar = () => {

    const currentURL = window.location.pathname;

    let selectSection = currentURL ? currentURL.split(`/builder/`)[1] : undefined;

    const { isAdmin, roles_permissions, company_features } = useAuth()

    const navigate = useNavigate()
    const store = useSelector((store) => store)
    const dispatch = useDispatch()
    const { updateState } = new UserAction

    const SIDE_MENUS = [

        {
            id: "projects",
            key: "projects",
            parent_id: null,
            icon: Icons.general.building,
            label: 'Projects',
            disabled: false,
            commingsoon: false,
            isSubMenus: false,
            expanded: false,
            submenus: [],
        },
        {
            id: "masters",
            parent_id: null,
            icon: Icons.general.database,
            label: 'Masters',
            disabled: false,
            commingsoon: false,
            isSubMenus: true,
            expanded: false,
            submenus: [
                {
                    id: "work-category",
                    key: "work_categories",
                    icon: Icons.general.cost_code,
                    parent_id: "masters",
                    label: 'Work Category',
                    disabled: false,
                    commingsoon: false,
                    isSubMenus: false,
                    expanded: false,
                    submenus: [],
                },
                {
                    id: "schedule-rates",
                    key: "schedule_rates",
                    parent_id: "masters",
                    icon: Icons.general.pay_schedule,
                    label: 'Schedule Rates',
                    disabled: false,
                    commingsoon: false,
                    isSubMenus: false,
                    expanded: false,
                    submenus: [],
                },
                {
                    id: "work-rates",
                    key: "work_rates",
                    parent_id: "masters",
                    icon: Icons.general.work,
                    label: 'Work rates',
                    disabled: false,
                    commingsoon: false,
                    isSubMenus: false,
                    expanded: false,
                    submenus: [],
                },
                {
                    id: "work-templates",
                    key: "work_templates",
                    parent_id: "masters",
                    icon: Icons.general.files,
                    label: 'Work templates',
                    disabled: false,
                    commingsoon: false,
                    isSubMenus: false,
                    expanded: false,
                    submenus: [],
                },
            ],
        },
        {

            id: "labour-management",
            parent_id: null,
            icon: Icons.general.workers,
            label: 'Labours',
            disabled: false,
            commingsoon: false,
            isSubMenus: true,
            expanded: false,
            submenus: [
                {
                    id: "labours",
                    key: "all_labours",
                    icon: Icons.general.manpower,
                    parent_id: "labour-management",
                    label: 'All Labours',
                    disabled: false,
                    commingsoon: false,
                    isSubMenus: false,
                    expanded: false,
                    submenus: [],
                },
                {
                    id: "labour-accounts",
                    key: "labour_accounts",
                    icon: Icons.general.cost_code,
                    parent_id: "labour-management",
                    label: 'Labour Accounds',
                    disabled: false,
                    commingsoon: false,
                    isSubMenus: false,
                    expanded: false,
                    submenus: [],
                },

            ],
        },
        {
            id: "clients",
            key: "clients",
            parent_id: null,
            icon: Icons.general.clients,
            label: 'Clients',
            disabled: false,
            commingsoon: false,
            isSubMenus: false,
            expanded: false,
            submenus: [],
        },
        {
            id: "vendors",
            key: "vendors",
            parent_id: null,
            icon: Icons.general.vendors,
            label: 'My Vendors',
            disabled: false,
            commingsoon: false,
            isSubMenus: false,
            expanded: false,
            submenus: [],
        },


        {
            id: "team-management",
            key: "employees",
            parent_id: null,
            icon: Icons.general.employees,
            label: 'Team',
            isSubMenus: false,
            expanded: false,
            submenus: [],
        },
        {
            id: "role-management",
            key: "role_managment",
            parent_id: null,
            icon: Icons.general.user_role,
            label: 'Role & Permissions',
            isSubMenus: false,
            expanded: false,
            submenus: [],
        },

        {
            id: "feedback",
            key: "support",
            parent_id: null,
            icon: Icons.general.support,
            label: 'Support',
            disabled: false,
            commingsoon: false,
            isSubMenus: false,
            expanded: false,
            submenus: [],
        },
        {
            id: "settings",
            key: "settings",
            parent_id: null,
            icon: Icons.general.settings,
            label: 'Settings',
            disabled: false,
            commingsoon: false,
            isSubMenus: false,
            expanded: false,
            submenus: [],
        }
    ]


    const [sidebar_items, setSidebar_items] = useState([])

    const [selectedMenu, setSelectedMenu] = useState('')
    const selectedMenuRef = useRef(null);


    const Hide_SideBar = () => {
        let sidebar_contanier = document.querySelector('#project-sidebar-main')

        let sidebar_active_class = "project-sidebar-active"
        sidebar_contanier.classList.remove(sidebar_active_class)
    }


    const HandleResetSubmenuExpand = (items) => {

        if (Array.isArray(items)) {
            items.forEach((item) => {
                item.expanded = false

                if (item.isSubMenus) {
                    item.submenus = HandleResetSubmenuExpand(item.submenus)
                }
            })
        }

        return items || []
    }

    const HandleOpenSubmenu = (id) => {

        let _menus = [...sidebar_items]


        const findAndUpdateGroup = (id, _menus) => {

            _menus = _menus.map((item, idx) => {

                if (item.id != id) item.expanded = false

                if (item.id == id) {
                    item.expanded = !item.expanded
                }
                else if (item.isSubMenus && Array.isArray(item.submenus)) {
                    item.submenus = findAndUpdateGroup(id, item.submenus)
                }

                return item
            })

            return _menus
        }

        _menus = findAndUpdateGroup(id, _menus)

        setSidebar_items(_menus)
    }
    const HandleSidebarMenu = (item, isSubItem) => {

        if (item.isSubMenus) {

            HandleOpenSubmenu(item.id)

        }
        else if (!item.disabled && !item.commingsoon) {

            if (!isSubItem) {
                let _menus = [...sidebar_items]

                _menus = HandleResetSubmenuExpand(_menus)

                setSidebar_items(_menus)
            }

            setSelectedMenu(item.id)
            navigate(`${ReverseProxy['proxyUrl']}/builder/${item.id}`)
            Hide_SideBar()
        }


    }

    const HandleSideMenuOpenByURL = (id) => {


        let _menus = [...sidebar_items]


        let openMenu = (id, menus) => {

            for (let i = 0; i < menus.length; i++) {

                if (menus[i].id == id) {
                    menus[i].expanded = true
                    break;
                }
                else if (menus[i].isSubMenus && Array.isArray(menus[i].submenus)) {
                    menus[i].submenus = openMenu(id, menus[i].submenus)
                }
            }

            return menus

        }

        let getMenu = (id, menus) => {

            for (let i = 0; i < menus.length; i++) {

                if (menus[i].id == id) {
                    return menus[i]
                }
                else if (menus[i].isSubMenus && Array.isArray(menus[i].submenus)) {
                    return getMenu(id, menus[i].submenus)
                }
            }

            return null
        }

        let menu = getMenu(id, _menus)

        if (menu && menu.parent_id) {
            _menus = openMenu(menu.parent_id, _menus)
            setSidebar_items(_menus)
        }
    }

    useEffect(() => {


        if (selectSection) {
            setSelectedMenu(selectSection)
            HandleSideMenuOpenByURL(selectSection)
        }
        else if (sidebar_items.length) {
            setSelectedMenu(sidebar_items[0].id)
            navigate(`${ReverseProxy['proxyUrl']}/builder/${sidebar_items[0].id}`)
            HandleSideMenuOpenByURL(sidebar_items[0].id)
        }

    }, [])

    useEffect(() => {

        if (isAdmin) {


            let sidebar_items = []

            SIDE_MENUS.forEach((_item, i) => {

                let item = { ..._item }

                if (['support', 'settings'].includes(_item.key)) sidebar_items.push(item)

                else if (item.isSubMenus && Array.isArray(item.submenus)) {

                    let submenus = []

                    item.submenus.forEach((subitem, j) => {

                        let isCompanyAllow = company_features.some((role) => {

                            let feature_key = String(role.feature_key).split('--')[1]

                            return feature_key == subitem.key && role._view == 1
                        })


                        if (isCompanyAllow) submenus.push(subitem)

                    })

                    if (submenus.length) {
                        item.submenus = submenus
                        sidebar_items.push(item)
                    }
                }

                else {

                    let isCompanyAllow = company_features.some((role) => {

                        let feature_key = String(role.feature_key).split('--')[1]

                        return feature_key == item.key && role._view == 1
                    })


                    if (isCompanyAllow) sidebar_items.push(item)
                }



            })

            setSidebar_items(sidebar_items)
        }

        else if (roles_permissions && Array.isArray(roles_permissions)) {

            let sidebar_items = []

            SIDE_MENUS.forEach((_item, i) => {

                let item = { ..._item }

                if (['support', 'settings'].includes(_item.key)) sidebar_items.push(item)

                else if (item.isSubMenus && Array.isArray(item.submenus)) {

                    let submenus = []

                    item.submenus.forEach((subitem, j) => {

                        let isRoleAllow = roles_permissions.some((role) => {

                            let feature_key = String(role.feature_key).split('--')[1]

                            return feature_key == subitem.key && role._view == 1
                        })

                        let isCompanyAllow = company_features.some((role) => {

                            let feature_key = String(role.feature_key).split('--')[1]

                            return feature_key == subitem.key && role._view == 1
                        })


                        if (isRoleAllow && isCompanyAllow) submenus.push(subitem)

                    })

                    if (submenus.length) {
                        item.submenus = submenus
                        sidebar_items.push(item)
                    }
                }

                else {


                    let isRoleAllow = roles_permissions.some((role) => {

                        let feature_key = String(role.feature_key).split('--')[1]

                        return feature_key == item.key && role._view == 1
                    })

                    let isCompanyAllow = company_features.some((role) => {

                        let feature_key = String(role.feature_key).split('--')[1]

                        return feature_key == item.key && role._view == 1
                    })


                    if (isRoleAllow && isCompanyAllow) sidebar_items.push(item)
                }



            })

            setSidebar_items(sidebar_items)
        }



    }, [store.user?.roles_permissions])


    const CreateMenuItem = (item, i, isSubItem = false) => {


        const { id, disabled, expanded, commingsoon, icon, label, isSubMenus, submenus } = item

        return (
            <div
                className={`${disabled ? 'sidebar-item-disabled' : 'sidebar-item'} ${expanded ? 'sidebar-item-submenu-active' : ''}  ${selectedMenu == id ? isSubItem ? 'sidebar-subitem-active' : 'sidebar-item-active' : ''}`}
                key={id}
                ref={selectedMenu == id ? selectedMenuRef : null}
            >
                <div
                    className="sidebar-item-menu"
                    onClick={() => HandleSidebarMenu(item, isSubItem)}
                >
                    <span
                        className='icon'
                        dangerouslySetInnerHTML={{ __html: icon }}
                    ></span>
                    <span className='label'>{label}</span>
                    {commingsoon ?
                        <span
                            className='sidebae-item-commingsoon icon'
                            dangerouslySetInnerHTML={{ __html: Icons.general.commingsoon }}
                        ></span> : null
                    }
                    {isSubMenus ?
                        <span
                            className={`sidebar-item-expanded ${expanded ? 'sidebar-item-expanded-open' : ''}`}
                            dangerouslySetInnerHTML={{ __html: Icons.general.dropdown_arrow }}
                        ></span> : null
                    }
                </div>
                <div className={`sidebar-item-submenu-main ${expanded ? 'sidebar-item-submenu-open' : ''}`}>

                    {isSubMenus && Array.isArray(submenus) ? submenus.map((submenu, i) => (
                        CreateMenuItem(submenu, i, true)
                    )) : ''}
                </div>
            </div>
        )
    }

    return (
        <div className='project-sidebar-main sidebar-global' id='project-sidebar-main'>
            <div className="sidebar-header">
                <div className="header-title">Menus</div>
                <div
                    className="header-close"
                    dangerouslySetInnerHTML={{ __html: Icons.general.close }}
                    onClick={(e) => Hide_SideBar(e)}
                ></div>
            </div>
            <div
                className="sidebar-logo"
                onClick={(e) => {
                    navigate(`${ReverseProxy['proxyUrl']}/quick-menu`)
                }}
            >
                <img src={Image.logo_light} />

            </div>
            <div className="sidebar-items">

                {sidebar_items.map((item, i) => (

                    CreateMenuItem(item, i)

                ))}

            </div>
        </div>
    )
}

export default Mainsidebar;
