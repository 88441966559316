
import proxyConfig from '../../config/reverseProxy'
import HeaderConfig from '../../config/header'

class PO_GRN {

    constructor() {

    }


    async getNextPO_GRN_NoHandler(params) {

        try {

            let response = await fetch(proxyConfig['serverBaseUrl'] + '/builder/po_grn/get-next-grn_no', {
                method: 'POST',
                headers: HeaderConfig.getHeader({ method: 'POST', isToken: true }),
                body: JSON.stringify(params)
            })

            response = await response.json()

            if (response) return response
        } catch {
            return { success: false }
        }


    }
    async getBasicPO_GRNHandler(params) {

        try {

            let response = await fetch(proxyConfig['serverBaseUrl'] + '/builder/po_grn/get-basic-grns', {
                method: 'POST',
                headers: HeaderConfig.getHeader({ method: 'POST', isToken: true }),
                body: JSON.stringify(params)
            })

            response = await response.json()

            if (response) return response
        } catch {
            return { success: false }
        }


    }
    async getPO_GRNsHandler(params) {

        try {

            let response = await fetch(proxyConfig['serverBaseUrl'] + '/builder/po_grn/get-grns', {
                method: 'POST',
                headers: HeaderConfig.getHeader({ method: 'POST', isToken: true }),
                body: JSON.stringify(params)
            })

            response = await response.json()

            if (response) return response
        } catch {
            return { success: false }
        }


    }
    async createPO_GRNHandler(params) {

        try {

            let response = await fetch(proxyConfig['serverBaseUrl'] + '/builder/po_grn/create-grn', {
                method: 'POST',
                headers: HeaderConfig.getHeader({ method: 'POST', isToken: true }),
                body: JSON.stringify(params)
            })

            response = await response.json()

            if (response) return response
        } catch {
            return { success: false }
        }


    }
    async updatePO_GRNHandler(params) {

        try {

            let response = await fetch(proxyConfig['serverBaseUrl'] + '/builder/po_grn/update-grn', {
                method: 'POST',
                headers: HeaderConfig.getHeader({ method: 'POST', isToken: true }),
                body: JSON.stringify(params)
            })

            response = await response.json()

            if (response) return response
        } catch {
            return { success: false }
        }


    }
    async deletePO_GRNHandler(params) {

        try {

            let response = await fetch(proxyConfig['serverBaseUrl'] + '/builder/po_grn/delete-grn', {
                method: 'POST',
                headers: HeaderConfig.getHeader({ method: 'POST', isToken: true }),
                body: JSON.stringify(params)
            })

            response = await response.json()

            if (response) return response
        } catch {
            return { success: false }
        }


    }

}

export default PO_GRN;