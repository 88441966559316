

import React, { useEffect, useState, useRef, useCallback, useMemo, StrictMode } from 'react'
import { Route, Routes, BrowserRouter, Link, useLocation, Navigate, Outlet, useNavigate, useParams } from 'react-router-dom';
import store from '../../redux/store'
import StoreHandler from '../../redux/StoreHandler'
import { useSelector, useDispatch } from 'react-redux'
import UserAction from '../../redux/action/userAction'

import ReverseProxy from '../../config/reverseProxy'

import BillsHandler from '../../Handlers/Bills/Bills';
import Icons from '../../assets/Icons'
import Images from '../../assets/Images'
import { CostInput, TextInput, SelectInput, DateTimeInput, RadioInput } from '../../components/Inputs'
import Utils from '../../utils'
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

import { PDFExport, savePDF } from '@progress/kendo-react-pdf';

import AlertPopup from '../../components/AlertPopup'
import SystemToastPopup from '../../components/SystemToastPopup'
import Loading from '../../components/Loading'

const PreviewBill = ({ props }) => {

    const { projectId, billId } = useParams();

    const { menuType = 'create', close, billLoaded, billdetials, saveCallback } = props

    const [project_id, setProject_id] = useState('')
    const [project_name, setProject_name] = useState('')
    const [estimate_id, setEstimate_id] = useState('')

    const [isLoading, setIsLoading] = useState(false)
    const [warningAlert, setWarningAlert] = useState(false)
    const [warningAlertType, setWarningAlertType] = useState('warning')
    const [apiFailedMessage, setApiFailedMessage] = useState("Error in Server")

    const navigate = useNavigate()
    const store = useSelector((store) => store)
    const dispatch = useDispatch()
    const { updateState } = new UserAction

    const storeHandler = new StoreHandler({
        dataStack: ['company', 'vendors', 'terms_conditions', 'clients', 'field_settings', 'finance_settings', `project-${projectId}`],
        store: store,
        dispatch: dispatch,
        updateState: updateState,
    })

    const LoadStoreData = async () => {
        setIsLoading(true)
        let resolved = await storeHandler.init()
        setIsLoading(false)
    }
    const pdfRef = useRef()
    const billsHandler = new BillsHandler()

    const [unitsOptions, setUnitsOptions] = useState([])
    const [currency_type, setCurrency_type] = useState('₹')

    const CompanyProfile = store.user.company || undefined
    const isCompany = Object.keys(CompanyProfile).length > 0

    const [ShowWatermark, setShowWatermark] = useState(false)


    const [companyImg, setCompanyImg] = useState('')
    const [companyName, setCompanyName] = useState('N/A')
    const [projectName, setProjectName] = useState('N/A')
    const [projectArea, setProjectArea] = useState('N/A')
    const [projectID, setProjectID] = useState('N/A')
    const [clientId, setClientId] = useState('')
    const [clientName, setClientName] = useState('N/A')
    const [clientEmail, setClientEmail] = useState('N/A')
    const [clientNumber, setClientNumber] = useState('N/A')
    const [projectAddress, setProjectAddress] = useState('N/A')
    const [dateFormat, setDateFormat] = useState('DD/MM/YYYY')

    const [id, setId] = useState('')
    const [name, setName] = useState('')
    const [billID, setBillID] = useState('')
    const [status, setStatus] = useState('')
    const [introduction_text, setIntroduction_text] = useState('')
    const [comments, setComments] = useState('')

    const [billDate, setBillDate] = useState('')
    const [dueDate, setDueDate] = useState('')


    const [BillSubTotal, setBillSubTotal] = useState(0)
    const [BillPretaxSubTotal, setBillPretaxSubTotal] = useState(0)
    const [BillTotal, setBillTotal] = useState(0)
    const [BillTotalTax, setBillTotalTax] = useState(0)
    const [BillTotalMarkup, setBillTotalMarkup] = useState(0)
    const [BillPaid, setBillPaid] = useState(0)
    const [BillDue, setBillDue] = useState(0)

    const [purchase_order_id, setPurchase_order_id] = useState('')
    const [purchase_order_name, setPurchase_order_name] = useState('')

    const [bill_reference, setBillReference] = useState('')

    const [vendor_id, setVendor_id] = useState('')
    const [vendor_name, setVendor_name] = useState('')
    const [vendor_gstno, setVendorGSTNo] = useState('N/A')

    const [vendorOptions, setVendorOptions] = useState([])

    const [termsConditionValue, setTermsConditionValue] = useState("")

    const [statusOptions, setStatusOptions] = useState([
        {
            value: 0,
            label: "Pending",
            isDefault: false
        },
        {
            value: 1,
            label: "Approved",
            isDefault: false
        },
        {
            value: 2,
            label: "Rejected",
            isDefault: false
        },
    ])
    const [materialItems, setMaterialItems] = useState([
        {
            id: Utils.getUniqueId(),
            material_id: "",
            material_name: "",
            unitrate: 0,
            tax: 0,
            markup: 0,
            tax_amount: 0,
            markup_amount: 0,
            final_unitrate: 0,
            amount: 0,
            final_amount: 0,
            pre_tax_amount: 0,
            value_type: `$`,
            unit_id: '',
            unit_name: '',
            quantity: 0,
            description: '',
            notes: ''
        }
    ])

    const [paymentHistory, setPaymentHistory] = useState([
    ])

    const HandleDownloadBtn = () => {

        pdfRef.current.save();
        // savePDF(pdfRef.current, { paperSize: "A4" });
    }


    const getNextBillID = async () => {


        let response = await billsHandler.getNextBillsIDHandler()

        if (response && response.success) {

            let { billId } = response.data

            if (billId) setBillID(billId)

        } else {
            setWarningAlert(true)
            setWarningAlertType('error')
            setApiFailedMessage(`Error in getting next bill id`)
        }
    }


    const LoadProposal = async () => {


        let filter = {
            billId: billId,
            projectId: projectId,
        }

        let _billdetials = undefined

        if (billLoaded && billdetials && Object.keys(billdetials).length) {

            _billdetials = billdetials

        } else if (billId) {

            setIsLoading(true)

            let response = await billsHandler.getBillssHandler(filter)
            setIsLoading(false)

            if (response.success) {
                let { data } = response

                if (!Array.isArray(data) || !data.length) navigate(`/builder/projects/${projectId}/bills`)

                console.log(data, 'data');

                _billdetials = data[0]
            }

        }

        if (_billdetials && Object.keys(_billdetials).length) {

            let {
                id,
                name,
                billId,
                projectId,
                bill_date,

                purchase_order_id,
                purchase_order_name,

                bill_reference,

                vendor_id,
                vendor_name,

                due_date,
                status,
                bill_total,
                bill_paid,
                bill_due,
                introduction_text,
                comments,
                terms_condition_id,
                terms_condition,
                cost_items,
                payment_history
            } = _billdetials


            setId(id)
            setBillID(billId)
            setName(name)

            setPurchase_order_id(purchase_order_id)
            setPurchase_order_name(purchase_order_name)

            setBillReference(bill_reference)

            setVendor_id(vendor_id)
            setVendor_name(vendor_name)

            if (status != undefined) {

                let _status = statusOptions.filter(opt => opt.value == status)[0]
                if (_status) setStatus(_status.label)
            }

            setIntroduction_text(introduction_text)

            setTermsConditionValue(terms_condition)
            setComments(comments)

            setBillDate(bill_date)
            setDueDate(due_date)

            setBillTotal(bill_total)
            setBillPaid(bill_paid)
            setBillDue(bill_due)

            setMaterialItems(cost_items || [])
            setPaymentHistory(payment_history || [])

        }

    }

    useEffect(() => {

        if (menuType == 'create') {
            getNextBillID()
        }
        else {
            LoadProposal()
        }

        LoadStoreData()

    }, [])

    useEffect(() => {

        if (store && store.user) {

            let { company, clients, settings, selected_project } = store.user

            if (selected_project) {
                let { name, status, area, unit_id, unit_name, type_id, type_name, address, client_id, client_name } = selected_project



                setProjectName(name || 'N/A')
                setProjectArea(`${area}${unit_name || ""}` || 'N/A')
                setProjectID(projectId)
                setProjectAddress(address || "N/A")
                setClientName(client_name || "N/A")


                if (clients && Array.isArray(clients) && clients.length) {

                    let _client = clients.filter(opt => opt.id == client_id)[0]

                    if (_client) {
                        let { name, email, phone } = _client

                        setClientName(name || 'N/A')
                        setClientEmail(email || 'N/A')
                        setClientNumber(phone || 'N/A')
                    }
                }
            }

            if (company) {
                let { name, logo_img, currency_setting, date_format_setting, timezone, temperature_setting } = company

                const profileImgURL = logo_img ? `data:image/png;base64,${logo_img}` : ''

                setCompanyImg(profileImgURL)
                setCompanyName(name || 'N/A')
                setDateFormat(date_format_setting || 'DD/MM/YYYY')
                if (currency_setting) setCurrency_type(currency_setting)
            }


            if (settings && Array.isArray(settings.field_settings) && settings.field_settings.length) {

                let { project_statuses, project_types, units } = settings.field_settings[0]

                units = units.filter(opt => opt.isActive).map((opt) => {
                    return {
                        value: opt.id,
                        label: opt.name,
                        isDefault: opt.isDefault || false
                    }
                })

                setUnitsOptions(units || [])

            }

        }

    }, [store])
    useEffect(() => {

        if (store && store.user) {

            let { company, vendors, settings, selected_project } = store.user


            if (vendors && Array.isArray(vendors) && vendors.length) {

                let vendor_options = vendors.filter(opt => opt.status == '1').map((opt) => {
                    return {
                        value: opt.vendorID,
                        label: opt.name,
                        gstin_no: opt.gstin_no,
                        tin_no: opt.tin_no,
                        isDefault: false
                    }
                })

                let vendor = vendor_options.filter(opt => opt.value == vendor_id)[0]
                if (vendor) setVendorGSTNo(vendor.gstin_no)

                setVendorOptions(vendor_options || [])

            }

        }

    }, [store, vendor_id])


    useEffect(() => {

        let total_amounts = materialItems.reduce((prev, val, i) => {

            return {
                pre_tax_amount: parseFloat(prev.pre_tax_amount || 0) + parseFloat(val.pre_tax_amount || 0),
                tax_amount: parseFloat(prev.tax_amount || 0) + parseFloat(val.tax_amount || 0),
                markup_amount: parseFloat(prev.markup_amount || 0) + parseFloat(val.markup_amount || 0),
                amount: parseFloat(prev.amount || 0) + parseFloat(val.amount || 0),
                final_amount: parseFloat(prev.final_amount || 0) + parseFloat(val.final_amount || 0),
            }

        }, {
            pre_tax_amount: 0,
            tax_amount: 0,
            markup_amount: 0,
            amount: 0,
            final_amount: 0

        })
        setBillSubTotal(total_amounts.amount)
        setBillPretaxSubTotal(total_amounts.pre_tax_amount)
        setBillTotal(total_amounts.final_amount)
        setBillTotalTax(total_amounts.tax_amount)
        setBillTotalMarkup(total_amounts.markup_amount)


        let paid_amount = paymentHistory.reduce((prev, val, i) => {

            return prev + parseFloat(val.amount || 0)
        }, 0)
        setBillPaid(paid_amount)

        let due_amount = total_amounts.final_amount - paid_amount
        due_amount = due_amount < 0 ? 0 : due_amount
        setBillDue(due_amount)


    }, [materialItems, paymentHistory])


    const HandleCancel = async (e) => {

        close()
    }


    return (
        <>


            {isLoading ?

                <Loading
                    props={{
                        isMainLogo: false,
                        isLabel: true
                    }} />

                : null}

            {warningAlert ?

                <SystemToastPopup
                    props={{
                        type: warningAlertType,
                        message: apiFailedMessage || "Error in Bill",
                        callback: (confirmation) => setWarningAlert(false)
                    }} />
                : null}

            <div className="popup-container-main popup-container-center">
                <div className="popup-block-ui"></div>
                <div className="side-popup-container preview-popup-container">

                    <div className="side-popup-header">
                        <div></div>
                        <div className="header-item-select">
                            <div className="header-item-select-content">
                                <div className="label">Web Preview</div>
                                <div className="description">This is what Vendor will see when you send the bill</div>
                            </div>
                        </div>
                        <div
                            className="header-item-close"
                            onClick={(e) => HandleCancel()}
                            dangerouslySetInnerHTML={{ __html: Icons.general.close }}
                        ></div>
                    </div>

                    <div className="sidebar-popup-content">
                        <div className="project-create_client_invoice-main" id='project-create_client_invoice-main'>

                            <div className="create_client_invoice-panel-content">
                                <PDFExport
                                    ref={pdfRef}
                                    author='CivilATOR'
                                    fileName={`${String(projectName).split(' ').join('_')}_Bill_${Utils.getLocalFullDateBYFormat(null, '_', dateFormat)}`}
                                    margin={5}
                                    paperSize="A4"
                                    scale={0.6}
                                >
                                    <div className="panel-content-sections">
                                        <div className="create-proposal-content-main">

                                            <div className="create-proposal-sections">

                                                <div className="preview-header-section">
                                                    <div className="header-section-top">
                                                        <div className="company-detials">
                                                            <div className="logo">
                                                                <img src={companyImg || Images.User} />
                                                            </div>
                                                            <div className="title">{companyName}</div>
                                                        </div>
                                                        <div className="detials">
                                                            <div className="detials-item" >
                                                                <div className="label">Status</div>
                                                                <div className="value">{status}</div>
                                                            </div>
                                                            <div className="detials-item">
                                                                <div className="label">Project ID</div>
                                                                <div className="value">{projectId}</div>
                                                            </div>
                                                            <div className="detials-item">
                                                                <div className="label">Bill ID</div>
                                                                <div className="value">{billId}</div>
                                                            </div>

                                                        </div>
                                                    </div>
                                                    <div className="header-section-detials">
                                                        <div className="title">Bill Information</div>
                                                        <div className="detials">
                                                            <div className="detials-item">
                                                                <div className="label">Bill Name</div>
                                                                <div className="value">{name}</div>
                                                            </div>
                                                            <div className="detials-item">
                                                                <div className="label">Bill Date</div>
                                                                <div className="value">{billDate}</div>
                                                            </div>

                                                            <div className="detials-item">
                                                                <div className="label">Due Date</div>
                                                                <div className="value">{dueDate}</div>
                                                            </div>
                                                            <div className="detials-item">
                                                                <div className="label">Vendor Name</div>
                                                                <div className="value">{vendor_name}</div>
                                                            </div>
                                                            <div className="detials-item">
                                                                <div className="label">Vendor GST</div>
                                                                <div className="value">{vendor_gstno}</div>
                                                            </div>
                                                            <div className="detials-item">
                                                                <div className="label">Project Name</div>
                                                                <div className="value">{projectName}</div>
                                                            </div>
                                                            <div className="detials-item">
                                                                <div className="label">Project Address</div>
                                                                <div className="value">{projectAddress}</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                {introduction_text ?
                                                    <div
                                                        className="create-proposal-section"

                                                        id={`create-proposal-introduction_text`}
                                                        dangerouslySetInnerHTML={{ __html: introduction_text }}
                                                    >
                                                    </div>
                                                    : ""}

                                                {materialItems.length ?
                                                    <div
                                                        className="create-client_invoicesection create-proposal-section"
                                                        id={`create-bill_payment_history`}

                                                    >
                                                        <div className={`createproposal-description-main`}>

                                                            <div className="head">
                                                                <div className="title">
                                                                    <div className="label">Items Includes</div>
                                                                </div>
                                                            </div>


                                                            <div className="preview-costItems-table-main">
                                                                <div className="preview-costItems-table-header">
                                                                    <div className="header-item table-item-description">Description</div>
                                                                    <div className="header-item table-item-amount">Quantity</div>
                                                                    <div className="header-item table-item-amount">Unit rate</div>
                                                                    <div className="header-item table-item-amount">Amount</div>
                                                                </div>
                                                                <div className="preview-costItems-table-content">

                                                                    {materialItems?.map((item, i) => (
                                                                        <div
                                                                            className="content-row"
                                                                            key={`preview-costItems-table-item-${item.id}`}
                                                                        >
                                                                            <div className="content-row-item table-item-description">
                                                                                {item.material_name} <br />{item.description ?
                                                                                    `${item.description}`
                                                                                    : ''}
                                                                            </div>
                                                                            <div className="content-row-item table-item-amount">{item.quantity} {item.unit_name}</div>
                                                                            <div className="content-row-item table-item-amount">{currency_type == '₹' ?
                                                                                <span
                                                                                    className='proposal-currency-small'
                                                                                    dangerouslySetInnerHTML={{ __html: Icons.general.inr }}
                                                                                ></span>
                                                                                : currency_type}{item.final_unitrate}</div>
                                                                            <div className="content-row-item table-item-amount">{currency_type == '₹' ?
                                                                                <span
                                                                                    className='proposal-currency-small'
                                                                                    dangerouslySetInnerHTML={{ __html: Icons.general.inr }}
                                                                                ></span>
                                                                                : currency_type}{item.pre_tax_amount}</div>
                                                                        </div>
                                                                    ))}

                                                                </div>

                                                            </div>



                                                        </div>
                                                    </div>
                                                    : ''}


                                                <div className="create-proposal-section" id={`create-proposal-summary`}>
                                                    <div className="createproject-section proposal-left-section">
                                                        <div className="proposal-summary-totals proposal-summary-title">
                                                            <div className="title">Summary</div>
                                                        </div>
                                                        <div className="proposal-summary-totals proposal-summary-items">

                                                            <div className="proposal-summary-item">
                                                                <div className="proposal-summary-item-content">

                                                                    <div className="item">
                                                                        <div className="title">Subtotal</div>
                                                                        <div className="quantity"></div>
                                                                        <div className="amount">
                                                                            {currency_type == '₹' ?
                                                                                <span
                                                                                    className='proposal-currency-small'
                                                                                    dangerouslySetInnerHTML={{ __html: Icons.general.inr }}
                                                                                ></span>
                                                                                : currency_type} {parseFloat(BillPretaxSubTotal || 0).toFixed(2)}
                                                                        </div>
                                                                    </div>
                                                                    <div className="item">
                                                                        <div className="title">Tax</div>
                                                                        <div className="quantity"></div>
                                                                        <div className="amount">
                                                                            {currency_type == '₹' ?
                                                                                <span
                                                                                    className='proposal-currency-small'
                                                                                    dangerouslySetInnerHTML={{ __html: Icons.general.inr }}
                                                                                ></span>
                                                                                : currency_type} {parseFloat(BillTotalTax || 0).toFixed(2)}
                                                                        </div>
                                                                    </div>
                                                                    <div className="item">
                                                                        <div className="title">Total</div>
                                                                        <div className="quantity"></div>
                                                                        <div className="amount">
                                                                            {currency_type == '₹' ?
                                                                                <span
                                                                                    className='proposal-currency-small'
                                                                                    dangerouslySetInnerHTML={{ __html: Icons.general.inr }}
                                                                                ></span>
                                                                                : currency_type} {parseFloat(BillTotal || 0).toFixed(2)}
                                                                        </div>
                                                                    </div>
                                                                    <div className="item">
                                                                        <div className="title">Paid</div>
                                                                        <div className="quantity"></div>
                                                                        <div className="amount">
                                                                            {currency_type == '₹' ?
                                                                                <span
                                                                                    className='proposal-currency-small'
                                                                                    dangerouslySetInnerHTML={{ __html: Icons.general.inr }}
                                                                                ></span>
                                                                                : currency_type} {parseFloat(BillPaid || 0).toFixed(2)}
                                                                        </div>
                                                                    </div>
                                                                    <div className="item" style={{ fontSize: '24px' }}>
                                                                        <div className="title">Due</div>
                                                                        <div className="quantity"></div>
                                                                        <div className="amount">
                                                                            {currency_type == '₹' ?
                                                                                <span
                                                                                    className='proposal-currency-small'
                                                                                    dangerouslySetInnerHTML={{ __html: Icons.general.inr }}
                                                                                ></span>
                                                                                : currency_type} {parseFloat(BillDue || 0).toFixed(2)}
                                                                        </div>
                                                                    </div>

                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                {termsConditionValue ?
                                                    <div
                                                        className="create-proposal-section"

                                                        id={`create-proposal-terms_conditions`}>

                                                        <div className={`createproposal-description-main`}>
                                                            <div className="head">
                                                                <div className="label">Terms and conditions</div>
                                                            </div>
                                                            <div className="terms-condition-result" dangerouslySetInnerHTML={{ __html: termsConditionValue }}></div>
                                                        </div>

                                                    </div>
                                                    : ''}

                                                {paymentHistory.length ?
                                                    <div
                                                        className="create-client_invoicesection"
                                                        id={`create-bill_payment_history`}

                                                    >
                                                        <div className={`createproposal-description-main`}>

                                                            <div className="head">
                                                                <div className="title">
                                                                    <div className="label">Payments History ({paymentHistory.length})</div>
                                                                </div>
                                                            </div>


                                                            <div className="paymenthistory-table-main">
                                                                <div className="paymenthistory-table-header">
                                                                    <div className="header-item table-item-sno">S:No</div>
                                                                    <div className="header-item table-item-date">Date</div>
                                                                    <div className="header-item table-item-amount">Amount</div>
                                                                    <div className="header-item table-item-payment_method">Payment Method</div>
                                                                    <div className="header-item table-item-notes">Notes</div>
                                                                    <div className="header-item table-item-status">Status</div>
                                                                </div>
                                                                <div className="paymenthistory-table-content">

                                                                    {paymentHistory?.map((item, i) => (
                                                                        <div
                                                                            className="content-row"
                                                                            key={`paymenthistory-table-item-${item.id}`}
                                                                        >
                                                                            <div className="content-row-item table-item-sno">{i + 1}</div>
                                                                            <div className="content-row-item table-item-date">{item.date}</div>
                                                                            <div className="content-row-item table-item-amount">{currency_type == '₹' ?
                                                                                <span
                                                                                    className='proposal-currency-small'
                                                                                    dangerouslySetInnerHTML={{ __html: Icons.general.inr }}
                                                                                ></span>
                                                                                : currency_type} {item.amount}</div>
                                                                            <div className="content-row-item table-item-payment_method">{item.payment_method}</div>
                                                                            <div className="content-row-item table-item-notes">{item.notes}</div>
                                                                            <div className="content-row-item table-item-status">{item.status}</div>
                                                                        </div>
                                                                    ))}

                                                                </div>

                                                            </div>



                                                        </div>
                                                    </div>
                                                    : ''}

                                                {comments ?
                                                    <div className="create-proposal-section" id={`create-proposal-comments`}>

                                                        <div className={`createproposal-description-main`}>
                                                            <div className="head">
                                                                <div className="label">Comments</div>
                                                            </div>
                                                            <div className="terms-condition-result" dangerouslySetInnerHTML={{ __html: comments }}></div>
                                                        </div>

                                                    </div>
                                                    : ''}
                                            </div>
                                        </div>
                                        {ShowWatermark ?
                                            <div className="proposal-product-watermark">
                                                <div className="watermark">
                                                    <div className="label">Powered by</div>
                                                    <img src={Images.logo} className='img' />
                                                </div>
                                            </div>
                                            : ''}
                                    </div>
                                </PDFExport>
                            </div>
                        </div>
                    </div>
                    <div className="sidebar-popup-footer">
                        <div className="footer-item action-items">
                            <div className="action-preview">
                            </div>
                            <div className='action-btns'>
                                <div
                                    className="action-btn button-download"
                                    onClick={(e) => HandleDownloadBtn(e)}
                                >
                                    <div
                                        className="icon"
                                        dangerouslySetInnerHTML={{ __html: Icons.general.download }}
                                    ></div>
                                    <div className="label">Download</div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )

}

export default PreviewBill;