
import proxyConfig from '../../config/reverseProxy'
import HeaderConfig from '../../config/header'

class Comments {

    constructor() {

    }


    async getCommentsHandler(params) {

        try {

            let response = await fetch(proxyConfig['serverBaseUrl'] + '/builder/comments/get-comments', {
                method: 'POST',
                headers: HeaderConfig.getHeader({ method: 'POST', isToken: true }),
                body: JSON.stringify(params)
            })

            response = await response.json()

            if (response) return response
        } catch {
            return { success: false }
        }


    }
    async createCommentHandler(params) {

        try {

            let response = await fetch(proxyConfig['serverBaseUrl'] + '/builder/comments/create-comment', {
                method: 'POST',
                headers: HeaderConfig.getHeader({ method: 'POST', isToken: true }),
                body: JSON.stringify(params)
            })

            response = await response.json()

            if (response) return response
        } catch {
            return { success: false }
        }


    }
    async createMultiCommentHandler(params) {

        try {

            let response = await fetch(proxyConfig['serverBaseUrl'] + '/builder/comments/create-multi-comment', {
                method: 'POST',
                headers: HeaderConfig.getHeader({ method: 'POST', isToken: true }),
                body: JSON.stringify(params)
            })

            response = await response.json()

            if (response) return response
        } catch {
            return { success: false }
        }


    }
    async updateCommentHandler(params) {

        try {

            let response = await fetch(proxyConfig['serverBaseUrl'] + '/builder/comments/update-comment', {
                method: 'POST',
                headers: HeaderConfig.getHeader({ method: 'POST', isToken: true }),
                body: JSON.stringify(params)
            })

            response = await response.json()

            if (response) return response
        } catch {
            return { success: false }
        }


    }
    async deleteCommentHandler(params) {

        try {

            let response = await fetch(proxyConfig['serverBaseUrl'] + '/builder/comments/delete-comment', {
                method: 'POST',
                headers: HeaderConfig.getHeader({ method: 'POST', isToken: true }),
                body: JSON.stringify(params)
            })

            response = await response.json()

            if (response) return response
        } catch {
            return { success: false }
        }


    }


}

export default Comments;