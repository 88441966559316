
import proxyConfig from '../../config/reverseProxy'
import HeaderConfig from '../../config/header'

class ScheduleRates {

    constructor() {

    }


    async getItemsHandler(params) {

        try {

            let response = await fetch(proxyConfig['serverBaseUrl'] + '/builder/schedule-rates/get-schedule-rate-items', {
                method: 'POST',
                headers: HeaderConfig.getHeader({ method: 'POST', isToken: true }),
                body: JSON.stringify(params)
            })

            response = await response.json()

            if (response) return response
        } catch {
            return { success: false }
        }


    }
    async createGroupHandler(params) {

        try {

            let response = await fetch(proxyConfig['serverBaseUrl'] + '/builder/schedule-rates/create-schedule-rate-group', {
                method: 'POST',
                headers: HeaderConfig.getHeader({ method: 'POST', isToken: true }),
                body: JSON.stringify(params)
            })

            response = await response.json()

            if (response) return response
        } catch {
            return { success: false }
        }


    }
    async updateGroupHandler(params) {

        try {

            let response = await fetch(proxyConfig['serverBaseUrl'] + '/builder/schedule-rates/update-schedule-rate-group', {
                method: 'POST',
                headers: HeaderConfig.getHeader({ method: 'POST', isToken: true }),
                body: JSON.stringify(params)
            })

            response = await response.json()

            if (response) return response
        } catch {
            return { success: false }
        }


    }
    async createItemHandler(params) {

        try {

            let response = await fetch(proxyConfig['serverBaseUrl'] + '/builder/schedule-rates/create-schedule-rate-item', {
                method: 'POST',
                headers: HeaderConfig.getHeader({ method: 'POST', isToken: true }),
                body: JSON.stringify(params)
            })

            response = await response.json()

            if (response) return response
        } catch {
            return { success: false }
        }


    }
    async updateItemHandler(params) {

        try {

            let response = await fetch(proxyConfig['serverBaseUrl'] + '/builder/schedule-rates/update-schedule-rate-item', {
                method: 'POST',
                headers: HeaderConfig.getHeader({ method: 'POST', isToken: true }),
                body: JSON.stringify(params)
            })

            response = await response.json()

            if (response) return response
        } catch {
            return { success: false }
        }


    }
    async deleteItemHandler(params) {

        try {

            let response = await fetch(proxyConfig['serverBaseUrl'] + '/builder/schedule-rates/delete-schedule-rate-item', {
                method: 'POST',
                headers: HeaderConfig.getHeader({ method: 'POST', isToken: true }),
                body: JSON.stringify(params)
            })

            response = await response.json()

            if (response) return response
        } catch {
            return { success: false }
        }


    }


}

export default ScheduleRates;