
import proxyConfig from '../../config/reverseProxy'
import HeaderConfig from '../../config/header'

class WorkTemplates {

    constructor() {

    }


    async getWorkTemplatesHandler(params) {

        try {

            let response = await fetch(proxyConfig['serverBaseUrl'] + '/builder/work-templates/get-work_templates', {
                method: 'POST',
                headers: HeaderConfig.getHeader({ method: 'POST', isToken: true }),
                body: JSON.stringify(params)
            })

            response = await response.json()

            if (response) return response
        } catch {
            return { success: false }
        }


    }
    async createWorkTemplateHandler(params) {

        try {

            let response = await fetch(proxyConfig['serverBaseUrl'] + '/builder/work-templates/create-work_templates', {
                method: 'POST',
                headers: HeaderConfig.getHeader({ method: 'POST', isToken: true }),
                body: JSON.stringify(params)
            })

            response = await response.json()

            if (response) return response
        } catch {
            return { success: false }
        }


    }
    async updateWorkTemplateHandler(params) {

        try {

            let response = await fetch(proxyConfig['serverBaseUrl'] + '/builder/work-templates/update-work_templates', {
                method: 'POST',
                headers: HeaderConfig.getHeader({ method: 'POST', isToken: true }),
                body: JSON.stringify(params)
            })

            response = await response.json()

            if (response) return response
        } catch {
            return { success: false }
        }


    }
    async deleteWorkTemplateHandler(params) {

        try {

            let response = await fetch(proxyConfig['serverBaseUrl'] + '/builder/work-templates/delete-work_templates', {
                method: 'POST',
                headers: HeaderConfig.getHeader({ method: 'POST', isToken: true }),
                body: JSON.stringify(params)
            })

            response = await response.json()

            if (response) return response
        } catch {
            return { success: false }
        }


    }

}

export default WorkTemplates;