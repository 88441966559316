
import proxyConfig from '../../config/reverseProxy'
import HeaderConfig from '../../config/header'

class Estimate {

    constructor() {

    }


    async getProjectEstimationHandler(params) {

        try {

            let response = await fetch(proxyConfig['serverBaseUrl'] + '/builder/estimate/get-project-estimate', {
                method: 'POST',
                headers: HeaderConfig.getHeader({ method: 'POST', isToken: true }),
                body: JSON.stringify(params)
            })

            response = await response.json()

            if (response) return response
        } catch {
            return { success: false }
        }


    }
    async getEstimateItemsHandler(params) {

        try {

            let response = await fetch(proxyConfig['serverBaseUrl'] + '/builder/estimate/get-estimate-items', {
                method: 'POST',
                headers: HeaderConfig.getHeader({ method: 'POST', isToken: true }),
                body: JSON.stringify(params)
            })

            response = await response.json()

            if (response) return response
        } catch {
            return { success: false }
        }


    }
    async createEstimateGroupHandler(params) {

        try {

            let response = await fetch(proxyConfig['serverBaseUrl'] + '/builder/estimate/create-estimate-group', {
                method: 'POST',
                headers: HeaderConfig.getHeader({ method: 'POST', isToken: true }),
                body: JSON.stringify(params)
            })

            response = await response.json()

            if (response) return response
        } catch {
            return { success: false }
        }


    }
    async updateEstimateGroupHandler(params) {

        try {

            let response = await fetch(proxyConfig['serverBaseUrl'] + '/builder/estimate/update-estimate-group', {
                method: 'POST',
                headers: HeaderConfig.getHeader({ method: 'POST', isToken: true }),
                body: JSON.stringify(params)
            })

            response = await response.json()

            if (response) return response
        } catch {
            return { success: false }
        }


    }
    async createEstimateItemHandler(params) {

        try {

            let response = await fetch(proxyConfig['serverBaseUrl'] + '/builder/estimate/create-estimate-item', {
                method: 'POST',
                headers: HeaderConfig.getHeader({ method: 'POST', isToken: true }),
                body: JSON.stringify(params)
            })

            response = await response.json()

            if (response) return response
        } catch {
            return { success: false }
        }


    }
    async updateEstimateItemHandler(params) {

        try {

            let response = await fetch(proxyConfig['serverBaseUrl'] + '/builder/estimate/update-estimate-item', {
                method: 'POST',
                headers: HeaderConfig.getHeader({ method: 'POST', isToken: true }),
                body: JSON.stringify(params)
            })

            response = await response.json()

            if (response) return response
        } catch {
            return { success: false }
        }


    }
    async importItemsFromWorkTemplatesHandler(params) {

        try {

            let response = await fetch(proxyConfig['serverBaseUrl'] + '/builder/estimate/import-worktemplate-items', {
                method: 'POST',
                headers: HeaderConfig.getHeader({ method: 'POST', isToken: true }),
                body: JSON.stringify(params)
            })

            response = await response.json()

            if (response) return response
        } catch {
            return { success: false }
        }


    }
    async deleteEstimateItemHandler(params) {

        try {

            let response = await fetch(proxyConfig['serverBaseUrl'] + '/builder/estimate/delete-estimate-item', {
                method: 'POST',
                headers: HeaderConfig.getHeader({ method: 'POST', isToken: true }),
                body: JSON.stringify(params)
            })

            response = await response.json()

            if (response) return response
        } catch {
            return { success: false }
        }


    }


    async updateEstimateOverheadHandler(params) {

        try {

            let response = await fetch(proxyConfig['serverBaseUrl'] + '/builder/estimate/update-estimate-summary-overhead', {
                method: 'POST',
                headers: HeaderConfig.getHeader({ method: 'POST', isToken: true }),
                body: JSON.stringify(params)
            })

            response = await response.json()

            if (response) return response
        } catch {
            return { success: false }
        }

    }
    async updateEstimateInsuranceHandler(params) {

        try {

            let response = await fetch(proxyConfig['serverBaseUrl'] + '/builder/estimate/update-estimate-summary-insurance', {
                method: 'POST',
                headers: HeaderConfig.getHeader({ method: 'POST', isToken: true }),
                body: JSON.stringify(params)
            })

            response = await response.json()

            if (response) return response
        } catch {
            return { success: false }
        }

    }
    async updateEstimatePricingHandler(params) {

        try {

            let response = await fetch(proxyConfig['serverBaseUrl'] + '/builder/estimate/update-estimate-summary-pricing', {
                method: 'POST',
                headers: HeaderConfig.getHeader({ method: 'POST', isToken: true }),
                body: JSON.stringify(params)
            })

            response = await response.json()

            if (response) return response
        } catch {
            return { success: false }
        }

    }

}

export default Estimate;