

import React, { useEffect, useState, useRef } from 'react'
import { Route, Routes, BrowserRouter, Link, useLocation, Navigate, Outlet, useNavigate, useParams } from 'react-router-dom';
import store from '../../redux/store'
import { useSelector, useDispatch } from 'react-redux'
import UserAction from '../../redux/action/userAction'

import ReverseProxy from '../../config/reverseProxy'
import RoleManagement from '../../Handlers/RoleManagement/RoleManagement';
import TeamManagementHandler from '../../Handlers/TeamManagement/TeamManagement';

import Icons from '../../assets/Icons'
import Images from '../../assets/Images'
import { CostInput, TextInput, SelectInput, RadioInput } from '../../components/Inputs'
import Utils from '../../utils'
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

import AlertPopup from '../../components/AlertPopup'
import Loading from '../../components/Loading'
import SystemToastPopup from '../../components/SystemToastPopup'


import SubscriptionsHandler from '../../Handlers/Subscriptions/Subscriptions'
import CashFree_Paymentgateway from '../../Handlers/PaymentGateways/CashFree_Paymentgateway'

import { useAuth, useMenuAuth } from '../../hooks/useAuth'


const MySubscriptions = () => {

    const store = useSelector((store) => store)
    const dispatch = useDispatch()
    const { updateState } = new UserAction

    const navigate = useNavigate()

    const subscriptionsHandler = new SubscriptionsHandler()

    const [isLoading, setIsLoading] = useState(false)
    const [warningAlert, setWarningAlert] = useState(false)
    const [warningAlertType, setWarningAlertType] = useState('warning')
    const [apiFailedMessage, setApiFailedMessage] = useState("Error in Server")

    const [UsersDetials_original, setUsersDetials_original] = useState([])
    const [UsersDetials, setUsersDetials] = useState([])
    const [selected_termsCondition, setSelected_termsCondition] = useState({})
    const [selectedAction, setSelectedAction] = useState('')

    const { isAdmin, roles_permissions, company_features } = useAuth()


    const [selected_view_settings_value, setSelected_view_settings_value] = useState('')
    const [has_view_access, setHas_view_access] = useState(false)
    const [has_add_access, setHas_add_access] = useState(false)
    const [has_edit_access, setHas_edit_access] = useState(false)
    const [has_delete_access, setHas_delete_access] = useState(false)

    const [subscriptionPlans, setSubscriptionPlans] = useState([])

    const LoadMySubscriptions = async () => {
        setIsLoading(true)
        const response = await subscriptionsHandler.getMySubscriptions()
        setIsLoading(false)

        if (response.success) {

            let plans = response.data



            plans = plans.sort((a, b) => new Date(b.created_at).getTime() - new Date(a.created_at).getTime()).map((plan) => {

                let expiry_days = Utils.getDuration(parseFloat(plan.date_subdcribed), parseFloat(plan.date_unsubdcribed), 'days')

                let status = expiry_days > 10 ? 1 : expiry_days > 0 ? 2 : 3
                let status_label = status == '3' ? 'Expired' : status == '1' ? 'Active' : status == '2' ? 'Expiry soon' : 'Default'
                let status_class = status == '3' ? 'plan-status-expired' : status == '1' ? 'plan-status-active' : status == '2' ? 'plan-status-going-to-expiry' : ''

                return {
                    id: plan.id,
                    name: plan.plan_name,
                    duration: plan.duration,
                    duration_type: plan.duration_type,
                    amount: plan.subscription_amount,
                    startdate: Utils.getLocalFullDateLabel(parseFloat(plan.date_subdcribed)),
                    expirydate: Utils.getLocalFullDateLabel(parseFloat(plan.date_unsubdcribed)),
                    status: status,
                    status_label,
                    status_class
                }

            })
            setSubscriptionPlans(plans)

        } else {
            setWarningAlert(true)
            setWarningAlertType('error')
            setApiFailedMessage('Error in retriving plans')
        }
    }


    const HandleUpgradePlan = () => {
        navigate(`${ReverseProxy['proxyUrl']}/builder/subscription-plans`)
    }

    useEffect(() => {

        // temp redirection
        // navigate(`${ReverseProxy['proxyUrl']}/quick-menu`)


        LoadMySubscriptions()
    }, [])

    useEffect(() => {

        if (!isAdmin) {
            navigate(`${ReverseProxy['proxyUrl']}/quick-menu`)
            return () => { }
        }

    }, [store.user?.roles_permissions])


    return (
        <>

            {isLoading ?

                <Loading
                    props={{
                        isMainLogo: false,
                        isLabel: true
                    }} />

                : null}

            {warningAlert ?

                <SystemToastPopup
                    props={{
                        type: warningAlertType,
                        message: apiFailedMessage || "Error in Bill",
                        callback: (confirmation) => setWarningAlert(false)
                    }} />
                : null}

            <div className="project-mysubscriptions-main">
                <div className="mysubscriptions-content-main">
                    <div className="mysubscriptions-header">
                        <div className="title">My Active Plans</div>

                    </div>

                    <div className="mysubscriptions-plans-main">

                        {!subscriptionPlans.length && !isLoading ?

                            <div className="mysubscriptions-noplans-main">
                                <div className="title">No Active Plans</div>
                                <div className="subtitle">Please upgrade your plan to continue</div>
                                <div className="buttons">
                                    <div
                                        className="button"
                                        onClick={() => HandleUpgradePlan()}
                                    >Upgrade Now</div>
                                </div>
                            </div>
                            : ''}

                        <div className="mysubscriptions-plans-content">



                            {
                                subscriptionPlans?.map((plan, index) => (

                                    <div
                                        className="mysubscriptions-plan"
                                        key={`mysubscriptions-plan-${index}`}
                                    >
                                        <div className="top-detials">
                                            <div className="left">
                                                <div className="plan-desc">Manpower Management</div>
                                                <div className="plan-name">{plan.name}</div>
                                                <div className="plan-startdate">Start's from: <span className='strong'>{plan.startdate}</span></div>
                                            </div>
                                            <div className="right">
                                                <div className="plan-duration">{plan.duration} {plan.duration_type == '2' ? 'Yrs' : 'Mnt'} /</div>
                                                <div className="plan-amount">₹{plan.amount}</div>
                                            </div>
                                        </div>
                                        <div className="bottom-detials">
                                            <div className="left">
                                                <div className="plan-expiry">Expiry : <span className='strong'>{plan.expirydate}</span></div>
                                            </div>
                                            <div className="right">
                                                <div className={`plan-status ${plan.status_class}`}>{plan.status_label}</div>
                                                {index == 0 ?
                                                    <div
                                                        className="plan-renew-button"
                                                        onClick={() => HandleUpgradePlan()}
                                                    >Upgrade Now</div>
                                                    : ''}
                                            </div>
                                        </div>
                                    </div>

                                ))
                            }


                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}

export default MySubscriptions;