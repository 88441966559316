

import React, { useEffect, useState, useRef, useCallback, useMemo, StrictMode } from 'react'
import { Route, Routes, BrowserRouter, Link, useLocation, Navigate, Outlet, useNavigate, useParams } from 'react-router-dom';
import store from '../../redux/store'
import StoreHandler from '../../redux/StoreHandler'
import { useSelector, useDispatch } from 'react-redux'
import UserAction from '../../redux/action/userAction'

import ReverseProxy from '../../config/reverseProxy'
import FinanceHandler from '../../Handlers/Finance/Finance';
import Icons from '../../assets/Icons'
import Images from '../../assets/Images'
import { CostInput, TextInput, SearcBarInput, SelectInput, RadioInput } from '../../components/Inputs'
import Utils from '../../utils'
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

import AlertPopup from '../../components/AlertPopup'
import Loading from '../../components/Loading'
import SystemToastPopup from '../../components/SystemToastPopup'

import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableFooter from '@mui/material/TableFooter';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';

import SideFilters from '../../components/SideFilters';
import SmarkTopCards from '../../components/SmarkTopCards'

import { useAuth, useMenuAuth } from '../../hooks/useAuth'
const FinanceSummary = () => {

    const store = useSelector((store) => store)
    const dispatch = useDispatch()
    const { updateState } = new UserAction

    const { projectId } = useParams();

    const navigate = useNavigate()

    const financeHandler = new FinanceHandler()

    const [currency_type, setCurrency_type] = useState('₹')

    const storeHandler = new StoreHandler({
        dataStack: ['company'],
        store: store,
        dispatch: dispatch,
        updateState: updateState,
    })

    const LoadStoreData = async () => {
        setIsLoading(true)
        let resolved = await storeHandler.init()
        setIsLoading(false)
    }


    const { isAdmin, menu_permission } = useMenuAuth({ menu: 'finance--finance_summary', feature_key: 'finance' })
    const location = useLocation()

    const [selected_view_settings_value, setSelected_view_settings_value] = useState('')
    const [has_view_access, setHas_view_access] = useState(false)
    const [has_add_access, setHas_add_access] = useState(false)
    const [has_edit_access, setHas_edit_access] = useState(false)
    const [has_delete_access, setHas_delete_access] = useState(false)

    useEffect(() => {

        if (isAdmin) {

            setHas_view_access(true)
            setHas_add_access(true)
            setHas_edit_access(true)
            setHas_delete_access(true)
        }

        else if (menu_permission) {

            if (
                !menu_permission._view
                &&
                (!menu_permission.selected_view_settings_value
                    || menu_permission.selected_view_settings_value == 'no_access')
            ) {
                navigate(`${ReverseProxy['proxyUrl']}/quick-menu`, { state: { from: location }, replace: true })
            }

            if (menu_permission._view == '0') navigate(`${ReverseProxy['proxyUrl']}/builder/projects`, { state: { from: location }, replace: true })

            setHas_view_access(menu_permission._view == '1')
            setHas_add_access(menu_permission._create == '1')
            setHas_edit_access(menu_permission._edit == '1')
            setHas_delete_access(menu_permission._delete == '1')

            setSelected_view_settings_value(menu_permission.selected_view_settings_value)

        }

    }, [store])


    const [isLoading, setIsLoading] = useState(false)
    const [warningAlert, setWarningAlert] = useState(false)
    const [warningAlertType, setWarningAlertType] = useState('warning')
    const [apiFailedMessage, setApiFailedMessage] = useState("Error in Server")

    const [SummaryDetials, setSummaryDetials] = useState({

        total_labour_estimated_price: 0,
        total_labour_expense_price: 0,

        total_material_estimated_price: 0,
        total_material_expense_price: 0,

        total_machine_estimated_price: 0,
        total_machine_expense_price: 0,

        total_markup_estimated_price: 0,
        total_markup_expense_price: 0,

        total_tax_estimated_price: 0,
        total_tax_expense_price: 0,

        total_changeorder_price: 0,

        subtotal_estimated_price: 0,
        subtotal_expense_price: 0,

        final_total_price: 0,
        total_difference_amount: 0,
        total_clientpayment_price: 0,
        total_balance_price: 0,

    })

    const LoadFinanceSummary = async () => {

        let filters = {
            projectId: projectId
        }

        setIsLoading(true)
        let financeSummary = await financeHandler.getFinanceSummaryHandler(filters)

        setIsLoading(false)
        if (financeSummary.success) {

            let financeSummaryData = financeSummary.data

            if (financeSummaryData && Object.keys(financeSummaryData).length > 0) {

                let _SummaryDetials = {
                    total_labour_estimated_price: parseFloat(financeSummaryData.total_labour_estimated_price || 0).toFixed(2),
                    total_labour_expense_price: parseFloat(financeSummaryData.total_labour_expense_price || 0).toFixed(2),
                    total_material_estimated_price: parseFloat(financeSummaryData.total_material_estimated_price || 0).toFixed(2),
                    total_material_expense_price: parseFloat(financeSummaryData.total_material_expense_price || 0).toFixed(2),
                    total_machine_estimated_price: parseFloat(financeSummaryData.total_machine_estimated_price || 0).toFixed(2),
                    total_machine_expense_price: parseFloat(financeSummaryData.total_machine_expense_price || 0).toFixed(2),
                    total_markup_estimated_price: parseFloat(financeSummaryData.total_markup_estimated_price || 0).toFixed(2),
                    total_markup_expense_price: parseFloat(financeSummaryData.total_markup_expense_price || 0).toFixed(2),
                    total_tax_estimated_price: parseFloat(financeSummaryData.total_tax_estimated_price || 0).toFixed(2),
                    total_tax_expense_price: parseFloat(financeSummaryData.total_tax_expense_price || 0).toFixed(2),
                    total_changeorder_price: parseFloat(financeSummaryData.total_changeorder_price || 0).toFixed(2),
                    subtotal_estimated_price: parseFloat(financeSummaryData.subtotal_estimated_price || 0).toFixed(2),
                    subtotal_expense_price: parseFloat(financeSummaryData.subtotal_expense_price || 0).toFixed(2),
                    final_total_price: parseFloat(financeSummaryData.final_total_price || 0).toFixed(2),
                    total_clientpayment_price: parseFloat(financeSummaryData.total_clientpayment_price || 0).toFixed(2),
                    total_balance_price: parseFloat(financeSummaryData.total_balance_price || 0).toFixed(2),

                    total_difference_amount: (parseFloat(financeSummaryData.subtotal_estimated_price || 0) - (parseFloat(financeSummaryData.subtotal_expense_price || 0) + parseFloat(financeSummaryData.total_changeorder_price || 0))).toFixed(2),
                }

                setSummaryDetials(_SummaryDetials)
            }

        }
        else {
            setWarningAlert(true)
            setWarningAlertType('error')
            setApiFailedMessage('Error in Finance Summary')
        }
    }

    useEffect(() => {
        LoadFinanceSummary()

    }, [])

    return (
        <>

            <div className="summary-component-main">
                <div className="summary-component-container">
                    <div className="summary-component-content">
                        <div className="summary-container-section">
                            <div className="summary-header">
                                <div className="summary-header-item summary-item-title">
                                    <div className="label">Summary</div>
                                </div>

                                <div className="summary-header-item summary-item-price">
                                    <div className="label">Estimated Price</div>
                                </div>
                                <div className="summary-header-item summary-item-price">
                                    <div className="label">Actual Price</div>
                                </div>
                            </div>
                            <div className="summary-content-items">
                                <div className="summary-content-item">
                                    <div className="label summary-item-navigate summary-item-title">Labour</div>
                                    <div className="value summary-item-price">{currency_type || '₹'}{SummaryDetials.total_labour_estimated_price}</div>
                                    <div className="value summary-item-price">{currency_type || '₹'}{SummaryDetials.total_labour_expense_price}</div>
                                </div>
                                <div className="summary-content-item">
                                    <div className="label summary-item-navigate summary-item-title">Material</div>
                                    <div className="value summary-item-price">{currency_type || '₹'}{SummaryDetials.total_material_estimated_price}</div>
                                    <div className="value summary-item-price">{currency_type || '₹'}{SummaryDetials.total_material_expense_price}</div>
                                </div>
                                <div className="summary-content-item">
                                    <div className="label summary-item-navigate summary-item-title">Machines</div>
                                    <div className="value summary-item-price">{currency_type || '₹'}{SummaryDetials.total_machine_estimated_price}</div>
                                    <div className="value summary-item-price">{currency_type || '₹'}{SummaryDetials.total_machine_expense_price}</div>
                                </div>
                                <div className="summary-content-item">
                                    <div className="label summary-item-navigate summary-item-title">Tax</div>
                                    <div className="value summary-item-price">{currency_type || '₹'}{SummaryDetials.total_tax_estimated_price}</div>
                                    <div className="value summary-item-price">{currency_type || '₹'}{SummaryDetials.total_tax_expense_price}</div>
                                </div>
                                <div className="summary-content-item">
                                    <div className="label summary-item-navigate summary-item-title">Markup</div>
                                    <div className="value summary-item-price">{currency_type || '₹'}{SummaryDetials.total_markup_estimated_price}</div>
                                    <div className="value summary-item-price">{currency_type || '₹'}{SummaryDetials.total_markup_expense_price}</div>
                                </div>
                                <div className="summary-content-subtotal">
                                    <div className="label summary-item-title">Total</div>
                                    <div className="value summary-item-price">{currency_type || '₹'}{SummaryDetials.subtotal_estimated_price}</div>
                                    <div className="value summary-item-price">{currency_type || '₹'}{SummaryDetials.subtotal_expense_price}</div>
                                </div>

                                <div className="summary-content-item">
                                    <div className="label summary-item-navigate summary-item-title">Change Order</div>
                                    <div className="value summary-item-price"></div>
                                    <div className="value summary-item-price">{currency_type || '₹'}{SummaryDetials.total_changeorder_price}</div>
                                </div>


                            </div>
                        </div>
                        <div className="summary-container-section">

                            <div className="summary-content-items">

                                <div className="summary-content-subtotal">
                                    <div className="label summary-item-title">Total Esti. Variation</div>
                                    <div className="value summary-item-price"></div>
                                    <div className="value summary-item-price">{currency_type || '₹'}{SummaryDetials.total_difference_amount}</div>
                                </div>
                                <div className="summary-content-subtotal">
                                    <div className="label summary-item-title">Total Price</div>
                                    <div className="value summary-item-price"></div>
                                    <div className="value summary-item-price">{currency_type || '₹'}{SummaryDetials.final_total_price}</div>
                                </div>
                                <div className="summary-content-item">
                                    <div className="label summary-item-navigate summary-item-title">Client Payments</div>
                                    <div className="value summary-item-price"></div>
                                    <div className="value summary-item-price">{currency_type || '₹'}{SummaryDetials.total_clientpayment_price}</div>
                                </div>
                            </div>
                        </div>
                        <div className="summary-container-section summary-balance-footer-main">

                            <div className="summary-balance-footer-content">
                                <div className="label">Balance Remaining</div>
                                <div className="label">({currency_type || '₹'}{SummaryDetials.total_balance_price})</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}

export default FinanceSummary;