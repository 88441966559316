

import React, { useEffect, useState, useRef } from 'react'
import { Route, Routes, BrowserRouter, Link, useLocation, Navigate, Outlet, useNavigate, useParams } from 'react-router-dom';
import store from '../../redux/store'
import StoreHandler from '../../redux/StoreHandler'
import { useSelector, useDispatch } from 'react-redux'
import UserAction from '../../redux/action/userAction'

import ReverseProxy from '../../config/reverseProxy'
import WorkRatesHandler from '../../Handlers/WorkRates/WorkRates';
import WorkTemplatesHandler from '../../Handlers/WorkTemplates/WorkTemplates';
import EstimateHandler from '../../Handlers/Estimate/Estimate';
import Icons from '../../assets/Icons'
import Images from '../../assets/Images'
import { CostInput, TextInput, SelectInput, SearcBarInput, QuantityInput, RadioInput } from '../../components/Inputs'
import Utils from '../../utils'
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

import AlertPopup from '../../components/AlertPopup'
import Loading from '../../components/Loading'
import SystemToastPopup from '../../components/SystemToastPopup'

import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';



const AddEstimateCatalogPopup = ({ props }) => {

    const { updateCallback } = props

    const { projectId, itemType, itemId, actionType } = useParams()

    const [isLoading, setIsLoading] = useState(false)
    const [warningAlert, setWarningAlert] = useState(false)
    const [warningAlertType, setWarningAlertType] = useState('warning')
    const [apiFailedMessage, setApiFailedMessage] = useState("Error in Server")

    const estimateHandler = new EstimateHandler()
    const workRatesHandler = new WorkRatesHandler()
    const workTemplatesHandler = new WorkTemplatesHandler()

    const getEstimateGroups = async () => {

        let filter = {
            type: 1,
            projectId: projectId
        }

        let response = await estimateHandler.getEstimateItemsHandler(filter)

        if (response.success) {

            let { data } = response

            if (!Array.isArray(data)) return

            data = data.filter(d => d.type == '1').map(d => {

                return {
                    value: d.id,
                    label: d.name,
                }

            })



            setGroupOptions(data)
        }
    }

    const [project_id, setProject_id] = useState('')
    const [estimate_id, setEstimate_id] = useState('')

    const [group_id, setGroup_id] = useState('')
    const [group, setGroup] = useState('')


    const [groupOptions, setGroupOptions] = useState([])


    useEffect(() => {
        getEstimateGroups()
    }, [])


    const Popup_Header = ({ props }) => {

        return (
            <div className="side-popup-header">
                <div className="header-item-select">
                    <div className="header-item-select-content">
                        <span className="icon" dangerouslySetInnerHTML={{ __html: Icons.general.work }}></span>
                        <div className="label">Use Work Template</div>
                    </div>
                </div>
                <div
                    className="header-item-close"
                    onClick={(e) => {
                        navigate(`/builder/projects/${projectId}/estimate`)
                    }}
                    dangerouslySetInnerHTML={{ __html: Icons.general.close }}
                ></div>
            </div>
        );
    };
    const Popup_Footer = ({ props }) => {

        return (
            <div className="sidebar-popup-footer">
                <div className="footer-item action-items">
                    <div className="footer-item-left">
                    </div>
                    <button
                        className="action-btn"
                        type='submit'
                    >

                        <div className="label">Save</div>
                    </button>
                </div>
            </div>
        );
    };




    const [currency_type, setCurrency_type] = useState('₹')

    const navigate = useNavigate()
    const store = useSelector((store) => store)
    const dispatch = useDispatch()
    const { updateState } = new UserAction

    const storeHandler = new StoreHandler({
        dataStack: ['company', `project-${projectId}`, `project_phases-${projectId}`],
        store: store,
        dispatch: dispatch,
        updateState: updateState,
    })

    const LoadStoreData = async () => {

        setIsLoading(true)
        let resolved = await storeHandler.init()
        setIsLoading(false)
    }


    const [tabelHeader, setTabelHeader] = useState([

        {
            id: 'select',
            label: 'Select',
            minWidth: 30,
            disable: false,
        },

        {
            id: 'name',
            label: 'Name',
            minWidth: 200,
            disable: false,
        },

    ])

    const [workRates, setWorkRates] = useState([])

    const [workTemplatesOrginal, setWorkTemplatesOrginal] = useState([])
    const [workTemplates, setWorkTemplates] = useState([])
    const [selectedAction, setSelectedAction] = useState('')

    const [tableSelectAll, setTableSelectAll] = useState(false)

    const [isAddBtnClicked, setIsAddBtnClicked] = useState(false)
    const [existItemData, setExistItemData] = useState({})

    const [ProjectPhases, setProjectPhases] = useState([])
    const [ProjectPhases_Options, setProjectPhases_Options] = useState([])

    const [Phase_id, setPhase_id] = useState('')
    const [Phase_name, setPhase_name] = useState('')


    const HandleOpenItem = (item) => {

        setExistItemData(item)
        navigate(`/builder/cost-catalog/${item.type_option.id}/${item.sno}/edit`)
    }

    const HanelCellAction = (id, row) => {

        if (id == "actions") {
            setSelectedAction(row.id)
            HandleActionDisable(row.id)
        }
    }
    const HandleActionDisable = (id) => {

        let input = document.getElementById(`estimate-tabel-action-btn-${id}`)
        let dropdown_items = document.getElementById(`estimate-tabel-action-dropdown-${id}`)

        const Event = (e) => {
            let path = e.path || (e.composedPath && e.composedPath());

            if (Array.isArray(path) && !path.includes(dropdown_items) && !path.includes(input)) {
                setSelectedAction('')
                window.removeEventListener('click', Event)
            }
        }

        window.addEventListener('click', Event)
    }

    const HandleSelectAllEvent = (value) => {
        setTableSelectAll(value)

        let _workTemplates = [...workTemplates]

        _workTemplates = _workTemplates.map(item => {

            item.selected = value
            return item
        })
        setWorkTemplates(_workTemplates)
    }

    const HandleSelectEvent = (group_id, parent_id, value) => {

        let _workTemplates = [...workTemplates]

        _workTemplates = _workTemplates.map(item => {

            if (item.id == group_id && item.parent_id == parent_id) item.selected = value
            return item
        })

        setWorkTemplates(_workTemplates)
    }

    const LoadWorkRateItems = async () => {

        setIsLoading(true)

        let response = await workRatesHandler.getWorkRatesItemsHandler()
        setIsLoading(false)

        if (response.success) {


            let { data } = response

            if (!Array.isArray(data)) return

            setWorkRates(data)
        }
        else {
            setWarningAlert(true)
            setWarningAlertType('error')
            setApiFailedMessage(`Error in Work Rates, Please try again.`)
        }
    }
    const LoadWorkTemplateItems = async () => {

        setIsLoading(true)

        let response = await workTemplatesHandler.getWorkTemplatesHandler()
        setIsLoading(false)

        if (response.success) {


            let { data } = response

            if (!Array.isArray(data)) return

            setWorkTemplatesOrginal(data)
            setWorkTemplates(data)
        }
        else {
            setWarningAlert(true)
            setWarningAlertType('error')
            setApiFailedMessage(`Error in Work Templates, Please try again.`)
        }
    }


    const CreateCostcatalogTableItem = (row, idx) => {


        let { id, disable, type, parent_id, expanded, _status } = row

        return (
            <>
                {!disable ?
                    <>
                        <TableRow
                            hover
                            role="checkbox"
                            tabIndex={-1}
                            key={id}
                            className={`estimate-table-row ${type == 'group' ? !parent_id ? 'estimate-table-row-group' : 'estimate-table-row-subgroup' : ''}`}
                        >
                            {tabelHeader.map((column) => {
                                const value = row[column.id];
                                return (

                                    column.disable ? '' :
                                        column.id == 'select' ?


                                            <TableCell
                                                key={column.id}
                                                align={column.align}
                                                className='estimate-table-row-item'
                                            >
                                                <RadioInput props={{
                                                    value: row.selected || false,
                                                    isIcon: false,
                                                    icon: "",
                                                    inputType: "checkbox",
                                                    name: "table-default-radio",
                                                    setValue: (value) => HandleSelectEvent(id, parent_id, value)
                                                }} />

                                            </TableCell>

                                            :
                                            <TableCell
                                                key={column.id}
                                                align={column.align}
                                                onClick={(e) => {
                                                    if (column.id != 'actions') HanelCellAction(column.id, row)
                                                }}
                                                className='estimate-table-row-item'
                                            >
                                                {
                                                    column.id == 'name' ?
                                                        <div
                                                            className="tabel-name-main"
                                                        >
                                                            <div
                                                                className="tabel-name"
                                                            > {column.format ? column.format(value) : value}</div>

                                                        </div>

                                                        : column.id == 'status' ?

                                                            <div
                                                                className="tabel-status-main"
                                                            >

                                                                <span className={`tabel-status-span status-span-${value}`}></span>
                                                                <div style={{ display: 'none' }} className="tabel-status"> {column.format ? column.format(value) : value}</div>

                                                            </div>
                                                            : column.format ? column.format(value) : value
                                                }
                                            </TableCell>
                                );
                            })}
                        </TableRow>
                    </>
                    : ''}
            </>
        )
    }


    const [Filters, setFilters] = useState({
        left: [

            {
                id: "estimate-search",
                value: '',
                isSearchBar: true,
                type: "input",
                isDropDownContainer: false,
                placeholder: "Search by name",
                dropDownOptions: [],
                callback: (val) => { },

                isIcon: true,
                icon: Icons.general.search,
                isLabel: false,
                label: "",
                isIconLeft: false
            },
        ],
        right: []
    })
    const HandleFiltersEvent = async (value, filter_id) => {

        if (!filter_id) return

        if (filter_id == 'estimate-search') {

            let filters = { ...Filters }

            filters.left[0].value = value

            let _workTemplates = [...workTemplates]

            let search_items = (items, value, column = 'name') => {

                let search_item = (items, value, column = 'name') => {

                    items = items.map(item => {

                        let is_item_match = String(item[column]).toLocaleLowerCase().includes(String(value).toLocaleLowerCase())

                        if (is_item_match) item.disable = false
                        else item.disable = true

                        return item

                    })

                    return items

                }

                items = search_item(items, value, column)
                return items
            }

            _workTemplates = search_items(_workTemplates, value, 'name')

            setWorkTemplates(_workTemplates)

            setFilters(filters)

        }
    }

    const isLeftFilter = Array.isArray(Filters.left) && Filters.left.length > 0
    const isRightFilter = Array.isArray(Filters.right) && Filters.right.length > 0

    const Icon_Filter = ({ props }) => {
        const {
            id,
            isSearchBar,
            type,
            value,
            isSelectToggle,
            isDropDownContainer,
            dropDownOptions,
            placeholder,
            callback,
            isIcon,
            icon,
            isLabel,
            label,
            isIconLeft,
        } = props

        const [iconStatus, setIconStatus] = useState(value != undefined ? value : false)

        const HandleFilterIconInput = (e) => {


            HandleFiltersEvent(!iconStatus, id)
            setIconStatus(!iconStatus)
        }

        return (
            <>
                {isIcon && type == 'icon' ?
                    <div
                        className="filter-item group-expand-filter"
                        onClick={(e) => HandleFilterIconInput(e)}
                        key={`filter-item-${id}`}
                    >
                        <div
                            className="icon"
                            dangerouslySetInnerHTML={{ __html: id.includes('expand') ? iconStatus ? Icons.general.collapse : Icons.general.expand : icon }}
                        ></div>
                        {isLabel ? <div className="label">{id.includes('expand') ? iconStatus ? 'Collapse all' : 'Expand all' : label}</div> : ''}
                    </div>

                    : null}
            </>
        )
    }
    const Button_Filter = ({ props }) => {
        const {
            id,
            isSearchBar,
            type,
            isDropDownContainer,
            dropDownOptions,
            callback,
            isIcon,
            icon,
            isLabel,
            label,
            isIconLeft,
        } = props
        const [selectFilterOpts, setSelectFilterOpts] = useState([])

        const [dropdownStatus, setDropDownStatus] = useState(false)

        const HandleClick = (e) => {
            setDropDownStatus(!dropdownStatus)
        }

        let HandleSubItemChange = (value, opt, i) => {

            let _filters = [...selectFilterOpts]

            if (_filters[i].id == 'opt-select-all') {
                _filters = _filters.map(f => {
                    f.value = value

                    return f
                })
            } else _filters[i].value = value

            setSelectFilterOpts(_filters)

            HandleFiltersEvent(opt, id)
        }

        useEffect(() => {

            let items_parent = document.querySelector(`#${id}`)

            window.addEventListener('click', (e) => {

                let path = e.path || (e.composedPath && e.composedPath());

                if (Array.isArray(path) && !path.includes(items_parent))
                    setDropDownStatus(false)
            })
            setSelectFilterOpts(dropDownOptions)
        }, [])


        return (
            <div className='filter-item dropdown-select-filter' id={id} key={`filter-item-${id}`}>
                <div
                    className="button"
                    onClick={(e) => HandleClick(e)}
                >
                    {isIcon && isIconLeft ?
                        <span
                            className={`icon ${dropdownStatus ? 'icon-active' : ''}`}
                            dangerouslySetInnerHTML={{ __html: icon }}
                        ></span>
                        : null}

                    {isLabel ? <span class='label'>{label}</span> : null}

                    {isIcon && !isIconLeft ?
                        <span
                            className={`icon ${dropdownStatus ? 'icon-active' : ''}`}
                            dangerouslySetInnerHTML={{ __html: icon }}
                        ></span>
                        : null}
                </div>

                {isDropDownContainer ?

                    <div className={`dropdown-select-items ${dropdownStatus ? 'dropdown-select-items-active' : ''}`}>

                        {selectFilterOpts.map((opt, i) => (

                            opt.type == 'label' ?
                                <div className="dropdown-select-title">{opt.label}</div>
                                :
                                <div className="dropdown-select-itm">
                                    <RadioInput props={{
                                        id: `filters-item-select-${opt.id}`,
                                        value: opt.value,
                                        isIcon: false,
                                        icon: "",
                                        inputType: "checkbox",
                                        name: "table-default-radio",
                                        setValue: (value) => HandleSubItemChange(value, opt, i)
                                    }} />
                                    <label className='label' htmlFor={`filters-item-select-${opt.id}`}>{opt.label}</label>
                                </div>

                        ))}

                    </div>

                    : null}
            </div>

        )
    }

    const getSelectedItems = (items) => {

        let selected_items = []

        const selectItem = (items) => {

            items = items.map(item => {

                if (item.selected) selected_items.push(item)
                return item
            })

            return items
        }

        selectItem(items)

        return selected_items
    }

    const HandlePopupSubmit = async (e) => {
        e.preventDefault()

        let selected_items = getSelectedItems(workTemplates)

        let selected_items_ids = selected_items.map(item => item.id)

        if (!selected_items_ids.length) return

        let update_data = {
            projectId: projectId,
            estimate_id: estimate_id,
            parent_id: group_id || undefined,
            parent_name: group || undefined,
            phase_id: String(Phase_id || ''),
            phase_name: Phase_name,

            selected_workTemplates: selected_items_ids || []
        }

        setIsLoading(true)

        let response = await estimateHandler.importItemsFromWorkTemplatesHandler(update_data)

        setIsLoading(false)

        if (response.success) {

            if (typeof updateCallback == 'function') updateCallback()
            navigate(`/builder/projects/${projectId}/estimate`)
        }
        else {
            setWarningAlert(true)
            setWarningAlertType('error')
            setApiFailedMessage(`Error in importing items, Please try again!`)
        }
    }


    const HandlePhaseInputChange = (value, label) => {

        let phase = ProjectPhases.filter(phase => phase.id == value)[0]

        if (!phase) return

        setPhase_id(phase.id)
        setPhase_name(phase.name)
    }


    useEffect(() => {
        LoadStoreData()
        LoadWorkTemplateItems()
        LoadWorkRateItems()
    }, [])

    useEffect(() => {


        if (store && store.user) {

            let { company, selected_project, selected_project_phases } = store.user


            if (selected_project) {
                let { id, estimate_id } = selected_project

                if (estimate_id) setEstimate_id(estimate_id)
                if (id) setProject_id(id)
            }

            if (company) {

                let { currency_setting, timezone, temperature_setting } = company

                if (currency_setting) setCurrency_type(currency_setting)
            }
            if (selected_project_phases) {

                setProjectPhases(selected_project_phases)

                let project_phases = selected_project_phases.map(phase => {
                    return {
                        value: phase.id,
                        label: `${phase.name} | ${phase.client_name}`,
                        isDefault: false
                    }
                })

                setProjectPhases_Options(project_phases || [])

            }

        }


    }, [store])


    return (
        <>

            <div className="popup-container-main popup-container-scroll-center">
                <div className="popup-block-ui"></div>
                <form className="side-popup-container" onSubmit={(e) => {
                    HandlePopupSubmit(e)
                }}>
                    <Popup_Header />

                    <div className="sidebar-popup-content">

                        <div className="content-section content-top-main">

                            <div className="content-top-items">


                                <div className="content-item" style={{ width: '300px' }}>
                                    <SelectInput
                                        props={{
                                            id: "estimate-work-phase",
                                            value: Phase_id,
                                            placeholder: '',
                                            options: [
                                                {
                                                    value: "",
                                                    label: "Select phase"
                                                }, ...ProjectPhases_Options || []
                                            ] || [],
                                            setValue: (value, label) => HandlePhaseInputChange(value, label),
                                            isIcon: false,
                                            isLabel: true,
                                            isRequired: false,
                                            label: "Project Phase",

                                        }}
                                    />

                                </div>
                                <div className="content-item" style={{ width: '300px' }}>
                                    <SelectInput
                                        props={{
                                            id: "estimate-work-group",
                                            value: group_id,
                                            placeholder: '',
                                            options: [
                                                {
                                                    value: "",
                                                    label: "Select Group"
                                                }, ...groupOptions || []
                                            ] || [],
                                            setValue: (value, label) => {
                                                setGroup_id(value)
                                                setGroup(label)
                                            },
                                            isIcon: false,
                                            isLabel: true,
                                            isRequired: false,
                                            label: "Group",

                                        }}
                                    />

                                </div>

                            </div>
                        </div>

                        <div className="content-section content-section-items">

                            <div className="filter-section-main">
                                {isLeftFilter ?

                                    <div className="left-filter-main">
                                        {Filters.left.map((item, i) => (

                                            item.type == 'icon' ? <Icon_Filter props={item} />
                                                : item.type == 'button' ? <Button_Filter props={item} />
                                                    : item.isSearchBar && item.type == 'input' ?
                                                        <SearcBarInput
                                                            props={{
                                                                id: item.id,
                                                                value: item.value,
                                                                placeholder: item.placeholder || "Search by name",
                                                                setValue: (val) => {
                                                                    HandleFiltersEvent(val, item.id)
                                                                },
                                                                isIcon: item.isIcon,
                                                                isLeft: item.isIconLeft,
                                                                icon: item.icon || Icons.general.search
                                                            }}
                                                        />
                                                        : ''
                                        ))}
                                    </div>
                                    : ''}
                                {isRightFilter ?

                                    <div className="right-filter-main">
                                        {Filters.right.map((item, i) => (

                                            item.type == 'icon' ? <Icon_Filter props={item} />
                                                : item.type == 'button' ? <Button_Filter props={item} />
                                                    : item.isSearchBar && item.type == 'input' ?
                                                        <SearcBarInput
                                                            props={{
                                                                id: item.id,
                                                                value: item.value,
                                                                placeholder: item.placeholder || "Search by name",
                                                                setValue: (val) => {
                                                                    HandleFiltersEvent(val, item.id)
                                                                },
                                                                isIcon: item.isIcon,
                                                                isLeft: item.isIconLeft,
                                                                icon: item.icon || Icons.general.search
                                                            }}
                                                        />
                                                        : ''
                                        ))}
                                    </div>
                                    : ''}
                            </div>
                            <div className="panel-content-sections">
                                <Paper style={{ width: '100%', height: '100%', boxShadow: 'none', overflow: 'hidden' }}>
                                    <TableContainer style={{ height: '100%', boxShadow: 'none !important', }} >
                                        <Table stickyHeader>
                                            <TableHead
                                                className='tabel-header estimate-tabel-header'
                                            >
                                                <TableRow>
                                                    {tabelHeader.filter(th => !th.disable).map((column) => (

                                                        <TableCell
                                                            key={column.id}
                                                            align={column.align}
                                                            style={{ minWidth: column.minWidth }}
                                                            className='tabel-header-items'
                                                        >
                                                            {column.id == 'select' ?

                                                                <RadioInput props={{
                                                                    value: tableSelectAll || false,
                                                                    isIcon: false,
                                                                    icon: "",
                                                                    inputType: "checkbox",
                                                                    name: "table-default-radio",
                                                                    setValue: (value) => HandleSelectAllEvent(value)
                                                                }} />
                                                                :
                                                                column.label}
                                                        </TableCell>
                                                    ))}
                                                </TableRow>
                                            </TableHead>
                                            <TableBody className='tabel-body'>
                                                {workTemplates.map((row, i) => (
                                                    CreateCostcatalogTableItem(row, i)
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </Paper>
                            </div>


                        </div>

                    </div>
                    <Popup_Footer />
                </form>
            </div>

        </>
    )

}



export default AddEstimateCatalogPopup;