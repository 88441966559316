


import React, { useEffect, useState, useRef, useCallback, useMemo, StrictMode } from 'react'
import { Route, Routes, BrowserRouter, Link, useLocation, Navigate, Outlet, useNavigate, useParams } from 'react-router-dom';
import store from '../../redux/store'
import StoreHandler from '../../redux/StoreHandler'
import { useSelector, useDispatch } from 'react-redux'
import UserAction from '../../redux/action/userAction'

import ReverseProxy from '../../config/reverseProxy'
import ProjectsHandler from '../../Handlers/Projects/Projects';
import LabourManagementHandler from '../../Handlers/LabourManagement/LabourManagement';
import LabourProjectAttendanceHandler from '../../Handlers/LabourManagement/LabourProjectAttendance';
import ScheduleRatesHandler from '../../Handlers/ScheduleRates/ScheduleRates';

import Icons from '../../assets/Icons'
import Images from '../../assets/Images'
import { CostInput, TextInput, SelectInput, RadioInput, DateTimeInput, RadioOptionsInput } from '../../components/Inputs'
import Utils from '../../utils'
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

import AlertPopup from '../../components/AlertPopup'
import SystemToastPopup from '../../components/SystemToastPopup'
import Loading from '../../components/Loading'
import NoDataFound from '../../components/NoDataFound';

import { useAuth, useMenuAuth } from '../../hooks/useAuth'

const AddLaboursPopup = ({ props }) => {

    const { type, heading, close, callback, detials } = props

    const { labourId, projectId } = useParams()

    const store = useSelector((store) => store)
    const dispatch = useDispatch()
    const { updateState } = new UserAction

    const navigate = useNavigate()

    const labourManagementHandler = new LabourManagementHandler()
    const projectsHandler = new ProjectsHandler()

    const [isLoading, setIsLoading] = useState(false)
    const [warningAlert, setWarningAlert] = useState(false)
    const [warningAlertType, setWarningAlertType] = useState('warning')
    const [apiFailedMessage, setApiFailedMessage] = useState('Error in Attendance')

    const { isAdmin, menu_permission } = useMenuAuth({ menu: 'labour_managment--labour_attendance', feature_key: 'projectLabourManagement' })
    const location = useLocation()

    const [selected_view_settings_value, setSelected_view_settings_value] = useState('')
    const [has_view_access, setHas_view_access] = useState(false)
    const [has_add_access, setHas_add_access] = useState(false)
    const [has_edit_access, setHas_edit_access] = useState(false)
    const [has_delete_access, setHas_delete_access] = useState(false)

    useEffect(() => {

        if (isAdmin) {

            setHas_view_access(true)
            setHas_add_access(true)
            setHas_edit_access(true)
            setHas_delete_access(true)
        }

        else if (menu_permission) {

            if (
                !menu_permission._view
                &&
                (!menu_permission.selected_view_settings_value
                    || menu_permission.selected_view_settings_value == 'no_access')
            ) {
                navigate(`${ReverseProxy['proxyUrl']}/quick-menu`, { state: { from: location }, replace: true })
            }

            if (menu_permission._create == '0') navigate(`${ReverseProxy['proxyUrl']}/builder/projects`, { state: { from: location }, replace: true })

            setHas_view_access(menu_permission._view == '1')
            setHas_add_access(menu_permission._create == '1')
            setHas_edit_access(menu_permission._edit == '1')
            setHas_delete_access(menu_permission._delete == '1')

            setSelected_view_settings_value(menu_permission.selected_view_settings_value)

        }

    }, [store])

    const [SearchValue, setSearchValue] = useState('')
    const [ProjectId, setProjectId] = useState('')
    const [ProjectLabours, setProjectLabours] = useState([])

    const [Labours, setLabours] = useState([])

    const [currency_type, setCurrency_type] = useState('₹')

    const HandleCancel = async (e) => {
        close(type)
    }
    const HandlePopupSubmit = async (e) => {

        if (!ProjectId) {
            setWarningAlert(true)
            setWarningAlertType('error')
            setApiFailedMessage(`Error in Attendance, Please try again!`)
        }

        let updateta = {
            id: ProjectId,
            projectId: projectId,
            labours: ProjectLabours || []
        }


        let response = await projectsHandler.updateProjectLaboursHandler(updateta)

        if (response.success) {
            callback()
            close(type)
            setIsLoading(false)
        }
        else {
            setIsLoading(false)
            setWarningAlert(true)
            setWarningAlertType('error')
            setApiFailedMessage(`Error in Attendance, Please try again!`)
        }


    }
    const Popup_Header = () => {

        return (
            <div className="side-popup-header">
                <div className="header-item-select">
                    <div className="header-item-select-content">
                        <div className="label">{heading}</div>
                    </div>
                </div>
                <div
                    className="header-item-close"
                    onClick={(e) => HandleCancel(e)}
                    dangerouslySetInnerHTML={{ __html: Icons.general.close }}
                ></div>
            </div>
        );
    };

    const Popup_Footer = () => {

        return (
            <div className="sidebar-popup-footer">
                <div className="footer-item action-items">
                    <div className="action-preview">
                    </div>
                    <div className='action-btns'>
                        <div className="action-cancel" onClick={(e) => HandleCancel(e)}>
                            Cancel
                        </div>
                        <div
                            className={`action-btn action-${type}`}
                            onClick={(e) => {
                                HandlePopupSubmit(e)
                            }}
                        >

                            <div className="label">Save</div>
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    const LoadLabours = async () => {

        let response = await labourManagementHandler.getLabourHandler()
        let project_response = await projectsHandler.getProjectLaboursHandler({
            projectID: projectId
        })

        if (response.success && Array.isArray(response.data) && project_response.success && Array.isArray(project_response.data)) {

            let labours = response.data
            let { id, labours: project_labours } = project_response.data[0]

            let labours_options = labours.map(l => {

                l.selected = Array.isArray(project_labours) ? project_labours.filter(p => p.id == l.id && p.status == '1')[0] != undefined : false

                return l
            })
            setProjectId(id)
            setLabours(labours_options)
            setProjectLabours(project_labours || [])

        }
        else {
            setWarningAlert(true)
            setWarningAlertType('error')
            setApiFailedMessage(`Error in Attendance, Please try again!`)
        }
    }

    const HandleLabourSelect = (id, idx, value) => {

        let _ProjectLabours = [...ProjectLabours]

        let _labours = [...Labours]
        let labour = _labours[idx]

        if (!labour) return

        _labours[idx].selected = value

        if (value) {

            let userdetials = JSON.parse(localStorage.getItem('userdetials'))


            let labour_exist_idx = _ProjectLabours.findIndex(pl => pl.id == id)

            if (labour_exist_idx != -1) {

                if (_ProjectLabours[labour_exist_idx].status != '1') {

                    _ProjectLabours[labour_exist_idx].status = 1
                    _ProjectLabours[labour_exist_idx].updated_at = new Date().getTime()
                    _ProjectLabours[labour_exist_idx].updated_by_id = userdetials.id
                    _ProjectLabours[labour_exist_idx].updated_by = userdetials.name
                }


                return
            }


            _ProjectLabours.push({
                id: labour.id,
                name: labour.name,
                status: 1,
                created_at: new Date().getTime(),
                created_by_id: userdetials.id,
                created_by: userdetials.name,
                updated_at: null,
                updated_by_id: null,
                updated_by: null,
            })

        }
        else {


            let labour_exist_idx = _ProjectLabours.findIndex(pl => pl.id == id)

            if (!labour_exist_idx == -1) return

            let userdetials = JSON.parse(localStorage.getItem('userdetials'))

            _ProjectLabours[labour_exist_idx].status = 0
            _ProjectLabours[labour_exist_idx].updated_at = new Date().getTime()
            _ProjectLabours[labour_exist_idx].updated_by_id = userdetials.id
            _ProjectLabours[labour_exist_idx].updated_by = userdetials.name
        }

        setProjectLabours(_ProjectLabours)
    }


    useEffect(() => {

        if (type == 'edit' && labourId) {

            const LoadLabours = async (labourId) => {

                if (Object.keys(detials).length) {

                    let { id, name, payment_records, updated_at, updated_by } = detials


                }
                else {

                    let response = await labourManagementHandler.getLabourHandler({
                        id: labourId
                    })

                    if (response && response.success && Array.isArray(response.data) && response.data.length) {

                        let { id, name, payment_records, updated_at, updated_by } = response.data[0]


                    }
                    else {
                        setWarningAlert(true)
                        setWarningAlertType('error')
                        setApiFailedMessage(`Error in Attendance, Please try again!`)
                    }
                }
            }

            LoadLabours(labourId)
        }


        LoadLabours()
    }, [])

    return (
        <>

            {isLoading ?
                <Loading
                    props={{
                        isMainLogo: false,
                        isLabel: true
                    }} />
                : null}
            {warningAlert ?

                <SystemToastPopup
                    props={{
                        type: warningAlertType,
                        message: apiFailedMessage || "Error in Attendance",
                        callback: (confirmation) => setWarningAlert(false)
                    }} />

                : null}


            <div className="popup-container-main popup-container-center">
                <div className="popup-block-ui"></div>
                <div className="side-popup-container" >
                    <Popup_Header />
                    <div className="labourManagement-popup-content" style={{ minHeight: '250px' }}>
                        <div className="content-items">
                            <TextInput
                                props={{
                                    id: "labourManagement-search",
                                    value: SearchValue,
                                    placeholder: 'Search for Labour',
                                    setValue: (value) => setSearchValue(value),
                                    isIcon: false,
                                    isLabel: false,
                                    isIcon: true,
                                    icon: Icons.general.search,
                                    isRequired: true,
                                    label: "Search for Labour",
                                }}
                            />
                        </div>

                        <div className="labourManagement-addlabour-list">
                            {
                                Labours?.map((labour, idx) => (
                                    <div className="addlabour-list-item" key={labour.id}>
                                        <RadioInput props={{
                                            id: `addlabour-item-select-${labour.id}`,
                                            value: labour.selected,
                                            isIcon: false,
                                            icon: "",
                                            inputType: "checkbox",
                                            name: "table-default-radio",
                                            setValue: (value) => HandleLabourSelect(labour.id, idx, value)
                                        }} />
                                        <label className='label' htmlFor={`addlabour-item-select-${labour.id}`}>{labour.name}</label>
                                    </div>
                                ))
                            }

                        </div>


                    </div>
                    <Popup_Footer />
                </div>
            </div>
        </>
    )
}


const AddVendorAttendancePopup = ({ props }) => {

    const { type, heading, close, callback, detials } = props

    const { projectId } = useParams()

    const store = useSelector((store) => store)
    const dispatch = useDispatch()
    const { updateState } = new UserAction

    const navigate = useNavigate()

    const [isLoading, setIsLoading] = useState(false)
    const [warningAlert, setWarningAlert] = useState(false)
    const [warningAlertType, setWarningAlertType] = useState('warning')
    const [apiFailedMessage, setApiFailedMessage] = useState('Error in Attendance')

    const storeHandler = new StoreHandler({
        dataStack: ['company', 'costcodes', 'vendors', `project-${projectId}`],
        store: store,
        dispatch: dispatch,
        updateState: updateState,
    })
    const LoadStoreData = async () => {
        setIsLoading(true)
        let resolved = await storeHandler.init()
        setIsLoading(false)
    }
    const [currency_type, setCurrency_type] = useState('₹')


    const [PaymentStatus, setPaymentStatus] = useState('0')
    const [WorkCategory_id, setWorkCategory_id] = useState('')
    const [WorkCategory_name, setWorkCategory_name] = useState('')
    const [Vendor_id, setVendor_id] = useState('')
    const [Vendor_name, setVendor_name] = useState('')
    const [ShiftType, setShiftType] = useState('')
    const [Remarks, setRemarks] = useState('')
    const [Workers, setWorkers] = useState([])

    const [Total_topay, setTotal_topay] = useState(0)

    const [ScheduleLabours, setScheduleLabours] = useState([])
    const [WorkTypeOptions, setWorkTypeOptions] = useState([])

    const [vendorOptions, setVendorOptions] = useState([])

    const [WorkCategory_Orginal, setWorkCategory_Orginal] = useState([])
    const [WorkCategory_Options, setWorkCategory_Options] = useState([])

    const scheduleRatesHandler = new ScheduleRatesHandler()


    const HandleCancel = async (e) => {
        close(type)
    }
    const HandlePopupSubmit = async (e) => {

        if (Total_topay <= 0 || PaymentStatus == '1') return

        let update_data = {
            ...detials,
            id: Utils.getUniqueId(),
            workcategory_id: WorkCategory_id,
            workcategory_name: WorkCategory_name,
            workshift_type: String(ShiftType),
            vendor_id: String(Vendor_id),
            vendor_name: String(Vendor_name),
            remarks: String(Remarks),
            total_topay: String(Total_topay),
            total_workers: Workers.reduce((prev, w) => prev + parseFloat(w.no_workers || 0), 0),
            workers: Workers,
            payment_status: detials && detials.id ? detials.payment_status || '0' : '0',
            paid_amount: detials && detials.id ? detials.paid_amount || 0 : 0,
        }

        let userdetials = JSON.parse(localStorage.getItem("userdetials"))


        if (detials && detials.id) {
            update_data.id = detials.id

            update_data.updated_at = new Date().getTime()
            update_data.updated_by = userdetials.name || ''
            update_data.updated_by_id = userdetials.id || ''

        } else {
            update_data.created_at = new Date().getTime()
            update_data.created_by = userdetials.name || ''
            update_data.created_by_id = userdetials.id || ''
        }
        callback(update_data)
        close(type)


    }
    const Popup_Header = () => {

        return (
            <div className="side-popup-header">
                <div className="header-item-select">
                    <div className="header-item-select-content">
                        <div className="label">{heading}</div>
                    </div>
                </div>
                <div
                    className="header-item-close"
                    onClick={(e) => HandleCancel(e)}
                    dangerouslySetInnerHTML={{ __html: Icons.general.close }}
                ></div>
            </div>
        );
    };

    const Popup_Footer = () => {

        return (
            <div className="sidebar-popup-footer">
                <div className="footer-item action-items">
                    <div className="action-preview">
                    </div>
                    <div className='action-btns'>
                        <div className="action-cancel" onClick={(e) => HandleCancel(e)}>
                            Cancel
                        </div>
                        <div
                            className={`action-btn action-${type}`}
                            onClick={(e) => {
                                HandlePopupSubmit(e)
                            }}
                        >

                            <div className="label">Save</div>
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    const LoadSchedule_Labours = async () => {

        let response = await scheduleRatesHandler.getItemsHandler({
            type: '3',
            item_type: '2',
        })

        if (response && response.success && Array.isArray(response.data)) {

            let _sor_items = response.data.map(d => {

                return {
                    value: d.id,
                    label: d.name
                }
            })

            setScheduleLabours(response.data)
            setWorkTypeOptions(_sor_items)

        }
        else {
            setWarningAlert(true)
            setWarningAlertType('error')
            setApiFailedMessage(`Error in Attendance, Please try again!`)
        }


    }


    const HandleAddWorker = () => {
        let _workers = [...Workers]

        let userdetials = JSON.parse(localStorage.getItem("userdetials"))

        _workers.push({
            id: Utils.getUniqueId(),
            worktype_id: '',
            worktype_name: '',
            no_workers: '',
            per_worker_salary: '',
            to_pay: 0,
            created_at: new Date().getTime(),
            created_by: userdetials.name || '',
            created_by_id: userdetials.id || '',
        })
        setWorkers(_workers)

    }
    const HandleWorkerChanges = (value, key, idx, label) => {

        let _workers = [...Workers]

        if (key == 'type') {

            _workers[idx].worktype_id = value
            _workers[idx].worktype_name = label

            let schedule_labour = ScheduleLabours.filter(l => l.id == value)[0]

            if (schedule_labour) {
                _workers[idx]['per_worker_salary'] = parseFloat(schedule_labour.price || 0)
                _workers[idx].to_pay = parseFloat(_workers[idx].no_workers || 0) * parseFloat(schedule_labour.price || 0)
            }
        }
        else {
            _workers[idx][key] = value
            _workers[idx].to_pay = parseFloat(_workers[idx].no_workers || 0) * parseFloat(_workers[idx].per_worker_salary || 0)
        }
        let userdetials = JSON.parse(localStorage.getItem("userdetials"))

        _workers[idx].updated_at = new Date().getTime()
        _workers[idx].updated_by = userdetials.name || ''
        _workers[idx].updated_by_id = userdetials.id || ''


        let total_topay = _workers.reduce((prev, w) => {

            return prev + parseFloat(w.to_pay || 0)
        }, 0)
        setTotal_topay(total_topay)

        setWorkers(_workers)
    }
    const HandleDeleteWorker = (idx) => {
        let _workers = [...Workers]

        _workers.splice(idx, 1)
        setWorkers(_workers)

    }

    useEffect(() => {

        if (detials && detials.id) {

            console.log(detials, 'detials');

            setWorkCategory_id(detials.workcategory_id)
            setWorkCategory_name(detials.workcategory_name)
            setShiftType(detials.workshift_type)
            setVendor_id(detials.vendor_id)
            setVendor_name(detials.vendor_name)
            setRemarks(detials.remarks)
            setTotal_topay(detials.total_topay)
            setWorkers(detials.workers || [])
            setPaymentStatus(detials.payment_status || '0')

        }


        LoadStoreData()
        LoadSchedule_Labours()
    }, [])



    useEffect(() => {

        if (store && store.user) {

            let { company, costcodes, vendors } = store.user

            if (company) {

                let { currency_setting, timezone, temperature_setting } = company

                if (currency_setting) setCurrency_type(currency_setting)
            }

            if (vendors && Array.isArray(vendors) && vendors.length) {

                let vendor_options = vendors.filter(opt => opt.status == '1').map((opt) => {
                    return {
                        value: opt.vendorID,
                        label: opt.name,
                        isDefault: false
                    }
                })

                setVendorOptions(vendor_options || [])

            }
            if (costcodes && Array.isArray(costcodes) && costcodes.length) {

                let options = costcodes.filter(opt => opt.status == '1').map((opt) => {
                    return {
                        value: opt.id,
                        label: opt.name,
                        isDefault: false
                    }
                })

                setWorkCategory_Options(options || [])
                setWorkCategory_Orginal(costcodes || [])
            }

        }

    }, [store])



    return (
        <>

            {isLoading ?
                <Loading
                    props={{
                        isMainLogo: false,
                        isLabel: true
                    }} />
                : null}
            {warningAlert ?

                <SystemToastPopup
                    props={{
                        type: warningAlertType,
                        message: apiFailedMessage || "Error in Attendance",
                        callback: (confirmation) => setWarningAlert(false)
                    }} />

                : null}


            <div className="popup-container-main popup-container-center">
                <div className="popup-block-ui"></div>
                <div className="side-popup-container center-popup-container " >
                    <Popup_Header />
                    <div className="labourManagement-popup-content sidebar-popup-content" style={{ minHeight: '400px' }}>
                        <div className="content-items">
                            <div className="content-item">
                                <SelectInput
                                    props={{
                                        id: "attendance-worktag",
                                        value: WorkCategory_id,
                                        placeholder: '',
                                        options: [
                                            {
                                                value: '',
                                                label: 'Select Tag'
                                            },
                                            ...WorkCategory_Options

                                        ],
                                        setValue: (value, label) => {
                                            if (!value) return
                                            setWorkCategory_id(value)
                                            setWorkCategory_name(label)
                                        },
                                        isIcon: false,
                                        isLabel: true,
                                        isRequired: false,
                                        label: "Work Tag",
                                        readOnly: PaymentStatus == '1' ? true : false
                                    }}
                                />
                            </div>

                            <div className="content-item">

                                <RadioOptionsInput
                                    props={{
                                        id: "attendance-workshift-type",
                                        value: ShiftType,
                                        placeholder: '',
                                        options: [
                                            {
                                                id: 'workshift-type-day',
                                                label: 'Day',
                                                value: '1'
                                            },
                                            {
                                                id: 'workshift-type-night',
                                                label: 'Night',
                                                value: '0'
                                            },
                                        ],
                                        setValue: (value, label) => setShiftType(value),
                                        isIcon: true,
                                        icon: Icons.general.work,
                                        isLabel: true,
                                        isIconLeft: true,
                                        isRequired: true,
                                        readOnly: PaymentStatus == '1' ? true : false,
                                        name: "workshift-type",
                                        label: "Work Shift type",
                                    }}
                                />

                            </div>

                        </div>
                        <div className="content-items">
                            <div className="content-item">
                                <SelectInput
                                    props={{
                                        id: "attendance-vendor",
                                        value: Vendor_id,
                                        placeholder: '',
                                        options: [
                                            {
                                                value: 'self',
                                                label: 'Self'
                                            },
                                            ...vendorOptions
                                        ],
                                        setValue: (value, label) => {
                                            setVendor_id(value)
                                            setVendor_name(label)
                                        },
                                        isIcon: false,
                                        isLabel: true,
                                        readOnly: PaymentStatus == '1' ? true : false,
                                        isRequired: true,
                                        label: "Vendor",
                                    }}
                                />
                            </div>
                            <div className="content-item">
                                <TextInput
                                    props={{
                                        id: "attendance-remarks",
                                        value: Remarks,
                                        placeholder: 'Eg. All work is done',
                                        setValue: (value) => setRemarks(value),
                                        isIcon: false,
                                        isLabel: true,
                                        isIcon: false,
                                        icon: '',
                                        isRequired: false,
                                        readOnly: PaymentStatus == '1' ? true : false,
                                        label: "Add Remarks",
                                    }}
                                />
                            </div>

                        </div>

                        <div className="content-section">
                            <div className="popup-detials-section ovetime-popup-detials-section" style={{ padding: 0 }}>
                                <div className="popup-detials-header">
                                    <div className="left">
                                        <div className="title">Worker detials</div>
                                    </div>
                                    {PaymentStatus != '1' ?
                                        <div className="right">

                                            <div
                                                className="add-button"
                                                onClick={() => HandleAddWorker()}
                                            >
                                                <div
                                                    className="icon"
                                                    dangerouslySetInnerHTML={{ __html: Icons.general.add_btn }}
                                                ></div>
                                                <div className="label">Add Workers</div>

                                            </div>
                                        </div>
                                        : ''}
                                </div>
                                <div className="popup-detials-content">

                                    {Workers?.map((worker, idx) => (

                                        <div
                                            className="content-items" key={worker.id}
                                            style={{ borderBottom: '1px solid var(--border-color)' }}
                                        >
                                            <div className="content-item">
                                                <SelectInput
                                                    props={{
                                                        id: `attendance-worker-type-${worker.id}`,
                                                        value: worker.worktype_id,
                                                        placeholder: 'Select Worker type',
                                                        options: WorkTypeOptions || [],
                                                        setValue: (value, label) => HandleWorkerChanges(value, 'type', idx, label),
                                                        isIcon: false,
                                                        isLabel: false,
                                                        isRequired: true,
                                                        readOnly: PaymentStatus == '1' ? true : false,
                                                        label: "Work Tag",
                                                    }}
                                                />
                                            </div>
                                            <div className="content-item">
                                                <TextInput
                                                    props={{
                                                        id: "attendance-remarks",
                                                        value: worker.no_workers,
                                                        placeholder: 'No. of Workers',
                                                        setValue: (value) => HandleWorkerChanges(value, 'no_workers', idx),
                                                        isIcon: false,
                                                        type: 'number',
                                                        isLabel: false,
                                                        isIcon: true,
                                                        icon: 'No.',
                                                        isRequired: true,
                                                        readOnly: PaymentStatus == '1' ? true : false,
                                                        label: "Add Remarks",
                                                    }}
                                                />
                                            </div>
                                            <div className="content-item">
                                                <TextInput
                                                    props={{
                                                        id: "attendance-remarks",
                                                        value: worker.per_worker_salary,
                                                        placeholder: 'Daily Salary',
                                                        setValue: (value) => HandleWorkerChanges(value, 'per_worker_salary', idx),
                                                        isIcon: false,
                                                        type: 'number',
                                                        isLabel: false,
                                                        isIcon: true,
                                                        icon: currency_type || '₹',
                                                        isRequired: true,
                                                        readOnly: PaymentStatus == '1' ? true : false,
                                                        label: "Add Remarks",
                                                    }}
                                                />
                                            </div>

                                            {PaymentStatus != '1' ?
                                                <div
                                                    className="attendance-delete-icon"

                                                    onClick={() => { HandleDeleteWorker() }}
                                                >
                                                    <div
                                                        className="icon"
                                                        dangerouslySetInnerHTML={{ __html: Icons.general.close }}
                                                    ></div>
                                                    <div className="label">Remove</div>
                                                </div>
                                                : ''}
                                        </div>

                                    ))}
                                    {!Workers.length ?
                                        <div className="popup-detials-content-noresult">
                                            <div
                                                className="icon"
                                                dangerouslySetInnerHTML={{ __html: Icons.general.no_records }}
                                            ></div>
                                            <div className="label">No Workers added</div>
                                        </div>
                                        : ''}


                                </div>
                                <div className="popup-detials-header">
                                    <div className="left">
                                        <div>Total payable: <strong>{currency_type}{Total_topay}</strong></div>
                                    </div>
                                </div>



                            </div>
                        </div>

                    </div>

                    {PaymentStatus == '0' ?
                        <Popup_Footer />
                        : ''}
                </div>
            </div>
        </>
    )
}
const AddOverTimePopup = ({ props }) => {

    const { type, heading, close, callback, detials } = props

    const { projectId } = useParams()

    const store = useSelector((store) => store)
    const dispatch = useDispatch()
    const { updateState } = new UserAction

    const [currency_type, setCurrency_type] = useState('₹')

    const navigate = useNavigate()

    const [OverTime_hours, setOverTime_hours] = useState(0)
    const [OverTime_perHr_salary, setOverTime_perHr_salary] = useState(0)
    const [OverTime_salary, setOverTime_salary] = useState(0)


    const HandleSubmit = () => {

        if (OverTime_salary <= 0) return

        let labour = { ...detials }

        labour.is_over_time = true
        labour.overtime_hours = parseFloat(OverTime_hours || 0) || 0
        labour.overtime_hourly_amount = parseFloat(OverTime_perHr_salary || 0) || 0
        labour.overtime_salary_amount = parseFloat(OverTime_salary || 0) || 0
        labour.to_pay = parseFloat(labour.salary_amount || 0) + parseFloat(OverTime_salary || 0)

        callback(labour)
        close(type)
    }
    const HandleRemove = () => {

        let labour = { ...detials }

        labour.is_over_time = false
        labour.overtime_hours = 0
        labour.overtime_hourly_amount = 0
        labour.overtime_salary_amount = 0
        labour.to_pay = parseFloat(labour.salary_amount || 0)

        callback(labour)
        close(type)
    }

    const HandleCancel = async (e) => {
        close(type)
    }

    const Popup_Header = () => {

        return (
            <div className="side-popup-header">
                <div className="header-item-select">
                    <div className="header-item-select-content">
                        <div className="label">{heading}</div>
                    </div>
                </div>
                <div
                    className="header-item-close"
                    onClick={(e) => HandleCancel(e)}
                    dangerouslySetInnerHTML={{ __html: Icons.general.close }}
                ></div>
            </div>
        );
    };

    const HandleHourUp = () => {

        let _OverTime_hours = OverTime_hours + 1

        setOverTime_hours(_OverTime_hours)
        setOverTime_salary(parseFloat(_OverTime_hours || 0) * parseFloat(OverTime_perHr_salary || 0))
    }
    const HandleHourDown = () => {

        let _OverTime_hours = OverTime_hours - 1

        if (_OverTime_hours - 1 >= 0) {
            setOverTime_hours(_OverTime_hours)
            setOverTime_salary(parseFloat(_OverTime_hours || 0) * parseFloat(OverTime_perHr_salary || 0))
        }
    }
    const HandleSalary = (value) => {

        value = Utils.numberOnly(value)

        setOverTime_perHr_salary(value)
        setOverTime_salary(parseFloat(OverTime_hours || 0) * parseFloat(value || 0))
    }

    useEffect(() => {

        if (detials && detials.id && detials.is_over_time) {

            setOverTime_hours(detials.overtime_hours)
            setOverTime_perHr_salary(detials.overtime_hourly_amount)
            setOverTime_salary(detials.overtime_salary_amount)
        }

    }, [])
    return (
        <>



            <div className="popup-container-main popup-container-center">
                <div className="popup-block-ui"></div>
                <div className="side-popup-container" >
                    <Popup_Header />
                    <div className="sidebar-popup-content">
                        <div className="content-section">
                            <div className="popup-detials-section ovetime-popup-detials-section">
                                <div className="popup-detials-header">
                                    <div className="left">
                                        <div className="desc">Please enter Over time detials for <strong>{detials.name}</strong></div>
                                    </div>
                                </div>
                                <div className="popup-detials-content">
                                    <div className="detials-content-inputitem">
                                        <div className="label">Over time hours</div>
                                        <div className="input up_down-input">
                                            <span
                                                onClick={() => HandleHourDown()}
                                            >-</span>
                                            <input
                                                type="text"
                                                value={OverTime_hours}
                                                readOnly={true}
                                            />
                                            <span
                                                onClick={() => HandleHourUp()}
                                            >+</span>
                                        </div>
                                    </div>
                                    <div className="detials-content-inputitem">
                                        <div className="label">Over time salary/hr</div>
                                        <div className="input ">
                                            <input
                                                type="text"
                                                value={OverTime_perHr_salary}
                                                onChange={(e) => HandleSalary(e.target.value)}
                                            />
                                        </div>
                                    </div>

                                </div>
                                <div className="popup-detials-header">
                                    <div className="left">
                                        <div>Overtime payable: <strong>{currency_type || '₹'}{OverTime_salary}</strong></div>
                                    </div>
                                </div>
                                <div className="popup-detials-buttons">
                                    <div
                                        className="detials-button remove-button"
                                        onClick={() => HandleRemove()}
                                    >
                                        <div
                                            className="icon"
                                            dangerouslySetInnerHTML={{ __html: Icons.general.reject }}
                                        ></div>
                                        <div className="label">Remove</div>
                                    </div>
                                    <div
                                        className={`detials-button add-button ${OverTime_salary > 0 ? 'add-button-active' : ''}`}
                                        onClick={() => HandleSubmit()}
                                    >
                                        <div
                                            className="icon"
                                            dangerouslySetInnerHTML={{ __html: Icons.general.add_btn }}
                                        ></div>
                                        <div className="label">Add Overtime</div>
                                    </div>
                                </div>

                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </>
    )
}

const LaboursAttendance = () => {

    const { projectId } = useParams()

    const store = useSelector((store) => store)
    const dispatch = useDispatch()
    const { updateState } = new UserAction

    const navigate = useNavigate()

    const labourManagementHandler = new LabourManagementHandler()
    const labourProjectAttendanceHandler = new LabourProjectAttendanceHandler()
    const projectsHandler = new ProjectsHandler()


    const { isAdmin, menu_permission } = useMenuAuth({ menu: 'labour_managment--labour_attendance', feature_key: 'projectLabourManagement' })
    const location = useLocation()

    const [selected_view_settings_value, setSelected_view_settings_value] = useState('')
    const [has_view_access, setHas_view_access] = useState(false)
    const [has_add_access, setHas_add_access] = useState(false)
    const [has_edit_access, setHas_edit_access] = useState(false)
    const [has_delete_access, setHas_delete_access] = useState(false)

    useEffect(() => {

        if (isAdmin) {

            setHas_view_access(true)
            setHas_add_access(true)
            setHas_edit_access(true)
            setHas_delete_access(true)
        }

        else if (menu_permission) {

            if (
                !menu_permission._view
                &&
                (!menu_permission.selected_view_settings_value
                    || menu_permission.selected_view_settings_value == 'no_access')
            ) {
                navigate(`${ReverseProxy['proxyUrl']}/quick-menu`, { state: { from: location }, replace: true })
            }

            if (menu_permission._view == '0') navigate(`${ReverseProxy['proxyUrl']}/builder/projects`, { state: { from: location }, replace: true })

            setHas_view_access(menu_permission._view == '1')
            setHas_add_access(menu_permission._create == '1')
            setHas_edit_access(menu_permission._edit == '1')
            setHas_delete_access(menu_permission._delete == '1')

            setSelected_view_settings_value(menu_permission.selected_view_settings_value)

        }

    }, [store])

    const [isLoading, setIsLoading] = useState(false)
    const [warningAlert, setWarningAlert] = useState(false)
    const [warningAlertType, setWarningAlertType] = useState('warning')
    const [apiFailedMessage, setApiFailedMessage] = useState('Error in Attendance')

    const [AttendanceDetials, setAttendanceDetials] = useState([])
    const [VendorAttendanceDetials, setVendorAttendanceDetials] = useState([])
    const [selected_termsCondition, setSelected_termsCondition] = useState({})
    const [selectedAction, setSelectedAction] = useState('')

    const [_Date, setDate] = useState(Utils._getLocalDate() || '')

    const [selectedResultSection, setSelectedResultSection] = useState('labour')

    const [currency_type, setCurrency_type] = useState('₹')

    const [totalDashboard, setTotalDashboard] = useState({
        labour: {
            total_present: 0,
            total_absent: 0,
            total_topay: 0,
        },
        vendor: {
            total_vendors: 0,
            total_labours: 0,
            total_topay: 0,
        },
    })

    const [hasChangesMade, setHasChangesMade] = useState(false)
    const [showOverTimePopup, setShowOverTimePopup] = useState(false)
    const [selectedOverTimeLabour, setSelectedOverTimeLabour] = useState(undefined)

    const [showAddVendorAttendancePopup, setShowAddVendorAttendancePopup] = useState(false)
    const [selectedVendorAttendance, setSelectedVendorAttendance] = useState(undefined)

    const HandleResultSection = (section) => {

        setSelectedResultSection(section)
    }

    const getProjectLabourDetials = async () => {

        setIsLoading(true)

        let labour_response = await labourManagementHandler.getLabourHandler()
        let response = await projectsHandler.getProjectLaboursHandler({
            projectID: projectId
        })

        setIsLoading(false)

        if (response && response.success && labour_response && labour_response.success) {

            let { labours } = response.data[0]

            if (!Array.isArray(labours) || !Array.isArray(labour_response.data)) return

            let _labours = labours.filter(l => l.status == '1').map(d => {

                let labour = labour_response.data.filter(l => l.id == d.id)[0]
                if (labour) {
                    d.worktype_id = labour.worktype_id
                    d.worktype_name = labour.worktype_name
                    d.salary_amount = labour.salary_amount
                }


                d.attendance = ''
                d.to_pay = 0
                d.payment_status = '0'
                d.paid_amount = 0
                d.is_over_time = false
                d.overtime_hours = 0
                d.overtime_hourly_amount = 0
                d.overtime_salary_amount = 0

                return d
            })

            let labour_attendance_response = await labourProjectAttendanceHandler.getLabourAttendanceHandler({
                projectId: projectId,
                date: _Date || Utils._getLocalDate()
            })


            if (!labour_attendance_response.success) return

            if (labour_attendance_response.data.length) {
                let { id, date, labour_attendance, vendor_attendance } = labour_attendance_response.data[0]

                labour_attendance = labour_attendance || []
                vendor_attendance = vendor_attendance || []

                _labours = _labours.map(labour => {

                    let exist_labour = labour_attendance.filter(l => l.id == labour.id)[0]

                    if (exist_labour) labour = exist_labour

                    return labour
                })

                vendor_attendance = vendor_attendance.map(vendor => {

                    vendor.total_workers = (vendor.workers || []).reduce((prev, w) => prev + parseFloat(w.no_workers || 0), 0)
                    return vendor
                })

                setVendorAttendanceDetials(vendor_attendance)
            } else {
                setVendorAttendanceDetials([])
            }

            setAttendanceDetials(_labours)



        } else {
            setWarningAlert(true)
            setWarningAlertType('error')
            setApiFailedMessage(`Error in Attendance, Please try again!`)
        }
    }


    const HandleAddLabour = () => {

        if (!has_add_access) return

        if (selectedResultSection == 'labour') navigate(`${window.location.pathname}/add-labours`)
        else if (selectedResultSection == 'vendor') {
            setShowAddVendorAttendancePopup(true)
            setSelectedVendorAttendance(undefined)
        }
    }
    const HandleEditUser = (user) => {


        setSelected_termsCondition(user)

        navigate(`${window.location.pathname}/edit/${user.id}`)
    }

    const CloseAddLabourPopup = (type) => {

        let back_path = String(window.location.pathname).split('/')
        back_path.pop()
        if (type == 'edit') back_path.pop()

        back_path = back_path.join('/')

        navigate(`${back_path}`)
    }

    const HanelCellAction = (id, row) => {

        console.log(selectedAction, 'selectedAction');
        if (id == "name") HandleEditUser(row)
        if (id == "actions") {
            setSelectedAction(row.id)
            HandleActionDisable(row.id)
        }
    }

    const HandleActionDisable = (id) => {

        let input = document.getElementById(`labourManagement-tabel-action-btn-${id}`)
        let dropdown_items = document.getElementById(`labourManagement-tabel-action-dropdown-${id}`)

        const Event = (e) => {
            let path = e.path || (e.composedPath && e.composedPath());

            if (Array.isArray(path) && !path.includes(dropdown_items) && !path.includes(input)) {
                setSelectedAction('')
                window.removeEventListener('click', Event)
            }
        }

        window.addEventListener('click', Event)
    }

    useEffect(() => {
        getProjectLabourDetials()
    }, [])

    const HandleAttendanceStatus = (id, idx, status) => {

        if (!has_edit_access) return

        let _AttendanceDetials = [...AttendanceDetials]


        if (_AttendanceDetials[idx].attendance == status) {
            _AttendanceDetials[idx].attendance = ""
            _AttendanceDetials[idx].to_pay = 0
        }
        else {
            _AttendanceDetials[idx].attendance = status


            if (status == 'present') _AttendanceDetials[idx].to_pay = _AttendanceDetials[idx].salary_amount || 0
            else if (status == 'halfday') _AttendanceDetials[idx].to_pay = parseFloat(_AttendanceDetials[idx].salary_amount || 0) / 2 || 0
            else if (status == 'absent') _AttendanceDetials[idx].to_pay = 0

        }

        _AttendanceDetials[idx].is_over_time = false
        _AttendanceDetials[idx].overtime_hours = 0
        _AttendanceDetials[idx].overtime_hourly_amount = 0
        _AttendanceDetials[idx].overtime_salary_amount = 0

        let userdetials = JSON.parse(localStorage.getItem('userdetials'))
        _AttendanceDetials[idx].updated_at = new Date().getTime()
        _AttendanceDetials[idx].updated_by_id = userdetials.id
        _AttendanceDetials[idx].updated_by = userdetials.name

        setHasChangesMade(true)

        setAttendanceDetials(_AttendanceDetials)

    }

    useEffect(() => {

        let labour_total_detials = [...AttendanceDetials].reduce((prev, item) => {

            let is_present = item.attendance == 'present' ? 1 : 0
            let is_absent = item.attendance == 'absent' ? 1 : 0
            let is_halfday = item.attendance == 'halfday' ? 1 : 0

            return {
                total_present: prev.total_present + is_present,
                total_absent: prev.total_absent + is_absent,
                total_topay: prev.total_topay + item.to_pay,
            }

        }, {
            total_present: 0,
            total_absent: 0,
            total_topay: 0,
        })

        let vendor_total_detials = [...VendorAttendanceDetials].reduce((prev, item) => {

            return {
                total_vendors: prev.total_vendors + 1,
                total_labours: prev.total_labours + parseFloat(item.total_workers || 0),
                total_topay: prev.total_topay + parseFloat(item.total_topay || 0),
            }

        }, {
            total_vendors: 0,
            total_labours: 0,
            total_topay: 0,
        })

        let _totalDashboard = {
            labour: {
                total_present: labour_total_detials.total_present,
                total_absent: labour_total_detials.total_absent,
                total_topay: labour_total_detials.total_topay,
            },
            vendor: {
                total_vendors: vendor_total_detials.total_vendors,
                total_labours: vendor_total_detials.total_labours,
                total_topay: vendor_total_detials.total_topay,
            },
        }

        setTotalDashboard(_totalDashboard)

    }, [AttendanceDetials, VendorAttendanceDetials])


    const HandleCloseFlottingFooter = () => {
        setHasChangesMade(false)
    }
    const HandleDiscardChanges = () => {

        let _AttendanceDetials = [...AttendanceDetials]

        _AttendanceDetials = _AttendanceDetials.map(d => {
            d.attendance = ''
            d.to_pay = 0
            d.is_over_time = false
            d.overtime_hours = 0
            d.overtime_hourly_amount = 0
            d.overtime_salary_amount = 0

            return d
        })

        setAttendanceDetials(_AttendanceDetials)
        setHasChangesMade(false)
    }
    const HandleSaveChanges = async (labour_attendance = [], vendor_attendance = []) => {

        if (!_Date) return HandleCloseFlottingFooter()

        let update_data = {
            projectId: projectId,
            date: _Date,
            labour_attendance: labour_attendance || AttendanceDetials,
            vendor_attendance: vendor_attendance || VendorAttendanceDetials,
        }

        setIsLoading(true)
        let response = await labourProjectAttendanceHandler.updateLabourAttendanceHandler(update_data)

        setIsLoading(false)
        if (response && response.success) {

            HandleCloseFlottingFooter()

        } else {
            setWarningAlert(true)
            setWarningAlertType('error')
            setApiFailedMessage(`Error in Attendance, Please try again!`)
        }
    }

    const HandleFilerDate = async (value) => {
        setDate(value)

        if (!value) return

        // await getProjectLabourDetials()

        let labour_attendance_response = await labourProjectAttendanceHandler.getLabourAttendanceHandler({
            projectId: projectId,
            date: value
        })

        if (!labour_attendance_response.success) return

        if (labour_attendance_response.data.length) {



            let { id, date, labour_attendance, vendor_attendance } = labour_attendance_response.data[0]


            let _AttendanceDetials = [...AttendanceDetials]

            _AttendanceDetials = _AttendanceDetials.map(labour => {

                let exist_labour = labour_attendance.filter(l => l.id == labour.id)[0]

                if (exist_labour) labour = exist_labour

                return labour
            })

            vendor_attendance = vendor_attendance.map(vendor => {

                vendor.total_workers = (vendor.workers || []).reduce((prev, w) => prev + parseFloat(w.no_workers || 0), 0)
                return vendor
            })

            setVendorAttendanceDetials(vendor_attendance)

            setAttendanceDetials(_AttendanceDetials)
        }
        else {
            let _AttendanceDetials = [...AttendanceDetials]

            _AttendanceDetials = _AttendanceDetials.map(d => {

                d.attendance = ''
                d.to_pay = 0
                d.payment_status = '0'
                d.paid_amount = 0
                d.is_over_time = false
                d.overtime_hours = 0
                d.overtime_hourly_amount = 0
                d.overtime_salary_amount = 0

                return d
            })
            setAttendanceDetials(_AttendanceDetials)

            setVendorAttendanceDetials([])
        }


    }

    const HandleOpenOverTime = (labour) => {

        if (!has_edit_access) return

        if (labour.attendance != 'present') return

        setShowOverTimePopup(true)
        setSelectedOverTimeLabour(labour)

    }

    const HandleOpenOverTimeCallback = (labour) => {

        if (!labour || !labour.id) return

        let _AttendanceDetials = [...AttendanceDetials]

        let labour_idx = _AttendanceDetials.findIndex(l => l.id == labour.id)

        if (labour_idx == -1) return
        _AttendanceDetials[labour_idx] = labour

        setAttendanceDetials(_AttendanceDetials)

        HandleSaveChanges(_AttendanceDetials, VendorAttendanceDetials)

    }
    const HandleAddVendorCallback = (attendance) => {

        if (!attendance || !attendance.id) return

        let _VendorAttendanceDetials = [...VendorAttendanceDetials]

        let idx = _VendorAttendanceDetials.findIndex(l => l.id == attendance.id)

        if (idx == -1) _VendorAttendanceDetials.push(attendance)
        else _VendorAttendanceDetials[idx] = attendance

        setVendorAttendanceDetials(_VendorAttendanceDetials)

        HandleSaveChanges(AttendanceDetials, _VendorAttendanceDetials)

    }

    const HandleCloseOverTime = () => {
        setShowOverTimePopup(false)
        setSelectedOverTimeLabour(undefined)
    }

    const HandleCloseVendorAttendance = () => {
        setShowAddVendorAttendancePopup(false)
        setSelectedVendorAttendance(undefined)
    }

    const HandleOpenVendorAttendance = (vendor) => {

        if (!has_edit_access || !vendor || !vendor.id) return

        setShowAddVendorAttendancePopup(true)
        setSelectedVendorAttendance(vendor)

    }

    return (
        <>

            {isLoading ?
                <Loading
                    props={{
                        isMainLogo: false,
                        isLabel: true
                    }} />
                : null}
            {warningAlert ?

                <SystemToastPopup
                    props={{
                        type: warningAlertType,
                        message: apiFailedMessage || "Error in Attendance",
                        callback: (confirmation) => setWarningAlert(false)
                    }} />

                : null}
            <Routes >
                <Route exact path='/add-labours' element={
                    <AddLaboursPopup props={{
                        type: "create",
                        heading: "Add Labours",
                        callback: getProjectLabourDetials,
                        close: CloseAddLabourPopup
                    }} />}></Route>

            </Routes>

            {showAddVendorAttendancePopup ?
                <AddVendorAttendancePopup props={{
                    type: "create",
                    heading: selectedVendorAttendance ? "Vendor Attendance" : "Add Attendance",
                    callback: HandleAddVendorCallback,
                    close: HandleCloseVendorAttendance,
                    detials: selectedVendorAttendance || undefined
                }} />
                : ''}
            {showOverTimePopup ?
                <AddOverTimePopup props={{
                    type: "create",
                    heading: "Add Over time",
                    callback: HandleOpenOverTimeCallback,
                    close: HandleCloseOverTime,
                    detials: selectedOverTimeLabour || undefined
                }} />
                : ''}

            <div className="project-client_invoice-main project-project_estimate-main project-labour_attendance-main">

                {hasChangesMade ?
                    <div className="panel-flotting-footer">
                        <div className="flotting-footer-items">
                            <div className="flotting-footer-item">
                                <div
                                    className="icon"
                                    dangerouslySetInnerHTML={{ __html: Icons.general.close }}
                                    onClick={(e) => HandleCloseFlottingFooter()}
                                ></div>
                            </div>
                            <div
                                className="flotting-footer-item footer-button"
                                onClick={(e) => HandleDiscardChanges()}
                            >
                                <div
                                    className="icon"
                                    dangerouslySetInnerHTML={{ __html: Icons.general.reject }}

                                ></div>
                                <div className="label">Reset All</div>
                            </div>
                            <div
                                className="flotting-footer-item footer-button active-footer-button"
                                onClick={(e) => HandleSaveChanges(AttendanceDetials, VendorAttendanceDetials)}
                            >
                                <div
                                    className="icon"
                                    dangerouslySetInnerHTML={{ __html: Icons.general.save }}

                                ></div>
                                <div className="label">Save</div>
                            </div>
                        </div>
                    </div>
                    : ''}

                <div className="proposals-panel-content">

                    <div className="panel-content-header">
                        <div className="title">Add Attendance</div>
                        <div className="buttons">
                            {has_add_access ?
                                <div
                                    className="button add-button"
                                    onClick={(e) => HandleAddLabour()}
                                >
                                    <div className="icon" dangerouslySetInnerHTML={{ __html: Icons.general.add_btn }}></div>
                                    <div className="label">Add {selectedResultSection == 'labour' ? 'Labours' : 'Vendor'}</div>
                                </div>
                                : ''}
                        </div>
                    </div>

                    <div className="report-result-sections labour-attendance-cards">

                        <div className="report-filter-main">
                            <div className="filter-item">
                                <div className="value">

                                    <DateTimeInput
                                        props={{
                                            id: "attendance-date",
                                            value: _Date,
                                            placeholder: '',
                                            setValue: (value) => {

                                                HandleFilerDate(value)
                                            },
                                            isIcon: false,
                                            isLabel: true,
                                            icon: Icons.general.schedule,
                                            isRequired: true,
                                            label: "Date",
                                        }}
                                    />

                                </div>
                            </div>
                        </div>
                        <div className="report-result-sections-items">

                            <div
                                className={`report-result-section ${selectedResultSection == 'labour' ? 'report-result-section-active' : ''}`}
                                onClick={() => HandleResultSection('labour')}
                            >
                                <div className="icon" dangerouslySetInnerHTML={{ __html: Icons.general.manpower }}></div>
                                <div className="label">Labour Attendance</div>
                            </div>
                            <div
                                className={`report-result-section ${selectedResultSection == 'vendor' ? 'report-result-section-active' : ''}`}
                                onClick={() => HandleResultSection('vendor')}
                            >
                                <div className="icon" dangerouslySetInnerHTML={{ __html: Icons.general.users }}></div>
                                <div className="label">Vendor Attendance</div>
                            </div>

                        </div>

                        <div className="report-total-dashboard-main">
                            <div className={`report-total-section ${selectedResultSection == 'labour' ? 'report-total-section-active' : ''}`}>
                                <div className="report-total-header">
                                    <div className="header-title">Labour Dashboard</div>
                                </div>
                                <div className="report-total-content">
                                    <div className="total-content-item">
                                        <div className="label">Present</div>
                                        <div className="value">{totalDashboard.labour.total_present || 0}</div>
                                    </div>
                                    <div className="total-content-item">
                                        <div className="label">Absent</div>
                                        <div className="value">{totalDashboard.labour.total_absent || 0}</div>
                                    </div>
                                    <div className="total-content-item">
                                        <div className="label">To be paid today</div>
                                        <div className="value">{currency_type || '₹'} {totalDashboard.labour.total_topay || 0}</div>
                                    </div>
                                </div>
                                <div className="attendance-info-label-items">
                                    <div className="label-item label-present">
                                        <div className="label">P</div>
                                        <div className="value">Present</div>
                                    </div>
                                    <div className="label-item label-absent">
                                        <div className="label">A</div>
                                        <div className="value">Absent</div>
                                    </div>
                                    <div className="label-item label-halfday">
                                        <div className="label">H</div>
                                        <div className="value">Halfday</div>
                                    </div>
                                </div>
                            </div>
                            <div className={`report-total-section ${selectedResultSection == 'vendor' ? 'report-total-section-active' : ''}`}>
                                <div className="report-total-header">
                                    <div className="header-title">Vendor Dashboard</div>
                                </div>
                                <div className="report-total-content">
                                    <div className="total-content-item">
                                        <div className="label">Vendors</div>
                                        <div className="value">{totalDashboard.vendor.total_vendors || 0}</div>
                                    </div>
                                    <div className="total-content-item">
                                        <div className="label">Labours</div>
                                        <div className="value">{totalDashboard.vendor.total_labours || 0}</div>
                                    </div>
                                    <div className="total-content-item">
                                        <div className="label">To be paid today</div>
                                        <div className="value">{currency_type || '₹'} {totalDashboard.vendor.total_topay || 0}</div>
                                    </div>
                                </div>

                            </div>

                        </div>

                    </div>

                    <div className="panel-content-sections panel-content-tabel-section panel-content-attendance-cards-main panel-content-cards-main">

                        {selectedResultSection == 'labour' ?
                            <>

                                {AttendanceDetials.length ?
                                    <div className="panel-content-cards-items">
                                        {
                                            AttendanceDetials?.map((item, idx) => (
                                                <div
                                                    className="panel-content-card"
                                                    key={item.id}
                                                >
                                                    <div className="card-top-section">
                                                        <div className="left">
                                                            <div className="card-id">{item.name || 'N/A'}</div>
                                                        </div>
                                                        <div className="right">
                                                            <div className="card-time">{item.updated_at ? Utils.getLocalFullDateLabel(parseFloat(item.updated_at || item.created_at)) : 'N/A'}</div>
                                                        </div>
                                                    </div>
                                                    <div className="attendance-card-section">

                                                        <div className="attendance-card-section-inputs">
                                                            <div
                                                                className={`section-input ${item.attendance == 'present' ? 'section-input-present' : ''} `}
                                                                onClick={(e) => HandleAttendanceStatus(item.id, idx, 'present')}
                                                            >P</div>
                                                            <div
                                                                className={`section-input ${item.attendance == 'absent' ? 'section-input-absent' : ''}`}
                                                                onClick={(e) => HandleAttendanceStatus(item.id, idx, 'absent')}
                                                            >A</div>
                                                            <div
                                                                className={`section-input ${item.attendance == 'halfday' ? 'section-input-halfday' : ''}`}
                                                                onClick={(e) => HandleAttendanceStatus(item.id, idx, 'halfday')}
                                                            >H</div>
                                                        </div>
                                                        <div className="attendance-card-section-pay">
                                                            <div className="label">To Pay</div>
                                                            <div className="value">{currency_type || '₹'} {item.to_pay}</div>
                                                        </div>
                                                    </div>
                                                    <div className="attendance-card-section">

                                                        <div className="attendance-card-section-info">{item.worktype_name}. {item.salary_amount}/Day</div>
                                                        {item.attendance == 'present' ?
                                                            item.is_over_time ?
                                                                <div
                                                                    className="attendance-card-section-info"
                                                                    onClick={() => HandleOpenOverTime(item)}
                                                                >
                                                                    <div className="label">{item.overtime_hours} hr/s Over time</div>
                                                                    <div className="edit" dangerouslySetInnerHTML={{ __html: Icons.general.edit }}></div>
                                                                </div>
                                                                :

                                                                <div
                                                                    className="attendance-card-section-addbtn"
                                                                    onClick={() => HandleOpenOverTime(item)}
                                                                >
                                                                    <div className="icon" dangerouslySetInnerHTML={{ __html: Icons.general.add_btn }}></div>
                                                                    <div className="label">Add Over time</div>
                                                                </div>

                                                            : ''

                                                        }
                                                    </div>
                                                </div>
                                            ))
                                        }

                                    </div>
                                    : <NoDataFound label={'No records Found'} />}
                            </>
                            : selectedResultSection == 'vendor' ?
                                <>
                                    {VendorAttendanceDetials.length ?
                                        <div className="panel-content-cards-items">
                                            {
                                                VendorAttendanceDetials?.map((item, idx) => (
                                                    <div
                                                        className="panel-content-card"
                                                        key={item.id}
                                                    >
                                                        <div className="card-top-section">
                                                            <div className="left">
                                                                <div className="card-id">{item.vendor_name || 'N/A'}</div>
                                                            </div>
                                                            <div className="right">
                                                                <div className="card-time">{item.updated_at ? Utils.getLocalFullDateLabel(parseFloat(item.updated_at || item.created_at)) : 'N/A'}</div>
                                                            </div>
                                                        </div>
                                                        <div className="attendance-card-section">

                                                            <div className="attendance-card-section-pay">
                                                                <div className="label">Total Workers</div>
                                                                <div className="value">{item.total_workers || 'N/A'}</div>
                                                            </div>
                                                            <div className="attendance-card-section-pay">
                                                                <div className="label">To Pay</div>
                                                                <div className="value">{currency_type || '₹'} {item.total_topay || 'N/A'}</div>
                                                            </div>
                                                        </div>
                                                        <div className="attendance-card-section">

                                                            <div className="attendance-card-section-info">Shift: <strong>{item.workshift_type == '1' ? 'Day' : 'Night'}</strong></div>

                                                            <div
                                                                className="attendance-card-section-addbtn"
                                                                onClick={() => HandleOpenVendorAttendance(item)}
                                                            >
                                                                <div className="icon" dangerouslySetInnerHTML={{ __html: Icons.general.arrow_right }}></div>
                                                                <div className="label">View Attendance</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                ))
                                            }

                                        </div>
                                        : <NoDataFound label={'No records Found'} />}
                                </>
                                : ''}



                    </div>

                </div>

            </div>
        </>
    )
}

export default LaboursAttendance;