import React, { useEffect, useState, useRef, Fragment } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import StoreHandler from '../redux/StoreHandler'
import UserAction from '../redux/action/userAction'
/* Redux Setup*/
import store from '../redux/store'
import { Link, useNavigate } from 'react-router-dom'
import AuthendicationHandler from '../Handlers/Authendication/Authendication'

import Header from './header/header'
import Image from '../assets/Images'
import Icons from '../assets/Icons'


import { useAuth, useMenuAuth } from '../hooks/useAuth'


const QuickMenus = () => {

    const navigate = useNavigate()
    const [isLoading, setIsLoading] = useState(false)

    const store = useSelector((store) => store)
    const dispatch = useDispatch()
    const { updateState } = new UserAction

    const { isAdmin, roles_permissions, company_features } = useAuth()

    const authendicationHandler = new AuthendicationHandler()

    const [PlanExpiryDays, setPlanExpiryDays] = useState(store.user.plan_expiry_duration_days || 0)


    const storeHandler = new StoreHandler({
        dataStack: ['builder'],
        store: store,
        dispatch: dispatch,
        updateState: updateState,
    })

    const LoadStoreData = async () => {
        setIsLoading(true)
        let resolved = await storeHandler.init()
        setIsLoading(false)
    }

    const QUICK_MENUS = [

        {
            id: 'project_smart_analytics',
            label: "Dashboard",
            key: 'project_dashboard',
            icon: Icons.general.dashboard,
            description: "(Dashboard, Profit/Loss, Payables)",
            callback: (e) => {
                navigate('/builder/projects/PR-001/dashboard')
            },
            background: '#e0f7fa',
            color: '#21abbc'
        },
        {
            id: 'projects',
            label: "Projects",
            disabled: true,
            key: 'projects',
            icon: Icons.general.takeoff,
            description: "(Project Details, Status)",
            callback: (e) => {
                navigate('/builder/projects')
            },
            background: '#e3f2fd',
            color: 'rgb(66, 165, 245)'
        },
        {
            id: 'pre_construction',
            label: "BOQ & BOM",
            key: 'project_estimate',
            icon: Icons.general.boq,
            description: "(Estimation, Proposals, BOQs)",
            callback: (e) => {
                navigate('/builder/projects/PR-001/estimate')
            },
            background: '#e0f7fa',
            color: '#21abbc'
        },
        {
            id: 'finance_dashoard',
            label: "Finance",
            key: 'finance_dashoard',
            icon: Icons.general.finance,
            description: "(Budget, Cash Flow)",
            callback: (e) => {
                navigate('/builder/projects/PR-001/finance-dashboard')
            },
            background: '#deecff',
            color: '#0060df'
        },
        {
            id: 'purchase_orders',
            label: "Purchases",
            key: 'purchase_orders',
            icon: Icons.general.orders,
            description: "(Purchase Orders, GRNs, Bills)",
            callback: (e) => {
                navigate('/builder/projects/PR-001/purchase_order')
            },
            background: '#deecff',
            color: '#0060df'
        },
        {
            id: 'grns',
            label: "GRNs",
            key: 'grns',
            icon: Icons.general.grn,
            description: "(Goods Receipt Notes, Deliveries)",
            callback: (e) => {
                navigate('/builder/projects/PR-001/grn')
            },
            background: '#deecff',
            color: '#0060df'
        },
        {
            id: 'bills',
            label: "Bills",
            key: 'bills',
            icon: Icons.general.bills,
            description: "(Bills, Payments, Receipts)",
            callback: (e) => {
                navigate('/builder/projects/PR-001/bills')
            },
            background: '#deecff',
            color: '#0060df'
        },
        {
            id: 'inventory',
            label: "Inventory",
            key: 'stock_items',
            icon: Icons.general.inventory,
            description: "(Stocks, Indents, Issues, Deliveries)",
            callback: (e) => {
                navigate('/builder/projects/PR-001/inventory/stock-items')
            },
            background: 'rgb(255, 243, 224)',
            color: 'rgb(255, 167, 38)'
        },
        {
            id: 'checklists',
            label: "Safety & Quality",
            key: 'checklists',
            icon: Icons.general.safety,
            description: "(Checklists, Inspections, Incidents)",
            callback: (e) => {
                navigate('/builder/projects/PR-001/checklists')
            },
            background: 'rgb(232, 234, 246)',
            color: 'rgb(92, 107, 192)'
        },
        {
            id: 'tasks',
            label: "Tasks",
            key: 'tasks',
            icon: Icons.general.tasks,
            description: "(Task Assignments, Status)",
            callback: (e) => {
                navigate('/builder/projects/PR-001/task-management')
            },
            background: 'rgb(232, 234, 246)',
            color: 'rgb(92, 107, 192)'
        },
        {
            id: 'issues',
            label: "Issues",
            key: 'issues',
            icon: Icons.general.issues,
            description: "(Issues, Resolutions, Reports)",
            callback: (e) => {
                navigate('/builder/projects/PR-001/issues-management')
            },
            background: 'rgb(232, 234, 246)',
            color: 'rgb(92, 107, 192)'
        },


        {
            id: 'labour_attendance',
            label: "Labours",
            key: "labour_attendance",
            icon: Icons.general.workers,
            description: "(Labour profiles, Labour accounts)",
            callback: (e) => {
                navigate('/builder/projects/PR-001/labour-attendance')
            },
            background: '#e0f7fa',
            color: '#21abbc'
        },
        {
            id: 'changes_orders',
            label: "Change Orders",
            key: "changes_orders",
            icon: Icons.general.change_orders,
            description: "(Change Orders, Approvals)",
            callback: (e) => {
                navigate('/builder/projects/PR-001/change_orders')
            },
            background: '#e0f7fa',
            color: '#21abbc'
        },
        {
            id: 'client_payments',
            label: "Payments",
            key: "client_payments",
            icon: Icons.general.invoice,
            description: "(Client Payments, Invoices, Receipts)",
            callback: (e) => {
                navigate('/builder/projects/PR-001/client_invoice')
            },
            background: '#e0f7fa',
            color: '#21abbc'
        },



        {
            id: 'employees',
            label: "Team",
            key: "employees",
            icon: Icons.general.employees,
            description: "(Tracking, Role permissions)",
            callback: (e) => {
                navigate('/builder/team-management')
            },
            background: '#fce4ec',
            color: 'rgb(236, 64, 122)'
        },
        {
            id: 'clinets',
            label: "Clinets ",
            key: "clients",
            disabled: true,
            icon: Icons.general.clients,
            description: "(Profiles, Status)",
            callback: (e) => {
                navigate('/builder/clients')
            },
            background: 'rgb(239, 235, 233)',
            color: 'rgb(141, 110, 99)'
        },
        {
            id: 'vendors',
            label: "My Vendors",
            key: "vendors",
            icon: Icons.general.vendors,
            description: "(Profiles, Status)",
            callback: (e) => {
                navigate('/builder/vendors')
            },
            background: 'rgb(251, 233, 231)',
            color: 'rgb(255, 112, 67)'
        },

        {
            id: 'masters',
            label: "Masters",
            key: "work_categories",
            icon: Icons.general.database,
            description: "(Work Category, Schedule Rates, Work Rates)",
            callback: (e) => {
                navigate('/builder/work-category')
            },
            background: '#ebf1f2',
            color: '#477c8d'
        },
        {
            id: 'reports',
            key: 'project_reports',
            label: "Reports",
            icon: Icons.general.report_file,
            description: "(Progress, Profit/Loss, Payables)",
            callback: (e) => {
                navigate('/builder/projects/PR-001/reports/progress-report')
            },
            background: 'rgb(232, 245, 233)',
            color: 'rgb(102, 187, 106)'
        },
        {
            id: 'drive',
            label: "Documents",
            key: 'drive',
            icon: Icons.general.documents,
            description: "(Documents, Drawings, Photos)",
            callback: (e) => {
                navigate('/builder/projects/PR-001/finance-summary')

            },
            background: 'rgb(232, 234, 246)',
            color: 'rgb(92, 107, 192)'
        },
        {
            id: 'settings',
            label: "My Company",
            key: "settings",
            icon: Icons.general.settings,
            description: "(Profile, Settings, Subscription)",
            callback: (e) => {
                navigate('/builder/settings/myprofile')
            },
            background: 'rgb(251, 233, 231)',
            color: 'rgb(255, 112, 67)'
        },


    ]

    const [UserName, setUserName] = useState('')
    const [QuickMenus_Orginal, setQuickMenus_Orginal] = useState([])
    const [QuickMenus, setQuickMenus] = useState([])
    const [Location, setLocation] = useState(undefined)

    const [SearchDebounce, setSearchDebounce] = useState(null)
    const [SearchDebounceDelay, setSearchDebounceDelay] = useState(500)
    const [SearchInputValue, setSearchInputValue] = useState('')

    const [CompanyDetials, setCompanyDetials] = useState({})
    const [UserDetials, setUserDetials] = useState({})

    const [CompanyLogoImg_url, setCompanyLogoImg_url] = useState('')
    const [ProfileImg_url, setProfileImg_url] = useState('')

    const getUserLocation = () => {
        if ('geolocation' in navigator) {
            navigator.geolocation.getCurrentPosition(function (position) {

                setLocation({
                    lat: position.coords.latitude,
                    lng: position.coords.longitude,
                })

            }, function (error) {
                console.error(`Error getting location: ${error.message}`);
            });
        } else {
            console.error('Geolocation is not available in your browser');
        }
    }

    const HandleHeaderCallback = (key) => {

        if (key == 'support') window.open('https://wa.link/2ktfa6', '_blank')
        else if (key == 'chat') navigate('/builder/chat')
        else if (key == 'notifications') navigate('/builder/notifications')
        else if (key == 'profile') navigate('/builder/settings/myprofile')
    }

    const HandleSearchInput = (e) => {
        let value = e.target.value
        setSearchInputValue(value)
        let items = [...QuickMenus_Orginal]

        let filtered_items = items.filter((item) => {
            return item.label.toLowerCase().includes(value.toLowerCase())
        })

        setQuickMenus(filtered_items)

    }


    useEffect(() => {
        LoadStoreData()
        getUserLocation()
    }, [])

    useEffect(() => {
        console.log(store.user, 'store.user');

        let user = store.user.builder
        if (user && Object.keys(user).length > 0) {
            setUserDetials(user)
            setCompanyDetials(store.user.company)

            if (user.profile_img) setProfileImg_url(`data:image/png;base64,${user.profile_img}`)
            if (store.user.company) setCompanyLogoImg_url(`data:image/png;base64,${store.user.company.logo_img}`)
        }

        setPlanExpiryDays(store.user.plan_expiry_duration_days || 0)

    }, [store])


    useEffect(() => {

        if (isAdmin) {


            let quickmenu_items = []

            QUICK_MENUS.forEach((_item, i) => {

                let item = { ..._item }

                if (['support', 'settings'].includes(_item.key)) quickmenu_items.push(item)
                else {

                    let isCompanyAllow = company_features.some((role) => {

                        let feature_key = String(role.feature_key).split('--')[1]

                        console.log(feature_key, feature_key == item.key);

                        return feature_key == item.key && role._view == 1
                    })


                    if (isCompanyAllow) quickmenu_items.push(item)
                }



            })

            setQuickMenus(quickmenu_items)
            setQuickMenus_Orginal(quickmenu_items)
        }

        else if (roles_permissions && Array.isArray(roles_permissions)) {

            let quickmenu_items = []

            QUICK_MENUS.forEach((_item, i) => {

                let item = { ..._item }

                if (['support', 'settings'].includes(_item.key)) quickmenu_items.push(item)

                else {


                    let isRoleAllow = roles_permissions.some((role) => {

                        let feature_key = String(role.feature_key).split('--')[1]

                        return feature_key == item.key && role._view == 1
                    })

                    let isCompanyAllow = company_features.some((role) => {

                        let feature_key = String(role.feature_key).split('--')[1]

                        console.log(feature_key, feature_key == item.key);


                        return feature_key == item.key && role._view == 1
                    })


                    if (isRoleAllow && isCompanyAllow) quickmenu_items.push(item)
                }



            })

            setQuickMenus(quickmenu_items)
            setQuickMenus_Orginal(quickmenu_items)
        }

    }, [store.user?.roles_permissions])

    return (

        <div className={`project-homescreen-main ${PlanExpiryDays <= 10 ? 'project-section-container-with-plan_expiry_banner' : ''} `}>
            <div className="homescreen-main-container">
                <div className="homescreen-header">
                    <div className="header-left">
                        {CompanyLogoImg_url ?
                            <div className="header-company_logo">
                                <img src={CompanyLogoImg_url} alt="" />
                            </div>
                            : ''}
                        <div className="header-welcome-item">
                            <div className="welcome-item-title">Welcome!</div>
                            {UserDetials?.name ? <div className="welcome-item-name">{UserDetials?.name}</div> : ''}
                        </div>
                    </div>
                    <div className="header-right">
                        <div className="header-searchbar">
                            <div className="search-input">
                                <input type="text"
                                    placeholder='Search'
                                    value={SearchInputValue}
                                    onChange={HandleSearchInput}
                                />
                            </div>
                            <div className="search-icon"
                                dangerouslySetInnerHTML={{ __html: Icons.general.search }}
                            ></div>
                        </div>
                        <div className="header-icon-item"
                            onClick={(e) => HandleHeaderCallback('support')}
                            dangerouslySetInnerHTML={{ __html: Icons.general.whatsapp }}
                        ></div>
                        <div className="header-icon-item"
                            onClick={(e) => HandleHeaderCallback('chat')}
                            dangerouslySetInnerHTML={{ __html: Icons.general.chat }}
                        ></div>
                        <div className="header-icon-item"
                            onClick={(e) => HandleHeaderCallback('notifications')}
                            dangerouslySetInnerHTML={{ __html: Icons.general.notification }}
                        ></div>
                        <div className="header-user_logo"
                            onClick={(e) => HandleHeaderCallback('profile')}
                        >
                            <img src={ProfileImg_url || Image.User} alt="" />
                        </div>
                    </div>
                </div>
                <div className="homescreen-content">
                    <div className="homescreen-content-items">
                        {QuickMenus.filter(q => !q.disabled).map((item, i) => {
                            console.log(item.id);

                            return (
                                <div className="homescreen-content-item"
                                    key={item.id}
                                    onClick={item.callback}
                                >
                                    <div className="item-icon"
                                        dangerouslySetInnerHTML={{ __html: item.icon }}
                                    ></div>
                                    <div className="item-label">{item.label}</div>
                                </div>
                            )
                        })}
                    </div>
                </div>
                <div className="homescreen-footer">
                    <div className="homescreen-footer-left">
                        <div className="footer-project-main">
                            <div className="project-icon"
                                dangerouslySetInnerHTML={{ __html: Icons.general.building }}
                            ></div>
                            <div className="project-label">Altis Ashraya</div>
                        </div>
                    </div>
                    <div className="homescreen-footer-right">
                        <div className="footer-powered_by">
                            <div className="powered_by-label">Powered by.</div>
                            <div className="powered_by-logo">
                                <img src={Image.logo} alt="" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default QuickMenus;
