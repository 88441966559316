

import React, { useEffect, useState, useRef } from 'react'
import { Route, Routes, BrowserRouter, Link, useLocation, Navigate, Outlet, useNavigate, useParams } from 'react-router-dom';
import store from '../../redux/store'
import StoreHandler from '../../redux/StoreHandler'
import { useSelector, useDispatch } from 'react-redux'
import UserAction from '../../redux/action/userAction'

import ReverseProxy from '../../config/reverseProxy'
import ScheduleRatesHandler from '../../Handlers/ScheduleRates/ScheduleRates';
import Icons from '../../assets/Icons'
import Images from '../../assets/Images'
import { CostInput, TextInput, SelectInput, QuantityInput, RadioInput } from '../../components/Inputs'
import Utils from '../../utils'
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

import AlertPopup from '../../components/AlertPopup'
import Loading from '../../components/Loading'
import SystemToastPopup from '../../components/SystemToastPopup'


import { useAuth, useMenuAuth } from '../../hooks/useAuth'

const AddGroup = ({ props }) => {

    const { existItemData, updateCallback } = props


    const { itemType, itemId, actionType } = useParams()

    const scheduleRatesHandler = new ScheduleRatesHandler()

    const [name, setName] = useState('')
    const [group_id, setGroup_id] = useState('')
    const [group, setGroup] = useState('')
    const [head_id, setHead_id] = useState('')
    const [head_name, setHeadName] = useState('')
    const [description, setDescription] = useState('')

    const [isLoading, setIsLoading] = useState(false)
    const [warningAlert, setWarningAlert] = useState(false)
    const [warningAlertType, setWarningAlertType] = useState('warning')
    const [apiFailedMessage, setApiFailedMessage] = useState("Error in Server")


    const [currency_type, setCurrency_type] = useState('₹')

    const navigate = useNavigate()
    const store = useSelector((store) => store)
    const dispatch = useDispatch()
    const { updateState } = new UserAction


    const storeHandler = new StoreHandler({
        dataStack: [`company`],
        store: store,
        dispatch: dispatch,
        updateState: updateState,
    })

    const LoadStoreData = async () => {
        setIsLoading(true)
        let resolved = await storeHandler.init()
        setIsLoading(false)
    }

    const isExistItemData = existItemData && existItemData.id != undefined
    const [additionalNotes, setAdditionalNotes] = useState(false);

    const head_options = [
        {
            value: "labour",
            label: "Labour"
        },
        {
            value: "material",
            label: "Material"
        },
        {
            value: "machine",
            label: "Machine"
        },
    ]
    const AddBtnOptions = [
        {
            icon: Icons.general.group,
            label: 'Category',
            type: 'group',
            id: "group"
        },
        {
            icon: Icons.general.labour,
            label: 'Labour',
            type: 'item',
            id: "labour"
        },
        {
            icon: Icons.general.material,
            label: 'Materials',
            type: 'item',
            id: "material"
        },
        {
            icon: Icons.general.machines,
            label: 'Machines',
            type: 'item',
            id: "machine"
        },
        {
            icon: Icons.general.subcontractor,
            label: 'Subcontractor',
            type: 'item',
            id: "subcontractor"
        },
        {
            icon: Icons.general.fee,
            label: 'Fee',
            type: 'item',
            id: "fee"
        },
        {
            icon: Icons.general.allowance,
            label: 'Allowances',
            type: 'item',
            id: "allowance"
        },
        {
            icon: Icons.general.work,
            label: 'Work',
            type: 'work',
            id: 'work'
        },
    ]

    const [AddBtnOption, setAddBtnOption] = useState(AddBtnOptions.filter(opt => opt.id == itemType)[0] || undefined)

    const [groupOptions, setGroupOptions] = useState([])

    const getEstimateGroups = async () => {

        let filter = {
            type: 1
        }

        let response = await scheduleRatesHandler.getItemsHandler(filter)

        if (response.success) {

            let { data } = response

            if (!Array.isArray(data)) return

            let getSubItemsIds = (items, parentid) => {

                let subGroupIds = []

                let getItems = (items, parentid) => {

                    if (!Array.isArray(items)) return

                    let _items = items.filter(itm => itm.parent_id == parentid)

                    subGroupIds.push(..._items)

                    _items.forEach(itm => getItems(items, itm.id))
                }

                getItems(items, parentid)

                subGroupIds = subGroupIds.map(g => g.id)

                subGroupIds.push(parentid)

                return subGroupIds
            }

            if (isExistItemData) {

                data = data.filter(d => !getSubItemsIds(data, existItemData.id).includes(d.id))
            }

            data = data.filter(d => d.type == '1').map(d => {

                return {
                    value: d.id,
                    label: d.name,
                    head_id: d.head_id,
                    head_name: d.head_name,
                }

            })



            setGroupOptions(data)
        }
    }

    const HandlePopupSubmit = async (e) => {
        e.preventDefault()

        let updated_data = {
            name,
            parent_id: group_id || undefined,
            parent_name: group || undefined,
            head_id: head_id,
            head_name: head_name,
            type: 1,
            expanded: false,
            description,

        }
        setIsLoading(true)

        let response = { success: false }

        if (actionType == 'edit' && isExistItemData && existItemData.id) {
            updated_data['id'] = existItemData.id
            response = await scheduleRatesHandler.updateGroupHandler(updated_data)
        }
        else if (actionType == 'add') response = await scheduleRatesHandler.createGroupHandler(updated_data)

        setIsLoading(false)

        if (response.success) {

            if (typeof updateCallback == 'function') updateCallback()
            navigate(`/builder/schedule-rates`)
        }
        else {
            setWarningAlert(true)
            setWarningAlertType('error')
            setApiFailedMessage(`Error in Group`)
        }


    }

    const Popup_Header = ({ props }) => {

        const { icon, label } = props
        return (
            <div className="side-popup-header">
                <div className="header-item-select">
                    <div className="header-item-select-content">
                        <span className="icon" dangerouslySetInnerHTML={{ __html: icon }}></span>
                        <div className="label">{actionType == 'edit' ? 'Update' : 'Add'} {label || 'Group'}</div>

                    </div>
                </div>
                <div
                    className="header-item-close"
                    onClick={(e) => {
                        navigate(`/builder/schedule-rates`)
                    }}
                    dangerouslySetInnerHTML={{ __html: Icons.general.close }}
                ></div>
            </div>
        );
    };
    const Popup_Footer = ({ props }) => {

        const { icon, label } = props

        return (
            <div className="sidebar-popup-footer">
                <div className="footer-item action-items">
                    <div className="action-preview">
                    </div>
                    <button
                        className="action-btn"
                        type='submit'
                    >
                        <div className="icon" dangerouslySetInnerHTML={{ __html: actionType == 'edit' ? Icons.general.save : Icons.general.add_btn }}></div>
                        <div className="label">Save</div>
                    </button>
                </div>
            </div>
        );
    };

    const getEstimateItemTypeEenem = (type) => {

        if (type == '1') return 'group'
        else if (type == '2') return 'work_template'
        else if (type == '3') return 'item'
        else if (type == '4') return 'work'
        else return ''

    }
    const getEstimateItemCategoryEenem = (type) => {

        if (type == '1') return 'material'
        else if (type == '2') return 'labour'
        else if (type == '3') return 'machine'
        else if (type == '4') return 'subcontractor'
        else if (type == '5') return 'fee'
        else if (type == '6') return 'allowance'
        else return ''

    }
    const getEstimateItemStatusEenem = (type) => {

        if (type == '0') return 'incomplete'
        else if (type == '1') return 'completed'
        else if (type == '2') return 'hold'
        else if (type == '3') return 'stopped'
        else return ''
    }

    const getFormatedEstimateItem = (item) => {

        return {
            sno: item.sno,
            expanded: true,
            selected: false,
            disable: false,

            parent_id: item.parent_id,
            parent_name: item.parent_name,
            head_id: item.head_id,
            head_name: item.head_name,
            id: item.id,
            name: item.name,
            description: item.description,


            type: getEstimateItemTypeEenem(item.type),
            item_type: item.type == '1' ? 'group' : getEstimateItemCategoryEenem(item.item_type),
            cost_type: getEstimateItemCategoryEenem(item.item_type) || "",
            _status: item.status,
            status: getEstimateItemStatusEenem(item.status),

            builder_price: parseFloat(item.builder_price || 0) || 0,
            price: parseFloat(item.price || 0) || 0,


            unit_id: item.unit_id || "",
            unit_name: item.unit_name || "",

            markup_id: item.markup_id || "",
            markup_name: item.markup_name || "",
            markup_value: parseFloat(item.markup_value || 0) || 0,
            markup_value_type: item.markup_value_type || "",
            markup_amount: parseFloat(item.markup_amount || 0) || 0,

            tax_id: item.tax_id || "",
            tax_name: item.tax_name || "",
            tax_value_type: item.tax_value_type || "",
            tax_value: parseFloat(item.tax_value || 0) || 0,
            tax_amount: parseFloat(item.tax_amount || 0) || 0,

            sub_items: []
        }

    }

    const LoadEstimateItem = async (idx) => {

        if (idx == undefined) return

        let filter = {
            idx: idx
        }

        setIsLoading(true)

        let response = await scheduleRatesHandler.getItemsHandler(filter)
        setIsLoading(false)

        if (response.success) {


            let { data } = response

            if (!Array.isArray(data) || !data.length) return

            let item = getFormatedEstimateItem(data[0])

            setName(item.name || '')
            setAdditionalNotes(item.description ? true : false)
            setDescription(item.description || '')
            setGroup_id(item.parent_id || '')
            setGroup(item.parent_name || '')

            setHead_id(item.head_id || '')
            setHeadName(item.head_name || '')

        }
        else {
            setWarningAlert(true)
            setWarningAlertType('error')
            setApiFailedMessage(`Error in Group`)
        }
    }

    const HandleHeadChange = (id, label) => {
        setHead_id(id)
        setHeadName(label)

        let _groupOptions = [...groupOptions]

        _groupOptions = _groupOptions.filter(g => g.head_id == id)

        setGroupOptions(_groupOptions)

    }


    useEffect(() => {

        // LoadStoreData()
        getEstimateGroups()

        if (actionType == 'edit' && isExistItemData) {

            setName(existItemData.name || '')
            setAdditionalNotes(existItemData.description ? true : false)
            setDescription(existItemData.description || '')
            setGroup_id(existItemData.parent_id || '')
            setGroup(existItemData.parent_name || '')
            setHead_id(existItemData.head_id || '')
            setHeadName(existItemData.head_name || '')
        }

        if (actionType == 'edit' && !isExistItemData) LoadEstimateItem(itemId)

    }, [])

    return (
        <div className="popup-container-main popup-container-center">
            <div className="popup-block-ui"></div>
            <form className="side-popup-container" onSubmit={HandlePopupSubmit}>

                <Popup_Header props={AddBtnOption} />

                <div className="sidebar-popup-content">
                    <div className="content-section">


                        <div className="content-item">
                            <TextInput
                                props={{
                                    id: "costcatalog-group-name",
                                    value: name,
                                    placeholder: '',
                                    setValue: (value) => setName(value),
                                    isIcon: false,
                                    isLabel: true,
                                    isRequired: true,
                                    label: "Name",
                                }}
                            />
                        </div>
                        <div className="content-item">
                            <SelectInput
                                props={{
                                    id: "estimation-group-head",
                                    value: head_id,
                                    placeholder: '',
                                    options: head_options || [],
                                    setValue: (value, label) => HandleHeadChange(value, label),
                                    isIcon: false,
                                    isLabel: true,
                                    isRequired: true,
                                    label: "Head",
                                }}
                            />
                        </div>
                        <div className="content-item">
                            <SelectInput
                                props={{
                                    id: "estimation-group-group",
                                    value: group_id,
                                    placeholder: '',
                                    options: [
                                        {
                                            value: "",
                                            label: "Select Group"
                                        }, ...groupOptions || []
                                    ] || [],
                                    setValue: (value, label) => {
                                        setGroup_id(value)
                                        setGroup(label)
                                    },
                                    isIcon: false,
                                    isLabel: true,
                                    isRequired: false,
                                    label: "Parent",
                                }}
                            />

                        </div>
                        <div className="content-section">
                            <div className="content-item">
                                <div id="estimation-group-description" className={`additional-item ${additionalNotes ? 'additional-item-active' : ''}`}>
                                    <div className="head" onClick={(e) => setAdditionalNotes(!additionalNotes)}>
                                        <div className="icon" dangerouslySetInnerHTML={{ __html: Icons.general.description }}></div>
                                        <div className="label">Description</div>
                                    </div>
                                    <div className="item-expanded">
                                        <ReactQuill
                                            theme="snow"
                                            value={description}
                                            onChange={(value) => setDescription(value)}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>

                <Popup_Footer props={AddBtnOption} />
            </form>
        </div>
    )
}
const AddItem = ({ props }) => {

    const { existItemData, updateCallback } = props

    const { itemType, itemId, actionType } = useParams()

    const [isLoading, setIsLoading] = useState(false)
    const [warningAlert, setWarningAlert] = useState(false)
    const [warningAlertType, setWarningAlertType] = useState('warning')
    const [apiFailedMessage, setApiFailedMessage] = useState("Error in Server")

    const scheduleRatesHandler = new ScheduleRatesHandler()

    const [currency_type, setCurrency_type] = useState('₹')

    const navigate = useNavigate()
    const store = useSelector((store) => store)
    const dispatch = useDispatch()
    const { updateState } = new UserAction

    const storeHandler = new StoreHandler({
        dataStack: ['company', 'field_settings', 'finance_settings'],
        store: store,
        dispatch: dispatch,
        updateState: updateState,
    })

    const LoadStoreData = async () => {
        setIsLoading(true)
        let resolved = await storeHandler.init()
        setIsLoading(false)
    }
    const getEstimateGroups = async () => {

        let filter = {
            type: 1,
        }

        let response = await scheduleRatesHandler.getItemsHandler(filter)

        if (response.success) {

            let { data } = response

            if (!Array.isArray(data)) return

            data = data.filter(d => d.type == '1').map(d => {

                return {
                    value: d.id,
                    label: d.name,
                    head_id: d.head_id,
                    head_name: d.head_name,
                }
            })

            data = data.filter(d => d.head_id == itemType)

            setGroupOptions(data)
        }
    }

    const AddBtnOptions = [
        {
            icon: Icons.general.group,
            label: 'Category',
            type: 'group',
            id: "group"
        },

        {
            icon: Icons.general.labour,
            label: 'Labour',
            type: 'item',
            id: "labour"
        },
        {
            icon: Icons.general.material,
            label: 'Material',
            type: 'item',
            id: "material"
        },
        {
            icon: Icons.general.machines,
            label: 'Machine',
            type: 'item',
            id: "machine"
        },
    ]

    const [AddBtnOption, setAddBtnOption] = useState(AddBtnOptions.filter(opt => opt.id == itemType)[0] || undefined)

    const isExistItemData = existItemData && existItemData.id != undefined


    const [name, setName] = useState('')

    const [group_id, setGroup_id] = useState('')
    const [group, setGroup] = useState('')

    const [id, setId] = useState(isExistItemData ? existItemData.id : '')
    const [unit_id, setUnit_id] = useState('')
    const [unit_name, setUnit_name] = useState('')
    const [unitrate, setUnitrate] = useState('')
    const [description, setDescription] = useState('')
    const [additionalNotes, setAdditionalNotes] = useState(false);

    const [tax_id, setTax_id] = useState('')
    const [tax_name, setTax_name] = useState('')
    const [tax_value, setTax_value] = useState(0)

    const [markup_id, setMarkup_id] = useState('')
    const [markup_name, setMarkup_name] = useState('')
    const [markup_value, setMarkup_value] = useState(0)
    const [markup_type, setMarkup_type] = useState('%')


    const [unitsOptions, setUnitsOptions] = useState([])
    const [taxesOptions, setTaxesOptions] = useState([])
    const [markupsOptions, setMarkupsOptions] = useState([])
    const [groupOptions, setGroupOptions] = useState([])

    const unti_tax = (parseFloat(unitrate || 0) * parseFloat(tax_value)) / 100
    const unti_markup = markup_type == '%' ? (parseFloat(unitrate || 0) * parseFloat(markup_value)) / 100 : parseFloat(markup_value)

    const price = parseFloat(unitrate || 0) + parseFloat(unti_tax || 0) + parseFloat(unti_markup || 0)


    useEffect(() => {

        if (store && store.user) {

            let { company, clients, costcodes, settings } = store.user



            if (company) {

                let { currency_setting, timezone, temperature_setting } = company

                if (currency_setting) setCurrency_type(currency_setting)
            }


            if (settings && Array.isArray(settings.field_settings) && settings.field_settings.length) {

                let { units } = settings.field_settings[0]

                units = units.filter(opt => opt.isActive).map((opt) => {
                    return {
                        value: opt.id,
                        label: opt.name,
                        isDefault: opt.isDefault || false
                    }
                })

                setUnitsOptions(units || [])

            }

            if (settings && Array.isArray(settings.finance_settings) && settings.finance_settings.length) {

                let { taxes, markups, overheads, insurance } = settings.finance_settings[0]

                taxes = taxes.filter(opt => opt.isActive).map((opt) => {
                    return {
                        id: opt.id,
                        value: parseFloat(opt.value || 0),
                        label: opt.name,
                        type: opt.type,
                        isDefault: opt.isDefault || false
                    }
                })

                setTaxesOptions(taxes || [])


                markups = markups.filter(opt => opt.isActive).map((opt) => {
                    return {
                        id: opt.id,
                        value: parseFloat(opt.value || 0),
                        label: opt.name,
                        type: opt.value_type,
                        isDefault: opt.isDefault || false
                    }
                })

                setMarkupsOptions(markups || [])
            }


        }

    }, [store])

    const getEstimateItemTypeEenemReverse = (type) => {

        if (type == 'material') return 1
        else if (type == 'labour') return 2
        else if (type == 'machine') return 3
        else if (type == 'subcontractor') return 4
        else if (type == 'fee') return 5
        else if (type == 'allowance') return 6
        else return 0

    }


    const getEstimateItemStatusEenemReverse = (type) => {

        if (type == 'incomplete') return 0
        else if (type == 'completed') return 1
        else if (type == 'hold') return 2
        else if (type == 'stopped') return 3
        else return -1

    }
    const getEstimateItemStatusEenem = (type) => {

        if (type == '0') return 'incomplete'
        else if (type == '1') return 'completed'
        else if (type == '2') return 'hold'
        else if (type == '3') return 'stopped'
        else return ''
    }

    const HandlePopupSubmit = async (e) => {
        e.preventDefault()


        let updated_data = {

            name: name,

            unit_id: unit_id,
            unit_name: unit_name,
            unitrate: String(unitrate),

            head_id: AddBtnOption.id,
            head_name: AddBtnOption.label,

            parent_id: group_id || undefined,
            parent_name: group || undefined,

            tax_id: tax_id,
            tax_name: tax_name,
            tax_value: String(tax_value),
            tax_amount: String(unti_tax),


            markup_id: markup_id,
            markup_name: markup_name,
            markup_value: String(markup_value),
            markup_value_type: markup_type,
            markup_amount: String(unti_markup),

            expanded: false,
            price: String(price),
            type: 3,
            item_type: getEstimateItemTypeEenemReverse(AddBtnOption.id),
            description: description,
        }

        setIsLoading(true)

        let response = { success: false }

        if (actionType == 'edit' && id) {
            updated_data['id'] = id
            response = await scheduleRatesHandler.updateItemHandler(updated_data)
        }
        else if (actionType == 'add') response = await scheduleRatesHandler.createItemHandler(updated_data)

        if (response.success) {
            setIsLoading(false)

            console.log(updateCallback, 'updateCallback');
            if (typeof updateCallback == 'function') updateCallback()
            navigate(`/builder/schedule-rates`)
        }
        else {
            setIsLoading(false)
            setWarningAlert(true)
            setWarningAlertType('error')
            setApiFailedMessage(`Error in Item`)
        }


    }


    const Popup_Header = ({ props }) => {

        const { icon, label } = props
        return (
            <div className="side-popup-header">
                <div className="header-item-select">
                    <div className="header-item-select-content">
                        <span className="icon" dangerouslySetInnerHTML={{ __html: icon }}></span>
                        <div className="label">{actionType == 'edit' ? 'Update' : 'Add'} {label || "Item"}</div>

                    </div>
                </div>
                <div
                    className="header-item-close"
                    onClick={(e) => {
                        navigate(`/builder/schedule-rates`)
                    }}
                    dangerouslySetInnerHTML={{ __html: Icons.general.close }}
                ></div>
            </div>
        );
    };
    const Popup_Footer = ({ props }) => {

        const { icon, label } = props

        return (
            <div className="sidebar-popup-footer">
                <div className="footer-item action-items">
                    <div className="action-preview">
                        <div className="label">{label} Price</div>
                        <div className="value">{currency_type}{price || '0.00'}</div>
                    </div>
                    <button
                        className="action-btn"
                        type='submit'
                    >
                        <div className="icon" dangerouslySetInnerHTML={{ __html: actionType == 'edit' ? Icons.general.save : Icons.general.add_btn }}></div>
                        <div className="label">{actionType == 'edit' ? 'Save' : 'Add Item'}</div>
                    </button>
                </div>
            </div>
        );
    };


    const getEstimateItemTypeEenem = (type) => {

        if (type == '1') return 'group'
        else if (type == '2') return 'work_template'
        else if (type == '3') return 'item'
        else if (type == '4') return 'work'
        else return ''

    }
    const getEstimateItemCategoryEenem = (type) => {

        if (type == '1') return 'material'
        else if (type == '2') return 'labour'
        else if (type == '3') return 'machine'
        else if (type == '4') return 'subcontractor'
        else if (type == '5') return 'fee'
        else if (type == '6') return 'allowance'
        else return ''

    }


    const getFormatedEstimateItem = (item) => {

        return {
            sno: item.sno,
            expanded: true,
            selected: false,
            disable: false,

            parent_id: item.parent_id,
            parent_name: item.parent_name,
            id: item.id,
            name: item.name,
            description: item.description,


            type: getEstimateItemTypeEenem(item.type),
            item_type: item.type != '3' ? getEstimateItemTypeEenem(item.type) : getEstimateItemCategoryEenem(item.item_type),
            cost_type: item.type != '1' && item.type != '3' ? getEstimateItemTypeEenem(item.type) : getEstimateItemCategoryEenem(item.item_type),

            _status: item.status,
            status: getEstimateItemStatusEenem(item.status),

            unitrate: parseFloat(item.unitrate || 0) || 0,
            builder_price: parseFloat(item.builder_price || 0) || 0,
            price: parseFloat(item.price || 0) || 0,


            unit_id: item.unit_id || "",
            unit_name: item.unit_name || "",

            markup_id: item.markup_id || "",
            markup_name: item.markup_name || "",
            markup_value: parseFloat(item.markup_value || 0) || 0,
            markup_value_type: item.markup_value_type || "",
            markup_amount: parseFloat(item.markup_amount || 0) || 0,

            tax_id: item.tax_id || "",
            tax_name: item.tax_name || "",
            tax_value_type: item.tax_value_type || "",
            tax_value: parseFloat(item.tax_value || 0) || 0,
            tax_amount: parseFloat(item.tax_amount || 0) || 0,

            sub_items: []
        }

    }

    const LoadEstimateItem = async (idx) => {

        if (idx == undefined) return

        let filter = {
            idx: idx
        }

        setIsLoading(true)

        let response = await scheduleRatesHandler.getItemsHandler(filter)
        setIsLoading(false)

        if (response.success) {


            let { data } = response


            if (!Array.isArray(data) || !data.length) return


            let items = data.map(d => {

                let item = getFormatedEstimateItem(d)

                let tax_amount = item.tax_amount
                let markup_amount = item.markup_amount
                let builder_price = item.builder_price
                let price = item.price

                item._tax_amount = tax_amount || ""
                item._tax_percent = ((tax_amount / builder_price) * 100 || 0).toFixed(2)
                item._markup_amount = markup_amount || ""
                item._markup_percent = ((markup_amount / builder_price) * 100 || 0).toFixed(2)
                item._builder_price = builder_price || ""
                item._price = price || ""
                item._unitrate = item.unitrate || ""

                item.tax_amount = tax_amount ? `${currency_type}${tax_amount}` : ""
                item.markup_amount = markup_amount ? `${currency_type}${markup_amount}` : ""
                item.builder_price = builder_price ? `${currency_type}${builder_price}` : ""
                item.price = price ? `${currency_type}${price}` : ""
                item.unitrate = item.unitrate ? `${currency_type}${item.unitrate}` : ""

                return item

            })

            let item = items[0]

            setId(item.id)

            setName(item.name || '')


            if (!['labour', 'material', 'machine'].includes(item.parent_id)) {
                setGroup_id(item.parent_id || '')
                setGroup(item.parent_name || '')
            }

            setUnit_id(item.unit_id || '')
            setUnit_name(item.unit_name || '')
            setUnitrate(parseFloat(item._unitrate || 0) || '')
            setDescription(item.description || '')

            setAdditionalNotes(item.description ? true : false)

            setTax_id(item.tax_id || '')
            setTax_name(item.tax_name || '')
            setTax_value(parseFloat(item.tax_value || 0) || 0)

            setMarkup_id(item.markup_id || '')
            setMarkup_name(item.markup_name || '')
            setMarkup_value(parseFloat(item.markup_value || 0) || 0)
            setMarkup_type(item.markup_value_type)

        }
        else {
            setWarningAlert(true)
            setWarningAlertType('error')
            setApiFailedMessage(`Error in Item`)
        }
    }


    const HandleHeadChange = (id, label) => {
        setGroup_id(id)
        setGroup(label)

    }



    useEffect(() => {

        LoadStoreData()
        getEstimateGroups()

        if (actionType == 'edit' && isExistItemData) {



            setId(existItemData.id)

            setName(existItemData.name || '')


            if (!['labour', 'material', 'machine'].includes(existItemData.parent_id)) {
                setGroup_id(existItemData.parent_id || '')
                setGroup(existItemData.parent_name || '')
            }

            setUnit_id(existItemData.unit_id || '')
            setUnit_name(existItemData.unit_name || '')

            setUnitrate(parseFloat(existItemData._unitrate || 0) || '')
            setDescription(existItemData.description || '')

            setAdditionalNotes(existItemData.description ? true : false)

            setTax_id(existItemData.tax_id || '')
            setTax_name(existItemData.tax_name || '')
            setTax_value(parseFloat(existItemData.tax_value || 0) || 0)

            setMarkup_id(existItemData.markup_id || '')
            setMarkup_name(existItemData.markup_name || '')
            setMarkup_value(parseFloat(existItemData.markup_value || 0) || 0)
            setMarkup_type(existItemData.markup_value_type)


        }

        if (actionType == 'edit' && !isExistItemData) LoadEstimateItem(itemId)

    }, [])

    return (

        <div className="popup-container-main propup-container-center">
            <div className="popup-block-ui"></div>
            <form className="side-popup-container animateXRight animateXRightactive" onSubmit={HandlePopupSubmit}>
                <Popup_Header props={AddBtnOption} />

                <div className="sidebar-popup-content">
                    <div className="content-section">

                        <div className="content-item">
                            <TextInput
                                props={{
                                    id: "costcatalog-work-name",
                                    value: name,
                                    placeholder: '',
                                    setValue: (value) => {
                                        setName(value)
                                    },
                                    isIcon: false,
                                    isLabel: true,
                                    isRequired: true,
                                    label: "Name",
                                }}
                            />
                        </div>

                        <div className="content-item">
                            <SelectInput
                                props={{
                                    id: "costcatalog-work-group",
                                    value: group_id,
                                    placeholder: '',
                                    options: [
                                        {
                                            value: "",
                                            label: "Select Category"
                                        }, ...groupOptions || []
                                    ] || [],
                                    setValue: (value, label) => HandleHeadChange(value, label),
                                    isIcon: false,
                                    isLabel: true,
                                    isRequired: false,
                                    label: "Category",
                                }}
                            />
                        </div>



                        <div className="content-item">

                            <div className="content-sub-item">
                                <SelectInput
                                    props={{
                                        id: "costcatalog-work-unit",
                                        value: unit_id,
                                        placeholder: '',
                                        readOnly: false,
                                        options: unitsOptions || [],
                                        setValue: (value, label) => {
                                            setUnit_id(value)
                                            setUnit_name(label)
                                        },
                                        isIcon: false,
                                        isLabel: true,
                                        isRequired: true,
                                        label: "Unit type",
                                    }}
                                />
                            </div>

                            <div className="content-sub-item">
                                <CostInput
                                    props={{
                                        id: "costcatalog-work-unitrate",
                                        value: unitrate,
                                        isCostMethod: false,
                                        currency_type: currency_type,
                                        readOnly: false,
                                        placeholder: '',
                                        setValue: (value) => {
                                            setUnitrate(value)
                                        },
                                        isIcon: true,
                                        icon: currency_type,
                                        isLabel: true,
                                        isRequired: true,
                                        label: "Unit rate",
                                    }}
                                />
                            </div>

                        </div>
                        <div className="content-item">
                            <CostInput
                                props={{
                                    id: "costcatalog-work-price",
                                    value: price,
                                    currency_type: currency_type,
                                    isCostMethod: false,
                                    placeholder: '',
                                    readOnly: true,
                                    setValue: (value) => {
                                        // setPrice(value)
                                    },
                                    isIcon: true,
                                    icon: currency_type,
                                    isLabel: true,
                                    isRequired: false,
                                    label: "Amount",
                                }}
                            />
                        </div>
                    </div>
                    <div className="content-section">
                        <div className="content-section-header">
                            <div className="label">Pricing</div>
                        </div>

                        <div className="content-section-header">
                            <div className="label">Tax</div>
                            <div className="input">
                                <select
                                    name=""
                                    id=""
                                    onInput={(e) => {

                                        let tax = taxesOptions?.filter(t => t.id == e.target.value)[0]

                                        if (tax) {

                                            setTax_id(tax.id)
                                            setTax_value(parseFloat(tax.value || 0))
                                            setTax_name(tax.label)
                                        }
                                    }}
                                    value={tax_id}
                                >
                                    {taxesOptions?.map((tax, i) => (
                                        <option value={`${tax.id}`}>{tax.label}</option>
                                    ))}
                                </select>
                            </div>
                        </div>
                        <div className="content-item">
                            <div className="content-sub-item">
                                <CostInput
                                    props={{
                                        value: tax_value,
                                        isCostMethod: false,
                                        currency_type: currency_type,
                                        placeholder: 'Unit cost',
                                        setValue: (value) => {
                                            setTax_value(parseFloat(value) || 0)
                                        },
                                        isIcon: true,
                                        icon: '%',
                                        isLabel: true,
                                        isRequired: true,
                                        label: "Tax",
                                        id: "costcatalog-item-tax"
                                    }}
                                />
                            </div>
                            <div className="content-sub-item content-sub-label-item">
                                <div className="content-sub-label-content">
                                    <div className="label">Unit tax</div>
                                    <div className="value">{currency_type}{unti_tax}</div>
                                </div>
                            </div>

                        </div>

                        <div className="content-section-header">
                            <div className="label">Markup</div>
                            <div className="input">
                                <select
                                    name=""
                                    id=""
                                    onInput={(e) => {

                                        let item = markupsOptions.filter(itm => itm.id == e.target.value)[0]
                                        if (item) {
                                            setMarkup_value(parseFloat(item.value || 0))
                                            setMarkup_id(item.id)
                                            setMarkup_name(item.label)
                                            setMarkup_type(item.type)
                                        }
                                    }}
                                    value={markup_id}
                                >
                                    {markupsOptions.map((itm, i) => (
                                        <option value={`${itm.id}`}>{itm.label}</option>
                                    ))}
                                </select>
                            </div>
                        </div>
                        <div className="content-item">
                            <div className="content-sub-item">
                                <CostInput
                                    props={{
                                        value: markup_value,
                                        costMethodValue: markup_type,
                                        isCostMethod: true,
                                        currency_type: currency_type,
                                        placeholder: 'Unit cost',
                                        setValue: (value, type) => {
                                            setMarkup_value(parseFloat(value) || 0)
                                            if (type) setMarkup_type(type)
                                        },
                                        isIcon: true,
                                        icon: '%',
                                        isLabel: true,
                                        isRequired: true,
                                        label: "Markup",
                                        id: "costcatalog-item-markup"
                                    }}
                                />
                            </div>
                            <div className="content-sub-item content-sub-label-item">
                                <div className="content-sub-label-content">
                                    <div className="label">Unit markup</div>
                                    <div className="value">{currency_type}{unti_markup}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="content-section">
                        <div className="content-item">
                            <div id="costcatalog-description" className={`additional-item ${additionalNotes ? 'additional-item-active' : ''}`}>
                                <div className="head" onClick={(e) => setAdditionalNotes(!additionalNotes)}>
                                    <div className="icon" dangerouslySetInnerHTML={{ __html: Icons.general.description }}></div>
                                    <div className="label">Description</div>
                                </div>
                                <div className="item-expanded">
                                    <ReactQuill
                                        theme="snow"
                                        value={description}
                                        onChange={(value) => setDescription(value)}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <Popup_Footer props={AddBtnOption} />
            </form>
        </div>
    )

}

const AddScheduleRateItemPopup = ({ props }) => {

    const { existItemData, updateCallback } = props

    const { itemType, itemId, actionType } = useParams()

    const [isLoading, setIsLoading] = useState(false)
    const [warningAlert, setWarningAlert] = useState(false)
    const [warningAlertType, setWarningAlertType] = useState('warning')
    const [apiFailedMessage, setApiFailedMessage] = useState("Error in Server")

    const [currency_type, setCurrency_type] = useState('₹')

    const navigate = useNavigate()
    const store = useSelector((store) => store)
    const dispatch = useDispatch()
    const { updateState } = new UserAction

    const { isAdmin, menu_permission } = useMenuAuth({ menu: 'master_features--schedule_rates' })
    const location = useLocation()

    const [selected_view_settings_value, setSelected_view_settings_value] = useState('')
    const [has_view_access, setHas_view_access] = useState(false)
    const [has_add_access, setHas_add_access] = useState(false)
    const [has_edit_access, setHas_edit_access] = useState(false)
    const [has_delete_access, setHas_delete_access] = useState(false)

    useEffect(() => {

        if (isAdmin) {

            setHas_view_access(true)
            setHas_add_access(true)
            setHas_edit_access(true)
            setHas_delete_access(true)
        }

        else if (menu_permission) {

            if (
                !menu_permission._view
                &&
                (!menu_permission.selected_view_settings_value
                    || menu_permission.selected_view_settings_value == 'no_access')
            ) {
                navigate(`${ReverseProxy['proxyUrl']}/quick-menu`, { state: { from: location }, replace: true })
            }

            if (actionType == 'create' && menu_permission._create == '0') navigate(`${ReverseProxy['proxyUrl']}/builder/projects`, { state: { from: location }, replace: true })
            if (actionType == 'edit' && menu_permission._edit == '0') navigate(`${ReverseProxy['proxyUrl']}/builder/projects`, { state: { from: location }, replace: true })

            setHas_view_access(menu_permission._view == '1')
            setHas_add_access(menu_permission._create == '1')
            setHas_edit_access(menu_permission._edit == '1')
            setHas_delete_access(menu_permission._delete == '1')

            setSelected_view_settings_value(menu_permission.selected_view_settings_value)

        }

    }, [store])


    return (

        <>
            {itemType == 'group'
                ?
                actionType == 'add' ? <AddGroup props={{ updateCallback }} />
                    : actionType == 'edit' ? <AddGroup props={{ existItemData, updateCallback }} /> : ''
                :
                actionType == 'add' ? <AddItem props={{ updateCallback }} />
                    : actionType == 'edit' ? <AddItem props={{ existItemData, updateCallback }} /> : ''

            }
        </>

    )

}

export default AddScheduleRateItemPopup;