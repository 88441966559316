


import React, { useEffect, useState, useRef, useCallback, useMemo, StrictMode } from 'react'
import { Route, Routes, BrowserRouter, Link, useLocation, Navigate, Outlet, useNavigate, useParams } from 'react-router-dom';
import store from '../../redux/store'
import StoreHandler from '../../redux/StoreHandler'
import { useSelector, useDispatch } from 'react-redux'
import UserAction from '../../redux/action/userAction'

import ReverseProxy from '../../config/reverseProxy'
import ProjectsHandler from '../../Handlers/Projects/Projects';
import LabourManagementHandler from '../../Handlers/LabourManagement/LabourManagement';
import LabourProjectAttendanceHandler from '../../Handlers/LabourManagement/LabourProjectAttendance';
import ScheduleRatesHandler from '../../Handlers/ScheduleRates/ScheduleRates';

import Icons from '../../assets/Icons'
import Images from '../../assets/Images'
import { CostInput, TextInput, SelectInput, RadioInput, DateTimeInput, RadioOptionsInput } from '../../components/Inputs'
import Utils from '../../utils'
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

import AlertPopup from '../../components/AlertPopup'
import Loading from '../../components/Loading'
import NoDataFound from '../../components/NoDataFound';
import SystemToastPopup from '../../components/SystemToastPopup'

import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableFooter from '@mui/material/TableFooter';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';


import { useAuth, useMenuAuth } from '../../hooks/useAuth'

const UpdatePaymentStatusPopup = ({ props }) => {

    const { type, heading, close, callback, detials } = props

    const { projectId } = useParams()

    const store = useSelector((store) => store)
    const dispatch = useDispatch()
    const { updateState } = new UserAction

    const [currency_type, setCurrency_type] = useState('₹')

    const navigate = useNavigate()

    const [isLoading, setIsLoading] = useState(false)
    const [warningAlert, setWarningAlert] = useState(false)
    const [warningAlertType, setWarningAlertType] = useState('warning')
    const [apiFailedMessage, setApiFailedMessage] = useState("Error in Server")


    const [Status, setStatus] = useState(0)


    const HandleSubmit = () => {

        let labour = { ...detials }

        labour.status = Status || labour.status || 0
        labour.total_paid = Status || labour.status || 0

        if (Status == '2') {
            labour.total_balance = 0
            labour.total_paid = labour.total_topay

        }
        else if (Status == '3') {
            labour.total_balance = labour.total_topay
            labour.total_paid = 0

        }

        callback(labour)
        close(type)
    }

    const HandleCancel = async (e) => {
        close(type)
    }

    const Popup_Header = () => {

        return (
            <div className="side-popup-header">
                <div className="header-item-select">
                    <div className="header-item-select-content">
                        <div className="label">{heading}</div>
                    </div>
                </div>
                <div
                    className="header-item-close"
                    onClick={(e) => HandleCancel(e)}
                    dangerouslySetInnerHTML={{ __html: Icons.general.close }}
                ></div>
            </div>
        );
    };

    useEffect(() => {

        if (detials && detials.id) {

            setStatus(detials.status)
        }

    }, [])
    return (
        <>



            <div className="popup-container-main popup-container-center">
                <div className="popup-block-ui"></div>
                <div className="side-popup-container" >
                    <Popup_Header />
                    <div className="sidebar-popup-content">
                        <div className="content-section">
                            <div className="popup-detials-section ovetime-popup-detials-section">
                                <div className="popup-detials-header">
                                    <div className="left">
                                        <div className="desc">Please update payment stats for <strong>{detials.name}</strong></div>
                                    </div>
                                </div>
                                <div className="popup-detials-content">
                                    <div className="payment-card-section-inputs">
                                        <div
                                            className={`section-input ${Status == '2' ? 'section-input-paid' : ''} `}
                                            onClick={() => setStatus(2)}
                                        > {Status == '2' ? 'Payment done' : 'Mark as Paid'}</div>
                                        <div
                                            className={`section-input ${Status == '3' ? 'section-input-notpaid' : ''}`}
                                            onClick={() => setStatus(3)}
                                        >{Status == '3' ? 'Payment stopped' : 'Mark as Not Paid'} </div>
                                    </div>

                                </div>

                                <div className="popup-detials-buttons">
                                    <div
                                        className="detials-button remove-button"
                                        onClick={() => HandleCancel()}
                                    >
                                        <div
                                            className="icon"
                                            dangerouslySetInnerHTML={{ __html: Icons.general.reject }}
                                        ></div>
                                        <div className="label">Cancel</div>
                                    </div>
                                    <div
                                        className={`detials-button add-button add-button-active`}
                                        onClick={() => HandleSubmit()}
                                    >
                                        <div
                                            className="icon"
                                            dangerouslySetInnerHTML={{ __html: Icons.general.add_btn }}
                                        ></div>
                                        <div className="label">Save</div>
                                    </div>
                                </div>

                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </>
    )
}

const LaboursPayrolls = () => {

    const { projectId } = useParams()

    const store = useSelector((store) => store)
    const dispatch = useDispatch()
    const { updateState } = new UserAction

    const navigate = useNavigate()

    const labourProjectAttendanceHandler = new LabourProjectAttendanceHandler()


    const [isLoading, setIsLoading] = useState(false)
    const [warningAlert, setWarningAlert] = useState(false)
    const [warningAlertType, setWarningAlertType] = useState('warning')
    const [apiFailedMessage, setApiFailedMessage] = useState("Error in Server")

    const [AttendanceDetials, setAttendanceDetials] = useState([])
    const [VendorAttendanceDetials, setVendorAttendanceDetials] = useState([])

    const [_Date, setDate] = useState(Utils._getLocalDate() || '')
    const [StartDate, setStartDate] = useState(Utils._getLocalDate() || '')
    const [EndDate, setEndDate] = useState(Utils._getLocalDate() || '')

    const [PaymentDurationType, setPaymentDurationType] = useState('0')

    const [selectedResultSection, setSelectedResultSection] = useState('labour')

    const [currency_type, setCurrency_type] = useState('₹')

    const [totalDashboard, setTotalDashboard] = useState({
        labour: {
            total_topay: 0,
            total_paid: 0,
            total_balance: 0,
        },
        vendor: {
            total_topay: 0,
            total_paid: 0,
            total_balance: 0,
        },
    })

    const { isAdmin, menu_permission } = useMenuAuth({ menu: 'labour_managment--labour_payroll', feature_key: 'projectLabourManagement' })
    const location = useLocation()

    const [selected_view_settings_value, setSelected_view_settings_value] = useState('')
    const [has_view_access, setHas_view_access] = useState(false)
    const [has_add_access, setHas_add_access] = useState(false)
    const [has_edit_access, setHas_edit_access] = useState(false)
    const [has_delete_access, setHas_delete_access] = useState(false)

    useEffect(() => {

        if (isAdmin) {

            setHas_view_access(true)
            setHas_add_access(true)
            setHas_edit_access(true)
            setHas_delete_access(true)
        }

        else if (menu_permission) {

            if (
                !menu_permission._view
                &&
                (!menu_permission.selected_view_settings_value
                    || menu_permission.selected_view_settings_value == 'no_access')
            ) {
                navigate(`${ReverseProxy['proxyUrl']}/quick-menu`, { state: { from: location }, replace: true })
            }

            if (menu_permission._view == '0') navigate(`${ReverseProxy['proxyUrl']}/builder/projects`, { state: { from: location }, replace: true })

            setHas_view_access(menu_permission._view == '1')
            setHas_add_access(menu_permission._create == '1')
            setHas_edit_access(menu_permission._edit == '1')
            setHas_delete_access(menu_permission._delete == '1')

            setSelected_view_settings_value(menu_permission.selected_view_settings_value)

        }

    }, [store])

    const [hasChangesMade, setHasChangesMade] = useState(false)

    const HandleResultSection = (section) => {

        setSelectedResultSection(section)
    }

    const getProjectLabourPayablesDetials = async () => {


        let response = {
            success: false
        }

        if (PaymentDurationType == '0' && _Date) {

            response = await labourProjectAttendanceHandler.getLabourAttendanceHandler({
                date: _Date || Utils._getLocalDate(),
                projectId: projectId
            })
        }
        else if (PaymentDurationType == '1' && StartDate && EndDate) {

            response = await labourProjectAttendanceHandler.getLabourAttendanceHandler({
                projectId: projectId,
                startdate: StartDate,
                enddate: EndDate
            })
        }


        if (!response.success) {
            setWarningAlert(true)
            setWarningAlertType('error')
            setApiFailedMessage(`Error in getting labour attendance detials`)

            return
        }

        LoadAttendanceData(response.data || [])


    }




    const HandleCloseFlottingFooter = () => {
        setHasChangesMade(false)
    }

    const HandleSaveChanges = async (AttendanceDetials = [], VendorAttendanceDetials = []) => {

        let labour_attendance = []
        let vendor_attendance = []

        console.log(AttendanceDetials, 'AttendanceDetials');
        console.log(VendorAttendanceDetials, 'VendorAttendanceDetials');

        if (Array.isArray(AttendanceDetials)) {

            AttendanceDetials.forEach(attendance => {


                if (Array.isArray(attendance.rawdata)) {

                    attendance.rawdata.forEach(labour => {
                        labour_attendance.push(
                            {
                                ...labour,
                                payment_status: attendance.status == '2' ? '1' : attendance.status,
                                paid_amount: attendance.status == '2' ? labour.to_pay : 0,
                            }
                        )

                    })
                }

            })
        }
        if (Array.isArray(VendorAttendanceDetials)) {

            VendorAttendanceDetials.forEach(attendance => {


                if (Array.isArray(attendance.rawdata)) {

                    attendance.rawdata.forEach(labour => {
                        vendor_attendance.push(
                            {
                                ...labour,
                                payment_status: attendance.status == '2' ? '1' : attendance.status,
                                paid_amount: attendance.status == '2' ? labour.total_topay : 0,
                            }
                        )

                    })
                }

            })
        }
        // if (!labour_attendance.length && !labour_attendance.length) return

        let update_data = {
            projectId: projectId,
            date: _Date,
            labour_attendance: labour_attendance,
            vendor_attendance: vendor_attendance,
            payment_duration: PaymentDurationType
        }

        if (PaymentDurationType == '0') update_data['date'] = _Date
        else if (PaymentDurationType == '1') {
            update_data['startdate'] = StartDate
            update_data['enddate'] = EndDate
        }

        let response = await labourProjectAttendanceHandler.updateLabourAttendancePayrollHandler(update_data)


        if (response && response.success) {

            HandleCloseFlottingFooter()

        } else {
            setWarningAlert(true)
            setWarningAlertType('error')
            setApiFailedMessage(`Error in updating labour attendance detials`)
        }
    }

    const LoadAttendanceData = (attendances) => {

        if (!Array.isArray(attendances)) return
        let _labour_attendances = []
        let _vendor_attendances = []

        attendances.forEach((item, i) => {

            let { id, date: _date, labour_attendance, vendor_attendance } = item


            if (Array.isArray(labour_attendance)) {

                labour_attendance.filter(l => l.attendance && l.attendance != 'absent').forEach(labour => {

                    labour.date = _date

                    let labour_exist = _labour_attendances.findIndex(l => l.id == labour.id)

                    if (labour_exist != -1) {


                        if (labour.attendance == 'present') _labour_attendances[labour_exist].total_presents += 1
                        else if (labour.attendance == 'halfday') _labour_attendances[labour_exist].total_halfdays += 1

                        _labour_attendances[labour_exist].total_overtime_hours += parseFloat(labour.overtime_hours || 0)
                        _labour_attendances[labour_exist].total_overtime_salary += parseFloat(labour.overtime_salary_amount || 0)
                        _labour_attendances[labour_exist].total_topay += parseFloat(labour.to_pay || 0) + parseFloat(labour.overtime_salary_amount || 0)
                        _labour_attendances[labour_exist].total_paid += labour.payment_status == '1' ? parseFloat(labour.to_pay || 0) : 0
                        _labour_attendances[labour_exist].total_balance += labour.payment_status != '1' ? parseFloat(labour.to_pay || 0) : 0
                        _labour_attendances[labour_exist].rawdata.push(labour)

                    }
                    else {

                        _labour_attendances.push({
                            id: labour.id,
                            name: labour.name,
                            total_presents: labour.attendance == 'present' ? 1 : 0,
                            total_halfdays: labour.attendance == 'halfday' ? 1 : 0,

                            total_overtime_hours: parseFloat(labour.overtime_hours || 0),
                            total_overtime_salary: parseFloat(labour.overtime_salary_amount || 0),
                            total_topay: parseFloat(labour.to_pay || 0),
                            total_paid: labour.payment_status == '1' ? parseFloat(labour.to_pay || 0) : 0,
                            total_balance: labour.payment_status != '1' ? parseFloat(labour.to_pay || 0) : 0,

                            status: labour.payment_status == '1' ? 2 : parseInt(labour.payment_status),
                            rawdata: [labour]
                        })
                    }
                })

            }
            if (Array.isArray(vendor_attendance)) {

                vendor_attendance.forEach(vendor => {

                    vendor.date = _date

                    let vendor_exist = _vendor_attendances.findIndex(l => l.id == vendor.vendor_id)

                    if (vendor_exist != -1) {

                        _vendor_attendances[vendor_exist].total_workers += parseFloat(vendor.total_workers)
                        _vendor_attendances[vendor_exist].total_topay += parseFloat(vendor.total_topay)
                        _vendor_attendances[vendor_exist].total_paid += vendor.payment_status == '1' ? parseFloat(vendor.total_topay || 0) : 0
                        _vendor_attendances[vendor_exist].total_balance += vendor.payment_status != '1' ? parseFloat(vendor.total_topay || 0) : 0
                        _vendor_attendances[vendor_exist].rawdata.push(vendor)

                    }
                    else {

                        _vendor_attendances.push({
                            id: vendor.vendor_id,
                            name: vendor.vendor_name,
                            total_workers: parseFloat(vendor.total_workers),

                            total_topay: parseFloat(vendor.total_topay),
                            total_paid: vendor.payment_status == '1' ? parseFloat(vendor.total_topay || 0) : 0,
                            total_balance: vendor.payment_status != '1' ? parseFloat(vendor.total_topay || 0) : 0,

                            status: vendor.payment_status == '1' ? 2 : parseInt(vendor.payment_status),
                            rawdata: [vendor]
                        })
                    }
                })

            }


        })

        console.log(_labour_attendances, '_labour_attendances');
        console.log(_vendor_attendances, '_vendor_attendances');

        setAttendanceDetials(_labour_attendances)
        setVendorAttendanceDetials(_vendor_attendances)
    }


    const HandleFilerDate = async (type, start, end) => {

        let response = {
            success: false
        }

        if (type == '0' && start) {
            setDate(start)

            response = await labourProjectAttendanceHandler.getLabourAttendanceHandler({
                projectId: projectId,
                date: start
            })
        }
        else if (type == '1' && start && end) {
            setStartDate(start)
            setEndDate(end)

            response = await labourProjectAttendanceHandler.getLabourAttendanceHandler({
                projectId: projectId,
                startdate: start,
                enddate: end
            })
        }


        if (!response.success) return

        LoadAttendanceData(response.data || [])


    }

    const [tableSelectAll, setTableSelectAll] = useState(false)

    const [LabourTabelHeader, setLabourTabelHeader] = useState([
        {
            id: 'select',
            label: 'Select',
            minWidth: 30,
            disable: false,
        },
        {
            id: 'name',
            label: 'Labour Name',
            minWidth: 200,

        },
        {
            id: 'total_presents',
            label: 'Total presents',
            format: (value) => `${value || '--'}`
        },
        {
            id: 'total_halfdays',
            label: 'Total halfdays',
            format: (value) => `${value || '--'}`

        },
        {
            id: 'total_topay',
            label: 'Total Topay',
            format: (value) => `${currency_type || '₹'} ${value || '--'}`

        },
        {
            id: 'total_paid',
            label: 'Total paid',
            format: (value) => `${currency_type || '₹'} ${value || '--'}`

        },
        {
            id: 'total_balance',
            label: 'Total balance',
            format: (value) => `${currency_type || '₹'} ${value || '--'}`
        },

        {
            id: 'status',
            label: 'Status',
            format: (value) => {
                if (value == "0") return "No payment"
                else if (value == "1") return "Partially paid"
                else if (value == "2") return "Payment done"
                else if (value == "3") return "Payment stopped"
            },

        },
        {
            id: 'actions',
            label: 'Action',
        },

    ])
    const [VendorTabelHeader, setVendorTabelHeader] = useState([
        {
            id: 'select',
            label: 'Select',
            minWidth: 30,
            disable: false,
        },
        {
            id: 'name',
            label: 'Vendor Name',
            minWidth: 200,

        },
        {
            id: 'total_workers',
            label: 'Total workers',
            format: (value) => `${value || '--'}`

        },
        {
            id: 'total_topay',
            label: 'Total Topay',
            format: (value) => `${currency_type || '₹'} ${value || '--'}`

        },
        {
            id: 'total_paid',
            label: 'Total paid',
            format: (value) => `${currency_type || '₹'} ${value || '--'}`

        },
        {
            id: 'total_balance',
            label: 'Total balance',
            format: (value) => `${currency_type || '₹'} ${value || '--'}`
        },

        {
            id: 'status',
            label: 'Status',
            format: (value) => {
                if (value == "0") return "No payment"
                else if (value == "1") return "Partially paid"
                else if (value == "2") return "Payment done"
                else if (value == "3") return "Payment stopped"
            },

        },
        {
            id: 'actions',
            label: 'Action',
        },

    ])


    const HandleSelectAllEvent = (value) => {
        setTableSelectAll(value)

        let _Attendance = [...(selectedResultSection == 'labour' ? AttendanceDetials : VendorAttendanceDetials)]

        const selectItem = (items, value) => {

            items = items.map(item => {

                item.selected = value
                return item
            })

            return items
        }

        _Attendance = selectItem(_Attendance, value)

        if (selectedResultSection == 'labour') setAttendanceDetials(_Attendance)
        else if (selectedResultSection == 'vendor') setVendorAttendanceDetials(_Attendance)

    }

    const HandleSelectEvent = (id, value) => {

        let _Attendance = [...(selectedResultSection == 'labour' ? AttendanceDetials : VendorAttendanceDetials)]

        _Attendance = _Attendance.map(item => {

            if (item.id == id) item.selected = value
            return item
        })

        if (selectedResultSection == 'labour') setAttendanceDetials(_Attendance)
        else if (selectedResultSection == 'vendor') setVendorAttendanceDetials(_Attendance)

    }

    const [showStatusUpdatePopup, setShowStatusUpdatePopup] = useState(false)
    const [selectedLabourPayment, setSelectedLabourPayment] = useState(undefined)


    const HandleMarkAllAsPaid = () => {

        if (selectedResultSection == 'labour') {
            let _AttendanceDetials = [...AttendanceDetials].map(item => {

                item.selected = false
                item.status = 2
                item.total_paid = item.total_topay
                item.total_balance = 0
                return item

            })
            setAttendanceDetials(_AttendanceDetials)

            HandleSaveChanges(_AttendanceDetials, VendorAttendanceDetials)
        }
        else if (selectedResultSection == 'vendor') {

            let _VendorAttendanceDetials = [...VendorAttendanceDetials].map(item => {

                item.selected = false
                item.status = 2
                item.total_paid = item.total_topay
                item.total_balance = 0
                return item

            })


            setVendorAttendanceDetials(_VendorAttendanceDetials)
            HandleSaveChanges(AttendanceDetials, _VendorAttendanceDetials)
        }
        setTableSelectAll(false)
        setHasChangesMade(false)


    }
    const HandleUpdateStatus = (labour) => {
        if (!has_edit_access) return
        setShowStatusUpdatePopup(true)
        setSelectedLabourPayment(labour)

    }

    const HandleCloseUpdateStatus = () => {
        setShowStatusUpdatePopup(false)
        setSelectedLabourPayment(undefined)
    }


    const HandleOpenUpdateStatusCallback = (labour) => {

        if (!labour || !labour.id) return

        if (selectedResultSection == 'labour') {
            let _AttendanceDetials = [...AttendanceDetials]

            let labour_idx = _AttendanceDetials.findIndex(l => l.id == labour.id)

            if (labour_idx == -1) return
            _AttendanceDetials[labour_idx] = labour

            setAttendanceDetials(_AttendanceDetials)
            HandleSaveChanges(_AttendanceDetials, VendorAttendanceDetials)
        }
        else if (selectedResultSection == 'vendor') {
            let _VendorAttendanceDetials = [...VendorAttendanceDetials]

            let labour_idx = _VendorAttendanceDetials.findIndex(l => l.id == labour.id)

            if (labour_idx == -1) return
            _VendorAttendanceDetials[labour_idx] = labour

            setVendorAttendanceDetials(_VendorAttendanceDetials)
            HandleSaveChanges(AttendanceDetials, _VendorAttendanceDetials)
        }
    }


    useEffect(() => {
        getProjectLabourPayablesDetials()
    }, [])

    useEffect(() => {

        let labour_total_detials = [...AttendanceDetials].reduce((prev, item) => {

            return {
                total_topay: prev.total_topay + item.total_topay,
                total_paid: prev.total_paid + item.total_paid,
                total_balance: prev.total_balance + item.total_balance,

            }

        }, {
            total_topay: 0,
            total_paid: 0,
            total_balance: 0,
        })

        let vendor_total_detials = [...VendorAttendanceDetials].reduce((prev, item) => {

            return {
                total_topay: prev.total_topay + item.total_topay,
                total_paid: prev.total_paid + item.total_paid,
                total_balance: prev.total_balance + item.total_balance,

            }

        }, {
            total_topay: 0,
            total_paid: 0,
            total_balance: 0,
        })

        let _totalDashboard = {
            labour: {
                total_topay: labour_total_detials.total_topay,
                total_paid: labour_total_detials.total_paid,
                total_balance: labour_total_detials.total_balance,
            },
            vendor: {
                total_topay: vendor_total_detials.total_topay,
                total_paid: vendor_total_detials.total_paid,
                total_balance: vendor_total_detials.total_balance,
            },
        }

        setTotalDashboard(_totalDashboard)

        let is_labour_selected = [...AttendanceDetials].filter(l => l.selected == true).length > 0
        let is_vendorselected = [...VendorAttendanceDetials].filter(l => l.selected == true).length > 0

        if (is_labour_selected || is_vendorselected) setHasChangesMade(true)
        else setHasChangesMade(false)

    }, [AttendanceDetials, VendorAttendanceDetials])


    return (
        <>
            {isLoading ?
                <Loading
                    props={{
                        isMainLogo: false,
                        isLabel: true
                    }} />
                : null}

            {showStatusUpdatePopup ?
                <UpdatePaymentStatusPopup props={{
                    type: "create",
                    heading: "Update Payment Status",
                    callback: HandleOpenUpdateStatusCallback,
                    close: HandleCloseUpdateStatus,
                    detials: selectedLabourPayment || undefined
                }} />
                : ''}

            <div className="project-client_invoice-main project-project_estimate-main project-labour_attendance-main">

                {hasChangesMade ?
                    <div className="panel-flotting-footer">
                        <div className="flotting-footer-items">
                            <div className="flotting-footer-item">
                                <div
                                    className="icon"
                                    dangerouslySetInnerHTML={{ __html: Icons.general.close }}
                                    onClick={(e) => HandleCloseFlottingFooter()}
                                ></div>
                            </div>

                            <div
                                className="flotting-footer-item footer-button active-footer-button"
                                onClick={(e) => HandleMarkAllAsPaid()}
                            >
                                <div
                                    className="icon"
                                    dangerouslySetInnerHTML={{ __html: Icons.general.save }}

                                ></div>
                                <div className="label">Mark all as Paid</div>
                            </div>
                        </div>
                    </div>
                    : ''}

                <div className="proposals-panel-content">

                    <div className="panel-content-header">
                        <div className="title">Labour Payroll</div>
                        <div className="buttons">

                        </div>
                    </div>

                    <div className="report-result-sections labour-attendance-cards">

                        <div className="report-filter-main" style={{ width: '100%' }}>
                            <div className="filter-item">
                                <div className="value">

                                    <SelectInput
                                        props={{
                                            id: "attendance-payment_duration",
                                            value: PaymentDurationType,
                                            placeholder: '',
                                            options: [
                                                {
                                                    value: '0',
                                                    label: 'One Day'
                                                },
                                                {
                                                    value: '1',
                                                    label: 'Multi Days'
                                                },

                                            ],
                                            setValue: (value, label) => {
                                                setPaymentDurationType(value)
                                                if (value == '0') HandleFilerDate(value, _Date)
                                                else HandleFilerDate(value, StartDate, EndDate)
                                            },
                                            isIcon: false,
                                            isLabel: true,
                                            isRequired: true,
                                            label: "Payment duration",
                                        }}
                                    />

                                </div>
                            </div>

                            {PaymentDurationType == '0' ?

                                <div className="filter-item">
                                    <div className="value">

                                        <DateTimeInput
                                            props={{
                                                id: "attendance-date",
                                                value: _Date,
                                                placeholder: '',
                                                setValue: (value) => {

                                                    HandleFilerDate(PaymentDurationType, value)
                                                },
                                                isIcon: false,
                                                isLabel: true,
                                                icon: Icons.general.schedule,
                                                isRequired: true,
                                                label: "Date",
                                            }}
                                        />

                                    </div>
                                </div>
                                :
                                <>
                                    <div className="filter-item">
                                        <div className="value">

                                            <DateTimeInput
                                                props={{
                                                    id: "attendance-date",
                                                    value: StartDate,
                                                    placeholder: '',
                                                    setValue: (value) => {

                                                        HandleFilerDate(PaymentDurationType, value, EndDate)
                                                    },
                                                    isIcon: false,
                                                    isLabel: true,
                                                    icon: Icons.general.schedule,
                                                    isRequired: true,
                                                    label: "Start date",
                                                }}
                                            />

                                        </div>
                                    </div>
                                    <div className="filter-item">
                                        <div className="value">

                                            <DateTimeInput
                                                props={{
                                                    id: "attendance-date",
                                                    value: EndDate,
                                                    placeholder: '',
                                                    setValue: (value) => {

                                                        HandleFilerDate(PaymentDurationType, StartDate, value)
                                                    },
                                                    isIcon: false,
                                                    isLabel: true,
                                                    icon: Icons.general.schedule,
                                                    isRequired: true,
                                                    label: "End date",
                                                }}
                                            />

                                        </div>
                                    </div>
                                </>
                            }
                        </div>

                        <div className="report-result-sections-items">

                            <div
                                className={`report-result-section ${selectedResultSection == 'labour' ? 'report-result-section-active' : ''}`}
                                onClick={() => HandleResultSection('labour')}
                            >
                                <div className="icon" dangerouslySetInnerHTML={{ __html: Icons.general.manpower }}></div>
                                <div className="label">Labour Attendance</div>
                            </div>
                            <div
                                className={`report-result-section ${selectedResultSection == 'vendor' ? 'report-result-section-active' : ''}`}
                                onClick={() => HandleResultSection('vendor')}
                            >
                                <div className="icon" dangerouslySetInnerHTML={{ __html: Icons.general.users }}></div>
                                <div className="label">Vendor Attendance</div>
                            </div>

                        </div>

                        <div className="report-total-dashboard-main">
                            <div className={`report-total-section ${selectedResultSection == 'labour' ? 'report-total-section-active' : ''}`} style={{ height: 'max-content' }}>
                                <div className="report-total-header">
                                    <div className="header-title">Labour Dashboard</div>
                                </div>
                                <div className="report-total-content">
                                    <div className="total-content-item">
                                        <div className="label">Total To pay</div>
                                        <div className="value total-topay-value">{currency_type || '₹'} {totalDashboard.labour.total_topay || 0}</div>
                                    </div>
                                    <div className="total-content-item">
                                        <div className="label">Paid</div>
                                        <div className="value total-paid-value">{currency_type || '₹'} {totalDashboard.labour.total_paid || 0}</div>
                                    </div>
                                    <div className="total-content-item">
                                        <div className="label">Balance</div>
                                        <div className="value total-balance-value">{currency_type || '₹'} {totalDashboard.labour.total_balance || 0}</div>
                                    </div>
                                </div>

                            </div>
                            <div className={`report-total-section ${selectedResultSection == 'vendor' ? 'report-total-section-active' : ''}`} style={{ height: 'max-content' }}>
                                <div className="report-total-header">
                                    <div className="header-title">Vendor Dashboard</div>
                                </div>
                                <div className="report-total-content">
                                    <div className="total-content-item">
                                        <div className="label">Total To pay</div>
                                        <div className="value total-topay-value">{currency_type || '₹'} {totalDashboard.vendor.total_topay || 0}</div>
                                    </div>
                                    <div className="total-content-item">
                                        <div className="label">Paid</div>
                                        <div className="value total-paid-value">{currency_type || '₹'} {totalDashboard.vendor.total_paid || 0}</div>
                                    </div>
                                    <div className="total-content-item">
                                        <div className="label">Balance</div>
                                        <div className="value total-balance-value">{currency_type || '₹'} {totalDashboard.vendor.total_balance || 0}</div>
                                    </div>
                                </div>

                            </div>

                        </div>

                    </div>

                    <div className="panel-content-sections panel-content-tabel-section panel-content-attendance-cards-main">
                        {(selectedResultSection == 'labour' ? AttendanceDetials : VendorAttendanceDetials).filter(p => !p.disable).filter(p => !p.disable).length ?
                            <Paper style={{ width: '100%', height: '100%', boxShadow: 'none', overflow: 'hidden' }}>
                                <TableContainer style={{ height: '93%', boxShadow: 'none !important', }}>
                                    <Table stickyHeader>
                                        <TableHead
                                            className='tabel-header'
                                        >
                                            <TableRow>
                                                {(selectedResultSection == 'labour' ? LabourTabelHeader : VendorTabelHeader).filter(th => !th.disable).map((column) => (
                                                    <TableCell
                                                        key={column.id}
                                                        align={column.align}
                                                        style={{ minWidth: column.minWidth }}
                                                        className='tabel-header-items'
                                                    >
                                                        {column.id == 'select' ?

                                                            <RadioInput props={{
                                                                value: tableSelectAll || false,
                                                                isIcon: false,
                                                                icon: "",
                                                                inputType: "checkbox",
                                                                name: "table-default-radio",
                                                                setValue: (value) => HandleSelectAllEvent(value)
                                                            }} />
                                                            :
                                                            column.label}
                                                    </TableCell>
                                                ))}
                                            </TableRow>
                                        </TableHead>
                                        <TableBody
                                            className='tabel-body'
                                        >
                                            {(selectedResultSection == 'labour' ? AttendanceDetials : VendorAttendanceDetials).filter(p => !p.disable)
                                                .map((row) => {
                                                    return (
                                                        <TableRow hover role="checkbox" tabIndex={-1} key={row.id}>
                                                            {(selectedResultSection == 'labour' ? LabourTabelHeader : VendorTabelHeader).filter(th => !th.disable).map((column) => {
                                                                const value = row[column.id];
                                                                return (
                                                                    column.id == 'select' ?


                                                                        <TableCell
                                                                            key={column.id}
                                                                            align={column.align}
                                                                            className='estimate-table-row-item'

                                                                        >
                                                                            <RadioInput props={{
                                                                                value: row.selected || false,
                                                                                isIcon: false,
                                                                                icon: "",
                                                                                inputType: "checkbox",
                                                                                name: "table-default-radio",
                                                                                setValue: (value) => HandleSelectEvent(row.id, value)
                                                                            }} />

                                                                        </TableCell>

                                                                        :
                                                                        <TableCell
                                                                            key={column.id}
                                                                            align={column.align}
                                                                            onClick={(e) => {
                                                                            }}
                                                                        >
                                                                            {
                                                                                column.id == 'status' ?

                                                                                    <div
                                                                                        className={`status-background-${value}`}
                                                                                    >
                                                                                        <div className="tabel-status"> {column.format ? column.format(value) : value}</div>

                                                                                    </div>
                                                                                    : column.id == 'actions' ?

                                                                                        <div
                                                                                            className="tabel-action-main"
                                                                                        >
                                                                                            <div
                                                                                                className="action-icon total-topay-value"
                                                                                                onClick={(e) => HandleUpdateStatus(row)}
                                                                                            >Update</div>
                                                                                        </div>
                                                                                        : column.format ? column.format(value) : value
                                                                            }
                                                                        </TableCell>
                                                                );
                                                            })}
                                                        </TableRow>
                                                    );
                                                })}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Paper>
                            : <NoDataFound label={'No Payables Found'} />}
                    </div>
                </div>

            </div>
        </>
    )
}

export default LaboursPayrolls;