
import proxyConfig from '../../config/reverseProxy'
import HeaderConfig from '../../config/header'

class Bills {

    constructor() {

    }


    async getNextBillsIDHandler(params) {

        try {

            let response = await fetch(proxyConfig['serverBaseUrl'] + '/builder/bills/get-next-billId', {
                method: 'POST',
                headers: HeaderConfig.getHeader({ method: 'POST', isToken: true }),
                body: JSON.stringify(params)
            })

            response = await response.json()

            if (response) return response
        } catch {
            return { success: false }
        }


    }
    async getBasicBillssHandler(params) {

        try {

            let response = await fetch(proxyConfig['serverBaseUrl'] + '/builder/bills/get-basic-bills', {
                method: 'POST',
                headers: HeaderConfig.getHeader({ method: 'POST', isToken: true }),
                body: JSON.stringify(params)
            })

            response = await response.json()

            if (response) return response
        } catch {
            return { success: false }
        }


    }
    async getBillssHandler(params) {

        try {

            let response = await fetch(proxyConfig['serverBaseUrl'] + '/builder/bills/get-bills', {
                method: 'POST',
                headers: HeaderConfig.getHeader({ method: 'POST', isToken: true }),
                body: JSON.stringify(params)
            })

            response = await response.json()

            if (response) return response
        } catch {
            return { success: false }
        }


    }
    async createBillHandler(params) {

        try {

            let response = await fetch(proxyConfig['serverBaseUrl'] + '/builder/bills/create-bills', {
                method: 'POST',
                headers: HeaderConfig.getHeader({ method: 'POST', isToken: true }),
                body: JSON.stringify(params)
            })

            response = await response.json()

            if (response) return response
        } catch {
            return { success: false }
        }


    }
    async updateBillHandler(params) {

        try {

            let response = await fetch(proxyConfig['serverBaseUrl'] + '/builder/bills/update-bills', {
                method: 'POST',
                headers: HeaderConfig.getHeader({ method: 'POST', isToken: true }),
                body: JSON.stringify(params)
            })

            response = await response.json()

            if (response) return response
        } catch {
            return { success: false }
        }


    }
    async deleteBillHandler(params) {

        try {

            let response = await fetch(proxyConfig['serverBaseUrl'] + '/builder/bills/delete-bills', {
                method: 'POST',
                headers: HeaderConfig.getHeader({ method: 'POST', isToken: true }),
                body: JSON.stringify(params)
            })

            response = await response.json()

            if (response) return response
        } catch {
            return { success: false }
        }


    }

}

export default Bills;