

import React, { useEffect, useState, useRef, useCallback, useMemo, StrictMode } from 'react'
import { Route, Routes, BrowserRouter, Link, useLocation, Navigate, Outlet, useNavigate, useParams } from 'react-router-dom';
import store from '../../redux/store'
import StoreHandler from '../../redux/StoreHandler'
import { useSelector, useDispatch } from 'react-redux'
import UserAction from '../../redux/action/userAction'

import ReverseProxy from '../../config/reverseProxy'
import FinanceHandler from '../../Handlers/Finance/Finance';
import Icons from '../../assets/Icons'
import Images from '../../assets/Images'
import { CostInput, TextInput, SearcBarInput, SelectInput, RadioInput } from '../../components/Inputs'
import Utils from '../../utils'
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

import AlertPopup from '../../components/AlertPopup'
import Loading from '../../components/Loading'
import NoDataFound from '../../components/NoDataFound';
import SystemToastPopup from '../../components/SystemToastPopup'

import Chart from "react-apexcharts";

import { useAuth, useMenuAuth } from '../../hooks/useAuth'

const FinanceSummary = () => {

    const store = useSelector((store) => store)
    const dispatch = useDispatch()
    const { updateState } = new UserAction

    const { projectId } = useParams();

    const navigate = useNavigate()

    const financeHandler = new FinanceHandler()

    const [currency_type, setCurrency_type] = useState('₹')

    const storeHandler = new StoreHandler({
        dataStack: ['company'],
        store: store,
        dispatch: dispatch,
        updateState: updateState,
    })

    const LoadStoreData = async () => {
        setIsLoading(true)
        let resolved = await storeHandler.init()
        setIsLoading(false)
    }

    const { isAdmin, menu_permission } = useMenuAuth({ menu: 'finance--finance_dashoard', feature_key: 'finance_dashoard' })
    const location = useLocation()

    const [selected_view_settings_value, setSelected_view_settings_value] = useState('')
    const [has_view_access, setHas_view_access] = useState(false)
    const [has_add_access, setHas_add_access] = useState(false)
    const [has_edit_access, setHas_edit_access] = useState(false)
    const [has_delete_access, setHas_delete_access] = useState(false)

    useEffect(() => {

        if (isAdmin) {

            setHas_view_access(true)
            setHas_add_access(true)
            setHas_edit_access(true)
            setHas_delete_access(true)
        }

        else if (menu_permission) {

            if (
                !menu_permission._view
                &&
                (!menu_permission.selected_view_settings_value
                    || menu_permission.selected_view_settings_value == 'no_access')
            ) {
                navigate(`${ReverseProxy['proxyUrl']}/quick-menu`, { state: { from: location }, replace: true })
            }

            if (menu_permission._view == '0') navigate(`${ReverseProxy['proxyUrl']}/builder/projects`, { state: { from: location }, replace: true })

            setHas_view_access(menu_permission._view == '1')
            setHas_add_access(menu_permission._create == '1')
            setHas_edit_access(menu_permission._edit == '1')
            setHas_delete_access(menu_permission._delete == '1')

            setSelected_view_settings_value(menu_permission.selected_view_settings_value)

        }

    }, [store])


    const [isLoading, setIsLoading] = useState(false)
    const [warningAlert, setWarningAlert] = useState(false)
    const [warningAlertType, setWarningAlertType] = useState('warning')
    const [apiFailedMessage, setApiFailedMessage] = useState("Error in Server")

    const [DashboardDetails, setDashboardDetails] = useState({
        total_estimated_amount: 0,
        total_expense_amount: 0,
        pending_expense_amount: 0,
        total_estimated_markup: 0,
        total_actual_markup: 0,

        total_labour_estimated_amount: 0,
        total_labour_expense_amount: 0,
        total_labour_paid_amount: 0,
        total_labour_pending_amount: 0,

        total_material_estimated_amount: 0,
        total_material_expense_amount: 0,
        total_material_paid_amount: 0,
        total_material_pending_amount: 0,

        total_client_payment_amount: 0,
        total_client_payment_paid_amount: 0,
        total_client_payment_pending_amount: 0,

        labour_expense_list: [],
        material_expense_list: [],
        expense_list: [],
        client_payment_list: [],
        overdue_payment_list: [],
        cash_required_list: []
    })


    const [TopCards, setTopCards] = useState([
        {
            id: 'lm-smart-top-cards-total_estimated',
            label: 'Total Estimated',
            value: "₹0",
            icon: Icons.general.estimate,
            unit_name: '',
            link: 'estimate',
            style: {
                color: '#0065ff',
                bg_linear_colors: {
                    start: '#e5efff',
                    end: '#e5f9ff'
                },
                border_color: '#c6d4dc',
            },
        },
        {
            id: 'lm-smart-top-cards-actual_amount',
            label: 'Actual Expense',
            value: "₹0",
            icon: Icons.general.bills,
            unit_name: '',
            link: 'bills',
            style: {
                color: '#ff5b5b',
                bg_linear_colors: {
                    start: '#ffeded',
                    end: '#fff9f9'
                },
                border_color: '#dcd1d1',
            },
        },
        {
            id: 'lm-smart-top-cards-pending_payable',
            label: 'Pending Payables',
            value: "₹0",
            icon: Icons.general.pending_payable,
            unit_name: '',
            link: 'pending_payable',
            style: {
                color: '#fb993d',
                bg_linear_colors: {
                    start: '#fff4ea',
                    end: '#fffdf4'
                },
                border_color: '#dcd6cd',
            },
        },
    ])
    const HandleNavigation = (link) => {

        let url = `${ReverseProxy['proxyUrl']}/builder/projects/${projectId}/${link}`
        return navigate(url)
    }

    // Budget Breakdown
    const [BudgetBreakdown, setBudgetBreakdown] = useState([
        {
            label: 'Labour Cost',
            value: 0,
            style: {
                color: '#c95252',
                bg_color: '#ffe5e5',
                value_bg_color: '#f7cfcf',
                border_color: '#dd8282',
            }
        },
        {
            label: 'Material Cost',
            value: 0,
            style: {
                color: '#005ab4',
                bg_color: '#e5eef7',
                value_bg_color: '#b6daff',
                border_color: '#5d9ad8',
            }
        },
        {
            label: 'Petty Cash',
            value: 0,
            style: {
                color: '#d2a800',
                bg_color: '#fff1dc',
                value_bg_color: '#fff4c8',
                border_color: '#cfc497',
            }
        }
    ])
    const [BudgetBreakdownChart, setBudgetBreakdownChart] = useState({
        series: BudgetBreakdown.map(item => item.value),
        options: {
            chart: {
                type: 'pie',
                toolbar: {
                    show: false
                }
            },
            labels: BudgetBreakdown.map(item => item.label),
            colors: BudgetBreakdown.map(item => item.style.color),

            dataLabels: {
                enabled: false
            },
            legend: {
                show: false
            },
            tooltip: {
                y: {
                    formatter: function (val) {
                        return `${currency_type || '₹'}` + Utils.shorternNumber(val)
                    }
                }
            }
        },
    })

    // Cashflow
    const [ExpenseCashFlow, setExpenseCashFlow] = useState([])
    const [ClientPaymentsCashFlow, setClientPaymentsCashFlow] = useState([])
    const [CashFlowChart, setCashFlowChart] = useState({
        series: [
            {
                name: 'Actual Expense',
                data: ExpenseCashFlow.map(item => {
                    return {
                        x: new Date(item.date).getTime(),
                        y: item.amount
                    }
                }),
                color: '#f86624'
            },
            {
                name: 'Client Payments',
                data: ClientPaymentsCashFlow.map(item => {
                    return {
                        x: new Date(item.date).getTime(),
                        y: item.amount
                    }
                }),
                color: '#449dd1'
            }
        ],
        options: {
            chart: {
                type: 'area',
                toolbar: {
                    show: false
                }
            },
            dataLabels: {
                enabled: false
            },
            stroke: {
                curve: 'straight'
            },

            markers: {
                size: 5,
                hover: {
                    size: 9
                }
            },
            toolbar: {
                show: false
            },

            tooltip: {
                y: {
                    formatter: function (val) {
                        return `${currency_type || '₹'}` + Utils.shorternNumber(val)
                    }
                }
            },

            theme: {
                palette: 'palette6'
            },
            xaxis: {
                type: 'datetime',
            },
            yaxis: {
                title: {
                    text: 'Amount (₹)'
                }
            }
        },
    })

    // Cash Required
    const [CashFlowRquired, setCashFlowRquired] = useState({
        total: 0,
        start_date: '',
        end_date: '',
        breakdown: []
    })

    const [DurationFilterOptions, setDurationFilterOptions] = useState([
        {
            label: 'Weekly',
            value: 'week'
        },
        {
            label: 'Monthly',
            value: 'month'
        },
        {
            label: 'Quarterly',
            value: 'quarter'
        },
        {
            label: 'Yearly',
            value: 'year'
        },
        {
            label: 'Custom',
            value: 'custom'
        }
    ])

    const [CashFlowRequiredDuration, setCashFlowRequiredDuration] = useState('week')
    const HandleCashFlowRequiredDuration = (duration) => {
        setCashFlowRequiredDuration(duration)
    }

    // OverDue Payments
    const [OverDuePayments, setOverDuePayments] = useState({
        total: 0,
        start_date: '',
        end_date: '',
        breakdown: []
    })
    const [OverDuePaymentsDuration, setOverDuePaymentsDuration] = useState('week')
    const HandleOverDuePaymentsDuration = (duration) => {
        setOverDuePaymentsDuration(duration)
    }

    // Actual Vs Estimated
    const [ActualVsEstimated, setActualVsEstimated] = useState([
        {
            label: 'Labour Cost',
            estimated: 0,
            actual: 0,
            paid: 0,
            pending: 0

        },
        {
            label: 'Material Cost',
            estimated: 0,
            actual: 0,
            paid: 0,
            pending: 0

        },
        {
            label: 'Client Payments',
            estimated: 0,
            actual: 0,
            paid: 0,
            pending: 0
        },
    ])

    const [ActualVsEstimatedChart, setActualVsEstimatedChart] = useState({
        series: [
            {
                name: 'Estimated',
                data: ActualVsEstimated.map(item => item.estimated),
                'color': '#857dfc',
            },
            {
                name: 'Actual',
                data: ActualVsEstimated.map(item => item.actual),
                'color': '#0060df'
            },
            {
                name: 'Paid',
                data: ActualVsEstimated.map(item => item.paid),
                'color': '#3fc467'
            },
            {
                name: 'Pending',
                data: ActualVsEstimated.map(item => item.pending),
                'color': '#ffa726'
            },

        ],
        options: {
            chart: {
                type: 'bar',
                height: 350,
                toolbar: {
                    show: false
                }
            },
            plotOptions: {
                bar: {
                    horizontal: false,
                    columnWidth: '40%',
                    endingShape: 'rounded'
                },
            },
            dataLabels: {
                enabled: false
            },
            stroke: {
                show: true,
                width: 2,
                colors: ['transparent']
            },
            xaxis: {
                categories: ActualVsEstimated.map(item => item.label),
            },
            yaxis: {
                title: {
                    text: `${currency_type || '₹'} (thousands)`
                }
            },
            fill: {
                opacity: 1,
            },
            tooltip: {
                y: {
                    formatter: function (val) {
                        return `${currency_type || '₹'}` + Utils.shorternNumber(val)
                    }
                }
            }
        },
    })

    // Revenue Forcast
    const [RevenueForcast, setRevenueForcast] = useState([
        {
            label: 'Total Expense',
            amount: 0,
            color: '#f86624',
            bg_color: '#ffe5e5',
            width: '0%',
            type: 'amount'
        },
        {
            label: 'Client Payments',
            amount: 0,
            color: '#449dd1',
            bg_color: '#e5eef7',
            width: '0%',
            type: 'amount'
        },
        {
            label: 'Total Margin',
            amount: 0,
            color: '#3fc467',
            bg_color: '#e2f7e5',
            width: '0%',
            type: 'amount'
        },
        {
            label: 'Margin(%)',
            amount: 0,
            color: '#ffa726',
            bg_color: '#fff7e5',
            width: '0%',
            type: 'percentage'
        }
    ])

    const Setup_Dashboard_Details = (dashboard_details) => {

        console.log(dashboard_details, 'dashboard_details');

        // setup top cards 
        {
            let _top_cards = [...TopCards]
            _top_cards[0].value = `${currency_type || '₹'} ${Utils.shorternNumber(dashboard_details.total_estimated_amount)}`
            _top_cards[1].value = `${currency_type || '₹'} ${Utils.shorternNumber(dashboard_details.total_expense_amount)}`
            _top_cards[2].value = `${currency_type || '₹'} ${Utils.shorternNumber(dashboard_details.pending_expense_amount)}`
            setTopCards(_top_cards)

            // Budget breakdown
            let _BudgetBreakdown = [...BudgetBreakdown]
            _BudgetBreakdown[0].value = dashboard_details.total_labour_estimated_amount
            _BudgetBreakdown[1].value = dashboard_details.total_material_estimated_amount

            let _BudgetBreakdownChart = { ...BudgetBreakdownChart }
            _BudgetBreakdownChart.series = _BudgetBreakdown.map(item => item.value)

            setBudgetBreakdown(_BudgetBreakdown)
            setBudgetBreakdownChart(_BudgetBreakdownChart)
        }

        //  Cashflow
        {
            let _expense_list = []
            dashboard_details.expense_list.forEach((item, index) => {


                let expense_exist_idx = _expense_list.findIndex(expense => expense.date == item.date)

                if (expense_exist_idx > -1) {
                    _expense_list[expense_exist_idx].amount += item.total_amount
                    _expense_list[expense_exist_idx].breakdown.push(item)
                }
                else {
                    _expense_list.push({
                        date: item.date,
                        amount: item.total_amount,
                        breakdown: [item]
                    })
                }
            })

            let _client_payment_list = []
            dashboard_details.client_payment_list.forEach((item, index) => {
                if (item.paid_amount <= 0) return
                let client_payment_exist_idx = _client_payment_list.findIndex(client_payment => client_payment.date == item.date)

                if (client_payment_exist_idx > -1) {
                    _client_payment_list[client_payment_exist_idx].amount += item.paid_amount
                    _client_payment_list[client_payment_exist_idx].breakdown.push(item)
                }
                else {
                    _client_payment_list.push({
                        date: item.date,
                        amount: item.paid_amount,
                        breakdown: [item]
                    })
                }
            })

            let _CashFlowChart = { ...CashFlowChart }
            _CashFlowChart.series = [
                {
                    name: 'Actual Expense',
                    data: _expense_list.map(item => {
                        return {
                            x: new Date(item.date).getTime(),
                            y: item.amount
                        }
                    }),
                    color: '#f86624'
                },
                {
                    name: 'Client Payments',
                    data: _client_payment_list.map(item => {
                        return {
                            x: new Date(item.date).getTime(),
                            y: item.amount
                        }
                    }),
                    color: '#449dd1'
                }
            ]

            setExpenseCashFlow(_expense_list)
            setClientPaymentsCashFlow(_client_payment_list)
            setCashFlowChart(_CashFlowChart)
        }

        // Cash Required
        {

            let _cash_required_list = {
                total: dashboard_details.cash_required_list.total ? `${currency_type || '₹'}${Utils.shorternNumber(dashboard_details.cash_required_list.total)}` : 'NIL',
                start_date: dashboard_details.cash_required_list.start_date,
                end_date: dashboard_details.cash_required_list.end_date,
                breakdown: []
            }
            dashboard_details.cash_required_list.breakdown.forEach((item, index) => {
                _cash_required_list.breakdown.push({
                    label: item.name,
                    type: item.type,
                    due_date: item.due_date,
                    value: `${currency_type || '₹'}${Utils.shorternNumber(item.amount)}`,
                })
            })
            console.log(_cash_required_list, '_cash_required_list');

            setCashFlowRquired(_cash_required_list)
        }

        // Revenue Forcast
        {
            let _RevenueForcast = [...RevenueForcast]
            let total_expense_amount = dashboard_details.total_expense_amount
            let total_client_payment_amount = dashboard_details.total_client_payment_amount
            let total_actual_markup = dashboard_details.total_actual_markup

            let max_reveue_width = Math.max(total_expense_amount, total_client_payment_amount)

            _RevenueForcast[0].amount = Utils.shorternNumber(total_expense_amount)
            _RevenueForcast[1].amount = Utils.shorternNumber(total_client_payment_amount)
            _RevenueForcast[2].amount = Utils.shorternNumber(total_actual_markup)

            _RevenueForcast[0].width = `${(total_expense_amount / max_reveue_width) * 100}%`
            _RevenueForcast[1].width = `${(total_client_payment_amount / max_reveue_width) * 100}%`
            _RevenueForcast[2].width = `${(total_actual_markup / max_reveue_width) * 100}%`

            // setup revenue margin percentage
            let revenue_margin_percentage = 0
            if (total_expense_amount > 0) {
                revenue_margin_percentage = ((total_actual_markup / total_expense_amount) * 100).toFixed(2)
            }
            _RevenueForcast[3].amount = parseFloat(revenue_margin_percentage).toFixed(2)
            _RevenueForcast[3].width = `${revenue_margin_percentage}%`

            setRevenueForcast(_RevenueForcast)
        }

        // Overdue Payments
        {
            let _overdue_payment_list = {
                total: dashboard_details.overdue_payment_list.total ? `${currency_type || '₹'}${Utils.shorternNumber(dashboard_details.overdue_payment_list.total)}` : 'NIL',
                start_date: dashboard_details.overdue_payment_list.start_date,
                end_date: dashboard_details.overdue_payment_list.end_date,
                breakdown: []
            }
            dashboard_details.overdue_payment_list.breakdown.forEach((item, index) => {
                _overdue_payment_list.breakdown.push({
                    label: item.name,
                    type: item.type,
                    overdue_days: item.overdue_days || 0,
                    value: `${currency_type || '₹'}${Utils.shorternNumber(item.pending_amount)}`,
                })
            })
            setOverDuePayments(_overdue_payment_list)
        }

        // Estimated vs Actual Costs
        {
            let _ActualVsEstimated = [...ActualVsEstimated]

            // labour cost
            _ActualVsEstimated[0].estimated = dashboard_details.total_labour_estimated_amount
            _ActualVsEstimated[0].actual = dashboard_details.total_labour_expense_amount
            _ActualVsEstimated[0].paid = dashboard_details.total_labour_paid_amount
            _ActualVsEstimated[0].pending = dashboard_details.total_labour_pending_amount

            // material cost
            _ActualVsEstimated[1].estimated = dashboard_details.total_material_estimated_amount
            _ActualVsEstimated[1].actual = dashboard_details.total_material_expense_amount
            _ActualVsEstimated[1].paid = dashboard_details.total_material_paid_amount
            _ActualVsEstimated[1].pending = dashboard_details.total_material_pending_amount

            // client payments
            _ActualVsEstimated[2].actual = dashboard_details.total_client_payment_paid_amount
            _ActualVsEstimated[2].paid = dashboard_details.total_client_payment_paid_amount
            _ActualVsEstimated[2].pending = dashboard_details.total_client_payment_pending_amount


            let _ActualVsEstimatedChart = {
                series: [
                    {
                        name: 'Estimated',
                        data: _ActualVsEstimated.map(item => item.estimated),
                        'color': '#857dfc',
                    },
                    {
                        name: 'Actual',
                        data: _ActualVsEstimated.map(item => item.actual),
                        'color': '#0060df'
                    },
                    {
                        name: 'Paid',
                        data: _ActualVsEstimated.map(item => item.paid),
                        'color': '#3fc467'
                    },
                    {
                        name: 'Pending',
                        data: _ActualVsEstimated.map(item => item.pending),
                        'color': '#ffa726'
                    },

                ],
                options: { ...ActualVsEstimatedChart.options }
            }


            console.log(_ActualVsEstimated, '_ActualVsEstimated');


            setActualVsEstimated(_ActualVsEstimated)
            setActualVsEstimatedChart(_ActualVsEstimatedChart)

        }

    }

    const LoadFinanceDashboard = async () => {

        let filters = {
            projectId: projectId
        }

        setIsLoading(true)
        let response = await financeHandler.getFinanceDashboardHandler(filters)

        setIsLoading(false)
        if (response.success) {

            let dashboard_details = response.data

            let _DashboardDetails = { ...DashboardDetails }
            Setup_Dashboard_Details(dashboard_details)
            setDashboardDetails(dashboard_details || _DashboardDetails)

        }
        else {
            setWarningAlert(true)
            setWarningAlertType('error')
            setApiFailedMessage('Error in Finance Dashboard Data')
        }
    }

    useEffect(() => {
        LoadFinanceDashboard()

    }, [])

    return (
        <>

            {isLoading ?
                <Loading
                    props={{
                        isMainLogo: false,
                        isLabel: true
                    }} />
                : null}
            {warningAlert ?

                <SystemToastPopup
                    props={{
                        type: warningAlertType,
                        message: apiFailedMessage || "Error in Finance Dashboard",
                        callback: (confirmation) => setWarningAlert(false)
                    }} />

                : null}

            <div className="finance-dashboard-main">
                <div className="fd-column-sections">
                    <div className="smart-top-cards-main">
                        <div className="smart-top-cards-items">
                            {
                                TopCards.map((item, index) => (

                                    <div
                                        className="smart-top-card"
                                        key={item.id}
                                        style={{
                                            color: item.style.color,
                                            borderColor: item.style.border_color,
                                            backgroundImage: `linear-gradient(to right, ${item.style.bg_linear_colors.start}, ${item.style.bg_linear_colors.end})`
                                        }}
                                        onClick={() => HandleNavigation(item.link)}
                                    >

                                        <div className="card-detials">
                                            <div className="card-label">{item.label}</div>
                                            <div className="card-value">{item.unit_name}{item.value}</div>
                                        </div>
                                        <div
                                            className="card-icon"
                                            style={{ fill: item.style.color }}
                                            dangerouslySetInnerHTML={{ __html: item.icon || Icons.general.invoice_total }}
                                        ></div>

                                    </div>


                                ))
                            }


                        </div>

                    </div>
                    <div className="fd-row-sections">
                        <div className="fd-section fd-section-medium fd-budget-section">
                            <div className="fd-section-header">
                                <div className="title">Budget Breakdown</div>
                            </div>
                            <div className="fd-section-content">
                                <div className="fd-section-chart">
                                    <Chart
                                        type="pie"
                                        style={{
                                            width: '100%',
                                            height: '100%',
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center'
                                        }}
                                        height={'80%'}
                                        options={BudgetBreakdownChart.options}
                                        series={BudgetBreakdownChart.series}

                                    />
                                </div>
                                <div className="fd-section-card-details">
                                    <div className="fd-card-details-items">
                                        {BudgetBreakdown.map((item, index) => (
                                            <div
                                                className="fd-card-details-item"
                                                key={`fd-card-details-item-${index}`}
                                                style={{
                                                    background: item.style.bg_color,
                                                    color: item.style.color,
                                                    borderColor: item.style.border_color
                                                }}
                                            >
                                                <div className="item-label">{item.label}</div>
                                                <div className="item-value" style={{ background: item.style.value_bg_color }}>{currency_type || '₹'}{Utils.shorternNumber(item.value)}</div>
                                            </div>
                                        ))}


                                    </div>
                                </div>
                            </div>

                        </div>
                        <div className="fd-section fd-section-mid fd-cashflow-section">
                            <div className="fd-section-header">
                                <div className="title">Cashflow</div>
                            </div>
                            <div className="fd-section-chart">
                                <Chart
                                    type="area"
                                    style={{ width: '100%', height: '100%' }}
                                    height='100%'
                                    options={CashFlowChart.options}
                                    series={CashFlowChart.series}

                                />
                            </div>

                        </div>
                        <div className="fd-section fd-section-medium fd-revenue-section">
                            <div className="fd-section-header">
                                <div className="title">Revenue Forcast</div>
                            </div>
                            <div className="fd-section-revenue-main">
                                <div className="fd-revenue-bars">
                                    {RevenueForcast.map((item, index) => (
                                        <div
                                            className="revenue-bar-item"
                                            key={`revenue-bar-item-${index}`}
                                            title={`${item.label} - ${item.type == 'amount' ? '₹' : ''}${item.amount}${item.type == 'percentage' ? '%' : ''}`}
                                            style={{ width: item.width, background: item.color }}
                                        ></div>
                                    ))}
                                </div>
                                <div className="fd-revenue-details">
                                    {RevenueForcast.map((item, index) => (
                                        <div
                                            className="revenue-details-item"
                                            key={`revenue-details-item-${index}`}
                                        >
                                            <div className="item-left">
                                                <div className="item-icon" style={{ background: item.color }}></div>
                                                <div className="item-label">{item.label}</div>
                                            </div>

                                            <div className="item-value" style={{ color: item.color }}>{item.type == 'amount' ? '₹' : ''}{item.amount}{item.type == 'percentage' ? '%' : ''}</div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                        <div className="fd-section fd-section-medium fd-cash_required-section">
                            <div className="fd-section-header">
                                <div className="title">Cash Required</div>
                            </div>
                            <div className="fd-section-cashflow-main">
                                <div className="fd-cashflow-filter">
                                    <div className="duration-label">{CashFlowRquired.start_date || '/'} - {CashFlowRquired.end_date || '/'}</div>
                                    <div className="duration-filter">
                                        <select
                                            className="duration-filter-select"
                                            value={CashFlowRequiredDuration}
                                            onChange={(e) => HandleCashFlowRequiredDuration(e.target.value)}
                                        >
                                            {DurationFilterOptions.map((item, index) => <option key={`duration-filter-option-${index}`} value={item.value}>{item.label}</option>)}
                                        </select>
                                    </div>
                                </div>
                                <div className="fd-cashflow-total">
                                    <div className="label">Total Required</div>
                                    <div className="value">{CashFlowRquired.total || 'NIL'}</div>
                                </div>
                                <div className="fd-cashflow-breakdown">
                                    {CashFlowRquired.breakdown.length ?
                                        <div className="fd-cashflow-breakdown-items">
                                            {CashFlowRquired.breakdown.map((item, index) => (
                                                <div className="breakdown-item" key={`breakdown-item-${index}`}>
                                                    <div className="item-left">
                                                        <div className="item-label">{item.label}</div>
                                                        {item.due_date && <div className="item-type">DUE: {item.due_date}</div>}
                                                    </div>
                                                    <div className="item-right">
                                                        <div className="item-value">{item.value}</div>
                                                        <div className="item-brief"
                                                            dangerouslySetInnerHTML={{ __html: Icons.general.dropdown_arrow }}
                                                        ></div>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                        : <NoDataFound label={'No records Found'} />}
                                </div>
                            </div>
                        </div>
                        <div className="fd-section fd-section-mid fd-actual_estimate-section">
                            <div className="fd-section-header">
                                <div className="title">Estimated vs. Actual Costs</div>
                            </div>
                            <div className="fd-section-chart">
                                <Chart
                                    type="bar"
                                    style={{ width: '100%', height: '100%' }}
                                    height='100%'
                                    options={ActualVsEstimatedChart.options}
                                    series={ActualVsEstimatedChart.series}

                                />
                            </div>

                        </div>
                        <div className="fd-section fd-section-medium fd-overdue-section">
                            <div className="fd-section-header">
                                <div className="title">Overdue Payments</div>
                            </div>
                            <div className="fd-section-overdue-main">
                                <div className="fd-overdue-filter">
                                    <div className="duration-label">{OverDuePayments.start_date || '/'} - {OverDuePayments.end_date || '/'}</div>
                                    <div className="duration-filter">
                                        <select
                                            className="duration-filter-select"
                                            value={OverDuePaymentsDuration}
                                            onChange={(e) => HandleOverDuePaymentsDuration(e.target.value)}
                                        >
                                            {DurationFilterOptions.map((item, index) => <option key={`duration-filter-option-${index}`} value={item.value}>{item.label}</option>)}
                                        </select>
                                    </div>
                                </div>
                                <div className="fd-overdue-total">
                                    <div className="label">Pending Amount</div>
                                    <div className="value">{OverDuePayments.total || "NIL"}</div>
                                </div>
                                <div className="fd-overdue-breakdown">
                                    {OverDuePayments.breakdown.length ?
                                        <div className="fd-overdue-breakdown-items">
                                            {OverDuePayments.breakdown.map((item, index) => (
                                                <div className="breakdown-item" key={`breakdown-item-${index}`}>
                                                    <div className="item-left">
                                                        <div className="item-label">{item.label}</div>
                                                        {item.overdue_days && <div className="item-type">{item.overdue_days} days</div>}
                                                    </div>
                                                    <div className="item-right">
                                                        <div className="item-value">{item.value}</div>
                                                        <div className="item-brief"
                                                            dangerouslySetInnerHTML={{ __html: Icons.general.dropdown_arrow }}
                                                        ></div>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                        : <NoDataFound label={'No records Found'} />}
                                </div>
                            </div>

                        </div>
                    </div>



                </div>
            </div>

        </>
    )
}

export default FinanceSummary;