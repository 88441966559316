
import proxyConfig from '../../config/reverseProxy'
import HeaderConfig from '../../config/header'

class Projects {

    constructor() {

    }


    async getNextProjectIDHandler(params) {

        try {

            let response = await fetch(proxyConfig['serverBaseUrl'] + '/builder/projects/get-next-projectId', {
                method: 'POST',
                headers: HeaderConfig.getHeader({ method: 'POST', isToken: true }),
                body: JSON.stringify(params)
            })

            response = await response.json()

            if (response) return response
        } catch {
            return { success: false }
        }


    }
    async getProjectBasicsHandler(params) {

        try {

            let response = await fetch(proxyConfig['serverBaseUrl'] + '/builder/projects/get-projects-basic', {
                method: 'POST',
                headers: HeaderConfig.getHeader({ method: 'POST', isToken: true }),
                body: JSON.stringify(params)
            })

            response = await response.json()

            if (response) return response
        } catch {
            return { success: false }
        }


    }
    async getProjectLaboursHandler(params) {

        try {

            let response = await fetch(proxyConfig['serverBaseUrl'] + '/builder/projects/get-project-labours', {
                method: 'POST',
                headers: HeaderConfig.getHeader({ method: 'POST', isToken: true }),
                body: JSON.stringify(params)
            })

            response = await response.json()

            if (response) return response
        } catch {
            return { success: false }
        }


    }
    async getProjectTeamHandler(params) {

        try {

            let response = await fetch(proxyConfig['serverBaseUrl'] + '/builder/projects/get-project-team', {
                method: 'POST',
                headers: HeaderConfig.getHeader({ method: 'POST', isToken: true }),
                body: JSON.stringify(params)
            })

            response = await response.json()

            if (response) return response
        } catch {
            return { success: false }
        }


    }
    async getProjectClientsHandler(params) {

        try {

            let response = await fetch(proxyConfig['serverBaseUrl'] + '/builder/projects/get-project-client', {
                method: 'POST',
                headers: HeaderConfig.getHeader({ method: 'POST', isToken: true }),
                body: JSON.stringify(params)
            })

            response = await response.json()

            if (response) return response
        } catch {
            return { success: false }
        }


    }
    async updateProjectLaboursHandler(params) {

        try {

            let response = await fetch(proxyConfig['serverBaseUrl'] + '/builder/projects/update-project-labours', {
                method: 'POST',
                headers: HeaderConfig.getHeader({ method: 'POST', isToken: true }),
                body: JSON.stringify(params)
            })

            response = await response.json()

            if (response) return response
        } catch {
            return { success: false }
        }


    }
    async updateProjectTeamHandler(params) {

        try {

            let response = await fetch(proxyConfig['serverBaseUrl'] + '/builder/projects/update-project-team', {
                method: 'POST',
                headers: HeaderConfig.getHeader({ method: 'POST', isToken: true }),
                body: JSON.stringify(params)
            })

            response = await response.json()

            if (response) return response
        } catch {
            return { success: false }
        }


    }

    async getProjectsHandler(params) {

        try {

            let response = await fetch(proxyConfig['serverBaseUrl'] + '/builder/projects/get-projects', {
                method: 'POST',
                headers: HeaderConfig.getHeader({ method: 'POST', isToken: true }),
                body: JSON.stringify(params)
            })

            response = await response.json()

            if (response) return response
        } catch {
            return { success: false }
        }


    }
    async createProjectHandler(params) {

        try {

            let response = await fetch(proxyConfig['serverBaseUrl'] + '/builder/projects/create-project', {
                method: 'POST',
                headers: HeaderConfig.getHeader({ method: 'POST', isdefault: false, isToken: true }),
                body: params
            })

            response = await response.json()

            if (response) return response
        } catch {
            return { success: false }
        }


    }
    async updateProjectHandler(params) {

        try {

            let response = await fetch(proxyConfig['serverBaseUrl'] + '/builder/projects/update-project', {
                method: 'POST',
                headers: HeaderConfig.getHeader({ method: 'POST', isdefault: false, isToken: true }),
                body: params
            })

            response = await response.json()

            if (response) return response
        } catch {
            return { success: false }
        }


    }
    async deleteProjectHandler(params) {

        try {

            let response = await fetch(proxyConfig['serverBaseUrl'] + '/builder/projects/delete-project', {
                method: 'POST',
                headers: HeaderConfig.getHeader({ method: 'POST', isToken: true }),
                body: JSON.stringify(params)
            })

            response = await response.json()

            if (response) return response
        } catch {
            return { success: false }
        }


    }

}

export default Projects;