
import proxyConfig from '../../config/reverseProxy'
import HeaderConfig from '../../config/header'

class LabourManagement {

    constructor() {

    }


    async createLabourHandler(params) {

        try {

            let response = await fetch(proxyConfig['serverBaseUrl'] + '/builder/labourManagement/create-labour', {
                method: 'POST',
                headers: HeaderConfig.getHeader({ method: 'POST', isToken: true }),
                body: JSON.stringify(params)
            })

            response = await response.json()

            if (response) return response
        } catch {
            return { success: false }
        }


    }
    async updateLabourHandler(params) {

        try {

            let response = await fetch(proxyConfig['serverBaseUrl'] + '/builder/labourManagement/update-labour', {
                method: 'POST',
                headers: HeaderConfig.getHeader({ method: 'POST', isToken: true }),
                body: JSON.stringify(params)
            })

            response = await response.json()

            if (response) return response
        } catch {
            return { success: false }
        }


    }
    async createLabourPaymentHandler(params) {

        try {

            let response = await fetch(proxyConfig['serverBaseUrl'] + '/builder/labourManagement/create-labour-payment', {
                method: 'POST',
                headers: HeaderConfig.getHeader({ method: 'POST', isToken: true }),
                body: JSON.stringify(params)
            })

            response = await response.json()

            if (response) return response
        } catch {
            return { success: false }
        }


    }
    async updateLabourPaymentHandler(params) {

        try {

            let response = await fetch(proxyConfig['serverBaseUrl'] + '/builder/labourManagement/update-labour-payment', {
                method: 'POST',
                headers: HeaderConfig.getHeader({ method: 'POST', isToken: true }),
                body: JSON.stringify(params)
            })

            response = await response.json()

            if (response) return response
        } catch {
            return { success: false }
        }


    }

    async deleteLabourHandler(params) {

        try {

            let response = await fetch(proxyConfig['serverBaseUrl'] + '/builder/labourManagement/delete-labour', {
                method: 'POST',
                headers: HeaderConfig.getHeader({ method: 'POST', isToken: true }),
                body: JSON.stringify(params)
            })

            response = await response.json()

            if (response) return response
        } catch {
            return { success: false }
        }


    }

    async getLabourHandler(params) {

        try {

            let response = await fetch(proxyConfig['serverBaseUrl'] + '/builder/labourManagement/get-labours', {
                method: 'POST',
                headers: HeaderConfig.getHeader({ method: 'POST', isToken: true }),
                body: JSON.stringify(params)
            })

            response = await response.json()

            if (response) return response
        } catch {
            return { success: false }
        }


    }
    async getLabourPaymentsHandler(params) {

        try {

            let response = await fetch(proxyConfig['serverBaseUrl'] + '/builder/labourManagement/get-labour-payments', {
                method: 'POST',
                headers: HeaderConfig.getHeader({ method: 'POST', isToken: true }),
                body: JSON.stringify(params)
            })

            response = await response.json()

            if (response) return response
        } catch {
            return { success: false }
        }


    }


}

export default LabourManagement;