
import proxyConfig from '../../config/reverseProxy'
import HeaderConfig from '../../config/header'

class Subscriptions {

    constructor() {

    }


    async getMySubscriptions(params) {

        try {

            let response = await fetch(proxyConfig['serverBaseUrl'] + '/builder/subscription/get-my-subscriptions', {
                method: 'POST',
                headers: HeaderConfig.getHeader({ method: 'POST', isToken: true }),
                body: JSON.stringify(params)
            })

            response = await response.json()

            if (response) return response
        } catch {
            return { success: false }
        }


    }
    async UpdatePendingSubscriptionRequest(params) {

        try {

            let response = await fetch(proxyConfig['serverBaseUrl'] + '/builder/subscription/update-pending-subscription', {
                method: 'POST',
                headers: HeaderConfig.getHeader({ method: 'POST', isToken: true }),
                body: JSON.stringify(params)
            })

            response = await response.json()

            if (response) return response
        } catch {
            return { success: false }
        }


    }





}

export default Subscriptions;