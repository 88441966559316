import BuilderHandler from '../Handlers/Builder/Builder'
import CompanyHandler from '../Handlers/Company/Company'
import SettingsHandler from '../Handlers/Settings/Settings'
import ClientsHandler from '../Handlers/Clients/Clients'
import CostCodeHandler from '../Handlers/CostCode/CostCode'
import ProjectsHandler from '../Handlers/Projects/Projects'
import ProjectPhasesHandler from '../Handlers/ProjectPhases/ProjectPhases'
import VendorsHandler from '../Handlers/Vendors/Vendors'
import TeamManagementHandler from '../Handlers/TeamManagement/TeamManagement'
import RoleManagementHandler from '../Handlers/RoleManagement/RoleManagement'
import ScheduleRatesHandler from '../Handlers/ScheduleRates/ScheduleRates'

class StoreHandler {

    constructor({ dataStack, store, dispatch, updateState }) {

        this.store = store || {}
        this.dataStack = dataStack || []
        this.dispatch = dispatch
        this.updateState = updateState

        this.builderHandler = new BuilderHandler()
        this.companyHandler = new CompanyHandler()
        this.settingsHandler = new SettingsHandler()
        this.clientsHandler = new ClientsHandler()
        this.costCodeHandler = new CostCodeHandler()
        this.projectsHandler = new ProjectsHandler()
        this.projectPhasesHandler = new ProjectPhasesHandler()
        this.vendorsHandler = new VendorsHandler()
        this.teamManagementHandler = new TeamManagementHandler()
        this.roleManagementHandler = new RoleManagementHandler()
        this.scheduleRatesHandler = new ScheduleRatesHandler()

        // this.init()
    }

    async init() {

        return new Promise((resolve, reject) => {


            this.dataStack.forEach(async (item, i) => {

                if (item == 'builder') return resolve(await this.loadUserData())
                if (item == 'company') return resolve(await this.loadCompanyData())
                if (item == 'terms_conditions') return resolve(await this.loadTermsConditionsData())
                if (item == 'field_settings') return resolve(await this.loadFieldSettingsData())
                if (item == 'vendors') return resolve(await this.loadVendorsData())
                if (item == 'finance_settings') return resolve(await this.loadFinanceSettingsData())
                if (item == 'clients') return resolve(await this.loadClientsData())
                if (item == 'costcodes') return resolve(await this.loadCostcodeData())
                if (item == 'team_users') return resolve(await this.loadUsersData())
                if (item == 'roles') return resolve(await this.loadRolesData())
                if (item == 'sites') return resolve(await this.loadSitesData())
                if (item.includes('project-')) return resolve(await this.loadProjectData(item))
                if (item.includes('project_client-')) return resolve(await this.loadProjectClientsData(item))
                if (item.includes('project_phases-')) return resolve(await this.loadProjectPhasesData(item))
                if (item == 'sor_materials') return resolve(await this.loadSORMaterialsData())
                if (item == 'sor_labours') return resolve(await this.loadSORLaboursData())
                if (item == 'sor_machines') return resolve(await this.loadSORMachinesData())

            })

        })


    }

    async loadUserData() {

        let response = await this.builderHandler.getProfileHandler()

        if (response && response.success) {

            this.dispatch(this.updateState({
                type: "SET_USER",
                payload: { builder: response.data }
            }))

        }

        return true
    }
    async loadCompanyData() {


        let response = await this.companyHandler.getCompanyProfileHandler()

        if (response && response.success) {

            this.dispatch(this.updateState({
                type: "SET_COMPANY",
                payload: { company: response.data }
            }))

        }

        return true
    }
    async loadTermsConditionsData() {


        let response = await this.settingsHandler.getTermsConditionsHandler()

        if (response && response.success) {

            let settings = this.store && this.store.user.settings ? this.store.user.settings : {}

            settings = { ...settings, terms_conditions: response.data }

            this.dispatch(this.updateState({
                type: "SET_TERMS_CONDITIONS",
                payload: {
                    settings
                }
            }))

        }

        return true
    }
    async loadFieldSettingsData() {


        let response = await this.settingsHandler.getFieldsHandler()

        if (response && response.success) {

            let settings = this.store && this.store.user.settings ? this.store.user.settings : {}

            settings = { ...settings, field_settings: response.data }

            this.dispatch(this.updateState({
                type: "SET_SETTINGS",
                payload: {
                    settings
                }
            }))

        }

        return true
    }
    async loadFinanceSettingsData() {


        let response = await this.settingsHandler.getFinanceHandler()

        if (response && response.success) {

            let settings = this.store && this.store.user.settings ? this.store.user.settings : {}

            settings = { ...settings, finance_settings: response.data }

            this.dispatch(this.updateState({
                type: "SET_SETTINGS",
                payload: {
                    settings
                }
            }))

        }

        return true
    }
    async loadVendorsData() {


        let response = await this.vendorsHandler.getVendorBasicsHandler()

        if (response && response.success) {

            this.dispatch(this.updateState({
                type: "SET_VENDORS",
                payload: {
                    vendors: response.data
                }
            }))

        }

        return true
    }

    async loadClientsData() {


        let response = await this.clientsHandler.getClientsHandler()

        if (response && response.success) {

            this.dispatch(this.updateState({
                type: "SET_CLIENTS",
                payload: {
                    clients: response.data
                }
            }))

        }

        return true
    }
    async loadCostcodeData() {


        let response = await this.costCodeHandler.getCostCodesHandler()

        if (response && response.success) {

            this.dispatch(this.updateState({
                type: "SET_COSTCODES",
                payload: {
                    costcodes: response.data
                }
            }))

        }

        return true
    }
    async loadUsersData() {


        let response = await this.teamManagementHandler.getTeamUsersHandler()

        if (response && response.success) {

            this.dispatch(this.updateState({
                type: "SET_USERS",
                payload: {
                    team_users: response.data
                }
            }))

        }

        return true
    }
    async loadRolesData() {


        let response = await this.roleManagementHandler.getRoleBasicsHandler()

        if (response && response.success) {

            this.dispatch(this.updateState({
                type: "SET_ROLES",
                payload: {
                    roles: response.data
                }
            }))

        }

        return true
    }

    async loadSitesData() {


        let response = await this.projectsHandler.getProjectsHandler()

        if (response && response.success) {

            this.dispatch(this.updateState({
                type: "SET_PROJECTS",
                payload: {
                    sites: response.data
                }
            }))

        }

        return true
    }
    async loadSORMaterialsData() {


        let response = await this.scheduleRatesHandler.getItemsHandler({
            type: '3',
            item_type: '1',
        })

        if (response && response.success) {

            this.dispatch(this.updateState({
                type: "SET_SOR_MATERIALS",
                payload: {
                    sor_materials: response.data
                }
            }))

        }

        return true
    }
    async loadSORLaboursData() {


        let response = await this.scheduleRatesHandler.getItemsHandler({
            type: '3',
            item_type: '2',
        })

        if (response && response.success) {

            this.dispatch(this.updateState({
                type: "SET_SOR_LABOURS",
                payload: {
                    sor_labours: response.data
                }
            }))

        }

        return true
    }
    async loadSORMachinesData() {


        let response = await this.scheduleRatesHandler.getItemsHandler({
            type: '3',
            item_type: '3',
        })

        if (response && response.success) {

            this.dispatch(this.updateState({
                type: "SET_SOR_MACHINES",
                payload: {
                    sor_machines: response.data
                }
            }))

        }

        return true
    }
    async loadProjectData(item) {

        let projectId = item.split('project-')[1]

        if (!projectId) return

        let filter = { projectID: projectId }

        let response = await this.projectsHandler.getProjectsHandler(filter)

        if (response && response.success) {

            if (!Array.isArray(response.data) || !response.data.length) return

            this.dispatch(this.updateState({
                type: "SET_SELECTED_PROJECT",
                payload: {
                    selected_project: response.data[0]
                }
            }))

        }

        return true
    }
    async loadProjectClientsData(item) {

        let project_id = item.split('project_client-')[1]

        if (!project_id) return

        let filter = { project_id: project_id }

        let response = await this.projectsHandler.getProjectClientsHandler(filter)

        if (response && response.success) {

            if (!Array.isArray(response.data) || !response.data.length) return

            this.dispatch(this.updateState({
                type: "SET_PROJECT_CLIENTS",
                payload: {
                    selected_project_clients: response.data
                }
            }))

        }

        return true
    }
    async loadProjectPhasesData(item) {

        let project_id = item.split('project_phases-')[1]

        if (!project_id) return

        let filter = {
            project_id: project_id,
            type: 1,
            basic_data: true
        }

        let response = await this.projectPhasesHandler.getProjectPhasesHandler(filter)

        if (response && response.success) {

            if (!Array.isArray(response.data) || !response.data.length) return

            this.dispatch(this.updateState({
                type: "SET_PROJECT_PHASES",
                payload: {
                    selected_project_phases: response.data
                }
            }))

        }

        return true
    }
}

export default StoreHandler;