
import proxyConfig from '../../config/reverseProxy'
import HeaderConfig from '../../config/header'

class MaterialIssueDeliveries {

    constructor() {

    }


    async getBasicMaterialIssueDeliveriesHandler(params) {

        try {

            let response = await fetch(proxyConfig['serverBaseUrl'] + '/builder/inventory-deliveries/get-basic-deliveries', {
                method: 'POST',
                headers: HeaderConfig.getHeader({ method: 'POST', isToken: true }),
                body: JSON.stringify(params)
            })

            response = await response.json()

            if (response) return response
        } catch {
            return { success: false }
        }


    }
    async getMaterialIssueDeliveriesHandler(params) {

        try {

            let response = await fetch(proxyConfig['serverBaseUrl'] + '/builder/inventory-deliveries/get-deliveries', {
                method: 'POST',
                headers: HeaderConfig.getHeader({ method: 'POST', isToken: true }),
                body: JSON.stringify(params)
            })

            response = await response.json()

            if (response) return response
        } catch {
            return { success: false }
        }


    }
    async updateMaterialIssueDeliveriesReceivedQtyHandler(params) {

        try {

            let response = await fetch(proxyConfig['serverBaseUrl'] + '/builder/inventory-deliveries/update-received-delivery', {
                method: 'POST',
                headers: HeaderConfig.getHeader({ method: 'POST', isToken: true }),
                body: JSON.stringify(params)
            })

            response = await response.json()

            if (response) return response
        } catch {
            return { success: false }
        }


    }
}

export default MaterialIssueDeliveries;