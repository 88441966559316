import React, { useEffect, useState } from 'react'

/* Redux Setup*/
import { Link, useNavigate, Route, Routes, BrowserRouter, Switch } from 'react-router-dom'
import { useSelector } from 'react-redux'
import store from '../redux/store'

import ReverseProxy from '../config/reverseProxy'

import Mainsidebar from './sidebar/Mainsidebar'
import Header from './header/header'

import SubscriptionPlans from './subscriptions/subscriptionPlans'
import MySubscriptions from './subscriptions/mySubscriptions'
import Clients from './clients/clients'
import Vendors from './vendors/vendors'
import CreateVendor from './vendors/createVendor'
import RoleManagement from './roleManagement/roleManagement'
import TeamManagement from './teamManagement/teamManagement'
import Settings from './settings/settings'
import CostCode from './costCode/costCode'
import ProjectsList from './projects/projectsList'
import CreateProject from './projects/createProject'
import CostCatalog from './costCatalog/costCatalog'
import ScheduleRates from './scheduleRates/scheduleRates'
import WorkRates from './workRates/workRates'
import WorkTemplate from './workTemplate/workTemplate'
import LabourManagement from './labourManagement/labours'
import LaboursAccounts from './labourManagement/laboursAccounts'
import Feedback from './feedback/feedback'
import Notification from './notification/notification'
import ChatMain from './chat/chatMain'
import RequireAuth from '../hooks/RequireAuth'

const Builder = () => {

    const store = useSelector((store) => store)
    const navigate = useNavigate()

    const [PlanExpiryDays, setPlanExpiryDays] = useState(store.user.plan_expiry_duration_days || 0)


    useEffect(() => {

        setPlanExpiryDays(store.user.plan_expiry_duration_days || 0)

    }, [store])

    return (

        <div className={`project-section-container ${PlanExpiryDays <= 10 ? 'project-section-container-with-plan_expiry_banner' : ''} `}>
            <Mainsidebar />
            <div className='project-content-main'>
                <Header />
                <div className='project-content-container'>
                    <Routes >

                        <Route element={<RequireAuth allowedRoles={['admin']} />}>

                            <Route exact path='/projects/*' element={<ProjectsList />}></Route>
                            <Route exact path='/create-project' element={<CreateProject props={{ menuType: 'create' }} />}></Route>
                            <Route exact path='/work-category/*' element={<CostCode />}></Route>
                            <Route exact path='/schedule-rates/*' element={<ScheduleRates />}></Route>
                            <Route exact path='/work-rates/*' element={<WorkRates />}></Route>
                            <Route exact path='/work-templates/*' element={<WorkTemplate />}></Route>
                            <Route exact path='/cost-catalog/*' element={<CostCatalog />}></Route>
                            <Route exact path='/role-management/*' element={<RoleManagement />}></Route>
                            <Route exact path='/labours/*' element={<LabourManagement />}></Route>
                            <Route exact path='/labour-accounts/*' element={<LaboursAccounts />}></Route>
                            <Route exact path='/team-management/*' element={<TeamManagement />}></Route>
                            <Route exact path='/clients/*' element={<Clients />}></Route>
                            <Route exact path='/create-vendor/*' element={<CreateVendor props={{ menuType: 'create' }} />}></Route>
                            <Route exact path='/edit-vendor/:vendorId' element={<CreateVendor props={{ menuType: 'edit' }} />}></Route>
                            <Route exact path='/vendors/*' element={<Vendors />}></Route>
                            <Route exact path='/settings/*' element={<Settings />}></Route>
                            <Route exact path='/subscription-plans/*' element={<SubscriptionPlans />}></Route>
                            <Route exact path='/my-subscriptions/*' element={<MySubscriptions />}></Route>

                            <Route exact path={`/feedback/*`} element={<Feedback />}></Route>
                            <Route exact path={`/notifications/*`} element={<Notification />}></Route>
                            <Route exact path={`/chat/*`} element={<ChatMain />}></Route>
                        </Route>
                    </Routes>
                </div>
            </div>
        </div>
    )
}

export default Builder;
