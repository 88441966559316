

import React, { useEffect, useState, useRef } from 'react'
import { Route, Routes, BrowserRouter, Link, useLocation, Navigate, Outlet, useNavigate, useParams } from 'react-router-dom';
import store from '../../redux/store'
import StoreHandler from '../../redux/StoreHandler'
import { useSelector, useDispatch } from 'react-redux'
import UserAction from '../../redux/action/userAction'

import ReverseProxy from '../../config/reverseProxy'
import ProjectPhasesHandler from '../../Handlers/ProjectPhases/ProjectPhases';

import Icons from '../../assets/Icons'
import Images from '../../assets/Images'
import { CostInput, TextInput, DateTimeInput, SelectInput, RadioInput } from '../../components/Inputs'
import Utils from '../../utils'
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

import AlertPopup from '../../components/AlertPopup'
import Loading from '../../components/Loading'
import SystemToastPopup from '../../components/SystemToastPopup'

import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { useAuth, useMenuAuth } from '../../hooks/useAuth'

const CreateNewPhase = ({ props }) => {
    const { type, heading, close, callback, detials } = props

    const { projectId, phaseId } = useParams()

    const store = useSelector((store) => store)
    const dispatch = useDispatch()
    const { updateState } = new UserAction

    const navigate = useNavigate()

    const projectPhasesHandler = new ProjectPhasesHandler()

    const [isLoading, setIsLoading] = useState(false)
    const [warningAlert, setWarningAlert] = useState(false)
    const [warningAlertType, setWarningAlertType] = useState('warning')
    const [apiFailedMessage, setApiFailedMessage] = useState("Error in Server")

    const { isAdmin, menu_permission } = useMenuAuth({ menu: 'global_menus--employees', feature_key: 'teamManagement' })
    const location = useLocation()

    const [selected_view_settings_value, setSelected_view_settings_value] = useState('')
    const [has_view_access, setHas_view_access] = useState(false)
    const [has_add_access, setHas_add_access] = useState(false)
    const [has_edit_access, setHas_edit_access] = useState(false)
    const [has_delete_access, setHas_delete_access] = useState(false)

    useEffect(() => {

        if (isAdmin) {

            setHas_view_access(true)
            setHas_add_access(true)
            setHas_edit_access(true)
            setHas_delete_access(true)
        }

        else if (menu_permission) {

            if (
                !menu_permission._view
                &&
                (!menu_permission.selected_view_settings_value
                    || menu_permission.selected_view_settings_value == 'no_access')
            ) {
                navigate(`${ReverseProxy['proxyUrl']}/quick-menu`, { state: { from: location }, replace: true })
            }

            if (type == 'edit' && menu_permission._edit == '0') navigate(`${ReverseProxy['proxyUrl']}/builder/projects`, { state: { from: location }, replace: true })
            if (type == 'create' && menu_permission._create == '0') navigate(`${ReverseProxy['proxyUrl']}/builder/projects`, { state: { from: location }, replace: true })

            setHas_view_access(menu_permission._view == '1')
            setHas_add_access(menu_permission._create == '1')
            setHas_edit_access(menu_permission._edit == '1')
            setHas_delete_access(menu_permission._delete == '1')

            setSelected_view_settings_value(menu_permission.selected_view_settings_value)

        }

    }, [store])

    const storeHandler = new StoreHandler({
        dataStack: [`project_client-${projectId}`],
        store: store,
        dispatch: dispatch,
        updateState: updateState,
    })

    const LoadStoreData = async () => {
        // setIsLoading(true)
        let resolved = await storeHandler.init()
        // setIsLoading(false)
    }

    const [PhasesGroups_Options, setPhasesGroups_Options] = useState([])
    const [Clients_Orginal, setClients_Orginal] = useState([])
    const [Clients_Options, setClients_Options] = useState([])

    const [Status_Options, setStatus_Options] = useState([
        {
            value: '0',
            label: 'Action Pending',
        },
        {
            value: '1',
            label: 'In Progress',
        },
        {
            value: '2',
            label: 'Completed',
        },
        {
            value: '3',
            label: 'On Hold',
        },
        {
            value: '4',
            label: 'Stopped',
        }
    ])

    const [currency_type, setCurrency_type] = useState('₹')


    const [Name, setName] = useState('')

    const [PhaseGroup_id, setPhaseGroup_id] = useState('')
    const [PhaseGroup_name, setPhaseGroup_name] = useState('')

    const [Client_id, setClient_id] = useState('')
    const [Client_name, setClient_name] = useState('')
    const [Client_POC_Colorcode, setClient_POC_Colorcode] = useState('')
    const [Client_POC_Name, setClient_POC_Name] = useState('')
    const [Client_POC_Phone, setClient_POC_Phone] = useState('')

    const [Area, setArea] = useState('')
    const [Budget, setBudget] = useState('')
    const [StartDate, setStartDate] = useState('')
    const [EndDate, setEndDate] = useState('')
    const [Status, setStatus] = useState('')


    const HandleClientChange = (value, label) => {
        setClient_id(value)
        setClient_name(label)
        let client = Clients_Orginal.filter((client) => client.client_id == value)
        if (client) {

            setClient_POC_Colorcode(client[0].client_color_code)
            setClient_POC_Name(client[0].contact_person_name)
            setClient_POC_Phone(client[0].contact_person_phone)
        }
    }


    const HandleCancel = async (e) => {
        close(type)
    }

    const HandleSubmit = async (e) => {
        e.preventDefault()

        let payload_details = {
            project_id: projectId,
            name: Name,
            parent_id: String(PhaseGroup_id),
            parent_name: PhaseGroup_name,
            client_id: String(Client_id),
            client_name: Client_name,
            client_contact_person_name: Client_POC_Name,
            client_contact_person_phone: String(Client_POC_Phone),
            area: String(Area),
            budget: String(Budget),
            startdate: StartDate,
            status: String(Status),
            enddate: EndDate,
        }

        let response = { success: true }

        if (type == 'edit') {
            payload_details.id = String(phaseId)
            response = await projectPhasesHandler.updateProjectPhasesHandler(payload_details)
        }
        else {
            response = await projectPhasesHandler.createProjectPhasesHandler(payload_details)
        }

        if (response.success) {
            callback(type)
        }
        else {
            setWarningAlert(true)
            setWarningAlertType('error')
            setApiFailedMessage(response.message || "Error in Phases")
        }

    }

    const getProjectPhaseGroupsDetials = async () => {


        let filter = {
            project_id: projectId,
            type: 0,
            basic_data: true,
        }

        let response = await projectPhasesHandler.getProjectPhasesHandler(filter)

        if (response && response.success) {

            let phases_groups = response.data

            phases_groups = phases_groups.map(group => {
                return {
                    value: group.id,
                    label: group.name
                }
            })

            setPhasesGroups_Options(phases_groups)

        } else {
            setWarningAlert(true)
            setWarningAlertType('error')
            setApiFailedMessage(`Error in Phases, Please try again!`)
        }
    }

    const getProjectPhaseDetials = async (id) => {


        let filter = {
            project_id: projectId,
            id: id
        }

        let response = await projectPhasesHandler.getProjectPhasesHandler(filter)

        if (response && response.success) {

            let phases_details = response.data

            let phase = phases_details[0]

            if (phase) {
                let {
                    id,
                    name,
                    type,
                    parent_id,
                    parent_name,
                    client_id,
                    client_name,
                    client_contact_person_name,
                    client_contact_person_phone,
                    area,
                    budget,
                    startdate,
                    enddate,
                    status
                } = phase




                setName(name)
                setPhaseGroup_id(parent_id)
                setPhaseGroup_name(parent_name)
                setClient_id(client_id)
                setClient_name(client_name)
                setClient_POC_Name(client_contact_person_name)
                setClient_POC_Phone(client_contact_person_phone)
                setArea(area)
                setBudget(budget)
                setStartDate(startdate)
                setEndDate(enddate)
                setStatus(status)
            }

        } else {
            setWarningAlert(true)
            setWarningAlertType('error')
            setApiFailedMessage(`Error in Phases, Please try again!`)
        }
    }


    useEffect(() => {

        LoadStoreData()
        getProjectPhaseGroupsDetials()

        if (type == 'edit') {

            if (!Object.keys(detials).length) return getProjectPhaseDetials(phaseId)

            let {
                id,
                name,
                type,
                parent_id,
                parent_name,
                client_id,
                client_name,
                client_contact_person_name,
                client_contact_person_phone,
                area,
                budget,
                startdate,
                enddate,
                status

            } = detials

            console.log(startdate, 'startdate');


            setName(name)
            setPhaseGroup_id(parent_id)
            setPhaseGroup_name(parent_name)
            setClient_id(client_id)
            setClient_name(client_name)
            setClient_POC_Name(client_contact_person_name)
            setClient_POC_Phone(client_contact_person_phone)
            setArea(area)
            setBudget(budget)
            setStartDate(startdate)
            setEndDate(enddate)
            setStatus(status)
        }



    }, [])


    useEffect(() => {
        if (store?.user?.selected_project_clients) {

            let selected_project_clients = store?.user?.selected_project_clients || []

            setClients_Orginal(selected_project_clients)

            let client_options = selected_project_clients.map((client) => {
                return {
                    value: client.client_id,
                    label: client.client_name
                }
            })
            setClients_Options(client_options)
        }

    }, [store?.user?.selected_project_clients])


    return (
        <>

            {isLoading ?
                <Loading
                    props={{
                        isMainLogo: false,
                        isLabel: true
                    }} />
                : null}
            {warningAlert ?

                <SystemToastPopup
                    props={{
                        type: warningAlertType,
                        message: apiFailedMessage || "Error in Team Management",
                        callback: (confirmation) => setWarningAlert(false)
                    }} />

                : null}


            <div className="popup-container-main">
                <div className="popup-block-ui"></div>
                <div className="side-popup-container side-role-popup-container animateXRight animateXRightactive" >
                    <div className="sidebar-role-popup-content role-main-container">
                        <div className="role-content-header">
                            <div className="role-header-left">
                                <div className="title">{type == 'edit' ? 'Project Phase Details' : 'Create Project Phase'}</div>
                            </div>
                            <div className="role-header-right">
                                <div
                                    className="close-btn"
                                    onClick={() => HandleCancel()}
                                    dangerouslySetInnerHTML={{ __html: Icons.general.close }}
                                ></div>
                            </div>
                        </div>
                        <div className="role-content-sections">
                            <div className="role-basic_details-section">
                                <div className="basic_details-section-item">
                                    <TextInput
                                        props={{
                                            id: "phase-form-name",
                                            value: Name,
                                            placeholder: 'Enter Role Name',
                                            setValue: (value) => setName(value),
                                            isIcon: false,
                                            isLabel: true,
                                            isRequired: true,
                                            label: "Phase Name",
                                        }}
                                    />
                                </div>

                                <div className="basic_details-section-item">
                                    <SelectInput
                                        props={{
                                            id: "phase-form-goup",
                                            value: PhaseGroup_id,
                                            placeholder: 'Select group',
                                            options: PhasesGroups_Options || [],
                                            setValue: (value, label) => {
                                                setPhaseGroup_id(value)
                                                setPhaseGroup_name(label)
                                            },
                                            isIcon: false,
                                            isLabel: true,
                                            isRequired: false,
                                            readOnly: false,
                                            label: "Phases Group",
                                        }}
                                    />
                                </div>
                                <div className="basic_details-section-item">
                                    <SelectInput
                                        props={{
                                            id: "phase-form-client",
                                            value: Client_id,
                                            placeholder: 'Select client',
                                            options: Clients_Options || [],
                                            setValue: (value, label) => HandleClientChange(value, label),
                                            isIcon: false,
                                            isLabel: true,
                                            isRequired: true,
                                            readOnly: false,
                                            label: "Phases Client",
                                        }}
                                    />
                                </div>
                                <div className="basic_details-section-item basic_details-view-item">
                                    <div className="label">Client POC</div>
                                    <div className="value">{Client_POC_Name || '-'} / {Client_POC_Phone || '-'}</div>
                                </div>
                                <div className="basic_details-section-item">
                                    <TextInput
                                        props={{
                                            id: "phase-form-area",
                                            value: Area,
                                            type: 'number',
                                            placeholder: 'Enter area',
                                            setValue: (value) => setArea(value),
                                            isIcon: false,
                                            isLabel: true,
                                            isRequired: true,
                                            label: "Phase Ares (in SQFT)",
                                        }}
                                    />
                                </div>
                                <div className="basic_details-section-item">
                                    <CostInput
                                        props={{
                                            id: "create-project-budget",
                                            value: Budget,
                                            isCostMethod: false,
                                            placeholder: '',
                                            currency_type: currency_type,
                                            setValue: (value) => setBudget(value),
                                            isIcon: true,
                                            isIconLeft: true,
                                            icon: currency_type,
                                            isLabel: true,
                                            isRequired: true,
                                            label: "Total Phase Budget",
                                        }}
                                    />
                                </div>
                                <div className="basic_details-section-item">
                                    <DateTimeInput
                                        props={{
                                            id: "phase-form-startdate",
                                            value: StartDate,
                                            placeholder: '',
                                            setValue: (value) => {
                                                setStartDate(value)
                                            },
                                            isIcon: false,
                                            isLabel: true,
                                            icon: Icons.general.schedule,
                                            isRequired: false,
                                            label: "Start Date",
                                        }}
                                    />
                                </div>
                                <div className="basic_details-section-item">
                                    <DateTimeInput
                                        props={{
                                            id: "phase-form-enddate",
                                            value: EndDate,
                                            placeholder: '',
                                            setValue: (value) => {
                                                setEndDate(value)
                                            },
                                            isIcon: false,
                                            isLabel: true,
                                            icon: Icons.general.schedule,
                                            isRequired: false,
                                            label: "End Date",
                                        }}
                                    />
                                </div>
                                <div className="basic_details-section-item">
                                    <SelectInput
                                        props={{
                                            id: "phase-form-status",
                                            value: Status,
                                            placeholder: 'Select status',
                                            options: Status_Options || [],
                                            setValue: (value, label) => setStatus(value),
                                            isIcon: false,
                                            isLabel: true,
                                            isRequired: false,
                                            readOnly: false,
                                            label: "Status",
                                        }}
                                    />
                                </div>

                            </div>
                        </div>

                    </div>
                    <div className="sidebar-popup-footer role-sidebar-footer">
                        <div className="footer-item action-items">
                            <div
                                className="action-btn discard-changes-btn"
                                onClick={() => HandleCancel()}
                            >
                                <div className="label">Cancel</div>
                            </div>
                            <div
                                className="action-btn save-changes-btn"
                                onClick={(e) => HandleSubmit(e)}
                            >
                                <div className="label">Save</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default CreateNewPhase