

import React, { useEffect, useState, useRef, useCallback, useMemo, StrictMode } from 'react'
import { Route, Routes, BrowserRouter, Link, useLocation, Navigate, Outlet, useNavigate, useParams } from 'react-router-dom';
import store from '../../redux/store'
import StoreHandler from '../../redux/StoreHandler'
import { useSelector, useDispatch } from 'react-redux'
import UserAction from '../../redux/action/userAction'

import ReverseProxy from '../../config/reverseProxy'
import SettingsHandler from '../../Handlers/Settings/Settings';
import EstimateHandler from '../../Handlers/Estimate/Estimate';
import ProposalHandler from '../../Handlers/Proposal/Proposal';
import CommentsHandler from '../../Handlers/Comments/Comments';
import Icons from '../../assets/Icons'
import Images from '../../assets/Images'
import { CostInput, TextInput, SelectInput, DateTimeInput, RadioInput, MultiSelectInput } from '../../components/Inputs'
import Utils from '../../utils'
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

import AlertPopup from '../../components/AlertPopup'
import SystemToastPopup from '../../components/SystemToastPopup'
import Loading from '../../components/Loading'

import PreviewProposal from './previewProposal'

import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { withStyles, makeStyles } from "@mui/material/styles";

import BuilderAttachments from '../../Handlers/BuilderAttachments/BuilderAttachments';
import ImagePreviewPopup from '../../components/ImagePreviewPopup'

import { useAuth, useMenuAuth } from '../../hooks/useAuth'


const CreateProposal = ({ props }) => {


    const { projectId, proposalId } = useParams();

    const { menuType = 'create' } = props

    const [project_id, setProject_id] = useState('')
    const [project_name, setProject_name] = useState('')
    const [estimate_id, setEstimate_id] = useState('')

    const [isLoading, setIsLoading] = useState(false)
    const [warningAlert, setWarningAlert] = useState(false)
    const [warningAlertType, setWarningAlertType] = useState('warning')
    const [apiFailedMessage, setApiFailedMessage] = useState('Error in Proposal')

    const navigate = useNavigate()
    const store = useSelector((store) => store)
    const dispatch = useDispatch()
    const { updateState } = new UserAction

    const formSubmitBtnRef = useRef()

    const storeHandler = new StoreHandler({
        dataStack: ['team_users', 'terms_conditions', 'field_settings', 'finance_settings', `project-${projectId}`, `project_phases-${projectId}`],
        store: store,
        dispatch: dispatch,
        updateState: updateState,
    })

    const LoadStoreData = async () => {
        setIsLoading(true)
        let resolved = await storeHandler.init()
        setIsLoading(false)
    }

    const { isAdmin, menu_permission } = useMenuAuth({ menu: 'pre_construction--project_proposal', feature_key: 'proposal' })
    const location = useLocation()

    const [selected_view_settings_value, setSelected_view_settings_value] = useState('')
    const [has_view_access, setHas_view_access] = useState(false)
    const [has_add_access, setHas_add_access] = useState(false)
    const [has_edit_access, setHas_edit_access] = useState(false)
    const [has_delete_access, setHas_delete_access] = useState(false)

    useEffect(() => {

        if (isAdmin) {

            setHas_view_access(true)
            setHas_add_access(true)
            setHas_edit_access(true)
            setHas_delete_access(true)
        }

        else if (menu_permission) {

            if (
                !menu_permission._view
                &&
                (!menu_permission.selected_view_settings_value
                    || menu_permission.selected_view_settings_value == 'no_access')
            ) {
                navigate(`${ReverseProxy['proxyUrl']}/quick-menu`, { state: { from: location }, replace: true })
            }

            if (menuType == 'create' && menu_permission._create == '0') navigate(`${ReverseProxy['proxyUrl']}/builder/projects`, { state: { from: location }, replace: true })
            if (menuType == 'edit' && menu_permission._edit == '0') navigate(`${ReverseProxy['proxyUrl']}/builder/projects`, { state: { from: location }, replace: true })

            setHas_view_access(menu_permission._view == '1')
            setHas_add_access(menu_permission._create == '1')
            setHas_edit_access(menu_permission._edit == '1')
            setHas_delete_access(menu_permission._delete == '1')

            setSelected_view_settings_value(menu_permission.selected_view_settings_value)

        }

    }, [store])

    const estimateHandler = new EstimateHandler()
    const proposalHandler = new ProposalHandler()
    const settingsHandler = new SettingsHandler()
    const commentsHandler = new CommentsHandler()
    const builderAttachments = new BuilderAttachments()

    const [unitsOptions, setUnitsOptions] = useState([])
    const [currency_type, setCurrency_type] = useState('₹')

    const [id, setId] = useState(Utils.getUniqueId())
    const [name, setName] = useState('')
    const [proposalID, setProposalID] = useState('')
    const [status, setStatus] = useState('')
    const [introduction_text, setIntroduction_text] = useState('')
    const [closing_text, setClosing_text] = useState('')
    const [comments, setComments] = useState('')
    const [EstimatePricing, setEstimatePricing] = useState({})

    const [proposalLoaded, setProposalLoaded] = useState(false)

    const [proposalStatusOptions, setProposalStatusOptions] = useState([
        {
            value: 0,
            label: "Pending",
            isDefault: false
        },
        {
            value: 1,
            label: "Approved",
            isDefault: false
        },
        {
            value: 2,
            label: "Rejected",
            isDefault: false
        },
    ])

    const [termsConditionOptions, setTermsConditionOptions] = useState([])
    const [termsConditionId, setTermsConditionId] = useState("")
    const [termsConditionValue, setTermsConditionValue] = useState("")

    const HandleTermsConditions = (value, label) => {

        setTermsConditionId(value)

        let selected_TC = termsConditionOptions.filter(tc => tc.value == value)[0]
        if (selected_TC) setTermsConditionValue(selected_TC.body)
    }

    const [PaymentSchedule, setPaymentSchedule] = useState([])

    const [paymentScheduleOptions, setPaymentScheduleOptions] = useState([])
    const [paymentScheduleId, setPaymentScheduleId] = useState("")

    const [paymentSplitLimitExists, setPaymentSplitLimitExists] = useState(false)
    const [paymentSplits, setPaymentSplits] = useState([
        {
            id: Utils.getUniqueId(),
            name: "",
            value: 0,
            amount: 0,
            value_type: `%`
        }
    ])

    const getPaymentSchedules = async () => {

        let response = await settingsHandler.getPaymentSchedulesHandler()

        if (response && response.success) {
            setPaymentSchedule(response.data)


            let paymentSchedule = response.data.map(ps => {
                return {
                    value: ps.id,
                    label: ps.name,
                    isDefault: false,
                }
            })

            setPaymentScheduleOptions([
                {
                    id: "none",
                    value: "none",
                    label: "None",
                    isDefault: false,
                },
                ...paymentSchedule])

        } else {
            setWarningAlert(true)
            setWarningAlertType('error')
            setApiFailedMessage(`Error in Payment Schedule, Please try again!`)
        }
    }

    const HandlePaymentSchedule = (value, label) => {

        if (value == 'none') {

            setPaymentScheduleId('')
            setPaymentSplits([{
                id: Utils.getUniqueId(),
                name: "",
                value: 0,
                amount: 0,
                value_type: `%`
            }])
            return
        }
        setPaymentScheduleId(value)

        let selected = PaymentSchedule.filter(p => p.id == value)[0]
        if (selected) {
            let _paymentSplits = selected.milestones.map(m => {
                return {
                    id: m.id,
                    name: m.name,
                    value: m.value,
                    amount: summaryFinalTotal * parseFloat(m.value || 0).toFixed(2) / 100,
                    value_type: `%`
                }
            })

            setPaymentSplits(_paymentSplits)
        }
    }


    const HandlePaymentsInputChange = (value, index, key, method = '%') => {

        let paymentSplits_ = [...paymentSplits]

        if (key == 'value' && method != '%') {

            paymentSplits_[index][key] = value
            paymentSplits_[index]['amount'] = value
            paymentSplits_[index]['value_type'] = currency_type
        }
        if (key == 'value' && method == '%') {

            paymentSplits_[index][key] = value
            paymentSplits_[index]['amount'] = summaryFinalTotal * parseFloat(value || 0).toFixed(2) / 100
            paymentSplits_[index]['value_type'] = `%`
        }
        else paymentSplits_[index][key] = value

        setPaymentSplits(paymentSplits_)
    }
    const HandlePaymentsRemove = (index) => {
        let paymentSplits_ = [...paymentSplits]
        if (index == 0) {

            paymentSplits_[index] = {
                id: Utils.getUniqueId(),
                name: "",
                value: 0,
                amount: 0
            }
            setPaymentSplits(paymentSplits_)
            return
        }
        paymentSplits_.splice(index, 1)
        setPaymentSplits(paymentSplits_)
    }
    const HandlePaymentsAddNew = () => {

        let paymentSplits_ = [...paymentSplits]
        paymentSplits_.push({
            id: Utils.getUniqueId(),
            name: '',
            value: '',
        })
        setPaymentSplits(paymentSplits_)
    }

    useEffect(() => {

        if (Array.isArray(paymentSplits)) {

            const PaymentTotal = paymentSplits.reduce((prev, val, i) => prev + parseFloat(val.value), 0)
            if (PaymentTotal > 100 && !paymentSplitLimitExists) setPaymentSplitLimitExists(true)
            else if (PaymentTotal <= 100 && paymentSplitLimitExists) setPaymentSplitLimitExists(false)

        }

    }, [paymentSplits])

    const [overHeads_options, setOverHeads_options] = useState([])
    const [insurances_options, setInsurances_options] = useState([])

    const [overHeadsOptions, setOverHeadsOptions] = useState(overHeads_options)
    const [insurancesOptions, setInsurancesOptions] = useState(insurances_options)

    const [builderFinalCost, setBuilderFinalCost] = useState(0)
    const [allowanceFinalCost, setAllowanceFinalCost] = useState(0)
    const [markupsFinalCost, setMarkupsFinalCost] = useState(0)
    const [taxFinalCost, setTaxFinalCost] = useState(0)
    const [overHeadValue, setOverHeadValue] = useState(0)
    const [insuranceValue, setInsuranceValue] = useState(0)
    const [adjustmentValue, setAdjustmentValue] = useState(0)
    const [clientFinalCost, setClientFinalCost] = useState(0)
    const [summaryFinalTotal, setSummaryFinalTotal] = useState(0)

    const [summaryOverHeads, setSummaryOverHeads] = useState([])
    const [summaryInsurance, setSummaryInsurance] = useState([])


    const [summaryItems, setSummaryItems] = useState([])
    const [summaryTotalItems, setSummaryTotalItems] = useState([])

    const [selectedLayoutPresets, setSelectedLayoutPresets] = useState('')
    const [layoutPresets, setLayoutPresets] = useState([
        {
            value: 0,
            label: "Pending",
            isDefault: false
        },
        {
            value: 1,
            label: "Approved",
            isDefault: false
        },
        {
            value: 2,
            label: "Rejected",
            isDefault: false
        },
    ])

    const [showCreatePreview, setShowCreatePreview] = useState(false)

    const [AssigneeOptions_Orginal, setAssigneeOptions_Orginal] = useState([])
    const [AssigneeOptions, setAssigneeOptions] = useState([])
    const [SelectedAssignees_Res, setSelectedAssignees_Res] = useState([])
    const [SelectedAssignees, setSelectedAssignees] = useState([])

    const HandleSelectAssignee = (users) => {

        users = users.map(p => {
            let users = AssigneeOptions_Orginal.filter(ph => ph.id == p.id)[0]
            if (!users) return p

            return users
        })

        setSelectedAssignees(users)
    }

    const [ProjectPhases, setProjectPhases] = useState([])
    const [ProjectPhases_Options, setProjectPhases_Options] = useState([])

    const [Phase_id, setPhase_id] = useState('')
    const [Phase_name, setPhase_name] = useState('')
    const [Client_id, setClient_id] = useState('')
    const [Client_name, setClient_name] = useState('')

    const HandlePhaseSelected = (value) => {
        if (!value) return

        let selected_phase = ProjectPhases.filter(phase => phase.id == value)[0]

        if (selected_phase) {
            setPhase_id(selected_phase.id)
            setPhase_name(selected_phase.name)
            setClient_id(selected_phase.client_id)
            setClient_name(selected_phase.client_name)
        }
    }


    const HandlePreviewProposal = (e) => {

        let is_selected_items = estimateItemsOrginal.filter(itm => itm.selected).length

        if (!is_selected_items) {

            setWarningAlert(true)
            setWarningAlertType('warning')
            setApiFailedMessage(`Please select atleast one estimate item!`)

            return
        }

        if (menuType == 'create') setShowCreatePreview(true)
        else navigate(`/builder/projects/${projectId}/proposals/${proposalId}/edit/preview`)

    }
    const HandleCancel = (e) => {

        navigate(`/builder/projects/${projectId}/proposals`)
    }

    const HandleSubmit = async (e) => {
        e.preventDefault()

        if (paymentSplitLimitExists) return

        let assignees = SelectedAssignees?.map(p => p.value)

        let update_detials = {
            id: id,
            name: name,
            proposalId: proposalID,
            projectId: projectId,
            estimate_id: estimate_id,

            phase_id: String(Phase_id),
            phase_name: Phase_name,

            client_id: String(Client_id),
            client_name: Client_name,

            status: String(status),
            proposal_total: String(summaryFinalTotal),
            layoutsettings: layoutsettingsItems,
            estimate_items: estimateItemsOrginal,
            estimate_pricing: EstimatePricing || {},
            introduction_text: introduction_text || "",
            closing_text: closing_text || "",

            terms_condition_id: termsConditionId || "",
            terms_condition: termsConditionValue || "",

            payment_schedule_id: paymentScheduleId || "",
            payment_schedule: paymentSplits || [],
            comments: comments || "",
            attachments: AttachmentsFiles || [],
            assignees: assignees || []
        }


        let response = { success: false }

        if (menuType == 'edit') {

            update_detials["id"] = id
            response = await proposalHandler.updateProposalHandler(update_detials)

        } else {
            response = await proposalHandler.createProposalHandler(update_detials)

            let update_data = {
                project_id: projectId,
                parent_id: proposalID,
                parent_name: name || `Proposal ${proposalID}`,
                parent_type: String(0),
                comments: CommentsItems
            }

            if (CommentsItems.length) {

                let comments_response = await commentsHandler.createMultiCommentHandler(update_data)

                if (!comments_response.success) {
                    setWarningAlert(true)
                    setWarningAlertType('error')
                    setApiFailedMessage(`Error in Comments, Please try again!`)
                }
            }

        }

        if (response.success) {
            setIsLoading(false)
            navigate(`/builder/projects/${projectId}/proposals`)
        }
        else {
            setIsLoading(false)
            setWarningAlert(true)
            setWarningAlertType('error')
            setApiFailedMessage(`Error in Proposal, Please try again!`)
        }
    }

    const getNextProjectID = async () => {

        let response = await proposalHandler.getNextProposalIDHandler()

        if (response && response.success) {

            let { proposalId } = response.data

            if (proposalId) setProposalID(proposalId)

        } else {
            setWarningAlert(true)
            setWarningAlertType('error')
            setApiFailedMessage(`Error in Proposal ID, Please try again!`)
        }
    }


    const [tabelHeader, setTabelHeader] = useState([

        {
            id: 'select',
            label: 'Select',
            disable: false,
        },

        {
            id: 'name',
            label: 'Name',
            minWidth: 200,
            maxWidth: 300,
            disable: false,

        },
        {
            id: 'cost_type',
            label: 'Cost Type',
            minWidth: 70,
            maxWidth: 70,
            disable: false,

        },

        {
            id: 'quantity',
            label: 'Quantity',
            minWidth: 70,
            maxWidth: 70,
            disable: false,
        },
        {
            id: 'unitrate',
            label: 'Unit rate',
            minWidth: 70,
            maxWidth: 70,
            disable: false,
        },
        {
            id: 'builder_price',
            label: 'Prime cost',
            minWidth: 70,
            maxWidth: 70,
            disable: false,
        },
        {
            id: 'markup_amount',
            label: 'Markup',
            minWidth: 70,
            maxWidth: 70,
            disable: false,
        },
        {
            id: 'tax_amount',
            label: 'Tax',
            minWidth: 70,
            maxWidth: 70,
            disable: false,
        },
        {
            id: 'price',
            label: 'Total',
            minWidth: 70,
            maxWidth: 70,
            disable: false,
        },
    ])
    const [tableSelectAll, setTableSelectAll] = useState(false)


    const [estimateItemsOrginal, setEstimateItemsOrginal] = useState([])
    const [estimateItems, setEstimateItems] = useState([])
    const AddBtnOptions = [
        {
            icon: Icons.general.group,
            label: 'Group',
            type: 'group',
            id: "group"
        },
        {
            icon: Icons.general.work,
            label: 'Work Template',
            type: 'work_template',
            id: 'work_template'
        },
        {
            icon: Icons.general.labour,
            label: 'Labour',
            type: 'item',
            id: "labour"
        },
        {
            icon: Icons.general.material,
            label: 'Materials',
            type: 'item',
            id: "material"
        },
        {
            icon: Icons.general.machines,
            label: 'Machines',
            type: 'item',
            id: "machine"
        },
        {
            icon: Icons.general.fee,
            label: 'Fee',
            type: 'item',
            id: "fee"
        },
        {
            icon: Icons.general.allowance,
            label: 'Allowances',
            type: 'item',
            id: "allowance"
        },
        {
            icon: Icons.general.work,
            label: 'Work',
            type: 'work',
            id: 'work'
        },
    ]

    const HandleSelectAllEvent = (value) => {
        setTableSelectAll(value)

        let _estimateItems = [...estimateItems]
        let _estimateItemsOrginal = [...estimateItemsOrginal]

        const selectItem = (items, value) => {

            items = items.map(item => {

                let orginal_item_index = _estimateItemsOrginal.findIndex(itm => itm.id == item.id)
                if (orginal_item_index != -1) {

                    _estimateItemsOrginal[orginal_item_index].selected = value
                }

                item.selected = value
                if (Array.isArray(item.sub_items)) item.sub_items = selectItem(item.sub_items, value)
                return item
            })

            return items
        }

        _estimateItems = selectItem(_estimateItems, value)

        setEstimateItems(_estimateItems)
        setEstimateItemsOrginal(_estimateItemsOrginal)
    }
    const HandleSelectEvent = (group_id, parent_id, value) => {

        let _estimateItems = [...estimateItems]
        let _estimateItemsOrginal = [...estimateItemsOrginal]

        const selectItem = (items, value) => {

            items = items.map(item => {

                item.selected = value

                let orginal_item_index = _estimateItemsOrginal.findIndex(itm => itm.id == item.id)

                if (orginal_item_index != -1) _estimateItemsOrginal[orginal_item_index].selected = value
                if (item.type != 'item' && Array.isArray(item.sub_items)) item.sub_items = selectItem(item.sub_items, value)
                return item
            })

            return items
        }

        const checkItemInGroup = (items, group_id, parent_id) => {

            let isGroup = false

            items.forEach(item => {

                if (item.id == group_id && item.parent_id == parent_id) isGroup = true
                else if (!isGroup && item.type != 'item' && Array.isArray(item.sub_items)) isGroup = checkItemInGroup(item.sub_items, group_id, parent_id)

            })

            return isGroup
        }

        const findAndUpdateGroup = (group_id, parent_id, _estimateItems, value) => {

            _estimateItems = _estimateItems.map((item, idx) => {

                if (item.id == group_id && item.parent_id == parent_id) {
                    item.selected = value

                    let orginal_item_index = _estimateItemsOrginal.findIndex(itm => itm.id == item.id)

                    if (orginal_item_index != -1) _estimateItemsOrginal[orginal_item_index].selected = value

                    if (item.type != 'item' && Array.isArray(item.sub_items)) {
                        item.sub_items = selectItem(item.sub_items, value)
                    }
                }
                else if (item.type != 'item' && Array.isArray(item.sub_items)) {

                    let isGroup = checkItemInGroup(item.sub_items, group_id, parent_id)
                    if (isGroup && value) {
                        item.selected = value
                        let orginal_item_index = _estimateItemsOrginal.findIndex(itm => itm.id == item.id)
                        if (orginal_item_index != -1) _estimateItemsOrginal[orginal_item_index].selected = value
                    }

                    item.sub_items = findAndUpdateGroup(group_id, parent_id, item.sub_items, value)

                }

                return item
            })

            return _estimateItems
        }

        _estimateItems = findAndUpdateGroup(group_id, parent_id, _estimateItems, value)
        setEstimateItems(_estimateItems)
        setEstimateItemsOrginal(_estimateItemsOrginal)
    }


    const HandlePricingItems = (data) => {

        if (!Object.keys(data).length) return

        setEstimatePricing(data || {})

        let { id, overhead_items, insurance_items } = data

        overhead_items = overhead_items || []
        insurance_items = insurance_items || []

        setEstimate_id(id)

        if (Array.isArray(overhead_items)) {

            setSummaryOverHeads(overhead_items)

            let _overHeads_options = overHeads_options.filter(itm => overhead_items.filter(item => item.id == itm.id).length == 0)
            setOverHeadsOptions(_overHeads_options)
        }
        if (Array.isArray(insurance_items)) {

            setSummaryInsurance(insurance_items)

            let _insurances_options = insurances_options.filter(itm => insurance_items.filter(item => item.id == itm.id).length == 0)
            setInsurancesOptions(_insurances_options)

        }
    }
    const getSubItem = (items, parentid) => {

        let subItems = []

        let getItems = (items, parentid) => {

            if (!Array.isArray(items)) return

            let _items = items.filter(itm => itm.parent_id == parentid)

            subItems.push(..._items)

            _items.forEach(itm => getItems(items, itm.id))
        }

        getItems(items, parentid)

        return subItems
    }

    const getEstimateItemTypeEenem = (type) => {

        if (type == '1') return 'group'
        else if (type == '2') return 'work_template'
        else if (type == '3') return 'item'
        else if (type == '4') return 'work'
        else return ''

    }
    const getEstimateItemCategoryEenem = (type) => {

        if (type == '1') return 'material'
        else if (type == '2') return 'labour'
        else if (type == '3') return 'machine'
        else if (type == '4') return 'subcontractor'
        else if (type == '5') return 'fee'
        else if (type == '6') return 'allowance'
        else return ''

    }
    const getEstimateItemStatusEenem = (type) => {

        if (type == '0') return 'incomplete'
        else if (type == '1') return 'completed'
        else if (type == '2') return 'hold'
        else if (type == '3') return 'stopped'
        else return ''
    }
    const getFormatedEstimateItem = (item) => {

        return {
            expanded: true,
            selected: item.selected || false,
            disable: false,

            parent_id: item.parent_id,
            parent_name: item.parent_name,
            id: item.id,
            name: item.name,
            description: item.description,

            costcode_id: item.costcode_id || "",
            costcode_name: item.costcode_name || "",


            co_efficient: parseFloat(item.co_efficient || 0) || 0,
            per_co_efficient_cost: parseFloat(item.per_co_efficient_cost || 0) || 0,

            type: getEstimateItemTypeEenem(item.type),
            cost_type: getEstimateItemCategoryEenem(item.item_type) || "",
            _status: item.status,
            status: getEstimateItemStatusEenem(item.status),

            quantity: parseFloat(item.quantity || 0) || 0,
            unitrate: parseFloat(item.unitrate || 0) || 0,
            builder_price: parseFloat(item.builder_price || 0) || 0,
            price: parseFloat(item.price || 0) || 0,


            unit_id: item.unit_id || "",
            unit_name: item.unit_name || "",

            markup_id: item.markup_id || "",
            markup_name: item.markup_name || "",
            markup_value: parseFloat(item.markup_value || 0) || 0,
            markup_value_type: item.markup_value_type || "",
            markup_amount: parseFloat(item.markup_amount || 0) || 0,

            tax_id: item.tax_id || "",
            tax_name: item.tax_name || "",
            tax_value_type: item.tax_value_type || "",
            tax_value: parseFloat(item.tax_value || 0) || 0,
            tax_amount: parseFloat(item.tax_amount || 0) || 0,

            sub_items: []
        }

    }
    const getOrderedTableItems = (items = []) => {
        const groupedItems = {};

        for (const item of items) {

            const parent_id = item.parent_id;

            if (parent_id != null) {

                if (!groupedItems[parent_id]) groupedItems[parent_id] = [];

                groupedItems[parent_id].push(item);
            }
        }

        for (const item of items) {
            const itemId = item.id;
            if (groupedItems[itemId]) {
                item.sub_items = groupedItems[itemId];
            }
        }

        for (const item of items) {
            const itemId = item.id;
            if (groupedItems[itemId]) {

                // const orderedItems = groupedItems[itemId].sort((a, b) => {
                //     if (a.type != 'item' && b.type == 'item') return -1;
                //     if (a.type == 'item' && b.type != 'item') return 1;
                //     return 0;
                // })

                item.sub_items = groupedItems[itemId]
            }
        }

        const orderedItems = items.sort((a, b) => {
            if (a.type != 'item' && b.type == 'item') return -1;
            if (a.type == 'item' && b.type != 'item') return 1;
            return 0;
        })

        return orderedItems.filter(d => d.parent_id == "")

    }

    const setUpEstimateItems = (data) => {

        if (!Array.isArray(data)) return

        let items = data.map(d => {

            let item = getFormatedEstimateItem(d)
            let subItems = getSubItem(data, d.id).map(itm => getFormatedEstimateItem(itm))

            let tax_amount = item.tax_amount
            let markup_amount = item.markup_amount
            let builder_price = item.builder_price
            let price = item.price

            let subItems_Total = subItems.reduce((prev, val, ind) => {

                return {
                    tax_amount: prev.tax_amount + val.tax_amount,
                    markup_amount: prev.markup_amount + val.markup_amount,
                    builder_price: prev.builder_price + val.builder_price,
                    price: prev.price + val.price,
                }

            }, { tax_amount: 0, markup_amount: 0, builder_price: 0, price: 0 })

            tax_amount = parseFloat(tax_amount + (subItems_Total.tax_amount || 0)).toFixed(2)
            markup_amount = parseFloat(markup_amount + (subItems_Total.markup_amount || 0)).toFixed(2)
            builder_price = parseFloat(builder_price + (subItems_Total.builder_price || 0)).toFixed(2)
            price = parseFloat(price + (subItems_Total.price || 0)).toFixed(2)

            item._tax_amount = tax_amount || ""
            item._tax_percent = ((tax_amount / builder_price) * 100 || 0).toFixed(2)
            item._markup_amount = markup_amount || ""
            item._markup_percent = ((markup_amount / builder_price) * 100 || 0).toFixed(2)
            item._builder_price = builder_price || ""
            item._price = price || ""
            item._unitrate = item.unitrate || ""
            item._quantity = item.quantity || ""

            item.tax_amount = tax_amount ? `${currency_type}${tax_amount}` : ""
            item.markup_amount = markup_amount ? `${currency_type}${markup_amount}` : ""
            item.builder_price = builder_price ? `${currency_type}${builder_price}` : ""
            item.price = price ? `${currency_type}${price}` : ""
            item.unitrate = item.unitrate ? `${currency_type}${item.unitrate}` : ""
            item.quantity = item.quantity ? `${item.quantity}${item.unit_name || ""}` : ""


            let type_option = AddBtnOptions.filter(opt => {

                if (opt.type == 'group' && item.type == 'group') return true
                else if (opt.type != 'group' && item.item_type == opt.id) return true
                else return false
            })

            type_option = type_option.length ? type_option[0] : undefined
            item.type_option = type_option

            return item

        })

        items.forEach((item, i) => {

            if (!item.parent_id && !item.isSubTotal && item.type != 'item') {

                let _item = {
                    expanded: true,
                    selected: false,
                    disable: false,
                    isSubTotal: true,
                    parent_id: item.id,
                    parent_name: item.name,
                    id: item.id + 'subtotal',
                    name: `${item.name} Subtotal`,
                    description: "",

                    costcode_id: "",
                    costcode_name: "",

                    type: "subtotal",
                    item_type: "",
                    cost_type: "",
                    _status: item.status,
                    status: "",

                    quantity: "",
                    unitrate: "",
                    builder_price: item.builder_price,
                    price: item.price,


                    unit_id: "",
                    unit_name: "",

                    markup_id: item.markup_id || "",
                    markup_name: item.markup_name || "",
                    markup_value: item.markup_value,
                    markup_value_type: item.markup_value_type || "",
                    markup_amount: item.markup_amount,

                    tax_id: item.tax_id || "",
                    tax_name: item.tax_name || "",
                    tax_value_type: item.tax_value_type || "",
                    tax_value: item.tax_value,
                    tax_amount: item.tax_amount,

                    sub_items: []
                }
                items.push(_item)
            }

        })

        items = getOrderedTableItems(items)

        // items

        setEstimateItems(items)
        setEstimateItemsOrginal(data)
    }
    const LoadEstimateItems = async () => {

        let filter = {
            projectId: projectId
        }

        setIsLoading(true)

        let response = await estimateHandler.getEstimateItemsHandler(filter)
        let estimation_response = await estimateHandler.getProjectEstimationHandler(filter)
        setIsLoading(false)

        if (response.success && estimation_response.success) {

            HandlePricingItems(estimation_response.data)

            let { data } = response

            if (!Array.isArray(data)) return

            setUpEstimateItems(data)

        }
        else {
            setWarningAlert(true)
            setWarningAlertType('error')
            setApiFailedMessage(`Error in Estimate Items, Please try again!`)
        }
    }
    const CreateEstimateTableItem = (row, idx, group_number) => {


        let { id, disable, type, parent_id, isSubTotal, sub_items, expanded, _status } = row

        sub_items = sub_items || []

        let description = row.description //Utils.getInnerTextFromHTML(row.description)

        let subTotalSetting = getLayoutItem(`estimate`).item.dropdownOptions[1].checklists.filter(c => c.id == 'group_subtotal')[0]
        let groupNumberSetting = getLayoutItem(`estimate`).item.dropdownOptions[1].checklists.filter(c => c.id == 'group_number')[0]

        let isGroupNumberEnabled = groupNumberSetting ? groupNumberSetting.value : true
        let issubTotalEnabled = subTotalSetting ? subTotalSetting.value : true

        let descriptionSettings = getLayoutItem(`estimate`).item.dropdownOptions[0].checklists.filter(c => c.id == 'description')[0]
        let isDescriptionEnabled = descriptionSettings ? descriptionSettings.value : true

        return (
            <>
                {!disable ?
                    isSubTotal && !issubTotalEnabled ? '' :
                        <>
                            <TableRow
                                role="checkbox"
                                tabIndex={-1}
                                key={id}
                                className={`estimate-table-row ${isSubTotal ? 'estimate-table-row-subtotal' : ''} ${type != 'item' ? !parent_id ? 'estimate-table-row-group' : 'estimate-table-row-subgroup' : ''}`}
                            >
                                {
                                    tabelHeader.filter(th => !th.disable).filter(th => {
                                        let estimate_setting = getLayoutItem(`estimate`).item.dropdownOptions[0].checklists
                                        let isColumnEnabled = estimate_setting.filter(es => es.id == th.id)
                                        isColumnEnabled = isColumnEnabled.length ? isColumnEnabled[0].value : !th.disable
                                        return isColumnEnabled

                                    }).map((column) => {
                                        const value = row[column.id];
                                        return (

                                            column.disable ? '' :
                                                column.id == 'select' && !isSubTotal ?


                                                    <TableCell
                                                        key={column.id}
                                                        align={column.align}
                                                        className={`estimate-table-row-item ${column.id == 'select' ? 'table-default-toggle-item' : ''} '`}
                                                        style={{
                                                            paddingRight: 0,
                                                        }}
                                                    >
                                                        <RadioInput props={{
                                                            value: row.selected || false,
                                                            isIcon: false,
                                                            icon: "",
                                                            inputType: "toggle",
                                                            name: "table-default-toggle",
                                                            setValue: (value) => HandleSelectEvent(id, parent_id, value)
                                                        }} />

                                                    </TableCell>

                                                    :
                                                    <TableCell
                                                        key={column.id}
                                                        align={column.align}
                                                        className='estimate-table-row-item'
                                                        style={{
                                                            maxWidth: column.maxWidth,
                                                            minWidth: column.minWidth
                                                        }}
                                                    >
                                                        {
                                                            row.isSubTotal ? value :
                                                                column.id == 'name' ?
                                                                    <div style={{ width: '100%', height: 'max-content', display: 'flex', flexDirection: 'column' }}>
                                                                        <span
                                                                            style={{
                                                                                width: '100%',
                                                                                height: '30px',
                                                                                display: 'flex',
                                                                                alignItems: 'center',
                                                                                fontSize: '18px',
                                                                            }}
                                                                        >
                                                                            {isGroupNumberEnabled ? `${group_number}.` : ''}&nbsp;{column.format ? column.format(value) : value}
                                                                        </span>
                                                                        {isDescriptionEnabled && description ?
                                                                            <span
                                                                                className='estimate-table-description'
                                                                                dangerouslySetInnerHTML={{ __html: description || "" }}
                                                                            ></span> : ''}
                                                                    </div>
                                                                    : column.format ? column.format(value) : value
                                                        }
                                                    </TableCell>
                                        );
                                    })}
                            </TableRow>
                            {sub_items.map((item, idxx) => (
                                CreateEstimateTableItem(item, idxx, `${group_number}.${idx + 1}`)
                            ))}
                        </>
                    : ''

                }
            </>
        )
    }

    const LoadProposal = async () => {

        let filter = {
            proposalId: proposalId,
            projectId: projectId
        }

        setIsLoading(true)

        let response = await proposalHandler.getProposalsHandler(filter)
        setIsLoading(false)

        if (response.success) {
            let { data } = response

            if (!Array.isArray(data) || !data.length) navigate(`/builder/projects/${projectId}/propsals`)

            let {
                id,
                proposalID,
                name,
                phase_id,
                phase_name,
                client_id,
                client_name,
                status,
                introduction_text,
                closing_text,
                terms_condition_id,
                terms_condition,
                comments,
                estimate_items,
                settings_layout,
                estimate_pricing,
                payment_schedule_id,
                payment_schedule,
                attachments,
                assignees
            } = data[0]

            setId(id)
            setProposalID(proposalID)
            setName(name)
            setPhase_id(phase_id)
            setPhase_name(phase_name)
            setClient_id(client_id)
            setClient_name(client_name)
            setStatus(status)
            setIntroduction_text(introduction_text)
            setClosing_text(closing_text)
            setTermsConditionId(terms_condition_id)

            setTermsConditionValue(terms_condition)
            setComments(comments)
            setLayoutsettingsItems(settings_layout)
            setEstimatePricing(estimate_pricing)

            setPaymentScheduleId(payment_schedule_id || "")
            setPaymentSplits(payment_schedule || paymentSplits || [])

            HandlePricingItems(estimate_pricing || {})
            setUpEstimateItems(estimate_items)

            setAttachmentsFiles(attachments || [])
            setSelectedAssignees_Res(assignees || [])
            setProposalLoaded(true)
        }
    }

    useEffect(() => {

        let total_project_price = 0
        let total_project_allowance = 0
        let total_project_markup = 0
        let total_project_tax = 0
        let total_project_overhead = 0
        let total_project_insurance = 0
        let total_project_client = 0


        if (Array.isArray(estimateItemsOrginal)) {


            // estimateItemsOrginal.filter(d => d.selected).forEach((data, i) => {
            estimateItemsOrginal.forEach((data, i) => {

                let { builder_price, item_type, tax_amount, markup_amount } = data

                if (item_type == "5" || item_type == "6") total_project_allowance += parseFloat(builder_price || 0)
                else {

                    total_project_price += parseFloat(builder_price || 0)
                    total_project_markup += parseFloat(markup_amount || 0)
                    total_project_tax += parseFloat(tax_amount || 0)
                }
            })
        }

        if (Array.isArray(summaryOverHeads)) {

            total_project_overhead = summaryOverHeads.reduce((prev, val, ind) => {

                let { value, value_type } = val

                let amount = 0

                if (value_type == '%') amount = (parseFloat(value) * total_project_price) / 100
                else amount = parseFloat(value)

                return prev + amount

            }, 0)
        }

        if (Array.isArray(summaryInsurance)) {

            total_project_insurance = summaryInsurance.reduce((prev, val, ind) => {
                let { value, value_type } = val

                let amount = 0

                if (value_type == '%') amount = (parseFloat(value) * total_project_price) / 100
                else amount = parseFloat(value)

                return prev + amount

            }, 0)
        }
        total_project_client = parseFloat(total_project_price + total_project_allowance + total_project_markup + total_project_tax + total_project_overhead + total_project_insurance).toFixed(2)

        setBuilderFinalCost(total_project_price)
        setAllowanceFinalCost(total_project_allowance)
        setMarkupsFinalCost(total_project_markup)
        setTaxFinalCost(total_project_tax)
        setOverHeadValue(total_project_overhead)
        setInsuranceValue(total_project_insurance)
        setClientFinalCost(total_project_client)



        let _summaryTotalOptions = [
            {
                id: 'builderFinalCost',
                label: "Prime Fixed Cost",
                value: total_project_price,
                value_type: currency_type,
                enabled: true,
                order: 1,
            },
            {
                id: 'allowanceFinalCost',
                label: "Allowances",
                value: total_project_allowance,
                value_type: currency_type,
                enabled: true,
                order: 2,
            },
            {
                id: 'overHeadValue',
                label: "Overheads",
                value: total_project_overhead,
                value_type: currency_type,
                enabled: true,
                order: 3,
            },
            {
                id: 'insuranceValue',
                label: "Insurances",
                value: total_project_insurance,
                value_type: currency_type,
                enabled: true,
                order: 4,
            },
            {
                id: 'markupsFinalCost',
                label: "Markup",
                value: total_project_markup,
                value_type: currency_type,
                enabled: true,
                order: 5,
            },
            {
                id: 'taxFinalCost',
                label: "Tax",
                value: total_project_tax,
                value_type: currency_type,
                enabled: true,
                order: 6,
            },
            {
                id: 'adustmentValue',
                label: "Adjust",
                value: adjustmentValue,
                value_type: currency_type,
                enabled: true,
                order: 7,
            },
        ]

        setSummaryTotalItems(_summaryTotalOptions)

        setBuilderFinalCost(parseFloat(total_project_price || 0))
        setSummaryFinalTotal(parseFloat(total_project_client || 0))



    }, [estimateItemsOrginal, summaryOverHeads, summaryInsurance])

    const [layoutsettingsOpen, setLayoutsettingsOpen] = useState(false)
    const [layoutsettingsItemSelected, setLayoutsettingsItemSelected] = useState(undefined)
    const [layoutsettingsItems, setLayoutsettingsItems] = useState([
        {
            id: 'introduction_text',
            isdragging: false,
            enabled: true,
            isDropdown: false,
            dropdownOptions: [],
            label: "Introduction Text",
            icon: Icons.general.description
        },
        {
            id: 'estimate',
            isdragging: false,
            enabled: true,
            isDropdown: true,
            dropdownOptions: [
                {
                    id: 'show_itemized',
                    title: 'Show itemized',
                    isTitle: true,
                    isToggle: true,
                    enabled: true,

                    checklists: [
                        {
                            id: 'description',
                            label: 'Description',
                            value: true,
                        },
                        {
                            id: 'cost_type',
                            label: 'Cost type',
                            value: false
                        },
                        {
                            id: 'quantity',
                            label: 'Quantity',
                            value: true
                        },
                        {
                            id: 'unitrate',
                            label: 'Unit rate',
                            value: false
                        },
                        {
                            id: 'builder_price',
                            label: 'Prime cost',
                            value: false
                        },
                        {
                            id: 'markup_amount',
                            label: 'Markup',
                            value: false
                        },
                        {
                            id: 'tax_amount',
                            label: 'Tax',
                            value: false
                        },
                        {
                            id: 'price',
                            label: 'Total',
                            value: true
                        },

                    ]
                },
                {
                    id: 'other',
                    title: 'Other',
                    isTitle: true,
                    isToggle: true,
                    enabled: true,

                    checklists: [
                        {
                            id: 'group_subtotal',
                            label: 'Show Subtotal',
                            value: true
                        },
                        {
                            id: 'group_number',
                            label: 'Group numbering',
                            value: true
                        },

                    ]
                },
            ],
            label: "Estimate",
            icon: Icons.general.estimate,
        },
        {
            id: 'allowances',
            isdragging: false,
            enabled: true,
            isDropdown: false,
            dropdownOptions: [],
            label: "Allowances",
            icon: Icons.general.allowances_icon
        },
        {
            id: 'overheads',
            isdragging: false,
            enabled: true,
            isDropdown: false,
            dropdownOptions: [],
            label: "Overheads",
            icon: Icons.general.percentage
        },
        {
            id: 'insurances',
            isdragging: false,
            enabled: true,
            isDropdown: false,
            dropdownOptions: [],
            label: "Insurances",
            icon: Icons.general.protection
        },
        {
            id: 'summary',
            isdragging: false,
            enabled: true,
            isDropdown: true,
            dropdownOptions: [
                {
                    id: 'summary',
                    title: '',
                    isTitle: false,
                    isToggle: false,
                    enabled: false,

                    checklists: [
                        {
                            id: 'builderFinalCost',
                            label: 'Prime Fixed Cost',
                            value: false
                        },
                        {
                            id: 'allowanceFinalCost',
                            label: 'Allowances',
                            value: false
                        },
                        {
                            id: 'overHeadValue',
                            label: 'Overheads',
                            value: false
                        },
                        {
                            id: 'insuranceValue',
                            label: 'Insurance',
                            value: true
                        },
                        {
                            id: 'markupsFinalCost',
                            label: 'Markup',
                            value: false
                        },
                        {
                            id: 'taxFinalCost',
                            label: 'Tax',
                            value: true
                        },
                        {
                            id: 'adustmentValue',
                            label: 'Adjust',
                            value: true
                        },
                        {
                            id: 'price',
                            label: 'Total',
                            value: true
                        },

                    ]
                },
            ],
            label: "Summary",
            icon: Icons.general.summary
        },
        {
            id: 'payment_schedule',
            isdragging: false,
            enabled: true,
            isDropdown: false,
            dropdownOptions: [],
            label: "Payment Schedule",
            icon: Icons.general.pay_schedule
        },
        {
            id: 'closing_text',
            isdragging: false,
            enabled: true,
            isDropdown: false,
            dropdownOptions: [],
            label: "Closing Text",
            icon: Icons.general.description
        },

        {
            id: 'attachments',
            isdragging: false,
            enabled: true,
            isDropdown: false,
            dropdownOptions: [],
            label: "Attachments",
            icon: Icons.general.attachments
        },
        {
            id: 'terms_conditions',
            isdragging: false,
            enabled: true,
            isDropdown: false,
            dropdownOptions: [],
            label: "Terms & Conditions",
            icon: Icons.general.terms_conditions
        },
        {
            id: 'signature',
            disableToggle: true,
            isdragging: false,
            enabled: true,
            isDropdown: false,
            dropdownOptions: [],
            label: "Signature",
            icon: Icons.general.signature
        },

    ])

    const activeLayoutSettingsIds = layoutsettingsItems.filter(ls => ls.enabled).map(ls => ls.id)


    const HandleLayoutOpen = (value) => {

        setLayoutsettingsOpen(value)

        if (value) document.querySelector(':root').style.setProperty('--proposal-layoutsettings-width', '300px')
        else document.querySelector(':root').style.setProperty('--proposal-layoutsettings-width', '60px')

    }

    const HandleLayoutItemEnable = (value, item, i) => {
        let _layoutsettingsItems = [...layoutsettingsItems]
        _layoutsettingsItems[i].enabled = value
        setLayoutsettingsItems(_layoutsettingsItems)
    }

    const HandleLayoutItemClick = (id) => {

        let parent_container = document.querySelector('#project-createproposal-main')

        if (parent_container && id) {

            let item = parent_container.querySelector(`#create-proposal-${id}`)

            if (item) item.scrollIntoView({ behavior: 'smooth' });
        }

    }

    const HandleLayoutDirectItemOpen = (id) => {

        let index = layoutsettingsItems.findIndex(item => item.id == id)
        let item = layoutsettingsItems[index]

        HandleLayoutOpen(true)

        HandleLayoutItemOpen(item, index)

    }
    const getLayoutItem = (id) => {

        let index = layoutsettingsItems.findIndex(item => item.id == id)
        let item = layoutsettingsItems[index]

        return { item, index }

    }
    const HandleLayoutItemOpen = (item, i) => {
        item.index = i
        setLayoutsettingsItemSelected(item)
    }
    const HandleLayoutItemClose = () => {
        setLayoutsettingsItemSelected(undefined)
    }



    const HandleLayoutItemChecklistsToggleAll = (value, parent_item, option_idx) => {
        let _layoutsettingsItems = [...layoutsettingsItems]

        _layoutsettingsItems[parent_item.index].dropdownOptions[option_idx].checklists = _layoutsettingsItems[parent_item.index].dropdownOptions[option_idx].checklists.map(c => {
            c.value = value

            return c
        })

        setLayoutsettingsItems(_layoutsettingsItems)
    }
    const HandleLayoutItemChecklistsToggle = (value, parent_item, option_idx, checklist_idx) => {
        let _layoutsettingsItems = [...layoutsettingsItems]
        _layoutsettingsItems[parent_item.index].dropdownOptions[option_idx].checklists[checklist_idx].value = value
        setLayoutsettingsItems(_layoutsettingsItems)
    }

    const [ShowSelectedPreviewImage, setShowSelectedPreviewImage] = useState(false)
    const [SelectedPreviewImage, setSelectedPreviewImage] = useState(undefined)
    const AttachmentsInputRef = useRef()
    const [AttachmentsFiles, setAttachmentsFiles] = useState([])

    const HandleTriggerAddAttachment = (e) => {
        if (AttachmentsInputRef.current) AttachmentsInputRef.current.click()
    }
    const HandleAddAttachment = async (e) => {

        let file = e.target.files[0]

        if (!file) return

        const formData = new FormData();

        formData.append('projectId', projectId);
        formData.append('attachment', file);
        formData.append('linked_type', '2');
        formData.append('linked_item_id', id);

        setIsLoading(true)

        let upload_response = await builderAttachments.createAttachmentHandler(formData)

        setIsLoading(false)

        if (upload_response.success) {

            let { public_id, url } = upload_response.data

            if (!public_id || !url) return

            let _AttachmentsFiles = [...AttachmentsFiles]

            _AttachmentsFiles.push({
                public_id: public_id,
                original_filename: file.name,
                bytes: file.size,
                format: file.type,
                file_url: url,
                linked_type: '2',
                linked_item_id: id,
            })

            setAttachmentsFiles(_AttachmentsFiles)

        } else {
            setWarningAlert(true)
            setWarningAlertType('error')
            setApiFailedMessage(`Error in uploading attachment, Please try again!`)
        }
    }
    const HandleDeleteAttachment = async (idx) => {

        let _AttachmentsFiles = [...AttachmentsFiles]

        setIsLoading(true)

        let upload_response = await builderAttachments.deleteAttachmentHandler({
            public_id: _AttachmentsFiles[idx].public_id,
            projectId: projectId,
        })

        setIsLoading(false)

        if (upload_response.success) {
            _AttachmentsFiles.splice(idx, 1)
            setAttachmentsFiles(_AttachmentsFiles)
        }
        else {
            setWarningAlert(true)
            setWarningAlertType('error')
            setApiFailedMessage(`Error in deleting attachment, Please try again!`)
        }
    }

    const HandleSelectPreviewImage = (image) => {
        setShowSelectedPreviewImage(true)
        setSelectedPreviewImage({
            name: image.original_filename,
            file_url: image.file_url,
        })
    }
    const HandleHideSelectPreviewImage = (confirmation) => {
        setShowSelectedPreviewImage(confirmation)
        setSelectedPreviewImage(undefined)
    }


    const [CommentsItems, setCommentsItems] = useState([])

    const [CommentsInput, setCommentsInput] = useState("")

    const LoadComments = async ({ project_id, parent_id }) => {

        console.log(project_id, parent_id, 'parent_id');
        if (!project_id || !parent_id) return

        let filter = {
            project_id: project_id,
            parent_id: parent_id
        }

        setIsLoading(true)

        let response = await commentsHandler.getCommentsHandler(filter)
        setIsLoading(false)

        if (response.success) {
            let { data } = response

            if (!Array.isArray(data)) return

            console.log(data, 'data');

            setCommentsItems(data)
        }
    }

    const HandleAddComment = async () => {
        let _CommentsItems = [...CommentsItems]

        let userdetials = JSON.parse(localStorage.getItem('userdetials'))

        _CommentsItems.push({
            id: Utils.getUniqueId(),
            created_at: new Date().getTime(),
            created_by: userdetials.id,
            created_by_name: userdetials.name,
            message: CommentsInput,
            parent_id: proposalID,
            parent_name: name || `Proposal ${proposalID}`,
            parent_type: 0,
            project_id: projectId,
            company_id: userdetials.company_id,
        })

        setCommentsItems(_CommentsItems)
        setCommentsInput("")

        if (menuType == 'create') return

        let update_data = {
            project_id: projectId,
            parent_id: proposalID,
            parent_name: name || `Proposal ${proposalID}`,
            parent_type: String(0),
            message: CommentsInput
        }

        let response = await commentsHandler.createCommentHandler(update_data)

        if (!response.success) {
            setWarningAlert(true)
            setWarningAlertType('error')
            setApiFailedMessage(`Error in creating comment, Please try again!`)
        }
    }

    const HandleDeleteComment = async (id, idx) => {
        let _CommentsItems = [...CommentsItems]

        _CommentsItems.splice(idx, 1)

        setCommentsItems(_CommentsItems)

        if (menuType == 'create') return

        let update_data = {
            project_id: projectId,
            ids: [id]
        }

        let response = await commentsHandler.deleteCommentHandler(update_data)

        if (!response.success) {
            setWarningAlert(true)
            setWarningAlertType('error')
            setApiFailedMessage(`Error in deleting comment, Please try again!`)
        }
    }

    useEffect(() => {

        if (AssigneeOptions.length && Array.isArray(SelectedAssignees_Res) && SelectedAssignees_Res.length) {

            let select_assignee = SelectedAssignees_Res.map(p => {
                let assignee = AssigneeOptions.filter(ph => ph.value == p)[0]

                if (!assignee) return p
                return assignee
            })

            setSelectedAssignees(select_assignee)

        }

    }, [AssigneeOptions, SelectedAssignees_Res])

    useEffect(() => {

        if (menuType == 'create') {
            getNextProjectID()
            LoadEstimateItems()
        }
        else {
            LoadProposal()

            LoadComments({ project_id: projectId, parent_id: proposalId })
        }

        LoadStoreData()
        getPaymentSchedules()
    }, [])

    useEffect(() => {

        if (store && store.user) {

            let { settings, team_users, selected_project_phases } = store.user

            if (Array.isArray(team_users)) {

                let _team_users = team_users.filter(opt => opt.status == '1').map((opt) => {
                    return {
                        value: opt.id,
                        label: opt.name,
                    }
                })

                setAssigneeOptions(_team_users)
                setAssigneeOptions_Orginal(team_users)
            }

            if (settings && Array.isArray(settings.terms_conditions) && settings.terms_conditions.length) {



                let terms_conditions = settings.terms_conditions.map(tc => {
                    return {
                        id: tc.id,
                        value: tc.id,
                        label: tc.name,
                        body: tc.body,
                        isDefault: false,
                        document: tc.document || []
                    }
                })

                setTermsConditionOptions([
                    {
                        id: "none",
                        value: "none",
                        label: "None",
                        body: "",
                        isDefault: false,
                        document: []
                    },
                    ...terms_conditions
                ])
            }
            if (settings && Array.isArray(settings.field_settings) && settings.field_settings.length) {

                let { project_statuses, project_types, units } = settings.field_settings[0]

                units = units.filter(opt => opt.isActive).map((opt) => {
                    return {
                        value: opt.id,
                        label: opt.name,
                        isDefault: opt.isDefault || false
                    }
                })

                setUnitsOptions(units || [])

            }
            if (settings && Array.isArray(settings.finance_settings) && settings.finance_settings.length) {

                let { taxes, markups, overheads, insurance } = settings.finance_settings[0]

                overheads = overheads.filter(opt => opt.isActive).map((opt) => {
                    return {
                        id: opt.name,
                        value: parseFloat(opt.value || 0),
                        value_type: opt.value_type,
                        label: opt.name,
                        isDefault: opt.isDefault || false
                    }
                })


                setOverHeads_options(overheads || [])


                insurance = insurance.filter(opt => opt.isActive).map((opt) => {
                    return {
                        id: opt.name,
                        value: parseFloat(opt.value || 0),
                        value_type: opt.value_type,
                        label: opt.name,
                        isDefault: opt.isDefault || false
                    }
                })

                setInsurances_options(insurance || [])


                let _summaryOptions = [
                    {
                        id: "overhead-summary-option",
                        head: {
                            icon: Icons.general.percentage,
                            label: "OverHead",
                        },
                        items: overheads,
                        selected: true,
                        order: 1
                    },
                    {
                        id: "insurance-summary-option",
                        head: {
                            icon: Icons.general.protection,
                            label: "Insurance",
                        },
                        items: insurance,
                        selected: true,
                        order: 2
                    }
                ]


                setSummaryItems(_summaryOptions)

            }
            if (selected_project_phases) {

                setProjectPhases(selected_project_phases)

                let project_phases = selected_project_phases.map(phase => {
                    return {
                        value: phase.id,
                        label: `${phase.name} | ${phase.client_name}`,
                        isDefault: false
                    }
                })

                setProjectPhases_Options(project_phases || [])

            }
        }

    }, [store])




    return (
        <>


            {isLoading ?
                <Loading
                    props={{
                        isMainLogo: false,
                        isLabel: true
                    }} />
                : null}
            {warningAlert ?

                <SystemToastPopup
                    props={{
                        type: warningAlertType,
                        message: apiFailedMessage || "Error in Proposal",
                        callback: (confirmation) => setWarningAlert(false)
                    }} />

                : null}
            {ShowSelectedPreviewImage ?

                <ImagePreviewPopup
                    props={{
                        heading: `Preview - ${SelectedPreviewImage.name}`,
                        image: SelectedPreviewImage.file_url,
                        message: "Invalid Inputs, Please try again with vaild Inputs!",
                        callback: (confirmation) => HandleHideSelectPreviewImage(confirmation)
                    }} />

                : null}


            {showCreatePreview ?
                <PreviewProposal
                    props={{
                        menuType: 'edit',
                        proposalLoaded: true,
                        proposaldetials: {
                            id: id,
                            name: name,
                            proposalID: proposalId,
                            status: status,
                            introduction_text: introduction_text,
                            closing_text: closing_text,
                            terms_condition_id: termsConditionId,
                            terms_condition: termsConditionValue,
                            payment_schedule_id: paymentScheduleId || "",
                            payment_schedule: paymentSplits.filter(p => p.name) || [],
                            comments: comments,
                            settings_layout: layoutsettingsItems,
                            estimate_pricing: EstimatePricing,
                            estimate_items: estimateItemsOrginal
                        },
                        onSettingsChange: (settings) => { setLayoutsettingsItems(settings) },
                        saveCallback: () => {
                            formSubmitBtnRef.current?.click()
                            setShowCreatePreview(false)
                        },
                        close: () => { setShowCreatePreview(false) }
                    }}
                />
                : null}

            <Routes>
                <Route path='/preview'
                    element={
                        <PreviewProposal
                            props={{
                                menuType: 'edit',
                                proposalLoaded: proposalLoaded,
                                proposaldetials: {
                                    id: id,
                                    name: name,
                                    proposalID: proposalId,
                                    phase_id: Phase_id,
                                    phase_name: Phase_name,
                                    client_id: Client_id,
                                    client_name: Client_name,
                                    status: status,
                                    introduction_text: introduction_text,
                                    closing_text: closing_text,
                                    terms_condition_id: termsConditionId,
                                    terms_condition: termsConditionValue,
                                    payment_schedule_id: paymentScheduleId || "",
                                    payment_schedule: paymentSplits.filter(p => p.name) || [],
                                    comments: comments,
                                    settings_layout: layoutsettingsItems,
                                    estimate_pricing: EstimatePricing,
                                    estimate_items: estimateItemsOrginal
                                },
                                onSettingsChange: (settings) => { setLayoutsettingsItems(settings) },
                                saveCallback: (e) => {
                                    formSubmitBtnRef.current?.click()
                                    if (menuType == 'create') navigate(`/builder/projects/${projectId}/proposals/create`)
                                    else navigate(`/builder/projects/${projectId}/proposals/${proposalId}/edit`)
                                },
                                close: () => {

                                    if (menuType == 'create') navigate(`/builder/projects/${projectId}/proposals/create`)
                                    else navigate(`/builder/projects/${projectId}/proposals/${proposalId}/edit`)

                                }
                            }}
                        />}>
                </Route>

            </Routes>

            <div className="project-createproposal-main" id='project-createproposal-main'>
                <div className="createproposal-panel-content">

                    <div className="panel-content-header">
                        <div className="left">
                            <div className="icon"
                                dangerouslySetInnerHTML={{ __html: Icons.general.back_arrow }}
                                onClick={(e) => HandleCancel(e)}
                            ></div>
                            <div className="title">{menuType == 'create' ? 'Create' : 'Edit'} Proposal</div>
                        </div>
                        <div className="right">
                            <div className="preview-button" onClick={() => HandlePreviewProposal()}>
                                <div
                                    className="icon"
                                    dangerouslySetInnerHTML={{ __html: Icons.general.eye_open }}
                                ></div>
                                <div className="label">Preview</div>
                            </div>
                            <div className="preview-button settings-button" onClick={() => HandleLayoutOpen(!layoutsettingsOpen)}>
                                <div
                                    className="icon"
                                    dangerouslySetInnerHTML={{ __html: Icons.general.settings }}
                                ></div>
                            </div>
                        </div>
                    </div>
                    <form className="panel-content-sections" onSubmit={HandleSubmit}>
                        <div className="create-proposal-content-main">

                            <div className="create-proposal-sections">

                                <div className="create-proposal-section">
                                    <div className="section-input-items">
                                        <div className="section-input-item">
                                            <TextInput
                                                props={{
                                                    id: "create-proposal-name",
                                                    value: name,
                                                    placeholder: '',
                                                    setValue: (value) => setName(value),
                                                    isIcon: false,
                                                    isLabel: true,
                                                    isRequired: true,
                                                    label: "Name",
                                                }}
                                            />
                                        </div>
                                        <div className="section-input-item">
                                            <TextInput
                                                props={{
                                                    id: "create-proposal-proposalID",
                                                    value: proposalID,
                                                    placeholder: '',
                                                    setValue: (value) => { },
                                                    isIcon: false,
                                                    isLabel: true,
                                                    isRequired: true,
                                                    readOnly: true,
                                                    label: "Propsal ID",
                                                }}
                                            />
                                        </div>

                                        <div className="section-input-item">
                                            <SelectInput
                                                props={{
                                                    id: "create-invoice-phase",
                                                    value: Phase_id,
                                                    placeholder: '',
                                                    readOnly: false,
                                                    options: ProjectPhases_Options || [],
                                                    setValue: (value, label) => HandlePhaseSelected(value, label),
                                                    isStatus: false,
                                                    isIcon: false,
                                                    isLabel: true,
                                                    isRequired: true,
                                                    label: "Project Phase",
                                                }}
                                            />
                                        </div>
                                        <div className="section-input-item">
                                            <TextInput
                                                props={{
                                                    id: "create-invoice-client",
                                                    value: Client_name,
                                                    placeholder: 'Select phase',
                                                    setValue: (value) => { },
                                                    isIcon: false,
                                                    isLabel: true,
                                                    isRequired: false,
                                                    readOnly: true,
                                                    label: "Phase Client",
                                                }}
                                            />
                                        </div>

                                    </div>
                                    <div className="section-input-items">
                                        <div className="section-input-item" style={{ height: 'max-content' }}>
                                            <MultiSelectInput
                                                props={{
                                                    id: 'assignee-multi-select-input',
                                                    placeholder: 'Select Assignees',
                                                    isRequired: true,
                                                    value: SelectedAssignees,
                                                    options: AssigneeOptions,
                                                    isIcon: false,
                                                    avoidDuplicate: true,
                                                    icon: '',
                                                    isLabel: true,
                                                    label: 'Assignees',
                                                    setValue: (value) => HandleSelectAssignee(value),
                                                }}
                                            />
                                        </div>
                                        <div className="section-input-item">
                                            <SelectInput
                                                props={{
                                                    id: "create-proposal-status",
                                                    value: status,
                                                    placeholder: '',
                                                    readOnly: false,
                                                    options: proposalStatusOptions || [],
                                                    setValue: (value, label) => {
                                                        setStatus(value)
                                                    },
                                                    isStatus: false,
                                                    isIcon: false,
                                                    isLabel: true,
                                                    isRequired: true,
                                                    label: "Status",
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>


                                {activeLayoutSettingsIds.includes('introduction_text') ?
                                    <div className="create-proposal-section" id={`create-proposal-introduction_text`}>

                                        <div className={`createproposal-description-main`}>
                                            <div className="head">
                                                <div className="icon" dangerouslySetInnerHTML={{ __html: Icons.general.description }}></div>
                                                <div className="label">Introduction Text</div>
                                            </div>
                                            <div className="item-expanded">
                                                <ReactQuill
                                                    theme="snow"
                                                    value={introduction_text || ""}
                                                    onChange={(value) => setIntroduction_text(value)}
                                                />
                                            </div>
                                        </div>

                                    </div>
                                    : ''}

                                {activeLayoutSettingsIds.includes('estimate') ?
                                    <div className="create-proposal-section" id={`create-proposal-estimate`}>
                                        <div className="createproposal-estimate-main">
                                            <div className="header">
                                                <div className="left">
                                                    <div className="title">BILL OF QUANTITIES</div>
                                                </div>
                                                <div className="right">
                                                    <div
                                                        className="active-buton"
                                                        onClick={() => HandleLayoutDirectItemOpen('estimate')}
                                                    >
                                                        <div className="icon" dangerouslySetInnerHTML={{ __html: Icons.general.layout_settings }}></div>
                                                        <div className="label">Customize</div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="estimate-table">

                                                <Paper style={{ width: '100%', height: '100%', boxShadow: 'none', overflow: 'hidden' }}>
                                                    <TableContainer style={{ height: '100%', boxShadow: 'none !important', }} >
                                                        <Table stickyHeader padding='dense'>
                                                            <TableHead
                                                                className='tabel-header estimate-tabel-header'
                                                            >
                                                                <TableRow>
                                                                    {
                                                                        tabelHeader.filter(th => !th.disable).filter(th => {
                                                                            let estimate_setting = getLayoutItem(`estimate`).item.dropdownOptions[0].checklists
                                                                            let isColumnEnabled = estimate_setting.filter(es => es.id == th.id)
                                                                            isColumnEnabled = isColumnEnabled.length ? isColumnEnabled[0].value : !th.disable
                                                                            return isColumnEnabled

                                                                        }).map((column) => (

                                                                            <TableCell

                                                                                key={column.id}
                                                                                align={column.align}
                                                                                style={{
                                                                                    minWidth: column.minWidth,
                                                                                    maxWidth: column.maxWidth,
                                                                                    paddingRight: `${column.id == 'select' ? '0' : '10px'}`
                                                                                }}
                                                                                className={`tabel-header-items ${column.id == 'select' ? 'table-default-toggle-item' : ''} '`}
                                                                            >
                                                                                {column.id == 'select' ?

                                                                                    <RadioInput props={{
                                                                                        value: tableSelectAll || false,
                                                                                        isIcon: false,
                                                                                        icon: "",
                                                                                        inputType: "toggle",
                                                                                        name: "table-default-toggle",
                                                                                        setValue: (value) => HandleSelectAllEvent(value)
                                                                                    }} />
                                                                                    :
                                                                                    column.label}
                                                                            </TableCell>
                                                                        ))}
                                                                </TableRow>
                                                            </TableHead>
                                                            <TableBody className='tabel-body'>
                                                                {estimateItems.map((row, i) => (
                                                                    CreateEstimateTableItem(row, i, String(i + 1))
                                                                ))}
                                                            </TableBody>
                                                        </Table>
                                                    </TableContainer>
                                                </Paper>

                                            </div>
                                        </div>
                                    </div>
                                    : ""}


                                {activeLayoutSettingsIds.includes('overheads') || activeLayoutSettingsIds.includes('insurances') ?
                                    <div className="create-proposal-section">
                                        <div className="createproject-section">

                                            <div className="proposal-summary-items">
                                                {activeLayoutSettingsIds.includes('overheads') && summaryItems[0] && summaryItems[0].id == 'overhead-summary-option' ?

                                                    <div className="proposal-summary-item" id={`create-proposal-overheads`}>
                                                        <div className="proposal-summary-item-head">
                                                            <div
                                                                className="icon"
                                                                dangerouslySetInnerHTML={{ __html: summaryItems[0].head.icon }}
                                                            ></div>
                                                            <div className="label">{summaryItems[0].head.label}</div>
                                                        </div>
                                                        <div className="proposal-summary-item-content">
                                                            {summaryOverHeads.map((item, i) => (

                                                                <div className="item" key={item.id} id={item.id}>
                                                                    <div className="title">{item.label}</div>
                                                                    <div className="quantity">{item.value_type == '%' ? `${item.value}%` : `${currency_type}${item.value}`}</div>
                                                                    <div className="amount">
                                                                        {currency_type} {item.value_type == '%' ? ((builderFinalCost * parseFloat(item.value)) / 100).toFixed(2) : parseFloat(item.value).toFixed(2)}
                                                                    </div>
                                                                </div>
                                                            ))}
                                                        </div>
                                                    </div>
                                                    : ''}
                                                {activeLayoutSettingsIds.includes('insurances') && summaryItems[1] && summaryItems[1].id == 'insurance-summary-option' ?

                                                    <div className="proposal-summary-item" id={`create-proposal-insurances`}>
                                                        <div className="proposal-summary-item-head">
                                                            <div
                                                                className="icon"
                                                                dangerouslySetInnerHTML={{ __html: summaryItems[1].head.icon }}
                                                            ></div>
                                                            <div className="label">{summaryItems[1].head.label}</div>
                                                        </div>
                                                        <div className="proposal-summary-item-content">
                                                            {summaryInsurance.map((item, i) => (

                                                                <div className="item" key={item.id} id={item.id}>
                                                                    <div className="title">{item.label}</div>
                                                                    <div className="quantity">{item.value_type == '%' ? `${item.value}%` : `${currency_type}${item.value}`}</div>
                                                                    <div className="amount">
                                                                        {currency_type} {item.value_type == '%' ? ((builderFinalCost * parseFloat(item.value)) / 100).toFixed(2) : parseFloat(item.value).toFixed(2)}
                                                                    </div>
                                                                </div>
                                                            ))}
                                                        </div>
                                                    </div>
                                                    : ''}

                                            </div>
                                        </div>
                                    </div>
                                    : ""}

                                {activeLayoutSettingsIds.includes('summary') ?
                                    <div className="create-proposal-section" id={`create-proposal-summary`}>
                                        <div className="createproject-section proposal-left-section">
                                            <div className="proposal-summary-totals proposal-summary-title">
                                                <div className="title">Summary</div>
                                                <div className="options">
                                                    <div className="select-input config-input">
                                                        <div
                                                            className="head"
                                                            onClick={() => HandleLayoutDirectItemOpen('summary')}
                                                        >
                                                            <div
                                                                className="icon"
                                                                dangerouslySetInnerHTML={{ __html: Icons.general.layout_settings }}
                                                            ></div>
                                                            <div className="label">Configure</div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="proposal-summary-totals proposal-summary-items">

                                                <div className="proposal-summary-item">
                                                    <div className="proposal-summary-item-content">

                                                        {summaryTotalItems.filter(s => s.enabled).filter(item => {
                                                            let estimate_setting = getLayoutItem(`summary`).item.dropdownOptions[0].checklists
                                                            let isColumnEnabled = estimate_setting.filter(es => es.id == item.id)
                                                            isColumnEnabled = isColumnEnabled.length ? isColumnEnabled[0].value : item.enabled
                                                            return isColumnEnabled
                                                        }).map((item, i) => (
                                                            <div className="item" key={item.id} id={item.id}>
                                                                <div className="title">{item.label}</div>
                                                                <div className="quantity"></div>
                                                                <div className="amount">
                                                                    {currency_type} {parseFloat(item.value || 0).toFixed(2)}
                                                                </div>
                                                            </div>
                                                        ))}
                                                        {
                                                            getLayoutItem(`summary`).item.dropdownOptions[0].checklists.some(ch => ch.id == 'price' && ch.value) ?
                                                                <div className="item summary-final-total" id='summary-final-total'>
                                                                    <div className="title">Final price</div>
                                                                    <div className="quantity"></div>
                                                                    <div className="amount">
                                                                        {currency_type} {parseFloat(summaryFinalTotal || 0).toFixed(2)}
                                                                    </div>
                                                                </div>
                                                                : ''}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    : ""}

                                {activeLayoutSettingsIds.includes('payment_schedule') ?
                                    <div className="create-proposal-section" id={`create-proposal-payment_schedule`}>
                                        <div className={`createproposal-description-main`}>
                                            <div className="head">
                                                <div className="label">Payment Schedule</div>
                                                <div className="icon" dangerouslySetInnerHTML={{ __html: Icons.general.info }}></div>
                                            </div>
                                            <div className="select-input">
                                                <div className="section-input-items">
                                                    <div className="section-input-item">
                                                        <SelectInput
                                                            props={{
                                                                id: "create-proposal-payment_schedule-input",
                                                                value: paymentScheduleId,
                                                                placeholder: '',
                                                                readOnly: false,
                                                                options: paymentScheduleOptions || [],
                                                                setValue: (value, label) => HandlePaymentSchedule(value, label),
                                                                isStatus: false,
                                                                isIcon: false,
                                                                isLabel: false,
                                                                isRequired: false,
                                                                label: "",
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                            </div>


                                            <div className="payment-splits-main">
                                                <div className="payment-splits-header">
                                                    <div className="header-item-name">Name</div>
                                                    <div className="header-item-milestone">Payment part</div>
                                                    <div className="header-item-amount">Amount</div>
                                                    <div className="header-item-action">Action</div>
                                                </div>
                                                <div className="payment-splits-content">
                                                    {paymentSplits.map((item, index) => (
                                                        <div className="payment-content-items">
                                                            <div className="payment-content-name">
                                                                <TextInput
                                                                    props={{
                                                                        id: "payment_schedule-paymentName",
                                                                        value: item.name,
                                                                        placeholder: '',
                                                                        setValue: (value) => HandlePaymentsInputChange(value, index, 'name'),
                                                                        isIcon: false,
                                                                        isLabel: false,
                                                                        isRequired: false,
                                                                        label: "Name",
                                                                    }}
                                                                />
                                                            </div>
                                                            <div className="payment-content-milestone">
                                                                <CostInput props={{
                                                                    id: `payment_schedule-milestone`,
                                                                    isCostMethod: true,
                                                                    currency_type: currency_type,
                                                                    value: item.value,
                                                                    isIcon: true,
                                                                    isRequired: false,
                                                                    icon: "%",
                                                                    setValue: (value, method) => HandlePaymentsInputChange(value, index, 'value', method)
                                                                }} />
                                                            </div>
                                                            <div className="payment-content-amount">{currency_type}{item.amount}</div>

                                                            <div className="payment-content-action">
                                                                <div
                                                                    className="action-remove"
                                                                    onClick={(e) => HandlePaymentsRemove(index)}
                                                                >
                                                                    <div className="icon" dangerouslySetInnerHTML={{ __html: Icons.general.close }}></div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    ))}
                                                </div>
                                                <div className={`payment-content-warning ${paymentSplitLimitExists ? 'warning-active' : ''}`}>
                                                    <div
                                                        className="icon"
                                                        dangerouslySetInnerHTML={{ __html: Icons.general.warning }}
                                                    ></div>
                                                    <div className="label">The sum of payment cannot exceed 100%.</div>
                                                </div>
                                                <div
                                                    className="payment-splits-action-buttons"
                                                >
                                                    <div
                                                        className="add-button"
                                                        onClick={(e) => HandlePaymentsAddNew()}
                                                    >
                                                        <div
                                                            className="icon"
                                                            dangerouslySetInnerHTML={{ __html: Icons.general.add_btn }}
                                                        ></div>
                                                        <div className="label">Add new payment milestone</div>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                    : ""}

                                {activeLayoutSettingsIds.includes('closing_text') ?
                                    <div className="create-proposal-section" id={`create-proposal-closing_text`}>

                                        <div className={`createproposal-description-main`}>
                                            <div className="head">
                                                <div className="icon" dangerouslySetInnerHTML={{ __html: Icons.general.description }}></div>
                                                <div className="label">Closing Text</div>
                                            </div>
                                            <div className="item-expanded">
                                                <ReactQuill
                                                    theme="snow"
                                                    value={closing_text}
                                                    onChange={(value) => setClosing_text(value)}
                                                />
                                            </div>
                                        </div>

                                    </div>
                                    : ""}



                                {activeLayoutSettingsIds.includes('attachments') ?
                                    <div className="create-proposal-section" id={`create-proposal-attachments`}>
                                        <div className={`createproposal-description-main`}>
                                            <div className="head">
                                                <div className="icon" dangerouslySetInnerHTML={{ __html: Icons.general.attachments }}></div>
                                                <div className="label">Attachments</div>
                                            </div>
                                            <div className="attachments-section-main">
                                                <div className="attachments-section-files">
                                                    <div className="attachment-input" onClick={HandleTriggerAddAttachment}>
                                                        <input type="file" onChange={HandleAddAttachment} ref={AttachmentsInputRef} accept='.png,.jpg,.jpeg' />
                                                        <div className="attachment-add-icon"
                                                            dangerouslySetInnerHTML={{ __html: Icons.general.add_attachments_plus }}
                                                        ></div>
                                                        <div className="attachment-label">Upload</div>
                                                        <div className="attachment-limit">Max limit: 5MB</div>
                                                    </div>
                                                    {AttachmentsFiles?.map((file, idx) => (
                                                        <div
                                                            className="attachment-file"
                                                            key={file.public_id}
                                                            id={`file-${file.public_id}`}
                                                        >
                                                            <div
                                                                className="attachment-delete-btn"
                                                                dangerouslySetInnerHTML={{ __html: Icons.general.close }}
                                                                onClick={() => HandleDeleteAttachment(idx)}
                                                            ></div>
                                                            <div className="attachment-file-preview" onClick={() => HandleSelectPreviewImage(file)}>
                                                                <img src={file.file_url} alt="attachment-file" />
                                                            </div>
                                                            <div className="attachment-file-footer">
                                                                <div className="file-name">{file.original_filename}</div>
                                                                <div
                                                                    className="file-download"
                                                                    dangerouslySetInnerHTML={{ __html: Icons.general.download }}
                                                                ></div>
                                                            </div>

                                                        </div>
                                                    ))}


                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    : ""}
                                {activeLayoutSettingsIds.includes('terms_conditions') ?
                                    <div className="create-proposal-section" id={`create-proposal-terms_conditions`}>
                                        <div className={`createproposal-description-main`}>
                                            <div className="head">
                                                <div className="label">Terms and conditions</div>
                                                <div className="icon" dangerouslySetInnerHTML={{ __html: Icons.general.info }}></div>
                                            </div>
                                            <div className="select-input">
                                                <div className="section-input-items">
                                                    <div className="section-input-item">
                                                        <SelectInput
                                                            props={{
                                                                id: "create-proposal-terms_conditions-input",
                                                                value: termsConditionId,
                                                                placeholder: '',
                                                                readOnly: false,
                                                                options: termsConditionOptions || [],
                                                                setValue: (value, label) => HandleTermsConditions(value, label),
                                                                isStatus: false,
                                                                isIcon: false,
                                                                isLabel: false,
                                                                isRequired: false,
                                                                label: "",
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="terms-condition-result" dangerouslySetInnerHTML={{ __html: termsConditionValue }}></div>
                                        </div>
                                    </div>
                                    : ""}

                                <div className="create-proposal-section">

                                    <div className={`createproposal-description-main`}>
                                        <div className="head">
                                            <div className="label">Comments</div>
                                        </div>
                                        <div className="comments-section-main">
                                            <div className="comments-section-items">

                                                {CommentsItems.map((item, idx) => (

                                                    <div
                                                        className="comments-item-main"
                                                        key={`comments-item-${item.id}`}
                                                        id={`comments-item-${item.id}`}
                                                    >
                                                        <div className="comments-item-usericon">
                                                            {item.created_by_name.charAt(0)}
                                                        </div>
                                                        <div className="comments-item-detials">
                                                            <div className="detials-head">
                                                                <div className="left">
                                                                    <div className="name">{item.created_by_name}</div>
                                                                    <div className="time">{Utils.getLocalFullDateLabel(item.created_at)}</div>
                                                                </div>
                                                                <div className="right">
                                                                    <div
                                                                        className="delete-icon"
                                                                        onClick={() => HandleDeleteComment(item.id, idx)}
                                                                        dangerouslySetInnerHTML={{ __html: Icons.general.delete_btn }}></div>
                                                                </div>
                                                            </div>
                                                            <div className="detials-message">{item.message}</div>
                                                        </div>
                                                    </div>


                                                ))}


                                                <div className="comments-input-main">
                                                    <div className="comments-input-usericon">
                                                        RT
                                                    </div>
                                                    <div className="comments-input comments-input-active">
                                                        <div className="comments-input-textarea">
                                                            <textarea
                                                                placeholder='Add a comment...'
                                                                value={CommentsInput}
                                                                onChange={(e) => setCommentsInput(e.target.value)}
                                                            ></textarea>
                                                        </div>
                                                        <div className={`comments-input-controls ${CommentsInput.length ? `comments-input-controls-active` : ''}`}>
                                                            <div
                                                                className={`comments-input-control-sendmsg`}
                                                                onClick={() => HandleAddComment()}
                                                            >
                                                                <div className="icon" dangerouslySetInnerHTML={{ __html: Icons.general.send_message }}></div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>

                                </div>

                            </div>


                            <div className="create-proposal-footer">
                                <div className="footer-item action-items">

                                    <div className="action-cancel" onClick={(e) => HandleCancel(e)}>
                                        Cancel
                                    </div>
                                    <button
                                        className={`action-btn`}
                                        type='submit'
                                        ref={formSubmitBtnRef}
                                    >

                                        <div className="label">Save</div>
                                    </button>
                                </div>
                            </div>

                        </div>
                    </form>
                </div>
                <div className={`createproposal-layoutsettings-main ${layoutsettingsOpen ? 'layoutsettings-open' : ''}`}>
                    <div className="createproposal-layoutsettings-main-content">
                        <div className="open-toggle-button" onClick={() => HandleLayoutOpen(!layoutsettingsOpen)}>
                            <div
                                className="icon"
                                dangerouslySetInnerHTML={{ __html: Icons.general.open_close_toggle }}
                            ></div>
                        </div>
                        {!layoutsettingsItemSelected ?
                            <div className="createproposal-layoutsettings-content">

                                <div className="header">
                                    <div className="left">
                                        <div className="title">
                                            <div
                                                className="icon"
                                                dangerouslySetInnerHTML={{ __html: Icons.general.config }}
                                            ></div>
                                            <div className="label">Layout settings</div>
                                        </div>
                                    </div>
                                    <div className="right">
                                        <div className="save-button">
                                            <div className="label">Save layout</div>
                                        </div>
                                    </div>
                                </div>
                                <div className="layoutsettings-preset-select">
                                    <SelectInput
                                        props={{
                                            id: "create-proposal-layoutpreset",
                                            value: selectedLayoutPresets,
                                            placeholder: '',
                                            readOnly: false,
                                            options: layoutPresets || [],
                                            setValue: (value, label) => {
                                                setSelectedLayoutPresets(value)
                                            },
                                            isStatus: false,
                                            isIcon: false,
                                            isLabel: false,
                                            isRequired: false,
                                            label: "",
                                        }}
                                    />
                                </div>
                                <div className="layoutsettings-items-table">

                                    <div className="table-items">
                                        {layoutsettingsItems?.map((item, i) => (

                                            <div className="table-row" key={`layoutsettings-item-${item.id}`} data-draggable-id={item.id}>
                                                <div
                                                    className="item-drag-icon"
                                                    dangerouslySetInnerHTML={{ __html: Icons.general.drag_updown }}
                                                ></div>
                                                <div
                                                    className="item-title"
                                                    style={{ width: '180px' }}
                                                    onClick={() => {
                                                        HandleLayoutItemClick(item.id)
                                                        if (item.isDropdown) HandleLayoutItemOpen(item, i)
                                                    }}
                                                >
                                                    <div className="icon" dangerouslySetInnerHTML={{ __html: item.icon }}></div>
                                                    <div className="label">{item.label || ""}</div>
                                                </div>
                                                {!item.disableToggle ?
                                                    <div className="item-toggle">
                                                        <RadioInput props={{
                                                            value: item.enabled,
                                                            isIcon: false,
                                                            icon: "",
                                                            inputType: "toggle",
                                                            name: "layoutsettings-default-toggle",
                                                            setValue: (value) => HandleLayoutItemEnable(value, item, i)
                                                        }} />
                                                    </div>
                                                    : ""}
                                                {item.isDropdown ?
                                                    <div className="item-dropdown" onClick={() => HandleLayoutItemOpen(item, i)}>
                                                        <div
                                                            className="icon"
                                                            dangerouslySetInnerHTML={{ __html: Icons.general.dropdown_arrow }}
                                                        ></div>
                                                    </div>
                                                    : ''}
                                            </div>

                                        ))}

                                    </div>
                                </div>
                            </div>
                            :

                            layoutsettingsItemSelected != undefined ?
                                <div className="layoutsettings-selected-content">
                                    <div className="header">
                                        <div className="left">
                                            <div className="title">
                                                <div
                                                    className="icon"
                                                    dangerouslySetInnerHTML={{ __html: Icons.general.back_arrow }}
                                                    onClick={() => HandleLayoutItemClose()}
                                                ></div>
                                            </div>
                                            <div className="title">
                                                <div
                                                    className="icon"
                                                    dangerouslySetInnerHTML={{ __html: layoutsettingsItemSelected.icon }}
                                                ></div>
                                                <div className="label">{layoutsettingsItemSelected.label}</div>
                                            </div>
                                        </div>
                                        <div className="right">
                                            <div className="item-toggle">
                                                <RadioInput props={{
                                                    value: layoutsettingsItemSelected.enabled,
                                                    isIcon: false,
                                                    icon: "",
                                                    inputType: "toggle",
                                                    name: "layoutsettings-default-toggle",
                                                    setValue: (value) => HandleLayoutItemEnable(value, layoutsettingsItemSelected, layoutsettingsItemSelected.index)
                                                }} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="message">
                                        Hide and show items you want to see in proposal
                                    </div>
                                    <div className="content">
                                        {layoutsettingsItemSelected?.dropdownOptions.map((option, i) => (
                                            <div className="content-item" key={`layoutsettings-item-${option.id}`}>
                                                <div className="content-item-header">


                                                    {option.isTitle ? option.title : ''}
                                                    {
                                                        option.isToggle ?
                                                            <div className="item-toggle">
                                                                <RadioInput props={{
                                                                    value: option.enabled,
                                                                    isIcon: false,
                                                                    icon: "",
                                                                    inputType: "toggle",
                                                                    name: "layoutsettings-default-toggle",
                                                                    setValue: (value) => HandleLayoutItemChecklistsToggleAll(value, layoutsettingsItemSelected, i)
                                                                }} />
                                                            </div>
                                                            : ''
                                                    }
                                                </div>
                                                <div className="content-item-checklists">
                                                    {option?.checklists.filter(ch => !ch.disable).map((checklist, cidx) => (
                                                        <div className="checklist-item" key={`layoutsettings-checklist-${checklist.id}`}>
                                                            <div className="input">
                                                                <RadioInput props={{
                                                                    value: checklist.value,
                                                                    isIcon: false,
                                                                    icon: "",
                                                                    inputType: "checkbox",
                                                                    name: "layoutsettings-default-toggle",
                                                                    setValue: (value) => HandleLayoutItemChecklistsToggle(value, layoutsettingsItemSelected, i, cidx)
                                                                }} />
                                                            </div>
                                                            <div className="label">{checklist.label}</div>
                                                        </div>
                                                    ))}
                                                </div>

                                            </div>
                                        ))}
                                    </div>
                                </div>
                                : ""
                        }
                    </div>
                </div>
            </div>
        </>
    )

}

export default CreateProposal;