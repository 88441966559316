

import React, { useEffect, useState, useRef } from 'react'
import { Route, Routes, BrowserRouter, Link, useLocation, Navigate, Outlet, useNavigate, useParams } from 'react-router-dom';
import store from '../../redux/store'
import StoreHandler from '../../redux/StoreHandler'
import { useSelector, useDispatch } from 'react-redux'
import UserAction from '../../redux/action/userAction'

import ReverseProxy from '../../config/reverseProxy'
import CostCatalogHandler from '../../Handlers/CostCatalog/CostCatalog';
import Icons from '../../assets/Icons'
import Images from '../../assets/Images'
import { CostInput, TextInput, SelectInput, QuantityInput, RadioInput } from '../../components/Inputs'
import Utils from '../../utils'
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

import AlertPopup from '../../components/AlertPopup'
import Loading from '../../components/Loading'
import SystemToastPopup from '../../components/SystemToastPopup'

import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';



const AddGroup = ({ props }) => {

    const { existItemData, updateCallback } = props


    const { itemType, itemId, actionType } = useParams()

    const costCatalogHandler = new CostCatalogHandler()

    const [name, setName] = useState('')
    const [group_id, setGroup_id] = useState('')
    const [group, setGroup] = useState('')
    const [description, setDescription] = useState('')

    const [isLoading, setIsLoading] = useState(false)
    const [warningAlert, setWarningAlert] = useState(false)
    const [warningAlertType, setWarningAlertType] = useState('warning')
    const [apiFailedMessage, setApiFailedMessage] = useState("Error in Server")


    const [currency_type, setCurrency_type] = useState('₹')

    const navigate = useNavigate()
    const store = useSelector((store) => store)
    const dispatch = useDispatch()
    const { updateState } = new UserAction


    const storeHandler = new StoreHandler({
        dataStack: [`costcode`],
        store: store,
        dispatch: dispatch,
        updateState: updateState,
    })

    const LoadStoreData = async () => {
        setIsLoading(true)
        let resolved = await storeHandler.init()
        setIsLoading(false)
    }

    const isExistItemData = existItemData && existItemData.id != undefined
    const [additionalNotes, setAdditionalNotes] = useState(false);


    const AddBtnOptions = [
        {
            icon: Icons.general.group,
            label: 'Category',
            type: 'group',
            id: "group"
        },
        {
            icon: Icons.general.labour,
            label: 'Labour',
            type: 'item',
            id: "labour"
        },
        {
            icon: Icons.general.material,
            label: 'Materials',
            type: 'item',
            id: "material"
        },
        {
            icon: Icons.general.machines,
            label: 'Machines',
            type: 'item',
            id: "machine"
        },
        {
            icon: Icons.general.subcontractor,
            label: 'Subcontractor',
            type: 'item',
            id: "subcontractor"
        },
        {
            icon: Icons.general.fee,
            label: 'Fee',
            type: 'item',
            id: "fee"
        },
        {
            icon: Icons.general.allowance,
            label: 'Allowances',
            type: 'item',
            id: "allowance"
        },
        {
            icon: Icons.general.work,
            label: 'Work',
            type: 'work',
            id: 'work'
        },
    ]

    const [AddBtnOption, setAddBtnOption] = useState(AddBtnOptions.filter(opt => opt.id == itemType)[0] || undefined)

    const [groupOptions, setGroupOptions] = useState([])

    const getEstimateGroups = async () => {

        let filter = {
            type: 1
        }

        let response = await costCatalogHandler.getCostCatalogItemsHandler(filter)

        if (response.success) {

            let { data } = response

            if (!Array.isArray(data)) return

            let getSubItemsIds = (items, parentid) => {

                let subGroupIds = []

                let getItems = (items, parentid) => {

                    if (!Array.isArray(items)) return

                    let _items = items.filter(itm => itm.parent_id == parentid)

                    subGroupIds.push(..._items)

                    _items.forEach(itm => getItems(items, itm.id))
                }

                getItems(items, parentid)

                subGroupIds = subGroupIds.map(g => g.id)

                subGroupIds.push(parentid)

                return subGroupIds
            }

            if (isExistItemData) {

                data = data.filter(d => !getSubItemsIds(data, existItemData.id).includes(d.id))
            }

            data = data.filter(d => d.type == '1').map(d => {

                return {
                    value: d.id,
                    label: d.name,
                }

            })



            setGroupOptions(data)
        }
    }

    const HandlePopupSubmit = async (e) => {
        e.preventDefault()

        let updated_data = {
            name,
            parent_id: group_id || undefined,
            parent_name: group || undefined,
            type: 1,
            expanded: false,
            description,

        }
        setIsLoading(true)

        let response = { success: false }

        if (actionType == 'edit' && isExistItemData && existItemData.id) {
            updated_data['id'] = existItemData.id
            response = await costCatalogHandler.updateCostCatalogGroupHandler(updated_data)
        }
        else if (actionType == 'add') response = await costCatalogHandler.createCostCatalogGroupHandler(updated_data)

        setIsLoading(false)

        if (response.success) {

            if (typeof updateCallback == 'function') updateCallback()
            navigate(`/builder/cost-catalog`)
        }
        else {
            setWarningAlert(true)
            setWarningAlertType('error')
            setApiFailedMessage(`Error in Adding Cost Catalog`)
        }


    }

    const Popup_Header = ({ props }) => {

        const { icon, label } = props
        return (
            <div className="side-popup-header">
                <div className="header-item-select">
                    <div className="header-item-select-content">
                        <span className="icon" dangerouslySetInnerHTML={{ __html: icon }}></span>
                        <div className="label">{actionType == 'edit' ? 'Update' : 'Add'} {label || 'Group'}</div>

                    </div>
                </div>
                <div
                    className="header-item-close"
                    onClick={(e) => {
                        navigate(`/builder/cost-catalog`)
                    }}
                    dangerouslySetInnerHTML={{ __html: Icons.general.close }}
                ></div>
            </div>
        );
    };
    const Popup_Footer = ({ props }) => {

        const { icon, label } = props

        return (
            <div className="sidebar-popup-footer">
                <div className="footer-item action-items">
                    <div className="action-preview">
                    </div>
                    <button
                        className="action-btn"
                        type='submit'
                    >
                        <div className="icon" dangerouslySetInnerHTML={{ __html: actionType == 'edit' ? Icons.general.save : Icons.general.add_btn }}></div>
                        <div className="label">Save</div>
                    </button>
                </div>
            </div>
        );
    };

    const getEstimateItemTypeEenem = (type) => {

        if (type == '1') return 'group'
        else if (type == '2') return 'work_template'
        else if (type == '3') return 'item'
        else if (type == '4') return 'work'
        else return ''

    }
    const getEstimateItemCategoryEenem = (type) => {

        if (type == '1') return 'material'
        else if (type == '2') return 'labour'
        else if (type == '3') return 'machine'
        else if (type == '4') return 'subcontractor'
        else if (type == '5') return 'fee'
        else if (type == '6') return 'allowance'
        else return ''

    }
    const getEstimateItemStatusEenem = (type) => {

        if (type == '0') return 'incomplete'
        else if (type == '1') return 'completed'
        else if (type == '2') return 'hold'
        else if (type == '3') return 'stopped'
        else return ''
    }

    const getFormatedEstimateItem = (item) => {

        return {
            sno: item.sno,
            expanded: true,
            selected: false,
            disable: false,

            parent_id: item.parent_id,
            parent_name: item.parent_name,
            id: item.id,
            name: item.name,
            description: item.description,

            costcode_id: item.costcode_id || "",
            costcode_name: item.costcode_name || "",


            co_efficient: parseFloat(item.co_efficient || 0) || 0,
            per_co_efficient_cost: parseFloat(item.per_co_efficient_cost || 0) || 0,

            type: getEstimateItemTypeEenem(item.type),
            item_type: item.type == '1' ? 'group' : getEstimateItemCategoryEenem(item.item_type),
            cost_type: getEstimateItemCategoryEenem(item.item_type) || "",
            _status: item.status,
            status: getEstimateItemStatusEenem(item.status),

            quantity: parseFloat(item.quantity || 0) || 0,
            unitrate: parseFloat(item.unitrate || 0) || 0,
            builder_price: parseFloat(item.builder_price || 0) || 0,
            price: parseFloat(item.price || 0) || 0,


            unit_id: item.unit_id || "",
            unit_name: item.unit_name || "",

            markup_id: item.markup_id || "",
            markup_name: item.markup_name || "",
            markup_value: parseFloat(item.markup_value || 0) || 0,
            markup_value_type: item.markup_value_type || "",
            markup_amount: parseFloat(item.markup_amount || 0) || 0,

            tax_id: item.tax_id || "",
            tax_name: item.tax_name || "",
            tax_value_type: item.tax_value_type || "",
            tax_value: parseFloat(item.tax_value || 0) || 0,
            tax_amount: parseFloat(item.tax_amount || 0) || 0,

            sub_items: []
        }

    }

    const LoadEstimateItem = async (idx) => {

        if (idx == undefined) return

        let filter = {
            idx: idx
        }

        setIsLoading(true)

        let response = await costCatalogHandler.getCostCatalogItemsHandler(filter)
        setIsLoading(false)

        if (response.success) {


            let { data } = response

            if (!Array.isArray(data) || !data.length) return

            let item = getFormatedEstimateItem(data[0])

            setName(item.name || '')
            setAdditionalNotes(item.description ? true : false)
            setDescription(item.description || '')
            setGroup_id(item.parent_id || '')
            setGroup(item.parent_name || '')

        }
        else {
            setWarningAlert(true)
            setWarningAlertType('error')
            setApiFailedMessage(`Error in Estimation Items`)
        }
    }


    useEffect(() => {

        // LoadStoreData()
        getEstimateGroups()

        if (actionType == 'edit' && isExistItemData) {

            setName(existItemData.name || '')
            setAdditionalNotes(existItemData.description ? true : false)
            setDescription(existItemData.description || '')
            setGroup_id(existItemData.parent_id || '')
            setGroup(existItemData.parent_name || '')
        }

        if (actionType == 'edit' && !isExistItemData) LoadEstimateItem(itemId)

    }, [])

    return (
        <div className="popup-container-main popup-container-center">
            <div className="popup-block-ui"></div>
            <form className="side-popup-container" onSubmit={HandlePopupSubmit}>

                <Popup_Header props={AddBtnOption} />

                <div className="sidebar-popup-content">
                    <div className="content-section">


                        <div className="content-item">
                            <TextInput
                                props={{
                                    id: "costcatalog-group-name",
                                    value: name,
                                    placeholder: '',
                                    setValue: (value) => setName(value),
                                    isIcon: false,
                                    isLabel: true,
                                    isRequired: true,
                                    label: "Name",
                                }}
                            />
                        </div>
                        <div className="content-item">
                            <SelectInput
                                props={{
                                    id: "estimation-group-group",
                                    value: group_id,
                                    placeholder: '',
                                    options: groupOptions || [],
                                    setValue: (value, label) => {
                                        setGroup_id(value)
                                        setGroup(label)
                                    },
                                    isIcon: false,
                                    isLabel: true,
                                    isRequired: false,
                                    label: "Parent",
                                }}
                            />

                        </div>
                        <div className="content-section">
                            <div className="content-item">
                                <div id="estimation-group-description" className={`additional-item ${additionalNotes ? 'additional-item-active' : ''}`}>
                                    <div className="head" onClick={(e) => setAdditionalNotes(!additionalNotes)}>
                                        <div className="icon" dangerouslySetInnerHTML={{ __html: Icons.general.description }}></div>
                                        <div className="label">Description</div>
                                    </div>
                                    <div className="item-expanded">
                                        <ReactQuill
                                            theme="snow"
                                            value={description}
                                            onChange={(value) => setDescription(value)}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>

                <Popup_Footer props={AddBtnOption} />
            </form>
        </div>
    )
}
const AddItem = ({ props }) => {

    const { existItemData, updateCallback } = props

    const { itemType, itemId, actionType } = useParams()

    const [isLoading, setIsLoading] = useState(false)
    const [warningAlert, setWarningAlert] = useState(false)
    const [warningAlertType, setWarningAlertType] = useState('warning')
    const [apiFailedMessage, setApiFailedMessage] = useState("Error in Server")

    const costCatalogHandler = new CostCatalogHandler()

    const [currency_type, setCurrency_type] = useState('₹')

    const navigate = useNavigate()
    const store = useSelector((store) => store)
    const dispatch = useDispatch()
    const { updateState } = new UserAction

    const storeHandler = new StoreHandler({
        dataStack: ['costcodes', 'company', 'field_settings', 'finance_settings'],
        store: store,
        dispatch: dispatch,
        updateState: updateState,
    })

    const LoadStoreData = async () => {
        setIsLoading(true)
        let resolved = await storeHandler.init()
        setIsLoading(false)
    }
    const getEstimateGroups = async () => {

        let filter = {
            type: 1,
        }

        let response = await costCatalogHandler.getCostCatalogItemsHandler(filter)

        if (response.success) {

            let { data } = response

            if (!Array.isArray(data)) return

            data = data.filter(d => d.type == '1').map(d => {

                return {
                    value: d.id,
                    label: d.name,
                }

            })

            setGroupOptions(data)
        }
    }

    const AddBtnOptions = [
        {
            icon: Icons.general.group,
            label: 'Category',
            type: 'group',
            id: "group"
        },

        {
            icon: Icons.general.labour,
            label: 'Labour',
            type: 'item',
            id: "labour"
        },
        {
            icon: Icons.general.material,
            label: 'Materials',
            type: 'item',
            id: "material"
        },
        {
            icon: Icons.general.machines,
            label: 'Machines',
            type: 'item',
            id: "machine"
        },
        {
            icon: Icons.general.subcontractor,
            label: 'Subcontractor',
            type: 'item',
            id: "subcontractor"
        },
        {
            icon: Icons.general.fee,
            label: 'Fee',
            type: 'item',
            id: "fee"
        },
        {
            icon: Icons.general.allowance,
            label: 'Allowances',
            type: 'item',
            id: "allowance"
        },
        {
            icon: Icons.general.work,
            label: 'Work',
            type: 'work',
            id: 'work'
        },
    ]

    const [AddBtnOption, setAddBtnOption] = useState(AddBtnOptions.filter(opt => opt.id == itemType)[0] || undefined)

    const isExistItemData = existItemData && existItemData.id != undefined

    const [enableAllowance, setEnableAllowance] = useState(false)
    const [allowanceFor, setAllowanceFor] = useState({
        manpower: false,
        labour: false,
        machine: false,
        material: false,
        subcontractor: false,
        fee: false,
    })

    const [name, setName] = useState('')

    const [group_id, setGroup_id] = useState('')
    const [group, setGroup] = useState('')

    const [costcode_id, setCostcode_id] = useState('')
    const [costcode_name, setCostcode_name] = useState('')

    const [id, setId] = useState(isExistItemData ? existItemData.id : '')
    const [quantity, setQuantity] = useState('')
    const [unit_id, setUnit_id] = useState('')
    const [unit_name, setUnit_name] = useState('')
    const [unitrate, setUnitrate] = useState('')
    const [description, setDescription] = useState('')
    const [additionalNotes, setAdditionalNotes] = useState(false);

    const [tax_id, setTax_id] = useState('')
    const [tax_name, setTax_name] = useState('')
    const [tax_value, setTax_value] = useState(0)

    const [markup_id, setMarkup_id] = useState('')
    const [markup_name, setMarkup_name] = useState('')
    const [markup_value, setMarkup_value] = useState(0)
    const [markup_type, setMarkup_type] = useState('%')

    const [parent_type, setParent_type] = useState('')
    const [parent_quantity, setParent_quantity] = useState(1)


    const [costcodeOptions, setCostcodeOptions] = useState([])
    const [unitsOptions, setUnitsOptions] = useState([])
    const [taxesOptions, setTaxesOptions] = useState([])
    const [markupsOptions, setMarkupsOptions] = useState([])
    const [groupOptions, setGroupOptions] = useState([])

    const unti_tax = (parseFloat((unitrate * parent_quantity * quantity) || 0) * parseFloat(tax_value)) / 100
    const unti_markup = markup_type == '%' ? (parseFloat((unitrate * parent_quantity * quantity) || 0) * parseFloat(markup_value)) / 100 : parseFloat(markup_value)

    const price = (unitrate * parent_quantity * quantity) + unti_tax + unti_markup


    useEffect(() => {

        if (store && store.user) {

            let { company, costcodes, clients, settings } = store.user



            if (company) {

                let { currency_setting, timezone, temperature_setting } = company

                if (currency_setting) setCurrency_type(currency_setting)
            }
            if (Array.isArray(costcodes)) {

                costcodes = costcodes.filter(opt => opt.type == '1').map((opt) => {
                    return {
                        value: opt.id,
                        label: opt.name,
                    }
                })

                setCostcodeOptions(costcodes)
            }


            if (settings && Array.isArray(settings.field_settings) && settings.field_settings.length) {

                let { units } = settings.field_settings[0]

                units = units.filter(opt => opt.isActive).map((opt) => {
                    return {
                        value: opt.id,
                        label: opt.name,
                        isDefault: opt.isDefault || false
                    }
                })

                setUnitsOptions(units || [])

            }

            if (settings && Array.isArray(settings.finance_settings) && settings.finance_settings.length) {

                let { taxes, markups, overheads, insurance } = settings.finance_settings[0]

                taxes = taxes.filter(opt => opt.isActive).map((opt) => {
                    return {
                        id: opt.id,
                        value: parseFloat(opt.value || 0),
                        label: opt.name,
                        type: opt.type,
                        isDefault: opt.isDefault || false
                    }
                })

                setTaxesOptions(taxes || [])


                markups = markups.filter(opt => opt.isActive).map((opt) => {
                    return {
                        id: opt.id,
                        value: parseFloat(opt.value || 0),
                        label: opt.name,
                        type: opt.value_type,
                        isDefault: opt.isDefault || false
                    }
                })

                setMarkupsOptions(markups || [])
            }


        }

    }, [store])

    const getEstimateItemTypeEenemReverse = (type) => {

        if (type == 'material') return 1
        else if (type == 'labour') return 2
        else if (type == 'machine') return 3
        else if (type == 'subcontractor') return 4
        else if (type == 'fee') return 5
        else if (type == 'allowance') return 6
        else return 0

    }


    const getEstimateItemStatusEenemReverse = (type) => {

        if (type == 'incomplete') return 0
        else if (type == 'completed') return 1
        else if (type == 'hold') return 2
        else if (type == 'stopped') return 3
        else return -1

    }
    const getEstimateItemStatusEenem = (type) => {

        if (type == '0') return 'incomplete'
        else if (type == '1') return 'completed'
        else if (type == '2') return 'hold'
        else if (type == '3') return 'stopped'
        else return ''
    }

    const HandlePopupSubmit = async (e) => {
        e.preventDefault()


        let updated_data = {

            name: name,

            unit_id: unit_id,
            unit_name: unit_name,
            unitrate: String(unitrate),
            quantity: String(quantity),

            costcode_id: costcode_id,
            costcode_name: costcode_name,

            parent_type: String(parent_type) || "",
            parent_quantity: String(parent_quantity) || "",

            parent_id: group_id || undefined,
            parent_name: group || undefined,

            tax_id: tax_id,
            tax_name: tax_name,
            tax_value: String(tax_value),
            tax_amount: String(unti_tax),


            markup_id: markup_id,
            markup_name: markup_name,
            markup_value: String(markup_value),
            markup_value_type: markup_type,
            markup_amount: String(unti_markup),

            expanded: false,
            builder_price: String(unitrate * quantity),
            price: String(price),
            type: 3,
            item_type: getEstimateItemTypeEenemReverse(AddBtnOption.id),
            description: description,
        }

        setIsLoading(true)

        let response = { success: false }

        if (actionType == 'edit' && id) {
            updated_data['id'] = id
            response = await costCatalogHandler.updateCostCatalogItemHandler(updated_data)
        }
        else if (actionType == 'add') response = await costCatalogHandler.createCostCatalogItemHandler(updated_data)

        if (response.success) {
            setIsLoading(false)

            console.log(updateCallback, 'updateCallback');
            if (typeof updateCallback == 'function') updateCallback()
            navigate(`/builder/cost-catalog`)
        }
        else {
            setIsLoading(false)
            setWarningAlert(true)
            setWarningAlertType('error')
            setApiFailedMessage(`Error in Adding Cost Catalog`)
        }


    }


    const Popup_Header = ({ props }) => {

        const { icon, label } = props
        return (
            <div className="side-popup-header">
                <div className="header-item-select">
                    <div className="header-item-select-content">
                        <span className="icon" dangerouslySetInnerHTML={{ __html: icon }}></span>
                        <div className="label">{actionType == 'edit' ? 'Update' : 'Add'} {label || "Item"}</div>

                    </div>
                </div>
                <div
                    className="header-item-close"
                    onClick={(e) => {
                        navigate(`/builder/cost-catalog`)
                    }}
                    dangerouslySetInnerHTML={{ __html: Icons.general.close }}
                ></div>
            </div>
        );
    };
    const Popup_Footer = ({ props }) => {

        const { icon, label } = props

        return (
            <div className="sidebar-popup-footer">
                <div className="footer-item action-items">
                    <div className="action-preview">
                        <div className="label">{label} Price</div>
                        <div className="value">{currency_type}{price || '0.00'}</div>
                    </div>
                    <button
                        className="action-btn"
                        type='submit'
                    >
                        <div className="icon" dangerouslySetInnerHTML={{ __html: actionType == 'edit' ? Icons.general.save : Icons.general.add_btn }}></div>
                        <div className="label">{actionType == 'edit' ? 'Save' : 'Add Item'}</div>
                    </button>
                </div>
            </div>
        );
    };


    const getEstimateItemTypeEenem = (type) => {

        if (type == '1') return 'group'
        else if (type == '2') return 'work_template'
        else if (type == '3') return 'item'
        else if (type == '4') return 'work'
        else return ''

    }
    const getEstimateItemCategoryEenem = (type) => {

        if (type == '1') return 'material'
        else if (type == '2') return 'labour'
        else if (type == '3') return 'machine'
        else if (type == '4') return 'subcontractor'
        else if (type == '5') return 'fee'
        else if (type == '6') return 'allowance'
        else return ''

    }


    const getFormatedEstimateItem = (item) => {

        return {
            sno: item.sno,
            expanded: true,
            selected: false,
            disable: false,

            parent_id: item.parent_id,
            parent_name: item.parent_name,
            id: item.id,
            name: item.name,
            description: item.description,

            costcode_id: item.costcode_id || "",
            costcode_name: item.costcode_name || "",

            co_efficient: parseFloat(item.co_efficient || 0) || 0,
            per_co_efficient_cost: parseFloat(item.per_co_efficient_cost || 0) || 0,

            type: getEstimateItemTypeEenem(item.type),
            item_type: item.type != '3' ? getEstimateItemTypeEenem(item.type) : getEstimateItemCategoryEenem(item.item_type),
            cost_type: item.type != '1' && item.type != '3' ? getEstimateItemTypeEenem(item.type) : getEstimateItemCategoryEenem(item.item_type),

            parent_type: parseInt(item.parent_type),
            parent_quantity: parseFloat(item.parent_quantity || 0),

            _status: item.status,
            status: getEstimateItemStatusEenem(item.status),

            quantity: parseFloat(item.quantity || 0) || 0,
            unitrate: parseFloat(item.unitrate || 0) || 0,
            builder_price: parseFloat(item.builder_price || 0) || 0,
            price: parseFloat(item.price || 0) || 0,


            unit_id: item.unit_id || "",
            unit_name: item.unit_name || "",

            markup_id: item.markup_id || "",
            markup_name: item.markup_name || "",
            markup_value: parseFloat(item.markup_value || 0) || 0,
            markup_value_type: item.markup_value_type || "",
            markup_amount: parseFloat(item.markup_amount || 0) || 0,

            tax_id: item.tax_id || "",
            tax_name: item.tax_name || "",
            tax_value_type: item.tax_value_type || "",
            tax_value: parseFloat(item.tax_value || 0) || 0,
            tax_amount: parseFloat(item.tax_amount || 0) || 0,

            sub_items: []
        }

    }

    const LoadEstimateItem = async (idx) => {

        if (idx == undefined) return

        let filter = {
            idx: idx
        }

        setIsLoading(true)

        let response = await costCatalogHandler.getCostCatalogItemsHandler(filter)
        setIsLoading(false)

        if (response.success) {


            let { data } = response


            if (!Array.isArray(data) || !data.length) return


            let items = data.map(d => {

                let item = getFormatedEstimateItem(d)

                let tax_amount = item.tax_amount
                let markup_amount = item.markup_amount
                let builder_price = item.builder_price
                let price = item.price

                item._tax_amount = tax_amount || ""
                item._tax_percent = ((tax_amount / builder_price) * 100 || 0).toFixed(2)
                item._markup_amount = markup_amount || ""
                item._markup_percent = ((markup_amount / builder_price) * 100 || 0).toFixed(2)
                item._builder_price = builder_price || ""
                item._price = price || ""
                item._unitrate = item.unitrate || ""
                item._quantity = item.quantity || ""

                item.tax_amount = tax_amount ? `${currency_type}${tax_amount}` : ""
                item.markup_amount = markup_amount ? `${currency_type}${markup_amount}` : ""
                item.builder_price = builder_price ? `${currency_type}${builder_price}` : ""
                item.price = price ? `${currency_type}${price}` : ""
                item.unitrate = item.unitrate ? `${currency_type}${item.unitrate}` : ""
                item.quantity = item.quantity ? `${item.quantity}${item.unit_name || ""}` : ""

                return item

            })

            let item = items[0]

            setId(item.id)

            setParent_type(parseInt(item.parent_type))
            setParent_quantity(item.parent_quantity)

            setName(item.name || '')

            setCostcode_id(item.costcode_id || '')
            setCostcode_name(item.costcode_name || '')

            setGroup_id(item.parent_id || '')
            setGroup(item.parent_name || '')

            setUnit_id(item.unit_id || '')
            setUnit_name(item.unit_name || '')
            setQuantity(parseFloat(item._quantity || 0) || '')
            setUnitrate(parseFloat(item._unitrate || 0) || '')
            setDescription(item.description || '')

            setAdditionalNotes(item.description ? true : false)

            setTax_id(item.tax_id || '')
            setTax_name(item.tax_name || '')
            setTax_value(parseFloat(item.tax_value || 0) || 0)

            setMarkup_id(item.markup_id || '')
            setMarkup_name(item.markup_name || '')
            setMarkup_value(parseFloat(item.markup_value || 0) || 0)
            setMarkup_type(item.markup_value_type)

        }
        else {
            setWarningAlert(true)
            setWarningAlertType('error')
            setApiFailedMessage(`Error in Estimation Items`)
        }
    }


    useEffect(() => {

        LoadStoreData()
        getEstimateGroups()

        if (actionType == 'edit' && isExistItemData) {

            if (existItemData.parent_type) {
                setParent_type(existItemData.parent_type || '')
                setParent_quantity(existItemData.parent_quantity || 0)
            }

            setId(existItemData.id)

            setName(existItemData.name || '')

            setCostcode_id(existItemData.costcode_id || '')
            setCostcode_name(existItemData.costcode_name || '')

            setGroup_id(existItemData.parent_id || '')
            setGroup(existItemData.parent_name || '')

            setUnit_id(existItemData.unit_id || '')
            setUnit_name(existItemData.unit_name || '')

            setQuantity(parseFloat(existItemData._quantity || 0) || '')
            setUnitrate(parseFloat(existItemData._unitrate || 0) || '')
            setDescription(existItemData.description || '')

            setAdditionalNotes(existItemData.description ? true : false)

            setTax_id(existItemData.tax_id || '')
            setTax_name(existItemData.tax_name || '')
            setTax_value(parseFloat(existItemData.tax_value || 0) || 0)

            setMarkup_id(existItemData.markup_id || '')
            setMarkup_name(existItemData.markup_name || '')
            setMarkup_value(parseFloat(existItemData.markup_value || 0) || 0)
            setMarkup_type(existItemData.markup_value_type)


        }

        if (actionType == 'edit' && !isExistItemData) LoadEstimateItem(itemId)

    }, [])

    return (

        <div className="popup-container-main propup-container-center">
            <div className="popup-block-ui"></div>
            <form className="side-popup-container animateXRight animateXRightactive" onSubmit={HandlePopupSubmit}>
                <Popup_Header props={AddBtnOption} />

                <div className="sidebar-popup-content">
                    <div className="content-section">

                        <div className="content-item">
                            <TextInput
                                props={{
                                    id: "costcatalog-work-name",
                                    value: name,
                                    placeholder: '',
                                    setValue: (value) => {
                                        setName(value)
                                    },
                                    isIcon: false,
                                    isLabel: true,
                                    isRequired: true,
                                    label: "Name",
                                }}
                            />
                        </div>

                        {parent_type != '4' ?
                            <div className="content-item">
                                <SelectInput
                                    props={{
                                        id: "costcatalog-work-group",
                                        value: group_id,
                                        placeholder: '',
                                        options: groupOptions || [],
                                        setValue: (value, label) => {
                                            setGroup_id(value)
                                            setGroup(label)
                                        },
                                        isIcon: false,
                                        isLabel: true,
                                        isRequired: false,
                                        label: "Category",
                                    }}
                                />
                            </div>
                            : ''}

                        <div className="content-item">
                            <SelectInput
                                props={{
                                    id: "costcatalog-work-costcode",
                                    value: costcode_id,
                                    placeholder: '',
                                    options: costcodeOptions || [],
                                    setValue: (value, label) => {
                                        setCostcode_id(value)
                                        setCostcode_name(label)
                                    },
                                    isIcon: false,
                                    isLabel: true,
                                    isRequired: true,
                                    label: "Work Category",
                                }}
                            />
                        </div>

                        <div className="content-item">

                            {parent_type != '4' ?
                                <TextInput
                                    props={{
                                        id: "costcatalog-work-quantity",
                                        value: quantity,
                                        type: 'number',
                                        placeholder: '',
                                        setValue: (value) => {
                                            setQuantity(value)
                                        },
                                        isIcon: false,
                                        isLabel: true,
                                        isRequired: true,
                                        label: "Quantity",
                                    }}
                                />
                                :

                                <QuantityInput
                                    props={{
                                        parentQty: String(parent_quantity),
                                        actionType: "multiple",
                                        value: String(quantity),
                                        placeholder: '',
                                        setValue: (value) => setQuantity(parseFloat(value)),
                                        isIcon: false,
                                        isLabel: true,
                                        isRequired: true,
                                        label: "Quantity (Main Qty x Work Quantity)",
                                        id: "costcatalog-work-quantity"
                                    }}
                                />
                            }

                        </div>
                        <div className="content-item">

                            <div className="content-sub-item">
                                <SelectInput
                                    props={{
                                        id: "costcatalog-work-unit",
                                        value: unit_id,
                                        placeholder: '',
                                        readOnly: false,
                                        options: unitsOptions || [],
                                        setValue: (value, label) => {
                                            setUnit_id(value)
                                            setUnit_name(label)
                                        },
                                        isIcon: false,
                                        isLabel: true,
                                        isRequired: true,
                                        label: "Unit type",
                                    }}
                                />
                            </div>

                            <div className="content-sub-item">
                                <CostInput
                                    props={{
                                        id: "costcatalog-work-unitrate",
                                        value: unitrate,
                                        isCostMethod: false,
                                        currency_type: currency_type,
                                        readOnly: false,
                                        placeholder: '',
                                        setValue: (value) => {
                                            setUnitrate(value)
                                        },
                                        isIcon: true,
                                        icon: currency_type,
                                        isLabel: true,
                                        isRequired: true,
                                        label: "Unit rate",
                                    }}
                                />
                            </div>

                        </div>
                        <div className="content-item">
                            <CostInput
                                props={{
                                    id: "costcatalog-work-price",
                                    value: price,
                                    currency_type: currency_type,
                                    isCostMethod: false,
                                    placeholder: '',
                                    readOnly: true,
                                    setValue: (value) => {
                                        // setPrice(value)
                                    },
                                    isIcon: true,
                                    icon: currency_type,
                                    isLabel: true,
                                    isRequired: false,
                                    label: "Amount",
                                }}
                            />
                        </div>
                    </div>
                    <div className="content-section">
                        <div className="content-section-header">
                            <div className="label">Pricing</div>
                        </div>

                        <div className="content-section-header">
                            <div className="label">Tax</div>
                            <div className="input">
                                <select
                                    name=""
                                    id=""
                                    onInput={(e) => {

                                        let tax = taxesOptions?.filter(t => t.id == e.target.value)[0]

                                        if (tax) {

                                            setTax_id(tax.id)
                                            setTax_value(parseFloat(tax.value || 0))
                                            setTax_name(tax.label)
                                        }
                                    }}
                                    value={tax_id}
                                >
                                    {taxesOptions?.map((tax, i) => (
                                        <option value={`${tax.id}`}>{tax.label}</option>
                                    ))}
                                </select>
                            </div>
                        </div>
                        <div className="content-item">
                            <div className="content-sub-item">
                                <CostInput
                                    props={{
                                        value: tax_value,
                                        isCostMethod: false,
                                        currency_type: currency_type,
                                        placeholder: 'Unit cost',
                                        setValue: (value) => {
                                            setTax_value(parseFloat(value) || 0)
                                        },
                                        isIcon: true,
                                        icon: '%',
                                        isLabel: true,
                                        isRequired: true,
                                        label: "Tax",
                                        id: "costcatalog-item-tax"
                                    }}
                                />
                            </div>
                            <div className="content-sub-item content-sub-label-item">
                                <div className="content-sub-label-content">
                                    <div className="label">Unit tax</div>
                                    <div className="value">{currency_type}{unti_tax}</div>
                                </div>
                            </div>

                        </div>

                        <div className="content-section-header">
                            <div className="label">Markup</div>
                            <div className="input">
                                <select
                                    name=""
                                    id=""
                                    onInput={(e) => {

                                        let item = markupsOptions.filter(itm => itm.id == e.target.value)[0]
                                        if (item) {
                                            setMarkup_value(parseFloat(item.value || 0))
                                            setMarkup_id(item.id)
                                            setMarkup_name(item.label)
                                            setMarkup_type(item.type)
                                        }
                                    }}
                                    value={markup_id}
                                >
                                    {markupsOptions.map((itm, i) => (
                                        <option value={`${itm.id}`}>{itm.label}</option>
                                    ))}
                                </select>
                            </div>
                        </div>
                        <div className="content-item">
                            <div className="content-sub-item">
                                <CostInput
                                    props={{
                                        value: markup_value,
                                        costMethodValue: markup_type,
                                        isCostMethod: true,
                                        currency_type: currency_type,
                                        placeholder: 'Unit cost',
                                        setValue: (value, type) => {
                                            setMarkup_value(parseFloat(value) || 0)
                                            if (type) setMarkup_type(type)
                                        },
                                        isIcon: true,
                                        icon: '%',
                                        isLabel: true,
                                        isRequired: true,
                                        label: "Markup",
                                        id: "costcatalog-item-markup"
                                    }}
                                />
                            </div>
                            <div className="content-sub-item content-sub-label-item">
                                <div className="content-sub-label-content">
                                    <div className="label">Unit markup</div>
                                    <div className="value">{currency_type}{unti_markup}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="content-section">
                        <div className="content-item">
                            <div id="costcatalog-description" className={`additional-item ${additionalNotes ? 'additional-item-active' : ''}`}>
                                <div className="head" onClick={(e) => setAdditionalNotes(!additionalNotes)}>
                                    <div className="icon" dangerouslySetInnerHTML={{ __html: Icons.general.description }}></div>
                                    <div className="label">Description</div>
                                </div>
                                <div className="item-expanded">
                                    <ReactQuill
                                        theme="snow"
                                        value={description}
                                        onChange={(value) => setDescription(value)}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <Popup_Footer props={AddBtnOption} />
            </form>
        </div>
    )

}

const AddWorkSideItem = ({ props }) => {

    let { type, header_option, close, MainQty, existItemData, updateCallback } = props

    MainQty = parseFloat(MainQty || 0) || 0

    const { itemType, itemId, actionType } = useParams()

    const [isLoading, setIsLoading] = useState(false)
    const [warningAlert, setWarningAlert] = useState(false)
    const [warningAlertType, setWarningAlertType] = useState('warning')
    const [apiFailedMessage, setApiFailedMessage] = useState("Error in Server")

    const costCatalogHandler = new CostCatalogHandler()

    const [currency_type, setCurrency_type] = useState('₹')

    const navigate = useNavigate()
    const store = useSelector((store) => store)
    const dispatch = useDispatch()
    const { updateState } = new UserAction

    const storeHandler = new StoreHandler({
        dataStack: ['costcodes', 'company', 'field_settings', 'finance_settings'],
        store: store,
        dispatch: dispatch,
        updateState: updateState,
    })

    const LoadStoreData = async () => {
        setIsLoading(true)
        let resolved = await storeHandler.init()
        setIsLoading(false)
    }

    const AddBtnOptions = [
        {
            icon: Icons.general.group,
            label: 'Category',
            type: 'group',
            id: "group"
        },

        {
            icon: Icons.general.labour,
            label: 'Labour',
            type: 'item',
            id: "labour"
        },
        {
            icon: Icons.general.material,
            label: 'Materials',
            type: 'item',
            id: "material"
        },
        {
            icon: Icons.general.machines,
            label: 'Machines',
            type: 'item',
            id: "machine"
        },
        {
            icon: Icons.general.subcontractor,
            label: 'Subcontractor',
            type: 'item',
            id: "subcontractor"
        },
        {
            icon: Icons.general.fee,
            label: 'Fee',
            type: 'item',
            id: "fee"
        },
        {
            icon: Icons.general.allowance,
            label: 'Allowances',
            type: 'item',
            id: "allowance"
        },
        {
            icon: Icons.general.work,
            label: 'Work',
            type: 'work',
            id: 'work'
        },
    ]

    const [AddBtnOption, setAddBtnOption] = useState(AddBtnOptions.filter(opt => opt.id == header_option)[0] || undefined)

    const isExistItemData = existItemData && existItemData.id != undefined

    const [enableAllowance, setEnableAllowance] = useState(false)
    const [allowanceFor, setAllowanceFor] = useState({
        manpower: false,
        labour: false,
        machine: false,
        material: false,
        subcontractor: false,
        fee: false,
    })




    const [name, setName] = useState('')

    const [group_id, setGroup_id] = useState('')
    const [group, setGroup] = useState('')

    const [costcode_id, setCostcode_id] = useState('')
    const [costcode_name, setCostcode_name] = useState('')

    const [id, setId] = useState(isExistItemData ? existItemData.id : '')
    const [quantity, setQuantity] = useState('')
    const [unit_id, setUnit_id] = useState('')
    const [unit_name, setUnit_name] = useState('')
    const [unitrate, setUnitrate] = useState('')
    const [description, setDescription] = useState('')
    const [additionalNotes, setAdditionalNotes] = useState(false);

    const [tax_id, setTax_id] = useState('')
    const [tax_name, setTax_name] = useState('')
    const [tax_value, setTax_value] = useState(0)

    const [markup_id, setMarkup_id] = useState('')
    const [markup_name, setMarkup_name] = useState('')
    const [markup_value, setMarkup_value] = useState(0)
    const [markup_type, setMarkup_type] = useState('%')

    const [costcodeOptions, setCostcodeOptions] = useState([])
    const [unitsOptions, setUnitsOptions] = useState([])
    const [taxesOptions, setTaxesOptions] = useState([])
    const [markupsOptions, setMarkupsOptions] = useState([])
    const [groupOptions, setGroupOptions] = useState([])

    const unti_tax = (parseFloat((unitrate * MainQty * quantity) || 0) * parseFloat(tax_value)) / 100
    const unti_markup = markup_type == '%' ? (parseFloat((unitrate * MainQty * quantity) || 0) * parseFloat(markup_value)) / 100 : parseFloat(markup_value)

    const price = (unitrate * MainQty * quantity) + unti_tax + unti_markup


    useEffect(() => {

        if (store && store.user) {

            let { company, costcodes, clients, settings } = store.user


            if (company) {

                let { currency_setting, timezone, temperature_setting } = company

                if (currency_setting) setCurrency_type(currency_setting)
            }
            if (Array.isArray(costcodes)) {

                costcodes = costcodes.filter(opt => opt.type == '1').map((opt) => {
                    return {
                        value: opt.id,
                        label: opt.name,
                    }
                })

                setCostcodeOptions(costcodes)
            }


            if (settings && Array.isArray(settings.field_settings) && settings.field_settings.length) {

                let { units } = settings.field_settings[0]

                units = units.filter(opt => opt.isActive).map((opt) => {
                    return {
                        value: opt.id,
                        label: opt.name,
                        isDefault: opt.isDefault || false
                    }
                })

                setUnitsOptions(units || [])

            }

            if (settings && Array.isArray(settings.finance_settings) && settings.finance_settings.length) {

                let { taxes, markups, overheads, insurance } = settings.finance_settings[0]

                taxes = taxes.filter(opt => opt.isActive).map((opt) => {
                    return {
                        id: opt.id,
                        value: parseFloat(opt.value || 0),
                        label: opt.name,
                        type: opt.type,
                        isDefault: opt.isDefault || false
                    }
                })

                setTaxesOptions(taxes || [])


                markups = markups.filter(opt => opt.isActive).map((opt) => {
                    return {
                        id: opt.id,
                        value: parseFloat(opt.value || 0),
                        label: opt.name,
                        type: opt.value_type,
                        isDefault: opt.isDefault || false
                    }
                })

                setMarkupsOptions(markups || [])
            }


        }

    }, [store])

    const getEstimateItemTypeEenemReverse = (type) => {

        if (type == 'material') return 1
        else if (type == 'labour') return 2
        else if (type == 'machine') return 3
        else if (type == 'subcontractor') return 4
        else if (type == 'fee') return 5
        else if (type == 'allowance') return 6
        else return 0

    }


    const getEstimateItemStatusEenemReverse = (type) => {

        if (type == 'incomplete') return 0
        else if (type == 'completed') return 1
        else if (type == 'hold') return 2
        else if (type == 'stopped') return 3
        else return -1

    }

    const HandlePopupSubmit = async (e) => {
        e.preventDefault()


        let updated_data = {
            id: isExistItemData ? existItemData.id : Utils.getUniqueId(),
            name: name,
            unit_id, unit_id,
            unit_name, unit_name,
            unitrate: String(unitrate),
            quantity: String(quantity),

            costcode_id: costcode_id,
            costcode_name: costcode_name,

            parent_id: group_id || undefined,
            parent_name: group || undefined,

            tax_id: tax_id,
            tax_name: tax_name,
            tax_value: String(tax_value),
            tax_amount: String(unti_tax),


            markup_id: markup_id,
            markup_name: markup_name,
            markup_value: String(markup_value),
            markup_value_type: markup_type,
            markup_amount: String(unti_markup),

            expanded: false,
            builder_price: String(unitrate * quantity * MainQty),
            price: String(price),
            type: 3,
            item_type: getEstimateItemTypeEenemReverse(AddBtnOption.id),
            type_option: AddBtnOption,
            description: description,
        }


        if (typeof updateCallback == 'function' && updated_data.id) updateCallback(updated_data, type)

        close()


    }


    const Popup_Header = ({ props }) => {

        const { icon, label } = props
        return (
            <div className="side-popup-header">
                <div className="header-item-select">
                    <div className="header-item-select-content">
                        <span className="icon" dangerouslySetInnerHTML={{ __html: icon }}></span>
                        <div className="label">{type == 'edit' ? 'Update' : 'Add'} {label || "Item"}</div>

                    </div>
                </div>
                <div
                    className="header-item-close"
                    onClick={(e) => close()}
                    dangerouslySetInnerHTML={{ __html: Icons.general.close }}
                ></div>
            </div>
        );
    };
    const Popup_Footer = ({ props }) => {

        const { icon, label } = props

        return (
            <div className="sidebar-popup-footer">
                <div className="footer-item action-items">
                    <div className="action-preview">
                        <div className="label">{label} Price</div>
                        <div className="value">{currency_type}{price || '0.00'}</div>
                    </div>
                    <button
                        className="action-btn"
                        type='submit'
                    >
                        <div className="icon" dangerouslySetInnerHTML={{ __html: type == 'edit' ? Icons.general.save : Icons.general.add_btn }}></div>
                        <div className="label">{type == 'edit' ? 'Save' : 'Add Item'}</div>
                    </button>
                </div>
            </div>
        );
    };



    useEffect(() => {

        LoadStoreData()

        console.log(type == 'edit' && isExistItemData, existItemData, 'isExistItemData');

        if (type == 'edit' && isExistItemData) {

            setId(existItemData.id)

            setName(existItemData.name || '')

            setCostcode_id(existItemData.costcode_id || '')
            setCostcode_name(existItemData.costcode_name || '')

            setGroup_id(existItemData.parent_id || '')
            setGroup(existItemData.parent_name || '')

            setUnit_id(existItemData.unit_id || '')
            setUnit_name(existItemData.unit_name || '')

            setQuantity(parseFloat(existItemData.quantity || 0) || '')
            setUnitrate(parseFloat(existItemData.unitrate || 0) || '')
            setDescription(existItemData.description || '')

            setAdditionalNotes(existItemData.description ? true : false)

            setTax_id(existItemData.tax_id || '')
            setTax_name(existItemData.tax_name || '')
            setTax_value(parseFloat(existItemData.tax_value || 0) || 0)

            setMarkup_id(existItemData.markup_id || '')
            setMarkup_name(existItemData.markup_name || '')
            setMarkup_value(parseFloat(existItemData.markup_value || 0) || 0)
            setMarkup_type(existItemData.markup_value_type)


        }


    }, [])

    return (

        <div className="popup-container-main propup-container-center">
            <div className="popup-block-ui"></div>
            <form className="side-popup-container animateXRight animateXRightactive" onSubmit={HandlePopupSubmit}>
                <Popup_Header props={AddBtnOption} />

                <div className="sidebar-popup-content">
                    <div className="content-section">

                        <div className="content-item">
                            <TextInput
                                props={{
                                    id: "costcatalog-work-name",
                                    value: name,
                                    placeholder: '',
                                    setValue: (value) => {
                                        setName(value)
                                    },
                                    isIcon: false,
                                    isLabel: true,
                                    isRequired: true,
                                    label: "Name",
                                }}
                            />
                        </div>


                        <div className="content-item">
                            <SelectInput
                                props={{
                                    id: "costcatalog-work-costcode",
                                    value: costcode_id,
                                    placeholder: '',
                                    options: costcodeOptions || [],
                                    setValue: (value, label) => {
                                        setCostcode_id(value)
                                        setCostcode_name(label)
                                    },
                                    isIcon: false,
                                    isLabel: true,
                                    isRequired: true,
                                    label: "Work Category",
                                }}
                            />
                        </div>

                        <div className="content-item">

                            <QuantityInput
                                props={{
                                    parentQty: String(MainQty),
                                    actionType: "multiple",
                                    value: String(quantity),
                                    placeholder: '',
                                    setValue: (value) => setQuantity(parseFloat(value)),
                                    isIcon: false,
                                    isLabel: true,
                                    isRequired: true,
                                    label: "Quantity (Main Qty x Work Quantity)",
                                    id: "costcatalog-work-quantity"
                                }}
                            />
                        </div>
                        <div className="content-item">

                            <div className="content-sub-item">
                                <SelectInput
                                    props={{
                                        id: "costcatalog-work-unit",
                                        value: unit_id,
                                        placeholder: '',
                                        readOnly: false,
                                        options: unitsOptions || [],
                                        setValue: (value, label) => {
                                            setUnit_id(value)
                                            setUnit_name(label)
                                        },
                                        isIcon: false,
                                        isLabel: true,
                                        isRequired: true,
                                        label: "Unit type",
                                    }}
                                />
                            </div>

                            <div className="content-sub-item">
                                <CostInput
                                    props={{
                                        id: "costcatalog-work-unitrate",
                                        value: unitrate,
                                        isCostMethod: false,
                                        currency_type: currency_type,
                                        readOnly: false,
                                        placeholder: '',
                                        setValue: (value) => {
                                            setUnitrate(value)
                                        },
                                        isIcon: true,
                                        icon: currency_type,
                                        isLabel: true,
                                        isRequired: true,
                                        label: "Unit rate",
                                    }}
                                />
                            </div>

                        </div>

                        <div className="content-item">
                            <CostInput
                                props={{
                                    id: "costcatalog-work-price",
                                    value: price,
                                    currency_type: currency_type,
                                    isCostMethod: false,
                                    placeholder: '',
                                    readOnly: true,
                                    setValue: (value) => {
                                        // setPrice(value)
                                    },
                                    isIcon: true,
                                    icon: currency_type,
                                    isLabel: true,
                                    isRequired: false,
                                    label: "Amount",
                                }}
                            />
                        </div>
                    </div>
                    <div className="content-section">
                        <div className="content-section-header">
                            <div className="label">Pricing</div>
                        </div>

                        <div className="content-section-header">
                            <div className="label">Tax</div>
                            <div className="input">
                                <select
                                    name=""
                                    id=""
                                    onInput={(e) => {

                                        let tax = taxesOptions?.filter(t => t.id == e.target.value)[0]

                                        if (tax) {

                                            setTax_id(tax.id)
                                            setTax_value(parseFloat(tax.value || 0))
                                            setTax_name(tax.label)
                                        }
                                    }}
                                    value={tax_id}
                                >
                                    {taxesOptions?.map((tax, i) => (
                                        <option value={`${tax.id}`}>{tax.label}</option>
                                    ))}
                                </select>
                            </div>
                        </div>
                        <div className="content-item">
                            <div className="content-sub-item">
                                <CostInput
                                    props={{
                                        value: tax_value,
                                        isCostMethod: false,
                                        currency_type: currency_type,
                                        placeholder: 'Unit cost',
                                        setValue: (value) => {
                                            setTax_value(parseFloat(value) || 0)
                                        },
                                        isIcon: true,
                                        icon: '%',
                                        isLabel: true,
                                        isRequired: true,
                                        label: "Tax",
                                        id: "costcatalog-item-tax"
                                    }}
                                />
                            </div>
                            <div className="content-sub-item content-sub-label-item">
                                <div className="content-sub-label-content">
                                    <div className="label">Unit tax</div>
                                    <div className="value">{currency_type}{unti_tax}</div>
                                </div>
                            </div>

                        </div>

                        <div className="content-section-header">
                            <div className="label">Markup</div>
                            <div className="input">
                                <select
                                    name=""
                                    id=""
                                    onInput={(e) => {

                                        let item = markupsOptions.filter(itm => itm.id == e.target.value)[0]
                                        if (item) {
                                            setMarkup_value(parseFloat(item.value || 0))
                                            setMarkup_id(item.id)
                                            setMarkup_name(item.label)
                                            setMarkup_type(item.type)
                                        }
                                    }}
                                    value={markup_id}
                                >
                                    {markupsOptions.map((itm, i) => (
                                        <option value={`${itm.id}`}>{itm.label}</option>
                                    ))}
                                </select>
                            </div>
                        </div>
                        <div className="content-item">
                            <div className="content-sub-item">
                                <CostInput
                                    props={{
                                        value: markup_value,
                                        costMethodValue: markup_type,
                                        isCostMethod: true,
                                        currency_type: currency_type,
                                        placeholder: 'Unit cost',
                                        setValue: (value, type) => {
                                            setMarkup_value(parseFloat(value) || 0)
                                            if (type) setMarkup_type(type)
                                        },
                                        isIcon: true,
                                        icon: '%',
                                        isLabel: true,
                                        isRequired: true,
                                        label: "Markup",
                                        id: "costcatalog-item-markup"
                                    }}
                                />
                            </div>
                            <div className="content-sub-item content-sub-label-item">
                                <div className="content-sub-label-content">
                                    <div className="label">Unit markup</div>
                                    <div className="value">{currency_type}{unti_markup}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="content-section">
                        <div className="content-item">
                            <div id="costcatalog-description" className={`additional-item ${additionalNotes ? 'additional-item-active' : ''}`}>
                                <div className="head" onClick={(e) => setAdditionalNotes(!additionalNotes)}>
                                    <div className="icon" dangerouslySetInnerHTML={{ __html: Icons.general.description }}></div>
                                    <div className="label">Description</div>
                                </div>
                                <div className="item-expanded">
                                    <ReactQuill
                                        theme="snow"
                                        value={description}
                                        onChange={(value) => setDescription(value)}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <Popup_Footer props={AddBtnOption} />
            </form>
        </div>
    )

}


const AddWork = ({ props }) => {

    const { existItemData, updateCallback } = props

    const { itemType, itemId, actionType } = useParams()

    const [isLoading, setIsLoading] = useState(false)
    const [warningAlert, setWarningAlert] = useState(false)
    const [warningAlertType, setWarningAlertType] = useState('warning')
    const [apiFailedMessage, setApiFailedMessage] = useState("Error in Server")

    const costCatalogHandler = new CostCatalogHandler()

    const [currency_type, setCurrency_type] = useState('₹')

    const navigate = useNavigate()
    const store = useSelector((store) => store)
    const dispatch = useDispatch()
    const { updateState } = new UserAction

    const storeHandler = new StoreHandler({
        dataStack: ['costcodes', 'company', 'field_settings'],
        store: store,
        dispatch: dispatch,
        updateState: updateState,
    })

    const LoadStoreData = async () => {
        setIsLoading(true)
        let resolved = await storeHandler.init()
        setIsLoading(false)
    }

    const AddBtnOptions = [
        {
            icon: Icons.general.group,
            label: 'Category',
            type: 'group',
            id: "group"
        },

        {
            icon: Icons.general.labour,
            label: 'Labour',
            type: 'item',
            id: "labour"
        },
        {
            icon: Icons.general.material,
            label: 'Materials',
            type: 'item',
            id: "material"
        },
        {
            icon: Icons.general.machines,
            label: 'Machines',
            type: 'item',
            id: "machine"
        },
        {
            icon: Icons.general.subcontractor,
            label: 'Subcontractor',
            type: 'item',
            id: "subcontractor"
        },
        {
            icon: Icons.general.fee,
            label: 'Fee',
            type: 'item',
            id: "fee"
        },
        {
            icon: Icons.general.allowance,
            label: 'Allowances',
            type: 'item',
            id: "allowance"
        },
        {
            icon: Icons.general.work,
            label: 'Work',
            type: 'work',
            id: 'work'
        },
    ]

    const getEstimateGroups = async () => {

        let filter = {
            type: 1,
        }

        let response = await costCatalogHandler.getCostCatalogItemsHandler(filter)

        if (response.success) {

            let { data } = response

            if (!Array.isArray(data)) return

            let getSubItemsIds = (items, parentid) => {

                let subGroupIds = []

                let getItems = (items, parentid) => {

                    if (!Array.isArray(items)) return

                    let _items = items.filter(itm => itm.parent_id == parentid)

                    subGroupIds.push(..._items)

                    _items.forEach(itm => getItems(items, itm.id))
                }

                getItems(items, parentid)

                subGroupIds = subGroupIds.map(g => g.id)

                subGroupIds.push(parentid)

                return subGroupIds
            }

            if (isExistItemData) {

                data = data.filter(d => !getSubItemsIds(data, existItemData.id).includes(d.id))
            }

            data = data.filter(d => d.type == '1').map(d => {

                return {
                    value: d.id,
                    label: d.name,
                }

            })



            setGroupOptions(data)
        }
    }

    const [group_id, setGroup_id] = useState('')
    const [group, setGroup] = useState('')

    const [id, setId] = useState('')

    const [builderPrice, setBuilderPrice] = useState(0)
    const [price, setPrice] = useState(0)
    const [totalTaxes, setTotalTaxes] = useState(0)
    const [totalMarkups, setTotalMarkups] = useState(0)
    const [name, setName] = useState('')
    const [unit_id, setUnit_id] = useState('')
    const [unit_name, setUnit_name] = useState('')
    const [quantity, setQuantity] = useState('')
    const [description, setDescription] = useState('')

    const [sideItemShowPopup, setSideItemShowPopup] = useState(false)
    const [sideItemType, setSideItemType] = useState('add')
    const [sideItemHeader, setSideItemHeader] = useState({})
    const [sideItemExistDetials, setSideItemExistDetials] = useState({})
    const [Cost_Lines, setCost_Lines] = useState([]);
    const [additionalNotes, setAdditionalNotes] = useState(false);

    const isExistItemData = existItemData && existItemData.id != undefined


    const [costcodeOptions, setCostcodeOptions] = useState([])
    const [unitsOptions, setUnitsOptions] = useState([])
    const [taxesOptions, setTaxesOptions] = useState([])
    const [markupsOptions, setMarkupsOptions] = useState([])
    const [groupOptions, setGroupOptions] = useState([])

    const getEstimateItemTypeEenem = (type) => {

        if (type == '1') return 'group'
        else if (type == '2') return 'work_template'
        else if (type == '3') return 'item'
        else if (type == '4') return 'work'
        else return ''

    }
    const getEstimateItemCategoryEenem = (type) => {

        if (type == '1') return 'material'
        else if (type == '2') return 'labour'
        else if (type == '3') return 'machine'
        else if (type == '4') return 'subcontractor'
        else if (type == '5') return 'fee'
        else if (type == '6') return 'allowance'
        else return ''

    }
    const getEstimateItemStatusEenem = (type) => {

        if (type == '0') return 'incomplete'
        else if (type == '1') return 'completed'
        else if (type == '2') return 'hold'
        else if (type == '3') return 'stopped'
        else return ''
    }

    const getFormatedEstimateItem = (item) => {

        return {
            sno: item.sno,
            expanded: true,
            selected: false,
            disable: false,

            parent_id: item.parent_id,
            parent_name: item.parent_name,
            id: item.id,
            name: item.name,
            description: item.description,

            costcode_id: item.costcode_id || "",
            costcode_name: item.costcode_name || "",

            co_efficient: parseFloat(item.co_efficient || 0) || 0,
            per_co_efficient_cost: parseFloat(item.per_co_efficient_cost || 0) || 0,

            type: getEstimateItemTypeEenem(item.type),
            item_type: item.type != '3' ? getEstimateItemTypeEenem(item.type) : getEstimateItemCategoryEenem(item.item_type),
            cost_type: item.type != '1' && item.type != '3' ? getEstimateItemTypeEenem(item.type) : getEstimateItemCategoryEenem(item.item_type),
            _status: item.status,
            status: getEstimateItemStatusEenem(item.status),

            parent_type: parseInt(item.parent_type),
            parent_quantity: parseFloat(item.parent_quantity || 0),

            quantity: parseFloat(item.quantity || 0) || 0,
            unitrate: parseFloat(item.unitrate || 0) || 0,
            builder_price: parseFloat(item.builder_price || 0) || 0,
            price: parseFloat(item.price || 0) || 0,


            unit_id: item.unit_id || "",
            unit_name: item.unit_name || "",

            markup_id: item.markup_id || "",
            markup_name: item.markup_name || "",
            markup_value: parseFloat(item.markup_value || 0) || 0,
            markup_value_type: item.markup_value_type || "",
            markup_amount: parseFloat(item.markup_amount || 0) || 0,

            tax_id: item.tax_id || "",
            tax_name: item.tax_name || "",
            tax_value_type: item.tax_value_type || "",
            tax_value: parseFloat(item.tax_value || 0) || 0,
            tax_amount: parseFloat(item.tax_amount || 0) || 0,

            sub_items: []
        }

    }

    const LoadEstimateItem = async (idx) => {

        if (idx == undefined) return

        let filter = {
            idx: idx
        }

        setIsLoading(true)

        let response = await costCatalogHandler.getCostCatalogItemsHandler(filter)
        setIsLoading(false)

        if (response.success) {


            let { data } = response


            if (!Array.isArray(data) || !data.length) return


            let items = data.map(d => {

                let item = getFormatedEstimateItem(d)

                let tax_amount = item.tax_amount
                let markup_amount = item.markup_amount
                let builder_price = item.builder_price
                let price = item.price

                item._tax_amount = tax_amount || ""
                item._tax_percent = ((tax_amount / builder_price) * 100 || 0).toFixed(2)
                item._markup_amount = markup_amount || ""
                item._markup_percent = ((markup_amount / builder_price) * 100 || 0).toFixed(2)
                item._builder_price = builder_price || ""
                item._price = price || ""
                item._unitrate = item.unitrate || ""
                item._quantity = item.quantity || ""

                item.tax_amount = tax_amount ? `${currency_type}${tax_amount}` : ""
                item.markup_amount = markup_amount ? `${currency_type}${markup_amount}` : ""
                item.builder_price = builder_price ? `${currency_type}${builder_price}` : ""
                item.price = price ? `${currency_type}${price}` : ""
                item.unitrate = item.unitrate ? `${currency_type}${item.unitrate}` : ""
                item.quantity = item.quantity ? `${item.quantity}${item.unit_name || ""}` : ""

                return item

            })

            let item = items[0]

            setId(item.id)

            setName(item.name || '')

            setGroup_id(item.parent_id || '')
            setGroup(item.parent_name || '')

            setUnit_id(item.unit_id || '')
            setUnit_name(item.unit_name || '')

            setQuantity(parseFloat(item._quantity || 0) || '')
            setDescription(item.description || '')

            setAdditionalNotes(item.description ? true : false)


            let cost_line_filter = {
                parent_id: item.id
            }

            let cost_lines_response = await costCatalogHandler.getCostCatalogItemsHandler(cost_line_filter)


            if (cost_lines_response.success && Array.isArray(cost_lines_response.data) && cost_lines_response.data) {

                let cost_lines = cost_lines_response.data.map(item => {

                    item = getFormatedEstimateItem(item)

                    let type_option = AddBtnOptions.filter(opt => {

                        if (opt.type != 'item' && item.type == opt.type) return true
                        else if (opt.type == 'item' && item.item_type == opt.id) return true
                        else return false
                    })

                    type_option = type_option.length ? type_option[0] : undefined
                    item.type_option = type_option

                    return item

                })

                setCost_Lines(cost_lines)
            }

        }
        else {
            setWarningAlert(true)
            setWarningAlertType('error')
            setApiFailedMessage(`Error in fetching estimate item`)
        }
    }

    useEffect(() => {

        console.log(quantity, 'quantity');

        console.log(existItemData, 'isExistItemData');

        if (actionType == 'edit' && isExistItemData) {

            setName(existItemData.name || '')
            setAdditionalNotes(existItemData.description ? true : false)
            setDescription(existItemData.description || '')
            setGroup_id(existItemData.parent_id || '')
            setGroup(existItemData.parent_name || '')
            setQuantity(existItemData._quantity || '')
            setUnit_id(existItemData.unit_id || '')
            setUnit_name(existItemData.unit_name || '')
            setCost_Lines(existItemData.cost_lines || [])

            calculateTotalSummary()
        }

        if (actionType == 'edit' && !isExistItemData) LoadEstimateItem(itemId)

        LoadStoreData()
        getEstimateGroups()

    }, [])

    useEffect(() => {

        if (store && store.user) {

            let { company, costcodes, clients, settings } = store.user



            if (company) {

                let { currency_setting, timezone, temperature_setting } = company

                if (currency_setting) setCurrency_type(currency_setting)
            }
            if (Array.isArray(costcodes)) {

                costcodes = costcodes.filter(opt => opt.type == '1').map((opt) => {
                    return {
                        value: opt.id,
                        label: opt.name,
                    }
                })

                setCostcodeOptions(costcodes)
            }


            if (settings && Array.isArray(settings.field_settings) && settings.field_settings.length) {

                let { units } = settings.field_settings[0]

                units = units.filter(opt => opt.isActive).map((opt) => {
                    return {
                        value: opt.id,
                        label: opt.name,
                        isDefault: opt.isDefault || false
                    }
                })

                setUnitsOptions(units || [])

            }

            if (settings && Array.isArray(settings.finance_settings) && settings.finance_settings.length) {

                let { taxes, markups, overheads, insurance } = settings.finance_settings[0]

                taxes = taxes.filter(opt => opt.isActive).map((opt) => {
                    return {
                        id: opt.id,
                        value: parseFloat(opt.value || 0),
                        label: opt.name,
                        type: opt.type,
                        isDefault: opt.isDefault || false
                    }
                })

                setTaxesOptions(taxes || [])


                markups = markups.filter(opt => opt.isActive).map((opt) => {
                    return {
                        id: opt.id,
                        value: parseFloat(opt.value || 0),
                        label: opt.name,
                        type: opt.value_type,
                        isDefault: opt.isDefault || false
                    }
                })

                setMarkupsOptions(markups || [])
            }


        }

    }, [store])

    const getEstimateItemTypeEenemReverse = (type) => {

        if (type == 'material') return 1
        else if (type == 'labour') return 2
        else if (type == 'machine') return 3
        else if (type == 'subcontractor') return 4
        else if (type == 'fee') return 5
        else if (type == 'allowance') return 6
        else return 0

    }
    const getEstimateItemStatusEenemReverse = (type) => {

        if (type == 'incomplete') return 0
        else if (type == 'completed') return 1
        else if (type == 'hold') return 2
        else if (type == 'stopped') return 3
        else return -1

    }
    const FormatCost_Lines = (Cost_Lines) => {

        if (!Array.isArray(Cost_Lines)) return []

        return Cost_Lines.map(item => {
            return {

                id: item.id,
                name: item.name,
                status: getEstimateItemStatusEenemReverse(item.status),

                unit_id: item.unit_id,
                unit_name: item.unit_name,
                unitrate: String(item.unitrate),
                quantity: String(item.quantity),

                costcode_id: item.costcode_id,
                costcode_name: item.costcode_name,

                parent_id: item.group_id || undefined,
                parent_name: item.group || undefined,

                tax_id: item.tax_id,
                tax_name: item.tax_name,
                tax_value: String(item.tax_value),
                tax_amount: String(item.tax_amount),


                markup_id: item.markup_id,
                markup_name: item.markup_name,
                markup_value: String(item.markup_value),
                markup_value_type: item.markup_value_type,
                markup_amount: String(item.markup_amount),

                builder_price: String(item.builder_price),
                price: String(item.price),
                type: 3,
                item_type: getEstimateItemTypeEenemReverse(item.type_option.id),
                description: item.description,
            }
        })
    }

    const HandlePopupSubmit = async (e) => {
        e.preventDefault()

        let updated_data = {

            name,
            parent_id: group_id || undefined,
            parent_name: group || undefined,
            quantity: String(quantity),
            unit_id: unit_id,
            unit_name: unit_name,
            type: 4,
            expanded: false,
            description,

            cost_lines: FormatCost_Lines(Cost_Lines)

        }

        setIsLoading(true)

        let response = { success: false }

        if (actionType == 'edit' && isExistItemData && existItemData.id) {
            updated_data['id'] = existItemData.id
            response = await costCatalogHandler.updateCostCatalogGroupHandler(updated_data)
        }
        else if (actionType == 'add') response = await costCatalogHandler.createCostCatalogGroupHandler(updated_data)

        setIsLoading(false)

        if (response.success) {

            if (typeof updateCallback == 'function') updateCallback()
            navigate(`/builder/cost-catalog`)
        }
        else {
            setWarningAlert(true)
            setWarningAlertType('error')
            setApiFailedMessage(`Error in Estimate group`)
        }


    }


    const Popup_Header = ({ props }) => {

        return (
            <div className="side-popup-header">
                <div className="header-item-select">
                    <div className="header-item-select-content">
                        <span className="icon" dangerouslySetInnerHTML={{ __html: Icons.general.work }}></span>
                        <div className="label">{actionType == 'edit' ? 'Update' : 'Add'} Work</div>
                    </div>
                </div>
                <div
                    className="header-item-close"
                    onClick={(e) => {
                        navigate(`/builder/cost-catalog`)
                    }}
                    dangerouslySetInnerHTML={{ __html: Icons.general.close }}
                ></div>
            </div>
        );
    };
    const Popup_Footer = ({ props }) => {

        return (
            <div className="sidebar-popup-footer">
                <div className="footer-item action-items">
                    <div className="footer-item-left">

                        <div className="action-preview">
                            <div className="label">Prime cost</div>
                            <div className="value">{currency_type}{parseFloat(builderPrice || 0).toFixed(2)}</div>
                        </div>
                        <div className="action-preview">
                            <div className="label">Taxes | Markups</div>
                            <div className="value">{currency_type}{parseFloat(totalTaxes || 0).toFixed(2)} | {currency_type}{parseFloat(totalMarkups).toFixed(2) || '0.00'}</div>
                        </div>

                        <div className="action-preview">
                            <div className="label">Final price</div>
                            <div className="value">{currency_type}{parseFloat(price).toFixed(2) || '0.00'}</div>
                        </div>
                    </div>
                    <button
                        className="action-btn"
                        type='submit'
                    >

                        <div className="label">Save</div>
                    </button>
                </div>
            </div>
        );
    };

    const HandleAddWorkItem = (item) => {

        setSideItemHeader(item)

        setSideItemType('add')
        setSideItemShowPopup(true)

    }
    const HandleRemoveWorkItem = (index) => {
        let _Cost_Lines = [...Cost_Lines]

        if (_Cost_Lines.length) _Cost_Lines.splice(index, 1)

        setCost_Lines(_Cost_Lines)
    }
    const HandleOpenItem = (item, index) => {

        setSideItemHeader(item.type_option)
        setSideItemType('edit')
        setSideItemExistDetials(item)

        setSideItemShowPopup(true)
    }
    const HandleCreateWorkItem = (data, type = 'add') => {

        console.log(data, 'data');

        if (type == 'add') setCost_Lines([...Cost_Lines, data])
        else if (type == 'edit') {

            let _items = [...Cost_Lines]

            let exist_item_index = _items.findIndex(itm => itm.id == data.id)

            if (exist_item_index != -1) {
                _items[exist_item_index] = data
                setCost_Lines(_items)
            }
        }
        setSideItemShowPopup(false)
    }
    const HandleQuantityChange = (value) => {

        setQuantity(value)

        let _items = [...Cost_Lines]

        let mainQty = parseFloat(value || 0) || 0

        if (Array.isArray(_items) && _items.length) {

            let __items = _items.map((itm, i) => {

                itm.quantity = parseFloat(itm.quantity || 0) || 0
                itm.tax_value = parseFloat(itm.tax_value || 0) || 0
                itm.unitrate = parseFloat(itm.unitrate || 0) || 0
                itm.markup_value = parseFloat(itm.markup_value || 0) || 0
                itm.tax_amount = parseFloat(itm.tax_amount || 0) || 0
                itm.markup_amount = parseFloat(itm.markup_amount || 0) || 0

                itm.builder_price = (mainQty * itm.quantity) * itm.unitrate
                itm.tax_amount = itm.tax_value ? (mainQty * itm.quantity) * itm.unitrate / itm.tax_value / 100 : 0
                itm.markup_amount = itm.markup_value ? itm.markupType == '%' ? ((itm.unitrate * (mainQty * itm.quantity)) * itm.markup_value) / 100 : itm.markup_value : 0
                itm.price = (itm.unitrate * (mainQty * itm.quantity)) + itm.tax_amount + itm.markup_amount

                return itm
            })

            setCost_Lines(__items)
        }

    }
    const HandleCloseSidePopup = () => {

        setSideItemShowPopup(false)
        setSideItemHeader({})
        setSideItemType('add')
        setSideItemExistDetials({})
    }

    const calculateTotalSummary = () => {

        let _items = [...Cost_Lines]


        let _total = _items.reduce((prev, val, i) => {
            return {
                builder_price: prev.price + parseFloat(val.builder_price),
                price: prev.price + parseFloat(val.price),
                markups: prev.markups + parseFloat(val.markup_amount),
                taxes: prev.taxes + parseFloat(val.tax_amount),
            }

        }, {
            price: 0,
            builder_price: 0,
            markups: 0,
            taxes: 0
        })

        setPrice(_total.price)
        setBuilderPrice(_total.builder_price)
        setTotalTaxes(_total.taxes)
        setTotalMarkups(_total.markups)
    }
    useEffect(() => {

        calculateTotalSummary()
    }, [Cost_Lines])

    return (
        <>

            <div className="popup-container-main popup-container-scroll-center">
                <div className="popup-block-ui"></div>
                <form className="side-popup-container" onSubmit={(e) => {
                    HandlePopupSubmit(e)
                }}>
                    <Popup_Header />

                    <div className="sidebar-popup-content">

                        <div className="content-section-title">GENERAL INFO</div>
                        <div className="content-section content-top-main">

                            <div className="content-top-items">

                                <div className="content-item">
                                    <TextInput
                                        props={{
                                            value: name,
                                            placeholder: '',
                                            setValue: (value) => setName(value),
                                            isIcon: false,
                                            isLabel: true,
                                            isRequired: true,
                                            label: "Name",
                                            id: "costcatalog-work-name"
                                        }}
                                    />
                                </div>
                                <div className="content-item">
                                    <SelectInput
                                        props={{
                                            id: "costcatalog-work-group",
                                            value: group_id,
                                            placeholder: '',
                                            options: groupOptions || [],
                                            setValue: (value, label) => {
                                                setGroup_id(value)
                                                setGroup(label)
                                            },
                                            isIcon: false,
                                            isLabel: true,
                                            isRequired: true,
                                            label: "Category",

                                        }}
                                    />

                                </div>
                                <div className="content-item">
                                    <TextInput
                                        props={{
                                            value: quantity,
                                            placeholder: '',
                                            setValue: (value) => HandleQuantityChange(value),
                                            isIcon: false,
                                            isLabel: true,
                                            isRequired: true,
                                            label: "Quantity",
                                            id: "costcatalog-work-quantity"
                                        }}
                                    />
                                </div>
                                <div className="content-item">
                                    <SelectInput
                                        props={{
                                            id: "costcatalog-work-unit",
                                            value: unit_id,
                                            placeholder: '',
                                            options: unitsOptions || [],
                                            setValue: (value, label) => {
                                                setUnit_id(value)
                                                setUnit_name(label)
                                            },
                                            isIcon: false,
                                            isLabel: true,
                                            isRequired: true,
                                            label: "Unit type",

                                        }}
                                    />

                                </div>
                            </div>

                            <div className="content-item">
                                <div className={`additional-item ${additionalNotes ? 'additional-item-active' : ''}`}>
                                    <div className="head" onClick={(e) => setAdditionalNotes(!additionalNotes)}>
                                        <div className="icon" dangerouslySetInnerHTML={{ __html: Icons.general.description }}></div>
                                        <div className="label">Description</div>
                                    </div>
                                    <div className="item-expanded">
                                        <ReactQuill
                                            theme="snow"
                                            value={description}
                                            onChange={(value) => setDescription(value)}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="content-section-title">Items Includes</div>
                        <div className="content-section content-section-items">
                            {Cost_Lines.map((item, index) => (

                                <div className="content-section-item" key={index} >
                                    <div className="left" onClick={(e) => HandleOpenItem(item, index)}>
                                        <div
                                            className="icon"
                                            dangerouslySetInnerHTML={{ __html: item.type_option.icon }}
                                        ></div>
                                        <div className="detials">
                                            <div className="title">{item.name}</div>
                                            <div className="desc">
                                                <div className="label">Quantity</div>
                                                <div className="value">{item.quantity} {item.unit_name}</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="right">
                                        <div className="right-items" onClick={(e) => HandleOpenItem(item, index)}>
                                            <div className="right-item">
                                                <div className="label">Prime cost</div>
                                                <div className="value">{currency_type}{parseFloat(item.builder_price || 0).toFixed(2)}</div>
                                            </div>
                                            <div className="right-item">
                                                <div className="label">Markup</div>
                                                <div className="value">{currency_type}{parseFloat(item.markup_amount || 0).toFixed(2)}</div>
                                            </div>
                                            <div className="right-item">
                                                <div className="label">Tax</div>
                                                <div className="value">{currency_type}{parseFloat(item.tax_amount || 0).toFixed(2)}</div>
                                            </div>
                                            <div className="right-item">
                                                <div className="label">Final Amount</div>
                                                <div className="value">{currency_type}{parseFloat(item.price || 0).toFixed(2)}</div>
                                            </div>
                                        </div>
                                        <div className="right-action">
                                            <div className="right-action-item">
                                                <div
                                                    className="icon"
                                                    dangerouslySetInnerHTML={{ __html: Icons.general.delete_btn }}
                                                    onClick={(e) => HandleRemoveWorkItem(index)}
                                                ></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                            {!Cost_Lines.length ?
                                <div className="content-section-noresult">
                                    <div
                                        className="icon"
                                        dangerouslySetInnerHTML={{ __html: Icons.general.noresult }}
                                    ></div>
                                    <div className="label">No Items added yet</div>
                                </div>
                                : ''}
                        </div>

                    </div>
                    <div className="content-addbtn-items">

                        {AddBtnOptions.filter(itm => itm.type == 'item')?.map((item, i) => (

                            <div
                                className="content-addbtn-item"
                                onClick={(e) => HandleAddWorkItem(item)}
                                key={`add-work-items-${item.id}`}
                            >
                                <div className="content">
                                    <div
                                        className="icon"
                                        dangerouslySetInnerHTML={{ __html: item.icon }}
                                    ></div>
                                    <div className="label">Add {item.label}</div>
                                </div>
                            </div>

                        ))}
                    </div>
                    <Popup_Footer />
                </form>
            </div>
            {sideItemShowPopup ? <AddWorkSideItem props={{
                type: sideItemType,
                header_option: sideItemHeader.id,
                updateCallback: HandleCreateWorkItem,
                close: HandleCloseSidePopup,
                existItemData: sideItemExistDetials,
                MainQty: quantity || 0
            }} /> : null}
        </>
    )

}


const AddCostCatalogItemPopup = ({ props }) => {

    const { existItemData, updateCallback } = props

    const { itemType, itemId, actionType } = useParams()

    const [isLoading, setIsLoading] = useState(false)
    const [warningAlert, setWarningAlert] = useState(false)
    const [warningAlertType, setWarningAlertType] = useState('warning')
    const [apiFailedMessage, setApiFailedMessage] = useState("Error in Server")

    const [currency_type, setCurrency_type] = useState('₹')

    const navigate = useNavigate()
    const store = useSelector((store) => store)
    const dispatch = useDispatch()
    const { updateState } = new UserAction


    return (

        <>
            {itemType == 'group'
                ?
                actionType == 'add' ? <AddGroup props={{ updateCallback }} />
                    : actionType == 'edit' ? <AddGroup props={{ existItemData, updateCallback }} /> : ''
                :
                itemType == 'work'
                    ?
                    actionType == 'add' ? <AddWork props={{ updateCallback }} />
                        : actionType == 'edit' ? <AddWork props={{ existItemData, updateCallback }} /> : ''
                    :
                    itemType == 'work_template' ? '' :
                        actionType == 'add' ? <AddItem props={{ updateCallback }} />
                            : actionType == 'edit' ? <AddItem props={{ existItemData, updateCallback }} /> : ''

            }
        </>

    )

}

export default AddCostCatalogItemPopup;