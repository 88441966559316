
import React, { useEffect, useState } from 'react'

/* Redux Setup*/
import { Link, useNavigate, Route, Routes, BrowserRouter, Switch } from 'react-router-dom'
import { useSelector } from 'react-redux'
import store from '../redux/store'
import ReverseProxy from '../config/reverseProxy'

import Images from '../assets/Images'
import Icons from '../assets/Icons'

const PlanExiryBanner = ({ props }) => {

    const { expiry_days } = props
    const navigate = useNavigate()

    const HandleViewPlans = (e) => {
        navigate(`${ReverseProxy['proxyUrl']}/builder/subscription-plans`)
    }

    return (
        <div className="freeplan_expiry_banner-main">
            <div className="banner-content">
                <div className="banner-content-item">
                    <div
                        className="icon icon-warning"
                        dangerouslySetInnerHTML={{ __html: Icons.general.warning }}
                    ></div>
                    <div className="message">
                        {expiry_days <= 0 ? 'Your plan has expired.' : `Your plan will expire in ${expiry_days} days.`}
                    </div>
                </div>
                <div
                    className="banner-content-item content-link"
                    onClick={HandleViewPlans}
                >
                    <div className="message">
                        View Plans
                    </div>
                    <div
                        className="icon"
                        dangerouslySetInnerHTML={{ __html: Icons.general.open_link }}
                    ></div>

                </div>

            </div>
        </div>
    )

}

export default PlanExiryBanner;