
import proxyConfig from '../../config/reverseProxy'
import HeaderConfig from '../../config/header'

class BuilderAttachments {

    constructor() {

    }

    async getAttachmentsHandler() {

        try {

            let response = await fetch(proxyConfig['serverBaseUrl'] + '/builder/attachment/get-attachment', {
                method: 'POST',
                headers: HeaderConfig.getHeader({ method: 'POST', isToken: true }),
            })

            response = await response.json()

            if (response) return response
        } catch {
            return { success: false }
        }


    }
    async createAttachmentHandler(params) {

        try {

            let response = await fetch(proxyConfig['serverBaseUrl'] + '/builder/attachment/create-attachment', {
                method: 'POST',
                headers: HeaderConfig.getHeader({ method: 'POST', isdefault: false, isToken: true }),
                body: params
            })

            response = await response.json()

            if (response) return response
        } catch {
            return { success: false }
        }


    }
    async updateAttachmentHandler(params) {

        try {

            let response = await fetch(proxyConfig['serverBaseUrl'] + '/builder/attachment/update-attachment', {
                method: 'POST',
                headers: HeaderConfig.getHeader({ method: 'POST', isdefault: false, isToken: true }),
                body: params
            })

            response = await response.json()

            if (response) return response
        } catch {
            return { success: false }
        }


    }
    async deleteAttachmentHandler(params) {

        try {

            let response = await fetch(proxyConfig['serverBaseUrl'] + '/builder/attachment/delete-attachment', {
                method: 'POST',
                headers: HeaderConfig.getHeader({ method: 'POST', isToken: true }),
                body: JSON.stringify(params)
            })

            response = await response.json()

            if (response) return response
        } catch {
            return { success: false }
        }


    }

}

export default BuilderAttachments;