
import proxyConfig from '../../config/reverseProxy'
import HeaderConfig from '../../config/header'

class PurchaseRequest {

    constructor() {

    }

    async getWebViewPurchaseRequestHandler(params) {

        try {

            let response = await fetch(proxyConfig['serverBaseUrl'] + '/builder/purchase-requests/get-webview-purchase-request', {
                method: 'POST',
                headers: HeaderConfig.getHeader({ method: 'POST', isToken: false }),
                body: JSON.stringify(params)
            })

            response = await response.json()

            if (response) return response
        } catch {
            return { success: false }
        }


    }
    async updateWebViewViewStatusHandler(params) {

        try {

            let response = await fetch(proxyConfig['serverBaseUrl'] + '/builder/purchase-requests/update-webview-view_status', {
                method: 'POST',
                headers: HeaderConfig.getHeader({ method: 'POST', isToken: false }),
                body: JSON.stringify(params)
            })

            response = await response.json()

            if (response) return response
        } catch {
            return { success: false }
        }


    }
    async submitWebViewViewHandler(params) {

        try {

            let response = await fetch(proxyConfig['serverBaseUrl'] + '/builder/purchase-requests/submit-webview-purchase-request', {
                method: 'POST',
                headers: HeaderConfig.getHeader({ method: 'POST', isToken: false }),
                body: JSON.stringify(params)
            })

            response = await response.json()

            if (response) return response
        } catch {
            return { success: false }
        }


    }
    async rejectWebViewViewHandler(params) {

        try {

            let response = await fetch(proxyConfig['serverBaseUrl'] + '/builder/purchase-requests/reject-webview-purchase-request', {
                method: 'POST',
                headers: HeaderConfig.getHeader({ method: 'POST', isToken: false }),
                body: JSON.stringify(params)
            })

            response = await response.json()

            if (response) return response
        } catch {
            return { success: false }
        }


    }


    async getNextPurchaseRequestIDHandler(params) {

        try {

            let response = await fetch(proxyConfig['serverBaseUrl'] + '/builder/purchase-requests/get-next-requestId', {
                method: 'POST',
                headers: HeaderConfig.getHeader({ method: 'POST', isToken: true }),
                body: JSON.stringify(params)
            })

            response = await response.json()

            if (response) return response
        } catch {
            return { success: false }
        }


    }
    async getBasicPurchaseRequestsHandler(params) {

        try {

            let response = await fetch(proxyConfig['serverBaseUrl'] + '/builder/purchase-requests/get-basic-purchase-requests', {
                method: 'POST',
                headers: HeaderConfig.getHeader({ method: 'POST', isToken: true }),
                body: JSON.stringify(params)
            })

            response = await response.json()

            if (response) return response
        } catch {
            return { success: false }
        }


    }

    async getPurchaseRequestsHandler(params) {

        try {

            let response = await fetch(proxyConfig['serverBaseUrl'] + '/builder/purchase-requests/get-purchase-requests', {
                method: 'POST',
                headers: HeaderConfig.getHeader({ method: 'POST', isToken: true }),
                body: JSON.stringify(params)
            })

            response = await response.json()

            if (response) return response
        } catch {
            return { success: false }
        }


    }
    async createPurchaseRequestHandler(params) {

        try {

            let response = await fetch(proxyConfig['serverBaseUrl'] + '/builder/purchase-requests/create-purchase-request', {
                method: 'POST',
                headers: HeaderConfig.getHeader({ method: 'POST', isToken: true }),
                body: JSON.stringify(params)
            })

            response = await response.json()

            if (response) return response
        } catch {
            return { success: false }
        }


    }
    async updatePurchaseRequestHandler(params) {

        try {

            let response = await fetch(proxyConfig['serverBaseUrl'] + '/builder/purchase-requests/update-purchase-request', {
                method: 'POST',
                headers: HeaderConfig.getHeader({ method: 'POST', isToken: true }),
                body: JSON.stringify(params)
            })

            response = await response.json()

            if (response) return response
        } catch {
            return { success: false }
        }


    }
    async deletePurchaseRequestHandler(params) {

        try {

            let response = await fetch(proxyConfig['serverBaseUrl'] + '/builder/purchase-requests/delete-purchase-request', {
                method: 'POST',
                headers: HeaderConfig.getHeader({ method: 'POST', isToken: true }),
                body: JSON.stringify(params)
            })

            response = await response.json()

            if (response) return response
        } catch {
            return { success: false }
        }


    }

}

export default PurchaseRequest;