

import React, { useEffect, useState, useRef } from 'react'
import { Route, Routes, BrowserRouter, Link, useLocation, Navigate, Outlet, useNavigate, useParams } from 'react-router-dom';
import store from '../../redux/store'
import { useSelector, useDispatch } from 'react-redux'
import UserAction from '../../redux/action/userAction'

import ReverseProxy from '../../config/reverseProxy'
import ProjectsHandler from '../../Handlers/Projects/Projects';
import Icons from '../../assets/Icons'
import Images from '../../assets/Images'
import { CostInput, TextInput, SelectInput, RadioInput } from '../../components/Inputs'
import Utils from '../../utils'
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

import AlertPopup from '../../components/AlertPopup'
import Loading from '../../components/Loading'
import SystemToastPopup from '../../components/SystemToastPopup'


import Projectsidebar from '../sidebar/Projectsidebar'
import Header from '../header/header'

import CreateProject from './createProject'
import ViewProject from './viewProject'

import ProjectDashboard from '../dashboards/projectDashboard'

import PhasesList from '../phases/phasesList'

import Estimate from '../estimate/estimate'
import EstimateBOQ from '../estimate/estimateBOQ'
import ProposalLists from '../proposal/proposalLists'
import ViewProposal from '../proposal/viewProposal'
import CreateProposal from '../proposal/createProposal'
import Reports from '../reports/reports'


import CreatePurchaseOrder from '../purchaseOrder/createPurchaseOrder'
import ViewPurchaseOrder from '../purchaseOrder/viewPurchaseOrder'
import PurchaseOrdersLists from '../purchaseOrder/purchaseOrder'

import CreatePurchaseRequest from '../purchaseRequest/createPurchaseRequest'
import ViewPurchaseRequest from '../purchaseRequest/viewPurchaseRequest'
import RequestSubmission from '../purchaseRequest/requestSubmission'
import PurchaseRequestResponses from '../purchaseRequest/purchaseRequestResponses'
import PurchaseRequestLists from '../purchaseRequest/purchaseRequest'

import CreateBill from '../bills/createBill'
import ViewBill from '../bills/viewBill'
import BillsLists from '../bills/bills'

import CreateGRN from '../GRN/createGRN'
import ViewGRN from '../GRN/viewGRN'
import GRNs from '../GRN/GRNs'

import Inventory from '../inventory/inventory'

import LaboursAttendance from '../labourManagement/laboursAttendance'
import LaboursPayrolls from '../labourManagement/laboursPayrolls'

import TaskManagement from '../taskManagement/taskManagement'
import IssuesManagement from '../issuesManagement/issuesManagement'


import FinanceDashboard from '../financeDashboard/financeDashboard'
import FinanceSummary from '../financeSummary/financeSummary'
import BudgetReport from '../budget/budgetReport'

import CreateChangeOrders from '../changeOrders/createChangeOrders'
import ViewChangeOrders from '../changeOrders/viewChangeOrders'
import ChangeOrdersLists from '../changeOrders/changeOrders'

import CreateClientInvoice from '../clientInvoices/createClientInvoice'
import ViewClientInvoice from '../clientInvoices/viewClientInvoice'
import ClientInvoicesLists from '../clientInvoices/clientInvoices'

import CheckLists from '../checkLists/checkLists'
import CheckListView from '../checkLists/checkListView'
import CheckListEdit from '../checkLists/checkListEdit'

import CheckListTemplates from '../checkLists/checkListTemplates'
import CreateCheckListTemplate from '../checkLists/createCheckListTemplate'

import Drive from '../drive/drive'

import ChatMain from '../chat/chatMain'



const Project = () => {

    const { projectId } = useParams()

    const store = useSelector((store) => store)
    const navigate = useNavigate()

    const projectsHandlerd = new ProjectsHandler()

    const [PlanExpiryDays, setPlanExpiryDays] = useState(store.user.plan_expiry_duration_days || 0)

    const checkProjectExists = async () => {

        let filters = {
            projectID: projectId
        }


        let response = await projectsHandlerd.getProjectBasicsHandler(filters)
        if (response.success) {


            let project = response.data[0]

            if (!project) {
                navigate(`${ReverseProxy['proxyUrl']}/builder/projects`)
            }
        }

    }

    useEffect(() => {
        checkProjectExists()
    }, [])


    useEffect(() => {



        setPlanExpiryDays(store.user.plan_expiry_duration_days || 0)

    }, [store])

    return (
        <div className={`project-section-container ${PlanExpiryDays <= 10 ? 'project-section-container-with-plan_expiry_banner' : ''} `}>
            <Projectsidebar />
            <div className='project-content-main'>
                <Header />
                <div className='project-content-container'>
                    <Routes >
                        <Route exact path='/edit/*' element={<CreateProject props={{ menuType: 'edit' }} />}></Route>
                        <Route exact path='/view/*' element={<ViewProject props={{ menuType: 'edit' }} />}></Route>

                        <Route exact path='/dashboard/*' element={<ProjectDashboard />}></Route>
                        <Route exact path='/phases/*' element={<PhasesList />}></Route>

                        <Route exact path='/estimate/*' element={<Estimate />}></Route>
                        <Route exact path='/estimate-boq/*' element={<EstimateBOQ props={{ menuType: 'create' }} />}></Route>
                        <Route exact path='/proposals/*' element={<ProposalLists />}></Route>
                        <Route exact path='/proposals/:proposalId/view/*' element={<ViewProposal props={{ menuType: 'edit' }} />}></Route>
                        <Route exact path='/proposals/create/*' element={<CreateProposal props={{ menuType: 'create' }} />}></Route>
                        <Route exact path='/proposals/:proposalId/edit/*' element={<CreateProposal props={{ menuType: 'edit' }} />}></Route>

                        <Route exact path='/client_invoice/*' element={<ClientInvoicesLists />}></Route>
                        <Route exact path='/client_invoice/create/*' element={<CreateClientInvoice props={{ menuType: 'create' }} />}></Route>
                        <Route exact path='/client_invoice/:invoiceId/edit/*' element={<CreateClientInvoice props={{ menuType: 'edit' }} />}></Route>
                        <Route exact path='/client_invoice/:invoiceId/view/*' element={<ViewClientInvoice props={{ menuType: 'edit' }} />}></Route>

                        <Route exact path='/change_orders/*' element={<ChangeOrdersLists />}></Route>
                        <Route exact path='/change_orders/create/*' element={<CreateChangeOrders props={{ menuType: 'create' }} />}></Route>
                        <Route exact path='/change_orders/:changeOrderId/edit/*' element={<CreateChangeOrders props={{ menuType: 'edit' }} />}></Route>
                        <Route exact path='/change_orders/:changeOrderId/view/*' element={<ViewChangeOrders props={{ menuType: 'edit' }} />}></Route>

                        <Route exact path='/purchase_request/*' element={<PurchaseRequestLists />}></Route>
                        <Route exact path='/purchase_request/create/*' element={<CreatePurchaseRequest props={{ menuType: 'create' }} />}></Route>
                        <Route exact path='/purchase_request/:requestId/edit/*' element={<CreatePurchaseRequest props={{ menuType: 'edit' }} />}></Route>
                        <Route exact path='/purchase_request/:requestId/view/*' element={<ViewPurchaseRequest props={{ menuType: 'edit' }} />}></Route>
                        <Route exact path='/purchase_request/:requestId/responses/submission/:submissionId*' element={<RequestSubmission props={{ menuType: 'edit' }} />}></Route>
                        <Route exact path='/purchase_request/:requestId/responses/*' element={<PurchaseRequestResponses props={{ menuType: 'edit' }} />}></Route>

                        <Route exact path='/purchase_order/*' element={<PurchaseOrdersLists />}></Route>
                        <Route exact path='/purchase_order/create/*' element={<CreatePurchaseOrder props={{ menuType: 'create' }} />}></Route>
                        <Route exact path='/purchase_order/:purchaseId/edit/*' element={<CreatePurchaseOrder props={{ menuType: 'edit' }} />}></Route>
                        <Route exact path='/purchase_order/:purchaseId/view/*' element={<ViewPurchaseOrder props={{ menuType: 'edit' }} />}></Route>

                        <Route exact path='/bills/*' element={<BillsLists />}></Route>
                        <Route exact path='/bills/create/*' element={<CreateBill props={{ menuType: 'create' }} />}></Route>
                        <Route exact path='/bills/:billId/edit/*' element={<CreateBill props={{ menuType: 'edit' }} />}></Route>
                        <Route exact path='/bills/:billId/view/*' element={<ViewBill props={{ menuType: 'edit' }} />}></Route>

                        <Route exact path='/grn/*' element={<GRNs />}></Route>
                        <Route exact path='/grn/create/*' element={<CreateGRN props={{ menuType: 'create' }} />}></Route>
                        <Route exact path='/grn/:grn_no/edit/*' element={<CreateGRN props={{ menuType: 'edit' }} />}></Route>
                        <Route exact path='/grn/:grn_no/view/*' element={<ViewGRN props={{ menuType: 'edit' }} />}></Route>

                        <Route exact path='/labour-attendance/*' element={<LaboursAttendance />}></Route>
                        <Route exact path='/labour-payroll/*' element={<LaboursPayrolls />}></Route>

                        <Route exact path='/inventory/*' element={<Inventory />}></Route>
                        <Route exact path='/finance-dashboard/*' element={<FinanceDashboard />}></Route>
                        <Route exact path='/finance-summary/*' element={<FinanceSummary />}></Route>
                        <Route exact path='/budget/*' element={<BudgetReport />}></Route>
                        <Route path='/drive/*' element={<Drive />}></Route>
                        <Route path='/drive/:selected_folder/*' element={<Drive />}></Route>
                        <Route exact path='/reports/*' element={<Reports />}></Route>

                        <Route exact path='/task-management/*' element={<TaskManagement />}></Route>
                        <Route exact path='/issues-management/*' element={<IssuesManagement />}></Route>

                        <Route exact path='/checklists/*' element={<CheckLists />}></Route>
                        <Route exact path='/checklists/:listId/view/*' element={<CheckListView />}></Route>
                        <Route exact path='/checklists/:listId/edit/*' element={<CheckListEdit />}></Route>

                        <Route exact path='/checklist-templates/*' element={<CheckListTemplates />}></Route>
                        <Route exact path='/checklist-templates/create/*' element={<CreateCheckListTemplate props={{ menuType: 'create' }} />}></Route>
                        <Route exact path='/checklist-templates/:listId/edit/*' element={<CreateCheckListTemplate props={{ menuType: 'edit' }} />}></Route>

                        <Route exact path='/chat/*' element={<ChatMain />}></Route>

                    </Routes>
                </div>
            </div>
        </div>
    )

}

export default Project;