

import React, { useEffect, useState, useRef } from 'react'
import { Route, Routes, BrowserRouter, Link, useLocation, Navigate, Outlet, useNavigate, useParams } from 'react-router-dom';
import store from '../../redux/store'
import { useSelector, useDispatch } from 'react-redux'
import UserAction from '../../redux/action/userAction'

import ReverseProxy from '../../config/reverseProxy'
import ClientsHandler from '../../Handlers/Clients/Clients';
import Icons from '../../assets/Icons'
import Images from '../../assets/Images'
import { CostInput, TextInput, SelectInput, RadioInput } from '../../components/Inputs'
import Utils from '../../utils'
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';



import StockItems from './stockItems'

import IndentsLists from './indents/indents'
import ViewIndents from './indents/viewIndents'
import CreateIndents from './indents/createIndents'

import CreateMaterialIssue from './materialIssue/createMaterialIssue'
import ViewMaterialIssue from './materialIssue/viewMaterialIssue'
import MaterialIssueLists from './materialIssue/materialIssue'

import MaterialIssueDeliveryLists from './materialIssueDelivery/materialIssueDelivery'



const Reports = () => {


    return (

        <Routes >

            <Route exact path='/stock-items/*' element={<StockItems />}></Route>

            <Route exact path='/indents/*' element={<IndentsLists />}></Route>
            <Route exact path='/indents/create/*' element={<CreateIndents props={{ menuType: 'create' }} />}></Route>
            <Route exact path='/indents/:indent_no/edit/*' element={<CreateIndents props={{ menuType: 'edit' }} />}></Route>
            <Route exact path='/indents/:indent_no/view/*' element={<ViewIndents props={{ menuType: 'edit' }} />}></Route>

            <Route exact path='/material-issued/*' element={<MaterialIssueLists />}></Route>
            <Route exact path='/material-issued/create/:issue_to/*' element={<CreateMaterialIssue props={{ menuType: 'create' }} />}></Route>
            <Route exact path='/material-issued/:issue_no/edit/:issue_to/*' element={<CreateMaterialIssue props={{ menuType: 'edit' }} />}></Route>
            <Route exact path='/material-issued/:issue_no/view/:issue_to/*' element={<ViewMaterialIssue props={{ menuType: 'edit' }} />}></Route>

            <Route exact path='/deliveries/*' element={<MaterialIssueDeliveryLists />}></Route>

        </Routes>

    )

}

export default Reports;