import React, { useEffect, useState } from 'react'
import { Link, useNavigate, useParams, useLocation } from 'react-router-dom'

/*Redux*/
import { useSelector, useDispatch } from 'react-redux'
import UserAction from '../../redux/action/userAction'

import Auth_Handler from '../../Handlers/Authendication/Authendication'
import ReverseProxy from '../../config/reverseProxy'
import Loading from '../../components/Loading'
import AlertPopup from '../../components/AlertPopup'

import Image from '../../assets/Images'
import Icons from '../../assets/Icons'

import SystemNotification from '../ToastMsg'
const VerifyResetpassword = () => {

    const [isLoading, setIsLoading] = useState(false)
    const [warningAlert, setWarningAlert] = useState(false)
    const [warningAlertType, setWarningAlertType] = useState('warning')
    const [apiFailedMessage, setApiFailedMessage] = useState("Error in Server")
    const location = useLocation()


    const auth_Handler = new Auth_Handler()
    const sysNotification = new SystemNotification()

    const queryParams = new URLSearchParams(location.search);
    const org = queryParams.get('org');

    const store = useSelector((store) => store)
    const dispatch = useDispatch()
    const { updateState } = new UserAction

    const [code, setCode] = useState('')
    const [lastCode, setLastCode] = useState('')

    const [CompanyName, setCompanyName] = useState('')
    const [CompanyLogo, setCompanyLogo] = useState('')
    const [CompanyWebsite, setCompanyWebsite] = useState('')

    const navigate = useNavigate();

    const verifyNumber = (value) => (/^[0-9]+$/.test(value))

    const VerifyResetPasswordHandler = async (e) => {
        e.preventDefault()


        if (!verifyNumber(code) || code.length != 6) {

            // setWarningAlert(true)
            // setApiFailedMessage("Invalid inputs. Please enter valid credentials.")

            sysNotification.show("Invalid inputs. Please enter valid credentials.", 2000)
            return
        }
        setIsLoading(true)

        let { email } = store.user

        email = email || sessionStorage.getItem('reset-email')

        let response = await auth_Handler.verifyResetCode({
            email, code
        })

        setIsLoading(false)

        if (!response.success || !response.data) {

            // setWarningAlert(true)
            // setApiFailedMessage(`${response.message}, Please enter valid input and try again!`)

            sysNotification.show("Email is invalid. Please enter valid credentials.", 2000)
            return
        }

        let { redirect_to } = response.data

        navigate(`${redirect_to}?org=${org}`)
    }

    useEffect(() => {
        let { email } = store.user

        email = email || sessionStorage.getItem('reset-email')

        if (!email) navigate(`${ReverseProxy['proxyUrl']}/forget-password`)

    }, [])

    const CodeInoutEvent = (e) => {

        let code_input = document.querySelector('#login-input-code')
        let code_error_msgs = document.querySelector('#code-error-msg')
        let login_button = document.querySelector('#login-button')

        const value = e.target.value;

        if (!value) {

            code_error_msgs.innerHTML = `Please fill in the recovery code`
            code_error_msgs.style.display = 'flex'
            code_input.classList.add('input-error-active')

            if (!login_button.classList.contains('content-submit-btn-disable')) login_button.classList.add('content-submit-btn-disable')

        }
        else {
            code_error_msgs.innerHTML = ``
            code_error_msgs.style.display = 'none'
            code_input.classList.remove('input-error-active')

            if (login_button.classList.contains('content-submit-btn-disable')) login_button.classList.remove('content-submit-btn-disable')
        }

        if (!value) {
            setLastCode('')
            setCode('')
            code_input.value = ''
        }

        else if (verifyNumber(value)) {
            setLastCode(value)
            setCode(value)
        }

        else {
            code_input.value = lastCode
            setCode(lastCode)
        }
    }

    const HandleWarningConfirm = (confirmation) => {

        setWarningAlert(false)
    }

    const HandleResendVerificationCode = async () => {


        setIsLoading(true)

        let { email } = store.user

        email = email || sessionStorage.getItem('reset-email')

        let response = await auth_Handler.resendResetCode({
            email
        })

        setIsLoading(false)

        if (!response.success) {

            sysNotification.show("Failed to resend verification code, Please check your inbox", 2000)
            return
        }

    }

    const LoadOrgSettings = async (org) => {

        let payload = {
            portal_url: org
        }

        setIsLoading(true)

        let response = await auth_Handler.getPublicProfileHandler(payload)

        setIsLoading(false)

        if (!response.success || !response.data) {

            sysNotification.show("Error in fetching organization details", 2000)
            return
        }

        let { id, name, phone, website, client_portal_settings, logo_img } = response.data

        if (client_portal_settings && Object.keys(client_portal_settings).length) {

            let { visible_name, color_theme } = client_portal_settings

            document.title = `Login | ${visible_name}`

            let default_color = document.querySelector(':root').style.getPropertyValue('--primary-color')
            document.querySelector(':root').style.setProperty('--primary-color', color_theme || default_color)

            let Old_Icon = document.querySelector('link[rel="icon"]');
            if (Old_Icon) document.head.removeChild(Old_Icon);

            let Logo_img = `data:image/png;base64,${logo_img}`

            setCompanyLogo(Logo_img)
            setCompanyName(visible_name)
            setCompanyWebsite(website)

        }



    }

    useEffect(() => {
        if (org) LoadOrgSettings(org)

    }, [org]);


    return (
        <>
            {isLoading ?

                <Loading
                    props={{
                        isMainLogo: false,
                        isLabel: true
                    }} />

                : null}

            {warningAlert ?

                <AlertPopup
                    props={{
                        type: "delete",
                        actionBtnOption: { icon: Icons.general.warning, label: "Yea, Ok" },
                        heading: "Something went Wrong!",
                        message: apiFailedMessage || "Invalid Inputs, Please try again with vaild Inputs!",
                        callback: (confirmation) => HandleWarningConfirm(confirmation)
                    }} />

                : null}


            <div className='project-login-main' id='project-login-main'>
                <div className='project-login-bg'></div>
                <div className="project-login-content">

                    <form className="login-left-section" onSubmit={VerifyResetPasswordHandler}>
                        {CompanyLogo ?
                            <Link to={CompanyWebsite} target='_blank' className="login-header-logo">
                                <img src={CompanyLogo} alt="" />
                            </Link>
                            : ''}
                        <div className="login-left-content">
                            <div className="login-content-title">Check your email</div>
                            <div className="login-content-desc">We have sent a password recovery link to your email</div>
                            <div className="login-content-inputs">
                                <div className="content-input-item">
                                    <div className="login-input-icon"
                                        dangerouslySetInnerHTML={{ __html: Icons.general.otp }}
                                    ></div>
                                    <input
                                        id="login-input-code"
                                        type='text'
                                        value={code}
                                        required={true}
                                        onInput={(e) => CodeInoutEvent(e)}
                                        placeholder='Enter confirmation code'
                                    />
                                    <span className='project-login-email-error-msgs' id='code-error-msg'></span>
                                </div>

                                <div
                                    className="content-input-resend-code"
                                    onClick={() => HandleResendVerificationCode()}
                                >Resend Code</div>
                            </div>

                            <div className="login-content-button">
                                <button type='submit' className="login-button" id='login-button'>Go to reset</button>
                            </div>

                        </div>
                    </form>
                    <div className="login-right-section">
                        <img src={Image.login_banner} alt="" />
                    </div>
                </div>
            </div>
        </>
    )
}

export default VerifyResetpassword;
