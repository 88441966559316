

import React, { useEffect, useState, useRef, useCallback, useMemo, StrictMode } from 'react'
import { Route, Routes, BrowserRouter, Link, useLocation, Navigate, Outlet, useNavigate, useParams } from 'react-router-dom';
import store from '../../redux/store'
import StoreHandler from '../../redux/StoreHandler'
import { useSelector, useDispatch } from 'react-redux'
import UserAction from '../../redux/action/userAction'

import { CostInput, TextInput, SelectInput, DateTimeInput, RadioInput } from '../../components/Inputs'

import ProjectsHandler from '../../Handlers/Projects/Projects';
import ReverseProxy from '../../config/reverseProxy'
import Image from '../../assets/Images'
import Icons from '../../assets/Icons'

import { useAuth, useMenuAuth } from '../../hooks/useAuth'


const Mainsidebar = () => {


    const currentURL = window.location.pathname;
    const { projectId } = useParams()

    const { isAdmin, roles_permissions, company_features } = useAuth()


    const navigate = useNavigate()
    const store = useSelector((store) => store)
    const dispatch = useDispatch()
    const { updateState } = new UserAction

    const [isLoading, setIsLoading] = useState(false)
    const [warningAlert, setWarningAlert] = useState(false)
    const [warningAlertType, setWarningAlertType] = useState('warning')
    const [apiFailedMessage, setApiFailedMessage] = useState("Error in Server")

    const projectsHandler = new ProjectsHandler()

    const SIDE_MENUS = [
        {
            id: "dashboard",
            key: 'project_dashboard',
            icon: Icons.general.dashboard,
            label: 'Smart Analytics',
            isSubMenus: false,
            submenus: [],
            expanded: false,
        },
        {
            id: "phases",
            key: 'project_phases',
            icon: Icons.general.hierachy,
            label: 'Phases',
            isSubMenus: false,
            submenus: [],
            expanded: false,
        },
        {
            id: "preconstruction",
            icon: Icons.general.boq,
            label: 'BOQ Estimation',
            disabled: false,
            commingsoon: false,
            isSubMenus: true,
            expanded: false,
            submenus: [
                {
                    id: "takeoff",
                    key: 'project_takeoff',
                    icon: Icons.general.takeoff,
                    label: 'Takeoff',
                    disabled: true,
                    commingsoon: true,
                    isSubMenus: false,
                    submenus: [],
                    expanded: false,
                },
                {
                    id: "estimate",
                    key: 'project_estimate',
                    icon: Icons.general.estimate,
                    label: 'Estimate',
                    disabled: false,
                    commingsoon: false,
                    isSubMenus: false,
                    submenus: [],
                    expanded: false,
                },
                {
                    id: "proposals",
                    key: 'project_proposal',
                    icon: Icons.general.proposal,
                    label: 'BOQ Proposal',
                    disabled: false,
                    commingsoon: false,
                    isSubMenus: false,
                    submenus: [],
                    expanded: false,
                },
                {
                    id: "estimate-boq",
                    key: 'boq_summary',
                    icon: Icons.general.boq_summary,
                    label: 'BOQ Summary',
                    disabled: false,
                    commingsoon: false,
                    isSubMenus: false,
                    expanded: false,
                    submenus: []
                },


            ]
        },
        {
            id: "purchase",
            icon: Icons.general.purchase,
            label: 'Procurement',
            disabled: false,
            commingsoon: false,
            isSubMenus: true,
            expanded: false,
            submenus: [
                {
                    id: "inventory/indents",
                    key: 'indents',
                    icon: Icons.general.indent,
                    label: 'Indent Requests',
                    disabled: false,
                    commingsoon: false,
                    isSubMenus: false,
                    expanded: false,
                    submenus: []
                },
                {
                    id: "purchase_request",
                    key: 'purchase_orders',
                    icon: Icons.general.purchase_request,
                    label: 'RFQs',
                    disabled: false,
                    commingsoon: false,
                    isSubMenus: false,
                    submenus: [],
                    expanded: false,
                },
                {
                    id: "purchase_order",
                    key: 'purchase_orders',
                    icon: Icons.general.orders,
                    label: 'Purchase Orders',
                    disabled: false,
                    commingsoon: false,
                    isSubMenus: false,
                    submenus: [],
                    expanded: false,
                },
                {
                    id: "grn",
                    key: 'grns',
                    icon: Icons.general.grn,
                    label: 'GRNs',
                    disabled: false,
                    commingsoon: false,
                    isSubMenus: false,
                    submenus: [],
                    expanded: false,
                },
                {
                    id: "bills",
                    key: 'bills',
                    icon: Icons.general.bills,
                    label: 'Bills',
                    disabled: false,
                    commingsoon: false,
                    isSubMenus: false,
                    submenus: [],
                    expanded: false,
                },
            ]
        },
        {
            id: "inventory",
            icon: Icons.general.inventory,
            label: 'Inventory',
            disabled: false,
            commingsoon: false,
            isSubMenus: true,
            expanded: false,
            submenus: [
                {
                    id: "inventory/stock-items",
                    key: 'stock_items',
                    icon: Icons.general.stock,
                    label: 'Stock Items',
                    disabled: false,
                    commingsoon: false,
                    isSubMenus: false,
                    expanded: false,
                    submenus: []
                },
                {
                    id: "inventory/material-issued",
                    key: 'material_issues',
                    icon: Icons.general.material_issue,
                    label: 'Material Issued',
                    disabled: false,
                    commingsoon: false,
                    isSubMenus: false,
                    expanded: false,
                    submenus: []
                },
                {
                    id: "inventory/deliveries",
                    key: 'material_deliveries',
                    icon: Icons.general.delivery,
                    label: 'Deliveries',
                    disabled: false,
                    commingsoon: false,
                    isSubMenus: false,
                    expanded: false,
                    submenus: []
                },
            ]
        },
        {
            id: "labour_management",
            icon: Icons.general.workers,
            label: 'Manage Labours',
            disabled: false,
            commingsoon: false,
            isSubMenus: true,
            expanded: false,
            submenus: [
                {
                    id: "labour-attendance",
                    key: 'labour_attendance',
                    icon: Icons.general.tasks,
                    label: 'Attendance',
                    disabled: false,
                    commingsoon: false,
                    isSubMenus: false,
                    expanded: false,
                    submenus: []
                },
                {
                    id: "labour-payroll",
                    key: 'labour_payroll',
                    icon: Icons.general.cost_code,
                    label: 'Payroll',
                    disabled: false,
                    commingsoon: false,
                    isSubMenus: false,
                    expanded: false,
                    submenus: []
                },

            ]
        },
        {
            id: "safety",
            icon: Icons.general.safety,
            label: 'Safety & Quality',
            disabled: false,
            commingsoon: false,
            isSubMenus: true,
            expanded: false,
            submenus: [

                {
                    id: "checklists",
                    key: 'checklists',
                    icon: Icons.general.survey,
                    label: 'Checklists',
                    disabled: false,
                    commingsoon: false,
                    isSubMenus: false,
                    submenus: [],
                    expanded: false,
                },
                {
                    id: "checklist-templates",
                    key: 'checklists_templates',
                    icon: Icons.general.inspection,
                    label: 'Templates',
                    disabled: false,
                    commingsoon: false,
                    isSubMenus: false,
                    submenus: [],
                    expanded: false,
                },

            ]
        },
        {
            id: "project_management",
            icon: Icons.general.tasks,
            label: 'Manage Project',
            disabled: false,
            commingsoon: false,
            isSubMenus: true,
            expanded: false,
            submenus: [
                {
                    id: "task-management",
                    key: 'tasks',
                    icon: Icons.general.tasks,
                    label: 'Tasks',
                    disabled: false,
                    commingsoon: false,
                    isSubMenus: false,
                    submenus: [],
                    expanded: false,
                },
                {
                    id: "issues-management",
                    key: 'issues',
                    icon: Icons.general.issues,
                    label: 'Issues',
                    disabled: false,
                    commingsoon: false,
                    isSubMenus: false,
                    submenus: [],
                    expanded: false,
                },
            ]
        },
        {
            id: "finance_management",
            icon: Icons.general.finance,
            label: 'Manage Finance',
            disabled: false,
            commingsoon: false,
            isSubMenus: true,
            expanded: false,
            submenus: [
                {
                    id: "finance-dashboard",
                    key: 'finance_dashoard',
                    icon: Icons.general.overview,
                    label: 'Dashboard',
                    disabled: false,
                    commingsoon: false,
                    isSubMenus: false,
                    submenus: [],
                    expanded: false,
                },
                {
                    id: "finance-summary",
                    key: 'finance_summary',
                    icon: Icons.general.summary,
                    label: 'Summary',
                    disabled: false,
                    commingsoon: false,
                    isSubMenus: false,
                    submenus: [],
                    expanded: false,
                },
                {
                    id: "budget",
                    key: 'finance_budget',
                    icon: Icons.general.budget,
                    label: 'Budget',
                    disabled: false,
                    commingsoon: false,
                    isSubMenus: false,
                    submenus: [],
                    expanded: false,
                },
                {
                    id: "change_orders",
                    key: 'changes_orders',
                    icon: Icons.general.change_orders,
                    label: 'Change Orders',
                    disabled: false,
                    commingsoon: false,
                    isSubMenus: false,
                    submenus: [],
                    expanded: false,
                },
                {
                    id: "client_invoice",
                    key: 'client_payments',
                    icon: Icons.general.invoice,
                    label: 'Client Payments',
                    disabled: false,
                    commingsoon: false,
                    isSubMenus: false,
                    submenus: [],
                    expanded: false,
                },
            ]
        },
        {
            id: "drive",
            key: 'drive',
            icon: Icons.general.documents,
            label: 'Drive',
            isSubMenus: false,
            submenus: [],
            expanded: false,
        },
        {
            id: "reports",
            icon: Icons.general.report_file,
            label: 'Reports',
            disabled: false,
            commingsoon: false,
            isSubMenus: true,
            expanded: false,
            submenus: [
                {
                    id: "reports/progress-report",
                    key: 'project_reports',
                    icon: Icons.general.report,
                    label: 'Progress report',
                    disabled: false,
                    commingsoon: false,
                    isSubMenus: false,
                    submenus: [],
                    expanded: false,
                },
            ]
        },
    ]

    const [sidebar_items, setSidebar_items] = useState([])


    let selectSection = currentURL ? currentURL.split(`/builder/projects/${projectId}/`)[1] : undefined;

    const [selectedMenu, setSelectedMenu] = useState('')
    const selectedMenuRef = useRef(null);

    const [projects, setProjects] = useState([])

    const [ProjectsOptions, setProjectsOptions] = useState([])

    const [selectedProjectName, setSelectedProjectName] = useState('')
    const [selectedProjectId, setSelectedProjectId] = useState('')

    const getProjectsDetials = async () => {

        let filters = {
            minimal_data: true
        }

        let response = await projectsHandler.getProjectBasicsHandler(filters)

        if (response && response.success) {

            let projects = response.data

            let ProjectsOptions = projects.map((item) => {
                return {
                    value: item.projectID,
                    label: item.name
                }
            })
            setProjectsOptions(ProjectsOptions || [])

            if (projectId && Array.isArray(projects)) {

                let project = projects.find((item) => item.projectID == projectId)
                if (project) {
                    setSelectedProjectId(project.projectID)
                    setSelectedProjectName(project.name)

                    dispatch(updateState({
                        type: 'SET_ACTIVE_PROJECT',
                        payload: {
                            active_project: project
                        }
                    }))
                }

            }

            setProjects(projects || [])

        } else {
            setWarningAlert(true)
            setWarningAlertType('error')
            setApiFailedMessage(`Error in getting projects`)
        }
    }

    const HandleChangeProject = (value, label) => {
        setSelectedProjectId(value)
        setSelectedProjectName(label)
        navigate(`${ReverseProxy['proxyUrl']}/builder/projects/${value}/${sidebar_items[0].id}`)
    }

    const Hide_SideBar = () => {
        let sidebar_contanier = document.querySelector('#project-sidebar-main')

        let sidebar_active_class = "project-sidebar-active"
        sidebar_contanier.classList.remove(sidebar_active_class)
    }

    const HandleSidebarBackBtn = () => {
        navigate(`${ReverseProxy['proxyUrl']}/builder/projects`)
    }

    const HandleResetSubmenuExpand = (items) => {

        if (Array.isArray(items)) {
            items.forEach((item) => {
                item.expanded = false

                if (item.isSubMenus) {
                    item.submenus = HandleResetSubmenuExpand(item.submenus)
                }
            })
        }

        return items || []
    }

    const HandleOpenSubmenu = (id) => {

        let _menus = [...sidebar_items]


        const findAndUpdateGroup = (id, _menus) => {

            _menus = _menus.map((item, idx) => {

                if (item.id != id) item.expanded = false

                if (item.id == id) {
                    item.expanded = !item.expanded
                }
                else if (item.isSubMenus && Array.isArray(item.submenus)) {
                    item.submenus = findAndUpdateGroup(id, item.submenus)
                }

                return item
            })

            return _menus
        }

        _menus = findAndUpdateGroup(id, _menus)

        setSidebar_items(_menus)
    }

    const HandleSidebarMenu = (item, isSubItem) => {

        if (item.isSubMenus) {

            HandleOpenSubmenu(item.id)

        }
        else if (!item.disabled && !item.commingsoon) {

            if (!isSubItem) {
                let _menus = [...sidebar_items]

                _menus = HandleResetSubmenuExpand(_menus)

                setSidebar_items(_menus)
            }

            setSelectedMenu(item.id)
            navigate(`${ReverseProxy['proxyUrl']}/builder/projects/${projectId}/${item.id}`)
            Hide_SideBar()
        }
    }

    const LoadSetup = () => {
        if (selectSection) setSelectedMenu(selectSection)
        else if (projectId != 'create' && Array.isArray(sidebar_items) && sidebar_items.length) {
            setSelectedMenu(sidebar_items[0].id)
            navigate(`${ReverseProxy['proxyUrl']}/builder/projects/${projectId}/${sidebar_items[0].id}`)
        }

        if (!projects.length) getProjectsDetials()
    }

    useEffect(() => {


        LoadSetup()

    }, [])

    useEffect(() => {
        LoadSetup()
    }, [sidebar_items])

    useEffect(() => {

        if (isAdmin) {


            let sidebar_items = []

            SIDE_MENUS.forEach((_item, i) => {

                let item = { ..._item }

                if (['support', 'settings', 'drive'].includes(_item.key)) sidebar_items.push(item)

                else if (item.isSubMenus && Array.isArray(item.submenus)) {

                    let submenus = []

                    item.submenus.forEach((subitem, j) => {

                        let isCompanyAllow = company_features.some((role) => {

                            let feature_key = String(role.feature_key).split('--')[1]

                            return feature_key == subitem.key && role._view == 1
                        })


                        if (isCompanyAllow) submenus.push(subitem)

                    })

                    if (submenus.length) {
                        item.submenus = submenus
                        sidebar_items.push(item)
                    }
                }

                else {

                    let isCompanyAllow = company_features.some((role) => {

                        let feature_key = String(role.feature_key).split('--')[1]

                        return feature_key == item.key && role._view == 1
                    })


                    if (isCompanyAllow) sidebar_items.push(item)
                }



            })

            setSidebar_items(sidebar_items)
        }

        else if (roles_permissions && Array.isArray(roles_permissions)) {

            let sidebar_items = []

            SIDE_MENUS.forEach((_item, i) => {

                let item = { ..._item }

                if (['support', 'settings'].includes(_item.key)) sidebar_items.push(item)

                else if (item.isSubMenus && Array.isArray(item.submenus)) {

                    let submenus = []

                    item.submenus.forEach((subitem, j) => {

                        let isRoleAllow = roles_permissions.some((role) => {

                            let feature_key = String(role.feature_key).split('--')[1]

                            return feature_key == subitem.key && role._view == 1
                        })

                        let isCompanyAllow = company_features.some((role) => {

                            let feature_key = String(role.feature_key).split('--')[1]

                            return feature_key == subitem.key && role._view == 1
                        })


                        if (isRoleAllow && isCompanyAllow) submenus.push(subitem)

                    })

                    if (submenus.length) {
                        item.submenus = submenus
                        sidebar_items.push(item)
                    }
                }

                else {


                    let isRoleAllow = roles_permissions.some((role) => {

                        let feature_key = String(role.feature_key).split('--')[1]

                        return feature_key == item.key && role._view == 1
                    })

                    let isCompanyAllow = company_features.some((role) => {

                        let feature_key = String(role.feature_key).split('--')[1]

                        return feature_key == item.key && role._view == 1
                    })


                    if (isRoleAllow && isCompanyAllow) sidebar_items.push(item)
                }



            })

            setSidebar_items(sidebar_items)
        }



    }, [store.user?.roles_permissions])

    const CreateMenuItem = (item, i, isSubItem = false) => {


        const { id, disabled, expanded, commingsoon, icon, label, isSubMenus, submenus } = item


        return (
            <div
                className={`${disabled ? 'sidebar-item-disabled' : 'sidebar-item'} ${expanded ? 'sidebar-item-submenu-active' : ''} ${selectedMenu == id ? 'sidebar-item-active' : ''}`}
                key={id}

                ref={selectedMenu == id ? selectedMenuRef : null}
            >
                <div
                    className="sidebar-item-menu"
                    onClick={() => HandleSidebarMenu(item, isSubItem)}
                >
                    <span
                        className='icon'
                        dangerouslySetInnerHTML={{ __html: icon }}
                    ></span>
                    <span className='label'>{label}</span>
                    {commingsoon ?
                        <span
                            className='sidebar-item-commingsoon icon'
                            dangerouslySetInnerHTML={{ __html: Icons.general.commingsoon }}
                        ></span> : null
                    }
                    {isSubMenus ?
                        <span
                            className={`sidebar-item-expanded ${expanded ? 'sidebar-item-expanded-open' : ''}`}
                            dangerouslySetInnerHTML={{ __html: Icons.general.dropdown_arrow }}
                        ></span> : null
                    }
                </div>
                <div className={`sidebar-item-submenu-main ${expanded ? 'sidebar-item-submenu-open' : ''}`}>

                    {isSubMenus && Array.isArray(submenus) ? submenus.map((submenu, i) => (
                        CreateMenuItem(submenu, i, true)
                    )) : ''}
                </div>
            </div>
        )
    }

    return (
        <div className='project-project_sidebar-main' id='project-sidebar-main'>
            <div className="sidebar-header">
                <div className="header-title">Menus</div>
                <div
                    className="header-close"
                    dangerouslySetInnerHTML={{ __html: Icons.general.close }}
                    onClick={(e) => Hide_SideBar(e)}
                ></div>
            </div>
            <div className="sidebar-logo-main">
                <div
                    className="sidebar-back-btn"
                    onClick={(e) => HandleSidebarBackBtn()}
                    dangerouslySetInnerHTML={{ __html: Icons.general.back_arrow }}
                >
                </div>
                <div
                    className="sidebar-logo"
                    onClick={(e) => HandleSidebarMenu(sidebar_items[0])}
                >
                    <img src={Image.logo} />

                </div>
            </div>
            <div className="sidebar-projects-list">

                <div className="projects-select-main">
                    <SelectInput
                        props={{
                            id: "sidebar-select-project",
                            value: selectedProjectId,
                            placeholder: '',
                            readOnly: false,
                            options: ProjectsOptions || [],
                            setValue: (value, label) => HandleChangeProject(value, label),
                            isStatus: false,
                            isIcon: true,
                            icon: Icons.general.takeoff,
                            disableDefaultIcon: false,
                            isLabel: false,
                            isRequired: true,
                            label: "",
                        }}
                    />
                </div>
            </div>
            <div className="sidebar-items">

                {sidebar_items.map((item, i) => (

                    CreateMenuItem(item, i)

                ))}

            </div>
        </div>
    )
}

export default Mainsidebar;
