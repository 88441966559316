

import React, { useEffect, useState, useRef, useLayoutEffect } from 'react'
import { Route, Routes, BrowserRouter, Link, useLocation, Navigate, Outlet, useNavigate, useParams } from 'react-router-dom';
import store from '../../redux/store'
import StoreHandler from '../../redux/StoreHandler'
import { useSelector, useDispatch } from 'react-redux'
import UserAction from '../../redux/action/userAction'

import ReverseProxy from '../../config/reverseProxy'
import TaskManagementHandler from '../../Handlers/TaskManagement/TaskManagement';
import IssuesManagementHandler from '../../Handlers/IssuesManagement/IssuesManagement';
import MaterialIssueHandler from '../../Handlers/MaterialIssue/MaterialIssue';
import CommentsHandler from '../../Handlers/Comments/Comments';

import Icons from '../../assets/Icons'
import Images from '../../assets/Images'
import { CostInput, TextInput, SelectInput, DateTimeInput, RadioInput } from '../../components/Inputs'
import Utils from '../../utils'
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

import * as XLSX from 'xlsx';
import Excel from "exceljs";

import SampleUploadTaskFile from './sample_upload_task_file.json'

const UploadTaskFilePopup = ({ props }) => {

    const { existItemData, close, updateCallback } = props


    const { projectId, itemType, itemId, actionType } = useParams()

    const taskManagementHandler = new TaskManagementHandler()

    const [name, setName] = useState('')
    const [ParentTask_id, setParentTask_id] = useState('')
    const [ParentTask_name, setParentTask_name] = useState('')
    const [description, setDescription] = useState('')

    const [isLoading, setIsLoading] = useState(false)
    const [warningAlert, setWarningAlert] = useState(false)
    const [warningAlertType, setWarningAlertType] = useState('warning')
    const [apiFailedMessage, setApiFailedMessage] = useState("Error in Server")
    const [WorkTypeOptions, setWorkTypeOptions] = useState([])
    const [currency_type, setCurrency_type] = useState('₹')

    const navigate = useNavigate()
    const store = useSelector((store) => store)
    const dispatch = useDispatch()
    const { updateState } = new UserAction


    const storeHandler = new StoreHandler({
        dataStack: [`project-${projectId}`, 'costcodes'],
        store: store,
        dispatch: dispatch,
        updateState: updateState,
    })

    const LoadStoreData = async () => {
        setIsLoading(true)
        let resolved = await storeHandler.init()
        setIsLoading(false)
    }

    const isExistItemData = existItemData && existItemData.id != undefined
    const [additionalNotes, setAdditionalNotes] = useState(false);
    const FileInputRef = useRef()

    const [FileUploadLoading, setFileUploadLoading] = useState(false)
    const [SelectedFileType, setSelectedFileType] = useState('xlsx')
    const [FileTypes, setFileTypes] = useState([
        {
            id: 'xlsx',
            name: 'XLS/XLSX',
            accepted: '.xls,.xlsx',
            icon: Icons.general.file_type_xlsx,
        }
    ])

    const HandleFiletypeSelected = (id) => {
        setSelectedFileType(id)
    }

    const HandleUploadFileSelect = (e) => {
        if (FileInputRef.current) FileInputRef.current.click()
    }
    const HandleUploadFile = async (e) => {

        let file = e.target.files[0]

        if (!file) return

        // e.preventDefault()

        const formData = new FormData();

        formData.append('projectId', projectId)
        formData.append('plan_file', file)

        setFileUploadLoading(true)

        let response = await taskManagementHandler.uploadBulkTasksHandler(formData)

        setFileUploadLoading(false)

        if (response.success) {

            if (typeof updateCallback == 'function') updateCallback()
        }
        else {
            setWarningAlert(true)
            setWarningAlertType('error')
            setApiFailedMessage(`Error in uploading file`)
        }

    }

    const HandleCancel = () => {
        close()
    }
    const HandleDownloadSampleFile = async () => {

        const jsonData = SampleUploadTaskFile

        let workbook = new Excel.Workbook();
        let worksheet = workbook.addWorksheet('Project Plan');

        let header_column = Object.keys(jsonData['projectplan'][0])

        let headerRow = worksheet.addRow(header_column);

        worksheet.properties.defaultColWidth = 40;

        headerRow.eachCell({ includeEmpty: true }, (cell, number) => {

            let column_name = cell._value.model.value

            cell.numFmt = 'dd-mm-yyyy'
            cell.font = {
                bold: true,
                size: 12
            }
            cell.fill = {
                type: 'pattern',
                pattern: 'solid',

                fgColor: { argb: 'A4C2F4' },
                bgColor: { argb: 'FFFFFF' }
            };
            cell.border = {
                top: { style: 'thin' },
                left: { style: 'thin' },
                bottom: { style: 'thin' },
                right: { style: 'thin' }
            };
        });

        jsonData['projectplan'].forEach((item, index) => {

            let row_data = header_column.map(column => item[column])

            worksheet.addRow(row_data);

        })

        let dropdownlist = WorkTypeOptions.map(item => item.name) || []

        let joineddropdownlist = "\"" + dropdownlist.join(',') + "\"";
        for (let i = 1; i < 1000; i++) {
            worksheet.getCell('B' + i).dataValidation = {
                type: 'list',
                allowBlank: true,
                formulae: [joineddropdownlist]
            };
        }

        workbook.xlsx.writeBuffer().then((data) => {
            let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
            // Create a download link and click it programmatically
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.download = `Project_${projectId}_Plan.xlsx`;
            // document.body.appendChild(a);
            a.click();

            // Clean up to avoid memory leaks
            window.URL.revokeObjectURL(url);
            // document.body.removeChild(a);
        });



    }

    const Popup_Header = ({ props }) => {
        return (
            <div className="side-popup-header">
                <div className="header-item-select">
                    <div className="header-item-select-content">
                        <span className="icon" dangerouslySetInnerHTML={{ __html: Icons.general.tasks }}></span>
                        <div className="label">Upload Plan</div>

                    </div>
                </div>
                <div
                    className="header-item-close"
                    onClick={(e) => HandleCancel()}
                    dangerouslySetInnerHTML={{ __html: Icons.general.close }}
                ></div>
            </div>
        );
    };

    useEffect(() => {

        LoadStoreData()

    }, [])

    useEffect(() => {

        if (store && store.user) {

            let { costcodes } = store.user


            if (Array.isArray(costcodes)) {


                setWorkTypeOptions(costcodes)
            }
        }


    }, [store])

    return (
        <div className="popup-container-main popup-container-center">
            <div className="popup-block-ui"></div>
            <div className="side-popup-container">

                <Popup_Header />

                <div className="sidebar-popup-content">

                    <div className="file-upload-section-main">

                        <div className="upload-type-options-main">
                            <div className="type-options-label">FILE TYPE:</div>
                            <div className="type-options-items">
                                {
                                    FileTypes?.map(filetype => (

                                        <div
                                            className={`type-options-item ${SelectedFileType == filetype.id ? 'type-options-item-active' : ''}`}
                                            key={`filetype-${filetype.id}`}
                                            onClick={(e) => HandleFiletypeSelected(filetype.id)}
                                        >
                                            <input
                                                type="radio"
                                                name='type-option-name'
                                                id={`upload-filetype-${filetype.id}`}
                                                checked={SelectedFileType == filetype.id ? true : false}

                                            />
                                            <label className="label">{filetype.name}</label>
                                        </div>

                                    ))
                                }
                            </div>
                        </div>
                        <div className="upload-dragdown-main">

                            <div className="upload-dragdown-input"
                                onClick={(e) => HandleUploadFileSelect()}
                            >
                                <input
                                    className='upload-file-input'
                                    type="file"
                                    id="upload-file-input"
                                    accept={FileTypes?.find(filetype => filetype.id == SelectedFileType)?.accepted}
                                    ref={FileInputRef}
                                    onChange={(e) => HandleUploadFile(e)}
                                />
                                <div
                                    className="dragdown-icon"
                                    dangerouslySetInnerHTML={{ __html: Icons.general.dragdrop_big_icon }}
                                ></div>
                                <div className="dragdown-label">Drop or Select file to Upload</div>

                            </div>
                            <div
                                className="upload-dragdrop-download-sample-main"
                            >
                                <div
                                    className="dragdrop-download-sample-item"
                                    onClick={(e) => HandleDownloadSampleFile()}
                                >
                                    <div className="icon"
                                        dangerouslySetInnerHTML={{ __html: Icons.general.download_mini }}
                                    ></div>
                                    <div className="label">Download sample spreadsheet</div>
                                </div>

                            </div>

                        </div>



                    </div>

                </div>
            </div>
        </div>
    )
}


export default UploadTaskFilePopup;