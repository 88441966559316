import { useLocation, Navigate, Outlet } from 'react-router-dom'
import { useAuth } from './useAuth'
import ReverseProxy from '../config/reverseProxy'

const RequireAuth = ({ allowedRoles }) => {
    const location = useLocation()
    const { isAdmin, isAuthenticated, roles_permissions } = useAuth()


    const allowed = allowedRoles.some((r) => ['admin']?.includes(r))


    return allowed ? <Outlet /> : <Navigate to={`${ReverseProxy['proxyUrl']}/quick-menu`} state={{ from: location }} replace />


}

export default RequireAuth
