

import React, { useEffect, useState, useRef } from 'react'
import { Route, Routes, BrowserRouter, Link, useLocation, Navigate, Outlet, useNavigate, useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux'
import UserAction from '../../redux/action/userAction'
import StoreHandler from '../../redux/StoreHandler'

import ReverseProxy from '../../config/reverseProxy'
import SettingsHandler from '../../Handlers/Settings/Settings';
import Icons from '../../assets/Icons'
import Images from '../../assets/Images'
import { CostInput, TextInput, SelectInput, RadioInput } from '../../components/Inputs'
import Utils from '../../utils'


import AlertPopup from '../../components/AlertPopup'
import Loading from '../../components/Loading'
import SystemToastPopup from '../../components/SystemToastPopup'
import { useAuth, useMenuAuth } from '../../hooks/useAuth'

const SystemConfig = () => {

    const settingsHandler = new SettingsHandler()

    const [isLoading, setIsLoading] = useState(false)
    const [warningAlert, setWarningAlert] = useState(false)
    const [warningAlertType, setWarningAlertType] = useState('warning')
    const [apiFailedMessage, setApiFailedMessage] = useState("Error in Server")

    const [Temperature, setTemperature] = useState('')
    const [DateFormat, setDateFormat] = useState('')
    const [Language, setLanguage] = useState('')
    const [Currency, setCurrency] = useState('')

    const [TemperatureOptions, setTemperatureOptions] = useState([
        {
            value: "fahrenheit",
            label: "Fahrenheit"
        },
        {
            value: "celsius",
            label: "Celsius"
        },
    ])
    const [DateFormatOptions, setDateFormatOptions] = useState([
        {
            value: "MM/DD/YYYY",
            label: "MM/DD/YYYY"
        },
        {
            value: "DD/MM/YYYY",
            label: "DD/MM/YYYY"
        },
    ])
    const [LanguageOptions, setLanguageOptions] = useState([
        {
            value: "en_in",
            label: "English (India)"
        }
    ])
    const [CurrencyOptions, setCurrencyOptions] = useState([
        {
            value: "₹",
            label: "INR (₹)"
        },
        {
            value: "$",
            label: "USD ($)"
        },
        {
            value: "€",
            label: "EUR (€)"
        },
    ])


    const [existingDetials, setExistingDetials] = useState({})

    const store = useSelector((store) => store)
    const dispatch = useDispatch()
    const { updateState } = new UserAction

    const storeHandler = new StoreHandler({
        dataStack: ['company'],
        store: store,
        dispatch: dispatch,
        updateState: updateState,
    })
    const LoadStoreData = async () => {
        setIsLoading(true)
        let resolved = await storeHandler.init()
        setIsLoading(false)
    }

    const navigate = useNavigate()
    const { isAdmin, menu_permission } = useMenuAuth({ menu: 'settings--system_configuration' })
    const location = useLocation()

    const [selected_view_settings_value, setSelected_view_settings_value] = useState('')
    const [has_view_access, setHas_view_access] = useState(false)
    const [has_add_access, setHas_add_access] = useState(false)
    const [has_edit_access, setHas_edit_access] = useState(false)
    const [has_delete_access, setHas_delete_access] = useState(false)

    useEffect(() => {

        if (isAdmin) {

            setHas_view_access(true)
            setHas_add_access(true)
            setHas_edit_access(true)
            setHas_delete_access(true)
        }

        else if (menu_permission) {

            if (
                !menu_permission._view
                &&
                (!menu_permission.selected_view_settings_value
                    || menu_permission.selected_view_settings_value == 'no_access')
            ) {
                navigate(`${ReverseProxy['proxyUrl']}/quick-menu`, { state: { from: location }, replace: true })
            }

            if (menu_permission._view == '0') navigate(`${ReverseProxy['proxyUrl']}/builder/projects`, { state: { from: location }, replace: true })

            setHas_view_access(menu_permission._view == '1')
            setHas_add_access(menu_permission._create == '1')
            setHas_edit_access(menu_permission._edit == '1')
            setHas_delete_access(menu_permission._delete == '1')

            setSelected_view_settings_value(menu_permission.selected_view_settings_value)

        }

    }, [store])


    const HandleformSubmit = async (e) => {
        e.preventDefault()

        if (!has_edit_access) return

        let update_data = {
            temperature: String(Temperature),
            date_format: String(DateFormat),
            language: String(Language),
            currency: String(Currency),
        }

        setIsLoading(true)

        let response = await settingsHandler.updateSystemSettingsHandler(update_data)

        if (response.success) {
            LoadStoreData()
            setIsLoading(false)
        }
        else {
            setIsLoading(false)
            setWarningAlert(true)
            setWarningAlertType('error')
            setApiFailedMessage(`Error in updating system settings`)
        }
    }

    useEffect(() => {
        LoadStoreData()
    }, [])

    useEffect(() => {

        if (store.user.company) setExistingDetials(store.user.company)

    }, [store])

    useEffect(() => {

        if (existingDetials) {

            if (existingDetials.temperature_setting) setTemperature(existingDetials.temperature_setting)
            if (existingDetials.date_format_setting) setDateFormat(existingDetials.date_format_setting)
            if (existingDetials.language_setting) setLanguage(existingDetials.language_setting)
            if (existingDetials.currency_setting) setCurrency(existingDetials.currency_setting)

        }

    }, [existingDetials])

    return (

        <>

            {isLoading ?
                <Loading
                    props={{
                        isMainLogo: false,
                        isLabel: true
                    }} />
                : null}
            {warningAlert ?

                <SystemToastPopup
                    props={{
                        type: warningAlertType,
                        message: apiFailedMessage || "Error in System Settings update",
                        callback: (confirmation) => setWarningAlert(false)
                    }} />

                : null}


            <form className="settings-panel-content" onSubmit={HandleformSubmit}>
                <div className="panel-content-main">

                    <div className="panel-content-header">
                        <div className="title">System configuration</div>
                    </div>
                    <div className="panel-content-sections">

                        <div className="panel-content-section">
                            <div className="content-items">
                                <div className="content-item">
                                    <SelectInput
                                        props={{
                                            id: "createclient-temperature",
                                            value: Temperature,
                                            placeholder: '',
                                            options: TemperatureOptions || [],
                                            setValue: (value, label) => {
                                                setTemperature(value)
                                            },
                                            isIcon: false,
                                            isLabel: true,
                                            isRequired: true,
                                            label: "Temperature Formatting",
                                        }}
                                    />
                                </div>
                            </div>
                            <div className="content-items">
                                <div className="content-item">
                                    <SelectInput
                                        props={{
                                            id: "createclient-dateFormat",
                                            value: DateFormat,
                                            placeholder: '',
                                            options: DateFormatOptions || [],
                                            setValue: (value, label) => {
                                                setDateFormat(value)
                                            },
                                            isIcon: false,
                                            isLabel: true,
                                            isRequired: true,
                                            label: "Date Formatting",
                                        }}
                                    />
                                </div>
                            </div>
                            <div className="content-items">
                                <div className="content-item">
                                    <SelectInput
                                        props={{
                                            id: "createclient-language",
                                            value: Language,
                                            placeholder: '',
                                            options: LanguageOptions || [],
                                            setValue: (value, label) => {
                                                setLanguage(value)
                                            },
                                            isIcon: false,
                                            isLabel: true,
                                            isRequired: true,
                                            label: "Language",
                                        }}
                                    />
                                </div>
                            </div>
                            <div className="content-items">
                                <div className="content-item">
                                    <SelectInput
                                        props={{
                                            id: "createclient-currency",
                                            value: Currency,
                                            placeholder: '',
                                            options: CurrencyOptions || [],
                                            setValue: (value, label) => {
                                                setCurrency(value)
                                            },
                                            isIcon: false,
                                            isLabel: true,
                                            isRequired: true,
                                            label: "Currency",
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
                <div className="panel-content-footer">
                    <div className="content-footer-items">
                        <div className="footer-button">
                            <div className="label">Cancel</div>
                        </div>
                        <button type='submit' className="footer-button footer-button-active">

                            <div className="label">Save Changes</div>
                        </button>
                    </div>
                </div>
            </form>
        </>
    )
}

export default SystemConfig;
