
import proxyConfig from '../../config/reverseProxy'
import HeaderConfig from '../../config/header'

class CheckLists {

    constructor() {

    }


    async getChecklistTemplatesHandler(params) {

        try {

            let response = await fetch(proxyConfig['serverBaseUrl'] + '/builder/checklist/get-templates', {
                method: 'POST',
                headers: HeaderConfig.getHeader({ method: 'POST', isToken: true }),
                body: JSON.stringify(params)
            })

            response = await response.json()

            if (response) return response
        } catch {
            return { success: false }
        }


    }
    async createChecklistTemplateHandler(params) {

        try {

            let response = await fetch(proxyConfig['serverBaseUrl'] + '/builder/checklist/create-template', {
                method: 'POST',
                headers: HeaderConfig.getHeader({ method: 'POST', isToken: true }),
                body: JSON.stringify(params)
            })

            response = await response.json()

            if (response) return response
        } catch {
            return { success: false }
        }


    }
    async updateChecklistTemplateHandler(params) {

        try {

            let response = await fetch(proxyConfig['serverBaseUrl'] + '/builder/checklist/update-template', {
                method: 'POST',
                headers: HeaderConfig.getHeader({ method: 'POST', isToken: true }),
                body: JSON.stringify(params)
            })

            response = await response.json()

            if (response) return response
        } catch {
            return { success: false }
        }


    }
    async deleteChecklistTemplateHandler(params) {

        try {

            let response = await fetch(proxyConfig['serverBaseUrl'] + '/builder/checklist/delete-template', {
                method: 'POST',
                headers: HeaderConfig.getHeader({ method: 'POST', isToken: true }),
                body: JSON.stringify(params)
            })

            response = await response.json()

            if (response) return response
        } catch {
            return { success: false }
        }


    }

    async getChecklistsHandler(params) {

        try {

            let response = await fetch(proxyConfig['serverBaseUrl'] + '/builder/checklist/get-checklists', {
                method: 'POST',
                headers: HeaderConfig.getHeader({ method: 'POST', isToken: true }),
                body: JSON.stringify(params)
            })

            response = await response.json()

            if (response) return response
        } catch {
            return { success: false }
        }


    }
    async createChecklistHandler(params) {

        try {

            let response = await fetch(proxyConfig['serverBaseUrl'] + '/builder/checklist/create-checklist', {
                method: 'POST',
                headers: HeaderConfig.getHeader({ method: 'POST', isToken: true }),
                body: JSON.stringify(params)
            })

            response = await response.json()

            if (response) return response
        } catch {
            return { success: false }
        }


    }
    async updateChecklistHandler(params) {

        try {

            let response = await fetch(proxyConfig['serverBaseUrl'] + '/builder/checklist/update-checklist', {
                method: 'POST',
                headers: HeaderConfig.getHeader({ method: 'POST', isToken: true }),
                body: JSON.stringify(params)
            })

            response = await response.json()

            if (response) return response
        } catch {
            return { success: false }
        }


    }
    async deleteChecklistHandler(params) {

        try {

            let response = await fetch(proxyConfig['serverBaseUrl'] + '/builder/checklist/delete-checklist', {
                method: 'POST',
                headers: HeaderConfig.getHeader({ method: 'POST', isToken: true }),
                body: JSON.stringify(params)
            })

            response = await response.json()

            if (response) return response
        } catch {
            return { success: false }
        }


    }


}

export default CheckLists;