

import React, { useEffect, useState, useRef } from 'react'
import { Route, Routes, BrowserRouter, Link, useLocation, Navigate, Outlet, useNavigate, useParams } from 'react-router-dom';
import store from '../../redux/store'
import { useSelector, useDispatch } from 'react-redux'
import UserAction from '../../redux/action/userAction'

import ReverseProxy from '../../config/reverseProxy'
import RoleManagementHandler from '../../Handlers/RoleManagement/RoleManagement';

import Icons from '../../assets/Icons'
import Images from '../../assets/Images'
import { CostInput, TextInput, SelectInput, RadioInput } from '../../components/Inputs'
import Utils from '../../utils'
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

import AlertPopup from '../../components/AlertPopup'
import Loading from '../../components/Loading'
import NoDataFound from '../../components/NoDataFound';
import SystemToastPopup from '../../components/SystemToastPopup'

import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { useAuth, useMenuAuth } from '../../hooks/useAuth'

import CreateNewRole from './CreateNewRole';

const RoleManagement = () => {

    const store = useSelector((store) => store)
    const dispatch = useDispatch()
    const { updateState } = new UserAction

    const navigate = useNavigate()

    const roleManagement = new RoleManagementHandler()

    const [isLoading, setIsLoading] = useState(false)
    const [warningAlert, setWarningAlert] = useState(false)
    const [warningAlertType, setWarningAlertType] = useState('warning')
    const [apiFailedMessage, setApiFailedMessage] = useState("Error in Server")

    const [RolesDetails_original, setRolesDetails_original] = useState([])
    const [RolesDetails, setRolesDetails] = useState([])
    const [selected_Role, setSelected_Role] = useState({})
    const [selectedAction, setSelectedAction] = useState('')

    const { isAdmin, menu_permission } = useMenuAuth({ menu: 'global_menus--role_managment', feature_key: 'teamManagement' })
    const location = useLocation()

    const [selected_view_settings_value, setSelected_view_settings_value] = useState('')
    const [has_view_access, setHas_view_access] = useState(false)
    const [has_add_access, setHas_add_access] = useState(false)
    const [has_edit_access, setHas_edit_access] = useState(false)
    const [has_delete_access, setHas_delete_access] = useState(false)


    const [deleteItemId, setDeleteItem] = useState(null)
    const [deleteItemType, setDeleteItemType] = useState(null)
    const [deleteItemAlert, setDeleteItemAlert] = useState(false)


    useEffect(() => {

        if (isAdmin) {

            setHas_view_access(true)
            setHas_add_access(true)
            setHas_edit_access(true)
            setHas_delete_access(true)
        }

        else if (menu_permission) {

            if (!menu_permission._view && (!menu_permission.selected_view_settings_value || menu_permission.selected_view_settings_value == 'no_access')) {
                navigate(`${ReverseProxy['proxyUrl']}/quick-menu`, { state: { from: location }, replace: true })
            }

            if (menu_permission._view == '0') navigate(`${ReverseProxy['proxyUrl']}/builder/projects`, { state: { from: location }, replace: true })

            setHas_view_access(menu_permission._view == '1')
            setHas_add_access(menu_permission._create == '1')
            setHas_edit_access(menu_permission._edit == '1')
            setHas_delete_access(menu_permission._delete == '1')

            setSelected_view_settings_value(menu_permission.selected_view_settings_value)

        }

    }, [store])

    const tabelHeader = [
        {
            id: 'sno',
            label: 'SI.No',
            maxWidth: 30,

        },
        {
            id: 'name',
            label: 'Project Level Role Name',
            minWidth: 200,

        },
        {
            id: 'type',
            label: 'Role Type',
            format: (value) => {
                if (value == "0") return "Default"
                if (value == "1") return "Customized"
            },
        },
        {
            id: 'status',
            label: 'Status',
            format: (value) => {
                if (value == "0") return "Inactive"
                if (value == "1") return "Active"
                if (value == "2") return "Blocked"
            },
        },
        {
            id: 'actions',
            label: 'Actions',
            minWidth: 60,
            align: 'right',
            format: (value) => value,
        },
    ];

    const getRoleDetails = async () => {

        setIsLoading(true)

        let response = await roleManagement.getRoleBasicsHandler()

        setIsLoading(false)

        if (response && response.success) {

            let roles = response.data || []

            roles = roles.map((role, index) => {
                role.sno = `${index + 1}.`

                return role
            })

            setRolesDetails_original(roles)
            setRolesDetails(roles)

        } else {
            setWarningAlert(true)
            setWarningAlertType('error')
            setApiFailedMessage(`Error in getting users detials`)
        }
    }

    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const HandleAddUser = () => {
        if (!has_add_access) return
        navigate(`${window.location.pathname}/add-user`)
    }
    const HandleEditUser = (user) => {
        if (!has_edit_access) return
        setSelected_Role(user)

        navigate(`${window.location.pathname}/edit/${user.id}`)
    }
    const HandleDeleteUser = async (item) => {
        setDeleteItem(item.id)
        setDeleteItemType('')
        setDeleteItemAlert(true)
    }
    const HandleDeleteConfirmItem = async (id, type, confirmation) => {
        if (!has_edit_access || !has_delete_access) return

        if (!confirmation || !id) {

            setDeleteItem(null)
            setDeleteItemType(null)
            setDeleteItemAlert(false)

            return
        }

        setIsLoading(true)
        let response = await roleManagement.deleteRoleHandler({
            id: id
        })

        setIsLoading(false)

        if (response && response.success) {

            getRoleDetails()

        } else {
            setWarningAlert(true)
            setWarningAlertType('error')
            setApiFailedMessage(`Error in deleting user detials`)
        }
        setDeleteItem(null)
        setDeleteItemType(null)
        setDeleteItemAlert(false)

    }

    const HanelCellAction = (id, row) => {

        console.log(selectedAction, 'selectedAction');
        if (id == "name") HandleEditUser(row)
        if (id == "actions") {
            setSelectedAction(row.id)
            HandleActionDisable(row.id)
        }
    }
    const HandleActionDisable = (id) => {

        let input = document.getElementById(`teamManagement-tabel-action-btn-${id}`)
        let dropdown_items = document.getElementById(`teamManagement-tabel-action-dropdown-${id}`)

        const Event = (e) => {
            let path = e.path || (e.composedPath && e.composedPath());

            if (Array.isArray(path) && !path.includes(dropdown_items) && !path.includes(input)) {
                setSelectedAction('')
                window.removeEventListener('click', Event)
            }
        }

        window.addEventListener('click', Event)
    }


    const CloseCreateRolePopup = (type) => {

        setSelected_Role({})

        navigate(`${ReverseProxy['proxyUrl']}/builder/role-management`)
    }
    const CreateRoleCallbackPopup = async (type) => {

        await getRoleDetails()
        // CloseCreateRolePopup(type)
    }

    useEffect(() => {
        getRoleDetails()
    }, [])


    return (
        <>

            {isLoading ?

                <Loading
                    props={{
                        isMainLogo: false,
                        isLabel: true
                    }} />

                : null}

            {warningAlert ?

                <SystemToastPopup
                    props={{
                        type: warningAlertType,
                        message: apiFailedMessage || "Error in Role Management",
                        callback: (confirmation) => setWarningAlert(false)
                    }} />

                : null}


            {deleteItemAlert ?

                <AlertPopup
                    props={{
                        type: "delete",
                        actionBtnOption: { label: "Yes, Delete" },
                        heading: "Delete Role",
                        message: 'Are you sure you want to delete the role?',
                        callback: (confirmation) => HandleDeleteConfirmItem(deleteItemId, deleteItemType, confirmation)
                    }} />

                : null}

            <Routes >
                <Route exact path='/add-user' element={
                    <CreateNewRole props={{
                        type: "create",
                        heading: "Add New Role",
                        callback: CreateRoleCallbackPopup,
                        close: CloseCreateRolePopup
                    }} />}></Route>
                <Route exact path='/edit/:roleId' element={
                    <CreateNewRole props={{
                        type: "edit",
                        heading: "Edit Role",
                        callback: CreateRoleCallbackPopup,
                        close: CloseCreateRolePopup,
                        detials: selected_Role
                    }} />}></Route>
            </Routes>

            <div className="project-teamManagement-main">
                <div className="teamManagement-panel-content">


                    <div className="smart-panel-content-main">
                        <div className="panel-content-header">
                            <div className="title">Project Level Roles</div>
                            <div className="buttons">
                                {has_add_access ?
                                    <div
                                        className="button add-button"
                                        onClick={(e) => HandleAddUser()}
                                    >
                                        <div className="icon" dangerouslySetInnerHTML={{ __html: Icons.general.add_btn }}></div>
                                        <div className="label">Add New</div>
                                    </div>
                                    : ''}
                            </div>
                        </div>
                        <div className="panel-content-sections panel-content-tabel-section">
                            {RolesDetails.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).length ?
                                <Paper style={{ width: '100%', height: '100%', boxShadow: 'none', overflow: 'hidden' }}>
                                    <TableContainer style={{ height: '93%', boxShadow: 'none !important', }}>
                                        <Table stickyHeader>
                                            <TableHead
                                                className='tabel-header'
                                            >
                                                <TableRow>
                                                    {tabelHeader.map((column) => (
                                                        <TableCell
                                                            key={column.id}
                                                            align={column.align}
                                                            style={{
                                                                minWidth: column.minWidth,
                                                                maxWidth: column.maxWidth,
                                                            }}
                                                            className='tabel-header-items'
                                                        >
                                                            {column.label}
                                                        </TableCell>
                                                    ))}
                                                </TableRow>
                                            </TableHead>
                                            <TableBody className='tabel-body'>
                                                {RolesDetails
                                                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                                    .map((row) => {
                                                        return (
                                                            <TableRow hover role="checkbox" tabIndex={-1} key={row.id}>
                                                                {tabelHeader.map((column) => {
                                                                    const value = row[column.id];
                                                                    return (
                                                                        <TableCell
                                                                            key={column.id}
                                                                            align={column.align}
                                                                            onClick={(e) => {
                                                                                if (column.id != 'actions') HanelCellAction(column.id, row)
                                                                            }}
                                                                        >
                                                                            {
                                                                                column.id == 'name' ?
                                                                                    <div
                                                                                        className="tabel-name"
                                                                                    >
                                                                                        {column.format ? column.format(value) : value}
                                                                                    </div>

                                                                                    : column.id == 'actions' ?

                                                                                        <div
                                                                                            className="tabel-action-main"
                                                                                        >

                                                                                            {has_edit_access ?
                                                                                                <div
                                                                                                    className="action-icon"
                                                                                                    dangerouslySetInnerHTML={{ __html: Icons.general.edit_btn }}
                                                                                                    onClick={(e) => HandleEditUser(row)}
                                                                                                ></div>
                                                                                                : ''}
                                                                                            {has_delete_access ?
                                                                                                <div
                                                                                                    className="action-icon"
                                                                                                    dangerouslySetInnerHTML={{ __html: Icons.general.delete_btn }}
                                                                                                    onClick={(e) => HandleDeleteUser(row)}
                                                                                                ></div>
                                                                                                : ''}

                                                                                        </div>
                                                                                        : column.format ? column.format(value) : value
                                                                            }
                                                                        </TableCell>
                                                                    );
                                                                })}
                                                            </TableRow>
                                                        );
                                                    })}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                    <TablePagination
                                        style={{ height: '7%', overflow: 'hidden' }}
                                        rowsPerPageOptions={[10, 25, 100]}
                                        component="div"
                                        count={RolesDetails.length || 0}
                                        rowsPerPage={rowsPerPage}
                                        page={page}
                                        onPageChange={handleChangePage}
                                        onRowsPerPageChange={handleChangeRowsPerPage}
                                    />
                                </Paper>
                                : <NoDataFound label={'No Roles Found'} />}
                        </div>
                    </div>
                </div>

            </div>
        </>
    )
}

export default RoleManagement;