

import React, { useEffect, useState, useRef, useCallback, useMemo, StrictMode } from 'react'
import { Route, Routes, BrowserRouter, Link, useLocation, Navigate, Outlet, useNavigate, useParams } from 'react-router-dom';
import store from '../../redux/store'
import StoreHandler from '../../redux/StoreHandler'
import { useSelector, useDispatch } from 'react-redux'
import UserAction from '../../redux/action/userAction'

import ReverseProxy from '../../config/reverseProxy'
import CheckListsHandler from '../../Handlers/CheckLists/CheckLists';


import Icons from '../../assets/Icons'
import Images from '../../assets/Images'
import { TextArea, TextInput, SelectInput, TimeInput, MultiSelectInput } from '../../components/Inputs'
import Utils from '../../utils'
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

import SystemToastPopup from '../../components/SystemToastPopup'
import AlertPopup from '../../components/AlertPopup'
import Loading from '../../components/Loading'
import NoDataFound from '../../components/NoDataFound'

import { useAuth, useMenuAuth } from '../../hooks/useAuth'


const CreateCheckListTemplate = ({ props }) => {

    const { projectId, listId } = useParams();

    const { menuType = 'create' } = props

    const [project_id, setProject_id] = useState('')
    const [project_name, setProject_name] = useState('')

    const [isLoading, setIsLoading] = useState(false)
    const [warningAlert, setWarningAlert] = useState(false)
    const [warningAlertType, setWarningAlertType] = useState('warning')
    const [apiFailedMessage, setApiFailedMessage] = useState('Error in Bills')

    const navigate = useNavigate()
    const store = useSelector((store) => store)
    const dispatch = useDispatch()
    const { updateState } = new UserAction

    const formSubmitBtnRef = useRef()

    const storeHandler = new StoreHandler({
        dataStack: ['team_users'],
        store: store,
        dispatch: dispatch,
        updateState: updateState,
    })

    const LoadStoreData = async () => {
        setIsLoading(true)
        let resolved = await storeHandler.init()
        setIsLoading(false)
    }

    const { isAdmin, menu_permission } = useMenuAuth({ menu: 'safety--checklists_templates', feature_key: 'checklists_templates' })
    const location = useLocation()

    const [selected_view_settings_value, setSelected_view_settings_value] = useState('')
    const [has_view_access, setHas_view_access] = useState(false)
    const [has_add_access, setHas_add_access] = useState(false)
    const [has_edit_access, setHas_edit_access] = useState(false)
    const [has_delete_access, setHas_delete_access] = useState(false)

    useEffect(() => {

        if (isAdmin) {

            setHas_view_access(true)
            setHas_add_access(true)
            setHas_edit_access(true)
            setHas_delete_access(true)
        }

        else if (menu_permission) {

            if (
                !menu_permission._view
                &&
                (!menu_permission.selected_view_settings_value
                    || menu_permission.selected_view_settings_value == 'no_access')
            ) {
                navigate(`${ReverseProxy['proxyUrl']}/quick-menu`, { state: { from: location }, replace: true })
            }

            if (menuType == 'create' && menu_permission._create == '0') navigate(`${ReverseProxy['proxyUrl']}/builder/projects`, { state: { from: location }, replace: true })
            if (menuType == 'edit' && menu_permission._edit == '0') navigate(`${ReverseProxy['proxyUrl']}/builder/projects`, { state: { from: location }, replace: true })

            setHas_view_access(menu_permission._view == '1')
            setHas_add_access(menu_permission._create == '1')
            setHas_edit_access(menu_permission._edit == '1')
            setHas_delete_access(menu_permission._delete == '1')

            setSelected_view_settings_value(menu_permission.selected_view_settings_value)

        }

    }, [store])

    const checkListsHandler = new CheckListsHandler()


    const [id, setId] = useState(Utils.getUniqueId())
    const [name, setName] = useState('')
    const [Description, setDescription] = useState('')
    const [ScheduleType_Id, setScheduleTypeId] = useState('')
    const [ScheduleType_Name, setScheduleType_Name] = useState('')
    const [ScheduleTime, setScheduleTime] = useState('')

    const [ScheduleOptions, setScheduleOptions] = useState([
        {
            value: '1',
            label: 'Daily Basics',
        },
        {
            value: '2',
            label: 'Weekly Basics',
        },
        {
            value: '3',
            label: 'Monthly Basics',
        },
        {
            value: '4',
            label: 'Incident Based',
        },
    ])

    const [AssigneeOptions_Orginal, setAssigneeOptions_Orginal] = useState([])
    const [AssigneeOptions, setAssigneeOptions] = useState([])
    const [SelectedAssignees_Res, setSelectedAssignees_Res] = useState([])
    const [SelectedAssignees, setSelectedAssignees] = useState([])


    const [CheckListQuestions, setCheckListQuestions] = useState([
        {
            id: Utils.getUniqueId(),
            question: {
                type: 'text',
                value: '',
                placeholder: 'Enter your question here'
            },
            is_required: false,
            response: {
                type: 'yes_no',
                yes_no_setting: {
                    yes_placeholder: 'Yes',
                    no_placeholder: 'No',
                    na_placeholder: 'N/A'
                },
                text_setting: {
                    placeholder: 'Enter your answer'
                },
                checkbox_setting: {
                    options: [
                        {
                            id: Utils.getUniqueId(),
                            placeholder: 'Option 1'
                        }
                    ]
                },
                multi_choice_setting: {
                    options: [
                        {
                            id: Utils.getUniqueId(),
                            placeholder: 'Option 1'
                        }
                    ]
                }

            },
        }
    ])

    const [QuestionResponseTypes, setQuestionResponseTypes] = useState([
        {
            id: 'yes_no',
            type: 'yes_no',
            label: 'Yes No N/A',
        },
        {
            id: '2',
            type: 'checkbox',
            label: 'Checkbox',
        },
        {
            id: '3',
            type: 'text',
            label: 'Text Answer',
        },
        {
            id: '5',
            type: 'multi_choice',
            label: 'Multi Choice',
        }
    ])

    const HandleQuestionInputChange = (index, value, key, option_idx) => {

        let questions = [...CheckListQuestions]

        if (key == 'question') questions[index].question.value = value
        else if (key == 'yes_placeholder' || key == 'no_placeholder' || key == 'na_placeholder') questions[index].response.yes_no_setting[key] = value

        else if (key == 'response_type') questions[index].response.type = value
        else if (key == 'is_required') questions[index].is_required = value
        else if (key == 'text_setting_placeholder') questions[index].response.text_setting.placeholder = value
        else if (key == 'checkbox_option') questions[index].response.checkbox_setting.options[option_idx].placeholder = value
        else if (key == 'reset_checkbox_option_value') questions[index].response.checkbox_setting.options[option_idx].placeholder = value || 'Option ' + (option_idx + 1)
        else if (key == 'add_checkbox_option') {
            questions[index].response.checkbox_setting.options.push({
                id: Utils.getUniqueId(),
                placeholder: 'Option ' + (questions[index].response.checkbox_setting.options.length + 1)
            })
        }
        else if (key == 'delete_checkbox_option') {
            questions[index].response.checkbox_setting.options.splice(option_idx, 1)
        }
        else if (key == 'multi_choice_option') questions[index].response.multi_choice_setting.options[option_idx].placeholder = value
        else if (key == 'reset_multi_choice_option_value') questions[index].response.multi_choice_setting.options[option_idx].placeholder = value || 'Option ' + (option_idx + 1)

        else if (key == 'add_multi_choice_option') {
            questions[index].response.multi_choice_setting.options.push({
                id: Utils.getUniqueId(),
                placeholder: 'Option ' + (questions[index].response.multi_choice_setting.options.length + 1)
            })
        }
        else if (key == 'delete_multi_choice_option') {
            questions[index].response.multi_choice_setting.options.splice(option_idx, 1)
        }


        else if (key == 'duplicate') {

            questions.push({
                id: Utils.getUniqueId(),
                question: {
                    type: questions[index].question.type,
                    value: questions[index].question.value,
                    placeholder: questions[index].question.placeholder
                },
                is_required: questions[index].is_required,
                response: {
                    type: questions[index].response.type,
                    yes_no_setting: {
                        yes_placeholder: questions[index].response.yes_no_setting.yes_placeholder,
                        no_placeholder: questions[index].response.yes_no_setting.no_placeholder,
                        na_placeholder: questions[index].response.yes_no_setting.na_placeholder,
                    },
                    text_setting: {
                        placeholder: questions[index].response.text_setting.placeholder
                    }
                }
            })
        }
        else if (key == 'delete') questions.splice(index, 1)


        setCheckListQuestions(questions)

    }

    const HandleAddNewQuestion = () => {
        let questions = [...CheckListQuestions]

        questions.push(
            {
                id: Utils.getUniqueId(),
                question: {
                    type: 'text',
                    value: '',
                    placeholder: 'Enter your question here'
                },
                is_required: false,
                response: {
                    type: 'yes_no',
                    yes_no_setting: {
                        yes_placeholder: 'Yes',
                        no_placeholder: 'No',
                        na_placeholder: 'N/A'
                    },
                    text_setting: {
                        placeholder: 'Enter your answer'
                    },
                    checkbox_setting: {
                        options: [
                            {
                                id: '1',
                                placeholder: 'Option 1'
                            }
                        ]
                    },
                    multi_choice_setting: {
                        options: [
                            {
                                id: '1',
                                placeholder: 'Option 1'
                            }
                        ]
                    }

                },
            }
        )

        setCheckListQuestions(questions)
    }

    const RenderResponseType = (params) => {

        const { q, i } = params

        const RenderYesNO = () => {
            return (
                <div className="answer-yes_no-main">
                    <div className="answer-yes_no-item answer-yes_no-yes_btn">
                        <input
                            className='form-item-answer-input'
                            type='text'
                            placeholder={q.response.yes_no_setting.yes_placeholder}
                            value={q.response.yes_no_setting.yes_placeholder}
                            onChange={(e) => HandleQuestionInputChange(i, e.target.value, 'yes_placeholder')}
                        />
                    </div>
                    <div className="answer-yes_no-item answer-yes_no-no_btn">
                        <input
                            className='form-item-answer-input'
                            type='text'
                            placeholder={q.response.yes_no_setting.no_placeholder}
                            value={q.response.yes_no_setting.no_placeholder}
                            onChange={(e) => HandleQuestionInputChange(i, e.target.value, 'no_placeholder')}
                        />
                    </div>
                    <div className="answer-yes_no-item">
                        <input
                            className='form-item-answer-input'
                            type='text'
                            placeholder={q.response.yes_no_setting.na_placeholder}
                            value={q.response.yes_no_setting.na_placeholder}
                            onChange={(e) => HandleQuestionInputChange(i, e.target.value, 'na_placeholder')}
                        />
                    </div>
                </div>
            )
        }

        const RenderText = () => {
            return (
                <div className="answer-text-main">
                    <textarea
                        className='answer-text-input'
                        placeholder={q.response.text_setting.placeholder}
                        onChange={(e) => HandleQuestionInputChange(i, e.target.value, 'text_setting_placeholder')}
                    >{q.response.text_setting.placeholder}</textarea>

                </div>
            )
        }

        const RenderCheckBox = () => {
            return (
                <div className="answer-checkbox-main">
                    <div className="answer-checkbox-items">
                        {q.response.checkbox_setting.options.map((option, op_idx) => (
                            <div
                                className="answer-checkbox-item"
                                key={`answer-checkbox-item-${option.id}`}
                            >
                                {q.response.checkbox_setting.options.length > 1 ?
                                    <div className="checkbox-item-delete"
                                        dangerouslySetInnerHTML={{ __html: Icons.general.minus_reduce }}
                                        onClick={() => HandleQuestionInputChange(i, true, 'delete_checkbox_option', op_idx)}
                                    ></div>
                                    : ''}
                                <div className="checkbox-input"></div>
                                <input
                                    className='checkbox-label-input'
                                    type='text'
                                    placeholder={option.placeholder}
                                    value={option.placeholder}
                                    onChange={(e) => HandleQuestionInputChange(i, e.target.value, 'checkbox_option', op_idx)}
                                    onBlur={(e) => HandleQuestionInputChange(i, e.target.value, 'reset_checkbox_option_value', op_idx)}
                                />
                            </div>
                        ))}

                        <div
                            className="answer-checkbox-add_item"
                            onClick={() => HandleQuestionInputChange(i, true, 'add_checkbox_option')}
                        >
                            <div className="icon"
                                dangerouslySetInnerHTML={{ __html: Icons.general.add_btn }}
                            ></div>
                            <div className="label">Add Option</div>
                        </div>
                    </div>
                </div>
            )
        }
        const RenderMultiChoice = () => {
            return (
                <div className="answer-multi_choice-main">
                    <div className="answer-multi_choice-items">
                        {q.response.multi_choice_setting.options.map((option, op_idx) => (
                            <div
                                className="answer-multi_choice-item"
                                key={`answer-multi_choice-item-${option.id}`}
                            >
                                {q.response.multi_choice_setting.options.length > 1 ?
                                    <div className="multi_choice-item-delete"
                                        dangerouslySetInnerHTML={{ __html: Icons.general.minus_reduce }}
                                        onClick={() => HandleQuestionInputChange(i, true, 'delete_multi_choice_option', op_idx)}
                                    ></div>
                                    : ''}
                                <div className="multi_choice-input"></div>
                                <input
                                    className='multi_choice-label-input'
                                    type='text'
                                    placeholder={option.placeholder}
                                    value={option.placeholder}
                                    onChange={(e) => HandleQuestionInputChange(i, e.target.value, 'multi_choice_option', op_idx)}
                                    onBlur={(e) => HandleQuestionInputChange(i, e.target.value, 'reset_multi_choice_option_value', op_idx)}
                                />
                            </div>
                        ))}

                        <div
                            className="answer-multi_choice-add_item"
                            onClick={() => HandleQuestionInputChange(i, true, 'add_multi_choice_option')}
                        >
                            <div className="icon"
                                dangerouslySetInnerHTML={{ __html: Icons.general.add_btn }}
                            ></div>
                            <div className="label">Add Option</div>
                        </div>
                    </div>
                </div>
            )
        }

        return (
            <>
                {
                    q.response.type == 'yes_no' ? RenderYesNO()
                        : q.response.type == 'text' ? RenderText()
                            : q.response.type == 'multi_choice' ? RenderMultiChoice()
                                : q.response.type == 'checkbox' ? RenderCheckBox()
                                    : ''}
            </>
        )

    }

    const HandleSelectAssignee = (users) => {

        users = users.map(p => {
            let users = AssigneeOptions_Orginal.filter(ph => ph.id == p.id)[0]
            if (!users) return p

            return users
        })

        setSelectedAssignees(users)
    }
    const HandlepreviewClientInvoice = (e) => {

    }

    const HandleCancel = (e) => {

        navigate(`/builder/projects/${projectId}/checklist-templates`)
    }

    const HandleSubmit = async (e) => {
        e.preventDefault()

        let assignees = SelectedAssignees.map(p => p.value)

        let update_detials = {
            name: name,
            project_id: projectId,
            description: Description,
            schedule_type: String(ScheduleType_Id),
            schedule_time: ScheduleTime,
            assignees: assignees,
            questions: CheckListQuestions,
        }

        let response = {
            success: false
        }

        if (menuType == 'edit') {
            update_detials.id = listId
            response = await checkListsHandler.updateChecklistTemplateHandler(update_detials)
        }
        else response = await checkListsHandler.createChecklistTemplateHandler(update_detials)

        if (response.success) {

            setWarningAlert(true)
            setWarningAlertType('success')
            setApiFailedMessage('Checklist Template Updated Successfully')

            HandleCancel()

        }
        else {
            setWarningAlert(true)
            setWarningAlertType('error')
            setApiFailedMessage('Error in updating Checklist Template')
        }

    }

    const LoadChecklistTemplate = async () => {

        let filter = {
            id: listId,
            project_id: projectId,
        }

        setIsLoading(true)

        let response = await checkListsHandler.getChecklistTemplatesHandler(filter)
        setIsLoading(false)

        if (response.success) {
            let { data } = response

            if (!Array.isArray(data) || !data.length) {
                return HandleCancel()
            }


            let {
                sno,
                name,
                schedule_type,
                schedule_time,
                description,
                assignees,
                questions

            } = data[0]

            setId(sno)
            setName(name)
            setScheduleTypeId(schedule_type)
            setScheduleTime(schedule_time)
            setDescription(description)
            setSelectedAssignees_Res(assignees)
            setCheckListQuestions(questions)
        }
    }

    const HandleInputChange = (value, key, label) => {

        if (key == 'name') setName(value)
        else if (key == 'description') setDescription(value)
        else if (key == 'schedule_type') {
            setScheduleTypeId(value)
            setScheduleType_Name(label)
        }
        else if (key == 'schedule_time') setScheduleTime(value)
    }


    useEffect(() => {

        if (AssigneeOptions.length && Array.isArray(SelectedAssignees_Res) && SelectedAssignees_Res.length) {

            let select_assignee = SelectedAssignees_Res.map(p => {
                let assignee = AssigneeOptions.filter(ph => ph.value == p)[0]

                if (!assignee) return p
                return assignee
            })

            setSelectedAssignees(select_assignee)

        }

    }, [AssigneeOptions, SelectedAssignees_Res])


    useEffect(() => {

        if (menuType != 'create') LoadChecklistTemplate()

        LoadStoreData()
    }, [])

    useEffect(() => {

        if (store && store.user) {

            let { team_users } = store.user


            if (Array.isArray(team_users)) {

                let _team_users = team_users.filter(opt => opt.status == '1').map((opt) => {
                    return {
                        value: opt.id,
                        label: opt.name,
                    }
                })

                setAssigneeOptions(_team_users)
                setAssigneeOptions_Orginal(team_users)
            }


        }

    }, [store])


    return (
        <>


            {isLoading ?
                <Loading
                    props={{
                        isMainLogo: false,
                        isLabel: true
                    }} />
                : null}
            {warningAlert ?

                <SystemToastPopup
                    props={{
                        type: warningAlertType,
                        message: apiFailedMessage || "Error in Bills",
                        callback: (confirmation) => setWarningAlert(false)
                    }} />

                : null}


            <div className="project-create_client_invoice-main" id='project-create_client_invoice-main'>
                <div className="create_client_invoice-panel-content">

                    <div className="panel-content-header">
                        <div className="left">
                            <div className="icon"
                                dangerouslySetInnerHTML={{ __html: Icons.general.back_arrow }}
                                onClick={(e) => HandleCancel(e)}
                            ></div>
                            <div className="title">{menuType == 'create' ? 'Create' : 'Edit'} Checklist Template</div>
                        </div>
                        <div className="right">


                        </div>
                    </div>
                    <form className="panel-content-sections" onSubmit={HandleSubmit}>
                        <div className="create-client_invoicecontent-main">

                            <div className="create-client_invoicesections">

                                <div className="create-client_invoicesection">
                                    <div className="section-input-items">
                                        <div className="section-input-item">
                                            <TextInput
                                                props={{
                                                    id: "create-checklist-name",
                                                    value: name,
                                                    placeholder: '',
                                                    setValue: (value) => HandleInputChange(value, 'name'),
                                                    isIcon: false,
                                                    isLabel: true,
                                                    isRequired: true,
                                                    label: "Name",
                                                }}
                                            />
                                        </div>

                                        <div className="section-input-item">
                                            <SelectInput
                                                props={{
                                                    id: "create-checklist-schedule_type",
                                                    value: ScheduleType_Id,
                                                    placeholder: 'Select Type',
                                                    options: ScheduleOptions || [],
                                                    setValue: (value, label) => HandleInputChange(value, 'schedule_type', label),
                                                    isIcon: false,
                                                    isLabel: true,
                                                    isRequired: true,
                                                    label: "Schedule Type",
                                                }}
                                            />
                                        </div>
                                        <div className="section-input-item">
                                            <TimeInput
                                                props={{
                                                    id: "create-checklist-schedule_time",
                                                    value: ScheduleTime,
                                                    placeholder: '',
                                                    setValue: (value) => HandleInputChange(value, 'schedule_time'),
                                                    isIcon: false,
                                                    isLabel: true,
                                                    icon: Icons.general.schedule,
                                                    isRequired: false,
                                                    label: "Schedule Time",
                                                }}
                                            />
                                        </div>
                                    </div>
                                    <div className="section-input-items">
                                        <div className="section-input-item" style={{ height: 'max-content' }}>
                                            <MultiSelectInput
                                                props={{
                                                    id: 'assignee-multi-select-input',
                                                    placeholder: 'Select Assignees',
                                                    isRequired: true,
                                                    value: SelectedAssignees,
                                                    options: AssigneeOptions,
                                                    isIcon: false,
                                                    avoidDuplicate: true,
                                                    icon: '',
                                                    isLabel: true,
                                                    label: 'Assignees',
                                                    setValue: (value) => HandleSelectAssignee(value),
                                                }}
                                            />
                                        </div>
                                        <div className="section-input-item">

                                            <TextArea
                                                props={{
                                                    id: "create-checklist-description",
                                                    value: Description,
                                                    placeholder: 'Description',
                                                    setValue: (value) => HandleInputChange(value, 'description'),
                                                    isIcon: false,
                                                    isLabel: true,
                                                    isRequired: false,
                                                    label: "Description",
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>

                                <div className="create-client_invoicesection">

                                    <div className="checklists-form-main">
                                        <div className="checklists-form-header">
                                            <div className="title">Checklist Questions</div>
                                            <div className="total-questions">
                                                {CheckListQuestions.length} Questions
                                            </div>
                                        </div>
                                        <div className="checklists-form-container">
                                            {CheckListQuestions.length ?
                                                CheckListQuestions?.map((q, i) => (
                                                    <div
                                                        className="checklists-form-item"
                                                        key={`checklists-form-item-${i}`}
                                                    >
                                                        <div className="form-item-question">
                                                            <input
                                                                className='form-item-question-input'
                                                                type={q.question.type}
                                                                placeholder={q.question.placeholder}
                                                                value={q.question.value}
                                                                onChange={(e) => HandleQuestionInputChange(i, e.target.value, 'question')}
                                                                required={true}
                                                            />
                                                            {q.is_required ?
                                                                <div className="form-item-question-mandatory-icon"
                                                                    dangerouslySetInnerHTML={{ __html: Icons.general.mandatory }}
                                                                ></div>
                                                                : ''}
                                                        </div>
                                                        <div className="form-item-answer">
                                                            {RenderResponseType({ q, i })}
                                                        </div>
                                                        <div className="form-item-actions">
                                                            <div className="form-item-action-left">
                                                                <div className="form-item-action-answer_type">
                                                                    <select
                                                                        className='action-answer_type-input'
                                                                        value={q.response.type}
                                                                        onChange={(e) => HandleQuestionInputChange(i, e.target.value, 'response_type')}
                                                                    >
                                                                        {QuestionResponseTypes?.map(q => <option value={q.type}>{q.label}</option>)}
                                                                    </select>
                                                                </div>
                                                            </div>
                                                            <div className="form-item-action-right">
                                                                <div
                                                                    className="form-item-action-item"
                                                                    onClick={(e) => HandleQuestionInputChange(i, true, 'duplicate')}
                                                                >
                                                                    <div className="icon"
                                                                        dangerouslySetInnerHTML={{ __html: Icons.general.duplicate }}
                                                                    ></div>
                                                                    <div className="label">Duplicate</div>
                                                                </div>
                                                                <div
                                                                    className={`form-item-action-item ${q.is_required ? 'form-item-action-item-active' : ''}`}
                                                                    onClick={(e) => HandleQuestionInputChange(i, !q.is_required, 'is_required')}
                                                                >
                                                                    <div className="icon"
                                                                        dangerouslySetInnerHTML={{ __html: Icons.general.mandatory }}
                                                                    ></div>
                                                                    <div className="label">Mandatory</div>
                                                                </div>
                                                                <div
                                                                    className="form-item-action-item"
                                                                    onClick={(e) => HandleQuestionInputChange(i, true, 'delete')}
                                                                >
                                                                    <div className="icon"
                                                                        dangerouslySetInnerHTML={{ __html: Icons.general.delete }}
                                                                    ></div>
                                                                    <div className="label">Delete</div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                ))
                                                : <NoDataFound label={'No Question Added Yet'} />
                                            }
                                            <div
                                                className="checklists-form-add_question-btn"
                                                onClick={() => HandleAddNewQuestion()}
                                            >
                                                <div className="label">Add Question</div>
                                            </div>
                                        </div>

                                    </div>

                                </div>

                            </div>
                            <div className="create-client_invoicefooter">
                                <div className="footer-item action-items">

                                    <div className="action-cancel" onClick={(e) => HandleCancel(e)}>
                                        Cancel
                                    </div>
                                    <button
                                        className={`action-btn`}
                                        type='submit'
                                        ref={formSubmitBtnRef}
                                    >

                                        <div className="label">Save</div>
                                    </button>
                                </div>
                            </div>

                        </div>
                    </form>
                </div>

            </div>
        </>
    )

}

export default CreateCheckListTemplate;