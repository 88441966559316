import React, { useEffect, useState } from 'react'

/* Redux Setup*/
import { Link, useNavigate, Route, Routes, BrowserRouter, Switch } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import StoreHandler from './redux/StoreHandler'
import UserAction from './redux/action/userAction'

import { SocketProvider } from './Services/Socket/SocketHook';

import './assets/css/index.css'

import ReverseProxy from './config/reverseProxy'

import Preloader from './components/Preloader'
import OnBoarding from './Pages/OnBoarding/OnBoarding'

import Utils from './utils'

import Signup from './Pages/login/signup'
import VerifyRegisteration from './Pages/login/verifyregisteration'
import Login from './Pages/login/login'
import Forgetpassword from './Pages/login/forgetpassword'
import ResetPassword from './Pages/login/resetpassword'
import VerifyResetPassword from './Pages/login/verifyresetpassword'

import AuthTokenValidation from './Handlers/Authendication/AuthTokenValidation'

import QuickMenus from './Pages/quickMenus'
import Builder from './Pages/builder'
import Project from './Pages/projects/project'
import PlanExiryBanner from './components/PlanExiryBanner'

import PWAInstallPopup from './components/PWAInstallPopup'
import SubscriptionExpiredPopup from './components/SubscriptionExpiredPopup'
import PendingSubscriptionRequestPopup from './components/PendingSubscriptionRequestPopup'
// import Razorpay_Paymentgateway from './Handlers/PaymentGateways/Razorpay_Paymentgateway'

const App = () => {

  const store = useSelector((store) => store)
  const dispatch = useDispatch()
  const { updateState } = new UserAction

  const navigate = useNavigate()
  const [preloaderStatus, setPreloaderStatus] = useState(true)
  const [isPlanExpired, setIsPlanExpired] = useState(store.user.plan_status == '2' && !window.location.pathname.includes('subscription-plans'))
  const [ShowPendingSubscriptionPlan, setShowPendingSubscriptionPlan] = useState(false)
  const [PendingSubscriptionPlan, setPendingSubscriptionPlan] = useState(undefined)

  const [PlanExpiryDays, setPlanExpiryDays] = useState(store.user.plan_expiry_duration_days || 0)

  // const razorpay_Paymentgateway = new Razorpay_Paymentgateway()

  const storeHandler = new StoreHandler({
    dataStack: ['builder'],
    store: store,
    dispatch: dispatch,
    updateState: updateState,
  })

  const LoadStoreData = async () => {
    let resolved = await storeHandler.init()
  }

  useEffect(() => {

    const builder_detials = store.user.builder
    const currentURL = window.location.pathname;
    let currentPath = String(currentURL).split('/')
    currentPath = currentPath[currentPath.length - 1]

    if (builder_detials && Object.keys(builder_detials).length > 0) {

      if (isPlanExpired) navigate(`${ReverseProxy['proxyUrl']}/quick-menu`)

      if (builder_detials.onboarding_status == 0) navigate(`${ReverseProxy['proxyUrl']}/onboarding`)
      else if (currentPath == 'app') navigate(`${ReverseProxy['proxyUrl']}/quick-menu`)
    }

    setIsPlanExpired(store.user.plan_status == '2' && !window.location.pathname.includes('subscription-plans'))
    setPlanExpiryDays(store.user.plan_expiry_duration_days || 0)

  }, [store])

  const PlannExpiryCallback = (confirmation) => {
    setIsPlanExpired(false)
    if (confirmation) {
      navigate(`${ReverseProxy['proxyUrl']}/builder/subscription-plans`)
    }
  }


  // const LoadPendingSubscriptionsRequest = async () => {
  //   const response = await razorpay_Paymentgateway.getPendingSubscriptionsRequest()

  //   if (response.success) {

  //     if (
  //       response.data
  //       &&
  //       Object.keys(response.data).length > 0
  //       && response.data.status == '1'
  //       && response.data.order_status == 'ACTIVE'
  //     ) {
  //       setShowPendingSubscriptionPlan(true)
  //       setPendingSubscriptionPlan(response.data)
  //     }

  //   }
  // }



  useEffect(() => {
    LoadStoreData()
    // LoadPendingSubscriptionsRequest()
  }, [])

  const PendingPlanCallback = (confirmation) => {
    setShowPendingSubscriptionPlan(false)
    setPendingSubscriptionPlan(undefined)
  }

  useEffect(() => {

    let { logo_img, client_portal_settings } = store.user.company

    if (client_portal_settings && Object.keys(client_portal_settings).length) {

      let { visible_name, color_theme } = client_portal_settings

      document.title = `${visible_name} | Civilator`

      let rgb_color_theme = Utils.getLightBgFromColor(color_theme, 1)
      let light_color_theme = Utils.getLightBgFromColor(color_theme, 0.1)

      let default_color = document.querySelector(':root').style.getPropertyValue('--primary-color')
      let default_light_color = document.querySelector(':root').style.getPropertyValue('--primary-trans-color')

      document.querySelector(':root').style.setProperty('--primary-color', rgb_color_theme || default_color)
      document.querySelector(':root').style.setProperty('--primary-trans-color', light_color_theme || default_light_color)

      let Old_Icon = document.querySelector('link[rel="icon"]');
      if (Old_Icon) document.head.removeChild(Old_Icon);

      let Logo_img = `data:image/png;base64,${logo_img}`

      let Icon_Link = document.createElement('link');
      Icon_Link.rel = 'icon';
      Icon_Link.href = Logo_img


    }

  }, [store.user.company])

  return (

    <div className='project-container-main'>

      {preloaderStatus ? <Preloader props={{ delay: 0, setPreloaderStatus }} /> : null}

      <Routes >

        <Route exact path='/signin' element={<Login />}></Route>
        <Route exact path='/signup' element={<Signup />}></Route>
        <Route exact path='/verify-registeration' element={<VerifyRegisteration />}></Route>
        <Route exact path='/forget-password' element={<Forgetpassword />}></Route>
        <Route exact path='/verify-reset-password' element={<VerifyResetPassword />}></Route>
        <Route exact path='/reset-password' element={<ResetPassword />}></Route>
        <Route exact path='/onboarding/*' element={<OnBoarding />}></Route>

      </Routes>

      <AuthTokenValidation />

      {store.user.isAuthenticated ?
        <div className='project-main-container'>

          {PlanExpiryDays <= 10 ?
            <PlanExiryBanner
              props={{
                expiry_days: PlanExpiryDays
              }}
            />
            : ''}

          {isPlanExpired ?
            <SubscriptionExpiredPopup
              props={{
                callback: PlannExpiryCallback
              }}
            /> : ''}
          {ShowPendingSubscriptionPlan ?
            <PendingSubscriptionRequestPopup
              props={{
                callback: PendingPlanCallback,
                request_data: PendingSubscriptionPlan
              }}
            /> : ''}
          <PWAInstallPopup />

          <SocketProvider>
            <Routes >
              <Route exact path='/quick-menu/*' element={<QuickMenus />}></Route>
              <Route exact path='/builder/*' element={<Builder />}></Route>
              <Route path='/builder/projects/:projectId/*' element={<Project />}></Route>
            </Routes>
          </SocketProvider>
        </div>
        : ''}

    </div>
  )
}

export default App;
