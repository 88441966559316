
import proxyConfig from '../../config/reverseProxy'
import HeaderConfig from '../../config/header'

class Chats {

    constructor() {

    }


    async getChatListsHandler(params) {

        try {

            let response = await fetch(proxyConfig['serverBaseUrl'] + '/builder/chats/get-lists', {
                method: 'POST',
                headers: HeaderConfig.getHeader({ method: 'POST' }),
                body: JSON.stringify(params)
            })

            response = await response.json()

            if (response) return response
        } catch {
            return { success: false }
        }


    }
    async getChatMessagesHandler(params) {

        try {

            let response = await fetch(proxyConfig['serverBaseUrl'] + '/builder/chats/get-messages', {
                method: 'POST',
                headers: HeaderConfig.getHeader({ method: 'POST' }),
                body: JSON.stringify(params)
            })

            response = await response.json()

            if (response) return response
        } catch {
            return { success: false }
        }


    }


}

export default Chats;