

import React, { useEffect, useState, useRef } from 'react'
import { Route, Routes, BrowserRouter, Link, useLocation, Navigate, Outlet, useNavigate, useParams } from 'react-router-dom';
import store from '../../redux/store'
import StoreHandler from '../../redux/StoreHandler'
import { useSelector, useDispatch } from 'react-redux'
import UserAction from '../../redux/action/userAction'

import ReverseProxy from '../../config/reverseProxy'
import TaskManagementHandler from '../../Handlers/TaskManagement/TaskManagement';
import IssuesManagementHandler from '../../Handlers/IssuesManagement/IssuesManagement';
import MaterialIssueHandler from '../../Handlers/MaterialIssue/MaterialIssue';
import CommentsHandler from '../../Handlers/Comments/Comments';
import BuilderAttachments from '../../Handlers/BuilderAttachments/BuilderAttachments';

import Icons from '../../assets/Icons'
import Images from '../../assets/Images'
import { CostInput, TextInput, SelectInput, DateTimeInput, RadioInput, MultiSelectInput } from '../../components/Inputs'
import Utils from '../../utils'
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import AlertPopup from '../../components/AlertPopup'
import Loading from '../../components/Loading'
import SystemToastPopup from '../../components/SystemToastPopup'

import AddIssuePopup from '../issuesManagement/AddIssuePopup'

import ImagePreviewPopup from '../../components/ImagePreviewPopup'

import { useAuth, useMenuAuth } from '../../hooks/useAuth'

import ShowToClient from '../../components/ShowToClient'

const CreateParentTask = ({ props }) => {

    const { existItemData, updateCallback } = props


    const { projectId, itemType, itemId, actionType } = useParams()

    const taskManagementHandler = new TaskManagementHandler()

    const [name, setName] = useState('')
    const [ParentTask_id, setParentTask_id] = useState('')
    const [ParentTask_name, setParentTask_name] = useState('')
    const [description, setDescription] = useState('')

    const [isLoading, setIsLoading] = useState(false)
    const [warningAlert, setWarningAlert] = useState(false)
    const [warningAlertType, setWarningAlertType] = useState('warning')
    const [apiFailedMessage, setApiFailedMessage] = useState("Error in Server")

    const [currency_type, setCurrency_type] = useState('₹')

    const navigate = useNavigate()
    const store = useSelector((store) => store)
    const dispatch = useDispatch()
    const { updateState } = new UserAction


    const storeHandler = new StoreHandler({
        dataStack: [`project-${projectId}`],
        store: store,
        dispatch: dispatch,
        updateState: updateState,
    })

    const LoadStoreData = async () => {
        setIsLoading(true)
        let resolved = await storeHandler.init()
        setIsLoading(false)
    }

    const isExistItemData = existItemData && existItemData.id != undefined
    const [additionalNotes, setAdditionalNotes] = useState(false);


    const [ParentTaskOptions, setParentTaskOptions] = useState([])

    const LoadParentTasks = async () => {

        let filter = {
            type: 1,
            projectId: projectId
        }

        let response = await taskManagementHandler.getTasksHandler(filter)

        if (response.success) {

            let { data } = response

            if (!Array.isArray(data)) return

            let getSubItemsIds = (items, parentid) => {

                let subGroupIds = []

                let getItems = (items, parentid) => {

                    if (!Array.isArray(items)) return

                    let _items = items.filter(itm => itm.parent_id == parentid)

                    subGroupIds.push(..._items)

                    _items.forEach(itm => getItems(items, itm.id))
                }

                getItems(items, parentid)

                subGroupIds = subGroupIds.map(g => g.id)

                subGroupIds.push(parentid)

                return subGroupIds
            }

            if (isExistItemData) {

                data = data.filter(d => !getSubItemsIds(data, existItemData.id).includes(d.id))
            }

            data = data.filter(d => d.type == '1').map(d => {

                return {
                    value: d.id,
                    label: d.name,
                }

            })

            setParentTaskOptions(data)
        }
    }

    const HandlePopupSubmit = async (e) => {
        e.preventDefault()

        let updated_data = {
            projectId: projectId,
            name,
            parent_id: ParentTask_id || undefined,
            parent_name: ParentTask_name || undefined,
            description,

        }
        setIsLoading(true)

        let response = { success: false }

        if (actionType == 'edit' && isExistItemData && existItemData.id) {
            updated_data['id'] = existItemData.id
            response = await taskManagementHandler.updateParentTaskHandler(updated_data)
        }
        else if (actionType == 'add') response = await taskManagementHandler.createParentTaskHandler(updated_data)

        setIsLoading(false)

        if (response.success) {

            if (typeof updateCallback == 'function') updateCallback()
            navigate(`/builder/projects/${projectId}/task-management`)
        }
        else {
            setWarningAlert(true)
            setWarningAlertType('error')
            setApiFailedMessage(`Error in updating parent task`)
        }


    }

    const Popup_Header = ({ props }) => {
        return (
            <div className="side-popup-header">
                <div className="header-item-select">
                    <div className="header-item-select-content">
                        <span className="icon" dangerouslySetInnerHTML={{ __html: Icons.general.group }}></span>
                        <div className="label">{actionType == 'edit' ? 'Update' : 'Add'} Parent Task</div>

                    </div>
                </div>
                <div
                    className="header-item-close"
                    onClick={(e) => {
                        navigate(`/builder/projects/${projectId}/task-management`)
                    }}
                    dangerouslySetInnerHTML={{ __html: Icons.general.close }}
                ></div>
            </div>
        );
    };
    const Popup_Footer = ({ props }) => {

        return (
            <div className="sidebar-popup-footer">
                <div className="footer-item action-items">
                    <div className="action-preview">
                    </div>
                    <button
                        className="action-btn"
                        type='submit'
                    >
                        <div className="icon" dangerouslySetInnerHTML={{ __html: actionType == 'edit' ? Icons.general.save : Icons.general.add_btn }}></div>
                        <div className="label">{actionType == 'edit' ? 'Save' : 'Add Parent Task'}</div>
                    </button>
                </div>
            </div>
        );
    };


    const LoadTaskItem = async (idx) => {

        if (idx == undefined) return

        let filter = {
            projectId: projectId,
            idx: idx
        }

        setIsLoading(true)

        let response = await taskManagementHandler.getTasksHandler(filter)
        setIsLoading(false)

        if (response.success) {


            let { data } = response

            if (!Array.isArray(data) || !data.length) return

            let item = data[0]

            setName(item.name || '')
            setAdditionalNotes(item.description ? true : false)
            setDescription(item.description || '')
            setParentTask_id(item.parent_id || '')
            setParentTask_name(item.parent_name || '')

        }
        else {
            setWarningAlert(true)
            setWarningAlertType('error')
            setApiFailedMessage(`Error in loading parent task`)
        }
    }


    useEffect(() => {

        LoadStoreData()
        LoadParentTasks()

        if (actionType == 'edit' && isExistItemData) {

            setName(existItemData.name || '')
            setAdditionalNotes(existItemData.description ? true : false)
            setDescription(existItemData.description || '')
            setParentTask_id(existItemData.parent_id || '')
            setParentTask_name(existItemData.parent_name || '')
        }

        if (actionType == 'edit' && !isExistItemData) LoadTaskItem(itemId)

    }, [])

    useEffect(() => {

        if (store && store.user) {
        }


    }, [store])

    return (
        <div className="popup-container-main popup-container-center">
            <div className="popup-block-ui"></div>
            <form className="side-popup-container" onSubmit={HandlePopupSubmit}>

                <Popup_Header />

                <div className="sidebar-popup-content">
                    <div className="content-section">


                        <div className="content-item">
                            <TextInput
                                props={{
                                    id: "estimation-group-name",
                                    value: name,
                                    placeholder: '',
                                    setValue: (value) => setName(value),
                                    isIcon: false,
                                    isLabel: true,
                                    isRequired: true,
                                    label: "Name",
                                }}
                            />
                        </div>
                        <div className="content-item">
                            <SelectInput
                                props={{
                                    id: "estimation-group-group",
                                    value: ParentTask_id,
                                    placeholder: '',
                                    options: [
                                        {
                                            value: "",
                                            label: "Select Parent"
                                        }, ...ParentTaskOptions || []
                                    ] || [],
                                    setValue: (value, label) => {
                                        setParentTask_id(value)
                                        setParentTask_name(label)
                                    },
                                    isIcon: false,
                                    isLabel: true,
                                    isRequired: false,
                                    label: "Parent Task",
                                }}
                            />

                        </div>
                        <div className="content-section">
                            <div className="content-item">
                                <div id="estimation-group-description" className={`additional-item ${additionalNotes ? 'additional-item-active' : ''}`}>
                                    <div className="head" onClick={(e) => setAdditionalNotes(!additionalNotes)}>
                                        <div className="icon" dangerouslySetInnerHTML={{ __html: Icons.general.description }}></div>
                                        <div className="label">Description</div>
                                    </div>
                                    <div className="item-expanded">
                                        <ReactQuill
                                            theme="snow"
                                            value={description}
                                            onChange={(value) => setDescription(value)}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>

                <Popup_Footer />
            </form>
        </div>
    )
}
const CreateTask = ({ props }) => {

    const { existItemData, updateCallback } = props


    const { projectId, itemType, itemId, actionType } = useParams()

    const taskManagementHandler = new TaskManagementHandler()

    const [ParentTask_id, setParentTask_id] = useState('')
    const [ParentTask_name, setParentTask_name] = useState('')

    const [name, setName] = useState('')
    const [WorkType_id, setWorkType_id] = useState('')
    const [WorkType_name, setWorkType_name] = useState('')
    const [Priority_id, setPriority_id] = useState('')
    const [Priority_name, setPriority_name] = useState('')
    const [StartDate, setStartDate] = useState('')
    const [EndDate, setEndDate] = useState('')
    const [Assignee_id, setAssignee_id] = useState('')
    const [Assignee_name, setAssignee_name] = useState('')
    const [description, setDescription] = useState('')

    const [isLoading, setIsLoading] = useState(false)
    const [warningAlert, setWarningAlert] = useState(false)
    const [warningAlertType, setWarningAlertType] = useState('warning')
    const [apiFailedMessage, setApiFailedMessage] = useState("Error in Server")


    const [currency_type, setCurrency_type] = useState('₹')

    const navigate = useNavigate()
    const store = useSelector((store) => store)
    const dispatch = useDispatch()
    const { updateState } = new UserAction


    const storeHandler = new StoreHandler({
        dataStack: ['company', 'costcodes', 'team_users', `project_phases-${projectId}`],
        store: store,
        dispatch: dispatch,
        updateState: updateState,
    })


    const LoadStoreData = async () => {
        setIsLoading(true)
        let resolved = await storeHandler.init()
        setIsLoading(false)
    }

    const isExistItemData = existItemData && existItemData.id != undefined
    const [additionalNotes, setAdditionalNotes] = useState(false);


    const [WorkTypeOptions, setWorkTypeOptions] = useState([])
    const [WorkTypeOptions_Orginal, setWorkTypeOptions_Orginal] = useState([])
    const [PriorityOptions, setPriorityOptions] = useState([
        {
            value: '0',
            label: 'Low'
        },
        {
            value: '1',
            label: 'Medium'
        },
        {
            value: '2',
            label: 'High'
        },
    ])

    const [AssigneeOptions_Orginal, setAssigneeOptions_Orginal] = useState([])
    const [AssigneeOptions, setAssigneeOptions] = useState([])
    const [SelectedAssignees_Res, setSelectedAssignees_Res] = useState([])
    const [SelectedAssignees, setSelectedAssignees] = useState([])

    const HandleSelectAssignee = (users) => {

        users = users.map(p => {
            let users = AssigneeOptions_Orginal.filter(ph => ph.id == p.id)[0]
            if (!users) return p

            return users
        })

        setSelectedAssignees(users)
    }


    const [ProjectPhases, setProjectPhases] = useState([])
    const [ProjectPhases_Options, setProjectPhases_Options] = useState([])

    const [ParentTaskOptions, setParentTaskOptions] = useState([])

    const LoadParentTasks = async () => {

        let filter = {
            type: 1,
            projectId: projectId
        }

        let response = await taskManagementHandler.getTasksHandler(filter)

        if (response.success) {

            let { data } = response

            if (!Array.isArray(data)) return

            let getSubItemsIds = (items, parentid) => {

                let subGroupIds = []

                let getItems = (items, parentid) => {

                    if (!Array.isArray(items)) return

                    let _items = items.filter(itm => itm.parent_id == parentid)

                    subGroupIds.push(..._items)

                    _items.forEach(itm => getItems(items, itm.id))
                }

                getItems(items, parentid)

                subGroupIds = subGroupIds.map(g => g.id)

                subGroupIds.push(parentid)

                return subGroupIds
            }

            if (isExistItemData) {

                data = data.filter(d => !getSubItemsIds(data, existItemData.id).includes(d.id))
            }

            data = data.filter(d => d.type == '1').map(d => {

                return {
                    value: d.id,
                    label: d.name,
                }

            })

            setParentTaskOptions(data)
        }
    }

    const [SelectedPhases, setSelectedPhases] = useState([])

    const HandleSelectPhase = (phase) => {

        phase = phase.map(p => {
            let phase = ProjectPhases.filter(ph => ph.id == p.id)[0]

            if (!phase) return p

            return phase
        })

        setSelectedPhases(phase)
    }


    const HandlePopupSubmit = async (e) => {
        e.preventDefault()

        let phases = SelectedPhases?.map(p => p.value)
        let assignees = SelectedAssignees?.map(p => p.value)

        let updated_data = {
            projectId: projectId,
            name,
            parent_id: ParentTask_id,
            parent_name: ParentTask_name,

            phases: phases || [],
            assignees: assignees || [],

            worktype_id: WorkType_id,
            worktype_name: WorkType_name,
            priority_id: Priority_id,
            priority_name: Priority_name,
            startdate: StartDate,
            enddate: EndDate,
            assignee_id: Assignee_id,
            assignee_name: Assignee_name,
            description,

        }

        console.log(updated_data, 'updated_data');
        setIsLoading(true)

        let response = await taskManagementHandler.createTaskHandler(updated_data)

        setIsLoading(false)

        if (response.success) {

            if (typeof updateCallback == 'function') updateCallback()
            navigate(`/builder/projects/${projectId}/task-management`)
        }
        else {
            setWarningAlert(true)
            setWarningAlertType('error')
            setApiFailedMessage(`Error in updating task`)
        }


    }

    const Popup_Header = ({ props }) => {
        return (
            <div className="side-popup-header">
                <div className="header-item-select">
                    <div className="header-item-select-content">
                        <span className="icon" dangerouslySetInnerHTML={{ __html: Icons.general.tasks }}></span>
                        <div className="label">Add Task</div>

                    </div>
                </div>
                <div
                    className="header-item-close"
                    onClick={(e) => {
                        navigate(`/builder/projects/${projectId}/task-management`)
                    }}
                    dangerouslySetInnerHTML={{ __html: Icons.general.close }}
                ></div>
            </div>
        );
    };
    const Popup_Footer = ({ props }) => {

        return (
            <div className="sidebar-popup-footer">
                <div className="footer-item action-items">
                    <div className="action-preview">
                    </div>
                    <button
                        className="action-btn"
                        type='submit'
                    >
                        <div className="icon" dangerouslySetInnerHTML={{ __html: Icons.general.add_btn }}></div>
                        <div className="label">Add</div>
                    </button>
                </div>
            </div>
        );
    };


    useEffect(() => {

        if (ProjectPhases_Options.length && existItemData && existItemData.phases) {
            let phases = existItemData.phases || []

            let select_phases = phases.map(p => {
                let phase = ProjectPhases_Options.filter(ph => ph.value == p)[0]

                if (!phase) return p
                return phase
            })

            setSelectedPhases(select_phases)

        }

    }, [ProjectPhases_Options])


    useEffect(() => {

        if (store && store.user) {

            let { team_users, costcodes, selected_project_phases } = store.user



            if (Array.isArray(team_users)) {

                let _team_users = team_users.filter(opt => opt.status == '1').map((opt) => {
                    return {
                        value: opt.id,
                        label: opt.name,
                    }
                })

                setAssigneeOptions(_team_users)
                setAssigneeOptions_Orginal(team_users)
            }
            if (Array.isArray(costcodes)) {

                let _costcodes = costcodes.filter(opt => opt.status == '1').map((opt) => {
                    return {
                        value: opt.id,
                        label: opt.name,
                    }
                })

                setWorkTypeOptions(_costcodes)
                setWorkTypeOptions_Orginal(costcodes)
            }

            if (selected_project_phases) {

                setProjectPhases(selected_project_phases)

                let project_phases = selected_project_phases.map(phase => {
                    return {
                        value: phase.id,
                        label: `${phase.name} | ${phase.client_name}`,
                        isDefault: false
                    }
                })

                setProjectPhases_Options(project_phases || [])

            }

        }

    }, [store])


    useEffect(() => {

        LoadStoreData()
        LoadParentTasks()
    }, [])


    return (
        <div className="popup-container-main popup-container-center">
            <div className="popup-block-ui"></div>
            <form className="side-popup-container" onSubmit={HandlePopupSubmit}>

                <Popup_Header />

                <div className="sidebar-popup-content">
                    <div className="content-section">


                        <div className="content-item">
                            <TextInput
                                props={{
                                    id: "create-task-name",
                                    value: name,
                                    placeholder: '',
                                    setValue: (value) => setName(value),
                                    isIcon: false,
                                    isLabel: true,
                                    isRequired: true,
                                    label: "Name",
                                }}
                            />
                        </div>
                        <div className="content-item">
                            <MultiSelectInput
                                props={{
                                    id: 'task-multi-select-input',
                                    placeholder: 'Select Phase',
                                    isRequired: false,
                                    value: SelectedPhases,
                                    options: ProjectPhases_Options,
                                    isIcon: false,
                                    avoidDuplicate: true,
                                    icon: '',
                                    isLabel: true,
                                    label: 'Project Phases',
                                    setValue: (value) => HandleSelectPhase(value),
                                }}
                            />

                        </div>

                        <div className="content-item">
                            <SelectInput
                                props={{
                                    id: "estimation-group-group",
                                    value: ParentTask_id,
                                    placeholder: '',
                                    options: [
                                        {
                                            value: "",
                                            label: "Select Parent"
                                        }, ...ParentTaskOptions || []
                                    ] || [],
                                    setValue: (value, label) => {
                                        setParentTask_id(value)
                                        setParentTask_name(label)
                                    },
                                    isIcon: false,
                                    isLabel: true,
                                    isRequired: false,
                                    label: "Parent Task",
                                }}
                            />

                        </div>
                        <div className="content-item">
                            <SelectInput
                                props={{
                                    id: "create-task-category",
                                    value: WorkType_id,
                                    placeholder: '',
                                    options: [
                                        {
                                            value: "",
                                            label: "Select Category"
                                        }, ...WorkTypeOptions || []
                                    ] || [],
                                    setValue: (value, label) => {
                                        setWorkType_id(value)
                                        setWorkType_name(label)
                                    },
                                    isIcon: false,
                                    isLabel: true,
                                    isRequired: false,
                                    label: "Work Category",
                                }}
                            />

                        </div>
                        <div className="content-item">
                            <SelectInput
                                props={{
                                    id: "create-task-priority",
                                    value: Priority_id,
                                    placeholder: '',
                                    options: [
                                        {
                                            value: "",
                                            label: "Select Priority"
                                        }, ...PriorityOptions || []
                                    ] || [],
                                    setValue: (value, label) => {
                                        setPriority_id(value)
                                        setPriority_name(label)
                                    },
                                    isIcon: false,
                                    isLabel: true,
                                    isRequired: true,
                                    label: "Priority",
                                }}
                            />
                        </div>
                        <div className="content-item">
                            <DateTimeInput
                                props={{
                                    id: "create-task-startdate",
                                    value: StartDate,
                                    placeholder: '',
                                    setValue: (value) => {
                                        setStartDate(value)
                                    },
                                    isIcon: false,
                                    isLabel: true,
                                    icon: Icons.general.schedule,
                                    isRequired: false,
                                    label: "Start date",
                                }}
                            />
                        </div>
                        <div className="content-item">
                            <DateTimeInput
                                props={{
                                    id: "create-task-enddate",
                                    value: EndDate,
                                    placeholder: '',
                                    setValue: (value) => {
                                        setEndDate(value)
                                    },
                                    isIcon: false,
                                    isLabel: true,
                                    icon: Icons.general.schedule,
                                    isRequired: false,
                                    label: "End date",
                                }}
                            />
                        </div>
                        <div className="content-item">
                            <SelectInput
                                props={{
                                    id: "create-task-assignee",
                                    value: Assignee_id,
                                    placeholder: '',
                                    options: [
                                        {
                                            value: "",
                                            label: "Select Assignee"
                                        }, ...AssigneeOptions || []
                                    ] || [],
                                    setValue: (value, label) => {
                                        setAssignee_id(value)
                                        setAssignee_name(label)
                                    },
                                    isIcon: false,
                                    isLabel: true,
                                    isRequired: false,
                                    label: "Assignee",
                                }}
                            />
                        </div>
                        <div className="content-item">
                            <MultiSelectInput
                                props={{
                                    id: 'assignee-multi-select-input',
                                    placeholder: 'Select Assignees',
                                    isRequired: true,
                                    value: SelectedAssignees,
                                    options: AssigneeOptions,
                                    isIcon: false,
                                    avoidDuplicate: true,
                                    icon: '',
                                    isLabel: true,
                                    label: 'Assignees',
                                    setValue: (value) => HandleSelectAssignee(value),
                                }}
                            />
                        </div>
                        <div className="content-section">
                            <div className="content-item">
                                <div id="create-task-description" className={`additional-item ${additionalNotes ? 'additional-item-active' : ''}`}>
                                    <div className="head" onClick={(e) => setAdditionalNotes(!additionalNotes)}>
                                        <div className="icon" dangerouslySetInnerHTML={{ __html: Icons.general.description }}></div>
                                        <div className="label">Description</div>
                                    </div>
                                    <div className="item-expanded">
                                        <textarea
                                            value={description}
                                            onChange={(e) => setDescription(e.target.value)}
                                            placeholder='Enter description'
                                        >

                                        </textarea>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>

                <Popup_Footer />
            </form>
        </div>
    )
}
const AddTaskLogPopup = ({ props }) => {

    const { taskDetials, updateCallback, close } = props

    const { projectId, itemType, itemId, actionType } = useParams()

    const [isLoading, setIsLoading] = useState(true)
    const [warningAlert, setWarningAlert] = useState(false)
    const [warningAlertType, setWarningAlertType] = useState('warning')
    const [apiFailedMessage, setApiFailedMessage] = useState("Error in Server")

    const builderAttachments = new BuilderAttachments()

    const [currency_type, setCurrency_type] = useState('₹')

    const navigate = useNavigate()
    const store = useSelector((store) => store)
    const dispatch = useDispatch()
    const { updateState } = new UserAction

    const storeHandler = new StoreHandler({
        dataStack: ['company', 'sor_labours'],
        store: store,
        dispatch: dispatch,
        updateState: updateState,
    })

    const LoadStoreData = async () => {
        setIsLoading(true)
        let resolved = await storeHandler.init()
        setIsLoading(false)
    }

    const [showTaskLogpopup, setShowTaskLogpopup] = useState(false)

    const [LogId, setLogId] = useState(Utils.getUniqueId())
    const [UpdateMinLimit, setUpdateMinLimit] = useState(0)
    const [UpdateMaxLimit, setUpdateMaxLimit] = useState(parseFloat(taskDetials.total_work_progress || 100))
    const [UpdateType, setUpdateType] = useState('')
    const [UpdateValue, setUpdateValue] = useState(parseInt(taskDetials.work_done_progress || 0))
    const [Remarks, setRemarks] = useState('')
    const [Unit_name, setUnit_name] = useState(taskDetials.unit_name || '')
    const [Unit_id, setUnit_id] = useState(taskDetials.unit_id || '')

    const [WorkerOptions, setWorkerOptions] = useState([])
    const [WorkerOptions_Orginal, setWorkerOptions_Orginal] = useState([])

    const [SelectedToggleSection, setSelectedToggleSection] = useState('task-update-radio-progress');

    const [AttendanceDetials, setAttendanceDetials] = useState([])

    const [MaterialOptions, setMaterialOptions] = useState([])
    const [MaterialOptions_Orginal, setMaterialOptions_Orginal] = useState([])

    const [MaterialUsed, setMaterialUsed] = useState([])
    const [MaterialIssued, setMaterialIssued] = useState()

    const [ShowToClientValue, setShowToClientValue] = useState(false)


    const HandleToggleItemClick = (id) => {

        setUpdateType('task-update-radio-progress' == id ? '1' : '0')
        setSelectedToggleSection(id)

        const toggle_input = document.getElementById(id)

        if (!toggle_input) return

        toggle_input.checked = true

        HandleUpdateSlider()
    }

    const HandleCancel = () => {
        close()
    }
    const HandleSave = (e) => {
        e.preventDefault()

        let userdetials = JSON.parse(localStorage.getItem('userdetials'))

        let progress_value = parseFloat(UpdateValue || 0) - parseFloat(taskDetials.work_done_progress || 0)

        let _UpdateType = UpdateType
        if (progress_value == 0) _UpdateType = '0'

        let update_data = {
            id: LogId,
            date: Utils._getLocalDate(),
            time: Utils.getLocalFullDateLabel(),
            ms_time: new Date().getTime(),

            update_type: String(_UpdateType),
            progress_value: String(parseFloat(UpdateValue || 0) - parseFloat(taskDetials.work_done_progress || 0)),
            progress_percentage: (UpdateValue / UpdateMaxLimit) * 100,
            total_progress_value: UpdateMaxLimit,
            remarks: String(Remarks),
            unit_id: String(Unit_id),
            show_to_client: ShowToClientValue ? '1' : '0',
            unit_name: String(Unit_name),
            worker_attendance: AttendanceDetials,
            materials_used: MaterialUsed,
            total_workers: AttendanceDetials.reduce((prev, a) => prev + parseInt(a.count), 0) || 0,
            attachments: AttachmentsFiles || [],
            added_by: userdetials.name,
            added_by_id: userdetials.id,
        }

        updateCallback(update_data)



    }

    const HandleUpdateSlider = () => {

        const slider_input = document.getElementById('task-update-slider_input'),
            slider_value = document.getElementById('task-update-range-value'),
            slider_thumb = document.getElementById('task-update-slider_thumb'),
            slider_line = document.getElementById('task-update-slider_line');

        if (SelectedToggleSection != 'task-update-radio-progress') return

        const showSliderValue = () => {

            let value = (slider_input.value / slider_input.max) * 100

            if (taskDetials.work_done_progress > slider_input.value) return

            setUpdateValue(parseFloat(slider_input.value))

            slider_value.innerHTML = `${slider_input.value}${Unit_name || taskDetials.unit_name}`

            const bulletPosition = (slider_input.value / slider_input.max),
                space = slider_input.offsetWidth - slider_thumb.offsetWidth;

            slider_thumb.style.left = (bulletPosition * space) + 'px';
            slider_line.style.width = value + '%';
        }

        showSliderValue();
        window.addEventListener("resize", showSliderValue);
        slider_input.addEventListener('input', showSliderValue, false);


    }

    const HandleAddWorker = () => {
        let _AttendanceDetials = [...AttendanceDetials]

        _AttendanceDetials.push({
            id: Utils.getUniqueId(),
            worktype_id: '',
            worktype_name: '',
            count: ''
        })

        setAttendanceDetials(_AttendanceDetials)
    }
    const HandleDeleteWorker = (idx) => {
        let _AttendanceDetials = [...AttendanceDetials]


        let value = _AttendanceDetials[idx]['worktype_id']

        let workerOption_idx = WorkerOptions.findIndex(w => w.value == value)

        if (workerOption_idx != -1) {
            let _WorkerOptions = [...WorkerOptions]
            _WorkerOptions[workerOption_idx].disabled = false
            setWorkerOptions(_WorkerOptions)
        }


        _AttendanceDetials.splice(idx, 1)

        setAttendanceDetials(_AttendanceDetials)

    }
    const HandleUpdateWorkerDetials = (id, idx, key, value) => {
        let _AttendanceDetials = [...AttendanceDetials]

        if (key == 'worktype_id') {

            let workerOption_idx = WorkerOptions.findIndex(w => w.value == value)

            if (workerOption_idx == -1) return

            _AttendanceDetials[idx]['worktype_id'] = value
            _AttendanceDetials[idx]['worktype_name'] = WorkerOptions[workerOption_idx].label

            let _WorkerOptions = [...WorkerOptions]
            _WorkerOptions[workerOption_idx].disabled = true
            setWorkerOptions(_WorkerOptions)

        }
        else if (key == 'count') {

            value = Utils.numberOnly(value)
            _AttendanceDetials[idx][key] = parseFloat(value || 0)
        }

        setAttendanceDetials(_AttendanceDetials)
    }

    const HandleAddMaterial = () => {
        let _MaterialUsed = [...MaterialUsed]

        _MaterialUsed.push({
            id: Utils.getUniqueId(),
            material_id: '',
            material_name: '',
            unit_id: '',
            unit_name: '',
            quantity: 0,
            time: Utils.getLocalFullDateLabel(),
        })

        setMaterialUsed(_MaterialUsed)
    }
    const HandleDeleteMaterial = (idx) => {
        let _MaterialUsed = [...MaterialUsed]

        let value = _MaterialUsed[idx]['material_id']

        let MaterialOption_idx = MaterialOptions.findIndex(w => w.value == value)

        if (MaterialOption_idx != -1) {
            let _MaterialOptions = [...MaterialOptions]
            _MaterialOptions[MaterialOption_idx].disabled = false
            setMaterialOptions(_MaterialOptions)
        }



        _MaterialUsed.splice(idx, 1)

        setMaterialUsed(_MaterialUsed)
    }
    const HandleUpdateMaterialDetials = (material_id, idx, key, value) => {
        let _MaterialUsed = [...MaterialUsed]

        if (key == 'material') {


            let MaterialOption_idx = MaterialOptions.findIndex(w => w.value == value)

            if (MaterialOption_idx == -1) return

            let material = MaterialIssued.filter(w => w.material_id == value)[0]

            _MaterialUsed[idx]['material_id'] = material.material_id
            _MaterialUsed[idx]['material_name'] = material.material_name
            _MaterialUsed[idx]['unit_id'] = material.unit_id
            _MaterialUsed[idx]['unit_name'] = material.unit_name

            let _MaterialOptions = [...MaterialOptions]
            _MaterialOptions[MaterialOption_idx].disabled = true
            setMaterialOptions(_MaterialOptions)

        }
        else if (key == 'quantity') {

            let material = MaterialIssued.filter(w => w.material_id == material_id)[0]
            if (!material) return

            value = Utils.numberOnly(value)

            if (parseFloat(value || 0) > material.balance_quantity) value = material.balance_quantity

            _MaterialUsed[idx][key] = value
        }

        setMaterialUsed(_MaterialUsed)
    }

    useEffect(() => {

        if (taskDetials.materials_issued) {

            let materials_issued = taskDetials.materials_issued.filter(m => parseFloat(m.balance_quantity || 0) > 0).map(m => {
                return {
                    value: m.material_id,
                    label: m.material_name,
                }
            })
            setMaterialOptions(materials_issued)
            setMaterialOptions_Orginal(taskDetials.materials_issued)
            setMaterialIssued(taskDetials.materials_issued)
        }


        LoadStoreData()
        HandleUpdateSlider()
        HandleToggleItemClick(SelectedToggleSection)

        setShowToClientValue(taskDetials?.show_to_client == '1' ? true : false)

        setIsLoading(true)

    }, [])

    useEffect(() => {

        if (store && store.user) {

            let { company, sor_labours } = store.user

            if (company) {

                let { currency_setting, timezone, temperature_setting } = company

                if (currency_setting) setCurrency_type(currency_setting)
            }
            if (Array.isArray(sor_labours)) {

                let _sor_labours = sor_labours.map((opt) => {
                    return {
                        value: opt.id,
                        label: opt.name,
                    }
                })

                console.log(_sor_labours);
                setWorkerOptions(_sor_labours)
                setWorkerOptions_Orginal(sor_labours)
            }
        }

    }, [store])


    const [ShowSelectedPreviewImage, setShowSelectedPreviewImage] = useState(false)
    const [SelectedPreviewImage, setSelectedPreviewImage] = useState(undefined)
    const AttachmentsInputRef = useRef()
    const [AttachmentsFiles, setAttachmentsFiles] = useState([])

    const HandleTriggerAddAttachment = (e) => {
        if (AttachmentsInputRef.current) AttachmentsInputRef.current.click()
    }
    const HandleAddAttachment = async (e) => {

        let file = e.target.files[0]

        if (!file) return

        const formData = new FormData();

        formData.append('projectId', projectId);
        formData.append('attachment', file);
        formData.append('linked_type', '0');
        formData.append('linked_item_id', LogId);

        setIsLoading(true)

        let upload_response = await builderAttachments.createAttachmentHandler(formData)

        setIsLoading(false)
        if (upload_response.success) {

            let { public_id, url } = upload_response.data

            if (!public_id || !url) return

            let _AttachmentsFiles = [...AttachmentsFiles]

            _AttachmentsFiles.push({
                public_id: public_id,
                original_filename: file.name,
                bytes: file.size,
                format: file.type,
                file_url: url,
                linked_type: '0',
                linked_item_id: LogId,
            })

            setAttachmentsFiles(_AttachmentsFiles)

        } else {
            setWarningAlert(true)
            setWarningAlertType('error')
            setApiFailedMessage(`Error in uploading attachment`)
        }
    }
    const HandleDeleteAttachment = async (idx) => {

        let _AttachmentsFiles = [...AttachmentsFiles]

        setIsLoading(true)

        let upload_response = await builderAttachments.deleteAttachmentHandler({
            public_id: _AttachmentsFiles[idx].public_id,
            projectId: projectId,
        })

        setIsLoading(false)

        if (upload_response.success) {
            _AttachmentsFiles.splice(idx, 1)
            setAttachmentsFiles(_AttachmentsFiles)
        }
        else {
            setWarningAlert(true)
            setWarningAlertType('error')
            setApiFailedMessage(`Error in deleting attachment`)
        }
    }


    const HandleSelectPreviewImage = (image) => {
        setShowSelectedPreviewImage(true)
        setSelectedPreviewImage({
            name: image.original_filename,
            file_url: image.file_url,
        })
    }
    const HandleHideSelectPreviewImage = (confirmation) => {
        setShowSelectedPreviewImage(confirmation)
        setSelectedPreviewImage(undefined)
    }

    return (

        <>

            <div className="popup-container-main propup-container-center">
                <div className="popup-block-ui"></div>

                {isLoading ?
                    <Loading
                        props={{
                            isMainLogo: false,
                            isLabel: true
                        }} />

                    : null}

                {warningAlert ?
                    <SystemToastPopup
                        props={{
                            type: warningAlertType,
                            message: apiFailedMessage || "Error in Tasks",
                            callback: (confirmation) => setWarningAlert(false)
                        }} />

                    : null}

                {ShowSelectedPreviewImage ?

                    <ImagePreviewPopup
                        props={{
                            heading: `Preview - ${SelectedPreviewImage.name}`,
                            image: SelectedPreviewImage.file_url,
                            message: "Invalid Inputs, Please try again with vaild Inputs!",
                            callback: (confirmation) => HandleHideSelectPreviewImage(confirmation)
                        }} />

                    : null}

                <form
                    style={{ maxWidth: '700px' }}
                    className="side-popup-container side-task-popup-container animateXRight animateXRightactive" onSubmit={HandleSave}>
                    <div className="sidebar-task-popup-content">

                        <div className="side-popup-header">
                            <div className="header-item-select">
                                <div className="header-item-select-content">
                                    <div className="label">Update Task</div>
                                </div>
                            </div>
                            <div className="header-item-right">
                                <ShowToClient
                                    props={{
                                        value: ShowToClientValue,
                                        callback: (value) => setShowToClientValue(value)
                                    }}
                                />
                                <div
                                    className="header-item-close"
                                    onClick={(e) => {
                                        close()
                                    }}
                                    dangerouslySetInnerHTML={{ __html: Icons.general.close }}
                                ></div>
                            </div>

                        </div>

                        <div className="task-radio-toggle-section">
                            <div
                                className={`task-toggle-item ${'task-update-radio-progress' == SelectedToggleSection ? 'task-toggle-item-active' : ''}`}
                                onClick={() => HandleToggleItemClick('task-update-radio-progress')}
                            >
                                <input
                                    id='task-update-radio-progress'
                                    type="radio"
                                    name='task-update-progress-radio'
                                    defaultChecked={'task-update-progress' == SelectedToggleSection}
                                    value={'task-update-progress' == SelectedToggleSection}
                                />
                                <label htmlFor='task-update-radio-progress' className="label">UPDATE PROGRESS</label>
                            </div>
                            <div
                                className={`task-toggle-item ${'task-update-radio-noprogress' == SelectedToggleSection ? 'task-toggle-item-active' : ''}`}
                                onClick={() => HandleToggleItemClick('task-update-radio-noprogress')}
                            >
                                <input
                                    id='task-update-radio-noprogress'
                                    type="radio"
                                    name='task-update-progress-radio'
                                    defaultChecked={'task-update-progress' == SelectedToggleSection}
                                    value={'task-update-progress' == SelectedToggleSection}
                                />
                                <label htmlFor='task-update-radio-noprogress' className="label">NO PROGRESS</label>
                            </div>

                        </div>

                        <div className="task-content-update-sections">
                            {SelectedToggleSection == 'task-update-radio-progress' ?
                                <>
                                    <div className="task-content-update-section">
                                        <div className="task-update-header">
                                            <div className="title">Work done today</div>
                                            <div className="title">({Unit_name})</div>
                                        </div>
                                        <div className="task-update-input">

                                            <div className="task-update-range-slider">
                                                <div id="task-update-slider_thumb" className="task-update-range-slider_thumb"></div>
                                                <div className="task-update-range-slider_line">
                                                    <div id="task-update-slider_line" className="task-update-range-slider_line-fill"></div>
                                                </div>
                                                <input id="task-update-slider_input" className="task-update-range-slider_input" type="range" value={UpdateValue} min={UpdateMinLimit} max={UpdateMaxLimit} />
                                            </div>

                                            <label className="task-update-range-value" id='task-update-range-value'>-</label>

                                        </div>
                                    </div>
                                    <div className="task-content-update-section">
                                        <div className="task-update-header">
                                            <div className="title">Attendance</div>
                                            <div
                                                className="add-button"
                                                onClick={() => HandleAddWorker()}
                                            >Add worker</div>
                                        </div>

                                        {!AttendanceDetials.length ?
                                            <div className="task-update-attendance-noinputs">
                                                <div
                                                    className="icon"
                                                    dangerouslySetInnerHTML={{ __html: Icons.general.labour }}
                                                ></div>
                                                <div className="label">No Workers added</div>
                                            </div>
                                            : ''}
                                        {AttendanceDetials.length ?
                                            <div className="task-update-attendance-inputs">
                                                {
                                                    AttendanceDetials?.map((detials, idx) => (
                                                        <div key={detials.id} className="task-update-attendance-item">
                                                            <div className="attendance-input">
                                                                <label htmlFor="task-update-attendance-type">Worker Type</label>
                                                                <select
                                                                    type="text"
                                                                    placeholder=''
                                                                    id='task-update-attendance-type'
                                                                    value={detials.worktype_id}
                                                                    required={true}
                                                                    onChange={(e) => HandleUpdateWorkerDetials(detials.worktype_id, idx, 'worktype_id', e.target.value)}
                                                                >
                                                                    {
                                                                        [
                                                                            {
                                                                                value: '',
                                                                                label: 'Select type',
                                                                            }, ...WorkerOptions
                                                                        ]?.map(opt => (
                                                                            <option
                                                                                style={{ display: `${opt.disabled ? 'none' : 'block'}` }}
                                                                                key={opt.value}
                                                                                value={opt.value}
                                                                            >{opt.label}</option>
                                                                        ))
                                                                    }
                                                                </select>

                                                            </div>
                                                            <div className="attendance-input">
                                                                <label htmlFor="task-update-attendance-count">Worker Type</label>
                                                                <input
                                                                    type="text"
                                                                    id='task-update-attendance-count'
                                                                    placeholder='Enter count'
                                                                    value={detials.count}
                                                                    required={true}
                                                                    onChange={(e) => HandleUpdateWorkerDetials(detials.id, idx, 'count', e.target.value)}
                                                                />

                                                            </div>
                                                            <div
                                                                className="attendance-remove"
                                                                onClick={() => HandleDeleteWorker(idx)}
                                                                dangerouslySetInnerHTML={{ __html: Icons.general.delete_btn }}
                                                            ></div>
                                                        </div>
                                                    ))
                                                }



                                            </div>
                                            : ''}
                                    </div>
                                    <div className="task-content-update-section">
                                        <div className="task-update-header">
                                            <div className="title">Materials Used</div>
                                            <div
                                                className="add-button"
                                                onClick={() => HandleAddMaterial()}
                                            >Add material</div>
                                        </div>

                                        {!MaterialUsed.length ?
                                            <div className="task-update-attendance-noinputs">
                                                <div
                                                    className="icon"
                                                    dangerouslySetInnerHTML={{ __html: Icons.general.material }}
                                                ></div>
                                                <div className="label">No Material added</div>
                                            </div>
                                            : ''}
                                        {MaterialUsed.length ?
                                            <div className="task-update-attendance-inputs">
                                                {
                                                    MaterialUsed?.map((detials, idx) => (
                                                        <div key={detials.id} className="task-update-attendance-item">
                                                            <div className="attendance-input">
                                                                <label htmlFor="task-update-attendance-material">Material</label>
                                                                <select
                                                                    type="text"
                                                                    placeholder=''
                                                                    id='task-update-attendance-material'
                                                                    value={detials.material_id}
                                                                    required={true}
                                                                    onChange={(e) => HandleUpdateMaterialDetials(detials.material_id, idx, 'material', e.target.value)}
                                                                >
                                                                    {
                                                                        [
                                                                            {
                                                                                value: '',
                                                                                label: 'Select material',
                                                                            }, ...MaterialOptions
                                                                        ]?.map(opt => (
                                                                            <option
                                                                                style={{ display: `${opt.disabled ? 'none' : 'block'}` }}
                                                                                key={opt.value}
                                                                                value={opt.value}
                                                                            >{opt.label}</option>
                                                                        ))
                                                                    }
                                                                </select>

                                                            </div>
                                                            <div className="attendance-input">
                                                                <label htmlFor="task-update-attendance-quantity">Quantity ({detials.unit_name || 'qty'})</label>
                                                                <input
                                                                    type="text"
                                                                    id='task-update-attendance-quantity'
                                                                    placeholder='Enter quantity'
                                                                    value={detials.quantity}
                                                                    required={true}
                                                                    onChange={(e) => HandleUpdateMaterialDetials(detials.material_id, idx, 'quantity', e.target.value)}
                                                                />

                                                            </div>
                                                            <div
                                                                className="attendance-remove"
                                                                onClick={() => HandleDeleteMaterial(idx)}
                                                                dangerouslySetInnerHTML={{ __html: Icons.general.delete_btn }}
                                                            ></div>
                                                        </div>
                                                    ))
                                                }



                                            </div>
                                            : ''}
                                    </div>

                                </>
                                : ''}

                            <div className="task-content-update-section">
                                <div className="task-update-header">
                                    <div className="title">Add Photos (Optional)</div>

                                </div>

                                <div className="attachments-section-main">
                                    <div className="attachments-section-files">
                                        <div className="attachment-input" onClick={HandleTriggerAddAttachment}>
                                            <input type="file" onChange={HandleAddAttachment} ref={AttachmentsInputRef} accept='.png,.jpg,.jpeg' />
                                            <div className="attachment-add-icon"
                                                dangerouslySetInnerHTML={{ __html: Icons.general.add_attachments_plus }}
                                            ></div>
                                            <div className="attachment-label">Upload</div>
                                            <div className="attachment-limit">Max limit: 5MB</div>
                                        </div>
                                        {AttachmentsFiles?.map((file, idx) => (
                                            <div
                                                className="attachment-file"
                                                key={file.public_id}
                                                id={`file-${file.public_id}`}
                                            >
                                                <div
                                                    className="attachment-delete-btn"
                                                    dangerouslySetInnerHTML={{ __html: Icons.general.close }}
                                                    onClick={() => HandleDeleteAttachment(idx)}
                                                ></div>
                                                <div className="attachment-file-preview" onClick={() => HandleSelectPreviewImage(file)}>
                                                    <img src={file.file_url} alt="attachment-file" />
                                                </div>
                                                <div className="attachment-file-footer">
                                                    <div className="file-name">{file.original_filename}</div>
                                                    <div
                                                        className="file-download"
                                                        dangerouslySetInnerHTML={{ __html: Icons.general.download }}
                                                    ></div>
                                                </div>

                                            </div>
                                        ))}


                                    </div>
                                </div>


                            </div>
                            <div className="task-content-update-section">
                                <div className="task-update-header">
                                    <div className="title">Remark</div>
                                </div>
                                <div className="task-update-remark-input">
                                    <textarea
                                        placeholder='Enter remark'
                                        onChange={(e) => setRemarks(e.target.value)}
                                        value={Remarks}
                                    ></textarea>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="sidebar-popup-footer task-sidebar-footer">
                        <div className="footer-item action-items">
                            <div
                                className="action-btn discard-changes-btn"
                                onClick={() => HandleCancel()}

                            >
                                <div className="label">Cancel</div>
                            </div>
                            <button
                                className="action-btn save-changes-btn"
                                type='submit'
                            >
                                <div className="label">Save</div>
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </>
    )

}
const UpdateTask = ({ props }) => {

    const { existItemData, updateCallback } = props

    const { projectId, itemType, itemId, actionType } = useParams()

    const [isLoading, setIsLoading] = useState(false)
    const [warningAlert, setWarningAlert] = useState(false)
    const [warningAlertType, setWarningAlertType] = useState('warning')
    const [apiFailedMessage, setApiFailedMessage] = useState("Error in Server")

    const taskManagementHandler = new TaskManagementHandler()
    const issuesManagementHandler = new IssuesManagementHandler()
    const materialIssueHandler = new MaterialIssueHandler()
    const commentsHandler = new CommentsHandler()

    const [currency_type, setCurrency_type] = useState('₹')

    const navigate = useNavigate()
    const store = useSelector((store) => store)
    const dispatch = useDispatch()
    const { updateState } = new UserAction


    const storeHandler = new StoreHandler({
        dataStack: ['company', 'costcodes', 'field_settings', 'team_users', `project_phases-${projectId}`],
        store: store,
        dispatch: dispatch,
        updateState: updateState,
    })

    const LoadStoreData = async () => {
        setIsLoading(true)
        let resolved = await storeHandler.init()
        setIsLoading(false)
    }

    const isExistItemData = existItemData && existItemData.id != undefined

    const [showTaskLogpopup, setShowTaskLogpopup] = useState(false)

    const [Name, setName] = useState('')
    const [Description, setDescription] = useState('')
    const [ParentTask_id, setParentTask_id] = useState('')
    const [ParentTask_name, setParentTask_name] = useState('')
    const [TaskPriority_id, setTaskPriority_id] = useState('')
    const [TaskPriority_name, setTaskPriority_name] = useState('')
    const [WorkType_id, setWorkType_id] = useState('')
    const [WorkType_name, setWorkType_name] = useState('')
    const [Duration, setDuration] = useState('')
    const [StartDate, setStartDate] = useState('')
    const [EndDate, setEndDate] = useState('')
    const [Unit_name, setUnit_name] = useState('')
    const [Unit_id, setUnit_id] = useState('')
    const [Assignee_id, setAssignee_id] = useState('')
    const [Assignee_name, setAssignee_name] = useState('')
    const [TotalPrice, setTotalPrice] = useState('')
    const [TotalWorkDone, setTotalWorkDone] = useState(0)
    const [TotalWork, setTotalWork] = useState(100)
    const [TotalWorkDonePercentage, setTotalWorkDonePercentage] = useState(0)
    const [StatusEnem, setStatusEnem] = useState('-1')
    const [StatusLabel, setStatusLabel] = useState('')

    const [DetilsInputsChanged, setDetilsInputsChanged] = useState(false)

    const [TaskProrityOptions, setTaskProrityOptions] = useState([
        {
            value: '0',
            label: 'Low'
        },
        {
            value: '1',
            label: 'Medium'
        },
        {
            value: '2',
            label: 'High'
        }
    ])
    const [ParentTaskOptions, setParentTaskOptions] = useState([])
    const [WorkTypeOptions, setWorkTypeOptions] = useState([])
    const [WorkTypeOptions_Orginal, setWorkTypeOptions_Orginal] = useState([])
    const [UnitsOptions, setUnitsOptions] = useState([])

    const [SelectedToggleSection, setSelectedToggleSection] = useState('task-section-detials');

    const [ToggleSectionItems, setToggleSectionItems] = useState([
        {
            id: 'task-section-detials',
            label: 'Detials'
        },
        {
            id: 'task-section-timeline',
            label: 'Timeline'
        },
        {
            id: 'task-section-photos',
            label: 'Photos'
        },
        {
            id: 'task-section-material_issued',
            label: 'Material issued'
        },
        {
            id: 'task-section-issues',
            label: 'Issues'
        },
        {
            id: 'task-section-comments',
            label: 'Comments'
        },
    ])

    const [Photos, setPhotos] = useState([])
    const [LinkedIssues, setLinkedIssues] = useState([])
    const [TimelineLogs, setTimelineLogs] = useState([])
    const [MaterialIssued_Orgianl, setMaterialIssued_Orgianl] = useState([])
    const [MaterialIssued, setMaterialIssued] = useState([])

    const [ProjectPhases, setProjectPhases] = useState([])
    const [ProjectPhases_Options, setProjectPhases_Options] = useState([])
    const [SelectedPhases, setSelectedPhases] = useState([])

    const HandleSelectPhase = (phase) => {

        setDetilsInputsChanged(true)

        phase = phase.map(p => {
            let phase = ProjectPhases.filter(ph => ph.id == p.id)[0]

            if (!phase) return p

            return phase
        })

        setSelectedPhases(phase)
    }

    const [ShowToClientValue, setShowToClientValue] = useState(false)

    const [AssigneeOptions_Orginal, setAssigneeOptions_Orginal] = useState([])
    const [AssigneeOptions, setAssigneeOptions] = useState([])
    const [SelectedAssignees_Res, setSelectedAssignees_Res] = useState([])
    const [SelectedAssignees, setSelectedAssignees] = useState([])

    const HandleSelectAssignee = (users) => {

        setDetilsInputsChanged(true)
        users = users.map(p => {
            let users = AssigneeOptions_Orginal.filter(ph => ph.id == p.id)[0]
            if (!users) return p

            return users
        })

        setSelectedAssignees(users)
    }


    const HandleMaterialExpandProgress = (id, idx, value) => {

        let _MaterialIssued = [...MaterialIssued]

        _MaterialIssued[idx].expanded = !value

        setMaterialIssued(_MaterialIssued)
    }


    const HandleToggleItemClick = (item) => {
        setSelectedToggleSection(item.id)

        let section_detials = document.getElementById(item.id);

        if (!section_detials) return
        section_detials.scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" });
    }

    const HandleExpandProgress = (id, idx, value) => {

        let _TimelineLogs = [...TimelineLogs]

        _TimelineLogs[idx].expanded = !value

        setTimelineLogs(_TimelineLogs)
    }
    const HandleDeleteProgress = async (id, idx) => {

        let _TimelineLogs = [...TimelineLogs]

        _TimelineLogs.splice(idx, 1)

        setTimelineLogs(_TimelineLogs)

        let response = await UpdateTaskLogToServer(_TimelineLogs)

        if (response) {

        }

    }

    const HandleDetialsInput = (key, event) => {

        setDetilsInputsChanged(true)

        let value = event.target.value

        if (key == 'work_type_id') {

            let worktype = WorkTypeOptions.filter(opt => opt.value == value)[0]

            if (!worktype) return
            setWorkType_id(value)
            setWorkType_name(worktype.label)
        }
        else if (key == 'parent_task_id') {

            let parent = ParentTaskOptions.filter(opt => opt.value == value)[0]
            if (!parent) return
            setParentTask_id(value)
            setParentTask_name(parent.label)
        }
        else if (key == 'priority_id') {

            let priority = TaskProrityOptions.filter(opt => opt.value == value)[0]
            if (!priority) return
            setTaskPriority_id(value)
            setTaskPriority_name(priority.label)
        }
        else if (key == 'description') {

            setDescription(value)
        }
        else if (key == 'total_work') {
            value = Utils.numberOnly(value)
            setTotalWork(value)
        }
        else if (key == 'duration') {

            value = Utils.numberOnly(value)
            setDuration(value)
            if (!value) return
            if (StartDate) {

                let end_date = Utils.getDateFromStartAndDaysDuration(StartDate, parseInt(value), '+')

                if (!end_date) return

                end_date = Utils.getLocalFullDate_reverse(end_date)

                setEndDate(end_date)
            }
            else if (EndDate) {

                let start_date = Utils.getDateFromStartAndDaysDuration(EndDate, parseInt(value), '-')
                if (!start_date) return

                start_date = Utils.getLocalFullDate_reverse(start_date)

                setStartDate(start_date)
            }

        }
        else if (key == 'start_date') {
            setStartDate(value)

            if (value && EndDate) {

                let duration_days = Utils.getDuration(new Date(EndDate).getTime(), new Date(value).getTime(), 'days')
                setDuration(duration_days)
            }
        }
        else if (key == 'end_date') {
            setEndDate(value)


            if (StartDate && value) {

                let duration_days = Utils.getDuration(new Date(value).getTime(), new Date(StartDate).getTime(), 'days')
                setDuration(duration_days)
            }
        }
        else if (key == 'unit_id') {

            let unit = UnitsOptions.filter(opt => opt.value == value)[0]

            if (!unit) return
            setUnit_id(value)
            setUnit_name(unit.label)
        }
        else if (key == 'assignee_id') {
            let assignee = AssigneeOptions.filter(opt => opt.value == value)[0]
            if (!assignee) return

            setAssignee_id(value)
            setAssignee_name(assignee.label)
        }
        else if (key == 'total_price') {
            value = Utils.numberOnly(value)
            setTotalPrice(value)
        }
    }

    const HandleClose = () => {
        navigate(`/builder/projects/${projectId}/task-management`)
        updateCallback()
    }
    const HandleCancelDetials = () => {

        setDetilsInputsChanged(false)
    }
    const HandleSaveDetials = async (e) => {

        e.preventDefault()

        let phases = SelectedPhases?.map(p => p.value)
        let assignees = SelectedAssignees?.map(p => p.value)

        let updated_data = {
            id: String(itemId),
            projectId: projectId,
            name: Name || "",
            description: Description || "",
            worktype_id: WorkType_id || "",
            worktype_name: WorkType_name || "",
            parent_id: ParentTask_id || "",
            parent_name: ParentTask_name || "",


            phases: phases || [],

            priority_id: TaskPriority_id || "",
            priority_name: TaskPriority_name || "",

            show_to_client: ShowToClientValue ? '1' : '0',

            duration: String(Duration) || "",
            startdate: StartDate || "",
            enddate: EndDate || "",
            unit_name: Unit_name || "",
            unit_id: Unit_id || "",
            assignee_id: Assignee_id || "",
            assignee_name: Assignee_name || "",
            total_task_price: String(TotalPrice) || "",
            total_work_progress: String(TotalWork || 100),
            task_progress: TimelineLogs || [],
            assignees: assignees || [],
        }
        setIsLoading(true)

        let response = await taskManagementHandler.updateTaskHandler(updated_data)

        setIsLoading(false)

        setDetilsInputsChanged(false)

        if (response.success) {

            if (typeof updateCallback == 'function') updateCallback()
            navigate(`/builder/projects/${projectId}/task-management`)
        }
        else {
            setWarningAlert(true)
            setWarningAlertType('error')
            setApiFailedMessage(`Error in updating task`)
        }



    }
    const HandleAddTaskLog = () => {

        setShowTaskLogpopup(true)
    }
    const HandleTaskLogupdateCallback = async (update_detials) => {

        if (!update_detials) return

        let _TimelineLogs = [...TimelineLogs]

        _TimelineLogs.push({
            id: update_detials.id,
            type: 'progress',
            update_type: update_detials.update_type,
            date: update_detials.date,
            time: update_detials.time,
            added_by: update_detials.added_by,
            added_by_id: update_detials.added_by_id,
            total_workers: update_detials.total_workers,
            show_to_client: update_detials.show_to_client,
            unit_id: update_detials.unit_id,
            unit_name: update_detials.unit_name,
            progress_value: update_detials.progress_value,
            progress_percentage: update_detials.progress_percentage,
            total_progress_value: update_detials.total_progress_value,
            materials_used: update_detials.materials_used,
            worker_attendance: update_detials.worker_attendance,
            attachments: update_detials.attachments,
            remarks: update_detials.remarks,
            expanded: false
        })

        setTimelineLogs(_TimelineLogs)


        let response = await UpdateTaskLogToServer(_TimelineLogs)

        if (response) {
            if (typeof updateCallback == 'function') updateCallback()
            setShowTaskLogpopup(false)
        }



    }
    const UpdateTaskLogToServer = async (task_progress) => {

        if (!Array.isArray(task_progress) || !task_progress.length) return

        let updated_data = {
            id: existItemData.id,
            projectId: projectId,
            task_progress: task_progress || [],
        }
        setIsLoading(true)

        let response = await taskManagementHandler.updateTaskLogHandler(updated_data)

        setIsLoading(false)

        if (!response.success) {

            setWarningAlert(true)
            setWarningAlertType('error')
            setApiFailedMessage(`Error in updating task log`)
            return false
        }


        return true
    }

    const LoadLinkedIssues = async () => {

        let filter = {
            linked_item_id: existItemData.id,
            projectId: projectId
        }

        let response = await issuesManagementHandler.getIssuesHandler(filter)

        if (response.success) {

            let { data } = response

            if (!Array.isArray(data)) return

            let _linkedIssues = data.map(d => {

                d.expanded = false
                return d
            })

            setLinkedIssues(_linkedIssues)

        }
    }
    const LoadLinkedMaterialIssued = async () => {

        let filter = {
            task_id: existItemData.id,
            projectId: projectId
        }

        let response = await materialIssueHandler.getMaterialIssueToTaskHandler(filter)

        return response.success ? response.data || [] : []
    }


    const HandleIssueExpandProgress = (id, idx, value) => {

        let _LinkedIssues = [...LinkedIssues]

        _LinkedIssues[idx].expanded = !value

        setLinkedIssues(_LinkedIssues)
    }

    const [selectedLinkedIssue, setSelectedLinkedIssue] = useState(undefined)

    const HandleAddNewIssue = () => {

        console.log(itemId, 'itemId');

        navigate(`/builder/projects/${projectId}/task-management/task/${itemId}/edit/linked-issue/add`)
    }

    const HandleOpenIssue = (issue, idx) => {
        console.log(itemId, 'itemId');
        setSelectedLinkedIssue(issue)
        navigate(`/builder/projects/${projectId}/task-management/task/${itemId}/edit/linked-issue/${issue.sno}/view`)

    }

    const HandleCloseIssue = async (id) => {

        if (!id) return

        let updated_data = {
            id: id,
            projectId: projectId,

        }

        setIsLoading(true)

        let response = await issuesManagementHandler.closeIssueHandler(updated_data)

        setIsLoading(false)

        if (response.success) {
            LoadLinkedIssues()
        }
        else {
            setWarningAlert(true)
            setWarningAlertType('error')
            setApiFailedMessage(`Error in closing issue`)
        }

    }

    const LoadParentTasks = async () => {

        let filter = {
            type: 1,
            projectId: projectId
        }

        let response = await taskManagementHandler.getTasksHandler(filter)

        if (response.success) {

            let { data } = response

            if (!Array.isArray(data)) return

            let getSubItemsIds = (items, parentid) => {

                let subGroupIds = []

                let getItems = (items, parentid) => {

                    if (!Array.isArray(items)) return

                    let _items = items.filter(itm => itm.parent_id == parentid)

                    subGroupIds.push(..._items)

                    _items.forEach(itm => getItems(items, itm.id))
                }

                getItems(items, parentid)

                subGroupIds = subGroupIds.map(g => g.id)

                subGroupIds.push(parentid)

                return subGroupIds
            }

            if (isExistItemData) {

                data = data.filter(d => !getSubItemsIds(data, existItemData.id).includes(d.id))
            }

            data = data.filter(d => d.type == '1').map(d => {

                return {
                    value: d.id,
                    label: d.name,
                }

            })

            setParentTaskOptions(data)
        }
    }

    const getStatusEenem = (type, delay) => {

        if (type == '0') return 'No dates added'
        else if (type == '1') return 'Upcoming task'
        else if (type == '2') return 'Not started'
        else if (type == '3') return 'In Progress'
        else if (type == '4') return 'Completed'
        else if (type == '5') return `Delayed by ${delay}`
        else if (type == '6') return 'on Hold'
        else if (type == '7') return 'Stopped'
        else return '-'
    }
    const getStatus = (item) => {

        if (
            !item.startdate
            || !item.enddate
        ) return '0' // No dates added
        else if (
            new Date().getTime() < new Date(item.startdate).getTime()
            && new Date().getTime() < new Date(item.enddate).getTime()
        ) return '1' // Upcoming task
        else if (
            new Date().getTime() > new Date(item.startdate).getTime()
            && new Date().getTime() < new Date(item.enddate).getTime()
            && item.work_done_progress == '0'
        ) return '2'  // Not started
        else if (
            new Date().getTime() > new Date(item.startdate).getTime()
            && new Date().getTime() < new Date(item.enddate).getTime()
            && item.work_done_progress != '0'
            && item.work_done_progress < item.total_work_progress
        ) return '3' // In Progress
        else if (
            item.work_done_progress == item.total_work_progress
        ) return '4' // Completed
        else if (
            new Date().getTime() > new Date(item.enddate).getTime()
            && item.work_done_progress != item.total_work_progress
        ) return '5' // Delayed by days
        else if (
            item.status == '3'
        ) return '6' // on Hold
        else if (
            item.status == '4'
        ) return '7' // Stopped

    }

    const LoadTaskDetails = async () => {

        let response = await taskManagementHandler.getTasksHandler({
            idx: itemId,
            projectId: projectId
        })

        if (response.success & response.data.length) {

            let data = response.data[0]

            setName(data.name || "")
            setDescription(data.description || "")

            setParentTask_id(data.parent_id || "")
            setParentTask_name(data.parent_name || "")

            setTaskPriority_id(data.priority_id || "")
            setTaskPriority_name(data.priority_name || "")

            setWorkType_id(data.worktype_id || "")
            setWorkType_name(data.worktype_name || "")

            setShowToClientValue(data?.show_to_client == '1' ? true : false)

            setDuration(data.duration || '')
            setStartDate(data.startdate || '')
            setEndDate(data.enddate || '')
            setUnit_name(data.unit_name || '')
            setUnit_id(data.unit_id || '')
            setAssignee_id(data.assignee_id || '')
            setAssignee_name(data.assignee_name || '')
            setTotalPrice(data.total_task_price || '')
            setTotalWorkDone(data.work_done_progress || 0)
            setTotalWork(data.total_work_progress || 100)
            setSelectedAssignees_Res(data.assignees || [])

            setTotalWorkDonePercentage((data.work_done_progress / data.total_work_progress) * 100)

            let duration_days = Utils.getDuration(new Date().getTime(), (new Date(data.enddate).getTime() || 0), 'days')

            setStatusEnem(getStatus(data))
            setStatusLabel(getStatusEenem(getStatus(data), `${duration_days} days`))

        }
        else {
            setWarningAlert(true)
            setWarningAlertType('error')
            setApiFailedMessage(`Error in loading task`)
            HandleClose()
        }
    }

    const LoadTaskProgress = async () => {


        let task_progress = existItemData.task_progress.map(t => {
            t.expanded = false
            return t
        })
        setTimelineLogs(task_progress || [])


        let materials_issue_response = await LoadLinkedMaterialIssued()

        console.log(materials_issue_response, 'materials_issue_response');

        materials_issue_response = materials_issue_response || []
        let materials_issued = []

        materials_issue_response.forEach(issue => {

            if (!Array.isArray(issue.cost_items)) return

            issue.cost_items.forEach(cost_item => {

                if (cost_item.task_id && cost_item.task_id == existItemData.id) {

                    materials_issued.push({

                        id: cost_item.material_id,
                        material_id: cost_item.material_id,
                        material_name: cost_item.material_name,
                        unit_id: cost_item.unit_id,
                        unit_name: cost_item.unit_name,
                        quantity: parseFloat(cost_item.quantity || 0),

                        used_quantity: 0,
                        balance_quantity: 0,

                        time: Utils.getLocalFullDateLabel(issue.created_at),
                        added_by: issue.checkedby_name,
                        added_by_id: issue.checkedby_id,
                        expanded: false,
                    })
                }

            })

        })

        if (Array.isArray(materials_issued) && materials_issued.length) {

            let used_maters = {}

            task_progress.forEach(progress => {

                if (!Array.isArray(progress.materials_used)) return
                progress.materials_used.forEach(mu => {
                    if (used_maters[mu.material_id]) used_maters[mu.material_id] += parseFloat(mu.quantity)
                    else used_maters[mu.material_id] = parseFloat(mu.quantity)
                })
            })

            console.log(used_maters, 'used_maters');

            materials_issued = materials_issued.map(matereial => {

                let used_qty = used_maters[matereial.material_id]

                used_qty = used_qty || 0

                let balance_quantity = parseFloat(matereial.quantity) - parseFloat(used_qty)

                matereial.used_quantity = used_qty
                matereial.balance_quantity = balance_quantity || 0


                return matereial

            })

            console.log(materials_issued, 'materials_issued');

            setMaterialIssued(materials_issued)
            setMaterialIssued_Orgianl(materials_issued)
        }

    }
    useEffect(() => {

        if (AssigneeOptions.length && Array.isArray(SelectedAssignees_Res) && SelectedAssignees_Res.length) {

            let select_assignee = SelectedAssignees_Res.map(p => {
                let assignee = AssigneeOptions.filter(ph => ph.value == p)[0]

                if (!assignee) return p
                return assignee
            })

            setSelectedAssignees(select_assignee)

        }

    }, [AssigneeOptions, SelectedAssignees_Res])

    useEffect(() => {

        if (!isExistItemData) {
            LoadTaskDetails()
        }
        LoadStoreData()
        LoadParentTasks()
        LoadLinkedIssues()

        if (isExistItemData) {

            setName(existItemData.name || "")
            setDescription(existItemData.description || "")

            setParentTask_id(existItemData.parent_id || "")
            setParentTask_name(existItemData.parent_name || "")

            setTaskPriority_id(existItemData.priority_id || "")
            setTaskPriority_name(existItemData.priority_name || "")

            setWorkType_id(existItemData.worktype_id || "")
            setWorkType_name(existItemData.worktype_name || "")

            setShowToClientValue(existItemData?.show_to_client == '1' ? true : false)

            setDuration(existItemData.duration || '')
            setStartDate(existItemData.startdate || '')
            setEndDate(existItemData.enddate || '')
            setUnit_name(existItemData.unit_name || '')
            setUnit_id(existItemData.unit_id || '')
            setAssignee_id(existItemData.assignee_id || '')
            setAssignee_name(existItemData.assignee_name || '')
            setTotalPrice(existItemData.total_task_price || '')
            setTotalWorkDone(existItemData.work_done_progress || 0)
            setTotalWork(existItemData.total_work_progress || 100)
            setSelectedAssignees_Res(existItemData.assignees || [])

            setTotalWorkDonePercentage((existItemData.work_done_progress / existItemData.total_work_progress) * 100)

            let duration_days = Utils.getDuration(new Date().getTime(), (new Date(existItemData.enddate).getTime() || 0), 'days')

            setStatusEnem(getStatus(existItemData))
            setStatusLabel(getStatusEenem(getStatus(existItemData), `${duration_days} days`))

            LoadTaskProgress()
        }

        LoadComments({ project_id: projectId, parent_id: existItemData.id })
    }, [])

    useEffect(() => {

        if (store && store.user) {

            let { company, team_users, costcodes, settings, selected_project_phases } = store.user

            if (company) {

                let { currency_setting, timezone, temperature_setting } = company

                if (currency_setting) setCurrency_type(currency_setting)
            }

            if (Array.isArray(team_users)) {

                let _team_users = team_users.filter(opt => opt.status == '1').map((opt) => {
                    return {
                        value: opt.id,
                        label: opt.name,
                    }
                })

                setAssigneeOptions(_team_users)
                setAssigneeOptions_Orginal(team_users)
            }
            if (Array.isArray(costcodes)) {

                let _costcodes = costcodes.filter(opt => opt.status == '1').map((opt) => {
                    return {
                        value: opt.id,
                        label: opt.name,
                    }
                })

                setWorkTypeOptions(_costcodes)
                setWorkTypeOptions_Orginal(costcodes)
            }
            if (settings && Array.isArray(settings.field_settings) && settings.field_settings.length) {

                let { project_statuses, project_types, units } = settings.field_settings[0]

                units = units.filter(opt => opt.isActive).map((opt) => {
                    return {
                        value: opt.id,
                        label: opt.name,
                        isDefault: opt.isDefault || false
                    }
                })

                setUnitsOptions(units || [])

            }

            if (selected_project_phases) {

                setProjectPhases(selected_project_phases)

                let project_phases = selected_project_phases.map(phase => {
                    return {
                        value: phase.id,
                        label: `${phase.name} | ${phase.client_name}`,
                        isDefault: false
                    }
                })

                setProjectPhases_Options(project_phases || [])

            }
        }

    }, [store])

    useEffect(() => {

        if (ProjectPhases_Options.length && existItemData && existItemData.phases) {
            let phases = existItemData.phases || []

            let select_phases = phases.map(p => {
                let phase = ProjectPhases_Options.filter(ph => ph.value == p)[0]

                if (!phase) return p
                return phase
            })

            setSelectedPhases(select_phases)

        }

    }, [ProjectPhases_Options])

    const HandleIssueUpdateCallback = () => {
        LoadLinkedIssues()
    }


    const [CommentsItems, setCommentsItems] = useState([])
    const [CommentsInput, setCommentsInput] = useState("")

    const LoadComments = async ({ project_id, parent_id }) => {

        console.log(project_id, parent_id, 'parent_id');
        if (!project_id || !parent_id) return

        let filter = {
            project_id: project_id,
            parent_id: parent_id
        }

        setIsLoading(true)

        let response = await commentsHandler.getCommentsHandler(filter)
        setIsLoading(false)

        if (response.success) {
            let { data } = response

            if (!Array.isArray(data)) return
            setCommentsItems(data)
        }
    }

    const HandleAddComment = async () => {
        let _CommentsItems = [...CommentsItems]

        let userdetials = JSON.parse(localStorage.getItem('userdetials'))

        _CommentsItems.push({
            id: Utils.getUniqueId(),
            created_at: new Date().getTime(),
            created_by: userdetials.id,
            created_by_name: userdetials.name,
            message: CommentsInput,
            parent_id: existItemData.id,
            parent_name: Name || `Task ${itemId}`,
            parent_type: 0,
            project_id: projectId,
            company_id: userdetials.company_id,
        })

        setCommentsItems(_CommentsItems)
        setCommentsInput("")

        if (!isExistItemData) return

        let update_data = {
            project_id: projectId,
            parent_id: existItemData.id,
            parent_name: Name || `Task ${itemId}`,
            parent_type: String(0),
            message: CommentsInput
        }

        let response = await commentsHandler.createCommentHandler(update_data)

        if (!response.success) {
            setWarningAlert(true)
            setWarningAlertType('error')
            setApiFailedMessage(`Error in creating comment`)
        }
    }

    const HandleDeleteComment = async (id, idx) => {
        let _CommentsItems = [...CommentsItems]

        _CommentsItems.splice(idx, 1)

        setCommentsItems(_CommentsItems)

        if (!isExistItemData) return

        let update_data = {
            project_id: projectId,
            ids: [id]
        }

        let response = await commentsHandler.deleteCommentHandler(update_data)

        if (!response.success) {
            setWarningAlert(true)
            setWarningAlertType('error')
            setApiFailedMessage(`Error in deleting comment`)
        }
    }

    const [ShowSelectedPreviewImage, setShowSelectedPreviewImage] = useState(false)
    const [SelectedPreviewImage, setSelectedPreviewImage] = useState(undefined)

    const HandleSelectPreviewImage = (image) => {
        setShowSelectedPreviewImage(true)
        setSelectedPreviewImage({
            file_url: image.file_url,
            original_filename: image.original_filename
        })
    }
    const HandleHideSelectPreviewImage = (confirmation) => {
        setShowSelectedPreviewImage(confirmation)
        setSelectedPreviewImage(undefined)
    }





    return (

        <div className="popup-container-main propup-container-center">
            <div className="popup-block-ui"></div>

            {ShowSelectedPreviewImage ?

                <ImagePreviewPopup
                    props={{
                        heading: `Preview - ${SelectedPreviewImage.original_filename}`,
                        image: SelectedPreviewImage.file_url,
                        message: "Invalid Inputs, Please try again with vaild Inputs!",
                        callback: (confirmation) => HandleHideSelectPreviewImage(confirmation)
                    }} />

                : null}

            {showTaskLogpopup ?
                <AddTaskLogPopup
                    props={{
                        taskDetials: {
                            id: existItemData.id,
                            name: Name || "",
                            Description: Description || "",
                            workType_id: WorkType_id || "",
                            workType_name: WorkType_name || "",
                            parent_id: ParentTask_id || "",
                            parent_name: ParentTask_name || "",
                            priority_id: TaskPriority_id || "",
                            priority_name: TaskPriority_name || "",
                            duration: Duration || "",
                            startDate: StartDate || "",
                            endDate: EndDate || "",
                            unit_name: Unit_name || "%",
                            unit_id: Unit_id || "",
                            assignee_id: Assignee_id || "",
                            assignee_name: Assignee_name || "",
                            unit_id: Unit_id || "",
                            totalPrice: TotalPrice || "",
                            work_done_progress: TotalWorkDone || 0,
                            total_work_progress: TotalWork || 100,
                            materials_issued: MaterialIssued || []
                        },
                        updateCallback: HandleTaskLogupdateCallback,
                        close: () => { setShowTaskLogpopup(false) }
                    }}
                />

                : ''}

            <Routes>
                <Route path='/linked-issue/:issueItemId/:issueActionType/*'
                    element={<AddIssuePopup
                        props={{
                            taskdetials: existItemData,
                            existItemData: selectedLinkedIssue || undefined,
                            updateCallback: HandleIssueUpdateCallback
                        }}
                    />}>
                </Route>
                <Route path='/linked-issue/:issueActionType/*'
                    element={<AddIssuePopup
                        props={{
                            taskdetials: existItemData,
                            existItemData: undefined,
                            updateCallback: HandleIssueUpdateCallback
                        }}
                    />}
                ></Route>
            </Routes>

            <form className="side-popup-container side-task-popup-container animateXRight animateXRightactive" onSubmit={HandleSaveDetials}>
                <div className="sidebar-task-popup-content">

                    <div className="task-content-header">
                        <div className="task-header-left">

                            <div className="task-header-name">
                                <TextInput
                                    props={{
                                        id: "task-item-name",
                                        value: Name,
                                        placeholder: '',
                                        setValue: (value) => {
                                            setName(value)
                                            setDetilsInputsChanged(true)
                                        },
                                        isIcon: false,
                                        isLabel: true,
                                        isRequired: true,
                                        label: "Task Name",
                                    }}
                                />
                            </div>
                            <div className="task-header-status-main">
                                <div
                                    className={`task-tabel-status task-tabel-status-${StatusEnem}`}
                                >{StatusLabel}</div>
                                <div className="task-status-progress">{parseInt(Math.round(TotalWorkDone))}/{parseInt(TotalWork)} {Unit_name} ({parseInt(Math.round(TotalWorkDonePercentage))}%)</div>
                            </div>
                        </div>
                        <div className="task-header-right">

                            <div
                                className="task-header-close"
                                onClick={(e) => HandleClose()}
                                dangerouslySetInnerHTML={{ __html: Icons.general.close }}
                            ></div>
                        </div>
                    </div>
                    <div className="task-toggle-section">
                        {
                            ToggleSectionItems?.map(item => (
                                <div
                                    className={`task-toggle-item ${item.id == SelectedToggleSection ? 'task-toggle-item-active' : ''}`}
                                    key={item.label.id}
                                    onClick={() => HandleToggleItemClick(item)}
                                >
                                    <div className="label">{item.label}</div>
                                </div>
                            ))
                        }

                    </div>

                    <div className="task-content-sections">
                        <div className="task-content-section" id='task-section-detials'>
                            <div className="task-section-header">
                                <div className="left">
                                    <div className="title">Detials</div>
                                </div>
                                <div className="right">
                                    <ShowToClient
                                        props={{
                                            value: ShowToClientValue,
                                            callback: (value) => {
                                                setDetilsInputsChanged(true)
                                                setShowToClientValue(value)
                                            }
                                        }}
                                    />
                                </div>
                            </div>
                            <div className="task-row-content">
                                <div className="task-row-item">
                                    <div className="label">PARENT TASK</div>
                                    <div className="input">
                                        <select
                                            type="text"
                                            placeholder=''
                                            value={ParentTask_id}
                                            onChange={(e) => HandleDetialsInput('parent_task_id', e)}
                                            required={false}
                                        >
                                            {
                                                [
                                                    {
                                                        value: '',
                                                        label: 'Select Parent Task',
                                                    },
                                                    ...ParentTaskOptions || []
                                                ]?.map(opt => (
                                                    <option key={opt.value} value={opt.value}>{opt.label}</option>
                                                ))
                                            }
                                        </select>
                                    </div>
                                </div>

                                <div className="task-row-item" >
                                    <div className="label">PROJECT PHASES</div>
                                    <div className="input">
                                        <MultiSelectInput
                                            props={{
                                                id: 'task-multi-select-input',
                                                placeholder: 'Select Phase',
                                                isRequired: false,
                                                value: SelectedPhases,
                                                options: ProjectPhases_Options,
                                                isIcon: false,
                                                avoidDuplicate: true,
                                                icon: '',
                                                isLabel: false,
                                                label: '',
                                                setValue: (value) => HandleSelectPhase(value),
                                            }}
                                        />

                                    </div>
                                </div>
                                <div className="task-row-item">
                                    <div className="label">PRIORITY</div>
                                    <div className="input">
                                        <select
                                            type="text"
                                            placeholder=''
                                            value={TaskPriority_id}
                                            onChange={(e) => HandleDetialsInput('priority_id', e)}
                                            required={false}
                                        >
                                            {
                                                [
                                                    {
                                                        value: '',
                                                        label: 'Select Priority',
                                                    },
                                                    ...TaskProrityOptions || []
                                                ]?.map(opt => (
                                                    <option key={opt.value} value={opt.value}>{opt.label}</option>
                                                ))
                                            }
                                        </select>
                                    </div>
                                </div>
                                <div className="task-row-item">
                                    <div className="label">WORK CATEGORY</div>
                                    <div className="input">
                                        <select
                                            type="text"
                                            placeholder=''
                                            value={WorkType_id}
                                            onChange={(e) => HandleDetialsInput('work_type_id', e)}
                                            required={false}
                                        >
                                            {
                                                [
                                                    {
                                                        value: '',
                                                        label: 'Select Category',
                                                    },
                                                    ...WorkTypeOptions || []
                                                ]?.map(opt => (
                                                    <option key={opt.value} value={opt.value}>{opt.label}</option>
                                                ))
                                            }
                                        </select>
                                    </div>
                                </div>
                                <div className="task-row-item">
                                    <div className="label">DURATION <span style={{ textTransform: 'lowercase', paddingLeft: '5px' }}> (days)</span></div>
                                    <div className="input">

                                        <input
                                            type="text"
                                            placeholder='Set Duration'
                                            value={Duration}
                                            onChange={(e) => HandleDetialsInput('duration', e)}
                                        />
                                    </div>
                                </div>
                                <div className="task-row-item">
                                    <div className="label">START DATE</div>
                                    <div className="input">

                                        <input
                                            type="date"
                                            placeholder='Enter start date'
                                            value={StartDate}
                                            onChange={(e) => HandleDetialsInput('start_date', e)}
                                        />
                                    </div>
                                </div>
                                <div className="task-row-item">
                                    <div className="label">END DATE</div>
                                    <div className="input">

                                        <input
                                            type="date"
                                            placeholder='Enter end date'
                                            value={EndDate}
                                            onChange={(e) => HandleDetialsInput('end_date', e)}
                                        />
                                    </div>
                                </div>

                                <div className="task-row-item">
                                    <div className="label">UNIT OF WORK UPDATE</div>
                                    <div className="input">
                                        <select
                                            type="text"
                                            placeholder=''
                                            value={Unit_id}
                                            onChange={(e) => HandleDetialsInput('unit_id', e)}
                                        >
                                            {
                                                [
                                                    {
                                                        value: '',
                                                        label: 'Select Unit',
                                                    },
                                                    ...UnitsOptions || []
                                                ]?.map(opt => (
                                                    <option key={opt.value} value={opt.value}>{opt.label}</option>
                                                ))
                                            }
                                        </select>
                                    </div>
                                </div>
                                <div className="task-row-item">
                                    <div className="label">TOTAL WORK ({Unit_name || '%'})</div>
                                    <div className="input">

                                        <input
                                            type="text"
                                            placeholder='Enter total work'
                                            value={TotalWork}
                                            onChange={(e) => HandleDetialsInput('total_work', e)}
                                        />
                                    </div>
                                </div>
                                <div className="task-row-item">
                                    <div className="label">ASSIGNED TO</div>
                                    <div className="input">
                                        <MultiSelectInput
                                            props={{
                                                id: 'assignee-multi-select-input',
                                                placeholder: 'Select Assignees',
                                                isRequired: true,
                                                value: SelectedAssignees,
                                                options: AssigneeOptions,
                                                isIcon: false,
                                                avoidDuplicate: true,
                                                icon: '',
                                                isLabel: false,
                                                label: 'Assignees',
                                                setValue: (value) => HandleSelectAssignee(value),
                                            }}
                                        />
                                    </div>
                                </div>
                                <div className="task-row-item">
                                    <div className="label">TOTAL PRICE</div>
                                    <div className="input">
                                        <input
                                            type="text"
                                            placeholder='Add Total Price'
                                            value={TotalPrice}
                                            onChange={(e) => HandleDetialsInput('total_price', e)}
                                        />
                                    </div>
                                </div>
                                <div className="task-row-item">
                                    <div className="label">DESCRIPTION</div>
                                    <div className="input">
                                        <input
                                            type="text"
                                            placeholder='Enter Description'
                                            value={Description}
                                            onChange={(e) => HandleDetialsInput('description', e)}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="task-content-section" id='task-section-timeline'>
                            <div className="task-section-header">
                                <div className="left">
                                    <div className="title">Timeline</div>
                                </div>
                            </div>
                            <div className="task-timeline-content">

                                {TimelineLogs.sort((a, b) => b.ms_time - a.ms_time)?.map((log, idx) => (

                                    log.type == 'created_at' ?
                                        <div
                                            className="task-timeline-item task-timeline-item-created_at"
                                        >
                                            <div className="icon"
                                                dangerouslySetInnerHTML={{ __html: Icons.general.created_at }}
                                            ></div>
                                            <div className="detials">
                                                <div className="title">Task Created</div>
                                                <div className="time">On {log.time} by {log.added_by}</div>
                                            </div>
                                        </div>
                                        : log.type == 'progress' ?
                                            <div className="task-timeline-item task-timeline-item-progress">
                                                <div className="timeline-progress-content">

                                                    <div className="icon"
                                                        dangerouslySetInnerHTML={{ __html: log.update_type == '1' ? Icons.general.progress : Icons.general.no_progress }}
                                                    ></div>
                                                    <div className="detials" >
                                                        <div className="detials" onClick={() => HandleExpandProgress(log.id, idx, log.expanded)}>
                                                            <div className="title">
                                                                {log.update_type == '1' ?
                                                                    `Progress Update - ${parseInt(log.progress_value)} ${log.unit_name} (${parseFloat(log.progress_percentage).toFixed(1)} %)`
                                                                    : 'No Progress'
                                                                }

                                                            </div>
                                                            <div className="time">On {log.time} by {log.added_by}</div>

                                                        </div>

                                                        <div className={`timeline-progress-detials ${log.expanded ? 'timeline-progress-detials-active' : ''}`}>
                                                            <div className="timeline-progress-detials-item">
                                                                <div className="label">Visible to Client</div>
                                                                <div className="value">{log.show_to_client == '1' ? 'Yes' : 'No'}</div>
                                                            </div>
                                                            <div className="timeline-progress-detials-item">
                                                                <div className="label">Work Done</div>
                                                                <div className="value">{log.progress_value || 0}/{log.total_progress_value} {log.unit_name}</div>
                                                            </div>

                                                            {log.total_workers ?
                                                                <div className="timeline-progress-detials-item">
                                                                    <div className="label">Total Workers</div>
                                                                    <div className="value">{log.total_workers || 0}</div>
                                                                </div>
                                                                : ''}

                                                            {Array.isArray(log.worker_attendance) && log.worker_attendance.length ?
                                                                <>
                                                                    {log.worker_attendance.map((worker, idx) => (
                                                                        <div className="timeline-progress-detials-item">
                                                                            <div className="label">{worker.worktype_name || ""}</div>
                                                                            <div className="value">{worker.count || ""} Worker</div>
                                                                        </div>
                                                                    ))}
                                                                </>
                                                                : ''}
                                                            {Array.isArray(log.materials_used) && log.materials_used.length ?
                                                                <>
                                                                    {log.materials_used.map((material, idx) => (
                                                                        <div className="timeline-progress-detials-item">
                                                                            <div className="label">{material.material_name || ""}</div>
                                                                            <div className="value">{material.quantity || ""} {material.unit_name || ""}</div>
                                                                        </div>
                                                                    ))}
                                                                </>
                                                                : ''}
                                                            {log.remarks ?
                                                                <div className="timeline-progress-detials-item">
                                                                    <div className="label">Remark</div>
                                                                    <div className="value">{log.remarks || ""}</div>
                                                                </div>
                                                                : ''}

                                                        </div>
                                                        <div className={`timeline-progress-detials ${log.expanded ? 'timeline-progress-detials-active' : ''}`}>

                                                            {Array.isArray(log.attachments) ? log.attachments.map((attachment, idx) => (

                                                                <div className="timeline-progress-detials-img-item"
                                                                    key={`task-attachment-${idx}`}
                                                                    onClick={() => HandleSelectPreviewImage(attachment)}
                                                                >
                                                                    <img src={attachment.file_url} />
                                                                </div>
                                                            ))
                                                                : ''}


                                                        </div>
                                                    </div>
                                                    <div
                                                        className={`delete-action-icon`}
                                                        onClick={() => HandleDeleteProgress(log.id, idx)}
                                                        dangerouslySetInnerHTML={{ __html: Icons.general.delete_btn }}
                                                    ></div>
                                                    <div
                                                        className="dropdown-icon"
                                                        style={{ transition: 'all .25s', transform: `${log.expanded ? 'rotate(180deg)' : 'rotate(0deg)'}` }}
                                                        dangerouslySetInnerHTML={{ __html: Icons.general.dropdown_arrow }}
                                                    ></div>
                                                </div>


                                            </div>
                                            : ''
                                ))}
                            </div>
                        </div>
                        <div className="task-content-section" id='task-section-photos'>
                            <div className="task-section-header">
                                <div className="left">
                                    <div className="title">Photos</div>
                                </div>
                            </div>
                            <div className="task-timeline-content">
                                {!Photos.length ?
                                    <div className="task-timeline-nocontent">
                                        <div className="label">No photos have been added to this task!</div>
                                        <div className="desc">Add a task log to add photos.</div>
                                    </div>
                                    : ''}
                            </div>
                        </div>
                        <div className="task-content-section" id='task-section-material_issued'>
                            <div className="task-section-header">
                                <div className="left">
                                    <div className="title">Material Issued</div>
                                </div>
                            </div>
                            <div className="task-timeline-content">

                                {!MaterialIssued.length ?
                                    <div className="task-timeline-nocontent">
                                        <div className="label">No materials have been added to this task!</div>
                                        <div className="desc">Add a task log to add materials.</div>

                                    </div>
                                    : ''}

                                {MaterialIssued?.map((item, idx) => (

                                    <div className="task-timeline-item task-timeline-item-progress">
                                        <div className="timeline-progress-content">

                                            <div className="icon"
                                                dangerouslySetInnerHTML={{ __html: Icons.general.material }}
                                            ></div>
                                            <div className="detials" onClick={() => HandleMaterialExpandProgress(item.id, idx, item.expanded)}>
                                                <div className="title">{item.material_name}</div>
                                                <div className="time">On {item.time} by {item.added_by}</div>
                                                <div className={`timeline-progress-detials ${item.expanded ? 'timeline-progress-detials-active' : ''}`}>
                                                    <div className="timeline-progress-detials-item">
                                                        <div className="label">Used Qty</div>
                                                        <div className="value">{item.used_quantity || 0}/{item.quantity} {item.unit_name}</div>
                                                    </div>
                                                    <div className="timeline-progress-detials-item">
                                                        <div className="label">Balance Qty</div>
                                                        <div className="value">{item.balance_quantity || 0}</div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div
                                                className="dropdown-icon"
                                                style={{ transition: 'all .25s', transform: `${item.expanded ? 'rotate(180deg)' : 'rotate(0deg)'}` }}
                                                dangerouslySetInnerHTML={{ __html: Icons.general.dropdown_arrow }}
                                            ></div>
                                        </div>


                                    </div>
                                ))}
                            </div>
                        </div>
                        <div className="task-content-section" id='task-section-issues'>
                            <div className="task-section-header">
                                <div className="left">
                                    <div className="title">Issues</div>
                                </div>
                                <div className="right">
                                    <div className="action-btn"
                                        onClick={() => HandleAddNewIssue()}
                                    >
                                        <div
                                            className="icon"
                                            dangerouslySetInnerHTML={{ __html: Icons.general.add_btn }}
                                        ></div>
                                        <div className="label">Add</div>
                                    </div>
                                </div>
                            </div>

                            {!LinkedIssues.length ?
                                <div className="task-timeline-nocontent">
                                    <div className="label">No Issues in this task!</div>
                                    <div className="action-btn" onClick={() => HandleAddNewIssue()}>
                                        Add Issue
                                    </div>
                                </div>
                                : ''}
                            <div className="task-timeline-content">

                                {LinkedIssues.sort((a, b) => a.status - b.status)?.map((issue, idx) => (


                                    <div key={issue.id} className="task-timeline-item task-timeline-item-progress">
                                        <div className="timeline-progress-content">

                                            <div className="detials" onClick={() => HandleIssueExpandProgress(issue.id, idx, issue.expanded)}>
                                                <div className="head">
                                                    <div className="title">
                                                        {issue.reason}
                                                    </div>
                                                    <div className="issue-type">{issue.issue_tag}</div>
                                                    <div className={`issue-status ${issue.status == '1' ? 'issue-status-close' : 'issue-status-open'}`}>{issue.status == '1' ? 'Closed' : 'Open'}</div>
                                                </div>
                                                <div className="time">On {Utils.getLocalFullDateLabel(issue.created_at)} by {issue.created_by_name}</div>
                                                <div className={`timeline-progress-detials ${issue.expanded ? 'timeline-progress-detials-active' : ''}`}>
                                                    <div className="timeline-progress-detials-item">
                                                        <div className="label">ASSIGNED TO</div>
                                                        <div className="value">{issue.assignee_name || "N/A"}</div>
                                                    </div>
                                                    <div className="timeline-progress-detials-item">
                                                        <div className="label">END DATE</div>
                                                        <div className="value">{issue.due_date || "N/A"}</div>
                                                    </div>
                                                    <div className="timeline-progress-detials-item" style={{ width: '100%' }}>
                                                        <div className="label"></div>
                                                        <div className="action-btn" onClick={() => HandleOpenIssue(issue, idx)}>View Issue</div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="right">
                                                {issue.status == '0' && issue.expanded ?
                                                    <div
                                                        className="dropdown-icon action-btn"
                                                        style={{ transition: 'all .25s', right: '40px' }}
                                                        onClick={() => HandleCloseIssue(issue.id)}
                                                    >CLOSE ISSUE</div>
                                                    : ''}
                                                <div
                                                    className="dropdown-icon"
                                                    style={{ transition: 'all .25s', transform: `${issue.expanded ? 'rotate(180deg)' : 'rotate(0deg)'}` }}
                                                    dangerouslySetInnerHTML={{ __html: Icons.general.dropdown_arrow }}
                                                ></div>
                                            </div>

                                        </div>


                                    </div>
                                ))}
                            </div>
                        </div>
                        <div className="task-content-section" id='task-section-comments'>
                            <div className="task-section-header">
                                <div className="left">
                                    <div className="title">Comments</div>
                                </div>
                            </div>

                            <div className="comments-section-main">
                                <div className="comments-section-items">

                                    {CommentsItems.map((item, idx) => (

                                        <div
                                            className="comments-item-main"
                                            key={`comments-item-${item.id}`}
                                            id={`comments-item-${item.id}`}
                                        >
                                            <div className="comments-item-usericon">
                                                {item.created_by_name.charAt(0)}
                                            </div>
                                            <div className="comments-item-detials">
                                                <div className="detials-head">
                                                    <div className="left">
                                                        <div className="name">{item.created_by_name}</div>
                                                        <div className="time">{Utils.getLocalFullDateLabel(item.created_at)}</div>
                                                    </div>
                                                    <div className="right">
                                                        <div
                                                            className="delete-icon"
                                                            onClick={() => HandleDeleteComment(item.id, idx)}
                                                            dangerouslySetInnerHTML={{ __html: Icons.general.delete_btn }}></div>
                                                    </div>
                                                </div>
                                                <div className="detials-message">{item.message}</div>
                                            </div>
                                        </div>


                                    ))}


                                    <div className="comments-input-main">
                                        <div className="comments-input-usericon">
                                            RT
                                        </div>
                                        <div className="comments-input comments-input-active">
                                            <div className="comments-input-textarea">
                                                <textarea
                                                    placeholder='Add a comment...'
                                                    value={CommentsInput}
                                                    onChange={(e) => setCommentsInput(e.target.value)}
                                                    style={{ minHeight: '50px' }}
                                                ></textarea>
                                            </div>
                                            <div className={`comments-input-controls ${CommentsInput.length ? `comments-input-controls-active` : ''}`}>
                                                <div
                                                    className={`comments-input-control-sendmsg`}
                                                    onClick={() => HandleAddComment()}
                                                >
                                                    <div className="icon" dangerouslySetInnerHTML={{ __html: Icons.general.send_message }}></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>

                    </div>

                </div>

                <div className="sidebar-popup-footer task-sidebar-footer">
                    <div className="footer-item action-items">
                        {DetilsInputsChanged ?
                            <>
                                <div
                                    className="action-btn discard-changes-btn"
                                    onClick={() => HandleCancelDetials()}

                                >
                                    <div className="label">Cancel</div>
                                </div>
                                <button
                                    className="action-btn save-changes-btn"
                                    type='submit'
                                >
                                    <div className="label">Save</div>
                                </button>
                            </>
                            : ''}

                        <div
                            className="action-btn"
                            onClick={() => HandleAddTaskLog()}
                        >
                            <div className="icon" dangerouslySetInnerHTML={{ __html: Icons.general.add_btn }}></div>
                            <div className="label">ADD TASK LOG</div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    )

}

const AddTaskItemPopup = ({ props }) => {

    const { existItemData, updateCallback } = props

    const { projectId, itemType, itemId, actionType } = useParams()

    const [isLoading, setIsLoading] = useState(false)
    const [warningAlert, setWarningAlert] = useState(false)
    const [warningAlertType, setWarningAlertType] = useState('warning')
    const [apiFailedMessage, setApiFailedMessage] = useState("Error in Server")

    const [currency_type, setCurrency_type] = useState('₹')

    const navigate = useNavigate()
    const store = useSelector((store) => store)
    const dispatch = useDispatch()
    const { updateState } = new UserAction


    const { isAdmin, menu_permission } = useMenuAuth({ menu: 'project_management--tasks', feature_key: 'task_management' })
    const location = useLocation()

    const [selected_view_settings_value, setSelected_view_settings_value] = useState('')
    const [has_view_access, setHas_view_access] = useState(false)
    const [has_add_access, setHas_add_access] = useState(false)
    const [has_edit_access, setHas_edit_access] = useState(false)
    const [has_delete_access, setHas_delete_access] = useState(false)

    useEffect(() => {

        if (isAdmin) {

            setHas_view_access(true)
            setHas_add_access(true)
            setHas_edit_access(true)
            setHas_delete_access(true)
        }

        else if (menu_permission) {

            if (
                !menu_permission._view
                &&
                (!menu_permission.selected_view_settings_value
                    || menu_permission.selected_view_settings_value == 'no_access')
            ) {
                navigate(`${ReverseProxy['proxyUrl']}/quick-menu`, { state: { from: location }, replace: true })
            }

            if (actionType == 'add' && menu_permission._create == '0') navigate(`${ReverseProxy['proxyUrl']}/builder/projects`, { state: { from: location }, replace: true })
            if (actionType == 'edit' && menu_permission._edit == '0') navigate(`${ReverseProxy['proxyUrl']}/builder/projects`, { state: { from: location }, replace: true })

            setHas_view_access(menu_permission._view == '1')
            setHas_add_access(menu_permission._create == '1')
            setHas_edit_access(menu_permission._edit == '1')
            setHas_delete_access(menu_permission._delete == '1')

            setSelected_view_settings_value(menu_permission.selected_view_settings_value)

        }

    }, [store])

    return (

        <>

            {isLoading ?
                <Loading
                    props={{
                        isMainLogo: false,
                        isLabel: true
                    }} />

                : null}

            {warningAlert ?
                <SystemToastPopup
                    props={{
                        type: warningAlertType,
                        message: apiFailedMessage || "Error in Tasks",
                        callback: (confirmation) => setWarningAlert(false)
                    }} />

                : null}

            {itemType == 'parent_task'
                ?
                actionType == 'add' ? <CreateParentTask props={{ updateCallback }} />
                    : actionType == 'edit' ? <CreateParentTask props={{ existItemData, updateCallback }} /> : ''
                :
                actionType == 'add' ? <CreateTask props={{ updateCallback }} />
                    : actionType == 'edit' ? <UpdateTask props={{ existItemData, updateCallback }} /> : ''

            }
        </>

    )

}

export default AddTaskItemPopup;