

import React, { useEffect, useState, useRef } from 'react'
import { Route, Routes, BrowserRouter, Link, useLocation, Navigate, Outlet, useNavigate, useParams } from 'react-router-dom';
import store from '../../redux/store'
import StoreHandler from '../../redux/StoreHandler'
import { useSelector, useDispatch } from 'react-redux'
import UserAction from '../../redux/action/userAction'

import ReverseProxy from '../../config/reverseProxy'
import CheckListsHandler from '../../Handlers/CheckLists/CheckLists';
import Icons from '../../assets/Icons'
import Images from '../../assets/Images'
import { SearcBarInput, TextInput, SelectInput, RadioInput } from '../../components/Inputs'
import Utils from '../../utils'
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

import AlertPopup from '../../components/AlertPopup'
import SystemToastPopup from '../../components/SystemToastPopup'
import Loading from '../../components/Loading'
import NoDataFound from '../../components/NoDataFound'

import { useAuth, useMenuAuth } from '../../hooks/useAuth'

const CheckLists = () => {

    const store = useSelector((store) => store)
    const dispatch = useDispatch()
    const { updateState } = new UserAction

    const navigate = useNavigate()

    const { projectId } = useParams()

    const { isAdmin, menu_permission } = useMenuAuth({ menu: 'safety--checklists', feature_key: 'checklists' })

    const location = useLocation()

    const [selected_view_settings_value, setSelected_view_settings_value] = useState('')
    const [has_view_access, setHas_view_access] = useState(false)
    const [has_add_access, setHas_add_access] = useState(false)
    const [has_edit_access, setHas_edit_access] = useState(false)
    const [has_delete_access, setHas_delete_access] = useState(false)

    useEffect(() => {

        if (isAdmin) {

            setHas_view_access(true)
            setHas_add_access(true)
            setHas_edit_access(true)
            setHas_delete_access(true)
        }

        else if (menu_permission) {

            if (
                !menu_permission._view
                &&
                (!menu_permission.selected_view_settings_value
                    || menu_permission.selected_view_settings_value == 'no_access')
            ) {
                navigate(`${ReverseProxy['proxyUrl']}/quick-menu`, { state: { from: location }, replace: true })
            }

            setHas_view_access(menu_permission._view == '1')
            setHas_add_access(menu_permission._create == '1')
            setHas_edit_access(menu_permission._edit == '1')
            setHas_delete_access(menu_permission._delete == '1')

            setSelected_view_settings_value(menu_permission.selected_view_settings_value)

        }

    }, [store])


    const storeHandler = new StoreHandler({
        dataStack: [`project_client-${projectId}`],
        store: store,
        dispatch: dispatch,
        updateState: updateState,
    })

    const LoadStoreData = async () => {
        // setIsLoading(true)
        let resolved = await storeHandler.init()
        // setIsLoading(false)
    }

    const checkListsHandler = new CheckListsHandler()

    const [isLoading, setIsLoading] = useState(false)
    const [warningAlert, setWarningAlert] = useState(false)
    const [warningAlertType, setWarningAlertType] = useState('warning')
    const [apiFailedMessage, setApiFailedMessage] = useState('Error in Projects')


    const [deleteItemId, setDeleteItem] = useState(null)
    const [deleteItemType, setDeleteItemType] = useState(null)
    const [deleteItemAlert, setDeleteItemAlert] = useState(false)

    const [ChecklistItems, setChecklistItems] = useState([])

    const [SeachInputValue, setSeachInputValue] = useState('')

    const [SelectedTypeResultSection, setSelectedTypeResultSection] = useState('1')


    const [ScheduleOptions, setScheduleOptions] = useState([
        {
            value: '1',
            label: 'Daily Basics',
        },
        {
            value: '2',
            label: 'Weekly Basics',
        },
        {
            value: '3',
            label: 'Monthly Basics',
        },
        {
            value: '4',
            label: 'Incident Based',
        },
    ])

    const [Status_Options, setStatus_Options] = useState([
        {
            value: '1',
            label: 'Pending',
            colorcode: '#0062ff'
        },
        {
            value: '2',
            label: 'Completed',
            colorcode: '#03ad00'
        }
    ])

    const getStatusValues = (value) => {

        if (!value) return { label: '', colorcode: '' }

        let status = Status_Options.filter(s => s.value == value)

        return status[0]

    }




    const getChecklistItems = async () => {


        let filter = {
            project_id: projectId,
            minimal_data: true
        }

        let response = await checkListsHandler.getChecklistTemplatesHandler(filter)

        if (response && response.success) {

            let checkLists = response.data || []

            checkLists = checkLists.map((checklist) => {

                checklist.status = 2

                return checklist
            })

            setChecklistItems(checkLists || [])

        } else {
            setWarningAlert(true)
            setWarningAlertType('error')
            setApiFailedMessage(`Error in Phases, Please try again!`)
        }
    }


    const HandleStatusResultSection = (section) => {
        setSelectedTypeResultSection(section)
    }

    const HandleViewChecklist = (id) => {

        const currentDate = Utils._getLocalDate()

        navigate(`/builder/projects/${projectId}/checklists/${id}/view?date=${currentDate}`)
    }


    const RenderChecklistItemsResult = (Resultitems, group, search_values) => {

        if (search_values) {
            Resultitems = Resultitems.filter(r => r.name.toLowerCase().includes(search_values.toLowerCase()))
        }

        Resultitems = Resultitems.filter(r => group ? r.schedule_type == group : true)

        return (
            <>
                {Resultitems.length ?
                    <div className="surveys-content-items">
                        {Resultitems.map((item, i) => (
                            <div className="surveys-content-item"
                                key={`surveys-content-item-${item.sno}`}
                                onClick={(e) => HandleViewChecklist(item.sno)}
                            >
                                <div className="item-details">
                                    <div className="title">{item.name}</div>
                                    {item.status ? <div className="status" style={{ background: getStatusValues(item.status).colorcode }}>{getStatusValues(item.status).label}</div> : ''}
                                    <div className="desc">{item.description}</div>
                                </div>

                            </div>
                        ))}

                    </div>
                    : <NoDataFound label={'No Checklists Found'} />}
            </>
        )
    }

    useEffect(() => {
        // LoadStoreData()
        getChecklistItems()
    }, [])



    return (
        <>


            {isLoading ?

                <Loading
                    props={{
                        isMainLogo: false,
                        isLabel: true
                    }} />

                : null}

            {warningAlert ?

                <SystemToastPopup
                    props={{
                        type: warningAlertType,
                        message: apiFailedMessage || "Error in Projects",
                        callback: (confirmation) => setWarningAlert(false)
                    }} />
                : null}



            <div className="project-surveys-main">

                <div className="project-surveys-header">
                    <div className="surveys-header-left">
                        <div className="title">Checklists</div>
                    </div>
                    <div className="surveys-header-right">
                        <div className="action-search-filter">
                            <SearcBarInput
                                props={{
                                    id: 'search',
                                    value: SeachInputValue,
                                    placeholder: "Search",
                                    setValue: (val) => setSeachInputValue(val),
                                    isIcon: true,
                                    isLeft: true,
                                    icon: Icons.general.search
                                }}
                            />
                        </div>

                    </div>
                </div>
                <div className="project-surveys-filters-main">
                    <div className="project-surveys-filters">
                        <div className="status_filter-items">
                            {ScheduleOptions.map((opt, i) => (
                                <div
                                    key={`project-status-filter-${opt.value}`}
                                    className={`status_filter-item ${SelectedTypeResultSection == opt.value ? 'status_filter-item-active' : ''}`}
                                    onClick={(e) => HandleStatusResultSection(opt.value)}
                                >
                                    <div className="label">{opt.label}</div>
                                </div>
                            ))}
                        </div>
                    </div>

                </div>
                <div className="project-surveys-content">
                    {RenderChecklistItemsResult(ChecklistItems, SelectedTypeResultSection == 'all' ? '' : SelectedTypeResultSection, SeachInputValue)}

                </div>

            </div>
        </>
    )
}

export default CheckLists;