

import React, { useEffect, useState, useRef, useCallback, useMemo, StrictMode } from 'react'
import { Route, Routes, BrowserRouter, Link, useLocation, Navigate, Outlet, useNavigate, useParams } from 'react-router-dom';
import store from '../../redux/store'
import StoreHandler from '../../redux/StoreHandler'
import { useSelector, useDispatch } from 'react-redux'
import UserAction from '../../redux/action/userAction'

import ReverseProxy from '../../config/reverseProxy'

import ChangeOrdersHandler from '../../Handlers/ChangeOrders/ChangeOrders';
import Icons from '../../assets/Icons'
import Images from '../../assets/Images'
import { CostInput, TextInput, SelectInput, DateTimeInput, RadioInput } from '../../components/Inputs'
import Utils from '../../utils'
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

import { PDFExport, savePDF } from '@progress/kendo-react-pdf';



const PreviewChangeOrders = ({ props }) => {

    const { projectId, changeOrderId } = useParams();

    const { menuType = 'create', close, RecordLoaded, detials, saveCallback } = props

    const [project_id, setProject_id] = useState('')
    const [project_name, setProject_name] = useState('')
    const [estimate_id, setEstimate_id] = useState('')

    const [isLoading, setIsLoading] = useState(false)
    const [warningAlert, setWarningAlert] = useState(false)
    const [warningAlertType, setWarningAlertType] = useState('warning')
    const [apiFailedMessage, setApiFailedMessage] = useState("Error in Server")

    const navigate = useNavigate()
    const store = useSelector((store) => store)
    const dispatch = useDispatch()
    const { updateState } = new UserAction

    const storeHandler = new StoreHandler({
        dataStack: ['company', 'terms_conditions', 'clients', 'field_settings', 'finance_settings', `project-${projectId}`],
        store: store,
        dispatch: dispatch,
        updateState: updateState,
    })

    const LoadStoreData = async () => {
        setIsLoading(true)
        let resolved = await storeHandler.init()
        setIsLoading(false)
    }
    const pdfRef = useRef()
    const changeOrdersHandler = new ChangeOrdersHandler()

    const [unitsOptions, setUnitsOptions] = useState([])
    const [currency_type, setCurrency_type] = useState('₹')

    const CompanyProfile = store.user.company || undefined
    const isCompany = Object.keys(CompanyProfile).length > 0

    const [ShowWatermark, setShowWatermark] = useState(false)

    const [companyImg, setCompanyImg] = useState('')
    const [companyName, setCompanyName] = useState('N/A')
    const [projectName, setProjectName] = useState('N/A')
    const [projectArea, setProjectArea] = useState('N/A')
    const [projectID, setProjectID] = useState('N/A')
    const [clientId, setClientId] = useState('')
    const [clientName, setClientName] = useState('N/A')
    const [clientEmail, setClientEmail] = useState('N/A')
    const [clientNumber, setClientNumber] = useState('N/A')
    const [projectAddress, setProjectAddress] = useState('N/A')
    const [dateFormat, setDateFormat] = useState('DD/MM/YYYY')

    const [id, setId] = useState('')
    const [name, setName] = useState('')
    const [ChangeOrderID, setChangeOrderID] = useState('')
    const [status, setStatus] = useState('')
    const [Description, setDescription] = useState('')

    const [Date, setDate] = useState('')
    const [DueDate, setDueDate] = useState('')

    const [ChangeOrderTotal, setChangeOrderTotal] = useState(0)

    const [termsConditionValue, setTermsConditionValue] = useState("")

    const [StatusOptions, setStatusOptions] = useState([
        {
            value: 0,
            label: "Pending",
            isDefault: false
        },
        {
            value: 1,
            label: "Approved",
            isDefault: false
        },
        {
            value: 2,
            label: "Rejected",
            isDefault: false
        },
    ])

    const [Phase_id, setPhase_id] = useState('')
    const [Phase_name, setPhase_name] = useState('')
    const [Client_id, setClient_id] = useState('')
    const [Client_name, setClient_name] = useState('')



    const HandleDownloadBtn = () => {

        pdfRef.current.save();
        // savePDF(pdfRef.current, { paperSize: "A4" });
    }
    const HandleSaveBtn = () => {

        saveCallback()
    }

    const getNextChangeOrdersID = async () => {

        let response = await changeOrdersHandler.getNextChangeOrdersIDHandler()

        if (response && response.success) {

            let { changeOrderId } = response.data

            if (changeOrderId) setChangeOrderID(changeOrderId)

        } else {
            setWarningAlert(true)
            setWarningAlertType('error')
            setApiFailedMessage(`Error in next change order id`)
        }
    }


    const LoadProposal = async () => {


        let filter = {
            changeOrderId: changeOrderId,
            projectId: projectId,
        }

        let _detials = undefined

        if (RecordLoaded && detials && Object.keys(detials).length) {

            _detials = detials

        } else if (changeOrderId) {

            setIsLoading(true)

            let response = await changeOrdersHandler.getChangeOrderssHandler(filter)
            setIsLoading(false)

            if (response.success) {
                let { data } = response

                if (!Array.isArray(data) || !data.length) navigate(`/builder/projects/${projectId}/change_orders`)

                _detials = data[0]
            }

        }

        if (_detials && Object.keys(_detials).length) {

            let {
                id,
                name,
                changeOrderId,
                projectId,
                phase_id,
                phase_name,
                client_id,
                client_name,
                date,
                due_date,
                status,
                total,

                description,
                terms_condition_id,
                terms_condition,
            } = _detials


            setId(id)
            setChangeOrderID(changeOrderId)
            setName(name)
            setPhase_id(phase_id)
            setPhase_name(phase_name)
            setClient_id(client_id)
            setClient_name(client_name)

            if (status != undefined) {

                let _status = StatusOptions.filter(opt => opt.value == status)[0]
                if (_status) setStatus(_status.label)
            }

            setDescription(description)

            setTermsConditionValue(terms_condition)

            setDate(date)
            setDueDate(due_date)

            setChangeOrderTotal(total)


        }

    }

    useEffect(() => {

        if (menuType == 'create') {
            getNextChangeOrdersID()
        }
        else {
            LoadProposal()
        }

        LoadStoreData()

    }, [])

    useEffect(() => {

        if (store && store.user) {

            let { company, clients, settings, selected_project } = store.user

            if (selected_project) {
                let { name, status, area, unit_id, unit_name, type_id, type_name, address, client_id, client_name } = selected_project



                setProjectName(name || 'N/A')
                setProjectArea(`${area}${unit_name || ""}` || 'N/A')
                setProjectID(projectId)
                setProjectAddress(address || "N/A")
                setClientName(client_name || "N/A")


                if (clients && Array.isArray(clients) && clients.length) {

                    let _client = clients.filter(opt => opt.id == client_id)[0]

                    if (_client) {
                        let { name, email, phone } = _client

                        setClientName(name || 'N/A')
                        setClientEmail(email || 'N/A')
                        setClientNumber(phone || 'N/A')
                    }
                }
            }

            if (company) {
                let { name, logo_img, currency_setting, date_format_setting, timezone, temperature_setting } = company

                const profileImgURL = logo_img ? `data:image/png;base64,${logo_img}` : ''

                setCompanyImg(profileImgURL)
                setCompanyName(name || 'N/A')
                setDateFormat(date_format_setting || 'DD/MM/YYYY')
                if (currency_setting) setCurrency_type(currency_setting)
            }


            if (settings && Array.isArray(settings.field_settings) && settings.field_settings.length) {

                let { project_statuses, project_types, units } = settings.field_settings[0]

                units = units.filter(opt => opt.isActive).map((opt) => {
                    return {
                        value: opt.id,
                        label: opt.name,
                        isDefault: opt.isDefault || false
                    }
                })

                setUnitsOptions(units || [])

            }

        }

    }, [store])




    const HandleCancel = async (e) => {

        close()
    }


    return (
        <>
            <div className="popup-container-main popup-container-center">
                <div className="popup-block-ui"></div>
                <div className="side-popup-container preview-popup-container">

                    <div className="side-popup-header">
                        <div></div>
                        <div className="header-item-select">
                            <div className="header-item-select-content">
                                <div className="label">Web Preview</div>
                                <div className="description">This is what client will see when you send the change order</div>
                            </div>
                        </div>
                        <div
                            className="header-item-close"
                            onClick={(e) => HandleCancel()}
                            dangerouslySetInnerHTML={{ __html: Icons.general.close }}
                        ></div>
                    </div>

                    <div className="sidebar-popup-content">
                        <div className="project-create_client_invoice-main" id='project-create_client_invoice-main'>

                            <div className="create_client_invoice-panel-content">
                                <PDFExport
                                    ref={pdfRef}
                                    author='CivilATOR'
                                    fileName={`${String(projectName).split(' ').join('_')}_ChangeOrder_${Utils.getLocalFullDateBYFormat(null, '_', dateFormat)}`}
                                    margin={5}
                                    paperSize="A4"
                                    scale={0.6}
                                >
                                    <div className="panel-content-sections">
                                        <div className="create-proposal-content-main">

                                            <div className="create-proposal-sections">

                                                <div className="preview-header-section">
                                                    <div className="header-section-top">
                                                        <div className="company-detials">
                                                            <div className="logo">
                                                                <img src={companyImg || Images.User} />
                                                            </div>
                                                            <div className="title">{companyName}</div>
                                                        </div>
                                                        <div className="detials">
                                                            <div className="detials-item" >
                                                                <div className="label">Status</div>
                                                                <div className="value">{status}</div>
                                                            </div>
                                                            <div className="detials-item">
                                                                <div className="label">Project ID</div>
                                                                <div className="value">{projectId}</div>
                                                            </div>
                                                            <div className="detials-item">
                                                                <div className="label">Change Order ID</div>
                                                                <div className="value">{ChangeOrderID}</div>
                                                            </div>

                                                        </div>
                                                    </div>
                                                    <div className="header-section-detials">
                                                        <div className="title">Change Order Information</div>
                                                        <div className="detials">
                                                            <div className="detials-item">
                                                                <div className="label">Name</div>
                                                                <div className="value">{name}</div>
                                                            </div>
                                                            <div className="detials-item">
                                                                <div className="label">Created Date</div>
                                                                <div className="value">{Date}</div>
                                                            </div>

                                                            <div className="detials-item">
                                                                <div className="label">Due Date</div>
                                                                <div className="value">{DueDate}</div>
                                                            </div>
                                                            <div className="detials-item">
                                                                <div className="label">Project Name</div>
                                                                <div className="value">{projectName}</div>
                                                            </div>
                                                            <div className="detials-item">
                                                                <div className="label">Project Phase</div>
                                                                <div className="value">{Phase_name}</div>
                                                            </div>

                                                            <div className="detials-item">
                                                                <div className="label">Phase Client</div>
                                                                <div className="value">{Client_name}</div>
                                                            </div>

                                                            <div className="detials-item">
                                                                <div className="label">Project Address</div>
                                                                <div className="value">{projectAddress}</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>



                                                {Description ?
                                                    <div
                                                        className="create-proposal-section"
                                                        id={`create-proposal-description`}>

                                                        <div className={`createproposal-description-main`}>
                                                            <div className="head">
                                                                <div className="label">Description</div>
                                                            </div>
                                                            <div className="description-result" dangerouslySetInnerHTML={{ __html: Description }}></div>
                                                        </div>

                                                    </div>
                                                    : ''}



                                                <div className="create-proposal-section" id={`create-proposal-summary`}>
                                                    <div className="createproject-section proposal-left-section">
                                                        <div className="proposal-summary-totals proposal-summary-title">
                                                            <div className="title">Summary</div>
                                                        </div>
                                                        <div className="proposal-summary-totals proposal-summary-items">

                                                            <div className="proposal-summary-item">
                                                                <div className="proposal-summary-item-content">

                                                                    <div className="item" style={{ fontSize: '24px' }}>
                                                                        <div className="title">Amount</div>
                                                                        <div className="quantity"></div>
                                                                        <div className="amount">
                                                                            {currency_type == '₹' ?
                                                                                <span
                                                                                    className='proposal-currency-small'
                                                                                    dangerouslySetInnerHTML={{ __html: Icons.general.inr }}
                                                                                ></span>
                                                                                : currency_type} {parseFloat(ChangeOrderTotal || 0).toFixed(2)}
                                                                        </div>
                                                                    </div>

                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                {termsConditionValue ?
                                                    <div
                                                        className="create-proposal-section"
                                                        id={`create-proposal-terms_conditions`}>

                                                        <div className={`createproposal-description-main`}>
                                                            <div className="head">
                                                                <div className="label">Terms and conditions</div>
                                                            </div>
                                                            <div className="terms_conditions-result" dangerouslySetInnerHTML={{ __html: termsConditionValue }}></div>
                                                        </div>

                                                    </div>
                                                    : ''}

                                            </div>
                                        </div>
                                        {ShowWatermark ?
                                            <div className="proposal-product-watermark">
                                                <div className="watermark">
                                                    <div className="label">Powered by</div>
                                                    <img src={Images.logo} className='img' />
                                                </div>
                                            </div>
                                            : ''}
                                    </div>
                                </PDFExport>
                            </div>
                        </div>
                    </div>
                    <div className="sidebar-popup-footer">
                        <div className="footer-item action-items">
                            <div className="action-preview">
                            </div>
                            <div className='action-btns'>
                                <div
                                    className="action-btn button-download"
                                    onClick={(e) => HandleDownloadBtn(e)}
                                >
                                    <div
                                        className="icon"
                                        dangerouslySetInnerHTML={{ __html: Icons.general.download }}
                                    ></div>
                                    <div className="label">Download</div>
                                </div>
                                <div
                                    className="action-btn button-download"
                                    onClick={(e) => HandleSaveBtn(e)}
                                >
                                    <div
                                        className="icon"
                                        dangerouslySetInnerHTML={{ __html: Icons.general.save }}
                                    ></div>
                                    <div className="label">Save</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )

}

export default PreviewChangeOrders;