
import proxyConfig from '../../config/reverseProxy'
import HeaderConfig from '../../config/header'

class WorkRates {

    constructor() {

    }


    async getNextWorkIDHandler(params) {

        try {

            let response = await fetch(proxyConfig['serverBaseUrl'] + '/builder/work-rates/get-work_rate-next-workid', {
                method: 'POST',
                headers: HeaderConfig.getHeader({ method: 'POST', isToken: true }),
                body: JSON.stringify(params)
            })

            response = await response.json()

            if (response) return response
        } catch {
            return { success: false }
        }


    }
    async getWorkRatesItemsHandler(params) {

        try {

            let response = await fetch(proxyConfig['serverBaseUrl'] + '/builder/work-rates/get-work_rate-items', {
                method: 'POST',
                headers: HeaderConfig.getHeader({ method: 'POST', isToken: true }),
                body: JSON.stringify(params)
            })

            response = await response.json()

            if (response) return response
        } catch {
            return { success: false }
        }


    }
    async createWorkRatesGroupHandler(params) {

        try {

            let response = await fetch(proxyConfig['serverBaseUrl'] + '/builder/work-rates/create-work_rate-group', {
                method: 'POST',
                headers: HeaderConfig.getHeader({ method: 'POST', isToken: true }),
                body: JSON.stringify(params)
            })

            response = await response.json()

            if (response) return response
        } catch {
            return { success: false }
        }


    }
    async updateWorkRatesGroupHandler(params) {

        try {

            let response = await fetch(proxyConfig['serverBaseUrl'] + '/builder/work-rates/update-work_rate-group', {
                method: 'POST',
                headers: HeaderConfig.getHeader({ method: 'POST', isToken: true }),
                body: JSON.stringify(params)
            })

            response = await response.json()

            if (response) return response
        } catch {
            return { success: false }
        }


    }
    async createWorkRatesItemHandler(params) {

        try {

            let response = await fetch(proxyConfig['serverBaseUrl'] + '/builder/work-rates/create-work_rate-item', {
                method: 'POST',
                headers: HeaderConfig.getHeader({ method: 'POST', isToken: true }),
                body: JSON.stringify(params)
            })

            response = await response.json()

            if (response) return response
        } catch {
            return { success: false }
        }


    }
    async updateWorkRatesItemHandler(params) {

        try {

            let response = await fetch(proxyConfig['serverBaseUrl'] + '/builder/work-rates/update-work_rate-item', {
                method: 'POST',
                headers: HeaderConfig.getHeader({ method: 'POST', isToken: true }),
                body: JSON.stringify(params)
            })

            response = await response.json()

            if (response) return response
        } catch {
            return { success: false }
        }


    }
    async deleteWorkRatesItemHandler(params) {

        try {

            let response = await fetch(proxyConfig['serverBaseUrl'] + '/builder/work-rates/delete-work_rate-item', {
                method: 'POST',
                headers: HeaderConfig.getHeader({ method: 'POST', isToken: true }),
                body: JSON.stringify(params)
            })

            response = await response.json()

            if (response) return response
        } catch {
            return { success: false }
        }


    }


}

export default WorkRates;