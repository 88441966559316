import React, { useEffect, useState, useRef, useCallback, useMemo, StrictMode } from 'react'
import SignatureCanvas from 'react-signature-canvas'

import Images from '../assets/Images'
import Icons from '../assets/Icons'

const CommentBoxPopup = ({ props }) => {

    const { placeholder, heading, close, callback } = props

    const [CommentInput, setCommentInput] = useState('');

    const saveComment = () => {

        callback(CommentInput)
    };


    const HandleCancel = async (e) => {
        close(false)
    }

    const Popup_Header = () => {
        return (
            <div className="side-popup-header">
                <div className="header-item-select">
                    <div className="header-item-select-content">
                        <div className="label">{heading}</div>
                    </div>
                </div>
                <div
                    className="header-item-close"
                    onClick={(e) => HandleCancel(e)}
                    dangerouslySetInnerHTML={{ __html: Icons.general.close }}
                ></div>
            </div>
        );
    };
    const Popup_Footer = () => {

        return (
            <div className="sidebar-popup-footer">
                <div className="footer-item action-items">
                    <div className="action-preview">
                    </div>
                    <div className='action-btns'>
                        <div
                            className={`action-btn`}
                            onClick={(e) => HandleCancel(e)}>
                            Close
                        </div>
                        <div
                            className={`action-btn action-active ${!CommentInput.length ? 'action-disabled' : ''}`}
                            onClick={(e) => saveComment(e)}>
                            Save
                        </div>

                    </div>
                </div>
            </div>
        );
    };


    return (
        <div className="popup-container-main popup-container-center">
            <div className="popup-block-ui"></div>
            <div className="side-popup-container signature-main-container" style={{ maxWidth: '500px', height: '600px !important' }}>
                <Popup_Header />
                <div className="sidebar-popup-content">
                    <div className="content-section">
                        <div className="popup-signature-main">
                            <div className="signature-header">
                                <div className="header-left">
                                    <div className="header-title">Comment</div>
                                </div>

                            </div>
                            <div className="signature-comment-box">
                                <textarea
                                    placeholder={placeholder || 'Enter your comment'}
                                    value={CommentInput}
                                    onChange={(e) => setCommentInput(e.target.value)}
                                >
                                </textarea>
                            </div>

                        </div>
                    </div>

                </div>
                <Popup_Footer />
            </div>
        </div>
    )
}

export default CommentBoxPopup;