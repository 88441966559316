

import React, { useEffect, useState, useRef, useContext } from 'react'
import { Route, Routes, BrowserRouter, Link, useLocation, Navigate, Outlet, useNavigate, useParams } from 'react-router-dom';
import store from '../../redux/store'
import StoreHandler from '../../redux/StoreHandler'
import { useSelector, useDispatch } from 'react-redux'
import UserAction from '../../redux/action/userAction'

import { SocketContext } from '../../Services/Socket/SocketHook';

import ReverseProxy from '../../config/reverseProxy'
import ChatsHandler from '../../Handlers/Chats/Chats';
import Icons from '../../assets/Icons'
import Images from '../../assets/Images'
import { SearcBarInput, TextInput, SelectInput, RadioInput } from '../../components/Inputs'
import Utils from '../../utils'
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

import AlertPopup from '../../components/AlertPopup'
import SystemToastPopup from '../../components/SystemToastPopup'
import Loading from '../../components/Loading'
import NoDataFound from '../../components/NoDataFound'

import { useAuth, useMenuAuth } from '../../hooks/useAuth'

const ChatMessagingMain = ({ props }) => {

    const { chatlist_details, close } = props

    const socket = useContext(SocketContext);
    const [SocketInstance, setSocketInstance] = useState(null)

    const store = useSelector((store) => store)
    const dispatch = useDispatch()
    const { updateState } = new UserAction

    const navigate = useNavigate()

    const chatsHandler = new ChatsHandler()

    const { projectId } = useParams()

    const { isAdmin, menu_permission } = useMenuAuth({ menu: 'safety--checklists', feature_key: 'checklists' })

    const location = useLocation()

    const [selected_view_settings_value, setSelected_view_settings_value] = useState('')
    const [has_view_access, setHas_view_access] = useState(false)
    const [has_add_access, setHas_add_access] = useState(false)
    const [has_edit_access, setHas_edit_access] = useState(false)
    const [has_delete_access, setHas_delete_access] = useState(false)

    useEffect(() => {

        if (isAdmin) {

            setHas_view_access(true)
            setHas_add_access(true)
            setHas_edit_access(true)
            setHas_delete_access(true)
        }

        else if (menu_permission) {

            if (
                !menu_permission._view
                &&
                (!menu_permission.selected_view_settings_value
                    || menu_permission.selected_view_settings_value == 'no_access')
            ) {
                navigate(`${ReverseProxy['proxyUrl']}/quick-menu`, { state: { from: location }, replace: true })
            }

            setHas_view_access(menu_permission._view == '1')
            setHas_add_access(menu_permission._create == '1')
            setHas_edit_access(menu_permission._edit == '1')
            setHas_delete_access(menu_permission._delete == '1')

            setSelected_view_settings_value(menu_permission.selected_view_settings_value)

        }

    }, [store])

    useEffect(() => {

        if (!socket) return;

        setSocketInstance(socket)

    }, [socket]);


    const storeHandler = new StoreHandler({
        dataStack: [`project_client-${projectId}`],
        store: store,
        dispatch: dispatch,
        updateState: updateState,
    })

    const LoadStoreData = async () => {
        // setIsLoading(true)
        let resolved = await storeHandler.init()
        // setIsLoading(false)
    }

    const [isLoading, setIsLoading] = useState(false)
    const [warningAlert, setWarningAlert] = useState(false)
    const [warningAlertType, setWarningAlertType] = useState('warning')
    const [apiFailedMessage, setApiFailedMessage] = useState('Error in Projects')


    const [deleteItemId, setDeleteItem] = useState(null)
    const [deleteItemType, setDeleteItemType] = useState(null)
    const [deleteItemAlert, setDeleteItemAlert] = useState(false)

    const HandleDeleteTemplate = async (item) => {

        setDeleteItem(item.id)
        setDeleteItemType(item.type)
        setDeleteItemAlert(true)
    }
    const HandleDeleteConfirmItem = async (id, type, confirmation) => {

        if (!confirmation || !id) {

            setDeleteItem(null)
            setDeleteItemType(null)
            setDeleteItemAlert(false)

            return
        }



        setDeleteItem(null)
        setDeleteItemType(null)
        setDeleteItemAlert(false)
    }

    const [ReceiverIds, setReceiverIds] = useState([])
    const [GroupId, setGroupId] = useState(null)

    const [ChatListName, setChatListName] = useState("")
    const [ChatListProfile, setChatListProfile] = useState(Images.User)
    const [ChatListStatus, setChatListStatus] = useState("tap here for info...")
    const [ChatListType, setChatListType] = useState("user")
    const [ChatMessages, setChatMessages] = useState([])
    const [ChatActiveTime, setChatActiveTime] = useState("Today")
    const [GroupUsers, setGroupUsers] = useState([])

    const getStatusIcon = (status) => {

        if (status == "read") return Icons.general.msg_read
        if (status == "delivered") return Icons.general.msg_delivered
        if (status == "sent") return Icons.general.msg_send
    }

    const [ChatMessageInput, setChatMessageInput] = useState("")

    const HandleSendMessage = () => {

        if (!ChatMessageInput || !ReceiverIds.length) return

        let newMessage = {
            id: Utils.getUniqueId(),
            type: "to",
            message: ChatMessageInput,
            time: Utils.getCurrentTime(),
            status: "sent",
            receiver_ids: ReceiverIds,

            attachments: []
        }

        if (SocketInstance) {
            let payload = {
                id: Utils.getUniqueId(),
                type: String(ChatListType == 'group' ? 1 : 0),
                message: ChatMessageInput,
                time: Utils.getCurrentTime(),
                receiver_ids: ReceiverIds,
                project_id: String(chatlist_details.project_id),
                attachments: []
            }

            SocketInstance.emitEvent('send_message', payload)
        }

        setChatMessages([...ChatMessages, newMessage])
        setChatMessageInput("")

    }

    const HandleReceiveMessage = (data) => {

        let newMessage = {
            id: data.id,
            type: "from",
            name: data.name,
            message: data.message,
            time: data.time,
            attachments: data.attachments,
            status: "send"
        }

        setChatMessages(prev => [...prev, newMessage])

    }

    const ListenForMessages = () => {

        if (SocketInstance) SocketInstance.socket.on('receive_message', HandleReceiveMessage)
    }

    const HandleClose = () => {
        close()
    }

    const getChatMessages = async (receiver_ids, chatlist_details) => {

        if (!receiver_ids.length) return

        let filter = {
            receiver_ids: receiver_ids || [],
            project_id: chatlist_details?.project_id || null,
            limit: 10,
            page: 1,
        }

        setIsLoading(true)
        let response = await chatsHandler.getChatMessagesHandler(filter)

        setIsLoading(false)

        if (response && response.success) {

            let messages = response.data || []

            messages = messages.map((item) => {

                let status = item.status == 0 ? 'sent' : item.status == 1 ? 'delivered' : 'read'

                return {
                    id: item.id,
                    type: item.msg_type,
                    message: item.message,
                    time: item.time,
                    ms: new Date(item.created_at).getTime(),
                    status: status,
                    attachments: item.attachments,
                    receiver_ids: item.receiver_ids,
                    name: item.name
                }
            })

            setChatMessages(prev => {

                let _messages = [...prev]

                _messages = _messages.concat(messages)
                _messages = _messages.sort((a, b) => a.ms - b.ms)

                return _messages
            })

        } else {
            setWarningAlert(true)
            setWarningAlertType('error')
            setApiFailedMessage(`Error in loading Messages, Please try again!`)
        }
    }

    const renderMessages = (receiver_ids, chatlist_details) => {
        let update_today = {
            id: Utils.getUniqueId(),
            type: "time",
            time: "Today",
            ms: new Date(Utils.getEasyDate('today').start).getTime()
        }

        setChatMessages([update_today])

        getChatMessages(receiver_ids, chatlist_details)
    }

    useEffect(() => {

        if (chatlist_details) {

            let { id, project_id, profile, type, name, desc, team_users, time } = chatlist_details

            console.log(chatlist_details, 'chatlist_details');


            let receiver_ids = [id]

            setChatListName(name)
            setChatListProfile(profile)
            setChatListType(type)
            if (type == 'user') setReceiverIds([id])
            else if (type == 'group') {
                let ids = team_users.map(item => item.id)
                setReceiverIds(ids)
                receiver_ids = ids
                setGroupUsers(team_users)
            }

            renderMessages(receiver_ids, chatlist_details)

        }

    }, [chatlist_details])


    useEffect(() => {
        ListenForMessages()
    }, [SocketInstance])



    return (
        <>


            {isLoading ?

                <Loading
                    props={{
                        isMainLogo: false,
                        isLabel: true
                    }} />

                : null}

            {warningAlert ?

                <SystemToastPopup
                    props={{
                        type: warningAlertType,
                        message: apiFailedMessage || "Error in Projects",
                        callback: (confirmation) => setWarningAlert(false)
                    }} />
                : null}

            {deleteItemAlert ?

                <AlertPopup
                    props={{
                        type: "delete",
                        actionBtnOption: { label: "Yes, Delete" },
                        heading: "Delete item",
                        message: 'Are you sure you want to delete?',
                        callback: (confirmation) => HandleDeleteConfirmItem(deleteItemId, deleteItemType, confirmation)
                    }} />

                : null}

            <div className="messaging_container-content-main">
                <div className="messaging_container-header">
                    <div className="header-left">
                        <div className="header-back" onClick={HandleClose}
                            dangerouslySetInnerHTML={{ __html: Icons.general.dropdown_arrow }}
                        ></div>
                        <div className="header-profile-main">
                            <div className="profile-img">
                                <img src={ChatListProfile} />
                            </div>
                            <div className="profile-details">
                                <div className="details-name">
                                    {ChatListName}
                                </div>
                                <div className="details-status">
                                    {ChatListStatus}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="messaging_container-messages">
                    <div className="messages-items">
                        {false ? <div className="messages-flooting_day-item">{ChatActiveTime}</div> : ''}
                        {ChatMessages.map((item, index) => (
                            item.type == "time" ?
                                <div className="messages-time-item"
                                    key={`messages-time-item-${index}`}
                                >{item.time}</div>
                                : item.type == "from" ?
                                    <div
                                        className="messages-from_msg-row"
                                        key={`messages-from_msg-row-${index}`}
                                    >
                                        <div className="messages-from_msg-item">
                                            {ChatListType == 'group' && item.name ? <div className="from_msg-sender-name">{item.name}</div> : ''}
                                            <div className="from_msg-content">
                                                <div className="content-message">{item.message}</div>
                                                <div className="content-actions">
                                                    <div className="content-action-time">{item.time}</div>
                                                    <div className="content-action-status"
                                                        dangerouslySetInnerHTML={{ __html: getStatusIcon(item.status) }}
                                                    ></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    : item.type == "to" ?
                                        <div
                                            className="messages-to_msg-row"
                                            key={`messages-to_msg-row-${index}`}
                                        >
                                            <div className="messages-to_msg-item">
                                                {ChatListType == 'group' && item.name ? <div className="to_msg-sender-name">{item.name}</div> : ''}
                                                <div className="to_msg-content">
                                                    <div className="content-message">{item.message}</div>
                                                    <div className="content-actions">
                                                        <div className="content-action-time">{item.time}</div>
                                                        <div className="content-action-status"
                                                            dangerouslySetInnerHTML={{ __html: getStatusIcon(item.status) }}
                                                        ></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        : ''

                        ))}




                    </div>
                </div>
                <div className="messaging_container-footer">
                    <div className="footer-input-main">
                        <div className="footer-input">
                            <input
                                type="text"
                                placeholder='Type a message'
                                value={ChatMessageInput}
                                onChange={(e) => setChatMessageInput(e.target.value)}
                            />
                        </div>
                        <div className="footer-picture"
                            dangerouslySetInnerHTML={{ __html: Icons.general.camera }}
                        ></div>
                    </div>
                    <div className={`footer-send_btn ${ChatMessageInput.length ? 'footer-send_btn-active' : ''}`}
                        dangerouslySetInnerHTML={{ __html: Icons.general.send_message }}
                        onClick={HandleSendMessage}
                    ></div>

                </div>
            </div>
        </>
    )
}

export default ChatMessagingMain;