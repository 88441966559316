

import React, { useEffect, useState, useRef } from 'react'
import { Route, Routes, BrowserRouter, Link, useLocation, Navigate, Outlet, useNavigate, useParams } from 'react-router-dom';
import store from '../../redux/store'
import StoreHandler from '../../redux/StoreHandler'
import { useSelector, useDispatch } from 'react-redux'
import UserAction from '../../redux/action/userAction'

import ReverseProxy from '../../config/reverseProxy'
import VendorsHandler from '../../Handlers/Vendors/Vendors';
import Icons from '../../assets/Icons'
import Images from '../../assets/Images'
import { CostInput, TextInput, SelectInput, DateTimeInput, RadioInput } from '../../components/Inputs'
import Utils from '../../utils'
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

import AlertPopup from '../../components/AlertPopup'
import Loading from '../../components/Loading'
import SystemToastPopup from '../../components/SystemToastPopup'

import { useAuth, useMenuAuth } from '../../hooks/useAuth'
const CreateVendor = ({ props }) => {

    const { vendorId } = useParams();

    const { menuType = 'create' } = props

    const [isLoading, setIsLoading] = useState(false)
    const [warningAlert, setWarningAlert] = useState(false)
    const [warningAlertType, setWarningAlertType] = useState('warning')
    const [apiFailedMessage, setApiFailedMessage] = useState("Error in Server")

    const navigate = useNavigate()
    const store = useSelector((store) => store)
    const dispatch = useDispatch()
    const { updateState } = new UserAction

    const storeHandler = new StoreHandler({
        dataStack: ['company'],
        store: store,
        dispatch: dispatch,
        updateState: updateState,
    })

    const LoadStoreData = async () => {
        setIsLoading(true)
        let resolved = await storeHandler.init()
        setIsLoading(false)
    }

    const { isAdmin, menu_permission } = useMenuAuth({ menu: 'global_menus--vendors' })
    const location = useLocation()

    const [selected_view_settings_value, setSelected_view_settings_value] = useState('')
    const [has_view_access, setHas_view_access] = useState(false)
    const [has_add_access, setHas_add_access] = useState(false)
    const [has_edit_access, setHas_edit_access] = useState(false)
    const [has_delete_access, setHas_delete_access] = useState(false)

    useEffect(() => {

        if (isAdmin) {

            setHas_view_access(true)
            setHas_add_access(true)
            setHas_edit_access(true)
            setHas_delete_access(true)
        }

        else if (menu_permission) {

            if (
                !menu_permission._view
                &&
                (!menu_permission.selected_view_settings_value
                    || menu_permission.selected_view_settings_value == 'no_access')
            ) {
                navigate(`${ReverseProxy['proxyUrl']}/quick-menu`, { state: { from: location }, replace: true })
            }

            if (menuType == 'edit' && menu_permission._edit == '0') navigate(`${ReverseProxy['proxyUrl']}/builder/projects`, { state: { from: location }, replace: true })
            if (menuType == 'create' && menu_permission._create == '0') navigate(`${ReverseProxy['proxyUrl']}/builder/projects`, { state: { from: location }, replace: true })

            setHas_view_access(menu_permission._view == '1')
            setHas_add_access(menu_permission._create == '1')
            setHas_edit_access(menu_permission._edit == '1')
            setHas_delete_access(menu_permission._delete == '1')

            setSelected_view_settings_value(menu_permission.selected_view_settings_value)

        }

    }, [store])

    const vendorsHandler = new VendorsHandler()


    //Basic Detials

    const [id, setId] = useState('')
    const [VendorCode, setVendorCode] = useState('')
    const [VendorName, setVendorName] = useState('')
    const [VendorTrade, setVendorTrade] = useState('')
    const [VendorPhone, setVendorPhone] = useState('')
    const [VendorAddress, setVendorAddress] = useState('')
    const [VendorPINCode, setVendorPINCode] = useState('')
    const [VendorEmail, setVendorEmail] = useState('')
    const [VendorWebsite, setVendorWebsite] = useState('')
    const [VendorStatus, setVendorStatus] = useState('')

    //Statutory Detials

    const [GSTINNo, setGSTINNo] = useState('')
    const [GST_Treatment, setGST_Treatment] = useState('')
    const [CSTNo, setCSTNo] = useState('')
    const [PANNo, setPANNo] = useState('')
    const [TINNo, setTINNo] = useState('')
    const [TANNo, setTANNo] = useState('')
    const [ServiceTaxNo, setServiceTaxNo] = useState('')

    //Contact Detials
    const [contact_person_name, setContact_person_name] = useState('')
    const [contact_person_phone, setContact_person_phone] = useState('')
    const [contact_person_email, setContact_person_email] = useState('')
    const [contact_person_designation, setContact_person_designation] = useState('')


    //Billing Detials
    const [billing_address, setBilling_address] = useState('')


    const [VendorStatus_Options, setVendorStatus_Options] = useState([
        {
            value: '0',
            label: 'Inactive'
        },

        {
            value: '1',
            label: 'Active'
        },
        {
            value: '2',
            label: 'Resticted'
        },

    ])
    const [VendorTrade_Options, setVendorTrade_Options] = useState([
        {
            value: 'Electrical',
            label: 'Electrical'
        },
        {
            value: 'Plumbing',
            label: 'Plumbing'
        },
        {
            value: 'Carpentry',
            label: 'Carpentry'
        },
        {
            value: 'Painting',
            label: 'Painting'
        },
        {
            value: 'Fabrication',
            label: 'Fabrication'
        },
        {
            value: 'Civil',
            label: 'Civil'
        },
        {
            value: 'Flooring',
            label: 'Flooring'
        },
        {
            value: 'False Ceiling',
            label: 'False Ceiling'
        },
        {
            value: 'Water Proofing',
            label: 'Water Proofing'
        },
        {
            value: 'Glass Work',
            label: 'Glass Work'
        },
        {
            value: 'Landscaping',
            label: 'Landscaping'
        },
        {
            value: 'Interior Designing',
            label: 'Interior Designing'
        },
        {
            value: 'HVAC',
            label: 'HVAC'
        },
        {
            value: 'Fire Fighting',
            label: 'Fire Fighting'
        },
        {
            value: 'Security',
            label: 'Security'
        },
        {
            value: 'Other',
            label: 'Other'
        },
    ])
    const [GSTTreatment_Options, setGSTTreatment_Options] = useState([
        {
            value: 'Registered Business - Regular',
            label: 'Registered Business - Regular'
        },
        {
            value: 'Registered Business - Composition',
            label: 'Registered Business - Composition'
        },
        {
            value: 'Unregistered Business',
            label: 'Unregistered Business'
        },
        {
            value: 'Overseas',
            label: 'Overseas'
        },
        {
            value: 'Special Economic Zone',
            label: 'Special Economic Zone'
        },
        {
            value: 'Deemed Export',
            label: 'Deemed Export'
        },

    ])
    const [ContactPersonDesignation_Options, setContactPersonDesignation_Options] = useState([
        {
            value: 'Proprietor',
            label: 'Proprietor'
        },
        {
            value: 'Partner',
            label: 'Partner'
        },
        {
            value: 'Director',
            label: 'Director'
        },
        {
            value: 'Manager',
            label: 'Manager'
        },
        {
            value: 'Employee',
            label: 'Employee'
        },
    ])


    const [currency_type, setCurrency_type] = useState('₹')

    const HandleCancel = (e) => {

        navigate('/builder/vendors')
    }

    const HandleSubmit = async (e) => {
        e.preventDefault()

        let update_detials = {
            vendorID: VendorCode,
            name: VendorName || "",
            trade: VendorTrade || "",
            email: VendorEmail || "",
            phone: VendorPhone || "",
            address: VendorAddress || "",
            pincode: VendorPINCode || "",
            website: VendorWebsite || "",
            contact_person_name: contact_person_name || "",
            contact_person_phone: contact_person_phone || "",
            contact_person_email: contact_person_email || "",
            contact_person_designation: contact_person_designation || "",
            gstin_no: GSTINNo || "",
            gst_treatment: GST_Treatment || "",
            cst_no: CSTNo || "",
            pan_no: PANNo || "",
            tin_no: TINNo || "",
            tan_no: TANNo || "",
            service_tax_no: ServiceTaxNo || "",
            billing_address: billing_address || "",
        }

        let response = { success: false }

        if (menuType == 'edit') {

            update_detials["id"] = id
            update_detials["status"] = VendorStatus || ""
            response = await vendorsHandler.updateVendorHandler(update_detials)

        }

        else response = await vendorsHandler.createVendorHandler(update_detials)

        if (response.success) {
            setIsLoading(false)
            navigate(`/builder/vendors`)
        }
        else {
            setIsLoading(false)
            setWarningAlert(true)
            setWarningAlertType('error')
            setApiFailedMessage(`Error in Vendor`)
        }
    }

    const getNextVendorCode = async () => {

        let response = await vendorsHandler.getNextVendorCodeHandler()

        if (response && response.success) {

            let { vendorId } = response.data

            if (vendorId) setVendorCode(vendorId)

        } else {
            setWarningAlert(true)
            setWarningAlertType('error')
            setApiFailedMessage(`Error in getting next vendor code`)
        }
    }

    const LoadVendor = async () => {

        let filters = {
            vendorID: vendorId
        }

        let response = await vendorsHandler.getVendorsHandler(filters)

        if (response && response.success && Array.isArray(response.data) && response.data.length) {

            let {
                id,
                vendorID,
                name,
                trade,
                email,
                phone,
                address,
                pincode,
                status,
                website,
                contact_person_name,
                contact_person_phone,
                contact_person_email,
                contact_person_designation,
                gstin_no,
                gst_treatment,
                cst_no,
                pan_no,
                tin_no,
                tan_no,
                service_tax_no,
                billing_address,


            } = response.data[0]


            setId(id)
            setVendorCode(vendorID || "")
            setVendorName(name || "")
            setVendorTrade(trade || "")
            setVendorEmail(email || "")
            setVendorPhone(phone || "")
            setVendorStatus(String(status) || "")
            setVendorAddress(address || "")
            setVendorPINCode(pincode || "")
            setVendorWebsite(website || "")
            setContact_person_name(contact_person_name || "")
            setContact_person_phone(contact_person_phone || "")
            setContact_person_email(contact_person_email || "")
            setContact_person_designation(contact_person_designation || "")
            setGSTINNo(gstin_no || "")
            setGST_Treatment(gst_treatment || "")
            setCSTNo(cst_no || "")
            setPANNo(pan_no || "")
            setTINNo(tin_no || "")
            setTANNo(tan_no || "")
            setServiceTaxNo(service_tax_no || "")
            setBilling_address(billing_address || "")

        } else {
            setWarningAlert(true)
            setWarningAlertType('error')
            setApiFailedMessage(`Error in getting vendor detials`)
        }
    }

    useEffect(() => {

        if (menuType == 'create') getNextVendorCode()
        else LoadVendor()

        LoadStoreData()
    }, [])

    useEffect(() => {

        if (store && store.user) {

            let { company } = store.user

            if (company) {

                let { currency_setting, timezone, temperature_setting } = company

                if (currency_setting) setCurrency_type(currency_setting)
            }



        }

    }, [store])

    return (
        <>


            {isLoading ?
                <Loading
                    props={{
                        isMainLogo: false,
                        isLabel: true
                    }} />
                : null}
            {warningAlert ?

                <SystemToastPopup
                    props={{
                        type: warningAlertType,
                        message: apiFailedMessage || "Error in Vendors",
                        callback: (confirmation) => setWarningAlert(false)
                    }} />

                : null}

            <div className="project-projects-main">
                <div className="projects-panel-content">

                    <div className="panel-content-header">
                        <div className="left">
                            <div className="icon"
                                dangerouslySetInnerHTML={{ __html: Icons.general.back_arrow }}
                                onClick={(e) => HandleCancel(e)}
                            ></div>
                            <div className="title">{menuType == 'create' ? 'Create New' : 'Edit'} Vendor</div>
                        </div>
                    </div>
                    <form className="panel-content-sections" onSubmit={HandleSubmit}>
                        <div className="create-project-content-main">

                            <div className="create-project-sections">
                                <div className="create-project-section">
                                    <div className="section-input-items">

                                        <div className="section-input-item">
                                            <TextInput
                                                props={{
                                                    id: "create-vendor-vendorID",
                                                    value: VendorCode,
                                                    placeholder: '',
                                                    setValue: (value) => setVendorCode(value),
                                                    isIcon: false,
                                                    isLabel: true,
                                                    isRequired: true,
                                                    readOnly: true,
                                                    label: "Code",
                                                }}
                                            />
                                        </div>
                                        <div className="section-input-item">
                                            <TextInput
                                                props={{
                                                    id: "create-vendor-name",
                                                    value: VendorName,
                                                    placeholder: '',
                                                    setValue: (value) => setVendorName(value),
                                                    isIcon: false,
                                                    isLabel: true,
                                                    isRequired: true,
                                                    label: "Name",
                                                }}
                                            />
                                        </div>
                                        <div className="section-input-item">
                                            <SelectInput
                                                props={{
                                                    id: "create-vendor-projectType",
                                                    value: VendorTrade,
                                                    placeholder: '',
                                                    readOnly: false,
                                                    options: VendorTrade_Options || [],
                                                    setValue: (value, label) => {
                                                        setVendorTrade(value)
                                                    },
                                                    isStatus: false,
                                                    isIcon: false,
                                                    isLabel: true,
                                                    isRequired: true,
                                                    label: "Trade",
                                                }}
                                            />
                                        </div>
                                        {menuType != 'create' ?
                                            <div className="section-input-item">
                                                <SelectInput
                                                    props={{
                                                        id: "create-vendor-status",
                                                        value: VendorStatus,
                                                        placeholder: '',
                                                        readOnly: false,
                                                        options: VendorStatus_Options || [],
                                                        setValue: (value, label) => {
                                                            setVendorStatus(value)
                                                        },
                                                        isStatus: false,
                                                        isIcon: false,
                                                        isLabel: true,
                                                        isRequired: true,
                                                        label: "Status",
                                                    }}
                                                />
                                            </div>
                                            : ''}

                                    </div>
                                    <div className="section-input-items">
                                        <div className="section-input-item">
                                            <TextInput
                                                props={{
                                                    id: "create-vendor-phone",
                                                    value: VendorPhone,
                                                    placeholder: '',
                                                    type: 'number',
                                                    setValue: (value) => setVendorPhone(value),
                                                    isIcon: false,
                                                    isLabel: true,
                                                    isRequired: false,
                                                    label: "Phone",
                                                }}
                                            />
                                        </div>

                                        <div className="section-input-item">
                                            <TextInput
                                                props={{
                                                    id: "create-vendor-pincode",
                                                    value: VendorPINCode,
                                                    placeholder: '',
                                                    type: 'number',
                                                    setValue: (value) => setVendorPINCode(value),
                                                    isIcon: false,
                                                    isLabel: true,
                                                    isRequired: false,
                                                    label: "PIN Code",
                                                }}
                                            />
                                        </div>

                                        <div className="section-input-item">
                                            <TextInput
                                                props={{
                                                    id: "create-vendor-email",
                                                    value: VendorEmail,
                                                    placeholder: '',
                                                    setValue: (value) => setVendorEmail(value),
                                                    isIcon: false,
                                                    isLabel: true,
                                                    isRequired: false,
                                                    label: "Email Id",
                                                }}
                                            />
                                        </div>
                                        <div className="section-input-item">
                                            <TextInput
                                                props={{
                                                    id: "create-vendor-website",
                                                    value: VendorWebsite,
                                                    placeholder: '',
                                                    setValue: (value) => setVendorWebsite(value),
                                                    isIcon: false,
                                                    isLabel: true,
                                                    isRequired: false,
                                                    label: "Website",
                                                }}
                                            />
                                        </div>

                                    </div>
                                </div>
                                <div className="create-project-section">
                                    <div className="section-header">
                                        <div className="title">Contact Detials</div>
                                    </div>
                                    <div className="section-input-items">
                                        <div className="section-input-item">
                                            <TextInput
                                                props={{
                                                    id: "create-vendor-contact_person_name",
                                                    value: contact_person_name,
                                                    placeholder: '',
                                                    setValue: (value) => setContact_person_name(value),
                                                    isIcon: false,
                                                    isLabel: true,
                                                    isRequired: false,
                                                    label: "Name",
                                                }}
                                            />
                                        </div>
                                        <div className="section-input-item">
                                            <TextInput
                                                props={{
                                                    id: "create-vendor-contact_person_phone",
                                                    value: contact_person_phone,
                                                    placeholder: '',
                                                    setValue: (value) => setContact_person_phone(value),
                                                    isIcon: false,
                                                    isLabel: true,
                                                    isRequired: false,
                                                    label: "Phone",
                                                }}
                                            />
                                        </div>
                                        <div className="section-input-item">
                                            <TextInput
                                                props={{
                                                    id: "create-vendor-contact_person_email",
                                                    value: contact_person_email,
                                                    placeholder: '',
                                                    setValue: (value) => setContact_person_email(value),
                                                    isIcon: false,
                                                    isLabel: true,
                                                    isRequired: false,
                                                    label: "Email",
                                                }}
                                            />
                                        </div>
                                        <div className="section-input-item">
                                            <SelectInput
                                                props={{
                                                    id: "create-vendor-designation",
                                                    value: contact_person_designation,
                                                    placeholder: '',
                                                    readOnly: false,
                                                    options: ContactPersonDesignation_Options || [],
                                                    setValue: (value, label) => {
                                                        setContact_person_designation(value)
                                                    },
                                                    isStatus: false,
                                                    isIcon: false,
                                                    isLabel: true,
                                                    isRequired: true,
                                                    label: "Designation",
                                                }}
                                            />
                                        </div>



                                    </div>
                                </div>
                                <div className="create-project-section">
                                    <div className="section-header">
                                        <div className="title">Statutory Detials</div>
                                    </div>
                                    <div className="section-input-items">
                                        <div className="section-input-item">
                                            <TextInput
                                                props={{
                                                    id: "create-vendor-grtin_no",
                                                    value: GSTINNo,
                                                    placeholder: '15 Digit GSTIN',
                                                    setValue: (value) => setGSTINNo(value),
                                                    isIcon: false,
                                                    isLabel: true,
                                                    isRequired: true,
                                                    label: "GSTIN No",
                                                }}
                                            />
                                        </div>
                                        <div className="section-input-item">
                                            <SelectInput
                                                props={{
                                                    id: "create-vendor-gst_teatment",
                                                    value: GST_Treatment,
                                                    placeholder: '',
                                                    readOnly: false,
                                                    options: GSTTreatment_Options || [],
                                                    setValue: (value, label) => {
                                                        setGST_Treatment(value)
                                                    },
                                                    isStatus: false,
                                                    isIcon: false,
                                                    isLabel: true,
                                                    isRequired: true,
                                                    label: "GST Treatment",
                                                }}
                                            />
                                        </div>
                                        <div className="section-input-item">
                                            <TextInput
                                                props={{
                                                    id: "create-vendor-cst_no",
                                                    value: CSTNo,
                                                    placeholder: '',
                                                    setValue: (value) => setCSTNo(value),
                                                    isIcon: false,
                                                    isLabel: true,
                                                    isRequired: false,
                                                    label: "CST No",
                                                }}
                                            />
                                        </div>
                                        <div className="section-input-item">
                                            <TextInput
                                                props={{
                                                    id: "create-vendor-pan_no",
                                                    value: PANNo,
                                                    placeholder: '',
                                                    setValue: (value) => setPANNo(value),
                                                    isIcon: false,
                                                    isLabel: true,
                                                    isRequired: false,
                                                    label: "PAN No",
                                                }}
                                            />
                                        </div>


                                    </div>
                                    <div className="section-input-items">
                                        <div className="section-input-item">
                                            <TextInput
                                                props={{
                                                    id: "create-vendor-tin_no",
                                                    value: TINNo,
                                                    placeholder: '',
                                                    setValue: (value) => setTINNo(value),
                                                    isIcon: false,
                                                    isLabel: true,
                                                    isRequired: false,
                                                    label: "TIN No",
                                                }}
                                            />
                                        </div>
                                        <div className="section-input-item">
                                            <TextInput
                                                props={{
                                                    id: "create-vendor-tan_no",
                                                    value: TANNo,
                                                    placeholder: '',
                                                    setValue: (value) => setTANNo(value),
                                                    isIcon: false,
                                                    isLabel: true,
                                                    isRequired: false,
                                                    label: "TAN No",
                                                }}
                                            />
                                        </div>
                                        <div className="section-input-item">
                                            <TextInput
                                                props={{
                                                    id: "create-vendor-service_tax_no",
                                                    value: ServiceTaxNo,
                                                    placeholder: '',
                                                    setValue: (value) => setServiceTaxNo(value),
                                                    isIcon: false,
                                                    isLabel: true,
                                                    isRequired: false,
                                                    label: "Service Tax No",
                                                }}
                                            />
                                        </div>
                                    </div>

                                </div>
                                <div className="create-project-section">
                                    <div className="section-header">
                                        <div className="title">Address Detials</div>
                                    </div>
                                    <div className="section-input-items-row">
                                        <div className="section-input-items">

                                            <div className="section-full-itm">
                                                <div className="head">
                                                    <div className="label">Office Address</div>
                                                </div>

                                                <textarea
                                                    id="create-project-billing_address"
                                                    value={VendorAddress}
                                                    onChange={(e) => setVendorAddress(e.target.value)}
                                                ></textarea>

                                            </div>
                                            <div className="section-full-itm">
                                                <div className="head">
                                                    <div className="label">Billing Address</div>
                                                </div>

                                                <textarea
                                                    id="create-project-billing_address"
                                                    value={billing_address}
                                                    onChange={(e) => setBilling_address(e.target.value)}
                                                ></textarea>

                                            </div>


                                        </div>

                                    </div>


                                </div>
                            </div>

                            <div className="create-project-footer">
                                <div className="footer-item action-items">

                                    <div className="action-cancel" onClick={(e) => HandleCancel(e)}>
                                        Cancel
                                    </div>
                                    <button
                                        className={`action-btn`}
                                        type='submit'
                                    >

                                        <div className="label">Save</div>
                                    </button>
                                </div>
                            </div>

                        </div>
                    </form>
                </div>
            </div>
        </>
    )

}

export default CreateVendor;