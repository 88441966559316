

import React, { useEffect, useState, useRef } from 'react'
import { Route, Routes, BrowserRouter, Link, useLocation, Navigate, Outlet, useNavigate, useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux'
import UserAction from '../../redux/action/userAction'
import StoreHandler from '../../redux/StoreHandler'

import ReverseProxy from '../../config/reverseProxy'
import SettingsHandler from '../../Handlers/Settings/Settings';
import Icons from '../../assets/Icons'
import Images from '../../assets/Images'
import { DateTimeInput, CostInput, TextInput, SelectInput, RadioInput } from '../../components/Inputs'
import Utils from '../../utils'


import AlertPopup from '../../components/AlertPopup'
import Loading from '../../components/Loading'
import SystemToastPopup from '../../components/SystemToastPopup'
import { useAuth, useMenuAuth } from '../../hooks/useAuth'

const License_insurance = () => {

    const settingsHandler = new SettingsHandler()

    const [isLoading, setIsLoading] = useState(false)
    const [warningAlert, setWarningAlert] = useState(false)
    const [warningAlertType, setWarningAlertType] = useState('warning')
    const [apiFailedMessage, setApiFailedMessage] = useState("Error in Server")

    const [LicenseImg_url, setLicenseImg_url] = useState('')
    const [License, setLicense] = useState({})

    const [InsuranceImg_url, setInsuranceImg_url] = useState('')
    const [Insurance, setInsurance] = useState({})

    const [LicenseExpiry, setLicenseExpiry] = useState('')
    const [InsuranceExpiry, setInsuranceExpiry] = useState('')


    const [existingDetials, setExistingDetials] = useState({})

    const store = useSelector((store) => store)
    const dispatch = useDispatch()
    const { updateState } = new UserAction

    const storeHandler = new StoreHandler({
        dataStack: ['company'],
        dispatch: dispatch,
        store: store,
        updateState: updateState,
    })
    const LoadStoreData = async () => {
        setIsLoading(true)
        let resolved = await storeHandler.init()
        setIsLoading(false)
    }


    const navigate = useNavigate()
    const { isAdmin, menu_permission } = useMenuAuth({ menu: 'settings--licenece_insurence' })
    const location = useLocation()

    const [selected_view_settings_value, setSelected_view_settings_value] = useState('')
    const [has_view_access, setHas_view_access] = useState(false)
    const [has_add_access, setHas_add_access] = useState(false)
    const [has_edit_access, setHas_edit_access] = useState(false)
    const [has_delete_access, setHas_delete_access] = useState(false)

    useEffect(() => {

        if (isAdmin) {

            setHas_view_access(true)
            setHas_add_access(true)
            setHas_edit_access(true)
            setHas_delete_access(true)
        }

        else if (menu_permission) {

            if (
                !menu_permission._view
                &&
                (!menu_permission.selected_view_settings_value
                    || menu_permission.selected_view_settings_value == 'no_access')
            ) {
                navigate(`${ReverseProxy['proxyUrl']}/quick-menu`, { state: { from: location }, replace: true })
            }

            if (menu_permission._view == '0') navigate(`${ReverseProxy['proxyUrl']}/builder/projects`, { state: { from: location }, replace: true })

            setHas_view_access(menu_permission._view == '1')
            setHas_add_access(menu_permission._create == '1')
            setHas_edit_access(menu_permission._edit == '1')
            setHas_delete_access(menu_permission._delete == '1')

            setSelected_view_settings_value(menu_permission.selected_view_settings_value)

        }

    }, [store])


    const HandleformSubmit = async (e) => {
        e.preventDefault()

        if (!has_edit_access) return

        const formData = new FormData();

        formData.append('license_document', License)
        formData.append('license_expiry', LicenseExpiry)
        formData.append('insurance_document', Insurance)
        formData.append('insurance_expiry', InsuranceExpiry)


        setIsLoading(true)

        let response = await settingsHandler.updateLicense_InsuranceHandler(formData)

        if (response.success) {
            LoadStoreData()
            setIsLoading(false)
        }
        else {
            setIsLoading(false)
            setWarningAlert(true)
            setWarningAlertType('error')
            setApiFailedMessage(`Error in updating License and Insurance`)
        }
    }



    useEffect(() => {

        if (store.user.company) setExistingDetials(store.user.company)

    }, [store])

    useEffect(() => {

        if (existingDetials) {

            if (existingDetials.license_expiry) setLicenseExpiry(existingDetials.license_expiry)
            if (existingDetials.insurance_expiry) setInsuranceExpiry(existingDetials.insurance_expiry)
            if (existingDetials.license_document) {

                setLicense(Utils.dataURLtoFile(`data:image/png;base64,${existingDetials.license_document}`, 'license'))
                setLicenseImg_url(`data:image/png;base64,${existingDetials.license_document}`)
            }
            if (existingDetials.insurance_document) {
                setInsurance(Utils.dataURLtoFile(`data:image/png;base64,${existingDetials.insurance_document}`, 'insurance'))
                setInsuranceImg_url(`data:image/png;base64,${existingDetials.insurance_document}`)
            }


        }

    }, [existingDetials])

    const handleImageChange = (event, file_type) => {

        if (!has_edit_access) return

        const file = event.target.files[0];

        if (!file) return

        if (file_type == 'license') setLicense(file);
        if (file_type == 'insurance') setInsurance(file);

        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {

            if (file_type == 'license') setLicenseImg_url(reader.result)
            if (file_type == 'insurance') setInsuranceImg_url(reader.result)
        };


    };
    const uploadImage = (event, file_type) => {
        if (!has_edit_access) return

        let input = document.getElementById(`file-upload-input-${file_type}`)
        input.click()
    };
    const resetUploadImage = (event, file_type) => {

        if (file_type == 'license') {
            setLicense({});
            setLicenseImg_url('')
        }
        if (file_type == 'insurance') {
            setInsurance({});
            setInsuranceImg_url('')
        }

        let input = document.getElementById(`file-upload-input-${file_type}`)
        input.value = ''
    }


    const isFile = (variable) => {
        return variable !== null && typeof variable === 'object' && variable.constructor === File;
    }

    useEffect(() => {

        let loadImg = (file_type, file) => {

            if (!isFile(file)) return

            let input = document.getElementById(`file-upload-input-${file_type}`)
            const fileList = new DataTransfer();
            fileList.items.add(file);

            input.files = fileList.files;
        }

        loadImg('license', License)
        loadImg('insurance', Insurance)

    }, [License, Insurance])

    return (

        <>

            {isLoading ?
                <Loading
                    props={{
                        isMainLogo: false,
                        isLabel: true
                    }} />
                : null}
            {warningAlert ?

                <SystemToastPopup
                    props={{
                        type: warningAlertType,
                        message: apiFailedMessage || "Error in License and Insurance",
                        callback: (confirmation) => setWarningAlert(false)
                    }} />

                : null}


            <form className="settings-panel-content" onSubmit={HandleformSubmit}>
                <div className="panel-content-main">

                    <div className="panel-content-header">
                        <div className="title">License and insurance</div>
                    </div>
                    <div className="panel-content-sections">

                        <div className="panel-content-section">
                            <div className="content-file-item">
                                <div className="label">License</div>
                                <input
                                    className='file-upload-input'
                                    accept="image/jpg, image/jpeg, image/png"
                                    onChange={(e) => handleImageChange(e, 'license')}
                                    type="file"
                                    id='file-upload-input-license'
                                />
                                <div className="file-dragdrop-main">
                                    <div className="file-dragdrop" onClick={(e) => uploadImage(e, 'license')}>
                                        <div className="icon" dangerouslySetInnerHTML={{ __html: Icons.general.add_btn }}></div>
                                        <div className="text">Browse file</div>
                                    </div>

                                    {LicenseImg_url ?
                                        <div className="file-preview-main">
                                            <div
                                                className="file-close"
                                                dangerouslySetInnerHTML={{ __html: Icons.general.close }}
                                                onClick={(e) => resetUploadImage(e, 'license')}
                                            ></div>
                                            <img src={LicenseImg_url} />
                                            <div className="file-expiry-input">
                                                <DateTimeInput
                                                    props={{
                                                        id: "createproject-license-expiry",
                                                        value: LicenseExpiry,
                                                        placeholder: '',
                                                        setValue: (value) => {
                                                            setLicenseExpiry(value)
                                                        },
                                                        isIcon: true,
                                                        isLabel: true,
                                                        icon: Icons.general.schedule,
                                                        isRequired: true,
                                                        label: "Expiry date",
                                                    }}
                                                />
                                            </div>
                                        </div>
                                        : ''}

                                </div>


                            </div>
                            <div className="content-file-item">
                                <div className="label">Insurance</div>
                                <input
                                    className='file-upload-input'
                                    accept="image/jpg, image/jpeg, image/png"
                                    onChange={(e) => handleImageChange(e, 'insurance')}
                                    type="file"
                                    id='file-upload-input-insurance'
                                />
                                <div className="file-dragdrop-main">
                                    <div className="file-dragdrop" onClick={(e) => uploadImage(e, 'insurance')}>
                                        <div className="icon" dangerouslySetInnerHTML={{ __html: Icons.general.add_btn }}></div>
                                        <div className="text">Browse file</div>
                                    </div>
                                    {InsuranceImg_url ?
                                        <div className="file-preview-main">
                                            <div
                                                className="file-close"
                                                dangerouslySetInnerHTML={{ __html: Icons.general.close }}
                                                onClick={(e) => resetUploadImage(e, 'insurance')}
                                            ></div>
                                            <img src={InsuranceImg_url} />
                                            <div className="file-expiry-input">

                                                <DateTimeInput
                                                    props={{
                                                        id: "createproject-insurance-expiry",
                                                        value: InsuranceExpiry,
                                                        placeholder: '',
                                                        setValue: (value) => {
                                                            setInsuranceExpiry(value)
                                                        },
                                                        isIcon: true,
                                                        isLabel: true,
                                                        icon: Icons.general.schedule,
                                                        isRequired: true,
                                                        label: "Expiry date",
                                                    }}
                                                />
                                            </div>
                                        </div>
                                        : ''}
                                </div>
                            </div>


                        </div>
                    </div>
                </div>
                <div className="panel-content-footer">
                    <div className="content-footer-items">
                        <div className="footer-button">
                            <div className="label">Cancel</div>
                        </div>
                        <button type='submit' className="footer-button footer-button-active">

                            <div className="label">Save Changes</div>
                        </button>
                    </div>
                </div>
            </form>
        </>
    )
}

export default License_insurance;
