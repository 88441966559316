

import React, { useEffect, useState, useRef } from 'react'
import { Route, Routes, BrowserRouter, Link, useLocation, Navigate, Outlet, useNavigate, useParams } from 'react-router-dom';
import store from '../../redux/store'
import { useSelector, useDispatch } from 'react-redux'
import UserAction from '../../redux/action/userAction'

import ReverseProxy from '../../config/reverseProxy'
import ProposalHandler from '../../Handlers/Proposal/Proposal';
import Icons from '../../assets/Icons'
import Images from '../../assets/Images'
import { CostInput, TextInput, SearcBarInput, SelectInput, RadioInput } from '../../components/Inputs'
import Utils from '../../utils'
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

import AlertPopup from '../../components/AlertPopup'
import Loading from '../../components/Loading'
import NoDataFound from '../../components/NoDataFound';
import SystemToastPopup from '../../components/SystemToastPopup'

import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableFooter from '@mui/material/TableFooter';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';

import SideFilters from '../../components/SideFilters';

import SmarkTopCards from '../../components/SmarkTopCards'

import { useAuth, useMenuAuth } from '../../hooks/useAuth'

const ProposalLists = () => {

    const store = useSelector((store) => store)
    const dispatch = useDispatch()
    const { updateState } = new UserAction

    const navigate = useNavigate()
    const { projectId } = useParams();

    const proposalHandler = new ProposalHandler()

    const [isLoading, setIsLoading] = useState(false)
    const [warningAlert, setWarningAlert] = useState(false)
    const [warningAlertType, setWarningAlertType] = useState('warning')
    const [apiFailedMessage, setApiFailedMessage] = useState("Error in Server")

    const [Proposals_original, setProposals_original] = useState([])
    const [Proposals, setProposals] = useState([])
    const [selected_termsCondition, setSelected_termsCondition] = useState({})
    const [selectedAction, setSelectedAction] = useState('')


    const [deleteItemId, setDeleteItem] = useState(null)
    const [deleteItemType, setDeleteItemType] = useState(null)
    const [deleteItemAlert, setDeleteItemAlert] = useState(false)

    const { isAdmin, menu_permission } = useMenuAuth({ menu: 'pre_construction--project_proposal', feature_key: 'proposal' })
    const location = useLocation()

    const [selected_view_settings_value, setSelected_view_settings_value] = useState('')
    const [has_view_access, setHas_view_access] = useState(false)
    const [has_add_access, setHas_add_access] = useState(false)
    const [has_edit_access, setHas_edit_access] = useState(false)
    const [has_delete_access, setHas_delete_access] = useState(false)

    useEffect(() => {

        if (isAdmin) {

            setHas_view_access(true)
            setHas_add_access(true)
            setHas_edit_access(true)
            setHas_delete_access(true)
        }

        else if (menu_permission) {

            if (
                !menu_permission._view
                &&
                (!menu_permission.selected_view_settings_value
                    || menu_permission.selected_view_settings_value == 'no_access')
            ) {
                navigate(`${ReverseProxy['proxyUrl']}/quick-menu`, { state: { from: location }, replace: true })
            }

            if (menu_permission._view == '0') navigate(`${ReverseProxy['proxyUrl']}/builder/projects`, { state: { from: location }, replace: true })

            setHas_view_access(menu_permission._view == '1')
            setHas_add_access(menu_permission._create == '1')
            setHas_edit_access(menu_permission._edit == '1')
            setHas_delete_access(menu_permission._delete == '1')

            setSelected_view_settings_value(menu_permission.selected_view_settings_value)

        }

    }, [store])


    const [tabelHeader, setTabelHeader] = useState([

        {
            id: 'proposalID',
            label: 'Proposal ID',

        },
        {
            id: 'name',
            label: 'Proposal Name',

        },
        {
            id: 'phase_name',
            label: 'Phase',
        },
        {
            id: 'client_name',
            label: 'Client',
        },
        {
            id: 'proposal_total',
            label: 'Price',
            format: (value) => `₹${value || '-/-'}`
        },
        {
            id: 'created_at',
            label: 'Created',
            format: (value) => Utils.getLocalFullDateBYFormat(value),

        },
        {
            id: 'status',
            label: 'Status',
            format: (value) => {
                if (value == "0") return "Pending"
                if (value == "1") return "Approved"
                if (value == "2") return "Rejected"
            },

        },
        {
            id: 'updated_at',
            label: 'Last updated',
            format: (value) => { return value ? Utils.getLocalFullDateBYFormat(value) : "" },

        },

        {
            id: 'actions',
            label: 'Actions',
            minWidth: 50,
            align: 'right',
            format: (value) => value,
        }
    ])

    const getProposals = async () => {

        let filter = {
            projectId: projectId
        }
        setIsLoading(true)

        let response = await proposalHandler.getBasicProposalsHandler(filter)

        setIsLoading(false)
        if (response && response.success) {

            setProposals_original(response.data)
            setProposals(response.data)

            HandleSmartCardSetup(response.data)

            dispatch(updateState({
                type: "SET_PROPOSALS",
                payload: { proposals: response.data }
            }))

        } else {
            setWarningAlert(true)
            setWarningAlertType('error')
            setApiFailedMessage(`Error in getting proposals`)
        }
    }


    const createTabelData = (header, items) => {

        console.log(!Object.keys(header).length, !Array.isArray(items), 'items');

        if (!Object.keys(header).length || !Array.isArray(items)) return []

        items = items.map((itm, i) => {

            let item = { ...itm }

            header.forEach((head, i) => item[head.id] = itm[head.id])

            return item
        })

        return items

    }

    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const HandleAddTemplate = () => {

        if (has_add_access == '1') navigate(`${window.location.pathname}/create`)
    }
    const HandleViewTemplate = (template) => {

        if (!has_view_access) return

        setSelected_termsCondition(template)

        navigate(`${window.location.pathname}/${template.proposalID}/view`)
    }
    const HandleEditTemplate = (template) => {

        if (!has_edit_access) return

        setSelected_termsCondition(template)

        navigate(`${window.location.pathname}/${template.proposalID}/edit`)
    }

    const HandleDeleteTemplate = async (item) => {

        setDeleteItem(item.id)
        setDeleteItemType(item.type)
        setDeleteItemAlert(true)

    }

    const HandleDeleteConfirmItem = async (id, type, confirmation) => {

        console.log(confirmation, 'confirmation');
        if (!confirmation || !id) {

            setDeleteItem(null)
            setDeleteItemType(null)
            setDeleteItemAlert(false)

            return
        }

        setIsLoading(true)

        let response = await proposalHandler.deleteProposalHandler({
            id: id
        })
        setIsLoading(false)

        if (response.success) {
            getProposals()
        }
        else {
            setWarningAlert(true)
            setWarningAlertType('error')
            setApiFailedMessage(`Error in Proposal, Please try again!`)
        }
        setDeleteItem(null)
        setDeleteItemType(null)
        setDeleteItemAlert(false)
    }


    const CloseAddTemplate = (type) => {

        let back_path = String(window.location.pathname).split('/')
        back_path.pop()
        if (type == 'edit') back_path.pop()

        back_path = back_path.join('/')

        navigate(`${back_path}`)
    }

    const HanelCellAction = (id, row) => {

        if (id == "proposalID") HandleViewTemplate(row)
        if (id == "actions") {
            setSelectedAction(row.id)
            HandleActionDisable(row.id)
        }
    }

    const HandleActionDisable = (id) => {

        let input = document.getElementById(`proposals-tabel-action-btn-${id}`)
        let dropdown_items = document.getElementById(`proposals-tabel-action-dropdown-${id}`)

        const Event = (e) => {
            let path = e.path || (e.composedPath && e.composedPath());

            if (Array.isArray(path) && !path.includes(dropdown_items) && !path.includes(input)) {
                setSelectedAction('')
                window.removeEventListener('click', Event)
            }
        }

        window.addEventListener('click', Event)
    }

    const [ShowSideFilter, setShowSideFilter] = useState(false)
    const [SideFilterSettings, setSideFilterSettings] = useState([
        {
            id: 'selectshow-filter',
            type: 'multiselect',
            value: true,
            placeholder: '',

            selectoptions: [
                {
                    id: 'select-filteroption-selectshow-all',
                    type: 'checkbox',
                    key: `all`,
                    value: true,
                    placeholder: '',
                    classNames: [],
                    isIconLeft: false,
                    isIcon: false,
                    icon: '',
                    isLabel: true,
                    label: 'Show select box',
                },
            ],

            callback: (e) => { },
            classNames: [],
            isIconLeft: true,
            isIcon: true,
            icon: Icons.general.multi_select,
            isLabel: true,
            label: 'Show Selection',
        },

        {
            id: 'filterby-filter',
            type: 'multiselect',
            value: true,
            placeholder: '',

            selectoptions: [
                {
                    id: 'select-filteroption-filterby-all',
                    type: 'checkbox',
                    key: `all`,
                    value: true,
                    placeholder: '',
                    classNames: [],
                    isIconLeft: false,
                    isIcon: false,
                    icon: '',
                    isLabel: true,
                    label: 'All',
                },
                {
                    id: 'select-filteroption-filterby-pending',
                    type: 'checkbox',
                    key: `0`,
                    category: 'status',
                    value: true,
                    placeholder: '',
                    classNames: [],
                    isIconLeft: false,
                    isIcon: false,
                    icon: '',
                    isLabel: true,
                    label: 'Pending',
                },
                {
                    id: 'select-filteroption-filterby-approved',
                    type: 'checkbox',
                    key: `1`,
                    category: 'status',
                    value: true,
                    placeholder: '',
                    classNames: [],
                    isIconLeft: false,
                    isIcon: false,
                    icon: '',
                    isLabel: true,
                    label: 'Approved',
                },
                {
                    id: 'select-filteroption-filterby-rejected',
                    type: 'checkbox',
                    key: `2`,
                    category: 'status',
                    value: true,
                    placeholder: '',
                    classNames: [],
                    isIconLeft: false,
                    isIcon: false,
                    icon: '',
                    isLabel: true,
                    label: 'Rejected',
                },
            ],

            callback: (e) => { },
            classNames: [],
            isIconLeft: true,
            isIcon: true,
            icon: Icons.general.filter_by,
            isLabel: true,
            label: 'Filter by Status',
        },

        {
            id: 'showtable-filter',
            type: 'multiselect',
            value: true,
            placeholder: '',

            selectoptions: [
                {
                    id: 'select-filteroption-showtable-all',
                    type: 'checkbox',
                    key: `all`,
                    value: true,
                    placeholder: '',
                    classNames: [],
                    isIconLeft: false,
                    isIcon: false,
                    icon: '',
                    isLabel: true,
                    label: 'All',
                },
                {
                    id: 'select-filteroption-showtable-name',
                    type: 'checkbox',
                    value: true,
                    key: `name`,
                    placeholder: '',
                    classNames: [],
                    isIconLeft: false,
                    isIcon: false,
                    icon: '',
                    isLabel: true,
                    label: 'PO Name',
                },
                {
                    id: 'select-filteroption-showtable-proposal_total',
                    type: 'checkbox',
                    value: true,
                    key: `proposal_total`,
                    placeholder: '',
                    classNames: [],
                    isIconLeft: false,
                    isIcon: false,
                    icon: '',
                    isLabel: true,
                    label: 'Price',
                },
                {
                    id: 'select-filteroption-showtable-created_at',
                    type: 'checkbox',
                    value: true,
                    key: `created_at`,
                    placeholder: '',
                    classNames: [],
                    isIconLeft: false,
                    isIcon: false,
                    icon: '',
                    isLabel: true,
                    label: 'Created',
                },
                {
                    id: 'select-filteroption-showtable-updated_at',
                    type: 'checkbox',
                    value: true,
                    key: `updated_at`,
                    placeholder: '',
                    classNames: [],
                    isIconLeft: false,
                    isIcon: false,
                    icon: '',
                    isLabel: true,
                    label: 'Last updated',
                },
                {
                    id: 'select-filteroption-showtable-status',
                    type: 'checkbox',
                    value: true,
                    key: `status`,
                    placeholder: '',
                    classNames: [],
                    isIconLeft: false,
                    isIcon: false,
                    icon: '',
                    isLabel: true,
                    label: 'Status',
                },
            ],

            callback: (e) => { },
            classNames: [],
            isIconLeft: true,
            isIcon: true,
            icon: Icons.general.show_hide,
            isLabel: true,
            label: 'Show Tables',
        },

    ])

    const HandleSideFilterClose = () => {
        setShowSideFilter(false)
    }
    const HandleSideFilterUpdateCallback = (filters) => {

        let _Proposals = [...Proposals]

        filters.forEach((filter, fidx) => {

            if (filter.type == 'multiselect') {

                if (filter.id == 'selectshow-filter') {

                    filter.selectoptions.forEach((option, oidx) => {

                        if (option.key == 'all') {

                            let _tabelHeader = [...tabelHeader]

                            _tabelHeader[0].disable = !option.value

                            setTabelHeader(_tabelHeader)

                        }

                    })
                }
                else if (filter.id == 'filterby-filter') {

                    filter.selectoptions.forEach((option, oidx) => {

                        if (option.key == 'all') {
                            _Proposals = _Proposals.map(item => {

                                item.disable = !option.value

                                return item
                            })
                        }
                        else {
                            _Proposals = _Proposals.map(item => {

                                if (item[option.category] == option.key) item.disable = !option.value

                                return item
                            })


                        }

                    })
                }

                else if (filter.id == 'showtable-filter') {

                    filter.selectoptions.forEach((option, oidx) => {

                        if (option.key == 'all') {

                            let _tabelHeader = [...tabelHeader]

                            _tabelHeader = _tabelHeader.map((th, i) => {
                                if (i > 0) th.disable = false
                                return th
                            })

                            setTabelHeader(_tabelHeader)
                        }
                        else {
                            let _tabelHeader = [...tabelHeader]

                            let head_idx = _tabelHeader.findIndex(h => h.id == option.key)

                            if (head_idx != -1) {
                                _tabelHeader[head_idx].disable = !option.value
                                setTabelHeader(_tabelHeader)
                            }
                        }

                    })
                }
            }

        })

        setProposals(_Proposals)

        setSideFilterSettings(filters)
        HandleSideFilterClose()
    }

    const [tableSelectAll, setTableSelectAll] = useState(false)
    const [Filters, setFilters] = useState({
        left: [


            {
                id: "estimate-search",
                value: '',
                isSearchBar: true,
                type: "input",
                isDropDownContainer: false,
                placeholder: "Search by name",
                dropDownOptions: [],
                callback: (val) => { },

                isIcon: true,
                icon: Icons.general.search,
                isLabel: false,
                label: "",
                isIconLeft: false
            },

        ],
        right: [
            {
                id: "estimate-morefilter",
                type: "icon",
                isSelectToggle: true,
                value: true,
                placeholder: "",
                callback: (val) => HandleFiltersEvent(val, 'estimate-morefilter'),
                isIcon: true,
                icon: Icons.general.filter_funnel,
                isLabel: true,
                label: "Filters",
                isIconLeft: true
            },
        ]
    })



    const HandleFiltersEvent = async (value, filter_id) => {

        if (!filter_id) return

        if (filter_id == 'estimate-search') {

            let filters = { ...Filters }

            filters.left[0].value = value

            let _Proposals = [...Proposals]

            let search_items = (items, value, column = 'name') => {

                let search_item = (items, value, column = 'name') => {

                    items = items.map(item => {

                        let is_item_match = String(item[column]).toLocaleLowerCase().includes(String(value).toLocaleLowerCase())

                        if (is_item_match) item.disable = false
                        else item.disable = true

                        return item

                    })

                    return items

                }

                items = search_item(items, value, column)
                return items
            }

            _Proposals = search_items(_Proposals, value, 'name')

            setProposals(_Proposals)

            setFilters(filters)

        }
        else if (filter_id == 'estimate-morefilter') {
            setShowSideFilter(true)
        }

    }


    const isLeftFilter = Array.isArray(Filters.left) && Filters.left.length > 0
    const isRightFilter = Array.isArray(Filters.right) && Filters.right.length > 0

    const Icon_Filter = ({ props }) => {
        const {
            id,
            isSearchBar,
            type,
            value,
            isSelectToggle,
            isDropDownContainer,
            dropDownOptions,
            placeholder,
            callback,
            isIcon,
            icon,
            isLabel,
            label,
            isIconLeft,
        } = props

        const [iconStatus, setIconStatus] = useState(value != undefined ? value : false)

        const HandleFilterIconInput = (e) => {


            HandleFiltersEvent(!iconStatus, id)
            setIconStatus(!iconStatus)
        }

        return (
            <>
                {isIcon && type == 'icon' ?
                    <div
                        className="filter-item group-expand-filter"
                        onClick={(e) => HandleFilterIconInput(e)}
                        key={`filter-item-${id}`}
                    >
                        <div
                            className="icon"
                            dangerouslySetInnerHTML={{ __html: id.includes('expand') ? iconStatus ? Icons.general.collapse : Icons.general.expand : icon }}
                        ></div>
                        {isLabel ? <div className="label">{id.includes('expand') ? iconStatus ? 'Collapse all' : 'Expand all' : label}</div> : ''}
                    </div>

                    : null}
            </>
        )
    }
    const Button_Filter = ({ props }) => {
        const {
            id,
            isSearchBar,
            type,
            isDropDownContainer,
            dropDownOptions,
            callback,
            isIcon,
            icon,
            isLabel,
            label,
            isIconLeft,
        } = props
        const [selectFilterOpts, setSelectFilterOpts] = useState([])

        const [dropdownStatus, setDropDownStatus] = useState(false)

        const HandleClick = (e) => {
            setDropDownStatus(!dropdownStatus)
        }

        let HandleSubItemChange = (value, opt, i) => {

            let _filters = [...selectFilterOpts]

            if (_filters[i].id == 'opt-select-all') {
                _filters = _filters.map(f => {
                    f.value = value

                    return f
                })
            } else _filters[i].value = value

            setSelectFilterOpts(_filters)

            HandleFiltersEvent(opt, id)
        }

        useEffect(() => {

            let items_parent = document.querySelector(`#${id}`)

            window.addEventListener('click', (e) => {

                let path = e.path || (e.composedPath && e.composedPath());

                if (Array.isArray(path) && !path.includes(items_parent))
                    setDropDownStatus(false)
            })
            setSelectFilterOpts(dropDownOptions)
        }, [])


        return (
            <div className='filter-item dropdown-select-filter' id={id} key={`filter-item-${id}`}>
                <div
                    className="button"
                    onClick={(e) => HandleClick(e)}
                >
                    {isIcon && isIconLeft ?
                        <span
                            className={`icon ${dropdownStatus ? 'icon-active' : ''}`}
                            dangerouslySetInnerHTML={{ __html: icon }}
                        ></span>
                        : null}

                    {isLabel ? <span class='label'>{label}</span> : null}

                    {isIcon && !isIconLeft ?
                        <span
                            className={`icon ${dropdownStatus ? 'icon-active' : ''}`}
                            dangerouslySetInnerHTML={{ __html: icon }}
                        ></span>
                        : null}
                </div>

                {isDropDownContainer ?

                    <div className={`dropdown-select-items ${dropdownStatus ? 'dropdown-select-items-active' : ''}`}>

                        {selectFilterOpts.map((opt, i) => (

                            opt.type == 'label' ?
                                <div className="dropdown-select-title">{opt.label}</div>
                                :
                                <div className="dropdown-select-itm">
                                    <RadioInput props={{
                                        id: `filters-item-select-${opt.id}`,
                                        value: opt.value,
                                        isIcon: false,
                                        icon: "",
                                        inputType: "checkbox",
                                        name: "table-default-radio",
                                        setValue: (value) => HandleSubItemChange(value, opt, i)
                                    }} />
                                    <label className='label' htmlFor={`filters-item-select-${opt.id}`}>{opt.label}</label>
                                </div>

                        ))}

                    </div>

                    : null}
            </div>

        )
    }


    const HandleSelectAllEvent = (value) => {
        setTableSelectAll(value)

        let _Proposals = [...Proposals]

        const selectItem = (items, value) => {

            items = items.map(item => {

                item.selected = value
                return item
            })

            return items
        }

        _Proposals = selectItem(_Proposals, value)

        setProposals(_Proposals)
    }

    const HandleSelectEvent = (id, value) => {

        let _Proposals = [...Proposals]

        _Proposals = _Proposals.map(item => {

            if (item.id == id) item.selected = value
            return item
        })

        setProposals(_Proposals)
    }

    useEffect(() => {
        getProposals()
    }, [])

    const [SmarkCard_Items, setSmarkCard_Items] = useState([])

    const HandleSmartCardClick = (id) => { }

    const HandleSmartCardSetup = (items) => {

        items = items || []

        let total = items.length
        let total_active = items.filter(itm => itm.status == "1").length
        let total_inactive = items.filter(itm => itm.status == "0").length

        let smark_cards = [
            {
                id: 'lm-smart-top-cards-total',
                label: 'Total Proposals',
                value: total || "0",
                icon: Icons.general.users,
                unit_name: '',
                style: {
                    color: '#0065ff',
                    bg_linear_colors: {
                        start: '#e5efff',
                        end: '#e5f9ff'
                    },
                    border_color: '#c6d4dc',
                },
            },
            {
                id: 'lm-smart-top-cards-total_active',
                label: 'Approved Proposals',
                value: total_active || "0",
                icon: Icons.general.users,
                unit_name: '',
                style: {
                    color: '#33c87e',
                    bg_linear_colors: {
                        start: '#eaf9f2',
                        end: '#f2fef7'
                    },
                    border_color: '#ccd8d2',
                },
            },
            {
                id: 'lm-smart-top-cards-outstanding_balance',
                label: 'Pending Proposals',
                value: total_inactive || "0",
                icon: Icons.general.users,
                unit_name: '',
                style: {
                    color: '#ff5b5b',
                    bg_linear_colors: {
                        start: '#ffeded',
                        end: '#fff9f9'
                    },
                    border_color: '#dcd1d1',
                },
            },
        ]

        setSmarkCard_Items(smark_cards)

    }

    return (
        <>

            {
                ShowSideFilter ?
                    <SideFilters
                        props={
                            {
                                heading: 'Filters',
                                filters: SideFilterSettings,
                                close: HandleSideFilterClose,
                                updateCallback: HandleSideFilterUpdateCallback
                            }
                        }
                    />
                    : ''
            }
            {isLoading ?

                <Loading
                    props={{
                        isMainLogo: false,
                        isLabel: true
                    }} />

                : null}

            {warningAlert ?

                <SystemToastPopup
                    props={{
                        type: warningAlertType,
                        message: apiFailedMessage || "Error in Purchase Order",
                        callback: (confirmation) => setWarningAlert(false)
                    }} />

                : null}

            {deleteItemAlert ?

                <AlertPopup
                    props={{
                        type: "delete",
                        actionBtnOption: { label: "Yes, Delete" },
                        heading: "Delete item",
                        message: 'Are you sure you want to delete the proposal?',
                        callback: (confirmation) => HandleDeleteConfirmItem(deleteItemId, deleteItemType, confirmation)
                    }} />

                : null}

            <div className="project-proposals-main">
                <div className="proposals-panel-content">

                    {SmarkCard_Items.length ?
                        <SmarkTopCards props={{
                            id: 'labourManagement-smart-top-cards',
                            cards: SmarkCard_Items || [],
                            callback: HandleSmartCardClick
                        }} />
                        : ''}
                    <div className="smart-panel-content-main">
                        <div className="panel-content-header">
                            <div className="title">BOQ Proposals</div>
                            <div className="buttons">

                                {has_add_access == '1' ?
                                    <div
                                        className="button add-button"
                                        onClick={(e) => HandleAddTemplate()}
                                    >
                                        <div className="icon" dangerouslySetInnerHTML={{ __html: Icons.general.add_btn }}></div>
                                        <div className="label">Create Proposal</div>
                                    </div>
                                    : ''}
                            </div>
                        </div>
                        <div className="filter-section-main">
                            {isLeftFilter ?

                                < div className="left-filter-main">
                                    {Filters.left.map((item, i) => (

                                        item.type == 'icon' ? <Icon_Filter props={item} />
                                            : item.type == 'button' ? <Button_Filter props={item} />
                                                : item.isSearchBar && item.type == 'input' ?
                                                    <div className="search-filter">
                                                        <SearcBarInput
                                                            props={{
                                                                id: item.id,
                                                                value: item.value,
                                                                placeholder: item.placeholder || "Search by name",
                                                                setValue: (val) => {
                                                                    HandleFiltersEvent(val, item.id)
                                                                },
                                                                isIcon: item.isIcon,
                                                                isLeft: item.isIconLeft,
                                                                icon: item.icon || Icons.general.search
                                                            }}
                                                        />
                                                    </div>
                                                    : ''
                                    ))}
                                </div>
                                : ''}
                            {isRightFilter ?

                                < div className="right-filter-main">
                                    {Filters.right.map((item, i) => (

                                        item.type == 'icon' ? <Icon_Filter props={item} />
                                            : item.type == 'button' ? <Button_Filter props={item} />
                                                : item.isSearchBar && item.type == 'input' ?
                                                    <div className="search-filter">
                                                        <SearcBarInput
                                                            props={{
                                                                id: item.id,
                                                                value: item.value,
                                                                placeholder: item.placeholder || "Search by name",
                                                                setValue: (val) => {
                                                                    HandleFiltersEvent(val, item.id)
                                                                },
                                                                isIcon: item.isIcon,
                                                                isLeft: item.isIconLeft,
                                                                icon: item.icon || Icons.general.search
                                                            }}
                                                        />
                                                    </div>
                                                    : ''
                                    ))}
                                </div>
                                : ''}
                        </div>
                        <div className="panel-content-sections panel-content-tabel-section">
                            {Proposals.filter(p => !p.disable).length ?
                                <Paper style={{ width: '100%', height: '100%', boxShadow: 'none', overflow: 'hidden' }}>
                                    <TableContainer style={{ height: '93%', boxShadow: 'none !important', }}>
                                        <Table stickyHeader>
                                            <TableHead
                                                className='tabel-header'
                                            >
                                                <TableRow>
                                                    {tabelHeader.filter(th => !th.disable).map((column) => (
                                                        <TableCell
                                                            key={column.id}
                                                            align={column.align}
                                                            style={{ minWidth: column.minWidth }}
                                                            className='tabel-header-items'
                                                        >
                                                            {column.id == 'select' ?

                                                                <RadioInput props={{
                                                                    value: tableSelectAll || false,
                                                                    isIcon: false,
                                                                    icon: "",
                                                                    inputType: "checkbox",
                                                                    name: "table-default-radio",
                                                                    setValue: (value) => HandleSelectAllEvent(value)
                                                                }} />
                                                                :
                                                                column.label}
                                                        </TableCell>
                                                    ))}
                                                </TableRow>
                                            </TableHead>
                                            <TableBody
                                                className='tabel-body'
                                            >
                                                {Proposals.filter(p => !p.disable)
                                                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                                    .map((row, idx) => {
                                                        return (
                                                            <TableRow hover role="checkbox" tabIndex={-1} key={row.id}>
                                                                {tabelHeader.filter(th => !th.disable).map((column) => {
                                                                    const value = row[column.id];
                                                                    return (

                                                                        column.id == 'select' ?


                                                                            <TableCell
                                                                                key={column.id}
                                                                                align={column.align}
                                                                                className='estimate-table-row-item'
                                                                            // 
                                                                            >
                                                                                <RadioInput props={{
                                                                                    value: row.selected || false,
                                                                                    isIcon: false,
                                                                                    icon: "",
                                                                                    inputType: "checkbox",
                                                                                    name: "table-default-radio",
                                                                                    setValue: (value) => HandleSelectEvent(row.id, value)
                                                                                }} />

                                                                            </TableCell>

                                                                            :

                                                                            <TableCell
                                                                                key={column.id}
                                                                                align={column.align}
                                                                                onClick={(e) => {
                                                                                    if (column.id != 'actions') HanelCellAction(column.id, row)
                                                                                }}
                                                                            >
                                                                                {

                                                                                    column.id == 'proposalID' ?
                                                                                        <div
                                                                                            className="tabel-name"
                                                                                        >
                                                                                            {column.format ? column.format(value) : value}
                                                                                        </div>

                                                                                        : column.id == 'actions' ?

                                                                                            <div
                                                                                                className="tabel-action-main"
                                                                                            >
                                                                                                {has_view_access ?
                                                                                                    <div
                                                                                                        className="action-icon"
                                                                                                        dangerouslySetInnerHTML={{ __html: Icons.general.view_btn }}
                                                                                                        onClick={(e) => HandleViewTemplate(row)}
                                                                                                    ></div>
                                                                                                    : ''}
                                                                                                {has_edit_access ?
                                                                                                    <div
                                                                                                        className="action-icon"
                                                                                                        dangerouslySetInnerHTML={{ __html: Icons.general.edit_btn }}
                                                                                                        onClick={(e) => HandleEditTemplate(row)}
                                                                                                    ></div>
                                                                                                    : ''}
                                                                                                {has_delete_access ?
                                                                                                    <div
                                                                                                        className="action-icon"
                                                                                                        dangerouslySetInnerHTML={{ __html: Icons.general.delete_btn }}
                                                                                                        onClick={(e) => HandleDeleteTemplate(row)}
                                                                                                    ></div>
                                                                                                    : ''}

                                                                                            </div>
                                                                                            : column.format ? column.format(value) : value
                                                                                }
                                                                            </TableCell>
                                                                    );
                                                                })}
                                                            </TableRow>
                                                        );
                                                    })}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                    <TablePagination
                                        style={{ height: '7%', overflow: 'hidden' }}
                                        rowsPerPageOptions={[10, 25, 100]}
                                        component="div"
                                        count={Proposals.length || 0}
                                        rowsPerPage={rowsPerPage}
                                        page={page}
                                        onPageChange={handleChangePage}
                                        onRowsPerPageChange={handleChangeRowsPerPage}
                                    />
                                </Paper>
                                : <NoDataFound label={'No Proposals Found'} />}
                        </div>
                    </div>
                </div>

            </div>
        </>
    )
}

export default ProposalLists;