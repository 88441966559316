

import React, { useEffect, useState, useRef } from 'react'
import { Route, Routes, BrowserRouter, Link, useLocation, Navigate, Outlet, useNavigate, useParams } from 'react-router-dom';
import store from '../../redux/store'
import StoreHandler from '../../redux/StoreHandler'
import { useSelector, useDispatch } from 'react-redux'
import UserAction from '../../redux/action/userAction'

import ReverseProxy from '../../config/reverseProxy'
import TaskManagementHandler from '../../Handlers/TaskManagement/TaskManagement';
import Icons from '../../assets/Icons'
import Images from '../../assets/Images'
import { CostInput, TextInput, SelectInput, SearcBarInput, RadioInput } from '../../components/Inputs'
import Utils from '../../utils'
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

import AlertPopup from '../../components/AlertPopup'
import Loading from '../../components/Loading'
import NoDataFound from '../../components/NoDataFound';
import SystemToastPopup from '../../components/SystemToastPopup'

import AddTaskItemPopup from './AddTaskItemPopup'
import UploadTaskFilePopup from './UploadTaskFilePopup'

import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

import SideFilters from '../../components/SideFilters';
import SmarkTopCards from '../../components/SmarkTopCards'

import { useAuth, useMenuAuth } from '../../hooks/useAuth'

const TaskManagement = () => {

    const { projectId } = useParams()

    const [project_id, setProject_id] = useState('')
    const [project_name, setProject_name] = useState('')
    const [estimate_id, setEstimate_id] = useState('')

    const [isLoading, setIsLoading] = useState(false)
    const [warningAlert, setWarningAlert] = useState(false)
    const [warningAlertType, setWarningAlertType] = useState('warning')
    const [apiFailedMessage, setApiFailedMessage] = useState("Error in Server")

    const [deleteItemId, setDeleteItem] = useState(null)
    const [deleteItemType, setDeleteItemType] = useState(null)
    const [deleteItemAlert, setDeleteItemAlert] = useState(false)

    const taskManagementHandler = new TaskManagementHandler()

    const [currency_type, setCurrency_type] = useState('₹')

    const navigate = useNavigate()
    const store = useSelector((store) => store)
    const dispatch = useDispatch()
    const { updateState } = new UserAction

    const storeHandler = new StoreHandler({
        dataStack: ['company', 'field_settings', 'finance_settings', `project-${projectId}`, `project_phases-${projectId}`],
        store: store,
        dispatch: dispatch,
        updateState: updateState,
    })

    const LoadStoreData = async () => {

        setIsLoading(true)
        let resolved = await storeHandler.init()
        setIsLoading(false)
    }
    const { isAdmin, menu_permission, company_features } = useMenuAuth({ menu: 'project_management--tasks', feature_key: 'task_management' })
    const { menu_permission: bulk_upload_menu_permission } = useMenuAuth({ menu: 'project_management--tasks_bulk_upload', feature_key: 'tasks_bulk_upload' })
    const location = useLocation()

    const [selected_view_settings_value, setSelected_view_settings_value] = useState('')
    const [has_view_access, setHas_view_access] = useState(false)
    const [has_add_access, setHas_add_access] = useState(false)
    const [has_edit_access, setHas_edit_access] = useState(false)
    const [has_delete_access, setHas_delete_access] = useState(false)
    const [has_bulk_upload_access, setHas_bulk_upload_access] = useState(false)

    useEffect(() => {



        if (isAdmin) {

            setHas_view_access(true)
            setHas_add_access(true)
            setHas_edit_access(true)
            setHas_delete_access(true)
        }

        else if (menu_permission) {

            if (
                !menu_permission._view
                &&
                (!menu_permission.selected_view_settings_value
                    || menu_permission.selected_view_settings_value == 'no_access')
            ) {
                navigate(`${ReverseProxy['proxyUrl']}/quick-menu`, { state: { from: location }, replace: true })
            }

            if (menu_permission._view == '0') navigate(`${ReverseProxy['proxyUrl']}/builder/projects`, { state: { from: location }, replace: true })

            setHas_view_access(menu_permission._view == '1')
            setHas_add_access(menu_permission._create == '1')
            setHas_edit_access(menu_permission._edit == '1')
            setHas_delete_access(menu_permission._delete == '1')

            setSelected_view_settings_value(menu_permission.selected_view_settings_value)

            if (bulk_upload_menu_permission._view == '1') {
                const company_features = store.user?.company_features || []

                let _has_bulk_upload_access = company_features?.some((item) => item.feature_key == 'project_management--tasks_bulk_upload' && item._view == '1')

                if (_has_bulk_upload_access) {

                    setHas_bulk_upload_access(true)

                    let filters = { ...Filters }

                    let dropdown_filter = filters.right.filter(f => f.id == 'estimate-options-dropdown')

                    if (dropdown_filter) {

                        let dropdown_filter_idx = filters.right.findIndex(f => f.id == 'estimate-options-dropdown')

                        filters.right[dropdown_filter_idx].dropDownOptions = [
                            {
                                id: 'upload',
                                type: 'option',
                                label: 'Upload bulk tasks',
                                value: 'upload',
                                isIcon: true,
                                icon: Icons.general.upload,
                            },
                            {
                                id: 'download',
                                type: 'option',
                                label: 'Download Task Extract',
                                value: 'download',
                                isIcon: true,
                                icon: Icons.general.download,
                            },
                        ]



                    }

                    setFilters(filters)
                } else setHas_bulk_upload_access(false)

            } else setHas_bulk_upload_access(false)

        }

    }, [store.user.roles_permissions])

    const [tabelHeader, setTabelHeader] = useState([

        {
            id: 'select',
            label: 'Select',
            minWidth: 30,
            disable: false,
        },

        {
            id: 'name',
            label: 'Name',
            minWidth: 200,
            disable: false,
        },
        {
            id: 'worktype_name',
            label: 'Work Type',
            disable: false,

        },
        {
            id: 'priority_name',
            label: 'Priority',
            disable: false,

        },
        {
            id: 'status',
            label: 'Status',
            disable: false,
        },
        {
            id: 'startdate',
            label: 'Start',
            disable: false,
        },
        {
            id: 'enddate',
            label: 'End',
            disable: false,
        },
        {
            id: 'assignee_name',
            label: 'Assignee',
            disable: false,
        },

        {
            id: 'work_done_progress_percentage',
            label: 'Completion',
            disable: false,
            format: (value) => `${value}%`,
        },
        {
            id: 'total_task_price',
            label: 'Price',
            format: (value) => `${currency_type}${parseFloat(value || 0).toFixed(2)}`,
            disable: false,
        },
        {
            id: 'actions',
            label: '',
            disable: false,
        },
    ])
    const [TasksOrginal, setTasksOrginal] = useState([])
    const [tasks, setTasks] = useState([])
    const [selectedAction, setSelectedAction] = useState('')

    const [tableSelectAll, setTableSelectAll] = useState(false)

    const [isAddBtnClicked, setIsAddBtnClicked] = useState(false)
    const [addBtnOption, setAddBtnOption] = useState({})
    const [existItemData, setExistItemData] = useState({})


    const [SelectedPhaseSection, setSelectedPhaseSection] = useState('all')

    const [ProjectPhases, setProjectPhases] = useState([])
    const [ProjectPhases_Options, setProjectPhases_Options] = useState([])

    const HandlePhaseSection = (section) => {
        setSelectedPhaseSection(section)
    }

    const [ActionDropdown, setActionDropdown] = useState([
        {
            id: 'edit',
            label: 'Edit',
            icon: Icons.general.edit,
            click: (row) => HandleOpenItem(row)
        },
        {
            id: 'delete',
            label: 'Delete',
            icon: Icons.general.delete,
            click: (row) => HandleDeleteItem(row)
        },
    ])

    const AddBtnOptions = [
        {
            icon: Icons.general.group,
            label: 'Parent task',
            type: 'parent_task',
            id: "parent_task"
        },
        {
            icon: Icons.general.tasks,
            label: 'Task',
            type: 'task',
            id: "task"
        },

    ]

    const HandleOpenItem = (item) => {

        if (!has_edit_access) return
        setExistItemData(item)
        setAddBtnOption(item.type)

        navigate(`/builder/projects/${projectId}/task-management/${item.type}/${item.sno}/edit`)
    }

    const HanelCellAction = (id, row) => {

        if (id == "actions") {
            setSelectedAction(row.id)
            HandleActionDisable(row.id)
        }
    }
    const HandleActionDisable = (id) => {

        let input = document.getElementById(`estimate-tabel-action-btn-${id}`)
        let dropdown_items = document.getElementById(`estimate-tabel-action-dropdown-${id}`)

        const Event = (e) => {
            let path = e.path || (e.composedPath && e.composedPath());

            if (Array.isArray(path) && !path.includes(dropdown_items) && !path.includes(input)) {
                setSelectedAction('')
                window.removeEventListener('click', Event)
            }
        }

        window.addEventListener('click', Event)
    }

    const HandleSelectAllEvent = (value) => {
        setTableSelectAll(value)

        let _tasks = [...tasks]

        const selectItem = (items, value) => {

            items = items.map(item => {

                item.selected = value
                if (Array.isArray(item.sub_items)) item.sub_items = selectItem(item.sub_items, value)
                return item
            })

            return items
        }

        _tasks = selectItem(_tasks, value)


        setTasks(_tasks)
    }

    const HandleSelectEvent = (group_id, parent_id, value) => {

        let _tasks = [...tasks]

        const selectItem = (items, value) => {

            items = items.map(item => {

                item.selected = value
                if (item.type != 'parent_task' && Array.isArray(item.sub_items)) item.sub_items = selectItem(item.sub_items, value)
                return item
            })

            return items
        }

        const findAndUpdateGroup = (group_id, parent_id, _tasks, value) => {

            _tasks = _tasks.map((item, idx) => {

                if (item.id == group_id && item.parent_id == parent_id) {
                    item.selected = value
                    if (item.type != 'task' && Array.isArray(item.sub_items)) {
                        item.sub_items = selectItem(item.sub_items, value)
                    }
                }
                else if (item.type != 'task' && Array.isArray(item.sub_items)) {
                    item.sub_items = findAndUpdateGroup(group_id, parent_id, item.sub_items, value)
                }

                return item
            })

            return _tasks
        }

        _tasks = findAndUpdateGroup(group_id, parent_id, _tasks, value)
        setTasks(_tasks)
    }

    const getTypeEenem = (type) => {

        if (type == '1') return 'parent_task'
        else if (type == '2') return 'task'
        else return ''

    }
    const getStatusEenem = (type, delay) => {

        if (type == '0') return 'No dates added'
        else if (type == '1') return 'Upcoming task'
        else if (type == '2') return 'Not started'
        else if (type == '3') return 'In Progress'
        else if (type == '4') return 'Completed'
        else if (type == '5') return `Delayed by ${delay}`
        else if (type == '6') return 'on Hold'
        else if (type == '7') return 'Stopped'
        else return '-'
    }

    const getSubItem = (items, parentid) => {

        let subItems = []

        let getItems = (items, parentid) => {

            if (!Array.isArray(items)) return

            let _items = items.filter(itm => itm.parent_id == parentid)

            subItems.push(..._items)

            _items.forEach(itm => getItems(items, itm.id))
        }

        getItems(items, parentid)

        return subItems
    }

    const getStatus = (item) => {

        if (
            !item.startdate
            || !item.enddate
        ) return '0' // No dates added
        else if (
            new Date().getTime() < new Date(item.startdate).getTime()
            && new Date().getTime() < new Date(item.enddate).getTime()
        ) return '1' // Upcoming task
        else if (
            new Date().getTime() > new Date(item.startdate).getTime()
            && new Date().getTime() < new Date(item.enddate).getTime()
            && item.work_done_progress == '0'
        ) return '2'  // Not started
        else if (
            new Date().getTime() > new Date(item.startdate).getTime()
            && new Date().getTime() < new Date(item.enddate).getTime()
            && item.work_done_progress != '0'
            && item.work_done_progress < item.total_work_progress
        ) return '3' // In Progress
        else if (
            item.work_done_progress == item.total_work_progress
        ) return '4' // Completed
        else if (
            new Date().getTime() > new Date(item.enddate).getTime()
            && item.work_done_progress != item.total_work_progress
        ) return '5' // Delayed by days
        else if (
            item.status == '3'
        ) return '6' // on Hold
        else if (
            item.status == '4'
        ) return '7' // Stopped

    }

    const getFormatedItem = (item) => {

        return {
            ...item,
            sno: item.sno,
            expanded: true,
            selected: false,
            disable: false,

            _type: item.type,
            type: getTypeEenem(item.type),
            phases: item.phases || [],
            sub_items: []
        }

    }

    const getOrderedTableItems = (items = []) => {
        const groupedItems = {};

        for (const item of items) {

            const parent_id = item.parent_id;

            if (parent_id != null) {

                if (!groupedItems[parent_id]) groupedItems[parent_id] = [];

                groupedItems[parent_id].push(item);
            }
        }

        for (const item of items) {
            const itemId = item.id;
            if (groupedItems[itemId]) {
                item.sub_items = groupedItems[itemId];
            }
        }

        return items.filter(d => !d.parent_id)

    }


    const LoadTaskItems = async () => {

        let filter = {
            projectId: projectId
        }

        setIsLoading(true)

        let response = await taskManagementHandler.getTasksHandler(filter)
        setIsLoading(false)

        if (response.success) {


            let { data } = response

            if (!Array.isArray(data)) return

            let items = data.map(d => {

                let item = getFormatedItem(d)
                let subItems = getSubItem(data, d.id).map(itm => getFormatedItem(itm))

                let subItems_Total = subItems.reduce((prev, val, ind) => {

                    return {
                        total_task_price: prev.total_task_price + val.total_task_price,
                        work_done_progress: prev.work_done_progress + ((parseFloat(val.work_done_progress) / parseFloat(val.total_work_progress) * 100)),
                    }

                }, { total_task_price: 0, work_done_progress: 0 })

                item.work_done_progress_percentage = parseFloat(((parseFloat(item.work_done_progress) / parseFloat(item.total_work_progress) * 100)) + subItems_Total.work_done_progress || 0).toFixed(2)
                item.total_task_price = parseFloat(item.total_task_price + subItems_Total.total_task_price || 0).toFixed(2)

                let duration_days = Utils.getDuration(new Date().getTime(), (new Date(item.enddate).getTime() || 0), 'days')

                let status = getStatusEenem(getStatus(item), `${duration_days} days`)
                item._status = getStatus(item)
                item.status = status
                return item

            })

            items = getOrderedTableItems(items)

            setTasksOrginal(data)
            setTasks(items)

            HandleSmartCardSetup(data)
        }
        else {
            setWarningAlert(true)
            setWarningAlertType('error')
            setApiFailedMessage(`Error in getting tasks`)
        }
    }


    useEffect(() => {
        LoadStoreData()
        LoadTaskItems()
    }, [])

    useEffect(() => {


        if (store && store.user) {

            let { company, selected_project, selected_project_phases } = store.user

            if (selected_project) {

                let { id, name } = selected_project

                setProject_id(id)
                setProject_name(name)
            }

            if (company) {

                let { currency_setting, timezone, temperature_setting } = company

                if (currency_setting) setCurrency_type(currency_setting)
            }
            if (selected_project_phases) {

                setProjectPhases(selected_project_phases)

                let project_phases = selected_project_phases.map(phase => {
                    return {
                        id: phase.id,
                        name: `${phase.name} | ${phase.client_name}`,
                        count: 0
                    }
                })

                setProjectPhases_Options([
                    {
                        id: 'all',
                        name: 'All',
                        count: 0
                    },
                    ...project_phases
                ])

            }


        }


    }, [store])


    const HandleUpdateCallback = () => {
        setShowUploadPopup(false)
        LoadTaskItems()
    }


    const HandleExpandGroup = (group_id, parent_id) => {

        let _tasks = [...tasks]

        const findAndUpdateGroup = (group_id, parent_id, _tasks) => {

            _tasks = _tasks.map((item, idx) => {

                if (item.id == group_id && item.parent_id == parent_id) {
                    item.expanded = !item.expanded
                }
                else if (item.type != 'task' && Array.isArray(item.sub_items)) {
                    item.sub_items = findAndUpdateGroup(group_id, parent_id, item.sub_items)
                }

                return item
            })

            return _tasks
        }

        _tasks = findAndUpdateGroup(group_id, parent_id, _tasks)

        setTasks(_tasks)
    }

    const CreateEstimateTableItem = (row, idx, sub_space_px, parent_expand = true) => {


        let { id, disable, type, parent_id, sub_items, expanded, _status } = row

        sub_items = sub_items || []

        return (
            <>
                {!disable ?
                    <>
                        <TableRow
                            hover
                            role="checkbox"
                            tabIndex={-1}
                            key={id}
                            style={{ display: parent_expand ? 'table-row' : 'none' }}
                            className={`estimate-table-row ${type != 'task' ? !parent_id ? 'estimate-table-row-group' : '' : ''}`}
                        >
                            {tabelHeader.map((column) => {
                                const value = row[column.id];
                                return (

                                    column.disable ? '' :
                                        column.id == 'select' ?


                                            <TableCell
                                                key={column.id}
                                                align={column.align}
                                                className='estimate-table-row-item'
                                            >
                                                <RadioInput props={{
                                                    value: row.selected || false,
                                                    isIcon: false,
                                                    icon: "",
                                                    inputType: "checkbox",
                                                    name: "table-default-radio",
                                                    setValue: (value) => HandleSelectEvent(id, parent_id, value)
                                                }} />

                                            </TableCell>

                                            :
                                            <TableCell
                                                key={column.id}
                                                align={column.align}
                                                onClick={(e) => {
                                                    if (column.id != 'actions') HanelCellAction(column.id, row)
                                                }}
                                                className='estimate-table-row-item'
                                                style={column.id == 'name' ? { paddingLeft: `${sub_space_px}px` } : {}}
                                            >
                                                {
                                                    column.id == 'name' ?
                                                        <div
                                                            className="tabel-name-main"
                                                        >
                                                            {type != 'task' ?
                                                                <div
                                                                    className={`tabel-row-arrow ${expanded ? 'tabel-row-arrow-open' : ''} `}
                                                                    dangerouslySetInnerHTML={{ __html: Icons.general.group_arrow }}
                                                                    onClick={() => HandleExpandGroup(id, parent_id)}
                                                                ></div>
                                                                : ''}

                                                            <div
                                                                className="tabel-name"
                                                                onClick={(e) => HandleOpenItem(row)}
                                                            > {column.format ? column.format(value) : value}</div>

                                                        </div>

                                                        : column.id == 'status' ?

                                                            <div
                                                                className="tabel-status-main"
                                                            >
                                                                <div className={`task-tabel-status task-tabel-status-${row._status}`}> {column.format ? column.format(value) : value}</div>

                                                            </div>

                                                            : column.id == 'actions' ?

                                                                <div
                                                                    className="tabel-action-main"
                                                                >
                                                                    {has_view_access ?
                                                                        <div
                                                                            className="action-icon"
                                                                            dangerouslySetInnerHTML={{ __html: Icons.general.view_btn }}
                                                                            onClick={(e) => HandleOpenItem(row)}
                                                                        ></div>
                                                                        : ''}
                                                                    {has_edit_access ?
                                                                        <div
                                                                            className="action-icon"
                                                                            dangerouslySetInnerHTML={{ __html: Icons.general.edit_btn }}
                                                                            onClick={(e) => HandleOpenItem(row)}
                                                                        ></div>
                                                                        : ''}
                                                                    {has_delete_access ?
                                                                        <div
                                                                            className="action-icon"
                                                                            dangerouslySetInnerHTML={{ __html: Icons.general.delete_btn }}
                                                                            onClick={(e) => HandleDeleteItem(row)}
                                                                        ></div>
                                                                        : ''}

                                                                </div>
                                                                : column.format ? column.format(value) : value
                                                }
                                            </TableCell>
                                );
                            })}
                        </TableRow>
                        {sub_items.map((item, idxx) => (
                            CreateEstimateTableItem(item, idxx, sub_space_px + 20, parent_expand ? expanded : parent_expand)
                        ))}
                    </>
                    : ''}
            </>
        )
    }

    const HandleDeleteItem = async (item) => {
        if (!has_edit_access || !has_delete_access) return
        setDeleteItem(item.id)
        setDeleteItemType(item.type)
        setDeleteItemAlert(true)
    }

    const getSubItemIds = (items, parentid) => {

        let subItems = []

        let getItems = (items, parentid) => {

            if (!Array.isArray(items)) return

            let _items = items.filter(itm => itm.parent_id == parentid)

            subItems.push(..._items)

            _items.forEach(itm => getItems(items, itm.id))
        }

        getItems(items, parentid)

        return subItems.map(itm => itm.id)
    }

    const HandleDeleteConfirmItem = async (id, type, confirmation) => {

        console.log(confirmation, 'confirmation');
        if (!confirmation || !id) {

            setDeleteItem(null)
            setDeleteItemType(null)
            setDeleteItemAlert(false)

            return
        }


        let ids = getSubItemIds([...TasksOrginal], id)

        let filter = {
            projectId,
            ids: [id, ...ids],
        }

        setIsLoading(true)

        let response = await taskManagementHandler.deleteTaskHandler(filter)
        setIsLoading(false)

        if (response.success) {
            LoadTaskItems()
        }
        else {
            setWarningAlert(true)
            setWarningAlertType('error')
            setApiFailedMessage(`Error in deleting task`)
        }
        setDeleteItem(null)
        setDeleteItemType(null)
        setDeleteItemAlert(false)
    }

    const HandleAddBtn = (e) => {
        if (!has_add_access) return
        setIsAddBtnClicked(true)

        let items_parent = document.querySelector('#pd_estimate-addbtn')

        window.addEventListener('click', (e) => {

            let path = e.path || (e.composedPath && e.composedPath());

            if (Array.isArray(path) && !path.includes(items_parent))
                setIsAddBtnClicked(false)
        })
    }
    const HandleAddBtnOption = (option) => {
        if (!has_add_access || !option) return
        setAddBtnOption(option)

        navigate(`/builder/projects/${projectId}/task-management/${option.id}/add`)
    }

    const getDeleteMessage = (type) => {

        if (type == "parent_task") return `Are you sure you want to delete the Parent? Items included in the task will be deleted accordingly.`
        else if (type == "task") return `Are you sure you want to delete the Task?`
        else return "Are you sure you want to delete?"
    }

    const [ShowUploadPopup, setShowUploadPopup] = useState(false)

    const [ShowSideFilter, setShowSideFilter] = useState(false)
    const [SideFilterSettings, setSideFilterSettings] = useState([
        {
            id: 'selectshow-filter',
            type: 'multiselect',
            value: true,
            placeholder: '',

            selectoptions: [
                {
                    id: 'select-filteroption-selectshow-all',
                    type: 'checkbox',
                    key: `all`,
                    value: true,
                    placeholder: '',
                    classNames: [],
                    isIconLeft: false,
                    isIcon: false,
                    icon: '',
                    isLabel: true,
                    label: 'Show select box',
                },
            ],

            callback: (e) => { },
            classNames: [],
            isIconLeft: true,
            isIcon: true,
            icon: Icons.general.multi_select,
            isLabel: true,
            label: 'Show Selection',
        },

        {
            id: 'filterby-filter',
            type: 'multiselect',
            value: true,
            placeholder: '',

            selectoptions: [
                {
                    id: 'select-filteroption-filterby-all',
                    type: 'checkbox',
                    key: `all`,
                    value: true,
                    placeholder: '',
                    classNames: [],
                    isIconLeft: false,
                    isIcon: false,
                    icon: '',
                    isLabel: true,
                    label: 'All',
                },
                {
                    id: 'select-filteroption-filterby-no_dates_added',
                    type: 'checkbox',
                    key: `0`,
                    category: '_status',
                    value: true,
                    placeholder: '',
                    classNames: [],
                    isIconLeft: false,
                    isIcon: false,
                    icon: '',
                    isLabel: true,
                    label: 'No dates added',
                },
                {
                    id: 'select-filteroption-filterby-upcoming',
                    type: 'checkbox',
                    key: `1`,
                    category: '_status',
                    value: true,
                    placeholder: '',
                    classNames: [],
                    isIconLeft: false,
                    isIcon: false,
                    icon: '',
                    isLabel: true,
                    label: 'Upcoming',
                },
                {
                    id: 'select-filteroption-filterby-not_started',
                    type: 'checkbox',
                    key: `2`,
                    category: '_status',
                    value: true,
                    placeholder: '',
                    classNames: [],
                    isIconLeft: false,
                    isIcon: false,
                    icon: '',
                    isLabel: true,
                    label: 'Not started',
                },
                {
                    id: 'select-filteroption-filterby-in_progress',
                    type: 'checkbox',
                    key: `3`,
                    category: '_status',
                    value: true,
                    placeholder: '',
                    classNames: [],
                    isIconLeft: false,
                    isIcon: false,
                    icon: '',
                    isLabel: true,
                    label: 'In Progress',
                },
                {
                    id: 'select-filteroption-filterby-completed',
                    type: 'checkbox',
                    key: `4`,
                    category: '_status',
                    value: true,
                    placeholder: '',
                    classNames: [],
                    isIconLeft: false,
                    isIcon: false,
                    icon: '',
                    isLabel: true,
                    label: 'Completed',
                },
                {
                    id: 'select-filteroption-filterby-delayed',
                    type: 'checkbox',
                    key: `5`,
                    category: '_status',
                    value: true,
                    placeholder: '',
                    classNames: [],
                    isIconLeft: false,
                    isIcon: false,
                    icon: '',
                    isLabel: true,
                    label: 'Delayed',
                },
                {
                    id: 'select-filteroption-filterby-on_hold',
                    type: 'checkbox',
                    key: `6`,
                    category: '_status',
                    value: true,
                    placeholder: '',
                    classNames: [],
                    isIconLeft: false,
                    isIcon: false,
                    icon: '',
                    isLabel: true,
                    label: 'on Hold',
                },
                {
                    id: 'select-filteroption-filterby-stopped',
                    type: 'checkbox',
                    key: `7`,
                    category: '_status',
                    value: true,
                    placeholder: '',
                    classNames: [],
                    isIconLeft: false,
                    isIcon: false,
                    icon: '',
                    isLabel: true,
                    label: 'Stopped',
                },
            ],

            callback: (e) => { },
            classNames: [],
            isIconLeft: true,
            isIcon: true,
            icon: Icons.general.filter_by,
            isLabel: true,
            label: 'Filter by Status',
        },

        {
            id: 'showtable-filter',
            type: 'multiselect',
            value: true,
            placeholder: '',

            selectoptions: [
                {
                    id: 'select-filteroption-showtable-all',
                    type: 'checkbox',
                    key: `all`,
                    value: true,
                    placeholder: '',
                    classNames: [],
                    isIconLeft: false,
                    isIcon: false,
                    icon: '',
                    isLabel: true,
                    label: 'All',
                },
                {
                    id: 'select-filteroption-showtable-name',
                    type: 'checkbox',
                    value: true,
                    key: `name`,
                    placeholder: '',
                    classNames: [],
                    isIconLeft: false,
                    isIcon: false,
                    icon: '',
                    isLabel: true,
                    label: 'Name',
                },

                {
                    id: 'select-filteroption-showtable-worktype_name',
                    type: 'checkbox',
                    value: true,
                    key: `worktype_name`,
                    placeholder: '',
                    classNames: [],
                    isIconLeft: false,
                    isIcon: false,
                    icon: '',
                    isLabel: true,
                    label: 'Work Type',
                },
                {
                    id: 'select-filteroption-showtable-priority_name',
                    type: 'checkbox',
                    value: true,
                    key: `priority_name`,
                    placeholder: '',
                    classNames: [],
                    isIconLeft: false,
                    isIcon: false,
                    icon: '',
                    isLabel: true,
                    label: 'Priority',
                },
                {
                    id: 'select-filteroption-showtable-status',
                    type: 'checkbox',
                    value: true,
                    key: `status`,
                    placeholder: '',
                    classNames: [],
                    isIconLeft: false,
                    isIcon: false,
                    icon: '',
                    isLabel: true,
                    label: 'Status',
                },
                {
                    id: 'select-filteroption-showtable-startdate',
                    type: 'checkbox',
                    value: true,
                    key: `startdate`,
                    placeholder: '',
                    classNames: [],
                    isIconLeft: false,
                    isIcon: false,
                    icon: '',
                    isLabel: true,
                    label: 'Start',
                },
                {
                    id: 'select-filteroption-showtable-enddate',
                    type: 'checkbox',
                    value: true,
                    key: `enddate`,
                    placeholder: '',
                    classNames: [],
                    isIconLeft: false,
                    isIcon: false,
                    icon: '',
                    isLabel: true,
                    label: 'End',
                },
                {
                    id: 'select-filteroption-showtable-assignee_name',
                    type: 'checkbox',
                    value: true,
                    key: `assignee_name`,
                    placeholder: '',
                    classNames: [],
                    isIconLeft: false,
                    isIcon: false,
                    icon: '',
                    isLabel: true,
                    label: 'Assignee',
                },
                {
                    id: 'select-filteroption-showtable-work_done_progress_percentage',
                    type: 'checkbox',
                    value: true,
                    key: `work_done_progress_percentage`,
                    placeholder: '',
                    classNames: [],
                    isIconLeft: false,
                    isIcon: false,
                    icon: '',
                    isLabel: true,
                    label: 'Completion',
                },
                {
                    id: 'select-filteroption-showtable-total_task_price',
                    type: 'checkbox',
                    value: true,
                    key: `total_task_price`,
                    placeholder: '',
                    classNames: [],
                    isIconLeft: false,
                    isIcon: false,
                    icon: '',
                    isLabel: true,
                    label: 'Price',
                },
            ],

            callback: (e) => { },
            classNames: [],
            isIconLeft: true,
            isIcon: true,
            icon: Icons.general.show_hide,
            isLabel: true,
            label: 'Show Tables',
        },

    ])

    const HandleFilterAllEvent = (value) => {

        let _tasks = [...tasks]

        const selectItem = (items, value) => {

            items = items.map(item => {

                if (item._type != '1') item.disable = value
                if (Array.isArray(item.sub_items)) item.sub_items = selectItem(item.sub_items, value)
                return item
            })

            return items
        }

        _tasks = selectItem(_tasks, value)


        setTasks(_tasks)
    }

    const HandleSideFilterClose = () => {
        setShowSideFilter(false)
    }
    const HandleSideFilterUpdateCallback = (filters) => {

        let _tasks = [...tasks]

        filters.forEach((filter, fidx) => {

            if (filter.type == 'multiselect') {

                if (filter.id == 'selectshow-filter') {

                    filter.selectoptions.forEach((option, oidx) => {

                        if (option.key == 'all') {

                            let _tabelHeader = [...tabelHeader]

                            _tabelHeader[0].disable = !option.value

                            setTabelHeader(_tabelHeader)

                        }

                    })
                }
                else if (filter.id == 'filterby-filter') {

                    filter.selectoptions.forEach((option, oidx) => {

                        if (option.key == 'all') HandleFilterAllEvent(!option.value)
                        else {
                            let filter_by = (items, key, validate_value, value) => {

                                const filterItem = (items, key, validate_value, value) => {

                                    items = items.map(item => {

                                        if (item._type != '1' && item[key] == validate_value) {

                                            item.disable = !value
                                        }
                                        if (Array.isArray(item.sub_items)) item.sub_items = filterItem(item.sub_items, key, validate_value, value)

                                        return item
                                    })

                                    return items
                                }

                                items = filterItem(items, key, validate_value, value)

                                return items
                            }
                            _tasks = filter_by(_tasks, option.category, option.key, option.value)



                        }

                    })
                }

                else if (filter.id == 'showtable-filter') {

                    filter.selectoptions.forEach((option, oidx) => {

                        if (option.key == 'all') {

                            let _tabelHeader = [...tabelHeader]

                            _tabelHeader = _tabelHeader.map((th, i) => {
                                if (i > 0) th.disable = false
                                return th
                            })

                            setTabelHeader(_tabelHeader)
                        }
                        else {
                            let _tabelHeader = [...tabelHeader]

                            let head_idx = _tabelHeader.findIndex(h => h.id == option.key)

                            if (head_idx != -1) {
                                _tabelHeader[head_idx].disable = !option.value
                                setTabelHeader(_tabelHeader)
                            }
                        }

                    })
                }
            }

        })

        console.log(_tasks, '_tasks');

        setTasks(_tasks)

        setSideFilterSettings(filters)
        HandleSideFilterClose()
    }


    const [Filters, setFilters] = useState({
        left: [


            {
                id: "estimate-search",
                value: '',
                isSearchBar: true,
                type: "input",
                isDropDownContainer: false,
                placeholder: "Search by name",
                dropDownOptions: [],
                callback: (val) => { },

                isIcon: true,
                icon: Icons.general.search,
                isLabel: false,
                label: "",
                isIconLeft: false
            },
            {
                id: "estimate-expand",
                value: true,
                type: "icon",
                isSelectToggle: true,
                placeholder: "",
                callback: (val) => { },
                isIcon: true,
                icon: Icons.general.expand,
                isLabel: true,
                label: "Expand all",
                isIconLeft: true
            },
        ],
        right: [
            {
                id: "estimate-morefilter",
                type: "icon",
                isSelectToggle: true,
                value: true,
                placeholder: "",
                callback: (val) => HandleFiltersEvent(val, 'estimate-morefilter'),
                isIcon: true,
                icon: Icons.general.filter_funnel,
                isLabel: true,
                label: "Filters",
                isIconLeft: true
            },
            {
                id: "estimate-options-dropdown",
                type: "button",
                isSelectToggle: true,
                value: true,
                placeholder: "",
                callback: (val) => HandleFiltersEvent(val, 'estimate-options-dropdown'),
                isIcon: true,
                icon: Icons.general.dotted_action,
                isLabel: false,
                label: "Upload",
                isIconLeft: true,
                isDropDownContainer: true,
                dropDownOptions: [
                    {
                        id: 'download',
                        type: 'option',
                        label: 'Download Task Extract',
                        value: 'download',
                        isIcon: true,
                        icon: Icons.general.download,
                    }
                ]
            },

        ]
    })


    const HandleDownloadEvent = () => {

        if (!tasks.length) return

        let _tasks = [...tasks]

        let ordered_items = []

        let flatter_subitems = (items) => {

            if (Array.isArray(items)) {

                items.forEach(item => {

                    ordered_items.push(item)

                    if (Array.isArray(item.sub_items)) flatter_subitems(item.sub_items)

                })
            }

        }
        flatter_subitems(_tasks)

        let table_header = [...tabelHeader]

        table_header.splice(0, 1)
        table_header.splice(table_header.length - 1, 1)


        let export_header = table_header.filter(th => !th.disable).map(th => {

            let id = ['quantity', 'unitrate', 'builder_price', 'markup_amount', 'tax_amount', 'price'].includes(th.id) ? `_${th.id}` : th.id

            return {
                id: id,
                label: th.label,
            }
        })

        export_header.splice(1, 0, ...[
            {
                id: 'parent_name',
                label: "Parent Name",
            },
        ])

        Utils.exportEstimateToExcel(export_header, ordered_items, `${project_name}_PLAN_EXTRACT`)

    }

    const HandleFiltersEvent = async (value, filter_id) => {

        if (!filter_id) return

        if (filter_id == 'estimate-expand') {

            let filters = { ...Filters }

            filters.left[1].value = value

            let _tasks = [...tasks]

            let expand_by_group = (items, value) => {

                const expandItem = (items, value) => {

                    items = items.map(item => {

                        if (item.type != 'item') item.expanded = value
                        if (Array.isArray(item.sub_items)) item.sub_items = expandItem(item.sub_items, value)

                        return item
                    })

                    return items
                }

                items = expandItem(items, value)

                return items
            }

            _tasks = expand_by_group(_tasks, value)
            setTasks(_tasks)

            setFilters(filters)

        }

        else if (filter_id == 'estimate-search') {

            let filters = { ...Filters }

            filters.left[0].value = value

            let _tasks = [...tasks]

            let search_items = (items, value, column = 'name') => {

                let search_item = (items, value, column = 'name') => {

                    items = items.map(item => {

                        let is_item_match = String(item[column]).toLocaleLowerCase().includes(String(value).toLocaleLowerCase())

                        if (Array.isArray(item.sub_items)) item.sub_items = search_item(item.sub_items, value, column)

                        let is_items_match = item.sub_items.filter(s => !s.disable).length > 0

                        if (is_items_match) item.disable = false
                        else if (is_item_match) item.disable = false
                        else item.disable = true

                        return item

                    })

                    return items

                }

                items = search_item(items, value, column)
                return items
            }

            _tasks = search_items(_tasks, value, 'name')

            setTasks(_tasks)

            setFilters(filters)

        }
        else if (filter_id == 'estimate-morefilter') {
            setShowSideFilter(true)
        }
        else if (filter_id == 'estimate-options-dropdown') {

            if (!value) return

            if (value.id == 'download') HandleDownloadEvent()
            if (value.id == 'upload' && has_bulk_upload_access) setShowUploadPopup(true)

        }

    }

    const isLeftFilter = Array.isArray(Filters.left) && Filters.left.length > 0
    const isRightFilter = Array.isArray(Filters.right) && Filters.right.length > 0

    const Icon_Filter = ({ props }) => {
        const {
            id,
            isSearchBar,
            type,
            value,
            isSelectToggle,
            isDropDownContainer,
            dropDownOptions,
            placeholder,
            callback,
            isIcon,
            icon,
            isLabel,
            label,
            isIconLeft,
        } = props

        const [iconStatus, setIconStatus] = useState(value != undefined ? value : false)

        const HandleFilterIconInput = (e) => {


            HandleFiltersEvent(!iconStatus, id)
            setIconStatus(!iconStatus)
        }

        return (
            <>
                {isIcon && type == 'icon' ?
                    <div
                        className="filter-item group-expand-filter"
                        onClick={(e) => HandleFilterIconInput(e)}
                        key={`filter-item-${id}`}
                    >
                        <div
                            className="icon"
                            dangerouslySetInnerHTML={{ __html: id.includes('expand') ? iconStatus ? Icons.general.collapse : Icons.general.expand : icon }}
                        ></div>
                        {isLabel ? <div className="label">{id.includes('expand') ? iconStatus ? 'Collapse all' : 'Expand all' : label}</div> : ''}
                    </div>

                    : null}
            </>
        )
    }
    const Dropdown_Filter = ({ props }) => {
        const {
            id,
            isSearchBar,
            type,
            isDropDownContainer,
            dropDownOptions,
            callback,
            isIcon,
            icon,
            isLabel,
            label,
            isIconLeft,
        } = props
        const [selectFilterOpts, setSelectFilterOpts] = useState([])

        const [dropdownStatus, setDropDownStatus] = useState(false)

        const HandleClick = (e) => {
            setDropDownStatus(!dropdownStatus)
        }

        let HandleSubItemClick = (opt) => {

            HandleFiltersEvent(opt, id)

            setDropDownStatus(false)
        }

        useEffect(() => {

            let items_parent = document.querySelector(`#${id}`)

            window.addEventListener('click', (e) => {

                let path = e.path || (e.composedPath && e.composedPath());

                if (Array.isArray(path) && !path.includes(items_parent))
                    setDropDownStatus(false)
            })
            setSelectFilterOpts(dropDownOptions)
        }, [])


        return (
            <div className='filter-item dropdown-select-filter' id={id} key={`filter-item-${id}`}>
                <div
                    className="button"
                    onClick={(e) => HandleClick(e)}
                >
                    {isIcon && isIconLeft ?
                        <span
                            className={`icon ${dropdownStatus ? 'icon-active' : ''}`}
                            dangerouslySetInnerHTML={{ __html: icon }}
                        ></span>
                        : null}

                    {isLabel ? <span class='label'>{label}</span> : null}

                    {isIcon && !isIconLeft ?
                        <span
                            className={`icon ${dropdownStatus ? 'icon-active' : ''}`}
                            dangerouslySetInnerHTML={{ __html: icon }}
                        ></span>
                        : null}
                </div>

                {isDropDownContainer ?

                    <div className={`dropdown-select-items ${dropdownStatus ? 'dropdown-select-items-active' : ''}`}>

                        {selectFilterOpts.map((opt, i) => (

                            opt.type == 'label' ?
                                <div className="dropdown-select-title">{opt.label}</div>
                                :
                                <div
                                    className="dropdown-select-itm"
                                    onClick={() => HandleSubItemClick(opt)}
                                >
                                    {opt.isIcon ?
                                        <span
                                            className="icon"
                                            dangerouslySetInnerHTML={{ __html: opt.icon }}
                                        ></span>
                                        : ''}
                                    <label className='label' htmlFor={`filters-item-select-${opt.id}`}>{opt.label}</label>
                                </div>

                        ))}

                    </div>

                    : null}
            </div>

        )
    }


    const [SmarkCard_Items, setSmarkCard_Items] = useState([])

    const HandleSmartCardClick = (id) => { }

    const HandleSmartCardSetup = (items) => {

        if (!Array.isArray(items)) return

        items = items.filter(itm => itm.type == "2")

        let total_tasks = items.length
        let delayed_tasks = items.filter(itm => getStatus(itm) == '5').length
        let not_started_tasks = items.filter(itm => getStatus(itm) == '2').length

        let smark_cards = [
            {
                id: 'lm-smart-top-cards-total_tasks',
                label: 'Total Tasks',
                value: total_tasks || "0",
                icon: Icons.general.tasks,
                unit_name: '',
                style: {
                    color: '#0065ff',
                    bg_linear_colors: {
                        start: '#e5efff',
                        end: '#e5f9ff'
                    },
                    border_color: '#c6d4dc',
                },
            },
            {
                id: 'lm-smart-top-cards-delayed_tasks',
                label: 'Delayed Tasks',
                value: delayed_tasks || "0",
                icon: Icons.general.tasks,
                unit_name: '',
                style: {
                    color: '#33c87e',
                    bg_linear_colors: {
                        start: '#eaf9f2',
                        end: '#f2fef7'
                    },
                    border_color: '#ccd8d2',
                },
            },
            {
                id: 'lm-smart-top-cards-not_started_tasks',
                label: 'Not Started Tasks',
                value: not_started_tasks || "0",
                icon: Icons.general.tasks,
                unit_name: '',
                style: {
                    color: '#ff5b5b',
                    bg_linear_colors: {
                        start: '#ffeded',
                        end: '#fff9f9'
                    },
                    border_color: '#dcd1d1',
                },
            },
        ]

        setSmarkCard_Items(smark_cards)

    }



    return (
        <>

            {
                ShowSideFilter ?
                    <SideFilters
                        props={
                            {
                                heading: 'Filters',
                                filters: SideFilterSettings,
                                close: HandleSideFilterClose,
                                updateCallback: HandleSideFilterUpdateCallback
                            }
                        }
                    />
                    : ''
            }
            {
                ShowUploadPopup && has_bulk_upload_access ?
                    <UploadTaskFilePopup
                        props={{
                            close: () => HandleUpdateCallback(),
                            updateCallback: () => {
                                HandleUpdateCallback()
                            }
                        }}
                    />
                    : ''
            }
            {isLoading ?

                <Loading
                    props={{
                        isMainLogo: false,
                        isLabel: true
                    }} />

                : null}

            {warningAlert ?

                <SystemToastPopup
                    props={{
                        type: warningAlertType,
                        message: apiFailedMessage || "Error in Tasks",
                        callback: (confirmation) => setWarningAlert(false)
                    }} />

                : null}

            {deleteItemAlert ?

                <AlertPopup
                    props={{
                        type: "delete",
                        actionBtnOption: { label: "Yes, Delete" },
                        heading: "Delete item",
                        message: getDeleteMessage(deleteItemType),
                        callback: (confirmation) => HandleDeleteConfirmItem(deleteItemId, deleteItemType, confirmation)
                    }} />

                : null}

            <Routes>
                <Route path='/:itemType/:itemId/:actionType/*'
                    element={<AddTaskItemPopup
                        props={{
                            existItemData: existItemData || undefined,
                            updateCallback: HandleUpdateCallback,
                        }}
                    />}>
                </Route>
                <Route path='/:itemType/:actionType/*'
                    element={<AddTaskItemPopup
                        props={{
                            existItemData: undefined,
                            updateCallback: HandleUpdateCallback,
                        }}
                    />}
                ></Route>
            </Routes>

            <div className="project-project_estimate-main">
                <div className="project_estimate-panel-content">

                    {SmarkCard_Items.length ?
                        <SmarkTopCards props={{
                            id: 'labourManagement-smart-top-cards',
                            cards: SmarkCard_Items || [],
                            callback: HandleSmartCardClick
                        }} />
                        : ''}
                    <div className="smart-panel-content-main">
                        <div className="panel-content-header">
                            <div className="title">Task View</div>
                            {has_add_access ?
                                <div className="buttons">
                                    <div className="button-item">

                                        <div
                                            className="button add-button"
                                            onClick={(e) => HandleAddBtn(e)}
                                            id="pd_estimate-addbtn"
                                        >
                                            <div className="icon" dangerouslySetInnerHTML={{ __html: Icons.general.add_btn }}></div>
                                            <div className="label">Add Task</div>
                                        </div>
                                        <div id="pd_estimate-content-btn-options" className={`pd_estimate-content-btn-options ${isAddBtnClicked ? 'pd_estimate-content-btn-options-active' : ''}`}>

                                            {AddBtnOptions.map((option, i) => (
                                                <div
                                                    key={`estimate-add-option-${option.id}`}
                                                    className="btn-option-item"
                                                    onClick={(e) => HandleAddBtnOption(option)}
                                                >
                                                    <div
                                                        className="icon"
                                                        dangerouslySetInnerHTML={{ __html: option.icon }}
                                                    ></div>
                                                    <div className="label">{option.label}</div>
                                                </div>
                                            ))}

                                        </div>

                                    </div>
                                </div>
                                : ''}
                        </div>
                        <div className="filter-section-main">
                            {isLeftFilter ?

                                < div className="left-filter-main">
                                    {Filters.left.map((item, i) => (

                                        item.type == 'icon' ? <Icon_Filter props={item} />
                                            : item.type == 'button' ? <Dropdown_Filter props={item} />
                                                : item.isSearchBar && item.type == 'input' ?
                                                    <div className="search-filter">
                                                        <SearcBarInput
                                                            props={{
                                                                id: item.id,
                                                                value: item.value,
                                                                placeholder: item.placeholder || "Search by name",
                                                                setValue: (val) => {
                                                                    HandleFiltersEvent(val, item.id)
                                                                },
                                                                isIcon: item.isIcon,
                                                                isLeft: item.isIconLeft,
                                                                icon: item.icon || Icons.general.search
                                                            }}
                                                        />
                                                    </div>
                                                    : ''
                                    ))}
                                </div>
                                : ''}
                            {isRightFilter ?

                                < div className="right-filter-main">
                                    {Filters.right.map((item, i) => (

                                        item.type == 'icon' ? <Icon_Filter props={item} />
                                            : item.type == 'button' ? <Dropdown_Filter props={item} />
                                                : item.isSearchBar && item.type == 'input' ?
                                                    <div className="search-filter">
                                                        <SearcBarInput
                                                            props={{
                                                                id: item.id,
                                                                value: item.value,
                                                                placeholder: item.placeholder || "Search by name",
                                                                setValue: (val) => {
                                                                    HandleFiltersEvent(val, item.id)
                                                                },
                                                                isIcon: item.isIcon,
                                                                isLeft: item.isIconLeft,
                                                                icon: item.icon || Icons.general.search
                                                            }}
                                                        />
                                                    </div>
                                                    : ''
                                    ))}
                                </div>
                                : ''}
                        </div>
                        <div className="project-phases-filter-main">
                            <div className="phases-filter-header">
                                <div className="title">Project Phases</div>
                            </div>
                            <div className="phases-filter-items">
                                {ProjectPhases_Options.map((opt, i) => (
                                    <div
                                        key={`project-phases-filter-${opt.id}`}
                                        className={`phases_filter-item ${SelectedPhaseSection == opt.id ? 'phases_filter-item-active' : ''}`}
                                        onClick={(e) => HandlePhaseSection(opt.id)}
                                    >
                                        <div className="label">{opt.name}</div>
                                    </div>
                                ))}

                            </div>
                        </div>
                        <div className="panel-content-sections">
                            {tasks.filter(e => (SelectedPhaseSection == 'all' ? '' : SelectedPhaseSection) ? e.phases.includes(SelectedPhaseSection) : true).length ?
                                <Paper style={{ width: '100%', height: '100%', boxShadow: 'none', overflow: 'hidden' }}>
                                    <TableContainer style={{ height: '100%', boxShadow: 'none !important', }} >
                                        <Table stickyHeader style={{ padding: '0' }}>
                                            <TableHead
                                                className='tabel-header estimate-tabel-header'
                                            >
                                                <TableRow>
                                                    {tabelHeader.filter(th => !th.disable).map((column) => (

                                                        <TableCell
                                                            key={column.id}
                                                            align={column.align}
                                                            style={{
                                                                minWidth: column.minWidth,
                                                            }}
                                                            className='tabel-header-items'
                                                        >
                                                            {column.id == 'select' ?

                                                                <RadioInput props={{
                                                                    value: tableSelectAll || false,
                                                                    isIcon: false,
                                                                    icon: "",
                                                                    inputType: "checkbox",
                                                                    name: "table-default-radio",
                                                                    setValue: (value) => HandleSelectAllEvent(value)
                                                                }} />
                                                                :
                                                                column.label}
                                                        </TableCell>
                                                    ))}
                                                </TableRow>
                                            </TableHead>
                                            <TableBody className='tabel-body'>
                                                {tasks.filter(e => (SelectedPhaseSection == 'all' ? '' : SelectedPhaseSection) ? e.phases.includes(SelectedPhaseSection) : true).map((row, i) => (
                                                    CreateEstimateTableItem(row, i, 15, true)
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </Paper>
                                : <NoDataFound label={'No Tasks Found'} />}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )

}

export default TaskManagement;