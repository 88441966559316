import React, { useEffect, useState, useRef } from 'react'
import { Route, Routes, BrowserRouter, Link, useLocation, Navigate, Outlet, useNavigate, useParams } from 'react-router-dom';
import store from '../../redux/store'
import StoreHandler from '../../redux/StoreHandler'
import { useSelector, useDispatch } from 'react-redux'
import UserAction from '../../redux/action/userAction'

import ReverseProxy from '../../config/reverseProxy'
import DriveHandler from '../../Handlers/Drive/Drive';
import CommentsHandler from '../../Handlers/Comments/Comments';
import Icons from '../../assets/Icons'
import Images from '../../assets/Images'
import { CostInput, TextInput, SelectInput, RadioInput, MultiSelectInput } from '../../components/Inputs'
import Utils from '../../utils'
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

import AlertPopup from '../../components/AlertPopup'
import SystemToastPopup from '../../components/SystemToastPopup'
import Loading from '../../components/Loading'
import ShowToClient from '../../components/ShowToClient'

import { useAuth, useMenuAuth } from '../../hooks/useAuth'


const UploadFileUI = ({ props }) => {

    const { current_folder, close, updateCallback } = props


    const { projectId, selected_folder } = useParams()

    const driveHandler = new DriveHandler()

    const [isLoading, setIsLoading] = useState(false)
    const [warningAlert, setWarningAlert] = useState(false)
    const [warningAlertType, setWarningAlertType] = useState('warning')
    const [apiFailedMessage, setApiFailedMessage] = useState("Error in Server")
    const [currency_type, setCurrency_type] = useState('₹')

    const navigate = useNavigate()
    const store = useSelector((store) => store)
    const dispatch = useDispatch()

    const FileInputRef = useRef()


    const HandleUploadFileSelect = (e) => {
        if (FileInputRef.current) FileInputRef.current.click()
    }
    const HandleUploadFile = async (e) => {

        let file = e.target.files[0]

        if (!file) return

        e.preventDefault()

        const formData = new FormData();

        formData.append('projectId', projectId)
        formData.append('folder_id', selected_folder || "")
        formData.append('attachment', file)

        setIsLoading(true)

        let response = await driveHandler.uploadFileHandler(formData)

        setIsLoading(false)

        if (response.success) {

            if (typeof updateCallback == 'function') updateCallback()
            close()
        }
        else {

            if (response.file_exist) {
                setWarningAlert(true)
                setWarningAlertType('warning')
                setApiFailedMessage(`File already exist, Please try with different name`)

            } else {
                setWarningAlert(true)
                setWarningAlertType('error')
                setApiFailedMessage(`Error in uploading file`)
            }


        }

    }

    const HandleCancel = () => {
        close()
    }

    const Popup_Header = () => {
        return (
            <div className="side-popup-header">
                <div className="header-item-select">
                    <div className="header-item-select-content">
                        <span className="icon" dangerouslySetInnerHTML={{ __html: Icons.general.files }}></span>
                        <div className="label">Upload File</div>

                    </div>
                </div>
                <div
                    className="header-item-close"
                    onClick={(e) => HandleCancel()}
                    dangerouslySetInnerHTML={{ __html: Icons.general.close }}
                ></div>
            </div>
        );
    };

    useEffect(() => {

    }, [])

    useEffect(() => {


    }, [store])

    return (
        <div className="popup-container-main popup-container-center">
            <div className="popup-block-ui"></div>

            {isLoading ?
                <Loading
                    props={{
                        isMainLogo: false,
                        isLabel: true
                    }} />
                : null}
            {warningAlert ?

                <SystemToastPopup
                    props={{
                        type: warningAlertType,
                        message: apiFailedMessage || "Error in Clients",
                        callback: (confirmation) => setWarningAlert(false)
                    }} />

                : null}


            <div className="side-popup-container">

                <Popup_Header />

                <div className="sidebar-popup-content">

                    <div className="file-upload-section-main">

                        <div className="upload-dragdown-main">

                            <div className="upload-dragdown-input"
                                onClick={(e) => HandleUploadFileSelect()}
                            >
                                <input
                                    className='upload-file-input'
                                    type="file"
                                    id="upload-file-input"
                                    ref={FileInputRef}
                                    onInput={(e) => HandleUploadFile(e)}
                                />
                                <div
                                    className="dragdown-icon"
                                    dangerouslySetInnerHTML={{ __html: Icons.general.dragdrop_big_icon }}
                                ></div>
                                <div className="dragdown-label">Drop or Select file to Upload</div>

                            </div>


                        </div>



                    </div>

                </div>
            </div>
        </div>
    )
}
const CreateFolderUI = ({ props }) => {

    const { current_folder, close, updateCallback } = props


    const { projectId, selected_folder } = useParams()

    const [name, setName] = useState('')
    const [description, setRemarks] = useState('')

    const [isLoading, setIsLoading] = useState(false)
    const [warningAlert, setWarningAlert] = useState(false)
    const [warningAlertType, setWarningAlertType] = useState('warning')
    const [apiFailedMessage, setApiFailedMessage] = useState("Error in Server")

    const driveHandler = new DriveHandler()

    const navigate = useNavigate()
    const store = useSelector((store) => store)

    const [additionalNotes, setAdditionalNotes] = useState(false);

    const HandlePopupSubmit = async (e) => {
        e.preventDefault()
        setIsLoading(true)

        let update_data = {
            projectId: projectId,
            name: name,
            remarks: description || "",
            folder_id: selected_folder
        }

        setIsLoading(true)

        let response = await driveHandler.createFolderHandler(update_data)

        setIsLoading(false)

        if (response.success) {

            if (typeof updateCallback == 'function') updateCallback()
            close()
        }
        else {
            setWarningAlert(true)
            setWarningAlertType('error')
            setApiFailedMessage(`Error in creating folder`)
        }

    }

    const Popup_Header = () => {
        return (
            <div className="side-popup-header">
                <div className="header-item-select">
                    <div className="header-item-select-content">
                        <span className="icon" dangerouslySetInnerHTML={{ __html: Icons.general.group }}></span>
                        <div className="label">Create Folder</div>

                    </div>
                </div>
                <div
                    className="header-item-close"
                    onClick={(e) => close()}
                    dangerouslySetInnerHTML={{ __html: Icons.general.close }}
                ></div>
            </div>
        );
    };
    const Popup_Footer = () => {

        return (
            <div className="sidebar-popup-footer">
                <div className="footer-item action-items">
                    <div className="action-preview">
                    </div>
                    <button
                        className="action-btn"
                        type='submit'
                    >
                        <div className="icon" dangerouslySetInnerHTML={{ __html: Icons.general.add_btn }}></div>
                        <div className="label">Create</div>
                    </button>
                </div>
            </div>
        );
    };

    useEffect(() => {


    }, [])

    useEffect(() => {




    }, [store])

    return (
        <div className="popup-container-main popup-container-center">
            <div className="popup-block-ui"></div>

            {isLoading ?
                <Loading
                    props={{
                        isMainLogo: false,
                        isLabel: true
                    }} />
                : null}
            {warningAlert ?

                <SystemToastPopup
                    props={{
                        type: warningAlertType,
                        message: apiFailedMessage || "Error in Clients",
                        callback: (confirmation) => setWarningAlert(false)
                    }} />

                : null}

            <form className="side-popup-container" onSubmit={HandlePopupSubmit}>

                <Popup_Header />

                <div className="sidebar-popup-content">
                    <div className="content-section">


                        <div className="content-item">
                            <TextInput
                                props={{
                                    id: "driver-folder-name",
                                    value: name,
                                    placeholder: '',
                                    setValue: (value) => setName(value),
                                    isIcon: false,
                                    isLabel: true,
                                    isRequired: true,
                                    label: "Folder Name",
                                }}
                            />
                        </div>

                        <div className="content-section">
                            <div className="content-item">
                                <div id="driver-folder-description" className={`additional-item ${additionalNotes ? 'additional-item-active' : ''}`}>
                                    <div className="head" onClick={(e) => setAdditionalNotes(!additionalNotes)}>
                                        <div className="icon" dangerouslySetInnerHTML={{ __html: Icons.general.description }}></div>
                                        <div className="label">Comment</div>
                                    </div>
                                    <div className="item-expanded">
                                        <ReactQuill
                                            theme="snow"
                                            value={description}
                                            onChange={(value) => setRemarks(value)}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>

                <Popup_Footer />
            </form>
        </div>
    )
}
const UpdateFolderFile = ({ props }) => {

    const { type, existItemData, close, updateCallback } = props

    const { projectId, selected_folder } = useParams()

    const [isLoading, setIsLoading] = useState(false)
    const [warningAlert, setWarningAlert] = useState(false)
    const [warningAlertType, setWarningAlertType] = useState('warning')
    const [apiFailedMessage, setApiFailedMessage] = useState("Error in Server")

    const [DeleteItem, setDeleteItem] = useState(null)
    const [DeleteItemAlert, setDeleteItemAlert] = useState(false)

    const [selected_view_settings_value, setSelected_view_settings_value] = useState('')
    const [has_view_access, setHas_view_access] = useState(false)
    const [has_add_access, setHas_add_access] = useState(false)
    const [has_edit_access, setHas_edit_access] = useState(false)
    const [has_delete_access, setHas_delete_access] = useState(false)

    const { isAdmin, menu_permission } = useMenuAuth({ menu: 'project_management--drive', feature_key: 'drive' })
    const location = useLocation()

    const driveHandler = new DriveHandler()
    const commentsHandler = new CommentsHandler()

    const navigate = useNavigate()
    const store = useSelector((store) => store)
    const dispatch = useDispatch()
    const { updateState } = new UserAction


    const storeHandler = new StoreHandler({
        dataStack: [`project_phases-${projectId}`],
        store: store,
        dispatch: dispatch,
        updateState: updateState,
    })


    const LoadStoreData = async () => {
        setIsLoading(true)
        let resolved = await storeHandler.init()
        setIsLoading(false)
    }

    const isExistItemData = existItemData && existItemData.public_id != undefined
    const TypeLabel = existItemData ? existItemData.type == '0' ? 'Folder' : 'File' : ''

    const [showTaskLogpopup, setShowTaskLogpopup] = useState(false)

    const [ID, setID] = useState('')
    const [Type, setType] = useState(-1)
    const [Name, setName] = useState('')
    const [Remarks, setRemarks] = useState('')
    const [Size, setSize] = useState('')
    const [FileType, setFileType] = useState('')
    const [FileCategory, setFileCategory] = useState('')
    const [Creator, setCreator] = useState('')
    const [CreatedAt, setCreatedAt] = useState('')
    const [LastUpdatedBy, setLastUpdatedBy] = useState(undefined)
    const [UpdatedAt, setUpdatedAt] = useState(undefined)

    const [VersionItems, setVersionItems] = useState([])

    const [DetilsInputsChanged, setDetilsInputsChanged] = useState(false)

    const [SelectedToggleSection, setSelectedToggleSection] = useState('task-section-detials');

    const [ShowToClientValue, setShowToClientValue] = useState(false)

    const [ProjectPhases, setProjectPhases] = useState([])
    const [ProjectPhases_Options, setProjectPhases_Options] = useState([])
    const [SelectedPhases, setSelectedPhases] = useState([])

    const HandleSelectPhase = (phase) => {

        setDetilsInputsChanged(true)

        phase = phase.map(p => {
            let phase = ProjectPhases.filter(ph => ph.id == p.id)[0]

            if (!phase) return p

            return phase
        })

        setSelectedPhases(phase)
    }



    const [ToggleSectionItems, setToggleSectionItems] = useState([
        {
            id: 'task-section-detials',
            label: `${TypeLabel} Detials`,
            disabled: false
        },
        {
            id: 'task-section-timeline',
            label: 'Versions',
            disabled: Type != '1'
        },
        {
            id: 'task-section-update_version',
            label: 'Update Version',
            disabled: Type != '1'
        },
        {
            id: 'task-section-comments',
            label: 'Comments',
            disabled: false
        },
    ])

    const HandleToggleItemClick = (item) => {
        setSelectedToggleSection(item.id)

        let section_detials = document.getElementById(item.id);

        if (!section_detials) return
        section_detials.scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" });
    }

    const HandleDetialsInput = (key, event) => {

        setDetilsInputsChanged(true)

        let value = event.target.value
        if (key == 'name') {

            setName(value)
        }
        else if (key == 'category') {

            setFileCategory(value)
        }
        else if (key == 'remark') {

            setRemarks(value)
        }


    }

    const HandleClose = () => {
        close()
    }
    const HandleCancelDetials = () => {

        setDetilsInputsChanged(false)

        setName(existItemData.name)
        setFileCategory(existItemData.category)
        setRemarks(existItemData.remarks)

    }
    const HandleSaveDetials = async (e) => {

        e.preventDefault()

        let phases = SelectedPhases?.map(p => p.value)

        let update_data = {
            public_id: existItemData.public_id,
            name: Name,
            category: FileCategory,
            show_to_client: ShowToClientValue ? '1' : '0',
            phases: phases || [],
            remarks: Remarks
        }

        setIsLoading(true)

        let response = await driveHandler.updateFileDetailsHandler(update_data)

        setIsLoading(false)

        if (response.success) {
            if (typeof updateCallback == 'function') updateCallback()
            close()
        }
        else {
            setWarningAlert(true)
            setWarningAlertType('error')
            setApiFailedMessage(`Error in updating ${TypeLabel} detials`)
        }
    }

    const getFileVersionItems = async () => {

        let filter = {
            project_id: projectId,
            version_parent_id: existItemData.public_id
        }

        setIsLoading(true)

        let response = await driveHandler.getDrivesHandler(filter)

        setIsLoading(false)

        if (response.success) {
            let { data } = response

            if (!Array.isArray(data)) return

            let version_items = data.map(item => {
                item.expanded = false

                item.version_primary = item.version_primary || 0
                item.size = Utils.getFileSizeFromBytes(item.bytes || 0)
                item.created_time = Utils.getLocalFullDateLabel(item.created_at)
                item.updated_time = Utils.getLocalFullDateLabel(item.updated_at || item.created_at)

                return item
            })

            setVersionItems(version_items || [])
        }

    }

    useEffect(() => {

        if (isAdmin) {

            setHas_view_access(true)
            setHas_add_access(true)
            setHas_edit_access(true)
            setHas_delete_access(true)
        }

        else if (menu_permission) {

            if (
                !menu_permission._view
                &&
                (!menu_permission.selected_view_settings_value
                    || menu_permission.selected_view_settings_value == 'no_access')
            ) {
                navigate(`${ReverseProxy['proxyUrl']}/quick-menu`, { state: { from: location }, replace: true })
            }

            if (menu_permission._view == '0') navigate(`${ReverseProxy['proxyUrl']}/builder/projects`, { state: { from: location }, replace: true })

            setHas_view_access(menu_permission._view == '1')
            setHas_add_access(menu_permission._create == '1')
            setHas_edit_access(menu_permission._edit == '1')
            setHas_delete_access(menu_permission._delete == '1')

            setSelected_view_settings_value(menu_permission.selected_view_settings_value)

        }

    }, [store])

    useEffect(() => {
        LoadStoreData()
        if (!isExistItemData) HandleClose()

        if (isExistItemData) {

            setID(existItemData.public_id)
            setType(existItemData.type)
            setName(existItemData.name)
            setFileCategory(existItemData.category)
            setRemarks(existItemData.remarks)
            setSize(Utils.getFileSizeFromBytes(existItemData.bytes || 0))
            setFileType(existItemData.format || 'N/A')
            setCreator(existItemData.created_by_name)
            setShowToClientValue(existItemData?.show_to_client == '1' ? true : false)

            setCreatedAt(Utils.getLocalFullDateLabel(existItemData.created_at))


            let version_items = [...existItemData.version_items] || []

            version_items.push({ ...existItemData })

            version_items = version_items.map(item => {

                let _item = { ...item }

                _item.expanded = false

                delete _item.version_items
                _item.version_primary = item.version_primary || 0
                _item.size = Utils.getFileSizeFromBytes(item.bytes || 0)
                _item.created_time = Utils.getLocalFullDateLabel(item.created_at)
                _item.updated_time = Utils.getLocalFullDateLabel(item.updated_at || item.created_at)

                return _item
            })

            setVersionItems(version_items || [])

            if (existItemData.updated_at) setLastUpdatedBy(existItemData.updated_by_name | '-')
            setUpdatedAt(Utils.getLocalFullDateLabel(existItemData.updated_at || existItemData.created_at))
        }

    }, [])

    useEffect(() => {

        LoadComments({ project_id: projectId, parent_id: existItemData.public_id })

        if (store && store.user) {
            let { selected_project_phases } = store.user

            if (selected_project_phases) {

                setProjectPhases(selected_project_phases)

                let project_phases = selected_project_phases.map(phase => {
                    return {
                        value: phase.id,
                        label: `${phase.name} | ${phase.client_name}`,
                        isDefault: false
                    }
                })

                setProjectPhases_Options(project_phases || [])

            }
        }


    }, [store])

    useEffect(() => {

        if (ProjectPhases_Options.length && existItemData && existItemData.phases) {
            let phases = existItemData.phases || []

            let select_phases = phases.map(p => {
                let phase = ProjectPhases_Options.filter(ph => ph.value == p)[0]

                if (!phase) return p
                return phase
            })

            setSelectedPhases(select_phases)

        }

    }, [ProjectPhases_Options])



    useEffect(() => {

        let _ToggleSectionItems = [...ToggleSectionItems]

        _ToggleSectionItems = _ToggleSectionItems.map(item => {
            if (item.id == 'task-section-timeline') item.disabled = Type != '1'
            if (item.id == 'task-section-update_version') item.disabled = Type != '1'
            return item
        })

        setToggleSectionItems(_ToggleSectionItems)

    }, [Type])

    const [CommentsItems, setCommentsItems] = useState([])
    const [CommentsInput, setCommentsInput] = useState("")

    const FileInputRef = useRef()
    const [VersionSelectedFile, setVersionSelectedFile] = useState(null)
    const [ShowVersionFileDetails, setShowVersionFileDetails] = useState(false)
    const [InvalidVersionName, setInvalidVersionName] = useState(false)
    const [VersionFileName, setVersionFileName] = useState('')
    const [VersionFileRemarks, setVersionFileRemarks] = useState('')

    const HandleCancelVersion = () => {
        setVersionSelectedFile(null)
        setShowVersionFileDetails(false)
        setVersionFileName('')
        setVersionFileRemarks('')
    }
    const HandleVersionSubmit = async () => {

        if (!VersionFileName) {
            return setInvalidVersionName(true)
        }

        const formData = new FormData();

        formData.append('projectId', projectId)
        formData.append('folder_id', selected_folder || "")
        formData.append('version_parent_id', existItemData.public_id)
        formData.append('attachment', VersionSelectedFile)
        formData.append('remarks', VersionFileRemarks)
        formData.append('name', VersionFileName)

        setIsLoading(true)

        let response = await driveHandler.uploadVersionFileHandler(formData)

        setIsLoading(false)

        if (response.success) {
            await getFileVersionItems()
            HandleCancelVersion()
        } else {
            setWarningAlert(true)
            setWarningAlertType('error')
            setApiFailedMessage(`Error in uploading version file`)

        }

    }

    const LoadComments = async ({ project_id, parent_id }) => {

        console.log(project_id, parent_id, 'parent_id');
        if (!project_id || !parent_id) return

        let filter = {
            project_id: project_id,
            parent_id: parent_id
        }

        setIsLoading(true)

        let response = await commentsHandler.getCommentsHandler(filter)
        setIsLoading(false)

        if (response.success) {
            let { data } = response

            if (!Array.isArray(data)) return

            console.log(data, 'data');

            setCommentsItems(data)
        }
    }

    const HandleAddComment = async () => {
        let _CommentsItems = [...CommentsItems]

        let userdetials = JSON.parse(localStorage.getItem('userdetials'))

        _CommentsItems.push({
            id: Utils.getUniqueId(),
            created_at: new Date().getTime(),
            created_by: userdetials.id,
            created_by_name: userdetials.name,
            message: CommentsInput,
            parent_id: existItemData.public_id,
            parent_name: Name || "",
            parent_type: 0,
            project_id: projectId,
            company_id: userdetials.company_id,
        })

        setCommentsItems(_CommentsItems)
        setCommentsInput("")

        if (!isExistItemData) return

        let update_data = {
            project_id: projectId,
            parent_id: existItemData.public_id,
            parent_name: Name || "",
            parent_type: String(0),
            message: CommentsInput
        }

        let response = await commentsHandler.createCommentHandler(update_data)

        if (!response.success) {
            setWarningAlert(true)
            setWarningAlertType('error')
            setApiFailedMessage(`Error in creating comment`)
        }
    }

    const HandleDeleteComment = async (id, idx) => {
        let _CommentsItems = [...CommentsItems]

        _CommentsItems.splice(idx, 1)

        setCommentsItems(_CommentsItems)

        if (!isExistItemData) return

        let update_data = {
            project_id: projectId,
            ids: [id]
        }

        let response = await commentsHandler.deleteCommentHandler(update_data)

        if (!response.success) {
            setWarningAlert(true)
            setWarningAlertType('error')
            setApiFailedMessage(`Error in deleting comment`)
        }
    }

    const HandleExpandProgress = (public_id, idx, value) => {

        let _VersionItems = [...VersionItems]

        _VersionItems = _VersionItems.map(item => {

            if (item.public_id == public_id) item.expanded = !value
            else item.expanded = false

            return item
        })

        setVersionItems(_VersionItems)
    }

    const HandleUploadFileSelect = (e) => {
        if (FileInputRef.current) FileInputRef.current.click()
    }
    const HandleUploadFile = async (e) => {

        e.preventDefault()

        let file = e.target.files[0]

        if (!file) return

        setVersionSelectedFile(file)
        setShowVersionFileDetails(true)

    }

    const HandleDeleteItem = async () => {

        if (!has_edit_access || !has_delete_access) return

        setDeleteItemAlert(true)
    }
    const HandleDeleteConfirmItem = async (confirmation) => {

        if (!confirmation) {

            setDeleteItem(null)
            setDeleteItemAlert(false)

            return
        }

        let update_data = {
            public_id: existItemData.public_id
        }

        setIsLoading(true)

        let response = {
            status: false
        }
        if (existItemData.type == '0') response = await driveHandler.deleteFolderHandler(update_data)
        else if (existItemData.type == '1') response = await driveHandler.deleteFileHandler(update_data)

        setIsLoading(false)

        if (response.success) {
            close()
        }
        else {
            setWarningAlert(true)
            setWarningAlertType('error')
            setApiFailedMessage(`Error in deleting file`)
        }

        setDeleteItem(null)
        setDeleteItemAlert(false)
    }

    const HandleShareFile = (file) => {
        if (!file) return

        let url = file.url
        // copy the url to clipboard

        navigator.clipboard.writeText(url)


        setWarningAlert(true)
        setWarningAlertType('success')
        setApiFailedMessage(`File URL copied to clipboard`)
    }
    const HandleDownloadFile = (file) => {
        if (!file) return
        window.open(file.url, '_blank')
    }

    const HandleUpdateVersion = async (public_id) => {

        if (!has_edit_access) return

        let _VersionItems = [...VersionItems]

        _VersionItems = _VersionItems.map(item => {

            if (item.public_id == public_id) item.version_primary = 1
            else item.version_primary = 0

            return item
        })

        setVersionItems(_VersionItems)

        let update_data = {
            public_id: public_id,
            version_parent_id: existItemData.public_id
        }

        setIsLoading(true)

        let response = await driveHandler.updateVersionHandler(update_data)

        setIsLoading(false)

        if (response.success) {
            await getFileVersionItems()
        }
        else {
            setWarningAlert(true)
            setWarningAlertType('error')
            setApiFailedMessage(`Error in updating version`)
        }

    }


    return (

        <div className="popup-container-main propup-container-center">
            <div className="popup-block-ui"></div>

            {isLoading ?
                <Loading
                    props={{
                        isMainLogo: false,
                        isLabel: true
                    }} />
                : null}
            {warningAlert ?

                <SystemToastPopup
                    props={{
                        type: warningAlertType,
                        message: apiFailedMessage || "Error in Clients",
                        callback: (confirmation) => setWarningAlert(false)
                    }} />

                : null}

            {DeleteItemAlert ?

                <AlertPopup
                    props={{
                        type: "delete",
                        actionBtnOption: { label: "Yes, Delete" },
                        heading: `Delete ${Name}${Type == '1' ? `.${FileType}` : ''}`,
                        message: `Are you sure want to delete all versions of ${Name}${Type == '1' ? `.${FileType}` : ''}?`,
                        callback: (confirmation) => HandleDeleteConfirmItem(confirmation)
                    }} />

                : null}

            <form className="side-popup-container side-drive-popup-container animateXRight animateXRightactive" onSubmit={HandleSaveDetials}>
                <div className="sidebar-task-popup-content">

                    <div className="task-content-header">
                        <div className="task-header-left">

                            <div className="task-header-name">{Name}{Type == '1' ? `.${FileType}` : ''}</div>
                        </div>
                        <div className="task-header-right">
                            <div
                                className="task-header-close"
                                onClick={(e) => HandleClose()}
                                dangerouslySetInnerHTML={{ __html: Icons.general.close }}
                            ></div>
                        </div>
                    </div>
                    <div className="task-toggle-section">
                        {
                            ToggleSectionItems.filter(item => !item.disabled && (!has_edit_access && item.id == 'task-section-update_version' ? false : true)).map(item => (
                                <div
                                    className={`task-toggle-item ${item.id == SelectedToggleSection ? 'task-toggle-item-active' : ''}`}
                                    key={item.label.id}
                                    onClick={() => HandleToggleItemClick(item)}
                                >
                                    <div className="label">{item.label}</div>
                                </div>
                            ))
                        }

                    </div>

                    <div className="task-content-sections">
                        <div className="task-content-section" id='task-section-detials'>
                            <div className="task-section-header">
                                <div className="left">
                                    <div className="title">{TypeLabel} Detials</div>
                                </div>
                                {has_edit_access ?
                                    <div className="right">
                                        <ShowToClient
                                            props={{
                                                value: ShowToClientValue,
                                                callback: (value) => {
                                                    setShowToClientValue(value)
                                                    setDetilsInputsChanged(true)
                                                }
                                            }}
                                        />
                                    </div>
                                    : ''}
                            </div>
                            <div className="task-row-content">

                                <div className="task-row-item">
                                    <div className="label">Name</div>
                                    <div className="input">
                                        <input
                                            type="text"
                                            value={Name}
                                            onChange={(e) => HandleDetialsInput('name', e)}
                                            readOnly={!has_edit_access}
                                        />
                                    </div>
                                </div>

                                {Type == 1 ?
                                    <>
                                        <div className="task-row-item">
                                            <div className="label">Size</div>
                                            <div className="input">
                                                <input
                                                    type="text"
                                                    value={Size}
                                                    readOnly={true}
                                                />
                                            </div>
                                        </div>
                                        <div className="task-row-item">
                                            <div className="label">File Type</div>
                                            <div className="input">
                                                <input
                                                    type="text"
                                                    value={FileType}
                                                    readOnly={true}
                                                />
                                            </div>
                                        </div>
                                        <div className="task-row-item">
                                            <div className="label">File Category</div>
                                            <div className="input">
                                                <select
                                                    value={FileCategory}
                                                    placeholder='Select Category'
                                                    onChange={(e) => HandleDetialsInput('category', e)}
                                                >
                                                    <option value=''>Select Category</option>
                                                    <option value='floor_plan'>Floor Plan</option>
                                                    <option value='elevation'>Elevation</option>
                                                    <option value='3d_view'>3D View</option>
                                                    <option value='approval_docs'>Approval Docs</option>
                                                    <option value='structural_docs'>Structural Docs</option>
                                                    <option value='electrical_docs'>Electrical Docs</option>
                                                    <option value='plumbing_docs'>Plumbing Docs</option>
                                                    <option value='interior_docs'>Interior Docs</option>
                                                    <option value='landscape_docs'>Landscape Docs</option>
                                                    <option value='site_plan'>Site Plan</option>
                                                    <option value='master_plan'>Master Plan</option>
                                                    <option value='others'>Others</option>
                                                </select>
                                            </div>
                                        </div>
                                    </>
                                    : ''}

                                <div className="task-row-item">
                                    <div className="label">Project Phases</div>
                                    <div className="input" style={{ height: 'max-content' }}>
                                        <MultiSelectInput
                                            props={{
                                                id: 'task-multi-select-input',
                                                placeholder: 'Select Phase',
                                                isRequired: false,
                                                readOnly: !has_edit_access,
                                                value: SelectedPhases,
                                                options: ProjectPhases_Options,
                                                isIcon: false,
                                                avoidDuplicate: true,
                                                icon: '',
                                                isLabel: false,
                                                label: '',
                                                setValue: (value) => HandleSelectPhase(value),
                                            }}
                                        />
                                    </div>
                                </div>
                                <div className="task-row-item">
                                    <div className="label">Creator</div>
                                    <div className="input">
                                        <input
                                            type="text"
                                            value={Creator}
                                            readOnly={true}
                                        />
                                    </div>
                                </div>
                                <div className="task-row-item">
                                    <div className="label">Created At</div>
                                    <div className="input">
                                        <input
                                            type="text"
                                            value={CreatedAt}
                                            readOnly={true}
                                        />
                                    </div>
                                </div>
                                {LastUpdatedBy ?
                                    <div className="task-row-item">
                                        <div className="label">LastUpdated By</div>
                                        <div className="input">
                                            <input
                                                type="text"
                                                value={LastUpdatedBy}
                                                readOnly={true}
                                            />
                                        </div>
                                    </div>
                                    : ''}
                                <div className="task-row-item">
                                    <div className="label">Updated At</div>
                                    <div className="input">
                                        <input
                                            type="text"
                                            value={UpdatedAt}
                                            readOnly={true}
                                        />
                                    </div>
                                </div>
                                <div className="task-row-item">
                                    <div className="label">Remark</div>
                                    <div className="input">
                                        <input
                                            type="text"
                                            value={Remarks}
                                            placeholder='No description'
                                            onChange={(e) => HandleDetialsInput('remark', e)}
                                            readOnly={!has_edit_access}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        {Type == '1' ?
                            <>
                                <div className="task-content-section" id='task-section-timeline'>
                                    <div className="task-section-header">
                                        <div className="left">
                                            <div className="title">Versions</div>
                                        </div>
                                    </div>
                                    <div className="task-timeline-content">

                                        {VersionItems.sort((a, b) => b.version_primary - a.version_primary)?.map((log, idx) => (

                                            <div
                                                className={`task-timeline-item task-timeline-item-progress ${log.version_primary == '1' ? 'task-timeline-item-active' : ''} `}
                                            >
                                                <div className="timeline-progress-content">

                                                    <div className="icon"
                                                        dangerouslySetInnerHTML={{ __html: Icons.general.progress }}
                                                    ></div>
                                                    <div className="detials" >
                                                        <div className="detials" onClick={() => HandleExpandProgress(log.public_id, idx, log.expanded || false)}>
                                                            <div className="title">{log.name}</div>
                                                            <div className="time">On {log.created_time} by {log.created_by_name}</div>

                                                        </div>

                                                        <div className={`timeline-progress-detials ${log.expanded ? 'timeline-progress-detials-active' : ''}`}>

                                                            <div className="timeline-progress-detials-item">
                                                                <div className="label">Version Name</div>
                                                                <div className="value">{log.name || 0}</div>
                                                            </div>
                                                            <div className="timeline-progress-detials-item">
                                                                <div className="label">Size</div>
                                                                <div className="value">{log.size}</div>
                                                            </div>
                                                            <div className="timeline-progress-detials-item">
                                                                <div className="label">Creator</div>
                                                                <div className="value">{log.created_by_name}</div>
                                                            </div>
                                                            <div className="timeline-progress-detials-item">
                                                                <div className="label">Created At</div>
                                                                <div className="value">{log.created_time}</div>
                                                            </div>
                                                            <div className="timeline-progress-detials-item">
                                                                <div className="label">Updated At</div>
                                                                <div className="value">{log.updated_time}</div>
                                                            </div>
                                                            <div className="timeline-progress-detials-item">
                                                                <div className="label">Comments</div>
                                                                <div className="value">{log.remarks}</div>
                                                            </div>

                                                            <div className="timeline-progress-actions">
                                                                <div className="timeline-progress-action-btn-icon icon"
                                                                    dangerouslySetInnerHTML={{ __html: Icons.general.files }}
                                                                    onClick={() => HandleShareFile(log)}
                                                                ></div>
                                                                <div className="timeline-progress-action-btn-icon icon"
                                                                    dangerouslySetInnerHTML={{ __html: Icons.general.download }}
                                                                    onClick={() => HandleDownloadFile(log)}
                                                                ></div>
                                                                {has_edit_access ?
                                                                    <div
                                                                        className="timeline-progress-action-btn"
                                                                        onClick={() => HandleUpdateVersion(log.public_id)}
                                                                    >Use this Version</div>
                                                                    : ''}

                                                            </div>

                                                        </div>

                                                    </div>
                                                    <div
                                                        className="dropdown-icon"
                                                        style={{ transition: 'all .25s', transform: `${log.expanded ? 'rotate(180deg)' : 'rotate(0deg)'}` }}
                                                        dangerouslySetInnerHTML={{ __html: Icons.general.dropdown_arrow }}
                                                    ></div>
                                                </div>


                                            </div>
                                        ))}
                                    </div>
                                </div>
                                {has_edit_access ?
                                    <div className="task-content-section" id='task-section-update_version'>
                                        {!ShowVersionFileDetails ?
                                            <div className="file-upload-section-main">

                                                <div className="upload-dragdown-main">

                                                    <div className="upload-dragdown-input"
                                                        onClick={(e) => HandleUploadFileSelect()}
                                                    >
                                                        <input
                                                            className='upload-file-input'
                                                            type="file"
                                                            id="upload-file-input"
                                                            ref={FileInputRef}
                                                            onChange={(e) => HandleUploadFile(e)}
                                                        />
                                                        <div
                                                            className="dragdown-icon"
                                                            dangerouslySetInnerHTML={{ __html: Icons.general.dragdrop_big_icon }}
                                                        ></div>
                                                        <div className="dragdown-label">Drop or Select file to Upload</div>

                                                    </div>


                                                </div>



                                            </div>
                                            :
                                            <div className="file-upload-inputs-main" >
                                                <div className="input-items">
                                                    <div className={`input-item ${InvalidVersionName ? 'invalid-input' : ''}`}>
                                                        <div className="label">Version Name</div>
                                                        <div className="input">
                                                            <input
                                                                type="text"
                                                                value={VersionFileName}
                                                                placeholder='Enter Version Name'
                                                                onChange={(e) => setVersionFileName(e.target.value)}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="input-item">
                                                        <div className="label">Commnet</div>
                                                        <div className="textarea">
                                                            <textarea
                                                                value={VersionFileRemarks}
                                                                placeholder='Enter Version Comment'
                                                                onChange={(e) => setVersionFileRemarks(e.target.value)}
                                                            ></textarea>
                                                        </div>
                                                    </div>

                                                    {InvalidVersionName ? <div className="invalid-input-label">Please Enter Version Name!</div> : ''}
                                                </div>
                                                <div className="input-buttons">
                                                    <div
                                                        className="action-btn-cancel"
                                                        onClick={() => HandleCancelVersion()}
                                                    >Cancel</div>
                                                    <div
                                                        className="action-btn-submit"
                                                        onClick={() => HandleVersionSubmit()}
                                                    >Upload Version</div>
                                                </div>

                                            </div>
                                        }
                                    </div> : ''}
                            </>
                            : ''}
                        <div className="task-content-section" id='task-section-comments'>
                            <div className="task-section-header">
                                <div className="left">
                                    <div className="title">Comments</div>
                                </div>
                            </div>

                            <div className="comments-section-main">
                                <div className="comments-section-items">

                                    {CommentsItems.map((item, idx) => (

                                        <div
                                            className="comments-item-main"
                                            key={`comments-item-${item.id}`}
                                            id={`comments-item-${item.id}`}
                                        >
                                            <div className="comments-item-usericon">
                                                {item.created_by_name.charAt(0)}
                                            </div>
                                            <div className="comments-item-detials">
                                                <div className="detials-head">
                                                    <div className="left">
                                                        <div className="name">{item.created_by_name}</div>
                                                        <div className="time">{Utils.getLocalFullDateLabel(item.created_at)}</div>
                                                    </div>
                                                    <div className="right">
                                                        <div
                                                            className="delete-icon"
                                                            onClick={() => HandleDeleteComment(item.id, idx)}
                                                            dangerouslySetInnerHTML={{ __html: Icons.general.delete_btn }}></div>
                                                    </div>
                                                </div>
                                                <div className="detials-message">{item.message}</div>
                                            </div>
                                        </div>


                                    ))}


                                    <div className="comments-input-main">
                                        <div className="comments-input-usericon">
                                            RT
                                        </div>
                                        <div className="comments-input comments-input-active">
                                            <div className="comments-input-textarea">
                                                <textarea
                                                    placeholder='Add a comment...'
                                                    value={CommentsInput}
                                                    onChange={(e) => setCommentsInput(e.target.value)}
                                                    style={{ minHeight: '50px' }}
                                                ></textarea>
                                            </div>
                                            <div className={`comments-input-controls ${CommentsInput.length ? `comments-input-controls-active` : ''}`}>
                                                <div
                                                    className={`comments-input-control-sendmsg`}
                                                    onClick={() => HandleAddComment()}
                                                >
                                                    <div className="icon" dangerouslySetInnerHTML={{ __html: Icons.general.send_message }}></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>

                    </div>

                </div>

                <div className="sidebar-popup-footer task-sidebar-footer">
                    <div className="footer-item action-items">
                        {has_delete_access ?
                            <div
                                className="action-btn delete-changes-btn"
                                onClick={() => HandleDeleteItem()}

                            >
                                <div className="label">Delete {TypeLabel}</div>
                            </div>
                            : ''}
                        {has_edit_access && DetilsInputsChanged ?
                            <>
                                <div
                                    className="action-btn discard-changes-btn"
                                    onClick={() => HandleCancelDetials()}

                                >
                                    <div className="label">Cancel</div>
                                </div>
                                <button
                                    className="action-btn save-changes-btn"
                                    type='submit'
                                >
                                    <div className="label">Save</div>
                                </button>
                            </>
                            : ''}
                    </div>
                </div>
            </form>
        </div>
    )

}

const Drive = () => {

    const store = useSelector((store) => store)
    const dispatch = useDispatch()
    const { updateState } = new UserAction

    const { projectId, selected_folder } = useParams()

    const navigate = useNavigate()

    const driveHandler = new DriveHandler()

    const [isLoading, setIsLoading] = useState(false)
    const [warningAlert, setWarningAlert] = useState(false)
    const [warningAlertType, setWarningAlertType] = useState('warning')
    const [apiFailedMessage, setApiFailedMessage] = useState('Error in Clients')

    const [DeleteItem, setDeleteItem] = useState(null)
    const [DeleteItemAlert, setDeleteItemAlert] = useState(false)

    const [DriveDetails_Orginal, setDriveDetails_Orginal] = useState([])
    const [DriveDetails, setDriveDetails] = useState([])

    const { isAdmin, menu_permission } = useMenuAuth({ menu: 'project_management--drive', feature_key: 'drive' })
    const location = useLocation()

    const [selected_view_settings_value, setSelected_view_settings_value] = useState('')
    const [has_view_access, setHas_view_access] = useState(false)
    const [has_add_access, setHas_add_access] = useState(false)
    const [has_edit_access, setHas_edit_access] = useState(false)
    const [has_delete_access, setHas_delete_access] = useState(false)

    const [showCreateFolder, setShowCreateFolder] = useState(false)
    const [showUploadFile, setShowUploadFile] = useState(false)
    const [showUpdateFolderFile, setShowUpdateFolderFile] = useState(false)

    const [SelectedFolderPath, setSelectedFolderPath] = useState([])
    const [selected_filefolder, setSelected_filefolder] = useState('')

    useEffect(() => {

        if (isAdmin) {

            setHas_view_access(true)
            setHas_add_access(true)
            setHas_edit_access(true)
            setHas_delete_access(true)
        }

        else if (menu_permission) {

            if (
                !menu_permission._view
                &&
                (!menu_permission.selected_view_settings_value
                    || menu_permission.selected_view_settings_value == 'no_access')
            ) {
                navigate(`${ReverseProxy['proxyUrl']}/quick-menu`, { state: { from: location }, replace: true })
            }

            if (menu_permission._view == '0') navigate(`${ReverseProxy['proxyUrl']}/builder/projects`, { state: { from: location }, replace: true })

            setHas_view_access(menu_permission._view == '1')
            setHas_add_access(menu_permission._create == '1')
            setHas_edit_access(menu_permission._edit == '1')
            setHas_delete_access(menu_permission._delete == '1')

            setSelected_view_settings_value(menu_permission.selected_view_settings_value)

        }

    }, [store])

    const getOrderedTableItems = (items = []) => {
        const groupedItems = {};

        for (const item of items) {

            const folder_id = item.folder_id;

            if (folder_id != null) {

                if (!groupedItems[folder_id]) groupedItems[folder_id] = [];

                groupedItems[folder_id].push(item);
            }
        }

        for (const item of items) {
            const itemId = item.public_id;
            if (groupedItems[itemId]) {

                const orderedItems = groupedItems[itemId].sort((a, b) => {
                    if (a.type != 1 && b.type == 1) return -1;
                    if (a.type == 1 && b.type != 1) return 1;
                    return 0;
                })

                item.sub_items = orderedItems
            }
        }

        let orderedItems = items.sort((a, b) => {
            if (a.type != 1 && b.type == 1) return -1;
            if (a.type == 1 && b.type != 1) return 1;
            return 0;
        })

        orderedItems = orderedItems.sort((a, b) => a.created_at - b.created_at)

        return orderedItems.filter(d => !d.version_parent_id && (!d.folder_id || d.folder_id == selected_folder))

    }

    const getDriveDetails = async () => {

        let filter = {
            project_id: projectId
        }

        if (selected_folder) {
            filter['folder_id'] = selected_folder
            await getDriveFolderPath(selected_folder)
        }



        setIsLoading(true)

        let response = await driveHandler.getDrivesHandler(filter)

        setIsLoading(false)

        if (response && response.success) {

            let _drivedetails = response.data || []

            _drivedetails = _drivedetails.map(item => {

                item['version_items'] = _drivedetails.filter(d => d.version_parent_id == item.public_id)

                return item
            })

            _drivedetails = getOrderedTableItems(_drivedetails)


            setDriveDetails_Orginal(response.data)
            setDriveDetails(_drivedetails)

        } else {
            setWarningAlert(true)
            setWarningAlertType('error')
            setApiFailedMessage(`Error in Drive, Please try again!`)
        }
    }
    const getDriveFolderPath = async (folder_id) => {

        let filter = {
            project_id: projectId,
            folder_id: folder_id
        }

        setIsLoading(true)

        let response = await driveHandler.getDriveFolderPathHandler(filter)

        setIsLoading(false)

        if (response && response.success) {

            let path = response.data || []

            setSelectedFolderPath(path)

        } else {
            setWarningAlert(true)
            setWarningAlertType('error')
            setApiFailedMessage(`Error in Drive, Please try again!`)
        }
    }

    const getFileTypeIcon = (format) => {

        let keys = Object.keys(Icons.file_formats)

        if (keys.includes(format)) return Icons.file_formats[format]
        else return Icons.file_formats.default
    }

    const HandleCreateFolder = () => {
        if (!has_add_access) return
        setShowCreateFolder(true)
    }
    const HandleUploadFile = () => {
        if (!has_add_access) return
        setShowUploadFile(true)
    }

    const HandleCloseCreateFolder = () => {
        setShowCreateFolder(false)
    }
    const HandleCloseUploadFile = () => {
        setShowUploadFile(false)
    }
    const HandleOpenFileFolder = (filefolder) => {
        setShowUpdateFolderFile(true)
        setSelected_filefolder(filefolder)
    }
    const HandleOpenFolder = async (folder) => {

        if (!folder) {
            setSelectedFolderPath([])
            return navigate(`/builder/projects/${projectId}/drive`)
        }
        return navigate(`/builder/projects/${projectId}/drive/${folder.public_id}`)
    }
    const HandleCloseFileFolder = () => {
        setShowUpdateFolderFile(false)
    }

    const HandleDownloadFile = (file) => {
        if (!file) return
        window.open(file.url, '_blank')
    }

    const HandleDeleteFile = async (item) => {

        if (!has_edit_access || !has_delete_access) return

        setDeleteItem(item)
        setDeleteItemAlert(true)
    }
    const HandleDeleteConfirmItem = async (file, confirmation) => {

        if (!confirmation || !file) {

            setDeleteItem(null)
            setDeleteItemAlert(false)

            return
        }

        let update_data = {
            public_id: file.public_id
        }

        setIsLoading(true)

        let response = await driveHandler.deleteFileHandler(update_data)

        setIsLoading(false)

        if (response.success) {
            getDriveDetails()
        }
        else {
            setWarningAlert(true)
            setWarningAlertType('error')
            setApiFailedMessage(`Error in deleting file`)
        }

        setDeleteItem(null)
        setDeleteItemAlert(false)
    }


    useEffect(() => {
        getDriveDetails()
    }, [])

    useEffect(() => {

        getDriveDetails()
    }, [selected_folder])

    return (
        <>

            {isLoading ?
                <Loading
                    props={{
                        isMainLogo: false,
                        isLabel: true
                    }} />
                : null}
            {warningAlert ?

                <SystemToastPopup
                    props={{
                        type: warningAlertType,
                        message: apiFailedMessage || "Error in Clients",
                        callback: (confirmation) => setWarningAlert(false)
                    }} />

                : null}
            {showCreateFolder ?

                <CreateFolderUI props={{
                    current_folder: selected_folder,
                    updateCallback: getDriveDetails,
                    close: HandleCloseCreateFolder
                }} />

                : null}

            {showUploadFile ?
                <UploadFileUI props={{
                    current_folder: selected_folder,
                    updateCallback: getDriveDetails,
                    close: HandleCloseUploadFile
                }} />
                : null}
            {showUpdateFolderFile ?
                <UpdateFolderFile props={{
                    type: '0',
                    existItemData: selected_filefolder,
                    updateCallback: getDriveDetails,
                    close: HandleCloseFileFolder
                }} />
                : null}

            {DeleteItemAlert ?

                <AlertPopup
                    props={{
                        type: "delete",
                        actionBtnOption: { label: "Yes, Delete" },
                        heading: `Delete ${DeleteItem.name}${DeleteItem.type == '1' ? `.${DeleteItem.format}` : ''}`,
                        message: `Are you sure want to delete all versions of ${DeleteItem.name}${DeleteItem.type == '1' ? `.${DeleteItem.format}` : ''}?`,
                        callback: (confirmation) => HandleDeleteConfirmItem(DeleteItem, confirmation)
                    }} />

                : null}

            <div className="project-clients-main">
                <div className="clients-panel-content">

                    <div className="smart-panel-content-main" style={{ height: '100%' }}>
                        <div className="panel-content-header">
                            <div className="panel-path-main">
                                <div className="panel-path-items">
                                    <div
                                        className="panel-path-main-item"
                                        onClick={() => HandleOpenFolder()}
                                    >Drive</div>
                                    {SelectedFolderPath.map((folder, idx) => (
                                        <>
                                            <div className="panel-path-item-arrow"
                                                dangerouslySetInnerHTML={{ __html: Icons.general.dropdown_arrow }}
                                            ></div>
                                            <div
                                                className="panel-path-item"
                                                key={`panel-path-item-${folder.public_id}`}
                                                onClick={() => HandleOpenFolder(folder)}
                                            >{folder.name}</div>
                                        </>
                                    ))}
                                </div>
                            </div>
                            <div className="buttons">
                                {has_add_access ?
                                    <div
                                        className="button add-button"
                                        onClick={(e) => HandleCreateFolder()}
                                    >
                                        <div className="icon" dangerouslySetInnerHTML={{ __html: Icons.general.add_btn }}></div>
                                        <div className="label">Create Folder</div>
                                    </div>
                                    : ''}
                                {has_add_access ?
                                    <div
                                        className="button add-button"
                                        onClick={(e) => HandleUploadFile()}
                                    >
                                        <div className="icon" dangerouslySetInnerHTML={{ __html: Icons.general.upload_light }}></div>
                                        <div className="label">Upload File</div>
                                    </div>
                                    : ''}
                            </div>
                        </div>
                        <div className="panel-content-sections panel-content-tabel-section">

                            {DriveDetails.length ?
                                <div className="driver-content-main">
                                    {DriveDetails.filter(d => d.type == '0').length ?
                                        <div className="driver-content-section section-type-cards">
                                            <div className="section-title">Folders</div>
                                            <div className="section-content-items">

                                                {DriveDetails.filter(d => d.type == '0').map(folder => (

                                                    <div
                                                        className="section-content-item"
                                                        key={folder.public_id}
                                                    >
                                                        <div
                                                            className="item-info-icon"
                                                            dangerouslySetInnerHTML={{ __html: Icons.general.info }}
                                                            onClick={() => HandleOpenFileFolder(folder)}
                                                        ></div>
                                                        <div
                                                            className="icon"
                                                            dangerouslySetInnerHTML={{ __html: Icons.general.folder }}
                                                            onClick={() => HandleOpenFolder(folder)}
                                                        ></div>
                                                        <div className="label">{folder.name || 'N/A'}</div>
                                                    </div>

                                                ))}



                                            </div>
                                        </div>
                                        : ''}
                                    {DriveDetails.filter(d => d.type == '1').length ?
                                        <div className="driver-content-section section-type-list">
                                            <div className="section-title">Files</div>
                                            <div className="section-content-items">
                                                {DriveDetails.filter(d => d.type == '1').map(file => (
                                                    <div
                                                        className="section-content-item"
                                                        key={file.public_id}
                                                    >
                                                        <div
                                                            className="list-td-section"
                                                            onClick={() => HandleOpenFileFolder(file)}
                                                        >
                                                            <div
                                                                className="section-list-item-td list-td-name"

                                                            >
                                                                <div className="icon" dangerouslySetInnerHTML={{ __html: getFileTypeIcon(file.format) }}></div>
                                                                <div className="label">{file.name}.{file.format}</div>
                                                            </div>
                                                            <div className="section-list-item-td list-td-size">
                                                                <div className="label">{Utils.getFileSizeFromBytes(file.bytes)}</div>
                                                            </div>
                                                            <div className="section-list-item-td list-td-lastupdate">
                                                                <div className="icon" dangerouslySetInnerHTML={{ __html: Icons.general.time }}></div>
                                                                <div className="label">{Utils.getLocalFullDateLabel(file.updated_at || file.created_at)}</div>
                                                            </div>
                                                            <div className="section-list-item-td list-td-creator">
                                                                <div className="icon" dangerouslySetInnerHTML={{ __html: Icons.general.user }}></div>
                                                                <div className="label">{file.created_by_name}</div>
                                                            </div>
                                                        </div>
                                                        <div className="section-list-item-td list-td-action">
                                                            <div
                                                                className="icon action-icon"
                                                                dangerouslySetInnerHTML={{ __html: Icons.general.download }}
                                                                onClick={() => HandleDownloadFile(file)}
                                                            ></div>
                                                            {has_delete_access ?
                                                                <div
                                                                    className="icon action-icon"
                                                                    dangerouslySetInnerHTML={{ __html: Icons.general.delete_btn }}
                                                                    onClick={() => HandleDeleteFile(file)}
                                                                ></div>
                                                                : ''}
                                                        </div>


                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                        : ''}
                                </div>
                                :
                                <div className="driver-content-main">
                                    <div className="driver-empty-content-main">
                                        <div className="label">No Files or Folder found</div>
                                        <div className="desc">Start Uploading your files here...</div>
                                        <div
                                            className="action-btn"
                                            onClick={(e) => HandleUploadFile()}
                                        >Upload File</div>
                                    </div>
                                </div>
                            }

                        </div>
                    </div>
                </div>

            </div>
        </>
    )
}

export default Drive;