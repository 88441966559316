

import React, { useEffect, useState, useRef } from 'react'
import { Route, Routes, BrowserRouter, Link, useLocation, Navigate, Outlet, useNavigate, useParams } from 'react-router-dom';
import store from '../../redux/store'
import StoreHandler from '../../redux/StoreHandler'
import { useSelector, useDispatch } from 'react-redux'
import UserAction from '../../redux/action/userAction'

import ReverseProxy from '../../config/reverseProxy'
import ProjectsHandler from '../../Handlers/Projects/Projects';
import Icons from '../../assets/Icons'
import Images from '../../assets/Images'
import { CostInput, TextInput, ColorInput, SelectInput, DateTimeInput, RadioInput } from '../../components/Inputs'
import Utils from '../../utils'
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

import AlertPopup from '../../components/AlertPopup'
import SystemToastPopup from '../../components/SystemToastPopup'
import Loading from '../../components/Loading'

import { useAuth, useMenuAuth } from '../../hooks/useAuth'

const ViewProject = ({ props }) => {

    const { projectId } = useParams();

    const { menuType = 'create', isEditView = false } = props

    const [isLoading, setIsLoading] = useState(false)
    const [warningAlert, setWarningAlert] = useState(false)
    const [warningAlertType, setWarningAlertType] = useState('warning')
    const [apiFailedMessage, setApiFailedMessage] = useState('Error in Create Projects')

    const navigate = useNavigate()
    const store = useSelector((store) => store)
    const dispatch = useDispatch()
    const { updateState } = new UserAction

    const storeHandler = new StoreHandler({
        dataStack: ['company', 'field_settings', 'clients'],
        store: store,
        dispatch: dispatch,
        updateState: updateState,
    })

    const LoadStoreData = async () => {
        setIsLoading(true)
        let resolved = await storeHandler.init()
        setIsLoading(false)
    }

    const { isAdmin, menu_permission } = useMenuAuth({ menu: 'project_management--projects', feature_key: 'projects' })
    const location = useLocation()

    const [selected_view_settings_value, setSelected_view_settings_value] = useState('')
    const [has_view_access, setHas_view_access] = useState(false)
    const [has_add_access, setHas_add_access] = useState(false)
    const [has_edit_access, setHas_edit_access] = useState(false)
    const [has_delete_access, setHas_delete_access] = useState(false)

    useEffect(() => {

        if (isAdmin) {

            setHas_view_access(true)
            setHas_add_access(true)
            setHas_edit_access(true)
            setHas_delete_access(true)
        }

        else if (menu_permission) {

            if (
                !menu_permission._view
                &&
                (!menu_permission.selected_view_settings_value
                    || menu_permission.selected_view_settings_value == 'no_access')
            ) {
                navigate(`${ReverseProxy['proxyUrl']}/quick-menu`, { state: { from: location }, replace: true })
            }
            setHas_view_access(menu_permission._view)
            setHas_add_access(menu_permission._create)
            setHas_edit_access(menu_permission._edit)
            setHas_delete_access(menu_permission._delete)

            setSelected_view_settings_value(menu_permission.selected_view_settings_value)

        }

    }, [store])


    const projectsHandler = new ProjectsHandler()

    const [id, setId] = useState('')
    const [name, setName] = useState('')
    const [projectID, setProjectID] = useState('')
    const [projectTypeID, setProjectTypeID] = useState('')
    const [projectTypeName, setProjectTypeName] = useState('')
    const [statusID, setStatusID] = useState('')
    const [statusName, setStatusName] = useState('')
    const [statusColor, setStatusColor] = useState('')

    const [City, setCity] = useState('')
    const [State, setState] = useState('')
    const [address, setAddress] = useState('')

    const [description, setDescription] = useState('')
    const [billing_address, setBilling_address] = useState('')
    const [delivery_address, setDelivery_address] = useState('')
    const [work_address, setWork_address] = useState('')
    const [area, setArea] = useState(0)
    const [Floors, setFloors] = useState(0)
    const [unitID, setUnitID] = useState('')
    const [unitName, setUnitName] = useState('')
    const [price, setPrice] = useState(0)
    const [colorcode, setColorCode] = useState(Utils.getRandomColor())

    const [AssignedProjectUsers, setAssignedProjectUsers] = useState([])
    const [ProjectBanner_URL, setProjectBanner_URL] = useState('')

    const [AssignedProjectClients, setAssignedProjectClients] = useState([])


    // Import Dates
    const [createdate, setCreatedate] = useState('')
    const [startdate, setStartdate] = useState('')
    const [enddate, setEnddate] = useState('')
    const [RecceDueDate, setRecceDueDate] = useState('')

    const HandleEditProject = () => {
        if (!has_edit_access) return
        navigate(`${ReverseProxy['proxyUrl']}/builder/projects/${projectId}/edit`)
    }

    const LoadProject = async () => {

        let filters = {
            projectID: projectId
        }

        let response = await projectsHandler.getProjectsHandler(filters)

        if (response && response.success && Array.isArray(response.data) && response.data.length) {

            let {
                id,
                name,
                projectID,
                banner_image,
                type_id,
                type_name,
                status_id,
                status_name,
                status_color,
                city, state,
                address,
                description,
                client_id,
                client_name,
                createdate,
                startdate,
                enddate,
                price,
                colorcode,
                area,
                floors,
                unit_id,
                unit_name,
                contact_person_name,
                contact_person_phone,
                billing_address,
                delivery_address,
                work_address,
                team_users,
                clients

            } = response.data[0]

            createdate = createdate ? Utils.getLocalFullDateBYFormat(createdate, '-', 'YYYY/MM/DD') : ''
            startdate = startdate ? Utils.getLocalFullDateBYFormat(startdate, '-', 'YYYY/MM/DD') : ''
            enddate = enddate ? Utils.getLocalFullDateBYFormat(enddate, '-', 'YYYY/MM/DD') : ''

            setId(id)
            setName(name)
            setProjectID(projectID)
            setProjectTypeID(type_id)
            setProjectTypeName(type_name)
            setStatusID(status_id)
            setStatusName(status_name)
            setStatusColor(status_color)

            setCity(city)
            setState(state)
            setAddress(address)
            setCreatedate(createdate)
            setStartdate(startdate)
            setEnddate(enddate)
            setDescription(description || "")
            setArea(area || 0)
            setFloors(floors || 0)
            setUnitID(unit_id)
            setUnitName(unit_name)
            setPrice(price || 0)
            setColorCode(colorcode || Utils.getRandomColor())


            setBilling_address(billing_address || "")
            setDelivery_address(delivery_address || "")
            setWork_address(work_address || "")

            setAssignedProjectUsers(team_users || [])
            setAssignedProjectClients(clients || [])

            if (banner_image) {
                setProjectBanner_URL(`data:image/png;base64,${banner_image}`)
            } else {
                setProjectBanner_URL(Images.DEFAULT_PROJECT_BANNER)
            }

        } else {
            setWarningAlert(true)
            setWarningAlertType('error')
            setApiFailedMessage(`Error in Projects, Please try again!`)
        }
    }

    useEffect(() => {

        LoadProject()
    }, [])



    return (
        <>


            {isLoading ?
                <Loading
                    props={{
                        isMainLogo: false,
                        isLabel: true
                    }} />
                : null}
            {warningAlert ?

                <SystemToastPopup
                    props={{
                        type: warningAlertType,
                        message: apiFailedMessage || "Error in Create Projects",
                        callback: (confirmation) => setWarningAlert(false)
                    }} />

                : null}



            <div className="project-projects-main">
                <div className="projects-panel-content">

                    <div className="panel-content-header">
                        <div className="left">
                            <div className="title">Project Details</div>
                        </div>
                        <div className="right">
                            {has_edit_access ?
                                <div
                                    className="edit-button"
                                    onClick={() => HandleEditProject()}
                                >
                                    <div className="icon"
                                        dangerouslySetInnerHTML={{ __html: Icons.general.edit_btn }}
                                    ></div>
                                    <div className="label">Edit</div>
                                </div>
                                : ''}
                        </div>
                    </div>
                    <div className="panel-content-sections">
                        <div className="create-project-content-main">

                            <div className="create-project-sections">
                                <div className="project-banner-main">
                                    <div className="project-banner-image">
                                        <img src={ProjectBanner_URL || ""} />
                                    </div>
                                </div>
                                {/* Basic Details */}
                                <div className="create-project-section">
                                    <div className="section-header">
                                        <div className="title">Basic Details</div>
                                    </div>
                                    <div className="section-input-items">
                                        <div className="section-input-item view-input">
                                            <div className="view-input-label">Project ID</div>
                                            <div className="view-input-value">{projectID}</div>
                                        </div>
                                        <div className="section-input-item view-input">
                                            <div className="view-input-label">Project Name</div>
                                            <div className="view-input-value">{name}</div>
                                        </div>

                                        <div className="section-input-item view-input">
                                            <div className="view-input-label">Color Code</div>
                                            <div className="view-input-value">
                                                <div className="color-code" style={{ background: colorcode }}></div>
                                                {colorcode}
                                            </div>
                                        </div>
                                        <div className="section-input-item view-input">
                                            <div className="view-input-label">Project Status</div>
                                            <div className="view-input-value" style={{
                                                background: statusColor,
                                                padding: '3px 6px',
                                                borderRadius: '5px',
                                                color: '#fff',
                                                width: 'max-content'
                                            }}>{statusName}</div>
                                        </div>
                                        <div className="section-input-item view-input">
                                            <div className="view-input-label">State</div>
                                            <div className="view-input-value">{State}</div>
                                        </div>
                                        <div className="section-input-item view-input">
                                            <div className="view-input-label">City</div>
                                            <div className="view-input-value">{City}</div>
                                        </div>


                                        <div className="section-full-itm view-input">
                                            <div className="view-input-label">Project Address</div>
                                            <div className="view-input-value">{address}</div>
                                        </div>
                                        <div className="section-full-itm view-input">
                                            <div className="view-input-label">Project Description</div>
                                            <div className="view-input-value">{description}</div>
                                        </div>
                                    </div>
                                </div>
                                {/* Project Scope */}
                                <div className="create-project-section">
                                    <div className="section-header">
                                        <div className="title">Project Scope</div>
                                    </div>
                                    <div className="section-input-items">

                                        <div className="section-input-item view-input">
                                            <div className="view-input-label">Project Type</div>
                                            <div className="view-input-value">{projectTypeName}</div>
                                        </div>
                                        <div className="section-input-item view-input">
                                            <div className="view-input-label">Project Ares (in SQFT)</div>
                                            <div className="view-input-value">{area}</div>
                                        </div>
                                        <div className="section-input-item view-input">
                                            <div className="view-input-label">Project Floors</div>
                                            <div className="view-input-value">G + {Floors}</div>
                                        </div>
                                        <div className="section-input-item view-input">
                                            <div className="view-input-label">Project Estimate (in INR)</div>
                                            <div className="view-input-value">{price}</div>
                                        </div>
                                    </div>
                                </div>
                                {/* Client POC Details */}
                                <div className="create-project-section">
                                    <div className="section-header">
                                        <div className="title">Clients & Details</div>
                                    </div>
                                    <div className="project-multi_client-main">

                                        <div className="multi_client-section">
                                            <div className="multi_client-items">
                                                {AssignedProjectClients.map((user, index) => (
                                                    <div
                                                        className="multi_client-item"
                                                        key={`multi_client-item-${index}`}
                                                    >
                                                        <div
                                                            className="multi_client-item-icon"
                                                            style={{ background: user.client_color_code }}
                                                        >
                                                            {Utils.getNameInitials(user.client_name)}
                                                        </div>
                                                        <div className="multi_client-item-details">
                                                            <div className="multi_client-item-name">{user.client_name}</div>
                                                            <div className="multi_client-item-desc">Contact: {user.contact_person_name} | {user.contact_person_phone}</div>
                                                        </div>

                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* Important Dates */}
                                <div className="create-project-section">
                                    <div className="section-header">
                                        <div className="title">Important Dates</div>
                                    </div>
                                    <div className="section-input-items">

                                        <div className="section-input-item view-input">
                                            <div className="view-input-label">Project Created Date</div>
                                            <div className="view-input-value">{createdate}</div>
                                        </div>
                                        <div className="section-input-item view-input" style={{ display: 'none' }}>
                                            <div className="view-input-label">Recce Due Date</div>
                                            <div className="view-input-value">{RecceDueDate}</div>
                                        </div>
                                        <div className="section-input-item view-input">
                                            <div className="view-input-label">Expected Start Date</div>
                                            <div className="view-input-value">{startdate}</div>
                                        </div>
                                        <div className="section-input-item view-input">
                                            <div className="view-input-label">Expected Due Date</div>
                                            <div className="view-input-value">{enddate}</div>
                                        </div>
                                    </div>
                                </div>
                                {/* Project Users */}
                                <div className="create-project-section">
                                    <div className="section-header">
                                        <div className="title">Project Team</div>
                                    </div>
                                    <div className="project-team-section">
                                        <div className="project-team-items">

                                            {AssignedProjectUsers.map((user, index) => (
                                                <div
                                                    className="project-team-item"
                                                    key={`project-team-item-${index}`}
                                                >
                                                    <div
                                                        className="team-item-icon"
                                                        style={{ background: user.color_code }}
                                                    >
                                                        {Utils.getNameInitials(user.name)}
                                                    </div>
                                                    <div className="team-item-details">
                                                        <div className="team-item-name">{user.name}</div>
                                                        <div className="team-item-desc">{user.role_name} | {user.phone}</div>
                                                    </div>
                                                </div>

                                            ))}
                                        </div>
                                    </div>

                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </>
    )

}

export default ViewProject;