

import React, { useEffect, useState, useRef } from 'react'
import { Route, Routes, BrowserRouter, Link, useLocation, Navigate, Outlet, useNavigate, useParams } from 'react-router-dom';
import store from '../../redux/store'
import StoreHandler from '../../redux/StoreHandler'
import { useSelector, useDispatch } from 'react-redux'
import UserAction from '../../redux/action/userAction'

import ReverseProxy from '../../config/reverseProxy'
import TaskManagementHandler from '../../Handlers/TaskManagement/TaskManagement';
import LabourProjectAttendanceHandler from '../../Handlers/LabourManagement/LabourProjectAttendance';
import IssuesManagementHandler from '../../Handlers/IssuesManagement/IssuesManagement';
import PO_GRNHandler from '../../Handlers/PO_GRN/PO_GRN';
import IndentsHandler from '../../Handlers/Indents/Indents';
import PurchaseOrderHandler from '../../Handlers/PurchaseOrder/PurchaseOrder';
import MaterialIssueHandler from '../../Handlers/MaterialIssue/MaterialIssue';

import Icons from '../../assets/Icons'
import Images from '../../assets/Images'
import { CostInput, TextInput, SelectInput, DateTimeInput, RadioInput } from '../../components/Inputs'
import Utils from '../../utils'
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

import AlertPopup from '../../components/AlertPopup'
import Loading from '../../components/Loading'
import SystemToastPopup from '../../components/SystemToastPopup'


import { PDFExport, savePDF } from '@progress/kendo-react-pdf';

import { useAuth, useMenuAuth } from '../../hooks/useAuth'

const ProgressReportPreview = ({ props }) => {

    const { projectId } = useParams();

    const { close, reportLoaded, contentsettings, reportdetials } = props

    const [project_id, setProject_id] = useState('')
    const [project_name, setProject_name] = useState('')
    const [estimate_id, setEstimate_id] = useState('')

    const [isLoading, setIsLoading] = useState(false)
    const [warningAlert, setWarningAlert] = useState(false)
    const [warningAlertType, setWarningAlertType] = useState('warning')
    const [apiFailedMessage, setApiFailedMessage] = useState("Error in Server")

    const navigate = useNavigate()
    const store = useSelector((store) => store)
    const dispatch = useDispatch()
    const { updateState } = new UserAction

    const storeHandler = new StoreHandler({
        dataStack: ['company', 'terms_conditions', 'clients', 'field_settings', 'finance_settings', `project-${projectId}`],
        store: store,
        dispatch: dispatch,
        updateState: updateState,
    })

    const LoadStoreData = async () => {
        setIsLoading(true)
        let resolved = await storeHandler.init()
        setIsLoading(false)
    }
    const pdfRef = useRef()

    const [ContentSettings, setContentSettings] = useState(contentsettings || {})
    const [companyImg, setCompanyImg] = useState('')
    const [companyName, setCompanyName] = useState('N/A')
    const [projectName, setProjectName] = useState('N/A')
    const [projectArea, setProjectArea] = useState('N/A')
    const [projectID, setProjectID] = useState('N/A')
    const [clientId, setClientId] = useState('')
    const [clientName, setClientName] = useState('N/A')
    const [clientEmail, setClientEmail] = useState('N/A')
    const [clientNumber, setClientNumber] = useState('N/A')
    const [projectAddress, setProjectAddress] = useState('N/A')
    const [dateFormat, setDateFormat] = useState('DD/MM/YYYY')
    const [StartDate, setStartDate] = useState(Utils._getLocalDate())
    const [EndDate, setEndDate] = useState(Utils._getLocalDate())


    const [unitsOptions, setUnitsOptions] = useState([])
    const [currency_type, setCurrency_type] = useState('₹')


    const [ReportTaskStatusData, setReportTaskStatusData] = useState({
        in_progress: 0,
        late: 0,
        completed: 0,
        not_started: 0,
    })
    const [ReportOverallSiteActivityData, setReportOverallSiteActivityData] = useState({
        tasks_updated: 0,
        issues_created: 0,
        attendance_updated: 0,
    })
    const [ReportTasksData, setReportTasksData] = useState([])
    const [ReportAttendanceData, setReportAttendanceData] = useState({

        vendor_attendance: [],
        labour_attendance: []
    })
    const [ReportIndentsData, setReportIndentsData] = useState([])
    const [ReportPurchaseOrderData, setReportPurchaseOrderData] = useState([])
    const [ReportMaterialInventoryData, setReportMaterialInventoryData] = useState([])
    const [ReportGRNData, setReportGRNData] = useState([])
    const [ReportIssuesData, setReportIssuesData] = useState([])




    const getInventoryDetialsTitle = (materials_log) => {

        if (materials_log.action_type == '0') return 'Issued to task'
        else if (materials_log.action_type == '1') return 'Site transfered'
        else if (materials_log.action_type == '2') return 'Added to stock'
        else if (materials_log.action_type == '3') return 'Added by GRN'
        else return 'N/A'
    }
    const getInventoryDetialsDesc = (materials_log) => {

        if (materials_log.action_type == '0') return `Task: ${materials_log.task_name}`
        else if (materials_log.action_type == '1') return `Site: ${materials_log.transfered_site_name}`
        else if (materials_log.action_type == '2') return ``
        else if (materials_log.action_type == '3') return `GRN No: ${materials_log.grn_no}`
        else return 'N/A'
    }

    const HandleDownloadBtn = () => {

        pdfRef.current.save();
        // savePDF(pdfRef.current, { paperSize: "A4" });
    }

    const CheckMenuEnable = (menu) => {

        if (ContentSettings && ContentSettings.filter(opt => opt.id == menu && opt.checked)[0]) return true
        else return false
    }


    const HandleCancel = async (e) => {

        close()
    }

    useEffect(() => {

        if (reportLoaded && reportdetials && Object.keys(reportdetials).length) {

            if (reportdetials && reportdetials.task_status) setReportTaskStatusData(reportdetials.task_status)
            if (reportdetials && reportdetials.overall_site_activity) setReportOverallSiteActivityData(reportdetials.overall_site_activity)

            if (reportdetials && reportdetials.progress) setReportTasksData(reportdetials.progress)
            if (reportdetials.attendance) setReportAttendanceData(reportdetials.attendance)
            if (reportdetials.indents) setReportIndentsData(reportdetials.indents)
            if (reportdetials.purchas_orders) setReportPurchaseOrderData(reportdetials.purchas_orders)
            if (reportdetials.grns) setReportGRNData(reportdetials.grns)
            if (reportdetials.issues) setReportIssuesData(reportdetials.issues)
            if (reportdetials.inventory) setReportMaterialInventoryData(reportdetials.inventory)
        }

        LoadStoreData()

    }, [])

    const getIndentStatusEnem = (status) => {

        if (status == '0') return 'Pending'
        else if (status == '1') return 'Approved'
        else if (status == '2') return 'Rejected'
        else return 'N/A'
    }

    useEffect(() => {

        if (store && store.user) {

            let { company, clients, settings, selected_project } = store.user

            if (selected_project) {
                let { name, status, area, unit_id, unit_name, type_id, type_name, address, client_id, client_name } = selected_project

                setProjectName(name || 'N/A')
                setProjectArea(`${area}${unit_name || ""}` || 'N/A')
                setProjectID(projectId)
                setProjectAddress(address || "N/A")
                setClientName(client_name || "N/A")


                if (clients && Array.isArray(clients) && clients.length) {

                    let _client = clients.filter(opt => opt.id == client_id)[0]

                    if (_client) {
                        let { name, email, phone } = _client

                        setClientName(name || 'N/A')
                        setClientEmail(email || 'N/A')
                        setClientNumber(phone || 'N/A')
                    }
                }
            }

            if (company) {
                let { name, logo_img, currency_setting, date_format_setting, timezone, temperature_setting } = company

                const profileImgURL = logo_img ? `data:image/png;base64,${logo_img}` : ''

                setCompanyImg(profileImgURL)
                setCompanyName(name || 'N/A')
                setDateFormat(date_format_setting || 'DD/MM/YYYY')
                if (currency_setting) setCurrency_type(currency_setting)
            }


            if (settings && Array.isArray(settings.field_settings) && settings.field_settings.length) {

                let { project_statuses, project_types, units } = settings.field_settings[0]

                units = units.filter(opt => opt.isActive).map((opt) => {
                    return {
                        value: opt.id,
                        label: opt.name,
                        isDefault: opt.isDefault || false
                    }
                })

                setUnitsOptions(units || [])

            }

        }

    }, [store])

    return (
        <div className="popup-container-main popup-container-center">
            <div className="popup-block-ui"></div>
            <div className="side-popup-container report-preview-popup-container">

                <div className="side-popup-header">
                    <div className="header-item-select">
                        <div className="header-item-select-content">
                            <div className="label">Progress Report</div>
                        </div>
                    </div>
                    <div
                        className="header-item-close"
                        onClick={(e) => HandleCancel()}
                        dangerouslySetInnerHTML={{ __html: Icons.general.close }}
                    ></div>
                </div>

                <div className="sidebar-popup-content">

                    <div className="progress-report-main" style={{ width: '100%', height: '100%' }}>

                        <PDFExport
                            ref={pdfRef}
                            author='CivilATOR'
                            fileName={`${String(projectName).split(' ').join('_')}_Progress_Report`}
                            margin={5}
                            paperSize="A4"
                            scale={0.6}
                        >

                            <div className="progress-report-content-main">
                                <div className="progress-report-into">
                                    <div className="progress-report-into-bg"></div>
                                    <div className="into-header">
                                        <div className="logo">
                                            <img src={companyImg} alt="" />
                                        </div>
                                        <div className="title">TR Constructions</div>
                                    </div>
                                    <div className="into-content">
                                        <div className="into-content-title">Project Report</div>
                                        <div className="into-content-desc">{projectName}</div>
                                        <div className="into-content-detials">
                                            <div className="detials-dates">
                                                <div className="detials-date">{Utils.getLocalFullDateLabel()}</div>
                                                <span>-</span>
                                                <div className="detials-date">{Utils.getLocalFullDateLabel()}</div>
                                            </div>
                                            <div className="detials-item">
                                                <div className="label">CREATED BY</div>
                                                <div className="value">Raja T</div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                                <div className="progress-report-section">

                                    <div className="report-section-totals">
                                        <div className="totals-title">TASK STATUS</div>
                                        <div className="totals-items">
                                            <div className={`totals-item totals-item-warning`}>
                                                <div className="value">{ReportTaskStatusData.in_progress}</div>
                                                <div className="label">In Progress</div>
                                            </div>
                                            <div className={`totals-item totals-item-danger`}>
                                                <div className="value">{ReportTaskStatusData.late}</div>
                                                <div className="label">Late</div>
                                            </div>
                                            <div className={`totals-item totals-item-success`}>
                                                <div className="value">{ReportTaskStatusData.completed}</div>
                                                <div className="label">Completed</div>
                                            </div>
                                            <div className={`totals-item`}>
                                                <div className="value">{ReportTaskStatusData.not_started}</div>
                                                <div className="label">Not Started</div>
                                            </div>

                                        </div>
                                    </div>

                                    <div className="report-section-big-totals">
                                        <div className="totals-title">OVERALL SITE ACTIVITY TODAY</div>
                                        <div className="totals-items">
                                            <div className={`totals-item totals-item-warning`}>
                                                <div className="value">{ReportOverallSiteActivityData.tasks_updated}</div>
                                                <div className="label">Tasks Updated</div>
                                            </div>
                                            <div className={`totals-item totals-item-danger`}>
                                                <div className="value">{ReportOverallSiteActivityData.issues_created}</div>
                                                <div className="label">Issued Created</div>
                                            </div>
                                            <div className={`totals-item totals-item-danger`}>
                                                <div className="value">{ReportOverallSiteActivityData.attendance_updated}</div>
                                                <div className="label">Attendance Logs</div>
                                            </div>

                                        </div>
                                    </div>

                                    {CheckMenuEnable('progress') && ReportTasksData.length ?
                                        <div className="report-section-tasks-updates">
                                            <div className="report-section-title">TASK UPDATES</div>

                                            <div className="report-section-tasks-items">

                                                {
                                                    ReportTasksData.map((dateTasks, didx) => (

                                                        <div className="tasks-item" key={`progress-date-${dateTasks.date}`}>
                                                            <div className="tasks-date">{dateTasks.date}</div>
                                                            <div className="tasks-content">
                                                                {dateTasks.tasks.map((categoryTasks, cidx) => (
                                                                    <div className="tasks-content-item" key={`category-${categoryTasks.category_id}`}>

                                                                        <div className="tasks-item-category">{categoryTasks.category_name}</div>
                                                                        <div className="tasks-item-category-tasks">

                                                                            <div className="tasks-item-category-task">

                                                                                <div className="task-table-main">
                                                                                    <div className="task-table-header">
                                                                                        <div className="task-table-header-item">Task</div>
                                                                                        <div className="task-table-header-item small-item">Total <br /> Work</div>
                                                                                        <div className="task-table-header-subitem">
                                                                                            <div className="subitem-title">Progress</div>
                                                                                            <div className="subitem-subtitles">
                                                                                                <div className="subitem-subtitle">Total</div>
                                                                                                <div className="subitem-subtitle">Today's</div>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="task-table-header-item">Attendance</div>
                                                                                        <div className="task-table-header-item">Remarks</div>
                                                                                    </div>
                                                                                    <div className="task-table-body">
                                                                                        {
                                                                                            categoryTasks.category_tasks.map((task, tidx) => (
                                                                                                <div className="task-table-body-row table-task-row" >
                                                                                                    <span className={`body-item-status body-item-status-${task.status}`}></span>
                                                                                                    <div className="task-table-body-item task-table-body-item-name">{task.name}</div>
                                                                                                    <div className="task-table-body-item small-item">{parseFloat(task.total_work).toFixed(0)} <br /> {task.unit_name}</div>
                                                                                                    <div className="task-table-body-subitem">
                                                                                                        <div className="body-subitem-item">
                                                                                                            {parseFloat(task.total_work_completed).toFixed(0)} {task.unit_name}
                                                                                                            <br />
                                                                                                            ({parseFloat(task.total_work_completed_percentage).toFixed(0)}%)
                                                                                                        </div>
                                                                                                        <div className="body-subitem-item">
                                                                                                            {parseFloat(task.today_work_completed).toFixed(0)} {task.unit_name}
                                                                                                            <br />
                                                                                                            ({parseFloat(task.today_work_completed_percentage).toFixed(0)}%)
                                                                                                        </div>
                                                                                                    </div>

                                                                                                    <div className="task-table-body-item">
                                                                                                        <div className="body-item-brief">
                                                                                                            {
                                                                                                                task.attendance.length ?

                                                                                                                    task.attendance.map((att, aidx) => (
                                                                                                                        <div
                                                                                                                            className="brief-item"
                                                                                                                            key={`task-attendance-${att.id}`}>
                                                                                                                            <div className="label">{att.worktype_name}: </div>
                                                                                                                            <div className="value">{att.count}</div>
                                                                                                                        </div>
                                                                                                                    ))
                                                                                                                    : '--'
                                                                                                            }

                                                                                                        </div>
                                                                                                    </div>
                                                                                                    <div className="task-table-body-item">{task.remarks || '--'}</div>
                                                                                                </div>
                                                                                            ))
                                                                                        }


                                                                                    </div>
                                                                                </div>



                                                                            </div>


                                                                        </div>



                                                                    </div>
                                                                ))}



                                                            </div>
                                                        </div>

                                                    ))
                                                }

                                            </div>
                                        </div>
                                        : ''}
                                    {CheckMenuEnable('attendance') && (ReportAttendanceData.vendor_attendance.length || ReportAttendanceData.labour_attendance.length) ?
                                        <div className="report-section-tasks-updates">
                                            <div className="report-section-title">ATTENDANCE</div>

                                            <div className="report-section-attendance-items">


                                                {ReportAttendanceData.vendor_attendance.length ?
                                                    <div className="tasks-item">
                                                        <div className="tasks-date">Vendor Attendance</div>
                                                        <div className="tasks-content">

                                                            {ReportAttendanceData.vendor_attendance?.map((vendor_attendances, aidx) => (

                                                                <div className="tasks-content-item">

                                                                    <div className="tasks-item-category">Vendor: {vendor_attendances.vendor_name} | Total Payables: {vendor_attendances.total_payable}</div>
                                                                    <div className="tasks-item-category-tasks">

                                                                        <div className="tasks-item-category-task">

                                                                            <div className="task-table-main">
                                                                                <div className="task-table-header" style={{ height: '50px' }}>
                                                                                    <div className="task-table-header-item">Date</div>
                                                                                    <div className="task-table-header-item">Work Category</div>
                                                                                    <div className="task-table-header-item" style={{ width: '40%' }}>Workers Count</div>
                                                                                    <div className="task-table-header-item">Total Workers</div>
                                                                                    <div className="task-table-header-item">Total Payables</div>
                                                                                </div>
                                                                                <div className="task-table-body">

                                                                                    {
                                                                                        vendor_attendances.vendor_attendance?.map((attendance, aidx) => (

                                                                                            <div
                                                                                                className="task-table-body-row table-task-row"
                                                                                            >
                                                                                                <div className="task-table-body-item">{Utils._getLocalDate(attendance.date)}</div>
                                                                                                <div className="task-table-body-item">{attendance.workcategory_name || 'N/A'}</div>

                                                                                                <div className="task-table-body-item" style={{ width: '40%' }}>
                                                                                                    <div className="body-item-brief">
                                                                                                        {
                                                                                                            attendance.workers?.map((worker, widx) => (
                                                                                                                <div
                                                                                                                    className="brief-item"
                                                                                                                    key={`attendance-worker-${worker.id}`}
                                                                                                                >
                                                                                                                    <div className="label">{worker.worktype_name}: </div>
                                                                                                                    <div className="value">{worker.no_workers}</div>
                                                                                                                    |
                                                                                                                    <div className="label">Payable: </div>
                                                                                                                    <div className="value">{parseFloat(worker.to_pay).toFixed(2)}</div>
                                                                                                                </div>
                                                                                                            ))
                                                                                                        }
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div className="task-table-body-item">{attendance.total_workers}</div>
                                                                                                <div className="task-table-body-item">{parseFloat(attendance.total_topay).toFixed(2)}</div>
                                                                                            </div>


                                                                                        ))
                                                                                    }





                                                                                </div>
                                                                            </div>



                                                                        </div>


                                                                    </div>



                                                                </div>


                                                            ))}




                                                        </div>
                                                    </div>
                                                    : ''}

                                                {ReportAttendanceData.labour_attendance.length ?
                                                    <div className="tasks-item">
                                                        <div className="tasks-date">Labour Attendance</div>
                                                        <div className="tasks-content">
                                                            <div className="tasks-content-item">

                                                                <div className="tasks-item-category">{StartDate} - {EndDate}</div>
                                                                <div className="tasks-item-category-tasks">

                                                                    <div className="tasks-item-category-task">

                                                                        <div className="task-table-main">
                                                                            <div className="task-table-header" style={{ height: '50px' }}>
                                                                                <div className="task-table-header-item">Labor Name</div>
                                                                                <div className="task-table-header-item" >Per day Salary</div>
                                                                                <div className="task-table-header-item">Present Days</div>
                                                                                <div className="task-table-header-item">Half Days</div>
                                                                                <div className="task-table-header-item">Absent Days</div>
                                                                                <div className="task-table-header-item">Overtime Hrs</div>
                                                                                <div className="task-table-header-item">Total Payable</div>
                                                                            </div>
                                                                            <div className="task-table-body">

                                                                                {
                                                                                    ReportAttendanceData.labour_attendance?.map((attendance, aidx) => (

                                                                                        <div
                                                                                            className="task-table-body-row table-task-row"
                                                                                            key={`attendance-${attendance.id}`}
                                                                                        >
                                                                                            <div className="task-table-body-item"
                                                                                                style={{
                                                                                                    display: 'flex',
                                                                                                    flexDirection: 'column',
                                                                                                    alignContent: 'center',
                                                                                                    justifyContent: 'center'
                                                                                                }}
                                                                                            >
                                                                                                <strong>{attendance.name}</strong>
                                                                                                <span className='table-row-item-span'>{attendance.worktype_name}</span>
                                                                                            </div>
                                                                                            <div className="task-table-body-item">{parseFloat(attendance.salary_amount).toFixed(0)}</div>
                                                                                            <div className="task-table-body-item">{attendance.total_present_days}</div>
                                                                                            <div className="task-table-body-item">{attendance.total_half_days}</div>
                                                                                            <div className="task-table-body-item">{attendance.total_absent_days}</div>
                                                                                            <div className="task-table-body-item">{attendance.total_ovettime_hours}</div>
                                                                                            <div className="task-table-body-item">{parseFloat(attendance.total_payable).toFixed(2)}</div>
                                                                                        </div>


                                                                                    ))
                                                                                }





                                                                            </div>
                                                                        </div>



                                                                    </div>


                                                                </div>



                                                            </div>



                                                        </div>
                                                    </div>
                                                    : ''}



                                            </div>
                                        </div>
                                        : ''}
                                    {CheckMenuEnable('material_requests') && ReportIndentsData.length ?
                                        <div className="report-section-tasks-updates">
                                            <div className="report-section-title">INDENT</div>

                                            <div className="report-section-attendance-items">

                                                <div className="tasks-item">
                                                    <div className="tasks-content">
                                                        <div className="tasks-content-item">

                                                            <div className="tasks-item-category-tasks">

                                                                {
                                                                    ReportIndentsData?.map((indent, iidx) => (

                                                                        <div
                                                                            className="tasks-item-category-task"
                                                                            key={`indent-${indent.id}`}
                                                                        >

                                                                            <div className="task-item-top-detials">
                                                                                <div className="detials-item">
                                                                                    <div className="label">Indent No:</div>
                                                                                    <div className="value">{indent.indent_no}</div>
                                                                                </div>
                                                                                <div className="detials-item">
                                                                                    <div className="label">Created On:</div>
                                                                                    <div className="value">{Utils._getLocalDate(indent.created_at)}</div>
                                                                                </div>
                                                                                <div className="detials-item">
                                                                                    <div className="label">Created By:</div>
                                                                                    <div className="value">{indent.assigned_name}</div>
                                                                                </div>
                                                                                <div className="detials-item">
                                                                                    <div className="label">Status:</div>
                                                                                    <div className={`value detials-item-status-${indent.status}`}>{getIndentStatusEnem(indent.status)}</div>
                                                                                </div>
                                                                                <div className="detials-item">
                                                                                    <div className="label">Expected On:</div>
                                                                                    <div className="value">{indent.delivery_date}</div>
                                                                                </div>
                                                                            </div>

                                                                            <div className="task-table-main">
                                                                                <div className="task-table-header" style={{ height: '50px', background: 'var(--primary-trans-color)' }}>
                                                                                    <div className="task-table-header-item" style={{ width: '50%' }}>Material Name</div>
                                                                                    <div className="task-table-header-item" >Requested Quandity</div>
                                                                                    <div className="task-table-header-item">Ordered Quandity</div>
                                                                                </div>
                                                                                <div className="task-table-body">

                                                                                    {
                                                                                        indent.cost_items?.map((costitem, aidx) => (

                                                                                            <div
                                                                                                className="task-table-body-row table-task-row"
                                                                                                key={`costitem-${costitem.id}`}
                                                                                            >
                                                                                                <div className="task-table-body-item" style={{ width: '50%' }}>{costitem.material_name}</div>
                                                                                                <div className="task-table-body-item">{costitem.quantity} {costitem.unit_name}</div>
                                                                                                <div className="task-table-body-item">{costitem.ordered_quantity || 0} {costitem.unit_name}</div>
                                                                                            </div>


                                                                                        ))
                                                                                    }





                                                                                </div>
                                                                            </div>

                                                                            <div className="task-item-top-detials" style={{ flexWrap: 'wrap', gap: '20px' }}>
                                                                                <div className="detials-item"
                                                                                    style={{ width: '100%', flexDirection: 'row', alignItems: 'center', gap: '5px' }}
                                                                                >
                                                                                    <div className="label">Remarks:</div>
                                                                                    <div className="value">{indent.remarks}</div>
                                                                                </div>
                                                                                <div className="detials-item" style={{ width: '48%' }}>
                                                                                    <div className="label">Converted to PO:</div>
                                                                                    <div className={`value detials-item-status-${indent.converted_to_po == '0' ? '2' : '1'}`}>{indent.converted_to_po ? 'Yes' : 'Not yet'}</div>
                                                                                </div>
                                                                                <div className="detials-item" style={{ width: '48%' }}>
                                                                                    <div className="label">Linked PO:</div>
                                                                                    <div className={`value detials-item-status-${indent.linked_po ? '1' : '-1'}`}>{indent.linked_po || '-'}</div>
                                                                                </div>
                                                                            </div>


                                                                        </div>


                                                                    ))
                                                                }




                                                            </div>



                                                        </div>



                                                    </div>
                                                </div>

                                            </div>

                                        </div>
                                        : ''}
                                    {CheckMenuEnable('purchas_orders') && ReportPurchaseOrderData.length ?
                                        <div className="report-section-tasks-updates">
                                            <div className="report-section-title">PURCHASE ORDERS</div>

                                            <div className="report-section-attendance-items">

                                                <div className="tasks-item">
                                                    <div className="tasks-content">
                                                        <div className="tasks-content-item">

                                                            <div className="tasks-item-category-tasks">

                                                                {
                                                                    ReportPurchaseOrderData?.map((po, iidx) => (

                                                                        <div
                                                                            className="tasks-item-category-task"
                                                                            key={`po-${po.id}`}
                                                                        >

                                                                            <div className="task-item-top-detials">
                                                                                <div className="detials-item">
                                                                                    <div className="label">PO No:</div>
                                                                                    <div className="value">{po.purchaseID}</div>
                                                                                </div>
                                                                                <div className="detials-item">
                                                                                    <div className="label">Created On:</div>
                                                                                    <div className="value">{Utils._getLocalDate(po.created_at)}</div>
                                                                                </div>
                                                                                <div className="detials-item">
                                                                                    <div className="label">Created By:</div>
                                                                                    <div className="value">{po.created_by_name || 'N/A'}</div>
                                                                                </div>
                                                                                <div className="detials-item">
                                                                                    <div className="label">Status:</div>
                                                                                    <div className={`value detials-item-status-${po.status}`}>{getIndentStatusEnem(po.status)}</div>
                                                                                </div>
                                                                                <div className="detials-item">
                                                                                    <div className="label">Expected On:</div>
                                                                                    <div className="value">{po.delivery_deadline_date}</div>
                                                                                </div>
                                                                            </div>

                                                                            <div className="task-table-main">
                                                                                <div className="task-table-header" style={{ height: '50px', background: 'var(--primary-trans-color)' }}>
                                                                                    <div className="task-table-header-item" style={{ width: '40%' }}>Material Name</div>
                                                                                    <div className="task-table-header-item" >Quandity</div>
                                                                                    <div className="task-table-header-item" >Unitrate</div>
                                                                                    <div className="task-table-header-item">Amount</div>
                                                                                </div>
                                                                                <div className="task-table-body">

                                                                                    {
                                                                                        po.cost_items?.map((costitem, aidx) => (

                                                                                            <div
                                                                                                className="task-table-body-row table-task-row"
                                                                                                key={`costitem-${costitem.id}`}
                                                                                            >
                                                                                                <div className="task-table-body-item" style={{ width: '40%' }}>{costitem.material_name}</div>
                                                                                                <div className="task-table-body-item">{costitem.quantity} {costitem.unit_name}</div>
                                                                                                <div className="task-table-body-item">{costitem.unitrate}/{costitem.unit_name}</div>
                                                                                                <div className="task-table-body-item">{costitem.amount}</div>
                                                                                            </div>


                                                                                        ))
                                                                                    }





                                                                                </div>
                                                                            </div>

                                                                            <div className="task-item-top-detials" style={{ flexWrap: 'wrap', gap: '20px' }}>
                                                                                <div className="detials-item"
                                                                                    style={{ width: '100%', flexDirection: 'row', alignItems: 'center', gap: '5px' }}
                                                                                >
                                                                                    <div className="label">Remarks:</div>
                                                                                    <div className="value">{po.introduction_text}</div>
                                                                                </div>
                                                                                <div className="detials-item" style={{ width: '30%' }}>
                                                                                    <div className="label">Total Amount:</div>
                                                                                    <div className={`value detials-item-status-${-1}`}>{po.purchase_total || '0.00'}</div>
                                                                                </div>
                                                                                <div className="detials-item" style={{ width: '30%' }}>
                                                                                    <div className="label">Paid amount:</div>
                                                                                    <div className={`value detials-item-status-${1}`}>{po.purchase_paid || '0.00'}</div>
                                                                                </div>
                                                                                <div className="detials-item" style={{ width: '30%' }}>
                                                                                    <div className="label">Due amount:</div>
                                                                                    <div className={`value detials-item-status-${2}`}>{po.purchase_due || '0.00'}</div>
                                                                                </div>
                                                                            </div>


                                                                        </div>


                                                                    ))
                                                                }




                                                            </div>



                                                        </div>



                                                    </div>
                                                </div>

                                            </div>

                                        </div>
                                        : ''}
                                    {CheckMenuEnable('inventory') && ReportMaterialInventoryData.length ?
                                        <div className="report-section-tasks-updates">
                                            <div className="report-section-title">INVENTORY</div>

                                            <div className="report-section-attendance-items">

                                                <div className="tasks-item">
                                                    <div className="tasks-content">
                                                        <div className="tasks-content-item">

                                                            <div className="tasks-item-category-tasks">

                                                                {
                                                                    ReportMaterialInventoryData?.map((material, iidx) => (

                                                                        <div
                                                                            className="tasks-item-category-task"
                                                                            key={`material-${material.id}`}
                                                                        >

                                                                            <div className="task-item-top-detials">
                                                                                <div className="detials-item">
                                                                                    <div className="label">Date:</div>
                                                                                    <div className="value">{material.date}</div>
                                                                                </div>
                                                                            </div>

                                                                            <div className="task-table-main">
                                                                                <div className="task-table-header" style={{ height: '50px', background: 'var(--primary-trans-color)' }}>
                                                                                    <div className="task-table-header-item">Material Name</div>
                                                                                    <div className="task-table-header-item">Opening Stock</div>
                                                                                    <div className="task-table-header-item">Used / transferred</div>
                                                                                    <div className="task-table-header-item">Added</div>
                                                                                    <div className="task-table-header-item">InStock</div>
                                                                                    <div className="task-table-header-item" style={{ width: '40%' }}>Detials</div>
                                                                                </div>
                                                                                <div className="task-table-body">

                                                                                    {
                                                                                        material.materials_logs?.map((log, aidx) => (

                                                                                            <div
                                                                                                className="task-table-body-row table-detials-row"
                                                                                                key={`log-${log.id}`}
                                                                                            >
                                                                                                <div className="task-table-body-item"
                                                                                                    style={{
                                                                                                        display: 'flex',
                                                                                                        flexDirection: 'column',
                                                                                                        alignContent: 'center',
                                                                                                        justifyContent: 'center'
                                                                                                    }}
                                                                                                >
                                                                                                    <strong>{log.material_name}</strong>
                                                                                                    <span className='table-row-item-span'>{log.category_name}</span>
                                                                                                </div>
                                                                                                <div className="task-table-body-item">{log.opening_stock_quandity > 0 ? `${log.opening_stock_quandity} ${log.unit_name}` : '--'} </div>
                                                                                                <div className='task-table-body-item inventory-item-used'>{log.used_stock_quandity ? `-${log.used_stock_quandity} ${log.unit_name}` : ''} </div>
                                                                                                <div className='task-table-body-item inventory-item-added'>{log.added_stock_quandity ? `+${log.added_stock_quandity} ${log.unit_name}` : ''} </div>
                                                                                                <div className='task-table-body-item'>{log.instock_stock_quandity} {log.unit_name}</div>
                                                                                                <div className="task-table-body-item" style={{ width: '40%' }}>
                                                                                                    <div className="body-item-brief">

                                                                                                        <div className="brief-item">
                                                                                                            <div className="label">{getInventoryDetialsTitle(log)}</div>
                                                                                                        </div>
                                                                                                        <div className="brief-item">
                                                                                                            <div className="label">{getInventoryDetialsDesc(log)}</div>
                                                                                                        </div>
                                                                                                        <div className="brief-item">
                                                                                                            <div className="label">Created by:</div>
                                                                                                            <div className="value">{log.created_by_name}</div>
                                                                                                        </div>
                                                                                                        <div className="brief-item">
                                                                                                            <div className="label">{log.created_at}</div>
                                                                                                        </div>

                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        ))
                                                                                    }

                                                                                </div>
                                                                            </div>
                                                                        </div>


                                                                    ))
                                                                }




                                                            </div>



                                                        </div>



                                                    </div>
                                                </div>

                                            </div>

                                        </div>
                                        : ''}
                                    {CheckMenuEnable('material_expenses') && ReportGRNData.length ?
                                        <div className="report-section-tasks-updates">
                                            <div className="report-section-title">GRN</div>

                                            <div className="report-section-attendance-items">

                                                <div className="tasks-item">
                                                    <div className="tasks-content">
                                                        <div className="tasks-content-item">

                                                            <div className="tasks-item-category-tasks">

                                                                {
                                                                    ReportGRNData?.map((grn, iidx) => (

                                                                        <div
                                                                            className="tasks-item-category-task"
                                                                            key={`grn-${grn.id}`}
                                                                        >

                                                                            <div className="task-item-top-detials">
                                                                                <div className="detials-item">
                                                                                    <div className="label">GRN No:</div>
                                                                                    <div className="value">{grn.grn_no}</div>
                                                                                </div>
                                                                                <div className="detials-item">
                                                                                    <div className="label">Received On:</div>
                                                                                    <div className="value">{grn.grn_date}</div>
                                                                                </div>
                                                                                <div className="detials-item">
                                                                                    <div className="label">Received By:</div>
                                                                                    <div className="value">{grn.created_by_name || 'N/A'}</div>
                                                                                </div>
                                                                                <div className="detials-item">
                                                                                    <div className="label">Vendor:</div>
                                                                                    <div className="value">{grn.vendor_name}</div>
                                                                                </div>
                                                                                <div className="detials-item">
                                                                                    <div className="label">Ref(Invoice No):</div>
                                                                                    <div className={`value`}>{grn.grn_reference}</div>
                                                                                </div>
                                                                            </div>

                                                                            <div className="task-table-main">
                                                                                <div className="task-table-header" style={{ height: '50px', background: 'var(--primary-trans-color)' }}>
                                                                                    <div className="task-table-header-item" style={{ width: '50%' }}>Material Name</div>
                                                                                    <div className="task-table-header-item" >Requested Quandity</div>
                                                                                    <div className="task-table-header-item">Ordered Quandity</div>
                                                                                </div>
                                                                                <div className="task-table-body">

                                                                                    {
                                                                                        grn.cost_items?.map((costitem, aidx) => (

                                                                                            <div
                                                                                                className="task-table-body-row table-task-row"
                                                                                                key={`costitem-${costitem.id}`}
                                                                                            >
                                                                                                <div className="task-table-body-item" style={{ width: '50%' }}>{costitem.material_name}</div>
                                                                                                <div className="task-table-body-item">{costitem.max_quantity || costitem.quantity} {costitem.unit_name}</div>
                                                                                                <div className="task-table-body-item">{costitem.quantity} {costitem.unit_name}</div>
                                                                                            </div>


                                                                                        ))
                                                                                    }





                                                                                </div>
                                                                            </div>

                                                                            <div className="task-item-top-detials" style={{ flexWrap: 'wrap', gap: '20px' }}>
                                                                                <div className="detials-item"
                                                                                    style={{ width: '100%', flexDirection: 'row', alignItems: 'center', gap: '5px' }}
                                                                                >
                                                                                    <div className="label">Remarks:</div>
                                                                                    <div className="value">{grn.introduction_text}</div>
                                                                                </div>
                                                                                <div className="detials-item" style={{ width: '48%' }}>
                                                                                    <div className="label">Linked PO:</div>
                                                                                    <div className={`value detials-item-status-${grn.purchase_order_id ? '1' : '-1'}`}>{grn.purchase_order_id || '-'}</div>
                                                                                </div>
                                                                            </div>


                                                                        </div>


                                                                    ))
                                                                }




                                                            </div>



                                                        </div>



                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                        : ''}
                                    {CheckMenuEnable('issues') && ReportIssuesData.length ?
                                        <div className="report-section-tasks-updates">
                                            <div className="report-section-title">ISSUES</div>

                                            <div className="report-section-attendance-items">

                                                <div className="tasks-item">
                                                    <div className="tasks-content">
                                                        <div className="tasks-content-item">

                                                            <div className="tasks-item-category-tasks">

                                                                {
                                                                    ReportIssuesData?.map((issues, iidx) => (

                                                                        <div
                                                                            className="tasks-item-category-task"
                                                                            key={`issues-${issues.id}`}
                                                                        >

                                                                            <div className="task-item-top-detials">
                                                                                <div className="detials-item">
                                                                                    <div className="label">Date:</div>
                                                                                    <div className="value">{issues.date}</div>
                                                                                </div>

                                                                            </div>

                                                                            <div className="task-table-main">
                                                                                <div className="task-table-header" style={{ height: '50px', background: 'var(--primary-trans-color)' }}>
                                                                                    <div className="task-table-header-item" style={{ width: '40%' }}>Issue</div>
                                                                                    <div className="task-table-header-item" >Tag</div>
                                                                                    <div className="task-table-header-item" >Status</div>
                                                                                    <div className="task-table-header-item">Due date</div>
                                                                                    <div className="task-table-header-item" style={{ width: '40%' }}>Detials</div>
                                                                                </div>
                                                                                <div className="task-table-body">

                                                                                    {
                                                                                        issues.issues?.map((issue, aidx) => (

                                                                                            <div
                                                                                                className="task-table-body-row table-detials-row"
                                                                                                key={`issue-${issue.id}`}
                                                                                            >
                                                                                                <div className="task-table-body-item" style={{ width: '40%' }}>{issue.reason}</div>
                                                                                                <div className="task-table-body-item">{issue.issue_tag}</div>
                                                                                                <div className={`task-table-body-item ${issue.status == '0' ? 'inventory-item-used' : 'inventory-item-added'}`}>{issue.status == '0' ? 'Open' : 'Close'}</div>
                                                                                                <div className="task-table-body-item">{issue.due_date}</div>
                                                                                                <div className="task-table-body-item" style={{ width: '40%' }}>
                                                                                                    <div className="body-item-brief">

                                                                                                        {issue.import_from_id ?
                                                                                                            <div className="brief-item">
                                                                                                                <div className="label">Linked Task:</div>
                                                                                                                <div className="value">{issue.import_from_name}</div>
                                                                                                            </div>
                                                                                                            : ''}
                                                                                                        {issue.status == '1' ?
                                                                                                            <>
                                                                                                                <div className="brief-item">
                                                                                                                    <div className="label">Closed by:</div>
                                                                                                                    <div className="value">{issue.closed_by_name || 'N/A'}</div>
                                                                                                                </div>
                                                                                                                <div className="brief-item">
                                                                                                                    <div className="label">Closed at:</div>
                                                                                                                    <div className="value">{issue.closed_at ? Utils.getLocalFullDateLabel(issue.closed_at) : 'N/A'}</div>
                                                                                                                </div>
                                                                                                            </>
                                                                                                            : ''}
                                                                                                        <div className="brief-item">
                                                                                                            <div className="label">Created by:</div>
                                                                                                            <div className="value">{issue.created_by_name}</div>
                                                                                                        </div>
                                                                                                        <div className="brief-item">
                                                                                                            <div className="label">Created at:</div>
                                                                                                            <div className="value">{Utils.getLocalFullDateLabel(issue.created_at)}</div>
                                                                                                        </div>


                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>


                                                                                        ))
                                                                                    }





                                                                                </div>
                                                                            </div>
                                                                        </div>


                                                                    ))
                                                                }




                                                            </div>



                                                        </div>



                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                        : ''}
                                </div>
                            </div>

                        </PDFExport>
                    </div>

                </div>

                <div className="sidebar-popup-footer">
                    <div className="footer-item action-items">
                        <div className="action-preview">
                        </div>
                        <div className='action-btns'>
                            <div
                                className="action-btn button-download"
                                onClick={(e) => HandleDownloadBtn(e)}
                            >
                                <div
                                    className="icon"
                                    dangerouslySetInnerHTML={{ __html: Icons.general.download }}
                                ></div>
                                <div className="label">Download PDF</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
const ProgressReport = () => {


    const { projectId } = useParams();


    const [isLoading, setIsLoading] = useState(false)
    const [warningAlert, setWarningAlert] = useState(false)
    const [warningAlertType, setWarningAlertType] = useState('warning')
    const [apiFailedMessage, setApiFailedMessage] = useState("Error in Server")

    const navigate = useNavigate()
    const store = useSelector((store) => store)
    const dispatch = useDispatch()
    const { updateState } = new UserAction

    const storeHandler = new StoreHandler({
        dataStack: ['company', 'field_settings', 'clients'],
        store: store,
        dispatch: dispatch,
        updateState: updateState,
    })

    const taskManagementHandler = new TaskManagementHandler()
    const labourProjectAttendanceHandler = new LabourProjectAttendanceHandler()
    const indentsHandler = new IndentsHandler()
    const purchaseOrderHandler = new PurchaseOrderHandler()
    const pO_GRNHandler = new PO_GRNHandler()
    const issuesManagementHandler = new IssuesManagementHandler()
    const materialIssueHandler = new MaterialIssueHandler()

    const LoadStoreData = async () => {
        setIsLoading(true)
        let resolved = await storeHandler.init()
        setIsLoading(false)
    }

    const { isAdmin, menu_permission } = useMenuAuth({ menu: 'project_management--project_reports', feature_key: 'projects' })
    const location = useLocation()

    const [selected_view_settings_value, setSelected_view_settings_value] = useState('')
    const [has_view_access, setHas_view_access] = useState(false)
    const [has_add_access, setHas_add_access] = useState(false)
    const [has_edit_access, setHas_edit_access] = useState(false)
    const [has_delete_access, setHas_delete_access] = useState(false)

    useEffect(() => {

        if (isAdmin) {

            setHas_view_access(true)
            setHas_add_access(true)
            setHas_edit_access(true)
            setHas_delete_access(true)
        }

        else if (menu_permission) {

            if (
                !menu_permission._view
                &&
                (!menu_permission.selected_view_settings_value
                    || menu_permission.selected_view_settings_value == 'no_access')
            ) {
                navigate(`${ReverseProxy['proxyUrl']}/quick-menu`, { state: { from: location }, replace: true })
            }

            if (menu_permission._view == '0') navigate(`${ReverseProxy['proxyUrl']}/builder/projects`, { state: { from: location }, replace: true })

            setHas_view_access(menu_permission._view == '1')
            setHas_add_access(menu_permission._create == '1')
            setHas_edit_access(menu_permission._edit == '1')
            setHas_delete_access(menu_permission._delete == '1')

            setSelected_view_settings_value(menu_permission.selected_view_settings_value)

        }

    }, [store])


    const [filterDate, setFilterDate] = useState({
        from: Utils._getLocalDate(),
        to: Utils._getLocalDate()
    })

    const [filterDateShortcuts, setFilterDateShortcuts] = useState([
        {
            id: 'today',
            name: 'Today',
            checked: true
        },
        {
            id: '7days',
            name: '7 Days',
            checked: false
        },
        {
            id: '30days',
            name: '30 Days',
            checked: false
        },
        {
            id: 'specificday',
            name: 'Specific Date',
            checked: false
        },
    ])

    const [contentSettingsState, setContentSettingsState] = useState(false)
    const [contentSettings, setContentSettings] = useState([
        {
            id: 'progress',
            name: 'Progress',
            desc: 'Includes task progress, attendance & remrks',
            checked: true
        },
        {
            id: 'material_expenses',
            name: 'Material Expenses',
            desc: 'Includes costing, GRN',
            checked: true
        },
        {
            id: 'material_requests',
            name: 'Material Requests',
            desc: 'Includes all material requests, Indent',
            checked: true
        },
        {
            id: 'issues',
            name: 'Issues',
            desc: 'Includes all issues ',
            checked: true
        },
        {
            id: 'attendance',
            name: 'Attendance',
            desc: 'Includes vendor/labour attendance & payables ',
            checked: true
        },
        {
            id: 'inventory',
            name: 'Inventory',
            desc: 'Includes used, added & in stock materials',
            checked: true
        },
        {
            id: 'purchas_orders',
            name: 'Purchas Orders',
            desc: 'Includes all purchas orders ',
            checked: true
        },

    ])

    const [ReportLoaded, setReportLoaded] = useState(false)
    const [ReportData, setReportData] = useState(false)
    const [ShowReportPreview, setShowReportPreview] = useState(false)



    const HandleShowConentSettings = (e) => {
        setContentSettingsState(!contentSettingsState)
    }
    const HandleSelectConentSettingsItems = (item, idx) => {

        let temp = [...contentSettings]
        temp[idx].checked = !temp[idx].checked
        setContentSettings(temp)
    }

    const HandleDateShortCutChange = (item, idx) => {

        let temp = [...filterDateShortcuts]
        temp.map((item, index) => {
            temp[index].checked = false
        })
        temp[idx].checked = true
        setFilterDateShortcuts(temp)

        if (item.id == 'today') {
            setFilterDate({
                from: Utils._getLocalDate(),
                to: Utils._getLocalDate()
            })
        }
        if (item.id == '7days') {

            console.log(Utils.getDaysBeforeDate(filterDate.to, 7));

            setFilterDate({
                from: Utils.getDaysBeforeDate(filterDate.to, 7),
                to: Utils._getLocalDate()
            })
        }
        if (item.id == '30days') {
            setFilterDate({
                from: Utils.getDaysBeforeDate(filterDate.to, 30),
                to: Utils._getLocalDate()
            })
        }
        if (item.id == 'specificday') {
            setFilterDate({
                from: Utils._getLocalDate(),
                to: Utils._getLocalDate()
            })
        }
    }


    const HandleDateChange = (e, key) => {

        setFilterDate({ ...filterDate, [key]: e.target.value })

        let temp = [...filterDateShortcuts]
        temp.map((item, index) => {
            temp[index].checked = false
        })

        temp[temp.length - 1].checked = true
        setFilterDateShortcuts(temp)
    }

    const getStatus = (item) => {

        if (
            !item.startdate
            || !item.enddate
        ) return '0' // No dates added
        else if (
            new Date().getTime() < new Date(item.startdate).getTime()
            && new Date().getTime() < new Date(item.enddate).getTime()
        ) return '1' // Upcoming task
        else if (
            new Date().getTime() > new Date(item.startdate).getTime()
            && new Date().getTime() < new Date(item.enddate).getTime()
            && item.work_done_progress == '0'
        ) return '2'  // Not started
        else if (
            new Date().getTime() > new Date(item.startdate).getTime()
            && new Date().getTime() < new Date(item.enddate).getTime()
            && item.work_done_progress != '0'
            && item.work_done_progress < item.total_work_progress
        ) return '3' // In Progress
        else if (
            item.work_done_progress == item.total_work_progress
        ) return '4' // Completed
        else if (
            new Date().getTime() > new Date(item.enddate).getTime()
            && item.work_done_progress != item.total_work_progress
        ) return '5' // Delayed by days
        else if (
            item.status == '3'
        ) return '6' // on Hold
        else if (
            item.status == '4'
        ) return '7' // Stopped

    }

    const HandleRenderReport = async (e) => {

        console.log(filterDate, 'filterDate');


        let reportData = {
            task_status: {
                in_progress: 0,
                late: 0,
                completed: 0,
                not_started: 0,
            },
            overall_site_activity: {
                tasks_updated: 0,
                issues_created: 0,
                attendance_updated: 0,
            },
            progress: [],
            grns: [],
            indents: [],
            issues: [],
            attendance: [],
            inventory: [],
            purchas_orders: [],

        }

        let task_filter = {
            projectId: projectId,
            type: '2',
            startdate: filterDate.from,
            enddate: filterDate.to,

        }
        let attendance_filter = {
            projectId: projectId,
            startdate: filterDate.from,
            enddate: filterDate.to,

        }
        let indent_filter = {
            projectId: projectId,
            startdate: filterDate.from,
            enddate: filterDate.to,

        }
        let po_filter = {
            projectId: projectId,
            startdate: filterDate.from,
            enddate: filterDate.to,

        }
        let po_grn_filter = {
            projectId: projectId,
            startdate: filterDate.from,
            enddate: filterDate.to,

        }
        let issues_filter = {
            projectId: projectId,
            startdate: filterDate.from,
            enddate: filterDate.to,

        }
        let materialIssues_filter = {
            projectId: projectId,
            startdate: filterDate.from,
            enddate: filterDate.to,

        }

        setIsLoading(true)

        let task_response = await taskManagementHandler.getTasksHandler(task_filter)
        let attendance_response = await labourProjectAttendanceHandler.getLabourAttendanceHandler(attendance_filter)
        let indents_response = await indentsHandler.getIndentsHandler(indent_filter)
        let po_response = await purchaseOrderHandler.getPurchaseOrdersHandler(po_filter)
        let po_grn_response = await pO_GRNHandler.getPO_GRNsHandler(po_grn_filter)
        let issues_response = await issuesManagementHandler.getIssuesHandler(issues_filter)
        let materialIssues_response = await materialIssueHandler.getMaterialIssuesHandler(materialIssues_filter)

        setIsLoading(false)

        if (
            !task_response.success
            || !attendance_response.success
            || !indents_response.success
            || !po_response.success
            || !po_grn_response.success
            || !issues_response.success
            || !materialIssues_response.success
        ) {
            setWarningAlert(true)
            setApiFailedMessage(task_response.message)
            return false
        }

        if (Array.isArray(task_response.data)) {
            let _ReportTasksData = []


            task_response.data.forEach((task, tidx) => {

                let task_status = getStatus(task)

                if (task_status == '3') reportData.task_status.in_progress += 1
                if (task_status == '5') reportData.task_status.late += 1
                if (task_status == '4') reportData.task_status.completed += 1
                if (task_status == '2') reportData.task_status.not_started += 1

                if (Array.isArray(task.task_progress)) {

                    if (task.updated_at) reportData.overall_site_activity.tasks_updated += task.task_progress.length

                    task.task_progress.forEach(progress => {


                        let new_progress_data = {
                            id: task.id,
                            name: task.name,
                            unit_id: task.unit_id,
                            unit_name: task.unit_name,
                            total_work: parseFloat(task.total_work_progress || 0),
                            status: getStatus(task),
                            date: progress.date,

                            worktype_id: task.worktype_id,
                            worktype_name: task.worktype_name,

                            total_work_completed: task.work_done_progress || 0,
                            total_work_completed_percentage: parseFloat(((task.work_done_progress || 0) / parseFloat(task.total_work_progress || 0)) * 100),

                            today_work_completed: parseFloat(progress.progress_value || 0),
                            today_work_completed_percentage: parseFloat((parseFloat(progress.progress_value || 0) / parseFloat(task.total_work_progress || 0)) * 100),

                            attendance: progress.worker_attendance || [],
                            remarks: progress.remarks,
                            rawdata: task
                        }

                        let _task = _ReportTasksData.findIndex(item => item.date == progress.date)

                        if (_task == -1) {
                            _ReportTasksData.push({
                                date: progress.date,
                                tasks: [
                                    {
                                        category_id: task.worktype_id,
                                        category_name: task.worktype_name,
                                        category_tasks: [new_progress_data]
                                    },
                                ]
                            })
                        } else {

                            let _category = _ReportTasksData[_task].tasks.findIndex(item => item.category_id == task.worktype_id)

                            if (_category == -1) {
                                _ReportTasksData[_task].tasks.push({
                                    category_id: task.worktype_id,
                                    category_name: task.worktype_name,
                                    category_tasks: [new_progress_data]
                                })
                            } else {

                                let _category_task = _ReportTasksData[_task].tasks[_category].category_tasks.findIndex(item => item.id == task.id)

                                if (_category_task == -1) {
                                    _ReportTasksData[_task].tasks[_category].category_tasks.push(new_progress_data)
                                } else {


                                    _ReportTasksData[_task].tasks[_category].category_tasks[_category_task].today_work_completed += parseFloat(progress.progress_value)
                                    _ReportTasksData[_task].tasks[_category].category_tasks[_category_task].today_work_completed_percentage += parseFloat((progress.progress_value / task.total_work_progress) * 100)


                                    let _attendances = _ReportTasksData[_task].tasks[_category].category_tasks[_category_task].attendance

                                    if (Array.isArray(progress.worker_attendance)) {

                                        progress.worker_attendance.forEach(attendance => {

                                            let _attendance = _attendances.findIndex(item => item.worktype_id == attendance.worktype_id)

                                            if (_attendance == -1) _attendances.push(attendance)
                                            else _attendances[_attendance].count += attendance.count
                                        })
                                    }

                                    _ReportTasksData[_task].tasks[_category].category_tasks[_category_task].attendance = _attendances


                                }
                            }
                        }

                    })


                }


            })

            reportData.progress = _ReportTasksData

        }

        if (Array.isArray(attendance_response.data)) {

            let labour_attendance = {}
            let vendor_attendances = []

            let attendance_logs = 0

            attendance_response.data.forEach(attendance_res => {

                if (Array.isArray(attendance_res.labour_attendance)) {

                    attendance_res.labour_attendance.forEach(labour => {

                        if (!labour.attendance) return

                        attendance_logs += 1;

                        let is_present = labour.attendance == "present"
                        let is_halfday = labour.attendance == "halfday"
                        let is_absent = labour.attendance == "absent"

                        if (labour_attendance[labour.id]) {

                            labour_attendance[labour.id].total_present_days += is_present ? 1 : 0
                            labour_attendance[labour.id].total_half_days += is_halfday ? 1 : 0
                            labour_attendance[labour.id].total_absent_days += is_absent ? 1 : 0
                            labour_attendance[labour.id].total_payable += parseFloat(labour.to_pay || 0)
                            labour_attendance[labour.id].total_ovettime_hours += parseFloat(labour.overtime_hours || 0)
                            labour_attendance[labour.id].total_overtime_salary_amount += parseFloat(labour.overtime_salary_amount || 0)
                        }

                        else {
                            labour_attendance[labour.id] = {
                                ...labour,

                                total_present_days: is_present ? 1 : 0,
                                total_half_days: is_halfday ? 1 : 0,
                                total_absent_days: is_absent ? 1 : 0,
                                total_payable: parseFloat(labour.to_pay || 0),
                                total_ovettime_hours: parseFloat(labour.overtime_hours || 0),
                                total_overtime_salary_amount: parseFloat(labour.overtime_salary_amount || 0),
                            }
                        }

                    })

                }

                if (Array.isArray(attendance_res.vendor_attendance)) {

                    attendance_logs += attendance_res.vendor_attendance.length;

                    attendance_res.vendor_attendance.forEach(vendor_attendance => {

                        let vendor_date_idx = vendor_attendances.findIndex(vendor => vendor.vendor_id == vendor_attendance.vendor_id)

                        if (vendor_date_idx == -1) {

                            vendor_attendances.push({
                                vendor_id: vendor_attendance.vendor_id,
                                vendor_name: vendor_attendance.vendor_name,
                                total_payable: parseFloat(vendor_attendance.total_topay || 0),
                                vendor_attendance: [{
                                    ...vendor_attendance,
                                    date: attendance_res.date
                                }]
                            })
                        }
                        else {

                            vendor_attendances[vendor_date_idx].total_payable += parseFloat(vendor_attendance.total_topay || 0)

                            vendor_attendances[vendor_date_idx].vendor_attendance.push({
                                ...vendor_attendance,
                                date: attendance_res.date
                            })
                        }
                    })

                }

            })


            reportData.attendance = {
                vendor_attendance: vendor_attendances,
                labour_attendance: Object.values(labour_attendance)
            }

            reportData.overall_site_activity.attendance_updated = attendance_logs
        }

        if (Array.isArray(issues_response.data)) {

            let issues_data = []

            reportData.overall_site_activity.issues_created = issues_response.data.length

            issues_response.data.forEach(issue => {

                let date = Utils._getLocalDate(issue.created_at)

                let _issue = issues_data.findIndex(item => item.date == date)

                if (_issue == -1) {
                    issues_data.push({
                        date: date,
                        issues: [issue]
                    })
                }
                else {
                    issues_data[_issue].issues.push(issue)
                }

            })
            reportData.issues = issues_data

        }

        let material_inventory_data = []

        if (Array.isArray(materialIssues_response.data)) {

            let material_issues_data = materialIssues_response.data || []

            material_issues_data.forEach(issue => {

                issue.cost_items.forEach(costitem => {

                    let date = Utils._getLocalDate(issue.created_at)
                    let instock_stock_quandity = parseFloat(costitem.opening_quantity || 0) - parseFloat(costitem.quantity || 0) || 0
                    instock_stock_quandity = instock_stock_quandity > 0 ? instock_stock_quandity : 0

                    let _material = material_inventory_data.findIndex(item => item.date == date)

                    let material_inventory_item = {

                        id: costitem.material_id,
                        date: date,
                        material_id: costitem.material_id,
                        material_name: costitem.material_name,
                        unit_id: costitem.unit_id,
                        unit_name: costitem.unit_name,
                        opening_stock_quandity: costitem.opening_quantity,
                        added_stock_quandity: '',
                        used_stock_quandity: costitem.quantity,
                        action_type: issue.issue_to != null ? issue.issue_to : '-1',
                        instock_stock_quandity: instock_stock_quandity,

                        task_id: issue.issue_to == '0' ? costitem.task_id : '',
                        task_name: issue.issue_to == '0' ? costitem.task_name : '',

                        transfered_site_id: issue.issue_to == '1' ? issue.other_site_id : '',
                        transfered_site_name: issue.issue_to == '1' ? issue.other_site_name : '',

                        created_at: issue.created_at,
                        created_by_id: issue.checkedby_id,
                        created_by_name: issue.checkedby_name,
                        reference: issue.issue_reference,
                    }

                    if (_material == -1) {

                        material_inventory_data.push({
                            id: Utils.getUniqueId(),
                            date: date,
                            date_ms: new Date(issue.created_at).getTime(),
                            materials_logs: [material_inventory_item]
                        })
                    }
                    else {
                        material_inventory_data[_material].materials_logs.push(material_inventory_item)
                    }


                })



            })

        }

        if (Array.isArray(po_grn_response.data)) {

            let material_added_data = po_grn_response.data || []

            material_added_data.forEach(grn => {

                grn.cost_items.forEach(costitem => {

                    let date = Utils._getLocalDate(grn.created_at)
                    let instock_stock_quandity = parseFloat(costitem.opening_quantity || 0) + parseFloat(costitem.quantity || 0) || 0
                    instock_stock_quandity = instock_stock_quandity > 0 ? instock_stock_quandity : 0


                    let material_inventory_item = {
                        id: costitem.material_id,
                        date: date,
                        material_id: costitem.material_id,
                        material_name: costitem.material_name,
                        unit_id: costitem.unit_id,
                        unit_name: costitem.unit_name,
                        opening_stock_quandity: costitem.opening_quantity,
                        added_stock_quandity: costitem.quantity,
                        used_stock_quandity: '',
                        action_type: '3',
                        instock_stock_quandity: instock_stock_quandity,

                        task_id: '',
                        task_name: '',
                        vendor_id: grn.vendor_id || '',
                        vendor_name: grn.vendor_name || '',
                        grn_no: grn.grn_no || '',

                        transfered_site_id: '',
                        transfered_site_name: '',

                        created_at: grn.created_at,
                        created_by_id: grn.checkedby_id || '',
                        created_by_name: grn.checkedby_name || '',
                        reference: grn.grn_reference,
                    }

                    let _material = material_inventory_data.findIndex(item => item.date == date)

                    if (_material == -1) {

                        material_inventory_data.push({
                            id: Utils.getUniqueId(),
                            date: date,
                            date_ms: new Date(grn.created_at).getTime(),
                            materials_logs: [material_inventory_item]
                        })
                    }
                    else {
                        material_inventory_data[_material].materials_logs.push(material_inventory_item)
                    }
                })



            })

        }

        if (Array.isArray(material_inventory_data) && material_inventory_data.length) {

            material_inventory_data = material_inventory_data.map(item => {

                let materials_logs = item.materials_logs.sort((a, b) => {
                    return new Date(a.created_at).getTime() - new Date(b.created_at).getTime()
                })

                materials_logs = materials_logs.sort((a, b) => {
                    const nameA = a.material_name.toUpperCase();
                    const nameB = b.material_name.toUpperCase();

                    if (nameA < nameB) return -1
                    if (nameA > nameB) return 1
                    return 0
                });

                item.materials_logs = materials_logs

                return item


            })

            material_inventory_data = material_inventory_data.sort((a, b) => a.date_ms - b.date_ms)

        }

        reportData.inventory = material_inventory_data
        reportData.indents = indents_response.data
        reportData.purchas_orders = po_response.data
        reportData.grns = po_grn_response.data


        setReportData(reportData)

        setReportLoaded(true)
        setShowReportPreview(true)
    }




    return (

        <>
            {isLoading ?

                <Loading
                    props={{
                        isMainLogo: false,
                        isLabel: true
                    }} />

                : null}

            {warningAlert ?

                <SystemToastPopup
                    props={{
                        type: warningAlertType,
                        message: apiFailedMessage || "Error in Projects",
                        callback: (confirmation) => setWarningAlert(false)
                    }} />
                : null}


            {ShowReportPreview ?
                <ProgressReportPreview
                    props={{
                        reportLoaded: ReportLoaded,
                        reportdetials: ReportData,
                        contentsettings: contentSettings,
                        close: () => { setShowReportPreview(false) }
                    }}
                />
                : ''}
            <div className="project-report-container">
                <div className="project-report-content">
                    <div className="report-filter-section">
                        <div className="filter-header">
                            <div className="filter-header-left">
                                <div className="filter-header-title">Progress Report</div>
                            </div>
                            <div className="filter-header-right">
                                <div className="datefilter-main">
                                    <div className="datefilter-shortcuts-items">
                                        {
                                            filterDateShortcuts.map((item, index) => (
                                                <div
                                                    className="datefilter-shortcut"
                                                    key={item.id}
                                                >
                                                    <input
                                                        id={`datefilter-shortcut-${item.id}`} type="radio" name='datefilter-shortcut'
                                                        value={item.checked}
                                                        checked={item.checked}
                                                        onChange={() => HandleDateShortCutChange(item, index)}
                                                    />
                                                    <label htmlFor={`datefilter-shortcut-${item.id}`}>{item.name}</label>
                                                </div>
                                            ))
                                        }

                                    </div>
                                    <div className="datefilter-dates-main">
                                        <div className="datefilter-date">
                                            <div className="label">From</div>
                                            <input
                                                type="date"
                                                value={filterDate.from}
                                                onChange={(e) => HandleDateChange(e, 'from')}
                                            />
                                        </div>
                                        <div className="datefilter-date">
                                            <div className="label">To</div>
                                            <input
                                                type="date"
                                                value={filterDate.to}
                                                onChange={(e) => HandleDateChange(e, 'to')}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="filter-content-settings">
                            <div className="content-settings-header"
                                onClick={HandleShowConentSettings}
                            >
                                <div className="left">
                                    <div className="title">Content in the Report</div>
                                </div>
                                <div className="right">
                                    <div
                                        className={`arrow-icon ${contentSettingsState ? 'arrow-icon-open' : ''}`}
                                        dangerouslySetInnerHTML={{ __html: Icons.general.dropdown_arrow }}
                                    ></div>
                                </div>

                            </div>
                            <div
                                className={`content-settings-items ${contentSettingsState ? 'content-settings-items-active' : ''}`}
                            >
                                {
                                    contentSettings.map((item, index) => (
                                        <div
                                            className="content-settings-item"
                                            key={item.id}
                                        >
                                            <label htmlFor={`content-settings-checkbox-${item.id}`} className="left">
                                                <div className="head">{item.name}</div>
                                                <div className="desc">{item.desc}</div>
                                            </label>
                                            <div className="right">
                                                <div className="checkbox-input">
                                                    <input
                                                        id={`content-settings-checkbox-${item.id}`}
                                                        type="checkbox"
                                                        value={item.checked}
                                                        defaultChecked={item.checked}
                                                        onChange={() => HandleSelectConentSettingsItems(item, index)}
                                                    />
                                                </div>
                                            </div>
                                        </div>

                                    ))
                                }


                            </div>
                        </div>

                        <div className="filter-footer">
                            <div className="filter-footer-buttons">
                                <div
                                    className="filter-report-button"
                                    onClick={HandleRenderReport}
                                >
                                    <div className="label">Generate Report</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>

    )

}

export default ProgressReport;