

import React, { useEffect, useState, useRef } from 'react'
import { Route, Routes, BrowserRouter, Link, useLocation, Navigate, Outlet, useNavigate, useParams } from 'react-router-dom';
import store from '../../redux/store'
import StoreHandler from '../../redux/StoreHandler'
import { useSelector, useDispatch } from 'react-redux'
import UserAction from '../../redux/action/userAction'

import ReverseProxy from '../../config/reverseProxy'
import ProjectsHandler from '../../Handlers/Projects/Projects';
import Icons from '../../assets/Icons'
import Images from '../../assets/Images'
import { CostInput, TextInput, SelectInput, RadioInput } from '../../components/Inputs'
import Utils from '../../utils'
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

import AlertPopup from '../../components/AlertPopup'
import SystemToastPopup from '../../components/SystemToastPopup'
import Loading from '../../components/Loading'
import NoDataFound from '../../components/NoDataFound';

import { useAuth, useMenuAuth } from '../../hooks/useAuth'


const ProjectsList = () => {

    const store = useSelector((store) => store)
    const dispatch = useDispatch()
    const { updateState } = new UserAction

    const navigate = useNavigate()

    const { isAdmin, menu_permission } = useMenuAuth({ menu: 'project_management--projects', feature_key: 'projects' })

    const location = useLocation()

    const [selected_view_settings_value, setSelected_view_settings_value] = useState('')
    const [has_view_access, setHas_view_access] = useState(false)
    const [has_add_access, setHas_add_access] = useState(false)
    const [has_edit_access, setHas_edit_access] = useState(false)
    const [has_delete_access, setHas_delete_access] = useState(false)

    useEffect(() => {

        if (isAdmin) {

            setHas_view_access(true)
            setHas_add_access(true)
            setHas_edit_access(true)
            setHas_delete_access(true)
        }

        else if (menu_permission) {

            if (
                !menu_permission._view
                &&
                (menu_permission.selected_view_settings_value == 'no_access'
                    || Array.isArray(menu_permission.selected_view_settings_value) && !menu_permission.selected_view_settings_value.length)
            ) {
                navigate(`${ReverseProxy['proxyUrl']}/quick-menu`, { state: { from: location }, replace: true })
            }

            setHas_view_access(menu_permission._view == '1')
            setHas_add_access(menu_permission._create == '1')
            setHas_edit_access(menu_permission._edit == '1')
            setHas_delete_access(menu_permission._delete == '1')

            setSelected_view_settings_value(menu_permission.selected_view_settings_value)

        }

    }, [store])


    const storeHandler = new StoreHandler({
        dataStack: ['field_settings'],
        store: store,
        dispatch: dispatch,
        updateState: updateState,
    })

    const LoadStoreData = async () => {
        setIsLoading(true)
        let resolved = await storeHandler.init()
        setIsLoading(false)
    }

    const projectsHandler = new ProjectsHandler()

    const [isLoading, setIsLoading] = useState(false)
    const [warningAlert, setWarningAlert] = useState(false)
    const [warningAlertType, setWarningAlertType] = useState('warning')
    const [apiFailedMessage, setApiFailedMessage] = useState('Error in Projects')


    const [deleteItemId, setDeleteItem] = useState(null)
    const [deleteItemType, setDeleteItemType] = useState(null)
    const [deleteItemAlert, setDeleteItemAlert] = useState(false)

    const [ProjectsDetials, setProjectsDetials] = useState([])
    const [selected_termsCondition, setSelected_termsCondition] = useState({})
    const [selectedAction, setSelectedAction] = useState('')

    const [ActionDropdown, setActionDropdown] = useState([
        {
            id: 'edit',
            label: 'Edit',
            icon: Icons.general.edit,

        },
        {
            id: 'delete',
            label: 'Delete',
            icon: Icons.general.delete,

        },
    ])

    const [projectTypeOptions, setProjectTypeOptions] = useState([])
    const [projectStatusOptions, setProjectStatusOptions] = useState([])
    const [currency_type, setCurrency_type] = useState('₹')

    const [ProjectStatusFilter_Options, setProjectStatusFilter_Options] = useState([])
    const [selectedResultSection, setSelectedResultSection] = useState('all')

    const getProjectsDetials = async () => {


        let filter = {}

        if (store.user.builder.user_type == '2') {
            filter['user_based_filter'] = '1'
        }

        let response = await projectsHandler.getProjectBasicsHandler(filter)

        if (response && response.success) {

            let projects = response.data

            projects = projects.map(project => {

                let start = project.startdate ? Utils.getLocalFullDateBYFormat(project.startdate, '-') : 'Start Date'
                let end = project.enddate ? Utils.getLocalFullDateBYFormat(project.enddate, '-') : 'End Date'

                project.startdate = start
                project.enddate = end
                project.date = `${start} - ${end}`

                return project
            })


            setProjectsDetials(projects || [])

        } else {
            setWarningAlert(true)
            setWarningAlertType('error')
            setApiFailedMessage(`Error in Projects, Please try again!`)
        }
    }


    const HandleAddNewProject = () => {

        if (has_add_access) navigate(`/builder/create-project`)
    }
    const HandleEditProject = (template) => {

        if (!has_edit_access) return

        setSelected_termsCondition(template)

        navigate(`${window.location.pathname}/${template.projectID}/edit`)
    }

    const HandleDeleteItem = async (item) => {

        if (!has_edit_access || !has_delete_access) return

        setDeleteItem(item.id)
        setDeleteItemType(item.type)
        setDeleteItemAlert(true)
    }
    const HandleDeleteConfirmItem = async (id, type, confirmation) => {

        console.log(confirmation, 'confirmation');
        if (!confirmation || !id) {

            setDeleteItem(null)
            setDeleteItemType(null)
            setDeleteItemAlert(false)

            return
        }


        let response = await projectsHandler.deleteProjectHandler({
            id: id
        })

        if (response && response.success) {

            getProjectsDetials()

        } else {
            setWarningAlert(true)
            setWarningAlertType('error')
            setApiFailedMessage(`Error in deleteing Projects`)
        }

        setDeleteItem(null)
        setDeleteItemType(null)
        setDeleteItemAlert(false)
    }
    const HandleActionDisable = (id) => {

        let input = document.getElementById(`projects-tabel-action-btn-${id}`)
        let dropdown_items = document.getElementById(`projects-tabel-action-dropdown-${id}`)

        const Event = (e) => {
            let path = e.path || (e.composedPath && e.composedPath());

            if (Array.isArray(path) && !path.includes(dropdown_items) && !path.includes(input)) {
                setSelectedAction('')
                window.removeEventListener('click', Event)
            }
        }

        window.addEventListener('click', Event)
    }
    const HandleProjectClick = (project) => {
        navigate(`/builder/projects/${project.projectID}`)

        dispatch(updateState({
            type: "SET_SELECTED_PROJECT",
            payload: {
                selected_project: project
            }
        }))
    }

    const getDeleteMessage = (type) => {

        if (type == "parent_task") return `Are you sure you want to delete the Parent? Items included in the task will be deleted accordingly.`
        else if (type == "task") return `Are you sure you want to delete the Task?`
        else return "Are you sure you want to delete?"
    }

    const HandleResultSection = (section) => {
        setSelectedResultSection(section)
    }

    const getProjectStatusColor = (progress) => {


        if (progress > 70) return '#07C07E'
        else if (progress > 30) return '#F29D41'
        else if (progress < 30) return '#f44336'
        else return '#3D90ED'
    }

    const RenderItemsResult = (Resultitems, status) => {

        return (
            <>
                {Resultitems?.filter(p => status ? p.status_id == status : true).length ?
                    <div className="projects-cards-main">
                        <div className="projects-cards-items">
                            {Resultitems?.filter(p => status ? p.status_id == status : true).map((project, idx) => (

                                <div
                                    className="projects-card-item"
                                    key={`project-${project.id}`}
                                >

                                    <div
                                        className="card-detials"
                                        onClick={(e) => HandleProjectClick(project)}
                                    >
                                        <div className="left">
                                            <div
                                                className="profile"
                                                style={{ background: project.colorcode || '#3D90ED' }}
                                            >
                                                {Utils.getNameInitials(project.name)}
                                            </div>
                                            <div className="detials">
                                                <div className="name">{project.name}</div>
                                                <div className="description">{project.projectID}</div>
                                                <div className="status" style={{ background: project.status_color }}>{project.status_name}</div>
                                            </div>
                                        </div>

                                        <div className="right">

                                            <div className="progress-main">
                                                <svg viewBox="22 22 44 44">
                                                    <circle cx="44" cy="44" r="20"></circle>
                                                    <circle
                                                        cx="44"
                                                        cy="44"
                                                        r="20"

                                                        style={{
                                                            stroke: getProjectStatusColor(parseInt(project.total_task_progress || 0)),
                                                            strokeDashoffset: ((100 - parseInt(project.total_task_progress || 0)) * 125.664) / 100
                                                        }}
                                                    ></circle>
                                                </svg>
                                                <div class="number">
                                                    {parseInt(project.total_task_progress || 0)}%
                                                </div>
                                            </div>

                                        </div>

                                    </div>
                                    <div className="card-footer">
                                        <div className="left">
                                            <div className="dates"
                                                style={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    gap: '10px'
                                                }}
                                            >
                                                <div className="date">Start: <strong>{project.startdate}</strong> </div>
                                                <div className="date">End: <strong>{project.enddate}</strong></div>
                                            </div>
                                        </div>
                                        <div className="right">

                                            {
                                                has_edit_access ?
                                                    <div className="action-button-main">
                                                        <div
                                                            id={`projects-tabel-action-btn-${project.id}`}
                                                            className="action-button"
                                                            dangerouslySetInnerHTML={{ __html: Icons.general.more_btn }}
                                                            onClick={(e) => {
                                                                setSelectedAction(project.id)
                                                                HandleActionDisable(project.id)
                                                            }}
                                                        ></div>
                                                        <div
                                                            id={`projects-tabel-action-dropdown-${project.id}`}
                                                            className={`action-result ${selectedAction == project.id ? 'action-result-active' : ''}`}
                                                        >
                                                            {ActionDropdown?.map((option, i) => (

                                                                !has_edit_access ? '' :
                                                                    option.id == 'delete' && !has_delete_access ? '' :
                                                                        <div
                                                                            key={`action-button-${option.id}`}
                                                                            className="option"
                                                                            onClick={(e) => {
                                                                                setSelectedAction('')
                                                                                if (option.id == 'delete') HandleDeleteItem(project)
                                                                                else if (option.id == 'edit') HandleEditProject(project)

                                                                            }}
                                                                        >{option.label}</div>
                                                            ))}
                                                        </div>
                                                    </div>
                                                    : ''}

                                        </div>
                                    </div>
                                </div>

                            ))}

                        </div>
                    </div>
                    : <NoDataFound label={'No Projects Found'} />}
            </>
        )

    }

    useEffect(() => {
        LoadStoreData()
        getProjectsDetials()
    }, [])


    useEffect(() => {

        if (ProjectsDetials.length && projectStatusOptions.length) {

            let _projectStatusFilter_Options = projectStatusOptions.map((opt) => {

                let count = ProjectsDetials.filter(project => project.status_id == opt.value).length

                return {
                    value: opt.value,
                    label: opt.label,
                    count: count
                }

            })
            setProjectStatusFilter_Options([
                {
                    value: 'all',
                    label: 'All',
                    count: ProjectsDetials.length
                },
                ..._projectStatusFilter_Options
            ])
        }

    }, [ProjectsDetials, projectStatusOptions])

    useEffect(() => {

        if (store && store.user) {

            let { settings } = store.user

            if (settings && Array.isArray(settings.field_settings) && settings.field_settings.length) {

                let { project_statuses, project_types, units } = settings.field_settings[0]

                project_statuses = project_statuses.filter(opt => opt.isActive).map((opt) => {
                    return {
                        value: opt.id,
                        label: opt.name,
                        isDefault: opt.isDefault || false
                    }
                })
                project_types = project_types.filter(opt => opt.isActive).map((opt) => {
                    return {
                        value: opt.id,
                        label: opt.name,
                        isDefault: opt.isDefault || false
                    }
                })

                setProjectTypeOptions(project_types || [])
                setProjectStatusOptions(project_statuses || [])
            }
        }

    }, [store])


    return (
        <>


            {isLoading ?

                <Loading
                    props={{
                        isMainLogo: false,
                        isLabel: true
                    }} />

                : null}

            {warningAlert ?

                <SystemToastPopup
                    props={{
                        type: warningAlertType,
                        message: apiFailedMessage || "Error in Projects",
                        callback: (confirmation) => setWarningAlert(false)
                    }} />
                : null}

            {deleteItemAlert ?

                <AlertPopup
                    props={{
                        type: "delete",
                        actionBtnOption: { label: "Yes, Delete" },
                        heading: "Delete item",
                        message: getDeleteMessage(deleteItemType),
                        callback: (confirmation) => HandleDeleteConfirmItem(deleteItemId, deleteItemType, confirmation)
                    }} />

                : null}

            <div className="project-projects-main" style={{ padding: 0 }}>
                <div className="projects-panel-content">

                    <div className="panel-content-header">
                        <div
                            className="project_list-status_filter-main"
                        >
                            <div className="project_list-status_filter-items">
                                {ProjectStatusFilter_Options.map((opt, i) => (
                                    <div
                                        key={`project-status-filter-${opt.value}`}
                                        className={`project_list-status_filter-item ${selectedResultSection == opt.value ? 'project_list-status_filter-item-active' : ''}`}
                                        onClick={(e) => HandleResultSection(opt.value)}
                                    >
                                        <div className="label">{opt.label}</div>
                                        <div className="count">{opt.count}</div>
                                    </div>
                                ))}
                            </div>
                        </div>
                        <div className="project_list-actions buttons">
                            {has_add_access ?
                                <div
                                    className="button add-button"
                                    onClick={(e) => HandleAddNewProject()}
                                >
                                    <div className="icon" dangerouslySetInnerHTML={{ __html: Icons.general.add_btn }}></div>
                                    <div className="label">Add Project</div>
                                </div>
                                : ''}
                        </div>
                    </div>
                    <div className="panel-content-sections panel-content-tabel-section">
                        {RenderItemsResult(ProjectsDetials, selectedResultSection == 'all' ? '' : selectedResultSection)}
                    </div>
                </div>
            </div>
        </>
    )
}

export default ProjectsList;