

import React, { useEffect, useState, useRef, useCallback, useMemo, StrictMode } from 'react'
import { Route, Routes, BrowserRouter, Link, useLocation, Navigate, Outlet, useNavigate, useParams } from 'react-router-dom';
import store from '../../redux/store'
import StoreHandler from '../../redux/StoreHandler'
import { useSelector, useDispatch } from 'react-redux'
import UserAction from '../../redux/action/userAction'

import ReverseProxy from '../../config/reverseProxy'
import CheckListsHandler from '../../Handlers/CheckLists/CheckLists';


import Icons from '../../assets/Icons'
import Images from '../../assets/Images'
import { SearcBarInput, TextArea, TextInput, SelectInput, TimeInput, MultiSelectInput, DateTimeInput } from '../../components/Inputs'
import Utils from '../../utils'
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

import SystemToastPopup from '../../components/SystemToastPopup'
import AlertPopup from '../../components/AlertPopup'
import Loading from '../../components/Loading'
import NoDataFound from '../../components/NoDataFound'

import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableFooter from '@mui/material/TableFooter';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';

import { useAuth, useMenuAuth } from '../../hooks/useAuth'


const CheckListView = ({ props }) => {

    const { projectId, listId } = useParams();
    const location = useLocation()

    const queryParams = new URLSearchParams(location.search);
    const date = queryParams.get('date');
    const currentDate = Utils._getLocalDate()

    const [isLoading, setIsLoading] = useState(false)
    const [warningAlert, setWarningAlert] = useState(false)
    const [warningAlertType, setWarningAlertType] = useState('warning')
    const [apiFailedMessage, setApiFailedMessage] = useState('Error in Bills')

    const navigate = useNavigate()
    const store = useSelector((store) => store)
    const dispatch = useDispatch()
    const { updateState } = new UserAction

    const storeHandler = new StoreHandler({
        dataStack: ['team_users'],
        store: store,
        dispatch: dispatch,
        updateState: updateState,
    })

    const LoadStoreData = async () => {
        setIsLoading(true)
        let resolved = await storeHandler.init()
        setIsLoading(false)
    }

    const { isAdmin, menu_permission } = useMenuAuth({ menu: 'safety--checklists', feature_key: 'checklists' })


    const [selected_view_settings_value, setSelected_view_settings_value] = useState('')
    const [has_view_access, setHas_view_access] = useState(false)
    const [has_add_access, setHas_add_access] = useState(false)
    const [has_edit_access, setHas_edit_access] = useState(false)
    const [has_delete_access, setHas_delete_access] = useState(false)

    useEffect(() => {

        if (isAdmin) {

            setHas_view_access(true)
            setHas_add_access(true)
            setHas_edit_access(true)
            setHas_delete_access(true)
        }

        else if (menu_permission) {

            if (
                !menu_permission._view
                &&
                (!menu_permission.selected_view_settings_value
                    || menu_permission.selected_view_settings_value == 'no_access')
            ) {
                navigate(`${ReverseProxy['proxyUrl']}/quick-menu`, { state: { from: location }, replace: true })
            }

            setHas_view_access(menu_permission._view == '1')
            setHas_add_access(menu_permission._create == '1')
            setHas_edit_access(menu_permission._edit == '1')
            setHas_delete_access(menu_permission._delete == '1')

            setSelected_view_settings_value(menu_permission.selected_view_settings_value)

        }

    }, [store])

    const checkListsHandler = new CheckListsHandler()


    const [_Date, set_Date] = useState('')
    const [ReachedMaxDate, setReachedMaxDate] = useState(false)
    const [SeachInputValue, setSeachInputValue] = useState('')

    const [CheckListQuestions, setCheckListQuestions] = useState([])

    const [tabelHeader, setTabelHeader] = useState([
        {
            id: 'sno',
            label: 'S:No',
            minWidth: 30,
            disable: false,
        },

        {
            id: 'question',
            label: 'Question',
            minWidth: 200,

        },
        {
            id: 'answer',
            label: 'Answer',

        },
        {
            id: 'description',
            label: 'Description',
            minWidth: 100,
        },
        {
            id: 'attachments',
            label: 'Attachments',
            minWidth: 100,
        },

    ])

    const HandleCancel = (e) => {

        navigate(`/builder/projects/${projectId}/checklists`)
    }

    const HandleEditForm = (e) => {
        navigate(`/builder/projects/${projectId}/checklists/${listId}/edit?date=${currentDate}`)
    }
    const getChecklistExist = async () => {

        let filter = {
            project_id: projectId,
            template_id: listId,
            date: date
        }

        setIsLoading(true)

        let response = await checkListsHandler.getChecklistsHandler(filter)
        setIsLoading(false)

        if (response.success) {
            let { data } = response

            if (!Array.isArray(data) || !data.length) {
                return false
            }

            return data[0]

        }
        return false
    }
    const LoadChecklistTemplate = async () => {

        let filter = {
            id: listId,
            project_id: projectId,
        }

        setIsLoading(true)

        let response = await checkListsHandler.getChecklistTemplatesHandler(filter)
        setIsLoading(false)

        if (response.success) {
            let { data } = response

            if (!Array.isArray(data) || !data.length) {
                return HandleCancel()
            }

            let {

                questions

            } = data[0]

            let _questions = questions.map((q, index) => {

                return {
                    sno: index + 1,
                    id: q.id,
                    q_id: q.id,
                    question: q.question.value,
                    answer: 'N/A',
                    description: 'N/A',
                    response: {
                        type: q.response.type,
                        value: '',
                        selected: [],
                        is_required: q.is_required,

                        yes_no_setting: {
                            yes_placeholder: q.response.yes_no_setting.yes_placeholder,
                            no_placeholder: q.response.yes_no_setting.no_placeholder,
                            na_placeholder: q.response.yes_no_setting.na_placeholder
                        },
                        text_setting: {
                            placeholder: q.response.text_setting.placeholder
                        },
                        checkbox_setting: {
                            options: q.response.checkbox_setting.options
                        },
                        multi_choice_setting: {
                            options: q.response.multi_choice_setting.options
                        }
                    },
                    attachments: []
                }
            })

            let checklist_exist = await getChecklistExist()

            if (checklist_exist) {
                let {
                    sno: id,
                    template_id,
                    template_name,
                    questions

                } = checklist_exist

                questions = questions || []
                _questions = _questions.map((q, index) => {

                    let found = questions.find(qq => qq.q_id == q.q_id)

                    if (found) {
                        q.q_id = found.q_id
                        q.question = found.question || 'N/A'
                        q.answer = found.response_value || 'N/A'
                        q.description = found.description || 'N/A'
                        q.response.value = found.response_value
                        q.response.selected = found.response_selected
                        q.attachments = found.response_attachments.map((a, a_idx) => {
                            return {
                                id: Utils.getUniqueId(),
                                url: a,
                                file: null
                            }
                        })
                    }

                    return q
                })
            }



            setCheckListQuestions(_questions)
        }
    }

    const HandleDateChanger = (action) => {


        if (!action) return
        if (action == '+') {
            if (Utils.isDateCompare(date, currentDate, '>=')) {
                setReachedMaxDate(true)
                return
            }
            else {
                if (ReachedMaxDate) setReachedMaxDate(false)
            }

            let _date = Utils.getDaysBeforeDate(date, -1)
            navigate(`/builder/projects/${projectId}/checklists/${listId}/view?date=${_date}`)
        }
        else if (action == '-') {
            if (ReachedMaxDate) setReachedMaxDate(false)
            let _date = Utils.getDaysBeforeDate(date, 1)
            navigate(`/builder/projects/${projectId}/checklists/${listId}/view?date=${_date}`)
        }
    }

    const HandleDateChange = (value) => {
        navigate(`/builder/projects/${projectId}/checklists/${listId}/view?date=${value}`)
    }

    const HandleDateToday = () => {

        navigate(`/builder/projects/${projectId}/checklists/${listId}/view?date=${currentDate}`)
    }

    useEffect(() => {
        if (!date) navigate(`/builder/projects/${projectId}/checklists/${listId}/view?date=${currentDate}`)
        else {

            setReachedMaxDate(Utils.isDateCompare(date, currentDate, '>='))

            set_Date(date)
            LoadChecklistTemplate()
        }

    }, [date]);

    useEffect(() => {

        // LoadStoreData()
    }, [])

    useEffect(() => {

        if (store && store.user) {

        }

    }, [store])


    return (
        <>


            {isLoading ?
                <Loading
                    props={{
                        isMainLogo: false,
                        isLabel: true
                    }} />
                : null}
            {warningAlert ?

                <SystemToastPopup
                    props={{
                        type: warningAlertType,
                        message: apiFailedMessage || "Error in Bills",
                        callback: (confirmation) => setWarningAlert(false)
                    }} />

                : null}


            <div className="project-survey_updater-main" id='project-survey_updater-main'>
                <div className="survey_updater-header">
                    <div className="survey_updater-header-left">

                        <div
                            className={`survey_updater-header-today ${ReachedMaxDate ? 'disabled' : ''}`}
                            onClick={e => HandleDateToday()}
                        >Today</div>
                        <div className="survey_updater-header-date">
                            <div className="survey_updater-date-arrow-left"
                                dangerouslySetInnerHTML={{ __html: Icons.general.dropdown_arrow }}
                                onClick={e => HandleDateChanger('-')}
                            ></div>
                            <div className="survey_updater-date-input">
                                <DateTimeInput
                                    props={{
                                        id: "survey_updater-date",
                                        value: _Date,
                                        maxValue: currentDate,
                                        placeholder: '',
                                        setValue: (value) => HandleDateChange(value),
                                        isIcon: false,
                                        isLabel: false,
                                        icon: Icons.general.schedule,
                                        isRequired: true,
                                        label: "Date",
                                    }}
                                />
                            </div>
                            <div className={`survey_updater-date-arrow-right ${ReachedMaxDate ? 'disabled' : ''}`}
                                dangerouslySetInnerHTML={{ __html: Icons.general.dropdown_arrow }}
                                onClick={e => HandleDateChanger('+')}
                            ></div>
                        </div>
                        <div className="survey_updater-header-no_work" style={{ display: 'none' }}>
                            <div className="icon"
                                dangerouslySetInnerHTML={{ __html: Icons.general.no_work }}
                            ></div>
                            <div className="label">No Work</div>
                        </div>
                    </div>
                    <div className="survey_updater-header-right">
                        <div className="survey_updater-header-search">
                            <SearcBarInput
                                props={{
                                    id: 'search',
                                    value: SeachInputValue,
                                    placeholder: "Search",
                                    setValue: (val) => setSeachInputValue(val),
                                    isIcon: true,
                                    isLeft: true,
                                    icon: Icons.general.search
                                }}
                            />
                        </div>

                    </div>
                </div>
                <div className="survey_updater-form">
                    <div className="survey_updater-form-header">
                        <div className="title">Questions</div>
                        <div className="actions">
                            <div
                                className="update-form-btn"
                                onClick={e => HandleEditForm(e)}
                            >
                                <div className="icon"
                                    dangerouslySetInnerHTML={{ __html: Icons.general.edit_btn }}
                                ></div>
                                <div className="label">Update</div>
                            </div>
                        </div>
                    </div>
                    <div className="survey_updater-form-content">
                        <Paper style={{ width: '100%', height: '100%', boxShadow: 'none', overflow: 'hidden' }}>
                            <TableContainer style={{ height: '93%', boxShadow: 'none !important', }}>
                                <Table stickyHeader>
                                    <TableHead
                                        className='tabel-header'
                                    >
                                        <TableRow>
                                            {tabelHeader.filter(th => !th.disable).map((column) => (
                                                <TableCell
                                                    key={column.id}
                                                    align={column.align}
                                                    style={{ minWidth: column.minWidth }}
                                                    className='tabel-header-items'
                                                >{column.label}</TableCell>
                                            ))}
                                        </TableRow>
                                    </TableHead>
                                    <TableBody
                                        className='tabel-body'
                                    >
                                        {CheckListQuestions.filter(p => !p.disable).map((row, idx) => {
                                            return (
                                                <TableRow
                                                    hover
                                                    role="checkbox"
                                                    tabIndex={-1}
                                                    key={`checklist-form-row-${idx}`}
                                                >
                                                    {tabelHeader.filter(th => !th.disable).map((column) => {
                                                        const value = row[column.id];
                                                        return (
                                                            <TableCell
                                                                key={column.id}
                                                                align={column.align}
                                                                onClick={HandleEditForm}
                                                            >{column.format ? column.format(value) : value}
                                                            </TableCell>
                                                        );
                                                    })}
                                                </TableRow>
                                            );
                                        })}
                                    </TableBody>
                                </Table>
                            </TableContainer>

                        </Paper>
                    </div>
                </div>

            </div>
        </>
    )

}

export default CheckListView;