

import React, { useEffect, useState, useRef } from 'react'
import { Route, Routes, BrowserRouter, Link, useLocation, Navigate, Outlet, useNavigate, useParams } from 'react-router-dom';
import store from '../../redux/store'
import { useSelector, useDispatch } from 'react-redux'
import UserAction from '../../redux/action/userAction'

import ReverseProxy from '../../config/reverseProxy'
import RoleManagementHandler from '../../Handlers/RoleManagement/RoleManagement';

import Icons from '../../assets/Icons'
import Images from '../../assets/Images'
import { CostInput, TextInput, SelectInput, RadioInput } from '../../components/Inputs'
import Utils from '../../utils'
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

import AlertPopup from '../../components/AlertPopup'
import Loading from '../../components/Loading'
import SystemToastPopup from '../../components/SystemToastPopup'

import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { useAuth, useMenuAuth } from '../../hooks/useAuth'

import Default_Roles from './defaultRoles.json'


const CreateNewRole = ({ props }) => {
    const { type, heading, close, callback, detials } = props

    const { roleId } = useParams()

    const store = useSelector((store) => store)
    const dispatch = useDispatch()
    const { updateState } = new UserAction

    const navigate = useNavigate()

    const roleManagement = new RoleManagementHandler()

    const [isLoading, setIsLoading] = useState(false)
    const [warningAlert, setWarningAlert] = useState(false)
    const [warningAlertType, setWarningAlertType] = useState('warning')
    const [apiFailedMessage, setApiFailedMessage] = useState("Error in Server")

    const { isAdmin, menu_permission } = useMenuAuth({ menu: 'global_menus--employees', feature_key: 'teamManagement' })
    const location = useLocation()

    const [selected_view_settings_value, setSelected_view_settings_value] = useState('')
    const [has_view_access, setHas_view_access] = useState(false)
    const [has_add_access, setHas_add_access] = useState(false)
    const [has_edit_access, setHas_edit_access] = useState(false)
    const [has_delete_access, setHas_delete_access] = useState(false)

    useEffect(() => {

        if (isAdmin) {

            setHas_view_access(true)
            setHas_add_access(true)
            setHas_edit_access(true)
            setHas_delete_access(true)
        }

        else if (menu_permission) {

            if (
                !menu_permission._view
                &&
                (!menu_permission.selected_view_settings_value
                    || menu_permission.selected_view_settings_value == 'no_access')
            ) {
                navigate(`${ReverseProxy['proxyUrl']}/quick-menu`, { state: { from: location }, replace: true })
            }

            if (type == 'edit' && menu_permission._edit == 0) navigate(`${ReverseProxy['proxyUrl']}/builder/projects`, { state: { from: location }, replace: true })
            if (type == 'create' && menu_permission._create == 0) navigate(`${ReverseProxy['proxyUrl']}/builder/projects`, { state: { from: location }, replace: true })

            setHas_view_access(menu_permission._view == 1)
            setHas_add_access(menu_permission._create == 1)
            setHas_edit_access(menu_permission._edit == 1)
            setHas_delete_access(menu_permission._delete == 1)

            setSelected_view_settings_value(menu_permission.selected_view_settings_value)

        }

    }, [store])


    const Default_Role_Detials = [...Default_Roles.features]
    const [selectedRole, setSelectedRole] = useState([])

    const [Name, setName] = useState('')
    const [RoleType, setRoleType] = useState('')
    const [RoleStatus, setRoleStatus] = useState('')

    const [RoleStatus_Options, setRoleStatus_Options] = useState([
        {
            value: 0,
            label: 'In Active'
        },
        {
            value: 1,
            label: 'Active'
        }
    ])
    const [RoleType_Options, setRoleType_Options] = useState([
        {
            value: 0,
            label: 'Default'
        },
        {
            value: 1,
            label: 'Custom'
        }
    ])

    const HandleCancel = async (e) => {
        close(type)
    }

    const HandleSubmit = async (e) => {
        e.preventDefault()

        let role_access = selectedRole.map((feature) => {
            return {
                id: feature._id,
                feature_name: feature.label,
                feature_key: feature.feature_key,

                has_view_setting: feature.has_view_setting == 1 ? true : false,
                selected_view_settings_value: feature.selected_view_settings_value,
                view_settings_options: feature.view_settings_options,

                has_view: feature.has_view,
                has_add: feature.has_add,
                has_edit: feature.has_edit,
                has_delete: feature.has_delete,
                has_approve: feature.has_approve,

                _access: feature._access,
                _view: feature._view,
                _create: feature._add,
                _delete: feature._delete,
                _edit: feature._edit,
                _approve: feature._approve,
                notifications: feature.notifications,
            }
        })

        let data = {
            name: Name,
            status: RoleStatus,
            role_access: role_access
        }

        let response = null
        setIsLoading(true)
        if (type == 'edit') {
            data.id = roleId

            response = await roleManagement.updateRoleHandler(data)
        } else {

            data.role_category = 0
            data.type = 1

            response = await roleManagement.createRoleHandler(data)
        }
        setIsLoading(false)

        if (response && response.success) {
            callback()
        } else {
            setWarningAlert(true)
            setWarningAlertType('error')
            setApiFailedMessage(`Error in Role Management`)
        }


    }

    const HandleExpandFeature = (index) => {
        let features = [...selectedRole]

        features[index].is_expanded = !features[index].is_expanded
        setSelectedRole(features)
    }

    const HandleSelectFeatureInput = (index, type, value) => {
        let features = [...selectedRole]

        if (value) features[index]._access = 1

        if (type == '_access') {
            features[index]._access = value ? 1 : 0
            features[index]._view = value ? 1 : 0
            features[index]._add = value ? 1 : 0
            features[index]._edit = value ? 1 : 0
            features[index]._delete = value ? 1 : 0
            features[index]._approve = value ? 1 : 0
            features[index]._notification = value ? 1 : 0
        } else if (type == '_view' && value) {
            features[index]._view = 1
        }
        else if (type == '_view' && !value) {
            features[index]._view = 0
            features[index]._add = 0
            features[index]._edit = 0
            features[index]._delete = 0
            features[index]._approve = 0
        }
        else if (type == '_add' && value) {
            features[index]._view = 1
            features[index]._add = 1
        }
        else if (type == '_add' && !value) {
            features[index]._add = 0
            features[index]._edit = 0
            features[index]._delete = 0
            features[index]._approve = 0
        }
        else if (type == '_edit' && value) {
            features[index]._view = 1
            features[index]._add = 1
            features[index]._edit = 1
        }
        else if (type == '_edit' && !value) {
            features[index]._edit = 0
            features[index]._delete = 0
            features[index]._approve = 0
        }
        else if (type == '_delete' && value) {
            features[index]._view = 1
            features[index]._add = 1
            features[index]._edit = 1
            features[index]._delete = 1
        }
        else if (type == '_delete' && !value) {
            features[index]._delete = 0
            features[index]._approve = 0
        }
        else if (type == '_approve' && value) {
            features[index]._view = 1
            features[index]._add = 1
            features[index]._edit = 1
            features[index]._delete = 1
            features[index]._approve = 1
        }
        else if (type == '_approve' && !value) {
            features[index]._approve = 0
        }
        else if (type == '_notification') {
            features[index]._notification = value ? 1 : 0
        }

        setSelectedRole(features)
    }

    const HandleNotificationInput = (index, nidx, value) => {
        let features = [...selectedRole]

        features[index].notifications[nidx].value = value ? 1 : 0

        setSelectedRole(features)
    }

    const getRoleFeatureDetails = async (id) => {

        setIsLoading(true)
        let response = await roleManagement.getRolesHandler({ id: id })

        setIsLoading(false)

        if (response && response.success) {

            let { roles } = response.data

            if (Array.isArray(roles) && !roles.length) {
                HandleCancel()
            }

            let {
                name,
                description,
                created_at,
                updated_at,
                created_by,
                updated_by,
                company_id,
                role_category,
                type,
                status,
                role_access,

            } = roles[0]

            role_access = role_access || []

            let role_features = Default_Role_Detials.map((d_feature, idxx) => {

                let feature = role_access.find((feature) => feature.feature_key == d_feature.feature_key)

                if (!feature) return d_feature

                return {
                    _id: feature.id,
                    id: feature.feature_key.split('--')[1],
                    label: feature.feature_name,
                    feature_key: feature.feature_key,

                    has_view_setting: feature.has_view_setting == 1 ? true : false,
                    selected_view_settings_value: feature.selected_view_settings_value || d_feature.selected_view_settings_value,
                    view_settings_options: feature.view_settings_options || d_feature.view_settings_options,

                    has_view: feature.has_view || d_feature.has_view,
                    has_add: feature.has_add || d_feature.has_add,
                    has_edit: feature.has_edit || d_feature.has_edit,
                    has_delete: feature.has_delete || d_feature.has_delete,
                    has_approve: feature.has_approve || d_feature.has_approve,

                    _access: feature._access,
                    _view: feature._view,
                    _add: feature._create,
                    _edit: feature._edit,
                    _delete: feature._delete,
                    _approve: feature._approve,
                    notifications: feature.notifications || [],
                }

            })

            setName(name)
            setRoleType(type)
            setRoleStatus(status)


            setSelectedRole(role_features)

        } else {
            setWarningAlert(true)
            setWarningAlertType('error')
            setApiFailedMessage(`Error in Fetching Role Detials, Please try again`)
        }

    }

    useEffect(() => {

        if (type == 'edit') {
            if (!detials) HandleCancel()

            let {
                id,
                name,
                type,
                status,
            } = detials

            setName(name)
            setRoleType(type)
            setRoleStatus(status)
            getRoleFeatureDetails(id)
        }
        else {
            setRoleType(1)
            setRoleStatus(1)
            setSelectedRole(Default_Role_Detials)
        }


    }, [])


    return (
        <>

            {isLoading ?
                <Loading
                    props={{
                        isMainLogo: false,
                        isLabel: true
                    }} />
                : null}
            {warningAlert ?

                <SystemToastPopup
                    props={{
                        type: warningAlertType,
                        message: apiFailedMessage || "Error in Team Management",
                        callback: (confirmation) => setWarningAlert(false)
                    }} />

                : null}


            <div className="popup-container-main">
                <div className="popup-block-ui"></div>
                <div className="side-popup-container side-role-popup-container animateXRight animateXRightactive" >
                    <div className="sidebar-role-popup-content role-main-container">
                        <div className="role-content-header">
                            <div className="role-header-left">
                                <div className="title">Project Level Role Details</div>
                            </div>
                            <div className="role-header-right">
                                <div
                                    className="close-btn"
                                    onClick={() => HandleCancel()}
                                    dangerouslySetInnerHTML={{ __html: Icons.general.close }}
                                ></div>
                            </div>
                        </div>
                        <div className="role-content-sections">
                            <div className="role-basic_details-section">
                                <div className="basic_details-section-item">
                                    <TextInput
                                        props={{
                                            id: "role-form-name",
                                            value: Name,
                                            placeholder: 'Enter Role Name',
                                            setValue: (value) => setName(value),
                                            isIcon: false,
                                            isLabel: true,
                                            isRequired: true,
                                            label: "Role Name",
                                        }}
                                    />
                                </div>
                                <div className="basic_details-section-item">
                                    <SelectInput
                                        props={{
                                            id: "role-form-type",
                                            value: RoleType,
                                            placeholder: 'Select Role Type',
                                            options: RoleType_Options || [],
                                            setValue: (value, label) => setRoleType(value),
                                            isIcon: false,
                                            isLabel: true,
                                            isRequired: true,
                                            readOnly: true,
                                            label: "Role Type",
                                        }}
                                    />
                                </div>
                                <div className="basic_details-section-item">
                                    <SelectInput
                                        props={{
                                            id: "role-form-status",
                                            value: RoleStatus,
                                            placeholder: 'Select Role Status',
                                            options: RoleStatus_Options || [],
                                            setValue: (value, label) => setRoleStatus(value),
                                            isIcon: false,
                                            isLabel: true,
                                            isRequired: true,
                                            label: "Role Status",
                                        }}
                                    />
                                </div>
                            </div>
                            <div className="role-permissions-section">
                                <div className="permissions-section-header">
                                    <div className="title">Role Permissions & Notifications</div>
                                </div>
                                <div className="permissions-section-content">
                                    <div className="permissions-header">Module Level Permissions</div>
                                    <div className="permissions-content-items">
                                        {selectedRole.map((feature, idx) => (
                                            <div
                                                className="permissions-content-item"
                                                key={`role-access-${idx}`}
                                            >
                                                <div className="permission-item-header">
                                                    <div
                                                        className={`dropdown-icon ${feature.is_expanded ? 'dropdown-icon-active' : ''}`}
                                                        onClick={() => HandleExpandFeature(idx)}
                                                        dangerouslySetInnerHTML={{ __html: Icons.general.dropdown_arrow }}
                                                    ></div>
                                                    <div className="group-select-input">
                                                        {console.log(feature._access, feature.label)}
                                                        <RadioInput props={{
                                                            value: feature._access == 1,
                                                            isIcon: false,
                                                            icon: "",
                                                            inputType: "checkbox",
                                                            name: "role-permission-item",
                                                            setValue: (value) => HandleSelectFeatureInput(idx, '_access', value)
                                                        }} />
                                                    </div>
                                                    <div
                                                        className="item-name"
                                                        onClick={() => HandleExpandFeature(idx)}
                                                    >
                                                        <div className="icon"
                                                            dangerouslySetInnerHTML={{ __html: Icons.general.takeoff }}
                                                        ></div>
                                                        <div className="label">{feature.label}</div>
                                                    </div>
                                                </div>
                                                <div className={`permission-item-details ${feature.is_expanded ? 'permission-item-details-active' : ''}`}>
                                                    <div className="permission-item-details-options">
                                                        <div className="options-header">Options</div>
                                                        {feature.has_view == 1 ?
                                                            <div className="option-item">
                                                                <div className="input">
                                                                    <RadioInput props={{
                                                                        value: feature._view == 1,
                                                                        isIcon: false,
                                                                        icon: "",
                                                                        inputType: "checkbox",
                                                                        name: "role-permission-item-view",
                                                                        setValue: (value) => HandleSelectFeatureInput(idx, '_view', value)
                                                                    }} />
                                                                </div>
                                                                <div className="label">View only</div>
                                                            </div>
                                                            : ''}
                                                        {feature.has_add == 1 ?
                                                            <div className="option-item">
                                                                <div className="input">
                                                                    <RadioInput props={{
                                                                        value: feature._add == 1,
                                                                        isIcon: false,
                                                                        icon: "",
                                                                        inputType: "checkbox",
                                                                        name: "role-permission-item-create",
                                                                        setValue: (value) => HandleSelectFeatureInput(idx, '_add', value)
                                                                    }} />
                                                                </div>
                                                                <div className="label">View and create only</div>
                                                            </div>
                                                            : ''}
                                                        {feature.has_edit == 1 ?
                                                            <div className="option-item">
                                                                <div className="input">
                                                                    <RadioInput props={{
                                                                        value: feature._edit == 1,
                                                                        isIcon: false,
                                                                        icon: "",
                                                                        inputType: "checkbox",
                                                                        name: "role-permission-item-edit",
                                                                        setValue: (value) => HandleSelectFeatureInput(idx, '_edit', value)
                                                                    }} />
                                                                </div>
                                                                <div className="label">View, create and edit only</div>
                                                            </div>
                                                            : ''}
                                                        {feature.has_delete == 1 ?
                                                            <div className="option-item">
                                                                <div className="input">
                                                                    <RadioInput props={{
                                                                        value: feature._delete == 1,
                                                                        isIcon: false,
                                                                        icon: "",
                                                                        inputType: "checkbox",
                                                                        name: "role-permission-item-delete",
                                                                        setValue: (value) => HandleSelectFeatureInput(idx, '_delete', value)
                                                                    }} />
                                                                </div>
                                                                <div className="label">View, create, edit and delete</div>
                                                            </div>
                                                            : ''}
                                                        {feature.has_approve == 1 ?
                                                            <div className="option-item">
                                                                <div className="input">
                                                                    <RadioInput props={{
                                                                        value: feature._approve == 1,
                                                                        isIcon: false,
                                                                        icon: "",
                                                                        inputType: "checkbox",
                                                                        name: "role-permission-item-approve",
                                                                        setValue: (value) => HandleSelectFeatureInput(idx, '_approve', value)
                                                                    }} />
                                                                </div>
                                                                <div className="label">View, edit, upload, create and approve</div>
                                                            </div>
                                                            : ''}
                                                    </div>
                                                    {Array.isArray(feature.notifications) && feature.notifications.length ?
                                                        <div className="permission-item-details-notifications">
                                                            <div className="options-header">Notification</div>
                                                            {feature.notifications.map((notification, nidx) => (
                                                                <div
                                                                    className="option-item"
                                                                    key={`role-notification-${nidx}`}
                                                                >
                                                                    <div className="input">
                                                                        <RadioInput props={{
                                                                            value: notification.value == 1,
                                                                            isIcon: false,
                                                                            icon: "",
                                                                            inputType: "checkbox",
                                                                            name: "role-permission-item-view",
                                                                            setValue: (value) => HandleNotificationInput(idx, nidx, value)
                                                                        }} />
                                                                    </div>
                                                                    <div className="label">{notification.label}</div>
                                                                </div>
                                                            ))}
                                                        </div>
                                                        : ''}
                                                </div>
                                            </div>
                                        ))}

                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                    <div className="sidebar-popup-footer role-sidebar-footer">
                        <div className="footer-item action-items">
                            <div
                                className="action-btn discard-changes-btn"
                                onClick={() => HandleCancel()}
                            >
                                <div className="label">Cancel</div>
                            </div>
                            <div
                                className="action-btn save-changes-btn"
                                onClick={(e) => HandleSubmit(e)}
                            >
                                <div className="label">Save</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default CreateNewRole