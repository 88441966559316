

import React, { useEffect, useState, useRef, useCallback, useMemo, StrictMode } from 'react'
import { Route, Routes, BrowserRouter, Link, useLocation, Navigate, Outlet, useNavigate, useParams } from 'react-router-dom';
import store from '../../redux/store'
import StoreHandler from '../../redux/StoreHandler'
import { useSelector, useDispatch } from 'react-redux'
import UserAction from '../../redux/action/userAction'

import ReverseProxy from '../../config/reverseProxy'

import PurchaseOrderHandler from '../../Handlers/PurchaseOrder/PurchaseOrder';
import Icons from '../../assets/Icons'
import Images from '../../assets/Images'
import { CostInput, TextInput, SelectInput, DateTimeInput, RadioInput } from '../../components/Inputs'
import Utils from '../../utils'
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

import { PDFExport, savePDF } from '@progress/kendo-react-pdf';



const PreviewPurchaseOrder = ({ props }) => {

    const { projectId, purchaseId } = useParams();

    const { menuType = 'create', close, purchaseOrderLoaded, purchaseOrderDetials, saveCallback } = props


    const [isLoading, setIsLoading] = useState(false)
    const [warningAlert, setWarningAlert] = useState(false)
    const [warningAlertType, setWarningAlertType] = useState('warning')
    const [apiFailedMessage, setApiFailedMessage] = useState("Error in Server")

    const navigate = useNavigate()
    const store = useSelector((store) => store)
    const dispatch = useDispatch()
    const { updateState } = new UserAction

    const storeHandler = new StoreHandler({
        dataStack: ['company', 'vendors', 'terms_conditions', 'field_settings', `project-${projectId}`],
        store: store,
        dispatch: dispatch,
        updateState: updateState,
    })

    const LoadStoreData = async () => {
        setIsLoading(true)
        let resolved = await storeHandler.init()
        setIsLoading(false)
    }
    const pdfRef = useRef()
    const purchaseOrderHandler = new PurchaseOrderHandler()

    const [unitsOptions, setUnitsOptions] = useState([])
    const [currency_type, setCurrency_type] = useState('₹')

    const CompanyProfile = store.user.company || undefined
    const isCompany = Object.keys(CompanyProfile).length > 0

    const [ShowWatermark, setShowWatermark] = useState(false)

    const [companyImg, setCompanyImg] = useState('')
    const [companyName, setCompanyName] = useState('N/A')
    const [projectName, setProjectName] = useState('N/A')
    const [projectArea, setProjectArea] = useState('N/A')
    const [projectID, setProjectID] = useState('N/A')
    const [projectAddress, setProjectAddress] = useState('N/A')
    const [dateFormat, setDateFormat] = useState('DD/MM/YYYY')

    const [id, setId] = useState('')
    const [name, setName] = useState('')
    const [purchaseID, setPurchaseID] = useState('')
    const [status, setStatus] = useState('')
    const [introduction_text, setIntroduction_text] = useState('')
    const [comments, setComments] = useState('')

    const [vendor_id, setVendorId] = useState('')
    const [vendor_name, setVendorName] = useState('N/A')
    const [vendor_gstno, setVendorGSTNo] = useState('N/A')

    const [purchaseOrderDate, setPurchaseOrderDate] = useState('N/A')
    const [approvalDeadlineDate, setApprovalDeadlineDate] = useState('N/A')
    const [deliveryDeadlineDate, setDeliveryDeadlineDate] = useState('N/A')


    const [GRNTotalAmount, setGRNTotalAmount] = useState(0)
    const [GRNPaidAmount, setGRNPaidAmount] = useState(0)
    const [GRNDueAmount, setGRNDueAmount] = useState(0)

    const [termsConditionValue, setTermsConditionValue] = useState("")

    const [vendorOptions, setVendorOptions] = useState([])

    const [StatusOptions, setStatusOptions] = useState([
        {
            value: 0,
            label: "Pending",
            isDefault: false
        },
        {
            value: 1,
            label: "Approved",
            isDefault: false
        },
        {
            value: 2,
            label: "Rejected",
            isDefault: false
        },
    ])
    const [materialItems, setMaterialItems] = useState([
        {
            id: Utils.getUniqueId(),
            material_id: "",
            material_name: "",
            unitrate: 0,
            tax: 0,
            markup: 0,
            tax_amount: 0,
            markup_amount: 0,
            final_unitrate: 0,
            amount: 0,
            final_amount: 0,
            pre_tax_amount: 0,
            value_type: `$`,
            unit_id: '',
            unit_name: '',
            quantity: 0,
            description: '',
            notes: ''
        }
    ])


    const HandleDownloadBtn = () => {

        pdfRef.current.save();
        // savePDF(pdfRef.current, { paperSize: "A4" });
    }


    const getNextClientInvoiceID = async () => {

        let response = await purchaseOrderHandler.getNextPurchaseOrderIDHandler()

        if (response && response.success) {

            let { purchaseId } = response.data

            if (purchaseId) setPurchaseID(purchaseId)

        } else {
            setWarningAlert(true)
            setWarningAlertType('error')
            setApiFailedMessage(`Error in fetching next purchase order id`)
        }
    }


    const LoadProposal = async () => {


        let filter = {
            purchaseId: purchaseId,
            projectId: projectId,
        }

        let _purchaseOrderDetials = undefined

        if (purchaseOrderLoaded && purchaseOrderDetials && Object.keys(purchaseOrderDetials).length) {

            _purchaseOrderDetials = purchaseOrderDetials

        } else if (purchaseId) {

            setIsLoading(true)

            let response = await purchaseOrderHandler.getPurchaseOrdersHandler(filter)
            setIsLoading(false)

            if (response.success) {
                let { data } = response

                if (!Array.isArray(data) || !data.length) navigate(`/builder/projects/${projectId}/client_invoice`)

                _purchaseOrderDetials = data[0]
            }

        }

        if (_purchaseOrderDetials && Object.keys(_purchaseOrderDetials).length) {

            let {
                id,
                name,
                purchaseID,
                projectId: projectID,
                purchase_date,
                approval_deadline_date,
                delivery_deadline_date,
                vendor_id,
                vendor_name,
                status,
                grn_total,
                grn_paid,
                grn_due,

                introduction_text,
                comments,
                terms_condition_id,
                terms_condition,
                cost_items,
            } = _purchaseOrderDetials


            setId(id)
            setPurchaseID(purchaseID)
            setName(name)

            if (status != undefined) {

                let _status = StatusOptions.filter(opt => opt.value == status)[0]
                if (_status) setStatus(_status.label)
            }

            setIntroduction_text(introduction_text)

            setTermsConditionValue(terms_condition)
            setComments(comments)

            setPurchaseOrderDate(purchase_date)
            setApprovalDeadlineDate(approval_deadline_date)
            setDeliveryDeadlineDate(delivery_deadline_date)

            setVendorId(vendor_id)
            setVendorName(vendor_name)

            setGRNTotalAmount(grn_total || 0)
            setGRNPaidAmount(grn_paid || 0)
            setGRNDueAmount(grn_due || 0)

            setMaterialItems(cost_items || [])
        }

    }

    useEffect(() => {

        if (menuType == 'create') {
            getNextClientInvoiceID()
        }
        else {
            LoadProposal()
        }

        LoadStoreData()

    }, [])

    useEffect(() => {

        if (store && store.user) {

            let { company, vendors, settings, selected_project } = store.user

            if (selected_project) {
                let { name, status, area, unit_id, unit_name, type_id, type_name, address, client_id, client_name } = selected_project

                setProjectName(name || 'N/A')
                setProjectArea(`${area}${unit_name || ""}` || 'N/A')
                setProjectID(projectId)
                setProjectAddress(address || "N/A")

            }

            if (company) {
                let { name, logo_img, currency_setting, date_format_setting, timezone, temperature_setting } = company

                const profileImgURL = logo_img ? `data:image/png;base64,${logo_img}` : ''

                setCompanyImg(profileImgURL)
                setCompanyName(name || 'N/A')
                setDateFormat(date_format_setting || 'DD/MM/YYYY')
                if (currency_setting) setCurrency_type(currency_setting)
            }


            if (settings && Array.isArray(settings.field_settings) && settings.field_settings.length) {

                let { project_statuses, project_types, units } = settings.field_settings[0]

                units = units.filter(opt => opt.isActive).map((opt) => {
                    return {
                        value: opt.id,
                        label: opt.name,
                        isDefault: opt.isDefault || false
                    }
                })

                setUnitsOptions(units || [])

            }
            if (vendors && Array.isArray(vendors) && vendors.length) {

                let vendor_options = vendors.filter(opt => opt.status == '1').map((opt) => {
                    return {
                        value: opt.vendorID,
                        label: opt.name,
                        gstin_no: opt.gstin_no,
                        tin_no: opt.tin_no,
                        isDefault: false
                    }
                })

                setVendorOptions(vendor_options || [])

            }

        }

    }, [store])

    useEffect(() => {

        if (store && store.user) {

            let { company, vendors, settings, selected_project } = store.user


            if (vendors && Array.isArray(vendors) && vendors.length) {

                let vendor_options = vendors.filter(opt => opt.status == '1').map((opt) => {
                    return {
                        value: opt.vendorID,
                        label: opt.name,
                        gstin_no: opt.gstin_no,
                        tin_no: opt.tin_no,
                        isDefault: false
                    }
                })

                let vendor = vendor_options.filter(opt => opt.value == vendor_id)[0]
                if (vendor) setVendorGSTNo(vendor.gstin_no)

                setVendorOptions(vendor_options || [])

            }

        }

    }, [store, vendor_id])


    const HandleCancel = async (e) => {

        close()
    }


    return (
        <>
            <div className="popup-container-main popup-container-center">
                <div className="popup-block-ui"></div>
                <div className="side-popup-container preview-popup-container">

                    <div className="side-popup-header">
                        <div></div>
                        <div className="header-item-select">
                            <div className="header-item-select-content">
                                <div className="label">Web Preview</div>
                                <div className="description">This is what vendor will see when you send the purchase order</div>
                            </div>
                        </div>
                        <div
                            className="header-item-close"
                            onClick={(e) => HandleCancel()}
                            dangerouslySetInnerHTML={{ __html: Icons.general.close }}
                        ></div>
                    </div>

                    <div className="sidebar-popup-content">
                        <div className="project-create_client_invoice-main" id='project-create_client_invoice-main'>

                            <div className="create_client_invoice-panel-content">
                                <PDFExport
                                    ref={pdfRef}
                                    author='CivilATOR'
                                    fileName={`${String(projectName).split(' ').join('_')}_Proposal_${Utils.getLocalFullDateBYFormat(null, '_', dateFormat)}`}
                                    margin={5}
                                    paperSize="A4"
                                    scale={0.6}
                                >
                                    <div className="panel-content-sections">
                                        <div className="create-proposal-content-main">

                                            <div className="create-proposal-sections">

                                                <div className="preview-header-section">
                                                    <div className="header-section-top">
                                                        <div className="company-detials">
                                                            <div className="logo">
                                                                <img src={companyImg || Images.User} />
                                                            </div>
                                                            <div className="title">{companyName}</div>
                                                        </div>
                                                        <div className="detials">
                                                            <div className="detials-item" >
                                                                <div className="label">Status</div>
                                                                <div className="value">{status}</div>
                                                            </div>
                                                            <div className="detials-item">
                                                                <div className="label">Project ID</div>
                                                                <div className="value">{projectId}</div>
                                                            </div>
                                                            <div className="detials-item">
                                                                <div className="label">Purchase Order ID</div>
                                                                <div className="value">{purchaseID}</div>
                                                            </div>

                                                        </div>
                                                    </div>
                                                    <div className="header-section-detials">
                                                        <div className="title">Purchase Order Information</div>
                                                        <div className="detials">
                                                            <div className="detials-item">
                                                                <div className="label">Purchase Order Name</div>
                                                                <div className="value">{name}</div>
                                                            </div>
                                                            <div className="detials-item">
                                                                <div className="label">Purchase Order Date</div>
                                                                <div className="value">{purchaseOrderDate}</div>
                                                            </div>

                                                            <div className="detials-item">
                                                                <div className="label">Approval Deadline</div>
                                                                <div className="value">{approvalDeadlineDate}</div>
                                                            </div>
                                                            <div className="detials-item">
                                                                <div className="label">Delivery Deadline</div>
                                                                <div className="value">{deliveryDeadlineDate}</div>
                                                            </div>
                                                            <div className="detials-item">
                                                                <div className="label">Vendor Name</div>
                                                                <div className="value">{vendor_name}</div>
                                                            </div>
                                                            <div className="detials-item">
                                                                <div className="label">Vendor GST</div>
                                                                <div className="value">{vendor_gstno}</div>
                                                            </div>
                                                            <div className="detials-item">
                                                                <div className="label">Project Name</div>
                                                                <div className="value">{projectName}</div>
                                                            </div>
                                                            <div className="detials-item">
                                                                <div className="label">Project Address</div>
                                                                <div className="value">{projectAddress}</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                {introduction_text ?
                                                    <div
                                                        className="create-proposal-section"

                                                        id={`create-proposal-introduction_text`}
                                                        dangerouslySetInnerHTML={{ __html: introduction_text }}
                                                    >
                                                    </div>
                                                    : ""}

                                                {materialItems.length ?
                                                    <div
                                                        className="create-client_invoicesection create-proposal-section"
                                                        id={`create-client_invoice_payment_history`}

                                                    >
                                                        <div className={`createproposal-description-main`}>

                                                            <div className="head">
                                                                <div className="title">
                                                                    <div className="label">Materials</div>
                                                                </div>
                                                            </div>


                                                            <div className="preview-costItems-table-main">
                                                                <div className="preview-costItems-table-header">
                                                                    <div className="header-item table-item-description">ITEM DETAILS</div>
                                                                    <div className="header-item table-item-amount">QUANDITY</div>

                                                                </div>
                                                                <div className="preview-costItems-table-content">

                                                                    {materialItems?.map((item, i) => (
                                                                        <div
                                                                            className="content-row"
                                                                            key={`preview-costItems-table-item-${item.id}`}
                                                                        >
                                                                            <div className="content-row-item table-item-description">
                                                                                {item.material_name} <br />{item.description ?
                                                                                    `${item.description}`
                                                                                    : ''}
                                                                            </div>
                                                                            <div className="content-row-item table-item-amount">{item.quantity} {item.unit_name}</div>

                                                                        </div>
                                                                    ))}

                                                                </div>

                                                            </div>



                                                        </div>
                                                    </div>
                                                    : ''}

                                                {termsConditionValue ?
                                                    <div
                                                        className="create-proposal-section"

                                                        id={`create-proposal-terms_conditions`}>

                                                        <div className={`createproposal-description-main`}>
                                                            <div className="head">
                                                                <div className="label">Terms and conditions</div>
                                                            </div>
                                                            <div className="terms-condition-result" dangerouslySetInnerHTML={{ __html: termsConditionValue }}></div>
                                                        </div>

                                                    </div>
                                                    : ''}

                                            </div>
                                        </div>
                                        {ShowWatermark ?
                                            <div className="proposal-product-watermark">
                                                <div className="watermark">
                                                    <div className="label">Powered by</div>
                                                    <img src={Images.logo} className='img' />
                                                </div>
                                            </div>
                                            : ''}
                                    </div>
                                </PDFExport>
                            </div>
                        </div>
                    </div>
                    <div className="sidebar-popup-footer">
                        <div className="footer-item action-items">
                            <div className="action-preview">
                            </div>
                            <div className='action-btns'>
                                <div
                                    className="action-btn button-download"
                                    onClick={(e) => HandleDownloadBtn(e)}
                                >
                                    <div
                                        className="icon"
                                        dangerouslySetInnerHTML={{ __html: Icons.general.download }}
                                    ></div>
                                    <div className="label">Download</div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )

}

export default PreviewPurchaseOrder;