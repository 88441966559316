

import React, { useEffect, useState, useRef } from 'react'
import { Route, Routes, BrowserRouter, Link, useLocation, Navigate, Outlet, useNavigate, useParams } from 'react-router-dom';
import store from '../../redux/store'
import { useSelector, useDispatch } from 'react-redux'
import UserAction from '../../redux/action/userAction'

import ReverseProxy from '../../config/reverseProxy'
import LabourManagementHandler from '../../Handlers/LabourManagement/LabourManagement';

import Icons from '../../assets/Icons'
import Images from '../../assets/Images'
import { CostInput, TextInput, SelectInput, RadioInput, DateTimeInput, RadioOptionsInput } from '../../components/Inputs'
import Utils from '../../utils'
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

import AlertPopup from '../../components/AlertPopup'
import SystemToastPopup from '../../components/SystemToastPopup'
import Loading from '../../components/Loading'
import NoDataFound from '../../components/NoDataFound';

import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableFooter from '@mui/material/TableFooter';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';

import SmarkTopCards from '../../components/SmarkTopCards'

import { useAuth, useMenuAuth } from '../../hooks/useAuth'

const AddNewPaymentRecordPopup = ({ props }) => {

    const { type, heading, close, callback, detials } = props

    const { labourId } = useParams()

    const store = useSelector((store) => store)
    const dispatch = useDispatch()
    const { updateState } = new UserAction

    const navigate = useNavigate()

    const labourManagementHandler = new LabourManagementHandler()

    const [isLoading, setIsLoading] = useState(false)
    const [warningAlert, setWarningAlert] = useState(false)
    const [warningAlertType, setWarningAlertType] = useState('warning')
    const [apiFailedMessage, setApiFailedMessage] = useState('Error in Labour accounts')


    const { isAdmin, menu_permission } = useMenuAuth({ menu: 'labour_managment--labour_accounts', feature_key: 'labourManagement' })
    const location = useLocation()

    const [selected_view_settings_value, setSelected_view_settings_value] = useState('')
    const [has_view_access, setHas_view_access] = useState(false)
    const [has_add_access, setHas_add_access] = useState(false)
    const [has_edit_access, setHas_edit_access] = useState(false)
    const [has_delete_access, setHas_delete_access] = useState(false)

    useEffect(() => {

        if (isAdmin) {

            setHas_view_access(true)
            setHas_add_access(true)
            setHas_edit_access(true)
            setHas_delete_access(true)
        }

        else if (menu_permission) {

            if (
                !menu_permission._view
                &&
                (!menu_permission.selected_view_settings_value
                    || menu_permission.selected_view_settings_value == 'no_access')
            ) {
                navigate(`${ReverseProxy['proxyUrl']}/quick-menu`, { state: { from: location }, replace: true })
            }

            if (menu_permission._create == '0') navigate(`${ReverseProxy['proxyUrl']}/builder/projects`, { state: { from: location }, replace: true })

            setHas_view_access(menu_permission._view == '1')
            setHas_add_access(menu_permission._create == '1')
            setHas_edit_access(menu_permission._edit == '1')
            setHas_delete_access(menu_permission._delete == '1')

            setSelected_view_settings_value(menu_permission.selected_view_settings_value)

        }

    }, [store])


    const [Labour_id, setLabour_id] = useState('')
    const [Labour_name, setLabour_name] = useState('')
    const [Date, setDate] = useState('')
    const [PaymentType, setPaymentType] = useState('0')
    const [Amount, setAmount] = useState('0')

    const [LaboursOptions_Orginal, setLaboursOptions_Orginal] = useState([])
    const [LaboursOptions, setLaboursOptions] = useState([])

    const HandleCancel = async (e) => {
        close(type)
    }
    const HandlePopupSubmit = async (e) => {

        let updateta = {

            id: Labour_id,
            name: Labour_name,
            date: Date,
            payment_type: String(PaymentType),
            amount: String(Amount),
        }

        let response = await labourManagementHandler.createLabourPaymentHandler(updateta)

        if (response.success) {
            callback()
            close(type)
            setIsLoading(false)
        }
        else {
            setIsLoading(false)
            setWarningAlert(true)
            setWarningAlertType('error')
            setApiFailedMessage(`Error in Labour, Please try again!`)
        }


    }
    const Popup_Header = () => {

        return (
            <div className="side-popup-header">
                <div className="header-item-select">
                    <div className="header-item-select-content">
                        <div className="label">{heading}</div>
                    </div>
                </div>
                <div
                    className="header-item-close"
                    onClick={(e) => HandleCancel(e)}
                    dangerouslySetInnerHTML={{ __html: Icons.general.close }}
                ></div>
            </div>
        );
    };

    const Popup_Footer = () => {

        return (
            <div className="sidebar-popup-footer">
                <div className="footer-item action-items">
                    <div className="action-preview">
                    </div>
                    <div className='action-btns'>
                        <div className="action-cancel" onClick={(e) => HandleCancel(e)}>
                            Cancel
                        </div>
                        <div
                            className={`action-btn action-${type}`}
                            onClick={(e) => {
                                HandlePopupSubmit(e)
                            }}
                        >

                            <div className="label">Save</div>
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    const LoadLabours = async () => {

        let response = await labourManagementHandler.getLabourHandler()

        if (response && response.success && Array.isArray(response.data)) {

            let labours = response.data

            let labours_options = labours.map(l => {
                return {
                    value: l.id,
                    label: l.name
                }
            })
            setLaboursOptions(labours_options)
            setLaboursOptions_Orginal(labours)


        }
        else {
            setWarningAlert(true)
            setWarningAlertType('error')
            setApiFailedMessage(`Error in Labour, Please try again!`)
        }
    }



    useEffect(() => {

        if (type == 'edit' && labourId) {

            const LoadLabours = async (labourId) => {

                if (Object.keys(detials).length) {

                    let { id, name, payment_records, updated_at, updated_by } = detials


                }
                else {

                    let response = await labourManagementHandler.getLabourHandler({
                        id: labourId
                    })

                    if (response && response.success && Array.isArray(response.data) && response.data.length) {

                        let { id, name, payment_records, updated_at, updated_by } = response.data[0]


                    }
                    else {
                        setWarningAlert(true)
                        setWarningAlertType('error')
                        setApiFailedMessage(`Error in Labour, Please try again!`)
                    }
                }
            }

            LoadLabours(labourId)
        }


        LoadLabours()
    }, [])

    return (
        <>

            {isLoading ?
                <Loading
                    props={{
                        isMainLogo: false,
                        isLabel: true
                    }} />
                : null}
            {warningAlert ?

                <SystemToastPopup
                    props={{
                        type: warningAlertType,
                        message: apiFailedMessage || "Error in Labour accounts",
                        callback: (confirmation) => setWarningAlert(false)
                    }} />

                : null}


            <div className="popup-container-main popup-container-center">
                <div className="popup-block-ui"></div>
                <div className="side-popup-container center-popup-container" >
                    <Popup_Header />
                    <div className="labourManagement-popup-content" style={{ minHeight: '250px' }}>
                        <div className="content-items">
                            <div className="content-item">
                                <SelectInput
                                    props={{
                                        id: "labourManagement-labour",
                                        value: Labour_id,
                                        placeholder: '',
                                        options: LaboursOptions || [],
                                        setValue: (value, label) => {
                                            setLabour_id(value)
                                            setLabour_name(value)
                                        },
                                        isIcon: false,
                                        isLabel: true,
                                        isRequired: true,
                                        label: "Select Labour",
                                    }}
                                />
                            </div>
                            <div className="content-item">
                                <DateTimeInput
                                    props={{
                                        id: "labourManagement-date",
                                        value: Date,
                                        placeholder: '',
                                        setValue: (value) => {
                                            setDate(value)
                                        },
                                        isIcon: false,
                                        isLabel: true,
                                        icon: Icons.general.schedule,
                                        isRequired: true,
                                        label: "Date",
                                    }}
                                />

                            </div>
                        </div>


                        <div className="content-items">
                            <div className="content-item">

                                <RadioOptionsInput
                                    props={{
                                        id: "labourManagement-labour_payment-type",
                                        value: PaymentType,
                                        placeholder: '',
                                        options: [
                                            {
                                                id: 'labour_payment-type-credit',
                                                label: 'Credit',
                                                value: '1'
                                            },
                                            {
                                                id: 'labour_payment-type-debit',
                                                label: 'Debit',
                                                value: '0'
                                            },
                                        ],
                                        setValue: (value, label) => setPaymentType(value),
                                        isIcon: true,
                                        icon: Icons.general.cost_code,
                                        isLabel: true,
                                        isIconLeft: true,
                                        isRequired: true,
                                        name: "labour_payment-type",
                                        label: "Payment Type",
                                    }}
                                />

                            </div>
                            <div className="content-item">
                                <TextInput
                                    props={{
                                        id: "labourManagement-amount",
                                        value: Amount,
                                        placeholder: '',
                                        setValue: (value) => setAmount(value),
                                        isIcon: false,
                                        type: 'number',
                                        isLabel: true,
                                        isIcon: true,
                                        icon: PaymentType == '0' ? '+' : '-',
                                        isRequired: true,
                                        label: "Amount",
                                    }}
                                />
                            </div>

                        </div>
                    </div>
                    <Popup_Footer />
                </div>
            </div>
        </>
    )
}
const EditLabourPaymentRecordsPopup = ({ props }) => {

    const { type, heading, close, callback, detials } = props

    const { labourId } = useParams()

    const store = useSelector((store) => store)
    const dispatch = useDispatch()
    const { updateState } = new UserAction

    const navigate = useNavigate()

    const labourManagementHandler = new LabourManagementHandler()

    const [isLoading, setIsLoading] = useState(false)
    const [warningAlert, setWarningAlert] = useState(false)
    const [warningAlertType, setWarningAlertType] = useState('warning')
    const [apiFailedMessage, setApiFailedMessage] = useState('Error in Labour accounts')


    const [Labour_id, setLabour_id] = useState('')
    const [Labour_name, setLabour_name] = useState('')
    const [Payment_records, setPayment_records] = useState([])
    const [Opening_balance, setOpening_balance] = useState("0")
    const [Total_credit, setTotal_credit] = useState("0")
    const [Total_debit, setTotal_debit] = useState("0")
    const [Balance_credit, setBalance_credit] = useState("0")


    const { isAdmin, menu_permission } = useMenuAuth({ menu: 'labour_managment--labour_accounts', feature_key: 'labourManagement' })
    const location = useLocation()

    const [selected_view_settings_value, setSelected_view_settings_value] = useState('')
    const [has_view_access, setHas_view_access] = useState(false)
    const [has_add_access, setHas_add_access] = useState(false)
    const [has_edit_access, setHas_edit_access] = useState(false)
    const [has_delete_access, setHas_delete_access] = useState(false)

    useEffect(() => {

        if (isAdmin) {

            setHas_view_access(true)
            setHas_add_access(true)
            setHas_edit_access(true)
            setHas_delete_access(true)
        }

        else if (menu_permission) {

            if (
                !menu_permission._view
                &&
                (!menu_permission.selected_view_settings_value
                    || menu_permission.selected_view_settings_value == 'no_access')
            ) {
                navigate(`${ReverseProxy['proxyUrl']}/quick-menu`, { state: { from: location }, replace: true })
            }

            if (menu_permission._view == '0') navigate(`${ReverseProxy['proxyUrl']}/builder/projects`, { state: { from: location }, replace: true })

            setHas_view_access(menu_permission._view == '1')
            setHas_add_access(menu_permission._create == '1')
            setHas_edit_access(menu_permission._edit == '1')
            setHas_delete_access(menu_permission._delete == '1')

            setSelected_view_settings_value(menu_permission.selected_view_settings_value)

        }

    }, [store])



    const HandleCancel = async (e) => {
        close(type)
    }

    const Popup_Header = () => {

        return (
            <div className="side-popup-header">
                <div className="header-item-select">
                    <div className="header-item-select-content">
                        <div className="label">{heading}</div>
                    </div>
                </div>
                <div
                    className="header-item-close"
                    onClick={(e) => HandleCancel(e)}
                    dangerouslySetInnerHTML={{ __html: Icons.general.close }}
                ></div>
            </div>
        );
    };

    useEffect(() => {

        if (type == 'edit' && labourId) {

            const LoadLabours = async (labourId) => {

                if (Object.keys(detials).length) {

                    let { id, name, opening_balance, total_credit, total_debit, balance_credit, payment_records, updated_at, updated_by } = detials

                    setLabour_id(id)
                    setLabour_name(name)
                    setPayment_records(payment_records || [])
                    setOpening_balance(opening_balance || 0)

                    setTotal_credit(total_credit || 0)
                    setTotal_debit(total_debit || 0)
                    setBalance_credit(balance_credit || 0)
                }
                else {

                    let response = await labourManagementHandler.getLabourHandler({
                        id: labourId
                    })

                    if (response && response.success && Array.isArray(response.data) && response.data.length) {

                        let { id, name, opening_balance, total_credit, total_debit, balance_credit, payment_records, updated_at, updated_by } = response.data[0]
                        setLabour_id(id)
                        setLabour_name(name)
                        setPayment_records(payment_records || [])
                        setOpening_balance(opening_balance || 0)

                        setTotal_credit(total_credit || 0)
                        setTotal_debit(total_debit || 0)
                        setBalance_credit(balance_credit || 0)

                    }
                    else {
                        setWarningAlert(true)
                        setWarningAlertType('error')
                        setApiFailedMessage(`Error in Labour, Please try again!`)
                    }
                }
            }

            LoadLabours(labourId)
        }


    }, [])

    return (
        <>

            {isLoading ?
                <Loading
                    props={{
                        isMainLogo: false,
                        isLabel: true
                    }} />
                : null}
            {warningAlert ?

                <SystemToastPopup
                    props={{
                        type: warningAlertType,
                        message: apiFailedMessage || "Error in Labour accounts",
                        callback: (confirmation) => setWarningAlert(false)
                    }} />

                : null}


            <div className="popup-container-main popup-container-center">
                <div className="popup-block-ui"></div>
                <div className="side-popup-container" >
                    <Popup_Header />
                    <div className="sidebar-popup-content">
                        <div className="content-section">
                            <div className="popup-detials-section">
                                <div className="popup-detials-header">
                                    <div className="left">
                                        <div className="title">Overview</div>
                                    </div>
                                </div>
                                <div className="popup-detials-content">
                                    <div className="detials-content-item">
                                        <div className="label">Labour NAME</div>
                                        <div className="value">{Labour_name}</div>
                                    </div>
                                    <div className="detials-content-item">
                                        <div className="label">Opening balance</div>
                                        <div className="value">₹{Opening_balance}</div>
                                    </div>
                                    <div className="detials-content-item">
                                        <div className="label">Total Credit</div>
                                        <div className="value">₹{Total_credit}</div>
                                    </div>
                                    <div className="detials-content-item">
                                        <div className="label">Total Debit</div>
                                        <div className="value">₹{Total_debit}</div>
                                    </div>
                                    <div className="detials-content-item">
                                        <div className="label">Balance Credit</div>
                                        <div className="value">₹{Balance_credit}</div>
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div className="content-section">
                            <div className="popup-detials-section">
                                <div className="popup-detials-header">
                                    <div className="left">
                                        <div className="title">Payment records </div>
                                        <div className="desc"></div>
                                    </div>
                                </div>
                                <div className="popup-detials-content">

                                    <div className="detials-card-sections">

                                        {Payment_records.map((item, index) => (

                                            <div className="detials-card-section">

                                                <div className="detials-card-item detials-card-item-full">
                                                    <div className="label label-dark">Date: </div>
                                                    <div className="value">{item.date}</div>
                                                </div>
                                                <div className="detials-card-item">
                                                    <div className="label label-dark">Payment Type: </div>
                                                    <div className="value">{item.payment_type == '1' ? 'Credit' : 'Debit'}</div>
                                                </div>
                                                <div className="detials-card-item">
                                                    <div className="label label-dark">Amount: </div>
                                                    <div className="value">₹{item.amount || "0"}</div>
                                                </div>
                                                <div className="detials-card-item">
                                                    <div className="label label-dark">Payment By: </div>
                                                    <div className="value">{item.payment_by}</div>
                                                </div>

                                            </div>

                                        ))}
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

const LaboursAccounts = () => {

    const store = useSelector((store) => store)
    const dispatch = useDispatch()
    const { updateState } = new UserAction

    const navigate = useNavigate()

    const labourManagementHandler = new LabourManagementHandler()

    const [isLoading, setIsLoading] = useState(false)
    const [warningAlert, setWarningAlert] = useState(false)
    const [warningAlertType, setWarningAlertType] = useState('warning')
    const [apiFailedMessage, setApiFailedMessage] = useState('Error in Labour accounts')

    const [LabourDetials_original, setLabourDetials_original] = useState([])
    const [LabourDetials, setLabourDetials] = useState([])
    const [selected_termsCondition, setSelected_termsCondition] = useState({})
    const [selectedAction, setSelectedAction] = useState('')

    const { isAdmin, menu_permission } = useMenuAuth({ menu: 'labour_managment--labour_accounts', feature_key: 'labourManagement' })
    const location = useLocation()

    const [selected_view_settings_value, setSelected_view_settings_value] = useState('')
    const [has_view_access, setHas_view_access] = useState(false)
    const [has_add_access, setHas_add_access] = useState(false)
    const [has_edit_access, setHas_edit_access] = useState(false)
    const [has_delete_access, setHas_delete_access] = useState(false)

    useEffect(() => {

        if (isAdmin) {

            setHas_view_access(true)
            setHas_add_access(true)
            setHas_edit_access(true)
            setHas_delete_access(true)
        }

        else if (menu_permission) {

            if (
                !menu_permission._view
                &&
                (!menu_permission.selected_view_settings_value
                    || menu_permission.selected_view_settings_value == 'no_access')
            ) {
                navigate(`${ReverseProxy['proxyUrl']}/quick-menu`, { state: { from: location }, replace: true })
            }

            if (menu_permission._view == '0') navigate(`${ReverseProxy['proxyUrl']}/builder/projects`, { state: { from: location }, replace: true })

            setHas_view_access(menu_permission._view == '1')
            setHas_add_access(menu_permission._create == '1')
            setHas_edit_access(menu_permission._edit == '1')
            setHas_delete_access(menu_permission._delete == '1')

            setSelected_view_settings_value(menu_permission.selected_view_settings_value)

        }

    }, [store])

    const [ActionDropdown, setActionDropdown] = useState([
        {
            id: 'edit',
            label: 'Edit',
            icon: Icons.general.edit,
            click: (row) => {
                setSelectedAction('')
                HandleEditUser(row)
            }
        }
    ])


    const tabelHeader = [
        {
            id: 'name',
            label: 'Name',

        },

        {
            id: 'opening_balance',
            label: 'Op. balance',
            format: (value) => `₹ ${value || "0"}`,

        },
        {
            id: 'total_credit',
            label: 'Total Credit',
            format: (value) => `₹ ${value || "0"}`,

        },
        {
            id: 'total_debit',
            label: 'Total Debit',
            format: (value) => `₹ ${value || "0"}`,

        },
        {
            id: 'balance_credit',
            label: 'Balance Credit',
            format: (value) => `₹ ${value || "0"}`,

        },
        {
            id: 'updated_at',
            label: 'Update At',
            format: (value) => Utils.getLocalFullDateBYFormat(value, '-'),

        },

    ];

    const getLabourDetials = async () => {

        setIsLoading(true)
        let response = await labourManagementHandler.getLabourPaymentsHandler()

        setIsLoading(false)
        if (response && response.success) {


            let data = response.data || []

            data = data.map(d => {
                d.payment_records = d.payment_records || []
                return d
            })

            data = data.filter((itm, i) => itm.opening_balance > 0 || itm.balance_credit > 0 || itm.payment_records.length > 0)

            setLabourDetials_original(response.data)
            setLabourDetials(data)

            HandleSmartCardSetup(data)

        } else {
            setWarningAlert(true)
            setWarningAlertType('error')
            setApiFailedMessage(`Error in Labour accounts, Please try again!`)
        }
    }


    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const HandleAddLabour = () => {
        if (!has_add_access) return
        navigate(`${window.location.pathname}/add-payment-record`)
    }
    const HandleEditUser = (user) => {

        let orginal_labour = LabourDetials_original.filter((itm, i) => itm.id == user.id)[0]
        if (!orginal_labour) return

        setSelected_termsCondition(orginal_labour)

        navigate(`${window.location.pathname}/edit/${orginal_labour.id}`)
    }

    const CloseAddLabourPopup = (type) => {

        let back_path = String(window.location.pathname).split('/')
        back_path.pop()
        if (type == 'edit') back_path.pop()

        back_path = back_path.join('/')

        navigate(`${back_path}`)
    }

    const HanelCellAction = (id, row) => {

        if (!has_view_access) return

        console.log(selectedAction, 'selectedAction');
        if (id == "name") HandleEditUser(row)
        if (id == "actions") {
            setSelectedAction(row.id)
            HandleActionDisable(row.id)
        }
    }

    const HandleActionDisable = (id) => {

        let input = document.getElementById(`labourManagement-tabel-action-btn-${id}`)
        let dropdown_items = document.getElementById(`labourManagement-tabel-action-dropdown-${id}`)

        const Event = (e) => {
            let path = e.path || (e.composedPath && e.composedPath());

            if (Array.isArray(path) && !path.includes(dropdown_items) && !path.includes(input)) {
                setSelectedAction('')
                window.removeEventListener('click', Event)
            }
        }

        window.addEventListener('click', Event)
    }

    useEffect(() => {
        getLabourDetials()
    }, [])

    const [SmarkCard_Items, setSmarkCard_Items] = useState([])

    const HandleSmartCardClick = (id) => { }

    const HandleSmartCardSetup = (items) => {


        let total_detials = items.reduce((acc, itm, i) => {

            acc.total_credit += parseInt(itm.total_credit || 0)
            acc.total_debit += parseInt(itm.total_debit || 0)
            acc.balance_credit += parseInt(itm.balance_credit || 0)

            return acc
        }, {
            total_credit: 0,
            total_debit: 0,
            balance_credit: 0,
        })

        let total_credit = total_detials.total_credit
        let total_debit = total_detials.total_debit
        let balance_credit = total_detials.balance_credit

        let smark_cards = [
            {
                id: 'lm-smart-top-cards-outstanding_balance',
                label: 'Total Outstanding',
                value: balance_credit || "0",
                icon: Icons.general.invoice_total,
                unit_name: '₹',
                style: {
                    color: '#fb993d',
                    bg_linear_colors: {
                        start: '#fff4ea',
                        end: '#fffdf4'
                    },
                    border_color: '#dcd6cd',
                },
            },
            {
                id: 'lm-smart-top-cards-total_credit',
                label: 'Total Credit',
                value: total_credit || "0",
                icon: Icons.general.invoice_total,
                unit_name: '₹',
                style: {
                    color: '#0065ff',
                    bg_linear_colors: {
                        start: '#e5efff',
                        end: '#e5f9ff'
                    },
                    border_color: '#c6d4dc',
                },
            },
            {
                id: 'lm-smart-top-cards-total_debit',
                label: 'Total Debit',
                value: total_debit || "0",
                icon: Icons.general.invoice_total,
                unit_name: '₹',
                style: {
                    color: '#33c87e',
                    bg_linear_colors: {
                        start: '#eaf9f2',
                        end: '#f2fef7'
                    },
                    border_color: '#ccd8d2',
                },
            },
        ]

        setSmarkCard_Items(smark_cards)

    }


    return (
        <>
            {isLoading ?
                <Loading
                    props={{
                        isMainLogo: false,
                        isLabel: true
                    }} />
                : null}
            {warningAlert ?

                <SystemToastPopup
                    props={{
                        type: warningAlertType,
                        message: apiFailedMessage || "Error in Labour accounts",
                        callback: (confirmation) => setWarningAlert(false)
                    }} />

                : null}

            <Routes >
                <Route exact path='/add-payment-record' element={
                    <AddNewPaymentRecordPopup props={{
                        type: "create",
                        heading: "Create Payment Record",
                        callback: getLabourDetials,
                        close: CloseAddLabourPopup
                    }} />}></Route>
                <Route exact path='/edit/:labourId' element={
                    <EditLabourPaymentRecordsPopup props={{
                        type: "edit",
                        heading: "Labour Payment Records",
                        callback: getLabourDetials,
                        close: CloseAddLabourPopup,
                        detials: selected_termsCondition
                    }} />}></Route>
            </Routes>

            <div className="project-labourManagement-main">
                <div className="labourManagement-panel-content">

                    {SmarkCard_Items.length ?
                        <SmarkTopCards props={{
                            id: 'labourManagement-smart-top-cards',
                            cards: SmarkCard_Items || [],
                            callback: HandleSmartCardClick
                        }} />
                        : ''}

                    <div className="smart-panel-content-main">

                        <div className="panel-content-header">
                            <div className="title">Labour Accounts</div>
                            <div className="buttons">
                                {has_add_access ?
                                    <div
                                        className="button add-button"
                                        onClick={(e) => HandleAddLabour()}
                                    >
                                        <div className="icon" dangerouslySetInnerHTML={{ __html: Icons.general.add_btn }}></div>
                                        <div className="label">Add Payment</div>
                                    </div>
                                    : ''}
                            </div>
                        </div>
                        <div className="panel-content-sections panel-content-tabel-section">
                            {LabourDetials.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).length ?
                                <Paper style={{ width: '100%', height: '100%', boxShadow: 'none', overflow: 'hidden' }}>
                                    <TableContainer style={{ height: '93%', boxShadow: 'none !important', }}>
                                        <Table stickyHeader>
                                            <TableHead
                                                className='tabel-header'
                                            >
                                                <TableRow>
                                                    {tabelHeader.map((column) => (
                                                        <TableCell
                                                            key={column.id}
                                                            align={column.align}
                                                            style={{ minWidth: column.minWidth }}
                                                            className='tabel-header-items'
                                                        >
                                                            {column.label}
                                                        </TableCell>
                                                    ))}
                                                </TableRow>
                                            </TableHead>
                                            <TableBody
                                                className='tabel-body'
                                            >
                                                {LabourDetials
                                                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                                    .map((row) => {
                                                        return (
                                                            <TableRow hover role="checkbox" tabIndex={-1} key={row.id}>
                                                                {tabelHeader.map((column) => {
                                                                    const value = row[column.id];
                                                                    return (
                                                                        <TableCell
                                                                            key={column.id}
                                                                            align={column.align}
                                                                            onClick={(e) => {
                                                                                if (column.id != 'actions') HanelCellAction(column.id, row)
                                                                            }}
                                                                        >
                                                                            {
                                                                                column.id == 'name' ?
                                                                                    <div
                                                                                        className="tabel-name"
                                                                                    >
                                                                                        {column.format ? column.format(value) : value}
                                                                                    </div>

                                                                                    : column.id == 'actions' ?

                                                                                        <div
                                                                                            className="tabel-action-main"
                                                                                        >
                                                                                            <div
                                                                                                className="action-icon labourManagement-tabel-action-btn"
                                                                                                id={`labourManagement-tabel-action-btn-${row.id}`}
                                                                                                dangerouslySetInnerHTML={{ __html: Icons.general.dotted_action }}
                                                                                                onClick={(e) => HanelCellAction(column.id, row)}
                                                                                            ></div>
                                                                                            <div
                                                                                                className={`action-dropdown labourManagement-tabel-action-dropdown ${selectedAction == row.id ? 'action-dropdown-active' : ''}`}
                                                                                                id={`labourManagement-tabel-action-dropdown${row.id}`}
                                                                                            >
                                                                                                {ActionDropdown.map(option => (
                                                                                                    <div
                                                                                                        className="action-dropdown-item"
                                                                                                        key={`labourManagement-action${option.id}`}
                                                                                                        onClick={(e) => option.click(row)}
                                                                                                    >
                                                                                                        <div
                                                                                                            className="icon"
                                                                                                            dangerouslySetInnerHTML={{ __html: option.icon }}
                                                                                                        ></div>
                                                                                                        <div className="label">{option.label}</div>
                                                                                                    </div>
                                                                                                ))}
                                                                                            </div>
                                                                                        </div>
                                                                                        : column.format ? column.format(value) : value
                                                                            }
                                                                        </TableCell>
                                                                    );
                                                                })}
                                                            </TableRow>
                                                        );
                                                    })}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                    <TablePagination
                                        style={{ height: '7%', overflow: 'hidden' }}
                                        rowsPerPageOptions={[10, 25, 100]}
                                        component="div"
                                        count={LabourDetials.length || 0}
                                        rowsPerPage={rowsPerPage}
                                        page={page}
                                        onPageChange={handleChangePage}
                                        onRowsPerPageChange={handleChangeRowsPerPage}
                                    />
                                </Paper>
                                : <NoDataFound label={'No Accounts Found'} />}
                        </div>
                    </div>
                </div>

            </div>
        </>
    )
}

export default LaboursAccounts;