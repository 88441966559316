import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom'

/*Redux*/
import { useSelector, useDispatch } from 'react-redux'
import UserAction from '../../redux/action/userAction'
import ReverseProxy from '../../config/reverseProxy'

import BuilderHandler from '../Builder/Builder'
import CompanyHandler from '../Company/Company'

import jwt_decode from 'jwt-decode';

import SystemToastPopup from '../../components/SystemToastPopup'
import Icons from '../../assets/Icons'

const AuthTokenValidation = () => {

    const [isLoading, setIsLoading] = useState(false)
    const [warningAlert, setWarningAlert] = useState(false)
    const [warningAlertType, setWarningAlertType] = useState('warning')
    const [apiFailedMessage, setApiFailedMessage] = useState('Error in Authendication')


    const builderHandler = new BuilderHandler()
    const companyHandler = new CompanyHandler()

    const navigate = useNavigate();

    const store = useSelector((store) => store)
    const dispatch = useDispatch()

    const { updateState } = new UserAction

    const setUserDetials = async () => {

        let response = await builderHandler.getProfileHandler()

        if (response && response.success) {

            let {
                email, id, name, company_features, role_detials, plan_expiry_duration_days, plan_status, user_type, profile_img, company_id, onboarding_step, phone
            } = response.data

            let userdetials = {
                email, id, name, company_id, profile_img, onboarding_step, phone
            }
            localStorage.setItem('userdetials', JSON.stringify(userdetials))

            dispatch(updateState({
                type: "SET_USER",
                payload: {
                    isAuthenticated: true,
                    builder: {
                        ...userdetials,
                        user_type
                    },
                    plan_status: plan_status || 0,
                    plan_expiry_duration_days: plan_expiry_duration_days || 0,
                    roles_permissions: role_detials || {},
                    company_features: company_features || []
                }
            }))

        } else {
            setWarningAlert(true)
            setWarningAlertType('error')
            setApiFailedMessage(`Error in User detials`)
        }

    }
    const setCompanyDetials = async () => {

        let response = await companyHandler.getCompanyProfileHandler()

        if (response && response.success) {

            let { id, builder_id, logo_img, name, phone, status, email, address, website, timezone, social_facebook, social_linkedin, social_instagram, social_twitter, social_googlebusiness, temperature_setting, date_format_setting, language_setting, currency_setting, license_document, license_expiry, insurance_document, insurance_expiry, created_at, updated_at, updated_by } = response.data

            let companydetials = {
                id, builder_id, name, phone, status, email, address, website, timezone, social_facebook, social_linkedin, social_instagram, social_twitter, social_googlebusiness, temperature_setting, date_format_setting, language_setting, currency_setting, license_expiry, insurance_expiry, created_at, updated_at
            }
            localStorage.setItem('companydetials', JSON.stringify(companydetials))

            dispatch(updateState({
                type: "SET_COMPANY",
                payload: { company: response.data }
            }))

        } else {
            setWarningAlert(true)
            setWarningAlertType('error')
            setApiFailedMessage(`Error in Company detials`)
        }

    }

    const HandleEvents = () => {

        const authToken = localStorage.getItem('authToken');
        const userdetials = store.user.builder || {}

        const url = window.location.pathname
        const Auth_Paths = ['/signin', '/signup', '/forget-password', '/verify-reset-password', '/reset-password']

        const checkURLValid = (url) => {
            for (const itm of Auth_Paths) if (url.includes(itm)) return true
            return false
        }

        if (authToken && checkURLValid(url)) {


            navigate(`${ReverseProxy['proxyUrl']}/quick-menu`);
            window.location.reload()

            return

        }

        if (!authToken && checkURLValid(url)) {
            return
        }

        if (!authToken) {
            navigate(`${ReverseProxy['proxyUrl']}/signin`);
            return;
        }

        if (userdetials.user_type == '1' && userdetials?.onboarding_step == 0) return navigate(`${ReverseProxy['proxyUrl']}/onboarding`);
        if (url.includes('/onboarding')) return navigate(`${ReverseProxy['proxyUrl']}/quick-menu`);
        if (!url.split('/').filter(i => i.length > 0).length) {
            navigate(`${ReverseProxy['proxyUrl']}/quick-menu`);
            return window.location.reload()
        }


        setUserDetials()
        setCompanyDetials()

        try {
            const decoded = jwt_decode(authToken);
            const currentTimestamp = Math.floor(Date.now() / 1000);

            if (decoded.exp < currentTimestamp) {

                localStorage.removeItem('authToken');

                navigate(`${ReverseProxy['proxyUrl']}/signin`);
            }
        } catch (error) {
            console.error('Error decoding authToken:', error);
        }
    }

    useEffect(() => {
        HandleEvents()

    }, []);


    useEffect(() => {
        const url = window.location.pathname
        const userdetials = store.user.builder || {}

        if (userdetials?.user_type == '1' && userdetials?.onboarding_step == 0) return navigate(`${ReverseProxy['proxyUrl']}/onboarding`);
        // if (url.includes('/onboarding')) return navigate(`${ReverseProxy['proxyUrl']}/quick-menu`);

    }, [store])
    return (
        <>
            {warningAlert ?

                <SystemToastPopup
                    props={{
                        type: warningAlertType,
                        message: apiFailedMessage || "Error in Authendication",
                        callback: (confirmation) => setWarningAlert(false)
                    }} />
                : null}
        </>
    );
};

export default AuthTokenValidation;