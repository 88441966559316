
import proxyConfig from '../../config/reverseProxy'
import HeaderConfig from '../../config/header'

class LabourProjectAttendance {

    constructor() {

    }


    async updateLabourAttendanceHandler(params) {

        try {

            let response = await fetch(proxyConfig['serverBaseUrl'] + '/builder/projectLabourManagement/update-attendances', {
                method: 'POST',
                headers: HeaderConfig.getHeader({ method: 'POST', isToken: true }),
                body: JSON.stringify(params)
            })

            response = await response.json()

            if (response) return response
        } catch {
            return { success: false }
        }


    }

    async updateLabourAttendancePayrollHandler(params) {

        try {

            let response = await fetch(proxyConfig['serverBaseUrl'] + '/builder/projectLabourManagement/update-attendances-payroll', {
                method: 'POST',
                headers: HeaderConfig.getHeader({ method: 'POST', isToken: true }),
                body: JSON.stringify(params)
            })

            response = await response.json()

            if (response) return response
        } catch {
            return { success: false }
        }


    }


    async deleteLabourAttendanceHandler(params) {

        try {

            let response = await fetch(proxyConfig['serverBaseUrl'] + '/builder/projectLabourManagement/delete-attendances', {
                method: 'POST',
                headers: HeaderConfig.getHeader({ method: 'POST', isToken: true }),
                body: JSON.stringify(params)
            })

            response = await response.json()

            if (response) return response
        } catch {
            return { success: false }
        }


    }

    async getLabourAttendanceHandler(params) {

        try {

            let response = await fetch(proxyConfig['serverBaseUrl'] + '/builder/projectLabourManagement/get-labour-attendances', {
                method: 'POST',
                headers: HeaderConfig.getHeader({ method: 'POST', isToken: true }),
                body: JSON.stringify(params)
            })

            response = await response.json()

            if (response) return response
        } catch {
            return { success: false }
        }


    }



}

export default LabourProjectAttendance;