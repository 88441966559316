
import proxyConfig from '../../config/reverseProxy'
import HeaderConfig from '../../config/header'

class ChangeOrders {

    constructor() {

    }


    async getNextChangeOrdersIDHandler(params) {

        try {

            let response = await fetch(proxyConfig['serverBaseUrl'] + '/builder/change_order/get-next-changeOrderId', {
                method: 'POST',
                headers: HeaderConfig.getHeader({ method: 'POST', isToken: true }),
                body: JSON.stringify(params)
            })

            response = await response.json()

            if (response) return response
        } catch {
            return { success: false }
        }


    }
    async getBasicChangeOrderssHandler(params) {

        try {

            let response = await fetch(proxyConfig['serverBaseUrl'] + '/builder/change_order/get-basic-change_orders', {
                method: 'POST',
                headers: HeaderConfig.getHeader({ method: 'POST', isToken: true }),
                body: JSON.stringify(params)
            })

            response = await response.json()

            if (response) return response
        } catch {
            return { success: false }
        }


    }
    async getChangeOrderssHandler(params) {

        try {

            let response = await fetch(proxyConfig['serverBaseUrl'] + '/builder/change_order/get-change_orders', {
                method: 'POST',
                headers: HeaderConfig.getHeader({ method: 'POST', isToken: true }),
                body: JSON.stringify(params)
            })

            response = await response.json()

            if (response) return response
        } catch {
            return { success: false }
        }


    }
    async createChangeOrderHandler(params) {

        try {

            let response = await fetch(proxyConfig['serverBaseUrl'] + '/builder/change_order/create-change_order', {
                method: 'POST',
                headers: HeaderConfig.getHeader({ method: 'POST', isToken: true }),
                body: JSON.stringify(params)
            })

            response = await response.json()

            if (response) return response
        } catch {
            return { success: false }
        }


    }
    async updateChangeOrderHandler(params) {

        try {

            let response = await fetch(proxyConfig['serverBaseUrl'] + '/builder/change_order/update-change_order', {
                method: 'POST',
                headers: HeaderConfig.getHeader({ method: 'POST', isToken: true }),
                body: JSON.stringify(params)
            })

            response = await response.json()

            if (response) return response
        } catch {
            return { success: false }
        }


    }
    async deleteChangeOrderHandler(params) {

        try {

            let response = await fetch(proxyConfig['serverBaseUrl'] + '/builder/change_order/delete-change_order', {
                method: 'POST',
                headers: HeaderConfig.getHeader({ method: 'POST', isToken: true }),
                body: JSON.stringify(params)
            })

            response = await response.json()

            if (response) return response
        } catch {
            return { success: false }
        }


    }

}

export default ChangeOrders;