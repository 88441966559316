
import proxyConfig from '../../config/reverseProxy'
import HeaderConfig from '../../config/header'

class CashFree_Paymentgateway {

    constructor() {

    }


    async createNewOrderHandler(params) {

        try {

            let response = await fetch(proxyConfig['serverBaseUrl'] + '/paymentgateway/cashfree/create-new-order', {
                method: 'POST',
                headers: HeaderConfig.getHeader({ method: 'POST', isToken: true }),
                body: JSON.stringify(params)
            })

            response = await response.json()

            if (response) return response
        } catch {
            return { success: false }
        }


    }
    async getOrderStatusHandler(params) {

        try {

            let response = await fetch(proxyConfig['serverBaseUrl'] + '/paymentgateway/cashfree/get-order-status', {
                method: 'POST',
                headers: HeaderConfig.getHeader({ method: 'POST', isToken: true }),
                body: JSON.stringify(params)
            })

            response = await response.json()

            if (response) return response
        } catch {
            return { success: false }
        }


    }

    async getPendingSubscriptionsRequest(params) {

        try {

            let response = await fetch(proxyConfig['serverBaseUrl'] + '/paymentgateway/cashfree/get-pending-subscription-request', {
                method: 'POST',
                headers: HeaderConfig.getHeader({ method: 'POST', isToken: true }),
                body: JSON.stringify(params)
            })

            response = await response.json()

            if (response) return response
        } catch {
            return { success: false }
        }


    }

}

export default CashFree_Paymentgateway;