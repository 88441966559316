

import React, { useEffect, useState, useRef } from 'react'
import { Route, Routes, BrowserRouter, Link, useLocation, Navigate, Outlet, useNavigate, useParams } from 'react-router-dom';
import store from '../../redux/store'
import StoreHandler from '../../redux/StoreHandler'
import { useSelector, useDispatch } from 'react-redux'
import UserAction from '../../redux/action/userAction'

import ReverseProxy from '../../config/reverseProxy'
import IssuesManagementHandler from '../../Handlers/IssuesManagement/IssuesManagement';
import CommentsHandler from '../../Handlers/Comments/Comments';

import Icons from '../../assets/Icons'
import Images from '../../assets/Images'
import { CostInput, TextInput, SelectInput, DateTimeInput, RadioInput, MultiSelectInput } from '../../components/Inputs'
import Utils from '../../utils'
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import BuilderAttachments from '../../Handlers/BuilderAttachments/BuilderAttachments';

import ImagePreviewPopup from '../../components/ImagePreviewPopup'

import ShowToClient from '../../components/ShowToClient'
import { useAuth, useMenuAuth } from '../../hooks/useAuth'

const ViewIssuePopup = ({ props }) => {

    const { existItemData, taskdetials, updateCallback } = props

    const { projectId, itemId, issueItemId } = useParams()

    const [isLoading, setIsLoading] = useState(false)
    const [warningAlert, setWarningAlert] = useState(false)
    const [warningAlertType, setWarningAlertType] = useState('warning')
    const [apiFailedMessage, setApiFailedMessage] = useState("Error in Server")

    const issuesManagementHandler = new IssuesManagementHandler()
    const commentsHandler = new CommentsHandler()

    const [currency_type, setCurrency_type] = useState('₹')

    const navigate = useNavigate()
    const store = useSelector((store) => store)
    const dispatch = useDispatch()
    const { updateState } = new UserAction

    const storeHandler = new StoreHandler({
        dataStack: ['team_users', `project_phases-${projectId}`],
        store: store,
        dispatch: dispatch,
        updateState: updateState,
    })

    const LoadStoreData = async () => {
        setIsLoading(true)
        let resolved = await storeHandler.init()
        setIsLoading(false)
    }

    const { isAdmin, menu_permission } = useMenuAuth({ menu: 'project_management--issues', feature_key: 'issue_management' })
    const location = useLocation()

    const [selected_view_settings_value, setSelected_view_settings_value] = useState('')
    const [has_view_access, setHas_view_access] = useState(false)
    const [has_add_access, setHas_add_access] = useState(false)
    const [has_edit_access, setHas_edit_access] = useState(false)
    const [has_delete_access, setHas_delete_access] = useState(false)

    useEffect(() => {

        if (isAdmin) {

            setHas_view_access(true)
            setHas_add_access(true)
            setHas_edit_access(true)
            setHas_delete_access(true)
        }

        else if (menu_permission) {

            if (
                !menu_permission._view
                &&
                (!menu_permission.selected_view_settings_value
                    || menu_permission.selected_view_settings_value == 'no_access')
            ) {
                navigate(`${ReverseProxy['proxyUrl']}/quick-menu`, { state: { from: location }, replace: true })
            }

            if (menu_permission._view == '0') navigate(`${ReverseProxy['proxyUrl']}/builder/projects`, { state: { from: location }, replace: true })

            setHas_view_access(menu_permission._view == '1')
            setHas_add_access(menu_permission._create == '1')
            setHas_edit_access(menu_permission._edit == '1')
            setHas_delete_access(menu_permission._delete == '1')

            setSelected_view_settings_value(menu_permission.selected_view_settings_value)

        }

    }, [store])


    const isExistItemData = existItemData && existItemData.id != undefined

    const [Reason, setReason] = useState('')
    const [DueDate, setDueDate] = useState('')
    const [Assignee_id, setAssignee_id] = useState('')
    const [Assignee_name, setAssignee_name] = useState('')
    const [IssueTag, setIssueTag] = useState('')
    const [Status, setStatus] = useState('0')
    const [ClosedById, setClosedById] = useState('')
    const [ClosedByName, setClosedByName] = useState('')
    const [ClosedAt, setClosedAt] = useState('')
    const [AttachmentsFiles, setAttachmentsFiles] = useState('')

    const [CreatedAt, setCreatedAt] = useState('')

    const [ImportFromId, setImportFromId] = useState('')
    const [ImportFromName, setImportFromName] = useState('')
    const [ImportFromType, setImportFromType] = useState('')

    const [ShowToClientValue, setShowToClientValue] = useState(false)

    const [ProjectPhases, setProjectPhases] = useState([])
    const [ProjectPhases_Options, setProjectPhases_Options] = useState([])
    const [SelectedPhases_Res, setSelectedPhases_Res] = useState([])
    const [SelectedPhases, setSelectedPhases] = useState([])

    const [AssigneeOptions_Orginal, setAssigneeOptions_Orginal] = useState([])
    const [AssigneeOptions, setAssigneeOptions] = useState([])
    const [SelectedAssignees_Res, setSelectedAssignees_Res] = useState([])
    const [SelectedAssignees, setSelectedAssignees] = useState([])

    const HandleCancel = () => {

        if (itemId) navigate(`/builder/projects/${projectId}/task-management/task/${itemId}/edit`)
        else navigate(`/builder/projects/${projectId}/issues-management`)

    }
    const HandleClose = async () => {

        if (!has_edit_access) return
        let updated_data = {
            id: existItemData.id,
            projectId: projectId,

        }

        setIsLoading(true)

        let response = await issuesManagementHandler.closeIssueHandler(updated_data)

        setIsLoading(false)

        if (response.success) {
            if (typeof updateCallback == 'function') updateCallback()
            HandleCancel()
        }
        else {
            setWarningAlert(true)
            setWarningAlertType('error')
            setApiFailedMessage(`Error in closing issue`)
        }

    }
    const HandleEdit = () => {

        if (!has_edit_access) return

        if (itemId) navigate(`/builder/projects/${projectId}/task-management/task/${itemId}/edit/linked-issue/${issueItemId}/edit`)
        else navigate(`/builder/projects/${projectId}/issues-management/${issueItemId}/edit`)
    }
    const HandleDelete = async () => {

        if (!has_edit_access || !has_delete_access) return

        let updated_data = {
            ids: [existItemData.id],
            projectId: projectId
        }

        setIsLoading(true)

        let response = await issuesManagementHandler.deleteIssueHandler(updated_data)

        setIsLoading(false)

        if (response.success) {
            if (typeof updateCallback == 'function') updateCallback()
            HandleCancel()
        }
        else {
            setWarningAlert(true)
            setWarningAlertType('error')
            setApiFailedMessage(`Error in deleting issue`)
        }
    }

    const [CommentsItems, setCommentsItems] = useState([])

    const [CommentsInput, setCommentsInput] = useState("")

    const LoadComments = async ({ project_id, parent_id }) => {

        console.log(project_id, parent_id, 'parent_id');
        if (!project_id || !parent_id) return

        let filter = {
            project_id: project_id,
            parent_id: parent_id
        }

        setIsLoading(true)

        let response = await commentsHandler.getCommentsHandler(filter)
        setIsLoading(false)

        if (response.success) {
            let { data } = response

            if (!Array.isArray(data)) return

            setCommentsItems(data)
        }
    }

    const HandleAddComment = async () => {
        let _CommentsItems = [...CommentsItems]

        let userdetials = JSON.parse(localStorage.getItem('userdetials'))

        _CommentsItems.push({
            id: Utils.getUniqueId(),
            created_at: new Date().getTime(),
            created_by: userdetials.id,
            created_by_name: userdetials.name,
            message: CommentsInput,
            parent_id: existItemData.id,
            parent_name: `Issue ${issueItemId}`,
            parent_type: 0,
            project_id: projectId,
            company_id: userdetials.company_id,
        })

        setCommentsItems(_CommentsItems)
        setCommentsInput("")

        if (!isExistItemData) return

        let update_data = {
            project_id: projectId,
            parent_id: existItemData.id,
            parent_name: `Issue ${issueItemId}`,
            parent_type: String(0),
            message: CommentsInput
        }

        let response = await commentsHandler.createCommentHandler(update_data)

        if (!response.success) {
            setWarningAlert(true)
            setWarningAlertType('error')
            setApiFailedMessage(`Error in creating comment`)
        }
    }

    const HandleDeleteComment = async (id, idx) => {
        let _CommentsItems = [...CommentsItems]

        _CommentsItems.splice(idx, 1)

        setCommentsItems(_CommentsItems)

        if (!isExistItemData) return

        let update_data = {
            project_id: projectId,
            ids: [id]
        }

        let response = await commentsHandler.deleteCommentHandler(update_data)

        if (!response.success) {
            setWarningAlert(true)
            setWarningAlertType('error')
            setApiFailedMessage(`Error in deleting comment`)
        }
    }

    const [ShowSelectedPreviewImage, setShowSelectedPreviewImage] = useState(false)
    const [SelectedPreviewImage, setSelectedPreviewImage] = useState(undefined)

    const HandleSelectPreviewImage = (image) => {
        setShowSelectedPreviewImage(true)
        setSelectedPreviewImage({
            file_url: image.file_url,
            original_filename: image.original_filename
        })
    }
    const HandleHideSelectPreviewImage = (confirmation) => {
        setShowSelectedPreviewImage(confirmation)
        setSelectedPreviewImage(undefined)
    }

    const LoadIssueDetails = async () => {

        let filter = {
            projectId: projectId,
            idx: issueItemId
        }

        setIsLoading(true)

        let response = await issuesManagementHandler.getIssuesHandler(filter)

        setIsLoading(false)

        if (response.success) {

            let data = response.data[0]

            if (!data) return

            setReason(data.reason || "")
            setDueDate(data.due_date || "")

            setIssueTag(data.issue_tag || "")
            setStatus(data.status)

            setClosedById(data.closed_by_id || "")
            setClosedByName(data.closed_by_name || "")
            setClosedAt(data.closed_at || "")

            setCreatedAt(data.created_at || "")

            setImportFromId(data.import_from_id || "")
            setImportFromName(data.import_from_name || "")
            setImportFromType(data.import_from_type || "")

            setAttachmentsFiles(data.attachments || [])
            setShowToClientValue(data.show_to_client == '1' ? true : false)

            setSelectedPhases_Res(data.phases || [])
            setSelectedAssignees_Res(data.assignees || [])

        }
    }

    useEffect(() => {

        if (!existItemData || itemId && (!taskdetials || !Object.keys(taskdetials).length)) {
            navigate(`/builder/projects/${projectId}/task-management/task/${itemId}/edit`)
        }

        LoadStoreData()

        if (existItemData && existItemData.id) {

            setReason(existItemData.reason || "")
            setDueDate(existItemData.due_date || "")

            setIssueTag(existItemData.issue_tag || "")
            setStatus(existItemData.status)

            setClosedById(existItemData.closed_by_id || "")
            setClosedByName(existItemData.closed_by_name || "")
            setClosedAt(existItemData.closed_at || "")

            setCreatedAt(existItemData.created_at || "")

            setImportFromId(existItemData.import_from_id || "")
            setImportFromName(existItemData.import_from_name || "")
            setImportFromType(existItemData.import_from_type || "")

            setAttachmentsFiles(existItemData.attachments || [])
            setShowToClientValue(existItemData.show_to_client == '1' ? true : false)

            LoadComments({ project_id: projectId, parent_id: existItemData.id })

            setSelectedPhases_Res(existItemData.phases || [])
            setSelectedAssignees_Res(existItemData.assignees || [])
        }
        else {
            LoadIssueDetails()
        }


    }, [])
    useEffect(() => {

        if (AssigneeOptions.length && Array.isArray(SelectedAssignees_Res) && SelectedAssignees_Res.length) {

            let select_assignee = SelectedAssignees_Res.map(p => {
                let assignee = AssigneeOptions.filter(ph => ph.value == p)[0]

                if (!assignee) return p
                return assignee
            })

            setSelectedAssignees(select_assignee)

        }

    }, [AssigneeOptions, SelectedAssignees_Res])

    useEffect(() => {

        if (ProjectPhases_Options.length && Array.isArray(SelectedPhases_Res) && SelectedPhases_Res.length) {
            let phases = SelectedPhases_Res || []

            let select_phases = phases.map(p => {
                let phase = ProjectPhases_Options.filter(ph => ph.value == p)[0]

                if (!phase) return p
                return phase
            })

            setSelectedPhases(select_phases)

        }

    }, [ProjectPhases_Options, SelectedPhases_Res])
    useEffect(() => {

        if (store && store.user) {

            let { team_users, selected_project_phases } = store.user


            if (Array.isArray(team_users)) {

                let _team_users = team_users.filter(opt => opt.status == '1').map((opt) => {
                    return {
                        value: opt.id,
                        label: opt.name,
                    }
                })

                setAssigneeOptions(_team_users)
                setAssigneeOptions_Orginal(team_users)
            }
            if (selected_project_phases) {

                setProjectPhases(selected_project_phases)

                let project_phases = selected_project_phases.map(phase => {
                    return {
                        value: phase.id,
                        label: `${phase.name} | ${phase.client_name}`,
                        isDefault: false
                    }
                })

                setProjectPhases_Options(project_phases || [])
            }
        }


    }, [store])

    return (

        <div className="popup-container-main propup-container-center">
            <div className="popup-block-ui"></div>

            {ShowSelectedPreviewImage ?

                <ImagePreviewPopup
                    props={{
                        heading: `Preview - ${SelectedPreviewImage.original_filename}`,
                        image: SelectedPreviewImage.file_url,
                        message: "Invalid Inputs, Please try again with vaild Inputs!",
                        callback: (confirmation) => HandleHideSelectPreviewImage(confirmation)
                    }} />

                : null}
            <div
                style={{ maxWidth: '700px' }}
                className="side-popup-container side-task-popup-container animateXRight animateXRightactive">
                <div className="sidebar-task-popup-content" style={{ height: '100%' }}>

                    <div className="side-popup-header">
                        <div className="header-item-select">
                            <div className="header-item-select-content">
                                <div className="label">Issue</div>
                            </div>
                        </div>
                        <div
                            className="header-item-close"
                            onClick={(e) => HandleCancel()}
                            dangerouslySetInnerHTML={{ __html: Icons.general.close }}
                        ></div>
                    </div>

                    <div className="task-content-update-sections" style={{ height: '100%' }}>

                        <div className="task-content-actions-main">
                            <div className="actions-left"></div>
                            <div className="actions-right">
                                {Status == '0' && has_edit_access ?
                                    <>
                                        <div className="task-label-action-btn" onClick={() => HandleClose()}>
                                            <div className="label">Close Issue</div>
                                        </div>

                                        <div className="task-icon-action-btn action-edit" onClick={() => HandleEdit()}>
                                            <div className="icon"
                                                dangerouslySetInnerHTML={{ __html: Icons.general.edit_btn }}
                                            ></div>
                                        </div>
                                    </>
                                    : ''}

                                {has_edit_access && has_delete_access ?
                                    <div className="task-icon-action-btn action-delete" onClick={() => HandleDelete()}>
                                        <div className="icon"
                                            dangerouslySetInnerHTML={{ __html: Icons.general.delete_btn }}
                                        ></div>
                                    </div>
                                    : ''}
                            </div>
                        </div>

                        <div className="task-content-update-section">

                            <div className="task-update-attendance-inputs">
                                <div className="task-update-attendance-item" style={{ border: 'none', padding: '0', flexWrap: 'wrap' }}>


                                    <div className="attendance-input" style={{ width: '100%' }}>
                                        <div className="label">Reason</div>
                                        <div className="value">{Reason}</div>
                                    </div>
                                    {SelectedPhases.length ?
                                        <div className="attendance-input" style={{ width: '100%' }}>
                                            <MultiSelectInput
                                                props={{
                                                    id: 'issue-multi-select-input',
                                                    placeholder: 'Select Phase',
                                                    isRequired: false,
                                                    value: SelectedPhases,
                                                    options: ProjectPhases_Options,
                                                    isIcon: false,
                                                    avoidDuplicate: true,
                                                    icon: '',
                                                    isLabel: true,
                                                    label: 'Project Phases',
                                                    readOnly: true,
                                                    setValue: (value) => { },
                                                }}
                                            />

                                        </div>
                                        : ''}
                                    {SelectedAssignees.length ?
                                        <div className="attendance-input" style={{ width: '100%' }}>
                                            <MultiSelectInput
                                                props={{
                                                    id: 'assignee-multi-select-input',
                                                    placeholder: 'Select Assignees',
                                                    isRequired: true,
                                                    value: SelectedAssignees,
                                                    options: AssigneeOptions,
                                                    isIcon: false,
                                                    avoidDuplicate: true,
                                                    icon: '',
                                                    isLabel: true,
                                                    label: 'Assignees',
                                                    readOnly: true,
                                                    setValue: (value) => { },
                                                }}
                                            />

                                        </div>
                                        : ''}
                                    <div className="attendance-input" style={{ width: '48%' }}>
                                        <div className="label">VISIBLE TO CLIENT</div>
                                        <div className="value">{ShowToClientValue == '1' ? 'Yes' : 'No'}</div>
                                    </div>
                                    <div className="attendance-input" style={{ width: '48%' }}>
                                        <div className="label">ISSUE TAG</div>
                                        <div className="value">{IssueTag}</div>
                                    </div>
                                    {ImportFromId && ImportFromName ?
                                        <div className="attendance-input" style={{ width: '48%' }}>
                                            <div className="label">Linked To{ImportFromType == '1' ? ' (Task) ' : ''}</div>
                                            <div className="value">{ImportFromName}</div>
                                        </div>
                                        : ''}
                                    <div className="attendance-input" style={{ width: '48%' }}>
                                        <div className="label">CREATED AT</div>
                                        <div className="value">{Utils.getLocalFullDateLabel(CreatedAt)}</div>
                                    </div>
                                    <div className="attendance-input" style={{ width: '48%' }}>
                                        <div className="label">CREATED BY</div>
                                        <div className="value">{Assignee_name || 'N/A'}</div>
                                    </div>
                                    <div className="attendance-input" style={{ width: '48%' }}>
                                        <div className="label">END DATE</div>
                                        <div className="value">{DueDate}</div>
                                    </div>


                                    {Status == '1' ?
                                        <>
                                            <div className="attendance-input" style={{ width: '48%' }}>
                                                <div className="label">CLOSED BY</div>
                                                <div className="value">{ClosedByName}</div>
                                            </div>
                                            <div className="attendance-input" style={{ width: '48%' }}>
                                                <div className="label">CLOSED AT</div>
                                                <div className="value">{Utils.getLocalFullDateLabel(ClosedAt)}</div>
                                            </div>
                                        </>
                                        : ''}

                                </div>

                            </div>
                            <div className="task-update-attendance-inputs">

                                {Array.isArray(AttachmentsFiles) ? AttachmentsFiles.map((attachment, idx) => (

                                    <div className="timeline-progress-detials-img-item"
                                        key={`task-attachment-${idx}`}
                                        onClick={() => HandleSelectPreviewImage(attachment)}
                                    >
                                        <img src={attachment.file_url} />
                                    </div>
                                ))
                                    : ''}

                            </div>

                        </div>

                        <div className="task-content-update-section">
                            <div className="task-update-header">
                                <div className="title">Comments</div>
                            </div>
                            <div className="comments-section-main">
                                <div className="comments-section-items">

                                    {CommentsItems.map((item, idx) => (

                                        <div
                                            className="comments-item-main"
                                            key={`comments-item-${item.id}`}
                                            id={`comments-item-${item.id}`}
                                        >
                                            <div className="comments-item-usericon">
                                                {item.created_by_name.charAt(0)}
                                            </div>
                                            <div className="comments-item-detials">
                                                <div className="detials-head">
                                                    <div className="left">
                                                        <div className="name">{item.created_by_name}</div>
                                                        <div className="time">{Utils.getLocalFullDateLabel(item.created_at)}</div>
                                                    </div>
                                                    <div className="right">
                                                        <div
                                                            className="delete-icon"
                                                            onClick={() => HandleDeleteComment(item.id, idx)}
                                                            dangerouslySetInnerHTML={{ __html: Icons.general.delete_btn }}></div>
                                                    </div>
                                                </div>
                                                <div className="detials-message">{item.message}</div>
                                            </div>
                                        </div>


                                    ))}


                                    <div className="comments-input-main">
                                        <div className="comments-input-usericon">
                                            RT
                                        </div>
                                        <div className="comments-input comments-input-active">
                                            <div className="comments-input-textarea">
                                                <textarea
                                                    placeholder='Add a comment...'
                                                    value={CommentsInput}
                                                    onChange={(e) => setCommentsInput(e.target.value)}
                                                    style={{ minHeight: '50px' }}
                                                ></textarea>
                                            </div>
                                            <div className={`comments-input-controls ${CommentsInput.length ? `comments-input-controls-active` : ''}`}>
                                                <div
                                                    className={`comments-input-control-sendmsg`}
                                                    onClick={() => HandleAddComment()}
                                                >
                                                    <div className="icon" dangerouslySetInnerHTML={{ __html: Icons.general.send_message }}></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    )

}

const AddIssuePopup = ({ props }) => {

    const { existItemData, taskdetials, updateCallback, issueActionType } = props

    const { projectId, itemId, issueItemId } = useParams()

    const [isLoading, setIsLoading] = useState(false)
    const [warningAlert, setWarningAlert] = useState(false)
    const [warningAlertType, setWarningAlertType] = useState('warning')
    const [apiFailedMessage, setApiFailedMessage] = useState("Error in Server")

    const issuesManagementHandler = new IssuesManagementHandler()
    const builderAttachments = new BuilderAttachments()

    const [currency_type, setCurrency_type] = useState('₹')

    const navigate = useNavigate()
    const store = useSelector((store) => store)
    const dispatch = useDispatch()
    const { updateState } = new UserAction

    const storeHandler = new StoreHandler({
        dataStack: ['team_users', `project_phases-${projectId}`],
        store: store,
        dispatch: dispatch,
        updateState: updateState,
    })

    const LoadStoreData = async () => {
        setIsLoading(true)
        let resolved = await storeHandler.init()
        setIsLoading(false)
    }


    const [IssueId, setIssueId] = useState(Utils.getUniqueId())
    const [Reason, setReason] = useState('')
    const [DueDate, setDueDate] = useState('')
    const [Assignee_id, setAssignee_id] = useState('')
    const [Assignee_name, setAssignee_name] = useState('')
    const [IssueTag, setIssueTag] = useState('')

    const [ShowToClientValue, setShowToClientValue] = useState(false)

    const [IssueTagOptions, setIssueTagOptions] = useState([
        {
            value: 'Critical',
            label: 'Critical',
        },
        {
            value: 'RFI',
            label: 'RFI',
        },
        {
            value: 'Blocker',
            label: 'Blocker',
        },
        {
            value: 'Design',
            label: 'Design',
        },
        {
            value: 'Purchase',
            label: 'Purchase',
        },
        {
            value: 'Risk',
            label: 'Risk',
        },
        {
            value: 'Modification',
            label: 'Modification',
        },
        {
            value: 'Highlight',
            label: 'Highlight',
        },
        {
            value: 'RFA',
            label: 'RFA',
        },
        {
            value: 'QA/QC',
            label: 'QA/QC',
        },
        {
            value: 'Budget',
            label: 'Budget',
        },
        {
            value: 'Change Order',
            label: 'Change Order',
        },
    ])
    const [TeamUsersOptions, setTeamUsersOptions] = useState([])
    const [TeamUsersOptions_Orginal, setTeamUsersOptions_Orginal] = useState([])

    const [ProjectPhases, setProjectPhases] = useState([])
    const [ProjectPhases_Options, setProjectPhases_Options] = useState([])
    const [SelectedPhases_Res, setSelectedPhases_Res] = useState([])
    const [SelectedPhases, setSelectedPhases] = useState([])

    const HandleSelectPhase = (phase) => {

        phase = phase.map(p => {
            let phase = ProjectPhases.filter(ph => ph.id == p.id)[0]

            if (!phase) return p

            return phase
        })

        setSelectedPhases(phase)
    }

    const HandleCancel = () => {

        if (itemId) navigate(`/builder/projects/${projectId}/task-management/task/${itemId}/edit`)
        else navigate(`/builder/projects/${projectId}/issues-management`)

    }
    const HandleSave = async (e) => {
        e.preventDefault()

        let phases = SelectedPhases?.map(p => p.value)
        let assignees = SelectedAssignees?.map(p => p.value)

        let updated_data = {
            id: IssueId,
            projectId: projectId,
            reason: Reason,
            due_date: DueDate,

            issue_tag: IssueTag,
            show_to_client: ShowToClientValue ? '1' : '0',
            attachments: AttachmentsFiles || [],
            phases: phases || [],
            assignees: assignees || [],
        }

        if (itemId && taskdetials && taskdetials.id && taskdetials.name) {
            updated_data['import_from_id'] = taskdetials.id
            updated_data['import_from_name'] = taskdetials.name
            updated_data['import_from_type'] = '1'
        }

        setIsLoading(true)

        let response = { success: false }

        if (issueActionType == 'edit') {

            updated_data["id"] = String(issueItemId)
            response = await issuesManagementHandler.updateIssueHandler(updated_data)

        } else response = await issuesManagementHandler.createIssueHandler(updated_data)

        setIsLoading(false)

        if (response.success) {
            if (typeof updateCallback == 'function') updateCallback()
            HandleCancel()
        }
        else {
            setWarningAlert(true)
            setWarningAlertType('error')
            setApiFailedMessage(`Error in creating issue`)
        }

    }

    const HandleDetialsInput = (key, value) => {
        if (key == 'reason') {

            setReason(value)
        }
        else if (key == 'due_date') {

            setDueDate(value)
        }
        else if (key == 'tag') {

            setIssueTag(value)
        }
    }

    const [AssigneeOptions_Orginal, setAssigneeOptions_Orginal] = useState([])
    const [AssigneeOptions, setAssigneeOptions] = useState([])
    const [SelectedAssignees_Res, setSelectedAssignees_Res] = useState([])
    const [SelectedAssignees, setSelectedAssignees] = useState([])

    const HandleSelectAssignee = (users) => {

        users = users.map(p => {
            let users = AssigneeOptions_Orginal.filter(ph => ph.id == p.id)[0]
            if (!users) return p

            return users
        })

        setSelectedAssignees(users)
    }

    const [ShowSelectedPreviewImage, setShowSelectedPreviewImage] = useState(false)
    const [SelectedPreviewImage, setSelectedPreviewImage] = useState(undefined)
    const AttachmentsInputRef = useRef()
    const [AttachmentsFiles, setAttachmentsFiles] = useState([])

    const HandleTriggerAddAttachment = (e) => {
        if (AttachmentsInputRef.current) AttachmentsInputRef.current.click()
    }
    const HandleAddAttachment = async (e) => {

        let file = e.target.files[0]

        if (!file) return

        const formData = new FormData();

        formData.append('projectId', projectId);
        formData.append('attachment', file);
        formData.append('linked_type', '1');
        formData.append('linked_item_id', issueItemId);

        setIsLoading(true)

        let upload_response = await builderAttachments.createAttachmentHandler(formData)

        setIsLoading(false)

        if (upload_response.success) {

            let { public_id, url } = upload_response.data

            if (!public_id || !url) return

            let _AttachmentsFiles = [...AttachmentsFiles]

            _AttachmentsFiles.push({
                public_id: public_id,
                original_filename: file.name,
                bytes: file.size,
                format: file.type,
                file_url: url,
                linked_type: '1',
                linked_item_id: IssueId,
            })

            setAttachmentsFiles(_AttachmentsFiles)

        } else {
            setWarningAlert(true)
            setWarningAlertType('error')
            setApiFailedMessage(`Error in uploading attachment`)
        }
    }
    const HandleDeleteAttachment = async (idx) => {

        let _AttachmentsFiles = [...AttachmentsFiles]

        setIsLoading(true)

        let upload_response = await builderAttachments.deleteAttachmentHandler({
            public_id: _AttachmentsFiles[idx].public_id,
            projectId: projectId,
        })

        setIsLoading(false)

        if (upload_response.success) {
            _AttachmentsFiles.splice(idx, 1)
            setAttachmentsFiles(_AttachmentsFiles)
        }
        else {
            setWarningAlert(true)
            setWarningAlertType('error')
            setApiFailedMessage(`Error in deleting attachment`)
        }
    }

    const HandleSelectPreviewImage = (image) => {
        setShowSelectedPreviewImage(true)
        setSelectedPreviewImage({
            name: image.original_filename,
            file_url: image.file_url,
        })
    }
    const HandleHideSelectPreviewImage = (confirmation) => {
        setShowSelectedPreviewImage(confirmation)
        setSelectedPreviewImage(undefined)
    }

    const LoadIssueDetails = async () => {

        let filter = {
            projectId: projectId,
            idx: issueItemId
        }

        setIsLoading(true)

        let response = await issuesManagementHandler.getIssuesHandler(filter)

        setIsLoading(false)

        if (response.success) {

            let data = response.data[0]

            if (!data) return

            setReason(data.reason || "")
            setDueDate(data.due_date || "")

            setIssueTag(data.issue_tag || "")
            setAttachmentsFiles(data.attachments || [])
            setShowToClientValue(data.show_to_client == '1' ? true : false)

            if (data.phases) {
                let phases = data.phases || []

                let select_phases = phases.map(p => {
                    let phase = ProjectPhases_Options.filter(ph => ph.value == p)[0]

                    if (!phase) return p
                    return phase
                })

                setSelectedPhases(select_phases)
            }

            setSelectedAssignees_Res(data.assignees || [])

        }
    }

    useEffect(() => {

        if (itemId && (!taskdetials || !Object.keys(taskdetials).length)) {
            navigate(`/builder/projects/${projectId}/task-management/task/${itemId}/edit`)
        }


        LoadStoreData()

        if (existItemData && existItemData.id) {

            setReason(existItemData.reason || "")
            setDueDate(existItemData.due_date || "")

            setIssueTag(existItemData.issue_tag || "")
            setAttachmentsFiles(existItemData.attachments || [])
            setShowToClientValue(existItemData.show_to_client == '1' ? true : false)

            setSelectedPhases_Res(existItemData.phases || [])
            setSelectedAssignees_Res(existItemData.assignees || [])
        }
        else {
            LoadIssueDetails()
        }

    }, [])

    useEffect(() => {

        if (AssigneeOptions.length && Array.isArray(SelectedAssignees_Res) && SelectedAssignees_Res.length) {

            let select_assignee = SelectedAssignees_Res.map(p => {
                let assignee = AssigneeOptions.filter(ph => ph.value == p)[0]

                if (!assignee) return p
                return assignee
            })

            setSelectedAssignees(select_assignee)

        }

    }, [AssigneeOptions, SelectedAssignees_Res])


    useEffect(() => {

        if (ProjectPhases_Options.length && Array.isArray(SelectedPhases_Res) && SelectedPhases_Res.length) {
            let phases = SelectedPhases_Res || []

            let select_phases = phases.map(p => {
                let phase = ProjectPhases_Options.filter(ph => ph.value == p)[0]

                if (!phase) return p
                return phase
            })

            setSelectedPhases(select_phases)
        }

    }, [ProjectPhases_Options, SelectedPhases_Res])

    useEffect(() => {

        if (store && store.user) {

            let { team_users, selected_project_phases } = store.user
            if (Array.isArray(team_users)) {

                let _team_users = team_users.map((opt) => {
                    return {
                        value: opt.id,
                        label: opt.name,
                    }
                })

                setAssigneeOptions(_team_users)
                setAssigneeOptions_Orginal(team_users)
            }


            if (selected_project_phases) {

                setProjectPhases(selected_project_phases)

                let project_phases = selected_project_phases.map(phase => {
                    return {
                        value: phase.id,
                        label: `${phase.name} | ${phase.client_name}`,
                        isDefault: false
                    }
                })

                setProjectPhases_Options(project_phases || [])

            }
        }


    }, [store])

    return (

        <div className="popup-container-main propup-container-center">
            <div className="popup-block-ui"></div>


            {ShowSelectedPreviewImage ?

                <ImagePreviewPopup
                    props={{
                        heading: `Preview - ${SelectedPreviewImage.name}`,
                        image: SelectedPreviewImage.file_url,
                        message: "Invalid Inputs, Please try again with vaild Inputs!",
                        callback: (confirmation) => HandleHideSelectPreviewImage(confirmation)
                    }} />

                : null}

            <form
                style={{ maxWidth: '700px' }}
                className="side-popup-container side-task-popup-container animateXRight animateXRightactive" onSubmit={HandleSave}>
                <div className="sidebar-task-popup-content" >

                    <div className="side-popup-header">
                        <div className="header-item-select">
                            <div className="header-item-select-content">
                                <div className="label"> {issueItemId ? 'Edit Issue' : 'Raise Issue'}</div>
                            </div>
                        </div>


                        <div className="header-item-right">
                            <ShowToClient
                                props={{
                                    value: ShowToClientValue,
                                    callback: (value) => setShowToClientValue(value)
                                }}
                            />
                            <div
                                className="header-item-close"
                                onClick={(e) => HandleCancel()}
                                dangerouslySetInnerHTML={{ __html: Icons.general.close }}
                            ></div>
                        </div>

                    </div>

                    <div className="task-content-update-sections" style={{ height: 'calc(100% - 50px)' }}>
                        {itemId ?
                            <div className="task-content-update-section">
                                <div className="task-update-header">
                                    <div className="title">Task Linked</div>
                                </div>
                                <div className="task-update-linked-input">
                                    <input
                                        type="text"
                                        id='task-update-attendance-linked'
                                        value={taskdetials ? taskdetials.name : 'Task name'}
                                        readOnly={true}
                                    />
                                </div>
                            </div>
                            : ''}
                        <div className="task-content-update-section">
                            <div className="task-update-header">
                                <div className="title">Reason</div>
                            </div>
                            <div className="task-update-remark-input">
                                <textarea
                                    placeholder='Enter reason'
                                    onChange={(e) => HandleDetialsInput('reason', e.target.value)}
                                    value={Reason}
                                ></textarea>
                            </div>
                        </div>
                        <div className="task-content-update-section">


                            <div className="task-update-attendance-inputs">
                                <div className="task-update-attendance-item">
                                    <div className="attendance-input">
                                        <label htmlFor="task-update-attendance-tag">Issue Tag</label>
                                        <select
                                            type="text"
                                            placeholder=''
                                            id='task-update-attendance-tag'
                                            value={IssueTag}
                                            onChange={(e) => HandleDetialsInput('tag', e.target.value)}
                                        >
                                            {
                                                [
                                                    {
                                                        value: '',
                                                        label: 'Select type',
                                                    }, ...IssueTagOptions
                                                ]?.map(opt => (
                                                    <option
                                                        key={opt.value}
                                                        value={opt.value}
                                                    >{opt.label}</option>
                                                ))
                                            }
                                        </select>

                                    </div>
                                </div>
                                <div className="task-update-attendance-item">
                                    <div className="attendance-input" style={{ width: '100%' }}>
                                        <MultiSelectInput
                                            props={{
                                                id: 'issue-multi-select-input',
                                                placeholder: 'Select Phase',
                                                isRequired: false,
                                                value: SelectedPhases,
                                                options: ProjectPhases_Options,
                                                isIcon: false,
                                                avoidDuplicate: true,
                                                icon: '',
                                                isLabel: true,
                                                label: 'Project Phases',
                                                setValue: (value) => HandleSelectPhase(value),
                                            }}
                                        />

                                    </div>
                                </div>
                                <div className="task-update-attendance-item">
                                    <div className="attendance-input">
                                        <MultiSelectInput
                                            props={{
                                                id: 'assignee-multi-select-input',
                                                placeholder: 'Select Assignees',
                                                isRequired: true,
                                                value: SelectedAssignees,
                                                options: AssigneeOptions,
                                                isIcon: false,
                                                avoidDuplicate: true,
                                                icon: '',
                                                isLabel: true,
                                                label: 'Assignees',
                                                setValue: (value) => HandleSelectAssignee(value),
                                            }}
                                        />

                                    </div>
                                    <div className="attendance-input">
                                        <label htmlFor="task-update-attendance-date">Due Date</label>
                                        <input
                                            type="date"
                                            id='task-update-attendance-date'
                                            value={DueDate}
                                            required={true}
                                            onChange={(e) => HandleDetialsInput('due_date', e.target.value)}
                                        />

                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="task-content-update-section">
                            <div className="task-update-header">
                                <div className="title">Add Photos (Optional)</div>

                            </div>

                            <div className="attachments-section-main">
                                <div className="attachments-section-files">
                                    <div className="attachment-input" onClick={HandleTriggerAddAttachment}>
                                        <input type="file" onChange={HandleAddAttachment} ref={AttachmentsInputRef} accept='.png,.jpg,.jpeg' />
                                        <div className="attachment-add-icon"
                                            dangerouslySetInnerHTML={{ __html: Icons.general.add_attachments_plus }}
                                        ></div>
                                        <div className="attachment-label">Upload</div>
                                        <div className="attachment-limit">Max limit: 5MB</div>
                                    </div>
                                    {AttachmentsFiles?.map((file, idx) => (
                                        <div
                                            className="attachment-file"
                                            key={file.public_id}
                                            id={`file-${file.public_id}`}
                                        >
                                            <div
                                                className="attachment-delete-btn"
                                                dangerouslySetInnerHTML={{ __html: Icons.general.close }}
                                                onClick={() => HandleDeleteAttachment(idx)}
                                            ></div>
                                            <div className="attachment-file-preview" onClick={() => HandleSelectPreviewImage(file)}>
                                                <img src={file.file_url} alt="attachment-file" />
                                            </div>
                                            <div className="attachment-file-footer">
                                                <div className="file-name">{file.original_filename}</div>
                                                <div
                                                    className="file-download"
                                                    dangerouslySetInnerHTML={{ __html: Icons.general.download }}
                                                ></div>
                                            </div>

                                        </div>
                                    ))}


                                </div>
                            </div>


                        </div>

                    </div>
                </div>

                <div className="sidebar-popup-footer task-sidebar-footer">
                    <div className="footer-item action-items">
                        <div
                            className="action-btn discard-changes-btn"
                            onClick={() => HandleCancel()}

                        >
                            <div className="label">Cancel</div>
                        </div>
                        <button
                            className="action-btn save-changes-btn"
                            type='submit'
                        >
                            <div className="label"> {issueItemId ? 'Update' : 'Raise Issue'}</div>
                        </button>
                    </div>
                </div>
            </form>
        </div>
    )

}

const AddIssueItemPopup = ({ props }) => {

    const { existItemData, taskdetials, updateCallback } = props

    const { projectId, itemId, itemType, issueItemId, issueActionType } = useParams()

    const [isLoading, setIsLoading] = useState(false)
    const [warningAlert, setWarningAlert] = useState(false)
    const [warningAlertType, setWarningAlertType] = useState('warning')
    const [apiFailedMessage, setApiFailedMessage] = useState("Error in Server")

    const [currency_type, setCurrency_type] = useState('₹')

    const navigate = useNavigate()
    const store = useSelector((store) => store)
    const dispatch = useDispatch()
    const { updateState } = new UserAction

    useEffect(() => {

        if (itemId && (!taskdetials || !Object.keys(taskdetials).length)) {
            navigate(`/builder/projects/${projectId}/task-management/task/${itemId}/edit`)
        }

    }, [])

    const { isAdmin, menu_permission } = useMenuAuth({ menu: 'project_management--issues', feature_key: 'issue_management' })
    const location = useLocation()

    const [selected_view_settings_value, setSelected_view_settings_value] = useState('')
    const [has_view_access, setHas_view_access] = useState(false)
    const [has_add_access, setHas_add_access] = useState(false)
    const [has_edit_access, setHas_edit_access] = useState(false)
    const [has_delete_access, setHas_delete_access] = useState(false)

    useEffect(() => {

        if (isAdmin) {

            setHas_view_access(true)
            setHas_add_access(true)
            setHas_edit_access(true)
            setHas_delete_access(true)
        }

        else if (menu_permission) {

            if (
                !menu_permission._view
                &&
                (!menu_permission.selected_view_settings_value
                    || menu_permission.selected_view_settings_value == 'no_access')
            ) {
                navigate(`${ReverseProxy['proxyUrl']}/quick-menu`, { state: { from: location }, replace: true })
            }

            if (issueActionType == 'view' && menu_permission._view == '0') navigate(`${ReverseProxy['proxyUrl']}/builder/projects`, { state: { from: location }, replace: true })
            if (issueActionType == 'add' && menu_permission._create == '0') navigate(`${ReverseProxy['proxyUrl']}/builder/projects`, { state: { from: location }, replace: true })
            if (issueActionType == 'edit' && menu_permission._edit == '0') navigate(`${ReverseProxy['proxyUrl']}/builder/projects`, { state: { from: location }, replace: true })

            setHas_view_access(menu_permission._view == '1')
            setHas_add_access(menu_permission._create == '1')
            setHas_edit_access(menu_permission._edit == '1')
            setHas_delete_access(menu_permission._delete == '1')

            setSelected_view_settings_value(menu_permission.selected_view_settings_value)

        }

    }, [store])

    return (

        <>
            {
                issueActionType == 'add' ? <AddIssuePopup props={{ taskdetials, updateCallback, issueActionType }} />
                    : issueActionType == 'edit' ? <AddIssuePopup props={{ taskdetials, existItemData, updateCallback, issueActionType }} />
                        : issueActionType == 'view' ? <ViewIssuePopup props={{ taskdetials, existItemData, updateCallback, issueActionType }} /> : ''
            }
        </>

    )

}

export default AddIssueItemPopup;