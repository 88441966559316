import React, { useEffect, useState, useRef } from "react";
import {
    Route, Routes, BrowserRouter, Link, useNavigate, useParams, useLocation
} from "react-router-dom";
import store from "../../redux/store";
import StoreHandler from "../../redux/StoreHandler";
import { useSelector, useDispatch } from "react-redux";
import UserAction from "../../redux/action/userAction";

import ReverseProxy from "../../config/reverseProxy";
import WorkRatesHandler from "../../Handlers/WorkRates/WorkRates";
import Icons from "../../assets/Icons";
import Images from "../../assets/Images";
import {
    CostInput, TextInput, SelectInput, SearcBarInput, RadioInput,
} from "../../components/Inputs";
import Utils from "../../utils";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

import AlertPopup from "../../components/AlertPopup";
import Loading from "../../components/Loading";
import NoDataFound from "../../components/NoDataFound";
import SystemToastPopup from '../../components/SystemToastPopup'

import AddWorkRateItemPopup from "./AddWorkRateItemPopup";

import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";

import { useAuth, useMenuAuth } from '../../hooks/useAuth'

const WorkRates = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [warningAlert, setWarningAlert] = useState(false);
    const [warningAlertType, setWarningAlertType] = useState('warning')
    const [apiFailedMessage, setApiFailedMessage] = useState("Error in Server")

    const [deleteItemId, setDeleteItem] = useState(null);
    const [deleteItemType, setDeleteItemType] = useState(null);
    const [deleteItemAlert, setDeleteItemAlert] = useState(false);

    const workRatesHandlerHandler = new WorkRatesHandler();

    const [currency_type, setCurrency_type] = useState("");

    const navigate = useNavigate();
    const store = useSelector((store) => store);
    const dispatch = useDispatch();
    const { updateState } = new UserAction();

    const storeHandler = new StoreHandler({
        dataStack: ["company"],
        store: store,
        dispatch: dispatch,
        updateState: updateState,
    });

    const LoadStoreData = async () => {
        setIsLoading(true);
        let resolved = await storeHandler.init();
        setIsLoading(false);
    };

    const { isAdmin, menu_permission } = useMenuAuth({ menu: 'master_features--work_rates', feature_key: 'work-rates' })
    const location = useLocation()

    const [selected_view_settings_value, setSelected_view_settings_value] = useState('')
    const [has_view_access, setHas_view_access] = useState(false)
    const [has_add_access, setHas_add_access] = useState(false)
    const [has_edit_access, setHas_edit_access] = useState(false)
    const [has_delete_access, setHas_delete_access] = useState(false)

    useEffect(() => {

        if (isAdmin) {

            setHas_view_access(true)
            setHas_add_access(true)
            setHas_edit_access(true)
            setHas_delete_access(true)
        }

        else if (menu_permission) {

            if (
                !menu_permission._view
                &&
                (!menu_permission.selected_view_settings_value
                    || menu_permission.selected_view_settings_value == 'no_access')
            ) {
                navigate(`${ReverseProxy['proxyUrl']}/quick-menu`, { state: { from: location }, replace: true })
            }

            if (menu_permission._view == '0') navigate(`${ReverseProxy['proxyUrl']}/builder/projects`, { state: { from: location }, replace: true })

            setHas_view_access(menu_permission._view == '1')
            setHas_add_access(menu_permission._create == '1')
            setHas_edit_access(menu_permission._edit == '1')
            setHas_delete_access(menu_permission._delete == '1')

            setSelected_view_settings_value(menu_permission.selected_view_settings_value)

        }

    }, [store])

    const [tabelHeader, setTabelHeader] = useState([
        {
            id: "select",
            label: "Select",
            minWidth: 30,
            disable: false,
        },

        {
            id: "name",
            label: "Name",
            minWidth: 200,
            disable: false,
        },

        {
            id: "costcode_name",
            label: "Category",
            disable: false,
        },

        {
            id: "builder_price",
            label: "Prime Cost",
            disable: false,
        },
        {
            id: "markup_amount",
            label: "Markup",
            disable: false,
        },
        {
            id: "tax_amount",
            label: "Tax",
            disable: false,
        },
        {
            id: "price",
            label: "Final Cost",
            disable: false,
        },
        {
            id: "actions",
            label: "Action",
            disable: false,
        },
    ]);
    const [workRateItemsOrginal, setWorkRateItemsOrginal] = useState([]);
    const [workRateItems, setWorkRateItems] = useState([]);
    const [selectedAction, setSelectedAction] = useState("");

    const [tableSelectAll, setTableSelectAll] = useState(false);

    const [existItemData, setExistItemData] = useState({});

    const [ActionDropdown, setActionDropdown] = useState([
        {
            id: "edit",
            label: "Edit",
            icon: Icons.general.edit,
            click: (row) => HandleOpenItem(row),
        },
        {
            id: "delete",
            label: "Delete",
            icon: Icons.general.delete,
            click: (row) => HandleDeleteItem(row),
        },
    ]);

    const AddBtnOptions = [
        {
            icon: Icons.general.group,
            label: "Category",
            type: "group",
            id: "group",
        },

        {
            icon: Icons.general.labour,
            label: "Labour",
            type: "item",
            id: "labour",
        },
        {
            icon: Icons.general.material,
            label: "Materials",
            type: "item",
            id: "material",
        },
        {
            icon: Icons.general.machines,
            label: "Machines",
            type: "item",
            id: "machine",
        },
        {
            icon: Icons.general.subcontractor,
            label: "Subcontractor",
            type: "item",
            id: "subcontractor",
        },
        {
            icon: Icons.general.fee,
            label: "Fee",
            type: "item",
            id: "fee",
        },
        {
            icon: Icons.general.allowance,
            label: "Allowances",
            type: "item",
            id: "allowance",
        },
        {
            icon: Icons.general.work,
            label: "Work",
            type: 'work',
            id: 'work',
        },
    ];

    const HandleOpenItem = (item) => {

        if (!has_edit_access) return
        setExistItemData(item);
        navigate(`/builder/work-rates/${item.sno}/edit`);
    };

    const HanelCellAction = (id, row) => {
        if (id == "actions") {
            setSelectedAction(row.id);
            HandleActionDisable(row.id);
        }
    };
    const HandleActionDisable = (id) => {
        let input = document.getElementById(`estimate-tabel-action-btn-${id}`);
        let dropdown_items = document.getElementById(
            `estimate-tabel-action-dropdown-${id}`
        );

        const Event = (e) => {
            let path = e.path || (e.composedPath && e.composedPath());

            if (
                Array.isArray(path) &&
                !path.includes(dropdown_items) &&
                !path.includes(input)
            ) {
                setSelectedAction("");
                window.removeEventListener("click", Event);
            }
        };

        window.addEventListener("click", Event);
    };

    const HandleSelectAllEvent = (value) => {
        setTableSelectAll(value);

        let _workRateItems = [...workRateItems];

        _workRateItems = _workRateItems.map((item) => {
            item.selected = value;
            return item;
        });

        setWorkRateItems(_workRateItems);
    };

    const HandleSelectEvent = (group_id, parent_id, value) => {
        let _workRateItems = [...workRateItems];

        _workRateItems = _workRateItems.map((item, idx) => {
            if (item.id == group_id && item.parent_id == parent_id)
                item.selected = value;
            return item;
        });

        setWorkRateItems(_workRateItems);
    };

    const getWorkRateItemTypeEenem = (type) => {
        if (type == "1") return "group";
        else if (type == "2") return 'work_template';
        else if (type == "3") return "item";
        else if (type == "4") return 'work';
        else return "";
    };
    const getWorkRateItemCategoryEenem = (type) => {
        if (type == "1") return "material";
        else if (type == "2") return "labour";
        else if (type == "3") return "machine";
        else if (type == "4") return "subcontractor";
        else if (type == "5") return "fee";
        else if (type == "6") return "allowance";
        else return "";
    };
    const getWorkRateItemStatusEenem = (type) => {
        if (type == "0") return "incomplete";
        else if (type == "1") return "completed";
        else if (type == "2") return "hold";
        else if (type == "3") return "stopped";
        else return "";
    };

    const getSubItem = (items, parentid) => {
        let subItems = [];

        let getItems = (items, parentid) => {
            if (!Array.isArray(items)) return;

            let _items = items.filter((itm) => itm.parent_id == parentid);

            subItems.push(..._items);

            _items.forEach((itm) => getItems(items, itm.id));
        };

        getItems(items, parentid);

        return subItems;
    };

    const getFormatedWorkRateItem = (item) => {
        return {
            sno: item.sno,
            expanded: true,
            selected: false,
            disable: false,

            parent_id: item.parent_id,
            parent_name: item.parent_name,
            id: item.id,
            name: item.name,
            description: item.description,

            costcode_id: item.costcode_id || "",
            costcode_name: item.costcode_name || "",

            sor_id: item.sor_id || "",
            sor_name: item.sor_name || "",

            std_per_quandity: parseFloat(item.std_per_quandity || 0),
            std_work_quantity: parseFloat(item.std_work_quantity || 0),

            std_per_unit_id: item.std_per_unit_id,
            std_per_unit_name: item.std_per_unit_name,

            std_work_unit_id: item.std_work_unit_id,
            std_work_unit_name: item.std_work_unit_name,

            co_efficient: parseFloat(item.co_efficient),
            per_co_efficient_cost: parseFloat(item.per_co_efficient_cost),

            type: getWorkRateItemTypeEenem(item.type),
            item_type:
                item.type != "3"
                    ? getWorkRateItemTypeEenem(item.type)
                    : getWorkRateItemCategoryEenem(item.item_type),
            cost_type:
                item.type != "1" && item.type != "3"
                    ? getWorkRateItemTypeEenem(item.type)
                    : getWorkRateItemCategoryEenem(item.item_type),

            parent_type: item.parent_type,
            parent_quantity: parseFloat(item.parent_quantity || 0),

            _status: item.status,
            status: getWorkRateItemStatusEenem(item.status),

            quantity: parseFloat(item.quantity || 0) || 0,
            unitrate: parseFloat(item.unitrate || 0) || 0,
            builder_price: parseFloat(item.builder_price || 0) || 0,
            price: parseFloat(item.price || 0) || 0,

            unit_id: item.unit_id || "",
            unit_name: item.unit_name || "",

            markup_id: item.markup_id || "",
            markup_name: item.markup_name || "",
            markup_value: parseFloat(item.markup_value || 0) || 0,
            markup_value_type: item.markup_value_type || "",
            markup_amount: parseFloat(item.markup_amount || 0) || 0,

            tax_id: item.tax_id || "",
            tax_name: item.tax_name || "",
            tax_value_type: item.tax_value_type || "",
            tax_value: parseFloat(item.tax_value || 0) || 0,
            tax_amount: parseFloat(item.tax_amount || 0) || 0,

            sub_items: [],
        };
    };

    const LoadWorkRateItems = async () => {
        setIsLoading(true);

        let response = await workRatesHandlerHandler.getWorkRatesItemsHandler();
        setIsLoading(false);

        if (response.success) {
            let { data } = response;

            if (!Array.isArray(data)) return;

            let items = data.map((d) => {
                let item = getFormatedWorkRateItem(d);
                let subItems = getSubItem(data, d.id).map((itm) =>
                    getFormatedWorkRateItem(itm)
                );

                let tax_amount = item.tax_amount;
                let markup_amount = item.markup_amount;
                let builder_price = item.builder_price;
                let price = item.price;

                let subItems_Total = subItems.reduce(
                    (prev, val, ind) => {
                        return {
                            tax_amount: prev.tax_amount + val.tax_amount,
                            markup_amount: prev.markup_amount + val.markup_amount,
                            builder_price: prev.builder_price + val.builder_price,
                            price: prev.price + val.price,
                        };
                    },
                    { tax_amount: 0, markup_amount: 0, builder_price: 0, price: 0 }
                );

                tax_amount = parseFloat(
                    tax_amount + (subItems_Total.tax_amount || 0)
                ).toFixed(2);
                markup_amount = parseFloat(
                    markup_amount + (subItems_Total.markup_amount || 0)
                ).toFixed(2);
                builder_price = parseFloat(
                    builder_price + (subItems_Total.builder_price || 0)
                ).toFixed(2);
                price = parseFloat(price + (subItems_Total.price || 0)).toFixed(2);

                if (item.parent_id) {
                    let parent_item = data.filter((d) => d.id == item.parent_id)[0];
                    if (parent_item) {
                        item.parent_type = parent_item.type;
                        if (parent_item.type == "4")
                            item.parent_quantity = parent_item.quantity;
                    }
                }

                item._tax_amount = tax_amount || "";
                item._tax_percent = ((tax_amount / builder_price) * 100 || 0).toFixed(
                    2
                );
                item._markup_amount = markup_amount || "";
                item._markup_percent = (
                    (markup_amount / builder_price) * 100 || 0
                ).toFixed(2);
                item._builder_price = builder_price || "";
                item._price = price || "";
                item._unitrate = item.unitrate || "";
                item._quantity = item.quantity || "";

                item.tax_amount = tax_amount ? `${currency_type}${tax_amount}` : "";
                item.markup_amount = markup_amount
                    ? `${currency_type}${markup_amount}`
                    : "";
                item.builder_price = builder_price
                    ? `${currency_type}${builder_price}`
                    : "";
                item.price = price ? `${currency_type}${price}` : "";
                item.unitrate = item.unitrate
                    ? `${currency_type}${item.unitrate}`
                    : "";
                item.quantity = item.quantity
                    ? `${item.quantity}${item.unit_name || ""}`
                    : "";

                if (item.type == 'work') {
                    let cost_lines =
                        getSubItem(data, d.id).map((itm) =>
                            getFormatedWorkRateItem(itm)
                        ) || [];

                    cost_lines = cost_lines.map((item) => {
                        let type_option = AddBtnOptions.filter((opt) => {
                            if (opt.type != "item" && item.type == opt.type) return true;
                            else if (opt.type == "item" && item.item_type == opt.id)
                                return true;
                            else return false;
                        });

                        type_option = type_option.length ? type_option[0] : undefined;
                        item.type_option = type_option;

                        return item;
                    });

                    item.cost_lines = cost_lines;
                }
                return item;
            })
                .filter((item) => item.type == 'work');

            setWorkRateItemsOrginal(data);
            setWorkRateItems(items);
        } else {
            setWarningAlert(true);
            setWarningAlertType('error')
            setApiFailedMessage(`Error in loading work rates items`);
        }
    };

    useEffect(() => {
        LoadStoreData();
        LoadWorkRateItems();
    }, []);

    useEffect(() => {
        if (store && store.user) {
            let { company, settings } = store.user;

            if (company) {
                let { currency_setting, timezone, temperature_setting } = company;

                if (currency_setting) setCurrency_type(currency_setting);
            }
        }
    }, [store]);

    const HandleUpdateCallback = () => {
        LoadWorkRateItems();
    };


    const CreateWorkRateTableItem = (row, idx) => {
        let { id, disable, type, parent_id, expanded, _status } = row;

        return (
            <>
                {!disable ? (
                    <>
                        <TableRow
                            hover
                            role="checkbox"
                            tabIndex={-1}
                            key={id}
                            className={`estimate-table-row ${type == "group"
                                ? !parent_id
                                    ? "estimate-table-row-group"
                                    : "estimate-table-row-subgroup"
                                : ""
                                }`}
                        >
                            {tabelHeader.map((column) => {
                                const value = row[column.id];
                                return column.disable ? (
                                    ""
                                ) : column.id == "select" ? (
                                    <TableCell
                                        key={column.id}
                                        align={column.align}
                                        className="estimate-table-row-item"
                                        style={{ paddingLeft: "0" }}
                                    >
                                        <RadioInput
                                            props={{
                                                value: row.selected || false,
                                                isIcon: false,
                                                icon: "",
                                                inputType: "checkbox",
                                                name: "table-default-radio",
                                                setValue: (value) =>
                                                    HandleSelectEvent(id, parent_id, value),
                                            }}
                                        />
                                    </TableCell>
                                ) : (
                                    <TableCell
                                        key={column.id}
                                        align={column.align}
                                        style={{ paddingLeft: "0" }}
                                        onClick={(e) => {

                                            if (column.id != "actions")
                                                HanelCellAction(column.id, row);
                                        }}
                                        className="estimate-table-row-item"
                                    >
                                        {column.id == "name" ? (
                                            <div
                                                className="tabel-id"
                                                onClick={(e) => HandleOpenItem(row)}
                                            >
                                                {column.format ? column.format(value) : value}
                                            </div>
                                        )
                                            : column.id == "status" ? (
                                                <div className="tabel-status-main">
                                                    <span
                                                        className={`tabel-status-span status-span-${value}`}
                                                    ></span>
                                                    <div
                                                        style={{ display: "none" }}
                                                        className="tabel-status"
                                                    >
                                                        {column.format ? column.format(value) : value}
                                                    </div>
                                                </div>
                                            ) : column.id == "actions" && has_edit_access ? (
                                                <div className="tabel-action-main">
                                                    <div
                                                        className="action-icon estimate-tabel-action-btn"
                                                        id={`estimate-tabel-action-btn-${row.id}`}
                                                        dangerouslySetInnerHTML={{
                                                            __html: Icons.general.dotted_action,
                                                        }}
                                                        onClick={(e) => HanelCellAction(column.id, row)}
                                                    ></div>
                                                    <div
                                                        className={`action-dropdown estimate-tabel-action-dropdown ${selectedAction == row.id
                                                            ? "action-dropdown-active"
                                                            : ""
                                                            }`}
                                                        id={`estimate-tabel-action-dropdown${row.id}`}
                                                    >
                                                        {ActionDropdown.map(option => (


                                                            !has_edit_access ? '' :
                                                                option.id == 'delete' && !has_delete_access ? '' :

                                                                    <div
                                                                        className="action-dropdown-item"
                                                                        key={`proposals-action${option.id}`}
                                                                        onClick={(e) => {
                                                                            setSelectedAction('')
                                                                            if (option.id == 'edit') HandleOpenItem(row)
                                                                            else if (option.id == 'delete') HandleDeleteItem(row)

                                                                        }}
                                                                    >
                                                                        <div
                                                                            className="icon"
                                                                            dangerouslySetInnerHTML={{ __html: option.icon }}
                                                                        ></div>
                                                                        <div className="label">{option.label}</div>
                                                                    </div>
                                                        ))}
                                                    </div>
                                                </div>
                                            ) : column.format ? (
                                                column.format(value)
                                            ) : (
                                                value
                                            )}
                                    </TableCell>
                                );
                            })}
                        </TableRow>
                    </>
                ) : (
                    ""
                )}
            </>
        );
    };

    const HandleDeleteItem = async (item) => {

        if (!has_edit_access || !has_delete_access) return
        setDeleteItem(item.id);
        setDeleteItemType(item.type);
        setDeleteItemAlert(true);
    };

    const getSubItemIds = (items, parentid) => {
        let subItems = [];

        let getItems = (items, parentid) => {
            if (!Array.isArray(items)) return;

            let _items = items.filter((itm) => itm.parent_id == parentid);

            subItems.push(..._items);

            _items.forEach((itm) => getItems(items, itm.id));
        };

        getItems(items, parentid);

        return subItems.map((itm) => itm.id);
    };

    const HandleDeleteConfirmItem = async (id, type, confirmation) => {
        console.log(confirmation, "confirmation");
        if (!confirmation || !id) {
            setDeleteItem(null);
            setDeleteItemType(null);
            setDeleteItemAlert(false);

            return;
        }

        let ids = getSubItemIds([...workRateItemsOrginal], id);

        let filter = {
            ids: [id, ...ids],
        };

        setIsLoading(true);

        let response = await workRatesHandlerHandler.deleteWorkRatesItemHandler(
            filter
        );
        setIsLoading(false);

        if (response.success) {
            LoadWorkRateItems();
        } else {
            setWarningAlert(true);
            setWarningAlertType('error')
            setApiFailedMessage(`Error in deleting work rates item`);
        }
        setDeleteItem(null);
        setDeleteItemType(null);
        setDeleteItemAlert(false);
    };

    const HandleAddBtn = (e) => {
        if (!has_add_access) return
        navigate(`/builder/work-rates/add`);
    };
    const getDeleteMessage = (type) => {
        if (type == "group")
            return `Are you sure you want to delete the Group? Items included in the group will be deleted accordingly.`;
        else if (type == "work") return `Are you sure you want to delete the Work?`;
        else if (type == "item") return `Are you sure you want to delete the Item?`;
        else return "Are you sure you want to delete?";
    };

    const [Filters, setFilters] = useState({
        left: [
            {
                id: "estimate-multiselect",
                type: "icon",
                isSelectToggle: true,
                value: false,
                placeholder: "",
                callback: (val) => HandleFiltersEvent(val, "estimate-multiselect"),
                isIcon: true,
                icon: Icons.general.multi_select,
                isLabel: false,
                label: "",
                isIconLeft: false,
            },

            {
                id: "estimate-search",
                value: "",
                isSearchBar: true,
                type: "input",
                isDropDownContainer: false,
                placeholder: "Search by name",
                dropDownOptions: [],
                callback: (val) => { },

                isIcon: true,
                icon: Icons.general.search,
                isLabel: false,
                label: "",
                isIconLeft: false,
            },
            {
                id: "estimate-expand",
                value: false,
                type: "icon",
                isSelectToggle: true,
                placeholder: "",
                callback: (val) => { },
                isIcon: true,
                icon: Icons.general.expand,
                isLabel: true,
                label: "Expand all",
                isIconLeft: true,
            },
        ],
        right: [
            {
                id: "estimate-show_hide",
                isSearchBar: false,
                type: "button",
                isDropDownContainer: true,
                dropDownOptions: [
                    {
                        type: "option",
                        label: "Select all",
                        id: "select-all",
                        value: true,
                    },
                    {
                        id: "name",
                        label: "Name",
                        value: true,
                    },
                    {
                        id: "quantity",
                        label: "Quantity",
                        value: true,
                    },
                    {
                        id: "markup_amount",
                        label: "Markup",
                        value: true,
                    },
                    {
                        id: "tax_amount",
                        label: "Tax",
                        value: true,
                    },
                    {
                        id: "price",
                        label: "Price",
                        value: true,
                    },
                ],
                callback: (val) => { },
                isIcon: true,
                icon: Icons.general.show_hide,
                isLabel: false,
                label: "",
                isIconLeft: false,
            },
        ],
    });
    const HandleFiltersEvent = async (value, filter_id) => {
        if (!filter_id) return;

        if (filter_id == "estimate-multiselect") {
            let _tabelHeader = [...tabelHeader];

            _tabelHeader[0].disable = !_tabelHeader[0].disable;

            setTabelHeader(_tabelHeader);
        } else if (filter_id == "estimate-expand") {
            let filters = { ...Filters };

            filters.left[2].value = value;

            let _workRateItems = [...workRateItems];

            let expand_by_group = (items, value) => {
                const expandItem = (items, value) => {
                    items = items.map((item) => {
                        if (item.type != "item") item.expanded = value;
                        if (Array.isArray(item.sub_items))
                            item.sub_items = expandItem(item.sub_items, value);

                        return item;
                    });

                    return items;
                };

                items = expandItem(items, value);

                return items;
            };

            _workRateItems = expand_by_group(_workRateItems, value);
            setWorkRateItems(_workRateItems);

            setFilters(filters);
        } else if (filter_id == "estimate-search") {
            let filters = { ...Filters };

            filters.left[1].value = value;

            let _workRateItems = [...workRateItems];

            let search_items = (items, value, column = "name") => {
                let search_item = (items, value, column = "name") => {
                    items = items.map((item) => {
                        let is_item_match = String(item[column])
                            .toLocaleLowerCase()
                            .includes(String(value).toLocaleLowerCase());

                        if (Array.isArray(item.sub_items))
                            item.sub_items = search_item(item.sub_items, value, column);

                        let is_items_match =
                            item.sub_items.filter((s) => !s.disable).length > 0;

                        if (is_items_match) item.disable = false;
                        else if (is_item_match) item.disable = false;
                        else item.disable = true;

                        return item;
                    });

                    return items;
                };

                items = search_item(items, value, column);
                return items;
            };

            _workRateItems = search_items(_workRateItems, value, "name");

            setWorkRateItems(_workRateItems);

            setFilters(filters);
        } else if (filter_id == "estimate-show_hide") {
            let _tabelHeader = [...tabelHeader];

            if (!value.id) return;

            if (value.id == "select-all") {
            } else {
                let head_idx = _tabelHeader.findIndex((h) => h.id == value.id);
                _tabelHeader[head_idx].disable = !value.value;

                setTabelHeader(_tabelHeader);
            }
        }
    };

    const isLeftFilter = Array.isArray(Filters.left) && Filters.left.length > 0;
    const isRightFilter =
        Array.isArray(Filters.right) && Filters.right.length > 0;

    const Icon_Filter = ({ props }) => {
        const {
            id,
            isSearchBar,
            type,
            value,
            isSelectToggle,
            isDropDownContainer,
            dropDownOptions,
            placeholder,
            callback,
            isIcon,
            icon,
            isLabel,
            label,
            isIconLeft,
        } = props;

        const [iconStatus, setIconStatus] = useState(
            value != undefined ? value : false
        );

        const HandleFilterIconInput = (e) => {
            HandleFiltersEvent(!iconStatus, id);
            setIconStatus(!iconStatus);
        };

        return (
            <>
                {isIcon && type == "icon" ? (
                    <div
                        className="filter-item group-expand-filter"
                        onClick={(e) => HandleFilterIconInput(e)}
                        key={`filter-item-${id}`}
                    >
                        <div
                            className="icon"
                            dangerouslySetInnerHTML={{
                                __html: id.includes("expand")
                                    ? iconStatus
                                        ? Icons.general.collapse
                                        : Icons.general.expand
                                    : icon,
                            }}
                        ></div>
                        {isLabel ? (
                            <div className="label">
                                {id.includes("expand")
                                    ? iconStatus
                                        ? "Collapse all"
                                        : "Expand all"
                                    : label}
                            </div>
                        ) : (
                            ""
                        )}
                    </div>
                ) : null}
            </>
        );
    };
    const Button_Filter = ({ props }) => {
        const {
            id,
            isSearchBar,
            type,
            isDropDownContainer,
            dropDownOptions,
            callback,
            isIcon,
            icon,
            isLabel,
            label,
            isIconLeft,
        } = props;
        const [selectFilterOpts, setSelectFilterOpts] = useState([]);

        const [dropdownStatus, setDropDownStatus] = useState(false);

        const HandleClick = (e) => {
            setDropDownStatus(!dropdownStatus);
        };

        let HandleSubItemChange = (value, opt, i) => {
            let _filters = [...selectFilterOpts];

            if (_filters[i].id == "opt-select-all") {
                _filters = _filters.map((f) => {
                    f.value = value;

                    return f;
                });
            } else _filters[i].value = value;

            setSelectFilterOpts(_filters);

            HandleFiltersEvent(opt, id);
        };

        useEffect(() => {
            let items_parent = document.querySelector(`#${id}`);

            window.addEventListener("click", (e) => {
                let path = e.path || (e.composedPath && e.composedPath());

                if (Array.isArray(path) && !path.includes(items_parent))
                    setDropDownStatus(false);
            });
            setSelectFilterOpts(dropDownOptions);
        }, []);

        return (
            <div
                className="filter-item dropdown-select-filter"
                id={id}
                key={`filter-item-${id}`}
            >
                <div className="button" onClick={(e) => HandleClick(e)}>
                    {isIcon && isIconLeft ? (
                        <span
                            className={`icon ${dropdownStatus ? "icon-active" : ""}`}
                            dangerouslySetInnerHTML={{ __html: icon }}
                        ></span>
                    ) : null}

                    {isLabel ? <span class="label">{label}</span> : null}

                    {isIcon && !isIconLeft ? (
                        <span
                            className={`icon ${dropdownStatus ? "icon-active" : ""}`}
                            dangerouslySetInnerHTML={{ __html: icon }}
                        ></span>
                    ) : null}
                </div>

                {isDropDownContainer ? (
                    <div
                        className={`dropdown-select-items ${dropdownStatus ? "dropdown-select-items-active" : ""
                            }`}
                    >
                        {selectFilterOpts.map((opt, i) =>
                            opt.type == "label" ? (
                                <div className="dropdown-select-title">{opt.label}</div>
                            ) : (
                                <div className="dropdown-select-itm">
                                    <RadioInput
                                        props={{
                                            id: `filters-item-select-${opt.id}`,
                                            value: opt.value,
                                            isIcon: false,
                                            icon: "",
                                            inputType: "checkbox",
                                            name: "table-default-radio",
                                            setValue: (value) => HandleSubItemChange(value, opt, i),
                                        }}
                                    />
                                    <label
                                        className="label"
                                        htmlFor={`filters-item-select-${opt.id}`}
                                    >
                                        {opt.label}
                                    </label>
                                </div>
                            )
                        )}
                    </div>
                ) : null}
            </div>
        );
    };

    return (
        <>
            {isLoading ? (
                <Loading
                    props={{
                        isMainLogo: false,
                        isLabel: true,
                    }}
                />
            ) : null}

            {warningAlert ? (
                <SystemToastPopup
                    props={{
                        type: warningAlertType,
                        message: apiFailedMessage || "Error in Schedule Rates items",
                        callback: (confirmation) => setWarningAlert(false)
                    }} />
            ) : null}

            {deleteItemAlert ? (
                <AlertPopup
                    props={{
                        type: "delete",
                        actionBtnOption: {
                            icon: Icons.general.btn_delete,
                            label: "Yes, Delete",
                        },
                        heading: "Delete item",
                        message: getDeleteMessage(deleteItemType),
                        callback: (confirmation) =>
                            HandleDeleteConfirmItem(
                                deleteItemId,
                                deleteItemType,
                                confirmation
                            ),
                    }}
                />
            ) : null}

            <Routes>
                <Route
                    path="/:itemId/:actionType/*"
                    element={
                        <AddWorkRateItemPopup
                            props={{
                                existItemData: existItemData || undefined,
                                updateCallback: HandleUpdateCallback,
                            }}
                        />
                    }
                ></Route>
                <Route
                    path="/:actionType/*"
                    element={
                        <AddWorkRateItemPopup
                            props={{
                                existItemData: undefined,
                                updateCallback: HandleUpdateCallback,
                            }}
                        />
                    }
                ></Route>
            </Routes>

            <div className="project-project_estimate-main">
                <div className="project_estimate-panel-content">
                    <div className="panel-content-header">
                        <div className="title">Work Rates</div>
                        <div className="buttons">
                            {has_add_access ?
                                <div
                                    className="button add-button"
                                    onClick={(e) => HandleAddBtn(e)}
                                    id="pd_estimate-addbtn"
                                >
                                    <div
                                        className="icon"
                                        dangerouslySetInnerHTML={{ __html: Icons.general.add_btn }}
                                    ></div>
                                    <div className="label">Add Item</div>
                                </div>
                                : ''}
                        </div>
                    </div>
                    <div className="filter-section-main">
                        {isLeftFilter ? (
                            <div className="left-filter-main">
                                {Filters.left.map((item, i) =>
                                    item.type == "icon" ? (
                                        <Icon_Filter props={item} />
                                    ) : item.type == "button" ? (
                                        <Button_Filter props={item} />
                                    ) : item.isSearchBar && item.type == "input" ? (
                                        <SearcBarInput
                                            props={{
                                                id: item.id,
                                                value: item.value,
                                                placeholder: item.placeholder || "Search by name",
                                                setValue: (val) => {
                                                    HandleFiltersEvent(val, item.id);
                                                },
                                                isIcon: item.isIcon,
                                                isLeft: item.isIconLeft,
                                                icon: item.icon || Icons.general.search,
                                            }}
                                        />
                                    ) : (
                                        ""
                                    )
                                )}
                            </div>
                        ) : (
                            ""
                        )}
                        {isRightFilter ? (
                            <div className="right-filter-main">
                                {Filters.right.map((item, i) =>
                                    item.type == "icon" ? (
                                        <Icon_Filter props={item} />
                                    ) : item.type == "button" ? (
                                        <Button_Filter props={item} />
                                    ) : item.isSearchBar && item.type == "input" ? (
                                        <SearcBarInput
                                            props={{
                                                id: item.id,
                                                value: item.value,
                                                placeholder: item.placeholder || "Search by name",
                                                setValue: (val) => {
                                                    HandleFiltersEvent(val, item.id);
                                                },
                                                isIcon: item.isIcon,
                                                isLeft: item.isIconLeft,
                                                icon: item.icon || Icons.general.search,
                                            }}
                                        />
                                    ) : (
                                        ""
                                    )
                                )}
                            </div>
                        ) : (
                            ""
                        )}
                    </div>
                    <div className="panel-content-sections">
                        {workRateItems.length ?
                            <Paper
                                style={{
                                    width: "100%",
                                    height: "100%",
                                    boxShadow: "none",
                                    overflow: "hidden",
                                }}
                            >
                                <TableContainer
                                    style={{ height: "100%", boxShadow: "none !important" }}
                                >
                                    <Table stickyHeader>
                                        <TableHead className="tabel-header estimate-tabel-header">
                                            <TableRow>
                                                {tabelHeader
                                                    .filter((th) => !th.disable)
                                                    .map((column) => (
                                                        <TableCell
                                                            key={column.id}
                                                            align={column.align}
                                                            style={{
                                                                minWidth: column.minWidth,
                                                                paddingLeft: "0",
                                                            }}
                                                            className="tabel-header-items"
                                                        >
                                                            {column.id == "select" ? (
                                                                <RadioInput
                                                                    props={{
                                                                        value: tableSelectAll || false,
                                                                        isIcon: false,
                                                                        icon: "",
                                                                        inputType: "checkbox",
                                                                        name: "table-default-radio",
                                                                        setValue: (value) =>
                                                                            HandleSelectAllEvent(value),
                                                                    }}
                                                                />
                                                            ) : (
                                                                column.label
                                                            )}
                                                        </TableCell>
                                                    ))}
                                            </TableRow>
                                        </TableHead>
                                        <TableBody className='tabel-body'>
                                            {workRateItems.map((row, i) =>
                                                CreateWorkRateTableItem(row, i)
                                            )}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Paper>
                            : <NoDataFound label={'No Items Found'} />}
                    </div>
                </div>
            </div>
        </>
    );
};

export default WorkRates;
