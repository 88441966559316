
import proxyConfig from '../../config/reverseProxy'
import HeaderConfig from '../../config/header'

class MaterialIssue {

    constructor() {

    }


    async getNextIssueIDHandler(params) {

        try {

            let response = await fetch(proxyConfig['serverBaseUrl'] + '/builder/inventory-material-issue/get-next-issue_no', {
                method: 'POST',
                headers: HeaderConfig.getHeader({ method: 'POST', isToken: true }),
                body: JSON.stringify(params)
            })

            response = await response.json()

            if (response) return response
        } catch {
            return { success: false }
        }


    }
    async getBasicMaterialIssuesHandler(params) {

        try {

            let response = await fetch(proxyConfig['serverBaseUrl'] + '/builder/inventory-material-issue/get-basic-issues', {
                method: 'POST',
                headers: HeaderConfig.getHeader({ method: 'POST', isToken: true }),
                body: JSON.stringify(params)
            })

            response = await response.json()

            if (response) return response
        } catch {
            return { success: false }
        }


    }
    async getMaterialIssuesHandler(params) {

        try {

            let response = await fetch(proxyConfig['serverBaseUrl'] + '/builder/inventory-material-issue/get-issues', {
                method: 'POST',
                headers: HeaderConfig.getHeader({ method: 'POST', isToken: true }),
                body: JSON.stringify(params)
            })

            response = await response.json()

            if (response) return response
        } catch {
            return { success: false }
        }


    }
    async getMaterialIssueToTaskHandler(params) {

        try {

            let response = await fetch(proxyConfig['serverBaseUrl'] + '/builder/inventory-material-issue/get-issues-task_linked', {
                method: 'POST',
                headers: HeaderConfig.getHeader({ method: 'POST', isToken: true }),
                body: JSON.stringify(params)
            })

            response = await response.json()

            if (response) return response
        } catch {
            return { success: false }
        }


    }
    async createMaterialIssueHandler(params) {

        try {

            let response = await fetch(proxyConfig['serverBaseUrl'] + '/builder/inventory-material-issue/create-issue', {
                method: 'POST',
                headers: HeaderConfig.getHeader({ method: 'POST', isToken: true }),
                body: JSON.stringify(params)
            })

            response = await response.json()

            if (response) return response
        } catch {
            return { success: false }
        }


    }
    async updateMaterialIssueHandler(params) {

        try {

            let response = await fetch(proxyConfig['serverBaseUrl'] + '/builder/inventory-material-issue/update-issue', {
                method: 'POST',
                headers: HeaderConfig.getHeader({ method: 'POST', isToken: true }),
                body: JSON.stringify(params)
            })

            response = await response.json()

            if (response) return response
        } catch {
            return { success: false }
        }


    }
    async deleteMaterialIssueHandler(params) {

        try {

            let response = await fetch(proxyConfig['serverBaseUrl'] + '/builder/inventory-material-issue/delete-issue', {
                method: 'POST',
                headers: HeaderConfig.getHeader({ method: 'POST', isToken: true }),
                body: JSON.stringify(params)
            })

            response = await response.json()

            if (response) return response
        } catch {
            return { success: false }
        }


    }

}

export default MaterialIssue;