

import React, { useEffect, useState, useRef, useCallback, useMemo, StrictMode } from 'react'
import { Route, Routes, BrowserRouter, Link, useLocation, Navigate, Outlet, useNavigate, useParams } from 'react-router-dom';

import ReverseProxy from '../../config/reverseProxy'
import PurchaseRequestHandler from '../../Handlers/PurchaseRequest/PurchaseRequest';
import CommentsHandler from '../../Handlers/Comments/Comments';

import Icons from '../../assets/Icons'
import Images from '../../assets/Images'
import Utils from '../../utils'

import SystemToastPopup from '../../components/SystemToastPopup'
import Loading from '../../components/Loading'

import ImagePreviewPopup from '../../components/ImagePreviewPopup'
import SignatureCanvasPopup from '../../components/SignatureCanvasPopup'
import CommentBoxPopup from '../../components/CommentBoxPopup'

const WebViewPurchaseRequest = () => {

    const location = useLocation()

    const queryParams = new URLSearchParams(location.search);
    const rfq_id = queryParams.get('rfq_id');
    const ven_id = queryParams.get('ven_id');

    const [isLoading, setIsLoading] = useState(false)
    const [warningAlert, setWarningAlert] = useState(false)
    const [warningAlertType, setWarningAlertType] = useState('warning')
    const [apiFailedMessage, setApiFailedMessage] = useState('Error in Purchase Order')

    const purchaseRequestHandler = new PurchaseRequestHandler()

    const [ShowWatermark, setShowWatermark] = useState(true)
    const [currency_type, setCurrency_type] = useState('₹')

    const [companyImg, setCompanyImg] = useState('')
    const [companyName, setCompanyName] = useState('N/A')
    const [projectId, setProjectId] = useState('N/A')
    const [projectName, setProjectName] = useState('N/A')
    const [projectAddress, setProjectAddress] = useState('N/A')

    const [id, setId] = useState(Utils.getUniqueId())
    const [name, setName] = useState('')
    const [requestID, setRequestID] = useState('')
    const [status, setStatus] = useState('')

    const [vendor_id, setVendorId] = useState('')
    const [vendor_name, setVendorName] = useState('N/A')
    const [vendor_email, setVendorEmail] = useState('N/A')
    const [vendor_gstno, setVendorGSTNo] = useState('N/A')

    const [OpeningDate, setOpeningDate] = useState(Utils._getLocalDate())
    const [DueDate, setDueDate] = useState('')
    const [DeliveryDeadlineDate, setDeliveryDeadlineDate] = useState('')

    const [introduction_text, setIntroduction_text] = useState('sadkhsagdhajsgd')
    const [comments, setComments] = useState('')

    const [termsConditionOptions, setTermsConditionOptions] = useState([])
    const [termsConditionId, setTermsConditionId] = useState("")
    const [termsConditionValue, setTermsConditionValue] = useState("")

    const [materialItems, setMaterialItems] = useState([
        {
            id: Utils.getUniqueId(),
            material_id: "",
            material_name: "",
            unitrate: 0,
            max_unitrate: 0,
            amount: 0,
            unit_id: '',
            unit_name: '',
            quantity: 0,
            description: '',
            notes: ''
        }
    ])

    const [FinalTotal, FinalsetTotal] = useState(0)

    const [ShowSelectedPreviewImage, setShowSelectedPreviewImage] = useState(false)
    const [SelectedPreviewImage, setSelectedPreviewImage] = useState(undefined)
    const AttachmentsInputRef = useRef()
    const [AttachmentsFiles, setAttachmentsFiles] = useState([])

    const HandleSelectPreviewImage = (image) => {
        setShowSelectedPreviewImage(true)
        setSelectedPreviewImage({
            name: image.original_filename,
            file_url: image.file_url,
        })
    }
    const HandleHideSelectPreviewImage = (confirmation) => {
        setShowSelectedPreviewImage(confirmation)
        setSelectedPreviewImage(undefined)
    }
    const HandleChangeMaterialItem = (value, idx, key) => {
        let _materialItems = [...materialItems]

        if (key == 'unitrate') {
            value = Utils.numberOnly(value)

            let quantity = _materialItems[idx].quantity || 0

            _materialItems[idx]['unitrate'] = value
            _materialItems[idx]['amount'] = parseFloat(value) * parseFloat(quantity)
        }

        setMaterialItems(_materialItems)
    }


    useEffect(() => {

        let _FinalTotal = materialItems.reduce((prev, curr) => prev + (curr.amount || 0), 0)

        FinalsetTotal(_FinalTotal)

    }, [materialItems])

    const [ShowSignaturePopup, setShowSignaturePopup] = useState(false)
    const [Signature, setSignature] = useState(undefined)
    const [SubmissionDate, setSubmissionDate] = useState(Utils._getLocalDate())
    const [SubmissionStatus, setSubmissionStatus] = useState(false)

    const HandleOpenSignature = () => {
        setShowSignaturePopup(true)
        setSignature(undefined)
    }
    const HandleCloseSignature = () => {
        setShowSignaturePopup(false)
        setSignature(undefined)
    }
    const HandleSaveSignature = async (signature) => {
        setSignature(signature)
        setShowSignaturePopup(false)
        setSubmissionStatus('1')
        await HandleSaveSubmission(signature)
    }

    const [ShowCommentBoxPopup, setShowCommentBoxPopup] = useState(false)
    const [CommentInput, setCommentInput] = useState('')

    const HandleOpenCommentBoxPopup = () => {
        setShowCommentBoxPopup(true)
        setCommentInput('')
    }
    const HandleCloseCommentBoxPopup = () => {
        setShowCommentBoxPopup(false)
        setCommentInput('')
    }
    const HandleSaveComment = async (comment) => {
        setSignature(comment)
        setShowCommentBoxPopup(false)
        setSubmissionStatus('2')
        await HandleSaveRejection(comment)
    }



    const HandleSubmitPurchaseRequest = async (e) => {
        e.preventDefault()

        HandleOpenSignature()

    }
    const HandleRejectPurchaseRequest = async (e) => {
        HandleOpenCommentBoxPopup()
    }

    const LoadPurchaseRequest = async () => {

        let filter = {
            rfq_id: rfq_id,
            ven_id: ven_id,
        }

        setIsLoading(true)

        let response = await purchaseRequestHandler.getWebViewPurchaseRequestHandler(filter)

        setIsLoading(false)

        if (response.success) {
            let { purchase_request, company_detials, project_detials, vendor_detials, } = response.data


            let {
                id: ID,
                name,
                requestID,
                projectID,
                opening_date,
                due_date,
                delivery_deadline_date,

                status,

                introduction_text,
                comments,
                terms_condition_id,
                terms_condition,
                cost_items,
                attachments,
                submissions = [],
            } = purchase_request



            setId(ID)
            setRequestID(requestID)
            setName(name)
            setStatus(status)
            setIntroduction_text(introduction_text)

            setTermsConditionId(terms_condition_id)
            setTermsConditionValue(terms_condition)
            setComments(comments)

            if (opening_date) setOpeningDate(Utils.getLocalFullDateBYFormat(opening_date))

            if (due_date) setDueDate(Utils.getLocalFullDateBYFormat(due_date))
            if (delivery_deadline_date) setDeliveryDeadlineDate(Utils.getLocalFullDateBYFormat(delivery_deadline_date))


            setAttachmentsFiles(attachments || [])

            if (submissions && submissions.length) {

                let submission = submissions.find(submission => submission.ven_id == ven_id)

                if (submission) {

                    let { ven_id, vendor_id, vendor_name, vendor_email, submission_status, submission_date, cost_items, signature, created_at, updated_at } = submission

                    setSubmissionStatus(submission_status)
                    setSubmissionDate(Utils.getLocalFullDateBYFormat(submission_date))
                    setSignature(signature)
                    setMaterialItems(cost_items || [])

                }
                else setMaterialItems(cost_items || [])
            } else setMaterialItems(cost_items || [])


            if (company_detials) {
                let { name, logo_img, currency_setting, date_format_setting, timezone, temperature_setting } = company_detials

                const profileImgURL = logo_img ? `data:image/png;base64,${logo_img}` : ''

                setCompanyImg(profileImgURL)
                setCompanyName(name || 'N/A')
                if (currency_setting) setCurrency_type(currency_setting)
            }

            if (project_detials) {
                let { projectID, name, delivery_address } = project_detials
                setProjectId(projectID || 'N/A')
                setProjectName(name || 'N/A')
                setProjectAddress(delivery_address || 'N/A')
            }

            if (vendor_detials) {
                let { vendorID, name, email, gstin_no } = vendor_detials
                setVendorId(vendorID || 'N/A')
                setVendorName(name || 'N/A')
                setVendorEmail(email || 'N/A')
                setVendorGSTNo(gstin_no || 'N/A')
            }

        }
        else {
            setWarningAlert(true)
            setWarningAlertType('error')
            setApiFailedMessage(`Invalid Purchase Request link, Please contact support!`)
        }
    }

    const UpdateViewStatus = async () => {
        let filter = {
            rfq_id: rfq_id,
            ven_id: ven_id,
        }

        setIsLoading(true)

        let response = await purchaseRequestHandler.updateWebViewViewStatusHandler(filter)

        setIsLoading(false)

        if (!response.success) {
            setWarningAlert(true)
            setWarningAlertType('error')
            setApiFailedMessage(`Failed to update view status, Please try again!`)
        }

    }

    const HandleSaveSubmission = async (signature) => {
        let payload = {
            rfq_id: rfq_id,
            ven_id: ven_id,
            vendor_id: vendor_id,
            vendor_name: vendor_name,
            vendor_email: vendor_email,

            signature: signature,
            submission_date: SubmissionDate,
            cost_items: materialItems || [],
        }

        setIsLoading(true)

        let response = await purchaseRequestHandler.submitWebViewViewHandler(payload)

        setIsLoading(false)

        if (response.success) {
            setWarningAlert(true)
            setWarningAlertType('success')
            setApiFailedMessage(`Purchase Request submitted successfully!`)
        }
        else {
            setWarningAlert(true)
            setWarningAlertType('error')
            setApiFailedMessage(`Failed to update view status, Please try again!`)
        }

    }
    const HandleSaveRejection = async (comment) => {
        let payload = {
            rfq_id: rfq_id,
            ven_id: ven_id,
            vendor_id: vendor_id,
            vendor_name: vendor_name,
            vendor_email: vendor_email,

            comment: comment,
            submission_date: SubmissionDate,
        }

        setIsLoading(true)

        let response = await purchaseRequestHandler.rejectWebViewViewHandler(payload)

        setIsLoading(false)

        if (response.success) {
            setWarningAlert(true)
            setWarningAlertType('success')
            setApiFailedMessage(`Purchase Request submitted successfully!`)
        }
        else {
            setWarningAlert(true)
            setWarningAlertType('error')
            setApiFailedMessage(`Failed to update view status, Please try again!`)
        }

    }

    useEffect(() => {
        LoadPurchaseRequest()
        UpdateViewStatus()
    }, [])


    return (
        <>


            {isLoading ?
                <Loading
                    props={{
                        isMainLogo: false,
                        isLabel: true
                    }} />
                : null}
            {warningAlert ?

                <SystemToastPopup
                    props={{
                        type: warningAlertType,
                        message: apiFailedMessage || "Error in Purchase Order, Please try again!",
                        callback: (confirmation) => setWarningAlert(false)
                    }} />

                : null}

            {ShowSelectedPreviewImage ?

                <ImagePreviewPopup
                    props={{
                        heading: `Preview - ${SelectedPreviewImage.name}`,
                        image: SelectedPreviewImage.file_url,
                        message: "Invalid Inputs, Please try again with vaild Inputs!",
                        callback: (confirmation) => HandleHideSelectPreviewImage(confirmation)
                    }} />

                : null}
            {ShowSignaturePopup ?

                <SignatureCanvasPopup
                    props={{
                        heading: `To approve the purchase request, please leave your signature`,
                        message: "Invalid Inputs, Please try again with vaild Inputs!",
                        callback: HandleSaveSignature,
                        close: HandleCloseSignature
                    }} />

                : null}
            {ShowCommentBoxPopup ?

                <CommentBoxPopup
                    props={{
                        heading: `Please indicate the reason for rejection`,
                        placeholder: "Enter your comment about the rejection reason",
                        callback: HandleSaveComment,
                        close: HandleCloseCommentBoxPopup
                    }} />

                : null}

            <form className="webview-purchase_request-main" onSubmit={HandleSubmitPurchaseRequest}>
                <div className="webview-purchase_request-content">
                    <div className="content-header-main">
                        <div className="content-header-left">
                            <div className="title">Purchase Request</div>
                            <div className="sub-title">{vendor_name || 'N/A'} - {projectId || 'N/A'} - {requestID || 'N/A'}</div>
                        </div>
                        <div className="content-header-right">
                            {!SubmissionStatus ?
                                <div className="button-group">
                                    <div
                                        className="button-item button-item-reject"
                                        onClick={() => HandleRejectPurchaseRequest()}
                                    >
                                        <div className="icon" dangerouslySetInnerHTML={{ __html: Icons.general.reject }}></div>
                                        <div className="label">Reject</div>
                                    </div>
                                    <button
                                        className="button-item button-item-submit" type='submit'
                                    >
                                        <div className="icon" dangerouslySetInnerHTML={{ __html: Icons.general.approve }}></div>
                                        <div className="label">Submit</div>
                                    </button>
                                </div>
                                : ''}
                        </div>
                    </div>
                    <div className={`content-info_banner-main ${SubmissionStatus == '1' ? `content-info_banner-submitted` : SubmissionStatus == '2' ? 'content-info_banner-rejected' : ''}`}>
                        <div className='info_banner-note'>
                            {SubmissionStatus == '1' ? `You have already submitted the Purchase Request at ${SubmissionDate}`
                                : SubmissionStatus == '2' ? `You have rejected the Purchase Request`
                                    : <>Review and respond to the Bid Request. {DueDate ? <>Deadline: <span className='strong'>{DueDate}</span></> : ''}</>
                            }
                        </div>
                    </div>
                    <div className="content-purchase_request-main">
                        <div className="webview-invoice_template-main" id='webview-invoice_template-main'>

                            <div className="invoice_template-content">

                                <div className="panel-content-sections">

                                    <div className="preview-header-section">
                                        <div className="header-section-top">
                                            <div className="company-detials">
                                                <div className="logo">
                                                    <img src={companyImg || Images.User} />
                                                </div>
                                                <div className="title">{companyName}</div>
                                            </div>
                                            <div className="detials">
                                                <div className="detials-item">
                                                    <div className="label">Project ID</div>
                                                    <div className="value">{projectId}</div>
                                                </div>
                                                <div className="detials-item">
                                                    <div className="label">RFQ ID</div>
                                                    <div className="value">{requestID}</div>
                                                </div>

                                            </div>
                                        </div>
                                        <div className="header-section-detials">
                                            <div className="title">RFQ Information</div>
                                            <div className="detials">
                                                <div className="detials-item">
                                                    <div className="label">RFQ Name</div>
                                                    <div className="value">{name}</div>
                                                </div>
                                                <div className="detials-item">
                                                    <div className="label">Vendor Name</div>
                                                    <div className="value">{vendor_name}</div>
                                                </div>
                                                <div className="detials-item">
                                                    <div className="label">Vendor GST</div>
                                                    <div className="value">{vendor_gstno}</div>
                                                </div>
                                                <div className="detials-item">
                                                    <div className="label">Opening Date</div>
                                                    <div className="value">{OpeningDate}</div>
                                                </div>

                                                <div className="detials-item">
                                                    <div className="label">Deadline</div>
                                                    <div className="value">{DueDate}</div>
                                                </div>
                                                <div className="detials-item">
                                                    <div className="label">Delivery Deadline</div>
                                                    <div className="value">{DeliveryDeadlineDate}</div>
                                                </div>

                                                <div className="detials-item">
                                                    <div className="label">Project Name</div>
                                                    <div className="value">{projectName}</div>
                                                </div>
                                                <div className="detials-item">
                                                    <div className="label">Project Address</div>
                                                    <div className="value">{projectAddress}</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {introduction_text ?
                                        <div
                                            className="create-proposal-section"

                                            id={`create-proposal-terms_conditions`}>

                                            <div className={`createproposal-description-main`}>
                                                <div className="head">
                                                    <div className="icon" dangerouslySetInnerHTML={{ __html: Icons.general.description }}></div>
                                                    <div className="label">Description</div>
                                                </div>
                                                <div className="terms-condition-result" dangerouslySetInnerHTML={{ __html: introduction_text }}></div>
                                            </div>

                                        </div>
                                        : ""}

                                    <div
                                        className="create-client_invoicesection create-proposal-section"
                                        id={`create-client_invoice_payment_history`}

                                    >
                                        <div className={`createproposal-description-main`}>

                                            <div className="head">
                                                <div className="title">
                                                    <div className="label">Materials</div>
                                                </div>
                                            </div>


                                            <div className="preview-costItems-table-main">
                                                <div className="preview-costItems-table-header">
                                                    <div className="header-item table-item-description">Item details</div>
                                                    <div className="header-item table-item-quandity">Quandity</div>
                                                    <div className="header-item table-item-quandity">Max Unit price</div>
                                                    <div className="header-item table-item-unit_price">Unit price</div>
                                                    <div className="header-item table-item-amount">Amount</div>

                                                </div>
                                                <div className="preview-costItems-table-content">

                                                    {materialItems?.map((item, i) => (
                                                        <div
                                                            className="content-row"
                                                            key={`preview-costItems-table-item-${item.id}`}
                                                        >
                                                            <div className="content-row-item table-item-description">
                                                                <div className="title">{i + 1}. {item.material_name} </div>
                                                                {item.description && <div className="desc">{item.description} </div>}
                                                            </div>
                                                            <div className="content-row-item table-item-quandity">{item.quantity} {item.unit_name}</div>
                                                            <div className="content-row-item table-item-quandity">
                                                                {currency_type}{item.max_unitrate || 'N/A'}
                                                            </div>
                                                            <div className="content-row-item table-item-unit_price">
                                                                <div className="label">{currency_type}</div>
                                                                {SubmissionStatus ? item.unitrate :
                                                                    <>
                                                                        <input
                                                                            type='text'
                                                                            className={`input ${item.max_unitrate && parseFloat(item.unitrate) > parseFloat(item.max_unitrate) ? 'input-warning' : ''}`}
                                                                            placeholder='Enter Price'
                                                                            value={item.unitrate}
                                                                            onChange={(e) => HandleChangeMaterialItem(e.target.value, i, 'unitrate')}
                                                                            required={true}
                                                                        />
                                                                        {item.max_unitrate && parseFloat(item.unitrate) > parseFloat(item.max_unitrate) ?
                                                                            <div className="input-warning-icon"
                                                                                dangerouslySetInnerHTML={{ __html: Icons.general.warning }}
                                                                                title='Unit price is greater than max unit price'
                                                                            ></div>
                                                                            : ''}
                                                                    </>
                                                                }

                                                            </div>
                                                            <div className="content-row-item table-item-amount">
                                                                {currency_type}{item.amount || '00'}
                                                            </div>

                                                        </div>
                                                    ))}

                                                </div>

                                            </div>



                                        </div>
                                    </div>

                                    <div className="create-proposal-section" id={`create-proposal-summary`}>
                                        <div className="createproject-section proposal-left-section">
                                            <div className="proposal-summary-totals proposal-summary-title">
                                                <div className="title">Summary</div>
                                            </div>
                                            <div className="proposal-summary-totals proposal-summary-items">

                                                <div className="proposal-summary-item">
                                                    <div className="proposal-summary-item-content">
                                                        <div className="item">
                                                            <div className="title">Total</div>
                                                            <div className="quantity"></div>
                                                            <div className="amount">
                                                                {currency_type}{parseFloat(FinalTotal || 0).toFixed(2)}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="create-client_invoicesection" id={`create-proposal-attachments`}>
                                        <div className={`createproposal-description-main`}>
                                            <div className="head">
                                                <div className="icon" dangerouslySetInnerHTML={{ __html: Icons.general.attachments }}></div>
                                                <div className="label">Attachments</div>
                                            </div>
                                            <div className="attachments-section-main">
                                                <div className="attachments-section-files">

                                                    {AttachmentsFiles?.map((file, idx) => (
                                                        <div
                                                            className="attachment-file"
                                                            key={file.public_id}
                                                            id={`file-${file.public_id}`}
                                                        >
                                                            <div className="attachment-file-preview" onClick={() => HandleSelectPreviewImage(file)}>
                                                                <img src={file.file_url} alt="attachment-file" />
                                                            </div>
                                                            <div className="attachment-file-footer">
                                                                <div className="file-name">{file.original_filename}</div>
                                                                <div
                                                                    className="file-download"
                                                                    dangerouslySetInnerHTML={{ __html: Icons.general.download }}
                                                                ></div>
                                                            </div>

                                                        </div>
                                                    ))}


                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {termsConditionValue ?
                                        <div
                                            className="create-client_invoicesection create-proposal-section"

                                            id={`create-proposal-terms_conditions`}>

                                            <div className={`createproposal-description-main`}>
                                                <div className="head">
                                                    <div className="label">Terms and conditions</div>
                                                </div>
                                                <div className="terms-condition-result" dangerouslySetInnerHTML={{ __html: termsConditionValue }}></div>
                                            </div>

                                        </div>
                                        : ''}
                                    {Signature &&
                                        <div className="section-signature-main">
                                            <div className="signature-title">Submitted</div>
                                            <div className="signature-desc">{vendor_name}</div>
                                            <div className="signature-desc">{SubmissionDate}</div>
                                            <div className="signature-img">
                                                <img src={Signature} alt="" />
                                            </div>
                                        </div>
                                    }
                                    {comments &&
                                        <div className="section-signature-main">
                                            <div className="signature-title signature-rejected">Rejected</div>
                                            <div className="signature-desc">{vendor_name}</div>
                                            <div className="signature-desc">{SubmissionDate}</div>
                                            <div className="signature-desc">{comments}</div>
                                        </div>
                                    }

                                </div>

                            </div>
                        </div>

                        {ShowWatermark ?
                            <div className="webview-invoice_template-watermark">
                                <div className="watermark">
                                    <div className="label">Powered by</div>
                                    <img src={Images.logo} className='img' />
                                </div>
                            </div>
                            : ''}

                    </div>
                </div>
            </form>
        </>
    )

}

export default WebViewPurchaseRequest;