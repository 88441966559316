

import React, { useEffect, useState, useRef } from 'react'
import { Route, Routes, BrowserRouter, Link, useLocation, Navigate, Outlet, useNavigate, useParams } from 'react-router-dom';
import store from '../../redux/store'
import { useSelector, useDispatch } from 'react-redux'
import UserAction from '../../redux/action/userAction'

import ReverseProxy from '../../config/reverseProxy'
import DashboardHandler from '../../Handlers/Dashboard/Dashboard';
import Icons from '../../assets/Icons'
import Images from '../../assets/Images'
import { CostInput, TextInput, SelectInput, RadioInput } from '../../components/Inputs'
import Utils from '../../utils'
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

import AlertPopup from '../../components/AlertPopup'
import SystemToastPopup from '../../components/SystemToastPopup'
import Loading from '../../components/Loading'
import NoDataFound from '../../components/NoDataFound';

import Chart from "react-apexcharts";
import { PieChart, Pie, Sector, Label, Tooltip, Cell, ResponsiveContainer } from 'recharts';

import { useAuth, useMenuAuth } from '../../hooks/useAuth'

const ProjectDashboard = () => {

    const store = useSelector((store) => store)
    const dispatch = useDispatch()
    const { updateState } = new UserAction

    const { projectId } = useParams()

    const navigate = useNavigate()

    const [currency_type, setCurrency_type] = useState('₹')

    const dashboardHandler = new DashboardHandler()

    const [isLoading, setIsLoading] = useState(false)
    const [warningAlert, setWarningAlert] = useState(false)
    const [warningAlertType, setWarningAlertType] = useState('warning')
    const [apiFailedMessage, setApiFailedMessage] = useState('Error in Clients')

    const [ProjectPrice, setProjectPrice] = useState(0)
    const [EstimatedPrice, setEstimatedPrice] = useState(0)
    const [ExpensePrice, setExpensePrice] = useState(0)
    const [Markups, setMarkups] = useState(0)

    const { isAdmin, menu_permission } = useMenuAuth({ menu: 'project_management--project_dashboard', feature_key: 'dashboard' })
    const location = useLocation()

    const [selected_view_settings_value, setSelected_view_settings_value] = useState('')
    const [has_view_access, setHas_view_access] = useState(false)
    const [has_add_access, setHas_add_access] = useState(false)
    const [has_edit_access, setHas_edit_access] = useState(false)
    const [has_delete_access, setHas_delete_access] = useState(false)

    useEffect(() => {

        if (isAdmin) {

            setHas_view_access(true)
            setHas_add_access(true)
            setHas_edit_access(true)
            setHas_delete_access(true)
        }

        else if (menu_permission) {

            if (
                !menu_permission._view
                &&
                (!menu_permission.selected_view_settings_value
                    || menu_permission.selected_view_settings_value == 'no_access')
            ) {
                navigate(`${ReverseProxy['proxyUrl']}/quick-menu`, { state: { from: location }, replace: true })
            }

            if (menu_permission._view == '0') navigate(`${ReverseProxy['proxyUrl']}/builder/projects`, { state: { from: location }, replace: true })

            setHas_view_access(menu_permission._view == '1')
            setHas_add_access(menu_permission._create == '1')
            setHas_edit_access(menu_permission._edit == '1')
            setHas_delete_access(menu_permission._delete == '1')

            setSelected_view_settings_value(menu_permission.selected_view_settings_value)

        }

    }, [store])



    const [DashboardTopCards, setDashboardTopCards] = useState([
        {
            id: 'lm-smart-top-cards-project_price',
            label: 'Pending POs',
            value: "0",
            icon: Icons.general.orders,
            unit_name: '',
            link: 'purchase_order',
            style: {
                color: '#0065ff',
                bg_linear_colors: {
                    start: '#e5efff',
                    end: '#e5f9ff'
                },
                border_color: '#c6d4dc',
            },
        },
        {
            id: 'lm-smart-top-cards-markups',
            label: 'Pending Bills',
            value: "0",
            icon: Icons.general.bills,
            unit_name: '',
            link: 'bills',
            style: {
                color: '#ff5b5b',
                bg_linear_colors: {
                    start: '#ffeded',
                    end: '#fff9f9'
                },
                border_color: '#dcd1d1',
            },
        },
        {
            id: 'lm-smart-top-cards-expense_amount',
            label: 'Pending Change Orders',
            value: "0",
            icon: Icons.general.change_orders,
            unit_name: '',
            link: 'change_orders',
            style: {
                color: '#33c87e',
                bg_linear_colors: {
                    start: '#eaf9f2',
                    end: '#f2fef7'
                },
                border_color: '#ccd8d2',
            },
        },
    ])

    const [DashboardFinanceTotal, setDashboardFinanceTotal] = useState([])
    const [DashboardDonutChart, setDashboardDonutChart] = useState([])

    const [ProjectProgressStatusChart, setProjectProgressStatusChart] = useState([])

    const [TasksPlannedStartDate, setTasksPlannedStartDate] = useState('')
    const [TasksPlannedEndDate, setTasksPlannedEndDate] = useState('')

    const [TasksActualStartDate, setTasksActualStartDate] = useState('')
    const [TasksActualEndDate, setTasksActualEndDate] = useState('')

    const [TasksTotalPrice, setTasksTotalPrice] = useState('0')
    const [TasksCompletedPrice, setTasksCompletedPrice] = useState('0')


    const [ProjectTasksChart, setProjectTasksChart] = useState([])

    const [TotalTasks, setTotalTasks] = useState(0)
    const [UpcomingTasks, setUpcomingTasks] = useState([])
    const [DelayedTasks, setDelayedTasks] = useState([])
    const [VendorPayables, setVendorPayables] = useState([])

    const [FinanceSummaryChart, setFinanceSummaryChart] = useState({
        series: [],
        options: {
            chart: {
                type: 'bar',
                height: 350,
                toolbar: {
                    show: false
                }
            },
            plotOptions: {
                bar: {
                    horizontal: false,
                    columnWidth: '40%',
                    endingShape: 'rounded'
                },
            },
            dataLabels: {
                enabled: false
            },
            stroke: {
                show: true,
                width: 2,
                colors: ['transparent']
            },
            xaxis: {
                categories: ['Client Payment', 'Labour Salary', 'Purchase Order', 'Bills', 'Change Order',],
            },
            yaxis: {
                title: {
                    text: `${currency_type || '₹'} (thousands)`
                }
            },
            fill: {
                opacity: 1,
            },
            tooltip: {
                y: {
                    formatter: function (val) {
                        return `${currency_type || '₹'} ` + Utils.shorternNumber(val || 0)
                    }
                }
            }
        },
    })

    const [InventoryOverviewChart, setInventoryOverviewChart] = useState([])

    const [InventoryUsageChart, setInventoryUsageChart] = useState({
        series: [],
        options: {
            chart: {
                type: 'bar',
                height: 350,
                stacked: true,
                stackType: '100%',
                toolbar: {
                    show: false
                }
            },
            plotOptions: {
                bar: {
                    horizontal: false,
                    columnWidth: '40%',
                    endingShape: 'rounded'
                },
            },
            dataLabels: {
                enabled: false
            },
            stroke: {
                show: true,
                width: 2,
                colors: ['transparent']
            },
            xaxis: {
                categories: [],
            },
            yaxis: {
                title: {
                    text: `Quandity`
                }
            },
            fill: {
                opacity: 1,
            },
            legend: {
                position: 'right',
                offsetX: 0,
                offsetY: 50
            },
            tooltip: {
                y: {
                    formatter: function (val) {
                        return val
                    }
                }
            }
        },
    })

    const [MaterialDelayedDeliveries, setMaterialDelayedDeliveries] = useState([])


    const LoadProjectDashboard = async () => {

        let filters = {
            projectId: projectId

        }
        setIsLoading(true)
        const response = await dashboardHandler.getProjectDashboardHandler(filters)
        setIsLoading(false)

        if (response.success) {

            let {
                topcards_overview,
                finance_overview,
                project_tasks_overview,
                upcoming_tasks,
                delayed_tasks,
                purchase_orders_overview,
                bills_overview,
                client_invoices_overview,
                change_orders_overview,
                attendance_overview,
                vendor_payables,
                delayed_material_deliveries,
                stock_items,
                material_usage
            } = response.data

            if (finance_overview && Object.keys(finance_overview)) {
                let {
                    total_estimated_price,
                    total_expense_price,
                    total_project_price,
                    total_markups_price
                } = finance_overview

                setProjectPrice(parseFloat(total_project_price || 0).toFixed(2))
                setEstimatedPrice(parseFloat(total_estimated_price || 0).toFixed(2))
                setExpensePrice(parseFloat(total_expense_price || 0).toFixed(2))
                setMarkups(parseFloat(total_markups_price || 0).toFixed(2))

                let max_amount = Math.max(total_project_price, total_estimated_price, total_expense_price, total_markups_price)


                let _DashboardDonutChart = [
                    { name: 'Project Price', value: (total_project_price || 0 / max_amount) * 100, fill: '#0088FE' },
                    { name: 'Estimated Price', value: (total_estimated_price || 0 / max_amount) * 100, fill: '#FFBB28' },
                    { name: 'Expense Price', value: (total_expense_price || 0 / max_amount) * 100, fill: '#FF8042' },
                    { name: 'Profit', value: (total_markups_price || 0 / max_amount) * 100, fill: '#00C49F' },
                ]

                setDashboardDonutChart(_DashboardDonutChart)

                setDashboardFinanceTotal(
                    [
                        { name: 'Project Price', value: (total_project_price || 0), fill: '#0088FE' },
                        { name: 'Estimated Price', value: (total_estimated_price || 0), fill: '#FFBB28' },
                        { name: 'Expense Price', value: (total_expense_price || 0), fill: '#FF8042' },
                        { name: 'Profit', value: (total_markups_price || 0), fill: '#00C49F' },
                    ]
                )
            }

            let _DashboardTopCards = [...DashboardTopCards]

            _DashboardTopCards[0].value = topcards_overview?.total_pending_pos || 0
            _DashboardTopCards[1].value = topcards_overview?.total_pending_bills || 0
            _DashboardTopCards[2].value = topcards_overview?.total_pending_changeorders || 0

            setDashboardTopCards(_DashboardTopCards)

            if (delayed_tasks && Array.isArray(delayed_tasks)) setDelayedTasks(delayed_tasks)
            if (upcoming_tasks && Array.isArray(upcoming_tasks)) setUpcomingTasks(upcoming_tasks)
            if (vendor_payables && Array.isArray(vendor_payables)) setVendorPayables(vendor_payables.filter((item) => item.due > 0))
            if (delayed_material_deliveries && Array.isArray(delayed_material_deliveries)) setMaterialDelayedDeliveries(delayed_material_deliveries)

            if (stock_items && Array.isArray(stock_items)) {

                let overrun_items = stock_items.filter(itm => parseFloat(itm.estimated_quantity || 0) < parseFloat(itm.issued_quantity || 0)).length
                let outoff_items = stock_items.filter(itm => parseFloat(itm.balace_quantity || 0) <= 0).length
                let critical_items = stock_items.filter(itm => parseFloat(itm.balace_quantity || 0) <= 10).length

                setInventoryOverviewChart([
                    { name: 'Critical Items', value: critical_items, fill: 'rgb(126, 87, 194)' },
                    { name: 'Out-off Stock Items', value: outoff_items, fill: 'rgb(251, 153, 61)' },
                    { name: 'Over-run Items', value: overrun_items, fill: 'rgb(255, 91, 91)' }
                ])
            }

            if (material_usage && Array.isArray(material_usage)) {

                let _InventoryUsageChart = { ...InventoryUsageChart }

                let _InventoryUsageChart_series = []

                let materials = [...new Set(material_usage.map(item => item.name))]

                let duration_labels = [...new Set(material_usage.map(item => item.date))]


                materials.forEach(material => {

                    let material_usage_data = material_usage.filter(item => item.name == material)

                    let _material_usage_data = duration_labels.map(date => {

                        let _date_data = material_usage_data.filter(item => item.date == date)
                        return _date_data.length ? parseFloat(_date_data[0].quantity || 0) : 0
                    })

                    _InventoryUsageChart_series.push({
                        name: material,
                        data: [..._material_usage_data],
                        color: Utils.getRandomColor()
                    })

                })

                duration_labels = duration_labels.map(date => Utils.formateDateLabel(date, true, false, true))

                _InventoryUsageChart.series = _InventoryUsageChart_series
                _InventoryUsageChart.options.xaxis.categories = duration_labels

                setInventoryUsageChart(_InventoryUsageChart)
            }

            let _FinanceSummaryChart_series = [
                {
                    name: 'Total Amount',
                    data: [
                        parseFloat(client_invoices_overview?.total || 0).toFixed(2),
                        parseFloat(attendance_overview?.total || 0).toFixed(2),
                        parseFloat(purchase_orders_overview?.total || 0).toFixed(2),
                        parseFloat(bills_overview?.total || 0).toFixed(2),
                        parseFloat(change_orders_overview?.total || 0).toFixed(2),
                    ],
                    'color': '#0065ff',
                },
                {
                    name: 'Paid/Received Amount',
                    data: [
                        parseFloat(client_invoices_overview?.paid || 0).toFixed(2),
                        parseFloat(attendance_overview?.paid || 0).toFixed(2),
                        parseFloat(purchase_orders_overview?.paid || 0).toFixed(2),
                        parseFloat(bills_overview?.paid || 0).toFixed(2),
                        parseFloat(change_orders_overview?.paid || 0).toFixed(2),
                    ],
                    'color': '#33c87e'
                },
                {
                    name: 'Pending Amount',
                    data: [
                        parseFloat(client_invoices_overview?.due || 0).toFixed(2),
                        parseFloat(attendance_overview?.due || 0).toFixed(2),
                        parseFloat(purchase_orders_overview?.due || 0).toFixed(2),
                        parseFloat(bills_overview?.due || 0).toFixed(2),
                        parseFloat(change_orders_overview?.due || 0).toFixed(2),
                    ],
                    'color': '#ff5b5b'
                },
            ]

            setFinanceSummaryChart({
                ...FinanceSummaryChart,
                series: _FinanceSummaryChart_series
            })


            if (project_tasks_overview && Object.keys(project_tasks_overview).length) {

                setProjectProgressStatusChart([
                    {
                        name: 'Completed',
                        fill: '#0088FE',
                        value: project_tasks_overview?.total_tasks_progress || 0
                    },
                    {
                        name: 'Total',
                        fill: '#dee2e6',
                        value: 100 - (project_tasks_overview?.total_tasks_progress || 0)
                    },
                ])

                if (project_tasks_overview?.planned_startdate) setTasksPlannedStartDate(Utils._getLocalDate(project_tasks_overview?.planned_startdate) || '')
                if (project_tasks_overview?.planned_enddate) setTasksPlannedEndDate(Utils._getLocalDate(project_tasks_overview?.planned_enddate) || '')

                if (project_tasks_overview?.actual_startdate) setTasksActualStartDate(Utils._getLocalDate(project_tasks_overview?.actual_startdate) || '')
                if (project_tasks_overview?.actual_enddate) setTasksActualEndDate(Utils._getLocalDate(project_tasks_overview?.actual_enddate) || '')

                if (project_tasks_overview?.total_tasks_price) setTasksTotalPrice(parseFloat(project_tasks_overview?.total_tasks_price || 0).toFixed(2) || '0')
                if (project_tasks_overview?.total_tasks_price_completed) setTasksCompletedPrice(parseFloat(project_tasks_overview?.total_tasks_price_completed || 0).toFixed(2) || '0')

                setTotalTasks(project_tasks_overview?.total_tasks || 0)

                setProjectTasksChart([
                    {
                        name: 'Not Started',
                        value: project_tasks_overview.total_tasks_notstarted || 0,
                        fill: '#dee2e6',
                        link: 'task-management'
                    },
                    {
                        name: 'In Progress',
                        value: project_tasks_overview.total_tasks_inprogress || 0,
                        fill: '#ebb840',
                        link: 'task-management'
                    },
                    {
                        name: 'Completed',
                        value: project_tasks_overview.total_tasks_completed || 0,
                        fill: '#68d083',
                        link: 'task-management'
                    },
                    {
                        name: 'Upcoming',
                        value: project_tasks_overview.total_tasks_upcoming || 0,
                        fill: '#8cc7fa',
                        link: 'task-management'
                    },
                    {
                        name: 'Delayed',
                        value: project_tasks_overview.total_tasks_delayed || 0,
                        fill: '#e6642e',
                        link: 'task-management'
                    },
                ])

            }


        } else {
            setWarningAlert(true)
            setWarningAlertType('error')
            setApiFailedMessage('Error in Dashboard Data')
        }
    }

    useEffect(() => {
        LoadProjectDashboard()
    }, [])

    const HandleNavigation = (link) => {

        let url = `${ReverseProxy['proxyUrl']}/builder/projects/${projectId}/${link}`
        return navigate(url)
    }

    return (
        <>

            {isLoading ?
                <Loading
                    props={{
                        isMainLogo: false,
                        isLabel: true
                    }} />
                : null}
            {warningAlert ?

                <SystemToastPopup
                    props={{
                        type: warningAlertType,
                        message: apiFailedMessage || "Error in Clients",
                        callback: (confirmation) => setWarningAlert(false)
                    }} />

                : null}



            <div className="project-dashboard-main">

                <div className="dashboard-container-sections">

                    <div className="dashboard-container-section">
                        <div className="smart-top-cards-main">
                            <div className="smart-top-cards-items">
                                {
                                    DashboardTopCards.map((item, index) => (

                                        <div
                                            className="smart-top-card"
                                            key={item.id}
                                            style={{
                                                color: item.style.color,
                                                borderColor: item.style.border_color,
                                                backgroundImage: `linear-gradient(to right, ${item.style.bg_linear_colors.start}, ${item.style.bg_linear_colors.end})`
                                            }}
                                            onClick={() => HandleNavigation(item.link)}
                                        >

                                            <div className="card-detials">
                                                <div className="card-label">{item.label}</div>
                                                <div className="card-value">{item.unit_name}{item.value}</div>
                                            </div>
                                            <div
                                                className="card-icon"
                                                style={{ fill: item.style.color }}
                                                dangerouslySetInnerHTML={{ __html: item.icon || Icons.general.invoice_total }}
                                            ></div>

                                        </div>


                                    ))
                                }


                            </div>

                        </div>
                    </div>

                    <div className="dashboard-container-section">

                        <div className="dashboard-multicomponent-items">
                            <div className="dashboard-donutchart-main">

                                <div className="dashboard-card-header">
                                    <div className="title">Overview</div>
                                    <div className="filter-button">
                                        <div
                                            className="icon"
                                            dangerouslySetInnerHTML={{ __html: Icons.general.filter_icon }}
                                        ></div>
                                    </div>
                                </div>

                                {DashboardDonutChart.length ?
                                    <>

                                        <div className="dashboard-donutchart-chart">
                                            <PieChart width={170} height={170}>

                                                <Pie
                                                    data={DashboardDonutChart}
                                                    cx={80}
                                                    cy={80}
                                                    innerRadius={60}
                                                    outerRadius={80}
                                                    isAnimationActive={true}
                                                    label={false}
                                                    fill="#8884d8"
                                                    paddingAngle={0}
                                                    dataKey="value"
                                                >

                                                    {DashboardDonutChart.map((entry, index) => (
                                                        <Cell key={`cell-${index}`} fill={entry.fill} />
                                                    ))}
                                                </Pie>
                                                <Tooltip
                                                    formatter={(value) => Utils.shorternNumber(value / 100)}
                                                />
                                            </PieChart>

                                        </div>
                                        <div className="dashboard-donutchart-detials">

                                            {
                                                DashboardFinanceTotal?.map((item, index) => (


                                                    <div
                                                        className="donutchart-detials-item"
                                                        key={`donutchart-detials-item-${index}`}
                                                    >
                                                        <div className="label-main">
                                                            <div
                                                                className="icon"
                                                                style={{ background: item.fill }}
                                                            ></div>
                                                            <div className="label">{item.name}</div>
                                                        </div>
                                                        <div className="value">{currency_type || '₹'}{Utils.shorternNumber(item.value || 0)}</div>
                                                    </div>

                                                ))
                                            }
                                        </div>
                                    </>
                                    : <NoDataFound label={'No Overview Found'} />}
                            </div>
                            <div className="dashboard-statusmeter-main">

                                <div className="dashboard-card-header">
                                    <div className="title">Project Task Status</div>
                                    <div className="filter-button">
                                        <div
                                            className="icon"
                                            dangerouslySetInnerHTML={{ __html: Icons.general.filter_icon }}
                                        ></div>
                                    </div>
                                </div>

                                {ProjectProgressStatusChart.length ?
                                    <>
                                        <div className="dashboard-statusmeter-progress">
                                            <div className="dashboard-statusmeter-chart">

                                                <PieChart width={170} height={90}>

                                                    <Pie
                                                        data={ProjectProgressStatusChart}
                                                        cx={80}
                                                        cy={80}
                                                        startAngle={180}
                                                        endAngle={0}
                                                        innerRadius={60}
                                                        outerRadius={80}
                                                        fill="#8884d8"
                                                        paddingAngle={0}
                                                        dataKey="value"
                                                    >
                                                        <Label style={{ fontSize: '24px', color: 'black', fontWeight: 'bold' }} position="centerBottom" >
                                                            {`${parseInt(ProjectProgressStatusChart[0]?.value || 0)}%`}
                                                        </Label>

                                                        {ProjectProgressStatusChart.map((entry, index) => (
                                                            <Cell key={`cell-progress-${index}`} fill={entry.fill} />
                                                        ))}
                                                    </Pie>
                                                </PieChart>



                                            </div>

                                            <div className="dashboard-statusmeter-detials">
                                                <div className="statusmeter-progress-detials-item">
                                                    <div className="label">Planned Start Date</div>
                                                    <div className="value">{TasksPlannedStartDate ? Utils.formateDateLabel(TasksPlannedStartDate, true, false, true) : 'N/A'}</div>
                                                </div>
                                                <div className="statusmeter-progress-detials-item">
                                                    <div className="label">Planned End Date</div>
                                                    <div className="value">{TasksPlannedEndDate ? Utils.formateDateLabel(TasksPlannedEndDate, true, false, true) : 'N/A'}</div>
                                                </div>
                                                <div className="statusmeter-progress-detials-item">
                                                    <div className="label">Total Price Value</div>
                                                    <div className="value">{currency_type || '₹'}{TasksTotalPrice}</div>
                                                </div>
                                                <div className="statusmeter-progress-detials-item">
                                                    <div className="label">Actual Start Date</div>
                                                    <div className="value">{TasksActualStartDate ? Utils.formateDateLabel(TasksActualStartDate, true, false, true) : 'N/A'}</div>
                                                </div>
                                                <div className="statusmeter-progress-detials-item">
                                                    <div className="label">Actual End Date</div>
                                                    <div className="value">{TasksActualEndDate ? Utils.formateDateLabel(TasksActualEndDate, true, false, true) : 'N/A'}</div>
                                                </div>

                                                <div className="statusmeter-progress-detials-item">
                                                    <div className="label">Total Earned Value</div>
                                                    <div className="value">{currency_type || '₹'}{TasksCompletedPrice}</div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="dashboard-statusmeter-progress">
                                            <div className="dashboard-statusmeter-chart">

                                                <PieChart width={170} height={90}>

                                                    <Pie
                                                        data={ProjectTasksChart}
                                                        cx={80}
                                                        cy={80}
                                                        startAngle={180}
                                                        endAngle={0}
                                                        innerRadius={60}
                                                        outerRadius={80}
                                                        fill="#8884d8"
                                                        paddingAngle={0}
                                                        dataKey="value"
                                                    >
                                                        <Label style={{ fontSize: '24px', color: 'black', fontWeight: 'bold' }} position="centerBottom" >
                                                            {`${TotalTasks}`}
                                                        </Label>
                                                        {ProjectTasksChart.map((entry, index) => (
                                                            <Cell key={`cell-statusmeter-${index}`} fill={entry.fill} />
                                                        ))}
                                                    </Pie>
                                                </PieChart>



                                            </div>

                                            <div className="dashboard-statusmeter-detials">
                                                {
                                                    ProjectTasksChart.map((item, index) => (

                                                        <div
                                                            className="statusmeter-task-item"
                                                            key={`statusmeter-task-item-${index}`}
                                                            style={{ borderColor: item.fill, background: Utils.getLightBgFromColor(item.fill, 0.1) }}
                                                            onClick={() => HandleNavigation(item.link)}
                                                        >
                                                            <div className="value">{item.value}</div>
                                                            <div className="label">{item.name}</div>
                                                        </div>

                                                    ))
                                                }

                                            </div>
                                        </div>
                                    </>

                                    : <NoDataFound label={'No Project Progress Found'} />}
                            </div>

                            <div className="dashboard-tasks-main">

                                <div className="dashboard-card-header">
                                    <div className="title">Upcoming Tasks</div>
                                    <div className="filter-button">
                                        <div
                                            className="icon"
                                            dangerouslySetInnerHTML={{ __html: Icons.general.filter_icon }}
                                        ></div>
                                    </div>
                                </div>

                                <div className="dashboard-tasks-items">

                                    {UpcomingTasks.length ?
                                        UpcomingTasks.map((item, index) => (

                                            <div
                                                className="dashboard-tasks-item"
                                                key={`dashboard-upcomingtasks-item-${index}`}
                                                onClick={() => HandleNavigation(`task-management/task/${item.sno}/edit`)}
                                            >
                                                <div className="left">
                                                    <div className="task-category">{item.worktype_name || ''}</div>
                                                    <div className="task-name">{item.name || `Task - ${item.sno}`}</div>
                                                    <div className="task-detials">
                                                        Start: <span className='strong'> {item.startdate ? Utils.formateDateLabel(item.startdate, true, false, true) : 'N/A'}</span>
                                                    </div>
                                                </div>
                                                <div className="right">
                                                    <div className="task-days task-upcomming-days">{Utils.getDurationFromDates(item.startdate, new Date().getTime(), 'days') || 0}days</div>
                                                </div>
                                            </div>

                                        )) :
                                        <NoDataFound label={'No Upcoming Tasks Found'} />
                                    }



                                </div>
                            </div>

                            <div className="dashboard-vendorpayables-main">

                                <div className="dashboard-card-header">
                                    <div className="title">Overdue Payments</div>
                                    <div className="filter-button">
                                        <div
                                            className="icon"
                                            dangerouslySetInnerHTML={{ __html: Icons.general.filter_icon }}
                                        ></div>
                                    </div>
                                </div>

                                <div className="dashboard-vendorpayables-content">
                                    <div className="dashboard-vendorpayables-items">
                                        {VendorPayables.length ?
                                            VendorPayables.map((item, index) => (

                                                <div
                                                    className="dashboard-vendorpayables-item"
                                                    key={`dashboard-vendorpayables-item-${item.id}`}
                                                >
                                                    <div className="left">
                                                        <div className="vendor-category">{item.id}</div>
                                                        <div className="vendor-name">{item.name}</div>
                                                        <div className="vendor-detials">
                                                            Due date: <span className='strong'>{item.due_date ? Utils.formateDateLabel(item.due_date, true, false, true) : 'N/A'}</span>
                                                        </div>
                                                    </div>
                                                    <div className="right">
                                                        <div className="vendor-amount">{currency_type || '₹'}{Utils.shorternNumber(item.due || 0)}</div>
                                                    </div>
                                                </div>

                                            ))
                                            : <NoDataFound label={'No Payales Found'} />
                                        }


                                    </div>
                                </div>
                            </div>

                            <div className="dashboard-finance_summary-main">

                                <div className="dashboard-card-header">
                                    <div className="title">Financial</div>
                                    <div className="filter-button">
                                        <div
                                            className="icon"
                                            dangerouslySetInnerHTML={{ __html: Icons.general.filter_icon }}
                                        ></div>
                                    </div>
                                </div>

                                <div className="dashboard-finance_summary-chart">
                                    {FinanceSummaryChart.series.length ?
                                        <Chart options={FinanceSummaryChart.options} series={FinanceSummaryChart.series} type="bar" height='100%' />
                                        : <NoDataFound label={'No Finance Data Found'} />}
                                </div>
                            </div>

                            <div className="dashboard-tasks-main">

                                <div className="dashboard-card-header">
                                    <div className="title">Delayed Tasks</div>
                                    <div className="filter-button">
                                        <div
                                            className="icon"
                                            dangerouslySetInnerHTML={{ __html: Icons.general.filter_icon }}
                                        ></div>
                                    </div>
                                </div>

                                <div className="dashboard-tasks-content">
                                    <div className="dashboard-tasks-items">
                                        {DelayedTasks.length ?
                                            DelayedTasks.map((item, index) => (

                                                <div
                                                    className="dashboard-tasks-item"
                                                    key={`dashboard-delayedtasks-item-${index}`}
                                                    onClick={() => HandleNavigation(`task-management/task/${item.sno}/edit`)}
                                                >
                                                    <div className="left">
                                                        <div className="task-category">{item.worktype_name || ''}</div>
                                                        <div className="task-name">{item.name || `Task - ${item.sno}`}</div>
                                                        <div className="task-detials">
                                                            Deadline: <span className='strong'> {item.enddate ? Utils.formateDateLabel(item.enddate, true, false, true) : 'N/A'}</span>
                                                        </div>
                                                    </div>
                                                    <div className="right">
                                                        <div className="task-days task-deadline-days">{Utils.getDurationFromDates(new Date().getTime(), item.enddate, 'days') || 0}days</div>
                                                    </div>
                                                </div>
                                            ))
                                            : <NoDataFound label={'No Delayed Tasks Found'} />
                                        }


                                    </div>
                                </div>



                            </div>

                            <div className="dashboard-donutchart-main">

                                <div className="dashboard-card-header">
                                    <div className="title">Material Risks</div>
                                    <div className="filter-button">
                                        <div
                                            className="icon"
                                            dangerouslySetInnerHTML={{ __html: Icons.general.filter_icon }}
                                        ></div>
                                    </div>
                                </div>
                                {InventoryOverviewChart.length ?
                                    <>
                                        <div className="dashboard-donutchart-chart">
                                            <PieChart width={170} height={170}>

                                                <Pie
                                                    data={InventoryOverviewChart}
                                                    cx={80}
                                                    cy={80}
                                                    outerRadius={80}
                                                    isAnimationActive={true}
                                                    label={false}
                                                    fill="#8884d8"
                                                    paddingAngle={0}
                                                    dataKey="value"
                                                >

                                                    {InventoryOverviewChart.map((entry, index) => (
                                                        <Cell key={`cell-${index}`} fill={entry.fill} />
                                                    ))}
                                                </Pie>
                                                <Tooltip />
                                            </PieChart>

                                        </div>
                                        <div className="dashboard-donutchart-detials">

                                            {
                                                InventoryOverviewChart?.map((item, index) => (


                                                    <div
                                                        className="donutchart-detials-item"
                                                        key={`donutchart-detials-item-${index}`}
                                                    >
                                                        <div className="label-main">
                                                            <div
                                                                className="icon"
                                                                style={{ background: item.fill }}
                                                            ></div>
                                                            <div className="label">{item.name}</div>
                                                        </div>
                                                        <div className="value">{item.value}</div>
                                                    </div>

                                                ))
                                            }
                                        </div>
                                    </>

                                    : <NoDataFound label={'No Inventory Found'} />}
                            </div>

                            <div className="dashboard-finance_summary-main dashboard-material_usage-main">

                                <div className="dashboard-card-header">
                                    <div className="title">Material Usage</div>
                                    <div className="filter-button">
                                        <div
                                            className="icon"
                                            dangerouslySetInnerHTML={{ __html: Icons.general.filter_icon }}
                                        ></div>
                                    </div>
                                </div>

                                <div className="dashboard-finance_summary-chart">
                                    {InventoryUsageChart.series.length ?
                                        <Chart options={InventoryUsageChart.options} series={InventoryUsageChart.series} type="bar" height='100%' />
                                        : <NoDataFound label={'No Material Usage Found'} />}
                                </div>
                            </div>

                            <div className="dashboard-tasks-main">

                                <div className="dashboard-card-header">
                                    <div className="title">Delayed Material Deliveries</div>
                                    <div className="filter-button">
                                        <div
                                            className="icon"
                                            dangerouslySetInnerHTML={{ __html: Icons.general.filter_icon }}
                                        ></div>
                                    </div>
                                </div>

                                <div className="dashboard-tasks-content">
                                    <div className="dashboard-tasks-items">
                                        {MaterialDelayedDeliveries.length ?
                                            MaterialDelayedDeliveries.map((item, index) => (

                                                <div
                                                    className="dashboard-tasks-item"
                                                    key={`dashboard-delayedtasks-item-${index}`}
                                                    onClick={() => HandleNavigation(`purchase_order/${item.purchaseID}/view`)}
                                                >
                                                    <div className="left">
                                                        {item.assignee_name ? <div className="task-detials">Vendor: <span className='strong'> {item.vendor_name}</span></div> : ''}
                                                        <div className="task-name">{item.name}</div>
                                                        <div className="task-detials">Deadline: <span className='strong'> {Utils.formateDateLabel(item.delivery_deadline_date, true, false, true)}</span></div>


                                                    </div>
                                                    <div className="right">
                                                        <div className="task-days task-deadline-days">{Utils.getDurationFromDates(new Date().getTime(), item.delivery_deadline_date, 'days') || 0}days</div>
                                                    </div>
                                                </div>
                                            ))
                                            : <NoDataFound label={'No Delayed Tasks Found'} />
                                        }


                                    </div>
                                </div>



                            </div>


                        </div>

                    </div>

                </div>


            </div>

        </>
    )
}

export default ProjectDashboard;