
import proxyConfig from '../../config/reverseProxy'
import HeaderConfig from '../../config/header'

class PurchaseOrder {

    constructor() {

    }


    async getNextPurchaseOrderIDHandler(params) {

        try {

            let response = await fetch(proxyConfig['serverBaseUrl'] + '/builder/purchase-orders/get-next-purchaseId', {
                method: 'POST',
                headers: HeaderConfig.getHeader({ method: 'POST', isToken: true }),
                body: JSON.stringify(params)
            })

            response = await response.json()

            if (response) return response
        } catch {
            return { success: false }
        }


    }
    async getBasicPurchaseOrdersHandler(params) {

        try {

            let response = await fetch(proxyConfig['serverBaseUrl'] + '/builder/purchase-orders/get-basic-purchase-orders', {
                method: 'POST',
                headers: HeaderConfig.getHeader({ method: 'POST', isToken: true }),
                body: JSON.stringify(params)
            })

            response = await response.json()

            if (response) return response
        } catch {
            return { success: false }
        }


    }
    async getPurchaseOrdersHandler(params) {

        try {

            let response = await fetch(proxyConfig['serverBaseUrl'] + '/builder/purchase-orders/get-purchase-orders', {
                method: 'POST',
                headers: HeaderConfig.getHeader({ method: 'POST', isToken: true }),
                body: JSON.stringify(params)
            })

            response = await response.json()

            if (response) return response
        } catch {
            return { success: false }
        }


    }
    async getPendingPurchaseOrderHandler(params) {

        try {

            let response = await fetch(proxyConfig['serverBaseUrl'] + '/builder/purchase-orders/get-pending-purchase-orders', {
                method: 'POST',
                headers: HeaderConfig.getHeader({ method: 'POST', isToken: true }),
                body: JSON.stringify(params)
            })

            response = await response.json()

            if (response) return response
        } catch {
            return { success: false }
        }


    }
    async createPurchaseOrderHandler(params) {

        try {

            let response = await fetch(proxyConfig['serverBaseUrl'] + '/builder/purchase-orders/create-purchase-orders', {
                method: 'POST',
                headers: HeaderConfig.getHeader({ method: 'POST', isToken: true }),
                body: JSON.stringify(params)
            })

            response = await response.json()

            if (response) return response
        } catch {
            return { success: false }
        }


    }
    async createMultiPurchaseOrderHandler(params) {

        try {

            let response = await fetch(proxyConfig['serverBaseUrl'] + '/builder/purchase-orders/create-multi-purchase-orders', {
                method: 'POST',
                headers: HeaderConfig.getHeader({ method: 'POST', isToken: true }),
                body: JSON.stringify(params)
            })

            response = await response.json()

            if (response) return response
        } catch {
            return { success: false }
        }


    }
    async updatePurchaseOrderHandler(params) {

        try {

            let response = await fetch(proxyConfig['serverBaseUrl'] + '/builder/purchase-orders/update-purchase-orders', {
                method: 'POST',
                headers: HeaderConfig.getHeader({ method: 'POST', isToken: true }),
                body: JSON.stringify(params)
            })

            response = await response.json()

            if (response) return response
        } catch {
            return { success: false }
        }


    }
    async deletePurchaseOrderHandler(params) {

        try {

            let response = await fetch(proxyConfig['serverBaseUrl'] + '/builder/purchase-orders/delete-purchase-orders', {
                method: 'POST',
                headers: HeaderConfig.getHeader({ method: 'POST', isToken: true }),
                body: JSON.stringify(params)
            })

            response = await response.json()

            if (response) return response
        } catch {
            return { success: false }
        }


    }

}

export default PurchaseOrder;