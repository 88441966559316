
import Images from '../assets/Images'
import Icons from '../assets/Icons'
import { useEffect } from 'react'

const ImagePreviewPopup = ({ props }) => {

    const { message, image, heading, callback } = props

    const HandleCancel = async (e) => {
        callback(false)
    }

    const Popup_Header = () => {
        return (
            <div className="side-popup-header">
                <div className="header-item-select">
                    <div className="header-item-select-content">
                        <div className="label">{heading}</div>
                    </div>
                </div>
                <div
                    className="header-item-close"
                    onClick={(e) => HandleCancel(e)}
                    dangerouslySetInnerHTML={{ __html: Icons.general.close }}
                ></div>
            </div>
        );
    };
    const Popup_Footer = () => {

        return (
            <div className="sidebar-popup-footer">
                <div className="footer-item action-items">
                    <div className="action-preview">
                    </div>
                    <div className='action-btns'>
                        <div
                            className={`action-btn action-active`}
                            onClick={(e) => HandleCancel(e)}>
                            Close
                        </div>

                    </div>
                </div>
            </div>
        );
    };

    useEffect(() => {

        if (!image) { return HandleCancel() }
    }, [])

    return (
        <div className="popup-container-main popup-container-center">
            <div className="popup-block-ui"></div>
            <div className="side-popup-container image-preview-container" style={{ maxWidth: '800px' }}>
                <Popup_Header />
                <div className="sidebar-popup-content">
                    <div className="content-section" style={{ height: '100%' }}>
                        <div className="popup-content-image">
                            <img src={image} alt="" />
                        </div>
                    </div>

                </div>
                <Popup_Footer />
            </div>
        </div>
    )

}

export default ImagePreviewPopup;