

import React, { useEffect, useState, useRef, useCallback, useMemo, StrictMode } from 'react'
import { Route, Routes, BrowserRouter, Link, useLocation, Navigate, Outlet, useNavigate, useParams } from 'react-router-dom';
import store from '../../../redux/store'
import StoreHandler from '../../../redux/StoreHandler'
import { useSelector, useDispatch } from 'react-redux'
import UserAction from '../../../redux/action/userAction'

import ReverseProxy from '../../../config/reverseProxy'
import MaterialIssueDeliveriesHandler from '../../../Handlers/MaterialIssueDeliveries/MaterialIssueDeliveries';
import Icons from '../../../assets/Icons'
import Images from '../../../assets/Images'
import { CostInput, TextInput, SelectInput, DateTimeInput, SearcBarInput, RadioInput } from '../../../components/Inputs'
import Utils from '../../../utils'
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

import AlertPopup from '../../../components/AlertPopup'
import Loading from '../../../components/Loading'
import NoDataFound from '../../../components/NoDataFound';

import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableFooter from '@mui/material/TableFooter';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';

import SideFilters from '../../../components/SideFilters';

import { useAuth, useMenuAuth } from '../../../hooks/useAuth'

const UpdateMaterialDelivery = ({ props }) => {

    const { type, existItemData } = props


    const { projectId, itemId } = useParams()

    const [project_id, setProject_id] = useState('')
    const [project_name, setProject_name] = useState('')

    const materialIssueDeliveriesHandler = new MaterialIssueDeliveriesHandler()

    const [id, setId] = useState('')
    const [issueName, setIssueName] = useState('N/A')
    const [issuedBy, setIssuedBy] = useState('N/A')
    const [issuedDate, setIssuedDate] = useState('N/A')
    const [issuedFrom, setIssuedFrom] = useState('N/A')
    const [issuedRemark, setIssuedRemark] = useState('N/A')

    const [date, setDate] = useState('')
    const [amount, setAmount] = useState('')
    const [payment_method, setPayment_method] = useState('')
    const [notes, setNotes] = useState('')

    const [costItems, setCostItems] = useState([])
    const [costItemsDeliveries, setCostItemsDeliveries] = useState([])

    const navigate = useNavigate()
    const store = useSelector((store) => store)
    const dispatch = useDispatch()
    const { updateState } = new UserAction

    const [unitsOptions, setUnitsOptions] = useState([])
    const [currency_type, setCurrency_type] = useState('₹')

    const [isLoading, setIsLoading] = useState(false)
    const [warningAlert, setWarningAlert] = useState(false)
    const [warningAlertType, setWarningAlertType] = useState('warning')
    const [apiFailedMessage, setApiFailedMessage] = useState("Error in Server")


    const storeHandler = new StoreHandler({
        dataStack: ['company', 'costcodes', 'field_settings', `project-${projectId}`],
        store: store,
        dispatch: dispatch,
        updateState: updateState,
    })

    const LoadStoreData = async () => {
        setIsLoading(true)
        let resolved = await storeHandler.init()
        setIsLoading(false)
    }



    const HandlePaymentsInputChange = (value, index, key, label) => {

        let costItems_ = [...costItems]

        costItems_[index][key] = value

        setCostItems(costItems_)
    }

    const isExistItemData = existItemData && existItemData.id != undefined

    const HandleCloseEvent = () => {
        navigate('/builder/projects/PR-002/inventory/deliveries')
    }

    const HandlePopupSubmit = async (e) => {
        e.preventDefault()

        let _costItems = costItems.map(c => {

            if (!Array.isArray(c.received_records)) c.received_records = []

            if (parseFloat(c.received_quantity || 0)) {
                c.received_records.push({
                    date: new Date().getTime(),
                    changed: true,
                    grn_no: '',
                    received_quantity: parseFloat(c.received_quantity)
                })

            }

            return c

        })

        if (!id || !itemId) return HandleCloseEvent()


        let updated_data = {
            id: id || itemId,
            projectId: projectId,
            cost_items: _costItems

        }

        let response = await materialIssueDeliveriesHandler.updateMaterialIssueDeliveriesReceivedQtyHandler(updated_data)

        if (response && response.success) {
            console.log(response, 'response');
            HandleCloseEvent()
        }
        else {
            setWarningAlert(true)
            setWarningAlertType('error')
            setApiFailedMessage(`Error in Updating Delivery`)
        }



    }

    const getClientInvoices = async () => {

        let filter = {
            id: itemId
        }

        let response = await materialIssueDeliveriesHandler.getMaterialIssueDeliveriesHandler(filter)

        if (response && response.success) {

            let data = response.data[0]

            console.log(data, 'data');

            if (!data) return

            setId(data.id || '')
            setIssuedDate(data.issue_date || 'N/A')
            setIssuedRemark(data.remark || 'N/A')
            setIssueName(data.name || 'N/A')
            setIssuedBy(data.issued_by || data.issued_updated_by || 'N/A')
            setIssuedFrom(data.transfered_from_project_name || 'N/A')

            let cost_items = data.cost_items.map(c => {

                let received_qty = Array.isArray(c.received_records) ? c.received_records.reduce((prev, r) => prev + parseFloat(r.received_quantity), 0) : 0

                c.max_quantity = parseFloat(c.quantity) - received_qty
                c.received_quantity = 0
                return c
            })

            let cost_item_deliveries = []

            data.cost_items.map(c => {


                if (Array.isArray(c.received_records)) {

                    c.received_records.forEach(r => {

                        cost_item_deliveries.push({
                            "id": c.id,
                            "amount": c.amount,
                            "unit_id": c.unit_id,
                            "quantity": c.quantity,
                            "unit_name": c.unit_name,
                            "value_type": c.value_type,
                            "material_id": c.material_id,
                            "received_quantity": r.received_quantity,
                            "description": c.idescription,
                            "max_quantity": c.max_quantity,
                            "material_name": c.material_name,
                        })
                    })
                }
            })

            setCostItemsDeliveries(cost_item_deliveries)
            setCostItems(cost_items || [])


        } else {
            setWarningAlert(true)
            setWarningAlertType('error')
            setApiFailedMessage(`Error in Getting Delivery Detials`)
        }
    }

    useEffect(() => {
        LoadStoreData()
        if (!isExistItemData) getClientInvoices()
        else {
            setId(existItemData.id || '')
            setIssuedDate(existItemData.issue_date || 'N/A')
            setIssuedRemark(existItemData.remark || 'N/A')
            setIssueName(existItemData.name || 'N/A')
            setIssuedBy(existItemData.issued_by || existItemData.issued_updated_by || 'N/A')
            setIssuedFrom(existItemData.transfered_from_project_name || 'N/A')

            let cost_items = existItemData.cost_items.map(c => {

                let received_qty = Array.isArray(c.received_records) ? c.received_records.reduce((prev, r) => prev + parseFloat(r.received_quantity), 0) : 0

                c.max_quantity = parseFloat(c.quantity) - received_qty
                c.received_quantity = 0
                return c
            })

            let cost_item_deliveries = []

            existItemData.cost_items.map(c => {


                if (Array.isArray(c.received_records)) {

                    c.received_records.forEach(r => {
                        cost_item_deliveries.push({
                            "id": c.id,
                            "amount": c.amount,
                            "unit_id": c.unit_id,
                            "grn_no": r.grn_no,
                            "quantity": c.quantity,
                            "unit_name": c.unit_name,
                            "value_type": c.value_type,
                            "material_id": c.material_id,
                            "received_quantity": r.received_quantity,
                            "description": c.idescription,
                            "max_quantity": c.max_quantity,
                            "material_name": c.material_name,
                        })
                    })
                }
            })

            setCostItemsDeliveries(cost_item_deliveries)

            setCostItems(cost_items || [])
        }
    }, [])


    useEffect(() => {

        if (store && store.user) {

            let { company, selected_project, settings } = store.user

            if (selected_project) {
                let { id, name, estimate_id } = selected_project

                if (id) setProject_id(id)
                if (name) setProject_name(name)
            }

            if (company) {

                let { currency_setting, timezone, temperature_setting } = company

                if (currency_setting) setCurrency_type(currency_setting)
            }

            if (settings && Array.isArray(settings.field_settings) && settings.field_settings.length) {

                let { project_statuses, project_types, units } = settings.field_settings[0]

                units = units.filter(opt => opt.isActive).map((opt) => {
                    return {
                        value: opt.id,
                        label: opt.name,
                        isDefault: opt.isDefault || false
                    }
                })

                setUnitsOptions(units || [])

            }

        }

    }, [store])

    return (
        <div className="popup-container-main popup-container-center">
            <div className="popup-block-ui"></div>
            <form className="side-popup-container create-payment_history-main" onSubmit={HandlePopupSubmit}>

                <div className="side-popup-header">
                    <div className="header-item-select">
                        <div className="header-item-select-content">
                            <div className="label">Update Delivery</div>

                        </div>
                    </div>
                    <div
                        className="header-item-close"
                        onClick={(e) => HandleCloseEvent()}
                        dangerouslySetInnerHTML={{ __html: Icons.general.close }}
                    ></div>
                </div>

                <div className="sidebar-popup-content">
                    <div className="content-section">
                        <div className="popup-detials-section">
                            <div className="popup-detials-header">
                                <div className="left">
                                    <div className="title">Detials</div>
                                </div>
                                <div className="right">
                                    <div className="icon-item">
                                        <div
                                            className="icon"
                                            dangerouslySetInnerHTML={{ __html: Icons.general.calender }}
                                        ></div>
                                        <div className="label">{issuedDate}</div>
                                    </div>
                                </div>

                            </div>
                            <div className="popup-detials-content">
                                <div className="detials-content-item">
                                    <div className="label">ISSUE NAME</div>
                                    <div className="value">{issueName}</div>
                                </div>
                                <div className="detials-content-item">
                                    <div className="label">ISSUED BY</div>
                                    <div className="value">{issuedBy}</div>
                                </div>
                                <div className="detials-content-item">
                                    <div className="label">ISSUED FROM</div>
                                    <div className="value">{issuedFrom}</div>
                                </div>
                                <div className="detials-content-full-item">
                                    <div className="label">Remark</div>
                                    <div className="value">{issuedRemark}</div>
                                </div>
                            </div>

                        </div>
                    </div>

                    {costItems.filter(c => c.max_quantity != 0).length ?
                        <div className="content-section">
                            <div className="popup-detials-section">
                                <div className="popup-detials-header">
                                    <div className="left">
                                        <div className="title">Pending Items </div>
                                        <div className="desc"></div>
                                    </div>
                                </div>
                                <div className="popup-detials-content">

                                    <div className="detials-card-sections">

                                        {costItems.map((item, index) => (

                                            <div className="detials-card-section">

                                                <div className="detials-card-item detials-card-item-full">
                                                    <div className="label label-dark">Material: </div>
                                                    <div className="value">{item.material_name}</div>
                                                </div>
                                                <div className="detials-card-item">
                                                    <div className="label">Request ID: </div>
                                                    <div className="value">-</div>
                                                </div>
                                                <div className="detials-card-item">
                                                    <div className="label">GRN ID: </div>
                                                    <div className="value">{item.grn_no || '-'}</div>
                                                </div>
                                                <div className="detials-card-item">
                                                    <div className="label label-dark">Ordered Qty: </div>
                                                    <div className="value">{item.quantity} {item.unit_name || ''}</div>
                                                </div>
                                                <div className="detials-card-item">
                                                    <div className="label label-dark">Pending Qty: </div>
                                                    <div className="value">{item.max_quantity} {item.unit_name || ''}</div>
                                                </div>
                                                <div className="detials-card-item-input">
                                                    <div className="label">Received Quantity </div>
                                                    <div className="input">
                                                        <TextInput
                                                            props={{
                                                                id: `invoice-payment-quantity-${index}`,
                                                                value: item.received_quantity,
                                                                type: 'number',
                                                                placeholder: 'Qty',
                                                                setValue: (value) => HandlePaymentsInputChange(value, index, 'received_quantity'),
                                                                isIcon: false,
                                                                isLabel: false,
                                                                setMaxLimit: item.max_quantity || 0,
                                                                maxLimitLabel: true,
                                                                isRequired: true,
                                                                label: "Qty",
                                                            }}
                                                        />
                                                    </div>
                                                </div>

                                            </div>

                                        ))}
                                    </div>
                                </div>

                            </div>
                        </div>
                        : ''}
                    {costItemsDeliveries.filter(c => c.received_quantity != 0).length ?
                        <div className="content-section">
                            <div className="popup-detials-section">
                                <div className="popup-detials-header">
                                    <div className="left">
                                        <div className="title">Delivered Items </div>
                                        <div className="desc"></div>
                                    </div>
                                </div>
                                <div className="popup-detials-content">

                                    <div className="detials-card-sections">

                                        {costItemsDeliveries.filter(c => c.received_quantity != 0).map((item, index) => (

                                            <div className="detials-card-section">

                                                <div className="detials-card-item detials-card-item-full">
                                                    <div className="label label-dark">Material: </div>
                                                    <div className="value">{item.material_name}</div>
                                                </div>
                                                <div className="detials-card-item">
                                                    <div className="label">Request ID: </div>
                                                    <div className="value">-</div>
                                                </div>
                                                <div className="detials-card-item">
                                                    <div className="label">GRN ID: </div>
                                                    <div className="value">{item.grn_no || '-'}</div>
                                                </div>
                                                <div className="detials-card-item">
                                                    <div className="label label-dark">Ordered Qty: </div>
                                                    <div className="value">{item.quantity} {item.unit_name || ''}</div>
                                                </div>
                                                <div className="detials-card-item">
                                                    <div className="label label-dark">Received Qty: </div>
                                                    <div className="value">{item.received_quantity} {item.unit_name || ''}</div>
                                                </div>
                                                <div className="detials-card-item">
                                                    <div className="label">Received By: </div>
                                                    <div className="value">Raja | 23 Dec, 9:44 AM</div>
                                                </div>

                                            </div>

                                        ))}
                                    </div>
                                </div>

                            </div>
                        </div>
                        : ''}



                </div>

                {costItems.filter(c => c.max_quantity != 0).length ?

                    <div className="sidebar-popup-footer">
                        <div className="footer-item action-items">
                            <div className="action-preview">
                            </div>
                            <button
                                className="action-btn"
                                type='submit'
                            >
                                <div className="icon" dangerouslySetInnerHTML={{ __html: Icons.general.add_btn }}></div>
                                <div className="label">Add To Stock</div>
                            </button>
                        </div>
                    </div>
                    : ''}
            </form>
        </div>
    )
}

const MaterialIssueDeliveryLists = () => {

    const store = useSelector((store) => store)
    const dispatch = useDispatch()
    const { updateState } = new UserAction

    const { projectId } = useParams();

    const navigate = useNavigate()

    const [project_id, setProject_id] = useState('')
    const [project_name, setProject_name] = useState('')

    const materialIssueDeliveriesHandler = new MaterialIssueDeliveriesHandler()

    const storeHandler = new StoreHandler({
        dataStack: ['company', 'team_users', 'sites', `project-${projectId}`],
        store: store,
        dispatch: dispatch,
        updateState: updateState,
    })

    const LoadStoreData = async () => {
        setIsLoading(true)
        let resolved = await storeHandler.init()
        setIsLoading(false)
    }

    const { isAdmin, menu_permission } = useMenuAuth({ menu: 'inventory--material_deliveries', feature_key: 'inventory-deliveries' })
    const location = useLocation()

    const [selected_view_settings_value, setSelected_view_settings_value] = useState('')
    const [has_view_access, setHas_view_access] = useState(false)
    const [has_add_access, setHas_add_access] = useState(false)
    const [has_edit_access, setHas_edit_access] = useState(false)
    const [has_delete_access, setHas_delete_access] = useState(false)

    useEffect(() => {

        if (isAdmin) {

            setHas_view_access(true)
            setHas_add_access(true)
            setHas_edit_access(true)
            setHas_delete_access(true)
        }

        else if (menu_permission) {

            if (
                !menu_permission._view
                &&
                (!menu_permission.selected_view_settings_value
                    || menu_permission.selected_view_settings_value == 'no_access')
            ) {
                navigate(`${ReverseProxy['proxyUrl']}/quick-menu`, { state: { from: location }, replace: true })
            }

            if (menu_permission._view == '0') navigate(`${ReverseProxy['proxyUrl']}/builder/projects`, { state: { from: location }, replace: true })

            setHas_view_access(menu_permission._view == '1')
            setHas_add_access(menu_permission._create == '1')
            setHas_edit_access(menu_permission._edit == '1')
            setHas_delete_access(menu_permission._delete == '1')

            setSelected_view_settings_value(menu_permission.selected_view_settings_value)

        }

    }, [store])


    const [isLoading, setIsLoading] = useState(false)
    const [warningAlert, setWarningAlert] = useState(false)
    const [warningAlertType, setWarningAlertType] = useState('warning')
    const [apiFailedMessage, setApiFailedMessage] = useState("Error in Server")

    const [ProposalsLoaded, setProposalsLoaded] = useState(false)
    const [Proposals_original, setProposals_original] = useState([])
    const [Proposals, setProposals] = useState([])

    const [selectedProposal, setSelectedProposal] = useState(undefined)

    const [sitesDetials, setSitesDetials] = useState([])

    const [currency_type, setCurrency_type] = useState('₹')

    const [tabelHeader, setTabelHeader] = useState([
        {
            id: 'select',
            label: 'Select',
            minWidth: 30,
            disable: false,
        },
        {
            id: 'issue_no',
            label: 'Issue No',

        },
        {
            id: 'name',
            label: 'Name',

        },
        {
            id: 'checkedby_name',
            label: 'Checked By',

        },
        {
            id: 'other_site_name',
            label: 'Transfered Site',

        },
        {
            id: 'issue_date',
            label: 'Date',
            format: (value) => Utils.getLocalFullDateBYFormat(value),
        },

        {
            id: 'status',
            label: 'Status',
            format: (value) => {
                if (value == "0") return "Pending"
                else if (value == "1") return "Approved"
                else if (value == "2") return "Rejected"
            },

        },
        {
            id: 'actions',
            label: 'Actions',
            minWidth: 50,
            align: 'right',
            format: (value) => value,
        },
    ])

    const getClientInvoices = async (projectid) => {

        let filter = {
            project_id: projectid || project_id
        }

        let response = await materialIssueDeliveriesHandler.getMaterialIssueDeliveriesHandler(filter)

        if (response && response.success) {

            setProposals_original(response.data)
            setProposals(createTabelData(tabelHeader, response.data))
            setProposalsLoaded(true)

        } else {
            setWarningAlert(true)
            setWarningAlertType('error')
            setApiFailedMessage(`Error in Getting Deliveries`)
        }
    }


    const createTabelData = (header, items) => {

        if (!Object.keys(header).length || !Array.isArray(items)) return []

        items = items.map((itm, i) => {

            let item = { ...itm }

            header.forEach((head, i) => item[head.id] = itm[head.id])

            return item
        })

        return items

    }


    const HandleEditTemplate = (item) => {



        let orginal_item = Proposals_original.filter(p => p.id == item.id)[0]

        if (!orginal_item) return
        setSelectedProposal(orginal_item)
        navigate(`${window.location.pathname}/update/${item.id}`)
    }



    useEffect(() => {
        LoadStoreData()
    }, [])

    useEffect(() => {

        if (store && store.user) {

            let { company, team_users, selected_project, sites } = store.user

            if (selected_project) {
                let { id, name, estimate_id } = selected_project

                if (id) setProject_id(id)
                if (name) setProject_name(name)

                if (!ProposalsLoaded) getClientInvoices(id)
            }

            if (company) {

                let { currency_setting, timezone, temperature_setting } = company

                if (currency_setting) setCurrency_type(currency_setting)
            }


            if (Array.isArray(sites)) {

                setSitesDetials(sites || [])

                let sideFilterSettings = [...SideFilterSettings]

                let filter_idx = sideFilterSettings.findIndex(f => f.id == 'siteby-filter')

                if (filter_idx != -1) {


                    let team_user_options = sites.filter(opt => opt.status == '1').map(opt => {
                        return {
                            id: `select-filteroption-siteby-${opt.id}`,
                            type: 'checkbox',
                            key: opt.id,
                            category: 'assigned_id',
                            value: true,
                            placeholder: '',
                            classNames: [],
                            isIconLeft: false,
                            isIcon: false,
                            icon: '',
                            isLabel: true,
                            label: `${opt.name}`,
                        }
                    })

                    sideFilterSettings[filter_idx].selectoptions = [
                        {
                            id: 'select-filteroption-siteby-all',
                            type: 'checkbox',
                            key: `all`,
                            value: true,
                            placeholder: '',
                            classNames: [],
                            isIconLeft: false,
                            isIcon: false,
                            icon: '',
                            isLabel: true,
                            label: 'All',
                        },
                        ...team_user_options
                    ]

                    setSideFilterSettings(sideFilterSettings)
                }


            }

        }

    }, [store])

    const [ShowSideFilter, setShowSideFilter] = useState(false)
    const [SideFilterSettings, setSideFilterSettings] = useState([


        {
            id: 'siteby-filter',
            type: 'multiselect',
            value: true,
            placeholder: '',

            selectoptions: [
                {
                    id: 'select-filteroption-siteby-all',
                    type: 'checkbox',
                    key: `all`,
                    value: true,
                    placeholder: '',
                    classNames: [],
                    isIconLeft: false,
                    isIcon: false,
                    icon: '',
                    isLabel: true,
                    label: 'All',
                },
            ],

            callback: (e) => { },
            classNames: [],
            isIconLeft: true,
            isIcon: true,
            icon: Icons.general.user,
            isLabel: true,
            label: 'Filter by Site Transfer',
        },
        {
            id: 'filterby-filter',
            type: 'multiselect',
            value: true,
            placeholder: '',

            selectoptions: [
                {
                    id: 'select-filteroption-filterby-all',
                    type: 'checkbox',
                    key: `all`,
                    value: true,
                    placeholder: '',
                    classNames: [],
                    isIconLeft: false,
                    isIcon: false,
                    icon: '',
                    isLabel: true,
                    label: 'All',
                },
                {
                    id: 'select-filteroption-filterby-pending',
                    type: 'checkbox',
                    key: `0`,
                    category: 'status',
                    value: true,
                    placeholder: '',
                    classNames: [],
                    isIconLeft: false,
                    isIcon: false,
                    icon: '',
                    isLabel: true,
                    label: 'Pending',
                },
                {
                    id: 'select-filteroption-filterby-approved',
                    type: 'checkbox',
                    key: `1`,
                    category: 'status',
                    value: true,
                    placeholder: '',
                    classNames: [],
                    isIconLeft: false,
                    isIcon: false,
                    icon: '',
                    isLabel: true,
                    label: 'Approved',
                },
                {
                    id: 'select-filteroption-filterby-rejected',
                    type: 'checkbox',
                    key: `2`,
                    category: 'status',
                    value: true,
                    placeholder: '',
                    classNames: [],
                    isIconLeft: false,
                    isIcon: false,
                    icon: '',
                    isLabel: true,
                    label: 'Rejected',
                },
            ],

            callback: (e) => { },
            classNames: [],
            isIconLeft: true,
            isIcon: true,
            icon: Icons.general.filter_by,
            isLabel: true,
            label: 'Filter by Status',
        },



    ])

    const HandleSideFilterClose = () => {
        setShowSideFilter(false)
    }
    const HandleSideFilterUpdateCallback = (filters) => {

        let _Proposals = [...Proposals]

        filters.forEach((filter, fidx) => {

            if (filter.type == 'multiselect') {

                if (filter.id == 'siteby-filter') {

                    filter.selectoptions.forEach((option, oidx) => {

                        if (option.key == 'all') {
                            _Proposals = _Proposals.map(item => {

                                if (item[option.category] == option.key) item.disable = !option.value

                                return item
                            })
                        }
                        else {

                            _Proposals = _Proposals.map(item => {

                                if (item[option.category] == option.key) item.disable = !option.value

                                return item
                            })


                        }

                    })
                }
                else if (filter.id == 'filterby-filter') {

                    filter.selectoptions.forEach((option, oidx) => {

                        if (option.key == 'all') {
                            _Proposals = _Proposals.map(item => {

                                item.disable = !option.value

                                return item
                            })
                        }
                        else {
                            _Proposals = _Proposals.map(item => {

                                if (item[option.category] == option.key) item.disable = !option.value

                                return item
                            })


                        }

                    })
                }


            }

        })

        setProposals(_Proposals)

        setSideFilterSettings(filters)
        HandleSideFilterClose()
    }

    const [Filters, setFilters] = useState({
        left: [


            {
                id: "estimate-search",
                value: '',
                isSearchBar: true,
                type: "input",
                isDropDownContainer: false,
                placeholder: "Search by name",
                dropDownOptions: [],
                callback: (val) => { },

                isIcon: true,
                icon: Icons.general.search,
                isLabel: false,
                label: "",
                isIconLeft: false
            },



        ],
        right: [
            {
                id: "estimate-morefilter",
                type: "icon",
                isSelectToggle: true,
                value: true,
                placeholder: "",
                callback: (val) => HandleFiltersEvent(val, 'estimate-morefilter'),
                isIcon: true,
                icon: Icons.general.filter_funnel,
                isLabel: true,
                label: "Filters",
                isIconLeft: true
            },
        ]
    })

    const HandleFiltersEvent = async (value, filter_id) => {

        if (!filter_id) return

        if (filter_id == 'estimate-search') {

            let filters = { ...Filters }

            filters.left[0].value = value

            let _Proposals = [...Proposals]

            let search_items = (items, value, column = 'name') => {

                let search_item = (items, value, column = 'name') => {

                    items = items.map(item => {

                        let is_item_match = String(item[column]).toLocaleLowerCase().includes(String(value).toLocaleLowerCase())

                        if (is_item_match) item.disable = false
                        else item.disable = true

                        return item

                    })

                    return items

                }

                items = search_item(items, value, column)
                return items
            }

            _Proposals = search_items(_Proposals, value, 'name')

            setProposals(_Proposals)

            setFilters(filters)

        }
        else if (filter_id == 'estimate-morefilter') {
            setShowSideFilter(true)
        }


    }

    const isLeftFilter = Array.isArray(Filters.left) && Filters.left.length > 0
    const isRightFilter = Array.isArray(Filters.right) && Filters.right.length > 0

    const Icon_Filter = ({ props }) => {
        const {
            id,
            isSearchBar,
            type,
            value,
            isSelectToggle,
            isDropDownContainer,
            dropDownOptions,
            placeholder,
            callback,
            isIcon,
            icon,
            isLabel,
            label,
            isIconLeft,
        } = props

        const [iconStatus, setIconStatus] = useState(value != undefined ? value : false)

        const HandleFilterIconInput = (e) => {


            HandleFiltersEvent(!iconStatus, id)
            setIconStatus(!iconStatus)
        }

        return (
            <>
                {isIcon && type == 'icon' ?
                    <div
                        className="filter-item group-expand-filter"
                        onClick={(e) => HandleFilterIconInput(e)}
                        key={`filter-item-${id}`}
                    >
                        <div
                            className="icon"
                            dangerouslySetInnerHTML={{ __html: id.includes('expand') ? iconStatus ? Icons.general.collapse : Icons.general.expand : icon }}
                        ></div>
                        {isLabel ? <div className="label">{id.includes('expand') ? iconStatus ? 'Collapse all' : 'Expand all' : label}</div> : ''}
                    </div>

                    : null}
            </>
        )
    }
    const Button_Filter = ({ props }) => {
        const {
            id,
            isSearchBar,
            type,
            isDropDownContainer,
            dropDownOptions,
            callback,
            isIcon,
            icon,
            isLabel,
            label,
            isIconLeft,
        } = props
        const [selectFilterOpts, setSelectFilterOpts] = useState([])

        const [dropdownStatus, setDropDownStatus] = useState(false)

        const HandleClick = (e) => {
            setDropDownStatus(!dropdownStatus)
        }

        let HandleSubItemChange = (value, opt, i) => {

            let _filters = [...selectFilterOpts]

            if (_filters[i].id == 'opt-select-all') {
                _filters = _filters.map(f => {
                    f.value = value

                    return f
                })
            } else _filters[i].value = value

            setSelectFilterOpts(_filters)

            HandleFiltersEvent(opt, id)
        }

        useEffect(() => {

            let items_parent = document.querySelector(`#${id}`)

            window.addEventListener('click', (e) => {

                let path = e.path || (e.composedPath && e.composedPath());

                if (Array.isArray(path) && !path.includes(items_parent))
                    setDropDownStatus(false)
            })
            setSelectFilterOpts(dropDownOptions)
        }, [])


        return (
            <div className='filter-item dropdown-select-filter' id={id} key={`filter-item-${id}`}>
                <div
                    className="button"
                    onClick={(e) => HandleClick(e)}
                >
                    {isIcon && isIconLeft ?
                        <span
                            className={`icon ${dropdownStatus ? 'icon-active' : ''}`}
                            dangerouslySetInnerHTML={{ __html: icon }}
                        ></span>
                        : null}

                    {isLabel ? <span class='label'>{label}</span> : null}

                    {isIcon && !isIconLeft ?
                        <span
                            className={`icon ${dropdownStatus ? 'icon-active' : ''}`}
                            dangerouslySetInnerHTML={{ __html: icon }}
                        ></span>
                        : null}
                </div>

                {isDropDownContainer ?

                    <div className={`dropdown-select-items ${dropdownStatus ? 'dropdown-select-items-active' : ''}`}>

                        {selectFilterOpts.map((opt, i) => (

                            opt.type == 'label' ?
                                <div className="dropdown-select-title">{opt.label}</div>
                                :
                                <div className="dropdown-select-itm">
                                    <RadioInput props={{
                                        id: `filters-item-select-${opt.id}`,
                                        value: opt.value,
                                        isIcon: false,
                                        icon: "",
                                        inputType: "checkbox",
                                        name: "table-default-radio",
                                        setValue: (value) => HandleSubItemChange(value, opt, i)
                                    }} />
                                    <label className='label' htmlFor={`filters-item-select-${opt.id}`}>{opt.label}</label>
                                </div>

                        ))}

                    </div>

                    : null}
            </div>

        )
    }


    return (
        <>



            <Routes >

                <Route exact path='/update/:itemId'
                    element={<UpdateMaterialDelivery
                        props={{
                            type: "edit",
                            existItemData: selectedProposal || undefined,

                        }} />}>

                </Route>


            </Routes>


            {
                ShowSideFilter ?
                    <SideFilters
                        props={
                            {
                                heading: 'Filters',
                                filters: SideFilterSettings,
                                close: HandleSideFilterClose,
                                updateCallback: HandleSideFilterUpdateCallback
                            }
                        }
                    />
                    : ''
            }

            <div className="project-client_invoice-main project-project_estimate-main">
                <div className="proposals-panel-content">

                    <div className="panel-content-header">
                        <div className="title">Upcomming Deliveries</div>
                    </div>
                    <div className="filter-section-main">
                        {isLeftFilter ?

                            < div className="left-filter-main">
                                {Filters.left.map((item, i) => (

                                    item.type == 'icon' ? <Icon_Filter props={item} />
                                        : item.type == 'button' ? <Button_Filter props={item} />
                                            : item.isSearchBar && item.type == 'input' ?
                                                <div className="search-filter">
                                                    <SearcBarInput
                                                        props={{
                                                            id: item.id,
                                                            value: item.value,
                                                            placeholder: item.placeholder || "Search by name",
                                                            setValue: (val) => {
                                                                HandleFiltersEvent(val, item.id)
                                                            },
                                                            isIcon: item.isIcon,
                                                            isLeft: item.isIconLeft,
                                                            icon: item.icon || Icons.general.search
                                                        }}
                                                    />
                                                </div>
                                                : ''
                                ))}
                            </div>
                            : ''}
                        {isRightFilter ?

                            < div className="right-filter-main">
                                {Filters.right.map((item, i) => (

                                    item.type == 'icon' ? <Icon_Filter props={item} />
                                        : item.type == 'button' ? <Button_Filter props={item} />
                                            : item.isSearchBar && item.type == 'input' ?
                                                <div className="search-filter">
                                                    <SearcBarInput
                                                        props={{
                                                            id: item.id,
                                                            value: item.value,
                                                            placeholder: item.placeholder || "Search by name",
                                                            setValue: (val) => {
                                                                HandleFiltersEvent(val, item.id)
                                                            },
                                                            isIcon: item.isIcon,
                                                            isLeft: item.isIconLeft,
                                                            icon: item.icon || Icons.general.search
                                                        }}
                                                    />
                                                </div>
                                                : ''
                                ))}
                            </div>
                            : ''}
                    </div>
                    <div className="panel-content-sections panel-content-tabel-section panel-content-cards-main">
                        {Proposals.filter(p => !p.disable).length ?
                            <div className="panel-content-cards-items">
                                {
                                    Proposals?.map((item, idx) => (
                                        <div
                                            className="panel-content-card"
                                            key={item.id}
                                            onClick={(e) => HandleEditTemplate(item)}
                                        >
                                            <div className="card-top-section">
                                                <div className="left">
                                                    <div className="card-id">{item.issue_no || 'N/A'}</div>
                                                    <div
                                                        className={`
                                                        card-status
                                                        ${item.status == '0' ? 'card-status-issued' :
                                                                item.status == '1' ? `card-status-approved` :
                                                                    item.status == '2' ? `card-status-partially_delivered` :
                                                                        item.status == '3' ? `card-status-delivered` :
                                                                            item.status == '4' ? `card-status-rejected` :
                                                                                'card-status-default'}`}
                                                    >
                                                        {`${item.status == '0' ? 'Issued' :
                                                            item.status == '1' ? `Approved` :
                                                                item.status == '2' ? `Partially Delivered` :
                                                                    item.status == '3' ? `Delivered` :
                                                                        item.status == '4' ? `Rejected` :
                                                                            'Default'}`}
                                                    </div>
                                                </div>
                                                <div className="right">
                                                    <div className="card-time">{item.issue_date || 'N/A'}</div>
                                                </div>
                                            </div>
                                            <div className="card-detials-section">
                                                <div className="card-detials-item">
                                                    <div className="label">ISSUE NAME</div>
                                                    <div className="value">{item.name || 'N/A'}</div>
                                                </div>
                                                <div className="card-detials-item">
                                                    <div className="label">ISSUED BY</div>
                                                    <div className="value">{item.issued_by || item.issued_updated_by || 'N/A'}</div>
                                                </div>
                                                <div className="card-detials-item">
                                                    <div className="label">ISSUED FROM</div>
                                                    <div className="value">{item.transfered_from_project_name || 'N/A'}</div>
                                                </div>
                                                <div className="card-detials-item">
                                                    <div className="label">ITEMS</div>
                                                    <div className="value">{item.cost_items.map(c => `${c.material_name}(${c.quantity})`).join(',') || 'N/A'}</div>
                                                </div>
                                            </div>
                                        </div>
                                    ))
                                }

                            </div>
                            : <NoDataFound label={'No Deliveries Found'} />}
                    </div>

                </div>

            </div>
        </>
    )
}

export default MaterialIssueDeliveryLists;