

import React, { useEffect, useState, useRef } from 'react'
import { Route, Routes, BrowserRouter, Link, useLocation, Navigate, Outlet, useNavigate, useParams } from 'react-router-dom';
import store from '../../redux/store'
import { useSelector, useDispatch } from 'react-redux'
import UserAction from '../../redux/action/userAction'

import ReverseProxy from '../../config/reverseProxy'
import ScheduleRatesHandler from '../../Handlers/ScheduleRates/ScheduleRates';
import LabourManagementHandler from '../../Handlers/LabourManagement/LabourManagement';

import Icons from '../../assets/Icons'
import Images from '../../assets/Images'
import { CostInput, TextInput, SelectInput, RadioInput, RadioOptionsInput } from '../../components/Inputs'
import Utils from '../../utils'
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

import AlertPopup from '../../components/AlertPopup'
import SystemToastPopup from '../../components/SystemToastPopup'
import Loading from '../../components/Loading'
import NoDataFound from '../../components/NoDataFound';

import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableFooter from '@mui/material/TableFooter';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';

import SmarkTopCards from '../../components/SmarkTopCards'

import { useAuth, useMenuAuth } from '../../hooks/useAuth'

const AddNewLabourPopup = ({ props }) => {

    const { type, heading, close, callback, detials } = props

    const { labourId } = useParams()

    const store = useSelector((store) => store)
    const dispatch = useDispatch()
    const { updateState } = new UserAction

    const navigate = useNavigate()

    const labourManagementHandler = new LabourManagementHandler()
    const scheduleRatesHandler = new ScheduleRatesHandler()

    const [isLoading, setIsLoading] = useState(false)
    const [warningAlert, setWarningAlert] = useState(false)
    const [warningAlertType, setWarningAlertType] = useState('warning')
    const [apiFailedMessage, setApiFailedMessage] = useState('Error in Labours, Please try again!')

    const [Name, setName] = useState('')
    const [Phone, setPhone] = useState('')
    const [Gender, setGender] = useState('')
    const [WorkType_id, setWorkType_id] = useState('')
    const [WorkType_name, setWorkType_name] = useState('')
    const [FixedSalary, setFixedSalary] = useState('0')
    const [FixedSalaryValue, setFixedSalaryValue] = useState('0')
    const [OpeningBalance, setOpeningBalance] = useState("0")

    const [ScheduleLabours, setScheduleLabours] = useState([])
    const [WorkTypeOptions, setWorkTypeOptions] = useState([])

    const { isAdmin, menu_permission } = useMenuAuth({ menu: 'labour_managment--all_labours', feature_key: 'labourManagement' })
    const location = useLocation()

    const [selected_view_settings_value, setSelected_view_settings_value] = useState('')
    const [has_view_access, setHas_view_access] = useState(false)
    const [has_add_access, setHas_add_access] = useState(false)
    const [has_edit_access, setHas_edit_access] = useState(false)
    const [has_delete_access, setHas_delete_access] = useState(false)

    useEffect(() => {

        if (isAdmin) {

            setHas_view_access(true)
            setHas_add_access(true)
            setHas_edit_access(true)
            setHas_delete_access(true)
        }

        else if (menu_permission) {

            if (
                !menu_permission._view
                &&
                (!menu_permission.selected_view_settings_value
                    || menu_permission.selected_view_settings_value == 'no_access')
            ) {
                navigate(`${ReverseProxy['proxyUrl']}/quick-menu`, { state: { from: location }, replace: true })
            }

            if (type == 'create' && menu_permission._create == '0') navigate(`${ReverseProxy['proxyUrl']}/builder/projects`, { state: { from: location }, replace: true })
            if (type == 'edit' && menu_permission._edit == '0') navigate(`${ReverseProxy['proxyUrl']}/builder/projects`, { state: { from: location }, replace: true })

            setHas_view_access(menu_permission._view == '1')
            setHas_add_access(menu_permission._create == '1')
            setHas_edit_access(menu_permission._edit == '1')
            setHas_delete_access(menu_permission._delete == '1')

            setSelected_view_settings_value(menu_permission.selected_view_settings_value)

        }

    }, [store])


    const HandleCancel = async (e) => {
        close(type)
    }
    const HandlePopupSubmit = async (e) => {

        let updateta = {
            name: Name,
            phone: String(Phone),
            worktype_id: WorkType_id || "",
            worktype_name: WorkType_name || "",
            gender: String(Gender) || "",
            salary_type: String(FixedSalary) || "0",
            salary_amount: String(FixedSalaryValue) || "0",
            opening_balance: String(OpeningBalance) || "0",
        }

        let response = { success: false }

        if (type == 'edit') {

            updateta["id"] = labourId
            response = await labourManagementHandler.updateLabourHandler(updateta)

        }

        else response = await labourManagementHandler.createLabourHandler(updateta)

        if (response.success) {
            callback()
            close(type)
            setIsLoading(false)
        }
        else {
            setIsLoading(false)
            setWarningAlert(true)
            setWarningAlertType('error')
            setApiFailedMessage(`Error in Labours, Please try again!`)
        }


    }
    const Popup_Header = () => {

        return (
            <div className="side-popup-header">
                <div className="header-item-select">
                    <div className="header-item-select-content">
                        <div className="label">{heading}</div>
                    </div>
                </div>
                <div
                    className="header-item-close"
                    onClick={(e) => HandleCancel(e)}
                    dangerouslySetInnerHTML={{ __html: Icons.general.close }}
                ></div>
            </div>
        );
    };

    const Popup_Footer = () => {

        return (
            <div className="sidebar-popup-footer">
                <div className="footer-item action-items">
                    <div className="action-preview">
                    </div>
                    <div className='action-btns'>
                        <div className="action-cancel" onClick={(e) => HandleCancel(e)}>
                            Cancel
                        </div>
                        <div
                            className={`action-btn action-${type}`}
                            onClick={(e) => {
                                HandlePopupSubmit(e)
                            }}
                        >

                            <div className="label">Save</div>
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    const LoadSchedule_Labours = async () => {

        let response = await scheduleRatesHandler.getItemsHandler({
            type: '3',
            item_type: '2',
        })

        if (response && response.success && Array.isArray(response.data)) {

            let _sor_items = response.data.map(d => {

                return {
                    value: d.id,
                    label: d.name
                }
            })

            setScheduleLabours(response.data)
            setWorkTypeOptions(_sor_items)

        }
        else {
            setWarningAlert(true)
            setWarningAlertType('error')
            setApiFailedMessage(`Error in Labours, Please try again!`)
        }


    }

    const HandleWorkTypeChange = (value, label) => {

        setWorkType_id(value)
        setWorkType_name(label)

        let sor_item = ScheduleLabours.filter(sor => sor.id == value)[0]

        if (!sor_item) return


        setFixedSalaryValue(sor_item.price || 0)
    }


    useEffect(() => {

        if (type == 'edit' && labourId) {

            const LoadLabours = async (labourId) => {

                if (Object.keys(detials).length) {

                    let { id, name, phone, gender, worktype_id, worktype_name, salary_type, salary_amount, opening_balance, updated_at, updated_by } = detials


                    setName(name)
                    setPhone(phone)
                    setGender(gender)
                    setWorkType_id(worktype_id)
                    setWorkType_name(worktype_name)
                    setFixedSalary(salary_type)
                    setFixedSalaryValue(salary_amount)
                    setOpeningBalance(opening_balance)



                }
                else {

                    let response = await labourManagementHandler.getLabourHandler({
                        id: labourId
                    })

                    if (response && response.success && Array.isArray(response.data) && response.data.length) {

                        let { id, name, phone, gender, worktype_id, worktype_name, salary_type, salary_amount, opening_balance, updated_at, updated_by } = response.data[0]

                        setName(name)
                        setPhone(phone)
                        setGender(gender)
                        setWorkType_id(worktype_id)
                        setWorkType_name(worktype_name)
                        setFixedSalary(salary_type)
                        setFixedSalaryValue(salary_amount)
                        setOpeningBalance(opening_balance)


                    }
                    else {
                        setWarningAlert(true)
                        setWarningAlertType('error')
                        setApiFailedMessage(`Error in Labours, Please try again!`)
                    }
                }
            }

            LoadLabours(labourId)
        }
        LoadSchedule_Labours()
    }, [])

    return (
        <>

            {isLoading ?
                <Loading
                    props={{
                        isMainLogo: false,
                        isLabel: true
                    }} />
                : null}
            {warningAlert ?

                <SystemToastPopup
                    props={{
                        type: warningAlertType,
                        message: apiFailedMessage || "Error in Labours, Please try again!",
                        callback: (confirmation) => setWarningAlert(false)
                    }} />

                : null}


            <div className="popup-container-main popup-container-center">
                <div className="popup-block-ui"></div>
                <div className="side-popup-container center-popup-container" >
                    <Popup_Header />
                    <div className="labourManagement-popup-content" style={{ minHeight: '250px' }}>
                        <div className="content-items">
                            <div className="content-item">
                                <TextInput
                                    props={{
                                        id: "labourManagement-name",
                                        value: Name,
                                        placeholder: '',
                                        setValue: (value) => setName(value),
                                        isIcon: false,
                                        isLabel: true,
                                        isRequired: true,
                                        label: "Name",
                                    }}
                                />
                            </div>
                            <div className="content-item">
                                <TextInput
                                    props={{
                                        id: "labourManagement-phone",
                                        value: Phone,
                                        placeholder: '',
                                        setValue: (value) => setPhone(value),
                                        isIcon: false,
                                        type: 'number',
                                        isLabel: true,
                                        isRequired: true,
                                        label: "Contact No",
                                    }}
                                />
                            </div>
                        </div>
                        <div className="content-items">
                            <div className="content-item">
                                <SelectInput
                                    props={{
                                        id: "labourManagement-gender",
                                        value: Gender,
                                        placeholder: '',
                                        options: [
                                            {
                                                value: '0',
                                                label: 'Male'
                                            },
                                            {
                                                value: '1',
                                                label: 'Female'
                                            },
                                            {
                                                value: '2',
                                                label: 'Other'
                                            },
                                        ],
                                        setValue: (value, label) => setGender(value),
                                        isIcon: false,
                                        isLabel: true,
                                        isRequired: true,
                                        label: "Gender",
                                    }}
                                />
                            </div>
                            <div className="content-item">
                                <SelectInput
                                    props={{
                                        id: "labourManagement-role",
                                        value: WorkType_id,
                                        placeholder: '',
                                        options: WorkTypeOptions || [],
                                        setValue: (value, label) => HandleWorkTypeChange(value, label),
                                        isIcon: false,
                                        isLabel: true,
                                        isRequired: true,
                                        label: "Work Type",
                                    }}
                                />
                            </div>
                        </div>

                        <div className="content-items">
                            <div className="content-item">

                                <RadioOptionsInput
                                    props={{
                                        id: "labourManagement-fixed_salary-type",
                                        value: FixedSalary,
                                        placeholder: '',
                                        options: [
                                            {
                                                id: 'fixed_salary-type-yes',
                                                label: 'Yes',
                                                value: '1'
                                            },
                                            {
                                                id: 'fixed_salary-type-no',
                                                label: 'No',
                                                value: '0'
                                            },
                                        ],
                                        setValue: (value, label) => setFixedSalary(value),
                                        isIcon: true,
                                        icon: Icons.general.cost_code,
                                        isLabel: true,
                                        isIconLeft: true,
                                        isRequired: true,
                                        name: "fixed_salary-type",
                                        label: "Fixed Daily Basic Salary",
                                    }}
                                />

                            </div>
                            <div className="content-item">
                                <TextInput
                                    props={{
                                        id: "labourManagement-daily_salary",
                                        value: FixedSalaryValue,
                                        placeholder: '',
                                        setValue: (value) => setFixedSalaryValue(value),
                                        isIcon: false,
                                        type: 'number',
                                        isLabel: true,
                                        isIcon: true,
                                        icon: '₹',
                                        isRequired: true,
                                        label: "Salary Per Day",
                                    }}
                                />
                            </div>

                        </div>
                        <div className="content-items">
                            <div className="content-item">
                                <TextInput
                                    props={{
                                        id: "labourManagement-balance",
                                        value: OpeningBalance,
                                        placeholder: '',
                                        setValue: (value) => setOpeningBalance(value),
                                        isIcon: true,
                                        icon: '₹',
                                        type: 'number',
                                        isLabel: true,
                                        isRequired: false,
                                        label: "Opening/Old Balance",
                                        // label: "Opening/Old Balance - Payable/Receivable (At the Time of Joining)",
                                    }}
                                />
                            </div>

                        </div>
                    </div>
                    <Popup_Footer />
                </div>
            </div>
        </>
    )
}

const Labours = () => {

    const store = useSelector((store) => store)
    const dispatch = useDispatch()
    const { updateState } = new UserAction

    const navigate = useNavigate()

    const labourManagementHandler = new LabourManagementHandler()

    const [isLoading, setIsLoading] = useState(false)
    const [warningAlert, setWarningAlert] = useState(false)
    const [warningAlertType, setWarningAlertType] = useState('warning')
    const [apiFailedMessage, setApiFailedMessage] = useState('Error in Labour')

    const [LabourDetials_original, setLabourDetials_original] = useState([])
    const [LabourDetials, setLabourDetials] = useState([])
    const [selected_termsCondition, setSelected_termsCondition] = useState({})
    const [selectedAction, setSelectedAction] = useState('')


    const { isAdmin, menu_permission } = useMenuAuth({ menu: 'labour_managment--all_labours', feature_key: 'labourManagement' })
    const location = useLocation()

    const [selected_view_settings_value, setSelected_view_settings_value] = useState('')
    const [has_view_access, setHas_view_access] = useState(false)
    const [has_add_access, setHas_add_access] = useState(false)
    const [has_edit_access, setHas_edit_access] = useState(false)
    const [has_delete_access, setHas_delete_access] = useState(false)

    const [deleteItemId, setDeleteItem] = useState(null)
    const [deleteItemType, setDeleteItemType] = useState(null)
    const [deleteItemAlert, setDeleteItemAlert] = useState(false)



    useEffect(() => {

        if (isAdmin) {

            setHas_view_access(true)
            setHas_add_access(true)
            setHas_edit_access(true)
            setHas_delete_access(true)
        }

        else if (menu_permission) {

            if (
                !menu_permission._view
                &&
                (!menu_permission.selected_view_settings_value
                    || menu_permission.selected_view_settings_value == 'no_access')
            ) {
                navigate(`${ReverseProxy['proxyUrl']}/quick-menu`, { state: { from: location }, replace: true })
            }

            if (menu_permission._view == '0') navigate(`${ReverseProxy['proxyUrl']}/builder/projects`, { state: { from: location }, replace: true })

            setHas_view_access(menu_permission._view == '1')
            setHas_add_access(menu_permission._create == '1')
            setHas_edit_access(menu_permission._edit == '1')
            setHas_delete_access(menu_permission._delete == '1')

            setSelected_view_settings_value(menu_permission.selected_view_settings_value)

        }

    }, [store])


    const [ActionDropdown, setActionDropdown] = useState([
        {
            id: 'edit',
            label: 'Edit',
            icon: Icons.general.edit,

        },
        {
            id: 'delete',
            label: 'Delete',
            icon: Icons.general.delete,

        },
    ])


    const tabelHeader = [
        {
            id: 'name',
            label: 'Name',

        },

        {
            id: 'phone',
            label: 'Contact No',

        },
        {
            id: 'gender',
            label: 'Gender',
            format: (value) => {
                if (value == "0") return "Male"
                else if (value == "1") return "Female"
                else if (value == "2") return "Other"
            },

        },
        {
            id: 'worktype_name',
            label: 'Work Type',
            format: (value) => value || "--",

        },
        {
            id: 'salary_type',
            label: 'Fixed Salary',
            format: (value) => {
                if (value == "0") return "No"
                else if (value == "1") return "Yes"
            },

        },
        {
            id: 'salary_amount',
            label: 'Salary Per Day',
            format: (value) => `₹ ${value || "0"}`,

        },
        {
            id: 'opening_balance',
            label: 'Op. balance',
            format: (value) => `₹ ${value || "0"}`,

        },
        {
            id: 'created_at',
            label: 'Created At',
            format: (value) => Utils.getLocalFullDateBYFormat(value, '-'),

        },
        {
            id: 'status',
            label: 'Status',
            format: (value) => {
                if (value == "0") return "Inactive"
                else if (value == "1") return "Active"
            },

        },
        {
            id: 'actions',
            label: 'Actions',
            minWidth: 50,
            align: 'right',
            format: (value) => value,
        },
    ];

    const getLabourDetials = async () => {

        setIsLoading(true)
        let response = await labourManagementHandler.getLabourHandler()

        setIsLoading(false)

        if (response && response.success) {

            setLabourDetials_original(response.data)
            setLabourDetials(response.data)

            HandleSmartCardSetup(response.data)

            dispatch(updateState({
                type: "SET_LABOURS",
                payload: { labours: response.data }
            }))

        } else {
            setWarningAlert(true)
            setWarningAlertType('error')
            setApiFailedMessage(`Error in Labours, Please try again!`)
        }
    }

    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const HandleAddLabour = () => {
        if (!has_add_access) return
        navigate(`${window.location.pathname}/add-labour`)
    }
    const HandleEditUser = (user) => {
        if (!has_edit_access) return
        let orginal_labour = LabourDetials_original.filter((itm, i) => itm.id == user.id)[0]
        if (!orginal_labour) return

        setSelected_termsCondition(orginal_labour)

        navigate(`${window.location.pathname}/edit/${orginal_labour.id}`)
    }

    const HandleDeleteUser = async (item) => {
        if (!has_edit_access || !has_delete_access) return
        setDeleteItem(item.id)
        setDeleteItemType('')
        setDeleteItemAlert(true)
    }
    const HandleDeleteConfirmItem = async (id, type, confirmation) => {
        if (!has_edit_access || !has_delete_access) return

        if (!confirmation || !id) {

            setDeleteItem(null)
            setDeleteItemType(null)
            setDeleteItemAlert(false)

            return
        }

        setIsLoading(true)
        let response = await labourManagementHandler.deleteLabourHandler({
            id: id
        })

        setIsLoading(false)

        if (response && response.success) {

            getLabourDetials()

        } else {
            setWarningAlert(true)
            setWarningAlertType('error')
            setApiFailedMessage(`Error in Labours, Please try again!`)
        }

    }
    const CloseAddLabourPopup = (type) => {

        let back_path = String(window.location.pathname).split('/')
        back_path.pop()
        if (type == 'edit') back_path.pop()

        back_path = back_path.join('/')

        navigate(`${back_path}`)
    }

    const HanelCellAction = (id, row) => {

        console.log(selectedAction, 'selectedAction');
        if (id == "name") HandleEditUser(row)
        if (id == "actions") {
            setSelectedAction(row.id)
            HandleActionDisable(row.id)
        }
    }

    const HandleActionDisable = (id) => {

        let input = document.getElementById(`labourManagement-tabel-action-btn-${id}`)
        let dropdown_items = document.getElementById(`labourManagement-tabel-action-dropdown-${id}`)

        const Event = (e) => {
            let path = e.path || (e.composedPath && e.composedPath());

            if (Array.isArray(path) && !path.includes(dropdown_items) && !path.includes(input)) {
                setSelectedAction('')
                window.removeEventListener('click', Event)
            }
        }

        window.addEventListener('click', Event)
    }

    useEffect(() => {
        getLabourDetials()
    }, [])

    const [SmarkCard_Items, setSmarkCard_Items] = useState([])

    const HandleSmartCardClick = (id) => { }

    const HandleSmartCardSetup = (items) => {

        items = items || []

        let total = items.length
        let total_active = items.filter(itm => itm.status == "1").length
        let total_inactive = items.filter(itm => itm.status == "0").length

        let smark_cards = [
            {
                id: 'lm-smart-top-cards-total',
                label: 'Total Labours',
                value: total || "0",
                icon: Icons.general.users,
                unit_name: '',
                style: {
                    color: '#0065ff',
                    bg_linear_colors: {
                        start: '#e5efff',
                        end: '#e5f9ff'
                    },
                    border_color: '#c6d4dc',
                },
            },
            {
                id: 'lm-smart-top-cards-total_active',
                label: 'Active Labours',
                value: total_active || "0",
                icon: Icons.general.users,
                unit_name: '',
                style: {
                    color: '#33c87e',
                    bg_linear_colors: {
                        start: '#eaf9f2',
                        end: '#f2fef7'
                    },
                    border_color: '#ccd8d2',
                },
            },
            {
                id: 'lm-smart-top-cards-outstanding_balance',
                label: 'Inactive Labours',
                value: total_inactive || "0",
                icon: Icons.general.users,
                unit_name: '',
                style: {
                    color: '#ff5b5b',
                    bg_linear_colors: {
                        start: '#ffeded',
                        end: '#fff9f9'
                    },
                    border_color: '#dcd1d1',
                },
            },
        ]

        setSmarkCard_Items(smark_cards)

    }


    return (
        <>

            {isLoading ?
                <Loading
                    props={{
                        isMainLogo: false,
                        isLabel: true
                    }} />
                : null}
            {warningAlert ?

                <SystemToastPopup
                    props={{
                        type: warningAlertType,
                        message: apiFailedMessage || "Error in Labours, Please try again!",
                        callback: (confirmation) => setWarningAlert(false)
                    }} />

                : null}

            {deleteItemAlert ?

                <AlertPopup
                    props={{
                        type: "delete",
                        actionBtnOption: { label: "Yes, Delete" },
                        heading: "Delete Labour",
                        message: 'Are you sure you want to delete the Labour?',
                        callback: (confirmation) => HandleDeleteConfirmItem(deleteItemId, deleteItemType, confirmation)
                    }} />

                : null}

            <Routes >
                <Route exact path='/add-labour' element={
                    <AddNewLabourPopup props={{
                        type: "create",
                        heading: "Create Labour",
                        callback: getLabourDetials,
                        close: CloseAddLabourPopup
                    }} />}></Route>
                <Route exact path='/edit/:labourId' element={
                    <AddNewLabourPopup props={{
                        type: "edit",
                        heading: "Edit Labour",
                        callback: getLabourDetials,
                        close: CloseAddLabourPopup,
                        detials: selected_termsCondition
                    }} />}></Route>
            </Routes>

            <div className="project-labourManagement-main">
                <div className="labourManagement-panel-content">

                    {SmarkCard_Items.length ?
                        <SmarkTopCards props={{
                            id: 'labourManagement-smart-top-cards',
                            cards: SmarkCard_Items || [],
                            callback: HandleSmartCardClick
                        }} />
                        : ''}

                    <div className="smart-panel-content-main">
                        <div className="panel-content-header">
                            <div className="title">All Labours</div>
                            <div className="buttons">
                                {has_add_access ?
                                    <div
                                        className="button add-button"
                                        onClick={(e) => HandleAddLabour()}
                                    >
                                        <div className="icon" dangerouslySetInnerHTML={{ __html: Icons.general.add_btn }}></div>
                                        <div className="label">Add User</div>
                                    </div>
                                    : ''}
                            </div>
                        </div>
                        <div className="panel-content-sections panel-content-tabel-section">
                            {LabourDetials.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).length ?
                                <Paper style={{ width: '100%', height: '100%', boxShadow: 'none', overflow: 'hidden' }}>
                                    <TableContainer style={{ height: '93%', boxShadow: 'none !important', }}>
                                        <Table stickyHeader>
                                            <TableHead
                                                className='tabel-header'
                                            >
                                                <TableRow>
                                                    {tabelHeader.map((column) => (
                                                        <TableCell
                                                            key={column.id}
                                                            align={column.align}
                                                            style={{ minWidth: column.minWidth }}
                                                            className='tabel-header-items'
                                                        >
                                                            {column.label}
                                                        </TableCell>
                                                    ))}
                                                </TableRow>
                                            </TableHead>
                                            <TableBody className='tabel-body'>
                                                {LabourDetials
                                                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                                    .map((row) => {
                                                        return (
                                                            <TableRow hover role="checkbox" tabIndex={-1} key={row.id}>
                                                                {tabelHeader.map((column) => {
                                                                    const value = row[column.id];
                                                                    return (
                                                                        <TableCell
                                                                            key={column.id}
                                                                            align={column.align}
                                                                            onClick={(e) => {
                                                                                if (column.id != 'actions') HanelCellAction(column.id, row)
                                                                            }}
                                                                        >
                                                                            {
                                                                                column.id == 'name' ?
                                                                                    <div
                                                                                        className="tabel-name"
                                                                                    >
                                                                                        {column.format ? column.format(value) : value}
                                                                                    </div>

                                                                                    : column.id == 'actions' ?

                                                                                        <div
                                                                                            className="tabel-action-main"
                                                                                        >

                                                                                            {has_edit_access ?
                                                                                                <div
                                                                                                    className="action-icon"
                                                                                                    dangerouslySetInnerHTML={{ __html: Icons.general.edit_btn }}
                                                                                                    onClick={(e) => HandleEditUser(row)}
                                                                                                ></div>
                                                                                                : ''}
                                                                                            {has_delete_access ?
                                                                                                <div
                                                                                                    className="action-icon"
                                                                                                    dangerouslySetInnerHTML={{ __html: Icons.general.delete_btn }}
                                                                                                    onClick={(e) => HandleDeleteUser(row)}
                                                                                                ></div>
                                                                                                : ''}

                                                                                        </div>
                                                                                        : column.format ? column.format(value) : value
                                                                            }
                                                                        </TableCell>
                                                                    );
                                                                })}
                                                            </TableRow>
                                                        );
                                                    })}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                    <TablePagination
                                        style={{ height: '7%', overflow: 'hidden' }}
                                        rowsPerPageOptions={[10, 25, 100]}
                                        component="div"
                                        count={LabourDetials.length || 0}
                                        rowsPerPage={rowsPerPage}
                                        page={page}
                                        onPageChange={handleChangePage}
                                        onRowsPerPageChange={handleChangeRowsPerPage}
                                    />
                                </Paper>
                                : <NoDataFound label={'No Labours Found'} />}
                        </div>
                    </div>
                </div>

            </div>
        </>
    )
}

export default Labours;