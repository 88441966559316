

import React, { useEffect, useState, useRef } from 'react'
import { Route, Routes, BrowserRouter, Link, useLocation, Navigate, Outlet, useNavigate, useParams } from 'react-router-dom';
import store from '../../redux/store'
import StoreHandler from '../../redux/StoreHandler'
import { useSelector, useDispatch } from 'react-redux'
import UserAction from '../../redux/action/userAction'
import AuthendicationHandler from '../../Handlers/Authendication/Authendication'

import Image from '../../assets/Images'
import Icons from '../../assets/Icons'

import { useAuth, useMenuAuth } from '../../hooks/useAuth'


const Header = () => {

    const { projectId } = useParams();
    const [isLoading, setIsLoading] = useState(false)

    const { isAdmin, roles_permissions, company_features } = useAuth()

    const store = useSelector((store) => store)
    const dispatch = useDispatch()
    const { updateState } = new UserAction
    const authendicationHandler = new AuthendicationHandler()
    const storeHandler = new StoreHandler({
        dataStack: ['builder'],
        store: store,
        dispatch: dispatch,
        updateState: updateState,
    })

    const LoadStoreData = async () => {
        setIsLoading(true)
        let resolved = await storeHandler.init()
        setIsLoading(false)
    }
    const [Location, setLocation] = useState(undefined)

    const [userDetials, setUserDetials] = useState({})
    const [profileImg_url, setProfileImg_url] = useState('')

    const [ActiveProject_ID, setActiveProject_ID] = useState('')
    const [ActiveProject_Name, setActiveProject_Name] = useState('')
    const [ActiveProject_Status, setActiveProject_Status] = useState('')
    const [ActiveProject_Status_Color, setActiveProject_Status_Color] = useState('')

    const navigate = useNavigate();


    const [ActiveModule, setActiveModule] = useState([])
    useEffect(() => {

        const currentURL = window.location.pathname;

        let currentMenuPath = currentURL ? currentURL.split(`/builder/`)[1] : undefined;

        if (!currentMenuPath) return ''

        let activeModule = String(currentMenuPath).split('/')
        if (!Array.isArray(activeModule)) return
        activeModule = activeModule[activeModule.length - 1]

        activeModule = String(activeModule).replace(/-/g, ' ');

        setActiveModule(activeModule)

    }, [window.location.pathname])

    const getUserLocation = () => {
        if ('geolocation' in navigator) {
            navigator.geolocation.getCurrentPosition(function (position) {

                setLocation({
                    lat: position.coords.latitude,
                    lng: position.coords.longitude,
                })

            }, function (error) {
                console.error(`Error getting location: ${error.message}`);
            });
        } else {
            console.error('Geolocation is not available in your browser');
        }
    }
    const LogoutEvent = async () => {
        try {


            let payload = {
                location: Location
            }
            setIsLoading(true)
            let respose = await authendicationHandler.signout(payload)

            setIsLoading(false)

            if (respose.success) {
                localStorage.removeItem('authToken');
                localStorage.removeItem('companydetials');
                localStorage.removeItem('userdetials');
                window.location.href = '/signin'
            } else {
                alert('Something went wrong, Please try again!')
            }


        } catch (error) {
            console.error('Error decoding authToken:', error);
        }
    }

    const makeCustomDropDown = (params) => {

        let { id, icon, isItemsLabel, itemsLabel, callback = () => { }, _classList, activeClass, isIcon, isImg, img, isLable, lablel, isOptions, options = [] } = params

        options = options || []
        _classList = _classList || []

        let active_dropdown_class = activeClass || "custom-dropdown-active"

        let container = document.createElement('div')

        _classList.push('custom-dropdown')

        container.classList.add(..._classList)
        container.id = id

        container.innerHTML = `
            <div class="custom-dropdown-label">
                ${isLable ? `<span class='lablel'>${lablel}</span>` : ''}
                ${isImg ? `<span class='img'><img src='${img}'/></span>` : ''}
                ${isIcon ? `<span class='icon'>${icon}</span>` : ''}
            </div>
            <div class="custom-dropdown-items-main">
                <span class='arrow-up'></span>
                ${isItemsLabel ? `<span class='dropdown-items-label'>${itemsLabel}</span>` : ''}
                <div class="custom-dropdown-items" id='custom-dropdown-items'>
                </div>
            </div>
        `

        let makeItem = (option) => {

            let { icon, isIcon, isLeft, lablel, callback } = option

            let item = document.createElement('div')
            item.classList.add('custom-dropdown-item')

            item.innerHTML = `

                <div class="custom-dropdown-item-label">
                    ${isLeft && isIcon ? `<span class='icon'>${icon}</span>` : ''}
                    <span class='lablel'>${lablel}</span>
                    ${!isLeft && isIcon ? `<span class='icon'>${icon}</span>` : ''}
                </div>

            `

            item.addEventListener('click', (e) => {
                callback(e)
                container.classList.remove(active_dropdown_class)
            })

            return item

        }

        let items_parent = container.querySelector('#custom-dropdown-items')

        if (isOptions) {

            options.forEach((opt, i) => {

                let item = makeItem(opt)
                items_parent.appendChild(item)
            })
        }


        let container_label = container.querySelector('.custom-dropdown-label')


        container_label.addEventListener('click', (e) => {

            if (isOptions) container.classList.toggle(active_dropdown_class)

            else callback(e)



        })

        window.addEventListener('click', (e) => {

            let path = e.path || (e.composedPath && e.composedPath());

            if (Array.isArray(path) && !path.includes(items_parent) && !path.includes(container_label))
                container.classList.remove(active_dropdown_class)
        })

        return container
    }

    const CustomDropDown = (dropdown, parentContainer) => {

        let hideContainer = (id, parentContainer) => {

            if (!id || !parentContainer) return false

            if (parentContainer.querySelector(`#${id}`))
                parentContainer.removeChild(parentContainer.querySelector(`#${id}`))

            return true
        }

        hideContainer(dropdown.id, parentContainer)

        let container = makeCustomDropDown(dropdown)
        parentContainer.appendChild(container)
    }

    const HandleDropDownEvents = () => {

        let parentContainer = document.querySelector('#project-header-main')
        let project_dropdown = parentContainer.querySelector('#project-select-dropdown')
        let notification_dropdown = parentContainer.querySelector('#notification-select-dropdown')
        let contact_dropdown = parentContainer.querySelector('#contact-select-dropdown')
        let help_dropdown = parentContainer.querySelector('#help-select-dropdown')
        let feedback_dropdown = parentContainer.querySelector('#feedback-select-dropdown')
        let profile_dropdown = parentContainer.querySelector('#profile-select-dropdown')

        let contacts_data = {
            id: "header-contact-dropdown-input",
            lablel: "Feedback",
            itemsLabel: 'SYSTEM SETTINGS',
            isItemsLabel: false,
            _classList: [],
            activeClass: 'custom-button-dropdown-active-norotate',
            isIcon: true,
            isImg: false,
            img: null,
            isLable: false,
            icon: Icons.general.whatsapp,
            isOptions: false,
            callback: () => {
                window.open('https://wa.link/2ktfa6', '_blank');
            },
            options: [
            ]
        }
        let notification_data = {
            id: "header-feedback-dropdown-input",
            lablel: "Feedback",
            itemsLabel: 'SYSTEM SETTINGS',
            isItemsLabel: false,
            _classList: [],
            activeClass: 'custom-button-dropdown-active-norotate',
            isIcon: true,
            isImg: false,
            img: null,
            isLable: false,
            icon: Icons.general.notification,
            isOptions: false,
            isNotification: true,
            callback: () => { navigate('/builder/notifications') },
            options: [
            ]
        }
        let profile_data = {
            id: "header-profile-dropdown-input",
            lablel: "Build Mate",
            itemsLabel: 'SYSTEM SETTINGS',
            isItemsLabel: false,
            _classList: ['custom-dropdown-img'],
            activeClass: 'custom-button-dropdown-active',
            isIcon: false,
            isImg: true,
            img: profileImg_url || Image.User,
            isLable: false,
            icon: Icons.general.dropdown_arrow,
            isOptions: true,
            callback: () => { },
            options: [
                {
                    icon: Icons.general.user,
                    isIcon: true,
                    isLeft: true,
                    lablel: "My Profile",
                    callback: () => { navigate('/builder/settings/myprofile') }
                },
                {
                    icon: Icons.general.settings,
                    isIcon: true,
                    isLeft: true,
                    lablel: "Settings",
                    callback: () => { navigate('/builder/settings/company') }
                },
                {
                    icon: Icons.general.logout,
                    isIcon: true,
                    isLeft: true,
                    lablel: "Log out",
                    callback: () => { LogoutEvent() }
                },
            ]
        }
        let feedback_data = {
            id: "header-feedback-dropdown-input",
            lablel: "Feedback",
            itemsLabel: 'SYSTEM SETTINGS',
            isItemsLabel: false,
            _classList: [],
            activeClass: 'custom-button-dropdown-active-norotate',
            isIcon: true,
            isImg: false,
            img: null,
            isLable: false,
            icon: Icons.general.chat,
            isOptions: false,
            callback: () => {

                if (projectId) navigate(`/builder/projects/${projectId}/chat`)
                else navigate('/builder/chat')

            },
            options: [
            ]
        }


        // temp redirection - remove after testing
        if (isAdmin) {
            profile_data.options.splice(2, 0, {
                icon: Icons.general.bill,
                isIcon: true,
                isLeft: true,
                lablel: "Subscription and billing",
                callback: () => { navigate('/builder/my-subscriptions') }
            })
        }

        CustomDropDown(contacts_data, contact_dropdown)
        CustomDropDown(notification_data, notification_dropdown)
        CustomDropDown(feedback_data, feedback_dropdown)
        CustomDropDown(profile_data, profile_dropdown)
    }

    const MobileSideBar_Event = (e) => {
        let sidebar_contanier = document.querySelector('#project-sidebar-main')

        let sidebar_active_class = "project-sidebar-active"

        if (sidebar_contanier) sidebar_contanier.classList.toggle(sidebar_active_class)
    }

    const HandleViewProjetDetails = () => {
        navigate(`/builder/projects/${projectId}/view`)
    }

    useEffect(() => {

        HandleDropDownEvents()
        getUserLocation()
        LoadStoreData()
    }, [])

    useEffect(() => {
        let user = store.user.builder

        if (user && Object.keys(user).length > 0) {
            setUserDetials(user)

            if (user.profile_img) setProfileImg_url(`data:image/png;base64,${user.profile_img}`)
        }

        HandleDropDownEvents()
    }, [store])

    useEffect(() => {

        HandleDropDownEvents()

    }, [store.user?.roles_permissions])

    useEffect(() => {

        let active_project = store.user?.active_project

        if (!active_project) return


        setActiveProject_ID(active_project.projectID)
        setActiveProject_Name(active_project.name)
        setActiveProject_Status(active_project.status_name)
        setActiveProject_Status_Color(active_project.status_color)


    }, [store.user?.active_project])

    return (
        <div className='project-header-main' id='project-header-main'>
            <div className="header-content">
                <div className="header-left">

                    <div className="header-left-item">
                        <Link to={'/quick-menu'}><img className='header-logo' src={Image.logo} /></Link>
                    </div>
                    <div
                        className="header-right-item header-mobile-menu-btn header-mobile-menu-disabled"
                        id='header-mobile-menu-btn'
                        dangerouslySetInnerHTML={{ __html: Icons.general.mobile_menu }}
                        onClick={(e) => MobileSideBar_Event(e)}
                    >
                    </div>
                    {projectId ?
                        <div className="header-project_id-main">
                            <div className="project_id-name">
                                <div className="default-label">All Projects</div>
                                <div className="default-slash">/</div>
                                <div className="project_name">{ActiveProject_Name}</div>
                            </div>

                            <div
                                className="project_id-id"
                                onClick={(e) => HandleViewProjetDetails()}
                            >
                                <div className="label">Project ID: </div>
                                <div className="value">{ActiveProject_ID}</div>
                                <div className="icon"
                                    dangerouslySetInnerHTML={{ __html: Icons.general.info_bg }}
                                ></div>
                            </div>
                        </div>
                        :
                        <div className="header-project_id-main">
                            <div className="project_id-name">
                                <div className="project_name">{ActiveModule}</div>
                            </div>
                        </div>
                    }
                </div>
                <div className="header-right">
                    {projectId ?
                        <div
                            className="header-project_status-main"
                            style={{ borderColor: ActiveProject_Status_Color }}
                        >

                            <div className="label">Project Status: </div>
                            <div className="value" style={{ color: ActiveProject_Status_Color }}>{ActiveProject_Status}</div>
                        </div>
                        : ''}

                    <div className="header-right-item" id='contact-select-dropdown'></div>
                    <div className="header-right-item" id='feedback-select-dropdown'></div>
                    <div className="header-right-item" id='notification-select-dropdown'></div>
                    <div className="header-right-item" id='profile-select-dropdown'></div>
                </div>
            </div>
        </div>
    )
}

export default Header;
