
import React, { useEffect, useState } from 'react'
import { Route, Routes, BrowserRouter, Link, useLocation, Navigate, Outlet, useNavigate, useParams } from 'react-router-dom';
import Images from '../assets/Images'
import Icons from '../assets/Icons'
import ReverseProxy from '../config/reverseProxy'

import AlertPopup from './AlertPopup'
import Loading from './Loading'
import SystemToastPopup from './SystemToastPopup'
import Utils from '../utils';


import { load } from '@cashfreepayments/cashfree-js';

import CashFree_PaymentgatewayHandler from '../Handlers/PaymentGateways/CashFree_Paymentgateway'
import SubscriptionsHandler from '../Handlers/Subscriptions/Subscriptions'


const subscriptionsHandler = new SubscriptionsHandler()

const PendingSubscriptionRequestPopup = ({ props }) => {

    const { callback, request_data } = props
    const navigate = useNavigate
    const [ShowContactUsResult, setShowContactUsResult] = useState(false)

    const [isLoading, setIsLoading] = useState(false)
    const [warningAlert, setWarningAlert] = useState(false)
    const [warningAlertType, setWarningAlertType] = useState('warning')
    const [apiFailedMessage, setApiFailedMessage] = useState("Error in Server")

    const [Request_ID, setRequest_ID] = useState('N/A')
    const [Request_Date, setRequest_Date] = useState('N/A')
    const [Request_Expiry, setRequest_Expiry] = useState('N/A')
    const [Request_Status, setRequest_Status] = useState('N/A')
    const [Request_Plan_Id, setRequest_Plan_Id] = useState('N/A')
    const [Request_Plan_Name, setRequest_Plan_Name] = useState('N/A')
    const [Request_Plan_Amount, setRequest_Plan_Amount] = useState('N/A')
    const [Request_Duration, setRequest_Duration] = useState('N/A')
    const [Request_Duration_Type, setRequest_Duration_Type] = useState('N/A')
    const [Request_Subscription_Amount, setRequest_Subscription_Amount] = useState('N/A')
    const [Request_Payment_OrderStatus, setRequest_Payment_OrderStatus] = useState('')
    const [Request_Payment_OrderId, setRequest_Payment_OrderId] = useState('')
    const [Request_Payment_OrderSessionId, setRequest_Payment_OrderSessionId] = useState('')


    const HandleProcessSubscribtion = async (payment_session_id) => {

        if (!payment_session_id) {
            return false
        }

        const CashFree_PaymentGateWay = await load({
            mode: "sandbox" //or production
        });

        let version = CashFree_PaymentGateWay.version();

        let checkoutOptions = {
            paymentSessionId: payment_session_id,
            // returnUrl: "http://localhost:8000/api/paymentgateway/cashfree/redirect-order/{order_id}",
            returnUrl: "https://civilator.in/api/paymentgateway/cashfree/redirect-order/{order_id}",
        }
        CashFree_PaymentGateWay.checkout(checkoutOptions).then(function (result) {
            if (result.error) {
                alert(result.error.message);
            }
            if (result.redirect) {
                console.log("Redirection")
                console.log(result);
            }
        });

    }

    const HandleRemaindLater = async (e) => {

        callback(true)
    }
    const HandleCancelPayment = async (e) => {




        let update_data = {
            id: Request_ID,
            payment_order_id: Request_Payment_OrderId,
            status: '3',
        }

        setIsLoading(true)


        let update_response = await subscriptionsHandler.UpdatePendingSubscriptionRequest(update_data)

        setIsLoading(false)


        if (update_response.success) {
            callback(true)
        } else {
            setWarningAlert(true)
            setWarningAlertType('error')
            setApiFailedMessage('Error in Updating Subscription Request')
        }


    }
    const HandleSubscribe = async (e) => {

        if (Request_Payment_OrderSessionId) HandleProcessSubscribtion(Request_Payment_OrderSessionId)
        callback(true)

    }



    useEffect(() => {
        console.log(request_data, 'request_data');

        if (request_data && Object.keys(request_data).length) {

            setRequest_ID(request_data.id || 'N/A')
            setRequest_Date(Utils.getLocalFullDateLabel(request_data.created_at) || 'N/A')
            setRequest_Expiry(Utils.getLocalFullDateLabel(request_data.order_expiry_time) || 'N/A')
            setRequest_Status(request_data.status || 'N/A')
            setRequest_Plan_Id(request_data.plan_id || 'N/A')
            setRequest_Plan_Name(request_data.plan_name || 'N/A')
            setRequest_Plan_Amount(request_data.plan_amount || 'N/A')
            setRequest_Duration(request_data.duration || 'N/A')
            setRequest_Duration_Type(request_data.duration_type || 'N/A')
            setRequest_Subscription_Amount(request_data.subscription_amount || 'N/A')
            setRequest_Payment_OrderStatus(request_data.order_status || 'N/A')
            setRequest_Payment_OrderId(request_data.payment_order_id || 'N/A')
            setRequest_Payment_OrderSessionId(request_data.payment_session_id || 'N/A')
        }
    }, [])


    return (
        <div className="popup-container-main popup-container-center">
            <div className="popup-block-ui"></div>

            {isLoading ?

                <Loading
                    props={{
                        isMainLogo: false,
                        isLabel: true
                    }} />

                : null}

            {warningAlert ?

                <SystemToastPopup
                    props={{
                        type: warningAlertType,
                        message: apiFailedMessage || "Error in Bill",
                        callback: (confirmation) => setWarningAlert(false)
                    }} />
                : null}

            <div className="subscription-exiry-popup-main">
                <div className="subscription-exiry-content">
                    <div className="heading">Your've a pending Subscription request!</div>
                    <div className="pending-request-detials">
                        <div className="top-detials">
                            <div className="left">
                                <div className="plan-desc">{Request_Date}</div>
                                <div className="plan-name">{Request_Plan_Name}</div>
                            </div>
                            <div className="right">
                                <div className="plan-duration">{Request_Duration} {Request_Duration_Type == '2' ? 'Yrs' : 'Mnt'} /</div>
                                <div className="plan-amount">₹{Request_Subscription_Amount}</div>
                            </div>
                        </div>
                        <div className="bottom-detials">
                            <div className="left">
                                <div className="plan-expiry">Expiry : <span className='strong'>{Request_Expiry}</span></div>
                            </div>
                            <div className="right">
                                <div className={`plan-status plan-status-going-to-expiry`}>PENDING</div>
                            </div>
                        </div>
                    </div>
                    <div className="action-buttons">
                        <div
                            className="button subscribe-button"
                            onClick={(e) => HandleSubscribe(e)}
                        >PROCESS NOW</div>
                        <div
                            className="button subscribe-contactus"
                            onClick={(e) => HandleRemaindLater(e)}
                        >REMAINE LATER</div>
                        <div
                            className="button subscribe-ignore"
                            onClick={(e) => HandleCancelPayment(e)}
                        >CANCEL</div>
                    </div>
                </div>

            </div>
        </div>
    )

}

export default PendingSubscriptionRequestPopup;