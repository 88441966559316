

import React, { useEffect, useState, useRef, useCallback, useMemo, StrictMode } from 'react'
import { Route, Routes, BrowserRouter, Link, useLocation, Navigate, Outlet, useNavigate, useParams } from 'react-router-dom';
import store from '../../redux/store'
import StoreHandler from '../../redux/StoreHandler'
import { useSelector, useDispatch } from 'react-redux'
import UserAction from '../../redux/action/userAction'

import ReverseProxy from '../../config/reverseProxy'
import SettingsHandler from '../../Handlers/Settings/Settings';
import EstimateHandler from '../../Handlers/Estimate/Estimate';
import PurchaseOrderHandler from '../../Handlers/PurchaseOrder/PurchaseOrder';
import BillsHandler from '../../Handlers/Bills/Bills';
import CommentsHandler from '../../Handlers/Comments/Comments';

import Icons from '../../assets/Icons'
import Images from '../../assets/Images'
import { CostInput, TextInput, SelectInput, DateTimeInput, RadioInput } from '../../components/Inputs'
import Utils from '../../utils'
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

import SystemToastPopup from '../../components/SystemToastPopup'
import AlertPopup from '../../components/AlertPopup'
import Loading from '../../components/Loading'

import PreviewClientInvoice from './previewBill'
import BuilderAttachments from '../../Handlers/BuilderAttachments/BuilderAttachments';
import ImagePreviewPopup from '../../components/ImagePreviewPopup'

import { useAuth, useMenuAuth } from '../../hooks/useAuth'

const AddPaymentHistory = ({ props }) => {

    const { type, existItemData, max_amount, updateCallback, close } = props


    const { projectId } = useParams()

    const [date, setDate] = useState(Utils._getLocalDate())
    const [amount, setAmount] = useState('')
    const [payment_method, setPayment_method] = useState('')
    const [notes, setNotes] = useState('')

    const navigate = useNavigate()
    const store = useSelector((store) => store)
    const dispatch = useDispatch()
    const { updateState } = new UserAction


    const isExistItemData = existItemData && existItemData.id != undefined
    const [paymentMenthodOptions, setPaymentMenthodOptions] = useState([
        {
            value: 'Bank transfer',
            label: 'Bank transfer'
        },
        {
            value: 'Direct Deposit',
            label: 'Direct Deposit'
        },
        {
            value: 'Credit card',
            label: 'Credit card'
        },
        {
            value: 'Cash',
            label: 'Cash'
        },
        {
            value: 'Check',
            label: 'Check'
        },
        {
            value: 'UPI',
            label: 'UPI'
        },
        {
            value: 'Other',
            label: 'Other'
        },
    ])


    const HandlePopupSubmit = async (e) => {
        e.preventDefault()

        let updated_data = {
            id: '',
            date,
            amount,
            payment_method,
            notes,

        }

        if (type == 'edit' && isExistItemData && existItemData.id) {
            updated_data.id = existItemData.id
        }
        else { updated_data.id = Utils.getUniqueId() }

        console.log(updated_data, 'updated_data');

        updateCallback(updated_data)


    }


    return (
        <div className="popup-container-main popup-container-center">
            <div className="popup-block-ui"></div>
            <form className="side-popup-container create-payment_history-main" onSubmit={HandlePopupSubmit}>

                <div className="side-popup-header">
                    <div className="header-item-select">
                        <div className="header-item-select-content">
                            <div className="label">Record Payments</div>

                        </div>
                    </div>
                    <div
                        className="header-item-close"
                        onClick={(e) => close()}
                        dangerouslySetInnerHTML={{ __html: Icons.general.close }}
                    ></div>
                </div>

                <div className="sidebar-popup-content">
                    <div className="content-section">
                        <div className="content-section-items">


                            <div className="content-item">
                                <DateTimeInput
                                    props={{
                                        id: "create-paymenthistory-date",
                                        value: date,
                                        placeholder: '',
                                        setValue: (value) => {
                                            setDate(value)
                                        },
                                        isIcon: false,
                                        isLabel: true,
                                        icon: Icons.general.schedule,
                                        isRequired: true,
                                        label: "Date",
                                    }}
                                />
                            </div>
                            <div className="content-item">
                                <TextInput
                                    props={{
                                        id: "create-paymenthistory-amount",
                                        value: amount,
                                        placeholder: '',
                                        setValue: (value) => setAmount(value),
                                        isIcon: false,
                                        isLabel: true,
                                        isRequired: true,
                                        type: 'number',
                                        setMaxLimit: max_amount || 0,
                                        maxLimitLabel: true,
                                        label: "Amount",
                                    }}
                                />
                            </div>
                            <div className="content-item">
                                <SelectInput
                                    props={{
                                        id: "estimation-group-group",
                                        value: payment_method,
                                        placeholder: '',
                                        options: paymentMenthodOptions || [],
                                        setValue: (value, label) => {
                                            setPayment_method(value)
                                        },
                                        isIcon: false,
                                        isLabel: true,
                                        isRequired: true,
                                        label: "Payment method",
                                    }}
                                />

                            </div>
                        </div>
                        <div className="content-section">
                            <div className="content-item">
                                <div id="group-description" className={`additional-item additional-item-active`}>
                                    <div className="head">
                                        <div className="icon" dangerouslySetInnerHTML={{ __html: Icons.general.description }}></div>
                                        <div className="label">Notes</div>
                                    </div>
                                    <div className="item-expanded">
                                        <textarea onChange={(e) => setNotes(e.target.value)}>{notes}</textarea>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>

                <div className="sidebar-popup-footer">
                    <div className="footer-item action-items">
                        <div className="action-preview">
                        </div>
                        <button
                            className="action-btn"
                            type='submit'
                        >

                            <div className="label">Record payment</div>
                        </button>
                    </div>
                </div>
            </form>
        </div>
    )
}


const ViewBill = ({ props }) => {

    const { projectId, billId } = useParams();

    const { menuType = 'create' } = props

    const [project_id, setProject_id] = useState('')
    const [project_name, setProject_name] = useState('')

    const [isLoading, setIsLoading] = useState(false)
    const [warningAlert, setWarningAlert] = useState(false)
    const [warningAlertType, setWarningAlertType] = useState('warning')
    const [apiFailedMessage, setApiFailedMessage] = useState('Error in Bills')

    const navigate = useNavigate()
    const store = useSelector((store) => store)
    const dispatch = useDispatch()
    const { updateState } = new UserAction

    const formSubmitBtnRef = useRef()

    const storeHandler = new StoreHandler({
        dataStack: ['company', 'sor_materials', 'vendors', 'terms_conditions', 'field_settings', 'finance_settings', `project-${projectId}`],
        store: store,
        dispatch: dispatch,
        updateState: updateState,
    })

    const LoadStoreData = async () => {
        setIsLoading(true)
        let resolved = await storeHandler.init()
        setIsLoading(false)
    }

    const { isAdmin, menu_permission } = useMenuAuth({ menu: 'purchase--bills', feature_key: 'bills' })
    const location = useLocation()

    const [selected_view_settings_value, setSelected_view_settings_value] = useState('')
    const [has_view_access, setHas_view_access] = useState(false)
    const [has_add_access, setHas_add_access] = useState(false)
    const [has_edit_access, setHas_edit_access] = useState(false)
    const [has_delete_access, setHas_delete_access] = useState(false)

    useEffect(() => {

        if (isAdmin) {

            setHas_view_access(true)
            setHas_add_access(true)
            setHas_edit_access(true)
            setHas_delete_access(true)
        }

        else if (menu_permission) {

            if (
                !menu_permission._view
                &&
                (!menu_permission.selected_view_settings_value
                    || menu_permission.selected_view_settings_value == 'no_access')
            ) {
                navigate(`${ReverseProxy['proxyUrl']}/quick-menu`, { state: { from: location }, replace: true })
            }
            setHas_view_access(menu_permission._view == '1')
            setHas_add_access(menu_permission._create == '1')
            setHas_edit_access(menu_permission._edit == '1')
            setHas_delete_access(menu_permission._delete == '1')

            setSelected_view_settings_value(menu_permission.selected_view_settings_value)

        }

    }, [store])

    const purchaseOrderHandler = new PurchaseOrderHandler()
    const billsHandler = new BillsHandler()
    const commentsHandler = new CommentsHandler()
    const builderAttachments = new BuilderAttachments()

    const [materialsOptions, setMaterialsOptions] = useState([])
    const [materialsOptions_Orginal, setMaterialsOptions_Orginal] = useState([])

    const [unitsOptions, setUnitsOptions] = useState([])
    const [currency_type, setCurrency_type] = useState('₹')

    const [id, setId] = useState(Utils.getUniqueId())
    const [name, setName] = useState('')
    const [billID, setBillID] = useState('')
    const [status, setStatus] = useState('')

    const [billDate, setBillDate] = useState(Utils._getLocalDate())
    const [dueDate, setDueDate] = useState('')

    const [BillSubTotal, setBillSubTotal] = useState(0)
    const [BillPretaxSubTotal, setBillPretaxSubTotal] = useState(0)
    const [BillTotal, setBillTotal] = useState(0)
    const [BillTotalTax, setBillTotalTax] = useState(0)
    const [BillTotalMarkup, setBillTotalMarkup] = useState(0)
    const [BillPaid, setBillPaid] = useState(0)
    const [BillDue, setBillDue] = useState(0)

    const [introduction_text, setIntroduction_text] = useState('')
    const [comments, setComments] = useState('')

    const [purchase_order_id, setPurchase_order_id] = useState('')
    const [purchase_order_name, setPurchase_order_name] = useState('')

    const [bill_reference, setBillReference] = useState('')

    const [vendor_id, setVendor_id] = useState('')
    const [vendor_name, setVendor_name] = useState('')

    const [purchaseOrdersOptions, setPurchaseOrdersOptions] = useState([])

    const [vendorsOptions, setVendorsOptions] = useState([])

    const [statusOptions, setBillStatusOptions] = useState([
        {
            value: 0,
            label: "Pending",
            isDefault: false
        },
        {
            value: 1,
            label: "Approved",
            isDefault: false
        },
        {
            value: 2,
            label: "Rejected",
            isDefault: false
        },
    ])


    const [remaniderOptions, setRemaniderOptions] = useState([
        {
            value: 0,
            label: "Pending",
            isDefault: false
        },
        {
            value: 1,
            label: "Approved",
            isDefault: false
        },
        {
            value: 2,
            label: "Rejected",
            isDefault: false
        },
    ])

    const [termsConditionOptions, setTermsConditionOptions] = useState([])
    const [termsConditionId, setTermsConditionId] = useState("")
    const [termsConditionValue, setTermsConditionValue] = useState("")

    const HandleTermsConditions = (value, label) => {

        setTermsConditionId(value)

        let selected_TC = termsConditionOptions.filter(tc => tc.value == value)[0]
        if (selected_TC) setTermsConditionValue(selected_TC.body)
    }

    const [billLoaded, setBillLoaded] = useState(false)

    const [materialItems, setMaterialItems] = useState([
        {
            id: Utils.getUniqueId(),
            material_id: "",
            material_name: "",
            unitrate: 0,
            tax: 0,
            markup: 0,
            tax_amount: 0,
            markup_amount: 0,
            final_unitrate: 0,
            amount: 0,
            final_amount: 0,
            pre_tax_amount: 0,
            value_type: `$`,
            unit_id: '',
            unit_name: '',
            quantity: 0,
            description: '',
            notes: ''
        }
    ])

    const [paymentHistory, setPaymentHistory] = useState([])
    const [paymentHistoryStatusOptions, setPaymentHistoryStatusOptions] = useState([
        {
            value: 'draft',
            label: "Draft",
        },
        {
            value: 'send',
            label: "Send",
        },
        {
            value: 'paid',
            label: "Paid",
        },
        {
            value: 'void',
            label: "Void",
        },
    ])
    const [showAddpaymentHistory, setShowAddpaymentHistory] = useState(false)
    const [paymentHistoryAlert, setPaymentHistoryAlert] = useState(false)
    const [paymentHistoryMessage, setPaymentHistoryMessage] = useState('By recording payment method the status of the item will be changed to approved')

    const HandleAddPaymentHistory = (confirmation = false) => {

        if (confirmation) setShowAddpaymentHistory(true)

    }
    const HandleUpdatePaymentHistory = (payment_history, type = 'create') => {

        let _paymentHistory = [...paymentHistory]
        if (type == 'create') _paymentHistory.push(payment_history)
        else {
            let idx = _paymentHistory.findIndex(p => p.id == payment_history.id)
            if (idx != -1) _paymentHistory[idx] = payment_history
        }

        setPaymentHistory(_paymentHistory)

    }


    const HandlePaymentHistoryDelete = (item, idx) => {

        let _paymentHistory = [...paymentHistory]

        _paymentHistory.splice(idx, 1)
        setPaymentHistory(_paymentHistory)
    }

    const HandlePaymentHistoryItemValue = (value, idx, key) => {

        let _paymentHistory = [...paymentHistory]

        _paymentHistory[idx][key] = value

        setPaymentHistory(_paymentHistory)
    }


    const HandleMaterialOptionsSelected = () => {

        let material_items = [...materialItems].map(c => c.material_id)

        let _materialsOptions = [...materialsOptions]

        _materialsOptions = _materialsOptions.map(option => {

            if (material_items.includes(option.value)) option.disabled = true
            else option.disabled = false
            return option
        })

        setMaterialsOptions(_materialsOptions)

    }

    const HandlePaymentsInputChange = (value, index, key, label) => {

        let materialItems_ = [...materialItems]

        if (key == 'material') {

            materialItems_[index]['material_id'] = value
            materialItems_[index]['material_name'] = label

            HandleMaterialOptionsSelected()

            let original_item = materialsOptions_Orginal.filter(m => m.id == value)[0]

            if (original_item) {

                materialItems_[index]['unit_id'] = original_item.unit_id
                materialItems_[index]['unit_name'] = original_item.unit_name
                materialItems_[index]['unitrate'] = parseFloat(original_item.price || 0)
            }
        }

        else materialItems_[index][key] = value

        let unit_quandity_amount = parseFloat(materialItems_[index]['unitrate'] || 0) * parseFloat(materialItems_[index]['quantity'] || 0)
        let final_unitrate = parseFloat(materialItems_[index]['unitrate'] || 0) * parseFloat(materialItems_[index]['markup'] || 0) / 100

        materialItems_[index]['amount'] = parseFloat(materialItems_[index]['unitrate'] || 0) * parseFloat(materialItems_[index]['quantity'] || 0)

        materialItems_[index]['final_unitrate'] = parseFloat(materialItems_[index]['unitrate'] || 0) + final_unitrate

        materialItems_[index]['markup_amount'] = unit_quandity_amount * parseFloat(materialItems_[index]['markup'] || 0) / 100
        materialItems_[index]['tax_amount'] = (unit_quandity_amount + materialItems_[index]['markup_amount']) * parseFloat(materialItems_[index]['tax'] || 0) / 100

        materialItems_[index]['final_amount'] = parseFloat(materialItems_[index]['tax_amount'] || 0) + parseFloat(materialItems_[index]['amount'] || 0) + parseFloat(materialItems_[index]['markup_amount'] || 0)
        materialItems_[index]['pre_tax_amount'] = parseFloat(materialItems_[index]['amount'] || 0) + parseFloat(materialItems_[index]['markup_amount'] || 0)


        setMaterialItems(materialItems_)
    }
    const HandlePaymentsRemove = (index) => {
        let materialItems_ = [...materialItems]
        if (index == 0) {

            materialItems_[index] = {
                id: Utils.getUniqueId(),
                name: "",
                value: 0,
                amount: 0
            }
            setMaterialItems(materialItems_)
            return
        }
        materialItems_.splice(index, 1)
        setMaterialItems(materialItems_)
    }
    const HandlePaymentsAddNew = () => {

        let materialItems_ = [...materialItems]
        materialItems_.push({
            id: Utils.getUniqueId(),
            material_id: "",
            material_name: "",
            unitrate: 0,
            tax: 0,
            markup: 0,
            tax_amount: 0,
            markup_amount: 0,
            final_unitrate: 0,
            amount: 0,
            final_amount: 0,
            pre_tax_amount: 0,
            value_type: `$`,
            unit_id: '',
            unit_name: '',
            quantity: 0,
            description: '',
            notes: ''
        })
        setMaterialItems(materialItems_)
    }

    const getStatusLabel = (status) => {

        let statusLabel = statusOptions.filter(opt => opt.value == status)
        if (statusLabel.length) return statusLabel[0].label
        else return ''
    }

    const [showCreatePreview, setShowCreatePreview] = useState(false)

    const HandlepreviewClientInvoice = (e) => {
        navigate(`/builder/projects/${projectId}/bills/${billId}/view/preview`)

    }
    const HandleEditClientInvoice = (e) => {
        if (!has_edit_access) return
        navigate(`/builder/projects/${projectId}/bills/${billId}/edit`)

    }
    const HandleCancel = (e) => {

        navigate(`/builder/projects/${projectId}/bills`)
    }

    const HandleSubmit = async (e) => {
        e.preventDefault()

        let update_detials = {
            id: id,
            name: name,
            billId: billID,
            projectId: projectId,

            purchase_order_id: String(""), // String(purchase_order_id)
            purchase_order_name: String(""), // String(purchase_order_name)

            vendor_id: String(vendor_id),
            vendor_name: String(vendor_name),

            bill_reference: String(bill_reference),

            bill_date: billDate,
            due_date: dueDate,
            status: String(status),
            bill_total: String(BillTotal || 0),
            bill_paid: String(BillPaid || 0),
            bill_due: String(BillDue || 0),

            introduction_text: introduction_text || "",
            comments: comments || "",

            terms_condition_id: termsConditionId || "",
            terms_condition: termsConditionValue || "",

            cost_items: materialItems || [],
            attachments: AttachmentsFiles || [],
            payment_history: paymentHistory || [],

        }


        let response = { success: false }

        if (menuType == 'edit') {

            update_detials["id"] = id
            response = await billsHandler.updateBillHandler(update_detials)

        } else {
            response = await billsHandler.createBillHandler(update_detials)

            let update_data = {
                project_id: projectId,
                parent_id: billID,
                parent_name: name || `Bill ${billID}`,
                parent_type: String(0),
                comments: CommentsItems
            }

            let comments_response = await commentsHandler.createMultiCommentHandler(update_data)

            if (!comments_response.success) {
                setWarningAlert(true)
                setWarningAlertType('error')
                setApiFailedMessage(`Filed to create comments`)
            }
        }

        if (response.success) {
            setIsLoading(false)
            navigate(`/builder/projects/${projectId}/bills`)
        }
        else {
            setIsLoading(false)
            setWarningAlert(true)
            setWarningAlertType('error')
            setApiFailedMessage(`Error in Bills, Please try again!`)
        }
    }

    const getNextProjectID = async () => {

        let response = await billsHandler.getNextBillsIDHandler()

        if (response && response.success) {

            let { billId } = response.data

            if (billId) setBillID(billId)

        } else {
            setWarningAlert(true)
            setWarningAlertType('error')
            setApiFailedMessage(`Error in Bills, Please try again!`)
        }
    }


    const LoadClientInvoice = async () => {

        let filter = {
            billId: billId,
            projectId: projectId,
        }

        setIsLoading(true)

        let response = await billsHandler.getBillssHandler(filter)
        setIsLoading(false)

        if (response.success) {
            let { data } = response

            if (!Array.isArray(data) || !data.length) {
                return navigate(`/builder/projects/${projectId}/bills`)
            }


            let {
                id: ID,
                name,
                billID,
                purchase_order_id,
                purchase_order_name,
                vendor_id,
                vendor_name,
                bill_reference,
                projectId: projectID,
                bill_date,
                due_date,
                status,
                bill_total,
                bill_paid,
                bill_due,
                introduction_text,
                comments,
                terms_condition_id,
                terms_condition,
                cost_items,
                payment_history,
                attachments
            } = data[0]

            setId(ID)
            setBillID(billID)
            setName(name)
            setStatus(status)
            setIntroduction_text(introduction_text)

            setPurchase_order_id(purchase_order_id)
            setPurchase_order_name(purchase_order_name)

            setVendor_id(vendor_id)
            setVendor_name(vendor_name)

            setBillReference(bill_reference)

            setTermsConditionId(terms_condition_id)
            setTermsConditionValue(terms_condition)
            setComments(comments)

            setBillDate(bill_date)
            setDueDate(due_date)

            setBillTotal(bill_total)
            setBillPaid(bill_paid)
            setBillDue(bill_due)

            setMaterialItems(cost_items || [])
            setPaymentHistory(payment_history || [])
            setAttachmentsFiles(attachments || [])

            setBillLoaded(true)

        }
    }

    const getPurchaseOrders = async () => {

        let filter = {
            projectId: projectId
        }

        let response = await purchaseOrderHandler.getBasicPurchaseOrdersHandler(filter)

        if (response && response.success) {

            if (!response.data || !Array.isArray(response.data) || !response.data.length) return

            let purchase_orders = response.data.map((opt) => {
                return {
                    value: opt.purchaseID,
                    label: opt.name,
                    isDefault: false
                }
            })

            setPurchaseOrdersOptions(purchase_orders || [])


        } else {
            setWarningAlert(true)
            setWarningAlertType('error')
            setApiFailedMessage(`Error in Purchase Orders`)
        }
    }

    useEffect(() => {

        if (menuType == 'create') {
            getNextProjectID()
        }
        else {
            LoadClientInvoice()
            LoadComments({ project_id: projectId, parent_id: billId })
        }

        getPurchaseOrders()
        LoadStoreData()
    }, [])


    useEffect(() => {

        let total_amounts = materialItems.reduce((prev, val, i) => {

            return {
                pre_tax_amount: parseFloat(prev.pre_tax_amount || 0) + parseFloat(val.pre_tax_amount || 0),
                tax_amount: parseFloat(prev.tax_amount || 0) + parseFloat(val.tax_amount || 0),
                markup_amount: parseFloat(prev.markup_amount || 0) + parseFloat(val.markup_amount || 0),
                amount: parseFloat(prev.amount || 0) + parseFloat(val.amount || 0),
                final_amount: parseFloat(prev.final_amount || 0) + parseFloat(val.final_amount || 0),
            }

        }, {
            pre_tax_amount: 0,
            tax_amount: 0,
            markup_amount: 0,
            amount: 0,
            final_amount: 0

        })
        setBillSubTotal(total_amounts.amount)
        setBillPretaxSubTotal(total_amounts.pre_tax_amount)
        setBillTotal(total_amounts.final_amount)
        setBillTotalTax(total_amounts.tax_amount)
        setBillTotalMarkup(total_amounts.markup_amount)


        let paid_amount = paymentHistory.reduce((prev, val, i) => {

            return prev + parseFloat(val.amount || 0)
        }, 0)
        setBillPaid(paid_amount)

        let due_amount = total_amounts.final_amount - paid_amount
        due_amount = due_amount < 0 ? 0 : due_amount
        setBillDue(due_amount)


    }, [materialItems, paymentHistory])

    useEffect(() => {

        if (store && store.user) {

            let { company, sor_materials, vendors, settings } = store.user

            if (company) {

                let { currency_setting, timezone, temperature_setting } = company

                if (currency_setting) setCurrency_type(currency_setting)
            }
            if (Array.isArray(sor_materials)) {

                let _sor_materials = sor_materials.map((opt) => {
                    return {
                        value: opt.id,
                        label: opt.name,
                    }
                })
                setMaterialsOptions(_sor_materials)
                setMaterialsOptions_Orginal(sor_materials)
            }
            if (settings && Array.isArray(settings.terms_conditions) && settings.terms_conditions.length) {



                let terms_conditions = settings.terms_conditions.map(tc => {
                    return {
                        id: tc.id,
                        value: tc.id,
                        label: tc.name,
                        body: tc.body,
                        isDefault: false,
                        document: tc.document || []
                    }
                })

                setTermsConditionOptions([
                    {
                        id: "none",
                        value: "none",
                        label: "None",
                        body: "",
                        isDefault: false,
                        document: []
                    },
                    ...terms_conditions
                ])
            }

            if (settings && Array.isArray(settings.field_settings) && settings.field_settings.length) {

                let { project_statuses, project_types, units } = settings.field_settings[0]

                units = units.filter(opt => opt.isActive).map((opt) => {
                    return {
                        value: opt.id,
                        label: opt.name,
                        isDefault: opt.isDefault || false
                    }
                })

                setUnitsOptions(units || [])

            }
            if (vendors && Array.isArray(vendors) && vendors.length) {

                let vendor_options = vendors.filter(opt => opt.status == '1').map((opt) => {
                    return {
                        value: opt.vendorID,
                        label: opt.name,
                        isDefault: false
                    }
                })

                setVendorsOptions(vendor_options || [])

            }

        }

    }, [store])



    const [ShowSelectedPreviewImage, setShowSelectedPreviewImage] = useState(false)
    const [SelectedPreviewImage, setSelectedPreviewImage] = useState(undefined)
    const AttachmentsInputRef = useRef()
    const [AttachmentsFiles, setAttachmentsFiles] = useState([])

    const HandleTriggerAddAttachment = (e) => {
        if (AttachmentsInputRef.current) AttachmentsInputRef.current.click()
    }
    const HandleAddAttachment = async (e) => {

        let file = e.target.files[0]

        if (!file) return

        const formData = new FormData();

        formData.append('projectId', projectId);
        formData.append('attachment', file);
        formData.append('linked_type', '5');
        formData.append('linked_item_id', id);

        setIsLoading(true)

        let upload_response = await builderAttachments.createAttachmentHandler(formData)

        setIsLoading(false)

        if (upload_response.success) {

            let { public_id, url } = upload_response.data

            if (!public_id || !url) return

            let _AttachmentsFiles = [...AttachmentsFiles]

            _AttachmentsFiles.push({
                public_id: public_id,
                original_filename: file.name,
                bytes: file.size,
                format: file.type,
                file_url: url,
                linked_type: '5',
                linked_item_id: id,
            })

            setAttachmentsFiles(_AttachmentsFiles)

        } else {
            setWarningAlert(true)
            setWarningAlertType('error')
            setApiFailedMessage(`Error in Attachments, Please try again!`)
        }
    }
    const HandleDeleteAttachment = async (idx) => {

        let _AttachmentsFiles = [...AttachmentsFiles]

        setIsLoading(true)

        let upload_response = await builderAttachments.deleteAttachmentHandler({
            public_id: _AttachmentsFiles[idx].public_id,
            projectId: projectId,
        })

        setIsLoading(false)

        if (upload_response.success) {
            _AttachmentsFiles.splice(idx, 1)
            setAttachmentsFiles(_AttachmentsFiles)
        }
        else {
            setWarningAlert(true)
            setWarningAlertType('error')
            setApiFailedMessage(`Error in Attachments, Please try again!`)
        }
    }


    const HandleSelectPreviewImage = (image) => {
        setShowSelectedPreviewImage(true)
        setSelectedPreviewImage({
            name: image.original_filename,
            file_url: image.file_url,
        })
    }
    const HandleHideSelectPreviewImage = (confirmation) => {
        setShowSelectedPreviewImage(confirmation)
        setSelectedPreviewImage(undefined)
    }

    const [CommentsItems, setCommentsItems] = useState([])

    const [CommentsInput, setCommentsInput] = useState("")

    const LoadComments = async ({ project_id, parent_id }) => {

        console.log(project_id, parent_id, 'parent_id');
        if (!project_id || !parent_id) return

        let filter = {
            project_id: project_id,
            parent_id: parent_id
        }

        setIsLoading(true)

        let response = await commentsHandler.getCommentsHandler(filter)
        setIsLoading(false)

        if (response.success) {
            let { data } = response

            if (!Array.isArray(data)) return

            console.log(data, 'data');

            setCommentsItems(data)
        }
    }

    const HandleAddComment = async () => {
        let _CommentsItems = [...CommentsItems]

        let userdetials = JSON.parse(localStorage.getItem('userdetials'))

        _CommentsItems.push({
            id: Utils.getUniqueId(),
            created_at: new Date().getTime(),
            created_by: userdetials.id,
            created_by_name: userdetials.name,
            message: CommentsInput,
            parent_id: billID,
            parent_name: name || `Bill ${billID}`,
            parent_type: 0,
            project_id: projectId,
            company_id: userdetials.company_id,
        })

        setCommentsItems(_CommentsItems)
        setCommentsInput("")

        if (menuType == 'create') return

        let update_data = {
            project_id: projectId,
            parent_id: billID,
            parent_name: name || `Bill ${billID}`,
            parent_type: String(0),
            message: CommentsInput
        }

        let response = await commentsHandler.createCommentHandler(update_data)

        if (!response.success) {
            setWarningAlert(true)
            setWarningAlertType('error')
            setApiFailedMessage(`Error in Comments, Please try again!`)
        }
    }

    const HandleDeleteComment = async (id, idx) => {
        let _CommentsItems = [...CommentsItems]

        _CommentsItems.splice(idx, 1)

        setCommentsItems(_CommentsItems)

        if (menuType == 'create') return

        let update_data = {
            project_id: projectId,
            ids: [id]
        }

        let response = await commentsHandler.deleteCommentHandler(update_data)

        if (!response.success) {
            setWarningAlert(true)
            setWarningAlertType('error')
            setApiFailedMessage(`Error in Comments, Please try again!`)
        }
    }

    return (
        <>


            {isLoading ?
                <Loading
                    props={{
                        isMainLogo: false,
                        isLabel: true
                    }} />
                : null}
            {warningAlert ?

                <SystemToastPopup
                    props={{
                        type: warningAlertType,
                        message: apiFailedMessage || "Error in Bills",
                        callback: (confirmation) => setWarningAlert(false)
                    }} />

                : null}

            {ShowSelectedPreviewImage ?

                <ImagePreviewPopup
                    props={{
                        heading: `Preview - ${SelectedPreviewImage.name}`,
                        image: SelectedPreviewImage.file_url,
                        message: "Invalid Inputs, Please try again with vaild Inputs!",
                        callback: (confirmation) => HandleHideSelectPreviewImage(confirmation)
                    }} />

                : null}

            {paymentHistoryAlert ?

                <AlertPopup
                    props={{
                        type: "success",
                        actionBtnOption: { icon: Icons.general.warning, label: "Ok" },
                        heading: "Create Payment Record",
                        message: paymentHistoryMessage || "By recording payment method the status of the item will be changed to approved",
                        callback: (confirmation) => {
                            setPaymentHistoryAlert(false)
                            HandleAddPaymentHistory(confirmation)
                        }
                    }} />

                : null}
            {showAddpaymentHistory ?

                <AddPaymentHistory
                    props={{
                        type: "create",
                        existItemData: undefined,
                        max_amount: BillDue || 0,
                        close: () => { setShowAddpaymentHistory(false) },
                        updateCallback: (data) => {
                            HandleUpdatePaymentHistory(data, 'create')
                            setShowAddpaymentHistory(false)

                        }
                    }} />

                : null}

            {showCreatePreview ?
                <PreviewClientInvoice
                    props={{
                        menuType: 'edit',
                        billLoaded: true,
                        billdetials: {
                            id: id,
                            name: name,
                            billId: billID,
                            projectId: projectId,

                            purchase_order_id,
                            purchase_order_name,

                            vendor_id,
                            vendor_name,

                            bill_reference,

                            bill_date: billDate,
                            due_date: dueDate,
                            status,
                            bill_total: BillTotal,
                            bill_paid: BillPaid,
                            bill_due: BillDue,

                            introduction_text: introduction_text || "",
                            comments: comments || "",

                            terms_condition_id: termsConditionId || "",
                            terms_condition: termsConditionValue || "",

                            cost_items: materialItems || [],
                            payment_history: paymentHistory || [],
                        },
                        saveCallback: () => {
                            formSubmitBtnRef.current?.click()
                            setShowCreatePreview(false)
                        },
                        close: () => { setShowCreatePreview(false) }
                    }}
                />
                : null}

            <Routes>
                <Route path='/preview'
                    element={
                        <PreviewClientInvoice
                            props={{
                                menuType: 'edit',
                                billLoaded: billLoaded,
                                billdetials: {

                                    id: id,
                                    name: name,
                                    billId: billID,
                                    projectId: projectId,

                                    purchase_order_id,
                                    purchase_order_name,

                                    vendor_id,
                                    vendor_name,

                                    bill_reference,

                                    bill_date: billDate,
                                    due_date: dueDate,
                                    status,
                                    bill_total: BillTotal,
                                    bill_paid: BillPaid,
                                    bill_due: BillDue,

                                    introduction_text: introduction_text || "",
                                    comments: comments || "",

                                    terms_condition_id: termsConditionId || "",
                                    terms_condition: termsConditionValue || "",

                                    cost_items: materialItems || [],
                                    payment_history: paymentHistory || [],
                                },
                                saveCallback: (e) => {
                                    navigate(`/builder/projects/${projectId}/bills/${billId}/view`)
                                },
                                close: () => {

                                    navigate(`/builder/projects/${projectId}/bills/${billId}/view`)

                                }
                            }}
                        />}>
                </Route>

            </Routes>

            <div className="project-create_client_invoice-main" id='project-create_client_invoice-main'>
                <div className="create_client_invoice-panel-content">

                    <div className="panel-content-header">
                        <div className="left">
                            <div className="icon"
                                dangerouslySetInnerHTML={{ __html: Icons.general.back_arrow }}
                                onClick={(e) => HandleCancel(e)}
                            ></div>
                            <div className="title">Bill Detials</div>
                        </div>
                        <div className="right">
                            <div className="total-items">
                                <div className={`total-item ${!BillTotal ? 'total-item-diable' : ''}`}>
                                    <div className="label">Total</div>
                                    <div className="value">{currency_type}{parseFloat(BillTotal).toFixed(2)}</div>
                                </div>
                                <div className={`total-item ${!BillPaid ? 'total-item-diable' : ''}`}>
                                    <div className="label">Paid</div>
                                    <div className="value">{currency_type}{parseFloat(BillPaid).toFixed(2)}</div>
                                </div>
                                <div className={`total-item ${!BillDue ? 'total-item-diable' : ''}`}>
                                    <div className="label">Due</div>
                                    <div className="value">{currency_type}{parseFloat(BillDue).toFixed(2)}</div>
                                </div>
                            </div>
                            <div className="preview-button" onClick={() => HandlepreviewClientInvoice()}>
                                <div
                                    className="icon"
                                    dangerouslySetInnerHTML={{ __html: Icons.general.eye_open }}
                                ></div>
                                <div className="label">Preview</div>
                            </div>
                            {has_edit_access ?
                                <div className="edit-button" onClick={() => HandleEditClientInvoice()}>
                                    <div
                                        className="icon"
                                        dangerouslySetInnerHTML={{ __html: Icons.general.edit_btn }}
                                    ></div>
                                    <div className="label">Edit</div>
                                </div>
                                : ''}
                        </div>
                    </div>
                    <form className="panel-content-sections">
                        <div className="create-client_invoicecontent-main">

                            <div className="create-client_invoicesections"
                                style={{
                                    height: '100%'
                                }}>

                                <div className="create-client_invoicesection">
                                    <div className="section-input-items">
                                        <div
                                            className="section-input-item"
                                            style={{
                                                height: 'max-content',
                                                display: 'flex',
                                                flexDirection: 'column',
                                                gap: '8px'
                                            }}
                                        >
                                            <div
                                                className="view-input-label"
                                                style={{
                                                    fontSize: '18px',
                                                    fontWeight: '600'
                                                }}
                                            >
                                                Name
                                            </div>
                                            <div className="view-input-value">{name}</div>

                                        </div>
                                        <div
                                            className="section-input-item"
                                            style={{
                                                height: 'max-content',
                                                display: 'flex',
                                                flexDirection: 'column',
                                                gap: '8px'
                                            }}
                                        >
                                            <div
                                                className="view-input-label"
                                                style={{
                                                    fontSize: '18px',
                                                    fontWeight: '600'
                                                }}
                                            >
                                                Bill ID
                                            </div>
                                            <div className="view-input-value">{billID}</div>

                                        </div>
                                        <div
                                            className="section-input-item"
                                            style={{
                                                height: 'max-content',
                                                display: 'flex',
                                                flexDirection: 'column',
                                                gap: '8px'
                                            }}
                                        >
                                            <div
                                                className="view-input-label"
                                                style={{
                                                    fontSize: '18px',
                                                    fontWeight: '600'
                                                }}
                                            >
                                                Vendor
                                            </div>
                                            <div className="view-input-value">{vendor_name}</div>

                                        </div>
                                        <div
                                            className="section-input-item"
                                            style={{
                                                height: 'max-content',
                                                display: 'flex',
                                                flexDirection: 'column',
                                                gap: '8px'
                                            }}
                                        >
                                            <div
                                                className="view-input-label"
                                                style={{
                                                    fontSize: '18px',
                                                    fontWeight: '600'
                                                }}
                                            >
                                                Reference
                                            </div>
                                            <div className="view-input-value">{bill_reference}</div>

                                        </div>



                                    </div>
                                    <div className="section-input-items">
                                        <div
                                            className="section-input-item"
                                            style={{
                                                height: 'max-content',
                                                display: 'flex',
                                                flexDirection: 'column',
                                                gap: '8px'
                                            }}
                                        >
                                            <div
                                                className="view-input-label"
                                                style={{
                                                    fontSize: '18px',
                                                    fontWeight: '600'
                                                }}
                                            >
                                                Due Date
                                            </div>
                                            <div className="view-input-value">{dueDate}</div>

                                        </div>
                                        <div
                                            className="section-input-item"
                                            style={{
                                                height: 'max-content',
                                                display: 'flex',
                                                flexDirection: 'column',
                                                gap: '8px'
                                            }}
                                        >
                                            <div
                                                className="view-input-label"
                                                style={{
                                                    fontSize: '18px',
                                                    fontWeight: '600'
                                                }}
                                            >
                                                Status
                                            </div>
                                            <div className="view-input-value">{getStatusLabel(status)}</div>

                                        </div>

                                    </div>

                                </div>

                                <div className="create-client_invoicesection" id={`create-client_invoiceintroduction_text`}>

                                    <div className={`createproposal-description-main`}>
                                        <div className="head">
                                            <div className="title">
                                                <div className="icon" dangerouslySetInnerHTML={{ __html: Icons.general.description }}></div>
                                                <div className="label">Description</div>
                                            </div>
                                        </div>
                                        <div
                                            className="item-expanded"
                                            dangerouslySetInnerHTML={{ __html: introduction_text || "" }}
                                        >

                                        </div>
                                    </div>

                                </div>

                                <div className="create-client_invoicesection" id={`create-client_invoicepayment_schedule`}>
                                    <div className={`createproposal-description-main`}>
                                        <div className="head">
                                            <div className="title">
                                                <div className="label">Materials</div>
                                                <div className="icon" dangerouslySetInnerHTML={{ __html: Icons.general.info }}></div>
                                            </div>
                                        </div>

                                        <div className="invoice-payment-splits-main">
                                            <div className="invoice-payment-splits-header">
                                                <div className="header-item-name">Item</div>
                                                <div className="header-item-quantity">Quantity</div>
                                                <div className="header-item-unitrate">Unitrate ({currency_type || '₹'})</div>
                                                <div className="header-item-tax">Tax (%)</div>
                                                <div className="header-item-markup">Markup (%)</div>
                                                <div className="header-item-amount">Amount</div>
                                            </div>
                                            <div className="invoice-payment-splits-content">
                                                {materialItems.map((item, index) => (
                                                    <div className="invoice-payment-content-item">
                                                        <div className="invoice-payment-content-items" style={{
                                                            height: '40px'
                                                        }}>
                                                            <div className="invoice-payment-content-name">
                                                                {item.material_name}

                                                            </div>
                                                            <div className="invoice-payment-content-quantity">
                                                                {item.quantity} {item.unit_name}

                                                            </div>

                                                            <div className="invoice-payment-content-unitrate">
                                                                {currency_type} {item.unitrate}

                                                            </div>
                                                            <div className="invoice-payment-content-tax">
                                                                {item.tax}%

                                                            </div>
                                                            <div className="invoice-payment-content-markup">
                                                                {item.markup}%

                                                            </div>
                                                            <div className="invoice-payment-content-amount">{currency_type}{item.final_amount}</div>


                                                        </div>
                                                        <div className="invoice-payment-content-items" style={{ height: '40px' }}>
                                                            <div className="invoice-payment-content-description">
                                                                Description: {item.description}

                                                            </div>
                                                            <div className="invoice-payment-content-notes">
                                                                Notes: {item.notes}

                                                            </div>

                                                        </div>
                                                    </div>
                                                ))}
                                            </div>


                                        </div>

                                    </div>
                                </div>

                                <div className="create-proposal-section" id={`create-proposal-summary`}>
                                    <div className="createproject-section proposal-left-section">
                                        <div className="proposal-summary-totals proposal-summary-title">
                                            <div className="title">Summary</div>
                                        </div>
                                        <div className="proposal-summary-totals proposal-summary-items">

                                            <div className="proposal-summary-item">
                                                <div className="proposal-summary-item-content">

                                                    <div className="item">
                                                        <div className="title">Sub Total</div>
                                                        <div className="quantity"></div>
                                                        <div className="amount">
                                                            {currency_type || '₹'} {parseFloat(BillSubTotal || 0).toFixed(2)}
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                            <div className="proposal-summary-item">
                                                <div className="proposal-summary-item-content">

                                                    <div className="item">
                                                        <div className="title">Markup</div>
                                                        <div className="quantity"></div>
                                                        <div className="amount">
                                                            {currency_type || '₹'} {parseFloat(BillTotalMarkup || 0).toFixed(2)}
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                            <div className="proposal-summary-item">
                                                <div className="proposal-summary-item-content">

                                                    <div className="item">
                                                        <div className="title">PreTax Total</div>
                                                        <div className="quantity"></div>
                                                        <div className="amount">
                                                            {currency_type || '₹'} {parseFloat(BillPretaxSubTotal || 0).toFixed(2)}
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                            <div className="proposal-summary-item">
                                                <div className="proposal-summary-item-content">

                                                    <div className="item">
                                                        <div className="title">Tax</div>
                                                        <div className="quantity"></div>
                                                        <div className="amount">
                                                            {currency_type || '₹'} {parseFloat(BillTotalTax || 0).toFixed(2)}
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>

                                            <div className="proposal-summary-item">
                                                <div className="proposal-summary-item-content">

                                                    <div className="item">
                                                        <div className="title">Total</div>
                                                        <div className="quantity"></div>
                                                        <div className="amount">
                                                            {currency_type || '₹'} {parseFloat(BillTotal || 0).toFixed(2)}
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                            <div className="proposal-summary-item">
                                                <div className="proposal-summary-item-content">

                                                    <div className="item" style={{ fontSize: '24px' }}>
                                                        <div className="title">Paid</div>
                                                        <div className="quantity"></div>
                                                        <div className="amount">
                                                            {currency_type || '₹'} {parseFloat(BillPaid || 0).toFixed(2)}
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                            <div className="proposal-summary-item">
                                                <div className="proposal-summary-item-content">

                                                    <div className="item" style={{ fontSize: '24px' }}>
                                                        <div className="title">Due</div>
                                                        <div className="quantity"></div>
                                                        <div className="amount">
                                                            {currency_type || '₹'} {parseFloat(BillDue || 0).toFixed(2)}
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="create-client_invoicesection" id={`create-client_bill_payment_history`}>
                                    <div className={`createproposal-description-main`}>
                                        <div className="head">
                                            <div className="title">
                                                <div className="label">Payments History ({paymentHistory.length})</div>
                                                <div className="icon" dangerouslySetInnerHTML={{ __html: Icons.general.info }}></div>
                                            </div>
                                        </div>
                                        <div className="head">
                                            <div className="title">
                                                <div className="label"></div>
                                            </div>



                                        </div>
                                        {!paymentHistory.length ?
                                            <div className="no-paymenthistory-main">

                                                <div className="no-paymenthistory-vector">
                                                    <div className="icon" dangerouslySetInnerHTML={{ __html: Icons.general.no_records }}></div>
                                                    <div className="label">No payments added yet</div>
                                                </div>


                                            </div>
                                            : ''}
                                        {paymentHistory.length ?

                                            <div className="paymenthistory-table-main">
                                                <div className="paymenthistory-table-header">
                                                    <div className="header-item table-item-sno">S:No</div>
                                                    <div className="header-item table-item-date">Date</div>
                                                    <div className="header-item table-item-amount">Amount</div>
                                                    <div className="header-item table-item-payment_method">Payment Method</div>
                                                    <div className="header-item table-item-notes">Notes</div>
                                                    <div className="header-item table-item-status">Status</div>
                                                </div>
                                                <div className="paymenthistory-table-content">

                                                    {paymentHistory?.map((item, i) => (
                                                        <div
                                                            className="content-row"
                                                            key={`paymenthistory-table-item-${item.id}`}
                                                        >
                                                            <div className="content-row-item table-item-sno">{i + 1}</div>
                                                            <div className="content-row-item table-item-date">{item.date}</div>
                                                            <div className="content-row-item table-item-amount">{currency_type} {item.amount}</div>
                                                            <div className="content-row-item table-item-payment_method">{item.payment_method}</div>
                                                            <div className="content-row-item table-item-notes">
                                                                {item.notes}
                                                            </div>
                                                            <div className="content-row-item table-item-status">
                                                                {item.status}

                                                            </div>


                                                        </div>
                                                    ))}

                                                </div>

                                            </div>

                                            : ''}


                                    </div>
                                </div>

                                <div className="create-client_invoicesection" id={`create-proposal-attachments`}>
                                    <div className={`createproposal-description-main`}>
                                        <div className="head">
                                            <div className="title">
                                                <div className="icon" dangerouslySetInnerHTML={{ __html: Icons.general.attachments }}></div>
                                                <div className="label">Attachments</div>
                                            </div>
                                        </div>
                                        <div className="attachments-section-main">
                                            <div className="attachments-section-files">

                                                {AttachmentsFiles?.map((file, idx) => (
                                                    <div
                                                        className="attachment-file"
                                                        key={file.public_id}
                                                        id={`file-${file.public_id}`}
                                                    >

                                                        <div className="attachment-file-preview" onClick={() => HandleSelectPreviewImage(file)}>
                                                            <img src={file.file_url} alt="attachment-file" />
                                                        </div>
                                                        <div className="attachment-file-footer">
                                                            <div className="file-name">{file.original_filename}</div>
                                                            <div
                                                                className="file-download"
                                                                dangerouslySetInnerHTML={{ __html: Icons.general.download }}
                                                            ></div>
                                                        </div>

                                                    </div>
                                                ))}


                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="create-client_invoicesection" id={`create-client_bill_terms_conditions`}>
                                    <div className={`createproposal-description-main`}>
                                        <div className="head">
                                            <div className="title">
                                                <div className="label">Terms and conditions</div>
                                                <div className="icon" dangerouslySetInnerHTML={{ __html: Icons.general.info }}></div>
                                            </div>
                                        </div>

                                        <div className="terms-condition-result" dangerouslySetInnerHTML={{ __html: termsConditionValue }}></div>
                                    </div>
                                </div>
                                <div className="create-proposal-section">

                                    <div className={`createproposal-description-main`}>
                                        <div className="head">
                                            <div className="label">Comments</div>
                                        </div>
                                        <div className="comments-section-main">
                                            <div className="comments-section-items">

                                                {CommentsItems.map((item, idx) => (

                                                    <div
                                                        className="comments-item-main"
                                                        key={`comments-item-${item.id}`}
                                                        id={`comments-item-${item.id}`}
                                                    >
                                                        <div className="comments-item-usericon">
                                                            {item.created_by_name.charAt(0)}
                                                        </div>
                                                        <div className="comments-item-detials">
                                                            <div className="detials-head">
                                                                <div className="left">
                                                                    <div className="name">{item.created_by_name}</div>
                                                                    <div className="time">{Utils.getLocalFullDateLabel(item.created_at)}</div>
                                                                </div>
                                                                <div className="right">
                                                                    <div
                                                                        className="delete-icon"
                                                                        onClick={() => HandleDeleteComment(item.id, idx)}
                                                                        dangerouslySetInnerHTML={{ __html: Icons.general.delete_btn }}></div>
                                                                </div>
                                                            </div>
                                                            <div className="detials-message">{item.message}</div>
                                                        </div>
                                                    </div>


                                                ))}


                                                <div className="comments-input-main">
                                                    <div className="comments-input-usericon">
                                                        RT
                                                    </div>
                                                    <div className="comments-input comments-input-active">
                                                        <div className="comments-input-textarea">
                                                            <textarea
                                                                placeholder='Add a comment...'
                                                                value={CommentsInput}
                                                                onChange={(e) => setCommentsInput(e.target.value)}
                                                            ></textarea>
                                                        </div>
                                                        <div className={`comments-input-controls ${CommentsInput.length ? `comments-input-controls-active` : ''}`}>
                                                            <div
                                                                className={`comments-input-control-sendmsg`}
                                                                onClick={() => HandleAddComment()}
                                                            >
                                                                <div className="icon" dangerouslySetInnerHTML={{ __html: Icons.general.send_message }}></div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>


                        </div>
                    </form>
                </div >

            </div >
        </>
    )

}

export default ViewBill;